import {  ActionIcon,  Box,  Flex,  Grid,  Menu,  Paper,  Text,  rem,} from "@mantine/core";
import { useEffect, useState } from "react";
import CalendarComponents from "../../../time_master/calendar";
import { IconX,} from "@tabler/icons-react";
import TrackWork from "./track_work";
import {  JudgmentTimeThreshold,   getCurrentWeekUtils, getYear,} from "../../../../utils/function";
import { notifications } from "@mantine/notifications";
import { Calendar } from "../../../time_master/svg/c";
import { Left } from "../../../time_master/svg/l";
import { Right } from "../../../time_master/svg/r";
import { regTimetrackerListWeekTotalTime, regUserAll } from "../../../../api";
import { ItimeProps } from "../../../../interface/Itimetrack";
import memoryUtils from "../../../../utils/memoryUtils";
import WordTranslationComponent from "../../../../utils/language_pack/words";
import PageTitle from "../../../../ttcomponents/PageTitle/PageTitle";
import ChooseUser from "./top/choose_user";
import TimeTrackerWeekStatisticsByUserId from "./timetracker_week_statistics_by_user_id";
import UniversalIcon from "../../../../ttcomponents/Icon/UniversalIcon";
import { requestTimeSheetUserList } from "../../../../api/index_v2";

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  image: string;
  label: string;
  value: string;
  description: string;
}

export default function TimeTrack() {
  // Get translation public configuration information
  const word_translation = WordTranslationComponent();
  // 初始化userID
  const [userId, setUseId] = useState<string | null>(memoryUtils.user.id_user);

  const [userlistnew, setUserList] = useState<ItemProps[]>([]);
  // Initialization current date
  const [isManager, setIsManager] = useState<boolean>(false);
  // Define the current week
  const [currentWeek, setCurrentWeek] = useState(
    getCurrentWeekUtils(new Date())
  );
  //Define the current year
  const [currentYear, setCurrentYear] = useState(0);
  //Define the current week noun
  const [dayOfWeekText, setDayOfWeekText] = useState("");

  const [timeTongji, setTimetongji] = useState<ItimeProps>({
    actual_time: "",
    target_time: "",
    diff_time: "",
    productivity: "",
  });

  const [currentDate, setCurrentDate] = useState<Date | null>(new Date());

  const getCurrentWeekNumber = (current: Date | null): number => {
    let weekNumber = getCurrentWeekUtils(current);
    setCurrentWeek(weekNumber);
    getdayOfWeek(current as Date);
    setCurrentYear(getYear(current as Date));
    return weekNumber;
  };


  useEffect(() => {
    // getTimetongji();
    getUserList();
    getCurrentWeekNumber(currentDate);
  }, [currentDate]);

  const getTimetongji = async () => {
    const week = getCurrentWeekNumber(currentDate);
    const TimetrackerListWeekTotalTime = await regTimetrackerListWeekTotalTime({  week: week, });
    setTimetongji(TimetrackerListWeekTotalTime.data.data);
  };

  const getUserList = async () => {
    // 获取user'
    try {
      const responseUser = await requestTimeSheetUserList({});
      await regUserAll({});
      const resultUser = responseUser.data;
      const userData = resultUser.data.managed_user_list.map(
        (item: { id_user: string; username: any; avatar: any }) => ({
          value: item.id_user as string,
          label: item.username,
          image: item.avatar,
        })
      );
      setIsManager(resultUser.data.is_manager)
      setUserList(userData);
    } catch (error) {
      // Log any errors that occur during the request
      console.error('Error fetching user registrations:', error);
    } finally {
      // Set loading state to false regardless of success or failure
    
    }
  };


  const SelectDate = (date: Date) => {
    if (JudgmentTimeThreshold(date) === false) {
      return false;
    }
    setCurrentDate(date);
    getdayOfWeek(date);
    let week: number = getCurrentWeekNumber(date);
  };

  const getdayOfWeek = (date: Date) => {
    //Convert the numbers of the week to the corresponding text representation
    const dayOfWeek = date.getDay();
   
    const weekdays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const dayOfWeekText = weekdays[dayOfWeek];
    setDayOfWeekText(dayOfWeekText);
  };
  const handleNextWeek = () => {
    if (currentDate) {
      const nextDate = new Date(
        currentDate.getTime() + 1000 * 60 * 60 * 24 * 7
      );

      if ((nextDate as Date) > new Date()) {
        notifications.show({
          title: "warning",
          color: "yellow.7",
          icon: <IconX />,
          message: "Can't choose future time",
        });
        return false;
      }
      getdayOfWeek(nextDate);
      setCurrentDate(nextDate);
    }
  };

  const handlePreviousWeek = () => {
    if (currentDate) {
      const previousDate = new Date(
        currentDate.getTime() - 1000 * 60 * 60 * 24 * 7
      );
      if (JudgmentTimeThreshold(previousDate) === false) {
        return false;
      }
      getdayOfWeek(previousDate);
      setCurrentDate(previousDate);
      // getCurrentWeekNumber(previousDate)
    }
  };

  const callbackHandler = () => {
    getTimetongji();
  };

  return (
    <Paper px={20} pb={10} w={"100%"} >
      <Box pb={90}>
        <Grid  gutter={0}   className="time_track_title_header"  justify="center" align="center"     >
        <Grid.Col span={6} >
            <PageTitle title={word_translation.timesheet_title} />
          </Grid.Col>
          {/* <Grid.Col span={1}><Text c='#fff' fw={100} size={10} >{formatDate(currentDate as Date)}-{dayOfWeekText}</Text></Grid.Col> */}
          <Grid.Col span={6}>
            <Flex justify="end" align="center">

              {/* Select a member and load the timetracker option component of the member */}
              {isManager &&  <ChooseUser userId={userId} userlistnew={userlistnew}  callBack={setUseId} />}
              {/***********************END**********************/}
              
               <Box>
                <Text c="#fff" fw={700}>{word_translation.kw} {currentWeek}  </Text>
              </Box>
               <Box pt={4}>
                <Menu openDelay={100} closeDelay={400} position="bottom-end">
                  <Menu.Target>
                    <ActionIcon     size={30} mx={15} radius="md" variant="transparent" color="customize-gray.7"  >
                      <Calendar width={rem(300)} />
                    </ActionIcon>
                  </Menu.Target>
                  <Menu.Dropdown>
                    <CalendarComponents
                      currentDate={currentDate}
                      CallBack={SelectDate}
                    />
                  </Menu.Dropdown>
                </Menu>
              </Box>
              <Box pt={4}>
                <UniversalIcon icon={ <Left width={rem(300)} />}  callBack={() => handlePreviousWeek()} size={30}  />
              </Box>
              <Box pt={4}>
                <UniversalIcon icon={ <Right width={rem(300)} />}  callBack={() => handleNextWeek()} size={30}  mr={15} />
              </Box>
            </Flex>
          </Grid.Col>
          <Box className="CalendarComponents"></Box>
        </Grid>
        {/* Table part */}
        <TrackWork
          callback={callbackHandler}
          userId={userId}
          weeks={currentWeek}
          currentDate={currentDate}
          currentYear={currentYear}
        />
      </Box>
    </Paper>
  );
}
