// 根据传入的Date判断是周几  ,默认是今天

import { useNavigate } from "react-router-dom";
import { regGlobalSetting } from "../api";
import localstorageUnits from "./localstorageUnits";
import { useEffect, useState } from 'react';
import { notifications } from "@mantine/notifications";
import { IconAlertCircleFilled, IconCheck, IconX } from "@tabler/icons-react";
import GlobalCoreSetting from "./globalCortSetting";
import {
  TextInput,
  Tooltip,
  Paper,
  Grid,
  Group,
  NumberInput,
  Text
} from "@mantine/core";
import TableTextInputTitle from "../ttcomponents/TableTextInputTitle/TableTextInputTitle";
import WordTranslationComponent from "./language_pack/words";
import data from "../pages/time_master/data";
import { getStartOfWeek } from "@mantine/dates";
import { startOfWeek, differenceInCalendarWeeks } from 'date-fns';
// Initialization after the system login
export async function SystemInit() {
  try {
    // Cchest the system settings to the local area
    const Settingresponse = await regGlobalSetting({}, "GET");
    localstorageUnits.saveSystem(Settingresponse.data.data);
    localstorageUnits.removeTimeTracker();
  } catch (error) {
    return false
  }
  return true;
}

//Judgment system time threshold
export function JudgmentTimeThreshold($inputDate: Date | string)
{
  
const initSystemTime = localstorageUnits.getSystem().init_use_date
  let result = compareTimeInputs($inputDate,initSystemTime)
  if(result === "lt" ){
      ClientWarningHint('Time illegal regulations, (system use time is '+initSystemTime+')')
      return false;
  }
  return true;
}


interface InavItem {
  label: string;
  icon: React.FC<any>;
  [key: string]: any
}


export function getWeekInfo(date: Date = new Date()): { year: number; week: number; dayOfWeek: string } {
  const year = date.getFullYear();
  const firstDayOfYear = new Date(year, 0, 1);
  const pastDaysOfYear = Math.floor((date.getTime() - firstDayOfYear.getTime()) / (24 * 60 * 60 * 1000));
  const week = Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);

  const dayOfWeek = ['sun', 'mon', 'tues', 'weds', 'thurs', 'fri', 'sat'][date.getDay()];

  return { year, week, dayOfWeek };
}


export function handleBlur(event: React.FocusEvent<HTMLInputElement>) {
  let value = event.target.value;
  console.log(value);
  value = value.replace(/：/g, ":"); // 将中文冒号替换为英文冒号
  if (value) {
    const [hours, minutes, seconds] = value.split(":"); // 使用英文冒号 ":" 分割时间
    let formattedTime = "";
    if (seconds) {
      // 时间已经是"hh:mm:ss"格式，保持原样
      formattedTime = value;
    } else {
      // 时间是"hh:mm"格式，补全为"hh:mm:00"
      formattedTime = `${hours}:${minutes.padStart(2, "0")}:00`;
    }
    console.log(formattedTime);
    return formattedTime;
  }
};

export function getWeekDates(data: Date | null) {
  if (data == null) data = new Date();
  const currentDateTime = new Date(data);
  const currentDay = currentDateTime.getDay();
  const startOfWeek = new Date(currentDateTime);

  startOfWeek.setDate(currentDateTime.getDate() - currentDay + (currentDay === 0 ? -6 : 1)); // 修正日期计算

  const dates = [];
  const formatter = new Intl.DateTimeFormat('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });

  for (let i = 0; i < 7; i++) {
    const formattedDate = formatter.format(startOfWeek);
    dates.push(formattedDate);
    startOfWeek.setDate(startOfWeek.getDate() + 1);
  }

  return dates;
}


// export const getCurrentWeekUtils = (inputDate: Date | null): number => {
//   if(inputDate == null){
//     inputDate = new Date();
//   }
//   const januaryFourth = new Date(inputDate.getFullYear(), 0, 4);
//   const daysBetween = (inputDate.getTime() - januaryFourth.getTime()) / (24 * 3600 * 1000);
//   const weekNumber = Math.ceil((daysBetween + januaryFourth.getDay() + 1) / 7);
//   if(weekNumber === 0){
//     return 53
//   }
//   return weekNumber;

// };





export const getCurrentWeekUtils = (inputDate: Date | null): number => {
  if (inputDate == null) {
    inputDate = new Date();
  }

  const startOfYear = startOfWeek(new Date(inputDate.getFullYear(), 0, 1), { weekStartsOn: 1 });
  let weeks = differenceInCalendarWeeks(inputDate, startOfYear, { weekStartsOn: 1 });
  if(inputDate.getFullYear() < 2024){
    if(weeks == 0){
      let resultDate = new Date(inputDate.setDate(inputDate.getDate() - 7)); // 减去 7 天
      weeks =  getCurrentWeekUtils(resultDate);
      return weeks + 1 ;
    }else{
      return weeks;
    }
  }
  return weeks + 1;
};


// Menu Determine whether there is a sub-menu according to the label
export function isTopMenu(label: string, menuItems: any) {
  for (let item of menuItems) {
    if (item.label === label && item.links) {
      return true;
    }
  }
  return false;
}

// format time
export function formatDate(date: Date | string): string {
  if(date == null ){
    return '-'
  }
  if (typeof (date) == 'string')
    date = new Date(date)
  // 默认格式
  let dateFormat = 'DD/MM/YYYY'
  // 从缓存中拿数据  localstorageUnits.save
  if (localstorageUnits.getSystem() && localstorageUnits.getSystem().date_format)
    dateFormat = localstorageUnits.getSystem().date_format;
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  let formattedDate = dateFormat
    .replace('YYYY', String(year))
    .replace('MM', month)
    .replace('DD', day);
  return formattedDate;
}

export function formatDateRetentionYear(date: Date | string): string {
  if(date == null ){
    return '-'
  }
  if (typeof (date) == 'string')
    date = new Date(date)
  // 默认格式
  const year = date.getFullYear();

  return year.toString();
}



export function formatNumber(value: string | number, number : number): string {
  // 将值转换为浮点数
  const numericValue = typeof value === 'string' ? parseFloat(value) : value;

  // 检查是否为有效的数字
  if (!isNaN(numericValue)) {
    // 使用 toFixed 方法截取小数点后两位
    return numericValue.toFixed(number);
  } else {
    return value as any;
  }
}



// format time
export function formatDateWithIOS(date: Date | string): string {
  if(date == null ){
    return '-'
  }
  if (typeof (date) == 'string')
    date = new Date(date)
  // 默认格式
  let dateFormat = 'YYYY-MM-DD'

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  let formattedDate = dateFormat
    .replace('YYYY', String(year))
    .replace('MM', month)
    .replace('DD', day);
  return formattedDate;
}


// Convert the date string to the date string in the specified format (including seconds)
export function formatDateUpgraded(date: Date, format?: string): string {
  if(!date){
    return '';
  }
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  let formattedDate = format
    ? format.replace("YYYY", String(year))
            .replace("MM", month)
            .replace("DD", day)
            .replace("HH", hours)
            .replace("mm", minutes)
            .replace("ss", seconds)
    : `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

  if (format && formattedDate.endsWith(":00")) {
    formattedDate = formattedDate.substring(0, formattedDate.length - 3);
  }

  return formattedDate;
}
//eg: Convert time in 21/02/2013 format to 2023-02-21 format
export function convertDateFormat(dateStr: string): string {
  const parts = dateStr.split('/');
  if (parts.length === 3) {
    const [day, month, year] = parts;
    return `${year}-${month}-${day}`;
  }
  return dateStr;
}

// Compare the size of two time
// The parameter can be a time object or a string form
export function compareTimeInputs(input1: Date | string, input2: Date | string) {
  const date1 = typeof input1 === "string" ? new Date(input1) : input1;
  const date2 = typeof input2 === "string" ? new Date(input2) : input2;
  console.log(date1,date2)
  if (date1.getTime() === date2.getTime()) {
    // Time
    return 'eq';
  } else if (date1.getTime() < date2.getTime()) {
    // Time 1 before time 1
    return 'lt';
  } else {
    // Time 1 after time 2
    return 'egt';
  }
}


// 将Time转变成 00:00 型式 ，如果 为字符串整型，则进行拼接分钟
export function TimeHHSS(event:any) {
  let value ='';
  if(typeof event == 'string'){
    value = event;
  }else{
     value = event.target.value;
  }
  
  console.log(value);
  value = value.replace(/：/g, ":"); // 将中文冒号替换为英文冒号
  // 检查value是否为字符串数字
  if (!isNaN(Number(value))) {
    let formattedTime = "";
    if (value.includes(":")) {
      // 时间已经是"hh:mm"格式，保持原样
      formattedTime = value;
    } else {
      // 时间是"hhmm"格式，补全为"hh:mm"
      const hours = value.padStart(2, '0'); // 在字符串前补充0，使小时部分总是两位数
      const minutes = '00';
      formattedTime = `${hours}:${minutes}`;
    }
    // 输出格式化的值
    return formattedTime;
  } else {
    // 输出原始值
    return value;
  }
};


export function ConvertTimeStringToDecimal(timeString: string) {
  if(timeString  == '-'){
    return timeString
  }
  // 判断是否为负数
  // return timeString
  const isNegative = timeString.includes('-');

  // 移除负号并拆分小时和分钟
  const [hours, minutes] = timeString.replace('-', '').split(':');

  // 将分钟转换为小数
  const decimalMinutes = parseInt(minutes, 10) / 60;

  // 将小时和小数分钟重新组合
  let result = parseFloat(hours) + decimalMinutes;

  // 如果是负数，则取相反数
  if (isNegative) {
    result = -result;
  }

  // 将结果格式化为字符串，保留两位小数
  const formattedResult = result.toFixed(2);

  return formattedResult;
}


// 得出时间
export function StringDate(date: Date, v = ''): string {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}${v}${month}${v}${day}`;

}

/**
 * Formats a time string, keeping only the hour and minute parts.
 * @param {string} timeString - The input time string in the "HH:mm" format.
 * @returns {string} The formatted time string, including only the hour and minute parts.
 */

export function formatTimeHHSS(timeString: string): string {
  let formattedTime = '00:00';
  // Split the input time string into an array using colon as the delimiter
  if(timeString) {
    const timeParts = timeString.split(":");

    // Take the first two elements of the array (hour and minute) and join them with a colon to create the formatted time string
     formattedTime = timeParts.slice(0, 2).join(":");
  }

  // Return the formatted time string
  return formattedTime;
}

  // 获取年
  export  const getYear = (date: Date | null): number => {
    const now = date || new Date();
    return now.getFullYear();
  };


// 获取状态值
export function getStatusNameById(id: string) {
  const statusColors = [
    {
      "id": "1",
      "status_name": "Ready",
      "color": "green",
    },
    {
      "id": "2",
      "status_name": "Running",
      "color": "yellow",
    },
    {
      "id": "3",
      "status_name": "Finished",
      "color": "orange",
    },
    {
      "id": "4",
      "status_name": "Cancelled",
      "color": "red",
    },
    {
      "id": "5",
      "status_name": "Paused",
      "color": "blue",
    },
    {
      "id": "6",
      "status_name": "Archived",
      "color": "gray",
    }
  ]

  return statusColors.filter((item) => {
    return item.id == id
  })
}


// List all weeks in 53 weeks a year
export function getWeeKAll(): string[] {
  const updatedWeeknumber: string[] = [];

  for (let i = 1; i <= 53; i++) {
    updatedWeeknumber.push(i.toString());
  }

  return updatedWeeknumber;
}


export function getYearAll(): string[] {
  const updatedYearnumber: string[] = [];
  const currentYear = new Date().getFullYear(); // get current year

  for (let i = 2018; i <= currentYear; i++) {
    updatedYearnumber.push(i.toString());
  }
  
  return updatedYearnumber;
}





// Request success/Failure prompt
export const HintInfoSuccess = ( msg: any) => {
    notifications.show({
      color: "green",
      icon: <IconCheck />,
      message: msg,
    });
    return true; // 返回true表示请求成功
}


/**
 * Display a notification for catching errors in case of request success or failure.
 * @param {any} msg - The message or data received from the request.
 * @returns {boolean} - Returns true to indicate a successful request.
 */
export const HintInfoCatchError = (msg?: any) => {
  // Get translation public configuration information
  const word_translation = WordTranslationComponent();

  // Show a notification with a red color and a close (X) icon for catching errors
  notifications.show({
    color: "red",
    icon: <IconX />,
    message: word_translation.catch_error,
  });

  return true; // Returns true to indicate a successful request
}



// Request success/Failure prompt
export const HintInfoError = ( msg: any) => {
  notifications.show({
    color: "red",
    icon: <IconX />,
    message: msg,
  });
  return true; // 返回true表示请求成功
}



// Request success/Failure prompt
export const HintInfo = (result: { code: number; msg: any; data: any }) => {
  if (result.code === 200) {
     let resultCallHintInfoSuccess = HintInfoSuccess(result.msg)
    return resultCallHintInfoSuccess; // 返回true表示请求成功
  } else {
    if (result.data && typeof result.data === 'object' && Object.keys(result.data).length > 0) {
      const firstKey = Object.keys(result.data)[0];
      const firstValue = result.data[firstKey];
      console.log(firstValue); // 输出第一个键的值
      HintInfoError(firstValue)
    }else{
      HintInfoError(result.msg)
    }
    return false; // 返回false表示请求失败
  }
}





// Request success/failure prompt
export const HintErrorMantineInfo = (result: any) => {
  // Check if the result is an object and contains errors
  if (typeof result === 'object' && result.hasErrors) {
    const errors = result.errors;

    // Check if there are errors and the number of keys is greater than 0
    if (errors && Object.keys(errors).length > 0) {
      // Get the first key and its corresponding value
      const firstKey = Object.keys(errors)[0];
      const firstValue = errors[firstKey];


      // Display a notification with the first error message
      HintInfoError(firstValue)
    }
  }else{
    ClientWarningHint(result)
  }
};



// Client warning client warning
export const ClientWarningHint =(errors:any)=>{
  let message = errors;
  if(typeof(errors) != 'string'){
    message =   Object.values(errors)[0] as React.ReactNode
  }
  notifications.show({
    color: "yellow.7",
    icon: <IconAlertCircleFilled />,
    message: message,
  });
}

// Total price processing

export const TotalPriceFunction = (num: any,unit_price:string) => {
  if (typeof num === 'number') {
    const unitPrice = parseFloat(String(unit_price)) || 0;
    const totalPrice = (unitPrice * num).toFixed(2); // 保留两位小数
    return totalPrice
  }
};

export const calculateUnitPrice=(totalprice: string, quantity: string)=> {
  const total = parseFloat(totalprice);
  const qty = parseFloat(quantity);
  if (!isNaN(total) && !isNaN(qty) && qty !== 0) {
    const unitPrice = total / qty;
    return unitPrice;
  } else {
    return null; // 或者返回一个默认值，或抛出异常，取决于你的需求
  }


}

export const ProjectOrderTaskModel = ()=> {
  const taskModeFlag = localstorageUnits.getSystem().task_mode_flag;
  return taskModeFlag == 1;
}
export const ProjectTaskModel = ()=> {
  const taskModeFlag = localstorageUnits.getSystem().task_mode_flag;
  return taskModeFlag == 2;
}
export const OrderModel = ()=> {
  const taskModeFlag = localstorageUnits.getSystem().task_mode_flag;
  return taskModeFlag == 3;
}
export const ProjectOrderFlag = ()=> {
  const taskModeFlag = localstorageUnits.getSystem().task_mode_flag;
  return taskModeFlag == 4;
}
export const OrderNotaskModel = ()=> {
  const taskModeFlag = localstorageUnits.getSystem().task_mode_flag;
  return taskModeFlag == 5;
}




/**
 * Obtain the corresponding onboarding status label based on the value incoming
 * @param {string} value - The value of the onboarding state
 * @returns {string} - the label of the onboarding status or "Unknown Status"
 */
export const getOnboardingStatusLabel = (value: string) => {
  
  // Get the default unchanged configuration
  const globalCoreSetting = GlobalCoreSetting();
  //Look for the corresponding onboarding status from the global settings
  const onboardingStatus = globalCoreSetting.ONBOARDING_STATUS.find(status => status.value === value);
  // If the corresponding onboarding status is found, the label is returned, otherwise "Unknown Status" is returned
  return onboardingStatus ? onboardingStatus.label : "Unknown Status";
};
/**
 * Get preview component
 * @param notation -string of component type type
 * @ReturnS Return to the corresponding component or null
 */
export const getPreviewComponent = (notation: string): JSX.Element => {
  //Convert Notment to a lowercase
  const lowerCaseNotation = notation.toLowerCase();
  //Define component mapping table
  const componentMap: Record<string, JSX.Element> = {
    text: <TextInput />,
    number: <NumberInput />,
    // You can add more mapping relationships as needed
  };
  return componentMap[lowerCaseNotation] || null ;
};

/**
* Get ATTRIBUTES preview component
 * @param notation -string of component type type
 * @ReturnS Return to the corresponding component or null
 */

export const getAttributesPreviewComponent = (
  attributes_id: string,
   notation: string, 
   name: string, 
   default_value: string,
   additionalCallback: (attributes_id: string ,newValue: string | number) => void,
   key?: any,): JSX.Element => {
  //Convert Notment to a lowercase
  const lowerCaseNotation = notation?.toLowerCase();
    // 定义一个更新组件值的回调函数
  const handleUpdate = (attributes_id: string ,newValue: string | number) => {
    additionalCallback(attributes_id,newValue); 
  };
  
  // Define component mapping table
  const componentMap: Record<string, JSX.Element> = {
    text: <>
       <Tooltip  label={name}  >
         <Text  
          className="nonowrap input_title" py={4}>{name}</Text>
      </Tooltip>
      <TextInput
      key={key}
      onChange={(event) => handleUpdate(attributes_id,event.currentTarget.value)} 
      defaultValue={default_value} />
    </>,
    number: <>
      <Text className=" nonowrap input_title" py={4}>{name}</Text>
      <NumberInput 
      key={key?key:''}
      onChange={(event) => handleUpdate(attributes_id,event)} 
      defaultValue={parseInt(default_value, 10)} />
    </>,
    // You can add more mapping relationships as needed
  };

  return componentMap[lowerCaseNotation] || null;
};



/**
 * Truncates a given string to a specified maximum length.
 * If the length of the string exceeds the maximum length,
 * it is truncated and '...' is appended to the end.
 * 
 * @param {string} str - The input string to be truncated.
 * @param {number} maxLength - The maximum length allowed for the string.
 * @returns {string} - The truncated string.
 */
export const truncateString = (str: string, maxLength: number): string => {
  // Determine whether the length of the string exceeds the maximum length
  if(str){
    return str.length > maxLength ? str.slice(0, maxLength) + '...' : str;
  }else{
    return '';
  }
}


/**
 * Retrieves the label corresponding to the provided assignment status value.
 * @param {string} status - The assignment status value.
 * @returns {string | undefined} - The label associated with the provided status value, or undefined if not found.
 */
export const getAssignmentStatusLabelByValue = (status: string): string | undefined => {
  // Get the default unchanged configuration
  const globalCoreSetting = GlobalCoreSetting();
  // Find the assignment status object with the matching value
  const foundStatus = globalCoreSetting.ASSIGNMENT_STATUS.find((s) => s.value === status.toString());

  // Return the label if the status is found, otherwise return undefined
  return foundStatus ? foundStatus.label : undefined;
};


/**
 * Retrieves the label corresponding to the provided assignment status value.
 * @param {string} status - The assignment status value.
 * @returns {string | undefined} - The label associated with the provided status value, or undefined if not found.
 */
export const getAssignmentStatusColorByValue = (status: string): string | undefined => {
  // Get the default unchanged configuration
  const globalCoreSetting = GlobalCoreSetting();
  // Find the assignment status object with the matching value
  const foundStatus = globalCoreSetting.ASSIGNMENT_STATUS.find((s) => s.value === status.toString());

  // Return the label if the status is found, otherwise return undefined
  return foundStatus ? foundStatus.color : undefined;
};



// Encrypt and decrypt arrays

// 引入 crypto-js 库
const CryptoJS = require('crypto-js');

// 加密函数
export const  encryptData = (data :any, key:string, iv:string) =>  {
  const encrypted = CryptoJS.AES.encrypt(data, key, { iv: iv });
  return encrypted.toString();
}

// 解密函数

export const  decryptData = (encryptedData:any, key:string, iv:string) => {
  const decrypted = CryptoJS.AES.decrypt(encryptedData, key, { iv: iv });
  return decrypted.toString(CryptoJS.enc.Utf8);
}


//===================================
// deepEqual function for deep comparison of two arrays.
// Parameter obj1: The first array.
// Parameter obj2: The second array.
// Returns: true if two arrays are deeply equal, false otherwise.
export const deepEqual = (obj1: any[], obj2: any[]) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}
//====================================
