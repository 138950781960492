import {
  Box,
  Button,
  Card,
  Group,
  LoadingOverlay,
  Grid,
  Textarea,
  Code,
  Title,
  Select,
  Text,
  TextInput,
  TransferListData,
  Table,
  Avatar,
  ActionIcon,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { hasLength, isNotEmpty, useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import {
  IconCalendar,
  IconCheck,
  IconDots,
  IconMoneybag,
  IconPlus,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import { FormEvent, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { IprojectTask } from "../../../../interface/ItaskOnlyProject";
import { regCustomerList, regProjectOnly } from "../../../../api";
import { SelectRole } from "../../../../interface/Icommon";
import {
  CustomerData,
  ResponseCustomerData as CustomerApi,
} from "../../../../interface/Icoustomer";
import SaveBottom from "../../../../ttcomponents/Button/SaveBtn";

interface IFromProps {
  callBack: () => void;
  formDataProps: IprojectTask | undefined;
  [key: string]: any;
}

export default function TaskOnlyProjectEdit({
  callBack,
  formDataProps,
}: IFromProps) {
  //加载中状态
  const [loading, setLoading] = useState(false);

  const formRef = useRef<HTMLFormElement>(null);

  const [searchValue, onSearchChange] = useState(formDataProps?.status_id);
  // 钩子函数-切换语言
  const intl = useIntl();

  // 初始化表单数据
  const form = useForm({
    initialValues: {
      project_no: formDataProps?.project_no,
      id: formDataProps?.id_task_only_project,
      info: formDataProps?.info,
      customer_id: formDataProps?.customer_id,
      name: formDataProps?.name,
      status_id: formDataProps?.status_id,
    },
    validate: {
      name: (value) =>
        (value as string).length < 1 ? "Name is required" : null,
    },
  });

  // 处理修改请求
  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    setLoading(false);
    event.preventDefault(); // Stop the formal submission of the form default
    // Verification form
    const valid = await form.validate();
    if (!valid.hasErrors) {
      const response = await regProjectOnly(form.values, "PUT");
      const result = response.data;
      if (result.code === 200) {
        setLoading(false);
        callBack();
        notifications.show({
          color: "green",
          icon: <IconCheck />,
          message: result.msg,
        });
      } else {
        notifications.show({
          color: "red",
          icon: <IconX />,
          message: result.msg,
        });
      }
    } else {
      notifications.show({
        color: "yellow.7",
        icon: <IconX />,
        message: Object.values(valid.errors)[0],
      });
    }
  };

  const [customerlist, setCustomerList] = useState<SelectRole[]>([]);
  const ajaxCurrentList = async () => {
    // 获取客户信息  Get customer information
    const response = await regCustomerList({});
    const result = response.data as CustomerApi;
    if (result.code === 200) {
      const customerData = result.data.map((item) => ({
        value: item.id_customer as string,
        label: item.company_name,
      }));
      setCustomerList(customerData);
    }
  };
  useEffect(() => {
    ajaxCurrentList();
  }, []);
  return (
    <form
      onSubmit={handleFormSubmit}
      style={{ position: "relative" }}
      ref={formRef}
    >
      <LoadingOverlay visible={loading} overlayBlur={2} />
      <Box ml={20} mih={500}>
        <Card>
          <Grid>
            <Grid.Col span={12}>
              <TextInput
                withAsterisk
                mt={10}
                labelProps={{ className: "input_title" }}
                {...form.getInputProps("name")}
                label={
                  <FormattedMessage id="name" defaultMessage="Project Name" />
                }
                placeholder={intl.formatMessage({
                  id: "input",
                  defaultMessage: "Input",
                })}
              />
              <TextInput
                withAsterisk
                mt={10}
                w={300}
                required
                labelProps={{ className: "input_title" }}
                {...form.getInputProps("project_no")}
                label={intl.formatMessage({
                  id: "project_no",
                  defaultMessage: "Project No",
                })}
                placeholder={intl.formatMessage({
                  id: "Input",
                  defaultMessage: "Input",
                })}
              />

              <Select
                placeholder={intl.formatMessage({
                  id: "select_customer",
                  defaultMessage: "Select Customer",
                })}
                labelProps={{ className: "input_title" }}
                label={intl.formatMessage({
                  // formatting a message
                  id: "Customer",
                  defaultMessage: "Customer",
                })}
                disabled
                value={form.values.customer_id}
                searchable
                dropdownPosition="bottom"
                nothingFound={intl.formatMessage({
                  id: "No options",
                  defaultMessage: "No options",
                })}
                onChange={(value) =>
                  form.setFieldValue("customer_id", value as string)
                }
                data={customerlist}
              />
              <Textarea
                // disabled={descEnable}
                labelProps={{ className: "input_title" }}
                placeholder={intl.formatMessage({
                  id: "input",
                  defaultMessage: "Input",
                })}
                label={
                  <FormattedMessage
                    id="Description"
                    defaultMessage="Description"
                  />
                }
                {...form.getInputProps("info")}
              />
            </Grid.Col>
          </Grid>
          {/* 如果存在雇员以表格形式显示 */}
          {/* <Code block mt={5}>
            {JSON.stringify(form.values, null, 2)}
          </Code> */}

          <Group position="right">
            <SaveBottom formRef={formRef} />
          </Group>
        </Card>
      </Box>
    </form>
  );
}
