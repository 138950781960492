import {
  Box,
  Grid,
  Avatar,
  Flex,
  Modal,
  Group,
  Text,
  Paper,
  LoadingOverlay,
  Table,
  Checkbox,
  Radio,
  ScrollArea,
  Tooltip,
  ActionIcon,
  Textarea,
  TextInput,
  Code,
} from "@mantine/core";
import SearchBox from "../../ttcomponents/SearchBox/SearchBox";
import {
  IconCheck,
  IconSquareRoundedPlus,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import "./css/index.css";
import { DatePicker } from "@mantine/dates";
import { CSSProperties, useEffect, useRef, useState } from "react";
import {
  Calendar,
  DateCellWrapperProps,
  momentLocalizer,
  SlotInfo,
} from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import { Vacation } from "../../interface/Ivaction";
import {
  ClientWarningHint,
  HintInfo,
  formatDate,
  formatDateWithIOS,
  formatTimeHHSS,
} from "../../utils/function";
import globalCortSetting from "../../utils/globalCortSetting";
import { modals } from "@mantine/modals";
import {
  regGetVation,
  regGetVationAction,
  regSickTimeAction,
  regTimeTrack,
  regTimeTrackRecordUp,
  regVacation,
  regVacationApproval,
} from "../../api";
import { Cancel } from "./svg/Cancel";
import EditVation from "./edit_vacation";
import { useDisclosure } from "@mantine/hooks";
import memoryUtils from "../../utils/memoryUtils";
import { ItimeTrackTask } from "../../interface/Itimetrack";
import { notifications } from "@mantine/notifications";
import { useIntl } from "react-intl";
const localizer = momentLocalizer(moment);

interface IRow {
  type: number;
  rand: number;
  timeTracker: ItimeTrackTask[];
  callBack: () => void;
}

const numberEg = [1, 2, 4, 56];
export default function SickRows({ callBack, timeTracker, rand }: IRow) {
  // Use the useIntl hook to access the intl object
  const intl = useIntl();

  // Get the current user information
  const userExists = memoryUtils.user;

  //Operate the application holiday
  const [randrow, setRandrow] = useState(0);
  const [openedEditVation, { open: EditVationopened, close: EditVationClose }] =
    useDisclosure(false);
  const [vacation, setVacation] = useState<Vacation>();

  var remarkNew = "";
  // Settings
  const [commnetvalue, setCommentValue] = useState("");
  const countRef = useRef(commnetvalue);
  countRef.current = commnetvalue;

  // Holiday request for processing application
  const ProcessingRequestHandle = async (
    row: any,
    type: string,
    remarks: string
  ) => {
    modals.openConfirmModal({
      title: intl.formatMessage({
        id: "delete_title",
        defaultMessage: "Delete Confirmation",
      }),
      centered: true,
      children: <Text size="sm">confirm to delete</Text>,
      labels: {
        confirm: intl.formatMessage({
          id: "confirm_delete",
          defaultMessage: "Confirm Delete",
        }),
        cancel: intl.formatMessage({
          id: "cancel_delete",
          defaultMessage: "Cancel Delete",
        }),
      },
      confirmProps: { color: "red" },
      onConfirm: async () => {
        const ApprovalResult = await regGetVationAction(
          { id: row.id_vacation },
          "DELETE"
        );
        HintInfo(ApprovalResult.data);
        if (ApprovalResult.data.code == 200) callBack();
      },
    });
  };
  const EditVationCallback = () => {
    callBack();
    EditVationClose();
  };

  // Listen to changes in timeTracker and force re-render
  useEffect(() => {
    // This effect will be triggered whenever timeTracker changes
    // Trigger a re-render by updating the state
    setRandrow(randrow + 1);
  }, [timeTracker]);

  const EditTimeTrackRecode = async (
    task: any,
    date: string,
    currenttime: string,
    value: string
  ) => {
    const FormData = {
      id: task.id_time_tracker,
      time: value,
      currentDayOfWeek: date,
    };
    //Use variables as key names to obtain the corresponding key value pair
    const dateValue = task[date];
    // If it does not change, it will not trigger the modification
    if (formatTimeHHSS(dateValue) == value) return;
    let methodType = "PUT";
    // setLoading(true);
    const response = await regTimeTrackRecordUp(FormData, methodType);
    // setLoading(false);
    const result = response.data;

    HintInfo(result);
    callBack();
  };

  // Delete
  const delHandle = (row: object) => {
    // return;
    const delValue = row as {
      id_time_tracker: string;
      week: string;
      total_time: string;
    };
    modals.openConfirmModal({
      title: "Delete this sick leave information ",
      centered: true,
      children: (
        <Text size="sm">
          Week : <Code>{delValue.week}</Code>
        </Text>
      ),
      labels: {
        confirm: intl.formatMessage({
          id: "confirm_delete",
          defaultMessage: "Confirm Delete",
        }),
        cancel: intl.formatMessage({
          id: "cancel_delete",
          defaultMessage: "Cancel Delete",
        }),
      },
      confirmProps: { color: "red" },
      onConfirm: async () => {
        const FormData = {
          id: delValue.id_time_tracker,
        };
        const response = await regTimeTrack(FormData, "DELETE");
        const result = response.data;
        if (result.code === 200) {
          callBack();
          notifications.show({
            color: "green",
            icon: <IconCheck />,
            message: result.msg,
          });
        } else {
          notifications.show({
            color: "red",
            icon: <IconX />,
            message: result.msg,
          });
        }
      },
    });
  };
  //Click the INTER key to input to trigger editing time
  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    tastlistItem: any,
    data: string
  ) => {
    if (event.key === "Enter") {
      EditTimeTrackRecode(
        tastlistItem,
        data,
        tastlistItem.mon,
        (event.target as HTMLInputElement).value
      );
    }
  };
  console.log(timeTracker);
  return (
    <Box className="sick-right-top-row  margin-18">
      <Group className="margin-26">
        <Box className="columns-Box">
          <Text className="columns-title">
            {intl.formatMessage({
              id: "year",
              defaultMessage: "Year",
            })}
          </Text>
        </Box>
        <Box className="columns-Box">
          <Text className="columns-title">
            {intl.formatMessage({
              id: "week",
              defaultMessage: "Week",
            })}
          </Text>
        </Box>
        <Box className="columns-Box">
          <Text className="columns-title">
            {intl.formatMessage({
              id: "Monday",
              defaultMessage: "Monday",
            })}
          </Text>
        </Box>
        <Box className="columns-Box">
          <Text className="columns-title">
            {intl.formatMessage({
              id: "Tuesday",
              defaultMessage: "Tuesday",
            })}
          </Text>
        </Box>
        <Box className="columns-Box">
          <Text className="columns-title">
            {intl.formatMessage({
              id: "Wednesday",
              defaultMessage: "Wednesday",
            })}
          </Text>
        </Box>
        <Box className="columns-Box">
          <Text className="columns-title">
            {intl.formatMessage({
              id: "Thursday",
              defaultMessage: "Thursday",
            })}
          </Text>
        </Box>
        <Box className="columns-Box">
          <Text className="columns-title">
            {intl.formatMessage({
              id: "Friday",
              defaultMessage: "Friday",
            })}
          </Text>
        </Box>
        <Box className="columns-Box">
          <Text className="columns-title">
            {intl.formatMessage({
              id: "Saturday",
              defaultMessage: "Saturday",
            })}
          </Text>
        </Box>
        <Box className="columns-Box">
          <Text className="columns-title">
            {intl.formatMessage({
              id: "Sunday",
              defaultMessage: "Sunday",
            })}
          </Text>
        </Box>
        <Box className="columns-Box">
          <Text className="columns-title">
            {intl.formatMessage({
              id: "Option",
              defaultMessage: "Option",
            })}
          </Text>
        </Box>
      </Group>

      <Box className="margin-26 border-Underline"></Box>
      <ScrollArea h={640}>
        {timeTracker.map((item, index) => {
          return (
            <>
              {" "}
              <Group className="margin-26" key={index}>
                <Box className="columns-Box">
                  {" "}
                  <Text className="columns-title">{item.year}</Text>
                </Box>
                <Box className="columns-Box">
                  {" "}
                  <Text color="red" className="columns-title">
                    {item.week}
                  </Text>
                </Box>
                <Box className="columns-Box">
                  <TextInput
                    withAsterisk
                    variant="unstyled"
                    key={randrow}
                    defaultValue={formatTimeHHSS(item.mon)}
                    className="columns-title"
                    onBlur={(event) =>
                      EditTimeTrackRecode(
                        item,
                        "mon",
                        "type",
                        event.target.value
                      )
                    }
                    onKeyDown={(event) => handleKeyDown(event, item, "mon")}
                    placeholder="mo"
                  />
                </Box>

                <Box className="columns-Box">
                  <TextInput
                    withAsterisk
                    key={randrow}
                    variant="unstyled"
                    defaultValue={formatTimeHHSS(item.tues)}
                    className="columns-title"
                    onBlur={(event) =>
                      EditTimeTrackRecode(
                        item,
                        "tues",
                        "type",
                        event.target.value
                      )
                    }
                    onKeyDown={(event) => handleKeyDown(event, item, "tues")}
                    placeholder="mo"
                  />
                </Box>

                <Box className="columns-Box">
                  <TextInput
                    withAsterisk
                    variant="unstyled"
                    key={randrow}
                    defaultValue={formatTimeHHSS(item.weds)}
                    className="columns-title"
                    onBlur={(event) =>
                      EditTimeTrackRecode(
                        item,
                        "weds",
                        "type",
                        event.target.value
                      )
                    }
                    onKeyDown={(event) => handleKeyDown(event, item, "weds")}
                    placeholder="mo"
                  />
                </Box>

                <Box className="columns-Box">
                  <TextInput
                    withAsterisk
                    variant="unstyled"
                    key={randrow}
                    defaultValue={formatTimeHHSS(item.thurs)}
                    className="columns-title"
                    onBlur={(event) =>
                      EditTimeTrackRecode(
                        item,
                        "thurs",
                        "type",
                        event.target.value
                      )
                    }
                    onKeyDown={(event) => handleKeyDown(event, item, "thurs")}
                    placeholder="mo"
                  />
                </Box>

                <Box className="columns-Box">
                  <TextInput
                    withAsterisk
                    variant="unstyled"
                    key={randrow}
                    defaultValue={formatTimeHHSS(item.fri)}
                    className="columns-title"
                    onBlur={(event) =>
                      EditTimeTrackRecode(
                        item,
                        "fri",
                        "type",
                        event.target.value
                      )
                    }
                    onKeyDown={(event) => handleKeyDown(event, item, "fri")}
                    placeholder="mo"
                  />
                </Box>
                <Box className="columns-Box">
                  {" "}
                  <Text className="columns-title">/</Text>
                </Box>
                <Box className="columns-Box">
                  {" "}
                  <Text className="columns-title">/</Text>
                </Box>
                <Box className="columns-Box">
                  <ActionIcon
                    onClick={() => {
                      delHandle(item);
                    }}
                  >
                    <IconTrash size="1rem" color="red" stroke={1.5} />{" "}
                  </ActionIcon>
                </Box>
              </Group>
              <Box className="margin-26 border-Underline"></Box>
            </>
          );
        })}
      </ScrollArea>
    </Box>
  );
}
