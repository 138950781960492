import { Select } from "@mantine/core";
import { useEffect, useState } from "react";
import { SelectRole } from "../../interface/Icommon";
import { regUseManager, regUserAll, requestApprovalUserList } from "../../api";
import { UserAllList } from "../../interface/Iuser";

interface ISelectUserAllInfo {
  callBack: (row: any) => void;
  rands?: number;
  currentUserid?: string;
  btn_size?: string;
  [key: string]: any
}


export default function SelectUserAllInfo({ callBack, btn_size = 'sm', type, currentUserid, rands }: ISelectUserAllInfo) {
  // Selected reviewer ID
  const [userValue, setUserValue] = useState<string | null>(null);
  const [rand, setRand] = useState(0);
  const [userList, setUserData] = useState<SelectRole[]>([])
  /**
   * Get user information
   */
  const ajaxCurrentList = async () => {
    let response;
    try {
      if (type === 'leave') {
        response = await regUseManager({}, "GET");
      } else {
        response = await requestApprovalUserList({}, "GET");
      }
  
      const result = response.data;
      const userListData = result.data as UserAllList[];
      const transferData = userListData.map((item) => {
        return {
          value: item.id_user.toString(),
          id_user: item.id_user.toString(),
          label: item.username,
          avatar: item.avatar,
          email: item.email,
        }
      })
      setUserData(transferData)
    } catch (error) {
      console.log(error)
    }
  };

  // Page initialization function
  useEffect(() => {
    ajaxCurrentList()
  }, [])

  // Page initialization function
  useEffect(() => {
    setRand(rand + 1)
  }, [currentUserid])

  function setUserValueFunction(value: string | null): void {
    setUserValue(value)
    const row = userList.filter((item, index) => { return item.value == value })
    callBack(row)
  }
  return (
    <>
      <Select searchable={true} defaultValue={currentUserid?.toString()} size={btn_size} key={rand} onChange={setUserValueFunction} data={userList} />
    </>
  )

}