import { ActionIcon, Avatar, Box, Group, LoadingOverlay, ScrollArea, Table, Tooltip, Text } from "@mantine/core";

import { Key, useEffect, useState } from "react";
import { Attribute,  PermissionUserAllList } from "../../../../interface/Iuser";
import cx from 'clsx';
import classes from './custom_attrbutes/TableScrollArea.module.css';
import { IconDots, IconEdit, IconTrash } from "@tabler/icons-react";
import WordTranslationComponent from "../../../../utils/language_pack/words";
import { modals } from "@mantine/modals";
import { HintInfo } from "../../../../utils/function";
import GlobalCoreSetting from "../../../../utils/globalCortSetting";
import { IconDotsVertical } from "@tabler/icons-react";
import { reGetEmployeeCustomAttributetTypesList } from "../../../../api";
import DeleteIcon from "../../../../ttcomponents/DeleteIcon/DeleteIcon";

interface ComponentInterface{
 is_refresh:number,
 permissionTaskUserList:PermissionUserAllList[],
 callBackDelfunction:(value:any)=>void,
 callBackfunction:(value:PermissionUserAllList)=>void,
 returnAttributeList:(value:PermissionUserAllList[])=>void,
}
export default function TaskUserListComponent({ is_refresh,permissionTaskUserList,callBackDelfunction,callBackfunction,returnAttributeList}:ComponentInterface){
    
  const [loading, setLoading] = useState(false);
    // Get translation public configuration information
  const word_translation = WordTranslationComponent();
  // Get the global configuration
  const globalCoreSetting = GlobalCoreSetting();
  const [attributeList, setAttributeList] = useState<PermissionUserAllList[]>([]);
  const ajaxCurrentList = async () => {
    setLoading(true); // Set the loading status to TRUE, indicating that the data is loading
    // Send GET requests to get Attribute Type data
    const responsePermissionUserAllListList = await reGetEmployeeCustomAttributetTypesList({},"GET");
    setLoading(false); // After the data is loaded, set the loading status to false
    if (responsePermissionUserAllListList.data.code === 200) {
        setAttributeList(responsePermissionUserAllListList.data.data); 
    }
    returnAttributeList(responsePermissionUserAllListList.data.data)
  };
  // Use the USEEFFECT hook. When Search changes, call the AjaxCurrenTList function
  useEffect(() => {
    ajaxCurrentList();
  }, [is_refresh]);

  
    const handleEdit =  (row:PermissionUserAllList) => {
        callBackfunction(row)
    };

   
    // Delete user role
    const handleDel =  (index: any) => {
        callBackDelfunction(index)
    };
    
    
    return (
        <Box className="px-15 pt-xs pt-4">
        <LoadingOverlay visible={loading} overlayBlur={2} />
        <ScrollArea h={150} >
        <Table>
            <thead  className="scrolleder ">
                <tr>
                <th  id="table-th-title-new">{word_translation.user}</th>
                <th  id="table-th-title-new">{word_translation.role_name}</th>
                <th  id="table-th-title-new">{word_translation.email}</th>
                <th  id="table-th-title-new">{word_translation.task}</th>
                <th  id="table-th-title-new">{word_translation.option}</th>
                </tr>
            </thead>
            <tbody>
            {permissionTaskUserList.length > 0 && permissionTaskUserList.map((item: PermissionUserAllList, index: Key | null | undefined) => {
                return (
                    <tr className="text-center table_tr_xs"  key={index}>
                        <td className="img-center">
                            <Tooltip  label={item.username}>
                                <Avatar
                                    src={item.avatar}
                                    radius={30}
                                    size={25 }
                                    />
                            </Tooltip>
                        </td>
                        <td>{item.permisson_role_title}</td>
                        <td>{item.email}</td>
                        <th  id="table-th-title-new"> - </th>
                        <td>
                        <Group spacing={0} position="center">
                            <ActionIcon onClick={() => handleEdit(item)}>
                                <Tooltip label={word_translation.edit_tips}>
                                    <IconDotsVertical size="1.2rem" stroke={1.5} />
                                </Tooltip>
                            </ActionIcon> 
                            <DeleteIcon callBack={()=>handleDel(index)}  />
                        </Group>
                        </td>
                    </tr>
                );
            })}
            </tbody>
            </Table>
            </ScrollArea>
        </Box>
    )
}