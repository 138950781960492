import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import localstorageUnits from "./utils/localstorageUnits";
import memoryUtils from "./utils/memoryUtils";
import { Provider } from "react-redux";
import { store } from './state_management/store'
const user = localstorageUnits.getUser();
memoryUtils.user = user;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
// 开发模式下会导致useEffect运行两次 ：react18将useEffect的默认运行改为了两次。
// In the development mode, the usereFFECT runs twice: React18 changes the default running of Useeffect to twice.
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );
root.render(
  <Provider store={store} >
     <App />
  </Provider>
);
reportWebVitals();
