import { Box, Code, Grid, Group, NumberInput, Paper, Select, TextInput, Textarea } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import CancelButton from "../../../../ttcomponents/Button/CancelBtn";
import SaveBottom from "../../../../ttcomponents/Button/SaveBtn";
import { FormEvent, useEffect, useRef, useState } from "react";
import { isNotEmpty, useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { IconCalendar, IconX } from "@tabler/icons-react";
import { HintInfo, TotalPriceFunction, formatDateUpgraded } from "../../../../utils/function";
import globalCortSetting from "../../../../utils/globalCortSetting";
import ProductSelect from "../../../../ttcomponents/Product/ProductSelect";
import { regCustomerList, regGetRecyclingCompany, regProductRecycling, regRecyclingCompany, regRentProduct } from "../../../../api";
import { ResponseCustomerData } from "../../../../interface/Icoustomer";
import { SelectRole } from "../../../../interface/Icommon";
interface deliverProductProps {
    additional_information: any;
    closeFucntion: (type: number) => void;
  }
export default function RecyclingFrom({additional_information,closeFucntion}:deliverProductProps){
  const [companyList, setCompanyList] = useState<SelectRole[]>([]);
  const formRef = useRef<HTMLFormElement>(null);

  const form = useForm({
    initialValues: {
        product_id: additional_information?.product_id,
        recycling_company_id:additional_information?.recycling_company_id,
        quantity:additional_information?.quantity,
        recycling_price:additional_information?.recycling_price
    },
    validate: {
      recycling_company_id: isNotEmpty('recycling_company_id  is required'),
        quantity: isNotEmpty('quantity  is required'),
    },
  });

  
  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
     // Stop the formal submission of the form default
    event.preventDefault();
    // Verification form
    const valid = form.validate();
    if (valid.hasErrors) {
      notifications.show({
        color: "yellow.7",
        icon: <IconX />,
        message: Object.values(valid.errors)[0],
      });
      return;
    }
    let submit_type = 'POST';
    if (form.values.recycling_company_id) {
      submit_type = 'PUT'
    }
    
    const response = await regProductRecycling(form.values, submit_type);
    HintInfo(response.data)
    console.log(response.data)
    if(response.data.code == 200){
       closeFucntion(2)
    }
  
  };


  const changeDelivelyTime = (num: any) => {
    const totalPrice =  TotalPriceFunction(num,additional_information?.unit_price)
    form.setFieldValue('total_price', totalPrice as string);
    form.setFieldValue('rent_quantity', num);

  };

  // Get Timetrack Record
  const ajaxCurrentList = async () => {
    if(companyList.length <=  0){
        const responseRecyclingCompany = await regGetRecyclingCompany({});
        if (responseRecyclingCompany.data.code === 200) {
        const customerData = responseRecyclingCompany.data.data.data.map((item: { recycling_company_id: string; company_name: any; }) => ({
            value: item.recycling_company_id as string,
            label: item.company_name,
        }));
        setCompanyList(customerData);
        }
    }
   
  };
console.log(additional_information)
  useEffect(() => {
    // if(rental_status == true){
        ajaxCurrentList();
    // }
  }, []);

  function ReceiveSupplierBack(row: any): void {
   
  }

    return (
        <Paper p={10}>
          <form onSubmit={handleFormSubmit} ref={formRef}>
            <Grid>
              <Grid.Col span={12}>
                <ProductSelect  createNew={false} disabled={additional_information?.product_id ?true : false} labelStr="Product Name" currentId={additional_information?.product_id as string} callBack={ReceiveSupplierBack} />
                {additional_information?.customer_id}
                <Select
                  label="Select Garbage Recovery Name"
                  onChange={(value) =>
                    form.setFieldValue("recycling_company_id", value as string)
                  }
                  searchable
                  key={additional_information.customer_id}
                  defaultValue={(additional_information.customer_id)}
           
                  data={companyList} />
                <NumberInput
                  {...form.getInputProps("quantity")}
                  // value={categoryName}
                  label=" Quantity"
                />
    
                 <TextInput
                  {...form.getInputProps("recycling_price")}
                label="Recycling Price"
                />
                
              </Grid.Col>
            </Grid>
    
            <Group position="right">
              {/* <CancelButton callBack={() => closeFucntion(1)} /> */}
              <SaveBottom formRef={formRef} />
            </Group>
    
            {/** can see form values */}
            <Code block mt={5}>
              {JSON.stringify(form.values, null, 2)}
            </Code>
          </form>
        </Paper>
      )
}