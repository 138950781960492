import {
  Box,
  Button,
  Card,
  Group,
  Grid,
  LoadingOverlay,
  MultiSelect,
  ActionIcon,
  Text,
  TextInput,
  Divider,
  Title,
  Select,
  Table,
  Tooltip,
  Code,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { hasLength, isNotEmpty, useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import {
  IconCalendar,
  IconCheck,
  IconMoneybag,
  IconCircleCheckFilled,
  IconTrash,
  IconX,
  IconArrowBadgeUpFilled,
  IconArrowBadgeDownFilled,
  IconTextPlus,
  IconRefresh,
  IconCircleKey,
  IconUserPlus,
} from "@tabler/icons-react";
import { FormEvent, forwardRef, useEffect, useRef, useState } from "react";
import {
  regGetProduct,
  regGetProductId,
  regLockedProduct,
  regOrder,
  regRefreshOrderProduct,
} from "../../api";
import memoryUtils from "../../utils/memoryUtils";
import {
  HintInfo,
  JudgmentTimeThreshold,
  TimeHHSS,
} from "../../utils/function";
import SaveBottom from "../../ttcomponents/Button/SaveBtn";
import { ProductOrder, Purchase } from "../../public/svg/ProductOrder";
import { Product } from "../../interface/IProduct";
import { IOrderProduct } from "../../interface/Iorder";
import localstorageUnits from "../../utils/localstorageUnits";
import { FormattedMessage, useIntl } from "react-intl";

// Define the type of props
interface IOrderFromProps {
  callBack: (value?: any) => void;
  [key: string]: any;
}

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  image: string;
  label: string;
  value: string;
  unit_price: string;
}

interface IProduct {
  product_name: string;
  product_id: string;
  order_product_id?: string;
  order_id?: string;
  order_quantity: number | string;
  total_price?: string;
  locked_quantitly?: number;
  delivery_schedule?: string;
  delivery_status?: number | string;
}

// Custom validation function for time_consumtion field
const validateTimeConsumtion = (value: string) => {
  const pattern = /^(\d+):([0-5][0-9])$/;
  if (!pattern.test(value)) {
    return "Time consumption must be in the format HH:MM";
  }

  const [hours, minutes] = value.split(":");
  if (Number(hours) > 10000 || Number(minutes) >= 60) {
    return "Invalid time consumption";
  }

  return undefined;
};

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ image, label, unit_price, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        {image}
        <div>
          <Text size="sm">{label}</Text>
          <Text size="xs" opacity={0.65}>
            {unit_price}
          </Text>
        </div>
      </Group>
    </div>
  )
);

export default function OrderEditForm({
  callBack,
  userlist,
  orderItem,
  customerlist,
  orderDetail,
}: IOrderFromProps) {
  // 钩子函数-切换语言
  const intl = useIntl();

  const taskModeFlag = localstorageUnits.getSystem().task_mode_flag;

  // 定义状态
  const [searchStatusValue, onSearchStatusValue] = useState("");
  // 模式类型
  const ProjectOrderTaskModel = taskModeFlag == 1;
  const ProjectTaskModel = taskModeFlag == 2;
  const OrderModel = taskModeFlag == 3;
  const ProjectOrder = taskModeFlag == 4;
  const OrderNotaskModel = taskModeFlag == 5;

  // 初始化userID
  const [userId, setUseId] = useState(memoryUtils.user.id_user);
  const [userType, setUserType] = useState(memoryUtils.user.user_type);

  const [teamLeaderValue, setTeamLeaderValue] = useState<string[]>([]);
  //加载中状态
  const [loading, setLoading] = useState(false);
  const [rand, setRand] = useState(0);
  const formRef = useRef<HTMLFormElement>(null);
  // Define the selection of customers to search for functions and assign a value to SearchCustomerValue
  const [searchCustomerValue, onSearchCustomerChange] = useState("");
  console.log(orderItem.customer_id);
  const form = useForm({
    initialValues: {
      project_id: orderItem.project_id,
      order_name_id: orderItem.order_name_id,
      order_no: orderItem.order_no,
      start_date: new Date(orderItem.start_date),
      end_date: orderItem.end_date ? new Date(orderItem.end_date) : "",
      budget: orderItem.budget,
      id: orderItem.id_order,
      status_id: orderItem.status_id,
      order_name: orderItem.order_name,
      time_estimate: orderItem.time_estimate,
      time_consumtion: orderItem.time_consumtion,
      order_team_leaders: teamLeaderValue,
      order_leaders: teamLeaderValue,
      product: orderDetail.products as IProduct[],
    },

    validate: {
      order_no: hasLength(
        { min: 2, max: 250 },
        "order_no must be 2-10 characters long"
      ),
      budget: (value) => {
        if (!value) {
          return "Enter your current budget";
        }

        return undefined; // No validation error
      },
    },
  });
  const setTeamLeaderValueHandle = (value: React.SetStateAction<string[]>) => {
    setTeamLeaderValue(value as []);
    setRand(rand + 1);
    form.setFieldValue("order_team_leaders", value as []);
  };

  useEffect(() => {
    setTeamLeaderValueHandle(orderDetail.order_leader_ids.split(","));
  }, [orderDetail.order_leader_ids]);

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Stop the formal submission of the form default
    // Verification form
    // The start time of the setting cannot exceed the initial time of the system settings
    if (JudgmentTimeThreshold(form.values.start_date) === false) {
      return false;
    }
    const valid = await form.validate();
    if (!valid.hasErrors) {
      let productInfo = form.values.product.map((item) => {
        return {
          product_id: item.product_id,
          product_name: item.product_name,
          order_quantity: item.order_quantity,
        };
      });
      let originalData = {
        project_id: form.values.project_id,
        order_name_id: form.values.order_name_id,
        order_no: form.values.order_no,
        budget: form.values.budget,
        order_name: form.values.order_name,
        time_estimate: form.values.time_estimate,
        status_id: form.values.status_id,
        id: form.values.id,
        order_team_leaders: teamLeaderValue,
        order_leader_ids: teamLeaderValue,
        // order_leader_ids:form.values.order_leader_ids,
        start_date: form.values.start_date?.toISOString()?.split("T")[0],
        end_date: form.values.end_date
          ? new Date(form.values.end_date).toISOString().split("T")[0]
          : "",
        time_consumtion: form.values.time_consumtion,
        // If I only want to pass some parameters, how should I modify it?
        product: productInfo,
      };

      if (OrderModel || OrderNotaskModel) {
        delete originalData.project_id;
      }
      if (OrderNotaskModel == true || ProjectOrder == true) {
      } else {
        delete originalData.status_id;
      }
      setLoading(true);
      const response = await regOrder(originalData, "PUT");
      setLoading(false);
      const result = response.data;
      if (result.code === 200) {
        callBack();
        notifications.show({
          color: "green",
          icon: <IconCheck />,
          message: result.msg,
        });
      } else {
        setLoading(false);
        notifications.show({
          color: "red",
          icon: <IconX />,
          message: result.msg,
        });
      }
    }
  };

  // 矫正时间格式
  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = TimeHHSS(event);
    form.setFieldValue("time_estimate", value);
  };

  const addProduct = () => {
    // Check if product_id is empty
    if (!productID) {
      notifications.show({
        color: "red",
        icon: <IconX />,
        message: "Please select a product",
      });
      return;
    }

    // Check if order_quantity is empty
    if (!order_quantity) {
      notifications.show({
        color: "red",
        icon: <IconX />,
        message: "Please enter the quantity",
      });
      return;
    }

    // Check if the product name is empty
    if (!productName) {
      notifications.show({
        color: "red",
        icon: <IconX />,
        message: "Please enter the product name",
      });
      return;
    }

    // Check if the product already exists
    const existingContact = form.values.product.find(
      (c) => c.product_name === productName
    );

    if (existingContact) {
      // Show error message
      notifications.show({
        color: "yellow.7",
        icon: <IconX />,
        message: "A product with the same name already exists",
      });
      return;
    }

    // Create a product object
    let productItem = {
      product_id: productID,
      product_name: productName,
      order_quantity: order_quantity,
    };
    // Add the product object to the form
    form.setFieldValue("product", [productItem, ...form.values.product]);
  };
  // 回调刷新产品信息
  const RefreshRecordAction = async (row: IProduct) => {
    const responseOrderProduct = await regRefreshOrderProduct(
      { order_product_id: row.order_product_id },
      "PUT"
    );
    HintInfo(responseOrderProduct.data);
    if (responseOrderProduct.data.code == 200) {
      callBack({ type: "refresh", id: row.order_id });
    }
  };

  // 解锁 加锁 ，暂时不需要
  const LockedProduct = async (row: IProduct, locked: number) => {
    const data = {
      order_product_id: row.order_product_id,
      order_quantity: row.order_quantity,
      product_id: row.product_id,
      locked: locked,
    };
    const responseLOckedData = await regLockedProduct(data, "PUT");
    HintInfo(responseLOckedData.data);
    if (responseLOckedData.data.code == 200) {
      callBack({ type: "refresh", id: row.order_id });
    }
  };

  const rows = form.values.product.map((element, index) => (
    <tr key={index}>
      <td>{element.product_name}</td>
      <td>{element.order_quantity}</td>
      <td>{element.delivery_schedule}</td>
      <td>{element.total_price}</td>
      <td>
        <Group key={element.locked_quantitly}>
          <ActionIcon
            onClick={
              element.delivery_status != 3
                ? () => RefreshRecordAction(element)
                : undefined
            }
          >
            <Tooltip
              label={
                element.delivery_status != 3
                  ? "You can update the price of this order, and the delivery date"
                  : "This order has been completed"
              }
            >
              <IconRefresh
                size="1.2rem"
                stroke={1.5}
                color={element.delivery_status == 3 ? "#cccccc" : "blue"}
              />
            </Tooltip>
          </ActionIcon>

          {/* 
          {element.locked_quantitly && element.locked_quantitly != 0  ? <ActionIcon
            onClick={() => {
              LockedProduct(element,0);
            }}
          >
            <IconCircleKey size="1rem" color="green" stroke={1.5} />{" "}
          </ActionIcon> : <ActionIcon
          >
            <IconCircleKey size="1rem" color="#ccc" stroke={1.5} />{" "}
          </ActionIcon>} */}

          <ActionIcon
            onClick={() => {
              delHandle(index);
            }}
          >
            <IconTrash size="1rem" color="red" stroke={1.5} />{" "}
          </ActionIcon>
        </Group>
      </td>
    </tr>
  ));

  const [productID, setProductID] = useState("");
  const [productName, setProductName] = useState("");
  const [order_quantity, setQuantity] = useState("");

  const delHandle = (index: number) => {
    const newProduct = [...form.values.product];
    newProduct.splice(index, 1);
    form.setFieldValue("product", newProduct);
  };

  const [addproductStatus, setAddproductStatus] = useState(
    form.values.product.length > 0 ? true : false
  );
  const [productTip, setProductTip] = useState(
    form.values.product.length > 0
      ? intl.formatMessage({
          id: "Cancel add products",
          defaultMessage: "Cancel add products",
        })
      : intl.formatMessage({
          id: "click to add products",
          defaultMessage: "click to add products",
        })
  );
  const [data, setData] = useState<ItemProps[] | undefined>([]);

  useEffect(() => {
    if (data?.length == 0 && addproductStatus) {
      getProductList();
    }
  }, []);

  const setProductNameFunction = async (string: string) => {
    const existResult =
      data && data.filter((item, index) => item.value == string.toString());
    if (existResult?.length == 0) {
      const responseProduct = await regGetProductId({ product_name: string });
      if (responseProduct.data.code == 200) {
        console.log(typeof responseProduct.data.data.product_id);
        setProductID(responseProduct.data.data.product_id);
      }
      setProductName(string);
    } else {
      // 使用 filter 方法过滤出 product_id 为 targetProductId 的信息
      const filteredData = data?.filter((item) => item.value === string);

      setProductID(string);
      if (filteredData && filteredData.length > 0) {
        console.log(filteredData[0]);
        setProductName(filteredData[0].label);
      }
    }
  };

  // Get product list
  const getProductList = async () => {
    setLoading(true);
    const responseProduct = await regGetProduct({});
    setLoading(false);
    if (responseProduct.data.code == 200) {
      // Assembly product
      const products = responseProduct.data.data.data.map((item: Product) => ({
        image: <ProductOrder />,
        label: item.product_name,
        value: item.product_id,
        unit_price:
          item.unit_price != null
            ? intl.formatMessage({
                id: "Unit Price",
                defaultMessage: "Unit Price",
              }) +
              ": " +
              item.unit_price
            : "This product has not been purchased in the warehouse",
      }));
      setData(products);
    }
  };

  // Add product
  const openAddProduct = async () => {
    if (addproductStatus) {
      setAddproductStatus(false);
      setProductTip(
        intl.formatMessage({
          id: "click to add products",
          defaultMessage: "click to add products",
        })
      );
      form.setFieldValue("product", []);
    } else {
      setProductTip(
        intl.formatMessage({
          id: "Cancel add products",
          defaultMessage: "Cancel add products",
        })
      );
      setAddproductStatus(true);

      if (data?.length == 0) {
        await getProductList();
      }
    }
  };

  return (
    <form onSubmit={handleFormSubmit} ref={formRef}>
      <LoadingOverlay visible={loading} overlayBlur={2} />
      <Box ml={20} mt={10}>
        <Card>
          {orderItem.project_leader_ids &&
          orderItem.project_leader_ids.split(",").includes(userId) ? (
            <>
              <TextInput
                disabled
                withAsterisk
                mt={20}
                labelProps={{ className: "input_title" }}
                description=""
                {...form.getInputProps("order_name")}
                label={intl.formatMessage({
                  id: "order_name",
                  defaultMessage: "Order Name",
                })}
                placeholder={intl.formatMessage({
                  id: "Input",
                  defaultMessage: "Input",
                })}
              />

              <MultiSelect
                mt={20}
                labelProps={{ className: "input_title" }}
                label={intl.formatMessage({
                  id: "Order Team Leaders",
                  defaultMessage: "Order Team Leaders",
                })}
                disabled={userType == "1" ? false : true}
                placeholder={intl.formatMessage({
                  id: "Order Team Leaders",
                  defaultMessage: "Order Team Leaders",
                })}
                onChange={setTeamLeaderValueHandle}
                data={userlist}
                defaultValue={teamLeaderValue}
              />
              <TextInput
                mt={20}
                labelProps={{ className: "input_title" }}
                description=""
                {...form.getInputProps("time_estimate")}
                onBlur={handleBlur}
                label={intl.formatMessage({
                  id: "Estimated time",
                  defaultMessage: "Estimated time",
                })}
                placeholder={intl.formatMessage({
                  id: "Input",
                  defaultMessage: "Input",
                })}
              />

              <Grid>
                <Grid.Col span={6}>
                  <DateInput
                    valueFormat="DD/MM/YYYY"
                    {...form.getInputProps("start_date")}
                    icon={<IconCalendar size="1.1rem" stroke={1.5} />}
                    label={intl.formatMessage({
                      id: "Start Time",
                      defaultMessage: "Start Time",
                    })}
                    placeholder={intl.formatMessage({
                      id: "Start Time",
                      defaultMessage: "Start Time",
                    })}
                    maw={400}
                    mx="auto"
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <DateInput
                    valueFormat="DD/MM/YYYY"
                    {...form.getInputProps("end_date")}
                    icon={<IconCalendar size="1.1rem" stroke={1.5} />}
                    label={intl.formatMessage({
                      id: "End Time",
                      defaultMessage: "End Time",
                    })}
                    placeholder={intl.formatMessage({
                      id: "End Time",
                      defaultMessage: "End Time",
                    })}
                    maw={400}
                    mx="auto"
                  />
                </Grid.Col>
                <Grid.Col span={4}></Grid.Col>
              </Grid>
            </>
          ) : (
            <Grid>
              <Grid.Col span={7}>
                {userType == "1" ? (
                  <>
                    <TextInput
                      disabled
                      withAsterisk
                      labelProps={{ className: "input_title" }}
                      description=""
                      {...form.getInputProps("order_name")}
                      label={intl.formatMessage({
                        id: "order_name",
                        defaultMessage: "Order Name",
                      })}
                      placeholder={intl.formatMessage({
                        id: "Input",
                        defaultMessage: "Input",
                      })}
                    />
                    <MultiSelect
                      mt={20}
                      labelProps={{ className: "input_title" }}
                      label={
                        <Group>
                          <Text>
                            {intl.formatMessage({
                              id: "Order Team Leaders",
                              defaultMessage: "Order Team Leaders",
                            })}
                            <span
                              className={
                                "mantine-103svbs mantine-InputWrapper-required mantine-MultiSelect-required"
                              }
                              aria-hidden="true"
                            >
                              {" "}
                              *{" "}
                            </span>
                          </Text>
                          <ActionIcon size={30}>
                            <IconCircleCheckFilled
                              color="blue"
                              width={20}
                              height={20}
                            />
                          </ActionIcon>
                        </Group>
                      }
                      placeholder={intl.formatMessage({
                        id: "Select Leader",
                        defaultMessage: "Select Leader",
                      })}
                      data={userlist}
                      key={rand}
                      defaultValue={form.values.order_team_leaders}
                      onChange={setTeamLeaderValueHandle}
                    />
                  </>
                ) : (
                  ""
                )}
                <Grid mt={20}>
                  <Grid.Col span={6}>
                    <DateInput
                      valueFormat="DD/MM/YYYY"
                      size="xs"
                      {...form.getInputProps("start_date")}
                      icon={<IconCalendar size="1.1rem" stroke={1.5} />}
                      label={intl.formatMessage({
                        id: "Start Time",
                        defaultMessage: "Start Time",
                      })}
                      placeholder={intl.formatMessage({
                        id: "Start Time",
                        defaultMessage: "Start Time",
                      })}
                      maw={400}
                      mx="auto"
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <DateInput
                      valueFormat="DD/MM/YYYY"
                      {...form.getInputProps("end_date")}
                      size="xs"
                      icon={<IconCalendar size="1.1rem" stroke={1.5} />}
                      label={intl.formatMessage({
                        id: "End Time",
                        defaultMessage: "End Time",
                      })}
                      placeholder={intl.formatMessage({
                        id: "End Time",
                        defaultMessage: "End Time",
                      })}
                      maw={400}
                      mx="auto"
                    />
                  </Grid.Col>
                  <Grid.Col span={4}></Grid.Col>
                </Grid>
              </Grid.Col>
              <Grid.Col span={4} ml={24}>
                {OrderModel && (
                  <Select
                    placeholder={intl.formatMessage({
                      id: "select_customer",
                      defaultMessage: "Select Customer",
                    })}
                    searchValue={searchCustomerValue}
                    labelProps={{ className: "input_title" }}
                    label={intl.formatMessage({
                      // formatting a message
                      id: "Customer",
                      defaultMessage: "Customer",
                    })}
                    onSearchChange={onSearchCustomerChange}
                    defaultValue={orderItem.customer_id}
                    searchable
                    nothingFound={intl.formatMessage({
                      id: "No options",
                      defaultMessage: "No options",
                    })}
                    onChange={(value) =>
                      form.setFieldValue("customer_id", value as string)
                    }
                    data={customerlist}
                  />
                )}
                <TextInput
                  labelProps={{ className: "input_title" }}
                  description=""
                  {...form.getInputProps("budget")}
                  label={
                    <Text>
                      {intl.formatMessage({
                        id: "Budget",
                        defaultMessage: "Budget",
                      })}
                    </Text>
                  }
                  placeholder={intl.formatMessage({
                    id: "Input",
                    defaultMessage: "Input",
                  })}
                />
                <TextInput
                  mt={20}
                  labelProps={{ className: "input_title" }}
                  description=""
                  {...form.getInputProps("time_estimate")}
                  onBlur={handleBlur}
                  label={intl.formatMessage({
                    id: "Estimated time",
                    defaultMessage: "Estimated time",
                  })}
                  placeholder={intl.formatMessage({
                    id: "Input",
                    defaultMessage: "Input",
                  })}
                />

                <Select
                  mt={20}
                  placeholder={intl.formatMessage({
                    id: "select_items",
                    defaultMessage: "Select Items",
                  })}
                  searchValue={searchStatusValue}
                  labelProps={{ className: "input_title" }}
                  label={
                    <FormattedMessage id="Status" defaultMessage="Status" />
                  }
                  onSearchChange={onSearchStatusValue}
                  defaultValue={orderItem.status_id.toString()}
                  key={orderItem.status_id}
                  dropdownPosition="bottom"
                  required
                  disabled={OrderModel ? false : ProjectOrder ? false : true}
                  searchable
                  maxDropdownHeight={150}
                  nothingFound={intl.formatMessage({
                    id: "No options",
                    defaultMessage: "No options",
                  })}
                  onChange={(value) =>
                    form.setFieldValue("status_id", value?.toString() as string)
                  }
                  data={[
                    { value: "1", label: "Ready", disabled: true },
                    { value: "2", label: "Running", disabled: false },
                    { value: "3", label: "Finished", disabled: false },
                    { value: "4", label: "Cancelled", disabled: false },
                    { value: "5", label: "Paused", disabled: false },
                    { value: "6", label: "Archived", disabled: true },
                  ]}
                />
              </Grid.Col>
              1
            </Grid>
          )}

          <Divider
            my="xs"
            variant="dashed"
            labelPosition="center"
            label={
              <>
                <ActionIcon onClick={() => openAddProduct()}>
                  {/* <Tooltip label={productTip}> */}
                  {addproductStatus ? (
                    <IconArrowBadgeUpFilled size={12} stroke={1.5} />
                  ) : (
                    <IconArrowBadgeDownFilled size={12} stroke={1.5} />
                  )}
                  {/* </Tooltip> */}
                </ActionIcon>
                <Box ml={5}>{productTip}</Box>
              </>
            }
          />
          {addproductStatus ? (
            <>
              {" "}
              <Title my={5} order={6}>
                {" "}
                {intl.formatMessage({
                  id: "Add Products",
                  defaultMessage: "Add Products",
                })}{" "}
              </Title>
              <Box className="border border-1" p={10}>
                <Grid gutter={4} grow>
                  <Grid.Col span={12}>
                    <Grid gutter={6}>
                      <Grid.Col span={6}>
                        <Select
                          placeholder={intl.formatMessage({
                            id: "Product Name",
                            defaultMessage: "Product Name",
                          })}
                          itemComponent={SelectItem}
                          data={data as []}
                          searchable
                          creatable
                          maxDropdownHeight={400}
                          onChange={(event) =>
                            setProductNameFunction(event as string)
                          }
                          nothingFound="Nobody here"
                          getCreateLabel={(query) => `+ Create ${query}`}
                          onCreate={(query) => {
                            const item = {
                              image: <Purchase />,
                              label: query,
                              value: query,
                              unit_price: "warehouse needs purchasing",
                            };
                            setData((current) => [...(current as any), item]);
                            return item;
                          }}
                          filter={(value, item) =>
                            (item.label &&
                              item.label
                                .toLowerCase()
                                .includes(value.toLowerCase().trim())) ||
                            item.unit_price
                              .toLowerCase()
                              .includes(value.toLowerCase().trim())
                          }
                        />
                      </Grid.Col>
                      <Grid.Col span={3}>
                        <TextInput
                          placeholder={intl.formatMessage({
                            id: "Quantity",
                            defaultMessage: "Quantity",
                          })}
                          onChange={(event) =>
                            setQuantity(event.currentTarget.value)
                          }
                        />
                      </Grid.Col>
                      <Grid.Col span={2}>
                        <Button
                          onClick={addProduct}
                          leftIcon={<IconTextPlus />}
                        >
                          {intl.formatMessage({
                            id: "Create",
                            defaultMessage: "Create",
                          })}
                        </Button>
                      </Grid.Col>
                    </Grid>
                  </Grid.Col>
                </Grid>

                {form.values.product.length > 0 ? (
                  <Table>
                    <thead>
                      <tr>
                        <th>
                          {intl.formatMessage({
                            id: "Product Name",
                            defaultMessage: "Product Name",
                          })}
                        </th>
                        <th>
                          {intl.formatMessage({
                            id: "Quantity",
                            defaultMessage: "Quantity",
                          })}
                        </th>
                        <th>
                          {intl.formatMessage({
                            id: "Delivery Schedule",
                            defaultMessage: "Delivery Schedule",
                          })}
                        </th>
                        <th>
                          {intl.formatMessage({
                            id: "Total Price",
                            defaultMessage: "Total Price",
                          })}
                        </th>
                        <th>
                          {intl.formatMessage({
                            id: "Option",
                            defaultMessage: "Option",
                          })}
                        </th>
                      </tr>
                    </thead>
                    <tbody>{rows}</tbody>
                  </Table>
                ) : (
                  <Divider
                    my="xs"
                    c="#ccc"
                    label={intl.formatMessage({
                      id: "Please add products",
                      defaultMessage: "Please add products",
                    })}
                    labelPosition="center"
                  />
                )}
              </Box>
            </>
          ) : null}

          <Group position="right">
            <SaveBottom formRef={formRef} />
          </Group>
        </Card>
      </Box>
      {/* <Code block mt={8}>
        {JSON.stringify(form.values, null, 2)}
      </Code> */}
    </form>
  );
}
