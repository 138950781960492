import { Box, Tabs } from "@mantine/core";
import { IconBusinessplan, IconMessageCircle, IconPackageExport, IconPhoto, IconSettings, IconShoppingCart, IconTags } from "@tabler/icons-react";
import PickingOutBoundProductFrom from "./picking_out_bound_product/picking_out_bound_product_from";
import InternalUseProductFrom from "./internal_use_product/internal_use_product_from";
import RentProductFrom from "./rent_product/rent_form";

interface deliverProductProps {
  additional_information: any;
  type?: boolean;
  closeFucntion: (type: number) => void;
}
export default function DeliveryIndex({additional_information,closeFucntion,type}:deliverProductProps){
    return(
        <Tabs mt={20} variant="pills" defaultValue="Picking">
        <Tabs.List >
          <Tabs.Tab value="Picking" icon={<IconPackageExport size="0.8rem" />}>Picking Out Bound </Tabs.Tab>
          <Tabs.Tab value="Internal" icon={<IconTags size="0.8rem" />}>Internal Use </Tabs.Tab>
          <Tabs.Tab value="Rent" icon={<IconBusinessplan size="0.8rem" />}>Rent </Tabs.Tab>
        </Tabs.List>
  
        <Tabs.Panel value="Sale" pt="xs">
        </Tabs.Panel>
  
        <Tabs.Panel value="Rent" pt="xs">
            <RentProductFrom additional_information={additional_information} closeFucntion={closeFucntion} />
        </Tabs.Panel>
        
        <Tabs.Panel value="Picking" pt="xs">
            <PickingOutBoundProductFrom additional_information={additional_information} closeFucntion={closeFucntion} />
        </Tabs.Panel>
        
        <Tabs.Panel value="Internal" pt="xs">
            <InternalUseProductFrom additional_information={additional_information} closeFucntion={closeFucntion} />
        </Tabs.Panel>
      </Tabs>
    )
}