import { Box,Group,  Flex,  Text, Paper, ActionIcon} from '@mantine/core';
import { useEffect, useState } from 'react';
import { regAbnormalApproval, regAbnormalRecords, regGetSurplusVacation, regGetVationAction, regVacationApproval } from '../../../api';
import memoryUtils from '../../../utils/memoryUtils';
import { Ipproval } from '../../../interface/Iapproval';
import { Edit } from './svg/Edit';
import { Delete } from './svg/Delete';
import { HintInfo } from '../../../utils/function';
import { modals } from '@mantine/modals';
import { Approve } from './svg/Approve';
import { Cancel } from './svg/Cancel';
import { Manageragree } from '../../leave_request/svg/Manageragree';
import { title } from 'process';
import { ManagerReject } from './svg/ManagerReject';
import PageTitle from '../../../ttcomponents/PageTitle/PageTitle';
import { FormattedMessage } from 'react-intl';

export default function ApprovalTimeTracker() {
 

  const [ApprovalData, setApprovalData] = useState<Ipproval[]>([]);
    
  // Get the current user information
  const userExists = memoryUtils.user;
  /**
   * Get user information
   */
  const ajaxCurrentList = async () => {
    //Get personal remaining holiday information
    const ApprovalRespone = await regAbnormalRecords({}, "GET");
    setApprovalData(ApprovalRespone.data.data)
  };
  // Page initialization function
  useEffect(() => {
    ajaxCurrentList()
  }, [])
  const ProcessingRequestHandle = async (row: any, type: string, remarks: string) => {
    let title = '';
    let desc = '';
    let color = '';
    if (type === 'approved') {
      title = 'Agree';
      desc = 'Agree to credit time to the system';
      color = 'green';
      // ActionHandler(title,color,desc,type,row)
    }else if (type === 'rejected') {
      title = 'Reject';
      desc = 'Reject to credit time to the system';
      color = 'red';
    }else if (type === 'delete') {
      title = 'Delete';
      desc = 'Delete this record';
      color = 'red';
    }
    modals.openConfirmModal({
      title: title,
      centered: true,
      children: (
        <Text size="sm">
          {desc}
        </Text>
      ),
      labels: { confirm: title, cancel: "Cancel" },
      confirmProps: { color: color },
      onConfirm: async () => {
        if (type === 'delete') {
          const ApprovalResult = await regAbnormalRecords({ id: row.id_abnormal_records }, "DELETE");
          HintInfo(ApprovalResult.data);
          if (ApprovalResult.data.code == 200) {
            ajaxCurrentList();
          }
        } else {
          const AbnormalResult = await regAbnormalApproval({ id: row.id_abnormal_records, status: type, remarks: '' }, "PUT");
          HintInfo(AbnormalResult.data);
          ajaxCurrentList();
        }
      }
    });
  }
  

const ActionHandler = async (title:string,color:string,desc:string,type:string,row:any) => {

};



  
  return (
    <Paper px={20} style={{ position: "relative" }}>
        
        <PageTitle title='Approvel Time Tracker' lang_id='approved_time_tracker' />
        <Group className='margin-26' mt={10} >
        <Box className='columns-Box'> <Text className='columns-title'>   <FormattedMessage id={'user'}  defaultMessage="User" /></Text></Box>
        <Box className='columns-Box'> <Text className='columns-title'><FormattedMessage id={'year'}  defaultMessage="Year" /></Text></Box>
        <Box className='columns-Box'> <Text className='columns-title'><FormattedMessage id={'week'}  defaultMessage="Week" /></Text></Box>
        <Box className='columns-Box'> <Text className='columns-title'><FormattedMessage id={'day'}  defaultMessage="Day" /></Text></Box>
        <Box className='columns-Box'> <Text className='columns-title'><FormattedMessage id={'time'}  defaultMessage="Time" /></Text></Box>
        <Box className='columns-Box'> <Text className='columns-title'><FormattedMessage id={'type'}  defaultMessage="Type" /></Text></Box>
        <Box className='columns-Box'> <Text className='columns-title'><FormattedMessage id={'project_name'}  defaultMessage="Project name" /> </Text></Box>
        <Box className='columns-Box'> <Text className='columns-title'><FormattedMessage id={'order_name'}  defaultMessage="Order name" /></Text></Box>
        <Box className='columns-Box'> <Text className='columns-title'><FormattedMessage id={'task_name'}  defaultMessage="Task Name" /></Text></Box>
        <Box className='columns-Box'> <Text className='columns-title'><FormattedMessage id={'status'}  defaultMessage="Status" /></Text></Box>
        <Box className='columns-Box'> <Text className='columns-title'><FormattedMessage id={'created_at'}  defaultMessage="CreateAt" /> </Text></Box>
        <Box className='columns-Box'> <Text className='columns-title'><FormattedMessage id={'option'}  defaultMessage="Options" /></Text></Box>
      </Group>
    
      {ApprovalData.map((item,index)=>{
         return (
            <Group className='margin-26' >
            <Box className='columns-Box'> <Text className='columns-title'>{item.user_info.username}</Text></Box>
            <Box className='columns-Box'> <Text className='columns-title'>{item.original_year}</Text></Box>
            <Box className='columns-Box'> <Text className='columns-title'>{item.original_week}</Text></Box>
            <Box className='columns-Box'> <Text className='columns-title'>{item.modification_field}</Text></Box>
            <Box className='columns-Box'> <Text className='columns-title'>{item.modification_new_value}</Text></Box>
            <Box className='columns-Box'> <Text className='columns-title'>{item.original_type}</Text></Box>
            <Box className='columns-Box'> <Text className='columns-title'>{item.original_project_name}</Text></Box>
            <Box className='columns-Box'> <Text className='columns-title'>{item.original_order_name ? item.original_order_name : '/'}</Text></Box>
            <Box className='columns-Box'> <Text className='columns-title'>{item.original_identifier ? item.original_identifier : '/'}</Text></Box>
            <Box className='columns-Box'> <Text className='columns-title'>{item.status}</Text></Box>
            <Box className='columns-Box'> <Text className='columns-title'>{item.record_date}</Text></Box>
            <Box className='columns-Box'>
                  <ActionIcon onClick={()=>ProcessingRequestHandle(item,'approved','')}>
                     <Manageragree  />
                   </ActionIcon>
                   <ActionIcon onClick={()=>ProcessingRequestHandle(item,'rejected','')}>
                     <ManagerReject  />
                   </ActionIcon>
                   <ActionIcon onClick={()=>ProcessingRequestHandle(item,'delete','')}>
                     <Delete  />
                   </ActionIcon>
            </Box>
          </Group>
         )
      })}
   </Paper>
  )
}