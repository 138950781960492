import {
  Box,
  Grid,
  Text,
  TextInput,
  Button,
  Badge,
  ActionIcon,
  Group,
  Select,
  Flex,
  Dialog,
} from "@mantine/core";
import {
  IconArrowsShuffle,
  IconCheck,
  IconCirclePlus,
  IconPlus,
  IconX,
} from "@tabler/icons-react";
import { FormEvent, useEffect, useRef, useState } from "react";
import globalCortSetting from "../../../utils/globalCortSetting";
import React from "react";
import {
  regCreateFilter,
  regGeReport,
  regGetFilter,
  regReporFormal,
} from "../../../api";
import { IFilterStatistic } from "../../../interface/IfilterStatistic";
import { SelectRole } from "../../../interface/Icommon";
import { ClientWarningHint } from "../../../utils/function";
import { useDisclosure } from "@mantine/hooks";
import SaveBottom from "../../../ttcomponents/Button/SaveBtn";
import { notifications } from "@mantine/notifications";
import localstorageUnits from "../../../utils/localstorageUnits";
import { useIntl } from "react-intl";
import GlobalCoreSetting from "../../../utils/globalCortSetting";

interface IFormalProps {
  callBack: () => void;
  badgeView: (value: string, type: string) => void;
  badgesFather: string;
  name: string;
  FormalId: string;
}

interface IFormal {
  dataLable: string;
  dataTypeValue: string;
  dataValue: string;
  datacolor: string;
}
export default function Formal({
  callBack,
  badgesFather,
  badgeView,
  FormalId,
  name,
}: IFormalProps) {
  // Use the useIntl hook to access the intl object
  const intl = useIntl();

   // Get the default unchanged configuration
   const globalCoreSetting = GlobalCoreSetting();
  // FORM form submission
  const formRef = useRef<HTMLFormElement>(null);

  // Task Mode Flag
  const taskModeFlag = localstorageUnits.getSystem().task_mode_flag;

  //Define the state of the display status logic calculation symbol state
  const [logicState, SetLogicState] = useState(false);
  //Define the name constant of the calculator
  const [calculatorValue, setCalculatorValue] = useState("");
  //Define the name constant of the calculator
  const [nameFormal, SetnameFormal] = useState("");

  const [FormalIds, SetFormalId] = useState("");

  const [indexYsf, SetindexYsf] = useState(0);
  // Define formal collection
  const [formalValue, setFormalValue] = useState<any[]>([]);
  //Select
  const [userItemList, setuserItemList] = useState<SelectRole[]>([]);
  //Select
  const [filterItemList, setFilterItemList] = useState<SelectRole[]>([]);
  const [filterOrderItemList, setFilterOrderItemList] = useState<SelectRole[]>(
    []
  );
  const [filterTaskItemList, setFilterTaskItemList] = useState<SelectRole[]>(
    []
  );

  const [AllFilterStatistic, SetAllFilterStatistic] = useState<SelectRole[]>(
    []
  );

  const [opened, { toggle, close }] = useDisclosure(false);
  // Use the status array to store the BADGE component data to be rendered
  const [badges, setBadges] = useState<IFormal[]>([]);
  /**
   * Delete element
   * @param index
   */
  const handleBadgeDel = (index: number) => {
    SetindexYsf(0);
    if (index >= 1 && index < badges.length) {
      const newBadges = badges
        .slice(0, index - 1)
        .concat(badges.slice(index + 1));
      setBadges(newBadges);
      // The formalValue processing is processed here as needed
      const newFormalValue = formalValue.filter(
        (item, i) => i !== index && i !== index - 1
      );
      setFormalValue(newFormalValue);
    } else {
      setBadges([]);
      setFormalValue([]);
    }
  };

  const handleBadgeClick = (event: { target: any }, type: string) => {
    // Determine if the last one is added to the operator, otherwise it will be prompted
    const lastBadge = badges[badges.length - 1];
    // Get the target element of the click event
    const badgeElement = event.target;
    // Find a parent-level element containing the Data-Value attribute
    const parentDiv = badgeElement.closest(
      ".mantine-Badge-root.cursor-pointer[data-value]"
    );
    if (parentDiv) {
      // Get the value of the data-value attribute
      const dataValue = parentDiv.getAttribute("data-value");
      const dataTypeValue = parentDiv.getAttribute("data-type");
      let dataLable = parentDiv.getAttribute("data-label");
      const datacolor = parentDiv.getAttribute("data-color");
      const BadgesRows = {
        dataLable: dataLable,
        dataTypeValue: dataTypeValue,
        datacolor: datacolor,
        dataValue: dataValue,
      };
      if (badges.length == 0) {
        formalPush(BadgesRows, false);
        return false;
      }
      if (lastBadge.dataTypeValue !== "-1") {
        ClientWarningHint("Please select logic relationship");
        return false;
      }
      formalPush(BadgesRows, false);
    }
  };

  const ajaxInit = async () => {
    const resFilter = await regGetFilter();
    const { data } = resFilter.data as IFilterStatistic;

    const filterAndMap = (type: string, formalValue: number) =>
      data
        .filter((item) => item.filter_type === type)
        .map((item) => ({
          label: item.filter_name,
          type: item.type,
          formal: formalValue,
          value: item.id_filter,
        }));

    SetAllFilterStatistic(
      data.map((item) => ({
        label: item.filter_name,
        type: item.type,
        formal: item.filter_type,
        value: item.id_filter,
      }))
    );

    setuserItemList(filterAndMap("user", 3));
    setFilterOrderItemList(filterAndMap("order", 3));
    setFilterItemList(filterAndMap("project", 2));
    setFilterTaskItemList(filterAndMap("task", 4)); // Assuming 'task' type has formal value 4
  };
  useEffect(() => {
    ajaxInit();
  }, []);

  useEffect(() => {
    SetnameFormal(name);
  }, [name]);

  useEffect(() => {
    SetFormalId(FormalId);
  }, [FormalId]);

  useEffect(() => {
    setBadges([]);
    SetindexYsf(indexYsf + 1);
    if (badgesFather) {
      const dataArray = badgesFather.split(",");
      setFormalValue(dataArray);
      const newBadges = [];
      for (let i = 0, length = dataArray.length; i < length; i++) {
        if (findItemByLabel(dataArray[i])) {
          const dataItme = findItemByLabel(dataArray[i]);
          const badgesItem = {
            dataLable: dataItme?.label || "", // 使用空字符串作为默认值
            dataTypeValue: dataItme?.value || "", // 使用空字符串作为默认值
            dataValue: dataItme?.value || "", // 使用空字符串作为默认值
            datacolor: "green",
          };
          newBadges.push(badgesItem);
        } else if (findOperator(dataArray[i])) {
          const dataItme = findOperator(dataArray[i]);
          const badgesItem = {
            dataLable: dataArray[i],
            dataTypeValue: "-1",
            datacolor: "gray",
            dataValue: dataArray[i],
          };
          newBadges.push(badgesItem);
        } else {
          const badgesItem = {
            dataLable: dataArray[i],
            dataTypeValue: "1",
            datacolor: "blue",
            dataValue: dataArray[i],
          };
          newBadges.push(badgesItem);
        }
      }
      setBadges(newBadges);
    }
  }, [badgesFather]);

  const OperatorValue = () => {
    // Determine what the last element is, if it is an operator, select the badge
    const lastBadge = badges[badges.length - 1];
  };
  const findItemByLabel = (label: string) => {
    return AllFilterStatistic.find((item) => item.label === label);
  };

  const findOperator = (label: string) => {
    return globalCoreSetting.OperatorStr.find((item) => item === label);
  };

  // Add logic of operational symbols
  const handleSelectChange = (selectedValue: any, type: string) => {
    //Update logState in the onChange event of the <select> component to Falselect> component to False
    SetLogicState(false);
    // Processing other logic here, do further operations according to the selectedValue
    if (badges.length == 0) {
      ClientWarningHint("The first parameter cannot be a logical operator");
      return false;
    }
    // The end of the judgment of the array is the logic symbol, but it is not added, and it is given a prompt
    const lastBadge = badges[badges.length - 1];
    if (lastBadge.dataTypeValue === "-1" && indexYsf == 0) {
      ClientWarningHint("Please select logic badge");
      return false;
    }
    // Define the type of the operator to -1
    const BadgesRows = {
      dataLable: selectedValue,
      dataTypeValue: "-1",
      datacolor: "gray",
      dataValue: selectedValue,
    };
    formalPush(BadgesRows, true);
  };
  /**
   * Public add formal method, including deduplication
   * @param value
   * @param type
   * type=false : OperatorValue;type=true : handleBadgeClick
   */
  const formalPush = (value: any, type: boolean) => {
    SetindexYsf(0);
    if (type) {
      if (indexYsf > 0) {
        const newBadges = [...badges];
        newBadges[indexYsf] = value;
        setBadges(newBadges);
        const updatedFormalValue = [...formalValue];
        updatedFormalValue[indexYsf] = value.dataLable;
        setFormalValue([...updatedFormalValue]);
      } else {
        setBadges([...badges, value]);
        // Data submitted to the backend
        formalValue.push(value.dataLable);
        setFormalValue([...formalValue]);
      }
    } else {
      if (!formalValue.includes(value.dataLable)) {
        // Check whether there are the same value in the array
        badges.push(value);
        //Update the status in the React component to reflect the new array
        setBadges([...badges]);
        // Data submitted to the backend
        formalValue.push(value.dataLable);
        setFormalValue([...formalValue]);
      } else {
        ClientWarningHint("The badge has been selected");
      }
    }
  };

  // Select the operator, if it is updated the operating symbol, Number is the index of the operator
  const SetLogicStateHand = (number: any) => {
    if (typeof number == "number") {
      SetindexYsf(number);
    }
    // Toggle the logicState (negate the current value)
    SetLogicState(!logicState);
  };

  const handleFormalFrom = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Stop the formal submission of the form default
    // Verification form FormalIds

    type OriginDataType = {
      id?: string; //Optionable attribute, adjust as needed
      formal_name: string;
      formal_value: any[];
    };

    if (nameFormal && formalValue) {
      let menthod = "POST";
      // Then use the ORIGINDATYPE type in the code
      let originData: OriginDataType = {
        formal_name: nameFormal,
        formal_value: formalValue,
      };

      if (FormalIds) {
        menthod = "PUT";
        originData = {
          id: FormalIds,
          formal_name: nameFormal,
          formal_value: formalValue,
        };
      }
      const response = await regReporFormal(originData, menthod);
      const result = response.data;
      if (result.code === 200) {
        callBack();
        setFormalValue([]);
        SetnameFormal("");
        setBadges([]);
        notifications.show({
          color: "green",
          icon: <IconCheck />,
          message: result.msg,
        });
      } else {
        notifications.show({
          color: "red",
          icon: <IconX />,
          message: result.msg,
        });
      }
    } else {
      ClientWarningHint("Please check parameter information");
    }
  };
  //   添加徽章

  const addCustom = () => {};
  return (
    <Box mih={380} className="border-creates" pl={10} pt={10}>
      <Grid columns={20} gutter={15}>
        <Grid.Col span={9} style={{ position: "relative" }}>
          <form onSubmit={handleFormalFrom} ref={formRef}>
            <Text className="form-title">
              {intl.formatMessage({
                id: "Name",
                defaultMessage: "Name",
              })}
            </Text>
            <Text className="form-title-desc mb-6">
              {intl.formatMessage({
                id: "Input Calculation Name",
                defaultMessage: "Input Calculation Name",
              })}
            </Text>
            <TextInput
              w={200}
              defaultValue={nameFormal}
              size="xs"
              onChange={(event) => SetnameFormal(event.currentTarget.value)}
            />
            <Box>
              <Text className="form-title">
                {intl.formatMessage({
                  id: "Calculation Method",
                  defaultMessage: "Calculation Method",
                })}
              </Text>
              <Text className="form-title-desc mb-6">
                {intl.formatMessage({
                  id: "Custom Calculation",
                  defaultMessage: "Custom Calculation",
                })}
              </Text>
              <Flex
                className="border-creates"
                mih={35}
                align="center"
                py={10}
                px={5}
              >
                <Box id="flex-container">
                  <Group key={indexYsf} spacing="xs">
                    {badges.map((item, index) => {
                      return (
                        <React.Fragment key={index}>
                          {" "}
                          <Badge
                            size="xs"
                            rightSection={
                              item.dataTypeValue == "-1" ? (
                                <ActionIcon
                                  onClick={() => SetLogicStateHand(index)}
                                >
                                  <IconArrowsShuffle color="gray" size={15} />
                                </ActionIcon>
                              ) : (
                                <ActionIcon
                                  onClick={() => handleBadgeDel(index)}
                                >
                                  <IconX color="gray" size={15} />
                                </ActionIcon>
                              )
                            }
                            mx={5}
                            className="cursor-pointer"
                            color={item.datacolor}
                            variant="outline"
                            radius="xs"
                          >
                            {item.dataLable}
                          </Badge>{" "}
                        </React.Fragment>
                      );
                    })}
                  </Group>
                </Box>
                <ActionIcon
                  style={{ position: "relative" }}
                  className="cursor-pointer"
                  onClick={OperatorValue}
                >
                  {/* <IconCirclePlus onClick={toggle}  color="blue"></IconCirclePlus> */}
                  <IconCirclePlus
                    onClick={() => SetLogicStateHand("add")}
                    color="blue"
                  ></IconCirclePlus>
                  {logicState ? (
                    <Box mt={10} className="report-bttom-add">
                      <Select
                        size="xs"
                        w={100}
                        onChange={(event) => handleSelectChange(event, "add")}
                        data={globalCoreSetting.Operator}
                      />
                    </Box>
                  ) : null}
                </ActionIcon>
              </Flex>
            </Box>
            <Box className="bttom-btn">
              <SaveBottom formRef={formRef} />
            </Box>
          </form>
        </Grid.Col>
        <Grid.Col span={11}>
          <Text className="form-title">
            {intl.formatMessage({
              id: "Selectable Values & Filters",
              defaultMessage: "Selectable Values & Filters",
            })}
          </Text>
          <Text className="form-title-desc mb-6">
            {intl.formatMessage({
              id: "Click to add values to calculation",
              defaultMessage: "Click to add values to calculation",
            })}
          </Text>
          <Box
            mih={310}
            className="border-create-sub"
            style={{ position: "relative" }}
            pl={10}
            pt={10}
            mr={12}
          >
            {/* company */}
            <Text fw={400} size={8} c="#868E96">
              {intl.formatMessage({
                id: "Filter Company Name",
                defaultMessage: "Filter Company Name",
              })}
            </Text>
            <Grid columns={12} gutter={0}>
              <Grid.Col span={12}>
                <React.Fragment>
                  {globalCoreSetting.reportBadgeList.Company.map(
                    (item, index) => {
                      if (index > 1) return null;
                      return (
                        <Badge
                          size="xs"
                          mx={5}
                          onClick={(event) => handleBadgeClick(event, "normal")}
                          data-color="blue"
                          data-value={item.value}
                          data-formal={item.formal}
                          data-type={item.type}
                          data-label={item.label}
                          className="cursor-pointer"
                          variant="outline"
                          radius="xs"
                        >
                          {item.label}
                        </Badge>
                      );
                    }
                  )}
                </React.Fragment>
              </Grid.Col>
              <Grid.Col span={12}>
                {globalCoreSetting.reportBadgeList.Company.map(
                  (item, index) => {
                    if (index <= 1) return null;
                    return (
                      <Badge
                        size="xs"
                        mx={5}
                        className="cursor-pointer"
                        data-color="blue"
                        onClick={(event) => handleBadgeClick(event, "normal")}
                        data-formal={item.formal}
                        data-type={item.type}
                        data-label={item.label}
                        data-value={item.value}
                        variant="outline"
                        radius="xs"
                      >
                        {item.label}
                      </Badge>
                    );
                  }
                )}
              </Grid.Col>
            </Grid>

            <Text fw={400} mt={10} size={8} c="#868E96">
              {intl.formatMessage({
                id: "Project Filter",
                defaultMessage: "Project Filter",
              })}
            </Text>
            <Grid columns={12} gutter={0}>
              <Grid.Col span={12}>
                {filterItemList.map((item, index) => {
                  return (
                    <Badge
                      size="xs"
                      color="green"
                      mx={5}
                      className="cursor-pointer"
                      data-color="green"
                      onClick={(event) => handleBadgeClick(event, "filter")}
                      data-formal={item.formal}
                      data-type={item.type}
                      data-label={item.label}
                      data-value={item.value}
                      variant="outline"
                      radius="xs"
                    >
                      {item.label}
                    </Badge>
                  );
                })}
              </Grid.Col>
            </Grid>
            {/* order */}
            {taskModeFlag !== "2" ? (
              <>
                {/* filter */}
                <Text fw={400} mt={10} size={8} c="#868E96">
                  {intl.formatMessage({
                    id: "Order Filter",
                    defaultMessage: "Order Filter",
                  })}
                </Text>
                <Grid columns={12} gutter={0}>
                  <Grid.Col span={12}>
                    {filterOrderItemList.map((item, index) => {
                      return (
                        <Badge
                          size="xs"
                          color="green"
                          mx={5}
                          className="cursor-pointer"
                          data-color="green"
                          onClick={(event) => handleBadgeClick(event, "filter")}
                          data-formal={item.formal}
                          data-type={item.type}
                          data-label={item.label}
                          data-value={item.value}
                          variant="outline"
                          radius="xs"
                        >
                          {item.label}
                        </Badge>
                      );
                    })}
                  </Grid.Col>
                </Grid>
                {/* task */}

                {/* filter */}
                <Text fw={400} mt={10} size={8} c="#868E96">
                  {intl.formatMessage({
                    id: "Task Filter",
                    defaultMessage: "Task Filter",
                  })}
                </Text>
                <Grid columns={12} gutter={0}>
                  <Grid.Col span={12}>
                    {filterTaskItemList.map((item, index) => {
                      return (
                        <Badge
                          size="xs"
                          color="green"
                          mx={5}
                          className="cursor-pointer"
                          data-color="green"
                          onClick={(event) => handleBadgeClick(event, "filter")}
                          data-formal={item.formal}
                          data-type={item.type}
                          data-label={item.label}
                          data-value={item.value}
                          variant="outline"
                          radius="xs"
                        >
                          {item.label}
                        </Badge>
                      );
                    })}
                  </Grid.Col>
                </Grid>
              </>
            ) : (
              <></>
            )}

            {/* user */}

            {/* filter */}
            <Text fw={400} mt={10} size={8} c="#868E96">
              {intl.formatMessage({
                id: "User Filter",
                defaultMessage: "User Filter",
              })}
            </Text>
            <Grid columns={12} gutter={0}>
              <Grid.Col span={12}>
                {userItemList.map((item, index) => {
                  return (
                    <Badge
                      size="xs"
                      color="green"
                      mx={5}
                      className="cursor-pointer"
                      data-color="green"
                      onClick={(event) => handleBadgeClick(event, "filter")}
                      data-formal={item.formal}
                      data-type={item.type}
                      data-label={item.label}
                      data-value={item.value}
                      variant="outline"
                      radius="xs"
                    >
                      {item.label}
                    </Badge>
                  );
                })}
              </Grid.Col>
            </Grid>
            {/* <Box className="bttom-btn">
                    <Button leftIcon={<IconPlus size={20}/>} onClick={addCustom} variant="subtle">  Add Custom</Button>
                   </Box> */}
          </Box>
        </Grid.Col>
      </Grid>
    </Box>
  );
}
