//

import { Group } from "@mantine/core";
import WordTranslationComponent from "../../../utils/language_pack/words";
import { PermissionActionInterface } from "../../../interface/Iemloyee";
import EditIconPerm from "../../EditIcon/EditIconPerm";
import DeleteIconPerm from "../../DeleteIcon/DeleteIconPerm";
import ActiveBadge from "../../ActiveBadge/ActiveBadge";
import CheckPermissionTools from "../../../utils/permission";


interface ComponentInterface {
  Permission:any;
  currentItem: PermissionActionInterface;
  editHandle: (value: PermissionActionInterface) => void;
  delHandle: (value: PermissionActionInterface) => void;
}

export default function PermissionActionTableTr({Permission,  currentItem,editHandle,delHandle}: ComponentInterface) {

  const Perm = CheckPermissionTools();
  // Get the information to be modified and pass to the parent -level component
  return (
    <tr  className="border-bt-2 my-10 ">
      <td> {currentItem.id_permission}</td>
      <td> {currentItem.title}</td>
      <td> {currentItem.identifier}</td>
      <td> {currentItem.description}</td>
      {/* <td> {currentItem.costCenter_name}</td> */}
      <td><ActiveBadge value={currentItem.active} /></td>
      <td> {currentItem.created_at}</td>
      <td>
        <Group position="center">
            
            <EditIconPerm  enable={Perm.module_service_update}  callBack={() => editHandle(currentItem)}/>
             <DeleteIconPerm enable={Perm.module_service_delete} callBack={() => delHandle(currentItem) }/>
        </Group>
      </td>
    </tr>
  )
}