import { ActionIcon, Avatar, Box, Group, LoadingOverlay, ScrollArea, Table, Tooltip, Text } from "@mantine/core";
import { reAttributeType, reGetEmployeeCustomAttributeList, reGetEmployeeCustomAttributetTypesList } from "../../../../api";
import { Key, useEffect, useState } from "react";
import { Attribute, AttributeType } from "../../../../interface/Iuser";
import cx from 'clsx';
import classes from './custom_attrbutes/TableScrollArea.module.css';
import { IconDots, IconEdit, IconTrash } from "@tabler/icons-react";
import WordTranslationComponent from "../../../../utils/language_pack/words";
import { modals } from "@mantine/modals";
import { HintInfo } from "../../../../utils/function";
import GlobalCoreSetting from "../../../../utils/globalCortSetting";
import { IconDotsVertical } from "@tabler/icons-react";
import { OrderDataInterface } from "../../../../interface/Iorder";
import AvatarGroupComponnet from "../../../../ttcomponents/AvatarGroup/AvatarGroup";
import OrderListTr from "./order_list_tr";
import React from "react";

interface ComponentInterface{
 is_refresh:number,
 orderlist:OrderDataInterface[];
 callBackfunction:(value:OrderDataInterface)=>void,
 callBackOrderIDfunction: (value: string,is_checked: boolean) => void,
 returnAttributeList:(value:OrderDataInterface[])=>void,
}
export default function OrderList({is_refresh,orderlist,callBackfunction,callBackOrderIDfunction,returnAttributeList}:ComponentInterface){
    
  const [loading, setLoading] = useState(false);
    // Get translation public configuration information
  const word_translation = WordTranslationComponent();
  // Get the global configuration
  const globalCoreSetting = GlobalCoreSetting();
  
  const [attributeList, setAttributeList] = useState<OrderDataInterface[]>([]);

  const ajaxCurrentList = async () => {
    setLoading(true); // Set the loading status to TRUE, indicating that the data is loading
    // Send GET requests to get Attribute Type data
    const responseAttributeTypeList = await reGetEmployeeCustomAttributetTypesList({},"GET");
    setLoading(false); // After the data is loaded, set the loading status to false
    if (responseAttributeTypeList.data.code === 200) {
        setAttributeList(responseAttributeTypeList.data.data); 
    }
    returnAttributeList(responseAttributeTypeList.data.data)
  };
  // Use the USEEFFECT hook. When Search changes, call the AjaxCurrenTList function
  useEffect(() => {
    ajaxCurrentList();
  }, [is_refresh]);

  
    const handleEdit =  (row:OrderDataInterface) => {
        callBackfunction(row)
    };

   
    // Delete user role
    const handleDel =  (row: OrderDataInterface) => {
        modals.openConfirmModal({
          title: word_translation.delete_title,
          centered: true,
          children: (
            <Text size="sm" mt={12}>
                <p>  {word_translation.order_name} : {row.order_name}</p> 
                <p>  {word_translation.order_no} : {row.order_no}</p> 
            </Text>
          ),
          labels:globalCoreSetting.DeleteOpenConfirmModaLabels,
          confirmProps: globalCoreSetting.DeleteOpenConfirmModaConfirmProps,
          onConfirm: async() => {
              const attributeTypeResult = await reAttributeType({ id: row.id_order }, "DELETE");
              HintInfo(attributeTypeResult.data);
              if (attributeTypeResult.data.code == 200) {
                ajaxCurrentList();
              }
          },
        });
      };
    
    
    return (
        <Box className="px-15 pt-xs pt-4">
        <LoadingOverlay visible={loading} overlayBlur={2} />
        <ScrollArea h={200} >
        <Table>
            <thead  className="scrolleder ">
                <tr>
                <th  id="table-th-title-new">{word_translation.order}</th>
                <th  id="table-th-title-new">{word_translation.order_no}</th>
                <th  id="table-th-title-new">{word_translation.order_leader}</th>
                <th  id="table-th-title-new">{word_translation.project_no}</th>
                <th  id="table-th-title-new">{word_translation.option}</th>
                </tr>
            </thead>
            <tbody>
            {orderlist && orderlist?.length > 0 && orderlist?.map((item, index) => {
                    return (
                        <React.Fragment key={index}>
                            <OrderListTr callBackfunction={callBackOrderIDfunction} row={item} index={index} />
                        </React.Fragment>
                    );
                })}
            </tbody>
            </Table>
            </ScrollArea>
        </Box>
    )
}