import {
  Avatar,
  Checkbox,
  Text,
  Grid,
  Group,
  PasswordInput,
  Select,
  TextInput,
  Textarea,
  Box,
  LoadingOverlay,
  ActionIcon,
  Modal,
  Code,
  Flex
} from '@mantine/core'
import { hasLength, isNotEmpty, useForm } from '@mantine/form'

import { forwardRef, useEffect, useMemo, useState } from 'react'
import { useDisclosure, useSetState } from '@mantine/hooks'
import { regGeUser, regUser, requestDeletePermission, requestManagePermissionRole } from '../../../api'
import { SelectRole } from '../../../interface/Icommon'
import WordTranslationComponent from '../../../utils/language_pack/words'
import TableTextInputTitle from '../../../ttcomponents/TableTextInputTitle/TableTextInputTitle'
import { ClientWarningHint, HintInfo, HintInfoCatchError } from '../../../utils/function'
import { SignalUserInterFace, UserInterface } from '../../../interface/Iuser'
import apiUrl from '../../../config/api_config/config'
import { modals } from '@mantine/modals'
import SubmitBtn from '../../../ttcomponents/Button/SubmitBtn'
import { IconLicense, IconX } from '@tabler/icons-react'
import UniversalButton from '../../../ttcomponents/Button/UniversalButton'
import ModalTitleText from '../../../ttcomponents/ModalTitleText/ModalTitleText'
import PermissionSet from './permission/role_permission'
import { PermissionData } from './permission/type'
import { getLeaveApproveUserListFunction } from '../../../api/core_request'
import CheckPermissionTools from '../../../utils/permission'
interface UserFormProps {
  userid: string
  emailOptions: string[]
  callBack: () => void
  formStatus?: boolean
  userRoles: SelectRole[]
  costCenterData: SelectRole[]
  calculationType: SelectRole[]
  userInfo?: UserInterface
  [key: string]: any
}

type Approver = {
  label: string
  value: string
  costCenter_id: string
  email: string
  avatar: string
}

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  label: string
  value: string
  costCenter_id: string
  email: string
  avatar: string
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(({ label, email, avatar, ...others }, ref) => (
  <div ref={ref} {...others}>
    <Group noWrap>
      <Avatar src={apiUrl.fileSystemDomain + avatar} />
      <div>
        <Text size="sm">{label}</Text>
        <Text size="xs" opacity={0.65}>
          {email}
        </Text>
      </div>
    </Group>
  </div>
))

export default function UserForm({
  userid,
  emailOptions,
  formStatus = false,
  userRoles,
  costCenterData,
  calculationType,
  callBack
}: UserFormProps) {
  //-------------permission list allocation-------------
  const [rand_number, setRandNumber] = useState<number>(1)
  const Perm = CheckPermissionTools()
  const [approveRandNumber, setApproveRandNumber] = useState<number>(1)
  const [userInfoDetail, setUserInfoDetail] = useState<SignalUserInterFace>()

  const [loading, setLoading] = useState<boolean>(false)
  const [approver_id, setApproverId] = useState<string>('')
  // Get the global configuration

  // 可见密码
  const [visible, { toggle }] = useDisclosure(false)

  // Get translation public configuration information
  const word_translation = WordTranslationComponent()

  // Vacation Approver options
  const [userList, setUserList] = useState<Approver[]>([])

  const form = useForm({
    validateInputOnBlur: true,
    initialValues: {
      id: '',
      username: '',
      costCenter_id: '',
      email: '',
      phone: '',
      user_role_id: '',
      mobil: '',
      first_name: '',
      last_name: '',
      calculation_type_id: '',
      personal_no: '',
      vacation_approver_id: '',
      approval_permission: '',
      vacation_day_year: '',
      work_days_week: '',
      work_hours_week: '',
      active: '0',
      confirm_password: '',
      password: ''
    },
    validate: {
      username: hasLength(
        { min: 4, max: 100 },
        `${word_translation.username}${word_translation.validata_value_need_6_100_length}`
      ),
      first_name: isNotEmpty(word_translation.first_name + word_translation.is_not_empty),
      last_name: isNotEmpty(word_translation.last_name + word_translation.is_not_empty),
      password: (value, values) => {
        if (values.password && values.password.length < 6) {
          return 'Password must be at least 6 characters'
        }
        return null
      }
    }
  })

  // permission edit open
  const [openPermissionEdit, setOpenPermissionEdit] = useState(false)
  const [openPermissionView, setOpenPermissionView] = useState(false)
  const [permissionList, setPermissionList] = useState<PermissionData[]>([])

  //===============  Get a list of users  Function===============  //
  const getSignalUser = async () => {
    // Call the Get User Universal Selection function and set the user list
    var response = await regGeUser(userid)
    var result = response.data
    if (result.code == 200) {
      var userInfo = result.data
      
      form.setFieldValue('id', userInfo?.id_user as string)
      form.setFieldValue('username', userInfo?.username as string)
      form.setFieldValue('email', userInfo?.email as string)
      form.setFieldValue('phone', userInfo?.phone as string)
      form.setFieldValue('mobil', userInfo?.mobil as string)
      form.setFieldValue('active', userInfo?.active as string)
      form.setFieldValue('personal_no', userInfo?.personal_no as string)
      form.setFieldValue('first_name', userInfo?.first_name as string)
      form.setFieldValue('vacation_approver_id', userInfo?.vacation_approver_id as string)
      form.setFieldValue('user_role_id', userInfo?.user_role_id?.toString() as string)
      form.setFieldValue('costCenter_id', userInfo?.costcenter?.toString() as string)
      form.setFieldValue('last_name', userInfo?.last_name as string)
      form.setFieldValue('calculation_type_id', userInfo?.calculation_type_id as string)
      form.setFieldValue('approval_permission', userInfo?.approval_permission as string)
      form.setFieldValue('vacation_day_year', userInfo?.vacation_day_year as string)
      form.setFieldValue('work_hours_week', userInfo?.work_hours_week as string)
      form.setFieldValue('work_days_week', userInfo?.work_days_week as string)
      setApproverId( userInfo?.vacation_approver_id.toString())
      setUserInfoDetail(result.data)
      setRandNumber(rand_number + 1)
    }
  }

  //=============== There is a permission prompt box  ===============  //

  const openPermissionPromptModal = async (user_id: string, msg: string) =>
    modals.openConfirmModal({
      title: 'Tips for changing costCenter',
      children: <Text size="sm">{msg}</Text>,
      labels: { confirm: 'Clear current user permissions', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onCancel: () => console.log('Cancel'),
      onConfirm: async () => {
        setLoading(true)
        var response = await requestDeletePermission({ user_id: user_id }, 'DELETE')
        let result = response.data
        setLoading(false)
        HintInfo(result)
      }
    })

  useEffect(() => {
    if (formStatus && userid) {
      getSignalUser()
    }
  }, [userid, formStatus])

  // Function responsible for saving form data to the backend
  const savePremissions = async (user_ids: number) => {
    const submitData = {
      permissions: permissionList,
      user_ids: [user_ids]
    }
    try {
      // Set loading state to true while awaiting response from backend
      setLoading(true)
      if (permissionList.length > 0) {
        await requestManagePermissionRole(submitData, 'POST')
      }
    } catch (error) {
      // Handle errors and display hint information
      HintInfoCatchError(error)
    } finally {
      setLoading(false)
    }
  }

  const handleFormSubmit = async () => {
    // Verification form
    const valid = await form.validate()
    if (!valid.hasErrors) {
      if (form.values.password !== form.values.confirm_password) {
        ClientWarningHint('The two passwords do not match')
        return
      }
      const vacation_day_year = parseInt(form.values.vacation_day_year)
      const work_hours_week = parseInt(form.values.work_hours_week)
      const work_days_week = parseInt(form.values.work_days_week)
      const max_work_hours_week = work_days_week * 24
      if (vacation_day_year > 365) {
        ClientWarningHint(`The ${word_translation.vacation_day_year} is maximum 365.`)
        return
      }
      if (work_hours_week > max_work_hours_week) {
        ClientWarningHint(`The ${word_translation.work_hours_week} is maximum ${max_work_hours_week}.`)
        return
      }
      if (work_days_week > 7) {
        ClientWarningHint(`The ${word_translation.working_days_week} is maximum 7.`)
        return
      }
      const methodType = form.values.id == '' || form.values.id == undefined ? 'POST' : 'PUT'

      const response = await regUser(form.values, methodType)
      const result = response.data

      if (result.code === 200) {
        HintInfo(result)

        await savePremissions(result.data.id)
        callBack()
      } else if (result.code === 4114) {
        openPermissionPromptModal(form.values.id, result.msg)
      } else {
        HintInfo(result)
      }
    }
  }

  const selectUser = (v: string) => {
    form.setFieldValue('vacation_approver_id', v)
  }

  const updateApprover = (data: Approver[]) => {
    if (data.length === 0 || !form.values.id) {
      selectUser('')
      return
    }

    const index = data.findIndex(d => form.values.id === d.value)
    if (index !== -1) {
      selectUser(data[index].value)
    } else {
      selectUser('')
    }
  }

  useEffect(() => {
    const getLeaveApproveUserList = async () => {
      const param: any = {}
      form.values.user_role_id && (param['role_id'] = form.values.user_role_id)
      form.values.costCenter_id && (param['costCenter_id'] = form.values.costCenter_id)

      const data = await getLeaveApproveUserListFunction(param)
      setUserList(data)
      updateApprover(data)
      setApproveRandNumber(approveRandNumber + 1)
    }
    getLeaveApproveUserList()
  }, [form.values.user_role_id, form.values.costCenter_id])

  // email
  const [emailPrefix, setEmailPrefix] = useState('')
  const [emailSuffix, setEmailSuffix] = useState('')

  useEffect(() => {
    if (emailOptions && emailOptions.length > 0) {
      setEmailSuffix(emailOptions[0])
    }
  }, [emailOptions])

  // emailPrefix
  useEffect(() => {
    if (form.values.first_name && form.values.last_name) {
      setEmailPrefix(`${form.values.first_name}.${form.values.last_name}`)
    }
  }, [form.values.first_name, form.values.last_name])

  // set email
  useEffect(() => {
    if (emailPrefix && emailSuffix) {
      form.setFieldValue('email', `${emailPrefix}@${emailSuffix}`)
    }
  }, [emailPrefix, emailSuffix])

  return (
    <>
      <form>
        <Box pos="relative">
          <LoadingOverlay visible={loading} overlayBlur={2} />
          <Grid>
            <Grid.Col span={4}>
              <TableTextInputTitle title={word_translation.username} />
              <TextInput
                required
                placeholder={word_translation.username}
                disabled={userid !== ''}
                {...form.getInputProps('username')}
              />
            </Grid.Col>
            <Grid.Col offset={1} span={4}>
              <TableTextInputTitle title={word_translation.personal_no_2} />
              <TextInput required placeholder={word_translation.personal_no_2} {...form.getInputProps('personal_no')} />
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={3}>
              <TableTextInputTitle title={word_translation.first_name} />
              <TextInput required placeholder={word_translation.first_name} {...form.getInputProps('first_name')} />
            </Grid.Col>
            <Grid.Col span={3}>
              <TableTextInputTitle title={word_translation.surname} />
              <TextInput required placeholder={word_translation.surname} {...form.getInputProps('last_name')} />
            </Grid.Col>
            <Grid.Col span={6}>
              <TableTextInputTitle title={word_translation.email} />
              <Group spacing={4}>
                <TextInput
                  w={150}
                  required
                  placeholder={word_translation.email}
                  value={emailPrefix}
                  onInput={e => setEmailPrefix((e.target as any).value)}
                />
                <span>@</span>
                <Select
                  w={150}
                  required
                  placeholder={word_translation.email_suffix}
                  searchable
                  clearable
                  nothingFound={word_translation.no_data}
                  value={emailSuffix}
                  onChange={value => setEmailSuffix(value ? value : '')}
                  data={emailOptions}
                  autoComplete="new-password"
                />
              </Group>
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={5}>
              <TableTextInputTitle title={word_translation.phone} />
              <TextInput placeholder={word_translation.phone} {...form.getInputProps('phone')} />
            </Grid.Col>
            <Grid.Col span={4}>
              <TableTextInputTitle title={word_translation.mobile} />
              <TextInput placeholder={word_translation.phone} {...form.getInputProps('mobil')} />
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={4}>
              <TableTextInputTitle title={word_translation.vacation_day_year} />
              <TextInput
                required
                placeholder={word_translation.vacation_day_year}
                {...form.getInputProps('vacation_day_year')}
              />
              {form.values.id && userInfoDetail?.vacation_day_year.toString() != form.values.vacation_day_year && (
                <Textarea
                  mt={2}
                  minRows={4}
                  placeholder={word_translation.update_reason}
                  {...form.getInputProps('vacation_day_year_reason')}
                />
              )}
            </Grid.Col>
            <Grid.Col span={4}>
              <TableTextInputTitle title={word_translation.work_hours_week} />
              <TextInput placeholder={word_translation.Input} {...form.getInputProps('work_hours_week')} />
              {form.values.id && userInfoDetail?.work_hours_week.toString() != form.values.work_hours_week && (
                <Textarea
                  mt={2}
                  minRows={4}
                  placeholder={word_translation.update_reason}
                  {...form.getInputProps('work_hours_week_reason')}
                />
              )}
            </Grid.Col>
            <Grid.Col span={4}>
              <TableTextInputTitle title={word_translation.working_days_week} />
              <TextInput required placeholder={word_translation.Input} {...form.getInputProps('work_days_week')} />
              {form.values.id && userInfoDetail?.work_days_week.toString() != form.values.work_days_week && (
                <Textarea
                  mt={2}
                  minRows={4}
                  placeholder={word_translation.update_reason}
                  {...form.getInputProps('work_days_week_reason')}
                />
              )}
            </Grid.Col>
          </Grid>

          <Grid>
            <Grid.Col span={12}>
              <Grid>
                <Grid.Col span={6}>
                  <TableTextInputTitle title={word_translation.password} />
                  <PasswordInput
                    defaultValue="secret"
                    visible={visible}
                    {...form.getInputProps('password')}
                    placeholder={word_translation.Input}
                    onVisibilityChange={toggle}
                    autoComplete="new-password"
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TableTextInputTitle title={word_translation.confirm_password} />
                  <PasswordInput
                    visible={visible}
                    placeholder={word_translation.Input}
                    {...form.getInputProps('confirm_password')}
                    onVisibilityChange={toggle}
                    autoComplete="new-password"
                  />
                </Grid.Col>
              </Grid>
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={4}>
              <TableTextInputTitle title={word_translation.select_cost_center} />
              <Select
                placeholder={word_translation.select_cost_center}
                searchable
                key={rand_number}
                disabled={!Perm.admin}
                defaultValue={form.values.costCenter_id}
                onChange={value => form.setFieldValue('costCenter_id', value as string)}
                data={costCenterData}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <TableTextInputTitle title={word_translation.select_role_name} />
              <Select
                placeholder={word_translation.pick_one}
                searchable
                key={rand_number}
                nothingFound={word_translation.no_data}
                defaultValue={form.values?.user_role_id && form.values?.user_role_id.toString()}
                onChange={value => form.setFieldValue('user_role_id', value as string)}
                data={userRoles}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              {/* <TableTextInputTitle title={word_translation.select_calculation_type} />
              <Select
                placeholder={word_translation.pick_one}
                searchable
                key={rand_number}
                nothingFound={word_translation.no_data}
                defaultValue={form.values?.calculation_type_id && form.values?.calculation_type_id.toString()}
                onChange={value => form.setFieldValue('calculation_type_id', value as string)}
                data={calculationType}
              /> */}
            </Grid.Col>

            <Grid.Col span={4}>
              <TableTextInputTitle title={word_translation.vacation + ' ' + word_translation.approver} />
              <Select
                placeholder={word_translation.pick_one}
                itemComponent={SelectItem}
                searchable
                clearable
                data={userList}
                key={approveRandNumber+rand_number}
                onChange={selectUser}
                nothingFound={word_translation.no_data}
                className="time_sheet_home"
                maxDropdownHeight={400}
                defaultValue={approver_id}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              {userid === '' ? (
                <Box mt={28}>
                  <UniversalButton
                    btn_text={word_translation.permission_edit}
                    btn_icon={<IconLicense size={'1rem'} />}
                    btn_size="xs"
                    callBack={() => setOpenPermissionEdit(true)}
                  />
                </Box>
              ) : (
                <></>
                // <Box mt={28}>
                //   <UniversalButton
                //     btn_text={word_translation.permission_view}
                //     btn_icon={<IconLicense size={'1rem'} />}
                //     btn_size="xs"
                //     callBack={() => setOpenPermissionView(true)}
                //   />
                // </Box>
              )}
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={8}>
              <Checkbox
                label={<TableTextInputTitle title={word_translation.active} />}
                mt={10}
                checked={form.values.active == '1' ? true : false}
                onChange={event => {
                  form.setFieldValue('active', event.currentTarget.checked ? '1' : '0')
                }}
              />
            </Grid.Col>
          </Grid>
          <Group position="right">
            <SubmitBtn btn_size="xs" callBack={handleFormSubmit} />
          </Group>
        </Box>
      </form>
      <Modal
        size={900}
        opened={openPermissionEdit}
        closeOnClickOutside={false}
        onClose={() => setOpenPermissionEdit(false)}
        title={<ModalTitleText title={word_translation.permission_edit} />}
        overlayProps={{ opacity: 0.5, blur: 4 }}
      >
        <PermissionSet
          formValue={permissionList}
          setFormValue={setPermissionList}
          close={() => setOpenPermissionEdit(false)}
        />
      </Modal>
      {/* <Modal
        size={900}
        opened={openPermissionEdit}
        closeOnClickOutside={false}
        onClose={() => setOpenPermissionView(false)}
        title={<ModalTitleText title={word_translation.permission_edit} />}
        overlayProps={{ opacity: 0.5, blur: 4 }}
      >
        <PermissionSet
          formValue={permissionList}
          setFormValue={setPermissionList}
          close={() => setOpenPermissionView(false)}
        />
      </Modal> */}
    </>
  )
}
