import React, { FormEvent, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import {
    MantineProvider, TextInput, PasswordInput, Text, Paper, Group, PaperProps, Button, Grid, Divider, Container,
    Center, Checkbox, Anchor, Flex, Stack, ActionIcon, useMantineColorScheme, Notification, Box, rem
} from '@mantine/core';

import { useForm } from '@mantine/form';
import { IconArrowLeft, IconSun, IconMoonStars, IconCheck, IconX } from '@tabler/icons-react';
import { useToggle, upperFirst } from '@mantine/hooks';
import { Notifications, notifications } from '@mantine/notifications';
import './login.css'
import { regGlobalSetting, regLogin } from "../../../api";
import memoryUtils from "../../../utils/memoryUtils";
import localstorageUnits from "../../../utils/localstorageUnits";
import { FormattedMessage, useIntl } from "react-intl";
import { HintInfoCatchError, SystemInit, encryptData } from "../../../utils/function";
import { useDispatch } from "react-redux";
import { setMenuFromApi } from "../../../state_management/features/menu/menuSlice";
import apiUrl from '../../../config/api_config/config';

export default function Login(props: PaperProps) {
    // 钩子函数-切换语言
    const intl = useIntl();
    const navigate = useNavigate();
    const { colorScheme, toggleColorScheme } = useMantineColorScheme(); 
    const [companyName, setCompanyName] = React.useState('Apollo');

    const dark = colorScheme === 'dark';
    const [type, toggle] = useToggle(['login', 'register']);
    const form = useForm({
        initialValues: {
            username: '',
            password: '',
            is_ldap: false
        },
        validate: {
            username: (val) => (/^[\p{L}\w.-]{4,45}$/u.test(val) ? null : 'Five to 16 Characters (including decimal points)'),
            password: (val) => (val.length < 6 ? 'Password should include at least 6 characters' : null),
        },
    });
    // update company name
    useEffect(()=>{
        localstorageUnits.removeUser();
        localstorageUnits.removeMenu();
        localstorageUnits.removePath();
        localstorageUnits.removePermission();
        let SystemInfo =  localstorageUnits.getSystem() ;
        if(SystemInfo.companyName)  setCompanyName(SystemInfo.companyName)
    },[])
    const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault(); // Stop the formal submission of the form default
        // Verification form
        const valid = await form.validate();
        if (!valid.hasErrors) {
            const response = await regLogin(form.values.username, form.values.password, form.values.is_ldap);
            const result = response.data;
            if(typeof result === 'string'){
                notifications.show({
                    title: intl.formatMessage({  id: "login_hint", defaultMessage: 'Login hint'}),
                    color: 'red',
                    icon: <IconX />,
                    message: result,
                })
                return false;
            }else  if (result.code === 200) {
                const userInfo = result.data;
                memoryUtils.user = userInfo;
                localstorageUnits.saveFootMenu(userInfo.footer_menu);
                localstorageUnits.saveUser(userInfo);
                localstorageUnits.saveMenu(userInfo.menu);
                localstorageUnits.savePermissionIdentifier(userInfo.permissionIdentifier_actions);
                notifications.show({
                    title: intl.formatMessage({  id: "login_hint", defaultMessage: 'Login hint'}),
                    color: 'green', 
                    icon: <IconCheck />,
                    message: result.msg,
                })
           
                localstorageUnits.savePath({ currentUrl: userInfo.redirect.link, currentActiveIndex:userInfo.redirect.label, currentPreActiveIndex: userInfo.redirect.label })
                
                navigate(userInfo.redirect.link);
           
            } else {
          
                console.log(result)
                notifications.show({
                    title: intl.formatMessage({  id: "login_hint", defaultMessage: 'Login hint'}),
                    color: 'red',
                    icon: <IconX />,
                    message: result.msg,
                })
            }
        }
    };

    // 点击inter键输入触发编辑时间

      const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, tastlistItem: any, data: string) => {
        if (event.key === 'Enter') {
            console.log((event.target as HTMLInputElement).value)
        //   EditTimeTrackRecode((event.target as HTMLInputElement).value);
        }
      };
  
    return (
        <Center maw={'100%'} h={'100vh'} mx="auto">
            <Container w={'100vh'} h={'51vh'} px={0}  >
                <Flex
                    gap="0px"
                    justify="flex-start"
                    h={'100%'}
                    align="flex-start"
                    direction="row"
                    wrap="wrap"
                >
                    <Box w={'65%'} h={'100%'}  >
                        <Paper px={60} pt={40} className="custom-box-left" h={'100%'}   {...props}>
                            <form onSubmit={handleFormSubmit}>
                                <div className="pt2 pb-12">
                                    <Text fw={700} size={35} color={dark ? 'customize-gray.2' : 'customize-gray.0'}>
                                        <FormattedMessage id="LoginTitle" defaultMessage="Login" />
                                    </Text>
                                </div>
                                <Stack>
                                    <Text mt={40} h={10} size={15} color="customize-gray.1">
                                        <FormattedMessage id="username" defaultMessage="Username" />
                                    </Text>
                                    <TextInput
                                        required
                                        placeholder={intl.formatMessage({ id: 'username_placeholder', defaultMessage: '5 to 16 Characters' })}
                                        value={form.values.username}
                                        size="md"
                                        onKeyDown={(even)=>handleKeyDown}
                                        labelProps={{
                                            style: {
                                                color: 'customize-gray.1',
                                            },
                                        }}
                                        onChange={(event) => form.setFieldValue('username', event.currentTarget.value)}
                                        error={form.errors.username && '5 to 45 Characters'}
                                        radius="md"
                                    />
                                    <Text h={10} size={15} color="customize-gray.1">
                                        <FormattedMessage id="password" defaultMessage="Password" />
                                    </Text>
                                    <PasswordInput
                                        required
                                        size="md"
                                        my={10}
                                        onKeyDown={(even)=>handleKeyDown}
                                        placeholder={intl.formatMessage({ id: 'password_placeholder', defaultMessage: 'Your password' })}
                                        value={form.values.password}
                                        onChange={(event) => form.setFieldValue('password', event.currentTarget.value)}
                                        error={form.errors.password && 'Password should include at least 6 characters'}
                                        radius="md"
                                    />
                                    <Button   size="md" type="submit" color="customize-blue.1"  >
                                        <FormattedMessage id="login" defaultMessage="Login" />
                                        {/* {upperFirst(type)} */}
                                    </Button>
                                    <Box mt={40}>
                                        {/* <Text   size={15} color="customize-gray.1"> Forget Password?</Text> */}
                                    </Box>
                                </Stack>

                            </form>
                        </Paper>
                    </Box>
                    <Box w={'35%'} h={'100%'}>
                        <Paper style={{ backgroundColor: '#49a3f2', position: 'relative', zIndex: -1 }} className="custom-box-right" radius-right="md" pt={40} shadow="xl" h={'100%'}  >

                            <Box className="rounded-customize-1" left={-190} top={5}></Box>
                            <Box className="rounded-customize-2" left={-154} top={-65}></Box>
                            <Box className="rounded-customize-3" bottom={31} right={15}></Box>

                            <Box className="rounded-customize-5" top={130} left={90}>
                                <Text size={32} fw={700} color={'customize-gray.2'} >
                                    {companyName}
                                </Text>
                            </Box>
                            <Box className="rounded-customize-4" bottom={40} left={29}>
                                <Text size={20} fw={700} color={'customize-gray.2'}>
                                    <FormattedMessage id="Welcome" defaultMessage="Welcome" />
                                </Text>
                                <Text size={20} fw={700} color={'customize-gray.2'}>
                                    <FormattedMessage id="Back" defaultMessage="Back" />
                                </Text>
                                <Text size={20} fw={700} color={'customize-gray.2'}>
                                    <FormattedMessage id="Hey" defaultMessage="Hey" />
                                </Text>
                            </Box>

                            {/* 
                         <div class="rounded-full rounded-customize-1 absolute h-56 w-56 -left-56"> </div>
                         <div class="rounded-full rounded-customize-2 absolute bottom-10 -top-24 -left-48"> </div>
                         <div class="rounded-full shadow-lg shadow-customize-gray-4  rounded-customize-3 absolute h-20 w-20 bottom-10 right-6"> </div>
                         <div class="absolute  h-20 w-20 bottom-10 font-bold text-white top-36 right-48 text-customize-f-40 ">
                                 Apollo
                         </div>
     <p>Welcome</p>
                               <p>Back</p>
                               <p>Hey</p> 
                        
                    */}
                        </Paper>
                    </Box>
                </Flex>


            </Container>

        </Center>
    )
}
