import { Box, Button, Card, Divider, Modal, Group, LoadingOverlay, Flex, Text, Paper, Table } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { FormattedMessage, useIntl } from "react-intl";
import { IPagination } from '../../../interface/Ipagintion';
import {  regGetSupplierContact, regSupplierContact } from '../../../api';
import SearchBox from '../../../ttcomponents/SearchBox/SearchBox';
import CreateBtn from '../../../ttcomponents/Button/CreateBtn';
import PaginationA from '../../../ttcomponents/Pagination/PaginationA';
import { Supplier } from '../../../interface/Iinventory';
import SupplierContactInfo from './SupplierContactInfo';
import { SupplierContact, SupplierContactResponse } from '../../../interface/ISupplierContact';
import { HintInfo } from '../../../utils/function';
import SupplierContactForm from './supplier_contact_form';



interface Isupplier {
  supplier: Supplier | undefined
}

export default function SupplierContactIndex({ supplier }: Isupplier) {
  // 钩子函数-切换语言
  const intl = useIntl();

  //加载中状态
  const [loading, setLoading] = useState(false);

  const [SupplierData, setContactSupplierData] = useState<SupplierContact[]>([]);
  const [SupplierRowData, setFormSupplierContactData] = useState<SupplierContact>({ supplier_contact_id: 0, supplier_id: supplier?.supplier_id as number, contact_name: '', contact_email: '', contact_phone: '', contact_introduction: '', });
  const [custListCount, setCustListCount] = useState<number | null>(null);
  const [SupplierContactFormModal, { open: SupplierContactFormOpen, close: SupplierContactFormClose }] = useDisclosure(false);
  const [pagination, setPagination] = useState<IPagination>({
    search: '',
    page: 1,
    pagesize: 10,
    sortBy: '',
    sortOrder: 'asc',
    query: {
      supplier_id: supplier?.supplier_id
    }
  });
  // 接收回调返回数据，搜索内容  Receive the callback return data, search for content
  const ReceiveSearchBack = (value: string | number) => {
    setPagination(prevpagination => ({
      ...prevpagination,
      search: value,
      query: {
        supplier_id: supplier?.supplier_id
      }
    }));
  }
  /**
 * 
 * Get user information
 */
  const ajaxCurrentList = async () => {
    if (supplier) {
      setLoading(true);
      const response = await regGetSupplierContact(pagination, "GET");
      const result = response.data.data as SupplierContactResponse;
      console.log(response.data, result)
      if (response.data.code == 200 && Array.isArray(result.data)) {
        setCustListCount(result.total)
        setContactSupplierData(result.data)
      } else {
      }
      setLoading(false);
    } else {

    }
  }
  useEffect(() => {
    ajaxCurrentList();
  }, [pagination]);

  // 分页回调触发更新 分页回调触发更新
  const ReceivePaginationABack = (page: number) => {
    setPagination(prevpagination => ({
      ...prevpagination,
      page: page
    }));
  }

  const receiveNotifications = (result: boolean) => {
    SupplierContactFormClose()
    ajaxCurrentList();
  }
  const handleOnValueEdit = (value: object) => {
    SupplierContactFormOpen()
    const editedValue = value as SupplierContact;
    setFormSupplierContactData(editedValue)
  };

  const handleOnValueDel = async (id: string) => {
    const FormData = {
      supplier_contact_id: id
    }
    const response = await regSupplierContact(FormData, 'DELETE');
    HintInfo(response.data)
    if (response.data.code == 200)
      ajaxCurrentList();
  }

  const handleCallback = () => {
    SupplierContactFormOpen()
  }
  return (
    <Paper px={20} style={{ position: "relative" }}>
      <LoadingOverlay visible={loading} overlayBlur={2} />
      <Box pb={20} >
        <Group mt={10}>
          <SearchBox callBack={ReceiveSearchBack} />
          <Box h={30}>
            <CreateBtn callBack={handleCallback} />
          </Box>
        </Group>
        {SupplierData.length > 0 ? <>
          <Table fontSize="sm" mt={20}  >
            <thead>
              <tr>
                <th><Text fw={500} fz="sm">  <FormattedMessage id="supplier_contact_name" defaultMessage="Contact Name" /></Text></th>
                <th><Text fw={500} fz="sm">  <FormattedMessage id="supplier_contact_name" defaultMessage="Contact Email" /></Text></th>
                <th><Text fw={500} fz="sm"> <FormattedMessage id="supplier_contact_desc" defaultMessage="Contact Phone" /> </Text></th>
                <th><Text fw={500} fz="sm"> <FormattedMessage id="supplier_contact_desc" defaultMessage="Contact Desc" /> </Text></th>
                <th><Text fw={500} fz="sm"> <FormattedMessage id="Option" defaultMessage="Option" /></Text></th>
              </tr>
            </thead>
            <SupplierContactInfo onValueEdit={handleOnValueEdit} onValueDel={handleOnValueDel} callBack={receiveNotifications} SupplierData={SupplierData} />
          </Table>
          <PaginationA callBack={ReceivePaginationABack} total_count={custListCount as number} pagesize={pagination.pagesize} page={pagination.page} /></> : <Divider my="xl" label="Have not added contacts yet" labelPosition="center" />}

      </Box>

      <Modal size="sm" opened={SupplierContactFormModal} onClose={SupplierContactFormClose}
        zIndex={300}
        title={intl.formatMessage({ id: 'supplier_contact', defaultMessage: 'Supplier Contact' })}      >
        <Card mt={10} radius={15} color="customize">
          <SupplierContactForm SupplierRowData={SupplierRowData} callBack={receiveNotifications} />
        </Card>
      </Modal>



    </Paper>
  );
}
