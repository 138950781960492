import { FC } from 'react'

const LogColgroup: FC<{ colWidth: number[] }> = ({ colWidth }) => {
  return (
    <colgroup>
      {colWidth.map((v, i) => (
        <col key={i} width={v + '%'}></col>
      ))}
    </colgroup>
  )
}

export default LogColgroup
