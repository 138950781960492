import { Box, Code, Text, Grid, Group, LoadingOverlay, Skeleton, Switch, Dialog, Button, TextInput, Modal } from "@mantine/core"

import { useEffect, useState } from "react";
import { UserAllList } from "../../../../../interface/Iuser";
import { CorePermissonSetFormDataUpdateInterface, PermissionRoleInterface } from "../../../../../interface/Iemloyee";
import { SelectRole } from "../../../../../interface/Icommon";
import { getCostCenterSelfSelectFunction } from "../../../../../api/assignments_request";
import TableTextInputTitle from "../../../../../ttcomponents/TableTextInputTitle/TableTextInputTitle";
import WordTranslationComponent from "../../../../../utils/language_pack/words";
import { getUserGeneralFunction } from "../../../../../api/common_request";
import PermissionSet from "./role_permission";
import { ClientWarningHint, HintInfo, HintInfoCatchError } from "../../../../../utils/function";
import { requestCorePermissionRole, requestCorePermissionRoleList, requestCoreRolePermissionRole, requestCostcenterPermissionRole, requestManagePermissionRole, requestManagePermissionRoleSet } from "../../../../../api";
import UniversalButton from "../../../../../ttcomponents/Button/UniversalButton";
import ResetBtn from "../../../../../ttcomponents/Button/ResetBtn";
import { getPermissionManageDetail, getPermissionManageUserDetail, getPermissionRoleDetail } from "../../../../../api/core_request";
import { useDispatch } from "react-redux";
import { setRefreshStatus } from "../../../../../state_management/features/assignments_refresh_status/assignmentsRefreshStatusSlice";

import CostCenterPermissionForm from "./role_form";
import AdminRoleList from "./role_list";
import TemPermissionList from "./tem_permission_list";
import CoreRolePermissionForm from "./role_form";
import { IconArrowBack, IconSwitch } from "@tabler/icons-react";
import memoryUtils from "../../../../../utils/memoryUtils";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";

interface ComponentInterface {
  is_load_str?: string;
  rand: number;
  is_load_str_top?: string;
  Permission: any
}
export default function GlobalCoreRoleIndex({ Permission, is_load_str, rand }: ComponentInterface) {

  // Get translation public configuration information
  const dispatch = useDispatch();


  const word_translation = WordTranslationComponent();
  const [costCenterData, setCostCenterData] = useState<SelectRole[]>([])
  const [costTemPermissionList, setCostTemPermissionList] = useState<SelectRole[]>([])
  const [corePermissonSetFormData, setCorePermissonSetFormData] = useState<CorePermissonSetFormDataUpdateInterface[]>([])


  const [isCompleted, setIsCompleted] = useState(true);
  const [managementStatus, setManagementStatus] = useState(false);
  const [reload, setReload] = useState(1);

  const [haveUserList, setHaveUserIds] = useState<string[]>([]);
  //加载中状态
  const [loading, setLoading] = useState(false);
  const [randNumber, setRandNumber] = useState(1);
  const [selectedRole, setSelectedRole] = useState('');
  const [costCentID, setCostCenterID] = useState<string>('');
  const [identifierInfo, setIdentifierInfo] = useState<string>('');

  const [opened, { open, close }] = useDisclosure(false);
  const [formValue, setFormValue] = useState<any>()

  // =============== Effect Hook for Cost Center Data ===============
  useEffect(() => {
    // Execute the following logic when the is_load_str state equals 'costcenter_leader_permission'

    if (is_load_str === 'management_role') {
      // Increment the randNumber state by 1 to trigger re-rendering or perform other related operations
      setRandNumber(randNumber + 1);
      getCostCenter();
    }
  }, [is_load_str]);

  const getCostCenter = async () => {
    var costCenterReult = await getCostCenterSelfSelectFunction({ list: true });
    costCenterReult = [ {label:'No CostCenter',value:'0'},...costCenterReult ]
    // Call the Get Cost Center General Selection function and set the cost center data
    setCostCenterData(costCenterReult)
  };


  const roleListcallBackFunction = async (user_id = '', costCent_id = '',index='') => {
    setFormValue((prevData: any) => {
      return {
        ...prevData,
        user_ids: [],
        costCenter_id: [],
        permissions: []
      };
    });
    setSelectedRole(index)
    // if (formValue.user_ids && formValue.costCenter_id && user_id == formValue.user_ids[0] && costCent_id == formValue.costCenter_id[0]) {
    //   return false;
    // }
    
    try {
      // Specify the type of `result`
      let result: { permissions: any[], user_id: string, manage: any, role_id: string, costCenter_id: string };
      result = await getPermissionManageUserDetail({ user_id: user_id, both_set: 1, costCenter_id: costCent_id });
      if (result) {
        if (result?.permissions) {
            const updatedPermissions = result.permissions.map((permission: any) => {
              return {
                ...permission,
                permission_action: permission?.permission_action.split(",") || []
              };
            });
              setFormValue((prevData: any) => {
                return {
                  ...prevData,
                  // role_id: result.role_id,
                  user_ids: result.user_id,
                  costCenter_id:result.costCenter_id,
                  permissions: updatedPermissions
                };
              });
        }
      } else {
        // Handle the case when `result` is undefined
        setFormValue((prevData: any) => {
          return {
            ...prevData,
            permissions: []
          };
        });
      }
    } catch (e) {
      // Handle errors
    }
  };



  // =============== Form Reset Function ===============
  // Resetting the form is different from the reset button; it resets the entire form, including permissions

  const resetFunction = () => {
    setFormValue((prevData: any) => {
      return {
        ...prevData,
        permissions: []
      };
    });
  }


  // =============== Save Form Function ===============
  // Function responsible for saving form data to the backend

  const SaveFormFunction = async () => {
    var response;
    // Create a copy of the form data
    let submitData = { ...formValue };

    try {
      // Send request to save form data to the backend
      if (managementStatus) {
        // 提交角色權限模板
        submitData.role_ids = [submitData.role_id]
        delete submitData.role_id;
        delete submitData.user_ids;
        delete submitData.active;
        // Set loading state to true while awaiting response from backend
        setLoading(true);
        response = await requestManagePermissionRoleSet(submitData, 'POST');
      } else {
        if(submitData.role_id && submitData.role_id != '0'&& submitData?.costCenter_id && submitData?.costCenter_id.length == 0){
           ClientWarningHint('If you select a special user Role (admin, boss...), please select costCenter');
           return false;
        }
        
      // Set loading state to true while awaiting response from backend
        setLoading(true);
        response = await requestManagePermissionRole(submitData, 'POST');
      }
      setLoading(false);
      // Extract response data
      const result = response.data;
      // Display hint information based on the response
      HintInfo(response.data);

      // If the response code is 200 (success)
      if (result.code === 200) {
        // Dispatch action to set refresh status

        dispatch(setRefreshStatus(true));
      }
    } catch (error) {
      // Handle errors and display hint information
      HintInfoCatchError();
    } finally {
      // Additional actions to be taken after try block execution
      // setHaveUserIds([])
      // setCurrentPermissionSet([])
      // setLoading(false);
    }
  };



  // =============== Save Form Function ===============
  // Function responsible for saving form data to the backend

  const SaveTemPermissionFormFunction = async () => {
    var response;
    // Create a copy of the form data
    let submitData = { ...formValue };
    try {
        delete submitData.role_id;
        delete submitData.user_ids;
        delete submitData.active;
        response = await requestManagePermissionRoleSet(submitData, 'POST');

      // Extract response data
      const result = response.data;
      // Display hint information based on the response
      HintInfo(response.data);

      // If the response code is 200 (success)
      if (result.code === 200) {
        // Dispatch action to set refresh status
        close()
        setFormValue((prevData: any) => {
          return {
            ...prevData,
            title: ''
          };
        });
        dispatch(setRefreshStatus(true));
      }
    } catch (error) {
      // Handle errors and display hint information
      HintInfoCatchError();
    } finally {
      // Additional actions to be taken after try block execution
      // setHaveUserIds([])
      // setCurrentPermissionSet([])
      // setLoading(false);
    }
  };


  // =============== Select Permissions Component Callback Function ===============
  // Function responsible for handling callbacks from the select permissions component

  const callBackPermission = (value: CorePermissonSetFormDataUpdateInterface[]) => {
    // Update the form value with the selected permissions
    setFormValue((prevData: any) => {
      return {
        ...prevData,
        permissions: value
      };
    });
  }

  const SavePermTemplateTitle = (value:any) => {
    setFormValue((prevData: any) => {
      return {
        ...prevData,
        title: value
      };
    });
  }

  const SavePermTemplate = () => {
   
  }
  

  // =============== Set Cost Center ID from Form ===============
  // Function responsible for setting the costCenterID state with the received value from the form

  const undoFunction = () => {
    if (managementStatus) {
       setReload(reload+1)
    } else if ( !managementStatus && formValue.user_ids && formValue.costCenter_id) {
      roleListcallBackFunction(formValue.user_ids[0], formValue.costCenter_id[0])
    } else {
      setFormValue((prevData: any) => {
        return {
          ...prevData,
          permissions: [],
          costCenter_id: [],
          manage: false,
          user_ids:[]
        };
      });
    }
  };


  const SwitchFunction = () => {
    setIsCompleted(false)
    setManagementStatus(!managementStatus)
    setFormValue((prevData: any) => {
      return {
        ...prevData,
        role_id:'',
        costCenter_id: [],
        table_id:'',
        permissions:[],
        user_ids:[]
      };
    });
    setIsCompleted(true)
  };

  // Check if getIdentifierList has completed before rendering
  if (!isCompleted) {
    return (<Box h={600}> <Skeleton height={600}>
      Lorem ipsum dolor sit amet...
      {/* other content */}
    </Skeleton></Box>);
  }

  return (
    <Box key={rand}>
      <Grid columns={36} gutter="xl" pos="relative">
        <LoadingOverlay visible={loading} overlayBlur={2} />
        <Grid.Col span={22} >
          <Group>
            <TableTextInputTitle title={word_translation.permission_role} />
            <Box>
              <Group>
                {managementStatus ? <Text color="#007bff" className="cursor-pointer" fs="italic" onClick={SwitchFunction} td="underline" fz={13}>Create user permissions</Text> :
                  <Text color="#007bff" className="cursor-pointer" fs="italic" onClick={SwitchFunction} td="underline" fz={13}>Create role permissions</Text>}
              </Group>
            </Box>
          </Group>

          <Box >
            <CoreRolePermissionForm
              formValue={formValue}
              reload={reload}
              managementStatus={managementStatus}
              setCostTemPermissionList={setCostTemPermissionList}
              setFormValue={setFormValue}
              costCenterData={costCenterData} />

          </Box>
          <Box mt={10}></Box>

          <TableTextInputTitle py={5} pb={5} title={word_translation.edit} />
          <PermissionSet
            randNumber={randNumber}
            formValue={formValue}
            setFormValue={setFormValue}
            haveUserList={haveUserList}
            managementStatus={managementStatus}
            currentPermissionSet={corePermissonSetFormData}
            callBack={callBackPermission}
            costCentID={costCentID} />
        </Grid.Col>
       
        <Grid.Col span={14} >
        {managementStatus ? <TemPermissionList
            type='core'
            formValue={formValue}
            setFormValue={setFormValue}
            selectedRole={selectedRole}
            Permission={Permission}
            IdentifierInfo={identifierInfo}
            managementStatus={managementStatus}
            listApi={requestCorePermissionRoleList}
            actionApi={requestCorePermissionRole}
            callBack={()=>{}}
          /> : <AdminRoleList
                type='core'
                formValue={formValue}
                selectedRole={selectedRole}
                Permission={Permission}
                IdentifierInfo={identifierInfo}
                managementStatus={managementStatus}
                listApi={requestCorePermissionRoleList}
                actionApi={requestCorePermissionRole}
                callBack={roleListcallBackFunction}
              />}

{/* <Code block >
 {JSON.stringify(formValue,null,2)}
          </Code> */}
          <Group position="right" mt={10}>
               {!managementStatus &&   
            <>
             <Modal opened={opened} onClose={close} title="Add Info">
              <TableTextInputTitle title={word_translation.role_name_template} />
                  <TextInput
                    mb={10}
                    placeholder={word_translation.Input}
                    defaultValue={formValue?.title}
                    onChange={(value) => SavePermTemplateTitle(value.target.value)}
                  />
               <UniversalButton btn_bg_color="green" btn_text={word_translation.submit} callBack={SaveTemPermissionFormFunction} />
            </Modal>

            <Group position="center">
              <Button size="xs" onClick={open}>Save Perm Template</Button>
            </Group>

           
            </>
          } 
          
          <UniversalButton
            btn_icon={<IconArrowBack size={14} />}
            btn_bg_color="blue"
            btn_text={word_translation.undo}
            callBack={undoFunction}
          />
            <ResetBtn callBack={resetFunction} />
            <UniversalButton btn_bg_color="green" btn_text={word_translation.submit} callBack={SaveFormFunction} />
          </Group>
      
        </Grid.Col>
      </Grid>
      <Group position="center">
     
    </Group>
    </Box>
  )
}