import { Box, Modal } from "@mantine/core";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { CSSProperties, useEffect, useState } from "react";
import { regGetVationComment } from "../../../api";
import { ClientWarningHint } from "../../../utils/function";
import WordTranslationComponent from "../../../utils/language_pack/words";
import { get } from "http";
import { getPublicHolidayListGeneralFunction } from "../../../api/core_request";
import LeaveInfo from "../common/preview";
import { Vacation } from "../../../interface/Ivaction";
import { useDisclosure } from "@mantine/hooks";

const localizer = momentLocalizer(moment);

interface ComponentInterface {
    events:any[];
    callBack:(year:string)=>void
}
interface RequestData {
    year: string;
    month?: string; 
    week?: string; 
  }
export default function EmployeeCalendar({events,callBack}:ComponentInterface){
  const [CurrentDatas, setVactionCaledarSelect] = useState<any[]>([]);
  const [publicDays, setPublicDays] = useState<string[]>([]);
  const [randNumber, setRandNumber] = useState<number>(1);
  const [year, setYear] = useState('');
  
  const [openvacationInfoStatus, { open: openvacationInfo, close: closevacationInfo }] = useDisclosure(false);
  
 // Get translation public configuration information
 const word_translation = WordTranslationComponent();
 useEffect(()=>{
    setVactionCaledarSelect(events)
    
    setRandNumber(randNumber+1)
 },[events])
  



 useEffect(()=>{
    const parsedDate = moment(new Date());
    setYear(parsedDate.format('YYYY')) ;
    getPublicHoliday('')
 },[])
  


 const getPublicHoliday = async (where:any|null) => {
    setPublicDays(await getPublicHolidayListGeneralFunction(where));
    // const ApprovalResult = await regGetVation(row.id_vacation);
  };
 
  const [vacation, setVacation] = useState<Vacation>();

  
  // 使用dayPropGetter设置每个日期的样式
  const dayPropGetter = (date: moment.MomentInput) => {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    const isSpecialDate = publicDays.includes(formattedDate);
    const backgroundColor = isSpecialDate ? '#fff9db' : undefined;

    return {
      style: { backgroundColor },
    };
  };

  const handleEventClick = async (event: any) => {
     
    // setVacation(event);
    // openvacationInfo();
    // const ApprovalResult = await regGetVationComment({ leave_id : event.id });
    // if (ApprovalResult.data.code == 200) {
    //   // setVacationDetailed(ApprovalResult.data.data);
    //   // vacationDetailedOpened()
    // } else {
    //   ClientWarningHint(word_translation.failed_to_get_data);
    // }
  };

  const handleSelectSlot = async (slotInfo: any) => {
    console.log("选择的时间段信息:", slotInfo);
    // const ApprovalResult = await regGetVation(row.id_vacation);
  };


  function eventStyleGetter(
    event: {
      title: string;
      id: string;
      time: string;
      time_project_id: string;
      start: Date;
      end: Date;
      leave_category_id:string;
      status_id: string;
      color: string;
    },
    start: Date,
    end: Date,
    isSelected: boolean
  ): { className?: string | undefined; style?: CSSProperties | undefined } {
    // Define the mapping relationship between status_id and color

    const statusColorMap: Record<string, string> = {
      // Add mapping here
      "1": "var(--orange-6, #FD7E14)",
      "2": "var(--green-6, #40C057)",
      "3": "red",
      "4": "red",
      "5": "red",
      // ...
    };
    //Look up the color in the mapping table according to status_id, if not found, use the default color
    const eventColor = statusColorMap[event.status_id] ? event.leave_category_id == '2' ?'#c1c2c5' : statusColorMap[event.status_id] :  "blue";
    
    const style = {
      backgroundColor: event.color || eventColor, //Use event color property or color from mapping
    };

    return {
      style,
    };
  }

  
  const handleNavigate = (date: any, view: string) => {
    console.log('view', view);
    const parsedDate = moment(date);
    const current_year = parsedDate.format('YYYY');
    if(year != current_year){
            // 发起请求
        getPublicHoliday({year:current_year})
        callBack(current_year)
        
    }
    setYear(current_year)
  };

    return(
        <Box>
             <Calendar
              className="calendar"
              localizer={localizer}
              events={CurrentDatas}
              startAccessor="start"
              dayPropGetter={dayPropGetter}
              endAccessor="end"
              selectable={true}
              onNavigate={handleNavigate}
              onSelectEvent={handleEventClick} // 添加事件点击回调函数
              onSelectSlot={handleSelectSlot}
              eventPropGetter={eventStyleGetter} // 设置事件样式
              style={{ height: "430px", fontSize: "14px" }}
            />    
             
      <Modal
        radius="md"
        size="xl"
        opened={openvacationInfoStatus}
        onClose={closevacationInfo}
        closeOnClickOutside={false}
        title={word_translation.preview}
      >
        <LeaveInfo row={vacation} />
      </Modal>
        </Box>
    )
}
