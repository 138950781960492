import { FormattedMessage } from "react-intl";
import { formatDateWithIOS, getWeekDates } from "../../../../../utils/function";
import WordTranslationComponent from "../../../../../utils/language_pack/words";


// Define the props interface for the Component
interface ChildComponentProps {
    publicDay: string[];
    vactionDay: string[];
    halfDay: string[];
    sickDay: string[];
    
    halPublicDay: string[];
    currentDate: Date;
}


// Component representing the SheetTableThead
const SheetTableThead: React.FC<ChildComponentProps> = ({ publicDay,sickDay, vactionDay, halfDay, halPublicDay, currentDate }) => {
    // Get translation public configuration information
    const word_translation = WordTranslationComponent();

    const weekDayInfo = getWeekDates(currentDate);
    const titleRowstd = (title: string, index: number) => {
        let active = "";
        if (
            (halPublicDay.includes(formatDateWithIOS(weekDayInfo[index - 1])) ||
                halfDay.includes(formatDateWithIOS(weekDayInfo[index - 1]))) &&
            (publicDay.includes(formatDateWithIOS(weekDayInfo[index - 1])) ||
                vactionDay.includes(formatDateWithIOS(weekDayInfo[index - 1])))
        ) {
            active = "one_day_holiday";
        }
        return (
            <th  id="table-th-title-new"
                className={`scrolleder table-th-title-new  ${publicDay.includes(formatDateWithIOS(weekDayInfo[index - 1]))
                        ? "public-holiday"
                        : halPublicDay.includes(formatDateWithIOS(weekDayInfo[index - 1]))
                            ? "halfday-holiday"
                            : vactionDay.includes(formatDateWithIOS(weekDayInfo[index - 1]))
                                ? "one_day_holiday"
                                : halfDay.includes(formatDateWithIOS(weekDayInfo[index - 1]))
                                    ? "halfday-holiday"
                                    : sickDay?.includes(formatDateWithIOS(weekDayInfo[index - 1]))
                                    ? "sick_leave"
                                    : ""
                    }  ${active}`}
            >
                <FormattedMessage id={title} defaultMessage={title} />
            </th>
        );
    };

    return (
        <thead className="table_th scrolleder">
            <tr>
                <th id="table-th-title-new">{word_translation.info}</th>
                <th id="table-th-title-new">&nbsp;</th>
                <th id="table-th-title-new">&nbsp;</th>
                <th id="table-th-title-new" className="c_9BC6FF" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{word_translation.total_time}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                {titleRowstd("monday", 1)}
                {titleRowstd("tuesday", 2)}
                {titleRowstd("wednesday", 3)}
                {titleRowstd("thursday", 4)}
                {titleRowstd("friday", 5)}
                <th id="table-th-title-new"  className={`table-th-title-new rest `}>
                    {word_translation.saturday}
                </th>
                <th id="table-th-title-new"  className={`table-th-title-newr rest ${publicDay.includes(formatDateWithIOS(weekDayInfo[6])) ? "public-holiday"
                            : ""
                        } `}
                >
                    {word_translation.sunday}
                </th>
                <th id="table-th-title-new">{word_translation.option}</th>
            </tr>
        </thead>
    );
};

export default SheetTableThead;
