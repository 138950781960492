//

import { Group } from "@mantine/core";
import WordTranslationComponent from "../../../../utils/language_pack/words";
import { PermissionActionInterface } from "../../../../interface/Iemloyee";
import EditIcon from "../../../../ttcomponents/EditIcon/EditIcon";
import DeleteIcon from "../../../../ttcomponents/DeleteIcon/DeleteIcon";
import ActiveBadge from "../../../../ttcomponents/ActiveBadge/ActiveBadge";


interface ComponentInterface {
  currentItem: PermissionActionInterface;
  editHandle: (value: PermissionActionInterface) => void;
  delHandle: (value: PermissionActionInterface) => void;
}

export default function PermissionActionTableTr({  currentItem,editHandle,delHandle}: ComponentInterface) {

  // Get the information to be modified and pass to the parent -level component
  return (
    <tr  className="border-bt-2 my-10 ">
      <td> {currentItem.id_permission}</td>
      <td> {currentItem.title}</td>
      <td> {currentItem.identifier}</td>
      <td> {currentItem.description}</td>
      <td> {currentItem.costCenter_name}</td>
      <td><ActiveBadge value={currentItem.active} /></td>
      <td> {currentItem.created_at}</td>
      <td>
        <Group position="center">
            <EditIcon callBack={() => editHandle(currentItem)}/>
            <DeleteIcon callBack={() => delHandle(currentItem)}/>
        </Group>
      </td>
    </tr>
  )
}