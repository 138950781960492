import { Box, Select, TextInput } from "@mantine/core";
import SelectItem from "./order_product_select";
import WordTranslationComponent from "../../../../../utils/language_pack/words";
import { useEffect, useState } from "react";
import { ProductOrder, Purchase } from "../../../../../public/svg/ProductOrder";
import { ItemProps, Product, ProductInterface } from "../../../../../interface/IProduct";
import { regGetProduct, regProduct } from "../../../../../api";
import { getProductsDetailGeneralFunction } from "../../../../../api/inventory_request";



interface OrderProductFormProps {
  is_loading?: boolean;
  is_show?: boolean;
  current_value?: string;
  rand_number?: number;
  product_name?: string;
  selectProductCallback: (value: any) => void;
  product_data?: ItemProps[]
}

export default function OrderProductForm({ is_loading, is_show,rand_number, current_value, product_name, product_data, selectProductCallback }: OrderProductFormProps) {
  // Get translation public configuration information
  const word_translation = WordTranslationComponent();

  const [data, setData] = useState<ItemProps[]>([]);
  const [productDetail, setProductDetail] = useState<ProductInterface>();

  const [loading, setLoading] = useState(false);

  // Request products from the warehouse
  const getProductList = async () => {
    // Check if already loading
    if (is_loading) {
      setLoading(true);
      // Make API call to get product data
      const responseProduct = await regGetProduct({});
      setLoading(false);

      // Check if the API call was successful
      if (responseProduct.data.code === 200) {
        // Assemble product data
        const products = responseProduct.data.data.data.map((item: Product) => ({
          image: <ProductOrder />,
          label: item.product_name + "---" + item.supplier_name,
          value: item.product_id,
          type: item.type,
          unit_price:
            item.unit_price != null
              ? word_translation.unit_price + ": " + item.unit_price
              : word_translation.product_not_available,
        }));
        setData(products);
      }
    }
  };

  // Fetch product data on component mount
  useEffect(() => {
    getProductList();
  }, []);

  // Update product data when product_data changes
  useEffect(() => {
    // Check if product_data is available
    if (product_data) {
      // Use the provided product_data
      setData(product_data);
    } else {
      // If product_data is not available, fetch it
      getProductList();
    }
  }, [product_data]);




  /**
   * Function for selecting a product. When the selected product is a number,
   * it needs to fetch the product details. If currentData is an empty array, it
   * means there are no existing product records. In this case, add a new pending
   * product to the order area. If there are products, add the selected product
   * to the order area.
   * 
   * @param {string|number} value - The value representing the selected product.
   */
  const setProductNameFunction = async (value: string | number) => {
    // Check if the value is a string and a valid number
    if (typeof value === "string" && !isNaN(value as unknown as number)) {
      // TODO: Fetch product details by product ID
      const currentData = await getProductsDetailGeneralFunction({ product_id: value });

      // Log the type and length of the currentData array
      console.log(typeof currentData, currentData.length);

      // Check if there are no existing product records
      if (currentData.length === 0) {
        console.log('New Product');
        // Add a new pending product to the order area
        selectProductCallback(value);
      } else {
        // Set the product details
        setProductDetail(currentData);
        // Add the selected product to the order area
        selectProductCallback(currentData);
      }
    } else {
      // If the value is not a string representing a number, simply select the product
      selectProductCallback(value);
    }
  };


  console.log('defaultValue', product_data, current_value, typeof current_value)
  return (
    <>
      
      {(current_value && product_name || is_show == false) ? (<Select
        placeholder={word_translation.product_name}
        itemComponent={SelectItem}
        data={data as []}
        searchable
        key={rand_number}
        creatable
        size="xs" 
        defaultValue={current_value}
        maxDropdownHeight={400}
        onChange={(event) =>
          setProductNameFunction(event as string)
        }
        nothingFound={word_translation.no_data}
        getCreateLabel={(query) => `+ Create New ${query}`}
        onCreate={(query) => {
          const item = {
            image: <Purchase />,
            label: query,
            value: query,
            unit_price: word_translation.warehouse_needs_purchasing,
          };
          setData((current) => [...(current as any), item]);
          return item;
        }}
        filter={(value, item) =>
          (item.label &&
            item.label
              .toLowerCase()
              .includes(value.toLowerCase().trim())) ||
          item.unit_price
            .toLowerCase()
            .includes(value.toLowerCase().trim())
        }
      />) : 
      <TextInput
          className="border-custome"
          placeholder={word_translation.project_no}
          value={product_name}
          disabled
      />
      }

    </>
  )
}