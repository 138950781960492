import { ActionIcon, Box, Button, Group, Select, TextInput } from "@mantine/core";
import { IconSearch, IconX } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { regGetSupplier } from "../../api";
import { Supplier, SuppliersData } from "../../interface/Iinventory";
import { SelectRole } from "../../interface/Icommon";

// 定义接口，接收一个函数操作 Define the interface and receive a function operation
interface SupplierSelectBoxProps {
    callBack:(row:any)=>void;
    currentId?:string;
    width?:number|string;
    prohibit?:boolean;
    type?:boolean;
    labelStr?:string
}
export default function SupplierSelect({ callBack ,type,currentId,labelStr,prohibit,width}: SupplierSelectBoxProps) {
    // 钩子函数-切换语言
    const intl = useIntl();

    const [rand, setRand] = useState(0);

    const [dataList, setListData] = useState<SelectRole[]>([])
    // 声明搜索内容 State the search content
    useEffect(() => {
        setRand(rand+1)
      }, [currentId])
  
  const ajaxCurrentList = async () => {
    const responseSupplier = await regGetSupplier({}, 'GET');
    if (responseSupplier.data.code === 200) {

        const transferData = responseSupplier.data.data.data.map((item:Supplier) => {
            return {
              value: item.supplier_id,
              label: item.supplier_name,
              type: 'supplier',
            }
          })
          setListData(transferData)
    }
  };
console.log(prohibit,'supplier')
  function setSelectValueFunction(value: string | null): void {
    const row =  dataList.filter((item,index)=>{return item.value == value })
    callBack(row[0])
    }
    // Clear conditions
  function  clearConditionFunction(): void {
    setRand(rand+1)
    callBack( {
        value: '',
        label:'',
        type: 'supplier',
      })
    }
  // Page initialization function
  useEffect(() => {
    ajaxCurrentList();
  }, [])

    return (
            <Select   
                label={labelStr?labelStr:null}
                rightSection={  <ActionIcon onClick={() => clearConditionFunction()}> <IconX />  </ActionIcon>}   
                key={rand} 
                defaultValue={currentId}  
                searchable 
                disabled={prohibit }
                onChange={setSelectValueFunction} 
                data={dataList} />
     
    )
}