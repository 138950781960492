import React, { useState, useRef } from 'react';

const Timer = () => {
  return (
    <div>
    123
    </div>
  );
};

export default Timer;
