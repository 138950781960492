import { useEffect, useState } from "react";
import { Group, Text, ActionIcon, Tooltip, Box } from "@mantine/core";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { modals } from "@mantine/modals";
import { FormattedMessage } from "react-intl";
import WordTranslationComponent from "../../utils/language_pack/words";
import { IcostCenter } from "../../interface/ICostCenter";
import GlobalCoreSetting from "../../utils/globalCortSetting";
import CheckPermissionTools from "../../utils/permission";
import EditIcon from "../EditIcon/EditIcon";
import DeleteIcon from "../DeleteIcon/DeleteIcon";

// Props interface for the TableCostCenter component
interface TableSortProps {
  data: IcostCenter[]; // Array of cost center data
  onValueEdit: (value: IcostCenter) => void; // Callback function for edit action
  onValueDel: (id: string) => void; // Callback function for delete action
}

// TableCostCenter component definition
export default function TableCostCenter({
  data,
  onValueEdit,
  onValueDel,
}: TableSortProps) {
  // Get translation public configuration information
  const word_translation = WordTranslationComponent();

  const Perm = CheckPermissionTools();
  // Get the global configuration
  const globalCoreSetting = GlobalCoreSetting();

  // Use state to manage sorted data
  const [sortedData, setSortedData] = useState(data);

  // Update sortedData when data prop changes
  useEffect(() => {
    setSortedData(data);
    // Additional logic can be added if needed
  }, [data]);

  // Delete action handler with confirmation modal
  const delHandle = (row: IcostCenter) => {
    modals.openConfirmModal({
      title: word_translation.delete_title,
      centered: true,
      children: (
        <Text size="sm" mt={12}>
          <p> {word_translation.cost_center_name} : {row.name}</p>
        </Text>
      ),
      labels: globalCoreSetting.DeleteOpenConfirmModaLabels,
      confirmProps: globalCoreSetting.DeleteOpenConfirmModaConfirmProps,
      onConfirm: () => {
        onValueDel(row.id_costcenter);
      },
    });
  };

  // Edit action handler
  const editHandle = (row: IcostCenter) => {
    onValueEdit(row);
  };

  // Map the data to table rows
  const rows =
    sortedData.length > 0 &&
    sortedData.map((item: IcostCenter, index: any | null | undefined) => (
      <tr className="text-center" key={index}>
        <td>{item.name}</td>
        <td>{item.street1}</td>
        <td>{item.street2}</td>
        <td>{item.city}</td>
        <td>{item.postalCode}</td>
        <td>
          <Group spacing={0} position="center">
            {/* Edit action icon */}
            <EditIcon disabled={Perm.cost_center_update == false} callBack={Perm.cost_center_update?() => editHandle(item) :()=>{}}/>
            <DeleteIcon disabled={Perm.cost_center_delete == false} callBack={Perm.cost_center_delete?() => delHandle(item) :()=>{}}/>
          </Group>
        </td>
      </tr>
    ));

  // Render the table
  return (
    <>
      <Box className=" pt-xs ">
        <table className="table_custome text-center">
          {/* Table header */}
          <thead className="table_th">
            <tr>
              <th id="table-th-title-new">{word_translation.cost_center_name}</th>
              <th id="table-th-title-new">{word_translation.street1}</th>
              <th id="table-th-title-new">{word_translation.street2}</th>
              <th id="table-th-title-new">{word_translation.city}</th>
              <th id="table-th-title-new">{word_translation.company_postal_code}</th>
              <th id="table-th-title-new">{word_translation.option}</th>
            </tr>
          </thead>
          {/* Table body */}
          <tbody>{rows} </tbody>
        </table>
      </Box>
    </>
  );
}
