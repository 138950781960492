import React, { useState, useRef, useEffect } from 'react';
import { Flex, Title, ThemeIcon } from '@mantine/core';
import {
  IconRefreshAlert,
  IconPlayerStop,
  IconPlayerPlay,
  IconPlayerPause,
  IconX,
} from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import localstorageUnits from '../../utils/localstorageUnits';

interface ITimerProps {
  callback: (value: string) => void;
}
const Timer = ({ callback }: ITimerProps) => {
  // 开始时间时间戳
  const [currentTimestamp, SetcurrentTimestamp] = useState(0);
  // 计时器时间
  const [time, setTime] = useState(0);
  // 计时器是否在运行
  const [isRunning, setIsRunning] = useState(false);
  // 计时器的引用
  const intervalRef = useRef<number | null>(null);

  const formatTime = (time: number) => {
    // 计算小时数
    const hours = Math.floor(time / 3600).toString().padStart(2, '0');
    // 计算分钟数
    const minutes = Math.floor((time % 3600) / 60).toString().padStart(2, '0');
    // 计算秒数
    const seconds = (time % 60).toString().padStart(2, '0');
    // 返回格式化后的时间字符串
    return `${hours}:${minutes}:${seconds}`;
  };

  useEffect(()=>{
    let TimeTracker = localstorageUnits.getTimeTracker();

    console.log(TimeTracker)
    if (TimeTracker && Object.keys(TimeTracker).length > 0) {

      const currentTimestamp = TimeTracker.time as string;
      const currentTime = Math.floor(Date.now() / 1000);
      const difference =currentTime -  parseInt(currentTimestamp, 10);
      // console.log(difference);
      if(TimeTracker.isRunning == true)
      {
        setTime(difference)
        startTimer()
      }else{
        setTime(TimeTracker.stoptime)
      }
    }
  },[])
  
  const startTimer = () => {
    let storagePath = localstorageUnits.getTimeTracker();
    if (storagePath && Object.keys(storagePath).length == 0) {
      const currentTimestamp = Math.floor(Date.now() / 1000);
      localstorageUnits.setTimeTracker({time:currentTimestamp,isRunning:true});
    }else{
      if (storagePath && Object.keys(storagePath).length > 0) {
        localstorageUnits.setTimeTracker({time:  storagePath.time,isRunning:true});
      }
    }

    if (!isRunning) {
      intervalRef.current = window.setInterval(() => {
        // 每秒递增时间
        setTime((prevTime) => prevTime + 1);
      }, 1000);
      // 设置计时器为运行状态
      setIsRunning(true);
    }
  };
  
  const stopTimer = () => {
    if (isRunning) {
      // 清除计时器
      window.clearInterval(intervalRef.current!);
      // 设置计时器为停止状态
      setIsRunning(false);
      let storagePath = localstorageUnits.getTimeTracker();
      if (storagePath && Object.keys(storagePath).length > 0) {
        localstorageUnits.setTimeTracker({time:  storagePath.time,isRunning:false,stoptime:time});
      }
    }
  };
  //  重置计时器
  const resetTimer = () => {
    localstorageUnits.removeTimeTracker();
    stopTimer();
    callback('');
    setTime(0);
  };

  //   开始计时器
  const commitTimer = () => {
    stopTimer();
    localstorageUnits.removeTimeTracker();
    callback(formatTime(time))
    setTime(0);
  };

  return (
    <div>
      <Flex mt={10} direction="column">
        {/* 显示格式化后的时间 */}
        <Flex>
          <Title order={3}>{formatTime(time)}</Title>
        </Flex>
        {/* 计时器控制按钮 */}
        <Flex mt={10}>
          {/* 清零按钮 */}
          <ThemeIcon className='cursor-pointer' size="md" p={2} color="customize-gray.7">
            <IconRefreshAlert onClick={resetTimer} />
          </ThemeIcon>
          {/* 停止按钮 */}
          <ThemeIcon className='cursor-pointer' ml={20} size="md" p={2} color="red" onClick={commitTimer}>
            <IconPlayerStop />
          </ThemeIcon>
          {/* 开始按钮 */}

          {isRunning ? <ThemeIcon className='cursor-pointer' ml={3} size="md" p={2} color="blue" onClick={stopTimer}>
          <IconPlayerPause />
          </ThemeIcon> : <ThemeIcon className='cursor-pointer' ml={3} size="md" p={2} color="blue" onClick={startTimer}>
           <IconPlayerPlay />
          </ThemeIcon>}

        </Flex>
      </Flex>
    </div>
  );
};

export default Timer;
