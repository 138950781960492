import { ActionIcon, Box, Button, Card, Drawer, Group, Select, Table, Text, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useDisclosure } from '@mantine/hooks'
import { IconCheck, IconEdit, IconPencil, IconTrack, IconTrash, IconX } from '@tabler/icons-react'
import { FormEvent, useEffect, useState } from 'react'
import { regCustomerRole, regCustomerRoleContact, regGeCustome } from '../../../api'
import { notifications } from '@mantine/notifications'
import { modals } from '@mantine/modals'
import { FormattedMessage, useIntl } from 'react-intl'
import EditIconPerm from '../../../ttcomponents/EditIcon/EditIconPerm'
import DeleteIconPerm from '../../../ttcomponents/DeleteIcon/DeleteIconPerm'
import WordTranslationComponent from '../../../utils/language_pack/words'
import CheckPermissionTools from '../../../utils/permission'

//定义接口 Definition interface ContactProps
interface ContactProps {
  role_name: string
  role_desc: string
  customer_contact_person_role_id: string | number
  customer_id: string | number
  contact_name: string
  contact_email: string
  contact_tel: string
  selection_id: string | number
}
interface CustomerData {
  id_customer: string | number
  company_name: string
  street: string
  postalcode: string
  city: string
  tel: string
  website: string
  email: string
  customer_roles: ContactProps[]
}

// 定义接口组 Definition interface group ContactRoleData
interface ContactRoleData {
  datacontcatList: ContactProps[]
  refreshCustomerList: (customer_id: string, type: 'editCustomer' | 'addContactName' | 'openCustomer') => void
}

interface Role {
  id_customer_contact_person_role: string
  role_name: string
  role_desc: string
}
interface SelectRole {
  value: string
  label: string
}

interface ApiResponse {
  code: number
  data: {
    data: Role[]
    filtered_count: number
    total_count: number
  }
  msg: string
}

export default function Contact({ datacontcatList, refreshCustomerList }: ContactRoleData) {
  // 钩子函数-切换语言
  const intl = useIntl()

  const Perm = CheckPermissionTools()

  const [openmodal1, { open: openedModal1, close: closeModal1 }] = useDisclosure(false)
  const [searchValue, onSearchChange] = useState('')
  const [dataList, setData] = useState<ContactProps[]>(datacontcatList)
  // 使用useState定义data和setData并初始化为Role[]类型
  const [dataSelect, setSelectData] = useState<SelectRole[]>([])
  const ajaxCurrentList = async () => {
    const response = await regCustomerRole({}, 'GET')
    const result = response.data as ApiResponse
    console.log(result)
    if (result.code === 200 && !Array.isArray(result.data)) {
      const datas = result.data.data.map(item => ({
        value: item.id_customer_contact_person_role,
        label: item.role_name
      }))
      setSelectData(datas)
    } else {
      console.log(result.msg)
    }
  }

  const refreshContact = async (value: number | string) => {
    // 方案一：取出当前的customerRollesData的值，删除其中的已经删除的id所属条目，达到刷新的效果：
    // setCustomerRollesData(customerRollesData.filter(item => item.selection_id !== value));
    // 方案二：请求当顾客的id,请求刷新到达效果
    const response = await regGeCustome(value)
    const result = response.data.data as CustomerData
    if (result) {
      setData(result.customer_roles)
    } else {
      console.log(result)
    }
  }

  const form = useForm({
    initialValues: {
      contact_name: '',
      contact_tel: '',
      id: '',
      contact_email: '',
      customer_contact_person_role_id: '',
      customer_id: ''
    }
  })

  const editCustomer = (value: object) => {
    ajaxCurrentList()
    const editData = value as ContactProps
    form.setFieldValue('contact_name', editData.contact_name)
    form.setFieldValue('contact_email', editData.contact_email)
    form.setFieldValue('customer_contact_person_role_id', editData.customer_contact_person_role_id as string)
    form.setFieldValue('contact_tel', editData.contact_tel)
    form.setFieldValue('customer_id', editData.customer_id as string)
    form.setFieldValue('id', editData.selection_id as string)
    onSearchChange(editData.customer_contact_person_role_id as string)
    openedModal1()
  }

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault() // Stop the formal submission of the form default
    // Verification form
    const valid = await form.validate()
    if (!valid.hasErrors) {
      const response = await regCustomerRoleContact(form.values, 'PUT')
      const result = response.data
      if (result.code === 200) {
        refreshContact(form.values.customer_id)
        refreshCustomerList(form.values.customer_id, 'openCustomer')
        closeModal1()
        notifications.show({
          color: 'green',
          icon: <IconCheck />,
          message: result.msg
        })
      } else {
        //Login failed
        console.log(result)
        notifications.show({
          color: 'red',
          icon: <IconX />,
          message: result.msg
        })
      }
    }
  }
  const delHandle = (value: object) => {
    const delValue = value as {
      selection_id: string
      contact_email: string
      customer_id: string
    }
    modals.openConfirmModal({
      title: <FormattedMessage id="delete_confirm" defaultMessage="Delete Confirmation" />,
      centered: true,
      children: (
        <Text size="sm">
          <FormattedMessage id="contact_email" defaultMessage="Contact Email" />: {delValue.contact_email}
        </Text>
      ),
      labels: {
        confirm: <FormattedMessage id="confirm_delete" defaultMessage="Confirm Delete" />,
        cancel: <FormattedMessage id="cancel_delete" defaultMessage="Cancel Delete" />
      },
      confirmProps: { color: 'red' },
      onConfirm: async () => {
        const FormData = {
          id: delValue.selection_id
        }
        const response = await regCustomerRoleContact(FormData, 'DELETE')
        const result = response.data
        if (result.code === 200) {
          refreshContact(delValue.customer_id)
          refreshCustomerList(delValue.customer_id, 'openCustomer')
          notifications.show({
            color: 'green',
            icon: <IconCheck />,
            message: result.msg
          })
        } else {
          notifications.show({
            color: 'red',
            icon: <IconX />,
            message: result.msg
          })
        }
      }
    })
  }

  const rows = dataList ? (
    dataList.map((customer, index) => (
      <tr key={index}>
        <td>{customer.contact_name}</td>
        <td>{customer.role_name}</td>
        <td>{customer.contact_tel}</td>
        <td>{customer.contact_email}</td>
        <td>{customer.role_desc}</td>
        {/* <td>{customer.customer_id}</td>
      <td>{customer.customer_contact_person_role_id}</td> */}
        <td>
          <Group spacing={0} position="left">
            <EditIconPerm
              enable={Perm.contact_person_update}
              callBack={() => editCustomer(customer) }
            />
            <DeleteIconPerm enable={Perm.contact_person_delete} callBack={() => delHandle(customer)} />
          </Group>
        </td>
      </tr>
    ))
  ) : (
    <tr>{<FormattedMessage id="No Data" defaultMessage="No Data" />}</tr>
  )

  return (
    <>
      {dataList ? (
        dataList.length > 0 ? (
          <Table fontSize="sm" mt={10}>
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="contact_name" defaultMessage="Contact Name" />
                </th>
                <th>
                  <FormattedMessage id="contact_role_name" defaultMessage="Role Name" />
                </th>
                <th>
                  <FormattedMessage id="contact_tel" defaultMessage="Contact Tel" />
                </th>
                <th>
                  <FormattedMessage id="contact_email" defaultMessage="Contact Email" />
                </th>
                <th>
                  <FormattedMessage id="contact_role_desc" defaultMessage="Role Desc" />
                </th>
                {/* <th>Customer ID</th>
          <th>Customer Roles ID</th> */}
                <th>
                  <FormattedMessage id="Option" defaultMessage="Option" />
                </th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
        ) : (
          <Box>{<FormattedMessage id="No Data" defaultMessage="No Data" />}</Box>
        )
      ) : (
        <Box className="text-center">{<FormattedMessage id="No Data" defaultMessage="No Data" />}</Box>
      )}

      <Drawer
        opened={openmodal1}
        position="right"
        zIndex={201}
        overlayProps={{ opacity: 0.4 }}
        onClose={closeModal1}
        title={intl.formatMessage({
          id: 'contact_manage',
          defaultMessage: 'Contact Manage'
        })}
      >
        <Box>
          <Card p={20} className="border-dotted" radius={15} color="customize">
            <form onSubmit={handleFormSubmit}>
              <Box>
                <TextInput
                  placeholder={intl.formatMessage({
                    id: 'input',
                    defaultMessage: 'Input'
                  })}
                  label={<FormattedMessage id="contact_name" defaultMessage="Contact Name" />}
                  withAsterisk
                  sx={{ flex: 1 }}
                  {...form.getInputProps('contact_name')}
                />
                <TextInput
                  placeholder={intl.formatMessage({
                    id: 'input',
                    defaultMessage: 'Input'
                  })}
                  label={<FormattedMessage id="contact_email" defaultMessage="Contact Email" />}
                  sx={{ flex: 1 }}
                  {...form.getInputProps('contact_email')}
                />
                <TextInput
                  placeholder={intl.formatMessage({
                    id: 'input',
                    defaultMessage: 'Input'
                  })}
                  label={<FormattedMessage id="contact_tel" defaultMessage="Contact Tel" />}
                  sx={{ flex: 1 }}
                  {...form.getInputProps('contact_tel')}
                />

                <Select
                  placeholder={intl.formatMessage({
                    id: 'select_role_style',
                    defaultMessage: 'Select Role Style'
                  })}
                  label={<FormattedMessage id="contact_role" defaultMessage="Contact Role" />}
                  searchValue={searchValue}
                  onSearchChange={onSearchChange}
                  defaultValue={searchValue}
                  onChange={value => form.setFieldValue('customer_contact_person_role_id', value as string)}
                  data={dataSelect}
                />

                <Button variant="outline" fw={200} type="submit" my={10}>
                  <FormattedMessage id="Save" defaultMessage="Save" />
                </Button>
              </Box>
            </form>
          </Card>
        </Box>
      </Drawer>
    </>
  )
}
