import { Box, Button, Card, LoadingOverlay, Grid, Textarea, Group, Title, Select, Text, TextInput, TransferListData, Table, Avatar, ActionIcon } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { hasLength, isNotEmpty, useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { IconCalendar, IconCheck, IconDots, IconMoneybag, IconPlus, IconTrash, IconX } from "@tabler/icons-react";
import { FormEvent, useEffect, useState } from "react";
import { regTaskOnlyCategory } from "../../api";
import { FormattedMessage, useIntl } from "react-intl";

interface IFromProps {
    callBack: () => void;
    [key: string]: any
}

export default function CustomerCategoryAddFrom({ callBack }: IFromProps) {
    //加载中状态
    const [loading, setLoading] = useState(false);

    // 钩子函数-切换语言
    const intl = useIntl();


    // 初始化表单数据
    const form = useForm({
        initialValues: {
            delete_status: "",
            description: "",
            name: "",
        },
        validate: {
            name: (value) => value.length < 1 ? "Name is required" : null
        },
    });


    // 处理修改请求
    const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
        setLoading(false);
        event.preventDefault(); // Stop the formal submission of the form default
        // Verification form
        const valid = await form.validate();

        if (!valid.hasErrors) {
            let originalData = {
                // flag: form.values.flag,
                // time_estimate: form.values.time_estimate,
                name: form.values.name,
                description: form.values.description,
            }
            // console.log(originalData, formDataProps, "formDataProps")
            // return;
            const response = await regTaskOnlyCategory(originalData, 'POST');
            const result = response.data;
            if (result.code === 200) {
                setLoading(false);
                callBack()
                notifications.show({
                    
                    color: 'green',
                    icon: <IconCheck />,
                    message: result.msg,
                })
            } else {
                notifications.show({
                    
                    color: 'red',
                    icon: <IconX />,
                    message: result.msg,
                })
            }
        } else {
            notifications.show({
                title: 'Error',
                color: 'yellow.7',
                icon: <IconX />,
                message: Object.values(valid.errors)[0],
            })
        }
    };
    return (
        <form onSubmit={handleFormSubmit} style={{ position: "relative" }}>
            <LoadingOverlay visible={loading} overlayBlur={2} />
            <Box ml={20} mih={500}>
                <Card >
                    <Grid>
                        <Grid.Col span={12}>
                            <TextInput
                                withAsterisk
                                mt={10}
                                labelProps={{ className: "input_title" }}
                                {...form.getInputProps("name")}
                                label={<FormattedMessage id="name" defaultMessage="Name" />}
                                placeholder={intl.formatMessage({ id: 'input', defaultMessage: "Input" })}
                            />

                            <Textarea
                                // disabled={descEnable}
                                labelProps={{ className: "input_title" }}
                                placeholder={intl.formatMessage({ id: 'input', defaultMessage: "Input" })}
                                label={<FormattedMessage id="Description" defaultMessage="Description" />}
                                {...form.getInputProps("description")}
                            />


                        </Grid.Col>

                    </Grid>
                    {/* 如果存在雇员以表格形式显示 */}

                    <Button variant='outline' fw={200} type="submit" my={10}>
                        <FormattedMessage id="Save" defaultMessage="Save" />
                    </Button>
                </Card>

            </Box>

        </form>
    )
}