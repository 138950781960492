import {
  Box,
  Grid,
  Text,
  TextInput,
  Button,
  Group,
  Select,
  Radio,
  Badge,
  Flex,
  ActionIcon,
} from "@mantine/core";
import { DatePicker, MonthPicker, YearPicker } from "@mantine/dates";
import {
  IconBrackets,
  IconBrandYatse,
  IconCalendarStats,
  IconChevronDown,
  IconColorPicker,
  IconLetterW,
  IconLoader,
  IconPlus,
  IconX,
} from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { SelectRole } from "../../../interface/Icommon";
import globalCortSetting from "../../../utils/globalCortSetting";
import {
  ClientWarningHint,
  JudgmentTimeThreshold,
  compareTimeInputs,
  getWeeKAll,
  getYearAll,
} from "../../../utils/function";
import { regGeReport, regSingleFormal } from "../../../api";
import localstorageUnits from "../../../utils/localstorageUnits";
import "../css/reports_custom.css";
import { useIntl } from "react-intl";
import GlobalCoreSetting from "../../../utils/globalCortSetting";

interface ICreateReport {
  Calculation: SelectRole[];
  Templatereport: SelectRole[];
  callback: (type: string, value: any) => void;
}

export default function CreateReport({
  Calculation,
  Templatereport,
  callback,
}: ICreateReport) {
  // Use the useIntl hook to access the intl object
  const intl = useIntl();

   // Get the default unchanged configuration
   const globalCoreSetting = GlobalCoreSetting();
  // Define the type of report selection option
  const [filterType, setFilterType] = useState("team");

  // Get the initial time of the system
  const initSystemTime = localstorageUnits.getSystem().init_use_date;

  // Define the name constant of the calculator
  const [reportName, setReportName] = useState("");

  // Set the time type for selection
  const [timeType, setTimeType] = useState("range");

  // Set the calculation rules variable
  const [FormatValue, setFormatValue] = useState<string | null>(null);
  const [FormatStr, setFormatStr] = useState<string>("");

  // Get all the weekly collection
  const weekNumberAll = getWeeKAll();

  // Get all the Year collection
  const yearNumberAll = getYearAll();

  // Define and initialize a random variable
  const [rand, setRand] = useState(0);

  // Define and initialize year, start, and end variables
  const [year, setYear] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");

  // Declare a variable for multiple date selection
  const [valueMultiple, setValueMultiple] = useState<Date[]>([]);

  // Define the type in the date selection as the constant of the time range
  const [rangeValue, setRangeValue] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);

  // Select date type handler
  const selectDateType = (value: string) => {
    setTimeType(value);
    callback("year", "");
    callback("start", "");
    callback("end", "");
  };

  useEffect(() => {
    callback("date_type", timeType);
    callback("date_value", rangeValue);
  }, [timeType]);

  // Handler to set report name and update its value
  const setReportNameHandle = (value: any) => {
    callback("name", value);
    setReportName(value);
  };

  // Handler to select calculation option
  const SelectCalculation = async (value: any) => {
    callback("formal", value);
    setFormatValue(value);
    const reporItem = await regSingleFormal(value);
    setFormatStr(reporItem.data.data.formal_value);
  };

  // Handler to select template
  const SelectTemplate = (value: any) => {
    callback("template", value);
    getReportSinger(value);
  };

  // Handler to select year date
  const SelectYearDate = (value: any) => {
    callback("year", value);
    setYear(value);
  };

  // Handler to select start date
  const SelectStartDate = (value: any) => {
    callback("start", value);
    setStart(value);
  };

  // Handler to select end date
  const SelectEndDate = (value: any) => {
    callback("end", value);
    setEnd(value);
  };

  // Function to fetch report details based on selected template
  const getReportSinger = async (value: string) => {
    const reporItem = await regGeReport(value);
    setReportName(reporItem.data.data.name);
    setFormatStr(reporItem.data.data.formal_value);
    callback("name", reporItem.data.data.name);
    setFormatValue(reporItem.data.data.formal_id);
    setTimeType(reporItem.data.data.date_type);
    callback("formal", reporItem.data.data.formal_id);
    switch (reporItem.data.data.date_type) {
      case "week":
        const DateCw = reporItem.data.data.date_value.split(",");
        setEnd(DateCw[2]);
        setStart(DateCw[1]);
        setYear(DateCw[0]);
        callback("year", DateCw[2]);
        callback("start", DateCw[1]);
        callback("end", DateCw[0]);
        break;
      case "picker":
        // Handle picker date type
        break;
      default:
        let dateValue = reporItem.data.data.date_value.split(",");
        dateValue[0] = new Date(dateValue[0]);
        dateValue[1] = new Date(dateValue[1]);
        callback("date_value", dateValue);
        setRangeValue(dateValue);
        break;
    }
  };

  /**
   * Set the date value and trigger a callback.
   *
   * @param {any} value - The date value, usually an array containing start and end dates
   * @returns {boolean} - Returns whether the date value was successfully set; returns false if the start date is null
   */
  function setDateValue(value: any) {
    // Check if the start date is null
    if (value[0] == null) {
      return false;
    }
    // Execute the callback function, passing the date value to it
    callback("date_value", value);
    // Update the component's internal range value
    setRangeValue(value);
  }

  /**
   * Handle the selection of a filter type.
   *
   * @param {string} value - The selected filter type value
   * @returns {void}
   */
  const handleFilterFunction = (value: string) => {
    // Execute the callback function, passing the selected filter type value
    callback("report_type", value);
    // Update the filter type state with the selected value
    setFilterType(value);
  };
  const FormalDom = () => {
    const valuesArray = FormatStr.split(",");
    const name = valuesArray.map((value, index) => {
      if (value.includes("@@")) {
        return (
          <Badge
            key={index}
            size="xs"
            mx={5}
            className="cursor-pointer"
            color="green"
            variant="outline"
            radius="xs"
          >
            {value.replace(/@@/g, "")}
          </Badge>
        );
      } else if (globalCoreSetting.OperatorStr.includes(value)) {
        return value;
      } else {
        if (value) {
          return (
            <Badge
              key={index}
              size="xs"
              mx={5}
              className="cursor-pointer"
              variant="outline"
              radius="xs"
            >
              {value}
            </Badge>
          );
        } else {
          return <></>;
        }
      }
    });
    return name;
  };

  const clearData = (type: string) => {
    if (type == "formal") {
      setFormatValue("");
      setFormatStr("");
      callback("formal", "");
    }
  };

  return (
    <Box mih={380} className="border-creates" pl={10} pt={10}>
      <Box>
        <Group>
          <Box>
            <Text className="form-title">
              {intl.formatMessage({
                id: "Select Report Type",
                defaultMessage: "Select Report Type",
              })}
            </Text>
            <Text className="form-title-desc mb-6">
              {intl.formatMessage({
                id: "Select Report Template",
                defaultMessage: "Select Report Template",
              })}
            </Text>

            <Select
              size="xs"
              onChange={(value) => SelectTemplate(value)}
              data={Templatereport}
            />
            {/* <Button size="xs" leftIcon={<IconLoader size='1rem' />}> Load</Button> */}
          </Box>
          <Box>
            <Text className="form-title">
              {intl.formatMessage({
                id: "Report Name",
                defaultMessage: "Report Name",
              })}
            </Text>
            <Text className="form-title-desc mb-6">
              {intl.formatMessage({
                id: "Report Name",
                defaultMessage: "Report Name",
              })}
            </Text>
            <TextInput
              value={reportName}
              size="xs"
              onChange={(event) =>
                setReportNameHandle(event.currentTarget.value)
              }
            />
          </Box>
        </Group>
      </Box>
      <Box mt={2}>
        <Flex className="mt-17 ">
          <Box>
            <Text className="form-title">
              {intl.formatMessage({
                id: "Date Selection",
                defaultMessage: "Date Selection",
              })}
            </Text>
            <Text className="form-title-desc mb-6">
              {intl.formatMessage({
                id: "Select Date Range",
                defaultMessage: "Select Date Range",
              })}
            </Text>

            {globalCoreSetting.ReportTimeType.map((item, index) => {
              return (
                <Button
                  size="xs"
                  className="btnicon"
                  onClick={() => selectDateType(item.value)}
                  compact={true}
                  radius={0}
                  color={item.value == timeType ? "blue" : "customizebgs"}
                  c={item.value == timeType ? "white" : "dark"}
                  variant="filled"
                  leftIcon={<IconColorPicker size="0.7rem" slope={0.8} />}
                >
                  {item.label}
                </Button>
              );
            })}
            <Group mt={10} grow>
              {timeType == "range" ? (
                <DatePicker
                  minDate={new Date(initSystemTime)}
                  size="xs"
                  className="border-creates reportdate report"
                  key={rand}
                  type="range"
                  value={rangeValue}
                  onChange={(even) => setDateValue(even)}
                />
              ) : timeType == "picker" ? (
                <DatePicker
                  minDate={new Date(initSystemTime)}
                  key={rand}
                  className="border-creates reportdate report"
                  type="multiple"
                  value={valueMultiple}
                  onChange={setValueMultiple}
                />
              ) : timeType == "month" ? (
                <MonthPicker
                  minDate={new Date(initSystemTime)}
                  key={rand}
                  className="border-creates reportdate report"
                  type="range"
                  value={rangeValue}
                  onChange={(even) => setDateValue(even)}
                />
              ) : timeType == "year" ? (
                <YearPicker
                  minDate={new Date(initSystemTime)}
                  key={rand}
                  className="border-creates reportdate report"
                  type="range"
                  value={rangeValue}
                  onChange={(even) => setDateValue(even)}
                />
              ) : (
                <>
                  <Box>
                    <Select
                      placeholder={intl.formatMessage({
                        id: "Choose Year",
                        defaultMessage: "Choose Year",
                      })}
                      required
                      searchable
                      key={year}
                      defaultValue={year}
                      onChange={(value) => SelectYearDate(value)}
                      data={yearNumberAll}
                    />
                    <Select
                      mt={10}
                      placeholder={intl.formatMessage({
                        id: "Start Week",
                        defaultMessage: "Start Week",
                      })}
                      searchable
                      key={start}
                      required
                      defaultValue={start}
                      onChange={(value) => SelectStartDate(value)}
                      data={weekNumberAll}
                    />

                    <Select
                      mt={10}
                      placeholder={intl.formatMessage({
                        id: "End Week",
                        defaultMessage: "End Week",
                      })}
                      searchable
                      required
                      key={end}
                      defaultValue={end}
                      onChange={(value) => SelectEndDate(value)}
                      data={weekNumberAll}
                    />
                  </Box>
                </>
              )}
            </Group>
          </Box>
          <Box pl={10}>
            <Box>
              <Text className="form-title">
                {intl.formatMessage({
                  id: "Select Calculation Method",
                  defaultMessage: "Select Calculation Method",
                })}
              </Text>
              <Text className="form-title-desc mb-6">
                {intl.formatMessage({
                  id: "Select A Calculation Method Or Create A New One",
                  defaultMessage:
                    "Select A Calculation Method Or Create A New One",
                })}
              </Text>
              <Select
                required
                withAsterisk
                w={200}
                size="xs"
                key={FormatValue}
                rightSection={
                  <ActionIcon size={30} onClick={() => clearData("formal")}>
                    <IconX width={20} height={20} />{" "}
                  </ActionIcon>
                }
                defaultValue={FormatValue}
                onChange={(value) => SelectCalculation(value)}
                data={Calculation}
              />
              <Text className="form-title" mt={5}>
                {intl.formatMessage({
                  id: "Calculation",
                  defaultMessage: "Calculation",
                }) + ": "}
              </Text>
              <Box>{FormalDom()}</Box>
              <Radio.Group
                value={filterType}
                onChange={(value) => handleFilterFunction(value)}
                size="xs"
                name="filterType"
                label={
                  <Text className="form-title">
                    {intl.formatMessage({
                      id: "Select Report Type",
                      defaultMessage: "Select Report Type",
                    })}
                  </Text>
                }
                description={
                  <Text>
                    {intl.formatMessage({
                      id: "Select Output Report Format",
                      defaultMessage: "Select Output Report Format",
                    })}
                  </Text>
                }
                key={"filterType"}
              >
                <Radio
                  mt={2}
                  value="team"
                  label={intl.formatMessage({
                    id: "Team Report",
                    defaultMessage: "Team Report",
                  })}
                />
                <Radio
                  mt={2}
                  value="project"
                  label={intl.formatMessage({
                    id: "Project Report",
                    defaultMessage: "Project Report",
                  })}
                />
                <Radio
                  mt={2}
                  value="user"
                  label={intl.formatMessage({
                    id: "User Report",
                    defaultMessage: "User Report",
                  })}
                />{" "}
              </Radio.Group>
            </Box>
          </Box>
        </Flex>
        <Grid>
          <Grid.Col span={12}></Grid.Col>
        </Grid>
      </Box>
    </Box>
  );
}
