import { Box, Grid, Group } from '@mantine/core'
import { useEffect, useState } from 'react'
import { UserAllList } from '../../../../../interface/Iuser'
import {
  CorePermissonSetFormDataUpdateInterface,
  PermissionRoleInterface,
  RolePermission
} from '../../../../../interface/Iemloyee'
import { SelectRole } from '../../../../../interface/Icommon'
import { getCostCenterSelfSelectFunction } from '../../../../../api/assignments_request'
import TableTextInputTitle from '../../../../../ttcomponents/TableTextInputTitle/TableTextInputTitle'
import WordTranslationComponent from '../../../../../utils/language_pack/words'
import { getAssignablePermissionFunction, getUserGeneralFunction, updateUserRoleTransferFunction } from '../../../../../api/common_request'
import { HintInfo, HintInfoCatchError, HintInfoError } from '../../../../../utils/function'
import UniversalButton from '../../../../../ttcomponents/Button/UniversalButton'
import ResetBtn from '../../../../../ttcomponents/Button/ResetBtn'
import { getDefaultFunction } from '../../../../../api/core_request'
import { requestTMPermissionRole, requestTMPermissionRoleList } from '../../../../../api/index_v2'
import {
  getTMPermissionActionListGeneralFunction,
  getTMPermissionIdentifierListGeneralFunction,
  getTMPermissionRoleDetail
} from '../../../../../api/timetracker_request'
import CommonPermissionRoleForm from '../common/role_form'
import CommonPermissionSet from '../common/role_permission'
import CommonRoleList from '../common/role_list'
import PermissionExistsView from '../common/permission_exists'
import { IPermissionDetailInterface } from '../../../../../interface/Ipermission'
import { useDisclosure } from '@mantine/hooks'
import { useCostCenterLeader, useCostCenterLeaderApi } from '../context'
import TransferListUserComponent from '../common/TransferListUserComponent/TransferListUserComponent'

interface ComponentInterface {
  is_load_str?: string
  Permission: any
}
export default function TimetrackerRoleIndex({ Permission, is_load_str }: ComponentInterface) {
  // Get translation public configuration information
  const word_translation = WordTranslationComponent()

  const [costCenterData, setCostCenterData] = useState<SelectRole[]>([])
  const [defaultRoleData, setDefaultRoleData] = useState<SelectRole[]>([])

  const { id, costCenter_id, role_costCenter_id, title, identifier, description, active, role_permissions, user_ids } =
    useCostCenterLeader()
  const { updateDatePermissionRole, clearCostCenter, updateData } = useCostCenterLeaderApi()

  const [currentItemInfo, setCurrentItemInfo] = useState<PermissionRoleInterface>()
  const [corePermissonSetFormData, setCorePermissonSetFormData] = useState<CorePermissonSetFormDataUpdateInterface[]>(
    []
  )

  const [randNumber, setRandNumber] = useState(0)
  const [currentPermission, setCurrentPermission] = useState<RolePermission[]>([])
  const [UserList, setResultUser] = useState<UserAllList[]>([])

  const [permissionDetail, setPermissionDetail] = useState<IPermissionDetailInterface[]>([])
  const [tempRoleId, setTempRoleId] = useState('')
  const [loading, setLoading] = useState(false)
  const [opened, { open, close }] = useDisclosure(false)

  // getDatas
  const getCostCenter = async () => {
    // Call the Get Cost Center General Selection function and set the cost center data
    setCostCenterData(await getCostCenterSelfSelectFunction({permission:true}))
  }
  const getDefaultRole = async () => {
    // Call the Get Cost Center General Selection function and set the cost center data
    setDefaultRoleData(await getDefaultFunction())
  }
  // Get a list of users <FUNCTION>
  const getUser = async () => {
    if (costCenter_id) {
      // Call the Get User Universal Selection function and set the user list
      let userList = await getUserGeneralFunction({ costCenter_ids: costCenter_id }, 'all_info')

      setResultUser(userList)
    } else {
      setResultUser([])
    }
  }
  const fetchPermissionRoleDetail = async (paramId: string) => {
    const result = await getTMPermissionRoleDetail({ id_permission_role: paramId })

    const premissions = result.permission_sets.map((item: any) => {
      return {
        permission_ids: item.permission_ids,
        permission_identifier_id: item.permission_identifier_id
      }
    })
    const user_ids = result.userIds.map((item: any) => item.user_id.toString())
    updateDatePermissionRole({
      title: result.detail.title,
      description: result.detail.description,
      active: result.detail.active,
      role_permissions: premissions,
      user_ids
    })
    if (costCenter_id == '-2') {
      updateData('role_costCenter_id', result.detail.costCenter_id as string[])
    }
    setCurrentPermission(premissions)
  }

  useEffect(() => {
    is_load_str == 'timetracker' && getCostCenter()
    is_load_str == 'timetracker' && getDefaultRole()
  }, [is_load_str])

  useEffect(() => {
    // Obtaining department users
    getUser()
  }, [costCenter_id])

  useEffect(() => {
    if (costCenter_id && id) {
      fetchPermissionRoleDetail(id)
    }
  }, [costCenter_id, id])
  useEffect(() => {
    setCurrentPermission([])
  }, [identifier])

  const roleListcallBackFunction = async (paramID: string) => {
    setRandNumber(randNumber + 1)
    updateData('selectedRole', paramID)
    updateData('id', paramID)
  }

  // Resetting the form is different from the reset button, it is resetting the entire form
  const resetFunction = () => {
    clearCostCenter()
    setResultUser([])
    // need to clear currentPermission state
    setCurrentPermission([])
  }

  const submitFormFunctionUp = async () => {
    let submitData: any = {
      id,
      costCenter_id,
      title,
      identifier,
      description,
      active,
      role_permissions,
      user_ids,
      senior_pass: true
    }
    
    if (costCenter_id == '-2') {
      submitData['costCenter_id'] = role_costCenter_id
      submitData['role_costCenter_id'] = '-2'
    } else {
      submitData['role_costCenter_id'] = costCenter_id
    }

    const methodType = submitData.id ? 'PUT' : 'POST'
    try {
      setLoading(true)
      const response = await requestTMPermissionRole(submitData, methodType)
      const result = response.data
      // Display information based on the response
      HintInfo(response.data)
      if (result.code === 200) {
        close()
        callBackClearRole()
      } else {
        HintInfoError(result.msg)
      }
    } catch (error) {
      HintInfoCatchError()
    } finally {
      // setHaveUserIds([])
      // setCurrentPermissionSet([])
      setLoading(false)
    }
  }

  const SaveFormFunction = async () => {
    let submitData: any = {
      id,
      costCenter_id,
      title,
      identifier,
      description,
      active,
      role_permissions,
      user_ids
    }

    if (costCenter_id == '-2') {
      submitData['costCenter_id'] = role_costCenter_id
      submitData['role_costCenter_id'] = '-2'
    } else {
      submitData['role_costCenter_id'] = costCenter_id
    }

    const methodType = submitData.id ? 'PUT' : 'POST'
    try {
      setLoading(true)
      const response = await requestTMPermissionRole(submitData, methodType)
      const result = response.data
      // Display information based on the response

      if (result.code === 200) {
        close()
        callBackClearRole()
        HintInfo(response.data)
        roleListcallBackFunction(result.data.id.toString())
      } else {
        if (result.code == 4113) {
          setTempRoleId(result.data.permission_identifier_id)
          setPermissionDetail(result.data.data)
          open()
        } else {
          HintInfoError(result.msg)
        }
      }
    } catch (error) {
      HintInfoCatchError()
    } finally {
      setLoading(false)
    }
  }

  // ============== Clear permission role callback  ================= //
  const callBackClearRole = () => {
    setRandNumber(randNumber + 1)
  }
  const refreshPermissionSetCallBack = () => {
    setRandNumber(randNumber + 1)
  }
  return (
    <Box>
      <Grid columns={24} gutter="xl">
        <Grid.Col span={12}>
          <CommonPermissionRoleForm
            defaultRoleData={defaultRoleData}
            costCenterData={costCenterData}
            resetFunction={resetFunction}
          />

          <Box mt={10}></Box>
          <TableTextInputTitle py={5} pb={5} title={word_translation.permission_edit} />

          <CommonPermissionSet
            currentPermission={currentPermission}
            actionListApi={getTMPermissionActionListGeneralFunction}
            identifierListApi={getTMPermissionIdentifierListGeneralFunction}
            assignablePermissionApi={getAssignablePermissionFunction('timetracker')}
            leftWidth={100}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <CommonRoleList
            randNumber={randNumber}
            requestPermissionRoleList={requestTMPermissionRoleList}
            requestPermissionRole={requestTMPermissionRole}
          />

          <TableTextInputTitle py={5} pb={5} title={word_translation.members} />
          <TransferListUserComponent
            userlist={UserList}
            updateUserRoleTransferApi={updateUserRoleTransferFunction('timetracker')}
            refreshPermissionSetCallBack={refreshPermissionSetCallBack}
          />
        </Grid.Col>
      </Grid>

      <Grid columns={12} gutter="xl">
        <Grid.Col span={12}>
          <Group position="right">
            <ResetBtn callBack={resetFunction} />
            <UniversalButton disabled={true} btn_text={word_translation.udpate_user} callBack={SaveFormFunction} />
            <UniversalButton btn_text={word_translation.create} callBack={SaveFormFunction} />
          </Group>
        </Grid.Col>
      </Grid>
      <PermissionExistsView
        close={close}
        permissionDetail={permissionDetail}
        tempRoleId={tempRoleId}
        callBack={roleListcallBackFunction}
        opened={opened}
        submitFormFunction={submitFormFunctionUp}
      />
    </Box>
  )
}
