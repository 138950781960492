import { useForm } from "@mantine/form";
import {
  NumberInput,
  Group,
  Text,
  Button,
  Box,
  Grid,
  Avatar,
  Checkbox,
  Textarea,
  Code,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { IconCalendar, IconCircleX } from "@tabler/icons-react";
import SaveBottom from "../../ttcomponents/Button/SaveBtn";
import { useState, useRef, FormEvent } from "react";
import { regCancelVationAction, regGetVationAction, regVacationModifications } from "../../api";
import { HintInfo, formatDateUpgraded } from "../../utils/function";
import { useIntl } from "react-intl";
import WordTranslationComponent from "../../utils/language_pack/words";


import apiUrl from '../../config/api_config/config';
import CancelButton from "../../ttcomponents/Button/CancelBtn";
interface IEditVation {
  row: any;
  callback: () => void;
  closeBack: () => void;
}
export default function EditVation({ row, closeBack,callback }: IEditVation) {
  // Use the useIntl hook to access the intl object
  const intl = useIntl();
  // Get translation public configuration information
   const word_translation = WordTranslationComponent();

  const [checkedHalf, setHalfChecked] = useState(row.flag_halfday == "1");
  // FORM form submission
  const formRef = useRef<HTMLFormElement>(null);
  const form = useForm({
    initialValues: {
      from: new Date(row.from),
      to: new Date(row.to),
      remarks: row.remarks,
      vacation_id: row.id_leave,
      modification_type: "modification",
      leave_category_id: row.leave_category_id,
      approved_by: row.approved_by,
    },

    // functions will be used to validate values at corresponding key
  });

  const CancelVation = async () => {
    if(row.status_id.toString()  == '2') {
      const ApprovalResult = await regCancelVationAction( { id: row.id_leave },"PUT"  );
      HintInfo(ApprovalResult.data);
      if (ApprovalResult.data.code == 200) {
        callback();
      }
    }else{
      const ApprovalResult = await regGetVationAction( { id: row.id_leave,  status_id: "4",   },"PUT"  );
      HintInfo(ApprovalResult.data);
      if (ApprovalResult.data.code == 200) {
        callback();
      }
    }
   
  };


  // Submit form information
  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    // Stop the formal submission of the form default
    event.preventDefault();
    const valid = await form.validate();
    // Initialize Project_employee_roles_new, deal with employees and employees, it has been abandoned, and the addition of the employee entry has changed to the creation of the TASK link
    const flag_halfday = checkedHalf ? 1 : 0;
    let ApprovalResult: any;
    if (!valid.hasErrors) {
      // The current record is still the "pending" state. This modification is the real holiday of the modification, otherwise a modification request will be sent
        let originalData = {
          user_id: row.user_id,
          status_id: row.status_id,
          id: row.id_leave,
          leave_category_id:row.leave_category_id,
          from: formatDateUpgraded(
            form.values.from as Date,
            "YYYY-MM-DD HH:mm:ss"
          ),
          to: formatDateUpgraded(form.values.to as Date, "YYYY-MM-DD HH:mm:ss"),
          flag_halfday: flag_halfday,
          approved_by: row.approved_by,
          user_remarks: form.values.remarks,
        };
        ApprovalResult = await regGetVationAction(originalData, "PUT");

      HintInfo(ApprovalResult.data);
      if (ApprovalResult.data.code == 200) callback();
    } else {
    }
  };
  return (
    <Box mx={10} mih={400} >
      <form onSubmit={handleFormSubmit} ref={formRef}>
        <DateInput
          valueFormat="DD/MM/YYYY"
          labelProps={{ className: "input_title" }}
          defaultValue={new Date()}
          required
          size="xs"
          icon={<IconCalendar size="1.1rem" stroke={1.5} />}
          {...form.getInputProps("from")}
          label={word_translation.from}
        />
        <DateInput
          valueFormat="DD/MM/YYYY"
          size="xs"
          labelProps={{ className: "input_title" }}
          defaultValue={new Date()}
          required
          icon={<IconCalendar size="1.1rem" stroke={1.5} />}
          {...form.getInputProps("to")}
          label={word_translation.to}
          placeholder={intl.formatMessage({
            id: "Start Time",
            defaultMessage: "Start Time",
          })}
        />

        <Group mt={10}>
            <Text className="columns-title">{word_translation.status}</Text>
            <Code> {row.status_name}</Code>
        </Group>

        <Group mt={10}>
            <Text className="columns-title">{word_translation.approver}</Text>
            <Box>
                <Group position="left">
                  <Box>
                    <Text className="leave-name">{row.manager[0].username}</Text>
                    <Text className="leave-role">{row.manager[0].email}</Text>
                  </Box>
                  <Box>
                    <Avatar size={20} src={apiUrl.fileSystemDomain +row.manager[0]?.avatar} alt="it's me" />
                  </Box>
                </Group>
            </Box>
        </Group>

        <Grid gutter={0} mt={5}>
          <Grid.Col span={12} mt={10}>
            <Box className="HalfBox">
              <Checkbox
                className="cursor-pointer"
                checked={checkedHalf}
                label={
                  <>
                    <Text className="leave-Half cursor-pointer">
                      {word_translation.half_day}
                    </Text>
                    <Text className="leave-Only cursor-pointer">
                    {word_translation.half_day_select_hitn} (Only possible if a single tag is selected)
                    </Text>
                  </>
                }
                onChange={(event) =>setHalfChecked(event.currentTarget.checked)}
              />
            </Box>
          </Grid.Col>
        </Grid>

        <Grid gutter={0} mt={5}>
          <Grid.Col span={12}>
            <Box>
              <Textarea
                minRows={8}
                className="commnet-content"
                {...form.getInputProps("user_remarks")}
              />
            </Box>
          </Grid.Col>
        </Grid>

        <Group position="right">
            <CancelButton  callBack={()=>closeBack()}/>
          
          {row.leave_category_id != '2' && 
          <>
                 <Button
              mt={10}
              size="xs"
              onClick={CancelVation}
              color="red"
              leftIcon={<IconCircleX width={14} height={14} />}
              styles={{
                leftIcon: { marginRight: "5px" },
              }}
            >
              Cancel  vacation ?
            </Button>
          <SaveBottom formRef={formRef} />
        
          </>
          }
     
        </Group>
      </form>
    </Box>
  );
}
