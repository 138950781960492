import { Box, Group, Text } from '@mantine/core'
import { IconLoader, IconX } from '@tabler/icons-react'

export function ProjectLeft() {
  return (
    <Box w={340} mt={60}>
      <Text>
        Here you can create projects, orders and tasks. You can skip any creation process, by clicking on the upper
        step-buttons.
        <br /> For example: If you wish to create only a single task, click the task-step in the upper stepper:
      </Text>
      <Group mt={32} spacing={8}>
        <Box bg={'#f1f3f5'} lh={1} p={6} style={{ borderRadius: '5px' }} c={'#495057'}>
          <IconLoader size="1rem" />
        </Box>
        <Box>
          <Text fz={12} lh={1.2} c={'#101113'} fw={600}>
            Order
          </Text>
          <Text fz={9} c={'rgb(134, 142, 150)'} fw={400}>
            Create a new order
          </Text>
        </Box>

        <Box w={40} h={2} bg={'#e9ecef'} mx={8}></Box>

        <Box bg={'#f1f3f5'} lh={1} p={6} style={{ borderRadius: '5px' }} c={'#495057'}>
          <IconX size="1rem" />
        </Box>
        <Box>
          <Text fz={12} lh={1.2} c={'#101113'} fw={600}>
            Task
          </Text>
          <Text fz={9} c={'rgb(134, 142, 150)'} fw={400}>
            Create a new task
          </Text>
        </Box>
      </Group>
    </Box>
  )
}
