
import { ActionIcon, Box, Checkbox, ColorInput, ColorPicker, ColorSwatch, Grid, Group, Modal, MultiSelect, ScrollArea, SegmentedControl, Select, Space, Switch, Table, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import WordTranslationComponent from '../../../../../utils/language_pack/words';
import { useEffect, useState } from 'react';
import TableTextInputTitle from '../../../../../ttcomponents/TableTextInputTitle/TableTextInputTitle';
import ResetBtn from '../../../../../ttcomponents/Button/ResetBtn';
import SubmitBtn from '../../../../../ttcomponents/Button/SubmitBtn';
import localstorageUnits from '../../../../../utils/localstorageUnits';
import { DateInput } from '@mantine/dates';
import { IconCalendar, IconDotsVertical, IconTrash } from '@tabler/icons-react';
import { getCustomerListGeneralFunction, getOrderProductsGeneralFunction } from '../../../../../api/assignments_request';
import { SelectRole } from '../../../../../interface/Icommon';
import GlobalCoreSetting from '../../../../../utils/globalCortSetting';
import UniversalButton from '../../../../../ttcomponents/Button/UniversalButton';
import TableTextInputTitleDesc from '../../../../../ttcomponents/TableTextInputTitleDesc/TableTextInputTitleDesc';
import ModalTitleText from '../../../../../ttcomponents/ModalTitleText/ModalTitleText';
import { AssignmentOrderProductData } from '../../../../../interface/Iorder';
import { truncateString } from '../../../../../utils/function';

function AssignmentsOrderedProducts() {
  
    // Get the default unchanged configuration
    const globalCoreSetting = GlobalCoreSetting();
    // Define all the customer lists that can be selected
    const [customerList, setCustomerList] = useState<SelectRole[]>([]);
    const [orderProducts, setOrderProduct] = useState<AssignmentOrderProductData[]>([]);
    
    const [randNumber, setRandNumber] = useState(1);
    // Get the initial time of the system
    const initSystemTime = localstorageUnits.getSystem().init_use_date;

    const [openForm, { open: openModalFunction, close: closeModalFunction }] = useDisclosure(false);
    // Get translation public configuration information
    const word_translation = WordTranslationComponent();


    useEffect(() => {
        getOrderProducts();
    }, [])

    
    // Close component
    const closeBtnModalFunction = () => {
        closeModalFunction();
    }

    // Click save
    const applyBtnFunction = () => {
        console.log('Apply');
    }

   
    const resetColorFunction = () => {
        console.log('Reset');
       
    }

    const getOrderProducts= async() =>{
        setOrderProduct(await getOrderProductsGeneralFunction(""));
    }

   
    
  const [assignmentModa, setAssignmentsModa] = useState<string[]>([]);
    const setAssignmentsModaExpandFunction=(value:any) =>
    { 
    if(Array.isArray(value)){
        setAssignmentsModa(value)
    }else if(value && typeof  value === 'string' ){
        setAssignmentsModa(value.split(","))
    }
    }
    return (
            <Box className='' p={10}>
                <Box py={5}> <TableTextInputTitle class_name='subtitle' title={word_translation.ordered_products} /></Box>
                <ScrollArea h={250}  >
                    <Table>
                        <thead  className="scrolleder">
                            <tr className='text-left'>
                            <th  id="table-th-title-new">{word_translation.product_name}</th>
                            <th  id="table-th-title-new">{word_translation.order_no}</th>
                            <th  id="table-th-title-new">{word_translation.price}</th>
                            <th  id="table-th-title-new">{word_translation.delivery_schedule}</th>
                            <th  id="table-th-title-new">{word_translation.quantity}</th>
                            <th  id="table-th-title-new">{word_translation.option}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orderProducts.map((item,index)=>{
                            return <tr className='text-center' key={index}>
                            <td  id="">{truncateString(item.product_name,30)}</td>
                            <td  id="">{item.price}</td>
                            <td  id="">{item.delivery_schedule}</td>
                            <td  id="">{item.quantity}</td>
                            <td  id="">{item.order_no}</td>
                            <td  id="">
                            <td>
                                <Group spacing={0} position="center">
                                    <ActionIcon onClick={() => {}}>
                                    <IconDotsVertical size="1rem" stroke={1.5} />
                                    </ActionIcon>
                                    <ActionIcon  onClick={() => {}}>
                                    <IconTrash size="1rem" color="red" stroke={1.5} />
                                    </ActionIcon>
                                </Group>
                                </td>
                            </td>
                            </tr>
                            })}
                        </tbody>
                        </Table>
                </ScrollArea>
            </Box>
    );
}
export default AssignmentsOrderedProducts;
