import { createSlice } from  '@reduxjs/toolkit'
import type { PayloadAction } from  '@reduxjs/toolkit'

export interface SearchState{
    searchValue:string
}

const initialState:SearchState ={
    searchValue:''
}

export const SearchSlice = createSlice({
    name:"searchValue",
    initialState,
    reducers:{
        setSearch:(state,action:PayloadAction<string>)=>{
            state.searchValue = action.payload as SearchState['searchValue']
        }
    }
})

export const {setSearch} = SearchSlice.actions;

export default SearchSlice.reducer;