import React, { useState } from 'react';
import { ICategory } from '../../../../interface/IProductCategory';
import { Box, Card,Text, ScrollArea, Group } from '@mantine/core';

//Interface definition
interface ICategoryProps {
  category: ICategory;
  callback: (value: any) => void;
}

const Category = ({ category, callback }: ICategoryProps) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [currentValue, setCurrentValue] = useState<any>();

  //Click the function triggered when clicking the classification item
  const toggleCollapse = (category: any) => {
    if (category.children && category.children.length > 0) {
      setIsCollapsed(!isCollapsed);
    } else {
      // If there is no sub -classification of classification, call the callback function
      // setCurrentValue(category)
      callback(category);
    }
  };

  // Transfer the callback function to the auxiliary function of the sub -component
  function takeOverFunction(value: any) {
    callback(value);
  }

  return (
    <Box>
      <Box onClick={() => toggleCollapse(category)} style={{ cursor: 'pointer' }}>
        <Group>
            <Text className={currentValue && currentValue.category_name == category.category_name ? 'category_bg' : ''}> {category.category_name}</Text> 
            {category.children && category.children.length > 0 && (
              <span>
                {isCollapsed ? '➕' : '➖'}
              </span>
            )}
        </Group>
      </Box>
      {!isCollapsed && (
        <Box  style={{ marginLeft: '16px' }}>
          {category.children && category.children.map((childCategory: ICategory) => (
            <Box key={childCategory.category_id}>
              {/* {/ * Recursively call Category components */}
              <Category callback={takeOverFunction} category={childCategory} />
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

// Main application component
interface Icategories {
  categories: ICategory[];
  callback: (value: any) => void;
}

function AppCategory({ categories, callback }: Icategories) {
  // The auxiliary function, pass the callback to the child component
  function takeOverFunction(value: any) {
    callback(value);
  }

  return (
    <Card withBorder>
        <ScrollArea type="always"  h={130} scrollbarSize={4} scrollHideDelay={20}>
                <Box className=''>
                        {categories.map((category: ICategory) => (
                    <Box key={category.category_id}>
                        {/* Rendering top classification */}
                        <Category callback={takeOverFunction} category={category} />
                    </Box>
                    ))}
                </Box>
        </ScrollArea>
    </Card>
     
  );
}

export default AppCategory;
