import { hasLength, useForm } from "@mantine/form";
import {
  Grid,
  TextInput,
  Box,
  Paper,
  Group,
} from "@mantine/core";
import {  useEffect, useRef,  } from "react";
import { regCurrency,} from "../../../../api";
import "./corrency.css";
import { ComponentInterface } from "../../../../interface/Icommon";
import WordTranslationComponent from "../../../../utils/language_pack/words";
import TableTextInputTitle from "../../../../ttcomponents/TableTextInputTitle/TableTextInputTitle";
import TableTopTitle from "../../../../ttcomponents/TableTopTitle/TableTopTitle";
import { ClientWarningHint, HintErrorMantineInfo, HintInfo, HintInfoCatchError, HintInfoError } from "../../../../utils/function";
import SubmitBtn from "../../../../ttcomponents/Button/SubmitBtn";
import CheckPermissionTools from "../../../../utils/permission";
import { NotPermission } from "../../../../ttcomponents/NoPermission";
export default function CurrencySettings({  is_load_str,}: ComponentInterface){

  const Perm = CheckPermissionTools();
  // Get translation public configuration information
  const word_translation = WordTranslationComponent();
  const form = useForm({
    validateInputOnBlur: true,
    initialValues: {
      id: 0,
      currency_name: "",
      currency_country: "",
      currency_notation: "",
    },
    // functions will be used to validate values at corresponding key
    validate: {
      currency_name: hasLength({ min: 2, max: 10 },   `${word_translation.currency_name}${word_translation.validata_value_need_2_10_length}`),
      currency_notation: hasLength({ min: 1, max: 10 },   `${word_translation.currency_notation}${word_translation.validata_value_need_1_10_length}`),
    },
  });

/**
 * Fetches currency settings information.
 * If the current section is related to currency settings, it sends a GET request to retrieve currency data.
 * Updates the form fields with the received currency information.
 * Logs an error message if the request is not successful.
 */
const ajaxCurrentList = async () => {
  if (is_load_str === "currency_set") {
    try {
      // Send a GET request to retrieve currency data
      const response = await regCurrency({}, "GET");
      const result = response.data;
      // Update form fields with the received currency information
      if (result.code === 200 ) {
        if (!Array.isArray(result.data)) {
          form.setFieldValue("currency_notation", result.data.currency_notation);
          form.setFieldValue("id", result.data.id_currency);
          form.setFieldValue("currency_country", result.data.currency_country);
          form.setFieldValue("currency_name", result.data.currency_name);
        }else{
          ClientWarningHint(word_translation.no_data)
        }
      } else {
        HintInfoError(result.msg)
      }
    } catch (error) {
      HintInfoCatchError();
    }
  }
};


/**
 * Handles the form submission.
 * Validates the form and determines the request type (POST or PUT) based on the form values.
 * Sends a request to create or update currency settings and displays information based on the response.
 */
  const handleFormSubmit = async () => {
    // Verification form
    const valid = await form.validate();
      // If there are validation errors, display error information
    if (valid.hasErrors) {
        HintErrorMantineInfo(valid);
        return false;
      }

    // Determine the request type (POST or PUT) based on the form values
    const type = form.values.id !== 0 ? "PUT" : "POST";
   
    // Send a request to create or update currency settings
    const response = await regCurrency(form.values, type);

    // Display information based on the response
    HintInfo(response.data);
  
  };

  useEffect(() => {
    // Fetch currency settings information when the `is_load_str` dependency changes
    ajaxCurrentList();
  }, [is_load_str]);
  
  return (
    <Paper>
      {Perm.currency_read ?    <Grid gutter={0} align="left">
        <Grid.Col span={5}>
            <Box w={400} className="border-radius-xs shadow-sm border-custom">
            <TableTopTitle title={word_translation.currency_info} />
            <Box  p={20} >
            <form >
              <TableTextInputTitle title={word_translation.currency_name} />
              <TextInput
                radius={5}
                placeholder={word_translation.currency_name}
                {...form.getInputProps("currency_name")}
              />
              <TableTextInputTitle title={word_translation.currency_country} />
              <TextInput
                radius={5}
                placeholder={word_translation.currency_country}
                {...form.getInputProps("currency_country")}
              />
              
              <TableTextInputTitle title={word_translation.currency_notation} />
              <TextInput
                radius={5}
                placeholder={word_translation.currency_notation}
                {...form.getInputProps("currency_notation")}
              />
         
              <Group position="right" mt={10}>
                <SubmitBtn disabled={!Perm.currency_update } btn_size="xs" callBack={handleFormSubmit}/>
              </Group>
            </form>
          </Box>
            </Box>
        </Grid.Col>
        </Grid> : <NotPermission />}
   
    </Paper>
  );
}
