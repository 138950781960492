import {
  Paper,
  Box,
  LoadingOverlay,
  Badge,
  Group,
  Select,
  ActionIcon,
  Alert,
  Table,
  ScrollArea,
  Grid,
  SelectItem
} from '@mantine/core'
import PageTitle from '../../../ttcomponents/PageTitle/PageTitle'
import { useEffect, useMemo, useRef, useState } from 'react'
import GlobalCoreSetting from '../../../utils/globalCortSetting'
import WordTranslationComponent from '../../../utils/language_pack/words'
import { requestSignalEmployeeOvertime } from '../../../api/index_v2'
import { RootState } from '../../../state_management/store'
import { useSelector } from 'react-redux'
import { EmployeeOvertimeReportChart, EmployeeOvertimeReportDataInterface } from '../../../interface/IReport'
import { ClientWarningHint, ConvertTimeStringToDecimal, formatTimeHHSS, getYearAll } from '../../../utils/function'
import { SelectRole } from '../../../interface/Icommon'
import { getUserGeneralFunction, getUserPermissionGeneralFunction } from '../../../api/common_request'
import { IconAlertCircle, IconX } from '@tabler/icons-react'
import UniversalButton from '../../../ttcomponents/Button/UniversalButton'
import EmployeeOvertime from '../chart/employee_overtime'
import memoryUtils from '../../../utils/memoryUtils'
import CheckPermissionTools from '../../../utils/permission'
import { getUserPermissionReport } from '../../../api/report_request'

// OvertimeReport component definition
export default function EmployeeOvertimeReport() {
  // Get the default unchanged configuration
  const globalCoreSetting = GlobalCoreSetting()
  // Get translation public configuration information
  const word_translation = WordTranslationComponent()

  const [loading, setLoading] = useState(false)

  const [employeeOvertime, setEmployeeOvertime] = useState<EmployeeOvertimeReportDataInterface>()
  const [employeeOvertimeChartData, setEmployeeOvertimeChartData] = useState<EmployeeOvertimeReportChart[]>()

  const Perm = CheckPermissionTools()
  const [year, setYear] = useState<string | null>('2024')
  const [userId, setUserId] = useState<string | null>(null)
  //Get all the Year collection
  const yearNumberAll = getYearAll()

  const [randNumber, setRandNumber] = useState(0)

  // Define all the list of employees you can choose
  const [userList, setUserList] = useState<SelectRole[]>([])
  const [searchValue, onSearchChange] = useState('')
  const options: SelectRole[] = useMemo(() => {
    if (userList.length === 0) return []
    return userList
      .filter(user => user.label.toLowerCase().includes(searchValue.toLowerCase()))
      .sort((_, b) => (b.label.toLowerCase().startsWith(searchValue.toLowerCase()) ? 1 : -1))
  }, [userList, searchValue])

  /**
   * Get a list of users
   */
  const getUser = async () => {
    setUserList(await getUserPermissionReport({ list: true }))
    setRandNumber(randNumber + 1)
  }

  // 获取redux store中的值
  const SearchValue = useSelector((state: RootState) => state.search.searchValue)
  // Execute the useeffect when the first load is loaded.
  const isInitialMount = useRef(true)
  useEffect(() => {
    if (!isInitialMount.current) {
      // ajaxCurrentList({ search: SearchValue });
    }
    isInitialMount.current = false
  }, [SearchValue])

  const [user_type, set_user_role_id] = useState<string | null>(memoryUtils.user.user_type)
  const [id_user, set_id_user] = useState<string | null>(memoryUtils.user.id_user)

  useEffect(() => {
    if (user_type == '1') {
    } else {
      setUserId(id_user)
    }
  }, [user_type])

  const is_select =
    user_type == '1' || Perm.employee_overtime_report_create || Perm.leader_reports_allread || Perm.core_reports_allread

  const getEmployeeOvertimeReport = async (value: any = {}) => {
    if (year && userId) {
      // Send GET requests to get  SignalEmployeeOvertime
      setLoading(true) // Set the loading status to TRUE, indicating that the data is loading
      const responseOverTime = await requestSignalEmployeeOvertime(userId, { year: year }, 'GET')
      setLoading(false) // After the data is loaded, set the loading status to false
      if (responseOverTime.data.code === 200) {
        setEmployeeOvertime(responseOverTime.data.data)
      } else {
        ClientWarningHint(word_translation.cant_view_report)
      }
    }
  }
  // Use the USEEFFECT hook. When Search changes, call the AjaxCurrenTList function
  useEffect(() => {
    getEmployeeOvertimeReport()
    getUser()
  }, [])

  useEffect(() => {
    // Check if employeeOvertime and week_list exist before mapping
    setEmployeeOvertimeChartData(
      employeeOvertime?.week_list?.map(item => {
        return {
          week: item.week.toString(),
          ActualTime: parseFloat(ConvertTimeStringToDecimal(formatTimeHHSS(item.actual_time))),
          overtime: parseFloat(ConvertTimeStringToDecimal(formatTimeHHSS(item.overtime)))
        }
      }) as EmployeeOvertimeReportChart[]
    )

    // Update the labels state
    // setLabels(newLabels);
  }, [employeeOvertime])
  // Define an asynchronous function ajaxcurrenTlist to send AJAX request to obtain the list of suppliers
  const getReportFunction = async (value: any = {}) => {
    getEmployeeOvertimeReport()
  }

  return (
    <Paper px={20} mih={600} className="position_relative">
      <PageTitle title={`${word_translation.over_time_report}      `} />
      <LoadingOverlay visible={loading} overlayBlur={2} />
      {/* Tabs for different settings */}

      <Group my={10} className="border_coustom">
        <Select
          placeholder={word_translation.pick_one}
          size="xs"
          required
          disabled
          value={year}
          onChange={setYear}
          searchable
          data={yearNumberAll}
        />

        <Select
          onChange={setUserId}
          size="xs"
          placeholder={word_translation.select_employees}
          searchable
          searchValue={searchValue}
          onSearchChange={onSearchChange}
          disabled={is_select ? false : true}
          defaultValue={is_select ? userId?.toString() : id_user?.toString()}
          key={randNumber}
          rightSection={
            <ActionIcon
              size="xs"
              onClick={() => {
                setUserId('')
                setRandNumber(randNumber + 1)
              }}
            >
              <IconX size="xs" />
            </ActionIcon>
          }
          data={options}
        />
        <UniversalButton callBack={getReportFunction} btn_text={word_translation.get_report} btn_size="xs" />
      </Group>

      {!employeeOvertime && (
        <Group position="center" mt={50}>
          <Alert icon={<IconAlertCircle size="1rem" />} title={word_translation.no_data}>
            {word_translation.emloyee_report_hitn}
          </Alert>
        </Group>
      )}
      <Box mt={6} className={`${employeeOvertime ? 'border-cusome border-radius-xs' : ''}`} px={30} pt={10}>
        <Grid gutter={0}>
          <Grid.Col span={2}>
            {employeeOvertime && employeeOvertime?.week_list.length > 0 && (
              <ScrollArea h="80vh">
                <Table>
                  <thead className=" scrolleder ">
                    <tr>
                      <th>{word_translation.kw}</th>
                      <th>{word_translation.actual}</th>
                      <th>{word_translation.diff_time}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {employeeOvertime?.week_list.map((row, index) => {
                      if (row.is_visible)
                        return (
                          <tr key={index} className="table_tr_hover border-bt-2 my-10 ">
                            <td className="">
                              <Badge radius="sm" color="dark">
                                {' '}
                                {row.week}
                              </Badge>
                            </td>
                            {/* <td>{formatTimeHHSS(row.actual_time)}&nbsp; {word_translation.hour}</td> */}
                            <td>
                              {ConvertTimeStringToDecimal(formatTimeHHSS(row.actual_time))}&nbsp;{' '}
                              {word_translation.hour}
                            </td>
                            <td>
                              {ConvertTimeStringToDecimal(formatTimeHHSS(row.actual_time_change))} &nbsp;{' '}
                              {word_translation.hour}
                            </td>
                            {/* <td>{formatTimeHHSS(row.actual_time_change)} &nbsp; {word_translation.hour}</td> */}
                          </tr>
                        )
                    })}
                  </tbody>
                </Table>
              </ScrollArea>
            )}
          </Grid.Col>
          <Grid.Col span={10}>
            <Group ml={20}>
              {employeeOvertime && (
                <Badge radius="sm" color="dark">
                  {word_translation.username} : {employeeOvertime.username}
                </Badge>
              )}
              {employeeOvertime && (
                <Badge radius="sm" color="dark">
                  {word_translation.total_actual_time_change} : {employeeOvertime.total_actual_time_change}
                </Badge>
              )}

              {employeeOvertime && (
                <Badge radius="sm" color="dark">
                  {word_translation.over_time} :
                  {ConvertTimeStringToDecimal(formatTimeHHSS(employeeOvertime.total_overtime as string))}{' '}
                  {word_translation.hour}
                </Badge>
              )}

              {employeeOvertime && (
                <Badge radius="sm" color="dark">
                  {word_translation.start_year} : {employeeOvertime.year}
                </Badge>
              )}
              {employeeOvertime && (
                <Badge radius="sm" color="dark">
                  Offset : {ConvertTimeStringToDecimal(formatTimeHHSS(employeeOvertime.offset as string))}{' '}
                  {word_translation.hour}
                </Badge>
              )}
            </Group>

            {employeeOvertime && <EmployeeOvertime datas={employeeOvertimeChartData} />}
          </Grid.Col>
        </Grid>
      </Box>
    </Paper>
  )
}
