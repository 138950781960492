import { Badge, Box, Button, CloseButton, Divider, Flex, Group, ScrollArea, TextInput } from '@mantine/core'
import TableTextInputTitle from '../../../../../ttcomponents/TableTextInputTitle/TableTextInputTitle'
import WordTranslationComponent from '../../../../../utils/language_pack/words'
import { ClientWarningHint, HintInfo, TimeHHSS, formatTimeHHSS } from '../../../../../utils/function'
import { timeRegex } from '../../../../../utils/validataUnits'
import { TaskByCostCenterInterface, TaskGroupInterface } from '../../../../../interface/Itask'
import { requestTask } from '../../../../../api/index_v2'
import { useEffect, useState } from 'react'
import SubmitBtn from '../../../../../ttcomponents/Button/SubmitBtn'
import UniversalButton from '../../../../../ttcomponents/Button/UniversalButton'
import { useDisclosure } from '@mantine/hooks'
import QuickCreateTask from '../../task/quick_create_task'
import { SelectRole } from '../../../../../interface/Icommon'
import {
  getAllTaskCategoryGeneralFunction,
  getCostCenterGeneralSelectFunction,
  getProjectDetailByIdFunction
} from '../../../../../api/assignments_request'
import { getDefaultFunction } from '../../../../../api/core_request'
import { getUserGeneralFunction } from '../../../../../api/common_request'
import { UserAllList } from '../../../../../interface/Iuser'
import { IconCirclePlus, IconCircleX } from '@tabler/icons-react'

interface ComponentProps {
  taskByCostCenter: TaskGroupInterface[]
  taskTargetTime: string
  projectTargetTime: string
  projectName?: string
  project_id?: string
  customer_id?: string
  closeBtnModalFunction: () => void
  callBack: () => void
}

export default function TaskTargetTime({
  taskByCostCenter,
  closeBtnModalFunction,
  taskTargetTime,
  projectName,
  project_id,
  customer_id,
  projectTargetTime,
  callBack
}: ComponentProps) {
  const [opened, { open, close }] = useDisclosure(false)
  const [isUpdate, setIsupdate] = useState(false)
  // Get translation public configuration information
  const word_translation = WordTranslationComponent()
  const [defaultRoleData, setDefaultRoleData] = useState<SelectRole[]>([])
  const [costCenterData, setCostCenterData] = useState<SelectRole[]>([])
  const [taskCategoryList, setTaskCategoryList] = useState<SelectRole[]>([])
  const [costCenterId, setCostCenterId] = useState('')
  // Define all the list of employees you can choose
  const [userList, setUserList] = useState<SelectRole[]>([])
  // Define the user list (including the user's detailed information)
  const [allUserList, setAllUserList] = useState<UserAllList[]>([])

  //Correct time format <FUNCTION>
  const handleTimeInputBlur = async (event: React.FocusEvent<HTMLInputElement>, item: TaskByCostCenterInterface) => {
    const value = TimeHHSS(event)
    const old_value = formatTimeHHSS(item.task_target_time)
    if (timeRegex.test(value) == false) {
      ClientWarningHint(word_translation.validata_time)
      return false
    }
    if (old_value != value) {
      let requestData = {
        id: item.id_task,
        target_time: value
      }
      const response = await requestTask(requestData, 'PUT')
      HintInfo(response.data)
      if (response.data.code == 200) {
        setIsupdate(false)
        callBack()
      } else {
        return false
      }
    }
  }

  const getCostCenter = async () => {
    // Call the Get Cost Center General Selection function and set the cost center data
    setCostCenterData(await getCostCenterGeneralSelectFunction({have_all_costcenter:"false"}))
  }
  const getDefaultRole = async () => {
    // Call the Get Cost Center General Selection function and set the cost center data
    setDefaultRoleData(await getDefaultFunction())
  }
  /**
   * Retrieves the task category list asynchronously and sets it in the component state.
   * @returns {Promise<void>} A promise that resolves when the task category list is retrieved and set.
   */
  const getTaskCategoryList = async (): Promise<void> => {
    setTaskCategoryList(await getAllTaskCategoryGeneralFunction({ list: true }))
  }
  /**
   * Get a list of users
   */
  const getUser = async () => {
    // Call the Get User Universal Selection function and set the user list
    let userList = await getUserGeneralFunction('all_info')
    setUserList(
      userList.map((item: { id_user: string; username: any }) => ({
        value: item.id_user as string,
        label: item.username
      }))
    )
  }

  const getProjectDeatil = async (project_id: string) => {
    try {
      const projectInfo = await getProjectDetailByIdFunction(project_id)
      if (projectInfo && projectInfo.project_employee_roles && Array.isArray(projectInfo.project_employee_roles)) {
        setAllUserList(
          projectInfo.project_employee_roles.map((_item: any, _index: any) => {
            return {
              id_user: _item.id_user,
              username: _item.username,
              email: _item.email,
              phone: _item.phone,
              avatar: _item.avatar
            }
          })
        )
      } else {
        setAllUserList([]) // Set an empty array if projectInfo or project_employee_roles is not as expected
      }
    } catch (error) {}
  }

  useEffect(() => {
    // getProjectDeatil(project_id as string)
  }, [project_id])

  const openAddTaskForm = (value = '') => {
    setCostCenterId(value)
    getCostCenter()
    getDefaultRole()
    getTaskCategoryList()
    getUser()
    open()
  }
  const quickCreateTaskCallBack = () => {
    callBack()
    close()
  }

  return (
    <Box>
      {taskByCostCenter.map((item, index) => {
        return (
          <Box key={index}>
            <Group position={'apart'}>
              <Box my={10} pl={10} className="title_2">
                {item.name}
              </Box>
              <UniversalButton
                btn_icon={<IconCirclePlus size={16} />}
                btn_bg_color={'rgb(64, 192, 87)'}
                btn_text={word_translation.task}
                btn_size="xs"
                callBack={() => openAddTaskForm(item.id_costcenter.toString())}
              />
            </Group>
            <Group pl={30} spacing={16}>
              {item.list.map((item1, index1) => {
                return (
                  <Box>
                    <TableTextInputTitle title={item1.identifier} />
                    <TextInput
                      onBlur={event => handleTimeInputBlur(event, item1)}
                      size="xs"
                      w={70}
                      onClick={() => setIsupdate(true)}
                      placeholder={word_translation.Input}
                      defaultValue={formatTimeHHSS(item1.task_target_time)}
                    />
                  </Box>
                )
              })}
            </Group>
            <Divider mt={10} size="xs" />
          </Box>
        )
      })}
      <Group position="right" mt={6}>
        <UniversalButton
          btn_icon={<IconCirclePlus size={16} />}
          btn_bg_color={'rgb(64, 192, 87)'}
          btn_color="#FFF"
          btn_size="xs"
          btn_text={word_translation.add_costcenter}
          callBack={() => openAddTaskForm('')}
        />
      </Group>
      {opened && (
        <QuickCreateTask
          opened={opened}
          close={close}
          project_id={project_id}
          order_id=""
          customer_id={customer_id}
          project_name={projectName}
          c_userList={allUserList}
          userList={userList}
          costCenterId={costCenterId}
          costCenterData={costCenterData}
          taskCategoryList={taskCategoryList}
          callBackFunction={quickCreateTaskCallBack}
          defaultRoleData={defaultRoleData}
        />
      )}
      <Box w="100%">
        <Group position="apart" align='flex-end'>
          <Group>
            <Box ta="end" lh={2} fz={12} fw={600}>
              <Box>Project Target Hour:</Box>
              <Box>Total Task Target Hour:</Box>
            </Box>
            <Box>
              <Box>
                <Badge color="blue" radius="sm">
                  {taskTargetTime}
                </Badge>
              </Box>
              <Box>
                <Badge color="blue" radius="sm">
                  {taskTargetTime}
                </Badge>
              </Box>
            </Box>
          </Group>
          <Group>
            <SubmitBtn btn_size="xs" callBack={closeBtnModalFunction} />
          </Group>
        </Group>
      </Box>
    </Box>
  )
}
