import { Tabs, Paper, Box } from '@mantine/core'
import {
  IconPhoto,
  IconLicense,
  IconLocation,
  IconCoins,
  IconBrandCodepen,
  IconSitemap,
  IconBrandBinance,
  IconBrandCoreos,
  IconAffiliate,
  IconNote,
  IconMagnet,
  IconAlignBoxRightStretch
} from '@tabler/icons-react'
import ModulesSettings from './modules/modules_settings'
import CompanySettings from './company/company_settings'
import CurrencySettings from './corrency/corrency_settings'
import GlobalCoreSettings from './global_core/global_core_settings'
import CostCenterSettings from './cost_center/cost_center_setting'
import CountriesSettings from './country/countries_settings'
import { FormattedMessage, useIntl } from 'react-intl'
import PageTitle from '../../../ttcomponents/PageTitle/PageTitle'
import { FormEvent, useEffect, useState } from 'react'
import GlobalCoreSetting from '../../../utils/globalCortSetting'
import WordTranslationComponent from '../../../utils/language_pack/words'
import PermissionIdentifier from '../../assignments/permission/permission_identifier/permission_identifier'
import PermissionAction from '../../assignments/permission/permission_action/permission_action'
import PermissionRoleIndex from './permission'
import PermissionActionIndex from './permission_action'
import LogSettings from './log/log_settings'
import { set } from 'date-fns'
import PermissionIdentifierIndex from './permission_identifier'
import AdminIndex from './permission/admin'
import CheckPermissionTools from '../../../utils/permission'
import { NotPermission } from '../../../ttcomponents/NoPermission'
import { Development } from '../../../ttcomponents/NoPermission/development'
import GlobalCoreRoleIndex from './permission/core_role'
import { requestUserPermissionShow } from '../../../api'

// Settings component definition
export default function Settings() {
  // Get the default unchanged configuration
  const globalCoreSetting = GlobalCoreSetting()
  // Get translation public configuration information
  const word_translation = WordTranslationComponent()

  const [randNumber, setRandNumber] = useState(1)

  const Perm = CheckPermissionTools()
  const corePremission: any = {
    create: Perm.permission_read,
    update: Perm.permission_update,
    read: Perm.permission_read,
    delete: Perm.permission_delete
  }

  const [currentType, setActiveTab] = useState<string | null>('company_settings')

  const [title, setTitle] = useState<string | null>('company_settings')
  const [isQueryPermission, setIsQueryPermission] = useState<boolean>(false)

  // Settings configuration
  // const settings = {
  //   links: [

  // };
  // 定义一个函数来生成根据用户权限过滤后的链接数组
  const generateLinks = () => {
    return [
      {
        label: 'settings',
        title: word_translation.settings,
        link: '#',
        view: <CompanySettings is_load_str={currentType as string} />,
        icon: IconBrandCodepen
      },
      {
        label: 'company_settings',
        title: word_translation.company_info,
        link: '/company_settings',
        view: <CompanySettings is_load_str={currentType as string} />,
        icon: IconBrandCodepen
      },
      {
        label: 'public_settings',
        title: word_translation.public_settings,
        link: '/log_settings',
        view: <Development />,
        icon: IconNote
      },
      {
        label: 'currency_set',
        title: word_translation.country_set,
        link: '/currency_settings',
        view: <CurrencySettings is_load_str={currentType as string} />,
        icon: IconCoins
      },
      {
        label: 'country_set',
        title: word_translation.country_set,
        link: '/country_settings',
        view: <CountriesSettings is_load_str={currentType as string} />,
        icon: IconBrandBinance
      },
      {
        label: 'global_core',
        title: word_translation.global_core,
        link: '/global_core_settings',
        view: <GlobalCoreSettings is_load_str={currentType as string} />,
        icon: IconBrandCoreos
      },
      {
        label: 'costcenter_settings',
        title: word_translation.costcenter_settings,
        link: '/cost_center_setting',
        view: <CostCenterSettings is_load_str={currentType as string} />,
        icon: IconAffiliate
      },
      // { label: 'server_settings',  title:word_translation.server_settings,link: '/log_settings', view:  <Development  />, icon: IconNote },
      {
        label: 'email_settings',
        title: word_translation.email_settings,
        link: '/log_settings',
        view: <Development />,
        icon: IconNote
      },
      // { label: 'network_settings',  title:word_translation.network_settings,link: '/log_settings', view:  <Development  />, icon: IconNote },
      {
        label: 'security_settings',
        title: word_translation.security_settings,
        link: '/log_settings',
        view: <Development />,
        icon: IconNote
      },
      {
        label: 'modules_serve',
        title: word_translation.modules_manage,
        link: '/modules_settings',
        view: <ModulesSettings is_load_str={currentType as string} />,
        icon: IconSitemap
      },
      {
        label: 'server_info',
        title: word_translation.server_info,
        link: '/log_settings',
        view: <Development />,
        icon: IconNote
      },
      {
        label: 'license_info',
        title: word_translation.license_info,
        link: '/log_settings',
        view: <Development />,
        icon: IconNote
      },

      // { label: 'application_settings',  title:word_translation.application_settings,link: '/log_settings', view:  <Development  />, icon: IconNote },
      // { label: 'developer_settings',  title:word_translation.developer_settings,link: '/log_settings', view:  <Development  />, icon: IconNote },
      // { label: 'dba_settings',  title:word_translation.dba_settings,link: '/log_settings', view:  <Development  />, icon: IconNote },
      {
        label: 'permission_identifier_set',
        title: word_translation.permission_identifier_set,
        link: '/permission_identifier',
        view: <PermissionIdentifierIndex is_load_str={currentType as string} />,
        icon: IconLocation
      },
      {
        label: 'permission_action_set',
        title: word_translation.permission_action_set,
        link: '/permission_action ',
        view: <PermissionActionIndex is_load_str={currentType as string} />,
        icon: IconLicense
      },

      // { label: 'contact_role',  title:word_translation.contact_name,link: '/log_settings', view:  <Development  />, icon: IconNote },

      {
        label: 'permissions_settings',
        title: word_translation.permissions_settings,
        link: '#',
        view: <CompanySettings is_load_str={currentType as string} />,
        icon: IconAlignBoxRightStretch
      },

      {
        label: 'management_role',
        title: word_translation.management_role,
        link: '/permission_identifier',
        view:
          Perm.admin || Perm.special_user_permission_create ? (
            <GlobalCoreRoleIndex
              rand={randNumber}
              is_load_str_top={currentType as string}
              Permission={corePremission}
              is_load_str={currentType as string}
            />
          ) : (
            <NotPermission />
          ),
        icon: IconLicense
      },
      // { label: 'log_settings',  title:word_translation.log_settings,link: '/log_settings', view:
      // Perm.admin? <LogSettings is_load_str={currentType as string} />: <NotPermission/>, icon: IconNote },
      {
        label: 'module_permissions',
        title: word_translation.module_permissions,
        link: '/permission_identifier',
        view: <PermissionRoleIndex is_query={isQueryPermission} is_load_str={currentType as string} />,
        icon: IconLicense
      }

      // { label: 'costcenter_leader_permission',  title:word_translation.permissions,link: '/permission_identifier',
      //   view: Perm.core_create ?  <AdminIndex rand={randNumber} is_load_str_top={currentType as string} Permission={corePremission} is_load_str={currentType as string} />: <NotPermission/>, icon: IconLicense },
    ].filter(item => {
      console.log(item, 222)
      if (Perm.admin) {
        return true
      }
      switch (item.label) {
        case 'settings':
          return (
            Perm.company_read ||
            Perm.public_holiday_read ||
            Perm.currency_read ||  Perm.currency_update ||
            Perm.global_seting_read ||
            Perm.country_read ||
            Perm.cost_center_read ||
            Perm.email_read ||
            Perm.security_read ||
            Perm.module_service_read ||
            Perm.license_read ||
            Perm.server_read ||
            Perm.action_read ||
            Perm.identifier_read
          )
          break
        case 'company_settings':
          return Perm.company_read
          break
        case 'public_settings':
          return Perm.public_holiday_read
          break
        case 'currency_set':
          return Perm.currency_read || Perm.currency_update|| Perm.currency_create
          break
        case 'global_core':
          return Perm.global_seting_read
          break
        case 'country_set':
          return Perm.country_read
          break
        case 'costcenter_settings':
          return Perm.cost_center_read
          break
        case 'email_settings':
          return Perm.email_read
          break
        case 'security_settings':
          return Perm.security_read
          break
        case 'modules_serve':
          return Perm.module_service_read
        case 'license_info':
          return Perm.license_read
          break
        case 'server_info':
          return Perm.server_read
          break
        case 'permission_identifier_set':
          return Perm.identifier_read
          break
        case 'permission_action_set':
          return Perm.action_read
          break
        case 'management_role':
          return Perm.special_user_permission_create
          break
        case 'permissions_settings':
          return (
            Perm.special_user_permission_create ||
            Perm.core_open_permissions ||
            Perm.assignments_open_permissions ||
            Perm.leaveRequest_open_permissions ||
            Perm.timeTracker_open_permissions ||
            Perm.report_open_permissions
          )
          break
        case 'module_permissions':
          return (
            Perm.core_open_permissions ||
            Perm.assignments_open_permissions ||
            Perm.leaveRequest_open_permissions ||
            Perm.timeTracker_open_permissions ||
            Perm.report_open_permissions
          )
          break
      }

      return true
    })
  }

  // 使用 generateLinks 函数生成 links 数组
  const settings = {
    links: generateLinks()
  }

  // const setSettingInfo()

  const getIsShowQueryBox = async () => {
    try {
      var response = await requestUserPermissionShow()
      var result = response.data
      if (result.code == 200) {
        setIsQueryPermission(result.data.show)
      } else {
        setIsQueryPermission(false)
      }
    } catch {
      setIsQueryPermission(false)
    }
  }

  useEffect(() => {
    getIsShowQueryBox()
  }, [])

  useEffect(() => {}, [])

  const setActiveTabFunction = (value: any) => {
    setActiveTab(value)
    setRandNumber(randNumber + 1)
    settings.links.map(item => {
      if (value == item.label) {
        setTitle(item.title)
      }
    })
  }

  return (
    <Paper px={20}>
      <PageTitle title={`${word_translation.settings} - ${title}`} />
      {/* Tabs for different settings */}
      <Tabs
        mt={10}
        h="85vh"
        keepMounted={false}
        defaultValue={currentType}
        variant="outline"
        onTabChange={event => setActiveTabFunction(event)}
        orientation="vertical"
      >
        <Tabs.List position="center">
          {/* Render individual tabs with icons and labels */}
          {settings.links.map((item, index) => (
            <Tabs.Tab
              key={item.label}
              disabled={item.link == '#'}
              value={item.label}
              icon={item.link == '#' ? <item.icon size={20} slope={1.5} /> : null}
            >
              <Box ml={item.link == '#' ? 10 : 20} fw={item.link == '#' ? 600 : 400} fz={item.link == '#' ? 16 : 13}>
                {word_translation[item.label as keyof typeof word_translation]}
              </Box>
            </Tabs.Tab>
          ))}
        </Tabs.List>
        {/* Render corresponding panels for each tab */}
        {settings.links.map((item, index) => (
          <Tabs.Panel h="80vh" pl={20} key={index} value={item.label} pt="xs">
            {item.view}
          </Tabs.Panel>
        ))}
      </Tabs>
    </Paper>
  )
}
