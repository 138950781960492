import { ActionIcon,Text, Avatar, Box, Grid, Paper, Tooltip, Progress, Group, Badge, Modal } from "@mantine/core";
import PageTitle from "../../../../ttcomponents/PageTitle/PageTitle";
import WordTranslationComponent from "../../../../utils/language_pack/words";
import PaginationA from "../../../../ttcomponents/Pagination/PaginationA";
import { IconAdjustmentsHorizontal, IconArrowBigDownLine, IconArrowBigUpLine, IconArrowsMaximize, IconChecklist, IconEdit, IconEye, IconPencil, IconPlaylist, IconPlaylistAdd, IconSubtask, IconTrash } from "@tabler/icons-react";
import '../css/index.css'
import UniversalButton from "../../../../ttcomponents/Button/UniversalButton";
import AssignmentOrderRow from "../order/order_item_row";
import { Fragment, useEffect, useRef, useState } from "react";
import { IconGripVertical } from "@tabler/icons-react";
import AssignmentTaskRow from "../task/task_item_row";
import ColorSelect from "../color_modal";
import { useDisclosure } from "@mantine/hooks";
import AssignmentsOrderProductList from "./assignments_order_product_list_modal/assignments_order_product_list";
import { AssignmentsInterface, ProjectDataInterface } from "../../../../interface/Iprojects";
import { HintInfo, getAssignmentStatusColorByValue, getAssignmentStatusLabelByValue } from "../../../../utils/function";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../state_management/store";
import AvatarGroupComponnet from "../../../../ttcomponents/AvatarGroup/AvatarGroup";
import ModalTitleText from "../../../../ttcomponents/ModalTitleText/ModalTitleText";
import { UserAllList } from "../../../../interface/Iuser";
import { SelectRole } from "../../../../interface/Icommon";
import { getUserGeneralFunction } from "../../../../api/common_request";
import { getCustomerListGeneralFunction, getOrderDetailByIdFunction, getProjectDetailByIdFunction } from "../../../../api/assignments_request";
import OrderFrom from "./order_create";
import { ChildMethods } from "../project/project_create";
import TableTd from "../../../../ttcomponents/TableTrTd/TableTd";
import EditIcon from "../../../../ttcomponents/EditIcon/EditIcon";
import { OrderDataInterface } from "../../../../interface/Iorder";
import { setRefreshStatus } from "../../../../state_management/features/assignments_refresh_status/assignmentsRefreshStatusSlice";
import { modals } from "@mantine/modals";
import GlobalCoreSetting from "../../../../utils/globalCortSetting";
import { requestOrder } from "../../../../api/index_v2";
import DeleteIcon from "../../../../ttcomponents/DeleteIcon/DeleteIcon";
import TaskFrom from "../task/create_task";
import ViewIcon from "../../../../ttcomponents/ViewIcon/ViewIcon";


interface ComponentProps{
    leve:number;
    index?:number;
    row:AssignmentsInterface;
}
export default function AssignmentOrdertRow({leve,index,row}:ComponentProps){
    
  const dispatch = useDispatch();
    // Get translation public configuration information
  const word_translation = WordTranslationComponent();
  const [openSelectColorStatus, { open: openSelectColorModalFunction, close: closeSelectColorModalFunction }] =useDisclosure(false);
  
  // Get the global configuration
  const globalCoreSetting = GlobalCoreSetting();

  const childRef = useRef<ChildMethods>(null);
  //  Define current  project info  in the page
  const [currentProjectInfo,setCurrentProjectInfo] = useState<ProjectDataInterface>();

  const [allUserList, setAllUserList] = useState<UserAllList[]>([]);

  // Define all the customer lists that can be selected
  const [customerList, setCustomerList] = useState<SelectRole[]>([]);
  // Define all the list of employees you can choose
  const [userList, setUserList] = useState<SelectRole[]>([]);

 //  Define current  order info  in the page
 const [currentOrderInfo,setCurrentOrderInfo] = useState<OrderDataInterface>();

  const [openProductStatus, { open: openProductFunction, close: colseProductFunction }] =useDisclosure(false);

  const [openOrderFormStatus, { open: openCurrentPageOrderForm, close: closeOrderPageFormFunction }] = useDisclosure(false);

  const [isExpanded, setIsExpanded] = useState(false);
  // Task Modal
  
  const [openTaskFormStatus, { open: openCurrentPageTaskForm, close: closeTaskPageFormFunction }] = useDisclosure(false);

  //展开单个的记录
  const expandItemFunction =()=>{
    setIsExpanded(!isExpanded)
  }

  // 获取redux store中的值
  const expandStatus = useSelector((state:RootState)=>state.expandStatus.expandStatus)

 // 用redux中的状态值来控制组件的展开状态
  useEffect(()=>{
    setIsExpanded(expandStatus)
  },[expandStatus])

  /**
   * useState Hook用于管理组件的状态
   * @param {boolean} isHovered - 当前鼠标是否悬停在元素上
   * @returns {Array} - 包含isHovered和setIsHovered两个值的数组
   */
  const [isHovered, setIsHovered] = useState(false);


  /**
   * 当鼠标移入元素时，设置isHovered为true
   */
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  /**
   * 当鼠标移出元素时，设置isHovered为false
   */
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

     

 /**
 * Get a list of users
 */
 const getUser = async() =>{
    // Call the Get User Universal Selection function and set the user list
    let userList = await getUserGeneralFunction('all_info')
    setUserList(userList.map((item: { id_user: string; username: any }) => ({
        value: item.id_user as string,
        label: item.username,
    })));
    setAllUserList(userList);
}

const getCustomerList = async () => {
    setCustomerList(await getCustomerListGeneralFunction(""));
};

// get a  product detail by  project_id
const getProjectDeatil = async (project_id:number) =>{
    setCurrentProjectInfo(await getProjectDetailByIdFunction(project_id))
}
   
const getOrderDeatil = async (order_id:number) =>{
        const orderIndo =  await getOrderDetailByIdFunction(order_id)
        //  如果当前订单存在项目ID
        if(orderIndo.project_id){
            // form.setFieldValue('project_id',orderIndo.project_id.toString())
            getProjectDeatil(orderIndo.project_id)
        }

        setCurrentOrderInfo(orderIndo);
}
   

const editHandleFunction = (row:AssignmentsInterface)=>{
  openCurrentPageOrderForm();
  getOrderDeatil(row.order_id as number)
  getUser();
  getCustomerList();
}
const createTaskFunction = (row:AssignmentsInterface)=>{
  openCurrentPageTaskForm();
    getOrderDeatil(row.order_id as number)
    getUser();
    getCustomerList();
}

// Fetches the delete  task
const handleDel = async (row: AssignmentsInterface) => {
  modals.openConfirmModal({
      title: word_translation.delete_title,
      centered: true,
      children: (
          <Text size="sm" mt={12}>
              <p>  {word_translation.order_name} : {row.order_name}</p>
          </Text>
      ),
      labels: globalCoreSetting.DeleteOpenConfirmModaLabels,
      confirmProps: globalCoreSetting.DeleteOpenConfirmModaConfirmProps,
      onConfirm: async () => {
          try {
              const deleteTaskResult = await requestOrder({ id: row.order_id }, "DELETE");
              HintInfo(deleteTaskResult.data);
              if (deleteTaskResult.data.code == 200) {
                  dispatch(setRefreshStatus(true));
              }
          } catch (error) {
              // Handle any errors that occur during the API call
              console.error('Error fetching project list:', error);
              // Optionally, you can throw the error or handle it as needed
              throw error;
          }

      },
  });
};


  // Project callback Function <FUNCTION>
  const orderFormCallbackFunction =(project_id:any)=>{
    if(project_id !== '-1'){
        closeTaskPageFormFunction();
        setIsExpanded(true)
        dispatch(setRefreshStatus(true));
    }
}
  /**
   * 定义行的class
   */
  const  tdClass = (leve == 1 || index == 0 ) ? '' : 'border-t-1'

  const delAbled : boolean = (row.task_count == 0)
  return (
    <>
    
    <tr  className={`text-center assignment_laypuout tr-container   ${leve == 1 ? "assignment_project_item" : 'assignment_order_item'}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
     >
        <td style={{}} className={tdClass} colSpan={leve == 1 ? 2 : 3} >
            <Box className="assignment_text_title">{word_translation.order_name}</Box>
            <Box className="assignment_text_content"> {row.order_name}</Box>
        </td>
        <td className={tdClass} >
            <Box>
                <ActionIcon onClick={() => expandItemFunction()}>
                    <Tooltip label={word_translation.expand_tips}>
                        {isExpanded ?   <IconArrowBigUpLine size="1.2rem"  color='#7D7D7D' stroke={1.5} /> :<IconArrowBigDownLine size="1.2rem"  color='#7D7D7D' stroke={1.5} /> }
                    </Tooltip>
                </ActionIcon> 
            </Box>
        </td>
        {leve == 1 ? 
        
         <TableTd class_name={tdClass}  font_color={row.text_color}  value= {leve == 1 ?  row.customer_name:<>&nbsp;</>} title={word_translation.customer}  />:''}
        <TableTd class_name={tdClass}   value={row.order_no} title={word_translation.order_no} colspan={2} />
        <TableTd class_name={tdClass}  value={  <AvatarGroupComponnet UserInfo={row.user_info}/>} title={word_translation.order_leader}  value_class="img-center"/>
        <TableTd class_name={tdClass}   value={   <Badge  color= {getAssignmentStatusColorByValue(row.status_id)}> {getAssignmentStatusLabelByValue(row.status_id)}</Badge>}  title={word_translation.status}  />
        <TableTd class_name={tdClass}   value={row.delay_days} title={word_translation.delivery_schedule}  />
        <TableTd class_name={tdClass}   value={row.delay_days} title={word_translation.delivery_time}  />
        <TableTd class_name={tdClass}   value={row.delay_days} title={word_translation.delay_falg}  />
        <TableTd
             class_name={tdClass}   
             title={word_translation.completion}  
             value_class="img-center" 
             value={<> <Text mb={2} ><span className="timevisual_blue"> {row.completion}% </span></Text>
                       <Progress
                            value={row.completion}
                            size="md"
                            color="blue" /> </>} />
      
        <TableTd class_name={tdClass}   value={row.cost} title={word_translation.cost}  />      
        <TableTd class_name={tdClass}   value={row.budget} title={word_translation.budget}  />
        <TableTd class_name={tdClass}   value="&nbsp;" title="&nbsp;"  />      
        <TableTd class_name={tdClass}   value={row.task_count} title={word_translation.tasks}  />
        <td className={tdClass}  style={{borderTopRightRadius:10,borderBottomRightRadius:10}}>
            <Box  className="assignment_text_title">{word_translation.option}</Box>
            <Group spacing={0} position="center">
                <ActionIcon onClick={openProductFunction}>
                        <Tooltip label={word_translation.edit_tips}>
                            <IconPlaylistAdd size="1.2rem" color="#7D7D7D" stroke={1.5} />
                        </Tooltip>
                </ActionIcon>
               <EditIcon callBack={()=>editHandleFunction(row)} />
               <ViewIcon callBack={()=>editHandleFunction(row)} />
                <ActionIcon onClick={() =>createTaskFunction(row)}>
                        <Tooltip label={word_translation.create_task}>
                            <IconSubtask size="1.2rem" color="#7D7D7D" stroke={1.5} />
                        </Tooltip>
                </ActionIcon>
                <DeleteIcon  disabled={delAbled} callBack={()=>handleDel(row)}/>
            </Group>
        </td>
        {isHovered && (
        <ActionIcon className="hovered-button-cell" style={{  borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px', borderStyle:"solid"}} onClick={openSelectColorModalFunction}>
              <Tooltip style={{border:0}}label={word_translation.edit_tips}>
                <IconGripVertical size="1.2rem" color="#7D7D7D"  stroke={1.5} />
              </Tooltip>
              {/* ... other icons ... */}
            </ActionIcon>
       )}
    </tr>
    

       <Modal
              opened={openTaskFormStatus}
              onClose={closeTaskPageFormFunction}
              closeOnClickOutside={false}
              title={<ModalTitleText title={`${word_translation.edit} - `} />}
              size='90%'
              radius={10}
            >
                {/* Form Madal */}
                <TaskFrom
                    callBackFunction={orderFormCallbackFunction}
                    ref={childRef} 
                    cannotBeChanged={currentOrderInfo?.id_order}
                    customerList={customerList}
                    c_allUserList={allUserList}
                    c_userList={userList} />
        
            </Modal>
   
    <Modal
        opened={openOrderFormStatus}
        onClose={closeOrderPageFormFunction}
        closeOnClickOutside={false}
        title={<ModalTitleText title={`${word_translation.edit} - ${currentOrderInfo?.order_no}`} />}
        size='90%'
        radius={10}
      >
        {/* Form Madal */}
      <OrderFrom 
        callBackFunction={orderFormCallbackFunction} 
        ref={childRef} 
        orderInfoFromEditPage={currentOrderInfo}
        customerList={customerList} 
        c_allUserList={allUserList} 
        c_userList={userList} /> 

      </Modal>
         { isExpanded &&( leve == 2 ? row.child_list.map((_,index)=>(
             <Fragment key={index}>  <AssignmentTaskRow row={_} leve={3} /></Fragment>
            )) :row.child_list.map((_,index)=>(
                <AssignmentTaskRow index={index}  row={_} leve={2} />
            ))     
            )}
    {/* {[...Array(1)].map((_,index)=>(
                    <AssignmentTaskRow leve={2} />
                ))} */}
    <AssignmentsOrderProductList is_load={true} objectsUsed={word_translation.task} successCallback={()=>{console.log('sucess')}} closeFunction={colseProductFunction} openFormStatus={openProductStatus} />
    {/* <ColorSelect objectsUsed={word_translation.task} successCallback={()=>{console.log('sucess')}} closeFunction={closeSelectColorModalFunction} openFormStatus={openSelectColorStatus} /> */}
    </>
    )
}

