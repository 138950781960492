import {
  Paper,
  TextInput,
  Box,
  Group,
  Text,
  Modal,
  Grid,
  Table,
  ActionIcon,
  LoadingOverlay,
  Tooltip,
  Flex,
  Badge,
  Dialog,
  Button,
  Textarea,
  Select,
  Tabs,
} from "@mantine/core";
import { FormEvent, useEffect, useState } from "react";
import { Supplier, SuppliersData } from "../../../../interface/Iinventory";
import { useDisclosure } from "@mantine/hooks";

import PageTitle from "../../../../ttcomponents/PageTitle/PageTitle";
import {
  IconEdit,
  IconPencil,
  IconPhoto,
  IconSearch,
  IconTrash,
  IconUserPlus,
  IconUsersMinus,
  IconX,
} from "@tabler/icons-react";
import CreateBtn from "../../../../ttcomponents/Button/CreateBtn";
import { useIntl } from "react-intl";
import { useForm } from "@mantine/form";
import { modals } from "@mantine/modals";
import {
  regGetProduct,
  regGetProductCategory,
  regGetProductPurchase,
  regGetProductPurchaseCheck,
  regGetReturnProductPurchase,
  regGetSupplier,
  regProduct,
  regProductPurchase,
  regSupplier,
} from "../../../../api";
import { HintInfo, formatDateUpgraded } from "../../../../utils/function";
import SearchBox from "../../../../ttcomponents/SearchBox/SearchBox";
import { IPagination } from "../../../../interface/Ipagintion";
import PaginationA from "../../../../ttcomponents/Pagination/PaginationA";
import { IProductResponse, Product } from "../../../../interface/IProduct";
import { DetailCustome } from "../../../../public/svg/DetailCustome";
import SupplierSelect from "../../../../ttcomponents/Supplier/SupplierSelect";
import { ICategory } from "../../../../interface/IProductCategory";
import {
  Cancellation,
  Purchase,
  Receipt,
} from "../../../../public/svg/ProductOrder";
import {
  IProductProcurementData,
  IProductProcurementOrderData,
} from "../../../../interface/IProductProcurement";
import { notifications } from "@mantine/notifications";
import PickingOutBoundProductIndex from "../picking_out_bound_product/picking_out_bound_product";
import DeliveryToCustomerIndex from "../delivery_to_customer/delivery_to_customer";
import InternalUseProductIndex from "../internal_use_product/internal_use_product";
import RentProductIndex from "../rent_product/rent_product";
import OrderProductIndex from "../../customer_order/product_procurement/order_product";
import OrderProductDeliveryIndex from "../../customer_order/product_procurement/order_product_delivery";
import ReturnRecordsIndex from "../return_records/return_records";

export default function DeliveredManagementIndex() {
  // useIntl for multiple language
  const intl = useIntl();
  // Edit modal status
  const [openedFrom, { open: openFrom, close: closeFrom }] =
    useDisclosure(false);

  // return the goods
  const [returnOpened, { toggle: openReturnOpened, close: closeReturnOpened }] =
    useDisclosure(false);

  // contact modal status
  const [
    ProductProcurementShow,
    { open: openProductProcurementShow, close: colseProductProcurementShow },
  ] = useDisclosure(false);

  const [loading, setLoading] = useState(false);
  // Form type
  const [formType, setFormType] = useState("create");
  // Form type

  const [currentRowInfo, setCurrentRowInfo] = useState<
    IProductProcurementOrderData | undefined
  >();
  const [productCategory, setProductCategory] = useState<ICategory[]>([]);
  const [returnNotes, setReturnNotes] = useState("");

  // define the supplier list
  const [productProcurementList, setProductProcurementList] =
    useState<IProductProcurementData>({
      total: 0,
      data: [], // Use the data property from the first item in the mock data array
    });

  const [pagination, setPagination] = useState<IPagination>({
    search: "",
    page: 1,
    pagesize: 10,
    sortBy: "",
    sortOrder: "asc",
    query: {},
  });
  // Receive the callback return data, search for content

  //Pagling recovery trigger update Terrament recovery trigger update
  const ReceivePaginationABack = (page: number) => {
    setPagination((prevpagination) => ({
      ...prevpagination,
      page: page,
    }));
  };
  // set search value
  const [search, setSearch] = useState("");

  // Receive the callback return data, search for content
  const ReceiveSearchBack = (value: string | number) => {
    setPagination((prevpagination) => ({
      ...prevpagination,
      search: value,
      page: 0,
      query: {},
    }));
  };
  // Use the USEEFFECT hook. When Search changes, call the AjaxCurrenTList function
  // useEffect(() => {

  // }, [currentType]);

  // Returntreatment
  const returnTreatment = async (row: IProductProcurementOrderData) => {
    setCurrentRowInfo(row);
    if (row.flag != 2) {
      notifications.show({
        color: "yellow",
        icon: <IconX />,
        message: "This order cannot be returned",
      });
    } else {
      openReturnOpened();
    }
  };

  // Create supplier
  const handleCallback = async () => {
    openProductProcurementShow();
    setCurrentRowInfo(undefined);
  };

  // function setSelectValueFunction(value: string | null): void {
  //     setCurrentType(value as string)
  //     console.log(currentType)
  // }

  const [currentType, setActiveTab] = useState<string | null>("pick");

  console.log(currentType);
  // list all the suppliers
  return (
    <Box>
      <Paper
        px={20}
        mih={500}
        pb={10}
        w={"100%"}
        style={{ position: "relative" }}
      >
        <PageTitle title="DELIVERY MANAGEMENT" lang_id="DELIVERY MANAGEMENT" />
     
        <Tabs mt={5} defaultValue={currentType} onTabChange={setActiveTab}>
          <Tabs.List>
            <Tabs.Tab value="service_charge" icon={<IconPhoto size="0.8rem" />}>
              {intl.formatMessage({
                id: "Delivery To Customer With Service",
                defaultMessage: "Delivery To Customer With Service",
              })}
            </Tabs.Tab>
            <Tabs.Tab value="delivery" icon={<IconPhoto size="0.8rem" />}>
              {intl.formatMessage({
                id: "Delivery To Customer",
                defaultMessage: "Delivery To Customer",
              })}
            </Tabs.Tab>
            <Tabs.Tab value="pick" icon={<IconPhoto size="0.8rem" />}>
              {intl.formatMessage({
                id: "Picking Out Bound",
                defaultMessage: "Picking Out Bound",
              })}
            </Tabs.Tab>
            <Tabs.Tab value="internal" icon={<IconPhoto size="0.8rem" />}>
              {intl.formatMessage({
                id: "Internal Use",
                defaultMessage: "Internal Use",
              })}
            </Tabs.Tab>
            <Tabs.Tab value="rent" icon={<IconPhoto size="0.8rem" />}>
              {intl.formatMessage({
                id: "Rent",
                defaultMessage: "Rent",
              })}
            </Tabs.Tab>
            <Tabs.Tab value="return" icon={<IconPhoto size="0.8rem" />}>
              {intl.formatMessage({
                id: "Return",
                defaultMessage: "Return",
              })}
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="service_charge" pt="xs">
            <OrderProductIndex is_load_str={currentType as string} />
          </Tabs.Panel>

          <Tabs.Panel value="delivery" pt="xs">
            <OrderProductDeliveryIndex is_load_str={currentType as string} />
          </Tabs.Panel>

          <Tabs.Panel value="pick" pt="xs">
            <PickingOutBoundProductIndex is_load_str={currentType as string} />
          </Tabs.Panel>
          <Tabs.Panel value="internal" pt="xs">
            <InternalUseProductIndex is_load_str={currentType as string} />
          </Tabs.Panel>
          <Tabs.Panel value="rent" pt="xs">
            <RentProductIndex is_load_str={currentType as string} />
          </Tabs.Panel>
          <Tabs.Panel value="return" pt="xs">
            <ReturnRecordsIndex is_load_str={currentType as string} />
          </Tabs.Panel>
        </Tabs>
        <LoadingOverlay visible={loading} overlayBlur={2} />
        {/* {currentType === 'pick' ? (
                    <PickingOutBoundProductIndex is_load_str={currentType} />
                ) : currentType === 'delivery' ? (
                    // <DeliveryToCustomerIndex is_load_str={currentType} />
                    <OrderProductIndex   is_load_str={currentType} />
                ) : currentType === 'internal' ? (
                    <InternalUseProductIndex is_load_str={currentType} />
                ) : currentType === 'rent' ? (
                    <RentProductIndex is_load_str={currentType} />
                ) : null}
                */}
      </Paper>
    </Box>
  );
}
