import {
  reGetDomains,
  reGetEmployeeCustomAttributeList,
  regCalculateEstimated,
  regCompanySeting,
  regCostcenterSetting,
  regGetUserRoleListNopagination,
  regGlobalSetting,
  regUserAll,
  regUserPermissionAll,
  requestAssignablePermissionList,
  requestGetAllPermission,
  requestUpdateUserRoleTransfer
} from '.'

export const getCalculateEstimated = async (select: any) => {
  const TimeTrackerResponese = await regCalculateEstimated(select, 'GET')
  if (TimeTrackerResponese.data.code == 200) {
    return TimeTrackerResponese.data.data
  }
  return []
}

/***********************************************************User******************************************************************/

// Obtain user data for general selection
export const getUserGeneralSelectFunction = async (): Promise<Array<{ value: string; label: string }>> => {
  let returnArray: Array<{ value: string; label: string }> = []

  try {
    // Make an asynchronous request to fetch all user data
    const response = await regUserAll({}, 'GET')
    const result = response.data

    // Check if the response code is 200 (OK)
    if (result.code === 200) {
      // Map the user data to the required format with 'value' and 'label' properties
      returnArray = result.data.map((item: { id_user: string; username: any }) => ({
        value: item.id_user as string,
        label: item.username
      }))
    }
  } catch (error) {
    // Handle errors by returning an empty array
    console.error('Error fetching user data:', error)
  }

  return returnArray
}

// Obtain user data for general selection
export const getUserGeneralFunction = async (where: any = [], get_type = 'select') => {
  let returnArray: any = []
  try {
    if (where == 'all_info') {
      get_type = 'all_info'
      where = {}
    }
    // Make an asynchronous request to fetch all user data
    const response = await regUserAll(where, 'GET')
    const result = response.data
    // Check if the response code is 200 (OK)
    if (result.code === 200) {
      if (get_type === 'select') {
        returnArray = result.data.map((item: { email: string; avatar: string; id_user: string; username: any }) => ({
          value: item.id_user.toString() as string,
          label: item.username,
          avatar: item.avatar,
          email: item.email
        }))
      } else if (get_type === 'all_info') {
        returnArray = result.data
      }
    }
  } catch (error) {
    // Handle errors by returning an empty array
    console.error('Error fetching user data:', error)
  }

  return returnArray
}


// Obtain user data for general selection
export const getUserPermissionGeneralFunction = async (where: any = [], get_type = 'select') => {
  let returnArray: any = []
  try {
    if (where == 'all_info') {
      get_type = 'all_info'
      where = {}
    }
    // Make an asynchronous request to fetch all user data
    const response = await regUserPermissionAll(where, 'GET')
    const result = response.data
    // Check if the response code is 200 (OK)
    if (result.code === 200) {
        returnArray = result.data.map((item: { avatar: string; id_user: string; username: any }) => ({
          value: item.id_user.toString() as string,
          label: item.username,
          avatar: item.avatar,
        }))
    }
  } catch (error) {
    // Handle errors by returning an empty array
    console.error('Error fetching user data:', error)
  }

  return returnArray
}

/**
 * Fetches the list of custom attributes for employee data.
 * @returns {Promise<Array>} A Promise resolving to an array of custom attributes.
 */
export const getCustomAttributesGeneralFunction = async (): Promise<Array<any>> => {
  try {
    // Call the reGetEmployeeCustomAttributeList method to get the list of custom attributes
    const responseAttributeList = await reGetEmployeeCustomAttributeList({}, 'GET')

    // Check if the response code is 200 (OK)
    if (responseAttributeList.data.code === 200) {
      // Return the array of custom attributes directly
      return responseAttributeList.data.data
    }

    // If the response code is not 200, return an empty array
    return []
  } catch (error) {
    // Handle errors by returning an empty array
    console.error('Error fetching custom attributes:', error)
    return []
  }
}

/**
 * Fetches user roles and returns them in the required format for a select dropdown.
 * @returns {Promise<Array<{ value: string; label: string }>>} A Promise containing an array of objects with 'value' and 'label' properties.
 */
export const getUserRoleGeneralSelectFunction = async (): Promise<Array<{ value: string; label: string }>> => {
  let returnArray: Array<{ value: string; label: string }> = []

  try {
    // Make an asynchronous request to fetch all user roles
    const response = await regGetUserRoleListNopagination({}, 'GET')
    const result = response.data

    // Check if the response code is 200 (OK)
    if (result.code === 200) {
      // Map the user role data to the required format with 'value' and 'label' properties
      returnArray = result.data.map((item: { id_user_role: string; role_name: string; role_identifier: string }) => ({
        value: item.id_user_role as string,
        role_identifier: item.role_identifier as string,
        label: `${item.role_name} ` // Combine role_name and role_identifier in the label
      }))
    }
  } catch (error) {
    // Handle errors by returning an empty array
    console.error('Error fetching user role data:', error)
  }

  return returnArray
}

/******************************************************System***********************************************************************/

/**
 * Fetches the list of domain names.
 * @returns {Promise<string[]>} A Promise resolving to an array of domain names.
 */
export const getDomainsSelectFunction = async (): Promise<string[]> => {
  try {
    // Call the RegetDomains method to get the list of domain names
    const responseGetDomains = await reGetDomains({})
    // Convert the response data to the ANY type
    const resultGetDomains = responseGetDomains.data as any

    // Check if the response code is 200 (OK)
    if (resultGetDomains.code === 200) {
      // Return the array of domain names directly
      return resultGetDomains.data
    }

    // If the response code is not 200, return an empty array
    return []
  } catch (error) {
    // Handle errors by returning an empty array
    console.error('Error fetching domain names:', error)
    return []
  }
}

/**
 * Fetches cost center general functions asynchronously.
 * @returns {Promise<Array<{ value: string; label: string }>>} An array of cost center data with 'value' and 'label' properties.
 */
export const getCostCenterGeneralSelectFunction = async (value:any={}): Promise<Array<{ value: string; label: string }>> => {
  let centerData: Array<{ value: string; label: string }> = []
  try {
    // Make an asynchronous request to fetch cost center settings
    const response = await regCostcenterSetting(value, 'GET')
    const result = response.data
    // Check if the response code is 200 (OK)
    if (result.code === 200) {
      // Map the data to the required format with 'value' and 'label' properties
      centerData = result.data.map((item: { id_costcenter: string; name: any }) => ({
        value: item.id_costcenter.toString() as string,
        label: item.name
      }))
    }
  } catch (error) {
    return centerData
  }
  return centerData
}

/**
 * Fetches cost center general functions asynchronously.
 * @returns {Promise<Array<any>>} An array of cost center data with any format.
 */
export const getCostCenterGeneralFunction = async (where:any ={}): Promise<[]> => {
  // Initialize an empty array to store cost center data
  let centerData: [] = []

  try {
    // Make an asynchronous request to fetch cost center settings using the regCostcenterSetting function with the "GET" method
    const response = await regCostcenterSetting(where, 'GET')
    const result = response.data

    // Check if the response code is 200 (OK)
    if (result.code === 200) {
      // Assign the data to the centerData array
      centerData = result.data
    }
  } catch (error) {
    // If an error occurs during the request, return the empty array
    return centerData
  }

  // Return the array of cost center data
  return centerData
}

/**
 * Asynchronous function to obtain company configuration information.
 *
 * @returns Returns a Promise that resolves to company configuration information on success and an empty array on failure.
 */
export const getCompanyGeneralFunction = async (): Promise<any> => {
  //Initialize an empty array to store company configuration information
  let companySettings: any

  try {
    // Initiate an asynchronous request and use the "GET" method to obtain company configuration information through the regCompanySeting function
    const response = await regCompanySeting({}, 'GET')
    const result = response.data

    // 检查响应代码是否为 200 (OK)
    if (result.code === 200) {
      // Assign data to companySettings
      companySettings = result.data
    }
  } catch (error) {
    // If an error occurs during the request, an empty array is returned
    return companySettings
  }

  // Returns   of company configuration information
  return companySettings
}

/**
 * Asynchronous function to obtain core configuration information.
 *
 * @returns Returns a Promise that resolves to core configuration information on success and an empty array on failure.
 */
export const getGlobalSettingGeneralFunction = async (): Promise<any> => {
  // Initialize an empty variable to store core configuration information
  let globalSetting: any

  try {
    // Initiate an asynchronous request and use the "GET" method to obtain core configuration information through the regGlobalSetting function
    const response = await regGlobalSetting({}, 'GET')
    const result = response.data

    // Check if the response code is 200 (OK)
    if (result.code === 200) {
      // Assign data to globalSetting
      globalSetting = result.data
    }
  } catch (error) {
    // If an error occurs during the request, return an empty array
    return globalSetting
  }

  // Return core configuration information
  return globalSetting
}

export const updateUserRoleTransferFunction = (moduleName: string) => {
  return async (where: any) => {
    try {
      const response = await requestUpdateUserRoleTransfer(moduleName, where)
      const result = response.data
      if (result.code === 200) {
        return result.data
      }
      return false
    } catch (error) {
      // Handle any errors that occur during the API call
      console.error('Error update UserRoleTransferFunction', error)
      // Optionally, you can throw the error or handle it as needed
      throw error
    }
  }
}

export const getAssignablePermissionFunction = (moduleName: string) => async (where: any) => {
  // Initialize the return result
  let returnResult: any = []

  try {
    const response = await requestAssignablePermissionList(moduleName, where)
    const result = response.data
    if (result.code === 200) {
      returnResult = result.data
    }
  } catch (error) {
    // Handle any errors that occur during the API call
    console.error('Error fetching AssignablePermission list:', error)
    // Optionally, you can throw the error or handle it as needed
    throw error
  }

  return returnResult
}


/**
 * Fetches the list of custom attributes for employee data.
 * @returns {Promise<Array>} A Promise resolving to an array of custom attributes.
 */
export const getAllPermissionList = async (where:any) => {
  try {
    // Call the reGetEmployeeCustomAttributeList method to get the list of custom attributes
    const responseAttributeList = await requestGetAllPermission(where, 'GET')

    // Check if the response code is 200 (OK)
    if (responseAttributeList.data.code === 200) {
      // Return the array of custom attributes directly
      return responseAttributeList.data.data
    }

    // If the response code is not 200, return an empty array
    return []
  } catch (error) {
    // Handle errors by returning an empty array
    console.error('Error fetching custom attributes:', error)
    return []
  }
}
