import { requesLeaveUniquePermissionActionList, requestLeavePermissionIdentifierListAction, requestLeavePermissionListAction, requestLeaveUniquePermissionIdentifierList } from ".";
import { requesTmUniquePermissionActionList, requestTMPermissionIdentifierListAction, requestTMPermissionListAction, requestTMPermissionRoleDetail, requestTMPermissionSetDetail, requestTMUniquePermissionIdentifierList } from "./index_v2";


// Fetches the permission Identifier Unique list
export const getTMPerIdentifierUniqueList = async () => {
    // Initialize the return result
    let returnResult: any = [];
  
    try {
      // Call the API to get the Unique PermissionIdentifier List
      const response = await requestTMUniquePermissionIdentifierList();
      const result = response.data;
  
      // Check if the API response is successful (status code 200)
      if (result.code === 200) {
        
          // Extract and format data for the 'select' type
          const customeData = result.data.map(
            (item: { identifier: string;  }) => ({
              value: item.identifier,
              label: item.identifier,
            })
          );
  
          // Set the formatted   data to the return result
          returnResult = customeData;
      }
    } catch (error) {
      // Handle any errors that occur during the API call
      console.error('Error fetching  list:', error);
      // Optionally, you can throw the error or handle it as needed
      throw error;
    }  // Return the final result
    return returnResult;
  };
  
  
  

// Fetches the permission action Unique list
export const getTMPerActionUnique = async () => {
  // Initialize the return result
  let returnResult: any = [];

  try {
    // Call the API to get the project list
    const response = await requesTmUniquePermissionActionList();
    const result = response.data;

    // Check if the API response is successful (status code 200)
    if (result.code === 200) {
      
        // Extract and format data for the 'select' type
        const customerData = result.data.map(
          (item: { identifier: string;  }) => ({
            value: item.identifier,
            label: item.identifier,
          })
        );
        // Set the formatted project list data to the return result
        returnResult = customerData;
    }
  } catch (error) {
    // Handle any errors that occur during the API call
    console.error('Error fetching project list:', error);
    // Optionally, you can throw the error or handle it as needed
    throw error;
  }
  // Return the final result
  return returnResult;
}


// Fetches the permission identifier list
export const getTMPermissionIdentifierListGeneralFunction = async (where: any, get_type = 'select') => {
  // Initialize the return result
  let returnResult: any = [];

  try {
    // Call the API to get the project list
    const response = await requestTMPermissionIdentifierListAction(where);
    const result = response.data;

    // Check if the API response is successful (status code 200)
    if (result.code === 200) {
      if (get_type === 'select') {
        // Extract and format data for the 'select' type
        const customerData = result.data.map(
          (item: { id_permission_identifier: string; name: any; }) => ({
            value: item.id_permission_identifier.toString(),
            label: item.name,
          })
        );

        // Set the formatted project list data to the return result
        returnResult = customerData;
      }
      returnResult = result.data;
    }
  } catch (error) {
    // Handle any errors that occur during the API call
    console.error('Error fetching project list:', error);
    // Optionally, you can throw the error or handle it as needed
    throw error;
  }

  // Return the final result
  return returnResult;
};




// Fetches the permission action list
export const getTMPermissionActionListGeneralFunction = async (where: any, get_type = 'select') => {
  // Initialize the return result
  let returnResult: any = [];

  try {
    // Call the API to get the project list
    const response = await requestTMPermissionListAction(where);
    const result = response.data;

    // Check if the API response is successful (status code 200)
    if (result.code === 200) {
      if (get_type === 'select') {
        // Extract and format data for the 'select' type
        const customerData = result.data.map(
          (item: { id_permission_identifier: string; name: any; }) => ({
            value: item.id_permission_identifier.toString(),
            label: item.name,
          })
        );

        // Set the formatted project list data to the return result
        returnResult = customerData;
      }
      returnResult = result.data;
    }
  } catch (error) {
    // Handle any errors that occur during the API call
    console.error('Error fetching project list:', error);
    // Optionally, you can throw the error or handle it as needed
    throw error;
  }

  // Return the final result
  return returnResult;
};



// get role detail by 
export const getTMPermissionRoleDetail = async (where: any) => {
  let returnArray: any = {};
  try {
    const response = await requestTMPermissionRoleDetail(where);
    const result = response.data;
    // Check if the response code is 200 (OK)
    if (result.code === 200) {
        returnArray =  result.data

        if(Array.isArray(returnArray) && returnArray.length === 0){
          returnArray = undefined;
        }
    }
  } catch (error) {
    // Handle errors by returning an empty array
    console.error('Error fetching user data:', error);
  }

  return returnArray;
};

// get role detail by project_id
export const getTMPermissionSetDetail = async (where: any) => {
  let returnArray: any = {};
  try {
    const response = await requestTMPermissionSetDetail(where);
    const result = response.data;
    // Check if the response code is 200 (OK)
    if (result.code === 200) {
        returnArray =  result.data

        if(Array.isArray(returnArray) && returnArray.length === 0){
          returnArray = undefined;
        }
    }
  } catch (error) {
    // Handle errors by returning an empty array
    console.error('Error fetching user data:', error);
  }

  return returnArray;
};