import {
  Table,
  Group,
  Avatar,
  Text,
  ActionIcon,
  TransferListData,
  Grid,
  Select,
  Code,
  Textarea,
  ScrollArea,
  TextInput,
  Button,
  Box,
  Modal,
  LoadingOverlay,
  NumberInput,
  Drawer,
  Card,
  Checkbox,
  Pagination,
  Divider,
  Paper,
  Flex,
  Skeleton,
} from "@mantine/core";
import SelectUserToProject from "../select_user_to_project";
import { SelectUser } from "../../../interface/Icommon";
import { selectEmployeeProps } from "../../../interface/Iemloyee";
import { IOrderNewCreateList, IOrderProps } from "../../../interface/Iorder";
import TaskOnlyEditFrom from "./task_only_edit_form";
import { filterEmployees } from "../../../utils/businessFunction";
import { ITask, ITaskGeResponseNopage } from "../../../interface/Itask";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import {
  IconPencil,
  IconCalendar,
  IconMessages,
  IconNote,
  IconReportAnalytics,
  IconTrash,
  IconDots,
  IconSearch,
  IconCheck,
  IconX,
  IconUserPlus,
  IconRefresh,
  IconSquareRoundedPlus,
} from "@tabler/icons-react";
import { FormEvent, useEffect, useRef, useState } from "react";
import {
  regCustomer,
  regCustomerList,
  regEmployeerolesList,
  regOrdername,
  regOrdernameList,
  regProject,
  regProjectStatus,
  regTaskCategory,
  regUser,
  regUserAll,
} from "../../../api";
import {
  regTaskOnly,
  regGeOrderTask,
  regOrder,
  regOrderTask,
} from "../../../api";
import {
  CustomerData,
  ResponseCustomerData as CustomerApi,
} from "../../../interface/Icoustomer";
import { notifications } from "@mantine/notifications";
import { modals } from "@mantine/modals";
import PaginationA from "../../../ttcomponents/Pagination/PaginationA";
import { IPagination } from "../../../interface/Ipagintion";
import {
  APIResponse,
  TaskOnlyData,
  CustomerInfo,
} from "../../../interface/Itaskonly";
import { APIResponse as APIStatesResponse } from "../../../interface/IprojectStatus";
import { FormattedMessage, useIntl } from "react-intl";
import { SelectRole } from "../../../interface/Icommon";
import { EmployeeRoleResponseApi } from "../../../interface/Iemloyee";
import { ResponseUserData, UserAllList } from "../../../interface/Iuser";
interface ICustomerProps {
  [key: string]: any;
}

export default function TaskOnly({
  callback,
  searchValue,
  isScrolledToBottom,
}: ICustomerProps) {
  // 钩子函数-切换语言
  const intl = useIntl();

  const [projectStatusSelect, setProjectStatusSelect] = useState<SelectRole[]>(
    []
  );
  // 定义任内务类型-
  const [taskcateGoryListData, setTaskcateGoryListData] = useState<
    SelectRole[]
  >([]);
  // 定义选择顾客可搜查函数并且赋值给searchCustomerValue
  const [searchCustomerValue, onSearchCustomerChange] = useState("");
  // 定义可选择的所有的员工角色列表
  const [employeesList, setEmployeesList] = useState<SelectRole[]>([]);
  // 定义可选择的所有的员工列表
  const [userlist, setUserList] = useState<SelectRole[]>([]);
  // 定义用户列表（含用户的详细信息）
  const [UserList, setResultUser] = useState<UserAllList[]>([]);
  const [orderItem, setOrderItem] = useState<IOrderProps>();

  //加载中状态
  const [loading, setLoading] = useState(false);
  const [resetUser, setresetUser] = useState(false);

  // 定义可选择的所有的客户列表
  const [customerlist, setCustomerList] = useState<SelectRole[]>([]);

  const [searchEmployeesValue, onEmployeesSearchChange] = useState("");
  const [descEnable, setDesEnable] = useState<boolean>(false);
  const [employeestool, setEmployees] = useState<selectEmployeeProps[]>();

  const [openedModal1, { open: openModal1, close: closeModal1 }] =
    useDisclosure(false);
  const [openedModal2, { open: openModal2, close: closeModal2 }] =
    useDisclosure(false);
  const [openedModal3, { open: openModal3, close: closeModal3 }] =
    useDisclosure(false);
  const [openedModal4, { open: openModal4, close: closeModal4 }] =
    useDisclosure(false);

  //等待数据
  const [loadingData, setLoadingData] = useState(true);
  //定义修改的tasl承接变量
  const [taskItem, setTaskItem] = useState<ITask>();

  const [id_customer_data, setid_customer_data] = useState("");

  const containerRef = useRef<HTMLDivElement>(null);

  const [taskOnlySingleData, setTaskOnlySingleData] = useState<
    TaskOnlyData | undefined
  >(undefined);
  const [custListCount, setCustListCount] = useState<APIResponse | null>(null);
  const [taskOnlyData, setTaskOnlyData] = useState<TaskOnlyData[]>([]);
  const [pagination, setPagination] = useState<IPagination>({
    search: "",
    page: 1,
    pagesize: 10,
    sortBy: "",
    sortOrder: "desc",
  });
  const form = useForm({
    initialValues: {
      time_estimate: "00:00",
      flag: "",
      task_category_id: "",
      free_description: "",
      start_date: new Date(),
      end_date: new Date(),
      cost: "",
      time_consumtion: "",
      time: "",
      customer_id: "",
      budget: "",
      task_leader_id: "",
      employees_id: "",
      employees: [],
      order_id: "",
      id_task: "",
    },
  });

  /**
   *
   * Get user information
   */
  const ajaxCurrentList = async () => {
    setLoading(true);
    const response = await regTaskOnly(pagination, "GET");
    const result = response.data as APIResponse;

    if (result.code === 200 && !Array.isArray(result.data)) {
      setCustListCount(result);
      // setCustomerData((prevList) => [...prevList, ...result.data.data]);
      setTaskOnlyData(result.data.data);
    } else {
      console.log(result.msg);
    }

    const responseCustomerData = await regCustomerList({});
    const resultCustomerData = responseCustomerData.data as CustomerApi;
    if (result.code === 200) {
      const customerData = resultCustomerData.data.map((item) => ({
        value: item.id_customer as string,
        label: item.company_name,
      }));
      setCustomerList(customerData);
    }

    // 获取user
    const responseUser = await regUserAll({});
    const resultUser = responseUser.data as ResponseUserData;
    setResultUser(responseUser.data.data);
    const userData = resultUser.data.map((item) => ({
      value: item.id_user as string,
      label: item.username,
    }));
    setUserList(userData);

    // 获取任务类型
    const cateGoryInfo = await regTaskCategory({});
    if (cateGoryInfo.data.code == 200) {
      const taskcateGoryData = cateGoryInfo.data.data.data.map(
        (item: { id_task_category: string; identifier: any }) => ({
          value: item.id_task_category as string,
          label: item.identifier,
        })
      );
      setTaskcateGoryListData(taskcateGoryData);
    }
    // 获取所有员工角色
    const responseEmployeerolesList = await regEmployeerolesList({}, "GET");
    const resultEmployeerolesList =
      responseEmployeerolesList.data as EmployeeRoleResponseApi;
    const EmployeesListData = resultEmployeerolesList.data.map((item) => ({
      value: item.id_employee_roles as string,
      label: item.role_name,
    }));
    setEmployeesList(EmployeesListData);

    // 获取项目状体
    const statusresponse = await regProjectStatus({}, "GET");
    const statusResult = statusresponse.data as APIStatesResponse;
    let datass;
    if (statusResult.code === 200) {
      datass = statusResult.data.data.map((item) => ({
        value: item.id_status as string,
        label: item.status_name,
      }));
      setProjectStatusSelect(datass);
    }

    setLoading(false);
  };

  //  定义修改表单后的回调函数
  const callBackhandle = () => {
    ReceivePaginationABack(pagination.page);
    closeModal1();
  };

  const editTask = async (value: object) => {
    // 获取这个task的详细信息、雇员等情况
    const editData = value as TaskOnlyData;
    const TaskresponseUser = await regGeOrderTask(editData.id_task);
    const resultTask = TaskresponseUser.data as ITaskGeResponseNopage;
    setTaskItem(resultTask.data);
    console.log("editData", editData);
    form.setFieldValue("task_category_id", editData.task_category_id);
    form.setFieldValue("free_description", editData.free_description);
    form.setFieldValue("customer_id", editData.customer_id);
    form.setFieldValue("budget", editData.budget);
    form.setFieldValue("task_leader_id", editData.task_leader_id);
    form.setFieldValue("start_date", editData.start_date);
    form.setFieldValue("cost", editData.cost);
    form.setFieldValue("status_id", editData.status_id);
    form.setFieldValue("time_consumption", editData.time_consumption);
    form.setFieldValue("end_date", editData.end_date);
    form.setFieldValue("time_estimate", editData.time_estimate);
    form.setFieldValue("id_task", editData.id_task);
    form.setFieldValue("flag", editData.flag);

    // form.setFieldValue('employees_id', editData.task_employee);
    // form.setFieldValue('employees', editData.employees);
    openModal1();
  };
  const addProject = (value: object) => {
    // const editData = value as CustomerData;
    // setid_customer_data(editData.id_customer as string)
    // openModal4();
  };

  // 快速添加客户的联系人
  const addContactName = (value: object) => {
    // const editData = value as CustomerData;
    // // setCustomerSingleData(editData)
    // console.log(editData)
    // openModal3();
  };

  const rows =
    taskOnlyData.length > 0 ? (
      taskOnlyData.map((item, index) => (
        <tr key={index}>
          {/* <td>
          <Group spacing="xs">
            <Avatar size={40} src={item.} radius={40} />
            <div>
              <Text fz="xs" fw={500}>{item.company_name}</Text>
              <Text c="dimmed" fz="xs">ID:{item.id_customer}</Text>
            </div>
          </Group>
        </td> */}

          <td>
            {" "}
            <Text fz="xs">{item.customer_info.company_name} </Text>{" "}
          </td>
          <td>
            {" "}
            <Text fz="xs">{item.task_category.identifier} </Text>{" "}
          </td>
          <td>
            {" "}
            <Text fz="xs">
              <Avatar src={item.user_info.avatar} radius={40} size={26} />
              {item.user_info.username}{" "}
            </Text>{" "}
          </td>
          <td>
            {" "}
            <Text fz="xs">{item.cost} </Text>{" "}
          </td>
          <td>
            {" "}
            <Text fz="xs">{item.time_consumption} </Text>{" "}
          </td>
          <td>
            {" "}
            <Group spacing={0} position="left">
              <ActionIcon onClick={() => editTask(item)}>
                <IconPencil size="1rem" stroke={1.5} />
              </ActionIcon>
              <ActionIcon onClick={() => delHandle(item)}>
                <IconTrash size="1rem" stroke={1.5} color="red" />
              </ActionIcon>
              {/* <ActionIcon onClick={() => addProject(item)} ><IconSquareRoundedPlus size="1rem" stroke={1.5} /></ActionIcon> */}
            </Group>
          </td>
        </tr>
      ))
    ) : (
      <>
        <tr>
          <FormattedMessage id="no_one_here" defaultMessage="No One Here" />
        </tr>
      </>
    );

  // 删除客户  Delete customers
  const delHandle = (row: object) => {
    const delValue = row as {
      id_task: string;
      customer_id: string;
      customer_info: CustomerInfo;
    };
    modals.openConfirmModal({
      title: (
        <FormattedMessage
          id="delete_confirm"
          defaultMessage="Delete Confirmation"
        />
      ),
      centered: true,
      children: (
        <Text mt={10} size="sm">
          <FormattedMessage id="Company Name" defaultMessage="Company Name" />:{" "}
          <Code> {delValue.customer_info.company_name}</Code>
        </Text>
      ),
      labels: {
        confirm: (
          <FormattedMessage
            id="confirm_delete"
            defaultMessage="Confirm Delete"
          />
        ),
        cancel: (
          <FormattedMessage id="cancel_delete" defaultMessage="Cancel Delete" />
        ),
      },
      confirmProps: { color: "red" },
      onConfirm: async () => {
        const FormData = {
          id: delValue.id_task,
        };
        const response = await regOrderTask(FormData, "DELETE");
        const result = response.data;
        if (result.code === 200) {
          // ajaxCurrentList();
          setTaskOnlyData(
            taskOnlyData.filter((item) => item.id_task !== delValue.id_task)
          );
          notifications.show({
            color: "green",
            icon: <IconCheck />,
            message: result.msg,
          });
        } else {
          notifications.show({
            color: "red",
            icon: <IconX />,
            message: result.msg,
          });
        }
      },
    });
  };

  // 接收回调返回数据，搜索内容  Receive the callback return data, search for content
  const ReceiveSearchBack = (value: string | number) => {
    setTaskOnlyData([]);
    setPagination((prevpagination) => ({
      ...prevpagination,
      search: value as string,
      page: 1,
    }));
  };
  // 分页回调触发更新
  const ReceivePaginationABack = (page: number) => {
    setPagination((prevpagination) => ({
      ...prevpagination,
      page: page,
    }));
  };

  useEffect(() => {
    ReceiveSearchBack(searchValue);
  }, [searchValue]);

  useEffect(() => {
    ajaxCurrentList();
  }, [pagination]);

  return (
    <Paper
      px={20}
      w={"100%"}
      className="scrollable-container-table"
      style={{ position: "relative" }}
    >
      <LoadingOverlay visible={loading} overlayBlur={2} />
      <Box
        pb={20}
        h="85vh"
        style={{ overflow: "auto" }}
        ref={containerRef}
        className="scrollable-content-table"
      >
        <Flex className="title_header" justify="left" align="center" pl={30}>
          <Text
            className="Headlinestitle_text"
            fw={700}
            size={18}
            color="white"
          >
            TASK ONLY MANAGEMENT
          </Text>{" "}
        </Flex>
        <Table
          horizontalSpacing="xs"
          verticalSpacing="xs"
          fontSize="xs"
          w="100%"
        >
          <thead>
            <tr>
              <th>
                <Text fw={500} fz="sm">
                  <FormattedMessage id="Customer" defaultMessage="Customer" />
                </Text>
              </th>
              <th>
                <Text fw={500} fz="sm">
                  <FormattedMessage
                    id="Task Category"
                    defaultMessage="Task Category"
                  />
                </Text>
              </th>
              <th>
                <Text fw={500} fz="sm">
                  <FormattedMessage id="username" defaultMessage="UserName" />
                </Text>
              </th>
              <th>
                <Text fw={500} fz="sm">
                  <FormattedMessage id="cost" defaultMessage="Cost" />
                </Text>
              </th>
              <th>
                <Text fw={500} fz="sm">
                  <FormattedMessage
                    id="time_consumtion"
                    defaultMessage="Time Consumtion"
                  />
                </Text>
              </th>
              <th>
                <Text fw={500} fz="sm">
                  <FormattedMessage id="Option" defaultMessage="Option" />
                </Text>
              </th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
        <PaginationA
          callBack={ReceivePaginationABack}
          total_count={custListCount?.data.total_count as number}
          pagesize={pagination.pagesize}
          page={pagination.page}
        />

        <Modal
          radius="md"
          size={"md"}
          opened={openedModal1}
          className="modal-setting"
          onClose={closeModal1}
          title={<FormattedMessage id="task" defaultMessage="Task Edit" />}
          overlayProps={{ opacity: 0.5, blur: 4 }}
        >
          <TaskOnlyEditFrom
            formDataProps={form.values}
            customerListProps={customerlist}
            statusListProps={projectStatusSelect}
            taskcateGoryListData={taskcateGoryListData}
            taskItem={taskItem}
            orderItem={orderItem}
            callBack={callBackhandle}
            employeesList={employeesList}
            userlist={userlist}
            UserLists={UserList}
          />
        </Modal>
      </Box>
    </Paper>
  );
}
