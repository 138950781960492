import {
  Avatar,
  Card,
  Checkbox,
  Group,
  Select,
  Text,
  TransferList,
  TransferListData,
  TransferListItemComponent,
  TransferListItemComponentProps,
} from "@mantine/core";
import { IconDots, IconTrash, IconX } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { ApiUserResponse, UserAllList } from "../../../interface/Iuser";
import { EmployeeRoleResponseApi } from "../../../interface/Iemloyee";
import { SelectRole } from "../../../interface/Icommon";
import {
  regCustomerRole,
  regEmployeeroles,
  regEmployeerolesList,
  regItemListByType,
} from "../../../api";
import { notifications } from "@mantine/notifications";
import { filterProjectItem } from "../../../interface/IReport";
import localstorageUnits from "../../../utils/localstorageUnits";
import { useIntl } from "react-intl";

const elements = [
  { position: 6, mass: 12.011, symbol: "C", name: "Carbon" },
  { position: 7, mass: 14.007, symbol: "N", name: "Nitrogen" },
  { position: 39, mass: 88.906, symbol: "Y", name: "Yttrium" },
  { position: 56, mass: 137.33, symbol: "Ba", name: "Barium" },
  { position: 58, mass: 140.12, symbol: "Ce", name: "Cerium" },
];

// 示例数据
const mockdata = [
  {
    id: "1",
    value: "ProjectA",
    label: "ProjectA",
    eamil: "Fascinated with cooking, though has no sense of taste",
  },

  {
    id: "2",
    value: "XingbaKe",
    label: "XingbaKe",
    eamil: "One of the richest people on Earth",
  },

  {
    id: "3",
    value: "Paiqun",
    label: "Paiqun",
    eamil: "One of the richest people on Earth",
  },

  {
    id: "4",
    value: "xuanziru",
    label: "xuanziru",
    eamil: "One of the richest people on Earth",
  },

  {
    id: "5",
    value: "Filter AA",
    label: "Filter AA",
    eamil: "One of the richest people on Earth",
  },
  // ...other items
];
interface UserDataListProps {
  callBack: (value: string) => void;
  projectData: filterProjectItem[];
  [key: string]: any;
}
export default function SelectProjectFilter({
  callBack,
  projectData,
}: UserDataListProps) {
  // Use the useIntl hook to access the intl object
  const intl = useIntl();

  // get Task Mode Flag
  const taskModeFlag = localstorageUnits.getSystem().task_mode_flag;
  const [data, setData] = useState<TransferListData>([mockdata, []]);
  const [datatest, setDatatest] = useState([]);
  // Initialize data information
  useEffect(() => {
    let transferList = projectData.map((item) => {
      return {
        value: item.id_project.toString() as string,
        id_project: item.id_project.toString() as string,
        name: item.name,
        label: item.name,
        project_no: item.project_no,
        description: item.project_no,
        order_count: item.order_count,
        task_count: item.task_count,
        company_name: item.company_name,
      };
    });
    setData([transferList, []]);
  }, [projectData]);
  const ItemComponent: TransferListItemComponent = ({
    data,
    selected,
  }: TransferListItemComponentProps) => (
    <Group noWrap>
      <div style={{ flex: 1 }}>
        <Text className="select-main">{data.label}</Text>
        <Group>
          <Text className="select-desc">{data.company_name}</Text>
          {taskModeFlag == 2 ? null : (
            <Text className="select-desc">Orders:{data.order_count}</Text>
          )}

          <Text className="select-desc">Tasks:{data.task_count}</Text>
        </Group>
      </div>
      <Checkbox
        checked={selected}
        onChange={() => {}}
        tabIndex={-1}
        sx={{ pointerEvents: "none" }}
      />
    </Group>
  );
  const setDataHandle = (value: TransferListData) => {
    let selectedFilter = value[1]
      .map((item) => {
        return item.id_project;
      })
      .join(",");
    setData(value); // 更新 data 状态
    callBack(selectedFilter);
  };
  const [searchEmployeesValue, onEmployeesSearchChange] = useState("");
  return (
    <>
      <Text className="selectTitle mb-3">
        {intl.formatMessage({
          id: "Search Item Filter",
          defaultMessage: "Search Item Filter",
        })}
      </Text>
      <TransferList
        value={data}
        onChange={setDataHandle}
        searchPlaceholder={intl.formatMessage({
          id: "Search",
          defaultMessage: "Search",
        })}
        nothingFound={intl.formatMessage({
          id: "No One Here",
          defaultMessage: "No One Here",
        })}
        initialSelection={[datatest, []]}
        listHeight={300}
        className="input_title"
        breakpoint="sm"
        itemComponent={ItemComponent}
        filter={(query, item) =>
          item.label.toLowerCase().includes(query.toLowerCase().trim())
        }
      />
    </>
  );
}
