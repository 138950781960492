import {
  Grid,
  Group,
  Paper,
  LoadingOverlay,
  Box,
} from "@mantine/core";
import { useEffect, useState, useRef } from "react";
import { SelectRole } from "../../../interface/Icommon";
import {
  regCustomerList,
  regProjectReport,
} from "../../../api";
import {
  IconAdjustmentsHorizontal
} from "@tabler/icons-react";
import ProjectTableWithReports from "./projectTableWithReports";
import { ProjectReports } from "../../../interface/Ireports";
import {
  ResponseCustomerData as CustomerApi,
} from "../../../interface/Icoustomer";
import PageTitle from "../../../ttcomponents/PageTitle/PageTitle";
import WordTranslationComponent from "../../../utils/language_pack/words";
import AssignmentListFilter from "../../assignments/assignments/assignment_list_filter_modal";
import { IPagination } from "../../../interface/Ipagintion";
import UniversalButton from "../../../ttcomponents/Button/UniversalButton";
import { RootState } from "../../../state_management/store";
import { useSelector } from "react-redux";

export default function ReportsProjectAll() {
  // Get translation public configuration information
  const word_translation = WordTranslationComponent();

  // Get all the project collection
  const [ProjectReports, setProjectReports] = useState<ProjectReports[]>([]);
  // 获取redux store中的值
  const SearchValue = useSelector((state: RootState) => state.search.searchValue)

  // 定义可选择的所有的客户列表
  const [customerlist, setCustomerList] = useState<SelectRole[]>([]);
  //Mid -load state
  const [loading, setLoading] = useState(false);
  // open filter option
  const [openSelectFilterStatus, setOpenSelectFilterStatus] = useState<boolean>(false);
  const [pagination, setPagination] = useState<IPagination>({
    search: '',
    page: 1,
    pagesize: 20,
    sortBy: "",
    sortOrder: "asc",
  });

  // The data is initialized after the page is loaded
  const ajaxCurrentList = async () => {
    setLoading(true);
    const response = await regProjectReport(pagination, "GET");
    const result = response.data;
    setProjectReports(result.data);
    // 获取客户信息  Get customer information
    const responseCustomer = await regCustomerList({});
    const resultCustomer = responseCustomer.data as CustomerApi;
    if (resultCustomer.code === 200) {
      const customerData = resultCustomer.data.map((item) => ({
        value: item.id_customer as string,
        label: item.company_name,
      }));
      setCustomerList(customerData);
    }
    setLoading(false);
  };

  // Utilizing RTK to implement search
  const ReceiveSearchBack = (value: string | number) => {
    // Update pagination state with the new search value
    setPagination((prevPagination) => ({
      ...prevPagination,
      search: value,
    }));
  };

  // Execute the useeffect when the first load is loaded.
  const isInitialMount = useRef(true);
  useEffect(() => {
    if (!isInitialMount.current) {
      ReceiveSearchBack(SearchValue);
    }
    isInitialMount.current = false;
  }, [SearchValue]);

  // Form submission
  useEffect(() => {
    ajaxCurrentList();
  }, [pagination]);

  //   Receive the callback return data, search for content
  const successCallbackFunction = (value: any) => {
    setProjectReports([])
    setPagination((prevpagination) => ({
      ...prevpagination,
      start_date: value.start_date,
      end_date: value.end_date,
      customer_ids: value.customer_ids,
      status_ids: value.status_ids,
      mode_types: value.mode_types,
    }));
  };

  const setOpenSelectFilterStatusFunction = () => {
    setOpenSelectFilterStatus(!openSelectFilterStatus)
  };

  const initFunction = () => {
    setOpenSelectFilterStatus(false)
  };
  return (
    <Paper px={20} mb={20} style={{ position: "relative" }} mih={900}>
      <LoadingOverlay visible={loading} overlayBlur={2} />
      <PageTitle title="PROJECT REPORTS" lang_id="PROJECT REPORTS" />
      <Group className="assignment_btn" position="right" py={4}>
        <UniversalButton
          btn_bg_color="#E7F5FF"
          btn_icon={<IconAdjustmentsHorizontal color="#228BE6" size={16} />}
          btn_size={'xs'}
          callBack={setOpenSelectFilterStatusFunction}
          btn_text="Filter" />
      </Group>
      {openSelectFilterStatus &&
        <AssignmentListFilter pagination={pagination} is_load={openSelectFilterStatus} objectsUsed={word_translation.task} successCallback={(value) => successCallbackFunction(value)} closeFunction={() => { }} openFormStatus={openSelectFilterStatus} />}

      <Box onClick={initFunction} mt={6}>
        <Grid>
          <ProjectTableWithReports ProjectReports={ProjectReports} />
        </Grid>
      </Box>
    </Paper>
  );
}
