import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Group } from '@mantine/core';
import { Calendar } from '@mantine/dates';
import { notifications } from '@mantine/notifications';
import { IconX } from '@tabler/icons-react';
import localstorageUnits from '../../utils/localstorageUnits';

interface IconCalendar {
  CallBack: (date: Date) => void;
  currentDate: Date | null;
}

// Get the initial time of the system
const initSystemTime = localstorageUnits.getSystem().init_use_date;

function CalendarComponents({ currentDate, CallBack }: IconCalendar) {
  // State to keep track of selected date
  const [selected, setSelected] = useState<Date | null>(currentDate);

  // Handle date selection
  const handleSelect = (currentDate: Date | null) => {
    // Check if the selected date is in the future
    if (currentDate && currentDate > new Date()) {
      notifications.show({
        title: 'Warning',
        color: 'yellow.7',
        icon: <IconX />,
        message: "Can't choose future time",
      });
      return false; // Prevent further execution
    }

    const now = currentDate || new Date();
    setSelected(now);
    CallBack(now);
  };

  // Initialize selection when component mounts
  useEffect(() => {
    handleSelect(currentDate);
  }, []);

  return (
    <Group position="center">
      {/* Calendar component */}
      <Calendar
        minDate={new Date(initSystemTime)}
        defaultDate={currentDate as Date}
        getDayProps={(date) => ({
          selected: selected !== null && dayjs(date).isSame(selected, 'date'),
          onClick: () => handleSelect(date),
        })}
      />
    </Group>
  );
}

export default CalendarComponents;
