import { Box, Group, LoadingOverlay, Paper, Select, Tabs, Text } from '@mantine/core'
import { IconBuilding, IconSearch, IconSettings, TablerIconsProps } from '@tabler/icons-react'
import WordTranslationComponent from '../../../../utils/language_pack/words'
import { FC, useEffect, useState } from 'react'
import CoreRoleIndex from './core'
import CheckPermissionTools from '../../../../utils/permission'
import { NotPermission } from '../../../../ttcomponents/NoPermission'
import AssignmentRoleIndex from './assignment'
import LeaveRoleIndex from './leave'
import TimetrackerRoleIndex from './timetracker'
import ReportRoleIndex from './report'
import { CostCenterLeaderProvider } from './context'
import QueryPermission from './query_permission'
import { ItemProps } from '../../../../interface/IProduct'
import { getUserGeneralFunction, getUserPermissionGeneralFunction } from '../../../../api/common_request'
import UniversalButton from '../../../../ttcomponents/Button/UniversalButton'
import { modals } from '@mantine/modals'
import { requestDeletePermission } from '../../../../api'
import { HintInfo, HintInfoError } from '../../../../utils/function'
import { PermissionsData } from '../../../../interface/Setting'
import BoardingIndex from './boarding'

interface ComponentInterface {
  is_load_str?: string;
  is_query:boolean;
}
type Settings = {
  links: {
    label: string
    view: React.ReactNode
    icon: (props: TablerIconsProps) => JSX.Element
  }[]
}

export default function PermissionRoleIndex({ is_load_str,is_query }: ComponentInterface) {
  // Get translation public configuration information
  const word_translation = WordTranslationComponent()
  const [currentType, setActiveTab] = useState<string | null>(null)
  const [userID, setUserID] = useState<string>('')

  var text = <NotPermission />

  const Perm = CheckPermissionTools()
  const corePremission: any = {
    create: Perm.permission_read,
    update: Perm.permission_update,
    read: Perm.permission_read,
    delete: Perm.permission_delete
  }

  function getLinks() {
    const links: Settings['links'] = []
    Perm.core_open_permissions &&
      links.push({
        label: 'core',
        view: (
          <CoreRoleIndex
            is_load_str_top={is_load_str}
            Permission={corePremission}
            is_load_str={currentType as string}
          />
        ),
        icon: IconSettings
      })
    Perm.assignments_open_permissions &&
      links.push({
        label: 'assignment',
        view: (
          <AssignmentRoleIndex
            is_load_str_top={is_load_str}
            Permission={corePremission}
            is_load_str={currentType as string}
          />
        ),
        icon: IconBuilding
      })

    Perm.leaveRequest_open_permissions &&
      links.push({
        label: 'leave_request',
        view: <LeaveRoleIndex Permission={corePremission} is_load_str={currentType as string} />,
        icon: IconBuilding
      })

    Perm.timeTracker_open_permissions &&
      links.push({
        label: 'timetracker',
        view: <TimetrackerRoleIndex Permission={corePremission} is_load_str={currentType as string} />,
        icon: IconBuilding
      })

    Perm.report_open_permissions &&
      links.push({
        label: 'report',
        view: <ReportRoleIndex Permission={corePremission} is_load_str={currentType as string} />,
        icon: IconBuilding
      })
      
      
    Perm.admin &&
    links.push({
      label: 'boarding',
      view: <BoardingIndex Permission={corePremission} is_load_str={currentType as string} />,
      icon: IconBuilding
    })
    
    links.push({ label: 'inventory', view: <>{text}</>, icon: IconSettings });

    (Perm.admin || is_query ) &&
      links.push ({
        label: 'query_permission',
        view: <QueryPermission user_id={userID} is_load_str={currentType as string} />,
        icon: IconBuilding
      })

    return links
  }

  const settings: Settings = {
    links: [...getLinks()]
  }

  useEffect(() => {
    setActiveTab(settings.links[0].label)
    // 请求是否有权限
  }, [])

  return (
    <Paper>
      {/* Tabs for different settings */}
      <Tabs mt={10} keepMounted={false} value={currentType} onTabChange={setActiveTab}>
        <Tabs.List>
          {/* Render individual tabs with icons and labels */}
          {settings.links.map((item, index) => (
            <Tabs.Tab
              key={item.label}
              value={item.label}
              icon={item.label == 'query_permission' ? null : <item.icon size={20} slope={1.5} />}
            >
              {item.label == 'query_permission' ? (
                <QueryPermissionTab currentType={currentType} userID={userID} setUserID={setUserID} />
              ) : (
                <> {word_translation[item.label as keyof typeof word_translation]}</>
              )}
            </Tabs.Tab>
          ))}
        </Tabs.List>
        {/* Render corresponding panels for each tab */}
        {settings.links.map((item, index) => (
          <Tabs.Panel key={index} value={item.label} pt="xs">
            <CostCenterLeaderProvider>{item.view}</CostCenterLeaderProvider>
          </Tabs.Panel>
        ))}
      </Tabs>
    </Paper>
  )
}

const QueryPermissionTab: FC<{ currentType: string | null; userID: string; setUserID: (id: string) => void }> = ({
  currentType,
  userID,
  setUserID
}) => {
  // Get translation public configuration information
  const word_translation = WordTranslationComponent()

  const [loading, setLoading] = useState<boolean>(false)
  const [UserList, setResultUser] = useState<ItemProps[]>([])

  useEffect(() => {
    // Function to get a list of users
    const getUser = async () => {
      setLoading(true)
      // Call the Get User Universal Selection function and set the user list
      setResultUser(await getUserPermissionGeneralFunction({list:true}))
      setLoading(false)
    }
    currentType == 'query_permission' && getUser()
  }, [currentType])

  return (
    <Group>
      <LoadingOverlay visible={loading} overlayBlur={2} />
      <Box>
        <Select
          clearable
          placeholder={word_translation.search_user}
          data={UserList as []}
          searchable
          dropdownPosition={'bottom'}
          withinPortal={true}
          className="time_sheet_home"
          value={userID}
          onChange={setUserID}
          size="xs"
          nothingFound="Nobody here"
        />
      </Box>
      <Box> 
        <Text fw={400} color='dark' fs="italic"  c="blue"> Search user permissions</Text>
      </Box>
      <Box> 
        <IconSearch size={20} color='blue' />
      </Box>
    </Group>
  )
}
