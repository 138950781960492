import {
  Box,
  Flex,
  Grid,
  Paper,
} from "@mantine/core";
import TimeAdd from "./time_add";
import LiveTracking from "./live_track";
import { ProjectItem } from "./project_item";
import { Services } from "./services";
import { useEffect, useState } from "react";
import { selectApiRespone, SelectRole } from "../../interface/Icommon";
import localstorageUnits from "../../utils/localstorageUnits";
import { getAllTaskCategoryGeneralFunction } from "../../api/assignments_request";
import { ClockInOut } from "./clock_in_out";

export default function Dashboard() {
  const [ProjectListA, setProjectList] = useState<SelectRole[]>([]);


  const [userTaskList, setUserTaskList] = useState<SelectRole[]>([]);

  const datas = {
    title: "Shopping Mall",
    completed: 78,
    total: 450,
    stats: [
      { value: 2, label: "Today hours" },
      { value: 3, label: "Total days" },
    ],
  };


  // get task list <FUNCTION>
  const getTaskList = async () => {
    // setUserTaskList((await getTaskListGeneralFunction(value)))
    setUserTaskList(await getAllTaskCategoryGeneralFunction({list:true}))
  };

  useEffect(() => {
    getTaskList();
  }, []);

  return (
    <Paper w={"100%"}>
      <Box w={{ base: "100%", xs: 200, sm: 600, lg: "100%", md: 800 }} pb={20}>
        <Grid gutter={0} p={20}>
          <Grid.Col span={3}>
            <Flex direction="column" gap="md">
              <TimeAdd userTaskList={userTaskList} projectData={ProjectListA} />
              <LiveTracking userTaskList={userTaskList} projectData={ProjectListA} />
            </Flex>
          </Grid.Col>
          <Grid.Col span={3} mx={40}>
            <Flex direction="column" gap="md">
              <Services />
              {/* <ProjectI tem title={data.title} completed={data.completed} total={data.total} stats={data.stats} /> */}
              <ProjectItem
                title={datas.title}
                completed={datas.completed}
                total={datas.total}
                stats={datas.stats}
              />
            </Flex>
          </Grid.Col>
          <Grid.Col span={3}>
            <ClockInOut />
          </Grid.Col>
          <Grid.Col span={5}>
            <Flex direction="column" gap="md">
              {/* <RecentlyTimeTracker /> */}
              {/* <DailyOverviewTimeTracker /> */}
            </Flex>
          </Grid.Col>
        </Grid>
      </Box>
    </Paper>
  );
}
