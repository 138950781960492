import {
  Box,
  Code,
  Grid,
  Group,
  NumberInput,
  Paper,
  Select,
  TextInput,
  Textarea,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import CancelButton from "../../../../ttcomponents/Button/CancelBtn";
import SaveBottom from "../../../../ttcomponents/Button/SaveBtn";
import { FormEvent, useRef } from "react";
import { isNotEmpty, useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { IconCalendar, IconX } from "@tabler/icons-react";
import {
  HintInfo,
  TotalPriceFunction,
  formatDateUpgraded,
} from "../../../../utils/function";
import globalCortSetting from "../../../../utils/globalCortSetting";
import ProductSelect from "../../../../ttcomponents/Product/ProductSelect";
import { regInternalUseProduct } from "../../../../api";
import { useIntl } from "react-intl";
interface deliverProductProps {
  additional_information: any;
  closeFucntion: (type: number) => void;
}
export default function InternalUseProductFrom({
  additional_information,
  closeFucntion,
}: deliverProductProps) {
  // Use the useIntl hook to access the intl object
  const intl = useIntl();

  const formRef = useRef<HTMLFormElement>(null);

  const form = useForm({
    initialValues: {
      product_id: additional_information?.product_id,
      quantity: "",
      total_price: "",
      type: "",
      use_begin_time: new Date(),
      use_end_time: new Date(),
      department: "",
      description: "",
    },
    validate: {
      product_id: isNotEmpty("Product name is required"),
      quantity: isNotEmpty("picking_out_bound_quantity  is required"),
    },
  });

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    // Stop the formal submission of the form default
    event.preventDefault();
    // Verification form
    const valid = form.validate();
    if (valid.hasErrors) {
      notifications.show({
        color: "yellow.7",
        icon: <IconX />,
        message: Object.values(valid.errors)[0],
      });
      return;
    }
    let submit_type = "POST";
    // if (form.values.order_id) {
    //   submit_type = 'PUT'
    // }

    const updatedFormValues = {
      ...form.values,
      use_begin_time: formatDateUpgraded(
        form.values.use_begin_time,
        "YYYY-MM-DD"
      ),
    };
    const response = await regInternalUseProduct(
      updatedFormValues,
      submit_type
    );
    HintInfo(response.data);
    if (response.data.code == 200) {
      closeFucntion(2);
    }
  };
  const ReceiveSupplierBack = (value: any) => {};

  const changeDelivelyTime = (num: any) => {
    const totalPrice = TotalPriceFunction(
      num,
      additional_information?.unit_price
    );
    form.setFieldValue("total_price", totalPrice as string);
    form.setFieldValue("quantity", num);
  };

  return (
    <Paper p={10}>
      <form onSubmit={handleFormSubmit} ref={formRef}>
        <Grid>
          <Grid.Col span={12}>
            <ProductSelect
              createNew={false}
              disabled={additional_information?.product_id ? true : false}
              labelStr="Product Name"
              currentId={additional_information?.product_id as string}
              callBack={ReceiveSupplierBack}
            />

            <Select
              label="Select Department"
              data={[
                { value: "1", label: "department—1" },
                { value: "2", label: "department—2" },
              ]}
              dropdownPosition="top"
              onChange={(value) =>
                form.setFieldValue("department", value as string)
              }
            />

            <NumberInput
              {...form.getInputProps("quantity")}
              // value={categoryName}
              onChange={(event) => changeDelivelyTime(event)}
              label="Internal Use Quantity"
            />

            <TextInput
              {...form.getInputProps("total_price")}
              label="Total Price"
            />
            <DateInput
              valueFormat="DD/MM/YYYY"
              required
              icon={<IconCalendar size="1.1rem" stroke={1.5} />}
              {...form.getInputProps("use_begin_time")}
              label="Use Begin Time"
            />

            <Textarea
              label={intl.formatMessage({
                id: "Product Introduction",
                defaultMessage: "Product Introduction",
              })}
              {...form.getInputProps("description")}
              minRows={7}
              radius="md"
            />
          </Grid.Col>
        </Grid>

        <Group position="right">
          {/* <CancelButton callBack={() => closeFucntion(1)} /> */}
          <SaveBottom formRef={formRef} />
        </Group>

        {/** can see form values */}
        <Code block mt={5}>
          {JSON.stringify(form.values, null, 2)}
        </Code>
      </form>
    </Paper>
  );
}
