import { useEffect, useState } from 'react'
import { Avatar, ScrollArea, Group, Box, Text, Modal, Card, Paper, Flex, ActionIcon, Tooltip } from '@mantine/core'
import { IconX, IconCheck, IconUserOff } from '@tabler/icons-react'
import { modals } from '@mantine/modals'
import { UserData } from '../../../interface/Iuser'
import UserForm from './user_form'
import { AbandonUser, regUser } from '../../../api'
import { SelectRole } from '../../../interface/Icommon'
import { notifications } from '@mantine/notifications'
import ModalTitleText from '../../../ttcomponents/ModalTitleText/ModalTitleText'
import WordTranslationComponent from '../../../utils/language_pack/words'
import DeleteIconPerm from '../../../ttcomponents/DeleteIcon/DeleteIconPerm'
import EditIconPerm from '../../../ttcomponents/EditIcon/EditIconPerm'
import apiUrl from '../../../config/api_config/config'
import CreateBtn from '../../../ttcomponents/Button/CreateBtn'
import MoreActionBtnWithBottom from '../../../ttcomponents/MoreActionBtnWithBottom/MoreActionBtnWithBottom'
import { useDispatch } from 'react-redux'
import { setRefreshStatus } from '../../../state_management/features/assignments_refresh_status/assignmentsRefreshStatusSlice'
import NoData from '../../../ttcomponents/NoData/Index'
import CheckPermissionTools from '../../../utils/permission'
import { getUserGeneralFunction } from '../../../api/common_request'

interface TableSortProps {
  userList: UserData[]
  emailOptions: string[]
  userRoles: SelectRole[]
  setLoading: (value: boolean) => void
  calculationType: SelectRole[]
  costCenterData: SelectRole[]
}
interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  label: string
  email: string
  value: string
  avatar: string
}

export default function TableUser({
  emailOptions,
  userRoles,
  calculationType,
  setLoading,
  costCenterData,
  userList
}: TableSortProps) {
  //-------------permission list allocation-------------

  const Perm = CheckPermissionTools()

  //------------- permission list allocation-------------

  // Show more switch settings
  const [moreOpened, setMoreOpened] = useState(false)

  // Show more switch settings
  const [formStatus, setOpenFormState] = useState(false)
  const [sortedData, setSortedData] = useState(userList)
  // 声明用户ID
  const [userId, setUserId] = useState('')
  // 声明点击用户的信息
  const [currentUserInfo, setCurrentUserInfo] = useState<UserData>()
  //表单title 初始化
  const [formTitle, setFormTitle] = useState('create')

  // 用户列表状态
  const [UserList, setResultUser] = useState<ItemProps[]>([])

  const openCurrentPageForm = () => {
    setUserId('')
    setFormTitle('create')
    setCurrentUserInfo(undefined)
    setOpenFormState(true)
  }
  // Get translation public configuration information
  const word_translation = WordTranslationComponent()
  useEffect(() => {
    setSortedData(userList)
    // ...
  }, [userList])

  const delHandle = (row: UserData) => {
    modals.openConfirmModal({
      title: word_translation.delete_title,
      centered: true,
      children: (
        <Text size="sm">
          {word_translation.username} : {row.username}
        </Text>
      ),
      labels: {
        confirm: word_translation.confirm_delete,
        cancel: word_translation.cancel_delete
      },
      confirmProps: { color: 'red' },
      onConfirm: () => {
        delUserInfo(row.id_user)
      }
    })
  }

  //===============  Get a list of users  Function===============  //
  const getUser = async () => {
    // Call the Get User Universal Selection function and set the user list
    setResultUser(await getUserGeneralFunction('', 'select'))
  }

  const editHandle = (row: UserData) => {
    setUserId(row.id_user)
    setFormTitle('update')
    setCurrentUserInfo(row)
    setOpenFormState(true)
    // getUserInfo(row.id_user);
  }
  const dispatch = useDispatch()

  // user表单结束回调
  const userFormCallback = () => {
    setOpenFormState(false)
    dispatch(setRefreshStatus(true))
  }

  const abandonEmployees = (row: UserData) =>
    modals.openConfirmModal({
      title: 'Please confirm your action',
      children: (
        <div>
          <Text size="sm" fw={400}>
            Are you sure you no longer need this user?
          </Text>
          <Text size="sm" color="#666">
            <span>
              Once confirmed,the user will be unable to log into the system and will await HR's decision on whether to
              change the cost center or leave the company.
            </span>
          </Text>
        </div>
      ),
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onCancel: () => console.log('Cancel'),
      onConfirm: async () => {
        abandonEmployeesRequest(row.id_user)
        // if (response.data.data.code == 200) {
        //     HintInfo(response.data);
        //     ajaxCurrentList();
        // }
      }
    })

  // // 请求用户信息
  const abandonEmployeesRequest = async (id: string | number) => {
    setLoading(true)
    const response = await AbandonUser({ id: id }, 'PUT')
    setLoading(false)
    const result = response.data
    if (result.code === 200) {
      dispatch(setRefreshStatus(true))
      notifications.show({
        color: 'green',
        icon: <IconCheck />,
        message: result.msg
      })
    } else {
      notifications.show({
        color: 'red',
        icon: <IconX />,
        message: result.msg
      })
    }
  }

  // 删除用户
  const delUserInfo = async (id: string) => {
    const response = await regUser({ id: id }, 'DELETE')
    const result = response.data
    if (result.code === 200) {
      notifications.show({
        color: 'green',
        icon: <IconCheck />,
        message: result.msg
      })
    } else {
      notifications.show({
        color: 'red',
        icon: <IconX />,
        message: result.msg
      })
    }
  }

  const rows =
    sortedData.length > 0 &&
    sortedData.map((row, index) => (
      <tr className="border-bt-2" key={index}>
        <td>{row.personal_no}</td>
        <td>
          <Group>
            <Flex justify="flex-end">
              <Avatar.Group spacing="sm">
                <Avatar src={apiUrl.fileSystemDomain + row.avatar} radius={40} size={26} />
              </Avatar.Group>
            </Flex>
            <Box>{row.username}</Box>
          </Group>
        </td>
        <td>{row.email}</td>
        <td>{row.phone}</td>
        <td>{row?.role_name}</td>
        <td>{row?.calculation_type_name}</td>
        <td>{row?.name}</td>
        <td>{row?.calculation_type_id == '3' ? 'Y' : 'N'} </td>
        <td>{row.last_login ? row.last_login : '-'}</td>
        <td>
          {row.active == '1' ? (
            <IconCheck color="green" size="1rem" stroke={1.5} />
          ) : (
            <IconX color="red" size="1rem" stroke={1.5} />
          )}
        </td>
        <td>
          <Group spacing={0} position="center">
            <EditIconPerm enable={Perm.user_update} callBack={() => editHandle(row)} />
            {/* <EditIconPerm enable={Perm.user_update} callBack={Perm.user_create == true ? () => editHandle(row) : () => { }} /> */}
            <ActionIcon onClick={() => abandonEmployees(row)}>
              <Tooltip label={word_translation.offboarding}>
                <IconUserOff size="1.1rem" color="red" stroke={1.3} />
              </Tooltip>
            </ActionIcon>
            <DeleteIconPerm enable={Perm.user_delete} callBack={() => delHandle(row)} />
          </Group>
        </td>
      </tr>
    ))

  return (
    <>
      <ScrollArea mih={400}>
        <table className="table_custome text-center">
          <thead className="table_th_xs">
            <tr>
              <th>{word_translation.personal_no}</th>
              <th>{word_translation.username}</th>
              <th>{word_translation.email}</th>
              <th>{word_translation.phone}</th>
              <th>{word_translation.user_role}</th>
              <th>{word_translation.calculation}</th>
              <th>{word_translation.cost_center_name}</th>
              {/* <th>{word_translation.allows_to_timeTracker}</th> */}
              <th>{word_translation.timetracker}</th>
              {/*   <th>{word_translation.order_leader}</th> */}
              {/* <th>{word_translation.approval_permission}</th> */}
              <th>{word_translation.last_login}</th>
              <th>{word_translation.active}</th>
              <th>{word_translation.option}</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
        {userList.length > 0 ? <></> : <NoData px={400} />}
        <Modal
          size="xl"
          opened={formStatus}
          closeOnClickOutside={false}
          onClose={() => setOpenFormState(false)}
          title={
            <ModalTitleText
              title={
                formTitle === 'create'
                  ? word_translation.create_user
                  : `${word_translation.udpate_user}:${currentUserInfo?.username}`
              }
            />
          }
          overlayProps={{ opacity: 0.5, blur: 4 }}
        >
          <UserForm
            userid={userId}
            emailOptions={emailOptions}
            formStatus={formStatus}
            costCenterData={costCenterData}
            UserList={UserList}
            calculationType={calculationType}
            userRoles={userRoles}
            callBack={userFormCallback}
          />
        </Modal>
      </ScrollArea>
      {Perm.user_create && (
        <>
          {moreOpened && (
            <Paper className="positioned-right-bottom-content" shadow="sm">
              <Card>
                <Flex
                  className="border-radius-md"
                  mih={50}
                  gap="md"
                  justify="flex-end"
                  align="flex-end"
                  direction="column"
                  wrap="wrap"
                >
                  <CreateBtn callBack={openCurrentPageForm} />
                </Flex>
                <Group noWrap>{/* Add more elements if needed */}</Group>
              </Card>
            </Paper>
          )}
          <MoreActionBtnWithBottom moreOpened={moreOpened} callBack={setMoreOpened} />
        </>
      )}
    </>
  )
}
