import { regReportGetUser, requestLeavePermissionIdentifierListAction, requestLeavePermissionListAction, requestReportPermissionIdentifierListAction, requestReportPermissionListAction, requestReportRoleDetail, requestReportSetDetail } from ".";
import { requesTmUniquePermissionActionList } from "./index_v2";




// Fetches the permission action list
export const getReportPermissionActionListGeneralFunction = async (where: any, get_type = 'select') => {
  // Initialize the return result
  let returnResult: any = [];

  try {
    // Call the API to get the project list
    const response = await requestReportPermissionListAction(where);
    const result = response.data;

    // Check if the API response is successful (status code 200)
    if (result.code === 200) {
      if (get_type === 'select') {
        // Extract and format data for the 'select' type
        const customerData = result.data.map(
          (item: { id_permission_identifier: string; name: any; }) => ({
            value: item.id_permission_identifier.toString(),
            label: item.name,
          })
        );

        // Set the formatted project list data to the return result
        returnResult = customerData;
      }
      returnResult = result.data;
    }
  } catch (error) {
    // Handle any errors that occur during the API call
    console.error('Error fetching project list:', error);
    // Optionally, you can throw the error or handle it as needed
    throw error;
  }

  // Return the final result
  return returnResult;
};



// Fetches the permission identifier list
export const getReportPermissionIdentifierListGeneralFunction = async (where: any, get_type = 'select') => {
  // Initialize the return result
  let returnResult: any = [];

  try {
    // Call the API to get the project list
    const response = await requestReportPermissionIdentifierListAction(where);
    const result = response.data;

    // Check if the API response is successful (status code 200)
    if (result.code === 200) {
      if (get_type === 'select') {
        // Extract and format data for the 'select' type
        const customerData = result.data.map(
          (item: { id_permission_identifier: string; name: any; }) => ({
            value: item.id_permission_identifier.toString(),
            label: item.name,
          })
        );

        // Set the formatted project list data to the return result
        returnResult = customerData;
      }
      returnResult = result.data;
    }
  } catch (error) {
    // Handle any errors that occur during the API call
    console.error('Error fetching project list:', error);
    // Optionally, you can throw the error or handle it as needed
    throw error;
  }

  // Return the final result
  return returnResult;
};


// get role detail by 
export const getReportPermissionRoleDetail = async (where: any) => {
  let returnArray: any = {};
  try {
    const response = await requestReportRoleDetail(where);
    const result = response.data;
    // Check if the response code is 200 (OK)
    if (result.code === 200) {
        returnArray =  result.data

        if(Array.isArray(returnArray) && returnArray.length === 0){
          returnArray = undefined;
        }
    }
  } catch (error) {
    // Handle errors by returning an empty array
    console.error('Error fetching user data:', error);
  }

  return returnArray;
};// get role detail by 
export const getReportPermissionSetDetail = async (where: any) => {
  let returnArray: any = {};
  try {
    const response = await requestReportSetDetail(where);
    const result = response.data;
    // Check if the response code is 200 (OK)
    if (result.code === 200) {
        returnArray =  result.data

        if(Array.isArray(returnArray) && returnArray.length === 0){
          returnArray = undefined;
        }
    }
  } catch (error) {
    // Handle errors by returning an empty array
    console.error('Error fetching user data:', error);
  }

  return returnArray;
};



// Obtain user data for general selection
export const getUserPermissionReport = async (where: any = [], get_type = 'select') => {
  let returnArray: any = []
  try {
    // Make an asynchronous request to fetch all user data
    const response = await regReportGetUser(where, 'GET')
    const result = response.data
    // Check if the response code is 200 (OK)
    if (result.code === 200) {
      if (get_type === 'select') {
        returnArray = result.data.map((item: { email: string; avatar: string; id_user: string; username: any }) => ({
          value: item.id_user.toString() as string,
          label: item.username,
          avatar: item.avatar,
          email: item.email
        }))
      } else if (get_type === 'all_info') {
        returnArray = result.data
      }
    }
  } catch (error) {
    // Handle errors by returning an empty array
    console.error('Error fetching user data:', error)
  }

  return returnArray
}