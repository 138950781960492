import { ActionIcon, Tooltip } from '@mantine/core'
import { IconTrash } from '@tabler/icons-react'
import WordTranslationComponent from '../../utils/language_pack/words'

// Define the props interface for the ChildComponent
interface ChildComponentProps {
  size?: any
  stroke?: any
  color?: any
  disabled?: boolean
  callBack: () => void // Callback function to handle button click events
}

// ChildComponent representing the Create Button
const DeleteIcon: React.FC<ChildComponentProps> = ({ stroke, disabled = true, size, color, callBack }) => {
  // Get translation public configuration information
  const word_translation = WordTranslationComponent()

  return (
    <ActionIcon disabled={disabled} onClick={() => callBack()}>
      <Tooltip label={word_translation.delete_tips}>
        <IconTrash
          size={size ? size : '1rem'}
          stroke={stroke ? stroke : '1.5'}
          color={!disabled ? '#333' : color ? color : 'red'}
        />
      </Tooltip>
    </ActionIcon>
  )
}

export default DeleteIcon
