import { Box, Paper, Grid, ScrollArea, Text, Select, TextInput, Tooltip, Group, Code, LoadingOverlay } from "@mantine/core";
import PageTitle from "../../../ttcomponents/PageTitle/PageTitle";
import { useIntl } from "react-intl";
import { useForm } from "@mantine/form";
import { IconAt, IconClipboardPlus, IconTrashX, IconX } from "@tabler/icons-react";
import globalCoreSetting from "../../../utils/globalCortSetting";
import GlobalCoreSetting from "../../../utils/globalCortSetting";
import { FocusEvent, FormEvent, useEffect, useRef, useState } from "react";
import { SelectRole } from "../../../interface/Icommon";
import { reGetDomains, reGetEmployeeCustomAttributeList, regUser, regUserAll, requestBoarding, requestDataBoarding } from "../../../api";
import { Attribute, IAttributeValueSubmit, ResponseUserData } from "../../../interface/Iuser";
import SaveBottom from "../../../ttcomponents/Button/SaveBtn";
import WordTranslationComponent from "../../../utils/language_pack/words";
import TableTextInputTitle from "../../../ttcomponents/TableTextInputTitle/TableTextInputTitle";
import TableTopTitle from "../../../ttcomponents/TableTopTitle/TableTopTitle";
import ResetBtn from "../../../ttcomponents/Button/ResetBtn";
import { getCostCenterGeneralSelectFunction, getCustomAttributesGeneralFunction, getDomainsSelectFunction, getUserGeneralSelectFunction, getUserRoleGeneralSelectFunction } from "../../../api/common_request";
import { HintErrorMantineInfo, HintInfo, getAttributesPreviewComponent } from "../../../utils/function";
import { notifications } from "@mantine/notifications";

import GeneralInformation from "./general_information";
import Affiliation from "./affiliation";
import Extras from "./extras";
import Attributes from "./attributes";
import SubmitBtn from "../../../ttcomponents/Button/SubmitBtn";
import { useDisclosure } from "@mantine/hooks";


interface ComponentInterface {
    id?: any
}
/**
 * OnBoarding 
 * @returns 
 */
export default function OnBoarding({id=0}:ComponentInterface) {

    const [loading, setLoading] = useState(false);
    const [rand_number, setRandNumber] = useState<number>(1);
    // Get translation public configuration information
    const word_translation = WordTranslationComponent();
 
    const [attributeList, setAttributeList] = useState<Attribute[]>([]);
    const [attributeSubmitData, setAttributeSubmitData] = useState<IAttributeValueSubmit[]>([]);

    // Define all the list of employees you can choose
    const [formValue, setFormValue] = useState<any>({
        personal_no: "",
        first_name: "",
        last_name: "",
        email: "",
        costCenter_id:'',
        phone: "",
        boarding_status_id:1,
        offset_overtime:'00:00',
        vacation_day_year: "30",
        work_hours_week: "40",
        working_days_week: "5",
        boarding_status: "1",
        project_ids: [],
    });


    /**
     * Get the current list data
     */
    const ajaxCurrentList = async () => {
        // Get a list of cost centers
        getCustomAttributes();
    };


    /**
     * Get a list of custom attributes
     */
    const getCustomAttributes = async () => {
        setAttributeList(await getCustomAttributesGeneralFunction());
    };

    /**
     * Reset the form
     */
    const resetFunction = () => {
        setRandNumber(rand_number + 1);
    }


    /**
     * Get a list of custom attributes
     */
    const getdataBoarding = async (id:any) => {
       let response =  await requestDataBoarding({id:id});
    
       if(response.data.code == 200){
            if(response.data.data.project_ids){
                response.data.data.project_ids = response.data.data.project_ids.split(',');
            }
            response.data.data.personal_no = response.data.data.stuff_no

            setFormValue(response.data.data)
       }
    };
    
    useEffect(() => {
        if(id != 0){
            getdataBoarding(id)       
        }
    }, [id]);
    
    /**
     * When the component is mounted, call the ajaxCurrentList method to get the data
    */
    useEffect(() => {
        // Call the ajaxCurrentList method to get the data
        ajaxCurrentList();
    }, []);


    const handleFormSubmit = async () => {
        setLoading(true);
        const response = await requestBoarding(formValue, "POST");
        setLoading(false);
        HintInfo(response.data);
    }
   
    return (
        <Paper px={20}>
            {id == 0 &&　   <PageTitle title="ON - BOARDING" lang_id="on_boarding" />　}
         
                <Box mt={10}  pos="relative">
                    <LoadingOverlay visible={loading} overlayBlur={2} />
                    <Grid gutter={0}  >
                        {/* General Informations */}
                        <Grid.Col span={5} >
                            <GeneralInformation formValue={formValue} setFormValue={setFormValue} />
                        </Grid.Col>
                        {/* General Informations end */}
                        {/* Affiliation */}
                        <Grid.Col span={3} >
                            <Affiliation formValue={formValue} setFormValue={setFormValue} />
                        </Grid.Col>
                        {/* Affiliation end */}
                        {/* Extras */}
                        <Grid.Col span={4}>
                            <Extras formValue={formValue} setFormValue={setFormValue} />
                        </Grid.Col>
                        {/* Extras end */}
                    </Grid>
                </Box>
  
                {/* Custom Attributes */}
                <Box>
                    {/* <Box className="border-radius-xs mt-md border-custom">
                        <TableTopTitle title={word_translation.custom_attributes} />
                        <ScrollArea h={id !=0?270:300} >
                            <Box className="px-15 pt-xs pt-4">
                                <Grid>
                                    {attributeList.map((item, index) => (
                                        <Grid.Col key={index} span={2}>
                                            {getAttributesPreviewComponent(item.id, item.type, item.name, item.default_value, handleAdditionalCallback, rand_number)}
                                        </Grid.Col>
                                    ))}
                                </Grid>
                            </Box>
                        </ScrollArea>
                    </Box> */}

                    <Attributes formValue={formValue} setFormValue={setFormValue} />

                    <Group className="m-xs" position="right">
                        <ResetBtn callBack={() => resetFunction()} />
                        <SubmitBtn btn_size="xs" callBack={handleFormSubmit}  />
                    </Group>
                    
                </Box>      
            {/* Custom Attributes end */}
        </Paper>
    )
}