import React from "react";
import { Button } from "@mantine/core";
import { IconCircleCheck, IconCirclePlus } from "@tabler/icons-react";
import { FormattedMessage } from "react-intl";
import WordTranslationComponent from "../../utils/language_pack/words";

// Define the props interface for the ChildComponent
interface ChildComponentProps {
  btn_size?: string;
  disabled?:boolean;
  callBack: () => void; // Callback function to handle button click events
}

// This is a reusable button component for submitting actions without using a form,
// addressing compatibility issues in Firefox to avoid NS_BINDING_ABORTED errors.
const SubmitBtn: React.FC<ChildComponentProps> = ({disabled=false, callBack, btn_size }) => {
  // Get translation public configuration information
  const word_translation = WordTranslationComponent();

  // Click event handler to invoke the callback function
  const handleButtonClick = () => {
    callBack();
  };

  return (
    // Button component with a submit type, click event handler, and left icon
    <Button
      type="button"
      size={btn_size ?? 'md'}
      onClick={handleButtonClick}
      disabled={disabled}
      leftIcon={<IconCircleCheck width={14} height={14} />}
      styles={{
        leftIcon: { marginRight: "5px" },
      }}
    >
      {word_translation.save} {/* Display the "save" text */}
    </Button>
  );
};

export default SubmitBtn;
