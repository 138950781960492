import {
  Box,
  Group,
  TextInput,
  Textarea,
} from "@mantine/core";
import { FormEvent, useEffect, useState, useRef } from "react";
import { ITaskCategoryParameter } from "../../../../interface/ItaskCategoryParameter";
import { hasLength, isNotEmpty, useForm } from "@mantine/form";
import { regTaskCategoryParameter } from "../../../../api";
import SaveBottom from "../../../../ttcomponents/Button/SaveBtn";
import WordTranslationComponent from "../../../../utils/language_pack/words";
import TableTextInputTitle from "../../../../ttcomponents/TableTextInputTitle/TableTextInputTitle";
import { HintInfo } from "../../../../utils/function";
// Define the interface of the component and specify that the component needs to accept some parameters
interface ItaskCategoryParameterProps {
  taskCategoryParameterInfo: ITaskCategoryParameter | undefined;
  callback: () => void;
  [key: string]: any;
}
export default function TaskCategoryParameterFrom({  taskCategoryParameterInfo,  callback,}: ItaskCategoryParameterProps) {

  const formRef = useRef<HTMLFormElement>(null);

  // Get translation public configuration information
  const word_translation = WordTranslationComponent()
  
  // Create form fields and their validation
  const form = useForm({
    validateInputOnBlur: true,
    initialValues: {
      id: taskCategoryParameterInfo?.id_task_category_parameter,
      description: taskCategoryParameterInfo?.description,
      matrix: taskCategoryParameterInfo?.matrix,
      name: taskCategoryParameterInfo?.name,
      identifier: taskCategoryParameterInfo?.identifier,
    },
    // functions will be used to validate values at corresponding key
    validate: {
      identifier:  isNotEmpty(`${word_translation.identifier} ${word_translation.is_not_empty}`),
      matrix:  isNotEmpty(`${word_translation.matrix} ${word_translation.is_not_empty}`),
      name: hasLength({ min: 2, max: 100 }, `${word_translation.street}${word_translation.validata_value_need_2_100_length}`),
    },
  });

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {

    event.preventDefault(); // Stop the formal submission of the form default
    // Verification form
    const valid = await form.validate();
    if (!valid.hasErrors) {
      const type = form.values.id != "" ? "PUT" :"POST";
      const response = await regTaskCategoryParameter(form.values, type);
      const result = response.data;
      HintInfo(result);
      if (result.code === 200) {
        callback();
      }
    }
  };

  return (
    <Box>
      <form onSubmit={handleFormSubmit} ref={formRef}>
        <TableTextInputTitle title={word_translation.identifier} />
        <TextInput
          radius={5}
          {...form.getInputProps("identifier")}
          placeholder={word_translation.Input}
        />

        <TableTextInputTitle title={word_translation.name} />
        <TextInput
          radius={5}
          {...form.getInputProps("name")}
          placeholder={word_translation.Input}
        />

        <TableTextInputTitle title={word_translation.matrix} />
        <TextInput
          radius={5}
          {...form.getInputProps("matrix")}
          placeholder={word_translation.Input}
        />

        <TableTextInputTitle title={word_translation.description} />
        <Textarea
          radius={5}
          {...form.getInputProps("description")}
          placeholder={word_translation.Input}
        />

        <Group position="right">
          <SaveBottom formRef={formRef} />
        </Group>
      </form>
    </Box>
  );
}
