import { decryptData, encryptData } from "./function";
import apiUrl from "../config/api_config/config";

const USER_KEY = "USER_KEY";
const PATH_KEY = "Path";
const TAB_KEY = "Tab_key";
const TimeTracker = "TimeTracker_key";
const System = "System";
const MENU_KEY = "MENU_KEY";
const MENU_FOOT_KEY = "MENU_FOOT_KEY";
const PERMISSIONS = "PERMISSIONS";

/*包含 n 个操作 local storage 的工具函数的模块
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  saveUser(user) {
    const userJSON = JSON.stringify(user);
    const encryptUserInfo = encryptData(userJSON, apiUrl.encryption_key, "");
    localStorage.setItem(USER_KEY, encryptUserInfo);
  },
  getUser() {
    const userStr = localStorage.getItem(USER_KEY);

    let decryptUserInfo = "";
    try {
      decryptUserInfo = decryptData(userStr, apiUrl.encryption_key, "");
    } catch (e) {
      decryptUserInfo = userStr;
    }
    const user = decryptUserInfo ? JSON.parse(decryptUserInfo) : {};

    return user;
  },

  saveMenu(menu) {
    const menuJSON = JSON.stringify(menu);
    localStorage.setItem(MENU_KEY, menuJSON);
  },
  getMenu() {
    const menuJSON = localStorage.getItem(MENU_KEY);
    const menu = menuJSON ? JSON.parse(menuJSON) : {};
    return menu;
  },

  saveFootMenu(menu) {
    const menuFootJSON = JSON.stringify(menu);
    localStorage.setItem(MENU_FOOT_KEY, menuFootJSON);
  },
  getFootMenu() {
    const menuFootJSON = localStorage.getItem(MENU_FOOT_KEY);
    const menu = menuFootJSON ? JSON.parse(menuFootJSON) : {};
    return menu;
  },

  // 设置系统缓存
  saveSystem(SystemValue) {
    const systemJSON = JSON.stringify(SystemValue);
    const encryptSystemInfo = encryptData(
      systemJSON,
      apiUrl.encryption_key,
      ""
    );
    localStorage.setItem(System, encryptSystemInfo);
  },

  getSystem() {
    const SystemStr = localStorage.getItem(System);

    let decryptUserInfo = "";
    try {
      decryptUserInfo = decryptData(SystemStr, apiUrl.encryption_key, "");
    } catch (e) {
      decryptUserInfo = SystemStr;
    }
    // 解析外层的 JSON 字符串
    let SystemInfo = JSON.parse(decryptUserInfo);

    if (SystemInfo) {
      SystemInfo.leave_module = SystemInfo.leave_module || {};
    } else {
      SystemInfo = {};
    }

    return SystemInfo;
  },

  // 存储权限
  savePermissionIdentifier(SystemValue) {
    const JSON_STR = JSON.stringify(SystemValue);
    const permissions = encryptData(JSON_STR, apiUrl.encryption_key, "");
    localStorage.setItem(PERMISSIONS, permissions);
    // const permissions =  (encryptData(JSON_STR,apiUrl.encryption_key,''))
    localStorage.setItem(PERMISSIONS, permissions);
  },

  getPermissionIdentifier() {
    try {
      const permissions = localStorage.getItem(PERMISSIONS);

      const JSON_STR = decryptData(permissions, apiUrl.encryption_key, "");

      const permissionsResult = permissions ? JSON.parse(JSON_STR) : {};

      return permissionsResult;
    } catch (error) {
      // console.error('Error parsing JSON from localStorage:', error);
      return {}; // 返回一个默认的空对象或根据你的需求返回其他值
    }
  },
  removeUser() {
    // localStorage.removeItem(USER_KEY)
    localStorage.removeItem("USER_KEY");
  },
  removeMenu() {
    // localStorage.removeItem(USER_KEY)
    localStorage.removeItem("MENU_KEY");
  },

  removePath() {
    // localStorage.removeItem(USER_KEY)
    localStorage.removeItem(PATH_KEY);
  },

  removePermission() {
    // localStorage.removeItem(USER_KEY)
    localStorage.removeItem("PERMISSIONS");
  },

  /**
   * currentActivePath
   * currentActiveIndex
   * currentChildrenActivePath
   * collapsedMenus
   * @param
   */
  savePath(pathName) {
    //保存路径,用于刷新时网址路径不是菜单栏的时候
    // Save the path to refresh the URL path is not when the menu bar is not the menu bar
    let pathData = {};
    if (localStorage.getItem(PATH_KEY)) {
      pathData = JSON.parse(localStorage.getItem(PATH_KEY));
      pathData = { ...pathData, ...pathName };
    } else {
      pathData = pathName;
    }
    localStorage.setItem(PATH_KEY, JSON.stringify(pathData));
  },
  getPath() {
    return JSON.parse(localStorage.getItem(PATH_KEY)) || {};
  },
  //保存选项卡索引，刷新后还停留当前位置
  //保存选项卡索引，刷新后还停留当前位置
  saveTabIndex(classTab) {
    let pathData = {};
    if (localStorage.getItem(TAB_KEY)) {
      pathData = JSON.parse(localStorage.getItem(TAB_KEY));
      pathData = { ...pathData, ...classTab };
    } else {
      pathData = classTab;
    }
    localStorage.setItem(TAB_KEY, JSON.stringify(pathData));
  },
  getTabIndex() {
    return JSON.parse(localStorage.getItem(TAB_KEY)) || {};
  },

  setTimeTracker(time) {
    const TimeTrackerContent = JSON.stringify(time);
    localStorage.setItem(TimeTracker, TimeTrackerContent);
  },

  getTimeTracker() {
    return JSON.parse(localStorage.getItem(TimeTracker)) || {};
  },
  // Clear timing cache
  removeTimeTracker() {
    localStorage.removeItem(TimeTracker);
  },
};
