import {LoadingOverlay, Group, TextInput, Textarea, Checkbox } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconCheck,  IconX } from "@tabler/icons-react";
import {useState } from "react";
import { IProjectStatusWithCallback, IProjectStatusData } from "../../interface/IprojectStatus";
import { notifications } from "@mantine/notifications";
import {  regProjectStatus } from "../../api";
import WordTranslationComponent from "../../utils/language_pack/words";
import SubmitBtn from "../../ttcomponents/Button/SubmitBtn";
import TableTextInputTitle from "../../ttcomponents/TableTextInputTitle/TableTextInputTitle";


export default function ProjectStatusForm({projectStatusSingleData ,callBack}: IProjectStatusWithCallback ) {
    const form = useForm<IProjectStatusData>({
        initialValues:projectStatusSingleData,
        validate: (values) => ({
            status_name: values.status_name.length < 2 ? 'Too short name' : null,
        }),
    });

  // Get translation public configuration information
  const word_translation = WordTranslationComponent();
    //加载中状态
    const [loading, setLoading] = useState(false);	

    const handleFormSubmit = async () => {
        // Verification form
        const valid = await form.validate();
        if (!valid.hasErrors) {
          let originalData = {
            status_name: form.values.status_name,
            status_desc: form.values.status_desc,
            active: form.values.active,
           }
           let formData = {};
           const methodType = form.values.id_status ? "PUT" : "POST";
           formData = form.values.id_status ? { ...originalData, id: form.values.id_status } : originalData;
           setLoading(true);
           const response = await regProjectStatus(formData,methodType);
           const result = response.data;
            if (result.code === 200) {
                setLoading(false);
                callBack(true)
                notifications.show({
                    title:  result.code,
                    color: 'green',
                    icon: <IconCheck />,
                    message: result.msg,
                })
            } else {
                setLoading(false);
                 callBack(false)
                notifications.show({
                    title:  result.code,
                    color: 'red',
                    icon: <IconX />,
                    message: result.msg,
                })
            }
        }
    };
    
    return (
        <form onSubmit={handleFormSubmit} >
         <LoadingOverlay visible={loading} overlayBlur={2} />
           <TextInput 
                mt={10} disabled label={word_translation.name} required placeholder="Status Name" {...form.getInputProps('status_name')} />
    
           <Textarea
                mt={10}
                label={word_translation.description}
                placeholder="Please write some profiles of the Status"
                minRows={5}
                maxRows={5}
                {...form.getInputProps('status_desc')}
            />   
                 
          <Checkbox
            label={<TableTextInputTitle title={word_translation.active} />}
            mt={10}
            checked={form.values.active == 1 ? true :false}
            onChange={(event) =>{
              form.setFieldValue('active',event.currentTarget.checked ? 1:0)
            }}
          />
            <Group position="right" mt="md" > 
                <SubmitBtn  callBack={handleFormSubmit} btn_size="xs" />
            </Group>
        </form>
    )
}