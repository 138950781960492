import { useEffect, useState } from "react";
import {
  createStyles,
  Table,
  Tooltip,
  UnstyledButton,
  Group,
  Text,
  Center,
  TextInput,
  rem,
  Button,
  ActionIcon,
} from "@mantine/core";
import { keys } from "@mantine/utils";
import {
  IconSelector,
  IconChevronDown,
  IconChevronUp,
  IconSearch,
  IconX,
  IconCheck,
  IconEdit,
  IconTrash,
  IconPencil,
  IconUserPlus,
} from "@tabler/icons-react";
import theme from "../../theme";
import DeleteModal from "../DeleteModals/DeleteModal";
import { modals } from "@mantine/modals";
import { FormattedMessage, useIntl } from "react-intl";
import WordTranslationComponent from "../../utils/language_pack/words";
import GlobalCoreSetting from "../../utils/globalCortSetting";
import { IModuleData } from "../../interface/Setting";
import EditIconPerm from "../EditIcon/EditIconPerm";
import DeleteIconPerm from "../DeleteIcon/DeleteIconPerm";
import CheckPermissionTools from "../../utils/permission";



interface TableSortProps {
  data: IModuleData[];
  onValueEdit: (value: IModuleData) => void;
  onValueDel: (id: string) => void;
}


export default function TableModules({
  data,
  onValueEdit,
  onValueDel,
}: TableSortProps) {

  // Get the global configuration
  const globalCoreSetting = GlobalCoreSetting();
  const Perm = CheckPermissionTools();
  // Get translation public configuration information
  const word_translation = WordTranslationComponent();
  useEffect(() => {
    setSortedData(data);
    // ...
  }, [data]);
  const [sortedData, setSortedData] = useState(data);

  const delHandle = (row: IModuleData) => {
    modals.openConfirmModal({
      title: word_translation.delete_title,
      centered: true,
      children: (
        <Text size="sm" mt={12}>
          <p> {word_translation.module_name} : {row.moduleName}</p>
        </Text>
      ),
      labels: globalCoreSetting.DeleteOpenConfirmModaLabels,
      confirmProps: globalCoreSetting.DeleteOpenConfirmModaConfirmProps,
      onConfirm: () => {
        onValueDel(row.id_modules);
      },
    });
  };

  const editHandle = (row: IModuleData) => {
    onValueEdit(row);
  };

  const rows = sortedData.map((row) => (
    <tr className="text-center" key={row.id_modules}>
      <td>{row.moduleName}</td>
      <td>{row?.version}</td>
      <td>{row.moduleIP}</td>
      <td>{row.licenceCount}</td>
      <td>
        {row.isEnabled == "1" ? (
          <IconCheck color="green" size="1rem" stroke={1.5} />
        ):(
          <IconX color="red" size="1rem" stroke={1.5} />
        )}
      </td>
      <td>
      <Group spacing={0} position="center">
            <EditIconPerm  enable={Perm.module_service_update}  callBack={() => editHandle(row)}/>
             <DeleteIconPerm enable={Perm.module_service_delete} callBack={() => delHandle(row)}/>
          </Group>
      </td>
    </tr>
  ));

  return (
    <>  
   <table className="table_custome text-center">
    {/* Table header */}
    <thead className="table_th">
      <tr>
        <th id="table-th-title-new">{word_translation.module_name}</th>
        <th id="table-th-title-new">{word_translation.version}</th>
        <th id="table-th-title-new">{word_translation.module_ip}</th>
        <th id="table-th-title-new">{word_translation.licence_count}</th>
        <th id="table-th-title-new">{word_translation.is_enable}</th>
        <th id="table-th-title-new">{word_translation.option}</th>
      </tr>
    </thead>
    {/* Table body */}
    <tbody>{rows}</tbody>
  </table></>
  );
}
