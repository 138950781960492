import { ActionIcon, Box, Text, Button, ColorPicker, ColorSwatch, Grid, Group, Modal, MultiSelect, SegmentedControl, Select, Space, Switch, Tooltip, TextInput, Flex } from '@mantine/core';
import { useCounter, useDisclosure } from '@mantine/hooks';
import ModalTitleText from '../../../ttcomponents/ModalTitleText/ModalTitleText';
import WordTranslationComponent from '../../../utils/language_pack/words';
import { useEffect, useState } from 'react';
import TableTextInputTitle from '../../../ttcomponents/TableTextInputTitle/TableTextInputTitle';
import ResetBtn from '../../../ttcomponents/Button/ResetBtn';
import SubmitBtn from '../../../ttcomponents/Button/SubmitBtn';
import localstorageUnits from '../../../utils/localstorageUnits';
import { DateInput } from '@mantine/dates';
import { IconCalendar } from '@tabler/icons-react';
import { getCustomerListGeneralFunction } from '../../../api/assignments_request';
import { SelectRole } from '../../../interface/Icommon';
import GlobalCoreSetting from '../../../utils/globalCortSetting';
import UniversalButton from '../../../ttcomponents/Button/UniversalButton';
import TableTextInputTitleDesc from '../../../ttcomponents/TableTextInputTitleDesc/TableTextInputTitleDesc';
import { AssignmentsInterface, ProjectDataInterface } from '../../../interface/Iprojects';
import { requestTaskOverTime } from '../../../api/index_v2';
import { validateTimeFormat } from '../../../utils/validataUnits';
import { ClientWarningHint, HintInfo, TimeHHSS, formatTimeHHSS } from '../../../utils/function';
import { time } from 'console';
interface ComponentProps {
    openFormStatus: boolean;
    is_load: boolean;
    closeFunction: () => void;
    objectsUsed: string;
    currentTaskInfo:AssignmentsInterface |undefined;
    currentProjectInfo:ProjectDataInterface |undefined;
    successCallback?: (value: any) => void
}

function AssignmentAddOvertime({ openFormStatus, currentTaskInfo,closeFunction, currentProjectInfo,is_load,successCallback, objectsUsed }: ComponentProps) {
  

    // Get the default unchanged configuration
    const globalCoreSetting = GlobalCoreSetting();
    // Define all the customer lists that can be selected
    const [customerList, setCustomerList] = useState<SelectRole[]>([]);
    const [overTime,setOverTime] = useState('00:00');
    const [randNumber, setRandNumber] = useState(1);
    // Get the initial time of the system
    const initSystemTime = localstorageUnits.getSystem().init_use_date;

    const [openForm, { open: openModalFunction, close: closeModalFunction }] = useDisclosure(false);
    // Get translation public configuration information
    const word_translation = WordTranslationComponent();

    useEffect(() => {
        openFormStatus ? openModalFunction() : closeModalFunction();
    }, [openFormStatus])


    useEffect(() => {
        setOverTime(currentTaskInfo?.estimated_increase_time?currentTaskInfo?.estimated_increase_time:'00:00')
    }, [currentTaskInfo])

    
    // Close component
    const closeBtnModalFunction = () => {
        closeModalFunction();
        closeFunction();
    }

    // Click save
    const applyBtnFunction = async () => {
        console.log('Apply');
        const data = {
            task_id:currentTaskInfo?.id_task,
            time: overTime
        }
        console.log(data)
        const response = await requestTaskOverTime(data, "POST");
        const result = response.data;
        HintInfo(result);
        if(result.code == 200){
            closeFunction()
        }
 
    }


    const addHour = () => {
        // 将 overTime 转换为分钟，加上 60 分钟（1 小时），再转换回时间格式
        const newOverTime = formatTimeInMinutes(overTime) + 60;
        setOverTime(formatMinutesInTime(newOverTime));
      };
    
      const delHour = () => {
        // 将 overTime 转换为分钟，减去 60 分钟（1 小时），再转换回时间格式
        const newOverTime = formatTimeInMinutes(overTime) - 60;
        setOverTime(formatMinutesInTime(newOverTime));
      };
    
      const formatTimeInMinutes = (time: string): number => {
        const [hours, minutes] = time.split(':').map(Number);
        return hours * 60 + minutes;
      };
    
      const formatMinutesInTime = (minutes: number): string => {
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;
        return `${String(hours).padStart(2, '0')}:${String(remainingMinutes).padStart(2, '0')}`;
      };
    // 手动填入
    
  const setOverTimeFunction = (event: React.FocusEvent<HTMLInputElement>) => {
  
    if (validateTimeFormat(event.target.value)) {
        setOverTime(TimeHHSS(event))
      } else {
        ClientWarningHint("00:00");
      }
  };

    return (
        <Modal
            opened={openForm}
            onClose={closeBtnModalFunction}
            closeOnClickOutside={false}
            title={<ModalTitleText title={word_translation.add_overtime} />}
            padding="xs"
            size='md'
            radius={10}
        >{overTime}
            <Box className='border-bt-1' px={40}>
                <TableTextInputTitle class_name='filter_title_text' title={word_translation.project_times} />
                <Group position="apart">
                    <Box>
                        <TableTextInputTitle title={word_translation.estimated_time} />
                        <Text>{currentProjectInfo?.estimated_time}</Text>
                    </Box>
                    <Box>
                        <TableTextInputTitle title={word_translation.actual_time} />
                        <Text>{formatTimeHHSS(currentProjectInfo?.actual_used_time || '00:00:00')}</Text>
                    </Box>
                </Group>

                <TableTextInputTitle class_name='filter_title_text' title={word_translation.task_times} />
                <Group position="apart">
                    <Box>
                        <TableTextInputTitle title={word_translation.estimated_time} />
                        <Text>{formatTimeHHSS(currentTaskInfo?.estimated_time || '00:00:00')}</Text>
                    </Box>
                    <Box>
                        <TableTextInputTitle title={word_translation.actual_time} />
                        <Text>{currentTaskInfo?.actual_time?currentTaskInfo?.actual_time: '00:00'  }</Text>
                    </Box>
                </Group>
            </Box>
            <Box className='border-bt-1'py={20} px={40}>
                <Grid mt={10}  align='center'> 
                <Grid.Col span={4}>
                        <Button size='xs' bg='#ccc' onClick={addHour}>
                        {word_translation.add_one_hour}
                        </Button>
                    </Grid.Col>
                    <Grid.Col span={4}>
                        <TextInput
                        placeholder={word_translation.overtime_init}
                        defaultValue={overTime}
                        onBlur={setOverTimeFunction}
                        key={overTime}
                        // onChange={(event) => setOverTime(event.target.value)}
                        />
                    </Grid.Col>
                    <Grid.Col span={4}>
                        <Button size='xs' bg='#ccc' onClick={delHour}>
                        {word_translation.subtract_one_hour}
                        </Button>
                    </Grid.Col>
                </Grid>
            </Box>
            <Group position="apart" mt={5}>
                <UniversalButton btn_bg_color='#868E96' btn_text={word_translation.cancel} btn_size='xs' callBack={closeBtnModalFunction} />
                <UniversalButton btn_text={word_translation.add} btn_size='xs' callBack={applyBtnFunction} />
            </Group>
        </Modal>
    );
}
export default AssignmentAddOvertime;
