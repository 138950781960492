import { Box, Flex, Text } from "@mantine/core";
import { FormattedMessage } from "react-intl";
interface SearchBoxProps {
  title: any;
  pt?:number;
  pb?:number;
  color?:string;
  lang_id?: any;
}
export default function TableTextInputTitleDesc({color, title,pt, pb,lang_id ='' }: SearchBoxProps) {
  return (
        <Text  className="input_title_desc" color={color? color:'#868E96'}  pt={pt ? pt : 0  } pb={pb ? pb : 0 }>
            {lang_id ? <FormattedMessage id={lang_id} defaultMessage={title}/> : title  }
        </Text>
  );
}
