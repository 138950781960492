import { useDisclosure } from "@mantine/hooks";
import { hasLength,  useForm } from "@mantine/form";
import {
  Group,
  TextInput,
  Paper,
  Checkbox,
  Flex,
  Card,
  Modal,
} from "@mantine/core";
import {  useEffect, useState } from "react";
import { regModules, regRefreshCache } from "../../../../api";
import {  IconRotateClockwise2 } from "@tabler/icons-react";
import TableModules from "../../../../ttcomponents/Table/TableModules";
import { HintErrorMantineInfo, HintInfo, HintInfoCatchError } from "../../../../utils/function";
import { ComponentInterface } from "../../../../interface/Icommon";
import MoreActionBtnWithBottom from "../../../../ttcomponents/MoreActionBtnWithBottom/MoreActionBtnWithBottom";
import CreateBtn from "../../../../ttcomponents/Button/CreateBtn";
import UniversalButton from "../../../../ttcomponents/Button/UniversalButton";
import WordTranslationComponent from "../../../../utils/language_pack/words";
import TableTextInputTitle from "../../../../ttcomponents/TableTextInputTitle/TableTextInputTitle";
import ModalTitleText from "../../../../ttcomponents/ModalTitleText/ModalTitleText";
import SubmitBtn from "../../../../ttcomponents/Button/SubmitBtn";
import CheckPermissionTools from "../../../../utils/permission";
import { NotPermission } from "../../../../ttcomponents/NoPermission";

export default function ModulesSettings({  is_load_str,}: ComponentInterface){
  // Show more switch settings
  const [moreOpened, setMoreOpened] = useState(false);
  
  const Perm = CheckPermissionTools();
  // Get translation public configuration information
  const word_translation = WordTranslationComponent();
  const [opened, { open, close }] = useDisclosure(false);
  const [moduleData, setmoduleData] = useState([]);
  const form = useForm({
    validateInputOnBlur: true,
    initialValues: {
      id_modules: 0,
      moduleIP: "",
      isEnabled: false,
      licenceCount: 0,
      moduleName: "",
      version:'',
      database_ip: "",
      database_user: "",
      database_port: "",
      database_password: "",
    },
    // functions will be used to validate values at corresponding key
    validate: {
      // moduleName Validation Rules
      moduleIP: hasLength(
      { min: 2, max: 100 },
      `${word_translation.module_ip}${word_translation.validata_value_need_2_100_length}`
    ),
    // moduleName Validation Rules
    moduleName: hasLength(
      { min: 2, max: 100 },
      `${word_translation.module_name}${word_translation.validata_value_need_2_100_length}`
    ),
    },
  });

/**
 * Fetches currency settings information.
 * If the current section is related to module services, it sends a GET request to retrieve module data.
 * Updates the state with the received module data.
 * Logs an error message if the request is not successful.
 */
const ajaxCurrentList = async () => {
  if (is_load_str === "modules_serve") {
    try {
      // Send a GET request to retrieve module data
      const response = await regModules({}, "GET");
      const result = response.data;

      // Update the state with the received module data
      setmoduleData(result.data.data);

      // Log an error message if the request is not successful
      if (result.code !== 200 || !Array.isArray(result.data.data)) {
        HintInfoCatchError();
      }
    } catch (error) {
      HintInfoCatchError();
    }
  }
};


  /**
 * Handles the form submission.
 * - Validates the form.
 * - Displays error information if validation fails.
 * - Prepares form data for the request.
 * - Determines the request method type (POST or PUT).
 * - Sends the request to update or create modules.
 * - Closes the form and refreshes the current list upon successful response.
 * - Displays a notification with success or error information.
 * @returns {boolean} - Returns false if there are validation errors, true otherwise.
 */
const handleFormSubmit = async () => {
  try {
    // Validate the form
    const valid = await form.validate();

    // If there are validation errors, display error information
    if (valid.hasErrors) {
      HintErrorMantineInfo(valid);
      return false;
    }

    // Prepare form data for the request
    const formData = {
      isEnabled: form.values.isEnabled ? 1 : 0,
      moduleIP: form.values.moduleIP,
      moduleName: form.values.moduleName,
      licenceCount: form.values.licenceCount,
      version: form.values.version,
      id: form.values.id_modules || undefined,
    };

    // Determine the request method type (POST or PUT)
    const methodType = form.values.id_modules ? "PUT" : "POST";

    // Send the request to update or create modules
    const response = await regModules(formData, methodType);
    const result = response.data;

    // Display a notification with success or error information
    HintInfo(response.data);

    // If the response is successful, close the form and refresh the current list
    if (result.code === 200) {
      close();
      ajaxCurrentList();
    } 
  } catch (error) {
    // Handle errors and display a notification
    HintInfoCatchError();
  }
};


  useEffect(() => {
    ajaxCurrentList();
  }, [is_load_str]);

  const handleOnValueDel = async (id: string) => {
    const response = await regModules({ id: id,}, "DELETE");
    const result = response.data;
    HintInfo(response.data);
    if (result.code === 200) {
      ajaxCurrentList();
    } 
  };

  /**
 * Handle the editing of a value in the form.
 * @param value The value that needs to be modified.
 */
const handleOnValueEdit = (value: object) => {
  // Open the form
  open();

  // Convert the value to a specific shape
  const editedValue = value as {
      isEnabled: string;
      licenceCount: number;
      moduleName: string;
      moduleIP: string;
      id_modules: number;
      version: string;
  };

  // Set form field values based on the editedValue
  form.setFieldValue("licenceCount", editedValue.licenceCount);
  form.setFieldValue("id_modules", editedValue.id_modules);
  form.setFieldValue("moduleName", editedValue.moduleName);
  // Set a boolean field value based on a condition
  form.setFieldValue("isEnabled", editedValue.isEnabled == "1");
  form.setFieldValue("moduleIP", editedValue.moduleIP);
  form.setFieldValue("version", editedValue.version);
};

  /**
   * refresh the cache
   */
  const refreshFunction = async () => {
    // Send an asynchronous request to refresh the cache
    const response = await regRefreshCache({ "cache_type": "redis" }, 'PUT');

    // Display information based on the response data
    HintInfo(response.data);
    
    // Check if the response code is 200
    if (response.data.code === 200) {
        // Uncomment the line below if you want to close something (e.g., a modal)
        // closeFunction(2);
    }
};


/**
 * Open the form on the current page and reset the form.
 */
const openCurrentPageForm = () => {
  // Reset the form
  form.reset();
  // Open the form
  open();
};

  return (
    <Paper>
        <Modal
          opened={opened}
          closeOnClickOutside={false}
          onClose={close}
          title={<ModalTitleText title={word_translation.modules_manage}/>}
          overlayProps={{ opacity: 0.5, blur: 4 }}
        >
          <Card className="" radius={15} color="customize">
                <TableTextInputTitle title={word_translation.module_name} />
                <TextInput
                  placeholder={word_translation.Input}
                  disabled={form.values.id_modules?true:false}
                  {...form.getInputProps("moduleName")}
                />

                <TableTextInputTitle py={10} title={word_translation.module_ip} />
                <TextInput
                  placeholder={word_translation.Input}
                  {...form.getInputProps("moduleIP")}
                />
             
             <TableTextInputTitle py={10} title={word_translation.licence_count} />
                <TextInput
                  placeholder={word_translation.Input}
                  {...form.getInputProps("licenceCount")}
                />   
                <TableTextInputTitle py={10} title={word_translation.version} />
                 <TextInput
                   placeholder={word_translation.Input}
                   {...form.getInputProps("version")}
                 />

              <Checkbox
                pt={10}
                label={<TableTextInputTitle  title={word_translation.is_enable} />}
                {...form.getInputProps("isEnabled", { type: "checkbox" })}
              />
              <Group position="right">
                <SubmitBtn btn_size="xs" callBack={handleFormSubmit}/>
              </Group>
          </Card>
        </Modal>
        <Group position="left">
          {Perm.module_service_read ? moduleData.length > 0 && (
            <TableModules
              data={moduleData}
              onValueEdit={handleOnValueEdit}
              onValueDel={handleOnValueDel}
            />
          ) : <NotPermission />}
       
        </Group>

        {moreOpened && (
                <Paper className="positioned-right-bottom-content" shadow="sm">
                    <Card>
                    <Flex
                      className="border-radius-md"
                      mih={50}
                      gap="md"
                      justify="flex-end"
                      align="flex-end"
                      direction="column"
                      wrap="wrap"
                    >
                       <UniversalButton 
                          btn_color="blue" 
                          btn_fw={200} 
                          btn_text={word_translation.refresh_module_cache} 
                          btn_icon={<IconRotateClockwise2 />} 
                          btn_variant="outline" 
                          callBack={refreshFunction}/>
                        <CreateBtn callBack={openCurrentPageForm} />
                  </Flex>

                      <Group noWrap >
                      
                      </Group>
                    </Card>
                </Paper>
            )}
            <MoreActionBtnWithBottom moreOpened={moreOpened} callBack={setMoreOpened}/>
    </Paper>
  );
}
