
import { reGetDomains, reGetEmployeeCustomAttributeList, regCalculateEstimated, regCompanySeting, regCostcenterSetting, regCustomerList, regCustomerRole, regGetUserRoleListNopagination, regGlobalSetting, regOrderProducts, regProjectList, regProjectStatus, regSelfCostcenterSetting, regTaskCategory, regUserAll, requestLeavePermissionSetDetail } from ".";

import { requestAssignmentPermissionSetDetail, requestCustomTaskList, requestGetPermissionRoleDetail, requestOrderList, requestPermissionActionList, requestPermissionIdentifierList, requestPermissionListRole, requestPermissionRole, requestPermissionSetList, requestProjectList, requestSingleOrder, requestSingleProject, requestSingleTask, requestTask, requestTaskList, requestTimeTrackerRemarkDetail, requestTimeTrackerUniquePermissionActionList, requestTimeTrackerUniquePermissionIdentifierList } from "./index_v2";


export const getCalculateEstimated= async (select:any) =>
{
    const TimeTrackerResponese = await regCalculateEstimated(select, "GET");
    if(TimeTrackerResponese.data.code==200){
        return TimeTrackerResponese.data.data;
    }
    return [];
}





  /***********************************************************User******************************************************************/
  
// Obtain user data for general selection
export const getUserGeneralSelectFunction = async (): Promise<Array<{ value: string; label: string }>> => {
    let returnArray: Array<{ value: string; label: string }> = [];
  
    try {
      // Make an asynchronous request to fetch all user data
      const response = await regUserAll({}, "GET");
      const result = response.data;
  
      // Check if the response code is 200 (OK)
      if (result.code === 200) {
        // Map the user data to the required format with 'value' and 'label' properties
        returnArray = result.data.map((item: { id_user: string; username: any }) => ({
          value: item.id_user as string,
          label: item.username,
        }));
      }
    } catch (error) {
      // Handle errors by returning an empty array
      console.error('Error fetching user data:', error);
    }
  
    return returnArray;
  };
  
  
  
 /**
 * Fetches the list of custom attributes for employee data.
 * @returns {Promise<Array>} A Promise resolving to an array of custom attributes.
 */
 export const getCustomAttributesGeneralFunction = async (): Promise<Array<any>> => {
  try {
    // Call the reGetEmployeeCustomAttributeList method to get the list of custom attributes
    const responseAttributeList = await reGetEmployeeCustomAttributeList({}, "GET");

    // Check if the response code is 200 (OK)
    if (responseAttributeList.data.code === 200) {
      // Return the array of custom attributes directly
      return responseAttributeList.data.data;
    }

    // If the response code is not 200, return an empty array
    return [];
  } catch (error) {
    // Handle errors by returning an empty array
    console.error('Error fetching custom attributes:', error);
    return [];
  }
};



/**
 * Fetches user roles and returns them in the required format for a select dropdown.
 * @returns {Promise<Array<{ value: string; label: string }>>} A Promise containing an array of objects with 'value' and 'label' properties.
 */
export const getUserRoleGeneralSelectFunction = async (): Promise<Array<{ value: string; label: string }>> => {
  let returnArray: Array<{ value: string; label: string }> = [];

  try {
    // Make an asynchronous request to fetch all user roles
    const response = await regGetUserRoleListNopagination({}, "GET");
    const result = response.data;

    // Check if the response code is 200 (OK)
    if (result.code === 200) {
      // Map the user role data to the required format with 'value' and 'label' properties
      returnArray = result.data.map((item: { id_user_role: string; role_name: string; role_identifier: string }) => ({
        value: item.id_user_role.toString() as string,
        label: `${item.role_name} (${item.role_identifier})`, // Combine role_name and role_identifier in the label
      }));
    }
  } catch (error) {
    // Handle errors by returning an empty array
    console.error('Error fetching user role data:', error);
  }

  return returnArray;
};


/******************************************************System***********************************************************************/

/**
 * Fetches the list of domain names.
 * @returns {Promise<string[]>} A Promise resolving to an array of domain names.
 */
export const getDomainsSelectFunction = async (): Promise<string[]> => {
  try {
    // Call the RegetDomains method to get the list of domain names
    const responseGetDomains = await reGetDomains({});
    // Convert the response data to the ANY type
    const resultGetDomains = responseGetDomains.data as any;

    // Check if the response code is 200 (OK)
    if (resultGetDomains.code === 200) {
      // Return the array of domain names directly
      return resultGetDomains.data;
    }

    // If the response code is not 200, return an empty array
    return [];
  } catch (error) {
    // Handle errors by returning an empty array
    console.error('Error fetching domain names:', error);
    return [];
  }
};


  /**
 * Fetches cost center general functions asynchronously.
 * @returns {Promise<Array<{ value: string; label: string }>>} An array of cost center data with 'value' and 'label' properties.
 */
export const getCostCenterGeneralSelectFunction = async (value:any=''): Promise<Array<{ value: string; label: string }>> => {
    if (value === '') {
      value = {
        list: true
      }
    }
    let centerData: Array<{ value: string; label: string }> = [];
    try {
      // Make an asynchronous request to fetch cost center settings
      const response = await regCostcenterSetting(value, "GET");
      const result = response.data;
      // Check if the response code is 200 (OK)
      if (result.code === 200) {
        // Map the data to the required format with 'value' and 'label' properties
        centerData = result.data.map((item: { id_costcenter: string; name: any }) => ({
          value: item.id_costcenter.toString() as string,
          label: item.name,
        }));
      }
    } catch (error) {
        return centerData;
    }
    return centerData;
  };


    /**
 * Fetches cost center general functions asynchronously.
 * @returns {Promise<Array<{ value: string; label: string }>>} An array of cost center data with 'value' and 'label' properties.
 */
export const getCostCenterSelfSelectFunction = async (value:any=''): Promise<Array<{ value: string; label: string }>> => {
  if (value === '') {
    value = {
      list: true
    }
  }
  let centerData: Array<{ value: string; label: string }> = [];
  try {
    // Make an asynchronous request to fetch cost center settings
    const response = await regSelfCostcenterSetting(value, "GET");
    const result = response.data;
    // Check if the response code is 200 (OK)
    if (result.code === 200) {
      // Map the data to the required format with 'value' and 'label' properties
      centerData = result.data.map((item: { id_costcenter: string; name: any }) => ({
        value: item.id_costcenter.toString() as string,
        label: item.name,
      }));
    }
  } catch (error) {
      return centerData;
  }
  return centerData;
};

/**
 * Fetches cost center general functions asynchronously.
 * @returns {Promise<Array<any>>} An array of cost center data with any format.
 */
export const getCostCenterGeneralFunction = async (): Promise<[]> => {
  // Initialize an empty array to store cost center data
  let centerData: [] = [];

  try {
    // Make an asynchronous request to fetch cost center settings using the regCostcenterSetting function with the "GET" method
    const response = await regCostcenterSetting({}, "GET");
    const result = response.data;

    // Check if the response code is 200 (OK)
    if (result.code === 200) {
      // Assign the data to the centerData array
      centerData = result.data.data;
    }
  } catch (error) {
    // If an error occurs during the request, return the empty array
    return centerData;
  }

  // Return the array of cost center data
  return centerData;
};

/**
* Asynchronous function to obtain company configuration information.
*
* @returns Returns a Promise that resolves to company configuration information on success and an empty array on failure.
*/
export const getCompanyGeneralFunction = async (): Promise<any> => {
  //Initialize an empty array to store company configuration information
  let companySettings: any;

  try {
  // Initiate an asynchronous request and use the "GET" method to obtain company configuration information through the regCompanySeting function
    const response = await regCompanySeting({}, 'GET');
    const result = response.data;

   // 检查响应代码是否为 200 (OK)
    if (result.code === 200) {
      // Assign data to companySettings 
      companySettings = result.data;
    }
  } catch (error) {
    // If an error occurs during the request, an empty array is returned
    return companySettings;
  }

  // Returns   of company configuration information
  return companySettings;
};

/**
 * Asynchronous function to obtain core configuration information.
 *
 * @returns Returns a Promise that resolves to core configuration information on success and an empty array on failure.
 */
export const getGlobalSettingGeneralFunction = async (): Promise<any> => {
  // Initialize an empty variable to store core configuration information
  let globalSetting: any;

  try {
    // Initiate an asynchronous request and use the "GET" method to obtain core configuration information through the regGlobalSetting function
    const response = await regGlobalSetting({}, "GET");
    const result = response.data;

    // Check if the response code is 200 (OK)
    if (result.code === 200) {
      // Assign data to globalSetting
      globalSetting = result.data;
    }
  } catch (error) {
    // If an error occurs during the request, return an empty array
    return globalSetting;
  }

  // Return core configuration information
  return globalSetting;
};


// Fetches the project list
export const getProjectListGeneralFunction = async (where: any, get_type = 'select') => {
  // Initialize the return result
  let returnResult: any = [];

  try {
    // Call the API to get the project list
    const response = await requestProjectList(where);
    const result = response.data;

    // Check if the API response is successful (status code 200)
    if (result.code === 200) {
      if (get_type === 'select') {
        // Extract and format data for the 'select' type
        const projectListInfo = result.data.map(
          (item: { id_project: string; name: any; project_no: string }) => ({
            value: item.id_project.toString(),
            project_no: item.project_no.toString(),
            label: item.name,
          })
        );

        // Set the formatted project list data to the return result
        returnResult = projectListInfo;
      }
    }
  } catch (error) {
    // Handle any errors that occur during the API call
    console.error('Error fetching project list:', error);
    // Optionally, you can throw the error or handle it as needed
    throw error;
  }

  // Return the final result
  return returnResult;
};


// Fetches the Order list
export const getOrderListGeneralFunction = async (where: any, get_type = 'select') => {
  // Initialize the return result
  let returnResult: any = [];

  try {
    // Call the API to get the order list
    const response = await requestOrderList(where);
    const result = response.data;

    // Check if the API response is successful (status code 200)
    if (result.code === 200) {
      if (get_type === 'select') {
        // Extract and format data for the 'select' type
        const orderListInfo = result.data.map(
          (item: { id_order: string; order_name: any;  }) => ({
            value: item.id_order.toString(),
            label: item.order_name,
          })
        );

        // Set the formatted project list data to the return result
        returnResult = orderListInfo;
      }else{
        returnResult = result.data;
      }
    }
  } catch (error) {
    // Handle any errors that occur during the API call
    console.error('Error fetching project list:', error);
    // Optionally, you can throw the error or handle it as needed
    throw error;
  }

  // Return the final result
  return returnResult;
};



// Fetches the project list
export const getTaskListGeneralFunction = async (where: any, get_type = 'select') => {
  // Initialize the return result
  let returnResult: any = [];

  try {
    // Call the API to get the project list
    const response = await requestTaskList(where,'GET');
    const result = response.data;
    console.log(response)
    // Check if the API response is successful (status code 200)
    if (result.code === 200) {
      if (get_type === 'select') {
        // Extract and format data for the 'select' type
        const taskListInfo = result.data.map(
          (item: { id_task: string; task_category_identifier: any;}) => ({
            value: item.id_task.toString(),
            label: item.task_category_identifier,
          })
        );

        // Set the formatted project list data to the return result
        return taskListInfo
      }
      return result.data
    }
  } catch (error) {
    // Handle any errors that occur during the API call
    console.error('Error fetching project list:', error);
    // Optionally, you can throw the error or handle it as needed
    throw error;
  }

  // Return the final result
  return returnResult;
};


// Fetches the project list
export const getCustomTaskListFunction = async (where: any, get_type = 'select') => {
  // Initialize the return result
  let returnResult: any = [];

  try {
    // Call the API to get the project list
    const response = await requestCustomTaskList(where,'GET');
    const result = response.data;
    // Check if the API response is successful (status code 200)
    if (result.code === 200) {
      if (get_type === 'select') {
        // Extract and format data for the 'select' type
        const taskListInfo = result.data.map(
          (item: {
            name: any; id_task: string; identifier: any;}) => ({
              value: item.id_task.toString(),
               project_name: item.name?.toString(),
               label: `${item.identifier}`,
          })
        );

        // Set the formatted project list data to the return result
        return taskListInfo
      }
      return result.data
    }
  } catch (error) {
    // Handle any errors that occur during the API call
    console.error('Error fetching project list:', error);
    // Optionally, you can throw the error or handle it as needed
    throw error;
  }

  // Return the final result
  return returnResult;
};


// 获取客户

// Fetches the project list
export const getCustomerListGeneralFunction = async (where: any, get_type = 'select') => {
  // Initialize the return result
  let returnResult: any = [];

  try {
    // Call the API to get the project list
    const response = await regCustomerList();
    const result = response.data;

    // Check if the API response is successful (status code 200)
    if (result.code === 200) {
      if (get_type === 'select') {
        // Extract and format data for the 'select' type
        const customerData = result.data.map(
          (item: { id_customer: string; company_name: any; }) => ({
            value: item.id_customer.toString(),
            label: item.company_name,
          })
        );

        // Set the formatted project list data to the return result
        returnResult = customerData;
      }
    }
  } catch (error) {
    // Handle any errors that occur during the API call
    console.error('Error fetching project list:', error);
    // Optionally, you can throw the error or handle it as needed
    throw error;
  }

  // Return the final result
  return returnResult;
};



/**
 * 获取员工在项目中的角色
 */

// Fetches the project list
export const getProjectRoleGeneralFunction = async (where: any, get_type = 'select') => {
  // Initialize the return result
  let returnResult: any = [];

  try {
    // Call the API to get the project list
    const response = await regCustomerRole({}, "GET");
    const result = response.data;

    // Check if the API response is successful (status code 200)

    if (result.code === 200) {
      const datas = result.data.data.map((item: { id_customer_contact_person_role: any; role_name: any; }) => ({
        value: item.id_customer_contact_person_role,
        label: item.role_name,
      }));
      returnResult = datas;
    } 
   
  } catch (error) {
    // Handle any errors that occur during the API call
    console.error('Error fetching role list:', error);
    // Optionally, you can throw the error or handle it as needed
    throw error;
  }

  // Return the final result
  return returnResult;
};



/**
 * 获取员工在项目中的角色
 */

// Fetches the project list
export const getPermissiontRoleGeneralFunction = async (where: any, get_type = 'select') => {
  // Initialize the return result
  let returnResult: any = [];

  try {
    // Call the API to get the project list
    const response = await requestPermissionRole(where, "GET");
    const result = response.data;

    // Check if the API response is successful (status code 200)

    if (result.code === 200) {
      const datas = result.data.data.map((item: { id_permission_role: any; title: any; }) => ({
        value: item.id_permission_role,
        label: item.title,
      }));
      returnResult = datas;
    } 
   
  } catch (error) {
    // Handle any errors that occur during the API call
    console.error('Error fetching role list:', error);
    // Optionally, you can throw the error or handle it as needed
    throw error;
  }

  // Return the final result
  return returnResult;
};

// Fetches the project list
export const getPermissiontRoleListGeneralFunction = async (where: any, get_type = 'select') => {
  // Initialize the return result
  let returnResult: any = [];
  try {
    // Call the API to get the project list
    const response = await requestPermissionListRole(where, "GET");
    const result = response.data;

    console.log(result,'**************') 
    // Check if the API response is successful (status code 200)

    if (result.code === 200) {
      const datas = result.data.map((item: { id_permission_role: any; title: any; }) => ({
        value: item.id_permission_role,
        label: item.title,
      }));

      returnResult = datas;
    } 
   
  } catch (error) {
    // Handle any errors that occur during the API call
    console.error('Error fetching role list:', error);
    // Optionally, you can throw the error or handle it as needed
    throw error;
  }

  // Return the final result
  return returnResult;
};

/**
 * 
 */

// Fetches the project list
export const getOrderProductsGeneralFunction = async (where: any, get_type = 'select') => {
  // Initialize the return result
  let returnResult: any = [];

  try {
    // Call the API to get the project list
    const response = await regOrderProducts({}, "GET");
    const result = response.data;

    // Check if the API response is successful (status code 200)

    if (result.code === 200) {
      returnResult = result.data;
    } 
   
  } catch (error) {
    // Handle any errors that occur during the API call
    console.error('Error fetching role list:', error);
    // Optionally, you can throw the error or handle it as needed
    throw error;
  }

  // Return the final result
  return returnResult;
};




// Fetches the project list
export const getTimetrackerRemarkFunction = async (where: any, get_type = 'select') => {
  // Initialize the return result
  let returnResult: any = [];

  try {
    // Call the API to get the project list
    const response = await requestTimeTrackerRemarkDetail(where, "GET");
    const result = response.data;

    // Check if the API response is successful (status code 200)

    if (result.code === 200) {
      returnResult = result.data;
    } 
   
  } catch (error) {
    // Handle any errors that occur during the API call
    console.error('Error fetching role list:', error);
    // Optionally, you can throw the error or handle it as needed
    throw error;
  }

  // Return the final result
  return returnResult;
};
// get Project detail by project_id
export const getProjectDetailByIdFunction = async (id: string | number) => {
    let returnArray: any = {};
    try {
      const response = await requestSingleProject(id);
      const result = response.data;
      // Check if the response code is 200 (OK)
      if (result.code === 200) {
          returnArray =  result.data

          if(Array.isArray(returnArray) && returnArray.length === 0){
            returnArray = undefined;
          }
      }
    } catch (error) {
      // Handle errors by returning an empty array
      console.error('Error fetching user data:', error);
    }

    return returnArray;
};


// get Order detail by project_id
export const getOrderDetailByIdFunction = async (id: string| number) => {
  let returnArray: any = [];
  try {
    const response = await requestSingleOrder(id);
    const result = response.data;
    // Check if the response code is 200 (OK)
    if (result.code === 200) {
        returnArray =  result.data
    }
  } catch (error) {
    // Handle errors by returning an empty array
    console.error('Error fetching user data:', error);
  }

  return returnArray;
};




// get task detail by task_id
export const getTaskDetailByIdFunction = async (id: string| number) => {
  let returnArray: any = [];
  try {
    const response = await requestSingleTask(id);
    const result = response.data;
    // Check if the response code is 200 (OK)
    if (result.code === 200) {
        returnArray =  result.data
    }
  } catch (error) {
    // Handle errors by returning an empty array
    console.error('Error fetching user data:', error);
  }

  return returnArray;
};


// Fetches the project list
export const getAllTaskCategoryGeneralFunction = async (where: any, get_type = 'select') => {
  // Initialize the return result
  let returnResult: any = [];

  try {
    // Call the API to get the project list
    const response = await regTaskCategory(where, "GET");
    const result = response.data;
 
    // Check if the API response is successful (status code 200)

    if (result.code === 200) {
      const datas = result.data.map((item: { id_task_category: string; identifier: any }) => ({
        value: item.id_task_category.toString(),
        label: item.identifier,
      }));
      returnResult = datas;
    } 
   
  } catch (error) {
    // Handle any errors that occur during the API call
    console.error('Error fetching role list:', error);
    // Optionally, you can throw the error or handle it as needed
    throw error;
  }

  // Return the final result
  return returnResult;
};




// Fetches the permission Identifier Unique list
export const getTmPerIdentifierUniqueList = async () => {
  // Initialize the return result
  let returnResult: any = [];

  try {
    // Call the API to get the Unique PermissionIdentifier List
    const response = await requestTimeTrackerUniquePermissionIdentifierList();
    const result = response.data;

    // Check if the API response is successful (status code 200)
    if (result.code === 200) {
      
        // Extract and format data for the 'select' type
        const customeData = result.data.map(
          (item: { identifier: string;  }) => ({
            value: item.identifier,
            label: item.identifier,
          })
        );

        // Set the formatted   data to the return result
        returnResult = customeData;
    }
  } catch (error) {
    // Handle any errors that occur during the API call
    console.error('Error fetching  list:', error);
    // Optionally, you can throw the error or handle it as needed
    throw error;
  }  // Return the final result
  return returnResult;
};

// Fetches the permission identifier list
export const getPermissionIdentifierListGeneralFunction = async (where: any, get_type = 'select') => {
  // Initialize the return result
  let returnResult: any = [];

  try {
    // Call the API to get the project list
    const response = await requestPermissionIdentifierList(where);
    const result = response.data;

    // Check if the API response is successful (status code 200)
    if (result.code === 200) {
      if (get_type === 'select') {
        // Extract and format data for the 'select' type
        const customerData = result.data.map(
          (item: { id_permission_identifier: string; name: any; }) => ({
            value: item.id_permission_identifier.toString(),
            label: item.name,
          })
        );

        // Set the formatted project list data to the return result
        returnResult = customerData;
      }
      returnResult = result.data;
    }
  } catch (error) {
    // Handle any errors that occur during the API call
    console.error('Error fetching project list:', error);
    // Optionally, you can throw the error or handle it as needed
    throw error;
  }

  // Return the final result
  return returnResult;
};



// Fetches the permission action Unique list
export const getTmPerActionUnique = async () => {
  // Initialize the return result
  let returnResult: any = [];

  try {
    // Call the API to get the project list
    const response = await requestTimeTrackerUniquePermissionActionList();
    const result = response.data;

    // Check if the API response is successful (status code 200)
    if (result.code === 200) {
      
        // Extract and format data for the 'select' type
        const customerData = result.data.map(
          (item: { identifier: string;  }) => ({
            value: item.identifier,
            label: item.identifier,
          })
        );
        // Set the formatted project list data to the return result
        returnResult = customerData;
    }
  } catch (error) {
    // Handle any errors that occur during the API call
    console.error('Error fetching project list:', error);
    // Optionally, you can throw the error or handle it as needed
    throw error;
  }
  // Return the final result
  return returnResult;
}
// Fetches the permission action list
export const getPermissionActionListGeneralFunction = async (where: any, get_type = 'select') => {
  // Initialize the return result
  let returnResult: any = [];

  try {
    // Call the API to get the project list
    const response = await requestPermissionActionList(where);
    const result = response.data;

    // Check if the API response is successful (status code 200)
    if (result.code === 200) {
      if (get_type === 'select') {
        // Extract and format data for the 'select' type
        const customerData = result.data.map(
          (item: { id_permission_identifier: string; name: any; }) => ({
            value: item.id_permission_identifier.toString(),
            label: item.name,
          })
        );

        // Set the formatted project list data to the return result
        returnResult = customerData;
      }
      returnResult = result.data;
    }
  } catch (error) {
    // Handle any errors that occur during the API call
    console.error('Error fetching project list:', error);
    // Optionally, you can throw the error or handle it as needed
    throw error;
  }

  // Return the final result
  return returnResult;
};




// Fetches the permission role list
export const getPermissionSetListGeneralFunction = async (where: any, get_type = 'select') => {
  // Initialize the return result
  let returnResult: any = [];

  try {
    // Call the API to get the project list
    const response = await requestPermissionSetList(where);
    const result = response.data;

    // Check if the API response is successful (status code 200)
    if (result.code === 200) {
      if (get_type === 'select') {
        // Extract and format data for the 'select' type
        const customerData = result.data.map(
          (item: { id_permission_identifier: string; name: any; }) => ({
            value: item.id_permission_identifier.toString(),
            label: item.name,
          })
        );
        // Set the formatted project list data to the return result
        returnResult = customerData;
      }
      returnResult = result.data;
    }
  } catch (error) {
    // Handle any errors that occur during the API call
    console.error('Error fetching project list:', error);
    // Optionally, you can throw the error or handle it as needed
    throw error;
  }

  // Return the final result
  return returnResult;
};






// 获取权限角色详细信息，包括角色下面的成员等等 角色的set记录等等
export const getTMPermissionRoleDetail = async (where: any) => {
  let returnArray: any = {};
  try {
    const response = await requestGetPermissionRoleDetail(where);
    const result = response.data;
    // Check if the response code is 200 (OK)
    if (result.code === 200) {
        returnArray =  result.data

        if(Array.isArray(returnArray) && returnArray.length === 0){
          returnArray = undefined;
        }
    }
  } catch (error) {
    // Handle errors by returning an empty array
    console.error('Error fetching user data:', error);
  }

  return returnArray;
};


/**
 * Retrieves role details based on the provided criteria.
 * @param {Object} where - Criteria for filtering role details.
 * @returns {Promise<Object>} - Returns an object containing role details.
 */
export const getAssignmentPermissionSetDetail = async (where: any): Promise<Object | undefined> => {
  let returnArray: any = {};
  try {
    const response = await requestAssignmentPermissionSetDetail(where);
    const result = response.data;
    // Check if the response code is 200 (OK)
    if (result.code === 200) {
        returnArray =  result.data

        if(Array.isArray(returnArray) && returnArray.length === 0){
          returnArray = undefined;
        }
    }
  } catch (error) {
    // Handle errors by returning an empty array
    console.error('Error fetching user data:', error);
  }

  return returnArray;
};



/**
 * Retrieves item status based on the provided criteria.
 * @param {Object} where - Criteria for filtering item status.
 * @param {string} get_type - Type of data retrieval (default: 'select').
 * @returns {Promise<Array>} - Returns an array of item statuses.
 * @throws {Error} - Throws an error if the API call fails.
 */
export const getItemStatus = async (where: any, get_type = 'select'): Promise<Array<any>> => {
  // Initialize the return result
  let returnResult: any = [];

  try {
    // Call the API to get the project list
    const response = await regProjectStatus(where);
    const result = response.data;

    // Check if the API response is successful (status code 200)
    if (result.code === 200) {
      if (get_type === 'select') {
        // Extract and format data for the 'select' type
        const customeData = result.data.map(
          (item: { id_status: any; status_name: string; }) => ({
            value: item.id_status.toString(),
            label: item.status_name,
          })
        );
        console.log(customeData)
        // Set the formatted project list data to the return result
        returnResult = customeData;
      }else{
        returnResult = result.data;
      }
    }
  } catch (error) {
    // Handle any errors that occur during the API call
    console.error('Error fetching project list:', error);
    throw error;
  }

  // Return the final result
  return returnResult;
};


/**
 * Fetches cost center general functions asynchronously.
 * @returns {Promise<Array<any>>} An array of cost center data with any format.
 */
export const getManageRoleDetail = async (): Promise<[]> => {
  // Initialize an empty array to store cost center data
  let centerData: [] = [];

  try {
    // Make an asynchronous request to fetch cost center settings using the regCostcenterSetting function with the "GET" method
    const response = await regCostcenterSetting({}, "GET");
    const result = response.data;

    // Check if the response code is 200 (OK)
    if (result.code === 200) {
      // Assign the data to the centerData array
      centerData = result.data.data;
    }
  } catch (error) {
    // If an error occurs during the request, return the empty array
    return centerData;
  }

  // Return the array of cost center data
  return centerData;
};