import React from 'react';
import {
  BarChart,
  Bar,
  Brush,
  ReferenceLine,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Area,
} from 'recharts';

const data = [
  { week: '1', uv: 300, overtime: 456 },
  { week: '2', uv: -145, overtime: 230 },
  { week: '3', uv: -100, overtime: 345 },
  { week: '4', uv: -8, overtime: 450 },
  { week: '5', uv: 100, overtime: 321 },
  { week: '6', uv: 9, overtime: 235 },
  { week: '7', uv: 53, overtime: 267 },
  { week: '8', uv: 252, overtime: -378 },
  { week: '9', uv: 79, overtime: -210 },
  { week: '10', uv: 294, overtime: -23 },
  { week: '12', uv: 43, overtime: 45 },
  { week: '13', uv: -74, overtime: 90 },
  { week: '14', uv: -71, overtime: 130 },
  { week: '15', uv: -117, overtime: 11 },
  { week: '16', uv: -186, overtime: 107 },
  { week: '17', uv: -16, overtime: 926 },
  { week: '18', uv: -125, overtime: 653 },
  { week: '19', uv: 222, overtime: 366 },
  { week: '20', uv: 372, overtime: 486 },
  { week: '21', uv: 182, overtime: 512 },
];

const Test = () => {
  return (
    <BarChart
      width={900}
      height={500}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="week" />
      <YAxis />
      <Tooltip />
      <Area type="monotone" dataKey="uv" fill="#1c7ed6" stroke="#1c7ed6" />
      <Legend verticalAlign="top" wrapperStyle={{ lineHeight: '40px' }} />
      <ReferenceLine y={0} stroke="#000" />
      <Brush dataKey="week" height={30} stroke="#1c7ed6" />
      <Bar dataKey="overtime" fill="#1c7ed6" />
    </BarChart>
  );
};

export default Test;
