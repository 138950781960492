import {
  Box,
  Grid,
  Avatar,
  Group,
  Text,
  Checkbox,
  ScrollArea,
  ActionIcon,
  Textarea,
  Tooltip,
  Highlight,
  Mark,
  Table,
  Badge,
  Flex,
  Modal,
} from "@mantine/core";
import "../css/index.css";
import { useEffect, useState } from "react";
import { momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import { randomId, useDisclosure, useListState } from "@mantine/hooks";
import { Vacation } from "../../../interface/Ivaction";
import { modals } from "@mantine/modals";
import { Edit } from "../svg/Edit";
import { Delete } from "../svg/Delete";
import { Cancel } from "../svg/Cancel";
import { ManagerReject } from "../svg/ManagerReject";
import { Manageragree } from "../svg/Manageragree";
import {
  regDeliverHolidays,
  regLeaveSickReaded,
  regVacationApproval,
  regVacationApprovalBatch,
} from "../../../api";
import { HintInfo, formatDate, formatDateUpgraded, formatDateWithIOS, truncateString } from "../../../utils/function";
import LeaveVactionInfo from "../leave_vaction_info";
import { Decline } from "../svg/Decline";
import { Approve } from "../svg/Approve";
import memoryUtils from "../../../utils/memoryUtils";
import { useIntl } from "react-intl";
import WordTranslationComponent from "../../../utils/language_pack/words";
import { IconBeach, IconEye, IconTrashX, IconX } from "@tabler/icons-react";
import LeaveManageRowTh from "../leave_row/leave_th";
import LeaveManageRowTd from "../leave_row/leave_td";
import UniversalButton from "../../../ttcomponents/Button/UniversalButton";
import Index from "../common/delete_hitn_content";
import TableTextInputTitle from "../../../ttcomponents/TableTextInputTitle/TableTextInputTitle";
import { IconChecks } from "@tabler/icons-react";
import ChecXIcon from "../../../ttcomponents/Icon/IconChecX";
import DeleteIcon from "../../../ttcomponents/DeleteIcon/DeleteIcon";
import EditIcon from "../../../ttcomponents/EditIcon/EditIcon";
import IconCheckDs from "../../../ttcomponents/Icon/IconChecks";
import ViewIcon from "../../../ttcomponents/ViewIcon/ViewIcon";
import apiUrl from '../../../config/api_config/config';
import LeaveInfo from "../common/preview";
const localizer = momentLocalizer(moment);


const initialValues = [
  {
    label: "ActionIcon",
    from: "22.07.2023",
    to: "12.07.2023",
    day: "3",
    flag_halfday: "",
    status_name: "PENDING",
    is_read:0,
    leave_category_id:0,
    user_remarks: "",
    Requested: "Requested",
    index: "",
    checked: false,
    appliance_date:'',
    leave_category_name:'',
    id_leave: "",
    status_id: "0",
    user_info: {
      id_user: "",
      username: "",
      email: "",
      phone: "",
      avatar: "",
      lastLogin: "",
      active: "",
    },
    manager: [
      {
        id_user: "",
        username: "",
        email: "",
        phone: "",
        avatar: "",
      },
    ],
  },
];

interface IRow {
  UserVationData: Vacation[] | undefined;
  type: number;
  currentUserid: any;
  callBack: () => void;
}
export default function LeaveRowsManage({  UserVationData,  currentUserid,  callBack,  type,}: IRow) {
 // Get translation public configuration information
  const word_translation = WordTranslationComponent();
  const [values, handlers] = useListState(initialValues);
  const [vacation, setVacation] = useState<Vacation>();

  const [openvacationInfoStatus, { open: openvacationInfo, close: closevacationInfo }] = useDisclosure(false);
  const [display, setDisplay] = useState(false);
  const allChecked = values.every((value) => value.checked);
  const indeterminate = values.some((value) => value.checked) && !allChecked;
  //Whether the current user has approval permission
  const userExists = memoryUtils.user;
  // Settings
  const [commnetvalue, setCommentValue] = useState("");
  var remarkNew = "";
  useEffect(() => {
    handlers.setState([]);
    if (UserVationData) {
      const updatedValues = UserVationData.map((item, index) => ({
        label: item.user_info.username,
        from: item.from,
        leave_category_name: item.leave_category_name || '',
        to: item.to,
        day: item.days,
        user_remarks: item.user_remarks || '',
        appliance_date:item.appliance_date,
        user_info: item.user_info,
        manager: item.manager,
        flag_halfday: item.flag_halfday,
        is_read:item.is_read,
        leave_category_id:item.leave_category_id,

        status_id: item.status_id,
        status_name: item.status_name.toUpperCase(),
        Requested: "Requested",
        checked: false,
        id_leave: item.id_leave,
        index: item.id_leave,
      }));
      // Update each column item
      if (updatedValues.length > 0) {
        setDisplay(true);
        updatedValues.forEach((item, index) => {
          handlers.setItem(index, item);
        });
      } else {
        setDisplay(false);
      }
    } else {
      console.log(UserVationData);
    }
  }, [UserVationData]);
  const openHandleVacationModal = async (row: any, type: string, isbatch: boolean) => {
    //
    type TitleSaveType = string | JSX.Element;
    let title: TitleSaveType = word_translation.vacation_approve_modal;
    let save: TitleSaveType = word_translation.agree;
    let color = "";
    if(type == 'reject'){
      save = word_translation.reject;
      color = 'red';
    }

    if(row == ''){
      // 处理多条记录
      title = word_translation.batch_processing;
    }

    modals.openConfirmModal({
      title: title,
      children: (
        <Box mt={20}>
          {row && 
          <>
            <Text  mt={20} className="columns-text">{word_translation.approver_remarks}</Text>
            <Textarea   className="commnet-content"    onChange={(event) =>  handleInputChange(event.currentTarget.value)} />
            <Index row={row}/>
          </>}
          <TableTextInputTitle title= {word_translation.is_exec} />
        </Box>
      ),
      labels: { confirm: save, cancel: word_translation.cancel },
      confirmProps: { color: color },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => {
        if (isbatch == false) {
          ProcessingRequestHandle(row, type, remarkNew);
        } else {
          BatchHandler(type);
        }
      },
    });
  };

  const handleInputChange = (newValue: string) => {
    remarkNew = newValue;
    setCommentValue(newValue); //update status
  };
  // Holiday request for processing application
  const ProcessingRequestHandle = async (
    row: any,
    type: string,
    remark: string
  ) => {
    if (type === "agree") {
      const ApprovalResult = await regVacationApproval(
        { id: row.id_leave, status_id: "2", approver_remarks: remark },
        "PUT"
      );
      HintInfo(ApprovalResult.data);
      if (ApprovalResult.data.code === 200) {
        callBack();
      }
    }
    if (type === "reject") {
      
      let status_id = row.status_id.toString == '6' ? '2' :'3';
      const ApprovalResult = await regVacationApproval(
        { id: row.id_leave, status_id: status_id, approver_remarks: remarkNew },
        "PUT"
      );
      HintInfo(ApprovalResult.data);
      if (ApprovalResult.data.code === 200) {
        callBack();
      }
    }
  };

 

  const BatchHandler = async (type: string) => {

    const selected = values.filter((value) => value.checked);
    const selectedIds = selected.map((value) => value.id_leave);
    let ApprovalResult: any;

    if (type == "reject") {
      ApprovalResult = await regVacationApprovalBatch(
        { leave_set: selectedIds, status_id: "3" },
        "PUT"
      );
    } else if (type == "agree") {
      ApprovalResult = await regVacationApprovalBatch(
        { leave_set: selectedIds, status_id: "2" },
        "PUT"
      );
    }
    HintInfo(ApprovalResult.data);
    if (ApprovalResult.data.code === 200) {
      callBack();
    }
  };
 
  
  const handleReadRequest  = (id:any) => {
    const openModal = () => modals.openConfirmModal({
      title: word_translation.is_sick_leave,
      labels: { confirm: word_translation.yes, cancel: word_translation.no},
      children:<>
            {word_translation.mark_or_not}
      </>,
      onCancel: () => console.log('Cancel'),
      onConfirm: async () =>{
        if(id == 'batch'){
          const selected = values.filter((value) => value.checked);
          id = selected.map((value) => value.id_leave);;
        }
          const ApprovalResult = await regLeaveSickReaded(  { id: id }, "PUT" );
          HintInfo(ApprovalResult.data);
          if (ApprovalResult.data.code == 200) {
            callBack();
          }
      },
    });
    openModal()
  };


  

  
  const handleScroll = () => {
    const element = document.getElementById('ScrollableDiv');
    const scrollTop = element?.scrollTop ?? 0;
    const scrollHeight = element?.scrollHeight ?? 0;
    const clientHeight = element?.clientHeight ?? 0;

    // Check if scrolled to the bottom
    if (scrollTop + clientHeight === scrollHeight) {
      // Uncomment the following line to load more data
      // loadMoreData();
      callBack();
    }
  };

  useEffect(() => {
    const scrollableDiv = document.getElementById('ScrollableDiv');
    
    // Check if the scrollable div is present in the DOM
    if (scrollableDiv) {
      scrollableDiv.addEventListener('scroll', handleScroll);
    }

    // Clean up the event listener to prevent memory leaks
    return () => {
      if (scrollableDiv) {
        scrollableDiv.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);
  const OpenVacation = (value: any) => {
    console.log(value,'传值')
    setVacation(value)
    openvacationInfo()
  };
  return (
    <>

    {/* <Box id="ScrollableDiv"  style={{ overflowY: 'auto', height: '25vh' }} w='100%' > */}
    <ScrollArea h={220}>   
    
      <table width='100%' style={{minWidth: '720px'}} className="table_custome_spacing" >
       <thead  className="scrolleder "> 
          <tr>
            <th>
              <Checkbox
              checked={allChecked}
              indeterminate={indeterminate}
              transitionDuration={0}
              onChange={() =>
                handlers.setState((current) =>
                  current.map((value) => ({ ...value, checked: !allChecked }))
                )
              }
            />
            </th>
            <LeaveManageRowTh class_name="columns-title" title={word_translation.applicant}/>
            <LeaveManageRowTh class_name="columns-title" title={word_translation.from}/>
            <LeaveManageRowTh class_name="columns-title" title={word_translation.to}/>
            <LeaveManageRowTh class_name="columns-title" title={word_translation.days}/>
            <LeaveManageRowTh class_name="columns-title" title={word_translation.manager}/>
            <LeaveManageRowTh class_name="columns-title" title={word_translation.category_name}/>
            <LeaveManageRowTh class_name="columns-title" title={word_translation.status}/>
            <LeaveManageRowTh class_name="columns-title" title={word_translation.requested_date}/>
            <LeaveManageRowTh class_name="columns-title" title={word_translation.option}/>
          </tr>
      </thead> 
      <tbody>
        
      {values.map((item, index) => {
              const color =
                item.status_id.toString() === "2"
                  ? "green"
                  : item.status_id.toString() === "1"
                  ? "pending"
                  : "red";
              return (
                <>
                  <tr className="text-center" >
                    <td>
                      <Checkbox
                        key={item.index + 1}
                        checked={item.checked}
                        onChange={(event) =>handlers.setItemProp(index, "checked",event.currentTarget.checked)}
                      />
                    </td>
                   
                    <LeaveManageRowTd class_name="columns-text" title={item.user_info.username}/>
                    <LeaveManageRowTd class_name="columns-text" style={{minWidth: '22px'}} title={ formatDate(item.from)}/>
                    <LeaveManageRowTd class_name="columns-text" style={{minWidth: '72px'}} title={ formatDate(item.to)}/>
                    <LeaveManageRowTd class_name="columns-text" title= {item.flag_halfday === "1" ? "0.5" : item.day}/>
                   
                    <td className=" border-b-1" >
                      <Text className="columns-text">
                        <Avatar.Group spacing="sm">
                          {item.manager.map(
                            (item_leader: any, item_leader_index: any) => (
                              <Tooltip
                                label={item_leader.username}
                                key={item_leader.username + item_leader.avatar}
                              >
                                <Avatar
                                  src={apiUrl.fileSystemDomain +item_leader.avatar}
                                  radius={40}
                                  size={26}
                                />
                              </Tooltip>
                            )
                          )}
                        </Avatar.Group>

                        {/* <Tooltip label={item.manager.username}><Avatar src={item.manager.avatar} radius='xl' size={12}  className='size-icon' alt="it's me" /></Tooltip> */}
                      </Text>
                    </td>
                    
                  <td className=" border-b-1" >
                   
                              
                 <Tooltip label={item.leave_category_name as string}>
                            <Badge color={item.leave_category_id.toString() == '2' ? "dark" :''}>{item.leave_category_name}</Badge>
                    </Tooltip>
                           
                  </td>
                    {item.status_id.toString() == '2' && item.leave_category_id.toString() == '2' ?
                                 <td>
                            <Tooltip label="Acknowledged">
                            <Text  className={`columns-text  leave-badge-layout ${color}-badge badge-text`}>
                                {/* {truncateString('Acknowledged',7)} */}
                                Acknowledged
                                </Text>
                      </Tooltip>
                      </td>:
                      <LeaveManageRowTd  class_name={`columns-text  leave-badge-layout ${color}-badge badge-text`} title={truncateString(item.status_name as string,12)}/>
                    }
                  
                    <LeaveManageRowTd class_name={`columns-text `} title={formatDate(item.appliance_date)}/>
                  
                    <td className="columns-Box  border-b-1" >

                    <Flex>
                      {/* 当前人的 可以修改删除 */}
                      <ViewIcon callBack={()=>{OpenVacation(item)}}/>
                      {type == 1 ? (
                          <>
                            <EditIcon callBack={() => ProcessingRequestHandle(item, "edit", "")} />
                            <DeleteIcon callBack={() => ProcessingRequestHandle(item, "delete", "")} />
                          </>
                       ) : 
                      (
                        <>

                        { item.status_id == '1' &&
                        <>
                            <ChecXIcon tip={word_translation.reject}  callBack={() =>   openHandleVacationModal(item, "reject", false)} />
                            <IconCheckDs  tip={word_translation.approve_this_leave}  callBack={() =>openHandleVacationModal(item, "agree", false)} />
                        </>
                        }

                    { item.status_id == '2' ?  
                         item.leave_category_id == 2 && item.is_read === 0 ?  
                          //  处理病假未读信息
                          <>
                             <ChecXIcon  color="#B8B8B8"  callBack={() =>{}} />
                              <IconCheckDs tip={word_translation.is_sick_leave}   color={'red'} callBack={() =>handleReadRequest(item.id_leave)} /> 
                          </>: 
                          <>
                            <ChecXIcon  tip="The vacation has been approved."   color="#B8B8B8"   callBack={() =>{}} />
                            <IconCheckDs tip="The vacation has been approved." color="green"  callBack={() =>{}} />
                          </>
                        :null
                        
                    }

                    { item.status_id == '3'  &&
                          <> 
                            <ChecXIcon  tip={word_translation.vacation_rejected} color="#B8B8B8"  callBack={() =>{}} />
                            <IconCheckDs tip={word_translation.vacation_rejected}  color="#B8B8B8" callBack={() =>{}} />
                          </>
                    }

                    { item.status_id == '4'  &&
                          <> 
                            <ChecXIcon tip={word_translation.vacation_canceled}  color="#B8B8B8"  callBack={() =>{}} />
                            <IconCheckDs  tip={word_translation.vacation_canceled}   color="#B8B8B8"  callBack={() =>{}} />
                          </>
                    }


                    { item.status_id == '6'  &&
                          <> 
                            <ChecXIcon tip={word_translation.reject} color="red"  callBack={() =>ProcessingRequestHandle(item, "reject", "")} />
                            <IconCheckDs  color="blue"  callBack={() =>openHandleVacationModal(item, "agree", false)} />
                          </>
                    }
                         
                        </>
                      )}
                    </Flex>


                    </td>
                  </tr>
                </>
              );
            })}

      </tbody>
    </table></ScrollArea>
      <Group position="right" py={10 } px={10}>
        <UniversalButton btn_color="bule" callBack={() => handleReadRequest('batch')} btn_text={word_translation.mark_sick} btn_icon={<IconChecks size={20}/>}/>
        <UniversalButton btn_color="red" callBack={() => openHandleVacationModal("", "reject", true)} btn_text={word_translation.reject} btn_icon={<IconTrashX size={20}/>}/>
        <UniversalButton btn_color="green" callBack={() => openHandleVacationModal("", "agree", true)} btn_text={word_translation.approver} btn_icon={<IconBeach size={20}/>}/>
      </Group>
      <Modal
        radius="md"
        size="xl"
        opened={openvacationInfoStatus}
        onClose={closevacationInfo}
        closeOnClickOutside={false}
        title={word_translation.preview}
      >
        <LeaveInfo row={vacation} />
      </Modal>

    </>


     


     

  );
}
