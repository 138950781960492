import {
  Image,
  Box,
  Text,
  useMantineColorScheme,
  Flex,
  Tooltip,
} from "@mantine/core";
import {
  IconBrandAsana,
  IconClipboardList,
  IconHourglass,
  IconLayoutDashboard,
  IconZeppelin,
  IconListCheck,
  IconAdjustmentsHorizontal,
  IconTruck,
  IconAdjustmentsFilled,
  IconCheck,
  IconX,
  IconCircleChevronLeft,
  IconUserCircle,
  IconLogout2,
  IconDoorExit,
} from "@tabler/icons-react";
import {
  Navbar,
  Group,
  createStyles,
  rem,
} from "@mantine/core";
import { LinksGroup } from "../../components/NavbarLinksGroup/NavbarLinksGroup";
import "./NavbarNested.css";
import React, { useState, useEffect } from "react";
import localstorageUnits from "../../utils/localstorageUnits";
import { regLogout } from "../../api";
import {  useLocation, useNavigate } from "react-router-dom";
import { notifications } from "@mantine/notifications";
import { FormattedMessage } from "react-intl";
import memoryUtils from "../../utils/memoryUtils";
import { isTopMenu} from "../../utils/function";
import apiUrl from '../../config/api_config/config';
import { RootState } from '../../state_management/store'
import { useSelector } from "react-redux";
import WordTranslationComponent from "../../utils/language_pack/words";
import CheckPermissionTools from "../../utils/permission";
import LogSettings from "../../pages/core/settings/log/log_settings";

const user = localstorageUnits.getUser();
memoryUtils.user = user;


let menuItems: {
  label: string;
  icon: React.FunctionComponent<any>; // 修改这里
  link: string;
  status?: boolean;
  links?: { status?: boolean; label: string; link: string }[];
}[] = [];

const useStyles = createStyles((theme) => ({
  // navbar: {
  //   background: "navbardark",
  //   paddingBottom: 0,
  // },

  header: {
    paddingTop: 30,
    marginLeft: 10,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    borderBottom: `${rem(1)}  ${theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
      }`,
  },

  links: {},

  linksInner: {
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
  },

  footer: {
    marginLeft: `calc(${theme.spacing.md} * -1)`,
    marginRight: `calc(${theme.spacing.md} * -1)`,
    borderTop: `${rem(1)}  ${theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
      }`,
  },
}));

interface MenuItem {
  label: string;
  icon: any;
  link: string;
  links?: { label: string; link: string }[];
}

interface INavProps {
  callback: () => void;
}
export function NavbarNested({ callback }: INavProps) {


  // update company name and icon
  const [companyName, setCompanyName] = React.useState('Apollo');
  const [companyIcon, setCompanyIcon] = React.useState('');
  const [footMenu, setFootMenu] = React.useState<any>();
  useEffect(() => {
    let SystemInfo = localstorageUnits.getSystem();
    let FootMenu = localstorageUnits.getFootMenu();
    setFootMenu(FootMenu)
    if (SystemInfo.companyName) setCompanyName(SystemInfo.companyName)
    if (SystemInfo.companyLogoPath) setCompanyIcon(SystemInfo.companyLogoPath)
  }, [])


  const Perm = CheckPermissionTools();

  // Get translation public configuration information
  const word_translation = WordTranslationComponent();
  const navigate = useNavigate();
  const location = useLocation();
  const currentUrl = location.pathname;

  //Initialized character type
  const [userType, setUserType] = useState(memoryUtils.user.user_type);
  const isAdmin = userType == "1";

  // 获取redux store中的值
  const menuItems = useSelector((state: RootState) => state.menu.menuItems)

  const iconList: Record<string, React.ReactNode> = {
    'IconBrandAsana': <IconBrandAsana size='1.2rem' color="#fff" />,
    'IconUserCircle': <IconUserCircle size='1.2rem' color="#fff" />,
    'IconTruck': <IconTruck size='1.2rem' color="#fff" />,
    'IconAdjustmentsHorizontal': <IconAdjustmentsHorizontal size='1.2rem' color="#fff" />,
    'IconListCheck': <IconListCheck size='1.2rem' color="#fff" />,
    'IconLayoutDashboard': <IconLayoutDashboard size='1.2rem' color="#fff" />,
    'IconDoorExit': <IconDoorExit size='1.2rem' color="#fff" />,
    'IconZeppelin': <IconZeppelin size='1.2rem' color="#fff" />,
    'IconHourglass': <IconHourglass size='1.2rem' color="#fff" />,
    'IconClipboardList': <IconClipboardList size='1.2rem' color="#fff" />,
  };

  function getIcon(label: string): React.ReactNode | undefined {
    return iconList[label];
  }



  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const dark = colorScheme === "dark";
  const [activeIndex, setActiveIndex] = useState(" "); // The activation status of the management menu item
  const [subActiveIndex, setSubActiveIndex] = useState(" "); // The activation status of the management sub -menu item
  const [initiallyOpeneds, setInitiallyOpeneds] = useState("");
  const { classes, theme } = useStyles();
  // isTopMenu
  useEffect(() => {
    if(Object.keys(menuItems).length !== 0 && menuItems.constructor !== Object ){
      let storagePath = localstorageUnits.getPath();
      if (storagePath && Object.keys(storagePath).length > 0) {
        if (storagePath.currentChildrenActivePath) {
          storagePath.currentChildrenActivePath.label
            ? setSubActiveIndex &&
            setSubActiveIndex(storagePath.currentChildrenActivePath.label)
            : setSubActiveIndex &&
            setSubActiveIndex(storagePath.currentPreChildrenActivePath.label);
        }
        setInitiallyOpeneds(
          storagePath.initiallyOpeneds ? storagePath.initiallyOpeneds : []
        );
  
        if (storagePath.currentActiveIndex) {
          if (isTopMenu(storagePath.currentActiveIndex, menuItems) == false) {
            setSubActiveIndex && setSubActiveIndex("");
          }
          setActiveIndex && setActiveIndex(storagePath.currentActiveIndex);
        } else {
          setActiveIndex && setActiveIndex(storagePath.currentPreActiveIndex);
        }
      }
    }
  }, []);

 
  const loginOut = async () => {
    const response = await regLogout();
    const result = response.data;
    if (result.code === 200) {
      localstorageUnits.removeUser();
      localstorageUnits.removeMenu();
      localstorageUnits.removePath();
      localstorageUnits.removePermission();
      navigate("/login");
      notifications.show({
        title: "You have exited the system",
        color: "green",
        icon: <IconCheck />,
        message: result.msg,
      });
    } else {
      notifications.show({
        title: "logOut Error",
        color: "red",
        icon: <IconX />,
        message: result.msg,
      });
    }
  };
  const navBread = () => {
    callback();
  };


  let refreshCount = 0;

  const checkAndRefresh = () => {
    if (!menuItems || Object.keys(menuItems).length === 0) {
      refreshCount++;
      if (refreshCount < 5) {
        setTimeout(() => {
          window.location.reload();
        }, 1000); // Refresh every second
      } else {
        console.log("Refreshed for five times, menuItems still cannot be obtained");
      }
    }
  };
  
  checkAndRefresh();
  
  const links =( Object.keys(menuItems).length !== 0 && menuItems.constructor !== Object ) && menuItems?.map((item, index) => {
    let activeBool = false;
    let initiallyOpened = false;

    if (item.label === activeIndex) {
      activeBool = true;
    }
    if (
      (initiallyOpeneds as any).opened == true &&
      item.label == (initiallyOpeneds as any).label
    ) {
      initiallyOpened = true;
    }

    return (
      <Box key={index}>
        <LinksGroup
          icon={getIcon(item.icon) as unknown as React.FC<any>}
          label={item.label}
          status={item.status}
          links={item.links}
          subActiveIndex={subActiveIndex}
          link={item.link}
          initiallyOpened={initiallyOpened}
          setActiveIndex={setActiveIndex}
          setSubActiveIndex={setSubActiveIndex}
          callback={navBread}
          key={item.label}
          isActive={activeBool}
        />

      </Box>
    );
  });

  function handNolinksMenu(flink: string): void {
    localstorageUnits.savePath({ currentUrl: flink });
    setActiveIndex && setActiveIndex("Settings");
    callback();
    navigate(flink);
  }

  return (
    <Box ml={4}>
      <Navbar
        height={"98vh"}
        mt={"1vh"}
        className={`scrollable-content ${dark ? "navbarLight" : "navbardark"}`}
      >
        <Navbar.Section className={classes.header}>
          <Group>
            <Box>
              <Text fw={500} color={dark ? theme.colors.customize[3] : theme.colors.customize[2]}>
                {companyIcon && <Image maw={60} mah={60} mx="auto" radius="md" src={apiUrl.fileSystemDomain + companyIcon} alt="Random image" />}
              </Text>
            </Box>
            <Box>
              <Tooltip label={companyName ? companyName : 'Apollo'}>
                <Text lineClamp={3} w={120} fw={600} size={10} pr={12} color="#fff">
                  <>{companyName}</>
                </Text>
              </Tooltip>
            </Box>
          </Group>
        </Navbar.Section>
        <Flex align="center" px={5}>
          <Box
            mr={6}
            style={{
              height: "1px",
              flex: 1,
              background:
                "linear-gradient(to right, #8D8888, #FFFFFF, #FFFFFF, #FFFFFF, #FFFFFF, #8D8888)",
            }}
          />
          <IconCircleChevronLeft color="white" size={23} />
        </Flex>

        <Box
          style={{
            overflow: "hidden",
            height: "87vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          <Group
            className="scrollable-container  scrollable-content"
            pb={20}
            style={{
              flex: 5,
              overflow: "auto",
              flexWrap: "nowrap",
              gap: 0,
              flexDirection: "column",
            }}
          >
            <Navbar.Section grow className={classes.links}>
              <Box py={0} className={classes.linksInner}>
                {links}
              </Box>
            </Navbar.Section>
          </Group>
          <Flex align="center" px={5}>
            <Box
              mr={6}
              my={2}
              style={{
                height: "1px",
                flex: 1,
                background:
                  "linear-gradient(to right, #8D8888, #8D8888, #8D8888, #8D8888, #8D8888, #8D8888)",
              }}
            />
          </Flex>
          <Group
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              gap: 0,
            }}
          >
            <Box style={{ width: "180px", flex: 4 }}>
              <Box
                onClick={() => handNolinksMenu("profile")}
                className="menu-items"
                style={{ cursor: "pointer" }}
                py={5}
                pr={10}
                pt={10}
                pb={10}
                pl={30}
              >
                <Flex align={"center"}>
                  <Flex align={"center"}>
                    <IconUserCircle
                      style={{ fontWeight: 500 }}
                      size="1.1rem"
                      color={
                        dark
                          ? theme.colors.customize[3]
                          : theme.colors.customize[2]
                      }
                    />
                  </Flex>
                  <Box pl={5} fw={500}>
                    <Text
                      size={10}
                      color={
                        dark
                          ? theme.colors.customize[3]
                          : theme.colors.customize[2]
                      }
                    >  {word_translation.profile}

                    </Text>{" "}
                  </Box>
                </Flex>
              </Box>

{/* 
        core_open_permissions: CheckPermission('-1.0001'),
        assignments_open_permissions: CheckPermission('-2.0001'),
        leaveRequest_open_permissions: CheckPermission('-4.0001'),
        timeTracker_open_permissions: CheckPermission('-3.0001'),
        report_open_permissions: CheckPermission('-5.0001'),
        borading_open_permissions: CheckPermission('-6.0001'),
            */}

              {(
                    Perm.company_read 
                    ||  Perm.borading_open_permissions 
                    ||  Perm.timeTracker_open_permissions 
                    ||  Perm.leaveRequest_open_permissions 
                    ||  Perm.report_open_permissions 
                    ||  Perm.core_open_permissions 
                ||  Perm.currency_read
                ||  Perm.global_seting_create ||  Perm.global_seting_update||  Perm.global_seting_read
                ||  Perm.module_service_create ||  Perm.module_service_update||  Perm.module_service_read
                ||  Perm.special_user_permission_create
                ||  Perm.purchase_create  ||  Perm.public_holiday_delete||  Perm.public_holiday_read
                ||  Perm.public_holiday_read   ||  Perm.public_holiday_create  ||  Perm.public_holiday_delete ||  Perm.public_holiday_update
                ||  Perm.identifier_read   ||  Perm.identifier_create  ||  Perm.identifier_update ||  Perm.identifier_delete
                ||  Perm.action_read   ||  Perm.action_create  ||  Perm.action_update ||  Perm.action_delete
                ||  Perm.email_read   ||  Perm.email_create  ||  Perm.email_update ||  Perm.email_delete
                ||  Perm.cost_center_read   ||  Perm.cost_center_update  ||  Perm.cost_center_delete ||  Perm.cost_center_delete
              )
                && <Box onClick={() => handNolinksMenu("settings")}
                  className={`menu-items  ${currentUrl == "/settings" ? "navSystem" : ""}`}
                  style={{ cursor: "pointer" }}
                  py={5}
                  my={5}
                  pr={10}
                  pt={10}
                  pb={10}
                  pl={30}
                >
                  <Flex align={"center"}>
                    <Text fw={500} color="#ffffff"  >
                      <Flex align={"center"}>
                        <IconAdjustmentsFilled size="1.1rem" />
                      </Flex>
                    </Text>
                    <Box pl={5} fw={500}>
                      <Text size={10} color="#ffffff"  >
                        {word_translation.settings}
                      </Text>
                    </Box>
                  </Flex>
                </Box>}
                {Perm.admin &&  <Box onClick={() => handNolinksMenu("log")}
                  className={`menu-items  ${currentUrl == "/log" ? "navSystem" : ""}`}
                  style={{ cursor: "pointer" }}
                  py={5}
                  my={5}
                  pr={10}
                  pt={10}
                  pb={10}
                  pl={30}
                >
                  <Flex align={"center"}>
                    <Text fw={500} color="#ffffff"  >
                      <Flex align={"center"}>
                        <IconAdjustmentsFilled size="1.1rem" />
                      </Flex>
                    </Text>
                    <Box pl={5} fw={500}>
                      <Text size={10} color="#ffffff"  >
                      Log
                      </Text>
                    </Box>
                  </Flex>
                </Box> }
              


              <Box
                className="menu-items"
                onClick={loginOut}
                style={{ cursor: "pointer" }}
                py={5}
                pr={10}
                pt={10}
                pb={10}
                pl={30}
              >
                <Flex align={"center"}>
                  <Flex align={"center"}>
                    {" "}
                    <IconLogout2
                      style={{ fontWeight: 500 }}
                      size="1.1rem"
                      color={
                        dark
                          ? theme.colors.customize[3]
                          : theme.colors.customize[2]
                      }
                    />
                  </Flex>
                  <Box pl={5} fw={500}>
                    {" "}
                    <Text
                      size={10}
                      color={
                        dark
                          ? theme.colors.customize[3]
                          : theme.colors.customize[2]
                      }
                    >
                      <FormattedMessage id="Logout" defaultMessage="Logout" />
                    </Text>
                  </Box>
                </Flex>
              </Box>
            </Box>

            <Box style={{ width: "180px", flex: 1 }}>
              <Text
                py={5}
                px={10}
                size={11}
                color={dark ? "customize-gray.2" : "customize-gray.3"}
              >
                <FormattedMessage id="Apollo" defaultMessage="Apollo" /> -{" "}
                <FormattedMessage
                  id="asc_version"
                  defaultMessage="ASC Version"
                />
                {" " + apiUrl.version}
              </Text>
            </Box>
          </Group>
        </Box>
      </Navbar>
    </Box>
  );
}
