import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

// Define the refresh status interface for the assignment list
export interface ActionIndexInterface {
  action_index:{
    index:string | number;
    id: string | number;
    type: string;
  } 
}

// Initial state, with the default refresh status set to false
const initialState: ActionIndexInterface = {
  action_index:{
    index:'',
    id: '',
    type: '',
  }
};

// Create a Redux Slice for managing the refresh status of the assignment list
export const actionIndexSlice = createSlice({
  name: 'ActionObj', // Name of the Slice
  initialState, // Initial state
  reducers: {
      setActionIndexStatus: (state, action: PayloadAction<any>) => {
      state.action_index = action.payload as ActionIndexInterface['action_index'];
    },
  },
});

// Export the reducer and action for easy use in other files
export const { setActionIndexStatus } = actionIndexSlice.actions;

export default actionIndexSlice.reducer;
