import { Card, Grid, Group, Text } from "@mantine/core";
import { ProjectReports } from "../../../interface/Ireports";
import { ConvertTimeStringToDecimal, TimeHHSS, formatTimeHHSS } from "../../../utils/function";
import { useNavigate } from "react-router-dom";
import WordTranslationComponent from "../../../utils/language_pack/words";

interface ProjectReport {
    ProjectReports: ProjectReports[]
}
export default function ProjectTableWithReports({ ProjectReports }: ProjectReport) {

  // Get translation public configuration information
  const word_translation = WordTranslationComponent();
    const navigate = useNavigate();
    const jumpLink = (project: string) => {
        const url = `/project_item_reports?item_id=${project}`;
        window.open(url, '_blank');
    }
    return (
        <>
            {ProjectReports.length > 0 && ProjectReports.map((item, index) => {
                return (

                    <Card withBorder w='100%' my={2} radius="md" >
                        <Grid w='100%' columns={12} fz={12}>
                            <Grid.Col span={2} style={{ cursor: "pointer" }} onClick={() => jumpLink(item.id_project)}>
                                <Text className='border-1 font-project'>Customer Project NO.</Text >
                                <Text fw={500}>{item.project_no}</Text>
                            </Grid.Col>
                            <Grid.Col span={2} style={{ cursor: "pointer" }} onClick={() => jumpLink(item.id_project)}>

                                <Text className='border-1 font-project'>{word_translation.company_name} </Text >
                                <Text fw={500}>{item.company_name}</Text>
                            </Grid.Col>
                            <Grid.Col span={2} style={{ cursor: "pointer" }} onClick={() => jumpLink(item.id_project)}>

                                <Text className='border-1 font-project'>Project Name </Text >
                                <Text fw={500}>{item.name}</Text>
                            </Grid.Col>

                            <Grid.Col span={2}>
                                <Text className='border-1 font-project'>{word_translation.actual_time}</Text>
                                <Text fw={500}>
                               {ConvertTimeStringToDecimal(formatTimeHHSS(item.actual_used_time))} &nbsp; {word_translation.hour}
                                </Text>
                            </Grid.Col>
                            <Grid.Col span={2}>
                                <Text className='border-1 font-project'>{word_translation.target_time}</Text>
                                <Text fw={500}>
                                    
                               {ConvertTimeStringToDecimal(formatTimeHHSS(item.target_time))} &nbsp; {word_translation.hour}
                                   </Text>
                            </Grid.Col>
                            <Grid.Col span={2}>
                                <Text className='border-1 font-project'>Actual value  rate</Text>
                                <Text fw={500}>{(item.actual_value * 100).toFixed(2)}%</Text>
                            </Grid.Col>
                        </Grid>
                    </Card>
                )
            })}

        </>
    )
}