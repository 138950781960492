import { Box, Checkbox, Group, MultiSelect, Text } from '@mantine/core'
import { forwardRef } from 'react'
import TableTextInputTitle from '../../../../../../ttcomponents/TableTextInputTitle/TableTextInputTitle'
import { SelectRole } from '../../../../../../interface/Icommon'

interface ComponentInterface {
  title: string
  checked: boolean
  setChecked: (value: boolean) => void
  itemIds: string[]
  setItemIds: (value: string[]) => void
  projectList: SelectRole[]
}
export default function ItemAssignment({
  title,
  checked,
  setChecked,
  itemIds,
  setItemIds,
  projectList
}: ComponentInterface) {
  return (
    <>
      <Box w={100}>
        <Checkbox
          checked={checked}
          disabled={itemIds.length !== 0}
          onChange={event => setChecked(event.currentTarget.checked)}
          label={<TableTextInputTitle class_name="leave-title" py={0} pb={5} title={title} />}
        />
      </Box>
      <Box>
        <Text className="font-style-ccc" mt={-2}>
          OR
        </Text>
      </Box>
      <MultiSelect
        size="xs"
        searchable
        value={itemIds}
        className="time_sheet"
        itemComponent={SelectItem}
        onChange={setItemIds}
        disabled={checked === true}
        data={projectList}
        filter={(value, selected, item) =>
          !selected &&
          (item.label?.toLowerCase().includes(value.toLowerCase().trim()) ||
            item.project_no.toLowerCase().includes(value.toLowerCase().trim()))
        }
      />
    </>
  )
}

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  image: string
  label: string
  project_no: string
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(({ image, label, project_no, ...others }: ItemProps, ref) => (
  <div ref={ref} {...others}>
    <Group noWrap>
      <div>
        <Text size="sm">{label}</Text>
        <Text size="xs" opacity={0.65}>
          {project_no}
        </Text>
      </div>
    </Group>
  </div>
))
