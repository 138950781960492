import { Code, Grid, Group, NumberInput, Paper, TextInput, Textarea } from "@mantine/core";
import AppCategory from "../product/category/category";
import SupplierSelect from "../../../ttcomponents/Supplier/SupplierSelect";
import CancelButton from "../../../ttcomponents/Button/CancelBtn";
import SaveBottom from "../../../ttcomponents/Button/SaveBtn";
import { FormEvent, useRef, useState } from "react";
import { Product } from "../../../interface/IProduct";
import { isNotEmpty, useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { IconCalendar, IconCheck, IconX } from "@tabler/icons-react";
import { IProductProcurementOrderData } from "../../../interface/IProductProcurement";
import { DateInput } from "@mantine/dates";
import { regGetProductToSupplier, regGetReturnProductPurchase, regProductPurchase } from "../../../api";
import { HintInfo, formatDateUpgraded } from "../../../utils/function";
import ProductSelect from "../../../ttcomponents/Product/ProductSelect";
import SelectReturnReason from "../../../ttcomponents/ReturnReason/SelectReturnReason";
import { IReturnProduct } from "../../../interface/IreturnProduct";

interface ReturnProductOrderProps {
  additional_information: any;
  type: boolean;
  closeFucntion: (type: number) => void;
}
export default function ReturnProductOrderFrom({ additional_information, type,closeFucntion }: ReturnProductOrderProps) {
  const [returnNotes, setReturnNotes] = useState('');
  const [rand, setRand] = useState(0);
  
  const [currentReturnReasonId, setCurrentUserid] = useState<any>('');
  const formRef = useRef<HTMLFormElement>(null);
  const form = useForm({
    initialValues: {
      order_id: additional_information?.order_id,
      return_reason_id: additional_information?.return_reason_id,
      return_notes:additional_information?.return_notes,
      product_id:additional_information?.product_id,
      return_date:additional_information?.return_date,
    },
    validate: {
      return_reason_id: isNotEmpty('return reasonid name is required'), // 验证 product_name 不为空
    },
  });

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
     // Stop the formal submission of the form default
    event.preventDefault();
    // Verification form
    const valid = form.validate();
    if (valid.hasErrors) {
      notifications.show({
        color: "yellow.7",
        icon: <IconX />,
        message: Object.values(valid.errors)[0],
      });
      return;
    }
    let submit_type = 'POST';
    // if (form.values.order_id) {
    //   submit_type = 'PUT'
    // }
    

    const updatedFormValues = {
      ...form.values,
      // delivery_schedule: formatDateUpgraded(
      //   form.values.delivery_schedule,
      //   "YYYY-MM-DD HH:mm:ss"
      // ),
      // delivered_date: formatDateUpgraded(
      //   form.values.delivered_date as Date,
      //   "YYYY-MM-DD HH:mm:ss"
      // )
    };
    let response;
    if(type==true){
      const updatedFormValues = {
        ...form.values,
        return_date: formatDateUpgraded(
          form.values.return_date,
          "YYYY-MM-DD"
        ),
        // delivered_date: formatDateUpgraded(
        //   form.values.delivered_date as Date,
        //   "YYYY-MM-DD HH:mm:ss"
        // )
      };
       response = await regGetProductToSupplier(updatedFormValues, submit_type);
    }else{
      response = await regGetReturnProductPurchase(updatedFormValues, submit_type);
    }
    HintInfo(response.data)
    if(response.data.code == 200){
       closeFucntion(2)
    }
  };

  const ReturnReasonCallbackFunction =  (row: any | null) => {
    console.log(row);
    if(row.type == 'return_reason'){
      form.setFieldValue('return_reason_id', row.return_reason_id)
    }
    // setCurrentUserid(row);
    // requestVacation(row[0].value )
    // setInspectorInfo(
    //   {
    //     name: row[0].label,
    //     email: row[0].email,
    //     avatar: row[0].avatar,
    //   });
  }

    //    Supplier obtain information
    const ReceiveSupplierBack = (value: any) => {
      if (value.type == "supplier") {
        if (value.value == '') {
          form.setFieldValue('supplier_id', '')
        } else {
          form.setFieldValue('supplier_id', value.value)
        }
      }else if(value.type == "product"){
        form.setFieldValue('product_id', value.value)
        form.setFieldValue('unit_price', value.unit_price)
        form.setFieldValue('supplier_id', value.supplier_id)
      }
    
    }
  return (
    <Paper p={10}>
      <form onSubmit={handleFormSubmit} ref={formRef}>
        <Grid gutter={0}>
          {type? 
              <Grid.Col span={12} >
                <Grid gutter={10}>
                    <Grid.Col span={6}>
                      <SupplierSelect labelStr='Supplier' width={'auto'} prohibit={true}  currentId={additional_information.supplier_id} callBack={ReceiveSupplierBack} />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <TextInput
                      label="Product Name"
                      defaultValue={additional_information.product_name}
                      disabled
                    />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <NumberInput
                        required
                        label="Return Quantity"
                        {...form.getInputProps("return_quantity")} />
                
                    </Grid.Col>

                    <Grid.Col span={6}>
                        <DateInput valueFormat="DD/MM/YYYY"
                          required
                          labelProps={{ className: "input_title" }}
                          icon={<IconCalendar size="1.1rem" stroke={1.5} />}
                          {...form.getInputProps("return_date")}
                          label="Return Time"
                          mx="auto"
                        />
                    </Grid.Col>

                    

                </Grid>
              </Grid.Col>
           : null}

          <Grid.Col span={12}>
            <SelectReturnReason  labelStr='Choose the reason for the return' callBack={ReturnReasonCallbackFunction} currentReturnReasonId={currentReturnReasonId}  rands={rand}/>
             <Textarea label="Reasons for detailed returns" 
             minRows={6}
              {...form.getInputProps("return_notes")} />
          </Grid.Col>
        </Grid>

        <Group position="right">
          <CancelButton callBack={() => closeFucntion(1)} />
          <SaveBottom formRef={formRef} />
        </Group>

        {/** can see form values */}
        <Code block mt={5}>
          {JSON.stringify(form.values, null, 2)}
        </Code>
      </form>
    </Paper>
  )
}