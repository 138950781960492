import {
  Text,
  Box,
  List,
  Group,
  Badge,
  ScrollArea,
  Paper,
  Tooltip,
  LoadingOverlay,
  Flex,
  Title,
  Center
} from '@mantine/core'
import { FC, useEffect, useMemo, useState } from 'react'
import { getAllPermissionList } from '../../../../api/common_request'
import DeleteIcon from '../../../../ttcomponents/DeleteIcon/DeleteIcon'
import { modals } from '@mantine/modals'
import { requestDelPermissionUser, requestDeletePermission } from '../../../../api'
import { HintInfo, HintInfoError, truncateString } from '../../../../utils/function'
import { PermissionSet, PermissionsData } from '../../../../interface/Setting'
import NoData from '../../../../ttcomponents/NoData/Index'
import UniversalButton from '../../../../ttcomponents/Button/UniversalButton'
import WordTranslationComponent from '../../../../utils/language_pack/words'

interface ComponentInterface {
  is_load_str?: string
  user_id: string
}

export default function QueryPermission({ is_load_str, user_id }: ComponentInterface) {
  // Get translation public configuration information
  const word_translation = WordTranslationComponent()
  const [loading, setLoading] = useState<boolean>(false)

  const [allPermission, setALlPermisssion] = useState<PermissionsData | null>()

  const getPermissionFunction = async () => {
    setLoading(true)
    const permission_result = await getAllPermissionList({ user_id: user_id })
    // if ()
    setALlPermisssion(permission_result)
    setLoading(false)
  }

  useEffect(() => {
    if (user_id) {
      getPermissionFunction()
    } else {
      setALlPermisssion(null)
    }
  }, [user_id])

  const hasData = useMemo(() => {
    if (!allPermission) {
      return false
    }
    const permissionsToCheck = [
      'assignment',
      'core',
      'leave',
      'report',
      'SpecialUserPermission',
      'timetracker'
    ] as const
    return permissionsToCheck.some(permission => allPermission[permission]?.length > 0)
  }, [allPermission])

  // Event handler for user change
  const deletePermissionSet = (item: any, module_name: string) => {
    modals.openConfirmModal({
      title: `Delete ${item.permission_identifier_id.name} ？`,
      centered: true,
      children: <Text size="sm">You want to delete {item.permission_identifier_id.name} of the current user?</Text>,
      labels: { confirm: 'Clear permissions', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onCancel: () => console.log('Cancel'),
      onConfirm: async () => {
        setLoading(true)
        const response = await requestDelPermissionUser(
          {
            user_id: user_id,
            costCenter_id: item.costCenter_id.id_costcenter,
            permission_set_id: item.id_permission_set,
            module_name
          },
          'DELETE'
        )
        let result = response.data
        setLoading(false)
        getPermissionFunction()
        HintInfo(result)
      }
    })
  }

  
  //=============== There is a permission prompt box  ===============  //
  const clearAllPermission = async () => {
    if (!user_id) {
      HintInfoError('No user selected.')
      return false
    }
    modals.openConfirmModal({
      title: 'Delete All Permission ？',
      centered: true,
      children: <Text size="sm">You want to delete all permissions of the current user?</Text>,
      labels: { confirm: 'Clear current user permissions', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onCancel: () => console.log('Cancel'),
      onConfirm: async () => {
        setLoading(true)
        var response = await requestDeletePermission({ user_id: user_id }, 'DELETE')
        let result = response.data
        setLoading(false)
        HintInfo(result)
      }
    })
  }


  return (
    <Paper>
      <ScrollArea h="76vh">
        <LoadingOverlay visible={loading} overlayBlur={2} />
        
    {  user_id  &&   <UniversalButton btn_text={word_translation.clear_all_permission} callBack={clearAllPermission} />}
        <Box miw={880}>
          {
            user_id ? ( 
              <List mt={10}>
                {allPermission && hasData ? (
                  Object.entries(allPermission).map(([moduleName, modulePermissions]) => {
                    if (modulePermissions.length === 0) {
                      return <></>
                    }
                    return (
                      <List.Item key={moduleName} w={'100%'}>
                        <Text fw={600} fz={16}>
                          {moduleName}
                        </Text>
                        <List>
                          {modulePermissions.map((permission: PermissionSet, index: number) => (
                            <List.Item key={permission.id_permission_set}>
                              <Group
                                w={'100%'}
                                mb={4}
                                px={8}
                                py={4}
                                sx={theme => ({
                                  backgroundColor:
                                    theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
                                  borderRadius: theme.radius.md,
                                  cursor: 'pointer',
                                  '&:hover': {
                                    backgroundColor:
                                      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]
                                  }
                                })}
                              >
                                <Box w={150}>
                                  <TruncateString
                                    str={
                                      permission.permission_identifier_id?.name
                                        ? permission.permission_identifier_id?.name
                                        : 'Invalid operation action'
                                    }
                                    maxLength={20}
                                  />
                                </Box>
                                <Box w={100}>
                                  <Text fw={600} fz={11}>
                                    {permission.costCenter_id.name}
                                  </Text>
                                </Box>
                                <Box w={500}>
                                  <Flex gap={6} wrap={'wrap'} align={'center'}>
                                    {permission.permission_ids?.map((_item, _index) => {
                                      return (
                                        <Badge
                                          size="xs"
                                          color={_item.identifier == '00004' ? 'red' : 'bule'}
                                          radius="xs"
                                          variant="filled"
                                        >
                                          {_item.title}
                                        </Badge>
                                      )
                                    })}
                                  </Flex>
                                </Box>
                                <Box w={20}>
                                  {moduleName !== 'SpecialUserPermission' && (
                                    <DeleteIcon
                                      disabled={false}
                                      callBack={() => deletePermissionSet(permission, moduleName)}
                                    />
                                  )}
                                </Box>
                              </Group>
                            </List.Item>
                          ))}
                        </List>
                      </List.Item>
                    )
                  })
                ) : (
                  <Center h={270}>
                    <Box style={{ textAlign: 'center' }}>
                      <Title>There is no permission under the current user</Title>
                    </Box>
                  </Center>
                )}
              </List>
            ) : (
              <Center h={270}>
                <Box style={{ textAlign: 'center' }}>
                  <Title>No user selected</Title>
                  <Text c="dimmed" size="lg" ta="center">
                    You have not selected a user. Please pick one.
                  </Text>
                </Box>
              </Center>
            )
            //
          }
        </Box>
      </ScrollArea>
    </Paper>
  )
}

const TruncateString: FC<{ str: string; maxLength: number }> = ({ str, maxLength }) => {
  if (str.length > maxLength) {
    return (
      <Tooltip label={str}>
        <Text inherit fw={600} fz={12}>
          {truncateString(str, maxLength)}
        </Text>
      </Tooltip>
    )
  }
  return (
    <Text inherit fw={600} fz={12}>
      {str}
    </Text>
  )
}
