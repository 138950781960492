import { Paper, LoadingOverlay, ActionIcon, Box, Select, Group, MultiSelect, Checkbox } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconX } from '@tabler/icons-react'
import { useEffect, useRef, useState } from 'react'
import { regUser } from '../../../api'
import TableUser from './user_table'
import { UserData } from '../../../interface/Iuser'
import PageTitle from '../../../ttcomponents/PageTitle/PageTitle'
import PaginationA from '../../../ttcomponents/Pagination/PaginationA'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../state_management/store'
import { IPagination } from '../../../interface/Ipagintion'
import { setRefreshStatus } from '../../../state_management/features/assignments_refresh_status/assignmentsRefreshStatusSlice'
import CheckPermissionTools from '../../../utils/permission'
import { NotPermission } from '../../../ttcomponents/NoPermission'
import { SelectRole } from '../../../interface/Icommon'
import { getCalculationTypeFunction, getCoreUserRoleFunction, getEmailOptionsFunction } from '../../../api/core_request'
import WordTranslationComponent from '../../../utils/language_pack/words'
import { getCostCenterGeneralSelectFunction, getCostCenterSelfSelectFunction } from '../../../api/assignments_request'
import TableTextInputTitle from '../../../ttcomponents/TableTextInputTitle/TableTextInputTitle'

export default function UserMg() {
  const Perm = CheckPermissionTools()
  // Get the value of SearchValue in redux store
  const dispatch = useDispatch()
  // Get the value of refreshStatus in redux store
  const refreshStatus = useSelector((state: RootState) => state.refreshStatus.refreshStatus)

  // Get translation public configuration information
  const word_translation = WordTranslationComponent()

  const [allowedTimesheet, setAllowedTimesheet] = useState<string | null>(null)
  // Get the value in redux store
  const SearchValue = useSelector((state: RootState) => state.search.searchValue)

  // Loading status
  const [loading, setLoading] = useState(false)
  const [randNumber, setRandNumber] = useState(0)
  const [randRoleNumber, setRoleRandNumber] = useState(0)

  const [userDataList, setUserDataList] = useState<UserData[]>([])

  const [totalCount, setTotalCount] = useState<number>(0)

  useDisclosure(false)
  const [pagination, setPagination] = useState<IPagination>({
    search: '',
    page: 1,
    pagesize: 10,
    sortBy: '',
    sortOrder: 'asc'
  })

  // Utilizing RTK to implement search
  const ReceiveSearchBack = (value: string | number) => {
    // Update pagination state with the new search value
    setPagination(prevPagination => ({
      ...prevPagination,
      search: value
    }))
  }

  // Execute the useeffect when the first load is loaded.
  const isInitialMount = useRef(true)
  useEffect(() => {
    if (!isInitialMount.current) {
      ReceiveSearchBack(SearchValue)
    }
    isInitialMount.current = false
  }, [SearchValue])

  // Execute the useeffect when the first load is loaded.
  const issInitialMount = useRef(true)
  useEffect(() => {
    if (!issInitialMount.current) {
      setPagination(prevpagination => ({
        ...prevpagination,
        page: 1,
        allowed_timesheet: allowedTimesheet
      }))
    }
    issInitialMount.current = false
  }, [allowedTimesheet])

  /**
   * Effect hook to automatically fetch user registrations when the 'pagination' parameter changes.
   */
  useEffect(() => {
    ajaxCurrentList()
    dispatch(setRefreshStatus(false))
  }, [pagination, refreshStatus])

  const ajaxCurrentList = async () => {
    try {
      // Get user information
      setLoading(true)
      const response = await regUser(pagination, 'GET')
      const result = response.data
      if (result.code == 200) {
        if (result.data.data) {
          setUserDataList(result.data.data)
          setTotalCount(result.data.total_count)
        }
      }
    } catch (error) {
      console.error('Error in request:', error)
    } finally {
      setLoading(false)
    }
  }

  // Pagination callback triggers update Pagination callback triggers update
  const ReceivePaginationABack = (page: number) => {
    setPagination(prevpagination => ({
      ...prevpagination,
      page: page
    }))
  }

  const [userRoles, setUserRolesInfo] = useState<SelectRole[]>([])
  const getCoreUserRole = async () => {
    // Call the Get User Universal Selection function and set the user list
    if (userRoles.length == 0) {
      var rolelist = await getCoreUserRoleFunction()
      rolelist = [...rolelist, { label: 'All User', value: '0' }]
      setUserRolesInfo(rolelist)
    }
  }

  // email options
  const [emailOptions, setEmailOptions] = useState<string[]>([])
  const getEmailOptions = async () => {
    const data = await getEmailOptionsFunction()
    setEmailOptions(data)
  }

  const [calculationType, setCalculationType] = useState<SelectRole[]>([])

  const getCalculationType = async () => {
    // Call the Get User Universal Selection function and set the user list
    if (calculationType.length == 0) {
      var calculationTypeList = await getCalculationTypeFunction()
      calculationTypeList = [...calculationTypeList, { label: 'NO  ', value: '0' }]
      setCalculationType(calculationTypeList)
    }
  }

  const [costCenterData, setCostCenterData] = useState<SelectRole[]>([])
  const getCostCenter = async () => {
    // Call the Get Cost Center General Selection function and set the cost center data
    setCostCenterData(await getCostCenterGeneralSelectFunction({have_all_costcenter:"false"}))
    // Call the Get Cost Center General Selection function and set the cost center data
  }

  /**
   * Effect hook to automatically fetch user registrations when the 'pagination' parameter changes.
   */
  useEffect(() => {
    getCostCenter()
    getCalculationType()
    getCoreUserRole()
    getEmailOptions()
  }, [])

  // Event handler for user role change
  const handlecalCulationChange = (value: any) => {
    // if (formValue.role_id !== value) {
    //   getUser({ 'user_role_id': value });
    // }
    setPagination(prevpagination => ({
      ...prevpagination,
      page: 1,
      calculation_type_id: value
    }))
  }

  // Event handler for user role change
  const handleUserRoleChange = (value: any) => {
    // if (formValue.role_id !== value) {
    //   getUser({ 'user_role_id': value });
    // }
    setPagination(prevpagination => ({
      ...prevpagination,
      page: 1,
      user_role_id: value
    }))
  }

  // Event handler for cost center change
  const handleCostCenterChange = (value: any) => {
    setPagination(prevpagination => ({
      ...prevpagination,
      page: 1,
      costCenter_id: value
    }))
  }

  return (
    <Paper px={20} w={'100%'} className="position_relative">
      <LoadingOverlay visible={loading} overlayBlur={2} />
      <PageTitle title="USER MANAGEMENT" lang_id="USER MANAGEMENT" />
      <Box>
        <Group position="apart">
          <Box>
            <Group>
              <Box my={10}>
                <Select
                  label=""
                  w={280}
                  size="xs"
                  defaultValue={allowedTimesheet}
                  onChange={setAllowedTimesheet}
                  key={allowedTimesheet}
                  rightSection={
                    <ActionIcon
                      size="xs"
                      onClick={() => {
                        setAllowedTimesheet('')
                      }}
                    >
                      <IconX color="gray" />
                    </ActionIcon>
                  }
                  placeholder="Allow to time tracker"
                  data={[
                    { value: '1', label: 'Y' },
                    { value: '2', label: 'N' }
                  ]}
                />
              </Box>
              <Box>
                <Select
                  labelProps={{ className: 'input_title' }}
                  placeholder={word_translation.select_role}
                  key={randRoleNumber}
                  onChange={value => handleUserRoleChange(value)}
                  rightSection={
                    <ActionIcon
                      size="xs"
                      onClick={() => {
                        setPagination(prevpagination => ({
                          ...prevpagination,
                          page: 1,
                          user_role_id: ''
                        }))
                        setRoleRandNumber(randRoleNumber + 1)
                      }}
                    >
                      <IconX color="gray" size="xs" />
                    </ActionIcon>
                  }
                  searchable
                  size="xs"
                  data={userRoles}
                />
              </Box>

              <Box>
                <Select
                  labelProps={{ className: 'input_title' }}
                  placeholder={word_translation.select_calculation_type}
                  key={randRoleNumber}
                  onChange={value => handlecalCulationChange(value)}
                  rightSection={
                    <ActionIcon
                      size="xs"
                      onClick={() => {
                        setPagination(prevpagination => ({
                          ...prevpagination,
                          page: 1,
                          calculation_type_id: ''
                        }))
                        setRoleRandNumber(randRoleNumber + 1)
                      }}
                    >
                      <IconX color="gray" size="xs" />
                    </ActionIcon>
                  }
                  searchable
                  size="xs"
                  data={calculationType}
                />
              </Box>

              <Box>
                <MultiSelect
                  labelProps={{ className: 'input_title' }}
                  onChange={value => handleCostCenterChange(value)}
                  placeholder={word_translation.select_cost_center}
                  searchable
                  key={randNumber}
                  rightSection={
                    <ActionIcon
                      size="xs"
                      onClick={() => {
                        setPagination(prevpagination => ({
                          ...prevpagination,
                          page: 1,
                          costCenter_id: ''
                        }))
                        setRandNumber(randNumber + 1)
                      }}
                    >
                      <IconX color="gray" size="xs" />
                    </ActionIcon>
                  }
                  size="xs"
                  data={costCenterData}
                />
              </Box>
            </Group>
          </Box>
          <Box>
            <Checkbox
              label={<TableTextInputTitle title={word_translation.display_inactive} />}
              onChange={event => {
                setPagination(prevpagination => ({
                  ...prevpagination,
                  page: 1,
                  active: event.currentTarget.checked ? '0' : ''
                }))
              }}
            />
          </Box>
        </Group>
        {Perm.user_read ? (
          <>
            <TableUser
              setLoading={setLoading}
              emailOptions={emailOptions}
              userRoles={userRoles}
              calculationType={calculationType}
              costCenterData={costCenterData}
              userList={userDataList}
            />
            <PaginationA
              callBack={ReceivePaginationABack}
              total_count={totalCount}
              pagesize={pagination.pagesize}
              page={pagination.page}
            />
          </>
        ) : (
          <NotPermission />
        )}
      </Box>
    </Paper>
  )
}
