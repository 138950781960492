import { FC } from "react";
import { Button } from "@mantine/core";
import { IconCircleX } from "@tabler/icons-react";
import { FormattedMessage } from "react-intl";

interface ChildComponentProps {
  callBack: () => void;
}

const CancelButton: FC<ChildComponentProps> = ({ callBack }) => {
  const handleCancelClick = () => {
    callBack();
  };

  return (
    <div>
      <Button
        mt={10}
        size="xs"
        color="gray"
        leftIcon={<IconCircleX width={18} height={18} />}
        onClick={handleCancelClick}
        styles={{
          leftIcon: { marginRight: "4px" },
        }}
      >
        <FormattedMessage id="cancel" defaultMessage="Cancel" />
      </Button>
    </div>
  );
};

export default CancelButton;
