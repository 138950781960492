import React, { useEffect } from "react";import {
    Tooltip,
    ActionIcon,
    Group,
    TextInput,
    Box,
    Paper,
    Card,
    Modal,
  } from "@mantine/core";
import ModalTitleText from "../../../../ttcomponents/ModalTitleText/ModalTitleText";
import { IcostCenter } from "../../../../interface/ICostCenter";
import WordTranslationComponent from "../../../../utils/language_pack/words";
import { hasLength, useForm } from "@mantine/form";
import { HintErrorMantineInfo, HintInfo } from "../../../../utils/function";
import { regCostcenterSetting } from "../../../../api";
import ResetBtn from "../../../../ttcomponents/Button/ResetBtn";
import SubmitBtn from "../../../../ttcomponents/Button/SubmitBtn";


//  Define the interface and receive a function operation
interface ICostCenterForm {
    closeFormModal: () => void;
    callBackId: (value:number) => void;
    refreshFunction: () => void;
    openFormState: boolean,
    costCenter: IcostCenter;
  }
export default function CostCenterForm({openFormState,callBackId,costCenter,refreshFunction,closeFormModal,}:ICostCenterForm){
    // Get translation public configuration information
   const word_translation = WordTranslationComponent();
   const form = useForm({
    validateInputOnBlur: true,
    initialValues: {
      id_costcenter:  "",
      street1: "",
      street2:"",
      cost_center_identifier: "",
      city:  "",
      name: "",
      postalCode:  "",
    },
    // functions will be used to validate values at corresponding key
    validate: {
      name: hasLength({ min: 2, max: 200 }, word_translation.validata_value_need_2_100_length),
      cost_center_identifier: hasLength({ min: 2, max: 200 }, word_translation.validata_value_need_2_100_length),
    },
  });

  useEffect(() => {
    form.setFieldValue("id_costcenter", costCenter.id_costcenter);
    form.setFieldValue("street1", costCenter.street1);
    form.setFieldValue("street2", costCenter.street2);
    form.setFieldValue("cost_center_identifier", costCenter.cost_center_identifier);
    form.setFieldValue("city", costCenter.city);
    form.setFieldValue("name", costCenter.name);
    form.setFieldValue("postalCode", costCenter.postalCode);
  }, [costCenter,openFormState]);
  
  const handleFormSubmit = async() => {
    // Verification form
    const valid = await form.validate();
    let originalData = {
      street2: form.values.street2,
      street1: form.values.street1,
      name: form.values.name,
      cost_center_identifier: form.values.cost_center_identifier,
      postalCode: form.values.postalCode,
      city: form.values.city,
    };
    const methodType = form.values.id_costcenter ? "PUT" : "POST";
    const formData = form.values.id_costcenter
      ? { ...originalData, id: form.values.id_costcenter }
      : originalData;

    if (!valid.hasErrors) {
      const response = await regCostcenterSetting(formData, methodType);
      if (response.data.code == 200){
          HintInfo(response.data);
          closeFormModal();
          callBackId(response.data.data.id)
          refreshFunction();
      }else{
        HintErrorMantineInfo(response.data.msg)
      }
    }else{
        HintErrorMantineInfo(valid)
      }
  };

    return (
        <Modal
        opened={openFormState}
        onClose={closeFormModal}
        centered={true}
        padding="md"
        radius={12}
        title={<ModalTitleText title={costCenter.id_costcenter ? word_translation.create : `${word_translation.edit}`} />}
      >
           <Card p={20} radius={15} color="customize">
            <form >
            <Box>
              <TextInput
                required
                key={costCenter.id_costcenter}
                label={word_translation.cost_center_name}
                placeholder={word_translation.Input}
                {...form.getInputProps("name")}
              />
              <TextInput
                required
                key={costCenter.cost_center_identifier}
                description={word_translation.cost_center_identifier_tips}
                label={word_translation.cost_center_identifier}
                placeholder={word_translation.Input}
                {...form.getInputProps("cost_center_identifier")}
              />
                <TextInput
                required
                key={costCenter.street1}
                label={word_translation.street1}
                placeholder={word_translation.street1}
                {...form.getInputProps("street1")}
              />
              <TextInput
                label={word_translation.street2}
                key={costCenter.street2}
                placeholder={word_translation.street2}
                {...form.getInputProps("street2")}
              />
              <TextInput
                key={costCenter.postalCode}
                label={word_translation.company_postal_code}
                placeholder={word_translation.company_postal_code}
                {...form.getInputProps("postalCode")}
              />
              <TextInput
                key={costCenter.city}
                label={word_translation.city}
                placeholder={word_translation.city}
                {...form.getInputProps("city")}
              />
            </Box>
            <Group position="right" mt={10}>
                <ResetBtn callBack={()=>{form.reset()}} />
                <SubmitBtn callBack={handleFormSubmit} />
            </Group>
            </form>
          </Card>
      </Modal>
    )
}