import {
    Box,
    Grid,
    Text,
    Select,
    TextInput,
} from "@mantine/core";
import {
    IconAt,
} from "@tabler/icons-react";
import { useEffect, useState } from "react";
import WordTranslationComponent from "../../../utils/language_pack/words";
import TableTextInputTitle from "../../../ttcomponents/TableTextInputTitle/TableTextInputTitle";
import TableTopTitle from "../../../ttcomponents/TableTopTitle/TableTopTitle";

import {
    getDomainsSelectFunction,
    getUserRoleGeneralSelectFunction,
} from "../../../api/common_request";

import { isNumber } from "../../../utils/validataUnits";
import { SelectRole } from "../../../interface/Icommon";

interface ComponentInterface {
    setFormValue: (data: any) => any;
    formValue: any
}

/**
 * OnBoarding
 * @returns
 */
export default function GeneralInformation({ formValue, setFormValue }: ComponentInterface) {

    const [rand_number, setRandNumber] = useState<number>(1);
    // Get translation public configuration information
    const word_translation = WordTranslationComponent();
    // Declare the variable email name
    const [emailName, setEmailName] = useState<string>(formValue?.email || '');
    // Declare the variable email value
    const [emailDomains, setEmailDomains] = useState<string>('');
    // Define all the list of employees you can choose
    const [domains, setDomains] = useState<string[]>([]);

    // Define all the list of user role you can choose
    const [userRoleList, setUserRoleList] = useState<SelectRole[]>([]);

    /**
     * Get the current list data
     */
    const ajaxCurrentList = async () => {
        // Get a list of domain names
        getDomains();
        // Get a list of user role
        getUserRole();
    };

    /**
     * Get a list of domain names
     */
    const getDomains = async () => {
        // Call the Get Domain Name Selection function and set the list of domain names
        let domainresult = await getDomainsSelectFunction();
        setDomains(domainresult)
        if(domainresult.length==1){
            setEmailDomains(domainresult[0])
        }
        setRandNumber(rand_number+1)
    };


    useEffect(() => {
        let email_ori = '';
        if (emailName && emailDomains) {
            email_ori = `${emailName}@${emailDomains}`
        }
        setFormValue((prevData: any) => {
            return {
                ...prevData,
                email: email_ori // 使用 name 作为属性名动态更新状态
            };
        });
    }, [emailName, emailDomains]);

    useEffect(() => {
        if (formValue?.first_name && formValue?.last_name) {
            setEmailName(`${formValue?.first_name }.${formValue?.last_name }`)
        }else{
            setEmailName(``)
        }
    }, [formValue?.first_name, formValue?.last_name]);

    /**
     * When the component is mounted, call the ajaxCurrentList method to get the data
     */
    useEffect(() => {
        // Call the ajaxCurrentList method to get the data
        ajaxCurrentList();
    }, []);

    /**
     * Get cost get use role
     */
    const getUserRole = async () => {
        // Call the Get Cost Center General Selection function and set the cost center data
        setUserRoleList(await getUserRoleGeneralSelectFunction());
    };
    /**
     * handle Data
     */
    const handleData = async (event: any) => {
        // 从 event.currentTarget 获取 name 和 value
        const { name, value } = event.currentTarget;
        // if((name =="work_hours_week" || name =="working_days_week" || name =="vacation_day_year" ) && !isNumber(value)){
        //     return ClientWarningHint(`${name} Must be a number`);
        // }
        // 更新状态
        setFormValue((prevData: any) => {
            return {
                ...prevData,
                [name]: value // 使用 name 作为属性名动态更新状态
            };
        });
    };

    

    /**
     * handle Data
     */
    const handleDataSelect = async (name:any,value: any) => {
        if(name == 'user_role_id'){
            for(let i = 0;i<userRoleList.length ;i++){
                if(userRoleList[i]['value'] == value){
                    setFormValue((prevData: any) => {
                        return {
                            ...prevData,
                            'role_identifier': userRoleList[i]['role_identifier'] ,// 使用 name 作为属性名动态更新状态
                            
                        };
                    });
                }
            }
        }
        // 更新状态
        setFormValue((prevData: any) => {
            return {
                ...prevData,
                [name]: value // 使用 name 作为属性名动态更新状态
            };
        });
    };
  
    return (
                <Box className="border-radius-xs min-h-25 border-custom">
                    <TableTopTitle title={word_translation.general_informations} />
                    <Box className="pl-x-15 pt-xs pt-2">
                        <Grid>
                            <Grid.Col span={3}>
                                <TableTextInputTitle title={word_translation.personal_no} />
                                <TextInput
                                    onChange={(event) => { handleData(event) }}
                                    name="personal_no"
                                    defaultValue={formValue?.stuff_no}
                                    key={rand_number}
                                />
                            </Grid.Col>
                        </Grid>
                        <Grid className="pt-2">
                            <Grid.Col span={5}>
                                <TableTextInputTitle title={word_translation.first_name} />
                                <TextInput
                                    onChange={(event) => { handleData(event) }}
                                    name="first_name"
                                    defaultValue={formValue?.first_name}
                                    key={rand_number}
                                />
                            </Grid.Col>
                            <Grid.Col span={5}>
                                <TableTextInputTitle title={word_translation.surname} />
                                <TextInput
                                    onChange={(event) => { handleData(event) }}
                                    name="last_name"
                                    defaultValue={formValue?.last_name}
                                    key={rand_number}
                                />
                            </Grid.Col>
                        </Grid>
                        <Grid className="pt-2">
                            <Grid.Col span={3}>
                                <TableTextInputTitle
                                    title={word_translation.vacation_day_year}
                                />
                                <TextInput
                                    onChange={(event) => { handleData(event) }}
                                    name="vacation_day_year"
                                    defaultValue={formValue?.vacation_day_year}
                                    key={rand_number}
                                />
                            </Grid.Col>

                            <Grid.Col span={3}>
                                <TableTextInputTitle
                                    title={word_translation.work_hours_week}
                                />
                                <TextInput
                                    onChange={(event) => { handleData(event) }}
                                    name="work_hours_week"
                                    key={rand_number}
                                    defaultValue={formValue?.work_hours_week}
                                />
                            </Grid.Col>

                            <Grid.Col span={3}>
                                <TableTextInputTitle
                                    title={word_translation.working_days_week}
                                />
                                <TextInput
                                    onChange={(event) => { handleData(event) }}
                                    name="working_days_week"
                                    defaultValue={formValue?.working_days_week}
                                    key={rand_number}
                                />
                            </Grid.Col>
                        </Grid>
                        
                        {/*   */}
                        <Grid className="pt-2">
                            <Grid.Col span={4}>
                                <TableTextInputTitle py={0} title={word_translation.email} />
                                <TextInput
                                    defaultValue={emailName}
                                    onChange={(event) => setEmailName(event.currentTarget.value)}
                                />
                            </Grid.Col>
                            <Box>
                                <Text className="input_title pt-4"> &nbsp;</Text>
                                <Text className="input_title">
                                    <IconAt></IconAt>
                                </Text>
                            </Box> 
                            <Grid.Col span={3}>
                                <Text className="input_title">&nbsp;</Text>
                                <Select
                                    key={rand_number}
                                    labelProps={{ className: "input_title" }}
                                    onChange={(value) => setEmailDomains(value as string)}
                                    placeholder={word_translation.domains}
                                    searchable
                                    
                                    defaultValue={emailDomains}
                                    data={domains}
                                />
                            </Grid.Col>
                            
                            <Grid.Col span={3}>
                                <Text className="input_title">Current Email</Text>
                                <Text size={11}>{formValue?.id&&formValue?.email}</Text>
                            </Grid.Col>
                        </Grid>
                        <Grid>
                            <Grid.Col span={4}>
                                <TableTextInputTitle title={word_translation.phone} />
                                <TextInput
                                    defaultValue={formValue?.phone}
                                    onChange={(event) => { handleData(event) }}
                                    name="phone" />
                            </Grid.Col>

                            <Grid.Col offset={2} span={4}>
                            <TableTextInputTitle title={word_translation.select_role_name} />
                                    <Select
                                        labelProps={{ className: "input_title" }}
                                        onChange={(value) =>handleDataSelect("user_role_id", value) }
                                        placeholder={word_translation.select_team_leader}
                                        searchable
                                        defaultValue={formValue?.user_role_id}
                                        key={formValue?.user_role_id}
                                        data={userRoleList}
                                      />
                            </Grid.Col>

                        </Grid>
                    </Box>
                </Box>
    
    );
}
