export function LangBtn(props: React.ComponentPropsWithoutRef<'svg'>) {
    return (
<svg xmlns="http://www.w3.org/2000/svg" width="27" height="26" viewBox="0 0 27 26" fill="none">
  <g filter="url(#filter0_d_2_4149)">
    <path d="M9.40716 19.9098C9.37673 19.9098 9.33617 19.9298 9.30574 19.9298C7.33818 18.9698 5.73573 17.3798 4.75195 15.4398C4.75195 15.4098 4.77224 15.3698 4.77224 15.3398C6.00957 15.6998 7.28747 15.9698 8.55523 16.1798C8.77835 17.4398 9.04205 18.6898 9.40716 19.9098Z" fill="#818181"/>
    <path d="M22.887 15.4498C21.8829 17.4398 20.2095 19.0498 18.1709 20.0198C18.5563 18.7498 18.8808 17.4698 19.0938 16.1798C20.3717 15.9698 21.6293 15.6998 22.8667 15.3398C22.8565 15.3798 22.887 15.4198 22.887 15.4498Z" fill="#818181"/>
    <path d="M22.9681 6.71047C21.6902 6.33047 20.4022 6.02047 19.0938 5.80047C18.8808 4.51047 18.5664 3.23047 18.1709 1.98047C20.2703 2.97047 21.964 4.64047 22.9681 6.71047Z" fill="#818181"/>
    <path d="M9.40919 2.08859C9.04408 3.30859 8.78039 4.54859 8.5674 5.80859C7.25908 6.00859 5.96089 6.32859 4.67285 6.70859C5.65663 4.69859 7.2895 3.04859 9.30777 2.05859C9.3382 2.05859 9.37877 2.08859 9.40919 2.08859Z" fill="#818181"/>
    <path d="M17.3623 5.59C15.0094 5.33 12.6361 5.33 10.2832 5.59C10.5368 4.22 10.8613 2.85 11.3177 1.53C11.338 1.45 11.3278 1.39 11.338 1.31C12.1392 1.12 12.9607 1 13.8228 1C14.6747 1 15.5063 1.12 16.2974 1.31C16.3076 1.39 16.3076 1.45 16.3279 1.53C16.7842 2.86 17.1088 4.22 17.3623 5.59Z" fill="#818181"/>
    <path d="M8.33392 14.4917C6.93431 14.2417 5.55499 13.9217 4.21624 13.4717C4.1351 13.4517 4.07425 13.4617 3.99311 13.4517C3.80042 12.6617 3.67871 11.8517 3.67871 11.0017C3.67871 10.1617 3.80042 9.3417 3.99311 8.56172C4.07425 8.55172 4.1351 8.55172 4.21624 8.53172C5.56513 8.09172 6.93431 7.76172 8.33392 7.51172C8.08037 9.8317 8.08037 12.1717 8.33392 14.4917Z" fill="#818181"/>
    <path d="M23.9628 11.0017C23.9628 11.8517 23.8411 12.6617 23.6484 13.4517C23.5673 13.4617 23.5064 13.4517 23.4253 13.4717C22.0764 13.9117 20.6971 14.2417 19.3076 14.4917C19.5713 12.1717 19.5713 9.8317 19.3076 7.51172C20.6971 7.76172 22.0865 8.08172 23.4253 8.53172C23.5064 8.55172 23.5673 8.56172 23.6484 8.56172C23.8411 9.3517 23.9628 10.1617 23.9628 11.0017Z" fill="#818181"/>
    <path d="M17.3623 16.4102C17.1088 17.7902 16.7842 19.1502 16.3279 20.4702C16.3076 20.5502 16.3076 20.6102 16.2974 20.6902C15.5063 20.8802 14.6747 21.0002 13.8228 21.0002C12.9607 21.0002 12.1392 20.8802 11.338 20.6902C11.3278 20.6102 11.338 20.5502 11.3177 20.4702C10.8714 19.1402 10.5368 17.7902 10.2832 16.4102C11.4597 16.5402 12.6361 16.6302 13.8228 16.6302C15.0094 16.6302 16.196 16.5402 17.3623 16.4102Z" fill="#818181"/>
    <path d="M17.6373 14.7633C15.1006 15.0789 12.5404 15.0789 10.0037 14.7633C9.68366 12.2622 9.68366 9.7378 10.0037 7.23667C12.5404 6.92111 15.1006 6.92111 17.6373 7.23667C17.9573 9.7378 17.9573 12.2622 17.6373 14.7633Z" fill="#818181"/>
  </g>
  <defs>
    <filter id="filter0_d_2_4149" x="0" y="0" width="27" height="26" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      
      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 10 0" result="softAlpha"/>
      <feOffset dy="1"/>
      <feGaussianBlur stdDeviation="2.5"/>
      <feComposite in2="hardAlpha" operator="over"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_4149"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2_4149" result="shape"/>
    </filter>
  </defs>
</svg>
    );
  }