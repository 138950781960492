import { Text,Box,ScrollArea,Table,Divider, Badge } from "@mantine/core";
import { TimeTrackerResponseCustomer } from "../../../interface/Ireports";
import React from "react";


const ReportTemplateThree: React.FC<{ Data: TimeTrackerResponseCustomer }> = ({ Data }) => {
    return (
        <ScrollArea mt={20}>
        <Table>
        <thead>
          <tr>
            <th>{Data?.items_list[0].name}</th>
            <th> staff</th>
          {Data?.week_number_list ? Data.week_number_list.map((item,index)=>{
             return(
               <th>Kw <>{item}</></th>
             ) 
          }):''}
            <th>Total </th>
          </tr>
        </thead>
        <tbody>
        { Data?.items_list.map((item,index)=>{
     return (
      <>
        {item.order_list.map((orderItem, orderIndex) => {
          return (
          <>
          <tr key={orderIndex}>
            <td  rowSpan={orderItem.time_tracker_list.length+1}>
              <Text className="title_text" size={12} color="red">
              Order name:<Badge> {orderItem.order_name}</Badge>
              </Text>
            </td>
          </tr>

          {orderItem.time_tracker_list.map((subitem, subIndex) => (
            <tr key={subIndex}>
              <td>{subitem.user_name}</td>
              {/* {(Object.entries(subitem.week_time) as [string, string][]).map(([week, time], weekIndex) => (
                <td key={weekIndex}>{time}
                  <Text c="#637381" className={time === '00:00' ? 'figma-font-nomal' : 'fontBold' } key={index} pt={5}   > {time}</Text>
                </td>
              ))} */}

{Object.entries(subitem.week_time).map(([week, time], weekIndex) => (
  <td key={weekIndex}>
    <Text c="#637381" className={time === '00:00' ? 'figma-font-nomal' : 'fontBold' } pt={5}>
      {time as string}
    </Text>
  </td>
))}

              <td>
              <Text c="#637381" className={subitem.week_total_time === '00:00' ? 'figma-font-nomal' : 'fontBold' } key={index} pt={5}   > {subitem.week_total_time}</Text>
              </td>
            </tr>
          ))}
          
          </>
        )})}
      </>)
          })}
        </tbody>
      </Table>
 
      </ScrollArea>
    )
}

export default ReportTemplateThree;