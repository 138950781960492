export function Right(props: React.ComponentPropsWithoutRef<'svg'>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="26" viewBox="0 0 27 26" fill="none">
        <g filter="url(#filter0_d_24_3343)">
          <path d="M20.6581 16.8496L20.658 16.8495L20.6525 16.8535C20.008 17.3188 19.1967 17.6123 18.23 17.7115V0.299446C19.5479 0.433085 20.5819 0.926663 21.2988 1.70803C22.0639 2.54184 22.4919 3.72977 22.4919 5.23014V12.7722C22.4919 14.0726 22.1738 15.1331 21.5827 15.936L21.5827 15.9359L21.5793 15.9408C21.3322 16.2939 21.0164 16.6054 20.6581 16.8496Z" fill="white" stroke="#D9D9D9" stroke-width="0.5"/>
          <path d="M6.58141 1.15162L6.58145 1.15167L6.58628 1.14821C7.40096 0.564447 8.47768 0.25 9.79776 0.25H16.3623V17.75H9.79776C8.18622 17.75 6.93038 17.277 6.07801 16.436C5.22607 15.5955 4.74756 14.3583 4.74756 12.771V5.229C4.74756 3.92856 5.06573 2.86801 5.65675 2.06522L5.65681 2.06526L5.66025 2.06035C5.90736 1.70726 6.22315 1.39566 6.58141 1.15162ZM11.5698 11.959L13.9051 9.65497C14.2689 9.29607 14.2689 8.70393 13.9051 8.34503L11.5698 6.04103C11.2063 5.68239 10.6233 5.68657 10.2547 6.03808C9.87835 6.39698 9.87772 6.99241 10.2528 7.3521L11.9149 9L10.2516 10.649L10.4272 10.827L10.2512 10.6495L10.2513 10.6494C9.88785 11.0083 9.88796 11.6002 10.2516 11.959C10.4381 12.1429 10.6757 12.229 10.9107 12.229C11.1457 12.229 11.3834 12.1429 11.5698 11.959Z" fill="white" stroke="#D9D9D9" stroke-width="0.5"/>
        </g>
        <defs>
          <filter id="filter0_d_24_3343" x="0.497559" y="0" width="26.2446" height="26" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="4"/>
            <feGaussianBlur stdDeviation="2"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_24_3343"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_24_3343" result="shape"/>
          </filter>
        </defs>
      </svg>

        );
    }