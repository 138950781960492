import {
  Paper,
  TextInput,
  Box,
  Group,
  Text,
  Modal,
  Grid,
  Table,
  ActionIcon,
  LoadingOverlay,
  Tooltip,
  Flex,
  Badge,
  Dialog,
  Button,
  Textarea,
} from "@mantine/core";
import { useRef, useEffect, useState } from "react";
import { Supplier, SuppliersData } from "../../../interface/Iinventory";
import { useDisclosure } from "@mantine/hooks";

import PageTitle from "../../../ttcomponents/PageTitle/PageTitle";
import {
  IconCalendar,
  IconEdit,
  IconPencil,
  IconSearch,
  IconTextPlus,
  IconTrash,
  IconUserPlus,
  IconUsersMinus,
  IconX,
} from "@tabler/icons-react";
import CreateBtn from "../../../ttcomponents/Button/CreateBtn";
import { useIntl } from "react-intl";
import { useForm } from "@mantine/form";
import { modals } from "@mantine/modals";
import {
  regGetProduct,
  regGetProductCategory,
  regGetProductPurchase,
  regGetProductPurchaseCheck,
  regGetReturnProductPurchase,
  regGetSupplier,
  regProduct,
  regProductPurchase,
  regSupplier,
} from "../../../api";
import { HintInfo, formatDateUpgraded } from "../../../utils/function";
import SearchBox from "../../../ttcomponents/SearchBox/SearchBox";
import SupplierContactIndex from "../supplier_contact/supplier_contact_index";
import { IPagination } from "../../../interface/Ipagintion";
import PaginationA from "../../../ttcomponents/Pagination/PaginationA";
import "./css/index.css";
import { IProductResponse, Product } from "../../../interface/IProduct";
import { DetailCustome } from "../../../public/svg/DetailCustome";
import SupplierSelect from "../../../ttcomponents/Supplier/SupplierSelect";
import { ICategory } from "../../../interface/IProductCategory";
import {
  Cancellation,
  Purchase,
  Receipt,
} from "../../../public/svg/ProductOrder";
import ProductProcurementFrom from "./purchasing_from";
import {
  IProductProcurementData,
  IProductProcurementOrderData,
} from "../../../interface/IProductProcurement";
import ReturnProductOrderFrom from "../return_product/return_product_order_from";
import { notifications } from "@mantine/notifications";
import { DateInput, DateValue } from "@mantine/dates";
import { ItopSearch } from "../../../interface/Icommon";

export default function ProductProcurementIndex({searchValue}:ItopSearch) {
  let deliveredDateCustome: any;
  // useIntl for multiple language
  const intl = useIntl();
  // Edit modal status
  const [openedFrom, { open: openFrom, close: closeFrom }] =
    useDisclosure(false);

  // return the goods
  const [returnOpened, { toggle: openReturnOpened, close: closeReturnOpened }] =
    useDisclosure(false);

  const [deliveredDate, setDeliveredDate] = useState<Date | null>(null);
  // contact modal status
  const [
    ProductProcurementShow,
    { open: openProductProcurementShow, close: colseProductProcurementShow },
  ] = useDisclosure(false);

  const [loading, setLoading] = useState(false);
  // Form type
  const [formType, setFormType] = useState("create");
  const [currentRowInfo, setCurrentRowInfo] = useState<
    IProductProcurementOrderData | undefined
  >();
  const [productCategory, setProductCategory] = useState<ICategory[]>([]);
  const [returnNotes, setReturnNotes] = useState("");

  // define the supplier list
  const [productProcurementList, setProductProcurementList] =
    useState<IProductProcurementData>({
      total: 0,
      data: [], // Use the data property from the first item in the mock data array
    });

  const [pagination, setPagination] = useState<IPagination>({
    search: "",
    page: 1,
    pagesize: 10,
    sortBy: "",
    sortOrder: "asc",
    query: {},
  });
  // Receive the callback return data, search for content
  const ReceiveSearchBack = (value: string | number) => {
    setPagination((prevpagination) => ({
      ...prevpagination,
      search: value,
      page: 0,
      query: {},
    }));
  };

      
  // Execute the useeffect when the first load is loaded.
  const isInitialMount = useRef(true);
  useEffect(() => {
    if (!isInitialMount.current) {
      ReceiveSearchBack(searchValue);
    }
    isInitialMount.current = false;
  }, [searchValue]);

  const ReceiveSupplierBack = (value: any) => {
    if (value.type == "supplier") {
      setPagination((prevpagination) => {
        const { query, ...rest } = prevpagination; // Extract the attributes other than Query to the REST
        if (value.value == "") {
          // If value.value exists, delete the support
          delete query.supplier_id;
        } else {
          query.supplier_id = value.value;
        }

        return {
          ...rest,
          page: 0, // Keep other attributes in REST
          query: query, // Set the modified query
        };
      });
    }
  };

  //Pagling recovery trigger update Terrament recovery trigger update
  const ReceivePaginationABack = (page: number) => {
    setPagination((prevpagination) => ({
      ...prevpagination,
      page: page,
    }));
  };
  // set search value
  const [search, setSearch] = useState("");

  // Define an asynchronous function ajaxcurrenTlist to send AJAX request to obtain the list of suppliers
  const ajaxCurrentList = async () => {
    setLoading(true); // Set the loading status to TRUE, indicating that the data is loading
    let searchStr = {};

    // Check whether there are search conditions, if so, build a search parameter object
    if (search) {
      searchStr = {
        search: search,
      };
    }

    // Send GET requests to get supplier data
    const responseProductProcurement = await regGetProductPurchase(
      pagination,
      "GET"
    );

    setLoading(false); // After the data is loaded, set the loading status to false
    if (responseProductProcurement.data.code === 200) {
      setProductProcurementList(responseProductProcurement.data.data); // 如果响应状态码为200，将供应商列表更新为响应数据中的数据
    }
  };
  // Use the USEEFFECT hook. When Search changes, call the AjaxCurrenTList function
  useEffect(() => {
    ajaxCurrentList();
  }, [pagination]);

  // Edit supplier
  const handleEdit = async (row: IProductProcurementOrderData) => {
    if (row.flag != 2) {
      notifications.show({
        color: "yellow",
        icon: <IconX />,
        message: "This order cannot be Edit",
      });
    } else {
      setFormType("edit");
      openProductProcurementShow();
      setCurrentRowInfo(row);
    }
  };

  // Delete supplier
  const handleDel = (row: IProductProcurementOrderData) => {
    modals.openConfirmModal({
      title: intl.formatMessage({
        id: "delete_title",
        defaultMessage: "Delete this row",
      }) + ' - '+ intl.formatMessage({
        id: "purchasing",
        defaultMessage: "Purchasing",
      }),
      centered: true,
      children: (
        <Text size="sm" mt={12}>
        <p>{ intl.formatMessage({id: "purchasing_no", defaultMessage: "Product name", })  }: {row.purchasing_no} </p>
        <p>{ intl.formatMessage({id: "product_name", defaultMessage: "Product name", })  }: {row.product_name} </p>
        <p>{ intl.formatMessage({id: "Supplier", defaultMessage: "Supplier name", })  }: {row.supplier_name} </p>
      
        </Text>
      ),
      labels: {confirm: intl.formatMessage({ id: "confirm_delete", defaultMessage: "Confirm Delete",}),
      cancel: intl.formatMessage({id: "cancel_delete",defaultMessage: "Cancel Delete",}),
      },
      confirmProps: { color: "red" },
      onConfirm: () => delSupplierInfo(row.id_purchasing),
    });
  };

  // Delete supplier info to backend
  const delSupplierInfo = async (id: string) => {
    const productResult = await regProductPurchase(
      { id_purchasing: id, confirm_deletion: "yes" },
      "DELETE"
    );
    HintInfo(productResult.data);
    if (productResult.data.code == 200) ajaxCurrentList();
  };

  // Create supplier
  const handleCallback = async () => {
    openProductProcurementShow();
    setCurrentRowInfo(undefined);
  };

  function setDeliveredDateFUnction(event: DateValue) {
    deliveredDateCustome = formatDateUpgraded(event as Date, "YYYY-MM-DD");
  }
  //  Review this order
  const reviewFuction = (row: IProductProcurementOrderData) => {
    deliveredDateCustome = formatDateUpgraded(
      row.delivery_date ? new Date(row.delivery_date) : new Date(),
      "YYYY-MM-DD"
    );
    modals.openConfirmModal({
      title: "Select an option",
      centered: true,
      children: (
        <Text size="sm" mih={500}>
          You can choose to enter the warehouse after review or return the
          goods.
          <DateInput
            valueFormat="DD/MM/YYYY"
            onChange={(event) => setDeliveredDateFUnction(event)}
            defaultValue={
              row.delivery_date ? new Date(row.delivery_date) : new Date()
            }
            labelProps={{ className: "input_title" }}
            icon={<IconCalendar size="1.1rem" stroke={1.5} />}
            label="Delivered Date"
            mx="auto"
          />
        </Text>
      ),
      labels: { confirm: "Inbound", cancel: "Rejected Item" },
      confirmProps: { color: "green", disabled: row.flag != 2 ? true : false },
      cancelProps: { color: "red", disabled: row.flag == 2 ? false : true },
      onCancel: () => {
        setCurrentRowInfo(row);
        openReturnOpened();
      },
      onConfirm: () => {
        examinationPassed(row);
      },
    });
  };
  // examination passed
  const examinationPassed = async (row: IProductProcurementOrderData) => {
    const examinationPassedResult = await regGetProductPurchaseCheck(
      { order_id: row.id_purchasing, flag: 1, delivered_date: deliveredDateCustome },
      "PUT"
    );
    HintInfo(examinationPassedResult.data);
    if (examinationPassedResult.data.code == 200) ajaxCurrentList();
    setCurrentRowInfo(undefined);
  };

  // Returntreatment
  const returnTreatment = async (row: IProductProcurementOrderData) => {
    setCurrentRowInfo(row);
    if (row.flag != 2) {
      notifications.show({
        color: "yellow",
        icon: <IconX />,
        message: "This order cannot be returned",
      });
    } else {
      openReturnOpened();
    }
  };

  const rows = productProcurementList.data.map((row) => {
    let flagStr: any, statusStr: any;
    if (row.flag === 1) {
      flagStr = <Badge color="green"> Audited</Badge>;
    } else if (row.flag === 2) {
      flagStr = <Badge color="red"> NotReviewed</Badge>;
    } else {
      flagStr = <Badge color="orange">Returned</Badge>;
    }
    if (row.status === 1) {
      statusStr = <Badge color="green"> completed</Badge>;
    } else if (row.status === 2) {
      statusStr = <Badge color="red">undone</Badge>;
    }
    return (
      <tr className="border-bt-2 my-10 ">
        <>
          <td>
            {row.product_name}{" "}
            <span className="red-font">
              {row.unit_price ? null : "(Need to purchase)"}
            </span>{" "}
          </td>
          <td> {row.purchasing_no ? row.purchasing_no : "-"}</td>
          <td> {row.supplier_name ? row.supplier_name : "-"} </td>
          <td> {row.unit_price ? row.unit_price : "-"} </td>
          <td> {row.total_price ? row.total_price : "-"} </td>
          <td> {row.order_quantity ? row.order_quantity : "-"} </td>
          <td> {row.delivered_quantity ? row.delivered_quantity : "-"} </td>
          <td>
            {" "}
            {row.delivery_schedule
              ? formatDateUpgraded(
                  new Date(row.delivery_schedule),
                  "YYYY-MM-DD"
                )
              : "-"}{" "}
          </td>
          <td>
            {" "}
            {row.delivery_date
              ? formatDateUpgraded(new Date(row.delivery_date), "YYYY-MM-DD")
              : "-"}{" "}
          </td>
          <td> {flagStr} </td>
          <td> {statusStr} </td>
          <td>
            <Group spacing={0} position="center">
              <ActionIcon onClick={() => handleEdit(row)}>
                <Tooltip label="Edit">
                  <IconEdit size="1.2rem" stroke={1.5} />
                </Tooltip>
              </ActionIcon>

              {/* <ActionIcon onClick={() => handleEdit(row)}>
                                    <Tooltip label="Edit">
                                        <IconTextPlus size="1.2rem" stroke={1.5} />
                                    </Tooltip>
                                </ActionIcon> */}

              <ActionIcon onClick={() => reviewFuction(row)}>
                <Tooltip label="Edit">
                  <Receipt />
                </Tooltip>
              </ActionIcon>

              <ActionIcon onClick={() => returnTreatment(row)}>
                <Tooltip label="Edit">
                  <Cancellation />
                </Tooltip>
              </ActionIcon>

              <ActionIcon onClick={() => handleDel(row)}>
                <Tooltip label="Delete">
                  <IconTrash size="1.2rem" stroke={1.5} color="red" />
                </Tooltip>
              </ActionIcon>
            </Group>
          </td>
        </>
      </tr>
    );
  });
  const closeEditFunction = (type: number) => {
    closeFrom();
    // openReturnOpened();
    colseProductProcurementShow();
    if (type == 2) {
      ajaxCurrentList();
    }
  };
  // list all the suppliers
  return (
    <Box>
      <Paper
        px={20}
        mih={500}
        pb={10}
        w={"100%"}
        style={{ position: "relative" }}
      >
        <PageTitle
          title="PRODUCT PURCHASE MANAGEMENT"
          lang_id="PRODUCT PURCHASE MANAGEMENT"
        />
        <Group my={10}>
          <SearchBox callBack={ReceiveSearchBack} />
          <Box h={30}>
            <SupplierSelect callBack={ReceiveSupplierBack} />
          </Box>
          <Box h={30}>
            <CreateBtn callBack={handleCallback} />
          </Box>
        </Group>
        <LoadingOverlay visible={loading} overlayBlur={2} />

        <table className="table_custome text-center">
          <tr className="table_th">
            <th>
              {intl.formatMessage({
                id: "Product Name",
                defaultMessage: "Product Name",
              })}
            </th>
            <th>
              {intl.formatMessage({
                id: "Order No",
                defaultMessage: "Order No",
              })}
            </th>
            <th>
              {intl.formatMessage({
                id: "Supplier",
                defaultMessage: "Supplier",
              })}
            </th>
            <th>
              {intl.formatMessage({
                id: "Unit Price",
                defaultMessage: "Unit Price",
              })}
            </th>
            <th>
              {intl.formatMessage({
                id: "Total Price",
                defaultMessage: "Total Price",
              })}
            </th>
            <th>
              {intl.formatMessage({
                id: "Order Quantity",
                defaultMessage: "Order Quantity",
              })}
            </th>
            <th>
              {intl.formatMessage({
                id: "Delivered Quantity",
                defaultMessage: "Delivered Quantity",
              })}
            </th>
            <th>
              {intl.formatMessage({
                id: "Delivery Schedule",
                defaultMessage: "Delivery Schedule",
              })}
            </th>
            <th>
              {intl.formatMessage({
                id: "Delivered Date",
                defaultMessage: "Delivered Date",
              })}
            </th>
            <th>
              {intl.formatMessage({
                id: "Flag",
                defaultMessage: "Flag",
              })}
            </th>
            <th>
              {intl.formatMessage({
                id: "Status",
                defaultMessage: "Status",
              })}
            </th>
            <th>
              {intl.formatMessage({
                id: "Option",
                defaultMessage: "Option",
              })}
            </th>
          </tr>

          {rows}
        </table>

        <PaginationA
          callBack={ReceivePaginationABack}
          total_count={productProcurementList.total as number}
          pagesize={pagination.pagesize}
          page={pagination.page}
        />
      </Paper>

      <Modal
        opened={openedFrom}
        onClose={closeFrom}
        title={formType === "create" ? "Create Product" : "Edit Supplier"}
        centered={true}
        size="60%"
        padding="md"
        radius={12}
      >
        {/* <ProductForm productCategory={productCategory} productItem={currentRowInfo} closeFucntion={closeEditFunction} /> */}
      </Modal>

   
   

      <Modal
        opened={ProductProcurementShow}
        onClose={colseProductProcurementShow}
        title=" Quick purchase "
        // centered={true}
        size="md"
        padding="md"
        radius={12}
      >
        <ProductProcurementFrom
          createNew={true}
          unit_price_prohibit_edit={false}
          additional_information={currentRowInfo}
          closeFucntion={closeEditFunction}
        />
      </Modal>
      <Modal
        opened={returnOpened}
        withCloseButton
        onClose={closeReturnOpened}
        size="xl"
        centered={true}
        radius="md"
      >
        <ReturnProductOrderFrom
          type={false}
          additional_information={currentRowInfo}
          closeFucntion={closeEditFunction}
        />
        {/* <Textarea value={returnNotes} onChange={(event) => setReturnNotes(event.currentTarget.value)} />;
                    <Button onClick={closeReturnOpened}>Subscribe</Button> */}
      </Modal>
    </Box>
  );
}
