import { ActionIcon, Avatar, Box, Group, LoadingOverlay, ScrollArea, Table, Tooltip, Text, Checkbox } from "@mantine/core";
import { reAttributeType, reGetEmployeeCustomAttributeList, reGetEmployeeCustomAttributetTypesList } from "../../../../api";
import { Key, useEffect, useState } from "react";
import { Attribute, AttributeType } from "../../../../interface/Iuser";
import cx from 'clsx';
import classes from './custom_attrbutes/TableScrollArea.module.css';
import { IconDots, IconEdit, IconGripVertical, IconTrash } from "@tabler/icons-react";
import WordTranslationComponent from "../../../../utils/language_pack/words";
import { modals } from "@mantine/modals";
import { HintInfo } from "../../../../utils/function";
import GlobalCoreSetting from "../../../../utils/globalCortSetting";
import { IconDotsVertical } from "@tabler/icons-react";
import { UserTaskInteface } from "../../../../interface/Itask";
import TableTextInputTitle from "../../../../ttcomponents/TableTextInputTitle/TableTextInputTitle";
import { AvatarGroup } from "@mantine/core/lib/Avatar/AvatarGroup/AvatarGroup";
import AvatarGroupComponent from "../../../../ttcomponents/AvatarGroup/AvatarGroup";
import { requestTask } from "../../../../api/index_v2";

interface ComponentInterface{
 row:UserTaskInteface;
 userTaskList?:UserTaskInteface[];  
 callBackfunction:(value:string,is_checked:boolean)=>void,
 callDeleteBackfunction:(value:boolean)=>void,
 index:number,
}
export default function TaskListTr({index,row,callBackfunction ,callDeleteBackfunction}:ComponentInterface){
    
    // Get translation public configuration information
  const word_translation = WordTranslationComponent();
  // Get the global configuration
  const globalCoreSetting = GlobalCoreSetting();
  
  const [isHovered, setIsHovered] = useState(false);

  
    const handleEdit =  (row:UserTaskInteface) => {
        // callBackfunction&&callBackfunction(row)
    };


    // Fetches the delete  task
    const handleDel = async (row: UserTaskInteface) => {
        modals.openConfirmModal({
            title: word_translation.delete_title,
            centered: true,
            children: (
                <Text size="sm" mt={12}>
                    <p>  {word_translation.task_category} : {row.task_category_identifier}</p>
                </Text>
            ),
            labels: globalCoreSetting.DeleteOpenConfirmModaLabels,
            confirmProps: globalCoreSetting.DeleteOpenConfirmModaConfirmProps,

            onConfirm: async () => {
                try {
                    const deleteTaskResult = await requestTask({ id: row.id_task }, "DELETE");
                    HintInfo(deleteTaskResult.data);
                    if (deleteTaskResult.data.code == 200) {
                        callDeleteBackfunction(true);
                    } else {
                        callDeleteBackfunction(false);
                    }
                } catch (error) {
                    callDeleteBackfunction(false);
                    // Handle any errors that occur during the API call
                    console.error('Error fetching project list:', error);
                    // Optionally, you can throw the error or handle it as needed
                    throw error;
                }

            },
        });
    };

      function openSelectColorModalFunction(): void {
       
     }

         /**
     * 当鼠标移入元素时，设置isHovered为true
     */
    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    /**
     * 当鼠标移出元素时，设置isHovered为false
     */
    const handleMouseLeave = () => {
        setIsHovered(false);
    };

 //Click the checkbox whether to associate order information <FUNCTION>
const checkResultFunction  =(value:boolean)=>{
    callBackfunction(row.id_task as string,value)
}

    return (
              <tr className="text-center assignment_relative  table_tr_xs"  
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    key={index}>
                        <td colSpan={2}>{row.task_category_identifier}</td>
                        <td>{row.free_description}</td>
                        <td className="img-center">
                          <AvatarGroupComponent  UserInfo={row.user_info}/>
                        </td>
                        <td>{row.order_no ? row.order_no : '-'}</td>
                        <td>
                        <Group spacing={0} position="center">
                            <ActionIcon onClick={() => handleEdit(row)}>
                                <Tooltip label={word_translation.edit_tips}>
                                    <IconDotsVertical size="1.2rem" stroke={1.5} />
                                </Tooltip>
                            </ActionIcon> 
                            <ActionIcon onClick={() => handleDel(row)}>
                                <Tooltip label={word_translation.delete_tips}>
                                    <IconTrash size="1.2rem" color="red" stroke={1.5} />
                                </Tooltip>
                            </ActionIcon> 
                        </Group>
                        </td>
                {isHovered && (
                    <Group spacing={0} align="center" className="hovered-task-list-checkbox" position="center">
                        <ActionIcon onClick={openSelectColorModalFunction}>
                            <Tooltip label={word_translation.edit_tips}>
                            <Checkbox
                               size="xs"
                               onChange={(event) =>checkResultFunction(event.currentTarget.checked) } />
                            </Tooltip>
                            {/* ... other icons ... */}
                        </ActionIcon>
                    </Group>
                )}
                    </tr>
               
    )
}