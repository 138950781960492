import { Box, Grid, Group, Modal, Tooltip, Avatar, Checkbox, Divider, Text } from '@mantine/core'
import { useDisclosure, useListState } from '@mantine/hooks'
import ModalTitleText from '../../../../../ttcomponents/ModalTitleText/ModalTitleText'
import WordTranslationComponent from '../../../../../utils/language_pack/words'
import { useEffect, useState } from 'react'
import TableTextInputTitle from '../../../../../ttcomponents/TableTextInputTitle/TableTextInputTitle'
import { IconCircleX } from '@tabler/icons-react'
import { getCustomerListGeneralFunction } from '../../../../../api/assignments_request'
import { SelectRole } from '../../../../../interface/Icommon'
import GlobalCoreSetting from '../../../../../utils/globalCortSetting'
import UniversalButton from '../../../../../ttcomponents/Button/UniversalButton'
import PermissionCheckBox, { initialValuesCheckboxInterface } from './check_box'
import { requestPermissionProvision } from '../../../../../api/index_v2'
import { UserAllList } from '../../../../../interface/Iuser'

import apiUrl from '../../../../../config/api_config/config'
import { IconCircleCheck } from '@tabler/icons-react'

interface ComponentProps {
  openFormStatus: boolean
  is_load: boolean
  item_name?: string
  closeFunction: () => void
  objectsUsed: string
  userLeader?: UserAllList[]
  callBackPermissionCheckBox: (value: any, type: string) => void
  callBackCurrentPermissionCheckBox: (value: any, type: string) => void
}

function ProjectPermissionsFrom({
  openFormStatus,
  item_name,
  closeFunction,
  is_load,
  userLeader,
  callBackPermissionCheckBox,
  callBackCurrentPermissionCheckBox,
  objectsUsed
}: ComponentProps) {
  console.log(123, item_name, userLeader)

  // Get the default unchanged configuration
  const globalCoreSetting = GlobalCoreSetting()
  // Define all the customer lists that can be selected
  const [customerList, setCustomerList] = useState<SelectRole[]>([])

  const [current_all, setCurrentProjectAllFlag] = useState(false)

  const [openForm, { open: openModalFunction, close: closeModalFunction }] = useDisclosure(false)
  // Get translation public configuration information
  const word_translation = WordTranslationComponent()

  const [initialOrderValues, setInitialOrderValues] = useState<initialValuesCheckboxInterface[]>([])

  const [initialTaskValues, setInitialTaskValues] = useState<initialValuesCheckboxInterface[]>([])

  const [initialProjectValues, setInitialProjectValues] = useState<initialValuesCheckboxInterface[]>([])

  const [initialValues, setInitialValues] = useState<initialValuesCheckboxInterface[]>([])

  useEffect(() => {
    openFormStatus ? openModalFunction() : closeModalFunction()
    openFormStatus && getPermissionActionAndIdentifier()
  }, [openFormStatus])

  const getPermissionActionAndIdentifier = async () => {
    try {
      const response = await requestPermissionProvision()
      const result = response.data
      // Check if the response code is 200 (OK)
      if (result.code === 200) {
        setInitialOrderValues(result.data.order ? result.data.order : [])
        setInitialTaskValues(result.data.task ? result.data.task : [])
        setInitialProjectValues(result.data.project ? result.data.project : [])
      }
    } catch (error) {
      // Handle errors by returning an empty array
      console.error('Error fetching user data:', error)
    }
  }

  useEffect(() => {
    getCustomerList()
  }, [])

  // Close component
  const closeBtnModalFunction = () => {
    closeModalFunction()
    closeFunction()
  }

  // Click save
  const applyBtnFunction = () => {
    closeFunction()
  }

  const [values, handlers] = useListState(initialValues)

  const allChecked = values.every(value => value.checked)
  const indeterminate = values.some(value => value.checked) && !allChecked

  const items = values.map((value, index) => (
    <Checkbox
      ml={20}
      label={<TableTextInputTitle class_name="assignment_permission_2" title={value.label} />}
      key={value.key}
      checked={value.checked}
      onChange={event => handlers.setItemProp(index, 'checked', event.currentTarget.checked)}
    />
  ))

  const getCustomerList = async () => {
    is_load && setCustomerList(await getCustomerListGeneralFunction(''))
  }

  return (
    <Modal
      opened={openForm}
      onClose={closeBtnModalFunction}
      closeOnClickOutside={false}
      title={<ModalTitleText title={word_translation.project_leader_role_editing} />}
      padding="xs"
      size="35%"
      radius={10}
    >
      <Box mx={0} py={15} className="border-bt-1 ">
        <Grid>
          <Grid.Col span={12}>
            <Group position="left" py={10} px={20}>
              <TableTextInputTitle class_name="" title={'Project Leader:'} />
              <Box w={110}>
                <Tooltip.Group openDelay={300} closeDelay={100}>
                  <Avatar.Group spacing="sm">
                    {userLeader?.map((item, index) => (
                      <Group>
                        <Box>
                          <Text align="right" fw={600} size={14} lh={1.2}>
                            {item.username}
                          </Text>
                          <Text align="right" fw={400} size={12} lh={1}>
                            {item.email}
                          </Text>
                        </Box>
                        <Tooltip position="bottom" label={item.username} withArrow>
                          <Avatar src={apiUrl.fileSystemDomain + item.avatar} radius="xl" />
                        </Tooltip>
                      </Group>
                    ))}
                  </Avatar.Group>
                </Tooltip.Group>
              </Box>
            </Group>
          </Grid.Col>
        </Grid>
      </Box>
      <Grid mt={5}>
        <Grid.Col span={12}>
          <Group position="apart" mx={30} align={'center'}>
            <TableTextInputTitle
              class_name="assignment_permission_title"
              title={item_name ? item_name : 'Current Projects'}
            />
            <Checkbox
              checked={current_all}
              label={
                <TableTextInputTitle
                  class_name="assignment_permission_2 assignment_permission_align_center"
                  title="Select All "
                />
              }
              onChange={event => setCurrentProjectAllFlag(event.currentTarget.checked)}
            />
          </Group>
        </Grid.Col>
        <Grid.Col span={12}>
          <Box ml={10}>
            <Grid>
              <Grid.Col span={6}>
                <PermissionCheckBox
                  current_all={current_all}
                  list={initialProjectValues}
                  identifire="Project"
                  callBack={callBackCurrentPermissionCheckBox}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <PermissionCheckBox
                  current_all={current_all}
                  list={initialTaskValues}
                  identifire="Task"
                  callBack={callBackCurrentPermissionCheckBox}
                />
              </Grid.Col>

              <Grid.Col span={6}>
                <PermissionCheckBox
                  current_all={current_all}
                  list={initialOrderValues}
                  identifire="Order"
                  callBack={callBackCurrentPermissionCheckBox}
                />
              </Grid.Col>
            </Grid>
          </Box>
        </Grid.Col>
      </Grid>
      <Divider my="sm" />
      <Group position="right" my={5}>
        <UniversalButton
          btn_icon={<IconCircleX size={16} />}
          btn_bg_color="red"
          btn_text={word_translation.cancel}
          btn_size="xs"
          callBack={closeBtnModalFunction}
        />
        <UniversalButton
          btn_icon={<IconCircleCheck size={16} />}
          btn_text={word_translation.add}
          btn_size="xs"
          callBack={applyBtnFunction}
        />
      </Group>
    </Modal>
  )
}
export default ProjectPermissionsFrom
