import {
  Box,
  Button,
  Card,
  Badge,
  Code,
  Grid,
  LoadingOverlay,
  MultiSelect,
  Select,
  Text,
  TextInput,
  Group,
  ActionIcon,
  Title,
  Textarea,
  Table,
  Divider,
  Tooltip,
  Avatar,
  Checkbox,
  Modal,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { hasLength, isNotEmpty, useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import {
  IconAddressBookOff,
  IconArrowBadgeDownFilled,
  IconArrowBadgeUpFilled,
  IconCalendar,
  IconCheck,
  IconCircleCheckFilled,
  IconCircleKey,
  IconMoneybag,
  IconPlus,
  IconSquarePlus,
  IconTextPlus,
  IconTrash,
  IconUserPlus,
  IconX,
} from "@tabler/icons-react";
import { FormEvent, forwardRef, useEffect, useRef, useState } from "react";
import {
  regGetProduct,
  regGetProductId,
  regOrder,
  regTimeViolation,
} from "../../api";
import {
  HintInfo,
  JudgmentTimeThreshold,
  TimeHHSS,
  convertDateFormat,
  formatDate,
  formatDateUpgraded,
  formatTimeHHSS,
} from "../../utils/function";
import SaveBottom from "../../ttcomponents/Button/SaveBtn";
import { getCalculateEstimated } from "../../api/common_request";
import Message from "../../ttcomponents/Message/Message";
import { ProductOrder, Purchase } from "../../public/svg/ProductOrder";
import { IProductResponse, Product } from "../../interface/IProduct";
import { useDisclosure } from "@mantine/hooks";
import localstorageUnits from "../../utils/localstorageUnits";
import { useIntl } from "react-intl";
import CustomerFrom from "../assignments/customer/customer_form";

interface IOrderFromProps {
  callBack: () => void;
  [key: string]: any;
}

interface IProduct {
  product_name: string;
  product_id: string;
  order_quantity: number | string;
}

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  image: string;
  label: string;
  value: string;
  supplier_name: string;
  unit_price: string;
  type: string;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  (
    { image, label, unit_price, supplier_name, type, ...others }: ItemProps,
    ref
  ) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        {image}
        <div>
          <Text size="sm">
            {label}{" "}
            {type == "1" ? (
              <Badge color="green">New</Badge>
            ) : type == "2" ? (
              <Badge color="red">Second -hand</Badge>
            ) : (
              <Badge color="">Inquiry</Badge>
            )}
          </Text>
          <Text size="xs" opacity={0.65}>
            {unit_price}
          </Text>
          <Text size="xs" opacity={0.65}></Text>
        </div>
      </Group>
    </div>
  )
);

export default function OrderFrom({
  callBack,
  projectData,
  customerlist,
  remainingResourcePorjectData,
  userlist,
}: IOrderFromProps) {
  // Use the useIntl hook to access the intl object
  const intl = useIntl();

  const messageTo = intl.formatMessage({
    id: "To",
    defaultMessage: "To",
  });

  const messageOrderFrom = intl.formatMessage({
    id: "The Order cycle starts from",
    defaultMessage: "The Order cycle starts from",
  });

  const messageRemainingTime = intl.formatMessage({
    id: "remaining time",
    defaultMessage: "remaining time",
  });

  const messageRemainingBudget = intl.formatMessage({
    id: "remaining budget",
    defaultMessage: "remaining budget",
  });

  const messageClickToAddProducts = intl.formatMessage({
    id: "click to add products",
    defaultMessage: "click to add products",
  });

  const messageClickToCancelProducts = intl.formatMessage({
    id: "Cancel add products",
    defaultMessage: "Cancel add products",
  });

  const taskModeFlag = localstorageUnits.getSystem().task_mode_flag;

  // 模式类型
  const ProjectOrderTaskModel = taskModeFlag == 1;
  const ProjectTaskModel = taskModeFlag == 2;
  const OrderModel = taskModeFlag == 3;
  const ProjectOrder = taskModeFlag == 4;
  const OrderNotaskModel = taskModeFlag == 5;

  const formRef = useRef<HTMLFormElement>(null);
  const [newCustomerId, setNewCustomerId] = useState("");

  const [productID, setProductID] = useState("");
  const [productName, setProductName] = useState("");
  const [productTip, setProductTip] = useState(messageClickToAddProducts);
  const [order_quantity, setQuantity] = useState("");

  const [data, setData] = useState<ItemProps[] | undefined>([]);

  const [productList, setProductList] = useState<IProductResponse>({
    total: 0,
    data: [], // Use the data property from the first item in the mock data array
  });
  const [service_charge, setChecked] = useState(false);
  const [internal, setInternal] = useState(false);
  const [teamLeaderValue, setTeamLeaderValue] = useState<string[]>([]);
  //加载中状态
  const [loading, setLoading] = useState(false);
  const form = useForm({
    initialValues: {
      project_id: projectData.id_project,
      order_name_id: "",
      order_no: "",
      order_name: "",
      start_date: new Date(),
      end_date: "",
      customer_id: "",
      budget: "0.00",
      time_consumtion: "",
      service_charge: "1",
      time_estimate: "00:00",
      order_team_leaders: [],
      product: [] as IProduct[],
    },

    validate: {
      order_no: hasLength(
        { min: 2, max: 250 },
        "order_no must be 2-250 characters long"
      ),
      order_name: hasLength(
        { min: 2, max: 250 },
        "order name must be 2-250  characters long"
      ),
      // time_consumtion: (value) => validateTimeConsumtion(value),
    },
  });
  const setTeamLeaderValueHandle = (value: React.SetStateAction<string[]>) => {
    // setTeamLeaderValue(value);
    form.setFieldValue("order_team_leaders", value as []);
  };

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Verification form
    const valid = await form.validate();
    // The start time of the setting cannot exceed the initial time of the system settings
    if (JudgmentTimeThreshold(form.values.start_date) === false) {
      return false;
    }
    if (!valid.hasErrors) {
      let originalData = {
        project_id: form.values.project_id,
        order_name_id: form.values.order_name_id,
        order_no: form.values.order_no,
        order_name: form.values.order_name,
        budget: form.values.budget,
        time_estimate: form.values.time_estimate,
        service_charge: service_charge == true ? 1 : 0,
        is_internal: internal == true ? 1 : 0,
        order_team_leaders: form.values.order_team_leaders,
        start_date: form.values.start_date.toISOString().split("T")[0],
        end_date: form.values.end_date
          ? new Date(form.values.end_date).toISOString().split("T")[0]
          : "",
        time_consumtion: form.values.time_consumtion,
        product: form.values.product,
        customer_id: form.values.customer_id,
      };

      if (OrderModel) {
        delete originalData.project_id;
      }

      setLoading(true);
      const response = await regOrder(originalData, "POST");
      setLoading(false);
      const result = response.data;
      if (result.code === 200) {
        callBack();
        notifications.show({
          title: result.code,
          color: "green",
          icon: <IconCheck />,
          message: result.msg,
        });
      } else {
        setLoading(false);
        notifications.show({
          title: result.code,
          color: "red",
          icon: <IconX />,
          message: result.msg,
        });
      }
    }
  };

  // State to control the visibility of the message
  const [showMessage, setShowMessage] = useState(false);

  const [addproductStatus, setAddproductStatus] = useState(false);

  // State to store the message content
  const [showMessageStr, setShowMessageStr] = useState("");

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = TimeHHSS(event);
    form.setFieldValue("time_estimate", value);
  };

  const checkTime = async (values: string) => {
    const value = convertDateFormat(values);
    if (value) {
      form.setFieldValue("time_estimate", convertDateFormat(values));
      const responseRestTimeCheck = await regTimeViolation({
        day: formatDate(value),
      });
      if (responseRestTimeCheck.data.code != 200) {
        setShowMessage(true);
        setShowMessageStr(responseRestTimeCheck.data.msg);
        setTimeout(() => {
          setShowMessage(false); //Hidden prompt message after 3 seconds
        }, 3000);
      } else {
        setShowMessage(false);
      }
    }

    //

    if (form.values.end_date && form.values.start_date) {
      const result = await getCalculateEstimated({
        start_time: formatDateUpgraded(form.values.start_date, "YYYY-MM-DD"),
        end_time: formatDateUpgraded(
          new Date(form.values.end_date),
          "YYYY-MM-DD"
        ),
      });
      const calculate_estimate = result.time_estimated as string;
      form.setFieldValue("time_estimate", formatTimeHHSS(calculate_estimate));
    }
  };

  const addProduct = () => {
    // Check if product_id is empty
    if (!productName) {
      notifications.show({
        color: "red",
        icon: <IconX />,
        message: "Please select a product",
      });
      return;
    }

    // Check if order_quantity is empty
    if (!order_quantity) {
      notifications.show({
        color: "red",
        icon: <IconX />,
        message: "Please enter the quantity",
      });
      return;
    }

    // Check if the product name is empty
    if (!productName) {
      notifications.show({
        color: "red",
        icon: <IconX />,
        message: "Please enter the product name",
      });
      return;
    }

    // Check if the product already exists
    const existingContact = form.values.product.find(
      (c) => c.product_name === productName
    );

    if (existingContact) {
      // Show error message
      notifications.show({
        color: "yellow.7",
        icon: <IconX />,
        message: "A product with the same name already exists",
      });
      return;
    }

    // Create a product object
    let productItem = {
      product_id: productID,
      product_name: productName,
      order_quantity: order_quantity,
    };

    // Add the product object to the form
    form.setFieldValue("product", [productItem, ...form.values.product]);
    setProductID("");
    setProductName("");
    setQuantity("");
  };

  const rows = form.values.product.map((element, index) => (
    <tr key={index}>
      <td>{element.product_name}</td>
      <td>{element.order_quantity}</td>
      <td>
        <ActionIcon
          onClick={() => {
            delHandle(index);
          }}
        >
          <IconTrash size="1rem" color="red" stroke={1.5} />{" "}
        </ActionIcon>
      </td>
    </tr>
  ));

  //Delete contact
  const delHandle = (indexs: number) => {
    const newData = form.values.product.filter(
      (item, index) => index !== indexs
    );
    form.setFieldValue("product", newData);
  };

  //Delete contact
  const setProductNameFunction = async (string: string) => {
    const existResult =
      data && data.filter((item, index) => item.value == string.toString());
    if (existResult?.length == 0) {
      // const responseProduct = await regGetProductId({ product_name: string });
      // if (responseProduct.data.code == 200) {
      //   console.log(typeof responseProduct.data.data.product_id);
      //   setProductID(responseProduct.data.data.product_id);
      // }
      setProductName(string);
    } else {
      // 使用 filter 方法过滤出 product_id 为 targetProductId 的信息
      const filteredData = data?.filter((item) => item.value === string);

      setProductID(string);
      if (filteredData && filteredData.length > 0) {
        console.log(filteredData[0]);
        setProductName(filteredData[0].label.split("---")[0].trim());
      }
    }
  };

  // Define the selection of customers to search for functions and assign a value to SearchCustomerValue
  const [searchCustomerValue, onSearchCustomerChange] = useState("");

  const [createCustomerStatus, { open: openCustomer, close: closeCustomer }] =
    useDisclosure(false);
  const createCustomer = () => {
    openCustomer();
  };
  //Delete contact
  const openAddProduct = async () => {
    if (addproductStatus) {
      setAddproductStatus(false);
      setProductTip(messageClickToAddProducts);
      form.setFieldValue("product", []);
    } else {
      setProductTip(messageClickToCancelProducts);
      setAddproductStatus(true);

      if (data?.length == 0) {
        setLoading(true);
        const responseProduct = await regGetProduct({});
        setLoading(false);
        if (responseProduct.data.code == 200) {
          // Assembly product
          const products = responseProduct.data.data.data.map(
            (item: Product) => ({
              image: <ProductOrder />,
              label: item.product_name + "---" + item.supplier_name,
              value: item.product_id,
              type: item.type,
              unit_price:
                item.unit_price != null
                  ? intl.formatMessage({
                      id: "Unit Price",
                      defaultMessage: "Unit Price",
                    }) +
                    ": " +
                    item.unit_price
                  : "This product has not been purchased in the warehouse",
            })
          );
          setData(products);
        }
      }
    }
  };

  const createrCustomerBack = (id: string) => {
    callBack();
    // 设置新添加的客户选中
    setNewCustomerId(id);
    closeCustomer();
    form.setFieldValue("customer_id", id as string);
  };

  // 监听新用户添加-直接选中
  useEffect(() => {
    if (newCustomerId) {
      const find = customerlist.find((item: { value: string }) => {
        return item.value == newCustomerId;
      });
      if (find) {
        onSearchCustomerChange(find.label);
      }
    }
  }, [customerlist]);

  return (
    <form onSubmit={handleFormSubmit} ref={formRef}>
      <LoadingOverlay visible={loading} overlayBlur={2} />
      <Box mt={10}>
        <Card>
          <Grid>
            <Grid.Col span={7}>
              <TextInput
                withAsterisk
                labelProps={{ className: "input_title" }}
                description=""
                {...form.getInputProps("order_name")}
                label={intl.formatMessage({
                  id: "order_name",
                  defaultMessage: "order_name",
                })}
                placeholder={intl.formatMessage({
                  id: "Input",
                  defaultMessage: "Input",
                })}
              />
              <MultiSelect
                mt={20}
                labelProps={{ className: "input_title" }}
                label={
                  <Group>
                    <Text>
                      {" "}
                      {intl.formatMessage({
                        id: "Order Team Leaders",
                        defaultMessage: "Order Team Leaders",
                      })}{" "}
                      <span
                        className={
                          "mantine-103svbs mantine-InputWrapper-required mantine-MultiSelect-required"
                        }
                        aria-hidden="true"
                      >
                        *
                      </span>
                    </Text>
                    <ActionIcon size={30}>
                      <IconCircleCheckFilled
                        color="blue"
                        width={20}
                        height={20}
                      />
                    </ActionIcon>
                  </Group>
                }
                searchable
                placeholder={intl.formatMessage({
                  id: "Select Leader",
                  defaultMessage: "Select Leader",
                })}
                onChange={setTeamLeaderValueHandle}
                data={userlist}
              />
              <TextInput
                withAsterisk
                mt={20}
                labelProps={{ className: "input_title" }}
                {...form.getInputProps("order_no")}
                label={intl.formatMessage({
                  id: "Customer Order Number",
                  defaultMessage: "Customer Order Number",
                })}
                placeholder={intl.formatMessage({
                  id: "Input",
                  defaultMessage: "Input",
                })}
              />
              <Grid mt={20}>
                <Grid.Col span={6}>
                  <DateInput
                    valueFormat="DD/MM/YYYY"
                    size="xs"
                    {...form.getInputProps("start_date")}
                    icon={<IconCalendar size="1.1rem" stroke={1.5} />}
                    label={intl.formatMessage({
                      id: "Start Time",
                      defaultMessage: "Start Time",
                    })}
                    placeholder={intl.formatMessage({
                      id: "Start Time",
                      defaultMessage: "Start Time",
                    })}
                    onBlur={(event) => checkTime(event.target.value)}
                    maw={400}
                    mx="auto"
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <DateInput
                    valueFormat="DD/MM/YYYY"
                    {...form.getInputProps("end_date")}
                    size="xs"
                    icon={<IconCalendar size="1.1rem" stroke={1.5} />}
                    label={intl.formatMessage({
                      id: "End Time",
                      defaultMessage: "End Time",
                    })}
                    placeholder={intl.formatMessage({
                      id: "End Time",
                      defaultMessage: "End Time",
                    })}
                    minDate={form.values.start_date}
                    onBlur={(event) => checkTime(event.target.value)}
                    maw={400}
                    mx="auto"
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <Text color="red" size={12}>
                    {remainingResourcePorjectData &&
                      messageOrderFrom +
                        " " +
                        remainingResourcePorjectData?.start_date +
                        " " +
                        messageTo +
                        " " +
                        remainingResourcePorjectData?.end_date}
                  </Text>
                </Grid.Col>
              </Grid>
            </Grid.Col>

            <Grid.Col span={4} ml={30}>
              {(OrderModel || OrderNotaskModel) && (
                <Select
                  placeholder={intl.formatMessage({
                    id: "select_customer",
                    defaultMessage: "Select Customer",
                  })}
                  searchValue={searchCustomerValue}
                  labelProps={{ className: "input_title" }}
                  label={
                    <Group style={{ gap: 0 }}>
                      <Text>Customer </Text>
                      <ActionIcon>
                        <IconUserPlus
                          style={{ cursor: "hand" }}
                          onClick={createCustomer}
                          size={20}
                        />{" "}
                      </ActionIcon>
                    </Group>
                  }
                  onSearchChange={onSearchCustomerChange}
                  defaultValue={searchCustomerValue}
                  searchable
                  nothingFound={intl.formatMessage({
                    id: "No options",
                    defaultMessage: "No options",
                  })}
                  onChange={(value) =>
                    form.setFieldValue("customer_id", value as string)
                  }
                  data={customerlist}
                />
              )}
              <TextInput
                labelProps={{ className: "input_title" }}
                description=""
                mt={15}
                {...form.getInputProps("budget")}
                label={
                  <>
                    {intl.formatMessage({
                      id: "Budget",
                      defaultMessage: "Budget",
                    })}{" "}
                    <Text color="red" size={12}>
                      {remainingResourcePorjectData &&
                        messageRemainingBudget +
                          ": " +
                          remainingResourcePorjectData?.remaining_budget}
                    </Text>
                  </>
                }
                placeholder={intl.formatMessage({
                  id: "Input",
                  defaultMessage: "Input",
                })}
              />

              <TextInput
                mt={20}
                labelProps={{ className: "input_title" }}
                description=""
                label={
                  <>
                    {intl.formatMessage({
                      id: "Estimated time",
                      defaultMessage: "Estimated time",
                    })}{" "}
                    <Text color="red" size={12}>
                      {remainingResourcePorjectData &&
                        messageRemainingTime +
                          ": " +
                          remainingResourcePorjectData?.remaining_time}
                    </Text>
                  </>
                }
                onBlur={handleBlur}
                value={form.values.time_estimate}
                placeholder={intl.formatMessage({
                  id: "Input",
                  defaultMessage: "Input",
                })}
                onChange={(event) =>
                  form.setFieldValue("time_estimate", event.target.value)
                }
                // {...form.getInputProps('time_estimate')}
              />
            </Grid.Col>
          </Grid>

          <Divider
            my="xs"
            variant="dashed"
            labelPosition="center"
            label={
              <>
                <ActionIcon onClick={() => openAddProduct()}>
                  {/* <Tooltip label={productTip}> */}
                  {addproductStatus ? (
                    <IconArrowBadgeUpFilled size={12} stroke={1.5} />
                  ) : (
                    <IconArrowBadgeDownFilled size={12} stroke={1.5} />
                  )}
                  {/* </Tooltip> */}
                </ActionIcon>
                <Box ml={5}>{productTip}</Box>
              </>
            }
          />
          {addproductStatus ? (
            <>
              {" "}
              <Title my={5} order={6}>
                {" "}
                {intl.formatMessage({
                  id: "Add Products",
                  defaultMessage: "Add Products",
                })}{" "}
              </Title>
              <Box className="border border-1" p={10}>
                <Grid gutter={4} grow>
                  {OrderNotaskModel || ProjectOrder ? null : (
                    <Grid.Col my={10} span={3}>
                      <Checkbox
                        checked={service_charge}
                        label={intl.formatMessage({
                          id: "Service Fee",
                          defaultMessage: "Service Fee",
                        })}
                        onChange={(event) =>
                          setChecked(event.currentTarget.checked)
                        }
                      />
                    </Grid.Col>
                  )}

                  <Grid.Col my={10} span={9}>
                    <Checkbox
                      checked={internal}
                      label={intl.formatMessage({
                        id: "Internal order",
                        defaultMessage: "Internal order",
                      })}
                      onChange={(event) =>
                        setInternal(event.currentTarget.checked)
                      }
                    />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <Grid gutter={6}>
                      <Grid.Col span={6}>
                        <Select
                          placeholder={intl.formatMessage({
                            id: "Product Name",
                            defaultMessage: "Product Name",
                          })}
                          itemComponent={SelectItem}
                          data={data as []}
                          searchable
                          creatable
                          maxDropdownHeight={400}
                          onChange={(event) =>
                            setProductNameFunction(event as string)
                          }
                          nothingFound="Nobody here"
                          getCreateLabel={(query) => `+ Create New ${query}`}
                          onCreate={(query) => {
                            const item = {
                              image: <Purchase />,
                              label: query,
                              value: query,
                              unit_price: "warehouse needs purchasing",
                            };
                            setData((current) => [...(current as any), item]);
                            return item;
                          }}
                          filter={(value, item) =>
                            (item.label &&
                              item.label
                                .toLowerCase()
                                .includes(value.toLowerCase().trim())) ||
                            item.unit_price
                              .toLowerCase()
                              .includes(value.toLowerCase().trim())
                          }
                        />
                      </Grid.Col>
                      {/* <Grid.Col span={3}>
                    <TextInput
                      placeholder={intl.formatMessage({
  id: "Product Name",
  defaultMessage: "Product Name",
})}
                      radius="md"
                      onChange={(event) => setProductName(event.currentTarget.value)}
                    />
                  </Grid.Col> */}
                      <Grid.Col span={3}>
                        <TextInput
                          placeholder={intl.formatMessage({
                            id: "Quantity",
                            defaultMessage: "Quantity",
                          })}
                          onChange={(event) =>
                            setQuantity(event.currentTarget.value)
                          }
                        />
                      </Grid.Col>
                      <Grid.Col span={2}>
                        <Button
                          onClick={addProduct}
                          leftIcon={<IconTextPlus />}
                        >
                          add
                        </Button>
                      </Grid.Col>
                    </Grid>
                  </Grid.Col>
                </Grid>

                {form.values.product.length > 0 ? (
                  <Table>
                    <thead>
                      <tr>
                        <th>
                          {intl.formatMessage({
                            id: "Product Name",
                            defaultMessage: "Product Name",
                          })}
                        </th>
                        <th>
                          {intl.formatMessage({
                            id: "Quantity",
                            defaultMessage: "Quantity",
                          })}
                        </th>
                        <th>
                          {intl.formatMessage({
                            id: "Option",
                            defaultMessage: "Option",
                          })}
                        </th>
                      </tr>
                    </thead>
                    <tbody>{rows}</tbody>
                  </Table>
                ) : (
                  <Divider
                    my="xs"
                    c="#ccc"
                    label={intl.formatMessage({
                      id: "Please add products",
                      defaultMessage: "Please add products",
                    })}
                    labelPosition="center"
                  />
                )}
              </Box>
            </>
          ) : null}

          <Group position="right">
            <SaveBottom formRef={formRef} />
          </Group>
        </Card>
      </Box>
      <Code block mt={5}>
        {JSON.stringify(form.values, null, 2)}
      </Code>

      <Modal
        size="md"
        opened={createCustomerStatus}
        onClose={closeCustomer}
        title={intl.formatMessage({
          id: "Create Customer",
          defaultMessage: "Create Customer",
        })}
      >
        <CustomerFrom callBack={createrCustomerBack}></CustomerFrom>
      </Modal>
      {showMessage && (
        <Message content={showMessageStr} duration={3000} color="green" />
      )}
    </form>
  );
}
