import {
  MantineProvider,
  ColorSchemeProvider,
  ColorScheme,
  Button,
  MantineThemeBase,
  Text,
  GlobalStyles,
  createStyles,
  getBreakpointValue,
  em
} from '@mantine/core'
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom'
import Login from './pages/core/login/login'
import './App.css'
import { Notifications, notifications } from '@mantine/notifications'
import { useHotkeys, useLocalStorage } from '@mantine/hooks'
import Dashboard from './pages/dashboard/dashboard'
// import Statistics from "./pages/statistics/statistics";
import Admin from './pages/admin/admin'
import { ModalsProvider } from '@mantine/modals'
import ProjectsManage from './pages/projects/projects_manage'
import ProjectStatus from './pages/project_status/project_status'
import OrderName from './pages/assignments/order_name/order_name'
import ProjectCreate from './pages/assignments/assignments/create_assignments'
import ProfileSettings from './pages/core/profiles/profile_settings'
import TimeTrack from './pages/assignments/time_sheet/home/timeTrack'
import Timer from './test'
import TaskCategory from './pages/projects/task_category/task_category_mange'
import TaskOnly from './pages/projects/task_only/task_only'
import { IntlProvider, FormattedMessage } from 'react-intl'
import messages from './utils/language_pack'
import localstorageUnits from './utils/localstorageUnits'
import Reports from './pages/reports/employeesReports/reports'
import ReportsProjectAll from './pages/reports/projectReport/reports_project'
import ReportsFilter from './pages/reports/report/report'
import CustomerCategory from './pages/customer_category/customer_category_mange'
import memoryUtils from './utils/memoryUtils'
import { IconX } from '@tabler/icons-react'
import FilterReportStastic from './pages/reports/report_all/filter_statistics'
import TaskProject from './pages/projects/task_only/task_only_project/task_only_project'
import UserReportStastic from './pages/reports/report_all/user_statistics'
import ProjectReportStastic from './pages/reports/report_all/project_statistics'
import ReportCustom from './pages/reports/report_custom/report_custome'
import LeaveRequest from './pages/leave_request/apply_holidays/leaveIndex'
import LeaveManger from './pages/leave_request/leave_manger/leave_manger'
import SickLeave from './pages/leave_request/sick_leave'
import ApprovalTimeTracker from './pages/time_master/approval/approval'
import SupplierIndex from './pages/inventory/supplier/supplier_index'
import ProductIndex from './pages/inventory/product/product_index'
import ProductProcurementIndex from './pages/inventory/purchasing/purchasing'
import ReturnReasonIndex from './pages/inventory/return_product/return_reason/return_reason'
import StoreHouseIndex from './pages/inventory/storehouse/store_house_index'
import OrderProductIndex from './pages/inventory/customer_order/product_procurement/product_requirments'
import DeliveredManagementIndex from './pages/inventory/storehouse/delivered_management/delivered_management'
import RecyclingCompanyIndex from './pages/inventory/recycling_ company/recycling_ company_index'
import ProductRequirmentsIndex from './pages/inventory/customer_order/product_procurement/product_requirments'
import OrderOnlyIndex from './pages/projects/order_only/order_only_index'
import OrderCreateIndex from './pages/projects/order_create'
import { useEffect, useState } from 'react'
import { SystemInit } from './utils/function'
import { refreshUsepPermission, regSystemConfig, requestMenu } from './api'
import OnBoarding from './pages/boarding/on_boarding/on_boarding'
import NewBoarding from './pages/boarding/new_boarding/new_boarding'
import OffBoarding from './pages/boarding/off_bording/off_boarding'
import BoardingArchive from './pages/boarding/boarding_archive/boarding_archive'
import CustomAttrbutesBoarding from './pages/boarding/custom_attributes/custom_attrbutes'
import UserMg from './pages/core/user/user'
import UserChangeLogs from './pages/core/user/user_change_log'
import UserRole from './pages/core/user/user_role/user_role'
import CustomerStack from './pages/assignments/customer/customer'
import ContactRoleIndex from './pages/assignments/contact role/contact_role'
import Settings from './pages/core/settings/settings'
import CreateCustomer from './pages/assignments/customer/customer_create'
import CreateAssignments from './pages/assignments/assignments/create_assignments'
import EmployeeRole from './pages/assignments/permission/permission_role/permission_role'
import AssignmentOverview from './pages/assignments/assignments'
import PermissionIdentifier from './pages/assignments/permission/permission_identifier/permission_identifier'
import PermissionAction from './pages/assignments/permission/permission_action/permission_action'
import PermissionSet from './pages/assignments/permission/permission_set/permission_set'
import OvertimeReport from './pages/report/overtime_report'
import EmployeeOvertimeReport from './pages/report/overtime_report/employee'
import EmployeeTimeReportRecord from './pages/report/employee_time_record/employee_time_record'
import EmployeeNoTimeReportRecord from './pages/report/employee_time_record/employee_no_time_record'
import StudentTimeReportRecord from './pages/report/employee_time_record/student_time_report'
import FejpTimeReportRecord from './pages/report/employee_time_record/fejp_time_report'
import { getCompanyGeneralFunction } from './api/common_request'
import { RootState } from './state_management/store'
import { useDispatch, useSelector } from 'react-redux'
import { setMenu, setMenuFromApi } from './state_management/features/menu/menuSlice'
import LeaveCotegory from './pages/leave_request/leave_category'
import CorePermissionIndex from './pages/core/settings/permission_action'
import ProjectItemReports from './pages/report/project/project_item_reports'
import LogSettings from './pages/core/settings/log/log_settings'

export default function App() {
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: 'mantine-color-scheme',
    defaultValue: 'light',
    getInitialValueInEffect: true
  })

  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'))

  useHotkeys([['mod+J', () => toggleColorScheme()]])

  const SearchValue = useSelector((state: RootState) => state.search.searchValue)
  const [systemLoaded, setSystemLoaded] = useState(false)
  /**
   *Set the default languagee default language
   */
  const [defaultLang, setDefaultLang] = useState<keyof typeof messages>('de-DE')

  // const [defaultLang, setDefaultLang] = useState<keyof typeof messages>('zh-CN');
  const getSystemFunction = async () => {
    try {
      const system_result = await regSystemConfig() // Call the regsystemConfig function to get the system configuration results
      console.log(system_result)

      localstorageUnits.saveSystem(system_result.data.data) // Save the system configuration results in the local storage
      // const MenuInfo = await requestMenu(); // Call the regsystemConfig function to get the system configuration results
      // console.log(MenuInfo.data.data)

      if (localstorageUnits.getSystem() && localstorageUnits.getSystem().default_lang) {
        //Determine whether there are system configuration and default language in local storage
        setDefaultLang(localstorageUnits.getSystem().default_lang) //Set the default language
      }
      if (!system_result) {
        notifications.show({
          title: 'System Init Error',
          color: 'red',
          icon: <IconX />,
          message: 'System Init Error'
        })
      }
      if (userExists.username) {
        try {
          const premission_response = await refreshUsepPermission() // 调用 refreshUsepPermission 函数来获取系统配置结果

          var premission = premission_response.data
          if (premission.code === 200) {
            localstorageUnits.saveMenu(premission.data.menu)
            localstorageUnits.savePermissionIdentifier(premission.data.permissionIdentifier_actions)
          }
        } catch (error) {
          console.error('获取系统功能时出错:', error)
        }
      }
      setSystemLoaded(true)
    } catch (error) {
      console.error('Error when obtaining the system function:', error)
    }
  }

  const userExists = memoryUtils.user

  const permissionRefesh = async () => {}

  /**
   * Call the GetSystemFunction method when the component is mounted to obtain the system function list
   */
  useEffect(() => {
    permissionRefesh()
    getSystemFunction() //Call the method of obtaining the system function list
  }, [])

  useEffect(() => {
    // 刷新页面
    // window.location.reload();
  }, [defaultLang])

  return (
    <>
      {systemLoaded ? (
        <IntlProvider locale="en" messages={messages[defaultLang]}>
          <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
            <ModalsProvider labels={{ confirm: 'Submit', cancel: 'Cancel' }}>
              <MantineProvider
                withGlobalStyles
                withNormalizeCSS
                theme={{
                  colorScheme: colorScheme,
                  fontFamily: 'Inter',
                  colors: {
                    'customize-blue': [
                      '#49a3f2',
                      '#43b2f2',
                      '#3dc2f2',
                      '#37d2f2',
                      '#31e2f2',
                      '#2bf1f2',
                      '#25f1e8',
                      '#1fe1df',
                      '#19d1d6',
                      '#13c1cd'
                    ],
                    // It's just a font, and the use of background floating effects is prohibited
                    'customize-gray': [
                      '#212B36',
                      '#ACB6BE',
                      '#ffffff',
                      '#B5B5B5',
                      '#8D8D8D',
                      '#868E96',
                      '#40C057',
                      '#495057',
                      '#3E5574',
                      '#13c1cd'
                    ],
                    customize: [
                      '#49A3F1',
                      '#5F5F64',
                      '#ffffff',
                      '#000000',
                      '#637381',
                      '#808080',
                      '#3056D3',
                      '#292D32',
                      '#FC5F05',
                      '#212B36'
                    ],
                    customizegreen: [
                      '#40C057',
                      '#5F5F64',
                      '#ffffff',
                      '#000000',
                      '#637381',
                      '#808080',
                      '#3056D3',
                      '#292D32',
                      '#FC5F05',
                      '#212B36'
                    ],
                    // It's just a font, and the use of background floating effects is prohibited 1
                    projectstatus: [
                      '#4ec762',
                      '#fab005',
                      '#ff922b',
                      '#ff6b6bc',
                      '#339af0c',
                      '#adb5bd',
                      '#3056D3',
                      '#292D32',
                      '#FC5F05',
                      '#212B36'
                    ],
                    customizebg: [
                      '#F9FAFB',
                      '#E7F5FF',
                      '#228BE6',
                      '#FFF4E6',
                      '#FD7E14',
                      '#30D371',
                      '#bbbfc3',
                      '#292D32',
                      '#FC5F05',
                      '#212B36'
                    ],
                    customizebgs: [
                      '#F9FAFB',
                      '#F9FAFB',
                      '#F9FAFB',
                      '#F9FAFB',
                      '#F9FAFB',
                      '#F9FAFB',
                      '#F9FAFB',
                      '#F9FAFB',
                      '#F9FAFB',
                      '#F9FAFB'
                    ]
                  },
                  breakpoints: {
                    // xs: '30em',
                    // sm: '48em',
                    // md: '64em',
                    // lg: '74em',
                    // xl: '90em',
                  }
                }}
              >
                <Notifications />
                <BrowserRouter>
                  <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/project_item_reports" element={<ProjectItemReports />} />
                    <Route path="/" element={<Admin />}>
                      <Route path="/admin" element={<Dashboard />} />
                      <Route path="/dashboard" element={<Dashboard />} />
                      {/* <Route path="/statistics" element={<Statistics />} /> */}
                      {/* <Route path="/company_settings" element={<CompanySettings />} />
                  <Route path="/currency_settings" element={<CurrencySettings />} />
                  <Route path="/country_settings" element={<CountriesSettings />} />
                  <Route path="/global_core_settings" element={<GlobalCoreSettings />} />
                  <Route path="/modules_settings" element={<ModulesSettings />} /> */}

                      <Route path="/permission_identifier" element={<PermissionIdentifier />} />
                      <Route path="/permission_action" element={<PermissionAction />} />
                      <Route path="/permission_action" element={<PermissionSet />} />
                      <Route path="/customer" element={<CustomerStack />} />
                      <Route path="/contact_role" element={<ContactRoleIndex />} />
                      <Route path="/project" element={<ProjectsManage />} />
                      <Route path="/project_status" element={<ProjectStatus />} />
                      <Route path="/order_names" element={<OrderName />} />
                      <Route path="/permission_role" element={<EmployeeRole />} />
                      <Route path="/create_assignments" element={<CreateAssignments />} />
                      <Route path="/profile" element={<ProfileSettings />} />
                      <Route path="/timetracker" element={<TimeTrack />} />
                      <Route path="/project_report" element={<ReportsProjectAll />} />
                      <Route path="/employee_reports" element={<Reports />} />
                      <Route path="/test" element={<Timer />} />
                      <Route path="/task_category" element={<TaskCategory />} />
                      <Route path="/task_only" element={<TaskOnly />} />
                      <Route path="/order_only" element={<OrderOnlyIndex />} />
                      <Route path="/user" element={<UserMg />} />
                      <Route path="/user_role" element={<UserRole />} />
                      {/* <Route path="/user_role" element={<CorePermissionIndex />} />     */}
                      <Route path="/settings" element={<Settings />} />
                      <Route path="/reports_filter" element={<ReportsFilter />} />
                      <Route path="/customer_category" element={<CustomerCategory />} />
                      <Route path="/filter_statistic" element={<FilterReportStastic />} />
                      <Route path="/task_only_project" element={<TaskProject />} />
                      <Route path="/project_statistic" element={<ProjectReportStastic />} />
                      <Route path="/user_statistic" element={<UserReportStastic />} />
                      <Route path="/create_customer" element={<CreateCustomer />} />
                      <Route path="/report_customer" element={<ReportCustom />} />
                      <Route path="/leave" element={<LeaveRequest />} />
                      <Route path="/leave_manger" element={<LeaveManger />} />
                      <Route path="/sick_leave" element={<SickLeave />} />
                      <Route path="/approval" element={<ApprovalTimeTracker />} />
                      <Route path="/supplier" element={<SupplierIndex />} />
                      <Route path="/product" element={<ProductIndex />} />
                      <Route path="/product_procurement" element={<ProductProcurementIndex />} />
                      <Route path="/return_reason" element={<ReturnReasonIndex />} />
                      <Route path="/storage" element={<StoreHouseIndex />} />
                      <Route path="/product_requirments" element={<ProductRequirmentsIndex />} />
                      <Route path="/delivered_product" element={<DeliveredManagementIndex />} />
                      <Route path="/recycling_company_index" element={<RecyclingCompanyIndex />} />
                      <Route path="/create_order" element={<OrderCreateIndex />} />
                      <Route path="/on_boarding" element={<OnBoarding />} />
                      <Route path="/new_boarding" element={<NewBoarding />} />
                      <Route path="/off_boarding" element={<OffBoarding />} />
                      <Route path="/boarding_archive" element={<BoardingArchive />} />
                      <Route path="/custom_attributes" element={<CustomAttrbutesBoarding />} />
                      <Route path="/user_change_log" element={<UserChangeLogs />} />
                      <Route path="/assignment_overview" element={<AssignmentOverview />} />
                      <Route path="/overtime_report" element={<OvertimeReport />} />
                      <Route path="/employee_overtime" element={<EmployeeOvertimeReport />} />
                      <Route path="/employee_record" element={<EmployeeTimeReportRecord />} />
                      <Route path="/employee_notime_record" element={<EmployeeNoTimeReportRecord />} />
                      <Route path="/student_record" element={<StudentTimeReportRecord />} />
                      <Route path="/timetracker_report" element={<FejpTimeReportRecord />} />
                      <Route path="/leave_category" element={<LeaveCotegory />} />
                      <Route path="/log" element={<LogSettings is_load_str={''} />} />
                    </Route>
                  </Routes>
                </BrowserRouter>
              </MantineProvider>
            </ModalsProvider>
          </ColorSchemeProvider>
        </IntlProvider>
      ) : null}
    </>
  )
}
