import {
  Box,
  Grid,
  Text,
  Paper,
  Textarea,
  Select,
} from "@mantine/core";

import "./css/index.css";
import { DateTimePicker } from "@mantine/dates";
import { useEffect, useState } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import memoryUtils from "../../utils/memoryUtils";
import Login from "../core/login/login";
import { regGetSurplusVacation, regSickTimeAction } from "../../api";
import {
  ClientWarningHint,
  HintInfo,
  JudgmentTimeThreshold,
  formatDateUpgraded,
} from "../../utils/function";
import SickRows from "./sick_row";
import localstorageUnits from "../../utils/localstorageUnits";
import { ItimeTrackTask } from "../../interface/Itimetrack";
import PageTitle from "../../ttcomponents/PageTitle/PageTitle";
import { useIntl } from "react-intl";

export default function SickLeave() {
  // Use the useIntl hook to access the intl object
  const intl = useIntl();

  //Set the request start time
  const [startTimeValue, setStartTimeValue] = useState<Date | null>(null);
  //Set the request end time
  const [endTimeValue, setEndTimeValue] = useState<Date | null>(null);

  // Settings
  const [commnetvalue, setCommentValue] = useState("");
  // Get the initial time of the system
  const initSystemTime = localstorageUnits.getSystem().init_use_date;

  const [rand, setRand] = useState(1);

  // Get the current user information
  const userExists = memoryUtils.user;

  const [timeTracker, setTimeTracker] = useState<ItimeTrackTask[]>([]);

  const ajaxCurrentList = async () => {
    //Get personal remaining holiday information
    const surplusVactionRespone = await regSickTimeAction(
      { user_id: userExists.id_user },
      "GET"
    );
    setTimeTracker(surplusVactionRespone.data.data);
  };

  const callback = async () => {
    ajaxCurrentList();
    setRand(rand + 1);
  };
  // Page initialization function
  useEffect(() => {
    ajaxCurrentList();
  }, []);

  const SickRequest = async () => {
    //Application start time
    if (!startTimeValue) {
      ClientWarningHint("There is no choice from the start time ");
      return;
    }

    if (JudgmentTimeThreshold(startTimeValue as Date) === false) {
      return false;
    }
    //Submit data initialization
    const originData = {
      user_id: userExists.id_user,
      start_date: formatDateUpgraded(
        startTimeValue as Date,
        "YYYY-MM-DD HH:mm:ss"
      ),
      end_date: endTimeValue
        ? formatDateUpgraded(endTimeValue as Date, "YYYY-MM-DD HH:mm:ss")
        : "",
      // "days":"3",
      remarks: commnetvalue,
    };

    console.log("sick info", originData);
    const sickTimeRespone = await regSickTimeAction(originData, "POST");
    if (sickTimeRespone.data.code == 200) {
      setRand(rand + 1);
      ajaxCurrentList();
      HintInfo(sickTimeRespone.data);
    } else if (
      sickTimeRespone.data.code === 5001 ||
      sickTimeRespone.data.code === 414
    ) {
      //Process update failure
      ClientWarningHint(
        sickTimeRespone.data.data.year +
          ",There is a work record in the " +
          sickTimeRespone.data.data.week +
          " week, please check"
      );
    }
  };

  return (
    <Paper px={20} style={{ position: "relative" }}>
      <PageTitle
        title="SICK LEAVE MANAGEMENT"
        lang_id="SICK LEAVE MANAGEMENT"
      />
      <Box mb={5} mt={10}>
        <Text className="leave-title">
          {intl.formatMessage({
            id: "Choose Sick Leave Time",
            defaultMessage: "Choose Sick Leave Time",
          })}
        </Text>
      </Box>
      <Grid gutter={0}>
        <Box className="sick-leave-left leave-border">
          <Box className=""></Box>
          <Grid gutter={0} className="margin-54">
            <Box>
              {" "}
              <DateTimePicker
                label={intl.formatMessage({
                  id: "From",
                  defaultMessage: "From",
                })}
                placeholder={intl.formatMessage({
                  id: "Select Vacation Start Date",
                  defaultMessage: "Select Vacation Start Date",
                })}
                minDate={new Date(initSystemTime)}
                key={rand}
                value={startTimeValue}
                onChange={setStartTimeValue}
                sx={{ "font-size": "12px" }}
                w={260}
                maw={260}
                mx="auto"
              />
            </Box>
            <Box className="datePicker-margin-58">
              <DateTimePicker
                label={intl.formatMessage({
                  id: "To",
                  defaultMessage: "To",
                })}
                placeholder={intl.formatMessage({
                  id: "Select Vacation End Date",
                  defaultMessage: "Select Vacation End Date",
                })}
                minDate={new Date(initSystemTime)}
                key={rand}
                value={endTimeValue}
                onChange={setEndTimeValue}
                sx={{ "font-size": "12px" }}
                w={260}
                maw={260}
                mx="auto"
              />
            </Box>
          </Grid>
          <Box className="HalfBox margin-54"></Box>

          <Box className="margin-54">
            <Text className="leave-title-date">
              {intl.formatMessage({
                id: "Additional Information",
                defaultMessage: "Additional Information",
              })}
            </Text>
            <Textarea
              className="sick-commnet-content"
              minRows={5}
              value={commnetvalue}
              onChange={(event) => setCommentValue(event.currentTarget.value)}
            />
          </Box>
          <Box className="margin-534" mt={20}>
            <Grid gutter={0}>
              <Box className="reset layout-btn  cursor-pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M7 9.66667H19M7.75 9.66667L8.5 17.6667C8.5 18.0203 8.65804 18.3594 8.93934 18.6095C9.22064 18.8595 9.60218 19 10 19H16C16.3978 19 16.7794 18.8595 17.0607 18.6095C17.342 18.3594 17.5 18.0203 17.5 17.6667L18.25 9.66667M10.75 9.66667V7.66667C10.75 7.48986 10.829 7.32029 10.9697 7.19526C11.1103 7.07024 11.3011 7 11.5 7H14.5C14.6989 7 14.8897 7.07024 15.0303 7.19526C15.171 7.32029 15.25 7.48986 15.25 7.66667V9.66667M11.5 13L14.5 15.6667M14.5 13L11.5 15.6667"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <Text className="reset-text">
                  {intl.formatMessage({
                    id: "Reset",
                    defaultMessage: "Reset",
                  })}
                </Text>
              </Box>
              <Box
                onClick={SickRequest}
                className="submit layout-btn cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    d="M13.1648 12.5625C12.1099 11.5077 10.6792 10.9152 9.18752 10.9152C7.69578 10.9152 6.26514 11.5077 5.21027 12.5625M13.5 2.853C12.4664 2.25629 11.2382 2.09458 10.0854 2.40345C8.93259 2.71232 7.94972 3.46647 7.35297 4.5L15.147 9C15.7437 7.96644 15.9054 6.73818 15.5965 5.5854C15.2876 4.43263 14.5335 3.44975 13.5 2.853ZM13.5 2.853C14.2177 3.267 13.7926 5.3475 12.5491 7.5M13.5 2.853C12.7822 2.439 11.1938 3.8475 9.95105 6M11.25 6.75L9 10.647M2.25 14.4375C2.48347 14.3214 2.73937 14.2574 3 14.25C3.29212 14.2439 3.58134 14.309 3.84268 14.4396C4.10402 14.5703 4.32962 14.7626 4.5 15C4.67038 15.2374 4.89598 15.4297 5.15732 15.5604C5.41866 15.691 5.70788 15.7561 6 15.75C6.29212 15.7561 6.58134 15.691 6.84268 15.5604C7.10402 15.4297 7.32962 15.2374 7.5 15C7.67038 14.7626 7.89598 14.5703 8.15732 14.4396C8.41866 14.309 8.70788 14.2439 9 14.25C9.29212 14.2439 9.58134 14.309 9.84268 14.4396C10.104 14.5703 10.3296 14.7626 10.5 15C10.6704 15.2374 10.896 15.4297 11.1573 15.5604C11.4187 15.691 11.7079 15.7561 12 15.75C12.2921 15.7561 12.5813 15.691 12.8427 15.5604C13.104 15.4297 13.3296 15.2374 13.5 15C13.6704 14.7626 13.896 14.5703 14.1573 14.4396C14.4187 14.309 14.7079 14.2439 15 14.25C15.2606 14.2574 15.5165 14.3214 15.75 14.4375"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <Text className="reset-text">
                  {intl.formatMessage({
                    id: "Submit",
                    defaultMessage: "Submit",
                  })}
                </Text>
              </Box>
            </Grid>
          </Box>
        </Box>

        <Box className="margin-49">
          <Box className="sick-right-top leave-border">
            <SickRows
              timeTracker={timeTracker}
              rand={rand}
              callBack={callback}
              type={1}
            />
          </Box>
        </Box>
      </Grid>
    </Paper>
  );
}
