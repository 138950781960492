import { ActionIcon, Box, Text, Button, ColorPicker, ColorSwatch, Grid, Group, Modal, MultiSelect, SegmentedControl, Select, Space, Switch, Tooltip, TextInput, Flex, Checkbox } from '@mantine/core';
import { useCounter, useDisclosure } from '@mantine/hooks';
import ModalTitleText from '../../../../ttcomponents/ModalTitleText/ModalTitleText';
import WordTranslationComponent from '../../../../utils/language_pack/words';
import { useEffect, useRef, useState } from 'react';
import TableTextInputTitle from '../../../../ttcomponents/TableTextInputTitle/TableTextInputTitle';
import ResetBtn from '../../../../ttcomponents/Button/ResetBtn';
import SubmitBtn from '../../../../ttcomponents/Button/SubmitBtn';
import localstorageUnits from '../../../../utils/localstorageUnits';
import { DateInput } from '@mantine/dates';
import { IconCalendar } from '@tabler/icons-react';
import { getCustomerListGeneralFunction, getPermissionActionListGeneralFunction, getPermissionIdentifierListGeneralFunction } from '../../../../api/assignments_request';
import { SelectRole } from '../../../../interface/Icommon';
import GlobalCoreSetting from '../../../../utils/globalCortSetting';
import UniversalButton from '../../../../ttcomponents/Button/UniversalButton';
import TableTextInputTitleDesc from '../../../../ttcomponents/TableTextInputTitleDesc/TableTextInputTitleDesc';
import { get } from 'http';
import { PermissionActionInterface, PermissionIdentifierInterface, PermissonSetFormDataInterface } from '../../../../interface/Iemloyee';
import PermissionSetCheckBoxGroup from './permission_set_checkbox_group';
import React from 'react';
import { requestPermissionSet } from '../../../../api/index_v2';
import { ClientWarningHint, HintInfo, HintInfoCatchError, HintInfoError } from '../../../../utils/function';

interface ComponentProps {
    openFormStatus: boolean;
    currentItem?: any;
    is_load?: boolean;
    closeFunction: () => void;
    disabled?: boolean;
    successCallback: (value: PermissonSetFormDataInterface[]) => void
}

function PermissionSetForm({ openFormStatus, closeFunction, currentItem,successCallback, disabled = false }: ComponentProps) {
    // Get the default unchanged configuration
    const [loading, setLoading] = useState(false);
    // Define all the customer lists that can be selected
    const [identifierList, setIdentifierList] = useState<PermissionIdentifierInterface[]>([]);
    const [actionList, setActionList] = useState<PermissionActionInterface[]>([]);
    
   const [rolePermissions, setRolePermissions] = useState<PermissonSetFormDataInterface[]>([]);
   
   const [itemPermissionSet, setItemPermissionSet] = useState<string[]>([]);
    // Get the initial time of the system
    const initSystemTime = localstorageUnits.getSystem().init_use_date;

    const [openForm, { open: openModalFunction, close: closeModalFunction }] = useDisclosure(false);
    // Get translation public configuration information
    const word_translation = WordTranslationComponent();

    useEffect(() => {
        openFormStatus ? openModalFunction() : closeModalFunction();
        getIdentifierList();
        getActionList();
    }, [openFormStatus])

     useEffect(() => {
        console.log(rolePermissions,'你heihie好')
    }, [rolePermissions])

    
    // Close component <FUNCTION>
    const closeBtnModalFunction = () => {
        closeModalFunction();
        closeFunction();
    }

    // Click save and  Submit Data TO Form by CallBack <FUNCTION>
    const saveBtnFunction = async () => {
      let foundResult:string = '';
      // TODO :Iterate through the selected data and check if "All" is chosen without selecting any action. If so, display an error message.
      rolePermissions.forEach((item,index)=>{
          if(item.all_flag === '1'  &&  item.permission_ids === '' ){
            foundResult = item.permission_identifier_id;
          }
      })
      //  display an error message with which 
      if(foundResult) {
        const ErrorItem = identifierList.find(item=> item.id_permission_identifier == foundResult)
        ClientWarningHint(`${word_translation.permissions_set_all_flag} -> ${ErrorItem?.name}` );
        return false;
      }
      // TODO ：Submit Data TO Form by CallBack
      successCallback(rolePermissions);
      closeBtnModalFunction();
    }  

    
    // Get the list of IdentifierList
    const getIdentifierList = async () => {
      openFormStatus && setIdentifierList(await getPermissionIdentifierListGeneralFunction('','all'));
    };
    
     
    // Get the list of IdentifierList
    const getActionList = async () => {
      openFormStatus && setActionList(await getPermissionActionListGeneralFunction("",'all'));
    };

    const setItemPermissionSetFunction = (index: number, permission_ids: string[], id_permission_identifier: string, all_flag: string) => {
      // Check if id_permission_identifier is missing, return false
      if (!id_permission_identifier) {
        return false;
      }
    
      // Determine whether there is data related to permission_identifier_id that currently exists in the array.
      const existingPermissionIndex = rolePermissions.findIndex(
        (permission) => permission.permission_identifier_id === id_permission_identifier
      );
    
      if (existingPermissionIndex !== -1) {
        // If permission with the same identifier exists, update it
        setRolePermissions((prevPermissions) =>
          prevPermissions.map((permission, currentIndex) => {
            if (currentIndex === existingPermissionIndex) {
              // If it is the index to update, return the updated object
              return {
                ...permission,
                permission_ids: permission_ids.join(','),
                all_flag: all_flag,
              };
            }
            // Otherwise, return the original object
            return permission;
          })
        );
      } else {
        // If not, add a new permission
        setRolePermissions((prevPermissions) => [
          ...prevPermissions,
          {
            permission_ids: permission_ids.join(','),
            permission_identifier_id: id_permission_identifier,
            all_flag: all_flag,
          },
        ]);
      }
    
      // TODO: Delete elements with permission_ids.length === 0 and all_flag === '0'
      setRolePermissions((prevPermissions) => {
        // Use the filter method to filter elements that meet conditions
        const updatedPermissions = prevPermissions.filter((permission) => {
          return !(permission.permission_ids.length === 0 && permission.all_flag === '0');
        });
        // Return the new permissions array
        return updatedPermissions;
      });
    };
    
    return (
        <Modal
            opened={openForm}
            onClose={closeBtnModalFunction}
            closeOnClickOutside={false}
            title={<ModalTitleText title={word_translation.project_permission} />}
            padding="xs"
            size='xl'
            radius={10}
        >
            <Grid>
                <Grid.Col span={12}>
                  {identifierList.map((item,index)=>{
                    const currentPremissionSetItem = currentItem.find(
                      (permission: { permission_identifier_id: string; }) => permission.permission_identifier_id === item.id_permission_identifier
                    );

                    return (
                        <React.Fragment key={index}>
                           <PermissionSetCheckBoxGroup disabled={disabled} currentPremissionSetItem={currentPremissionSetItem} index={index} identifierList={item}  actionList={actionList}  callBack={setItemPermissionSetFunction} />
                       </React.Fragment>
                    )
                  })}
                </Grid.Col>
                <Grid.Col span={6}>
                 
                </Grid.Col>
            </Grid>
            <Group position="apart" mt={5}>
              {disabled === false && <>
                <UniversalButton btn_bg_color='#868E96' btn_text={word_translation.cancel} btn_size='xs' callBack={closeBtnModalFunction} />
                <UniversalButton btn_text={word_translation.add} btn_size='xs' callBack={saveBtnFunction} />
              </>}
               
            </Group>
        </Modal>
    );
}
export default PermissionSetForm;
