
import {  Paper, Box, LoadingOverlay, Badge, Group, MultiSelect, Select, ActionIcon,  Alert, Table, ScrollArea, Grid, Checkbox } from '@mantine/core';
import PageTitle from '../../../ttcomponents/PageTitle/PageTitle';
import { useEffect, useRef, useState } from 'react';
import GlobalCoreSetting from '../../../utils/globalCortSetting';
import WordTranslationComponent from '../../../utils/language_pack/words';
import { requestSignalEmployeeOvertime } from '../../../api/index_v2';
import { requestEmployeeTime } from '../../../api';
import { RootState } from '../../../state_management/store';
import { useSelector } from 'react-redux';
import { EmployeeReportInterface } from '../../../interface/IReport';
import { ConvertTimeStringToDecimal, formatTimeHHSS, getWeeKAll, getYearAll } from '../../../utils/function';
import { SelectRole } from '../../../interface/Icommon';
import { IconAlertCircle, IconX } from '@tabler/icons-react';
import UniversalButton from '../../../ttcomponents/Button/UniversalButton';
import TableTextInputTitle from '../../../ttcomponents/TableTextInputTitle/TableTextInputTitle';



// OvertimeReport component definition
export default function EmployeeNoTimeReportRecord() {
  // Get the default unchanged configuration
  const globalCoreSetting = GlobalCoreSetting();
  // Get translation public configuration information
  const word_translation = WordTranslationComponent();



  const [loading, setLoading] = useState(false);

  const [employeeTimeRecord, setEmployeeTimeRecord] = useState<EmployeeReportInterface>();
  const [active, setActive] = useState('0');
  const [projectName, setProjectName] = useState<string[]>([]);
  const [kwList, setKwlist] = useState<any[]>([]);

  const [projectArr, setProjectArr] = useState([]);
  const [year, setYear] = useState<string | null>(null);
  const [startWeek, setStartWeek] = useState<string | null>(null);
  const [endWeek, setEndWeek] = useState<string | null>(null);
  const [costCentor, setCostCentor] = useState<string | null>(null);
  const [costCentorName, setCostCentorName] = useState<string | null>(null);
  //Get all the Year collection
  const yearNumberAll = getYearAll();
  const weekNumberAll = getWeeKAll();

  const [randNumber, setRandNumber] = useState(0);



  // 获取redux store中的值
  const SearchValue = useSelector((state: RootState) => state.search.searchValue)
  // Execute the useeffect when the first load is loaded.
  const isInitialMount = useRef(true);
  useEffect(() => {
    if (!isInitialMount.current) {
      // ajaxCurrentList({ search: SearchValue });
    }
    isInitialMount.current = false;
  }, [SearchValue]);

  // 2/employee_time?excluded_project=1,2,4&year&start_week&end_week
  const getEmployeeOvertimeReport = async (value: any = {}) => {
    console.log(projectArr)
    if (year && startWeek && endWeek) {
      const requestData = {
        start_week: startWeek,
        end_week: endWeek,
        type:"4",
        year: year,
        active: active,
        costCenter_id:costCentor,
        excluded_project: projectArr?.join(',')
      }
      // Send GET requests to get  SignalEmployeeOvertime
      setLoading(true); // Set the loading status to TRUE, indicating that the data is loading
      const responseOverTime = await requestEmployeeTime(requestData, 'GET');
      setLoading(false); // After the data is loaded, set the loading status to false
      if (responseOverTime.data.code === 200) {
        setEmployeeTimeRecord(responseOverTime.data.data);
        setKwlist(responseOverTime.data.data.week_list);
      }
    }


  };

    // Declare the variable costCenterData that stores the user branch center
    const [costCenterData, setCostCenterData] = useState<SelectRole[]>([]);




  // Define an asynchronous function ajaxcurrenTlist to send AJAX request to obtain the list of suppliers
  const getComputeFunction = async (value: any = {}) => {
    getEmployeeOvertimeReport();
  };

  // Define all the project lists that can be selected
  const [projectList, setProjectList] = useState<SelectRole[]>([]);

  useEffect(() => {
    // setProjectName
    setProjectName(projectArr.map(projectId => {
      const project = projectList.find(item => item.value === projectId);
      return project ? project.label : null;
    }) as [])
  }, [projectArr]);

  useEffect(() => {
    // setProjectName
  const foundItem = costCenterData.find(item => item.value === costCentor);
     setCostCentorName(foundItem?.label as string)
  }, [costCentor]);


  return (
    <Paper px={20} mih={600} className='position_relative'  >
      <PageTitle title={`${word_translation.time_employee_report}  `} />
      <LoadingOverlay visible={loading} overlayBlur={2} />
      {/* Tabs for different settings */}

      <Group my={10} className='border_coustom'>
        <Select
          placeholder={word_translation.start_year}
          size='xs'
          required
          w={120}
          value={year}
          rightSection={<ActionIcon size='xs' onClick={()=>{setYear('')}}> <IconX /></ActionIcon>}
          onChange={setYear}
          searchable
          data={yearNumberAll}
        />
        <Select
          placeholder={word_translation.start_kw}
          size='xs'
          required
          w={120}
          defaultValue={startWeek}
          rightSection={<ActionIcon size='xs' onClick={()=>{setStartWeek('')}}> <IconX /></ActionIcon>}
          onChange={setStartWeek}
          searchable
          data={weekNumberAll}
        />
        <Select
          placeholder={word_translation.end_kw}
          size='xs'
          required
          value={endWeek}
          w={120}
          rightSection={<ActionIcon size='xs' onClick={()=>{setEndWeek('')}}> <IconX /></ActionIcon>}
          onChange={setEndWeek}
          searchable
          data={weekNumberAll}
        />
 
        <Checkbox
            checked={active == '1' ? true : false}
            label={ <TableTextInputTitle
              title={word_translation.include_lnactive_users}
            />}
            size="xs"
            onChange={(event) =>{
              setActive(event.currentTarget.checked ? '1':'0')
                }}
              />
        <UniversalButton callBack={getComputeFunction} btn_text={word_translation.compute} btn_size='xs' />
        <UniversalButton disabled={true} callBack={getComputeFunction} btn_text={word_translation.print} btn_size='xs' />
      </Group>

      {!employeeTimeRecord &&
        <Group position='center' mt={50}>
          <Alert icon={<IconAlertCircle size="1rem" />} title={word_translation.no_data}>
            {word_translation.report_hitn}
          </Alert>
        </Group>
      }
      <Box className={`${employeeTimeRecord ? 'border-cusome border-radius-xs' : ''}`}>
        <Box mb={10}>
          <Group>
            {year && <Badge radius='sm' color='dark'>{word_translation.start_year} : {year}</Badge>}
            {startWeek && <Badge radius='sm' color='dark'>{word_translation.start_kw} : {startWeek}</Badge>}
            {endWeek && <Badge radius='sm' color='dark'>{word_translation.end_kw} : {endWeek}</Badge>}
            {costCentorName && <Badge radius='sm' color='dark'>{word_translation.cost_center_name} : {costCentorName}</Badge>}
            {projectName.length > 0 && <Badge radius='sm' color='dark'> {word_translation.excluded_project} : {projectName.join(',')} </Badge>}
          </Group>

          
          <Group mt={10}>
            {employeeTimeRecord && <Badge radius='sm' color='dark'>{word_translation.completion} : {employeeTimeRecord?.completion}%</Badge>}
          </Group>

        </Box>
        {kwList.length > 0 && 
           <ScrollArea h='74vh' >
           <Table  className='white_space_nowrap' >
             <thead className="scrolleder_F3F7FB ">
               <tr >
                 <th>{word_translation.kw}</th>
                 {kwList.map((item, index) => {
                   let thText = item;
                   if(item == 'total'){
                     thText = <b>∑</b>
                   }
                   return (
                     <th>{thText}</th>
                   )
                 })}
               </tr>
             </thead>
             <tbody>
               {employeeTimeRecord?.employee_time_list.map((item, index) => {
                 return (
                   <tr >
                     <td >
                       <div>{item.username}</div>
                         {item.exclude_item_list.length > 0 && item.exclude_item_list.map((ec_item, ec_index) => {
                           return (
                             <div className='exclude_item'>{ec_item.item_name}</div>
                           )
                         })}
                   
                <div>PRODUCTIVE TIME</div>
                        <div>TOTAL TIME</div>
                        <div>TARGET TIME</div>
                        <div>DIFF TIME</div>
                     </td>
                     {kwList.map((kw_item, kw_index) => {
                       return (
                         <td>
                           {/* TOP */}
                           {item.user_time_list.length > 0 && item.user_time_list.map(us_tl_item => {
                             if (us_tl_item.week == kw_item) {
                               return (
                                 <>
                                   <div>
                                     <b>   {us_tl_item.completion} %</b>
                                   </div>
                                   <div>
                                   </div>
                                 </>
                               )
                             }
                           })}
                           {/* exclude PROject */}
                           {item.exclude_item_list.length > 0 && item.exclude_item_list.map((ec_item, ec_index) => {
                             return (
                               <div className='exclude_item'>{ec_item.item_time_list.map((ec_it_item,ec_it_index) => {
          
                                 if (ec_it_item.week == kw_item) {
                                   let thText:any = '';
                                   if(ec_it_item.week == 'total'){
                                     thText = <b>  {ec_it_item.completion} %</b>
                                   }else{
                                    thText = <> {formatTimeHHSS(ec_it_item.time as string) == '00:00' ? <Badge size='xs' color='blue' radius='xs'>N/A</Badge> : `${ConvertTimeStringToDecimal(formatTimeHHSS(ec_it_item.time as string))}  ${word_translation.hour}`}</>
                                  }
                                   return thText
                                 }
                               })} </div>
                             )
                           })}
 
                           {/* exclude user_time */}
                           {item.user_time_list.length > 0 && item.user_time_list.map(us_tl_item => {
                             if (us_tl_item.week == kw_item) {
                               return (
                                 <>
                                   <div>
                                     {formatTimeHHSS(us_tl_item.actual_time as string) == '00:00' ? <Badge size='xs' color='gray' radius='xs'>N/A</Badge> : `${ConvertTimeStringToDecimal(formatTimeHHSS(us_tl_item.actual_time as string))}  ${word_translation.hour}`}
                                   </div>
                                   <div>
                                     {formatTimeHHSS(us_tl_item.total_time as string) == '00:00' ? <Badge size='xs' color='gray' radius='xs'>N/A</Badge> : `${ConvertTimeStringToDecimal(formatTimeHHSS(us_tl_item.total_time as string))}  ${word_translation.hour}`}
                                   </div>
                                   <div>
                                     {formatTimeHHSS(us_tl_item.target_time as string) == '00:00' ? <Badge size='xs' color='gray' radius='xs'>N/A</Badge> : `${ConvertTimeStringToDecimal(formatTimeHHSS(us_tl_item.target_time as string))}  ${word_translation.hour}`}
                                   </div>
                                   <div>
                                     {formatTimeHHSS(us_tl_item.difference_time as string) == '00:00' ? <Badge size='xs' color='gray' radius='xs'>N/A</Badge> : `${ConvertTimeStringToDecimal(formatTimeHHSS(us_tl_item.difference_time as string))}  ${word_translation.hour}`}
                                   </div>
                                 </>
                               )
                             }
                           })}
                           
 
                         </td>
                       )
                     })}
 
                     <td>
 
 
                     </td>
                   </tr>
                 )
               })}
 
             </tbody>
           </Table>
         </ScrollArea>}
     
      </Box>


    </Paper>
  );
}
