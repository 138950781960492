import {
  Code,
  Grid,
  Group,
  NumberInput,
  Paper,
  TextInput,
  Textarea,
} from "@mantine/core";
import AppCategory from "../product/category/category";
import SupplierSelect from "../../../ttcomponents/Supplier/SupplierSelect";
import CancelButton from "../../../ttcomponents/Button/CancelBtn";
import SaveBottom from "../../../ttcomponents/Button/SaveBtn";
import { FormEvent, useRef } from "react";
import { Product } from "../../../interface/IProduct";
import { isNotEmpty, useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { IconCalendar, IconCheck, IconX } from "@tabler/icons-react";
import { IProductProcurementOrderData } from "../../../interface/IProductProcurement";
import { DateInput } from "@mantine/dates";
import { regProductPurchase } from "../../../api";
import { HintInfo, formatDateUpgraded } from "../../../utils/function";
import ProductSelect from "../../../ttcomponents/Product/ProductSelect";
import { useIntl } from "react-intl";

interface ProductProcurementFromProps {
  additional_information: any;
  unit_price_prohibit_edit: boolean;
  createNew?: boolean;
  closeFucntion: (type: number) => void;
}
export default function ProductProcurementFrom({
  additional_information,
  createNew,
  unit_price_prohibit_edit,
  closeFucntion,
}: ProductProcurementFromProps) {
  // Use the useIntl hook to access the intl object
  const intl = useIntl();

  const formRef = useRef<HTMLFormElement>(null);
  const form = useForm<IProductProcurementOrderData>({
    initialValues: {
      id_purchasing: additional_information?.id_purchasing,
      order_product_id: additional_information?.order_product_id,
      product_id: additional_information?.product_id,
      supplier_id: additional_information?.supplier_id,
      purchasing_no: additional_information?.purchasing_no,
      unit_price: additional_information?.unit_price,
      product_name: additional_information?.product_name,
      delivered_quantity: additional_information?.delivered_quantity ?? 0 ,
      order_quantity: additional_information?.order_quantity, // 初始值为 productItem 中的 unit_price
      delivery_schedule: additional_information?.delivery_schedule
        ? new Date(additional_information?.delivery_schedule)
        : new Date(),
      delivery_date: additional_information?.delivery_date
        ? new Date(additional_information?.delivery_date)
        : null,
    },
    validate: {
      // product_id: isNotEmpty('Product name is required'), // 验证 product_name 不为空
      purchasing_no: isNotEmpty("Order NO is required"), // 验证 product_name 不为空
      supplier_id: isNotEmpty("Supplier ID is required"), // 验证 supplier_id 不为空
      order_quantity: isNotEmpty("Order Quantity  is required"), // 验证 category_id 不为空
      delivery_schedule: isNotEmpty("Delivery Schedule  is required"),
      unit_price: (value) => {
        if (value) {
          if (/^\d+(\.\d+)?$/.test(value)) {
            return null; // 验证通过，返回 undefined
          }
          return "Need to be floating -point numbers";
        }
        return null;
      },
    },
  });

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Stop the formal submission of the form default
    // Verification form
    const valid = form.validate();
    if (valid.hasErrors) {
      notifications.show({
        color: "yellow.7",
        icon: <IconX />,
        message: Object.values(valid.errors)[0],
      });
      return;
    }
    let submit_type = "POST";
    if (form.values.id_purchasing) {
      submit_type = "PUT";
    }

    const updatedFormValues = {
      ...form.values,
      delivery_schedule: formatDateUpgraded(
        form.values.delivery_schedule,
        "YYYY-MM-DD HH:mm:ss"
      ),
      delivery_date: formatDateUpgraded(
        form.values.delivery_date as Date,
        "YYYY-MM-DD HH:mm:ss"
      ),
    };

    const response = await regProductPurchase(updatedFormValues, submit_type);
    HintInfo(response.data);
    if (response.data.code == 200) {
      closeFucntion(2);
    }
  };
  //    Supplier obtain information
  const ReceiveSupplierBack = (value: any) => {
    if (value.type == "supplier") {
      if (value.value == "") {
        form.setFieldValue("supplier_id", "");
      } else {
        form.setFieldValue("supplier_id", value.value);
      }
    } else if (value.type == "product") {
      form.setFieldValue("product_id", value.value);
      form.setFieldValue("unit_price", value.unit_price);
      form.setFieldValue("supplier_id", value.supplier_id);
    }
  };

  return (
    <Paper p={10}>
      <form onSubmit={handleFormSubmit} ref={formRef}>
        <Grid>
          <Grid.Col span={12}>

          <TextInput
              {...form.getInputProps("purchasing_no")}
              placeholder={intl.formatMessage({
                id: "input",
                defaultMessage: "Input",
              })}
              label={intl.formatMessage({
                id: "purchasing_no",
                defaultMessage: "Purchasing no",
              })}
              // disabled={form.values.id_purchasing ? true : false}
              style={{ width: "100%" }}
            />

            {createNew || additional_information?.product_id ? (
              <ProductSelect
                createNew={false}
                disabled={additional_information?.product_id ? true : false}
                labelStr={intl.formatMessage({
                  id: "product_name",
                  defaultMessage: "Product Name",
                })}
                currentId={additional_information?.product_id as string}
                callBack={ReceiveSupplierBack}
              />
            ) : (
              <TextInput
                disabled
                value={additional_information?.product_name}
                label={intl.formatMessage({
                  id: "product_name",
                  defaultMessage: "Product Name",
                })}
              />
            )}


          <SupplierSelect
              labelStr={intl.formatMessage({
                id: "Supplier",
                defaultMessage: "Supplier",
              })}
              prohibit={additional_information?.product_id ? true : false}
              width={"auto"}
              currentId={form.values.supplier_id as string}
              callBack={ReceiveSupplierBack}
            />
                  <TextInput
              disabled={unit_price_prohibit_edit}
              {...form.getInputProps("unit_price")}
              
              label={intl.formatMessage({
                id: "Unit Price",
                defaultMessage: "Unit Price",
              })}
            />


            <NumberInput
              {...form.getInputProps("order_quantity")}
              // value={categoryName}
              placeholder={intl.formatMessage({
                id: "input",
                defaultMessage: "Input",
              })}
              label={intl.formatMessage({
                id: "order_quantity",
                defaultMessage: "Order quantity",
              })}
            />

          {/* </Grid.Col>

          <Grid.Col span={6}> */}
      
            <NumberInput
              {...form.getInputProps("delivered_quantity")}
              // value={categoryName}
              placeholder={intl.formatMessage({
                id: "input",
                defaultMessage: "Input",
              })}
              label={intl.formatMessage({
                id: "delivered_quantity",
                defaultMessage: "Delivered quantity",
              })}
            />


          <DateInput
              valueFormat="DD/MM/YYYY"
              required
              size="xs"
              icon={<IconCalendar size="1.1rem" stroke={1.5} />}
              {...form.getInputProps("delivery_schedule")}
              label={intl.formatMessage({
                id: "delivery_schedule",
                defaultMessage: "Delivery schedule",
              })}
              
              mx="auto"
            />
            <DateInput
              valueFormat="DD/MM/YYYY"
              size="xs"
              icon={<IconCalendar size="1.1rem" stroke={1.5} />}
              {...form.getInputProps("delivery_date")}
              label={intl.formatMessage({
                id: "delivery_date",
                defaultMessage: "Delivery date",
              })}
              mx="auto"
            />
          </Grid.Col>
        </Grid>

        <Group position="right">
          <CancelButton callBack={() => closeFucntion(1)} />
          <SaveBottom formRef={formRef} />
        </Group>

        {/** can see form values */}
        {/* <Code block mt={5}>
          {JSON.stringify(form.values, null, 2)}
        </Code> */}
      </form>
    </Paper>
  );
}
