import { Box, Flex,Grid, Paper, Text } from "@mantine/core";
import CustomerFrom from "./customer_form";
import PageTitle from "../../../ttcomponents/PageTitle/PageTitle";
import WordTranslationComponent from "../../../utils/language_pack/words";

export default  function CreateCustomer(){

  
  // Get translation public configuration information
   const word_translation = WordTranslationComponent();
  // Add customer callback
  const createrCustomerBack = (id: string) => {
      // Immediately refresh the page immediately after the addition, the purpose to initialize the page, so that the next time it is added
      // window.location.reload();
  }
    
    return(
        <Paper px={20}>
        <Box mb={20} style={{ position: "relative", minHeight: "70vh" }} >
          <PageTitle title={word_translation.create_customer_title} />
          <Grid   gutter={0} mt={10}>
            <Grid.Col span={4}  pt={10} >
               <CustomerFrom callBack={createrCustomerBack}></CustomerFrom>
            </Grid.Col>
            <Grid.Col span={4}>
                  {/* 可以写提交过的记录，这里 */}
            </Grid.Col>
          </Grid>
            <Box mt={10}   w={400}>
           
            </Box>
        </Box>
      </Paper>
    )
}