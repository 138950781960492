export function User(props: React.ComponentPropsWithoutRef<'svg'>) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="27" height="26" viewBox="0 0 27 26" fill="none">
  <path d="M20.6581 16.8496L20.658 16.8495L20.6525 16.8535C20.008 17.3188 19.1967 17.6123 18.23 17.7115V0.299446C19.5479 0.433085 20.5819 0.926663 21.2988 1.70803C22.0639 2.54184 22.4919 3.72977 22.4919 5.23014V12.7722C22.4919 14.0726 22.1738 15.1331 21.5827 15.936L21.5827 15.9359L21.5793 15.9408C21.3322 16.2939 21.0164 16.6054 20.6581 16.8496Z" fill="none" stroke="none"/>
  <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" fill="none" stroke="white" stroke-width="2"/>
  <path d="M12 10m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" fill="none" stroke="white" stroke-width="2"/>
  <path d="M6.168 18.849a4 4 0 0 1 3.832 -2.849h4a4 4 0 0 1 3.834 2.855" fill="none" stroke="white" stroke-width="2"/>
</svg>

    
      
    );
  }

