import {
  Paper,
  TextInput,
  Box,
  Group,
  Text,
  Modal,
  Grid,
  Table,
  ActionIcon,
  LoadingOverlay,
  Tooltip,
  Flex,
  Badge,
  Dialog,
  Button,
  Textarea,
} from "@mantine/core";
import { FormEvent, useEffect, useState } from "react";
import { Supplier, SuppliersData } from "../../../../interface/Iinventory";
import { useDisclosure } from "@mantine/hooks";

import PageTitle from "../../../../ttcomponents/PageTitle/PageTitle";
import {
  IconEdit,
  IconPencil,
  IconSearch,
  IconTrash,
  IconUserPlus,
  IconUsersMinus,
} from "@tabler/icons-react";
import CreateBtn from "../../../../ttcomponents/Button/CreateBtn";
import { useIntl } from "react-intl";
import { useForm } from "@mantine/form";
import { modals } from "@mantine/modals";
import {
  regGetProduct,
  regGetProductCategory,
  regGetProductPurchase,
  regGetProductPurchaseCheck,
  regGetReturnProductPurchase,
  regGetReturnProductReason,
  regGetSupplier,
  regProduct,
  regProductPurchase,
  regSupplier,
} from "../../../../api";
import { HintInfo, formatDateUpgraded } from "../../../../utils/function";
import SearchBox from "../../../../ttcomponents/SearchBox/SearchBox";
import { IPagination } from "../../../../interface/Ipagintion";
import PaginationA from "../../../../ttcomponents/Pagination/PaginationA";
import { IProductResponse, Product } from "../../../../interface/IProduct";
import { DetailCustome } from "../../../../public/svg/DetailCustome";
import SupplierSelect from "../../../../ttcomponents/Supplier/SupplierSelect";
import { ICategory } from "../../../../interface/IProductCategory";
import {
  Cancellation,
  Purchase,
  Receipt,
} from "../../../../public/svg/ProductOrder";
// import ProductProcurementFrom from "./product_procurement_from";
import {
  IProductProcurementData,
  IProductProcurementOrderData,
} from "../../../../interface/IProductProcurement";
import {
  IReturnProduct,
  IReturnProductData,
} from "../../../../interface/IreturnProduct";

export default function ReturnReasonIndex() {
  // useIntl for multiple language
  const intl = useIntl();
  // Edit modal status
  const [openedFrom, { open: openFrom, close: closeFrom }] =
    useDisclosure(false);

  // return the goods
  const [returnOpened, { toggle: openReturnOpened, close: closeReturnOpened }] =
    useDisclosure(false);

  // contact modal status
  const [
    ProductProcurementShow,
    { open: openProductProcurementShow, close: colseProductProcurementShow },
  ] = useDisclosure(false);

  const [loading, setLoading] = useState(false);
  // Form type
  const [formType, setFormType] = useState("create");
  const [currentRowInfo, setCurrentRowInfo] = useState<
    IReturnProductData | undefined
  >();
  const [returnNotes, setReturnNotes] = useState("");

  // define the supplier list
  const [productProcurementList, setProductProcurementList] =
    useState<IReturnProduct>({
      total: 0,
      data: [], // Use the data property from the first item in the mock data array
    });

  const [pagination, setPagination] = useState<IPagination>({
    search: "",
    page: 1,
    pagesize: 10,
    sortBy: "",
    sortOrder: "asc",
    query: {},
  });
  // Receive the callback return data, search for content
  const ReceiveSearchBack = (value: string | number) => {
    setPagination((prevpagination) => ({
      ...prevpagination,
      search: value,
      page: 0,
      query: {},
    }));
  };
  const ReceiveSupplierBack = (value: any) => {
    if (value.type == "supplier") {
      setPagination((prevpagination) => {
        const { query, ...rest } = prevpagination; // Extract the attributes other than Query to the REST
        if (value.value == "") {
          // If value.value exists, delete the support
          delete query.supplier_id;
        } else {
          query.supplier_id = value.value;
        }

        return {
          ...rest,
          page: 0, // Keep other attributes in REST
          query: query, // Set the modified query
        };
      });
    }
  };

  //Pagling recovery trigger update Terrament recovery trigger update
  const ReceivePaginationABack = (page: number) => {
    setPagination((prevpagination) => ({
      ...prevpagination,
      page: page,
    }));
  };
  // set search value
  const [search, setSearch] = useState("");

  // Define an asynchronous function ajaxcurrenTlist to send AJAX request to obtain the list of suppliers
  const ajaxCurrentList = async () => {
    setLoading(true); // Set the loading status to TRUE, indicating that the data is loading
    let searchStr = {};

    // Check whether there are search conditions, if so, build a search parameter object
    if (search) {
      searchStr = {
        search: search,
      };
    }

    // Send GET requests to get supplier data
    const responseReturnProductReason = await regGetReturnProductReason(
      pagination,
      "GET"
    );

    setLoading(false); // After the data is loaded, set the loading status to false
    if (responseReturnProductReason.data.code === 200) {
      setProductProcurementList(responseReturnProductReason.data.data); // 如果响应状态码为200，将供应商列表更新为响应数据中的数据
    }
  };

  // Use the USEEFFECT hook. When Search changes, call the AjaxCurrenTList function
  useEffect(() => {
    ajaxCurrentList();
  }, [pagination]);

  // Edit supplier
  const handleEdit = async (row: IReturnProductData) => {
    setFormType("edit");
    openProductProcurementShow();
    setCurrentRowInfo(row);
  };

  // Delete supplier
  const handleDel = (row: IReturnProductData) => {
    modals.openConfirmModal({
      title: "Delete this row",
      centered: true,
      children: (
        <Text size="sm" mt={12}>
          Supplier Name: {row.return_reason}
        </Text>
      ),
      labels: {
        confirm: intl.formatMessage({
          id: "confirm_delete",
          defaultMessage: "Confirm Delete",
        }),
        cancel: intl.formatMessage({
          id: "cancel_delete",
          defaultMessage: "Cancel Delete",
        }),
      },
      confirmProps: { color: "red" },
      onConfirm: () => delSupplierInfo(row.return_reason_id),
    });
  };

  // Delete supplier info to backend
  const delSupplierInfo = async (id: string) => {
    const productResult = await regProductPurchase(
      { id_purchasing: id, confirm_deletion: "no" },
      "DELETE"
    );
    HintInfo(productResult.data);
    if (productResult.data.code == 200) ajaxCurrentList();
  };

  // Create supplier
  const handleCallback = async () => {
    openProductProcurementShow();
    setCurrentRowInfo(undefined);
  };
  //  Review this order

  // examination passed
  const examinationPassed = async (row: IProductProcurementOrderData) => {
    const examinationPassedResult = await regGetProductPurchaseCheck(
      { id_purchasing: row.id_purchasing, flag: 1 },
      "PUT"
    );
    HintInfo(examinationPassedResult.data);
    if (examinationPassedResult.data.code == 200) ajaxCurrentList();
    setCurrentRowInfo(undefined);
  };

  // Returntreatment
  const returnTreatment = async (row: IProductProcurementOrderData) => {
    const examinationPassedResult = await regGetReturnProductPurchase(
      { id_purchasing: row.id_purchasing, flag: 1 },
      "PUT"
    );
    HintInfo(examinationPassedResult.data);
    if (examinationPassedResult.data.code == 200) ajaxCurrentList();
    setCurrentRowInfo(undefined);
  };

  const rows = productProcurementList.data.map((row) => {
    return (
      <tr className="border-bt-2 my-10 ">
        <>
          <td> {row.return_reason_id ? row.return_reason_id : "-"} </td>
          <td> {row.return_reason ? row.return_reason : "-"} </td>
          <td>
            <Group spacing={0} position="center">
              <ActionIcon onClick={() => handleEdit(row)}>
                <Tooltip label="Edit">
                  <IconEdit size="1.2rem" stroke={1.5} />
                </Tooltip>
              </ActionIcon>
              <ActionIcon onClick={() => handleDel(row)}>
                <Tooltip label="Delete">
                  <IconTrash size="1.2rem" stroke={1.5} color="red" />
                </Tooltip>
              </ActionIcon>
            </Group>
          </td>
        </>
      </tr>
    );
  });
  const closeEditFunction = (type: number) => {
    closeFrom();
    colseProductProcurementShow();
    if (type == 2) {
      ajaxCurrentList();
    }
  };
  // list all the suppliers
  return (
    <Box>
      <Paper
        px={20}
        mih={500}
        pb={10}
        w={"100%"}
        style={{ position: "relative" }}
      >
        <PageTitle
          title="RETURN REASON MANAGEMENT"
          lang_id="RETURN REASON MANAGEMENT"
        />
        <Group my={10}>
          <SearchBox callBack={ReceiveSearchBack} />
          <Box h={30}>
            <CreateBtn callBack={handleCallback} />
          </Box>
        </Group>
        <LoadingOverlay visible={loading} overlayBlur={2} />

        <table className="table_custome text-center">
          <tr className="table_th">
            <th>
              {intl.formatMessage({
                id: "Return Reason Id",
                defaultMessage: "Return Reason Id",
              })}
            </th>
            <th>
              {intl.formatMessage({
                id: "Return Reason",
                defaultMessage: "Return Reason",
              })}
            </th>
            <th>
              {intl.formatMessage({
                id: "Option",
                defaultMessage: "Option",
              })}
            </th>
          </tr>

          {rows}
        </table>

        <PaginationA
          callBack={ReceivePaginationABack}
          total_count={productProcurementList.total as number}
          pagesize={pagination.pagesize}
          page={pagination.page}
        />
      </Paper>

      <Modal
        opened={openedFrom}
        onClose={closeFrom}
        title={formType === "create" ? "Create Product" : "Edit Supplier"}
        centered={true}
        size="60%"
        padding="md"
        radius={12}
      >
        {/* <ProductForm productCategory={productCategory} productItem={currentRowInfo} closeFucntion={closeEditFunction} /> */}
      </Modal>

      <Modal
        opened={ProductProcurementShow}
        onClose={colseProductProcurementShow}
        title=" Supplier Contact"
        // centered={true}
        size="60%"
        padding="md"
        radius={12}
      >
        {/* <SupplierContactIndex supplier={currentRowInfo}  /> */}
      </Modal>
      {/* Quick purchase order */}

      <Modal
        opened={ProductProcurementShow}
        onClose={colseProductProcurementShow}
        title=" Quick purchase "
        // centered={true}
        size="60%"
        padding="md"
        radius={12}
      >
        {/* <ProductProcurementFrom unit_price_prohibit_edit={false} additional_information={currentRowInfo}  closeFucntion={closeEditFunction} /> */}
      </Modal>
      <Modal
        opened={returnOpened}
        withCloseButton
        onClose={closeReturnOpened}
        size="xs"
        centered={true}
        radius="md"
      >
        <Text size="sm" mb="xs" weight={500}>
          输入退货原因
        </Text>
        {/* <ReturnProductOrderFrom  unit_price_prohibit_edit={false} additional_information={currentRowInfo}  closeFucntion={closeEditFunction} /> */}
        {/* <Textarea value={returnNotes} onChange={(event) => setReturnNotes(event.currentTarget.value)} />;
                    <Button onClick={closeReturnOpened}>Subscribe</Button> */}
      </Modal>
    </Box>
  );
}
