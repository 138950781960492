import {
  Box,
  Code,
  Overlay,
  Avatar,
  Modal,
  Select,
  Card,
  TextInput,
  Divider,
  Group,
  AspectRatio,
  Tooltip,
  Radio,
  Button,
  ActionIcon,
  TransferListData,
  Table,
  LoadingOverlay,
  Flex,
  Text,
  Paper,
  Grid,
  RadioProps,
} from "@mantine/core";
import {
  IconAlertSquareRounded,
  IconCheck,
  IconClipboardPlus,
  IconEdit,
  IconPlus,
  IconTransferIn,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import { FormEvent, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import SelectProjectFilter from "./select_project";
import SelectUserFilter from "./select_user";
import {
  HintInfo,
  formatDate,
  getWeeKAll,
  getYearAll,
} from "../../../utils/function";
import SearchBox from "../../../ttcomponents/SearchBox/SearchBox";
import { useForm } from "@mantine/form";
import {
  regCreateFilter,
  regGetFilter,
  regItemListByType,
  regReporFilter,
  regReportEmployeeReport,
} from "../../../api";
import {
  IAPIFilterProjectList,
  filterOrderItem,
  filterProjectItem,
  filterTaskItem,
  filterTaskOnlyItem,
  IFilterList,
} from "../../../interface/IReport";
import SelectOrderFilter from "./select_order";
import SelectTaskOnlyFilter from "./select_task_only";
import SelectTaskFilter from "./select_task";
import memoryUtils from "../../../utils/memoryUtils";
import { notifications } from "@mantine/notifications";
import { useDisclosure } from "@mantine/hooks";
import SaveBottom from "../../../ttcomponents/Button/SaveBtn";
import localstorageUnits from "../../../utils/localstorageUnits";
import { get } from "http";
import { modals } from "@mantine/modals";
import "../css/reports_custom.css";
import { Warn } from "../svg/warn";
import PageTitle from "../../../ttcomponents/PageTitle/PageTitle";

export default function ReportsFilter() {
  // Use the useIntl hook to access the intl object
  const intl = useIntl();

  // Task Mode Flag
  const taskModeFlag = localstorageUnits.getSystem().task_mode_flag;

  // 定义过滤参数选项内容
  const [FilterListItem, SetFilterListItem] = useState<IFilterList[]>([]);

  const [FilterListUser, SetFilterListUser] = useState<IFilterList[]>([]);

  // 定义选择选项的类型
  const [FilterListUserSearch, SetFilterListUserSearch] = useState("");

  // 定义选择选项的类型
  const [FilterListItemSearch, setIsFilterItemSearch] = useState("");

  const formRef = useRef<HTMLFormElement>(null);
  //Get all the weekly collection
  const weekNumberAll = getWeeKAll();
  //Get all the Year collection
  const yearNumberAll = getYearAll();

  const [loading, setLoading] = useState(false);
  // 当选择user的时候禁止操作
  const [isOnlyUser, setIsOnlyUser] = useState(false);
  // 定义选择选项的类型
  const [filterType, setFilterType] = useState("project");

  // 定义选择porjectList
  const [filterProjectList, setFilterProjectList] = useState<
    filterProjectItem[]
  >([]);

  // 定义选择orderList
  const [filterOrderList, setFilterOrderList] = useState<filterOrderItem[]>([]);

  // 定义选择taskList
  const [filterTaskList, setFilterTaskList] = useState<filterTaskItem[]>([]);

  // 定义选择taskOnlyList
  const [filterTaskOnlyList, setFilterTaskOnlyList] = useState<
    filterTaskOnlyItem[]
  >([]);

  // 初始化userID
  const [userId, setUseId] = useState(memoryUtils.user.id_user);

  // 定义选择选项的类型
  const [isUserInclude, setIsUserInclude] = useState("1");

  // 定义选择选项的类型
  const [isFilterInclude, setIsFilterInclude] = useState("1");

  // 接收回调返回数据，搜索内容  Receive the callback return data, search for content
  const ReceiveSearchItemBack = (value: string) => {
    console.log(value, "ReceiveSearchBack");
    setIsFilterItemSearch(value);
  };

  // 接收回调返回数据，搜索内容  Receive the callback return data, search for content
  const ReceiveSearchUserBack = (value: string) => {
    console.log(value, "ReceiveSearchUserBack");
    SetFilterListUserSearch(value);
  };

  const form = useForm({
    initialValues: {
      // year: '',
      // start_week: '',
      // end_week: '',
      id_user: userId,
      is_user_include: "1",
      is_filter_include: "1",
      time_interval: "",
      filter_type: "project",
      id_filter_arr: "",
      id_user_arr: "",
      filter_name: "",
      item_status: "0",
    },
    validate: {
      filter_name: (value) =>
        value.length < 1
          ? intl.formatMessage({
              id: "Filter Name Required",
              defaultMessage: "Filter Name Required",
            })
          : null,
      // end_week: (value, values) => {
      //   if (parseInt(values.start_week) > parseInt(value)) {
      //     return 'The end week must be greater than the beginning week';
      //   }
      //   return null;
      // },
    },
  });
  useEffect(() => {
    getFilterListUser();
    getFilterListItem();
    if (filterType == "user") {
      setIsOnlyUser(true);
    } else {
      ajaxCurrentList();
      setIsOnlyUser(false);
    }
  }, [filterType, FilterListUserSearch, FilterListItemSearch]);

  // project TransferList callback
  const handlerCallback = (value: string) => {
    console.log("handlerCallbac", value);
    form.setFieldValue("id_filter_arr", value);
  };
  // 获取过滤器
  const getFilterListItem = async () => {
    const responseFilter = await regReporFilter(
      { type: 1, search: FilterListItemSearch },
      "GET"
    );
    const resultFilter = responseFilter.data;
    SetFilterListItem(resultFilter.data);
  };
  // 获取用户相关的过滤器
  const getFilterListUser = async () => {
    const responseFilter = await regReporFilter(
      { type: 2, search: FilterListUserSearch },
      "GET"
    );
    const resultFilter = responseFilter.data;
    SetFilterListUser(resultFilter.data);
  };
  // User TransferList callback
  const handlerCallbackUser = (value: string) => {
    console.log("handlerCallbackUser", value);
    form.setFieldValue("id_user_arr", value);
  };
  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Stop the formal submission of the form default
    // Verification form

    const valid = await form.validate();

    if (!valid.hasErrors) {
      const response = await regCreateFilter(form.values, "POST");
      const result = response.data;
      console.log(result.data);
      if (result.code === 200) {
        // ajaxCurrentList();
        notifications.show({
          color: "green",
          icon: <IconCheck />,
          message: result.msg,
        });
      } else {
        notifications.show({
          color: "red",
          icon: <IconX />,
          message: result.msg,
        });
      }
    }
  };

  // 选择类型
  const handleFilterFuntion = (value: string) => {
    console.log(value);
    form.setFieldValue("filter_type", value);
    setFilterType(value);
  };

  // 选择 Filter Type Item
  const handleFilterIncludeFuntion = (value: string) => {
    console.log(value);
    form.setFieldValue("is_filter_include", value);
    setIsFilterInclude(value);
  };

  // 选择用户
  const handleUserIncludeFuntion = (value: string) => {
    console.log(value);
    form.setFieldValue("is_user_include", value);
    setIsUserInclude(value);
  };
  // 选择状态
  const handleSelectItemStatus = (value: string) => {
    console.log(value);
    form.setFieldValue("item_status", value as string);
    ajaxCurrentList();
  };

  const delHandle = (row: IFilterList, type: string) => {
    modals.openConfirmModal({
      title: intl.formatMessage({
        id: "delete_title",
        defaultMessage: "Delete Confirmation",
      }),
      centered: true,
      children: (
        <Text size="sm">
          {intl.formatMessage({
            id: "Input Filter Name",
            defaultMessage: "Input Filter Name",
          }) + ": "}
          {row.filter_name}
        </Text>
      ),
      labels: {
        confirm: intl.formatMessage({
          id: "confirm_delete",
          defaultMessage: "Confirm Delete",
        }),
        cancel: intl.formatMessage({
          id: "cancel_delete",
          defaultMessage: "Cancel Delete",
        }),
      },
      confirmProps: { color: "red" },
      onConfirm: async () => {
        const response = await regReporFilter({ id: row.id_filter }, "DELETE");
        if (type == "user") {
          getFilterListUser();
        } else {
          getFilterListItem();
        }
      },
    });
  };
  // 获取请求
  const ajaxCurrentList = async () => {
    setLoading(true);
    // 请求不同type\

    const response = await regItemListByType(
      {
        flag: taskModeFlag,
        type: form.values.filter_type,
        status_id: form.values.item_status,
      },
      "GET"
    );
    setLoading(false);
    // const response = await regItemListByType({ type: 'task_only', status_id: form.values.item_status }, "GET");
    const result = response.data as IAPIFilterProjectList;

    switch (filterType) {
      case "project":
        setFilterProjectList(result.data);
        break;
      case "order":
        setFilterOrderList(result.data);
        break;
      case "task":
        setFilterTaskList(result.data);
        break;
      case "task_only":
        setFilterTaskOnlyList(result.data);
        break;
    }
  };

  return (
    <Paper
      px={20}
      w={"100%"}
      className="scrollable-container-table"
      style={{ position: "relative" }}
    >
      <LoadingOverlay visible={loading} overlayBlur={2} />
      <Box>
        {/* <Code block mt={5}>
        {JSON.stringify(form.values, null, 2)}
      </Code> */}
        <PageTitle title="REPORT FILTER" lang_id="REPORT FILTER" />
      </Box>

      <form onSubmit={handleFormSubmit} ref={formRef}>
        <Box px={10} mt={10}>
          <Grid columns={20} gutter={0}>
            <Box>
              <Text className="form-title">
                {intl.formatMessage({
                  id: "Create A Filter",
                  defaultMessage: "Create A Filter",
                })}
              </Text>
              <Text className="form-title-desc mb-6">
                {intl.formatMessage({
                  id: "Project Filter Can Also Include User",
                  defaultMessage: "Project Filter Can Also Include User",
                })}
              </Text>
              {taskModeFlag == "2" ? (
                <Select
                  onChange={handleFilterFuntion}
                  defaultValue="project"
                  data={["project", "task", "user"]}
                />
              ) : (
                <Select
                  onChange={handleFilterFuntion}
                  defaultValue="project"
                  data={["project", "order", "task", "user"]}
                />
              )}
            </Box>

            <Box className="ml-277">
              <Grid className="mt-27" align="center" justify="center">
                <Box>
                  {" "}
                  <Warn color="gray" />
                </Box>
                <Box>
                  {" "}
                  <Text className="desc-main ">
                    {" "}
                    {intl.formatMessage({
                      id: "Filter Rules",
                      defaultMessage:
                        "A Item filter can also contain an employee filter, An employee filter always stands alone. Filters can also be created using existing filters.",
                    })}
                  </Text>
                </Box>
              </Grid>
            </Box>
          </Grid>
        </Box>
        <Divider my="sm" />
        <Box>
          <Grid columns={22} gutter={0}>
            <Grid.Col span={10}>
              <Box style={{ position: "relative" }}>
                <Box>
                  <Grid grow>
                    <Grid.Col span={4}>
                      <TextInput
                        placeholder={intl.formatMessage({
                          id: "Input Filter Name",
                          defaultMessage: "Input Filter Name",
                        })}
                        description={
                          <Text className="form-title-desc mb-6">
                            {intl.formatMessage({
                              id: "Input Filter Name",
                              defaultMessage: "Input Filter Name",
                            })}
                          </Text>
                        }
                        size="xs"
                        {...form.getInputProps("filter_name")}
                        label={
                          <Text className="form-title">
                            {intl.formatMessage({
                              id: "Filter Name",
                              defaultMessage: "Filter Name",
                            })}
                          </Text>
                        }
                      />
                    </Grid.Col>
                    <Grid.Col span={3} style={{ position: "relative" }}>
                      {isOnlyUser && (
                        <Overlay
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            backgroundColor: "white",
                          }}
                          color="#000"
                          opacity={0.85}
                        />
                      )}
                      <Select
                        placeholder="Search  Item Status"
                        w={120}
                        size="xs"
                        onChange={handleSelectItemStatus}
                        key={form.values.item_status}
                        defaultValue={form.values.item_status}
                        label={intl.formatMessage({
                          id: "Status",
                          defaultMessage: "Status",
                        })}
                        description={
                          <Text className="form-title-desc mb-6">
                            {intl.formatMessage({
                              id: "Select Status",
                              defaultMessage: "Select Status",
                            })}
                          </Text>
                        }
                        searchable
                        data={[
                          { value: "0", label: "All" },
                          { value: "1", label: "Ready" },
                          { value: "2", label: "Running" },
                          { value: "3", label: "Finished" },
                          { value: "4", label: "Cancelled" },
                          { value: "5", label: "Paused" },
                          { value: "6", label: "Archived" },
                        ]}
                      />
                    </Grid.Col>

                    <Grid.Col span={4} style={{ position: "relative" }}>
                      {isOnlyUser && (
                        <Overlay
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            backgroundColor: "white",
                          }}
                          color="#000"
                          opacity={0.85}
                        />
                      )}
                      <Radio.Group
                        value={isFilterInclude}
                        onChange={(value) => handleFilterIncludeFuntion(value)}
                        size="xs"
                        name="isFilterInclude"
                        label={
                          <Text className="form-title">
                            {intl.formatMessage({
                              id: "Filter Type Item",
                              defaultMessage: "Filter Type Item",
                            })}
                          </Text>
                        }
                        key={"isFilterInclude"}
                        description={
                          <Text className="form-title-desc mb-6">
                            {intl.formatMessage({
                              id: "Include Or Exclude Selection",
                              defaultMessage: "Include Or Exclude Selection",
                            })}
                          </Text>
                        }
                      >
                        <Group mt="md">
                          <Radio
                            value="1"
                            className="single-choice-font"
                            label={intl.formatMessage({
                              id: "Include",
                              defaultMessage: "Include",
                            })}
                          />
                          <Radio
                            value="2"
                            className="single-choice-font"
                            label={intl.formatMessage({
                              id: "Exclude",
                              defaultMessage: "Exclude",
                            })}
                          />
                        </Group>
                      </Radio.Group>
                    </Grid.Col>
                  </Grid>
                </Box>
                <Box style={{ position: "relative" }}>
                  {isOnlyUser && (
                    <Overlay
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "white",
                      }}
                      color="#000"
                      opacity={0.85}
                    />
                  )}
                  {filterType === "project" && (
                    <SelectProjectFilter
                      callBack={handlerCallback}
                      projectData={filterProjectList}
                    />
                  )}
                  {filterType === "order" && (
                    <SelectOrderFilter
                      callBack={handlerCallback}
                      orderData={filterOrderList}
                    />
                  )}
                  {filterType === "task_only" && (
                    <SelectTaskOnlyFilter
                      callBack={handlerCallback}
                      taskOnlyData={filterTaskOnlyList}
                    />
                  )}
                  {filterType === "task" && (
                    <SelectTaskFilter
                      callBack={handlerCallback}
                      taskData={filterTaskList}
                    />
                  )}
                  {filterType === "user" && (
                    <SelectProjectFilter
                      callBack={handlerCallback}
                      projectData={filterProjectList}
                    />
                  )}
                </Box>
              </Box>
            </Grid.Col>
            <Grid.Col offset={1} span={10}>
              <Group position="right" mr={60}>
                <Radio.Group
                  value={isUserInclude}
                  onChange={(value) => handleUserIncludeFuntion(value)}
                  size="xs"
                  name="isUserInclude"
                  label={
                    <Text className="form-title">
                      {intl.formatMessage({
                        id: "Filter Type User",
                        defaultMessage: "Filter Type User",
                      })}
                    </Text>
                  }
                  key={"isUserInclude"}
                  description={intl.formatMessage({
                    id: "Include Or Exclude Selection",
                    defaultMessage: "Include Or Exclude Selection",
                  })}
                >
                  <Group mt="md">
                    <Radio
                      value="1"
                      className="single-choice-font"
                      label={intl.formatMessage({
                        id: "Include",
                        defaultMessage: "Include",
                      })}
                    />
                    <Radio
                      value="2"
                      className="single-choice-font"
                      label={intl.formatMessage({
                        id: "Exclude",
                        defaultMessage: "Exclude",
                      })}
                    />
                  </Group>
                </Radio.Group>
              </Group>
              <SelectUserFilter callBack={handlerCallbackUser} />
            </Grid.Col>
          </Grid>
          <Grid columns={22} gutter={0}>
            <Grid.Col span={10}></Grid.Col>
            <Grid.Col offset={1} span={10}>
              <Group position="right">
                <Button mt={10} leftIcon={<IconTrash />} size="xs" color="red">
                  Rest
                </Button>
                <SaveBottom formRef={formRef} />
              </Group>
            </Grid.Col>
          </Grid>
          <Divider my="sm" />

          <Grid columns={22} gutter={0}>
            <Grid.Col span={10}>
              <Text className="form-title mb-6">
                <FormattedMessage
                  id="Search Item Filter"
                  defaultMessage="Search Item Filter"
                />
              </Text>

              <SearchBox callBack={ReceiveSearchItemBack} />
              <Card withBorder className="mt-14">
                <Grid columns={20}>
                  <Grid.Col span={4}>
                    {" "}
                    <Text className="table-title">
                      {intl.formatMessage({
                        id: "Filter Name",
                        defaultMessage: "Filter Name",
                      })}
                    </Text>{" "}
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Text className="table-title">
                      {intl.formatMessage({
                        id: "Item Count",
                        defaultMessage: "Item Count",
                      })}
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <Text className="table-title">
                      {intl.formatMessage({
                        id: "Creater",
                        defaultMessage: "Creater",
                      })}
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Text className="table-title">
                      {intl.formatMessage({
                        id: "Type",
                        defaultMessage: "Type",
                      })}
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Text className="table-title">
                      {intl.formatMessage({
                        id: "Create At",
                        defaultMessage: "Create At",
                      })}
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Text className="table-title">
                      {intl.formatMessage({
                        id: "Option",
                        defaultMessage: "Option",
                      })}
                    </Text>
                  </Grid.Col>
                </Grid>

                {FilterListItem.length > 0 ? (
                  FilterListItem.map((item, index) => {
                    return (
                      <>
                        <Grid columns={20} gutter={0}>
                          <Grid.Col span={4}>
                            <Text className="table-td">{item.filter_name}</Text>{" "}
                          </Grid.Col>
                          <Grid.Col span={3}>
                            <Text className="table-td">
                              {" "}
                              {item.items_count}
                            </Text>
                          </Grid.Col>
                          <Grid.Col span={4}>
                            <Text className="table-td">
                              <Avatar
                                src={item.create_user.avatar}
                                radius="xl"
                                size="sm"
                              />
                            </Text>
                          </Grid.Col>
                          <Grid.Col span={3}>
                            <Text className="table-td">
                              {item.is_include == 1 ? "Include" : "Exclude"}
                            </Text>
                          </Grid.Col>
                          <Grid.Col span={3}>
                            <Text className="table-td">{item.create_at}</Text>
                          </Grid.Col>
                          <Grid.Col span={3}>
                            <Text className="table-td">
                              <Group>
                                <Tooltip label="delete">
                                  <ActionIcon>
                                    <IconTrash
                                      onClick={() => delHandle(item, "item")}
                                      size="1rem"
                                      stroke={1}
                                    />{" "}
                                  </ActionIcon>
                                </Tooltip>
                              </Group>
                            </Text>
                          </Grid.Col>
                        </Grid>
                        <Divider my={3} labelPosition="center" />
                      </>
                    );
                  })
                ) : (
                  <Divider
                    my="xs"
                    label={
                      <FormattedMessage id="No Data" defaultMessage="No Data" />
                    }
                    labelPosition="center"
                  />
                )}
              </Card>
            </Grid.Col>
            <Grid.Col offset={1} span={10}>
              <Text className="form-title mb-6">
                <FormattedMessage
                  id="Search User Filter"
                  defaultMessage="Search User Filter"
                />
              </Text>
              <SearchBox callBack={ReceiveSearchUserBack} />
              <Card withBorder mt={10}>
                <Grid columns={20}>
                  <Grid.Col span={4}>
                    {" "}
                    <Text className="table-title">
                      {intl.formatMessage({
                        id: "Filter Name",
                        defaultMessage: "Filter Name",
                      })}
                    </Text>{" "}
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Text className="table-title">
                      {intl.formatMessage({
                        id: "Item Count",
                        defaultMessage: "Item Count",
                      })}
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <Text className="table-title">
                      {intl.formatMessage({
                        id: "Creater",
                        defaultMessage: "Creater",
                      })}
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Text className="table-title">
                      {intl.formatMessage({
                        id: "Type",
                        defaultMessage: "Type",
                      })}
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Text className="table-title">
                      {intl.formatMessage({
                        id: "Create At",
                        defaultMessage: "Create At",
                      })}
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Text className="table-title">
                      {intl.formatMessage({
                        id: "Option",
                        defaultMessage: "Option",
                      })}
                    </Text>
                  </Grid.Col>
                </Grid>

                {FilterListUser.length > 0 ? (
                  FilterListUser.map((item, index) => {
                    return (
                      <>
                        <Grid columns={20} gutter={0}>
                          <Grid.Col span={4}>
                            <Text className="table-td">{item.filter_name}</Text>{" "}
                          </Grid.Col>
                          <Grid.Col span={3}>
                            <Text className="table-td">
                              {" "}
                              {item.items_count}
                            </Text>
                          </Grid.Col>
                          <Grid.Col span={4}>
                            <Text className="table-td">
                              <Avatar
                                src={item.create_user.avatar}
                                radius="xl"
                                size="sm"
                              />
                            </Text>
                          </Grid.Col>
                          <Grid.Col span={3}>
                            <Text className="table-td">
                              {item.is_include == 1 ? "Include" : "Exclude"}
                            </Text>
                          </Grid.Col>
                          <Grid.Col span={3}>
                            <Text className="table-td">{item.create_at}</Text>
                          </Grid.Col>
                          <Grid.Col span={3}>
                            <Text className="table-td">
                              <Group>
                                <Tooltip label="delete">
                                  <ActionIcon>
                                    <IconTrash
                                      onClick={() => delHandle(item, "item")}
                                      size="1rem"
                                      stroke={1}
                                    />{" "}
                                  </ActionIcon>
                                </Tooltip>
                              </Group>
                            </Text>
                          </Grid.Col>
                        </Grid>
                        <Divider my={3} labelPosition="center" />
                      </>
                    );
                  })
                ) : (
                  <Divider
                    my="xs"
                    label={
                      <FormattedMessage id="No Data" defaultMessage="No Data" />
                    }
                    labelPosition="center"
                  />
                )}
              </Card>
            </Grid.Col>
          </Grid>
        </Box>
      </form>
    </Paper>
  );
}
