import { Alert, Box } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";
import WordTranslationComponent from "../../utils/language_pack/words";

interface ComponentInterface{
    pt?:number;
    px?:number
}
export default function NoData({pt = 40,px = 40}:ComponentInterface) {
    // Get translation public configuration information
   const word_translation = WordTranslationComponent();
  return (
    <Box w='100%' className="text-center" px={px} pt={pt}>
     
    <Alert icon={<IconAlertCircle size="1rem" />} title={word_translation.hiti}>
      {word_translation.no_data}
   </Alert>
    </Box>
  );
}