import { Badge, Box, Card, Flex, Grid, Group, Text } from "@mantine/core";
import {ProjectDataInterface } from "../../../../../interface/Iprojects";
import {  IconPackage } from "@tabler/icons-react";
import { useIntl } from "react-intl";
import WordTranslationComponent from "../../../../../utils/language_pack/words";
import InfoItem from "./item";
import { getAssignmentStatusColorByValue, getAssignmentStatusLabelByValue } from "../../../../../utils/function";
interface ComponentInterface{
  row:ProjectDataInterface
}
export default function ProjectInfo({ row } :ComponentInterface) {
  // Use the useIntl hook to access the intl object
  const intl = useIntl();
  // Get translation public configuration information
  const word_translation = WordTranslationComponent();
  
  return (
    <Grid mt={10} className="bg-img-light">
      <Grid.Col span={4} p={2} className="bg-blue">
        <Box  className="">
          <Text fz="xl" fw={700} c="#fff">{word_translation.customer_overview}</Text>
          <Flex justify="left" align="center" mt={10}>
            <Box mt={10}>
              <IconPackage color="white" />
            </Box>
            <Box mx={10}>
              <Text fz="xs" fw={500} c="#fff">
                {intl.formatMessage({
                  id: "company_name",
                  defaultMessage: "company_name",
                })}
              </Text>
              <Text fz="sm" fw={500} c="#fff">
                {/* {row.company_name} */}
              </Text>
            </Box>
          </Flex>

          <Flex justify="left" align="center" mt={10}>
            <Box mt={10}>
              <IconPackage color="white" />
            </Box>
            <Box mx={10}>
              <Text fz="xs" fw={500} c="#fff">
                {intl.formatMessage({
                  id: "Email",
                  defaultMessage: "Email",
                })}
              </Text>
              <Text fz="sm" fw={500} c="#fff">
                {/* {row.email} */}
              </Text>
            </Box>
          </Flex>
          <Flex justify="left" align="center" mt={10}>
            <Box mt={10}>
              <IconPackage color="white" />
            </Box>
            <Box mx={10}>
              <Text fz="xs" fw={500} c="#fff">
                {intl.formatMessage({
                  id: "phone",
                  defaultMessage: "phone",
                })}
              </Text>
              <Text fz="sm" fw={500} c="#fff">
                {/* {row.tel} */}
              </Text>
            </Box>
          </Flex>
          <Flex justify="left" align="center" mt={10}>
            <Box mt={10}>
              <IconPackage color="white" />
            </Box>
            <Box mx={10}>
              <Text fz="xs" fw={500} c="#fff">
                {intl.formatMessage({
                  id: "Postal Code",
                  defaultMessage: "Postal Code",
                })}
              </Text>
              <Text fz="sm" fw={500} c="#fff">
                {/* {row.postalcode} */}
              </Text>
            </Box>
          </Flex>
          <Flex justify="left" align="center" mt={10}>
            <Box mt={10}>
              <IconPackage color="white" />
            </Box>
            <Box mx={10}>
              <Text fz="xs" fw={500} c="#fff">
                {intl.formatMessage({
                  id: "City",
                  defaultMessage: "City",
                })}
              </Text>
              <Text fz="sm" fw={500} c="#fff">
                {/* {row.city} */}
              </Text>
            </Box>
          </Flex>
          <Flex justify="left" align="center" mt={10}>
            <Box mt={10}>
              <IconPackage color="white" />
            </Box>
            <Box mx={10}>
              <Text fz="xs" fw={500} c="#fff">
                {intl.formatMessage({
                  id: "Website",
                  defaultMessage: "Website",
                })}
              </Text>
              <Text fz="sm" fw={500} c="#fff">
                {/* {row.website} */}
              </Text>
            </Box>
          </Flex>
        </Box>
      </Grid.Col>
      <Grid.Col span={8} p={1}>
        <Card className="nobg">
          <Text fz="xl" fw={700} c="#000">{row.name}</Text>
          <InfoItem value= {row.project_no} icon={ <IconPackage  size={20}/>} title={word_translation.project_no}/>
          <InfoItem value= {row.estimated_time} icon={ <IconPackage  size={20}/>} title={word_translation.estimated_time}/>
          <InfoItem value= {row.estimated_start_date} icon={ <IconPackage  size={20}/>} title={word_translation.start_time}/>
          <InfoItem value= {row.estimated_end_date} icon={ <IconPackage  size={20}/>} title={word_translation.end_time}/>
          <InfoItem value= {row.actual_used_time} icon={ <IconPackage  size={20}/>} title={word_translation.actual_time}/>
          <InfoItem value= {row.actual_start_date} icon={ <IconPackage  size={20}/>} title={word_translation.actual_start_time}/>
          <InfoItem value= {row.actual_end_date} icon={ <IconPackage  size={20}/>} title={word_translation.actual_end_time}/>
          <InfoItem value= {row.estimated_end_date} icon={ <IconPackage  size={20}/>} title={word_translation.end_time}/>
          <InfoItem value= {row.info} icon={ <IconPackage  size={20}/>} title={word_translation.info}/>
          <InfoItem value= {row.project_employee_roles?.length} icon={ <IconPackage  size={20}/>} title={word_translation.employee_count}/>
          <InfoItem 
          value= {<Badge  color= {getAssignmentStatusColorByValue(row.status_id)}> {getAssignmentStatusLabelByValue(row.status_id)}</Badge>}  
          title={word_translation.status} 
           icon={ <IconPackage  size={20}/>} />
         
        </Card>
      </Grid.Col>
    </Grid>
  );
}
