import {
  Box,
  Grid,
  Avatar,
  Flex,
  Group,
  Text,
  Paper,
  Checkbox,
  Radio,
  Textarea,
  Select,
  Divider,
  ActionIcon,
  TextInput,
  Alert,
  LoadingOverlay,
} from "@mantine/core";
import "../css/index.css";
import { DatePicker } from "@mantine/dates";
import { useEffect, useRef, useState } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import LeaveRows from "../leave_row/leave_row";
import memoryUtils from "../../../utils/memoryUtils";
import {
  regGetSurplusVacation,
  regVacation,
  regVacationCaledar,
} from "../../../api";
import {
  EventData,
  IUserVaction,
  IcategoryInteface,
  Vacation,
} from "../../../interface/Ivaction";
import { SelectRole } from "../../../interface/Icommon";
import {
  ClientWarningHint,
  HintInfo,
  HintInfoSuccess,
  JudgmentTimeThreshold,
  formatDateUpgraded,
} from "../../../utils/function";
import localstorageUnits from "../../../utils/localstorageUnits";
import PageTitle from "../../../ttcomponents/PageTitle/PageTitle";
import WordTranslationComponent from "../../../utils/language_pack/words";
import TableTextInputTitle from "../../../ttcomponents/TableTextInputTitle/TableTextInputTitle";
import ResetBtn from "../../../ttcomponents/Button/ResetBtn";
import UniversalButton from "../../../ttcomponents/Button/UniversalButton";
import { IconAlertCircle, IconAlertCircleFilled, IconBeach, IconInfoSquare, IconX } from "@tabler/icons-react";
import { getLeaveCateGoryListListGeneralFunction } from "../../../api/leave_request";
import React from "react";
import EmployeeCalendar from "../calendar/employee_calendar";
import CheckPermissionTools from "../../../utils/permission";
import { useClickOutside } from "@mantine/hooks";



export default function LeaveRequest() {
  const [year, setYear] = useState('');
  // Get translation public configuration information
  const word_translation = WordTranslationComponent();
  const Perm = CheckPermissionTools();
  //Set the request start time
  const [startTimeValue, setStartTimeValue] = useState<Date | null>(null);
  const [maxTimeValue, setMaxTimeValue] = useState<Date | null>(null);
  //Set the request end time
  const [endTimeValue, setEndTimeValue] = useState<Date | null>(null);
  //   For a long time
  const [checkedHalf, setHalfChecked] = useState(false);
  // is show select category
  const [selectCategoryStatus, setSelectCategoryStatus] = useState("");
  // Settings
  const [commnetvalue, setCommentValue] = useState("");
  // User holiday info
  const [userHoliday, setUserHoliday] = useState<IUserVaction>();
  const [events, setVactionCaledarSelect] = useState<any[]>([]);

  const [loading, setLoading] = useState(false);
  const [CaledarValue, setCaledarValue] = useState("team");

  const [categoryLeave, setCategoryLeave] = useState('');
  const [timeHours, setTimeHours] = useState('00:00');
  const [hint, setHint] = useState('');

  const [rand, setRand] = useState(1);
  const [dispalySatus, setDispalySatus] = useState('display');
  // Get all holidays for an individual
  const [UserVationData, SetUserVationData] = useState<Vacation[]>([]);

  const [sickTime, setSickTime] = useState<IcategoryInteface>();
  const [vacationTime, setVacationTime] = useState<IcategoryInteface>();
  const [specialLeaveTimeList, setSpecialLeaveTimeList] = useState<SelectRole[]>([]);

  const [opened, setOpened] = useState(true);
  const ref = useClickOutside(() => setOpened(false));

  // Get the initial time of the system
  const initSystemTime = localstorageUnits.getSystem().init_use_date;

  // Get the current user information
  const userExists = memoryUtils.user;

  // ReCord for the application for the application
  const getVationRecord = async (where: any) => {
    //Get the holiday request of the current user
    const VactionUserRespone = await regVacation(where, "GET");
    SetUserVationData(VactionUserRespone.data.data);


  };


  //刷新个人信息

  const getVacationCaledar = async (where: any) => {
    const VactionUserCaledarRespone = await regVacationCaledar(where, "GET");
    if (VactionUserCaledarRespone.data.code === 200) {
      const TestData = VactionUserCaledarRespone.data.data
        .vacation as EventData[];
      // Start and END fields in the formatting data are string in ISO 8601 format
      const formattedData = TestData?.map(({ start, end, ...item }) => ({
        start: new Date(Date.parse(start)),
        end: new Date(Date.parse(end)),
        ...item,
      }));
      setVactionCaledarSelect(formattedData);
    }
  };



  const callBackLeave = async () => {
    let data = {
      year: year,
      user_id: userExists.id_user
    }
    getSelfLeaveInfo()
    getVationRecord(data)
  };


  //刷新个人信息

  const getSelfLeaveInfo = async () => {
    //Get personal remaining holiday information
    const surplusVactionRespone = await regGetSurplusVacation({ user_id: userExists.id_user, year: year }, "GET");
    if (surplusVactionRespone.data.code == 200) {
      setUserHoliday(surplusVactionRespone.data.data);
    }
  };

  const callBackCalendar = async (value: any) => {
    let data = {
      year: value,
      user_id: userExists.id_user
    }
    setYear(value)
  };


  // Execute the useeffect when the first load is loaded.
  const issInitialMount = useRef(true);
  useEffect(() => {
    if (!issInitialMount.current) {
      getSelfLeaveInfo();
      getVacationCaledar({ user_id: userExists.id_user, year: year })
      getVationRecord({ user_id: userExists.id_user, year: year });
    }
    issInitialMount.current = false;
  }, [year]);

  // Page initialization function
  useEffect(() => {
    getSelfLeaveInfo();
    getCategoryFunction();
    getVacationCaledar({ user_id: userExists.id_user, year: year })
    getVationRecord({ user_id: userExists.id_user, year: year });
  }, []);


  const applyVacationRequest = async () => {
    //Application start time
    if (!startTimeValue) {
      ClientWarningHint("There is no choice from the start time ");
      return;
    }

    if (JudgmentTimeThreshold(startTimeValue as Date) === false) {
      return false;
    }

    if (categoryLeave == '') {
      ClientWarningHint("Please choose a type of leave");
      return false;
    }
    //Submit data initialization
    const originData = {
      user_id: userExists.id_user,
      status_id: "1",
      hours: timeHours,
      from: formatDateUpgraded(startTimeValue as Date, "YYYY-MM-DD HH:mm:ss"),
      to: endTimeValue
        ? formatDateUpgraded(endTimeValue as Date, "YYYY-MM-DD HH:mm:ss")
        : "",
      // "days":"3",
      flag_halfday: checkedHalf ? 1 : 0,
      leave_category_id: categoryLeave,
      user_remarks: commnetvalue,
    };
    setLoading(true)
    const surplusVactionRespone = await regVacation(originData, "POST");
    setLoading(false)
    if (surplusVactionRespone.data.code == 200) {
      HintInfoSuccess(word_translation.promptForSuccessfulLeaveRequest)
      setOpened(true)
      setHint(word_translation.promptForSuccessfulLeaveRequest)
      getVationRecord({ user_id: userExists.id_user });
      getSelfLeaveInfo()
      callBackCalendar(year)
      setStartTimeValue(null);
      setEndTimeValue(null);
      setTimeHours('00:00');
      setCommentValue("");
      setRand(rand + 1);
    } else {
      setOpened(true)
      setHint(surplusVactionRespone.data.msg)
      HintInfo(surplusVactionRespone.data);
    }
  };



  /**
   * get  leave category list <FUNCTION>
   */
  const getCategoryFunction = async () => {
    try {
      const result = await getLeaveCateGoryListListGeneralFunction();
      setSickTime(result.sick)
      setSelectCategoryStatus(result.vacation.id_leave_category.toString())
      setCategoryLeave(result.vacation.id_leave_category.toString())
      setVacationTime(result.vacation)
      setSpecialLeaveTimeList([
        {
          value: 'post_leave',
          label: word_translation.post_leave,
        },
        ...result.special.map((item: any) => {
          return {
            value: item.id_leave_category as string,
            label: item.name,
          };
        })
      ]);
      setRand(rand + 1);
    } catch (e) {

    }

  }

  /**
   * select category type <FUNCTION>
   */
  const ClickLeaveTypeFunction = (value: any) => {
    setSelectCategoryStatus(value)
    if (value == 'special') {
      setCategoryLeave('')
    } else {
      setRand(rand + 1);
      setCategoryLeave(value)
    }
  }

  function callBackUser(value: Vacation): void { }
  const setCategoryLeaveFunction = (value: string) => {
    if (value == 'post_leave') {
      setSelectCategoryStatus((vacationTime?.id_leave_category as string).toString())
      setCategoryLeave((vacationTime?.id_leave_category as string).toString())
      setRand(rand + 1);
    } else {
      setCategoryLeave(value)
    }
  }
  // reset data 《FUNCTION》
  const restInfoFunction = () => {
    setStartTimeValue(null)
    setEndTimeValue(null)
    setHalfChecked(false)
    setCommentValue('')
    setTimeHours('00:00')
    setRand(rand + 1);
  }




  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setHint('');
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [hint]);


  // Page initialization function
  useEffect(() => {// 获取年底日期
    if (startTimeValue) {
      const year = startTimeValue.getFullYear(); // 获取年份
      const endOfYear = new Date(year, 11, 31); // 设置为当年的最后一天（12月31日）
      setMaxTimeValue(endOfYear); // 将年底日期设置为 endTimeValue
    }
    setEndTimeValue(startTimeValue);
    setRand(rand + 1);
  }, [startTimeValue]);

  // Page initialization function
  useEffect(() => {
    if (checkedHalf) {
      setEndTimeValue(startTimeValue)
      setDispalySatus('none')
      setRand(rand + 1);
    } else {
      setDispalySatus('block')
    }

  }, [checkedHalf]);


  return (
    <Paper px={20} pb={10} >
      <PageTitle title="LEAVE REQUEST" lang_id="LEAVE REQUEST" />

      <Group mt={10}>
        <TableTextInputTitle class_name="leave-title" title='Leave Request For:' />
        <Radio.Group
          onChange={ClickLeaveTypeFunction}
          size="xs"
          withAsterisk
          key={rand}
          value={selectCategoryStatus}
        >
          <Group>
            <Radio key={rand} value={vacationTime?.id_leave_category.toString()} label={vacationTime?.name} />
            <Radio key={rand} value={sickTime?.id_leave_category.toString()} label={sickTime?.name} />
            <Radio value="special" label="Special" />
          </Group>
        </Radio.Group>
      </Group>

      <Grid mt={2} gutter={20}>
        <Grid.Col span={6} className=""  pos="relative">
        <LoadingOverlay visible={loading} overlayBlur={2} />
          <Box className="border-custom  leave-border">
            <Group position="apart" py={14} px={40}>
              <Box>
                <Group>
                  <TableTextInputTitle class_name="leave-title" title={word_translation.vacation_entitlement} />
                </Group>
              </Box>
              <Box className="btn">
                <Text className="leave-title-btn">
                  {userHoliday ? userHoliday?.remaining_days : word_translation.no_data_v2}{" "}
                  {word_translation.days} For {new Date().getFullYear()}
                </Text>
              </Box>
              <Box className="">
                <Group>
                  <Box>
                    <Text className="leave-name">{userExists.username}</Text>
                    <Text className="leave-role">{userExists.email}</Text>
                  </Box>
                  <Box>
                    <Avatar radius="xl" src={userExists?.avatar} />
                  </Box>
                </Group>
              </Box>
            </Group>
            <Divider color="#E7E7E7" />
            <Box mx={40} my={20}>


              <Grid gutter={30} >
                <Grid.Col span={4}>
                  <Box>

                    <TableTextInputTitle py={0} pb={0} title={word_translation.special_for} />
                    <Select
                      size='xs'
                      searchable
                      mb={10}
                      disabled={selectCategoryStatus == 'special' ? false : true}
                      key={rand}
                      onChange={(event) => setCategoryLeaveFunction(event as string)}
                      rightSection={<ActionIcon size='xs' onClick={() => { setRand(rand + 1); setCategoryLeave(''); }}> <IconX /></ActionIcon>}
                      data={specialLeaveTimeList as []} />
                  </Box>
                  <Box>
                    <TableTextInputTitle py={0} pb={0} title={word_translation.leave_hours} />
                    <TextInput
                      withAsterisk
                      className={`cursor-pointer-bg`}
                      onChange={(event) => { setTimeHours(event.target.value) }}
                      size="xs"
                      disabled={selectCategoryStatus == '2' || selectCategoryStatus == 'special' ? false : true}
                      key={rand}
                      defaultValue={timeHours}
                    />

                  </Box>
                </Grid.Col>


                <Grid.Col pt={30} span={8}>
                  <Flex>
                    <Box>
                      <IconInfoSquare color="#ccc" />
                    </Box>
                    <Box ml={4}>
                      <Text fw={300} size={14}>Leave Hours can only be entered, if only a single  day is selected. </Text>
                    </Box>
                  </Flex>

                  {opened && hint && (
                    <Alert ref={ref} icon={<IconAlertCircle size="1rem" />} title="hint!" withCloseButton closeButtonLabel="Close alert" >
                      {hint} <Text size={12} color="red">(Click anywhere to close)</Text>
                    </Alert>
                  )}
                </Grid.Col>
              </Grid>
              <Grid gutter={0}  >
                <Grid.Col span={6} >
                  <Box>
                    <TableTextInputTitle class_name="leave-title-date" title={word_translation.from} />
                    <TableTextInputTitle class_name="leave-title-date-desc" title={word_translation.select_vacation_start_date} />
                    <DatePicker
                      minDate={new Date(initSystemTime)}
                      className="DatePicker"
                      firstDayOfWeek={0}
                      value={startTimeValue as Date}
                      onChange={setStartTimeValue}
                    />
                  </Box>
                </Grid.Col>
                <Grid.Col span={6}>
                  <Box>
                    <TableTextInputTitle class_name="leave-title-date" title={word_translation.to} />
                    <TableTextInputTitle class_name="leave-title-date-desc" title={word_translation.select_vacation_end_date} />

                    <DatePicker
                      minDate={startTimeValue ? startTimeValue : new Date(initSystemTime)}
                      maxDate={maxTimeValue as Date}
                      className="DatePicker"
                      key={rand + 1}
                      firstDayOfWeek={0}
                      display={dispalySatus}
                      defaultValue={startTimeValue as Date}
                      defaultDate={startTimeValue as Date}
                      onChange={setEndTimeValue}
                    />
                  </Box>
                </Grid.Col>

              </Grid>
              {selectCategoryStatus == '1' && <Checkbox mt={20} key={rand} className="cursor-pointer" checked={checkedHalf}
                label={
                  <>
                    <Group mt={-4}>
                      <TableTextInputTitle class_name="leave-Half cursor-pointer" title={word_translation.half_day} />
                      <TableTextInputTitle class_name="leave-Only cursor-pointer" title={word_translation.only_vaild_for_one_day} />
                    </Group>
                  </>
                }
                onChange={(event) => setHalfChecked(event.currentTarget.checked)}
              />}

              <Textarea
                mt={20}
                className="commnet-content"
                value={commnetvalue}
                onChange={(event) => setCommentValue(event.currentTarget.value)}
              />
              <TableTextInputTitle class_name="leave-manger text-right" title={word_translation.rules} />
              <TableTextInputTitle class_name="leave-manger" title={word_translation.rules_description} />
              <Group position="right">
                <ResetBtn callBack={restInfoFunction} />
                <UniversalButton btn_icon={<IconBeach />} btn_text={word_translation.create} callBack={applyVacationRequest} />
              </Group>
            </Box>
          </Box>

        </Grid.Col>
        <Grid.Col span={6}>
          <Box className="leave-border">
            <LeaveRows
              statusSwich={() => { }}
              callBackUser={callBackUser}
              refreshItem={callBackLeave}
              UserVationData={UserVationData}
              callBack={callBackLeave}
              type={1}
            />
          </Box>

          <Box mt={10} className=" ">
            <TableTextInputTitle class_name="leave-title text-right" title={word_translation.calendar} />

            <Box className="leave-border">
              <Box mx={20} py={10} >
                <Radio.Group
                  value={CaledarValue}
                  onChange={setCaledarValue}
                  name="CaledarValue"
                  withAsterisk
                >
                  <Group>
                    <Radio
                      value="team"
                      label={<TableTextInputTitle title={word_translation.team_calendar} />}
                    />
                    <Radio
                      value="company"
                      disabled
                      label={<TableTextInputTitle title={word_translation.company_calendar} />}
                    />
                  </Group>
                </Radio.Group>
                <EmployeeCalendar events={events} callBack={callBackCalendar} />
              </Box>
            </Box>
          </Box>
        </Grid.Col>
      </Grid>
    </Paper>
  );
}
