import {
  Paper,
  TextInput,
  Box,
  Group,
  Text,
  Modal,
  Grid,
  Table,
  ActionIcon,
  LoadingOverlay,
  Tooltip,
  Flex,
  Badge,
  Dialog,
  Button,
  Textarea,
  Select,
} from "@mantine/core";
import { FormEvent, useEffect, useState } from "react";
import { Supplier, SuppliersData } from "../../../../interface/Iinventory";
import { useDisclosure } from "@mantine/hooks";

import PageTitle from "../../../../ttcomponents/PageTitle/PageTitle";
import {
  IconArrowBackUp,
  IconEdit,
  IconPencil,
  IconSearch,
  IconTrash,
  IconPdf,
  IconUsersMinus,
  IconX,
} from "@tabler/icons-react";
import CreateBtn from "../../../../ttcomponents/Button/CreateBtn";
import { useIntl } from "react-intl";
import { useForm } from "@mantine/form";
import { modals } from "@mantine/modals";
import {
  regGetPickingOutBound,
  regGetProduct,
  regGetProductCategory,
  regGetProductPurchase,
  regGetProductPurchaseCheck,
  regGetRentPdf,
  regGetRentProductList,
  regGetReturnProductPurchase,
  regGetSupplier,
  regProduct,
  regProductPurchase,
  regSupplier,
} from "../../../../api";
import { HintInfo, formatDateUpgraded } from "../../../../utils/function";
import SearchBox from "../../../../ttcomponents/SearchBox/SearchBox";
import { IPagination } from "../../../../interface/Ipagintion";
import PaginationA from "../../../../ttcomponents/Pagination/PaginationA";
import { IProductResponse, Product } from "../../../../interface/IProduct";
import { DetailCustome } from "../../../../public/svg/DetailCustome";
import SupplierSelect from "../../../../ttcomponents/Supplier/SupplierSelect";
import { ICategory } from "../../../../interface/IProductCategory";
import {
  Cancellation,
  Purchase,
  Receipt,
} from "../../../../public/svg/ProductOrder";
import {
  IProductProcurementData,
  IProductProcurementOrderData,
} from "../../../../interface/IProductProcurement";
import { notifications } from "@mantine/notifications";
import {
  IDeliveryData,
  IDeliveryPickData,
  IRentProductData,
  IRentProductResponseData,
} from "../../../../interface/IDeliveryProduct";
import RentProductFrom from "./rent_form";
import ReturnRentProductFrom from "./return_rent_form";
import { useNavigate } from "react-router-dom";
import globalCortSetting from "../../../../utils/globalCortSetting";

interface ComponentInterface {
  is_load_str: string;
}

export default function RentProductIndex({ is_load_str }: ComponentInterface) {
  const navigate = useNavigate();
  // useIntl for multiple language
  const intl = useIntl();
  // Edit modal status
  const [openedFrom, { open: openFrom, close: closeFrom }] =
    useDisclosure(false);

  // return the goods
  const [RentOpened, { toggle: openRentOpened, close: closeRentOpened }] =
    useDisclosure(false);

  // contact modal status
  const [
    RentProductPFromShow,
    { open: openRentProductPFromShow, close: colseReturnRentProductFromShow },
  ] = useDisclosure(false);

  const [loading, setLoading] = useState(false);
  // Form type
  const [formType, setFormType] = useState("create");
  const [currentRowInfo, setCurrentRowInfo] = useState<
    IRentProductData | undefined
  >();
  const [productCategory, setProductCategory] = useState<ICategory[]>([]);
  const [returnNotes, setReturnNotes] = useState("");

  // define the supplier list
  const [productProcurementList, setProductProcurementList] =
    useState<IRentProductResponseData>({
      total: 0,
      data: [], // Use the data property from the first item in the mock data array
    });

  const [pagination, setPagination] = useState<IPagination>({
    search: "",
    page: 1,
    pagesize: 10,
    sortBy: "",
    sortOrder: "asc",
    query: {},
  });

  // Receive the callback return data, search for content
  const ReceiveSearchBack = (value: string | number) => {
    setPagination((prevpagination) => ({
      ...prevpagination,
      search: value,
      page: 0,
      query: {},
    }));
  };

  const ReceiveSupplierBack = (value: any) => {
    if (value.type == "supplier") {
      setPagination((prevpagination) => {
        const { query, ...rest } = prevpagination; // Extract the attributes other than Query to the REST
        if (value.value == "") {
          // If value.value exists, delete the support
          delete query.supplier_id;
        } else {
          query.supplier_id = value.value;
        }

        return {
          ...rest,
          page: 0, // Keep other attributes in REST
          query: query, // Set the modified query
        };
      });
    }
  };

  //Pagling recovery trigger update Terrament recovery trigger update
  const ReceivePaginationABack = (page: number) => {
    setPagination((prevpagination) => ({
      ...prevpagination,
      page: page,
    }));
  };
  // set search value
  const [search, setSearch] = useState("");

  // Define an asynchronous function ajaxcurrenTlist to send AJAX request to obtain the list of suppliers
  const ajaxCurrentList = async () => {
    console.log(is_load_str);
    if (is_load_str === "rent") {
      // setLoading(true); // Set the loading status to TRUE, indicating that the data is loading
      let searchStr = {};
      // Check whether there are search conditions, if so, build a search parameter object
      if (search) {
        searchStr = {
          search: search,
        };
      }
      setLoading(true); // After the data is loaded, set the loading status to false
      // Send GET requests to get supplier data
      const responseProductProcurement = await regGetRentProductList(
        pagination,
        "GET"
      );
      setLoading(false); // After the data is loaded, set the loading status to false
      if (responseProductProcurement.data.code === 200) {
        setProductProcurementList(responseProductProcurement.data.data); // 如果响应状态码为200，将供应商列表更新为响应数据中的数据
      }
    }
  };
  // Use the USEEFFECT hook. When Search changes, call the AjaxCurrenTList function
  useEffect(() => {
    ajaxCurrentList();
  }, [pagination, is_load_str]);

  // Edit supplier
  const handleEdit = async (row: IRentProductData) => {
    setFormType("edit");
    openRentOpened();
    setCurrentRowInfo(row);
  };

  // Delete supplier
  const handleDel = (row: IRentProductData) => {
    modals.openConfirmModal({
      title: "Delete this row",
      centered: true,
      children: (
        <Text size="sm" mt={12}>
          Supplier Name: {row.supplier_name}
        </Text>
      ),
      labels: {
        confirm: intl.formatMessage({
          id: "confirm_delete",
          defaultMessage: "Confirm Delete",
        }),
        cancel: intl.formatMessage({
          id: "cancel_delete",
          defaultMessage: "Cancel Delete",
        }),
      },
      confirmProps: { color: "red" },
      onConfirm: () => delSupplierInfo(row.rent_id),
    });
  };

  // Delete supplier info to backend
  const delSupplierInfo = async (id: string) => {
    const productResult = await regProductPurchase(
      { order_id: id, confirm_deletion: "no" },
      "DELETE"
    );
    HintInfo(productResult.data);
    if (productResult.data.code == 200) ajaxCurrentList();
  };

  // Create supplier
  const handleCallback = async () => {
    openRentProductPFromShow();
    setCurrentRowInfo(undefined);
  };

  //  Review this order
  const reviewFuction = (row: IRentProductData) => {
    modals.openConfirmModal({
      title: "Select an option",
      centered: true,
      children: (
        <Text size="sm">
          You can choose to enter the warehouse after review or return the
          goods.
        </Text>
      ),
      labels: { confirm: "Enter the warehouse", cancel: "Return the goods" },
      confirmProps: { color: "green" },
      cancelProps: { color: "red" },
      onCancel: () => {
        setCurrentRowInfo(row);
        openRentOpened();
      },
      onConfirm: () => {
        examinationPassed(row);
      },
    });
  };
  // examination passed
  const examinationPassed = async (row: IRentProductData) => {
    const examinationPassedResult = await regGetProductPurchaseCheck(
      { rent_id: row.rent_id, flag: 1 },
      "PUT"
    );
    HintInfo(examinationPassedResult.data);
    if (examinationPassedResult.data.code == 200) ajaxCurrentList();
    setCurrentRowInfo(undefined);
  };

  // Returntreatment
  const returnProduct = async (row: IRentProductData) => {
    setCurrentRowInfo(row);
    openRentProductPFromShow();
    // if (row.flag != 2) {
    //     notifications.show({
    //         color: "yellow",
    //         icon: <IconX />,
    //         message: 'This order cannot be returned',
    //     });
    // } else {
    //     openRentOpened()
    // }
  };

  const PdfFucntion = async (row: IRentProductData) => {
    setCurrentRowInfo(row);
    const PdfData = regGetRentPdf(row, "PUT", "blob");
    PdfData.then((response) => {
      const blob = new Blob([response.data], { type: "application/pdf" });
      var a = document.createElement("a");
      var url = window.URL.createObjectURL(blob); // 创建一个URL对象
      a.href = url;
      a.download = "Rental_" + row.contract_no + ".pdf"; // 指定文件名
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const rows = productProcurementList.data.map((row) => {
    let flagStr: any, statusStr: any;
    if (row.status == "1") {
      statusStr = <Badge color="red"> ongoing</Badge>;
    } else if (row.status == "3") {
      statusStr = <Badge color="green">returned</Badge>;
    }

    return (
      <tr className="border-bt-2 my-10 ">
        <>
          <td> {row.rent_id} </td>
          <td> {row.contract_no} </td>
          <td> {row.product_name} </td>
          <td> {row.unit ? row.unit : "-"} </td>
          <td> - </td>
          <td> {row.rent_quantity ? row.rent_quantity : "-"} </td>
          <td> {row.total_price ? row.total_price : "-"} </td>
          <td> {row.name ? row.name : "-"} </td>

          {/* <td> ASC </td> */}
          <td>
            {" "}
            {row.rental_start_time
              ? formatDateUpgraded(
                  new Date(row.rental_start_time),
                  "YYYY-MM-DD"
                )
              : "-"}{" "}
          </td>
          <td>
            {" "}
            {row.rental_end_time
              ? formatDateUpgraded(new Date(row.rental_end_time), "YYYY-MM-DD")
              : "-"}{" "}
          </td>
          <td>
            {" "}
            {row.actual_return_time
              ? formatDateUpgraded(
                  new Date(row.actual_return_time),
                  "YYYY-MM-DD"
                )
              : "-"}{" "}
          </td>
          {/* <td> <Badge  color ={row.status=='renti' ?'blue' :row.type =='order'? "green": "red"} > {row.type}</Badge> </td> */}
          <td> {row.return_quantity ? row.return_quantity : "-"} </td>
          <td>{statusStr}</td>
          <td>
            <Group spacing={0} position="center">
              {row.status == "3" ? (
                <ActionIcon>
                  <Tooltip label="Cannot edit">
                    <IconEdit size="1.2rem" color="#ccc" stroke={1.5} />
                  </Tooltip>
                </ActionIcon>
              ) : (
                <ActionIcon onClick={() => handleEdit(row)}>
                  <Tooltip label="Edit">
                    <IconEdit size="1.2rem" stroke={1.5} />
                  </Tooltip>
                </ActionIcon>
              )}
              <ActionIcon onClick={() => returnProduct(row)}>
                <Tooltip label="Return warehouse">
                  <IconArrowBackUp color="blue" />
                </Tooltip>
              </ActionIcon>

              <ActionIcon onClick={() => PdfFucntion(row)}>
                <Tooltip label="Edit">
                  <IconPdf color="red" />
                </Tooltip>
              </ActionIcon>
              {/* 
                            <ActionIcon onClick={() => handleDel(row)}>
                                <Tooltip label="Delete">
                                    <IconTrash size="1.2rem" stroke={1.5} color="red" />
                                </Tooltip>
                            </ActionIcon> */}
            </Group>
          </td>
        </>
      </tr>
    );
  });
  const closeEditFunction = (type: number) => {
    closeFrom();
    closeRentOpened();
    colseReturnRentProductFromShow();
    if (type == 2) {
      ajaxCurrentList();
    }
  };

  function setSelectValueFunction(value: string | null): void {}
  // list all the suppliers
  return (
    <Box>
      <LoadingOverlay visible={loading} overlayBlur={2} />

      <table className="table_custome text-center">
        <tr className="table_th">
          <th>Rental ID</th>
          <th>Contract No</th>
          <th>Device Name</th>
          <th>
            {intl.formatMessage({
              id: "Unit",
              defaultMessage: "Unit",
            })}
          </th>
          <th>
            {intl.formatMessage({
              id: "Unit Price",
              defaultMessage: "Unit Price",
            })}
          </th>
          <th>Rental Quantity</th>
          <th>Total Price</th>
          <th>Customer</th>
          <th> Rental Start</th>
          <th> Rental End</th>
          <th> Return Time</th>
          <th>Return Quantity</th>
          <th>
            {intl.formatMessage({
              id: "status",
              defaultMessage: "status",
            })}
          </th>
          <th>
            {intl.formatMessage({
              id: "Option",
              defaultMessage: "Option",
            })}
          </th>
        </tr>

        {rows}
      </table>

      <PaginationA
        callBack={ReceivePaginationABack}
        total_count={productProcurementList.total as number}
        pagesize={pagination.pagesize}
        page={pagination.page}
      />

      <Modal
        opened={openedFrom}
        onClose={closeFrom}
        title={formType === "create" ? "Create Product" : "Edit Supplier"}
        centered={true}
        size="60%"
        padding="md"
        radius={12}
      >
        {/* <ProductForm productCategory={productCategory} productItem={currentRowInfo} closeFucntion={closeEditFunction} /> */}
      </Modal>

      <Modal
        opened={RentProductPFromShow}
        onClose={colseReturnRentProductFromShow}
        title=" Supplier Contact"
        // centered={true}
        size="60%"
        padding="md"
        radius={12}
      >
        {/* <SupplierContactIndex supplier={currentRowInfo}  /> */}
      </Modal>
      {/* Quick purchase order */}

      <Modal
        opened={RentProductPFromShow}
        onClose={colseReturnRentProductFromShow}
        title=" Return Product "
        // centered={true}
        size="60%"
        padding="md"
        radius={12}
      >
        <ReturnRentProductFrom
          additional_information={currentRowInfo}
          closeFucntion={closeEditFunction}
        />
      </Modal>
      <Modal
        opened={RentOpened}
        title=" Edit  "
        withCloseButton
        onClose={closeRentOpened}
        size="xl"
        centered={true}
        radius="md"
      >
        <RentProductFrom
          additional_information={currentRowInfo}
          closeFucntion={closeEditFunction}
        />
      </Modal>
    </Box>
  );
}
