import { ActionIcon, Text, Group, Tooltip, Checkbox, Box } from "@mantine/core";
import { useEffect, useState } from "react";
import WordTranslationComponent from "../../../../../../utils/language_pack/words";
import { modals } from "@mantine/modals";
import {
    HintInfo,
    formatDate,
    truncateString,
} from "../../../../../../utils/function";
import GlobalCoreSetting from "../../../../../../utils/globalCortSetting";
import { OrderDataInterface } from "../../../../../../interface/Iorder";
import DeleteIcon from "../../../../../../ttcomponents/DeleteIcon/DeleteIcon";
import { PermissionRoleInterface } from "../../../../../../interface/Iemloyee";
import { IconCheck, IconX } from "@tabler/icons-react";
import { requestManagePermissionDel } from "../../../../../../api";
import { detectOverflow } from "@popperjs/core";

interface User {
    user_id: [] | string;
    costCenter_id: string;
    costCenter_name?: string;
    username: string;
    [key:string]:any;
    user_role_name: string;
}

interface ComponentInterface {
    row: User;
    ischeck:boolean;
    userTaskList?: OrderDataInterface[];
    callBackfunction: (value: string, is_checked: boolean) => void;
    callBackSelectFunction: (value: string, costCenter: string,index:any) => void;
    index: number;
    selectedRole: string;
    callBack: () => void;
    actionApi: (data: any, methed: string) => any;
    Permission: any;
}

export default function ListTr({
    index,
    row,
    callBack,
    ischeck,
    callBackSelectFunction,
}: ComponentInterface) {
    // Get translation public configuration information
    const word_translation = WordTranslationComponent();
    // Get the global configuration
    const globalCoreSetting = GlobalCoreSetting();

    const [isHovered, setIsHovered] = useState(false);
    const [randNumber, setRandNumber] = useState(1);

    // Delete user role
    const handleDel = (row: User) => {
        modals.openConfirmModal({
            title: word_translation.delete_title,
            centered: true,
            children: (
                <>
                    <p>{word_translation.title} : {row.username}  </p>
                    <p>
                        {word_translation.cost_center_name} : {row.costCenter_name}
                    </p>
                </>

            ),
            labels: globalCoreSetting.DeleteOpenConfirmModaLabels,
            confirmProps: globalCoreSetting.DeleteOpenConfirmModaConfirmProps,
            onConfirm: async () => {
                const attributeTypeResult = await requestManagePermissionDel(
                    { user_id: row.user_id, costCenter_id: row.costCenter_id },
                    "DELETE"
                );
                HintInfo(attributeTypeResult.data);
                if (attributeTypeResult.data.code == 200) {
                    callBack();
                }
            },
        });
    };


    useEffect(()=>{
        setRandNumber(randNumber+1);
    },[ischeck])

    /**
     * 当鼠标移入元素时，设置isHovered为true
     */
    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    /**
     * 当鼠标移出元素时，设置isHovered为false
     */
    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const SelectFunction = () => {
        callBackSelectFunction(row.user_id as string, row.costCenter_id as string,index);
    };
    
    
    return (
        <tr
            className="text-center table_tr_xs tr-hover grod-radius assignment_relative "
            key={index}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <td>
                <Group spacing={0} align="center" position="center">
                    <ActionIcon onClick={SelectFunction}>
                        <Tooltip label={word_translation.edit_tips}>
                            <Checkbox
                                size="xs"
                                key={randNumber}
                                checked={ischeck}
                            />
                        </Tooltip>
                    </ActionIcon>
                </Group>
            </td>
            <td onClick={SelectFunction}>
                <Tooltip label={row.username}>
                        <span>{truncateString(row.username, 20)}</span>
                </Tooltip>
            </td>
            <td onClick={SelectFunction}>
                <Tooltip label={row.rolename}>
                        <span>{truncateString(row.rolename, 20)}</span>
                </Tooltip>
            </td>
            <td onClick={SelectFunction}> 
                    <Tooltip label={row?.user_id}>
                        <span>{row?.user_id?.length}</span>
                </Tooltip>
            </td>
            
            <td onClick={SelectFunction}> 
                    <Tooltip label={row?.costCenter_name}>
                        <span>{truncateString(row?.costCenter_name as string, 5)}</span>
                </Tooltip>
            </td>
            <td>
                <Group spacing={0} position="center">
                    <DeleteIcon
                        disabled={false}
                        color={"red"}
                        callBack={() => handleDel(row)}
                    />
                </Group>
            </td>
        </tr>
    );
}
