// 内存：放置用户信息，基本从localstorage中获取
export default {
  user: {
    id_user: "",
    username: "",
    user_type: "",
    email: "",
    avatar: "",
    user_role_id: "",
    approval_permission: "",
    user_role_identifier: "",
  },
};
