import {
    Paper,
    Box,
    Group,
    Text,
    Avatar,
    Grid,
    Table,
    ActionIcon,
    LoadingOverlay,
    Tooltip,
    Badge,
  } from "@mantine/core";
  import { FormEvent, useEffect, useState } from "react";
  import { useDisclosure } from "@mantine/hooks";
  import PageTitle from "../../../ttcomponents/PageTitle/PageTitle";
  
  import apiUrl from '../../../config/api_config/config';
  import CreateBtn from "../../../ttcomponents/Button/CreateBtn";
  import { useIntl } from "react-intl";
  import { modals } from "@mantine/modals";
  import { getEmployeeListToBeArchived, getHiredEmployees, mockapi1, regGetSupplier, regGetUserChangeLog, regSupplier } from "../../../api";
  import { HintInfo, getOnboardingStatusLabel } from "../../../utils/function";
  import SearchBox from "../../../ttcomponents/SearchBox/SearchBox";
  import { IPagination } from "../../../interface/Ipagintion";
  import PaginationA from "../../../ttcomponents/Pagination/PaginationA";
  import { NewBoardingListData, NewBoardingUserData, UserChangeLog } from "../../../interface/Iuser";
  import { IconArchive } from "@tabler/icons-react";
  import GlobalCoreSetting from "../../../utils/globalCortSetting";
  
  export default function UserChangeLogs() {
    // useIntl for multiple language
    const intl = useIntl();
    // Edit modal status
    const [openedEdit, { open: openEdit, close: closeEdit }] =
      useDisclosure(false);
  
    // contact modal status
    const [contactShow, { open: opencontactShow, close: colsecontactShow }] =
      useDisclosure(false);
  
    const [loading, setLoading] = useState(false);
    // Form type
    const [formType, setFormType] = useState("create");
    const [currentRowInfo, setCurrentRowInfo] = useState<NewBoardingListData | undefined>();
  
    // define the supplier list
    const [supplierList, setSupplierList] = useState<UserChangeLog>({
      total_count: 0,
      data: [], // Use the data property from the first item in the mock data array
    });
  
    const [pagination, setPagination] = useState<IPagination>({
      search: "",
      page: 1,
      pagesize: 10,
      sortBy: "",
      sortOrder: "desc",
      query: {},
    });
    // 接收回调返回数据，搜索内容  Receive the callback return data, search for content
    const ReceiveSearchBack = (value: string | number) => {
      setPagination((prevpagination) => ({
        ...prevpagination,
        search: value,
        query: {},
      }));
    };
    // 分页回调触发更新 分页回调触发更新
    const ReceivePaginationABack = (page: number) => {
      setPagination((prevpagination) => ({
        ...prevpagination,
        page: page,
      }));
    };
    // set search value
    const [search, setSearch] = useState("");
  
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget;
      setSearch(value);
    };
    //Define an asynchronous function SearchFunction for setting up search conditions
    const searchFunction = async (value: string) => {
      setSearch(value); // Set the transmitted value to search conditions
    };
  
    // Define an asynchronous function ajaxcurrenTlist to send AJAX request to obtain the list of suppliers
    const ajaxCurrentList = async () => {
      setLoading(true); // Set the loading status to TRUE, indicating that the data is loading
      let searchStr = {};
  
      // Check whether there are search conditions, if so, build a search parameter object
      if (search) {
        searchStr = {
          search: search,
        };
      }
  
      // Send GET requests to get supplier data
      const responseSupplier = await regGetUserChangeLog(pagination, "GET");
  
      console.log(responseSupplier.data.data)
      setLoading(false); // After the data is loaded, set the loading status to false
      if (responseSupplier.data.code === 200) {
        setSupplierList(responseSupplier.data.data); // 如果响应状态码为200，将供应商列表更新为响应数据中的数据
      }
    };
    // Use the USEEFFECT hook. When Search changes, call the AjaxCurrenTList function
    useEffect(() => {
      ajaxCurrentList();
    }, [pagination]);
  
    // Edit supplier
    const handleEdit = (row: NewBoardingListData) => {
      setFormType("edit");
      openEdit();
      setCurrentRowInfo(row);
    };

    // Delete supplier
    const handleDel = (row: NewBoardingListData) => {
      modals.openConfirmModal({
        title: "Delete this row",
        centered: true,
        children: (
          <Text size="sm" mt={12}>
            Supplier Name: {row.personal_no}
          </Text>
        ),
        labels: {
          confirm: intl.formatMessage({
            id: "confirm_delete",
            defaultMessage: "Confirm Delete",
          }),
          cancel: intl.formatMessage({
            id: "cancel_delete",
            defaultMessage: "Cancel Delete",
          }),
        },
        confirmProps: { color: "red" },
        onConfirm: () => delSupplierInfo(row.id_user),
      });
    };
  
    // Delete supplier info to backend
    const delSupplierInfo = async (id: number | string) => {
      const SupplierResult = await regSupplier({ supplier_id: id }, "DELETE");
      HintInfo(SupplierResult.data);
      if (SupplierResult.data.code == 200) ajaxCurrentList();
    };
  
    // Create supplier
    const handleCallback = () => {
      setFormType("create");
      openEdit();
      setCurrentRowInfo(undefined);
    };
  
    const contackList = (row: NewBoardingListData) => {
      opencontactShow();
      setCurrentRowInfo(row);
    };
  
    // Create a row for each supplier
    const rows = supplierList.data.map((row) => (
      <tr key={row.id_user} className="border-bt-2 my-10 ">
        <td>{row.personal_no}</td>
        <td> 
          <Grid justify="center" align="center">
                    <Grid.Col offset={3} span={2} className="text-right"><Avatar
                        src={apiUrl.fileSystemDomain+row.user_avatar}
                        radius={40}
                        size={26}
                    /></Grid.Col>
                    <Grid.Col span={7}><Text className="text-left">{row.username}</Text>
                        <Text className="text-left" fz={11}>{row.email}</Text></Grid.Col>
         </Grid>
     
        </td>
        <td>{row.type_name}</td>
        <td>{row.previous_value}</td>
        <td>{row.current_value}</td>
        <td>{row.reason}</td>
        <td className="img-center">
                      <Tooltip
                        label={row.editor_first_name + ' ' + row.editor_last_name}
                      >
                        <Avatar
                          src={apiUrl.fileSystemDomain+row.editor_user_avatar}
                          radius={40}
                          size={26}
                        />
                      </Tooltip>
               
        </td>
        <td className="text-center">
            <Text fw={500}>{row.created_at}</Text>
            <Text fw={500}>{row.created_time}</Text>
        </td>
      </tr>
    ));
    // list all the suppliers
    return (
      <Box>
        <Paper px={20} pb={10} w={"100%"} style={{ position: "relative" }}>
          <PageTitle title="Change Log" lang_id="Change Log" />
          {/* <Group py={20}>
            <SearchBox callBack={searchFunction} />
            <Box h={30}>
              <CreateBtn callBack={handleCallback} />
            </Box>
          </Group> */}
          <Group my={10}>
            <SearchBox callBack={ReceiveSearchBack} />
            {/* <Box h={30}>
              <CreateBtn callBack={handleCallback} />
            </Box> */}
          </Group>
          <Grid gutter={0}>
            <LoadingOverlay visible={loading} overlayBlur={2} />
  
            <table className="table_custome text-center">
              <tr className="table_th">
                <th>
                  {intl.formatMessage({
                    id: "personal_no",
                    defaultMessage: "Staff No.",
                  })}
                </th>
                <th>
                  {intl.formatMessage({
                    id: "user",
                    defaultMessage: "User",
                  })}
                </th>
                <th>
                  {intl.formatMessage({
                    id: "log_type",
                    defaultMessage: "Type",
                  })}
                </th>
                <th>
                  {intl.formatMessage({
                    id: "previous_value",
                    defaultMessage: "Previous Value",
                  })}
                </th>
                <th>
                  {intl.formatMessage({
                    id: "current_value",
                    defaultMessage: "Current Value",
                  })}
                </th>
                <th>
                  {intl.formatMessage({
                    id: "reason",
                    defaultMessage: "Reason",
                  })}
                </th>
                <th>
                  {intl.formatMessage({
                    id: "editor",
                    defaultMessage: "Editor",
                  })}
                </th>
                <th>
                  {intl.formatMessage({
                    id: "modified",
                    defaultMessage: "Modified",
                  })}
                </th>
              </tr>
              {rows}
            </table>
          </Grid>
          <PaginationA
            callBack={ReceivePaginationABack}
            total_count={supplierList.total_count as number}
            pagesize={pagination.pagesize}
            page={pagination.page}
          />
        </Paper>
  
       
      </Box>
    );
  }
  