import { useEffect, useState } from 'react'
import { Text, Paper, Box, Checkbox, Group, ScrollArea, Alert, Skeleton, Divider, Button, Code } from '@mantine/core'

import { IconAlertCircle, IconCircleX } from '@tabler/icons-react'
import { PermissionActionInterface, PermissionIdentifierInterface } from '../../../../interface/Iemloyee'
import WordTranslationComponent from '../../../../utils/language_pack/words'
import {
  getAllPermissionIdentifierListGeneralFunction,
  getCorePermissionActionListGeneralFunction
} from '../../../../api/core_request'
import PermissionSetCheckBoxGroup from './permission_checkbox_group'
import { FormattedMessage } from 'react-intl'
import ResetBtn from '../../../../ttcomponents/Button/ResetBtn'
import { ModuleName, ModuleSelectAll, PermissionData } from './type'

interface ComponentProps {
  formValue: PermissionData[]
  setFormValue: React.Dispatch<React.SetStateAction<PermissionData[]>> // Function to set form value
  close: () => void // close modal
}

export default function PermissionSet({ formValue, setFormValue, close }: ComponentProps) {
  // Get translation public configuration information
  const word_translation = WordTranslationComponent()

  // Identifier list state
  const [identifierList, setIdentifierList] = useState<PermissionIdentifierInterface[]>([])
  const [isGetIdentifierListCompleted, setIsGetIdentifierListCompleted] = useState(false)

  // Action list state
  const [actionList, setActionList] = useState<PermissionActionInterface[]>([])

  const [moduleSelectAll, setModuleSelectAll] = useState<ModuleSelectAll>({
    boarding: false,
    assignment: false,
    timetracker: false,
    report: false,
    core: false,
    leave: false
  })

  // Execute the useEffect when the component is first loaded.
  useEffect(() => {
    // Fetch identifier list and action list based on the costCentID when the component is first loaded.
    getIdentifierList()
    getActionList()
  }, [])

  // ================ Function to fetch the identifier list if it's not already populated ================
  const getIdentifierList = async () => {
    // Check if the identifierList is empty, then fetch the list of core permission identifiers
    if (identifierList.length === 0) {
      var result_core = await getAllPermissionIdentifierListGeneralFunction('', 'all')
      setIdentifierList(result_core)
    }
    setIsGetIdentifierListCompleted(true)
  }
  // ================ Function to fetch the action list if it's not already populated ================
  const getActionList = async () => {
    // Check if the actionList is empty, then fetch the list of core permission actions
    if (actionList.length === 0) {
      setActionList(await getCorePermissionActionListGeneralFunction({ type: 'module' }, 'all'))
    }
  }

  // Check if getIdentifierList has completed before rendering
  if (!isGetIdentifierListCompleted) {
    return (
      <Box h={800}>
        <Skeleton height={800}>
          Lorem ipsum dolor sit amet...
          {/* other content */}
        </Skeleton>
      </Box>
    )
  }

  const selectAll = (moduleName: string, check: boolean) => {
    setModuleSelectAll((prevData: ModuleSelectAll) => {
      const temp = { ...prevData }
      temp[moduleName as ModuleName] = check

      return temp
    })

    setFormValue(prev => {
      const filterData = identifierList.filter(item => item.module === moduleName)

      if (check) {
        const allidentifiers = actionList.map(item => item.identifier.toString())
        const new_data = filterData.map(item => ({
          permission_identifier: item.identifier,
          permission_action: allidentifiers
        }))
        return [...prev, ...new_data]
      }

      // remove
      return prev.filter(item => {
        let bol = true
        filterData.forEach(j => {
          if (j.identifier === item.permission_identifier) {
            bol = false
          }
        })
        return bol
      })
    })
  }
  const displayFunction = () => {}

  const handleReset = () => {
    setFormValue([])
  }

  return (
    <Paper
      className="overflow-hidden"
      py={8}
      mt={16}
      radius="md"
      withBorder
      p="lg"
      pr={0}
      bg="var(--mantine-color-body)"
    >
      {identifierList.length == 0 || actionList.length == 0 ? (
        <Group position="center">
          <Alert icon={<IconAlertCircle size="1rem" />} title={word_translation.no_data}>
            {word_translation.permission_select_hitn}
          </Alert>
        </Group>
      ) : (
        <>
          <ScrollArea h={720}>
            <table>
              <thead className="scrolleder ">
                <tr className="table_tr_xs">
                  <th></th>
                  {actionList.map((item, index) => (
                    <th key={index} className={`input_title`}>
                      <Text className="rotate--90deg" ml={2} my={15} w={50}>
                        {item.title}
                      </Text>
                    </th>
                  ))}
                  <th className="input_title"></th>
                </tr>
              </thead>
              <tbody>
                {identifierList.map(item => {
                  if (item.fgx) {
                    return (
                      <tr key={'identifierList' + item.name}>
                        <td colSpan={actionList.length + 2}>
                          <Box>
                            <Divider
                              onClick={() => displayFunction()}
                              my="sm"
                              labelPosition="center"
                              label={item.title}
                              variant="dashed"
                            />
                          </Box>
                          <Group position="right">
                            <Checkbox
                              mt={8}
                              mb={4}
                              mr={8}
                              label={word_translation.select_all}
                              onChange={event => selectAll(item.name.toLocaleLowerCase(), event.currentTarget.checked)}
                            />
                          </Group>
                        </td>
                      </tr>
                    )
                  } else {
                    const checkResult = formValue.find(_item => _item.permission_identifier == item.identifier)
                    return (
                      <PermissionSetCheckBoxGroup
                        key={item.identifier}
                        value={checkResult}
                        setValue={setFormValue}
                        PermissionIdentifierItem={item}
                        PermissionAction={actionList as []}
                        selectAll={moduleSelectAll[item.module as ModuleName]}
                      />
                    )
                  }
                })}
              </tbody>
            </table>
          </ScrollArea>
          <Group position="right" mt={4}>
            <ResetBtn callBack={handleReset} />
            <Button
              size="xs"
              color="gray"
              leftIcon={<IconCircleX width={18} height={18} />}
              onClick={close}
              styles={{
                leftIcon: { marginRight: '4px' }
              }}
            >
              <FormattedMessage id="close" defaultMessage="Close" />
            </Button>
          </Group>
        </>
      )}
    </Paper>
  )
}
