import { useEffect, useState } from 'react'
import { Text, Paper, Box, Checkbox, Group, ScrollArea, Alert, Skeleton, Divider } from '@mantine/core'
import WordTranslationComponent from '../../../../../../utils/language_pack/words'
import {
  CorePermissonSetFormDataUpdateInterface,
  PermissionActionInterface,
  PermissionIdentifierInterface
} from '../../../../../../interface/Iemloyee'

import {
  getAllPermissionIdentifierListGeneralFunction,
  getCorePermissionActionListGeneralFunction
} from '../../../../../../api/core_request'

import PermissionSetCheckBoxGroup from './permission_checkbox_group'
import { IconAlertCircle } from '@tabler/icons-react'

interface ComponentProps {
  costCentID: string
  haveUserList: String[]
  formValue: any
  setFormValue: (data: any) => void // Function to set form value
  randNumber: number
  managementStatus: boolean
  currentPermissionSet: CorePermissonSetFormDataUpdateInterface[]
  callBack: (value: CorePermissonSetFormDataUpdateInterface[]) => void
}

export default function PermissionSet({
  costCentID,
  haveUserList,
  setFormValue,
  managementStatus,
  formValue,
  randNumber,
  currentPermissionSet,
  callBack
}: ComponentProps) {
  // Get translation public configuration information
  const word_translation = WordTranslationComponent()
  // Role permissions state
  const [rolePermissions, setRolePermissions] = useState<CorePermissonSetFormDataUpdateInterface[]>([])

  // Receiver number state
  const [receiverNumber, setReceiveNumber] = useState(1)

  const [currentActionIndex, setCurrentActionIndex] = useState<any>(null)

  // Current user ID state
  const [currentUserid, setCurrentUserid] = useState<string>()

  // Identifier list state
  const [identifierList, setIdentifierList] = useState<PermissionIdentifierInterface[]>([])
  const [isGetIdentifierListCompleted, setIsGetIdentifierListCompleted] = useState(false)

  // Action list state
  const [actionList, setActionList] = useState<PermissionActionInterface[]>([])
  const [currentSelectAll, setCurrentSelectAll] = useState<string[]>([])

  const [moduleSelectAll, setModuleSelectAll] = useState<any>()
  // Execute the useEffect when the component is first loaded.
  useEffect(() => {
    // Fetch identifier list and action list based on the costCentID when the component is first loaded.
    getIdentifierList({ costCenter_id: costCentID })
    getActionList({ costCenter_id: costCentID })
  }, [costCentID])

  // Execute the useEffect when the randNumber state changes.
  useEffect(() => {
    // Increment the receiverNumber state by adding the value of randNumber when randNumber changes.
    setReceiveNumber(receiverNumber + randNumber)
  }, [randNumber])

  // Watch the value of rolePermissions and update the rolePermissions state with the currentPermissionSet.
  // This synchronization ensures that rolePermissions matches the data in currentPermissionSet.
  useEffect(() => {
    setRolePermissions(currentPermissionSet)
  }, [currentPermissionSet])

  // Surveillance of the value of Rolepermissions and returned the value to the parent -level formation
  useEffect(() => {
    callBack(rolePermissions)
  }, [rolePermissions])

  // ================ Effect Hook to Set Current User ID ================
  useEffect(() => {
    // Get the first user ID from the haveUserList array
    var user_id = haveUserList[0]
    // Check if user_id exists
    if (user_id) {
      // If user_id exists, set the currentUserid state to its string representation
      setCurrentUserid(user_id.toString())
    } else {
      // If user_id doesn't exist, set the currentUserid state to an empty string
      setCurrentUserid('')
    }
  }, [haveUserList])

  // Execute the useEffect when the manage state changes.
  useEffect(() => {
    if (formValue?.manage && formValue?.manage == true) {
      getManage()
    }
  }, [formValue?.manage])

  // ================ Function to fetch the action list if it's not already populated ================
  const getManage = async () => {
    // Check if the actionList is empty, then fetch the list of core permission actions
    // var result_core = await getCorePermissionIdentifierListGeneralFunction({type:'manage'}, 'all');
    // result_core = [{fgx:true,title:word_translation.administrator_permission},...result_core]
    // setIdentifierList((prevList) => [ ...result_core,...prevList]);
  }

  // ================ Function to fetch the identifier list if it's not already populated ================
  const getIdentifierList = async (where: any) => {
    // Check if the identifierList is empty, then fetch the list of core permission identifiers
    if (identifierList.length === 0) {
      var result_core = await getAllPermissionIdentifierListGeneralFunction('', 'all')
      setIdentifierList(prevList => [...prevList, ...result_core])

      // // var result =   await getCorePermissionIdentifierListGeneralFunction({ type: 'core' }, 'all')
      // // setIdentifierList((prevList) => [...prevList, ...result]);
      // var result_report = await getReportPermissionIdentifierListGeneralFunction('', 'all');
      // result_report = [{fgx:true,title:word_translation.report, name: 'Report'},...result_report]
      // setIdentifierList((prevList) => [...prevList, ...result_report]);

      // var result_leave = await getLeavePermissionIdentifierListGeneralFunction('', 'all');
      // result_leave = [{fgx:true,title:word_translation.leave_request, name: 'LeaveRequest'},...result_leave]
      // setIdentifierList((prevList) => [...prevList, ...result_leave]);

      // var result_assignment = await getPermissionIdentifierListGeneralFunction('', 'all');
      // result_assignment = [{fgx:true,title:word_translation.assigment, name: 'Assigment'},...result_assignment]
      // setIdentifierList((prevList) => [...prevList, ...result_assignment]);

      // var result_timetracker = await getTMPermissionIdentifierListGeneralFunction('', 'all');
      // result_timetracker = [{fgx:true,title:word_translation.timetracker, name: 'TimeTracker'},...result_timetracker]
      // setIdentifierList((prevList) => [...prevList, ...result_timetracker]);

      // var result_report = await getReportPermissionIdentifierListGeneralFunction('', 'all');
      // setIdentifierList((prevList) => [...prevList, ...result_report]);
    }
    setIsGetIdentifierListCompleted(true)
  }

  // ================ Function to fetch the action list if it's not already populated ================
  const getActionList = async (where: any) => {
    // Check if the actionList is empty, then fetch the list of core permission actions
    if (actionList.length === 0) {
      setActionList(await getCorePermissionActionListGeneralFunction({ type: 'module' }, 'all'))
    }
  }

  // ================ Function to fetch the action list if it's not already populated ================
  const currentColumnFunction = async (index: any) => {
    // Check if the actionList is empty, then fetch the list of core permission actions
    console.log(index)
    setCurrentActionIndex(index)
  }

  // ================ Set Item Permission Set Function ================
  // Function to set or update the permission set for a specific permission identifier
  const setItemPermissionSetFunction = (
    permission_identifier: string,
    permission_action: string[],
    id_permission_set: any
  ) => {
    // Check if id_permission_identifier is missing, return false
    if (!permission_identifier) {
      return false
    }

    setFormValue((prevData: any) => {
      const PermissionsStore = prevData.permissions as {
        permission_action: string[]
        permission_identifier: string
        [key: string]: any
      }[]

      // Determine whether there is data related to permission_identifier_id that currently exists in the array.
      const existingPermissionIndex = PermissionsStore.findIndex(
        permission => permission.permission_identifier.toString() === permission_identifier.toString()
      )

      console.log(existingPermissionIndex, permission_action, permission_identifier)

      if (existingPermissionIndex === -1) {
        // Add new permission
        return {
          ...prevData,
          permissions: [
            ...prevData.permissions,
            {
              permission_action: permission_action,
              permission_identifier: permission_identifier
            }
          ]
        }
      } else {
        // Update existing permission
        PermissionsStore[existingPermissionIndex] = {
          permission_action: permission_action,
          permission_identifier: permission_identifier
        }

        return {
          ...prevData,
          permissions: PermissionsStore
        }
      }
    })
  }

  useEffect(() => {
    // Increment the receiverNumber state by adding the value of randNumber when randNumber changes.
    if (formValue?.permissions.length == 0) {
      setReceiveNumber(receiverNumber + randNumber)
    }
  }, [formValue?.permissions])

  // Check if getIdentifierList has completed before rendering
  if (!isGetIdentifierListCompleted) {
    return (
      <Box h={600}>
        {' '}
        <Skeleton height={600}>
          Lorem ipsum dolor sit amet...
          {/* other content */}
        </Skeleton>
      </Box>
    )
  }

  const selectAll = (moduleName: string, check: boolean) => {
    setModuleSelectAll((prevData: any) => {
      return {
        ...prevData,
        [moduleName]: check
      }
    })
  }
  const displayFunction = () => {}

  return (
    <Paper className="overflow-hidden" py={10} radius="md" withBorder p="lg" bg="var(--mantine-color-body)">
      {identifierList.length == 0 || actionList.length == 0 ? (
        <Group position="center">
          <Alert icon={<IconAlertCircle size="1rem" />} title={word_translation.no_data}>
            {word_translation.permission_select_hitn}
          </Alert>
        </Group>
      ) : (
        <>
          <ScrollArea h={560}>
            <table>
              <thead className="scrolleder ">
                <tr className="table_tr_xs">
                  <th> </th>
                  <Checkbox.Group w={600} m={0} withAsterisk>
                    {actionList.map((item, index) => (
                      <th key={index} className={`${index == currentActionIndex && 'currentActionIndex'} input_title `}>
                        <Text className="rotate--90deg" ml={2} my={15} w={50}>
                          {item.title}
                        </Text>
                      </th>
                    ))}
                    <th className="input_title"></th>
                  </Checkbox.Group>
                </tr>
              </thead>
              <tbody>
                {identifierList.map((item, index) => {
                  if (item.fgx) {
                    return (
                      <tr>
                        <td colSpan={6}>
                          <Box>
                            <Divider
                              onClick={() => displayFunction()}
                              my="sm"
                              labelPosition="center"
                              label={item.title}
                              variant="dashed"
                            />
                          </Box>
                          <Group position="right">
                            <Checkbox
                              mt={10}
                              disabled={
                                (managementStatus && formValue?.title) || (!managementStatus && formValue?.user_ids)
                                  ? false
                                  : true
                              }
                              indeterminate={formValue?.user_ids ? false : true}
                              label={word_translation.select_all}
                              onChange={event => selectAll(item.name, event.currentTarget.checked)}
                            />
                          </Group>
                        </td>
                      </tr>
                    )
                  } else {
                    const checkResult = formValue?.permissions.filter(
                      (_item: { permission_identifier: string }) => _item.permission_identifier == item.identifier
                    )

                    const allCheck = currentSelectAll.some(i => i === item.name)

                    return (
                      <>
                        <PermissionSetCheckBoxGroup
                          formValue={formValue}
                          PermissionIdentifierItem={item}
                          currentUserid={currentUserid}
                          PermissionAction={actionList as []}
                          currentItem={checkResult && checkResult?.length > 0 ? checkResult[0] : null}
                          index={index}
                          moduleSelectAll={moduleSelectAll}
                          managementStatus={managementStatus}
                          allCheck={allCheck}
                          currentColumn={currentColumnFunction}
                          callBack={setItemPermissionSetFunction}
                        />
                      </>
                    )
                  }
                })}
              </tbody>
            </table>
          </ScrollArea>
        </>
      )}
    </Paper>
  )
}
