import {
  Avatar,
  Card,
  Checkbox,
  Group,
  Select,
  Text,
  TransferList,
  TransferListData,
  TransferListItemComponent,
  TransferListItemComponentProps
} from '@mantine/core'
import { IconDots, IconTrash, IconX } from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import { ApiUserResponse, UserAllList } from '../../interface/Iuser'
import { EmployeeRoleResponseApi } from '../../interface/Iemloyee'
import { SelectRole } from '../../interface/Icommon'
import apiUrl from '../../config/api_config/config'
import { regCustomerRole, regEmployeeroles, regEmployeerolesList } from '../../api'
import { notifications } from '@mantine/notifications'
import { useIntl } from 'react-intl'
import WordTranslationComponent from '../../utils/language_pack/words'
import TableTextInputTitle from '../TableTextInputTitle/TableTextInputTitle'
import TableTextInputTitleDesc from '../TableTextInputTitleDesc/TableTextInputTitleDesc'
import React from 'react'

const ItemComponent: TransferListItemComponent = React.memo(({ data, selected }: TransferListItemComponentProps) => (
  <Group noWrap>
    <Avatar src={data.avatar} radius="xl" size={25} />
    <div style={{ flex: 1 }}>
      <TableTextInputTitle title={data.label} />
      <TableTextInputTitleDesc title={data.eamil} />
    </div>
    <Checkbox checked={selected} onChange={() => {}} tabIndex={-1} sx={{ pointerEvents: 'none' }} />
  </Group>
))
// 示例数据
const mockdata = [
  {
    value: 'bender',
    avatar: 'https://img.icons8.com/clouds/256/000000/futurama-bender.png',
    label: 'Bender Bending Rodríguez',
    eamil: 'Fascinated with cooking, though has no sense of taste'
  },

  {
    value: 'carol2',
    avatar: 'https://img.icons8.com/clouds/256/000000/futurama-mom.png',
    label: 'Carol Miller',
    eamil: 'One of the richest people on Earth'
  },

  {
    value: 'carol1',
    avatar: 'https://img.icons8.com/clouds/256/000000/futurama-mom.png',
    label: 'Carol Miller',
    eamil: 'One of the richest people on Earth'
  },

  {
    value: 'carol3',
    avatar: 'https://img.icons8.com/clouds/256/000000/futurama-mom.png',
    label: 'Carol Miller',
    eamil: 'task 10'
  }
  // ...other items
]
interface UserDataListProps {
  userlist: UserAllList[]
  callBack: (value: TransferListData) => void
  targetUserIdsArr?: string[]
  listHeight?: number
  [key: string]: any
}

// 原始的函数组件
const TransferListUserComponent = ({
  userlist,
  targetUserIdsArr,
  listHeight,
  resetUser,
  callBack
}: UserDataListProps) => {
  // Get translation public configuration information
  const word_translation = WordTranslationComponent()

  const [data, setData] = useState<TransferListData>([mockdata, []])
  const [datatest, setDatatest] = useState([])
  const [randNumber, setRandNumber] = useState(1)

  useEffect(() => {
    const customerData = userlist.map(item => ({
      value: item.id_user.toString() as string,
      label: item.username,
      avatar: apiUrl.fileSystemDomain + item.avatar,
      role: '',
      email: item.email
    }))

    if (!targetUserIdsArr) targetUserIdsArr = []
    const targetUserIds: string[] = targetUserIdsArr
    const extractedUsers: SelectRole[] = []
    const remainingUsers: SelectRole[] = []
    for (const user of customerData) {
      if (targetUserIds.includes(user.value)) {
        extractedUsers.push(user)
      } else {
        remainingUsers.push(user)
      }
    }

    setData([remainingUsers, extractedUsers])
    callBack([remainingUsers, extractedUsers])
    setRandNumber(randNumber + 1)
  }, [userlist, resetUser, targetUserIdsArr])

  const setDataHandle = (value: TransferListData) => {
    setData(value)
    callBack(value)
  }
  return (
    <>
      <TransferList
        value={data}
        onChange={setDataHandle}
        searchPlaceholder={word_translation.search_user}
        nothingFound={word_translation.search_user}
        initialSelection={[datatest, []]}
        className="input_title"
        breakpoint="sm"
        key={randNumber}
        listHeight={listHeight ? listHeight : 200}
        itemComponent={ItemComponent}
        filter={(query, item) => item.label.toLowerCase().includes(query.toLowerCase().trim())}
      />
    </>
  )
}

// 使用 React.memo 进行包装
export default React.memo(TransferListUserComponent)
