import { Box, Flex, Text } from "@mantine/core";
import { FormattedMessage } from "react-intl";

// Define the props interface for the ModalTitleText component
interface SearchBoxProps {
  title: any; // Title content to be displayed in the modal
  py?: number;
}

// ModalTitleText component definition
export default function ModalTitleText({ title ,py}: SearchBoxProps) {
  return (
    // Text component representing the modal title with specified styling
    <Text className="modal_title" py={py || py===0 ?py:4}>
      {title} {/* Display the title content */}
    </Text>
  );
}
