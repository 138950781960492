/**
 * Including all interface request functions in the application
 */

// ajax('http://local.fr.com/login',{username:"admin",password:"admin123"},"POST")

import ajax from "./ajax";

import apiUrl from "../config/api_config/config";
// Timetrack service

// Default Host value
// let Host = "http://localhost:8181/api/";
let Host = apiUrl.apiUrl + "/api/";

// 获取全局 配置

export const requestGetDefaultRole = (data, method) =>
  ajax(Host + "get_default_role", data, method, false);

export const regCreateTimesheet = (data) =>
  ajax(Host + "/create_timesheet", data, "POST");
export const regSetCompany = (data) =>
  ajax(Host + "/set_company", data, "POST", true);
export const regTestlist = (data) =>
  ajax(Host + "/test_list", data, "POST", true);

//Request login interface
export const regLogin = (username, password, login_ldap) =>
  ajax(Host + "login", { username, password, login_ldap }, "POST");

//  Processing of projects (adding, deleting, modifying, and checking)
export const requestMenu = (data, method) =>
  ajax(Host + "get_menu", data, method, false);

export const requestCorePermissionRole = (data, method) =>
  ajax(Host + "permission_role", data, method, false);
export const requestCostcenterPermissionRole = (data, method) =>
  ajax(Host + "permission_costcenter_role", data, method, false);
export const requestCoreRolePermissionRole = (data, method) =>
  ajax(Host + "permission_core_role", data, method, false);

export const requestManagePermissionRole = (data, method) =>
  ajax(Host + "mana_permission_set", data, method, false);
export const requestManagePermissionRoleSet = (data, method) =>
  ajax(Host + "mana_permission_set_set", data, method, false);

// 獲取角色模板
export const requestManageRolePermissionDetail = (data, method) =>
  ajax(Host + "mana_permission_set_get", data, method, false);

export const requestManagePermissionDel = (data, method) =>
  ajax(Host + "mana_permission_del", data, method, false);

export const requestManagePermissionSetDel = (data, method) =>
  ajax(Host + "mana_permission_set_del", data, method, false);
export const requestManagePermissionList = (data, method) =>
  ajax(Host + "mana_permission_getlist", data, method, false);

export const requestGetPermissionRoleDetail = (data, method) =>
  ajax(Host + "get_permission_role", data, method, false);

export const requestCorePermissionRoleList = (data, method) =>
  ajax(Host + "permission_role_list", data, method, false);
export const requestCorePermissionAction = (data, method) =>
  ajax(Host + "permission_action", data, method, false);

export const requestCorePermissionListAction = (data, method) =>
  ajax(Host + "permission_action_list", data, method, false);

// Request permission action unique interface
export const requestCorePermissionUniqueAction = (data, method) =>
  ajax(Host + "get_unique_permission_action", data, method, false);

export const refreshPermissions = (data, method) =>
  ajax(Host + "refresh_user_permissions", data, method, false);

export const requestCorePermissionIdentifier = (data, method) =>
  ajax(Host + "permission_identifier", data, method, false);

export const requestCorePermissionIdentifierListAction = (data, method) =>
  ajax(Host + "permission_identifier_list", data, method, false);
export const requestAllPermissionIdentifierList = (data, method) =>
  ajax(Host + "get_all_permission_identifier", data, method, false);

export const requestCorePermissionIdentifierUniqueList = (data, method) =>
  ajax(Host + "get_unique_permission_identifier", data, method, false);

export const requestCorePermissionSetList = (data, method) =>
  ajax(Host + "permission_set_list", data, method, false);

export const requestCoreAssignablePermissionList = (data, method) =>
  ajax(Host + "grant_permission", data, method, false);

export const requestAssignablePermissionList = (moduleName, data, method) =>
  ajax(`${Host}${moduleName}\\grant_permission`, data, method, false);

export const requestGetPermissionSetDetail = (data, method) =>
  ajax(Host + "get_permission_set", data, method, false);

export const requestGetManagePermissionSetDetail = (data, method) =>
  ajax(Host + "mana_permission_get", data, method, false);

export const requestDeletePermission = (data, method) =>
  ajax(Host + "delete_permission", data, method, false);

export const requestDelPermissionUser = (data, method) =>
  ajax(Host + "del_permission_user", data, method);

export const requestSetPermissionShow = (data, method) =>
  ajax(Host + "menu_permission_identifier_list", data, method, false);

export const requestCorePermissionSet = (data, method) =>
  ajax(Host + "permission_set", data, method, false);

export const requestCoreUpdateUserRoleTransfer = (data) =>
  ajax(Host + "update_user_role_transfer", data, "PUT", false);

export const requestUpdateUserRoleTransfer = (moduleName, data) =>
  ajax(`${Host}${moduleName}\\update_user_role_transfer`, data, "PUT", false);

export const requestGetAllPermission = (data, method) =>
  ajax(Host + "get_permission_user", data, method, false);

//Request Logout interface
export const regLogout = () => ajax(Host + "logout", {}, "POST");
//Set company information
export const regCompanySeting = (data, method) =>
  ajax(Host + "companysettings", data, method, false);

export const setCorlorSeting = (data, method) =>
  ajax(Host + "color_addorupdate", data, method, false);

export const requestCorlorSeting = (data, method) =>
  ajax(Host + "color_get", data, method, false);
export const regImgUpload = (data, method) =>
  ajax(Host + "image/upload", data, method, true);

export const regCurrency = (data, method) =>
  ajax(Host + "currency", data, method, false);
// 日志信息
export const regLogs = (data) => ajax(Host + "log", data);
export const regLogDetail = (data) => ajax(Host + "log_detail", data);
// 公司mudoles信息接口
// Company Mudoles information interface
export const regModules = (data, method) =>
  ajax(Host + "modules", data, method, false);

export const regRefreshCache = (data, method) =>
  ajax(Host + "refresh_moudle", data, method, false);
//获取单个公司mudoles信息接口
//Get a single company Mudoles information interface
export const regGetModules = (data) =>
  ajax(Host + "modules/" + data, {}, "GET", false);
//设置国家信息
//Set national information
export const regCountrySeting = (data, method) =>
  ajax(Host + "country", data, method, false);
// 设置全局邮箱信息
// set global email
export const regEmailShowSetting = (data, method) =>
  ajax(Host + "email_show", data, method, false);
// 设置全局邮箱信息
// set global email
export const regEmailSetSetting = (data, method) =>
  ajax(Host + "email_set", data, method, false);

//设置系统全局设置信息
//Set the system for system setting information
export const reGetDomains = (data, method) =>
  ajax(Host + "globalsettings/domains", data, method, false);

//Set the system for system setting information
export const regGlobalSetting = (data, method) =>
  ajax(Host + "globalsettings", data, method, false);

// 打卡 Check in
export const reqPostSign = (data, method) =>
  ajax(Host + "clockin/sign", data, method, false);
// 查看打卡数据  View punch-in data
export const reqPostSignShowData = (data, method) =>
  ajax(Host + "clockin/sign_showData", data, method, false);
// 撤销上一个结束时间  Undo the last end time
export const reqPostSignDel = (data, method) =>
  ajax(Host + "clockin/sign_del", data, method, false);

// Add Business Mode
// params:
//    mode: 1,2,3 | 1,2
export const regAssignmentSettingUpdateOrinsert = (data, method) =>
  ajax(Host + "assignment_setting_updateOrinsert", data, method, false);

// Get Business Mode list
export const regAssignmentSettingShowData = (data, method) =>
  ajax(Host + "assignment_setting_showData", data, method, false);

// Delete Business Mode by id
export const regAssignmentSettingDelData = (data, method) =>
  ajax(Host + "assignment_setting_delData", data, method, false);

// get Business Mode type list
export const regAssignmentSettingType = (data, method) =>
  ajax(Host + "assignment_setting_getType", data, method, false);

export const regSystemConfig = (data, method) =>
  ajax(Host + "system_config", data, method, false);
export const refreshUsepPermission = (data, method) =>
  ajax(Host + "refresh_permission", data, method, false);

// 设置公司分支信息
// Set the company branch information
export const regCostcenterSetting = (data, method) =>
  ajax(Host + "costcenter", data, method, false);
// Set the company branch information
export const updateUserCostCenter = (data, method) =>
  ajax(Host + "set_user_costcenter", data, method, false);

// 设置公司分支信息
// Set the company branch information
export const regSelfCostcenterSetting = (data, method) =>
  ajax(Host + "get_cost_special_self", data, method, false);
// 获取用户信息
// Get user information
export const regUser = (data, method) =>
  ajax(Host + "user", data, method, false);
// 获取一个用户信息
// Get user information
export const regGeUser = (data) =>
  ajax(Host + "user/" + data, {}, "GET", false);
//获取全部用户信息
export const regUserAll = (data, method) =>
  ajax(Host + "user_list", data, method, false);

// Get user information
export const AbandonUser = (data, method) =>
  ajax(Host + "change_department", data, method, false);

export const regUserPermissionAll = (data, method) =>
  ajax(Host + "user_list_permission", data, method, false);

export const regGetUserRoleList = (data, method) =>
  ajax(Host + "user_role", data, method, false);

export const regGetUserRoleListNopagination = (data, method) =>
  ajax(Host + "user_role_list", data, method, false);

export const regGetEmailListNopagination = (data, method) =>
  ajax(Host + "globalsettings/domains", data, method, false);

export const reqGetLeaveApproveUserList = (data, method) =>
  ajax(Host + "leave_approve_user_list", data, method, false);

export const regCalculationGet = (data, method) =>
  ajax(Host + "calculation_get", data, method, false);

export const regTemPermissionTable = (data, method) =>
  ajax(Host + "mana_permission_set_getlist", data, method, false);

export const requestUserPermissionShow = (data, method) =>
  ajax(Host + "user_permission_isshow", data, method, false);
//
export const regGetPermissionRoleListNopagination = (data, method) =>
  ajax(Host + "permission_role_list", data, method, false);

export const regGetUserChangeLog = (data, method) =>
  ajax(Host + "user_change_log", data, method, false);

export const reAttributeType = (data, method) =>
  ajax(Host + "attribute_type", data, method, false);

export const reAttributeTypeList = (data, method) =>
  ajax(Host + "attribute_type_list", data, method, false);

// 获取节假日
export const getPublicHolidayList = (data, method) =>
  ajax(Host + "public_holiday_list", data, method, false);

/**
 * Get a list of custom attribute types
 * @param {*} data
 * @param {*} method
 * @returns
 */
export const reGetEmployeeCustomAttributetTypesList = (data, method) =>
  ajax(Host + "attribute_type_list", data, method, false);

/**************************************************************************
 *
 *                   **********      ***********
 * ******************    ***    *****    ***    ***************************
 *                   **********      ***********
 *
 *                           TimeTrack
 *                           模块接口测试
 *
 *
 *
 *
 * ************************************************************************************
 *
 *
 *
 *************************************************************************
 */

// 客户信息接口  /
//Customer information interface
export const regCustomer = (data, method) =>
  ajax(Host + "customer", data, method, false);

// 获取单个客户信息
//Get a single user informationhaiyang

export const regGeCustome = (data) =>
  ajax(Host + "customer/" + data, {}, "GET", false);

// 获取所有的客户信息,没有分页
export const regCustomerList = (data, method) =>
  ajax(Host + "customer_list", data, method, false);

//获取指定用户所属的客户

export const regTimeTrackerCustomerList = (data, method) =>
  ajax(Host + "assignment/timetracker_customer_list", data, method, false);

// 获取客户角色信息
//Get customer role information
export const regCustomerRole = (data, method) =>
  ajax(Host + "customerroles", data, method, false);

// 获取项目的所有状态
// Get all the states of the project
export const regProjectStatus = (data, method) =>
  ajax(Host + "assignment/projectstatus", data, method, false);

// 获取所有的项目分页
// Get all the projects
export const regProject = (data, method) =>
  ajax(Host + "assignment/project", data, method, false);

// 获取单个的项目
// Get all the projects
export const regGeProject = (data) =>
  ajax(Host + "assignment/project/" + data, {}, "GET", false);

export const regProjectList = (data, method) =>
  ajax(Host + "assignment/project_list", data, method, false);

// 获取订单信息
// Get order information
export const regOrdername = (data, method) =>
  ajax(Host + "assignment/ordername", data, method, false);

// 产看所有订单id
// Get an order
export const regOrder = (data, method) =>
  ajax(Host + "assignment/order", data, method, false);
// 获取单个的订单任务
//  Get a single order task
export const regGeOrder = (data) =>
  ajax(Host + "assignment/order/" + data, {}, "GET", false);

// Task Only
export const regTaskOnly = (data, method) =>
  ajax(Host + "assignment/timetracker_task_only", data, method, false);
// 获取全部的仅仅是任务的信息
export const regTaskOnlyList = (data, method) =>
  ajax(Host + "assignment/task_only_list", data, method, false);

// Get all the order names
export const regOrdernameList = (data, method) =>
  ajax(Host + "assignment/order_list", data, method, false);
// Get all the order names
export const regOrderList = (data, method) =>
  ajax(Host + "assignment/order_list", data, method, false);

// 获取订单中的产品
export const regOrderProducts = (data, method) =>
  ajax(
    "https://mockapi.eolink.com/SHHZMze4031ad4d6a7a37aee142f098547eef42b3f201be/api/get/get_order_products",
    data,
    method,
    false
  );

// 获取作业列表
export const regGetAssignments = (data, method) =>
  ajax(
    "https://mockapi.eolink.com/SHHZMze4031ad4d6a7a37aee142f098547eef42b3f201be/api/get_assignments",
    data,
    method,
    false
  );

// 获取客户联系人
//Get customer contact
export const regCustomerRoleContact = (data, method) =>
  ajax(Host + "customerroleselection", data, method, false);

// 获取客户员工角色
// Get the role of customer employee
export const regEmployeeroles = (data, method) =>
  ajax(Host + "assignment/employeeroles", data, method, false);
// 获取所有的员工角色
// Get the role of customer employee
export const regEmployeerolesList = (data, method) =>
  ajax(Host + "assignment/employee_roles_list", data, method, false);

// 绑定用户以角色的方式绑定到项目上
export const regEmployeeroleSlection = (data, method) =>
  ajax(Host + "assignment/employeeroleselection", data, method, false);

// 获取timetrack
export const regTimeTrack = (data, method) =>
  ajax(Host + "assignment/timetracker", data, method, false);

//获取单个的timetrack
export const regGeTimeTrack = (data) =>
  ajax(Host + "assignment/assignment/" + data, {}, "GET", false);

// 绑定添加记录获取timetrack记录
export const regTimeTrackRecord = (data, method) =>
  ajax(Host + "assignment/timetracker_list", data, method, false);

export const regTimeTrackRecordUp = (data, method) =>
  ajax(Host + "assignment/timetracker_up", data, method, false);

// 在orderlist 下添加task  api/assignment/task
export const regOrderTask = (data, method) =>
  ajax(Host + "assignment/task", data, method, false);
//  Get a single order task
export const regGeOrderTask = (data) =>
  ajax(Host + "assignment/task/" + data, {}, "GET", false);
// 添加仅仅的task任务
export const regCreateTaskOnly = (data, method) =>
  ajax(Host + "assignment/timetracker_task_only", data, method, false);

export const regProjectOnly = (data, method) =>
  ajax(Host + "assignment/task_only_project", data, method, false);

// 在orderlist 下添加task  api/assignment/task
export const regTaskList = (data, method) =>
  ajax(Host + "assignment/task_list", data, method, false);

// 根据参数获取任务列表
export const regGetTaskList = (data, method) =>
  ajax(Host + "assignment/get_task_list", data, method, false);

// 获取board_recent最近的timetrack记录
export const regRecentlyTimertrack = (data, method) =>
  ajax(Host + "assignment/board_recent", data, method, false);
// 获取daily_overview最近的timetrack记录
export const regBoardDailyOverview = (data, method) =>
  ajax(Host + "assignment/board_daily_overview", data, method, false);

// 获取工作记录 一周
export const regTimertrackJobs = (data, method) =>
  ajax(Host + "assignment/job_remarks_list", data, method, false);

// 添加任务类型参数
export const regTaskCategoryParameter = (data, method) =>
  ajax(Host + "assignment/task_category_parameter", data, method, false);
// 添加任务类型
export const regTaskCategory = (data, method) =>
  ajax(Host + "assignment/task_category", data, method, false);

// 添加timejobremarks

// 获取工作记录 一周
export const regrackJobs = (data, method) =>
  ajax(Host + "assignment/jobremarks", data, method, false);
// 获取假期
export const regHolidays = (data, method) =>
  ajax(Host + "leave/holidays_list", data, method, false);
//
/**
 * Get the small component information of the homepage
 * eg:http://core.shenleshe.com/api/assignment/board_most_recent_project
 * @param {*} data
 * @param {*} method
 * @returns
 */
export const regBoardMostRecentProject = (data, method) =>
  ajax(Host + "assignment/board_most_recent_project", data, method, false);

// Remain the project or order remain
export const regRemainingResource = (data, method) =>
  ajax(Host + "assignment/remaining_resource", data, method, false);

// Get a holiday
export const regProjectRencent = (data, method) =>
  ajax(Host + "assignment/project_recent", data, method, false);

export const regProjectAllBoard = (data, method) =>
  ajax(Host + "assignment/board_total_time", data, method, false);
export const regTaskOnlyCategory = (data, method) =>
  ajax(Host + "assignment/task_only_category", data, method, false);
export const regTimetrackerListWeekTotalTime = (data, method) =>
  ajax(
    Host + "assignment/timetracker_list_week_total_time",
    data,
    method,
    false
  );

/**
 * Get personal Timrtracker record
 * eg: http://core.shenleshe.com/api/assignment/board_profile_tracked_hour?user_id=1
 * @param {*} data
 * @param {*} method
 * @returns
 */
export const regBoardProfileTrackedHour = (data, method) =>
  ajax(Host + "assignment/board_profile_tracked_hour", data, method, false);

// Condition disease leave information
export const regSickTimeAction = (data, method) =>
  ajax(Host + "assignment/sick_time", data, method, false);
export const regTimeViolation = (data, method) =>
  ajax(Host + "assignment/time_violation", data, method, false);

export const regRecordViolation = (data, method) =>
  ajax(Host + "assignment/record_violation", data, method, false);
// get not approved timetracker record
export const regAbnormalRecords = (data, method) =>
  ajax(Host + "assignment/abnormal_records", data, method, false);

// get not approved timetracker record
export const regAbnormalApproval = (data, method) =>
  ajax(Host + "assignment/abnormal_approval", data, method, false);

// Get the leader of a user's project? If there are multiple users, take the leader of the previous level.
export const regUseManager = (data, method) =>
  ajax(Host + "assignment/user_manager", data, method, false);

export const regCalculateEstimated = (data, method) =>
  ajax(Host + "assignment/calculate_estimated", data, method, false);

// 获取客户订单中的产品
export const regGetOrderProductList = (data, method) =>
  ajax(Host + "assignment/get_order_proudct_list", data, method, false);

// 更新客户订单
export const regRefreshOrderProduct = (data, method) =>
  ajax(Host + "assignment/order_proudct", data, method, false);

/******************************************************
 * REPORT
 * ****************************************************
 */

const report_module = "report/";

export const regReportEmployeeReport = (data, method) =>
  ajax(Host + "report/employee_report", data, method, false);
export const regReportGetUser = (data, method) =>
  ajax(Host + "report/get_can_read_user", data, method, false);

export const regProjectReport = (data, method) =>
  ajax(Host + "report/project_report", data, method, false);

export const regItemReport = (data, method) =>
  ajax(Host + "report/project_item", data, method, false);

// 获取report 各种类型的列表
export const regItemListByType = (data, method) =>
  ajax(Host + "report/get_items_by_type", data, method, false);

// report 过滤条件添加 api/report/create_filter
export const regCreateFilter = (data, method) =>
  ajax(Host + "report/create_filter", data, method, false);
// 获取过滤条件
export const regGetFilter = (data, method) =>
  ajax(Host + "report/get_filter", data, method, false);
// 根据过滤条件获取报表
export const regReportsByFilter = (data, method) =>
  ajax(Host + "report/reports_by_filter", data, method, false);

export const regReporFilter = (data, method) =>
  ajax(Host + "report/filter", data, method, false);

export const regReportUserAllProject = (data, method) =>
  ajax(Host + "report/reports_user_all_project_time", data, method, false);

export const regReporFormal = (data, method) =>
  ajax(Host + "report/formal", data, method, false);

export const regReport = (data, method) =>
  ajax(Host + "report/report", data, method, false);

export const regGeReport = (data) =>
  ajax(Host + "report/report/" + data, {}, "GET", false);

export const regSingleFormal = (data, method) =>
  ajax(Host + "report/formal/" + data, {}, "GET", false);

// Processing of permission identifie (adding, deleting, modifying, and checking)
export const requestReportPermissionIdentifier = (data, method) =>
  ajax(Host + report_module + "permission_identifier", data, method, false);

// Processing of permission action (adding, deleting, modifying, and checking)
export const requestReportPermissionAction = (data, method) =>
  ajax(Host + report_module + "permission_action", data, method, false);

export const requestReportPermissionListAction = (data, method) =>
  ajax(Host + report_module + "permission_action_list", data, method, false);

export const requestReportPermissionIdentifierListAction = (data, method) =>
  ajax(
    Host + report_module + "permission_identifier_list",
    data,
    method,
    false
  );

export const requestReportPermissionRole = (data, method) =>
  ajax(Host + report_module + "permission_role", data, method, false);

//get employee report
export const requestTeamOvertime = (data, method) =>
  ajax(Host + report_module + "team_overtime", data, method, false);

export const requestReportPermissionSet = (data, method) =>
  ajax(Host + report_module + "permission_set", data, method, false);

export const requestReportPermissionRoleList = (data, method) =>
  ajax(Host + report_module + "permission_role_list", data, method, false);

export const requestReportRoleDetail = (data, method) =>
  ajax(Host + report_module + "get_permission_role", data, method, false);

export const requestReportSetDetail = (data, method) =>
  ajax(Host + report_module + "get_permission_set", data, method, false);
export const requestReportPermissionSetList = (data, method) =>
  ajax(Host + report_module + "permission_set_list", data, method, false);
export const requestReportItem = (data, method) =>
  ajax(Host + report_module + "project_item", data, method, false);
export const requestUserProjectReports = (data, method) =>
  ajax(Host + report_module + "user_project_reports", data, method, false);
export const requestUserWeekProjectReports = (data, method) =>
  ajax(Host + report_module + "user_week_project", data, method, false);

//get employee report
export const requestEmployeeTime = (data, method) =>
  ajax(Host + report_module + "employee_time", data, method, false);
/**************************************************************************
 *
 *                   **********      ***********
 * ******************    ***    *****    ***    ***************************
 *                   **********      ***********
 *
 *                       leave    Request
 *                           模块接口测试
 *
 *
 *
 *
 * ************************************************************************************
 *
 *
 *
 *************************************************************************
 */
const leave_module = "leave/";
/**
 *
 * Get the surplus vacation of the user
 * @param {user_id:13} data
 * @returns
 */
export const regGetSurplusVacation = (data, method) =>
  ajax(Host + "leave/user_vacation_info", data, method, false);

/**
 * @param {*} method
 * @returns
 */
export const regVacation = (data, method) =>
  ajax(Host + "leave/vacation", data, method, false);

export const regVacationDateil = (data, method) =>
  ajax(Host + "leave/vacation/" + data, {}, "GET", false);

export const regVacationCategory = (data, method) =>
  ajax(Host + "leave/leave_category", data, method, false);

export const regLeaveCateGoryList = (data, method) =>
  ajax(Host + "leave/leave_category_list", data, method, false);

/**
 * http://localhost:8888/tm-leave/public/api/vacation_calendar?status_id=1&start=1&end=23
 * @param {*} data
 * @param {*} method
 * @returns
 * Parameters can be omitted
 */

export const regVacationCaledar = (data, method) =>
  ajax(Host + "leave/vacation_calendar", data, method, false);

/**
 * Manager (reviewer) obtain the VACATION record to be managed
 * @param {*} data
 * @param {*} method
 * @returns
 */

export const regVacationMangement = (data, method) =>
  ajax(Host + "leave/leave_mangement", data, method, false);

/**
 * Request request for review holiday
 * @param {*} data
 * @param {*} method
 * @returns
 */
export const regVacationApproval = (data, method) =>
  ajax(Host + "leave/leave_approval", data, method, false);

// 批量处理请求

export const regVacationApprovalBatch = (data, method) =>
  ajax(Host + "leave/leave_approval_batch", data, method, false);

//  修改假期
export const regVacationModifications = (data, method) =>
  ajax(Host + "leave/vacation_modifications", data, method, false);

// 获取假期信息
export const regGetVation = (data) =>
  ajax(Host + "leave/vacation/" + data, {}, "GET", false);

export const regGetVationAction = (data, method) =>
  ajax(Host + "leave/vacation", data, method, false);
export const regCancelVationAction = (data, method) =>
  ajax(Host + "leave/vacation_cancel", data, method, false);

// 病假设置成已读
export const regLeaveSickReaded = (data, method) =>
  ajax(Host + "leave/leave_sick_readed", data, method, false);

// Holiday comment remarks
export const regGetVationComment = (data, method) =>
  ajax(Host + "leave/comment", data, method, false);

// Deliver holidays to superiors
export const regDeliverHolidays = (data, method) =>
  ajax(Host + "leave/leave_approval_upper_level", data, method, false);

// Processing of permission identifie (adding, deleting, modifying, and checking)
export const requestLeavePermissionIdentifier = (data, method) =>
  ajax(Host + leave_module + "permission_identifier", data, method, false);

// Get one the permission identifie list no Pagination
export const requestLeaveUniquePermissionIdentifierList = (data, method) =>
  ajax(
    Host + leave_module + "get_unique_permission_identifier",
    data,
    method,
    false
  );

// Processing of permission action (adding, deleting, modifying, and checking)
export const requestLeavePermissionAction = (data, method) =>
  ajax(Host + leave_module + "permission_action", data, method, false);

// Get one the permission action list no Pagination
export const requesLeaveUniquePermissionActionList = (data, method) =>
  ajax(
    Host + leave_module + "get_unique_permission_action",
    data,
    method,
    false
  );

export const requestLeavePermissionIdentifierListAction = (data, method) =>
  ajax(Host + leave_module + "permission_identifier_list", data, method, false);

export const requestLeavePermissionListAction = (data, method) =>
  ajax(Host + leave_module + "permission_action_list", data, method, false);

export const requestLeavePermissionRole = (data, method) =>
  ajax(Host + leave_module + "permission_role", data, method, false);

export const requestLeavePermissionSet = (data, method) =>
  ajax(Host + leave_module + "permission_set", data, method, false);

export const requestLeavePermissionRoleList = (data, method) =>
  ajax(Host + leave_module + "permission_role_list", data, method, false);

export const requestLeavePermissionRoleDetail = (data, method) =>
  ajax(Host + leave_module + "get_permission_role", data, method, false);

// Get the leader of a user's project? If there are multiple users, take the leader of the previous level.
export const requestApprovalUserList = (data, method) =>
  ajax(
    Host + leave_module + "leave_can_approval_user_list",
    data,
    method,
    false
  );

export const requestLeavePermissionSetList = (data, method) =>
  ajax(Host + leave_module + "permission_set_list", data, method, false);

export const requestLeavePermissionSetDetail = (data, method) =>
  ajax(Host + leave_module + "get_permission_set", data, method, false);

// Obtain the information of the upper-level personnel delivered

/**************************************************************************
 *
 *                   **********      ***********
 * ******************    ***    *****    ***    ***************************
 *                   **********      ***********
 *
 *                           inventory
 *                           模块接口测试
 * ************************************************************************************
 *
 *
 *
 *************************************************************************
 */

export const regSupplier = (data, method) =>
  ajax(Host + "inventory/supplier", data, method, false);

export const regGetSupplier = (data, method) =>
  ajax(Host + "inventory/get_supplier", data, method, false);

export const regGetSupplierContact = (data, method) =>
  ajax(Host + "inventory/get_supplier_contact", data, method, false);
export const regSupplierContact = (data, method) =>
  ajax(Host + "inventory/supplier_contact", data, method, false);

// 获取产品
export const regGetProduct = (data, method) =>
  ajax(Host + "inventory/get_product", data, method, false);
//
export const regProduct = (data, method) =>
  ajax(Host + "inventory/product", data, method, false);

export const regGetProductId = (data, method) =>
  ajax(Host + "inventory/get_product_id", data, method, false);

//  获取产品分类

export const regGetProductCategory = (data, method) =>
  ajax(Host + "inventory/get_product_category", data, method, false);
// 采购
export const regProductPurchase = (data, method) =>
  ajax(Host + "inventory/product_purchase", data, method, false);

export const regGetProductPurchase = (data, method) =>
  ajax(Host + "inventory/get_product_purchase", data, method, false);

// Review order

export const regGetProductPurchaseCheck = (data, method) =>
  ajax(Host + "inventory/product_purchase_check", data, method, false);
// 退货
export const regGetReturnProductPurchase = (data, method) =>
  ajax(Host + "inventory/return_product_purchase", data, method, false);

export const regGetReturnProductReason = (data, method) =>
  ajax(Host + "inventory/get_return_reason", data, method, false);

// Warehouse return
export const regGetProductToSupplier = (data, method) =>
  ajax(Host + "inventory/product_to_supplier", data, method, false);
export const regDeliveryToCustomer = (data, method) =>
  ajax(Host + "inventory/delivery_to_customer", data, method, false);

// picking_out_bound
export const regPickingOutBound = (data, method) =>
  ajax(Host + "inventory/picking_out_bound", data, method, false);

// get_picking_out_bound
export const regGetPickingOutBound = (data, method) =>
  ajax(Host + "inventory/get_picking_out_bound", data, method, false);

// internal_use_product
export const regInternalUseProduct = (data, method) =>
  ajax(Host + "inventory/internal_use_product", data, method, false);

// rent_product
export const regRentProduct = (data, method) =>
  ajax(Host + "inventory/rent_product", data, method, false);
export const regGetRentPdf = (data, method, type) =>
  ajax(Host + "inventory/get_rent_pdf", data, method, type);

export const regDeliveryToCustomerList = (data, method) =>
  ajax(Host + "inventory/delivery_to_customer_list", data, method, false);

export const regGetInternalUseProduct = (data, method) =>
  ajax(Host + "inventory/get_internal_use_product", data, method, false);

export const regGetRentProductList = (data, method) =>
  ajax(Host + "inventory/get_rent_product", data, method, false);
export const regReturnRentProduct = (data, method) =>
  ajax(Host + "inventory/rent_return_product", data, method, false);
// get_recycling_company
export const regGetRecyclingCompany = (data, method) =>
  ajax(Host + "inventory/get_recycling_company", data, method, false);
export const regRecyclingCompany = (data, method) =>
  ajax(Host + "inventory/recycling_company", data, method, false);
// product_recycling
export const regProductRecycling = (data, method) =>
  ajax(Host + "inventory/product_recycling", data, method, false);

export const regProductCategory = (data, method) =>
  ajax(Host + "inventory/product_category", data, method, false);

export const regProductPriceHostory = (data, method) =>
  ajax(Host + "inventory/get_product_price", data, method, false);

export const regReturnInternalUseProduct = (data, method) =>
  ajax(Host + "inventory/return_internal_use_product", data, method, false);

export const regLockedProduct = (data, method) =>
  ajax(Host + "inventory/locked_product", data, method, false);

export const regRetrunProductRecords = (data, method) =>
  ajax(Host + "inventory/get_retrun_product_records", data, method, false);

export const getHiredEmployees = (data, method) =>
  ajax(
    "https://mockapi.eolink.com/SHHZMze4031ad4d6a7a37aee142f098547eef42b3f201be/api/get/off_boarding",
    data,
    method,
    false
  );

export const getEmployeeListToBeArchived = (data, method) =>
  ajax(
    "https://mockapi.eolink.com/SHHZMze4031ad4d6a7a37aee142f098547eef42b3f201be/api/get/boarding_archive",
    data,
    method,
    false
  );

/******************************************************
 * on_boarding
 * ****************************************************
 */

const on_boarding_module = "onoffboarding/";

// Processing of permission identifie (adding, deleting, modifying, and checking)
export const requestBoarding = (data, method) =>
  ajax(
    Host + on_boarding_module + "onboarding_addorupdate",
    data,
    method,
    false
  );

export const requestDataListBoarding = (data, method) =>
  ajax(Host + on_boarding_module + "showDataList", data, method, false);

export const requestDataBoarding = (data, method) =>
  ajax(Host + on_boarding_module + "showData", data, method, false);

export const requestSetStatus = (data, method) =>
  ajax(Host + on_boarding_module + "set_user_status", data, method, false);
export const requestDeleteBoarding = (data, method) =>
  ajax(Host + on_boarding_module + "onboarding_del", data, method, false);

export const reAttribute = (data, method) =>
  ajax(Host + on_boarding_module + "attr_addorupdate", data, method, false);

/**
 * Get a list of user member custom properties
 * @param {*} data
 * @param {*} method
 * @returns
 */
export const reGetEmployeeCustomAttributeList = (data, method) =>
  ajax(Host + on_boarding_module + "attr_showdata", data, method, false);

export const requestBoardingPermissionIdentifierListAction = (data, method) =>
  ajax(
    Host + on_boarding_module + "permission_identifier_list",
    data,
    method,
    false
  );

export const requestBoardingPermissionListAction = (data, method) =>
  ajax(
    Host + on_boarding_module + "permission_action_list",
    data,
    method,
    false
  );

export const requestBoardingPermissionRole = (data, method) =>
  ajax(Host + on_boarding_module + "permission_role", data, method, false);

export const requestBoardingPermissionRoleList = (data, method) =>
  ajax(Host + on_boarding_module + "permission_role_list", data, method, false);
export const requestBoardingPermissionRoleDetail = (data, method) =>
  ajax(Host + on_boarding_module + "get_permission_role", data, method, false);

export const mockapi1 = (data, method) =>
  ajax(
    "https://mockapi.eolink.com/SHHZMze4031ad4d6a7a37aee142f098547eef42b3f201be/api/get/new_boarding",
    data,
    method,
    false
  );
