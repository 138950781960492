import { Avatar, Tooltip } from "@mantine/core";
import { UserInfoInterface } from "../../interface/Iprojects";

// Import API URL configuration
import apiUrl from "../../config/api_config/config";

/**
 * Interface for the individual item in the AvatarGroupComponent.
 */
interface ItemComponentInterface {
    first_name: string;
    last_name: string;
    avatar: string;
}

/**
 * Interface for the AvatarGroupComponent.
 */
interface ComponentInterface {
    AvatarInfo?: ItemComponentInterface[];
    UserInfo?: UserInfoInterface[];
}

/**
 * AvatarGroupComponent renders a group of avatars with tooltips.
 *
 * @param {ComponentInterface} props - Props containing AvatarInfo and UserInfo arrays.
 * @returns {JSX.Element} - Rendered AvatarGroupComponent.
 */
const Host = apiUrl.fileSystemDomain;
export default function AvatarGroupComponent({ AvatarInfo, UserInfo }: ComponentInterface) {
    return (
        <>
            <Avatar.Group spacing="md">
                {AvatarInfo?.map((item, index) => (
                    <Tooltip  key={index} label={`${item.first_name} ${item.last_name}`}                    >
                        <Avatar  src={item.avatar} radius={40} size={26} />
                    </Tooltip>
                ))}
                {UserInfo?.map((user, index) => (
                    <Tooltip   key={index}   label={`${user.username}`}  >
                        <Avatar  src={Host + user.avatar}   radius={40}  size={26} />
                    </Tooltip>
                ))}
            </Avatar.Group>
        </>
    );
}
