import { Box, Code, Flex, Group ,Text} from "@mantine/core";
import { IconInfoCircleFilled } from "@tabler/icons-react";

interface ComponentInterface{
    title:string;
    title_color?:string;
    value:any;
    value_color?:string;
    icon?:any
}
export default function InfoItem({title,title_color='#000',icon='',value,value_color='#000'}:ComponentInterface){
return(
    <Flex py={4} align="center" >
    {icon ? icon :<IconInfoCircleFilled size={10} color="blue"/>} 
    <Box  mx={4}>
        <Group>
            <Text fz={15} fw={500} c= {title_color}>
           {title}
            </Text>
            <Text fz={14} fw={500} c={value_color}>
                { typeof value =='string' || typeof value =='number' ? <Code>{value}</Code> : value}
            </Text>
        </Group>
    
    </Box>
</Flex>
)
}
    