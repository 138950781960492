import {
  Box,
  Button,
  Card,
  LoadingOverlay,
  Grid,
  Textarea,
  Group,
  Title,
  Select,
  Text,
  TextInput,
  TransferListData,
  Table,
  Avatar,
  ActionIcon,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { hasLength, isNotEmpty, useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import {
  IconCalendar,
  IconCheck,
  IconDots,
  IconMoneybag,
  IconPlus,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import { FormEvent, useEffect, useRef, useState } from "react";
import { regOrder, regOrderTask } from "../../../api";
import SelectUserToProject from "../../projects/select_user_to_project";
import { SelectRole, SelectUser } from "../../../interface/Icommon";
import { selectEmployeeProps } from "../../../interface/Iemloyee";
import { IOrderNewCreateList, IOrderProps } from "../../../interface/Iorder";
import { ITask } from "../../../interface/Itask";
import { FormattedMessage, useIntl } from "react-intl";
import SaveBottom from "../../../ttcomponents/Button/SaveBtn";
import { JudgmentTimeThreshold } from "../../../utils/function";
import localstorageUnits from "../../../utils/localstorageUnits";

interface IOrderFromProps {
  callBack: (order_id: string) => void;
  orderItem: IOrderProps | undefined;
  taskcateGoryListData: SelectRole[];
  [key: string]: any;
}

// Custom validation function for time_consumtion field
const validateTimeConsumtion = (value: string) => {
  const pattern = /^(\d+):(\d+):([0-5][0-9])$/;
  if (!pattern.test(value)) {
    return "Time consumption must be in the format HH:MM";
  }

  const [hours, minutes] = value.split(":");
  if (Number(hours) > 10000 || Number(minutes) >= 60) {
    return "Invalid time consumption";
  }
  return undefined;
};

export default function TaskEditFrom({
  callBack,
  statusListProps,
  isWithoutOrder,
  taskcateGoryListData,
  orderItem,
  employeesList,
  taskItem,
  UserLists,
  userlist,
}: IOrderFromProps) {
  //加载中状态
  const [loading, setLoading] = useState(false);
  // get Task Mode Flag
  const taskModeFlag = localstorageUnits.getSystem().task_mode_flag;
  const formRef = useRef<HTMLFormElement>(null);
  const [teamLeaderValue, setTeamLeaderValue] = useState<string[]>([]);
  const [employeestool, setEmployees] = useState<selectEmployeeProps[]>(
    taskItem.task_employee
  );
  const [taskItemSingel, setTaskItemSingel] = useState<ITask>(taskItem);
  const [resetUser, setresetUser] = useState(false);
  const [projectEmployeelist, setProjectEmployeelist] =
    useState<TransferListData>([[], []]);
  // 钩子函数-切换语言
  const intl = useIntl();
  // 定义状态
  const [searchStatusValue, onSearchStatusValue] = useState("");

  const [searchEmployeesValue, onEmployeesSearchChange] = useState("");
  // 设置task的free descripotion 是否可以填写
  const [descEnable, setDesEnable] = useState<boolean>(false);
  // 初始化表单数据
  const form = useForm({
    initialValues: {
      project_id: orderItem?.project_id,
      id_task: taskItemSingel.id_task,
      order_id: orderItem?.id_order,
      time_estimate: taskItemSingel.time_estimate,
      flag: taskItemSingel.flag,
      status_id: taskItemSingel.status_id,
      cost: taskItemSingel.cost,
      budget: taskItemSingel.budget,
      free_description: taskItemSingel.free_description,
      start_date: new Date(taskItemSingel.start_date),
      end_date: taskItemSingel.end_date
        ? new Date(taskItemSingel.end_date)
        : "",
      time: taskItemSingel.time_consumtion,
      task_leader_id: taskItemSingel.task_leader_id,
      employees_id: "",
      task_category_id: taskItemSingel.task_category_id,
    },
  });
  const setTeamLeaderValueHandle = (value: React.SetStateAction<string[]>) => {
    setTeamLeaderValue(value);
    form.setFieldValue("order_team_leaders", value as []);
  };

  // 处理修改请求
  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    setLoading(false);
    event.preventDefault(); // Stop the formal submission of the form default
    // Verification form
    const valid = await form.validate();
    // 使用 Set 数据结构来判断是否已经存在相同的元素
    const filteredData = employeestool.filter((item, index, self) => {
      const uniqueKey = `${item.employee_role_id}-${item.project_id}-${item.order_id}-${item.user_id}`;
      return (
        index ===
        self.findIndex((el) => {
          const elUniqueKey = `${el.employee_role_id}-${el.project_id}-${el.order_id}-${el.user_id}`;
          return elUniqueKey === uniqueKey;
        })
      );
    });

    // The start time of the setting cannot exceed the initial time of the system settings
    if (JudgmentTimeThreshold(form.values.start_date) === false) return false;

    if (!valid.hasErrors) {
      let originalData = {
        order_id: form.values.order_id,
        id: form.values.id_task,
        flag: form.values.flag,
        time_estimate: form.values.time_estimate,
        task_category_id: form.values.task_category_id,
        start_date: form.values.start_date.toISOString().split("T")[0],
        end_date: form.values.end_date
          ? new Date(form.values.end_date).toISOString().split("T")[0]
          : "",
        time_consumtion: form.values.time,
        free_description: form.values.free_description,
        task_leader_id: form.values.task_leader_id,
        cost: form.values.cost,
        budget: form.values.budget,
        status_id: form.values.status_id,
        employees: filteredData,
      };
      const response = await regOrderTask(originalData, "PUT");
      const result = response.data;
      if (result.code === 200) {
        setLoading(false);
        callBack(form.values.order_id as string);
        notifications.show({
          color: "green",
          icon: <IconCheck />,
          message: result.msg,
        });
      } else {
        notifications.show({
          color: "red",
          icon: <IconX />,
          message: result.msg,
        });
      }
    } else {
      notifications.show({
        title: "Error",
        color: "yellow.7",
        icon: <IconX />,
        message: Object.values(valid.errors)[0],
      });
    }
  };

  // 删除已选择的雇员信息
  const delOrderList = (indexs: number) => {
    const newData = employeestool.filter((item, index) => index !== indexs);
    form.setFieldValue("project_order", newData);
    setEmployees(newData); // 更新状态
  };

  const selectUserToProject = (value: TransferListData) => {
    let selectElement = value[1] as unknown as SelectUser[];
    const employees_id = form.values.employees_id;
    if (!employees_id) {
      notifications.show({
        title: intl.formatMessage({
          id: "warning",
          defaultMessage: "warning",
        }),
        color: "red",
        icon: <IconX />,
        message: "Need to assign roles to employees",
      });
      return null;
    }
    const updatedEmployeesSub = selectElement.map((item) => {
      return {
        employee_role_id: employees_id,
        avatar: item.avatar,
        label: item.label,
        project_id: form.values.project_id,
        order_id: form.values.order_id,
        user_id: item.value,
      };
    });
    const updatedEmployees = Array.isArray(employeestool)
      ? employeestool.concat(
          updatedEmployeesSub as unknown as selectEmployeeProps[]
        )
      : updatedEmployeesSub;
    setEmployees(updatedEmployees as selectEmployeeProps[]);
    setProjectEmployeelist(value);
    // form.setFieldValue('employees', updatedEmployees as selectEmployeeProps);
    // 其他逻辑...
    const filteredData = updatedEmployees.filter((item, index, self) => {
      // 使用 Set 数据结构来判断是否已经存在相同的元素
      const uniqueKey = `${item.employee_role_id}-${item.project_id}-${item.order_id}-${item.user_id}`;
      return (
        index ===
        self.findIndex((el) => {
          const elUniqueKey = `${el.employee_role_id}-${el.project_id}-${el.order_id}-${el.user_id}`;
          return elUniqueKey === uniqueKey;
        })
      );
    });

    setEmployees(filteredData as selectEmployeeProps[]);
  };
  //
  const rows = employeestool.map((element, index) => (
    <tr key={index}>
      <td>
        <Avatar src={element.avatar} radius={40} size={26} />
        <Text>{element.label ? element.label : element.username}</Text>
      </td>
      <td> {orderItem?.order_name}</td>
      <td>{element.project_id}</td>
      <td>
        {employeesList.map((item: any, index: any) => {
          if (element.employee_role_id == item.value) {
            return item.label;
          }
        })}
      </td>
      <td>
        <Group mt="md">
          <ActionIcon onClick={() => delOrderList(index)}>
            <IconTrash size="1rem" stroke={1.5} />
          </ActionIcon>
        </Group>
      </td>
    </tr>
  ));

  useEffect(() => {
    setDesEnable(taskItemSingel.flag === "0" ? false : true);
  }, [taskItemSingel.flag]);
  // 更具flag选择 选择freedescripotion 是否可填
  function pickCategory(value: string | null): void {
    setDesEnable(value === "0" ? false : true);
    form.setFieldValue("flag", value as string);
  }

  function onEmployeesSearchChangeHandle(value: string | null): void {
    setresetUser(true);
    form.setFieldValue("employees_id", value as string);
  }

  return (
    <form onSubmit={handleFormSubmit} ref={formRef}>
      <LoadingOverlay visible={loading} overlayBlur={2} />
      <Box ml={taskModeFlag == 2 ? 0 : 20}>
        <Card mih={320}>
          <Grid>
            <Grid.Col span={taskModeFlag == 2 ? 12 : 6}>
              {isWithoutOrder ? null : (
                <>
                  <Title order={4} color="blue">
                    order name : {orderItem?.order_name}
                  </Title>
                  <Title order={5} color="blue">
                    order no : {orderItem?.order_no}
                  </Title>
                </>
              )}

              <Select
                mt={20}
                labelProps={{ className: "input_title" }}
                label={intl.formatMessage({
                  id: "Task Category",
                  defaultMessage: "Task Category",
                })}
                required
                placeholder={intl.formatMessage({
                  id: "Select category",
                  defaultMessage: "Select category",
                })}
                disabled={!descEnable}
                defaultValue={taskItemSingel?.task_category_id}
                onChange={(value) =>
                  form.setFieldValue("task_category_id", value as string)
                }
                data={taskcateGoryListData}
              />

              {isWithoutOrder ? null : (
                <>
                  {" "}
                  <Select
                    mt={20}
                    required
                    labelProps={{ className: "input_title" }}
                    label={intl.formatMessage({
                      id: "Task Team Leaders",
                      defaultMessage: "Task Team Leaders",
                    })}
                    placeholder={intl.formatMessage({
                      id: "Select Leader",
                      defaultMessage: "Select Leader",
                    })}
                    defaultValue={taskItemSingel.task_leader_id}
                    onChange={(value) =>
                      form.setFieldValue("task_leader_id", value as string)
                    }
                    data={userlist}
                  />
                  <Grid>
                    <Grid.Col span={6}>
                      <DateInput
                        valueFormat="DD/MM/YYYY"
                        size="xs"
                        required
                        {...form.getInputProps("start_date")}
                        icon={<IconCalendar size="1.1rem" stroke={1.5} />}
                        label={intl.formatMessage({
                          id: "Start Time",
                          defaultMessage: "Start Time",
                        })}
                        placeholder={intl.formatMessage({
                          id: "Start Time",
                          defaultMessage: "Start Time",
                        })}
                        maw={400}
                        mx="auto"
                      />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <DateInput
                        valueFormat="DD/MM/YYYY"
                        {...form.getInputProps("end_date")}
                        size="xs"
                        icon={<IconCalendar size="1.1rem" stroke={1.5} />}
                        label={intl.formatMessage({
                          id: "End Time",
                          defaultMessage: "End Time",
                        })}
                        minDate={form.values.start_date}
                        placeholder={intl.formatMessage({
                          id: "End Time",
                          defaultMessage: "End Time",
                        })}
                        maw={400}
                        mx="auto"
                      />
                    </Grid.Col>
                    <Grid.Col span={4}></Grid.Col>
                  </Grid>
                </>
              )}
              <Select
                placeholder={intl.formatMessage({
                  id: "select_items",
                  defaultMessage: "Select Items",
                })}
                searchValue={searchStatusValue}
                labelProps={{ className: "input_title" }}
                label={<FormattedMessage id="Status" defaultMessage="Status" />}
                onSearchChange={onSearchStatusValue}
                defaultValue={form.values.status_id.toString()}
                key={form.values.status_id}
                dropdownPosition="bottom"
                required
                searchable
                maxDropdownHeight={150}
                nothingFound={intl.formatMessage({
                  id: "No options",
                  defaultMessage: "No options",
                })}
                onChange={(value) =>
                  form.setFieldValue("status_id", value as string)
                }
                data={[
                  { value: "1", label: "Ready", disabled: true },
                  { value: "2", label: "Running", disabled: false },
                  { value: "3", label: "Finished", disabled: false },
                  { value: "4", label: "Cancelled", disabled: false },
                  { value: "5", label: "Paused", disabled: false },
                  { value: "6", label: "Archived", disabled: true },
                ]}
              />

              {isWithoutOrder ? (
                <TextInput
                  withAsterisk
                  mt={10}
                  mb={5}
                  required
                  labelProps={{ className: "input_title" }}
                  {...form.getInputProps("cost")}
                  label=" Cost "
                  placeholder={intl.formatMessage({
                    id: "Input",
                    defaultMessage: "Input",
                  })}
                />
              ) : (
                <> </>
              )}
            </Grid.Col>

            <Grid.Col span={4} ml={30}>
              {/* <Select
                                mt={20}
                                labelProps={{ className: 'input_title', }}
                                label="Flag"
                                placeholder="Select category"
                                defaultValue={taskItemSingel?.flag}
                                onChange={(value) => pickCategory(value)}
                                data={[{ 'value': '0', 'label': 'no' }, { 'value': '1', 'label': 'yes' },]} /> */}

              {isWithoutOrder ? null : (
                <>
                  <Textarea
                    mt={20}
                    labelProps={{ className: "input_title" }}
                    placeholder={intl.formatMessage({
                      id: "Input",
                      defaultMessage: "Input",
                    })}
                    label={intl.formatMessage({
                      id: "Task Description",
                      defaultMessage: "Task Description",
                    })}
                    withAsterisk
                    {...form.getInputProps("free_description")}
                  />
                  <TextInput
                    withAsterisk
                    mt={20}
                    labelProps={{ className: "input_title" }}
                    {...form.getInputProps("time_estimate")}
                    label=" Estimated Time"
                    placeholder={intl.formatMessage({
                      id: "Input",
                      defaultMessage: "Input",
                    })}
                  />
                  <TextInput
                    withAsterisk
                    mt={20}
                    labelProps={{ className: "input_title" }}
                    {...form.getInputProps("budget")}
                    label={intl.formatMessage({
                      id: "Budget",
                      defaultMessage: "Budget",
                    })}
                    required
                    placeholder={intl.formatMessage({
                      id: "Input",
                      defaultMessage: "Input",
                    })}
                  />
                  <TextInput
                    withAsterisk
                    mt={20}
                    mb={5}
                    required
                    labelProps={{ className: "input_title" }}
                    {...form.getInputProps("cost")}
                    label=" Cost "
                    placeholder={intl.formatMessage({
                      id: "Input",
                      defaultMessage: "Input",
                    })}
                  />
                </>
              )}
            </Grid.Col>
          </Grid>
          {isWithoutOrder ? null : (
            <>
              <Card px={0} withBorder radius="md" shadow="sm">
                <Select
                  mx={10}
                  placeholder="Select Role "
                  labelProps={{ className: "input_title" }}
                  label={intl.formatMessage({
                    id: "Employees Role",
                    defaultMessage: "Employees Role",
                  })}
                  searchValue={searchEmployeesValue}
                  size="xs"
                  searchable
                  onChange={(value) => onEmployeesSearchChangeHandle(value)}
                  onSearchChange={onEmployeesSearchChange}
                  w={300}
                  defaultValue={searchEmployeesValue}
                  data={employeesList}
                />
                <SelectUserToProject
                  resetUser={resetUser}
                  orderid={form.values.order_id}
                  employee={form.values.employees_id}
                  callBack={selectUserToProject}
                  userlist={UserLists}
                />
                {employeestool.length > 0 ? (
                  <Table w="50%" mx={20} mt={20}>
                    <thead>
                      <tr>
                        <th>User </th>
                        <th>Order ID</th>
                        <th> Project ID</th>
                        <th>Role Name </th>
                        <th>Option </th>
                      </tr>
                    </thead>
                    <tbody>{rows}</tbody>
                  </Table>
                ) : (
                  "No employee information set"
                )}
              </Card>
            </>
          )}

          {/* 如果存在雇员以表格形式显示 */}

          <Group position="right">
            <SaveBottom formRef={formRef} />
          </Group>
        </Card>
      </Box>
    </form>
  );
}
