import {
  Box,
  Button,
  Card,
  Flex,
  Modal,
  Group,
  Text,
  Paper,
  LoadingOverlay,
  Table,
  ActionIcon,
} from "@mantine/core";
import {
  IconCheck,
  IconCirclePlus,
  IconPencil,
  IconSquareRoundedPlus,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import SearchBox from "../../../../ttcomponents/SearchBox/SearchBox";
import { regCustomerRole, regEmployeeroles } from "../../../../api";
import { useEffect, useRef, useState } from "react";
import TableUniversal from "../../../../ttcomponents/Table/TableUniversal";
import PaginationA from "../../../../ttcomponents/Pagination/PaginationA";
import { useDisclosure } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { IPagination } from "../../../../interface/Ipagintion";
import { Role, ApiResponse, PermissionIdentifierInterface } from "../../../../interface/Iemloyee";
import EmployeeRowForm from "./permission_identifier_form";
import TableEmployee from "../../../../ttcomponents/Table/TableEmployee";
import PageTitle from "../../../../ttcomponents/PageTitle/PageTitle";
import { useIntl } from "react-intl";
import CreateBtn from "../../../../ttcomponents/Button/CreateBtn";
import MoreActionBtnWithBottom from "../../../../ttcomponents/MoreActionBtnWithBottom/MoreActionBtnWithBottom";
import WordTranslationComponent from "../../../../utils/language_pack/words";
import ModalTitleText from "../../../../ttcomponents/ModalTitleText/ModalTitleText";
import { ContactRole } from "../../../../interface/Icoustomer";
import { requestPermissionIdentifier, requestPermissionRole } from "../../../../api/index_v2";
import { RootState } from "../../../../state_management/store";
import { useSelector } from "react-redux";
import { getCostCenterGeneralSelectFunction } from "../../../../api/common_request";
import { SelectRole } from "../../../../interface/Icommon";
import ActiveBadge from "../../../../ttcomponents/ActiveBadge/ActiveBadge";
import DeleteIcon from "../../../../ttcomponents/DeleteIcon/DeleteIcon";
import EditIcon from "../../../../ttcomponents/EditIcon/EditIcon";
import PermissionRoleTableTr from "./permission_identifier_tr";
import React from "react";
import { modals } from "@mantine/modals";
import { HintInfo } from "../../../../utils/function";
import PermissionIdentifierForm from "./permission_identifier_form";
import PermissionIdentifierTableTr from "./permission_identifier_tr";
interface ComponentInterface{
  is_load_str?:string
}
export default function PermissionIdentifier({is_load_str}:ComponentInterface) {
  // Get translation public configuration information
  const word_translation = WordTranslationComponent();

  // Show more switch settings
  const [moreOpened, setMoreOpened] = useState(false);
  //Mid -load state
  const [loading, setLoading] = useState(false);

  // Declare the variable costCenterData that stores the user branch center
  const [costCenterData, setCostCenterData] = useState<SelectRole[]>([]);
  // 获取redux store中的值
  const SearchValue = useSelector((state: RootState) => state.search.searchValue)

  const [permissionRoleData, setPermissionRoleData] = useState<PermissionIdentifierInterface[]>([]);
  const [listCount, setListCount] = useState<number>(0);
  const [currentItemInfo, setCurrentItemInfo] = useState<PermissionIdentifierInterface | undefined>(undefined);

  const [openFormStatus, { open: openCurrentPageForm, close: closePageForm }] =
    useDisclosure(false);
  const [pagination, setPagination] = useState<IPagination>({
    search: "",
    page: 1,
    pagesize: 10,
    sortBy: "",
    sortOrder: "asc",
  });

  const getCostCenter = async () => {
    // Call the Get Cost Center General Selection function and set the cost center data
    setCostCenterData(await getCostCenterGeneralSelectFunction());
  };



  // Utilizing RTK to implement search
  const ReceiveSearchBack = (value: string | number) => {
    // Update pagination state with the new search value
    setPagination((prevPagination) => ({
      ...prevPagination,
      search: value,
    }));
  };

  // Execute the useeffect when the first load is loaded.
  const isInitialMount = useRef(true);
  useEffect(() => {
    if (!isInitialMount.current) {
      ReceiveSearchBack(SearchValue);
    }
    isInitialMount.current = false;
  }, [SearchValue]);
  const ajaxCurrentList = async () => {
    // Set loading state to true while fetching data
    setLoading(true);

    try {
      // Make a GET request to retrieve user registrations with pagination parameters
      const response = await requestPermissionIdentifier(pagination, "GET");
      const result = response.data;

      // Check if the response is successful and the data is not an array
      if (result.code === 200 && !Array.isArray(result.data)) {
        // Update the total count and user registration data in component state
        setPermissionRoleData(result.data.data);
        setListCount(result.data.total_count)
      } else {
        // Log an error message if the response indicates an issue
        console.error(result.msg);
      }
    } catch (error) {
      // Log any errors that occur during the request
      console.error('Error fetching user registrations:', error);
    } finally {
      // Set loading state to false regardless of success or failure
      setLoading(false);
    }
  };

  useEffect(() => {
    if(is_load_str =="permission_identifier"){
      ajaxCurrentList();
      getCostCenter()
    }
  }, [is_load_str]);

  
  useEffect(() => {
    if(is_load_str =="permission_identifier"){
      ajaxCurrentList();
    }
  }, [pagination]);

  // 分页回调触发更新 分页回调触发更新
  const ReceivePaginationABack = (page: number) => {
    setPagination((prevpagination) => ({
      ...prevpagination,
      page: page,
    }));
  };

  const handleOnValueEdit = (value: PermissionIdentifierInterface) => {
    setCurrentItemInfo(value);
    openCurrentPageForm();
  };


  // Delete Permission Role
  const handleOnValueDel =  (row: PermissionIdentifierInterface) => {
    modals.openConfirmModal({
      title: word_translation.delete_title,
      centered: true,
      children: (
        <Text size="sm" mt={12}>
            <p>{word_translation.name} : {row.name}</p>
            <p>{word_translation.identifier} : {row.identifier}</p>
        </Text>
      ),
      labels: {
        confirm: word_translation.confirm_delete,
        cancel:word_translation.cancel_delete,
      },
      confirmProps: { color: "red" },
      onConfirm: async() => {
          const SupplierResult = await requestPermissionIdentifier({ id: row.id_permission_identifier }, "DELETE");
          HintInfo(SupplierResult.data);
          if (SupplierResult.data.code == 200) {
            ajaxCurrentList();
          }
      },
    });
  };


  // open form component 
  const openCurrentPageFormFunction = () => {
    openCurrentPageForm();
    setCurrentItemInfo(undefined)
  }
  
  // close form component 
  const closePageFormFunction = () => {
    closePageForm();
  }


  // close form component 
  const successFormCallback = (value: boolean) => {
    closePageForm();
    // refresh list
    value && ajaxCurrentList();
  }


  return (
    <Paper>
      <LoadingOverlay visible={loading} overlayBlur={2} />
      <Box pb={20}>
        {/* <PageTitle title={word_translation.permission_role_management} /> */}
        <table className="table_custome text-center">
          <thead className="table_th">
            <tr>
              <th>{word_translation.id_permission_identifier}</th>
              <th>{word_translation.name}</th>
              <th>{word_translation.identifier}</th>
              <th> {word_translation.cost_center_name}</th>
              <th>{word_translation.created_at}</th>
              <th>{word_translation.option}</th>  
            </tr>
          </thead>
          <tbody>
            {
              permissionRoleData.length > 0 ? (
                permissionRoleData.map((item, index) => (
                  <React.Fragment key={index}>
                      <PermissionIdentifierTableTr currentItem={item} delHandle={()=>handleOnValueDel(item)} editHandle={()=>handleOnValueEdit(item)} />
                  </React.Fragment>
                ))
              ) : (
                <tr>
                  <td colSpan={6}>
                    {word_translation.no_data}
                  </td>
                </tr>
              )}
          </tbody>
        </table>
        <PaginationA
          callBack={ReceivePaginationABack}
          total_count={listCount as number}
          pagesize={pagination.pagesize}
          page={pagination.page}
        />
      </Box>

      <Modal
        opened={openFormStatus}
        onClose={closePageFormFunction}
        closeOnClickOutside={false}
        title={<ModalTitleText title={currentItemInfo?.id_permission_identifier ? word_translation.edit +  word_translation.identifier : word_translation.add_new +  word_translation.identifier} />}
        px={10}
        size='md'
        radius={10}
      >
        {/* Form Madal */}
      <PermissionIdentifierForm
        currentItemInfo={currentItemInfo}
        closeFunction={closePageFormFunction}
        successCallback={(value) => { successFormCallback(value) }}
        costCenterData={costCenterData}
      />

      </Modal>

      {moreOpened && (
        <Paper className="positioned-right-bottom-content" shadow="sm">
          <Card>
            <Flex
              className="border-radius-md"
              mih={50}
              gap="md"
              justify="flex-end"
              align="flex-end"
              direction="column"
              wrap="wrap"
            >
              <CreateBtn callBack={openCurrentPageFormFunction} />
            </Flex>
            <Group noWrap>
              {/* Add more elements if needed */}
            </Group>
          </Card>
        </Paper>
      )}
      <MoreActionBtnWithBottom moreOpened={moreOpened} callBack={setMoreOpened} />
    </Paper>
  );
}
