import {
  Box,
  Code,
  Grid,
  Group,
  NumberInput,
  Paper,
  Select,
  Text,
  TextInput,
  Textarea,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import CancelButton from "../../../../ttcomponents/Button/CancelBtn";
import SaveBottom from "../../../../ttcomponents/Button/SaveBtn";
import { FormEvent, forwardRef, useEffect, useRef, useState } from "react";
import { isNotEmpty, useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { IconX } from "@tabler/icons-react";
import {
  HintInfo,
  TotalPriceFunction,
  formatDateUpgraded,
} from "../../../../utils/function";
import globalCortSetting from "../../../../utils/globalCortSetting";
import ProductSelect from "../../../../ttcomponents/Product/ProductSelect";
import {
  regGetProductPurchase,
  regItemListByType,
  regOrder,
  regPickingOutBound,
  regProductPurchase,
  regProjectList,
} from "../../../../api";
import { SelectRole } from "../../../../interface/Icommon";
import { IOrderPropsUp, OrderProductData } from "../../../../interface/Iorder";
import localstorageUnits from "../../../../utils/localstorageUnits";
import {
  IAPIFilterProjectList,
  filterOrderItem,
  filterProjectItem,
  filterTaskItem,
} from "../../../../interface/IReport";
import { useIntl } from "react-intl";
interface deliverProductProps {
  additional_information: any;
  closeFucntion: (type: number) => void;
}

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  value: string;
  label: string;
  order_name?: string;
  name?: string;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ value, label, order_name, name, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <div>
          <Text size="sm">{label}</Text>
          {order_name ? (
            <Text size="xs" opacity={0.65}>
              order name: {order_name}
            </Text>
          ) : null}

          {name ? (
            <Text size="xs" opacity={0.65}>
              project name: {name}
            </Text>
          ) : null}
        </div>
      </Group>
    </div>
  )
);
export default function PickingOutBoundProductFrom({
  additional_information,
  closeFucntion,
}: deliverProductProps) {
  // Use the useIntl hook to access the intl object
  const intl = useIntl();

  const formRef = useRef<HTMLFormElement>(null);
  // Task Mode Flag
  const taskModeFlag = localstorageUnits.getSystem().task_mode_flag;

  const [filterProjectList, setFilterProjectList] = useState<
    filterProjectItem[]
  >([]);

  const [dataList, setListData] = useState<SelectRole[]>([]);
  const [modelStr, setModelStr] = useState("associate id ");

  const form = useForm({
    initialValues: {
      product_id: additional_information?.product_id,
      picking_out_bound_quantity: "",
      total_price: "",
      type: "",
      associate_id: "",
      description: "",
    },
    validate: {
      product_id: isNotEmpty("Product name is required"),
      picking_out_bound_quantity: isNotEmpty(
        "picking_out_bound_quantity  is required"
      ),
    },
  });

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    // Stop the formal submission of the form default
    event.preventDefault();
    // Verification form
    const valid = form.validate();
    if (valid.hasErrors) {
      notifications.show({
        color: "yellow.7",
        icon: <IconX />,
        message: Object.values(valid.errors)[0],
      });
      return;
    }
    let submit_type = "POST";
    // if (form.values.order_id) {
    //   submit_type = 'PUT'
    // }

    const response = await regPickingOutBound(form.values, submit_type);
    HintInfo(response.data);
    if (response.data.code == 200) {
      closeFucntion(2);
    }
  };
  const ReceiveSupplierBack = (value: any) => {};

  const changeDelivelyTime = (num: any) => {
    const totalPrice = TotalPriceFunction(
      num,
      additional_information?.unit_price
    );
    form.setFieldValue("total_price", totalPrice as string);
    form.setFieldValue("picking_out_bound_quantity", num);
  };

  const getAssociateId = async (type: string) => {
    if (type == "") return false;
    const response = await regItemListByType(
      { flag: taskModeFlag, type: type, status_id: "" },
      "GET"
    );
    const result = response.data as IAPIFilterProjectList;
    let transferData: any;
    switch (type) {
      case "project":
        setModelStr("Project");
        transferData = response.data.data.map((item: filterProjectItem) => {
          return {
            value: item.id_project,
            label: item.name,
            type: "project",
          };
        });
        setListData(transferData);
        break;
      case "order":
        setModelStr("Order");
        transferData = response.data.data.map((item: filterOrderItem) => {
          return {
            label: item.order_name,
            value: item.id_order,
            name: item.project_name,
            order_name: "",
            type: "order",
          };
        });
        setListData(transferData);
        break;
      case "task":
        setModelStr("Task");
        transferData = response.data.data.map((item: filterTaskItem) => {
          return {
            label: item.identifier,
            value: item.id_task,
            order_name: item.order_name,
            name: item.name,
            type: "task",
          };
        });
        setListData(transferData);
        break;
      case "task_only":
        break;
    }
  };

  // // 选择类型
  // const handleFilterFuntion = (value: string) => {
  //   console.log(value);
  //   form.setFieldValue('type', value)
  //   setFilterType(value);
  // }

  useEffect(() => {
    getAssociateId(form.values.type);
  }, [form.values.type]);

  return (
    <Paper p={10}>
      <form onSubmit={handleFormSubmit} ref={formRef}>
        <ProductSelect
          createNew={false}
          disabled={additional_information?.product_id ? true : false}
          labelStr="Product Name"
          currentId={additional_information?.product_id as string}
          callBack={ReceiveSupplierBack}
        />

        {taskModeFlag == "2" ? (
          <Select
            label="Select the type of product"
            placeholder="Pick one"
            // data={globalCortSetting.PickingProductType}
            data={["project", "task"]}
            labelProps={{ className: "input_title" }}
            dropdownPosition="top"
            onChange={(value) => form.setFieldValue("type", value as string)}
          />
        ) : (
          <Select
            label="Select the type of product"
            placeholder="Pick one"
            // data={globalCortSetting.PickingProductType}
            data={["project", "order", "task"]}
            labelProps={{ className: "input_title" }}
            dropdownPosition="top"
            onChange={(value) => form.setFieldValue("type", value as string)}
          />
        )}

        <Select
          label={"Select " + modelStr}
          placeholder="Pick one"
          itemComponent={SelectItem}
          data={dataList}
          searchable
          maxDropdownHeight={400}
          nothingFound="Nobody here"
          filter={(value, item) =>
            (item.label &&
              item.label.toLowerCase().includes(value.toLowerCase().trim())) ||
            item.name.toLowerCase().includes(value.toLowerCase().trim()) ||
            item.order_name.toLowerCase().includes(value.toLowerCase().trim())
          }
          onChange={(value) =>
            form.setFieldValue("associate_id", value as string)
          }
        />

        <NumberInput
          {...form.getInputProps("picking_out_bound_quantity")}
          // value={categoryName}
          onChange={(event) => changeDelivelyTime(event)}
          label="Picking Out Bound Quantity"
        />
        <TextInput {...form.getInputProps("total_price")} label="Total Price" />

        <Textarea
          label={intl.formatMessage({
            id: "Product Introduction",
            defaultMessage: "Product Introduction",
          })}
          {...form.getInputProps("description")}
          minRows={5}
          radius="md"
        />

        <Group position="right">
          {/* <CancelButton callBack={() => closeFucntion(1)} /> */}
          <SaveBottom formRef={formRef} />
        </Group>

        {/** can see form values */}
        <Code block mt={5}>
          {JSON.stringify(form.values, null, 2)}
        </Code>
      </form>
    </Paper>
  );
}
