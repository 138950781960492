import {
  Paper,
  Box,
} from "@mantine/core";
import PageTitle from "../../../ttcomponents/PageTitle/PageTitle";

import WordTranslationComponent from "../../../utils/language_pack/words";
import TableBoarding from "../common/table_boarding";

export default function NewBoarding() {
  // Get translation public configuration information
  const word_translation = WordTranslationComponent();
  return (
    <Box>
      <Paper px={20} pb={10} w={"100%"} style={{ position: "relative" }}>
        <PageTitle title="NEW - BOARDINGS" lang_id="new_boarding" />
      </Paper>
       <TableBoarding boarding_status_id='1' />
    </Box>
  );
}
