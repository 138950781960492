import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSearch } from '../../state_management/features/search/searchSlice'

import {
  createStyles,
  Header,
  Group,
  ActionIcon,
  Container,
  Burger,
  rem,
  Box,
  Paper,
  Text,
  useMantineColorScheme,
  useMantineTheme,
  Menu,
  UnstyledButton,
  Avatar,
  Flex,
  Divider,
  PaperProps,
  Grid,
  TextInput
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import {
  IconWorld,
  IconSettings,
  IconSwitchHorizontal,
  IconLogout,
  IconChevronDown,
  IconHome,
  IconArrowNarrowRight,
  IconBell,
  IconMessageDots,
  IconAlarm,
  IconSearch,
  IconCheck,
  IconX,
  IconLayoutDashboard
} from '@tabler/icons-react'

import apiUrl from '../../config/api_config/config'
import localstorageUnits from '../../utils/localstorageUnits'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { notifications } from '@mantine/notifications'
import { regLogout } from '../../api'
import memoryUtils from '../../utils/memoryUtils'
import { channel } from 'diagnostics_channel'
import { FormattedMessage, useIntl } from 'react-intl'
import './css/index.css'
import { getCurrentWeekUtils } from '../../utils/function'
import { Arrow } from './svg/svg/Arrow'
import { Home } from './svg/svg/Home'
import { LangBtn } from './svg/svg/LangBtn'
import { MessageSvg } from './svg/svg/MessageSvg'
import { SettingsSvg } from './svg/svg/SettingsSvg'
import { AppDispatch, RootState } from '../../state_management/store'
import s from './css/header.module.css'

const useStyles = createStyles(theme => ({
  inner: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: rem(36),
    paddingTop: '3vh',

    [theme.fn.smallerThan('sm')]: {
      justifyContent: 'flex-start'
    }
  },

  links: {
    width: rem(260),

    [theme.fn.smallerThan('sm')]: {
      display: 'none'
    }
  },

  social: {
    width: rem(260),

    [theme.fn.smallerThan('sm')]: {
      width: 'auto',
      marginLeft: 'auto'
    }
  },

  burger: {
    marginRight: theme.spacing.md,

    [theme.fn.largerThan('sm')]: {
      display: 'none'
    }
  },

  link: {
    display: 'block',
    lineHeight: 1,
    padding: `${rem(8)} ${rem(12)}`,
    borderRadius: theme.radius.sm,
    textDecoration: 'none',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0]
    }
  },

  linkActive: {
    '&, &:hover': {
      backgroundColor: theme.fn.variant({
        variant: 'light',
        color: theme.primaryColor
      }).background,
      color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color
    }
  }
}))

interface HeaderMiddleProps {
  sendCurrentPath: string
  PaperProps?: PaperProps
  searchValue: string
  searchValueCallback: (value: string) => void
  callback: (value: string) => void
}

export function HeaderMiddle(props: HeaderMiddleProps) {
  const intl = useIntl()

  const dispatch = useDispatch()

  const navigate = useNavigate()

  const userExists = memoryUtils.user

  if (!userExists.username) {
    notifications.show({
      title: 'Warning',
      color: 'yellow.7',
      icon: <IconX size="1.5rem" />,
      message: 'No user information, please log in'
    })
    setTimeout(() => {
      // 自动跳转登录
      navigate('/login')
    }, 0)
  }

  const [searchValue, setSearchValue] = useState<string>(props.searchValue || '')

  const location = useLocation()

  const currentLink = location.pathname

  let currentLabel = ''
  let parentLink = ''
  let parentLabel = ''

  const [userMenuOpened, setUserMenuOpened] = useState(false)

  const { colorScheme, toggleColorScheme } = useMantineColorScheme()

  const theme = useMantineTheme()

  const dark = colorScheme === 'dark'

  const [opened, { toggle }] = useDisclosure(false)

  const [user, setUser] = useState({
    name: 'xiaohua',
    Image: localstorageUnits.getUser().avatar ? apiUrl.fileSystemDomain + localstorageUnits.getUser().avatar : ''
  })

  const [user_role_id, set_user_role_id] = useState<string | null>(memoryUtils.user.user_role_id)
  const { classes, cx } = useStyles()
  const [nameUser, setNameUser] = useState('')
  const [emailUser, setEmailUser] = useState('')
  //   初始化当前日期
  const [currentDayString, setCurrentDayString] = useState('')
  const [currentWeek, setCurrentWeek] = useState(0)
  const [currentYear, setCurrentYear] = useState(0)
  const [dayOfWeekText, setDayOfWeekText] = useState('')
  const [routePath, setRoutePath] = useState('')
  const [activeIndex, setActiveIndex] = useState(' ') // The activation status of the management menu item
  const [subActiveIndex, setSubActiveIndex] = useState(' ') // The activation status of the management sub -menu item

  const [initiallyOpeneds, setInitiallyOpeneds] = useState('')

  const [currentDate, setCurrentDate] = useState<Date>(new Date())

  // 获取当前的星期
  const getCurrentWeekNumber = (current: Date): number => {
    const weekNumber = getCurrentWeekUtils(current)
    setCurrentWeek(weekNumber)
    getdayOfWeek(current)
    getDay(current)
    getYear(current)
    return weekNumber
  }
  // 获取年
  const getYear = (date: Date) => {
    const now = date || new Date()
    setCurrentYear(now.getFullYear())
  }

  function callback() {
    let storagePath = localstorageUnits.getPath()
    if (storagePath && Object.keys(storagePath).length > 0) {
      setRoutePath(storagePath.currentUrl)
    }
  }

  function handNolinksMenu(flink: string): void {
    localstorageUnits.savePath({ currentUrl: flink })
    setActiveIndex && setActiveIndex('Settings')
    // callback();
    navigate(flink)
  }
  useEffect(() => {
    getCurrentWeekNumber(currentDate)
    setNameUser(userExists.username)
    setEmailUser(userExists.email)
    let storagePath = localstorageUnits.getPath()
  }, [currentDate])

  const SelectDate = (date: Date) => {
    setCurrentDate(date)
    getdayOfWeek(date)
    let week: number = getCurrentWeekNumber(date)
  }

  const getDay = (date: Date) => {
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear()
    const dateString = `${day}.${month}.${year}`
    setCurrentDayString(dateString)
  }
  const getdayOfWeek = (date: Date) => {
    // Convert the numbers of the week to the corresponding text representation
    const dayOfWeek = date.getDay()
    const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    const dayOfWeekText = weekdays[dayOfWeek]
    setDayOfWeekText(dayOfWeekText)
  }
  // 获取redux store中 menu的值
  const menuItemStatus = useSelector((state: RootState) => state.menu.menuItems)

  if (Object.keys(menuItemStatus).length !== 0 && menuItemStatus.constructor !== Object) {
    for (const menuItem of menuItemStatus) {
      if (menuItem.link === currentLink) {
        currentLabel = menuItem.label
        break
      }
      if (menuItem.links) {
        for (const subMenuItem of menuItem.links) {
          if (subMenuItem.link === currentLink) {
            currentLabel = subMenuItem.label
            parentLink = menuItem.link
            parentLabel = menuItem.label
            break
          }
        }
      }
    }
  }

  // 如果没有下面的变量的值，则视为左侧菜单底部的三个处理
  if (currentLabel == '' && parentLabel == '' && parentLink == '') {
    let storagePath = localstorageUnits.getPath()
    if (storagePath && Object.keys(storagePath).length > 0) {
      currentLabel = storagePath.currentUrl
    }
  }

  const loginOut = async () => {
    const response = await regLogout()
    const result = response.data
    if (result.code === 200) {
      localstorageUnits.removeUser()
      localstorageUnits.removeMenu()
      localstorageUnits.removePath()
      localstorageUnits.removePermission()
      navigate('/login')
      notifications.show({
        title: 'You have exited the system',
        color: 'green',
        icon: <IconCheck size="1.5rem" />,
        message: result.msg
      })
    } else {
      notifications.show({
        title: 'logOut Error',
        color: 'red',
        icon: <IconX size="1.5rem" />,
        message: result.msg
      })
    }
  }

  const channelValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
    /**
     * redux
     */
    dispatch(setSearch(event.target.value as string))
    props.callback(event.target.value)
  }
  //Retracement
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      props.callback(searchValue)
    }
  }
  // 导航跳转
  interface InavItem {
    label: string
    icon: React.FC<any>
    [key: string]: any
  }
  const handleSubMenuClick = (navItem: InavItem) => {
    navigate(navItem.link)
    localstorageUnits.savePath({ currentUrl: navItem.label })

    let storagePath = localstorageUnits.getPath()
    if (storagePath && Object.keys(storagePath).length > 0) {
      storagePath.currentActiveIndex
        ? setActiveIndex && setActiveIndex(storagePath.currentActiveIndex)
        : setActiveIndex && setActiveIndex(storagePath.currentPreActiveIndex)
      setSubActiveIndex('')
    }
  }

  return (
    <Box px={20}>
      <Box mah={38} className={s.header}>
        {/* date */}
        <Box className={s.headerDate}>
          <Text size={10}>
            KW {currentWeek} / {dayOfWeekText} {currentDayString}
          </Text>
        </Box>

        <Group spacing={30} position="right" w={'100%'} h={40} align={'center'} noWrap>
          <TextInput
            placeholder={intl.formatMessage({
              id: 'Search',
              defaultMessage: 'Search'
            })}
            style={{ transform: 'translateY(-2px)' }}
            w={158}
            size="xs"
            h={27}
            className="searchInput"
            rightSection={
              <IconSearch size="1.1rem" strokeWidth={2} style={{ stroke: '#acacac66', cursor: 'pointer' }} />
            }
            value={searchValue}
            onChange={channelValue}
          />
          <Group spacing={4}>
            <MessageSvg />
            <LangBtn />
            <SettingsSvg />
          </Group>

          <Group pr={10} spacing={'xs'}>
            <Box style={{ transform: 'translateY(-2px)' }}>
              <Text
                align="right"
                fw={600}
                color={dark ? theme.colors.customize[2] : theme.colors.customize[3]}
                size={14}
                lh={1.2}
              >
                {nameUser}
              </Text>
              <Text
                align="right"
                fw={400}
                color={dark ? theme.colors.customize[2] : theme.colors.customize[4]}
                size={12}
                lh={1}
              >
                {emailUser}
              </Text>
            </Box>
            <Group position="apart">
              <Burger opened={opened} onClick={toggle} className={classes.burger} size="sm" />
              <Menu
                width={260}
                position="bottom-end"
                transitionProps={{ transition: 'pop-top-right' }}
                onClose={() => setUserMenuOpened(false)}
                onOpen={() => setUserMenuOpened(true)}
                withinPortal
              >
                <Menu.Target>
                  <UnstyledButton>
                    <Group spacing={7}>
                      <Avatar src={user.Image} radius="xl" className="avatar" />
                      <IconChevronDown size={rem(12)} stroke={1.5} />
                    </Group>
                  </UnstyledButton>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Label>
                    <FormattedMessage id="Profile" defaultMessage="Profile" />
                  </Menu.Label>
                  {user_role_id == '1' ||
                    (user_role_id == '2' && (
                      <Menu.Item
                        onClick={() => handNolinksMenu('settings')}
                        icon={<IconSettings size="0.9rem" stroke={1.5} />}
                      >
                        <Text mx={8}>
                          <FormattedMessage id="Settings" defaultMessage="Settings" />
                        </Text>
                      </Menu.Item>
                    ))}

                  <Menu.Item icon={<IconMessageDots size="0.9rem" stroke={1.5} />}>
                    <Text mx={8}>
                      <FormattedMessage id="Message" defaultMessage="Message" />
                    </Text>
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => handNolinksMenu('timetracker')}
                    icon={<IconAlarm size="0.9rem" stroke={1.5} />}
                  >
                    <Text mx={8}>
                      <FormattedMessage id="time" defaultMessage="Time" />
                    </Text>
                  </Menu.Item>
                  <Menu.Item icon={<IconSearch size="0.9rem" stroke={1.5} />}>
                    <Text mx={8}>
                      <FormattedMessage id="Search" defaultMessage="Search" />
                    </Text>
                  </Menu.Item>
                  <Menu.Divider />

                  <Menu.Item
                    onClick={() => handNolinksMenu('profile')}
                    icon={<IconSwitchHorizontal size="0.9rem" stroke={1.5} />}
                  >
                    <Text mx={8}>
                      <FormattedMessage id="Profile" defaultMessage="Profile" />
                    </Text>
                  </Menu.Item>
                  <Menu.Item onClick={loginOut} icon={<IconLogout size="0.9rem" stroke={1.5} />}>
                    <Text mx={8}>
                      <FormattedMessage id="Logout" defaultMessage="Logout" />
                    </Text>
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </Group>
          </Group>
        </Group>
      </Box>
      <Box className={s.navbar} h={12} pb={8} mb={8}>
        <Text
          className="cursor-pointer"
          color={dark ? theme.colors.customize[5] : theme.colors.customize[5]}
          size={20}
          mr={2}
        >
          <Home />
        </Text>
        <Text
          className="cursor-pointer home-text-font"
          px={6}
          fw={500}
          size={14}
          onClick={() =>
            handleSubMenuClick({
              label: 'Dashboard',
              icon: IconLayoutDashboard,
              link: '/dashboard'
            })
          }
        >
          <FormattedMessage id="home" defaultMessage="Home" />
        </Text>
        {parentLink && (
          <>
            <Text mx={6}>
              <Arrow />
            </Text>
            <Text className="home-text-font">
              <Link style={{ textDecoration: 'none' }} to={parentLink}>
                <FormattedMessage id={parentLabel} defaultMessage={parentLabel} />
              </Link>
            </Text>
          </>
        )}

        {currentLink === '/dashboard' ? (
          ''
        ) : (
          <>
            <Text mx={6}>
              <Arrow />
            </Text>
            <Text className="Anchor-text-font">
              <Link style={{ textDecoration: 'none' }} to={currentLink}>
                <FormattedMessage id={currentLabel} defaultMessage={currentLabel} />
              </Link>
            </Text>
          </>
        )}
      </Box>
    </Box>
  )
}
