import { useForm } from "@mantine/form";
import {
  Grid,
  TextInput,
  Button,
  Box,
  Paper,
  useMantineColorScheme,
  Card,
  Group,
  Badge,
  Text,
} from "@mantine/core";
import { FormEvent, useEffect, useRef, useState } from "react";
import {
  regCompanySeting,
  regCountrySeting,
  regCurrency,
  regImgUpload,
  regLogin,
} from "../../../../api";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconX, IconUpload, IconPhoto } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import UploadSingleImage from "../../../../ttcomponents/UploadImage/UploadSingleImage/UploadSingleImage";
import { type } from "os";
import { FormattedMessage, useIntl } from "react-intl";
import SaveBottom from "../../../../ttcomponents/Button/SaveBtn";
import { ComponentInterface } from "../../../../interface/Icommon";
import WordTranslationComponent from "../../../../utils/language_pack/words";
import TableTopTitle from "../../../../ttcomponents/TableTopTitle/TableTopTitle";
import TableTextInputTitle from "../../../../ttcomponents/TableTextInputTitle/TableTextInputTitle";
import SubmitBtn from "../../../../ttcomponents/Button/SubmitBtn";
import { ClientWarningHint, HintErrorMantineInfo, HintInfo, HintInfoCatchError, HintInfoError } from "../../../../utils/function";
import CheckPermissionTools from "../../../../utils/permission";
import { NotPermission } from "../../../../ttcomponents/NoPermission";

export default function CountriesSettings({  is_load_str,}: ComponentInterface){



  const Perm = CheckPermissionTools();
  // Get translation public configuration information
  const word_translation = WordTranslationComponent();

  const form = useForm({
    validateInputOnBlur: true,
    initialValues: { id: 0, countryCode: "", iso: "", name: "" },
    // functions will be used to validate values at corresponding key
    validate: {
      name: (value) => {
        if (!value) {
          return "name is required";
        }
        if (value.length < 2 || value.length > 200) {
          return "name must be between 2 and 200 characters";
        }
        return null;
      },
      countryCode: (value) => {
        if (!value) {
          return "country code is required";
        }
        if (value.length < 2 || value.length > 11) {
          return "country code  must be between 2 and 200 characters";
        }

        return null;
      },
      iso: (value) => {
        if (!value) {
          return "iso must fill in";
        }
        if (value.length < 2 || value.length > 45) {
          return <IconX></IconX>;
        }
        return null;
      },
    },
  });

  /**
 * Handles the form submission.
 * Validates the form and determines the request type (POST or PUT) based on the form values.
 * Sends a request to create or update country settings and displays information based on the response.
 * @returns {boolean} Returns false if there are validation errors; otherwise, true.
 */
const handleFormSubmit = async () => {
  // Validate the form
  const valid = await form.validate();

  // If there are validation errors, display error information
  if (valid.hasErrors) {
    HintErrorMantineInfo(valid);
    return false;
  }

  // Determine the request type (POST or PUT) based on the form values
  const type = form.values.id !== 0 ? "PUT" : "POST";

  // Send a request to create or update country settings
  const response = await regCountrySeting(form.values, type);

  // Display information based on the response
  HintInfo(response.data);

  return true;
};

/**
 * Fetches country settings information from the server based on the current context.
 * If the current context is related to country settings, it makes an API request to get the data,
 * updates the form fields with the retrieved data, and logs an error message if the request is not successful.
 */
const ajaxCurrentList = async () => {
  // Check if the current context is related to country settings
  if (is_load_str === "country_set") {
    try {
      // Make an API request to get country settings data
      const response = await regCountrySeting({}, "GET");
      const result = response.data;

      // Check if the request was successful and the data is in the expected format
      if (result.code === 200 ) {
        if(!Array.isArray(result.data)){
          // Update form fields with the retrieved data
          form.setFieldValue("name", result.data.name);
          form.setFieldValue("id", result.data.id_countries);
          form.setFieldValue("countryCode", result.data.countryCode);
          form.setFieldValue("iso", result.data.iso);
        }else{

          ClientWarningHint(word_translation.no_data)
        }
      } else {
        // Log an error message if the request was not successful
          HintInfoError(result.msg)
      }
    } catch (error) {
      // Log an error if there is an exception during the request
      HintInfoCatchError();
    }
  }
};

  useEffect(() => {
    ajaxCurrentList();
  }, [is_load_str]);
  
  console.log(Perm.country_read,Perm.country_create,Perm.country_delete,'权限')
  return (
    <Paper>
      {Perm.country_read ?   <Grid gutter={0} align="left">
        <Grid.Col span={5}>
            <Box w={400} className="border-radius-xs shadow-sm border-custom">
               <TableTopTitle title={word_translation.country_name} />
                  <Box  p={20}  >
                  <TableTextInputTitle title={word_translation.country_name} />
                  <TextInput
                    w="100%"
                    placeholder={word_translation.country_name}
                    {...form.getInputProps("name")}
                  />
                  <TableTextInputTitle title={word_translation.country_code} />
                  <TextInput
                    placeholder={word_translation.Input}
                    {...form.getInputProps("countryCode")}
                  />
                  <TableTextInputTitle title={word_translation.country_iso} />
                  <TextInput
                    placeholder={word_translation.Input}
                    {...form.getInputProps("iso")}
                  />
                  <Group position="right" mt={10}>
                     <SubmitBtn  disabled={Perm.company_update == false}  btn_size="xs" callBack={handleFormSubmit}/>
                  </Group>
                </Box>
            </Box>
        </Grid.Col>
      </Grid> : <NotPermission />}  
   
        
        
    </Paper>
  );
}
