import React, { FormEvent, useEffect, useRef, useState } from "react";
import {
  TextInput,
  Textarea,
  Paper,
  Grid,
  Group,
  Checkbox,
  Radio,
} from "@mantine/core";
import {
  hasLength,
  isNotEmpty,
  useForm,
} from "@mantine/form";
import { IconCheck, IconX } from "@tabler/icons-react";
import { notifications } from "@mantine/notifications";
import { regGetUserRoleList } from "../../../../api";
import { UserRoleData } from "../../../../interface/Iuser";
import TableTextInputTitle from "../../../../ttcomponents/TableTextInputTitle/TableTextInputTitle";
import WordTranslationComponent from "../../../../utils/language_pack/words";
import CancelButton from "../../../../ttcomponents/Button/CancelBtn";
import SaveBottom from "../../../../ttcomponents/Button/SaveBtn";
import { HintInfo } from "../../../../utils/function";

interface ISupplierFormProps {
  closeFunction: (type: number) => void;
  userRoleItem: UserRoleData | undefined;
}
export default function UserRoleForm({
  closeFunction,
  userRoleItem,
}: ISupplierFormProps) {
  // Initialize references and hooks
  const formRef = useRef<HTMLFormElement>(null);

  const [value, setValue] = useState('1');
  // Get translation public configuration information
  const word_translation = WordTranslationComponent();
  const form = useForm({
    initialValues: {
      role_name: userRoleItem?.role_name,
      role_desc: userRoleItem?.role_desc,
      disabled: '0',
      normal:value,
      id: userRoleItem?.id_user_role,
      role_identifier: userRoleItem?.role_identifier,
    },
    // Validation rules for form fields
    validate: {
      role_name: hasLength(
        { min: 2, max: 100 },
        word_translation.role_name_validata
      ),
      // role_identifier: isNotEmpty(
      //   word_translation.role_original_name_not_empty
      // ),
    },
  });

  const [randRoleNumber, setRoleRandNumber] = useState(0);
  
  useEffect(() => {
    setRoleRandNumber(randRoleNumber+1);
  }, [userRoleItem]);
  // Handle form submission
  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Validate form fields
    const valid = form.validate();
    if (valid.hasErrors) {
      // Display error notification if validation fails
      notifications.show({
        color: "yellow.7",
        icon: <IconX />,
        message: Object.values(valid.errors)[0],
      });
      return;
    }
    // Determine submission type (POST or PUT)
    const submitType = form.values.id ? "PUT" : "POST";
    // Perform API request to save user role data
    const response = await regGetUserRoleList(form.values, submitType);
    HintInfo(response.data);
    if (response.data.code === 200) {
      // Close the form and show success notification
      closeFunction(2);
    }
  };
  return (
    <Paper  key={randRoleNumber} bg="var(--mantine-color-body)">
      <form onSubmit={handleFormSubmit} ref={formRef}>
        <Grid>
          {form.values.id == '' ||form.values.id == undefined &&  <Grid.Col span={12}>
          <Radio.Group
            mt={5}
           defaultValue={value}
           {...form.getInputProps("normal")}
            withAsterisk
          >    <Group mt="xs" position="right">
          <Radio value="1" label="General user" />
          <Radio value="2" label="Special user" />
          </Group>
          </Radio.Group>
          </Grid.Col> }
        
 
          {/* Input field for original role name */}
          <Grid.Col span={12}>
            <TableTextInputTitle title={word_translation.role_name} />
            <TextInput
              {...form.getInputProps("role_name")}
              placeholder={word_translation.Input}
              radius="md"
            />
          </Grid.Col>
          {/* Textarea for role description */}
          <Grid.Col span={12}>
            <TableTextInputTitle title={word_translation.user_role_desc} />
            <Textarea
              placeholder={word_translation.Input}
              {...form.getInputProps("role_desc")}
            />
          </Grid.Col>

        </Grid>
        <Checkbox
          label={<TableTextInputTitle title={word_translation.active} />}
          mt={10}
          key={form.values.disabled.toString()}
          checked={form.values.disabled.toString() == '0' ? true : false}
          onChange={(event) => {
            form.setFieldValue('disabled', event.currentTarget.checked ? '0' : '1')
          }}
        />

        <Group position="right">
          <CancelButton callBack={() => closeFunction(1)} />
          <SaveBottom formRef={formRef} />
        </Group>
      </form>
    </Paper>
  );
}
