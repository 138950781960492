import {
  Box,
  Grid,
  Avatar,
  Modal,
  Group,
  Code,
  Text,
  Paper,
  LoadingOverlay,
  Radio,
  Divider,
  Tooltip,
  Flex
} from '@mantine/core'
import '../css/index.css'
import { useEffect, useState } from 'react'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import LeaveRows from '../leave_row/leave_row'
import LeaveRowsManage from './leave_row_manger'
import { EventData, IComment, IUserVaction, Vacation, VacationData } from '../../../interface/Ivaction'
import {
  regGetSurplusVacation,
  regGetVationComment,
  regVacationCaledar,
  regVacationDateil,
  regVacationMangement
} from '../../../api'
import memoryUtils from '../../../utils/memoryUtils'
import SelectUserAllInfo from '../../../ttcomponents/SelectUser/SelectUser'
import { ClientWarningHint, HintInfo } from '../../../utils/function'
import LeaveVactionCommnet from '../leave_commnet'
import { useDisclosure, useToggle } from '@mantine/hooks'
import PageTitle from '../../../ttcomponents/PageTitle/PageTitle'
import TableTextInputTitle from '../../../ttcomponents/TableTextInputTitle/TableTextInputTitle'
import WordTranslationComponent from '../../../utils/language_pack/words'
import { IPagination } from '../../../interface/Ipagintion'
import apiUrl from '../../../config/api_config/config'
import TeamCalendar from '../calendar/team_calendar'
import localstorageUnits from '../../../utils/localstorageUnits'
import { UserAllList } from '../../../interface/Iuser'

export default function LeaveManger() {
  // Get translation public configuration information
  const word_translation = WordTranslationComponent()
  // Get the current user information
  const userExists = memoryUtils.user

  const leave_module_config = localstorageUnits.getSystem().leave_module
    ? JSON.parse(localstorageUnits.getSystem().leave_module)
    : {
        display_vacation_entitlement: '0',
        display_special_days: '0',
        display_latest_request: '0',
        display_sick_days: '0'
      }

  const [rand, setRand] = useState(1)
  const [year, setYear] = useState('')
  const [currentUserid, setCurrentUserid] = useState<any>('')
  const [currentVactionId, setCurrentVactionId] = useState<any>()
  const [value, toggle] = useToggle(['', '1', '2', '3', '4'])

  const [pagination, setPagination] = useState<IPagination>({
    search: '',
    page: 1,
    pagesize: 15,
    sortBy: '',
    year: '',
    query: {
      status_id: value
    },
    sortOrder: 'asc'
  })

  // Define holiday remarks
  const [vactionComment, setVactionComment] = useState<IComment[]>()
  const [userAllApprover, SetUserAllApprover] = useState<UserAllList[]>()

  const [vacationDetailedStatue, { open: vacationDetailedOpened, close: vacationDetailedClose }] = useDisclosure(false)

  const [loading, setLoading] = useState(false)

  const [item_loading, setItemLoading] = useState(false)

  const handleEventClick = async (event: any) => {
    vacationDetailedOpened()
    const ApprovalResult = await regGetVationComment({ leave_id: event.id })
    if (ApprovalResult.data.code === 200) {
      setCurrentVactionId(event.id)
      setVactionComment(ApprovalResult.data.data)
    } else {
      ClientWarningHint('You have no operational permissions for this vacation')
    }
  }

  const [calendarVationData, SetCalendarVationData] = useState<VacationData>()

  const [events, setVactionCaledarSelect] = useState<any[]>([])

  // Get all your personal holidays
  const [UserVationData, SetUserVationData] = useState<Vacation[]>([])

  // User holiday info
  const [userHoliday, setUserHoliday] = useState<IUserVaction>()

  // Define the user of the selection
  const [InspectorInfo, setInspectorInfo] = useState({
    name: '',
    email: '',
    avatar: ''
  })

  // Record for the application for the application
  const getVationRecord = async (where: any = '') => {
    try {
      setLoading(true)
      const VactionUserRespone = await regVacationMangement(pagination, 'GET')
      setLoading(false)
      if (VactionUserRespone.data.code == 200) {
        if (VactionUserRespone.data.data.length != 0) {
          SetUserVationData(prevData => [...prevData, ...VactionUserRespone.data.data])
        }
      }
    } catch (e) {
      console.log(word_translation.wrong_request)
    }
  }

  // Record for the application for the application
  const getVacationCaledar = async (where: any = '') => {
    // Get the holiday request of the current user
    const VactionUserCaledarRespone = await regVacationCaledar(where, 'GET')
    SetCalendarVationData(VactionUserCaledarRespone.data.data)
    if (VactionUserCaledarRespone.data.code === 200) {
      const TestData = VactionUserCaledarRespone.data.data.vacation as EventData[]
      // Start and END fields in the formatting data are strings in ISO 8601 format
      const formattedData = TestData?.map(({ start, end, ...item }) => ({
        start: new Date(Date.parse(start)),
        end: new Date(Date.parse(end)),
        ...item
      }))
      setVactionCaledarSelect(formattedData)
    }
  }

  useEffect(() => {
    getVationRecord()
  }, [pagination])

  // Page initialization function
  useEffect(() => {
    const currentDate = new Date()
    // 获取当前月份（注意：月份是从 0 开始计数的，所以要加上 1）
    const currentMonth = currentDate.getMonth() + 1
    getVacationCaledar({ month: currentMonth })
  }, [])

  // 获取用户一个人的假期
  const requestVacation = async (where: any) => {
    try {
      setItemLoading(true)
      const surplusVactionRespone = await regGetSurplusVacation(where, 'GET')
      setItemLoading(false)
      if (surplusVactionRespone.data.code == 200) {
        setUserHoliday(surplusVactionRespone.data.data)
        setInspectorInfo({
          name: surplusVactionRespone.data.data.username,
          email: surplusVactionRespone.data.data.email,
          avatar: surplusVactionRespone.data.data.avatar
        })
        SetUserAllApprover(surplusVactionRespone.data.data.approver as [])
      } else {
        HintInfo(surplusVactionRespone.data)
        setUserHoliday(undefined)
      }
    } catch (error) {
      // Log any errors that occur during the request
      console.error('Error fetching user registrations:', error)
    } finally {
    }
  }

  // 设置用户信息,并刷新该用户的假期
  const setUserValueFunction = (row: any | null) => {
    setCurrentUserid(row[0]?.value)
    // setInspectorInfo({
    //   name: row[0]?.label,
    //   email: row[0]?.email,
    //   avatar: row[0]?.avatar,
    // });
  }

  useEffect(() => {
    requestVacation({ user_id: currentUserid, year: year })
  }, [currentUserid])

  useEffect(() => {
    requestVacation({ user_id: currentUserid, year: year })
    SetUserVationData([])
    setPagination(prevPagination => ({
      ...prevPagination,
      page: 1,
      year: year
    }))
  }, [year])

  useEffect(() => {
    if (value) {
      SetUserVationData([])
      setPagination(prevPagination => ({
        ...prevPagination,
        page: 1,
        query: {
          status_id: value
        }
      }))
    } else {
      if (pagination.query && pagination.query.status_id) {
        delete pagination.query.status_id
      }
    }
  }, [value])

  const EditVationCallback = () => {
    vacationDetailedClose()
  }

  const callBackUser = (value: Vacation) => {
    setCurrentUserid(value.user_id)
  }

  const refreshCurrentUserVacationList = () => {
    setPagination(prevPagination => ({
      ...prevPagination,
      page: prevPagination.page + 1
    }))
    // requestVacation(currentUserid.toString());
  }

  const callBackCalendar = async (value: any = '', current_month: string = '') => {
    //  requestVacation(data)
    getVacationCaledar({ month: current_month, year: value })
    //  getVationRecord(data)
    setYear(value)
    setRand(rand + 1)
  }

  // 获取用户一个人的假期
  const refreshCurrentUserVacationItem = async (value: string) => {
    try {
      setLoading(true)
      const surplusVactionRespone = await regVacationDateil(value, 'GET')
      const item = surplusVactionRespone.data
      setLoading(false)
      // 找到customerData 中的id_customer 与返回的id_customer相同的数据 ，然后进行替换
      let index = UserVationData.findIndex(item => item.id_leave == value)
      if (index !== -1) {
        UserVationData[index] = item.data
        SetUserVationData([...UserVationData])
      }

      if (userHoliday && userHoliday.request_list) {
        // 找到 request_list 中 id_leave 与 value 相匹配的数据索引
        let index = userHoliday.request_list.findIndex(item => item.id_leave === value)
        if (index !== -1) {
          // 创建一个新的用户假期对象，其中 request_list 已更新
          const newUserHoliday = {
            ...userHoliday,
            request_list: [
              ...userHoliday.request_list.slice(0, index), // 前面的项不变
              item.data, // 替换更新的项
              ...userHoliday.request_list.slice(index + 1) // 后面的项不变
            ]
          }
          // 更新用户假期状态
          setUserHoliday(newUserHoliday)
        }
      }
    } catch (error) {
      // Log any errors that occur during the request
      console.error('Error fetching user registrations:', error)
    } finally {
      // Set loading state to false regardless of success or failure
      setLoading(false)
    }
  }

  return (
    <Paper px={20} pb={10} style={{ position: 'relative' }}>
      <PageTitle title="LEAVE MANAGEMENT" lang_id="LEAVE MANAGEMENT" />

      <Grid gutter={20} columns={24} mt={10}>
        <Grid.Col span={13}>
          <TableTextInputTitle class_name="leave-title" title={word_translation.current_requests_list} />
          <Box className="border-custom  leave-border" mb={20}>
            <LeaveRows
              statusSwich={toggle}
              callBackUser={callBackUser}
              callBack={refreshCurrentUserVacationList}
              refreshItem={refreshCurrentUserVacationItem}
              UserVationData={UserVationData}
              type={2}
            />
          </Box>

          <TableTextInputTitle class_name="leave-title" title={word_translation.employee_vacation_information} />
          <Box className="border-custom  leave-border">
            <Group position="apart" py={10} px={15}>
              <Box>
                <TableTextInputTitle title={word_translation.choose_a_employee} />
                <Box>
                  <SelectUserAllInfo
                    callBack={setUserValueFunction}
                    btn_size="xs"
                    currentUserid={currentUserid}
                    rands={rand}
                  />
                </Box>
              </Box>
              <Box className="">
                <Group>
                  <Box>
                    <Text className="leave-name">{InspectorInfo.name}</Text>
                    <Text className="leave-role">{InspectorInfo.email} </Text>
                  </Box>
                  <Box>
                    <Avatar radius="xl" src={apiUrl.fileSystemDomain + InspectorInfo.avatar} alt={InspectorInfo.name} />
                  </Box>
                </Group>
              </Box>
            </Group>
            <Divider color="#E7E7E7" />
            <Box mt={15}>
              <Group position="apart" px={40}>
                <Box>
                  {/* <Code block>
                  {JSON.stringify(leave_module_config,null,2)}
                </Code> */}
                  {leave_module_config?.display_vacation_entitlement == '1' && (
                    <Group>
                      <Box w={150} className="text-right">
                        <TableTextInputTitle title={word_translation.vacation_entitlement} />
                      </Box>
                      <Box className="">
                        <Box miw={100} className="btn">
                          <Text className="leave-title-btn">
                            {userHoliday
                              ? userHoliday.remaining_days + ' ' + word_translation.day_left
                              : word_translation.no_data_v2}
                          </Text>
                        </Box>
                      </Box>
                    </Group>
                  )}
                  {leave_module_config?.display_sick_days == '1' && (
                    <Group>
                      <Box w={150} className="text-right">
                        <TableTextInputTitle title={word_translation.sick_days} />
                      </Box>
                      <Box className="">
                        <Box miw={100} className="btn">
                          <Text className="leave-title-btn">
                            {userHoliday
                              ? userHoliday.sick_days + ' ' + word_translation.days
                              : word_translation.no_data_v2}
                          </Text>
                        </Box>
                      </Box>
                    </Group>
                  )}

                  {leave_module_config?.display_special_days == '1' && (
                    <Group>
                      <Box w={150}>
                        <TableTextInputTitle title={word_translation.special_days} />
                      </Box>
                      <Box className="">
                        <Box miw={100} className="btn">
                          <Text className="leave-title-btn">
                            {userHoliday
                              ? userHoliday.special_days + ' ' + word_translation.days
                              : word_translation.no_data_v2}
                          </Text>
                        </Box>
                      </Box>
                    </Group>
                  )}

                  {leave_module_config?.display_latest_request == '1' && (
                    <Group>
                      <Box w={150}>
                        <TableTextInputTitle title={word_translation.latest_request} />
                      </Box>
                      <Box className="">
                        <Box miw={100} className="btn">
                          <Text className="leave-title-btn">
                            {userHoliday
                              ? userHoliday?.vacation_taken_actually_year + ' ' + word_translation.day_ago
                              : word_translation.no_data_v2}
                          </Text>
                        </Box>
                      </Box>
                    </Group>
                  )}
                </Box>
                <Box>
                  <Box>
                    <Box className="">
                      <Group>
                        <Box>
                          <Flex justify="flex-start" align="flex-start">
                            <Box pr={10}>
                              <Tooltip
                                position="top"
                                label={userAllApprover?.map((item, index) => (
                                  <Box>
                                    <Text className="">{item.username}</Text>
                                    <Text className="" fz={11} c="blue">
                                      {item.email}{' '}
                                    </Text>
                                  </Box>
                                ))}
                                withArrow
                              >
                                <Text className="leave-name " fz={14} mb={2}>
                                  {word_translation.approver}:
                                </Text>
                              </Tooltip>
                            </Box>

                            <Box pt={10}>
                              {userAllApprover?.map((item, index) => (
                                <>
                                  {index == 0 && (
                                    <Group>
                                      <Box>
                                        <Text className="leave-name">{item.username}</Text>
                                        <Text className="leave-role">{item.email} </Text>
                                      </Box>
                                      {/* <Box>
                                    <Avatar radius="xl" src={apiUrl.fileSystemDomain + item.avatar} alt={item.name} />
                                  </Box> */}
                                    </Group>
                                  )}
                                </>
                              ))}
                            </Box>
                          </Flex>
                          {/* <TableTextInputTitle class_name="leave-title" title={word_translation.approver} /> */}
                        </Box>
                        <Box>
                          <Group>
                            <Box>
                              <Tooltip.Group>
                                <Avatar.Group spacing="sm">
                                  {userAllApprover?.map((item, index) => (
                                    <>
                                      <Tooltip position="bottom" label={item.username} withArrow>
                                        <Avatar src={apiUrl.fileSystemDomain + item.avatar} radius="xl" />
                                      </Tooltip>
                                    </>
                                  ))}
                                </Avatar.Group>
                              </Tooltip.Group>
                            </Box>
                          </Group>
                        </Box>
                      </Group>
                    </Box>
                  </Box>
                </Box>
              </Group>
            </Box>
            <Box mt={20} mx={10} className="" pos="relative">
              <LoadingOverlay visible={item_loading} overlayBlur={2} />
              <LeaveRowsManage
                UserVationData={userHoliday?.request_list}
                currentUserid={currentUserid}
                callBack={callBackCalendar}
                type={2}
              />
            </Box>
          </Box>
        </Grid.Col>

        <Grid.Col span={11}>
          <TableTextInputTitle class_name="leave-title" title={word_translation.calendar} />
          <Box px={20} py={20} className="leave-border">
            <Group>
              <Radio value="team" label={<TableTextInputTitle title={word_translation.team_calendar} />} />
              <Radio
                disabled
                value="company"
                label={<TableTextInputTitle title={word_translation.company_calendar} />}
              />
            </Group>
            <TeamCalendar callCommontBack={handleEventClick} events={events} callBack={callBackCalendar} />
          </Box>
        </Grid.Col>
      </Grid>

      <Modal opened={vacationDetailedStatue} onClose={vacationDetailedClose} title="Modify the request">
        <LeaveVactionCommnet row={vactionComment} id_leave={currentVactionId} callback={EditVationCallback} />
        {/* Modal content */}
      </Modal>
    </Paper>
  )
}
