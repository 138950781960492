
import {formatTimeHHSS, getWeekDates } from "../../../../../utils/function";
import WordTranslationComponent from "../../../../../utils/language_pack/words";
import { useState } from "react";
import { CustomerInterface, ScheduleInterface } from "../../../../../interface/Itimetrack";
import SheetTableBodyTr from "./table_body_tr";
import PreSheetTd from "./pre_sheet_td";


// Define the props interface for the Component
interface ChildComponentProps {
    timeTrackerList: CustomerInterface[];
    publicDay: string[];
    vactionDay: string[];
    halfDay: string[];
    sickDay: string[];
    totalTImeSummary:ScheduleInterface |undefined;
    halPublicDay: string[];
    weeks: number;
    currentDate: Date;
    existMarkid:any
}


// Component representing the SheetTableThead
const SheetTableBody: React.FC<ChildComponentProps> = ({weeks, existMarkid,timeTrackerList,totalTImeSummary, publicDay,sickDay, vactionDay, halfDay, halPublicDay, currentDate }) => {
    // Get translation public configuration information
    const word_translation = WordTranslationComponent();

    const [mark, setmark] = useState<string>("No content");

    const weekDayInfo = getWeekDates(currentDate);

    const [isHovered, setIsHovered] = useState<string>('mo');
    const [hovered, setHovered] = useState<boolean>(false);



    return (

        <tbody>
            {timeTrackerList.map((item, index) => {
                return (
                <>
              
                    {item.time_tracker_list.map((_i, _index) => {
                        return (
                            <SheetTableBodyTr weeks={weeks} existMarkid={existMarkid} timeTracker={_i} currentDate={currentDate as Date} sickDay={sickDay} publicDay={publicDay} vactionDay={vactionDay} halfDay={halfDay} halPublicDay={halPublicDay}/>
                                )
                    })}</>
                )  
            })}
             <tr className="sheet_tr c_9BC6FF">
               
                    <PreSheetTd  title={word_translation.total_time}   is_icon={false}   />
                     <PreSheetTd   title={'ㅤㅤㅤ'}  is_icon={false}   />
                     <PreSheetTd   title={'ㅤㅤㅤ'}  is_icon={false}   />
                <td className=" b_color sheet_td">{formatTimeHHSS(totalTImeSummary?.total_time as string)}</td>
                <td  className=" b_color sheet_td">{formatTimeHHSS(totalTImeSummary?.mon as string)}</td>
                <td  className=" b_color sheet_td">{formatTimeHHSS(totalTImeSummary?.tues as string)}</td>
                <td className=" b_color sheet_td">{formatTimeHHSS(totalTImeSummary?.weds as string)}</td>
                <td className=" b_color sheet_td">{formatTimeHHSS(totalTImeSummary?.thurs as string)}</td>
                <td className=" b_color sheet_td">{formatTimeHHSS(totalTImeSummary?.fri as string)}</td>
                <td className=" b_color  sheet_td">{formatTimeHHSS(totalTImeSummary?.sat as string)}</td>
                <td className=" b_color  sheet_td">{formatTimeHHSS(totalTImeSummary?.sun as string)}</td>
                <td></td>
             </tr>
        </tbody>
    );
};

export default SheetTableBody;
