import { ActionIcon, Avatar, Box, Group, LoadingOverlay, ScrollArea, Table, Tooltip, Text } from "@mantine/core";
import { reAttribute, reGetEmployeeCustomAttributeList } from "../../../api";
import { Key, useEffect, useState } from "react";
import { Attribute } from "../../../interface/Iuser";
import cx from 'clsx';
import classes from './custom_attrbutes/TableScrollArea.module.css';
import { IconEdit, IconTrash } from "@tabler/icons-react";
import WordTranslationComponent from "../../../utils/language_pack/words";
import { modals } from "@mantine/modals";
import GlobalCoreSetting from "../../../utils/globalCortSetting";
import { HintInfo } from "../../../utils/function";

interface ComponentInterface{
 is_refresh:number,
 callBackfunction:(value:Attribute)=>void,
}
export default function CustomAttributeTable({is_refresh,callBackfunction}:ComponentInterface){
    
  const [loading, setLoading] = useState(false);
  // Get translation public configuration information
const word_translation = WordTranslationComponent();
 // Get the global configuration
 const globalCoreSetting = GlobalCoreSetting();
  const [attributeList, setAttributeList] = useState<Attribute[]>([]);
  const ajaxCurrentList = async () => {
    setLoading(true); // Set the loading status to TRUE, indicating that the data is loading
    // Send GET requests to get supplier data
    const responseAttributeList = await reGetEmployeeCustomAttributeList({},"GET");
    setLoading(false); // After the data is loaded, set the loading status to false
    if (responseAttributeList.data.code === 200) {
        setAttributeList(responseAttributeList.data.data); // 如果响应状态码为200，将供应商列表更新为响应数据中的数据
    }
  };
  // Use the USEEFFECT hook. When Search changes, call the AjaxCurrenTList function
  useEffect(() => {
    ajaxCurrentList();
  }, [is_refresh]);

  
    const handleEdit =  (row:Attribute) => {
        callBackfunction(row)
    };
    // Delete user Attribute
    const handleDel =  (row: Attribute) => {
        modals.openConfirmModal({
            title: word_translation.delete_title,
            centered: true,
            children: (
            <Text size="sm" mt={12}>
                <p>  {word_translation.attribute_key} : {row.attribute_key}</p> 
                <p>  {word_translation.attribute_type_notation} : {row.attribute_type_notation}</p> 
            </Text>
            ),
            labels:globalCoreSetting.DeleteOpenConfirmModaLabels,
            confirmProps: globalCoreSetting.DeleteOpenConfirmModaConfirmProps,
            onConfirm: async() => {
                const attributeTypeResult = await reAttribute({ id: row.id_attribute }, "DELETE");
                HintInfo(attributeTypeResult.data);
                if (attributeTypeResult.data.code == 200) {
                ajaxCurrentList();
                }
            },
        });
        };
        
    return (
        <Box className="px-15 pt-xs pt-4">
        <LoadingOverlay visible={loading} overlayBlur={2} />
        <ScrollArea h={300} >
        <Table>
            <thead  className="scrolleder ">
                <tr>
                <th  id="table-th-title-new">{word_translation.attribute_key}</th>
                <th  id="table-th-title-new">{word_translation.attribute_type_notation}</th>
                <th  id="table-th-title-new">{word_translation.creator}</th>
                <th  id="table-th-title-new">{word_translation.created_at}</th>
                <th  id="table-th-title-new">{word_translation.option}</th>
                </tr>
            </thead>
            <tbody>
            {attributeList.length > 0 && attributeList.map((item: Attribute, index: Key | null | undefined) => {
                return (
                    <tr className="text-center"  key={index}>
                        <td>{item.attribute_key}</td>
                        <td>{item.attribute_type_notation}</td>
                        <td className="img-center">
                                    <Tooltip label={item.creator_first_name +" "+  item.creator_last_name}>
                                        <Avatar
                                        src={item.creator_avatar}
                                        radius={30}
                                        size={30}
                                        />
                                </Tooltip>
                        </td>
                        <td>{item.created_at}</td>
                        <td>
                        <Group spacing={0} position="center">
                            <ActionIcon onClick={() => handleEdit(item)}>
                                <Tooltip label={word_translation.edit_tips}>
                                    <IconEdit size="1.2rem" stroke={1.5} />
                                </Tooltip>
                            </ActionIcon> 
                            <ActionIcon onClick={() => handleDel(item)}>
                                <Tooltip label={word_translation.delete_tips}>
                                    <IconTrash size="1.2rem" color="red" stroke={1.5} />
                                </Tooltip>
                            </ActionIcon> 
                        
    


        </Group>
                        </td>
                    </tr>
                );
            })}


            </tbody>
            </Table>
            </ScrollArea>
        </Box>
    )
}