

  // Importing necessary functions and components from Redux toolkit
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Importing icons from Tabler Icons library
import {
  IconBrandAsana,
  IconClipboardList,
  IconHourglass,
  IconDoorExit,
  IconLayoutDashboard,
  IconZeppelin,
  IconListCheck,
  IconAdjustmentsHorizontal,
  IconTruck,
  IconUserCircle,
} from '@tabler/icons-react';

// Interface representing an individual menu item
interface MenuItem {
  label: string;                           // Label for the menu item
  icon:any;      // Icon component for the menu item
  link: string;    
  status?:boolean;                       // Link associated with the menu item
  links?: { status?:boolean; label: string; link: string }[];  // Optional nested links for submenus
}

// Interface representing the state of the menu
export interface MenuState {
  menuItems: MenuItem[];  // Array containing menu items
}

// Initial state for the menu
const initialState: MenuState = {
  menuItems: [
    { label: 'dashboard', icon: 'IconLayoutDashboard', link: '/dashboard' },
    {
      label: 'TimeTracker',
      icon: 'IconHourglass',
      link: '/timeTracker',
      links: [
        { label: 'TimeTracker Overview', link: '/timeTracker' },
      ],
    },
    {
      label: 'assignments',
      icon: 'IconListCheck',
      link: '/assignment_overview',
      links: [
        { label: 'Overview', link: '/assignment_overview' },
        // { label: 'project_overview', link: '/project' },
        { label: 'create_assignments', link: '/create_assignments' },
        // { label: 'create_order', link: '/create_order' },
        { label: 'employee_role', link: '/permission_role' },
        // { label: 'permission_identifier', link: '/permission_identifier' },
        // { label: 'permission_action', link: '/permission_action' },
        { label: 'task_category', link: '/task_category' },
        // { label: 'task_only', link: '/task_only' },
      ],
    },
    {
      label: 'customer',
      icon: 'IconUserCircle',
      link: '/customer',
      links: [
        { label: 'Overview', link: '/customer' },
        { label: 'Contact Role', link: '/contact_role' },
        // { label: 'Customer Category', link: '/customer_category' },
        { label: 'Create Customer', link: '/create_customer' },
      ],
    },
    { label: 'Team', icon: 'IconBrandAsana', link: '/user', links: [
      { label: 'Overview', link: '/user' },
      { label: 'User Role', link: '/user_role' },
      { label: 'Change Log', link: '/user_change_log' },
    ] },
    {
      label: 'Profile',
      icon: IconUserCircle,
      link: '/profile',
      links: [{ label: 'Overview', link: '/profile' }],
    },
    {
      label: 'Reports',
      icon: 'IconAdjustmentsHorizontal',
      link: '/employee_overtime',
      links: [ 
        { label: 'Employee overtime', link: '/employee_overtime' },
        { label: 'Overtime Reports', status:false, link: '/overtime_report' },
        { label: 'Team Report ', link: '/employee_record' },
        { label: 'Employee Time ', link: '/fejp_record' },
        { label: 'Employee NoTime ', link: '/employee_notime_record' },
        { label: 'Student Report ', link: '/student_record' },
        // { label: 'Reports Filter', link: '/reports_filter' },
        // { label: 'Creatr Reports', link: '/report_customer' },
        // { label: 'All Project Reports', link: '/reports' },
        // { label: 'Employee Performance Reports', link: '/employee_reports' },
        // { label: 'Employee Reports', link: '/user_statistic' },
        // { label: 'Filter Statistics', link: '/filter_statistic' },
      ],
    },
    {
      label: 'On/Off Boarding',
      icon: 'IconZeppelin',
      status:false,
      link: '#',
      links: [
        { label: 'On-Boarding', link: '/on_boarding' },
        { label: 'Off-Boarding', link: '/off_boarding' },
        { label: 'New-Boarding', link: '/new_boarding' },
        { label: 'Custom Attributes', link: '/custom_attributes' },
        { label: 'Boarding Archive', link: '/boarding_archive' },
      ],
    },
    {
      label: 'Leave Request',
      status:false,
      icon: 'IconDoorExit',
      link: '/leave',
      links: [
        { label: 'Leave Request', link: '/leave' },
        { label: 'Leave Management', link: '/leave_manger' },
        { label: 'Sick Leave', link: '/sick_leave' },
      ],
    },
    {
      label: 'Inventory',
      icon: 'IconTruck',
      status:false,
      link: '/product_requirments',
      links: [
        { label: 'Product Requirments', link: '/product_requirments' },
        { label: 'Product Purchase', link: '/product_procurement' },
        { label: 'Storage', link: '/storage' },
        { label: 'Outbound', link: '/delivered_product' },
        { label: 'Product List', link: '/product' },
        { label: 'Supplier', link: '/supplier' },
        { label: 'Recycling Company', link: '/recycling_company_index' },
        { label: 'Return Reason', link: '/return_reason' },
      ],
    },
  ],
};

// Redux slice for managing the menu state
export const MenuSlice = createSlice({
  name: 'menu',  // Name of the slice
  initialState,  // Initial state
  reducers: {
    // Action to set the menu
    setMenu: (state, action: PayloadAction<string>) => {
      // Handle logic related to setting the menu
      // You may update the state based on the action payload
      // For example: state.menuItems = someUpdatedMenuItems;
    },
    setMenuFromApi: (state, action: PayloadAction<MenuItem[]>) => {
       state.menuItems = action.payload;
    },
  },
});

// Exporting action creators and reducer from the slice
export const { setMenu,setMenuFromApi } = MenuSlice.actions;
export default MenuSlice.reducer;
