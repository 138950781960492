import { ActionIcon, Avatar, Box, Group, LoadingOverlay, ScrollArea, Table, Tooltip, Text, Checkbox } from "@mantine/core";
import { reAttributeType, reGetEmployeeCustomAttributeList, reGetEmployeeCustomAttributetTypesList } from "../../../../api";
import { Key, useEffect, useState } from "react";
import { Attribute, AttributeType } from "../../../../interface/Iuser";
import cx from 'clsx';
import classes from './custom_attrbutes/TableScrollArea.module.css';
import { IconDots, IconEdit, IconGripVertical, IconTrash } from "@tabler/icons-react";
import WordTranslationComponent from "../../../../utils/language_pack/words";
import { modals } from "@mantine/modals";
import { HintInfo } from "../../../../utils/function";
import GlobalCoreSetting from "../../../../utils/globalCortSetting";
import { IconDotsVertical } from "@tabler/icons-react";
import { UserTaskInteface } from "../../../../interface/Itask";
import TableTextInputTitle from "../../../../ttcomponents/TableTextInputTitle/TableTextInputTitle";
import { AvatarGroup } from "@mantine/core/lib/Avatar/AvatarGroup/AvatarGroup";
import AvatarGroupComponent from "../../../../ttcomponents/AvatarGroup/AvatarGroup";
import { OrderDataInterface } from "../../../../interface/Iorder";
import DeleteIcon from "../../../../ttcomponents/DeleteIcon/DeleteIcon";

interface ComponentInterface{
 row:OrderDataInterface;
 userTaskList?:OrderDataInterface[];  
 callBackfunction:(value:string,is_checked:boolean)=>void,
 index:number,
}
export default function OrderListTr({index,row,callBackfunction }:ComponentInterface){
    
    // Get translation public configuration information
  const word_translation = WordTranslationComponent();
  // Get the global configuration
  const globalCoreSetting = GlobalCoreSetting();
  
  const [isHovered, setIsHovered] = useState(false);

  
    const handleEdit =  (row:OrderDataInterface) => {
        // callBackfunction&&callBackfunction(row)
    };

   
    // Delete user role
    const handleDel =  (row: OrderDataInterface) => {
        modals.openConfirmModal({
          title: word_translation.delete_title,
          centered: true,
          children: (
            <Text size="sm" mt={12}>
                <p>  {word_translation.task_category} : {row.order_no}</p> 
            </Text>
          ),
          labels:globalCoreSetting.DeleteOpenConfirmModaLabels,
          confirmProps: globalCoreSetting.DeleteOpenConfirmModaConfirmProps,
          onConfirm: async() => {
              const attributeTypeResult = await reAttributeType({ id: row.id_order }, "DELETE");
              HintInfo(attributeTypeResult.data);
              if (attributeTypeResult.data.code == 200) {
               
              }
          },
        });
      };
    
    
      function openSelectColorModalFunction(): void {
       
     }

         /**
     * 当鼠标移入元素时，设置isHovered为true
     */
    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    /**
     * 当鼠标移出元素时，设置isHovered为false
     */
    const handleMouseLeave = () => {
        setIsHovered(false);
    };

 //Click the checkbox whether to associate order information <FUNCTION>
const checkResultFunction  =(value:boolean)=>{
    callBackfunction(row.id_order as string,value)
}
    return (
        <tr className="text-center table_tr_xs assignment_relative "  key={index}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        >
                <td>{row.order_name}</td>
                <td>{row.order_no}</td>
                <td className="img-center">
                    <Box className="img-center"> 
                        <AvatarGroupComponent UserInfo={row.user_info}/>
                    </Box>
                </td>
                <td>{row.project_no}</td>
                <td>
                <Group spacing={0} position="center">
                    <ActionIcon onClick={() => handleEdit(row)}>
                        <Tooltip label={word_translation.edit_tips}>
                            <IconDotsVertical size="1rem" stroke={1.5} />
                        </Tooltip>
                    </ActionIcon> 
                    <DeleteIcon callBack={() => handleDel(row)}/>
                </Group>
                </td>
                {isHovered && (
                    <Group  spacing={0} align="center" className="hovered-task-list-checkbox" position="center">
                        <ActionIcon onClick={openSelectColorModalFunction}>
                            <Tooltip label={word_translation.edit_tips}>
                            <Checkbox
                               size="xs"
                               onChange={(event) =>checkResultFunction(event.currentTarget.checked) } />
                            </Tooltip>
                            {/* ... other icons ... */}
                        </ActionIcon>
                    </Group>
                )}
                    </tr>
               
    )
}