import { Box, Pagination } from "@mantine/core";



interface PaginationAProps {
    callBack: (value: number) => void;
    total_count: number;
    page: number;
    pagesize?: number;
}
export default function PaginationA({ total_count, page, pagesize = 1, callBack }: PaginationAProps) {

    const searchHandle = (value: number | string) => {
        callBack(value as number)
    }
    const PagehandleClickChange = (control: number | string) => {
      
        if (control === 'next') {
            callBack(page + 1 as number)
        }
        if (control === 'previous') {
            if (page > 1) {
                callBack(page - 1 as number)
            }
        }
    }

    return (
        <>
        {total_count > 10 &&  
          <Box className="windows_bottom">
           <Pagination
                 my="sm" 
                position="center"
                total={Math.ceil(
                    (total_count as number) / pagesize
                )}
                getItemProps={(page) => ({ onClick: () => searchHandle(page) })}
                getControlProps={(control) => ({ onClick: () => PagehandleClickChange(control) })}
                value={page}
                siblings={4}
                styles={(theme) => ({
                    control: {
                        '&[data-disabled]': {
                            display: "none"
                        },
                    },
                })}
                defaultValue={1} /></Box>}
            {/* <Divider my="sm" /> */}
         
        </>
    )
}