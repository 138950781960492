import { configureStore } from "@reduxjs/toolkit"
import SearchValue from '../features/search/searchSlice'
import menu from '../features/menu/menuSlice'
import expandStatus from "../features/assignments_expand_status/assignmentsExpandStatusSlice";
import refreshStatus from "../features/assignments_refresh_status/assignmentsRefreshStatusSlice";
import ExecutingLockStatus from "../features/executing_lock_status/executing_lock_statusSlice";
import ActionObj from "../features/action_index/actionIndexSlice";
import productData from "../features/product_list/productListSlice";

export const store = configureStore({
    reducer:{
        search: SearchValue,
        expandStatus: expandStatus,
        menu: menu,
        refreshStatus:refreshStatus,
        ExecutingLockStatus:ExecutingLockStatus,
        ActionObj:ActionObj,
        productData:productData,
    }
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch