import { Select } from "@mantine/core";
import { useEffect, useState } from "react";
import { SelectRole } from "../../interface/Icommon";
import { regGetReturnProductReason, regUseManager, regUserAll } from "../../api";
import { UserAllList } from "../../interface/Iuser";
import { IReturnProductData } from "../../interface/IreturnProduct";

interface ISelectReturnReasonAllInfo{
    callBack:(row:any)=>void;
    rands:number;
    [key:string]:any
}

export default function SelectReturnReason({callBack,labelStr,currentReturnReasonId,rands}:ISelectReturnReasonAllInfo)
{

  const [rand, setRand] = useState(0);
  const [returnReasonList, setReturnReasonData] = useState<SelectRole[]>([])
  /**
   * Get user information
   */
  const ajaxCurrentList = async () => {
    const response = await regGetReturnProductReason({}, "GET");
    const transferData = response.data.data.data.map((item:IReturnProductData) => {
      return {
        value: item.return_reason_id,
        label: item.return_reason,
      }
    })

    setReturnReasonData(transferData)
  };
  // Page initialization function
  useEffect(() => {
    ajaxCurrentList()
  }, [])
  useEffect(() => {
     setRand(rand+1)
  }, [currentReturnReasonId])
function setReturnReasonFunction(value: string | null): void {
    const row =  returnReasonList.filter((item,index)=>{return item.value == value })
    if(row){
      callBack({
        "return_reason_id": row[0].value,
        "return_reason": row[0].label,
        "type": 'return_reason',
      })
    }else{
      callBack({
        "type": 'return_reason',
        "return_reason_id": '',
        "return_reason": ''
      })
    }
    }
    return(
        <Select label={labelStr}   maxDropdownHeight={200} defaultValue={currentReturnReasonId} key={rand} onChange={setReturnReasonFunction} data={returnReasonList} />
    )        

}