//

import {
  ActionIcon,
  Badge,
  Box,
  Divider,
  Flex,
  Group,
  Menu,
  Text,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import {
  IconCheck,
  IconDots,
  IconMessages,
  IconNote,
  IconPencil,
  IconTrash,
  IconUserPlus,
  IconX,
} from "@tabler/icons-react";
import { notifications } from "@mantine/notifications";
import { FormattedMessage, useIntl } from "react-intl";
import { regCustomerRole, regSupplierContact } from "../../../api";
import { SupplierContact } from "../../../interface/ISupplierContact";
import { HintInfo } from "../../../utils/function";

interface Role {
  id_customer_roles: string | number;
  role_name: string;
  role_desc: string;
}

interface RolesProps {
  SupplierData: SupplierContact[];
  callBack: (value: boolean) => void;
  onValueEdit: (value: object) => void;
  onValueDel: (id: string) => void;
}

export default function SupplierContactInfo({
  SupplierData,
  onValueEdit,
  callBack,
  onValueDel,
}: RolesProps) {
  // 钩子函数-切换语言
  const intl = useIntl();

  // 获取要修改的信息并传递到父级组件当中  Get the information to be modified and pass to the parent -level component
  const editCustomer = (value: object) => {
    const editData = value as Role;
    onValueEdit(editData);
  };

  //   Delete customers
  const delHandle = (row: SupplierContact) => {
    modals.openConfirmModal({
      title: (
        <FormattedMessage
          id="delete_confirm"
          defaultMessage="Delete Confirmation"
        />
      ),
      centered: true,
      zIndex: 301,
      children: (
        <Box>
          <Box>
            <FormattedMessage id="contact_role_name" defaultMessage=" Name" />:{" "}
            {row.contact_name}
          </Box>
        </Box>
      ),
      labels: {
        confirm: (
          <FormattedMessage
            id="confirm_delete"
            defaultMessage="Confirm Delete"
          />
        ),
        cancel: (
          <FormattedMessage id="cancel_delete" defaultMessage="Cancel Delete" />
        ),
      },
      confirmProps: { color: "red" },
      onConfirm: async () => {
        const FormData = {
          supplier_contact_id: row.supplier_contact_id,
        };
        const response = await regSupplierContact(FormData, "DELETE");
        HintInfo(response.data);
        if (response.data.code == 200) callBack(true);
      },
    });
  };

  const rows =
    SupplierData.length > 0 ? (
      SupplierData.map((item, index) => (
        <tr key={index}>
          <td>
            {" "}
            <Text fz="sm">{item.contact_name} </Text>{" "}
          </td>
          <td>
            {" "}
            <Text fz="sm">{item.contact_email} </Text>{" "}
          </td>
          <td>
            {" "}
            <Text fz="sm">{item.contact_phone}</Text>{" "}
          </td>
          <td>
            {" "}
            <Text fz="sm">{item.contact_introduction}</Text>{" "}
          </td>
          <td>
            <Flex>
              <ActionIcon onClick={() => editCustomer(item)}>
                <IconPencil size="1rem" stroke={1.5} />
              </ActionIcon>
              <ActionIcon onClick={() => delHandle(item)}>
                <IconTrash size="1rem" stroke={1.5} color="red" />
              </ActionIcon>
            </Flex>
          </td>
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan={6}>
          {" "}
          <Divider
            my="xs"
            label="Have not added contacts yet"
            labelPosition="center"
          />
        </td>
      </tr>
    );
  return <tbody>{rows}</tbody>;
}
