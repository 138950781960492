import { Alert, Avatar, Badge, Box, Card, Flex, Grid, Group, List, Text } from "@mantine/core";
import {ProjectDataInterface } from "../../../interface/Iprojects";
import {  IconAlertCircle, IconPackage } from "@tabler/icons-react";
import { useIntl } from "react-intl";
import WordTranslationComponent from "../../../utils/language_pack/words";
import apiUrl from '../../../config/api_config/config';

import { getAssignmentStatusColorByValue, getAssignmentStatusLabelByValue } from "../../../utils/function";
import { IComment, Vacation } from "../../../interface/Ivaction";
import InfoItem from "../../assignments/assignments/project/project_info/item";
import { useEffect, useState } from "react";
import { regGetVationComment, regVacationDateil } from "../../../api";
interface ComponentInterface{
  row:  Vacation | undefined
}



export default function LeaveInfo({ row } :ComponentInterface) {
  // Use the useIntl hook to access the intl object
 
  // Define holiday remarks
  const [vactionComment, setVactionComment] = useState<IComment[]>();

  const handleEventClick = async (id: any) => {
    const ApprovalResult = await regGetVationComment({ leave_id: id });
    if (ApprovalResult.data.code === 200) {
      setVactionComment(ApprovalResult.data.data);
    }
  };
  useEffect(()=>{
      row &&  handleEventClick(row.id_leave)
  },[row])
  // Get translation public configuration information
  const word_translation = WordTranslationComponent();
  console.log(row,'****')
  return (
    <Grid mt={10} className="bg-img-light">
      <Grid.Col span={4} p={2} className="bg-blue">
        <Box  className="">
          <Text fz="xl" fw={700} c="#fff">{word_translation.team_leader}</Text>
          <Flex justify="left" align="center" mt={10}>
            <Box mt={10}>
              <IconPackage color="white" />
            </Box>
            <Box mx={10}>
              <Text fz="xs" fw={500} c="#fff">
              {word_translation.team_leader}
              </Text>
              <Text fz="sm" fw={500} c="#fff">
                {row?.manager[0].username}
              </Text>
            </Box>
            <Box mx={10}>
                <Avatar radius={40} w={40} h={40} src={apiUrl.fileSystemDomain +  row?.manager[0].avatar} />
            </Box>
          </Flex>
          <Flex justify="left" align="center" mt={10}>
            <Box mt={10}>
              <IconPackage color="white" />
            </Box>
            <Box mx={10}>
              <Text fz="xs" fw={500} c="#fff">
              {word_translation.email}
              </Text>
              <Text fz="sm" fw={500} c="#fff">
                {row?.manager[0].email}
              </Text>
            </Box>
          </Flex>
          <Flex justify="left" align="center" mt={10}>
            <Box mt={10}>
              <IconPackage color="white" />
            </Box>
            <Box mx={10}>
              <Text fz="xs" fw={500} c="#fff">
                {word_translation.phone}
              </Text>
              <Text fz="sm" fw={500} c="#fff">
                {row?.manager[0].phone}
              </Text>
            </Box>
          </Flex>
          

          <Flex justify="left" align="center" mt={10}>
            <Box mt={10}>
              <IconAlertCircle color="white" />
            </Box>
            <Box mx={10}>
              <Text fz="xs" fw={500} c="#fff">
                {word_translation.approver_remarks}
              </Text>
              <Text fz="sm" fw={500} c="#fff">
               {vactionComment?.map((item,index)=>(
                    <>
                      <div> {item.approver_remarks} </div>
                    </>
                ))}
              </Text>
            </Box>
          </Flex>
        </Box>
      </Grid.Col>
      <Grid.Col span={8} p={1}>
        <Card className="nobg">
          <Text fz="xl" fw={700} c="#000">{row?.leave_category_name}</Text>
          <InfoItem value= {row?.user_info.username} icon={ <IconPackage  size={20}/>} title={word_translation.username}/>
          <InfoItem value= {row?.user_info.email} icon={ <IconPackage  size={20}/>} title={word_translation.email}/>
          <InfoItem value= {row?.user_info.phone} icon={ <IconPackage  size={20}/>} title={word_translation.phone}/>
          <InfoItem value= {row?.from} icon={ <IconPackage  size={20}/>} title={word_translation.from}/>
          <InfoItem value= {row?.to} icon={ <IconPackage  size={20}/>} title={word_translation.to}/>
          <InfoItem value= {row?.leave_category_name} icon={ <IconPackage  size={20}/>} title={word_translation.category_name}/>
          <InfoItem  
          value= {<Badge  color= {getAssignmentStatusColorByValue(row?.status_id as string)}> {row?.status_name as string}</Badge>}  
          title={word_translation.status} 
           icon={ <IconPackage  size={20}/>} />
           <Alert icon={<IconAlertCircle size="1rem" />} title={word_translation.user_remark}>
           <>{row?.user_remarks}</>
          </Alert>
        </Card>
      </Grid.Col>
    </Grid>
  );
}
