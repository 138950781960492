import {
  ActionIcon,
  Box,
  Group,
  Table,
  Text,
  Code,
  LoadingOverlay,
} from "@mantine/core";
import {
  IProjectStatusWithCallback,
  IProjectStatusData,
} from "../../interface/IprojectStatus";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import { IconCheck,  IconX } from "@tabler/icons-react";
import { regProjectStatus } from "../../api";
import { useState } from "react";
import { useIntl } from "react-intl";
import WordTranslationComponent from "../../utils/language_pack/words";
import EditIcon from "../EditIcon/EditIcon";

export default function TableProjectStatus({
  projectStatusData,
  onValueEdit,
  callBack,
}: IProjectStatusWithCallback) {
  // Get translation public configuration information
  const word_translation = WordTranslationComponent();

  // 获取要修改的信息并传递到父级组件当中
  //  Get the information to be modified and pass to the parent -level component
  const editCustomer = (value: object) => {
    const editData = value as IProjectStatusData;
    onValueEdit!(editData);
  };

  // 循环输出数据集合
  const rows = projectStatusData ? (
    projectStatusData.map((item, index) => (
      <tr className="border-bt-2" key={index}>
        <td><Text fz="sm">{item.id_status}</Text></td>
        <td><Text fz="sm">{item.status_name}</Text></td>
        <td><Text fz="sm">{item.status_desc}</Text></td>
        <td>{item.active ? <IconCheck color="green" /> :<IconX color="red" /> }</td>
        <td>
          <Group spacing={0} position="center">
             <EditIcon callBack={() => editCustomer(item)} />
          </Group>
        </td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan={6}>Loading...</td>
    </tr>
  );
  return (
    <>
    <table className="table_custome text-center">
        <thead  className="table_th_xs">
        <tr>
          <th>{word_translation.id}</th>
          <th >{word_translation.status}</th>
          <th>{word_translation.description}</th>
          <th >{word_translation.active}</th>
          <th>{word_translation.option}</th>
          </tr>
        </thead>
      <tbody>{rows}</tbody>
      </table>
    </>
  );
}
