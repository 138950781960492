import { Button,Tooltip } from "@mantine/core";
import { IconCircleCheckFilled, IconCirclePlus, IconTrashX } from "@tabler/icons-react";
import { FormattedMessage } from "react-intl";
import WordTranslationComponent from "../../utils/language_pack/words";

interface ChildComponentProps {
  callBack: () => void;
  disabled?: boolean;
}

const ResetBtn: React.FC<ChildComponentProps> = ({disabled = false, callBack }) => {
  // Click the event to return to the submit of the form
  const handleButtonClick = () => {
    callBack();
  };
  
  const word_translation = WordTranslationComponent();
  return (
    <Button
      type="button"
      color="red"
      disabled={disabled}
      size="xs"
      onClick={handleButtonClick}
      leftIcon={<IconTrashX  width={18} height={18} />}
      styles={{
        leftIcon: { marginRight: "5px" },
      }}
    >
      {word_translation.reset_btn}
    </Button>
   
  );
};

export default ResetBtn;
