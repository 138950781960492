import { ActionIcon,Text, Avatar, Box, Grid, Paper, Image, Progress, Group, Badge, Modal, Tooltip } from "@mantine/core";

import WordTranslationComponent from "../../../../utils/language_pack/words";

import '../css/index.css'
import { Fragment, useEffect, useRef, useState } from "react";
import { useDisclosure } from "@mantine/hooks";
import { AssignmentsInterface, ProjectDataInterface } from "../../../../interface/Iprojects";
import { HintInfo, formatTimeHHSS, getAssignmentStatusColorByValue, getAssignmentStatusLabelByValue } from "../../../../utils/function";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../state_management/store";
import AvatarGroupComponnet from "../../../../ttcomponents/AvatarGroup/AvatarGroup";
import { UserAllList } from "../../../../interface/Iuser";
import { SelectRole } from "../../../../interface/Icommon";
import { getUserGeneralFunction } from "../../../../api/common_request";
import { getCustomerListGeneralFunction, getOrderDetailByIdFunction, getProjectDetailByIdFunction } from "../../../../api/assignments_request";

import { ChildMethods } from "../project/project_create";
import TableTd from "../../../../ttcomponents/TableTrTd/TableTd";
import { OrderDataInterface } from "../../../../interface/Iorder";
import { setRefreshStatus } from "../../../../state_management/features/assignments_refresh_status/assignmentsRefreshStatusSlice";
import { modals } from "@mantine/modals";
import GlobalCoreSetting from "../../../../utils/globalCortSetting";
import { requestOrder } from "../../../../api/index_v2";
import tticon from "../../../../public/images/tt.png";
import { IconArrowBigDownLine, IconArrowBigUpLine } from "@tabler/icons-react";
import React from "react";
import AssignmentTaskRow from "../task/task_item_row";


interface ComponentProps{
    leve:number;
    index?:number;
    row:AssignmentsInterface;
}
export default function AssignmentTaskCategoryRow({leve,index,row}:ComponentProps){
    
  const dispatch = useDispatch();
    // Get translation public configuration information
  const word_translation = WordTranslationComponent();
  const [openSelectColorStatus, { open: openSelectColorModalFunction, close: closeSelectColorModalFunction }] =useDisclosure(false);
  
  // Get the global configuration
  const globalCoreSetting = GlobalCoreSetting();

  const childRef = useRef<ChildMethods>(null);
  //  Define current  project info  in the page
  const [currentProjectInfo,setCurrentProjectInfo] = useState<ProjectDataInterface>();

  const [allUserList, setAllUserList] = useState<UserAllList[]>([]);

  // Define all the customer lists that can be selected
  const [customerList, setCustomerList] = useState<SelectRole[]>([]);
  // Define all the list of employees you can choose
  const [userList, setUserList] = useState<SelectRole[]>([]);

 //  Define current  order info  in the page
 const [currentOrderInfo,setCurrentOrderInfo] = useState<OrderDataInterface>();

  const [openProductStatus, { open: openProductFunction, close: colseProductFunction }] =useDisclosure(false);

  const [openOrderFormStatus, { open: openCurrentPageOrderForm, close: closeOrderPageFormFunction }] = useDisclosure(false);

  const [isExpanded, setIsExpanded] = useState(false);
  // Task Modal
  
  const [openTaskFormStatus, { open: openCurrentPageTaskForm, close: closeTaskPageFormFunction }] = useDisclosure(false);

  //展开单个的记录
  const expandItemFunction =()=>{
    setIsExpanded(!isExpanded)
  }

  // 获取redux store中的值
  const expandStatus = useSelector((state:RootState)=>state.expandStatus.expandStatus)

 // 用redux中的状态值来控制组件的展开状态
  useEffect(()=>{
    setIsExpanded(expandStatus)
  },[expandStatus])

  /**
   * useState Hook用于管理组件的状态
   * @param {boolean} isHovered - 当前鼠标是否悬停在元素上
   * @returns {Array} - 包含isHovered和setIsHovered两个值的数组
   */
  const [isHovered, setIsHovered] = useState(false);


  /**
   * 当鼠标移入元素时，设置isHovered为true
   */
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  /**
   * 当鼠标移出元素时，设置isHovered为false
   */
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

     

 /**
 * Get a list of users
 */
 const getUser = async() =>{
    // Call the Get User Universal Selection function and set the user list
    let userList = await getUserGeneralFunction('all_info')
    setUserList(userList.map((item: { id_user: string; username: any }) => ({
        value: item.id_user as string,
        label: item.username,
    })));
    setAllUserList(userList);
}

const getCustomerList = async () => {
    setCustomerList(await getCustomerListGeneralFunction(""));
};

// get a  product detail by  project_id
const getProjectDeatil = async (project_id:number) =>{
    setCurrentProjectInfo(await getProjectDetailByIdFunction(project_id))
}
   
const getOrderDeatil = async (order_id:number) =>{
        const orderIndo =  await getOrderDetailByIdFunction(order_id)
        //  如果当前订单存在项目ID
        if(orderIndo.project_id){
            // form.setFieldValue('project_id',orderIndo.project_id.toString())
            getProjectDeatil(orderIndo.project_id)
        }

        setCurrentOrderInfo(orderIndo);
}
   

const editHandleFunction = (row:AssignmentsInterface)=>{
  openCurrentPageOrderForm();
  getOrderDeatil(row.order_id as number)
  getUser();
  getCustomerList();
}
const createTaskFunction = (row:AssignmentsInterface)=>{
  openCurrentPageTaskForm();
    getOrderDeatil(row.order_id as number)
    getUser();
    getCustomerList();
}

// Fetches the delete  task
const handleDel = async (row: AssignmentsInterface) => {
  modals.openConfirmModal({
      title: word_translation.delete_title,
      centered: true,
      children: (
          <Text size="sm" mt={12}>
              <p>  {word_translation.order_name} : {row.order_name}</p>
          </Text>
      ),
      labels: globalCoreSetting.DeleteOpenConfirmModaLabels,
      confirmProps: globalCoreSetting.DeleteOpenConfirmModaConfirmProps,
      onConfirm: async () => {
          try {
              const deleteTaskResult = await requestOrder({ id: row.order_id }, "DELETE");
              HintInfo(deleteTaskResult.data);
              if (deleteTaskResult.data.code == 200) {
                  dispatch(setRefreshStatus(true));
              }
          } catch (error) {
              // Handle any errors that occur during the API call
              console.error('Error fetching project list:', error);
              // Optionally, you can throw the error or handle it as needed
              throw error;
          }

      },
  });
};


  // Project callback Function <FUNCTION>
  const orderFormCallbackFunction =(project_id:any)=>{
    if(project_id !== '-1'){
        closeTaskPageFormFunction();
        setIsExpanded(true)
        dispatch(setRefreshStatus(true));
    }
}
  /**
   * 定义行的class
   */
  const  tdClass = (leve == 1 || index == 0 ) ? '' : ''

  return (
    <>
    
    <tr  className={`text-center assignment_laypuout tr-container   ${leve == 1 ? "assignment_project_item" : 'assignment_category_item assignment_order_item'}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
     >
        <td style={{}} className={tdClass} >
            <Group ml={leve==2?20:0}>
            <Image mt={10} width={15} height={15} src={tticon}  />
            <Box >
                <Box className="hide-excess-text assignment_text_title">{word_translation.task_name}</Box>
                <Box className="assignment_text_content text-left hide-excess-text"> {row.task_category_name}</Box>
            </Box>
            </Group>
        </td>
        
        <td style={{width:"5px"}}>
            <Box  p={0} >
                <ActionIcon p={0} onClick={() => expandItemFunction()}>
                    <Tooltip label={word_translation.expand_tips}>
                       {isExpanded ? <IconArrowBigUpLine size="1.2rem"   color='#7D7D7D' stroke={1.5} /> :<IconArrowBigDownLine  size="1.2rem"  color='#7D7D7D' stroke={1.5} /> }
                    </Tooltip>
                </ActionIcon> 
            </Box>
        </td>
        <TableTd class_name={tdClass}   value="&nbsp;" title="&nbsp;"  />  
           
        <TableTd class_name={tdClass}   value="&nbsp;" title="&nbsp;"  />  
        <TableTd class_name={tdClass}   value="&nbsp;" title="&nbsp;"  />  
        <TableTd class_name={tdClass}   value="&nbsp;" title="&nbsp;"  />      
        <TableTd class_name={tdClass}   value="&nbsp;" title="&nbsp;"  />      
        <TableTd  class_name={tdClass}   value={formatTimeHHSS(row.target_time)} title={word_translation.target_time}  /> 
        <TableTd  class_name={tdClass} value={formatTimeHHSS(row.actual_time)} title={word_translation.actual_time}  /> 
        <TableTd  class_name={tdClass}  value={formatTimeHHSS(row.over_time)} title={word_translation.overtime}  /> 
        
        <TableTd
          class_name={tdClass}   
          title={word_translation.completion}  
          value_class="img-center" 
          value={<> <Text mb={2} ><span className="timevisual_blue"> {row.completion}% </span></Text>
                       <Progress
                            value={row.completion}
                            size="md"
                            color="blue" /> </>} />
        <TableTd  class_name={tdClass}  value={row.cost} title={word_translation.cost}  /> 
        <TableTd  class_name={tdClass}  value={row.budget} title={word_translation.budget}  /> 
        <TableTd class_name={tdClass}   value="&nbsp;" title="&nbsp;"  />       
        <TableTd class_name={tdClass}   value={row?.task_list?.length} title={word_translation.tasks}  />
        
        <td className={tdClass}  style={{borderTopRightRadius:0,borderBottomRightRadius:0}}>

        </td>

    </tr>
    

    {isExpanded && row?.task_list?.map((item, index) => {
                  if (item.type == 'task') {
                      return (<React.Fragment key={index}>
                          <AssignmentTaskRow row={item} index={index} leve={2} /></React.Fragment>
                      )
                  }
              })}
  
  
    </>
    )
}

