import { useListState, randomId } from '@mantine/hooks';
import { Box, Checkbox } from '@mantine/core';
import TableTextInputTitle from '../../../../../ttcomponents/TableTextInputTitle/TableTextInputTitle';
import WordTranslationComponent from '../../../../../utils/language_pack/words';
import { useEffect, useState } from 'react';

const initialValues = [
    { label: 'Create',permission_action:'00001',value:'create', checked: false, key: randomId() },
    { label: 'Update', permission_action:'00002',value:'update',checked: false, key: randomId() },
    { label: 'Delete', permission_action:'00004',value:'delete',checked: false, key: randomId() },
    { label: 'Add/Edit/Delete User',permission_action:'00006',value:'projectuser', checked: false, key: randomId() },
];

export interface initialValuesCheckboxInterface {
    label: string;
    value: string;
    checked: boolean;
    key: string;
    id_permission:string;
    permission_action:string;
    [key:string]:any
}

 interface ComponentInterface {
    callBack: (value: any,type:string) => void;
    list: initialValuesCheckboxInterface[];
    identifire: string;
    current_all:boolean;
}



// 循环处理函数
function processListValues(list: initialValuesCheckboxInterface[]): string[] {
    const valuesArray: string[] = [];

    // 循环处理list中的每个元素
    list.forEach((item) => {
        // 将value添加到数组中，如果数组中不存在相同的值
        if (!valuesArray.includes(item.value)) {
            valuesArray.push(item.value.toString());
        }
    });

    return valuesArray;
}

export function PermissionCheckBox({identifire,current_all,list,callBack}:ComponentInterface) {
    
    const [values, handlers] = useListState(initialValues);
    const [value, setValue] = useState<string[]>([]);
    const allChecked = values.every((value) => value.checked);
    
    // Get translation public configuration information
    const word_translation = WordTranslationComponent();
    const [checked, setChecked] = useState(false);
    const indeterminate = values.some((value) => value.checked) && !allChecked;

    useEffect(() => {
        // 使用 setState 方法来设置新的初始值
        handlers.setState(list);
    }, [list]);

    useEffect(() => {
        // 使用 setState 方法来设置新的初始值
        setChecked(current_all)
    }, [current_all]);
    

    useEffect(() => {
       if(checked){
        //  setValue();
         const all_value = processListValues(list as initialValuesCheckboxInterface[] )
         setValue(all_value)
       }else{
        setChecked(checked)
        if(current_all == false){
            setValue([])
        }
       }
    }, [checked]);
    
    useEffect(() => {
        callBack(value,list[0]?.id_permission_identifier)
    }, [value]);

    
    const setValueFunction = (value:string[])=>{
        setChecked(value.length == list.length)
        setValue(value)
    }
    
    const setCheckedFunction = (value:boolean)=>{
        setChecked(value)
        if(value == false){
            setValue([])
        }
    }
    
    const itemsGroup = values.map((value, index) => {
        return(
            <>
           {identifire =='Project'  &&  value.permission_action == '00001' ?  <Checkbox
              ml={33}
              mt={5}
              disabled={true}
              label={ <TableTextInputTitle class_name='assignment_permission_2 assignment_permission_align_center' title={`${value.label}`} />}
          />:
              <Checkbox
              ml={33}
              mt={5}
              value={value.value.toString()}
              label={ <TableTextInputTitle class_name='assignment_permission_2 assignment_permission_align_center' title={`${value.label}`} />}
          />
           }
            </>

     )
    });

    return (
        <>
        <Box ml={15}>
            <TableTextInputTitle title={identifire} />
        </Box>
         <Checkbox  ml={20} checked={checked} label={<TableTextInputTitle class_name='assignment_permission_2 assignment_permission_align_center' title='All' />} onChange={(event) => setCheckedFunction(event.currentTarget.checked)} />
            <Checkbox.Group value={value}  onChange={setValueFunction}>
                <Box ml={10}>
                    {itemsGroup}
                </Box>
            </Checkbox.Group>
  
            {/* <Checkbox
                ml={20}
                checked={allChecked}
                indeterminate={indeterminate}
                label={<TableTextInputTitle class_name='assignment_permission_2' title='All' />}
                transitionDuration={0}
                onChange={() =>
                    handlers.setState((current) =>
                        current.map((value) => ({...value, checked: !allChecked }))
                    )
                }
            />
            {items} */}
        </>
    );
}

export default PermissionCheckBox