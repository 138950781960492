export function MessageSvg(props: React.ComponentPropsWithoutRef<'svg'>) {
  const numMessages = 3;
    return (
      <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_309_360)">
      <path d="M20.4796 7C22.1601 7 23.5222 5.65685 23.5222 4C23.5222 2.34315 22.1601 1 20.4796 1C18.7992 1 17.437 2.34315 17.437 4C17.437 5.65685 18.7992 7 20.4796 7Z" fill="#FC5F05"/>
      <path d="M22.5082 9.4V15.48C22.5082 15.62 22.4981 15.76 22.4879 15.89C22.4778 16.01 22.4676 16.12 22.4473 16.24C22.4372 16.36 22.4169 16.48 22.3966 16.59C22.0417 19.01 20.4798 20.54 18.0356 20.89C17.924 20.91 17.8023 20.93 17.6806 20.94C17.5589 20.96 17.4473 20.97 17.3256 20.98C17.1938 20.99 17.0518 21 16.9098 21H8.8367C8.69471 21 8.55272 20.99 8.42088 20.98C8.29917 20.97 8.18761 20.96 8.0659 20.94C7.9442 20.93 7.82249 20.91 7.71093 20.89C5.26669 20.54 3.70482 19.01 3.34984 16.59C3.32956 16.48 3.30928 16.36 3.29913 16.24C3.27885 16.12 3.26871 16.01 3.25857 15.89C3.24842 15.76 3.23828 15.62 3.23828 15.48V7.52C3.23828 7.38 3.24842 7.24 3.25857 7.11C3.26871 6.99 3.27885 6.88 3.29913 6.76C3.30928 6.64 3.32956 6.52 3.34984 6.41C3.70482 3.99 5.26669 2.46 7.71093 2.11C7.82249 2.09 7.9442 2.07 8.0659 2.06C8.18761 2.04 8.29917 2.03 8.42088 2.02C8.55272 2.01 8.69471 2 8.8367 2H15.0031C15.6522 2 16.1187 2.58 15.997 3.2C15.997 3.22 15.9869 3.24 15.9869 3.26C15.9666 3.36 15.9564 3.46 15.9361 3.57C15.8956 3.99 15.9159 4.44 16.0071 4.9C16.0376 5.02 16.0578 5.12 16.0984 5.23C16.1796 5.56 16.3114 5.87 16.4838 6.16C16.5447 6.28 16.6258 6.4 16.6968 6.51C17.0315 6.99 17.4473 7.4 17.9341 7.73C18.0457 7.8 18.1674 7.88 18.2891 7.94C18.5832 8.11 18.8976 8.24 19.2323 8.32C19.3439 8.36 19.4453 8.38 19.567 8.41C20.0335 8.5 20.4899 8.52 20.9159 8.48C21.0275 8.46 21.1289 8.45 21.2303 8.43C21.2506 8.43 21.2709 8.42 21.2912 8.42C21.92 8.3 22.5082 8.76 22.5082 9.4Z" fill="#818181"/>
      </g>
      <defs>
      <filter id="filter0_d_309_360" x="0" y="0" width="27" height="26" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="softAlpha"/>
      <feOffset dy="1"/>
      <feGaussianBlur stdDeviation="2.5"/>
      <feComposite in2="hardAlpha" operator="over"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_309_360"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_309_360" result="shape"/>
      </filter>
      </defs>
      <text x="13" y="18" fill="white" fontSize="12" textAnchor="middle" fontFamily="Inter" fontWeight="700">
        {numMessages}
      </text>
      </svg>
    );
  }