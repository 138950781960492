import { Text, Box, ScrollArea, Table, Divider } from "@mantine/core";
import { filterReportData } from "../../../interface/IfilterStatistic";
import { ScikTimeReport } from "../../../interface/IReport";

interface IReportTemplateOne{
    Data:ScikTimeReport | undefined
}
export default function ReportTemplateFive({ Data }:IReportTemplateOne) {
    return (
        <ScrollArea mt={20}>
            <Table>
   
                    <thead >
                        <tr key={"index"}>
                            <th>
                                <Text fw={500} size={15} color="dark" > User/Week</Text>
                            </th>
                            {Data?.week.map((item, index) => {
                                return (
                                    <th key={index}>
                                      <Text fw={500} size={15} color="dark" >    KW {item}</Text>
                                    </th>
                                )
                            })}
                            <th >
                            </th>
                        </tr>
                    </thead>
                <tbody>


                {Data?.record_data.map((item, index) => {
       return(
          
                <tr key={index} >
                    <td > <Text pt={5} size={15} color="dark" >{item.username}</Text>  </td>
                     {item.list.map((timeItem, index) => {
                            return (   <td>  <Text c="#637381" className={timeItem.time === '00:00:00' ? 'figma-font-nomal' : 'fontBold' } key={index} pt={5}   > {timeItem.time}</Text> </td>  )
                        })}
                </tr>
            )})}



                 
                </tbody>      
            </Table>
          
        </ScrollArea>
    )
}