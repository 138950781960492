import {
  Card,
  Table,
} from "@mantine/core";
import { MonthlyTotal } from "../../../interface/Ireports";

import { ConvertTimeStringToDecimal, formatTimeHHSS } from "../../../utils/function";
import WordTranslationComponent from "../../../utils/language_pack/words";

// Define interface for component props
interface ComponentInterface {
  monthlyTotal: MonthlyTotal[] | undefined; // Array of MonthlyTotal objects or undefined
}

// MonthlyStatistics component definition
export default function MonthlyStatistics({monthlyTotal}: ComponentInterface) {
  // Get translation public configuration information
  const word_translation = WordTranslationComponent();

  // Render MonthlyStatistics component
  return (
    <Card mt={20} bg='#eee'>
      <Table highlightOnHover withBorder withColumnBorders>
        <thead>
          {/* Table header is empty */}
        </thead>
        <tbody>
          {monthlyTotal?.map((item, index) => {
            return (
              // Each iteration represents a year
              <>
                {/* Year row */}
                <tr className="background_report_title">
                  <td colSpan={13}><b>{item.year}</b></td>
                </tr>
                {/* Month abbreviation row */}
                <tr>
                  {item.list.map((item1, index1) => {
                    return (
                      <td>{item1.month_short}</td> // Display month abbreviation
                    )
                  })}
                </tr>
                {/* Time spent row */}
                <tr>
                  {item.list.map((item1, index1) => {
                    return (
                      <td>
                        {/* Convert time string to decimal format and display */}
                        {item1?.time && item1?.time != '-' && 
                        <>{ ConvertTimeStringToDecimal(formatTimeHHSS(item1?.time as string))}&nbsp;  {word_translation.hour}</>}
                       
                      </td>
                    )
                  })}
                </tr>
              </>
            )
          })}
        </tbody>
      </Table>
    </Card>
  )
}
