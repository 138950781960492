import { createSlice } from  '@reduxjs/toolkit'
import type { PayloadAction } from  '@reduxjs/toolkit'



// Define the refresh status interface for the assignment list
export interface ExecutingLockStatusInterface {
    ExecutingLockStatus: boolean;
  }
  
  // Initial state, with the default refresh status set to false
  const initialState: ExecutingLockStatusInterface = {
    ExecutingLockStatus: true,
  };
  
  // Create a Redux Slice for managing the refresh status of the assignment list
  export const ExecutingLockStatusSlice = createSlice({
    name: 'ExecutingLockStatus', // Name of the Slice
    initialState, // Initial state
    reducers: {
      // Define a reducer to set the refresh status
      setLockStatus: (state, action: PayloadAction<boolean>) => {
        state.ExecutingLockStatus = action.payload as ExecutingLockStatusInterface['ExecutingLockStatus'];
      },
    },
  });
  
  // Export the reducer and action for easy use in other files
  export const { setLockStatus } = ExecutingLockStatusSlice.actions;
  
  export default ExecutingLockStatusSlice.reducer;
  