import { ActionIcon, Avatar, Box, Group, LoadingOverlay, ScrollArea, Table, Tooltip, Text } from "@mantine/core";
// import { reAttributeType, reGetEmployeeCustomOrederProduct, reGetEmployeeCustomAttributetTypesList } from "../../../../../api";
import { Key, useEffect, useState } from "react";
import { Attribute, AttributeType } from "../../../../../interface/Iuser";
import cx from 'clsx';
import classes from './custom_attrbutes/TableScrollArea.module.css';
import { IconEdit, IconTrash } from "@tabler/icons-react";
import WordTranslationComponent from "../../../../../utils/language_pack/words";
import { modals } from "@mantine/modals";
import { HintInfo } from "../../../../../utils/function";
import GlobalCoreSetting from "../../../../../utils/globalCortSetting";
import { IconDotsVertical } from "@tabler/icons-react";
import { requestOrderProduct } from "../../../../../api/index_v2";
import { OrderProductInterface } from "../../../../../interface/Iorder";

interface ComponentInterface{
 is_refresh:number,
 callBackfunction:(value:OrderProductInterface)=>void,
 returnOrederProduct:(value:OrderProductInterface[])=>void,
}
export default function ProductList({is_refresh,callBackfunction,returnOrederProduct}:ComponentInterface){
    
  const [loading, setLoading] = useState(false);
    // Get translation public configuration information
  const word_translation = WordTranslationComponent();
  // Get the global configuration
  const globalCoreSetting = GlobalCoreSetting();
  
  const [attributeList, setOrederProduct] = useState<OrderProductInterface[]>([]);

  const ajaxCurrentList = async () => {
    setLoading(true); // Set the loading status to TRUE, indicating that the data is loading
    // Send GET requests to get Attribute Type data
    const responseAttributeTypeList = await requestOrderProduct({},"GET");
    setLoading(false); // After the data is loaded, set the loading status to false
    if (responseAttributeTypeList.data.code === 200) {
        setOrederProduct(responseAttributeTypeList.data.data); 
    }
    returnOrederProduct(responseAttributeTypeList.data.data)
  };
  // Use the USEEFFECT hook. When Search changes, call the AjaxCurrenTList function
  useEffect(() => {
    //  ajaxCurrentList();
  }, [is_refresh]);

  
    const handleEdit =  (row:OrderProductInterface) => {
        callBackfunction(row)
    };

   
    // Delete user role
    const handleDel =  (row: OrderProductInterface) => {
        // modals.openConfirmModal({
        //   title: word_translation.delete_title,
        //   centered: true,
        //   children: (
        //     <Text size="sm" mt={12}>
        //         <p>  {word_translation.attribute_type_name} : {row.attribute_type_name}</p> 
        //         <p>  {word_translation.attribute_type_notation} : {row.attribute_type_notation}</p> 
        //     </Text>
        //   ),
        //   labels:globalCoreSetting.DeleteOpenConfirmModaLabels,
        //   confirmProps: globalCoreSetting.DeleteOpenConfirmModaConfirmProps,
        //   onConfirm: async() => {
        //       const attributeTypeResult = await reAttributeType({ id: row.id_attribute_type }, "DELETE");
        //       HintInfo(attributeTypeResult.data);
        //       if (attributeTypeResult.data.code == 200) {
        //         ajaxCurrentList();
        //       }
        //   },
        // });
      };
    
    
    return (
        <Box  className="px-15 pt-xs pt-4">
        <LoadingOverlay visible={loading} overlayBlur={2} />
        <ScrollArea h={200} >
        <Table>
            <thead  className="scrolleder ">
                <tr>
                <th  id="table-th-title-new">{word_translation.product_name}</th>
                <th  id="table-th-title-new">{word_translation.order_no}</th>
                <th  id="table-th-title-new">{word_translation.price}</th>
                <th  id="table-th-title-new">{word_translation.delivery_schedule}</th>
                <th  id="table-th-title-new">{word_translation.quantity}</th>
                <th  id="table-th-title-new">{word_translation.option}</th>
                </tr>
            </thead>
            <tbody>
            {attributeList.length > 0 && attributeList.map((item: OrderProductInterface, index: Key | null | undefined) => {
                return (
                    <tr className="text-center  table_tr_xs"  key={index}>
                        {/* <td>{item.attribute_type_name}</td>
                        <td>{item.attribute_type_notation}</td>
                        <td className="img-center">
                            <Tooltip  label={item.creator_first_name +" "+  item.creator_last_name}>
                                <Avatar
                                    src={item.creator_avatar}
                                    radius={25}
                                    size={25}
                                    />
                            </Tooltip>
                        </td> */}
                        <td>{item.created_at}</td>
                        <td>{item.created_at}</td>
                        <td>
                        <Group spacing={0} position="center">
                            <ActionIcon onClick={() => handleEdit(item)}>
                                <Tooltip label={word_translation.edit_tips}>
                                    <IconDotsVertical size="1.2rem" stroke={1.5} />
                                </Tooltip>
                            </ActionIcon> 
                            <ActionIcon onClick={() => handleDel(item)}>
                                <Tooltip label={word_translation.delete_tips}>
                                    <IconTrash size="1.2rem" color="red" stroke={1.5} />
                                </Tooltip>
                            </ActionIcon> 
                        </Group>
                        </td>
                    </tr>
                );
            })}
            </tbody>
            </Table>
            </ScrollArea>
        </Box>
    )
}