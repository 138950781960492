import {
  ActionIcon,
  Box,
  Divider,
  Card,
  ScrollArea,
  LoadingOverlay,
} from "@mantine/core";
import {
  useToggle,
} from "@mantine/hooks";
import {
  IconArrowNarrowDown,
  IconArrowNarrowUp,
} from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { SelectRole } from "../../../../interface/Icommon";
import {
  CustomerInterface,
  ScheduleInterface,
  TimeSummaryInterface,
} from "../../../../interface/Itimetrack";

import CreateTimerTrackRecord from "./timer_track_create";
import {
  ClientWarningHint,
  getWeekDates,
} from "../../../../utils/function";
import "./css/index.css";
import localstorageUnits from "../../../../utils/localstorageUnits";
import WordTranslationComponent from "../../../../utils/language_pack/words";
import SheetTableThead from "./sheet_table/table_thead";
import SheetTableBody from "./sheet_table/table_body";
import { requestTimeTrackerActionList } from "../../../../api/index_v2";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../state_management/store";
import { setRefreshStatus } from "../../../../state_management/features/assignments_refresh_status/assignmentsRefreshStatusSlice";
import TimeTrackerWeekStatisticsByUserId from "./timetracker_week_statistics_by_user_id";
interface ItrackProps {
  weeks: number;
  currentYear: number;
  callback: () => void;
  userId: string | null;
  currentDate: Date | null;
}
// const publicDay = ['06/19/2023', '06/22/2023', '06/14/2023'];
// dayday.includes(userId)
export default function TrackWork({
  weeks,
  currentYear,
  userId,
  currentDate,
  callback,
}: ItrackProps) {
  // get Task Mode Flag
  const taskModeFlag = localstorageUnits.getSystem().task_mode_flag;
  
    // Get translation public configuration information
  const word_translation = WordTranslationComponent();
  const weekDayInfo = getWeekDates(currentDate);
  const [iconSwitch, switchIcon] = useToggle([
    <ActionIcon
      size="sm"
      radius="sm"
      variant="outline"
      color="gray"
      onClick={() => switchIcon()}
      mx={5}
    >  
      <IconArrowNarrowDown size="1.2rem" stroke={1.5} className="font-bold" />
    </ActionIcon>,
    <ActionIcon
      size="sm"
      radius="sm"
      variant="outline"
      color="gray"
      onClick={() => switchIcon()}
      mx={5}
    >
      <IconArrowNarrowUp size="1.2rem" stroke={1.5} className="font-bold" />
    </ActionIcon>,
  ]);
  //加载中状态
  const [loading, setLoading] = useState(false);
  // Definition shows task only or Project> Order> Task
  const [unOnlyTask, setUnOnlyTask] = useState(true);
  // 获取redux store中refreshStatus的值
  const refreshStatus = useSelector((state:RootState)=>state.refreshStatus.refreshStatus)

  const dispatch = useDispatch();
  const [publicDay, setpublicDay] = useState<string[]>([]);
  const [vactionDay, setVactionDay] = useState<string[]>([]);
  const [halfDay, setHalfDay] = useState<string[]>([]);
  const [halPublicDay, setHalfPublicDay] = useState<string[]>([]);
  const [sickDay, setSickDay] = useState<string[]>([]);
  const [ProjectListA, setProjectList] = useState<SelectRole[]>([]);
  const [totalTImeSummary, setTotalTImeSummary] = useState<ScheduleInterface>();
  
  // Define task type list
  const [taskcateGoryListData, setTaskcateGoryListData] = useState<
    SelectRole[]
  >([]);
  // Add a label when adding an existing record
  const [existMarkid, setExistMarkid] = useState<string>("");

  // Define the time tracking record mark,
  const [timeTrackerList, setTimeTrackerList] = useState<CustomerInterface[]>([]);
  const [timeSummary, setTimeSummary] = useState<TimeSummaryInterface>();

  // Define all the customer lists that can be selected
  const [customerlist, setCustomerList] = useState<SelectRole[]>([]);
  //Define the task of choosing customers
  const [getCustomerCatogory, setCustomerCatogory] = useState<SelectRole[]>([]);

  // Get Timetrack Record
  const ajaxCurrentList = async () => {

        // 获取user'
        try {
         // setProjectListResult([]);
         setLoading(true)
            const response_new = await requestTimeTrackerActionList({
              week: weeks,
              year: currentYear,
              user_id: userId,
            });  
            setLoading(false)
            if(response_new.data.code == 200){
              setTimeTrackerList(response_new.data.data.customer_list);
              setTotalTImeSummary(response_new.data.data.time_tracker_summary);
              setTimeSummary(response_new.data.data.user_statistics)
              setpublicDay(response_new.data.data.user_days_off.holidays)
              setVactionDay(response_new.data.data.user_days_off.vacations)
              setHalfDay(response_new.data.data.user_days_off.half_vacations)
              setHalfPublicDay(response_new.data.data.user_days_off.half_vacations)
              setSickDay(response_new.data.data.user_days_off.sick_leave)
            }else{
              ClientWarningHint(response_new.data.msg)
            }
            console.log(response_new.data.customer_list)
       
        } catch (error) {
          // Log any errors that occur during the request
          console.error('Error fetching :', error);
        } finally {
          // Set loading state to false regardless of success or failure
        
        }
  
  };

  useEffect(() => {
    ajaxCurrentList();
    // setIconSwitches(new Array(rows.length).fill(false));
  }, [weeks, userId]);

  useEffect(() => {
    if(refreshStatus){
      ajaxCurrentList()
      dispatch(setRefreshStatus(false))
    }
}, [refreshStatus]);

 

  const refreshTimetrack = (value:any) => {
    setExistMarkid(value)
    dispatch(setRefreshStatus(true))
  };
  // 重置标记框
  const resetexistMarkid = () => {
    setExistMarkid("");
  };


    
  return (
    <>
      <Card  onClick={resetexistMarkid} mih={400} p={0} radius={0}>
        {/* <CreateTimerTrackRecord customerlist={customerlist} callBack={refreshTimetrack} projectData={ProjectListA} currentDate={currentDate} /> */}

        <CreateTimerTrackRecord
          customerlist={customerlist}
          taskcateGoryListData={taskcateGoryListData}
          callBack={refreshTimetrack}
          userId={userId}
          getCustomerCatogory={getCustomerCatogory}
          projectData={ProjectListA}
          currentDate={currentDate}
        />
        <Divider my="sm" variant="dashed" />

        <Box key={unOnlyTask + "1"}>
          {/* <LoadingOverlay visible={loading} overlayBlur={2} /> */}
        </Box>

        <ScrollArea className="max700 scrollable-content-xs" px={2} pos="relative">
         <LoadingOverlay visible={loading} overlayBlur={2} />
        <table className="table_custome text-center ">
          <SheetTableThead sickDay={sickDay} currentDate={currentDate as Date} publicDay={publicDay} vactionDay={vactionDay} halfDay={halfDay} halPublicDay={halPublicDay}/>
          <SheetTableBody 
           existMarkid={existMarkid}   
           weeks={weeks}
           totalTImeSummary={totalTImeSummary} 
           timeTrackerList={timeTrackerList} 
           currentDate={currentDate as Date}
           publicDay={publicDay} 
           vactionDay={vactionDay} 
           halfDay={halfDay} 
           sickDay={sickDay}
           halPublicDay={halPublicDay}/>
          
        </table>
            </ScrollArea>
      </Card>
   
      <Box mt={20} className="buttonFelx">
        <TimeTrackerWeekStatisticsByUserId  timeSummary={timeSummary}/>
      </Box>

    </>
  );
}
