import React from "react";
import { CheckPermission } from "./permission";

const CheckPermissionTools  = () => {
      // Use the useIntl hook to access the intl object
      const [Perm, setPerm] = React.useState({

        admin:CheckPermission('admin_permission'),
        
        customer_read:CheckPermission('00001.00003'),
        customer_update:CheckPermission('00001.00002'),
        customer_delete:CheckPermission('00001.00004'),
        customer_create:CheckPermission('00001.00001'),

        license_read:CheckPermission('00001.00003'),
        license_update:CheckPermission('00001.00002'),
        license_delete:CheckPermission('00001.00004'),
        license_create:CheckPermission('00001.00001'),
        
        server_read:CheckPermission('00025.00003'),
        server_update:CheckPermission('00025.00002'),
        server_delete:CheckPermission('00025.00004'),
        server_create:CheckPermission('00025.00001'),
        
        special_user_permission_create:CheckPermission('00023.00001'),
        purchase_create:CheckPermission('00022.00001'),
        
        public_holiday_read:CheckPermission('00014.00003'),
        public_holiday_update:CheckPermission('00014.00002'),
        public_holiday_delete:CheckPermission('00014.00004'),
        public_holiday_create:CheckPermission('00014.00001'),

        system_log_read:CheckPermission('00016.00003'),

        email_read:CheckPermission('00020.00003'),
        email_update:CheckPermission('00020.00002'),
        email_delete:CheckPermission('00020.00004'),
        email_create:CheckPermission('00020.00001'),

        identifier_read:CheckPermission('00018.00003'),
        identifier_update:CheckPermission('00018.00002'),
        identifier_delete:CheckPermission('00018.00004'),
        identifier_create:CheckPermission('00018.00001'),

        action_read:CheckPermission('00019.00003'),
        action_update:CheckPermission('00019.00002'),
        action_delete:CheckPermission('00019.00004'),
        action_create:CheckPermission('00019.00001'),
       
        

        security_read:CheckPermission('00020.00003'),
        security_update:CheckPermission('00020.00002'),
        security_delete:CheckPermission('00020.00004'),
        security_create:CheckPermission('00020.00001'),
        
        
        company_read:CheckPermission('00002.00003'),
        company_update:CheckPermission('00002.00002'),
        company_delete:CheckPermission('00002.00004'),
        company_create:CheckPermission('00002.00001'),

        contact_role_read:CheckPermission('00003.00003'),
        contact_role_update:CheckPermission('00003.00002'),
        contact_role_delete:CheckPermission('00003.00004'),
        contact_role_create:CheckPermission('00003.00001'),

        
        contact_person_read:CheckPermission('00008.00003'),
        contact_person_update:CheckPermission('00008.00002'),
        contact_person_delete:CheckPermission('00008.00004'),
        contact_person_create:CheckPermission('00008.00001'),

        country_read:CheckPermission('00004.00003'),
        country_update:CheckPermission('00004.00002'),
        country_delete:CheckPermission('00004.00004'),
        country_create:CheckPermission('00004.00001'),

        currency_read:CheckPermission('00005.00003'),
        currency_update:CheckPermission('00005.00002'),
        currency_delete:CheckPermission('00005.00004'),
        currency_create:CheckPermission('00005.00001'),

        permission_read:CheckPermission('00006.00003'),
        permission_update:CheckPermission('00006.00002'),
        permission_delete:CheckPermission('00006.00004'),
        permission_create:CheckPermission('00006.00001'),

        global_seting_read:CheckPermission('00007.00003'),
        global_seting_update:CheckPermission('00007.00002'),
        global_seting_delete:CheckPermission('00007.00004'),
        global_seting_create:CheckPermission('00007.00001'),

        module_service_read:CheckPermission('00017.00003'),
        module_service_update:CheckPermission('00017.00002'),
        module_service_delete:CheckPermission('00017.00004'),
        module_service_create:CheckPermission('00017.00001'),

        cost_center_read:CheckPermission('00009.00003'),
        cost_center_update:CheckPermission('00009.00002'),
        cost_center_delete:CheckPermission('00009.00004'),
        cost_center_select:CheckPermission('00009.00009'),
        cost_center_create:CheckPermission('00009.00001'),
      
        
        user_read:CheckPermission('00011.00003'),
        user_update:CheckPermission('00011.00002'),
        user_delete:CheckPermission('00011.00004'),
        user_create:CheckPermission('00011.00001'),
        
        user_change_log_read:CheckPermission('00012.00003'),
        user_change_log_update:CheckPermission('00012.00002'),
        user_change_log_delete:CheckPermission('00012.00004'),
        user_change_log_create:CheckPermission('00012.00001'),

        user_role_read:CheckPermission('00013.00003'),
        user_role_update:CheckPermission('00013.00002'),
        user_role_delete:CheckPermission('00013.00004'),
        user_role_create:CheckPermission('00013.00001'),
      
        
        time_tracker_read:CheckPermission('20001.00003'),
        time_tracker_update:CheckPermission('20001.00002'),
        time_tracker_delete:CheckPermission('20001.00004'),
        time_tracker_create:CheckPermission('20001.00001'),
        time_tracker_manage:CheckPermission('20001.00010'),
        leave_category_read:CheckPermission('30001.00003'),

        leave_category_update:CheckPermission('30001.00002'),
        leave_category_delete:CheckPermission('30001.00004'),
        leave_category_create:CheckPermission('30001.00001'),
        leave_request_read:CheckPermission('30002.00003'),
        leave_request_update:CheckPermission('30002.00002'),
        leave_request_delete:CheckPermission('30002.00004'),
        leave_request_create:CheckPermission('30002.00001'),
      
        task_category_parameter_read:CheckPermission('10004.00003'),
        task_category_parameter_update:CheckPermission('10004.00002'),
        task_category_parameter_delete:CheckPermission('10004.00004'),
        task_category_parameter_create:CheckPermission('10004.00001'),

        task_category_read:CheckPermission('10005.00003'),
        task_category_update:CheckPermission('10005.00002'),
        task_category_delete:CheckPermission('10005.00004'),
        task_category_create:CheckPermission('10005.00001'),

        employee_overtime_report_create:CheckPermission('40005.00050'),
        employee_overtime_report_read:CheckPermission('40005.00003'),
        // permission CostCenter 
        assignment_read:CheckPermission('10001.00003'),
        assignment_update:CheckPermission('10001.00002'),
        assignment_delete:CheckPermission('10001.00004'),
        assignment_create:CheckPermission('10001.00001'),
        assignment_archive:CheckPermission('10001.00005'),

        
        leader_timetracker_read:CheckPermission('00102.00003'),
        leader_timetracker_update:CheckPermission('00102.00002'),
        leader_timetracker_delete:CheckPermission('00102.00004'),
        leader_timetracker_create:CheckPermission('00102.00001'),
        leader_timetracker_archive:CheckPermission('00102.00005'),
        

        leader_reports_read:CheckPermission('00104.00003'),
        leader_reports_update:CheckPermission('00104.00002'),
        leader_reports_delete:CheckPermission('00104.00004'),
        leader_reports_create:CheckPermission('00104.00001'),
        leader_reports_archive:CheckPermission('00104.00005'),
        leader_reports_allread:CheckPermission('00104.00050'),

        core_reports_read:CheckPermission('90004.00003'),
        core_reports_update:CheckPermission('90004.00002'),
        core_reports_delete:CheckPermission('90004.00004'),
        core_reports_create:CheckPermission('90004.00001'),
        core_reports_archive:CheckPermission('90004.00005'),
        core_reports_allread:CheckPermission('90004.00050'),
        
        core_read:CheckPermission('90006.00003'),
        core_update:CheckPermission('90006.00002'),
        core_delete:CheckPermission('90006.00004'),
        core_create:CheckPermission('90006.00001'),
        core_archive:CheckPermission('90006.00005'),
        
        core_timetracker_read:CheckPermission('90002.00003'),
        core_timetracker_update:CheckPermission('90002.00002'),
        core_timetracker_delete:CheckPermission('90002.00004'),
        core_timetracker_create:CheckPermission('90002.00001'),
        core_timetracker_archive:CheckPermission('90002.00005'),
        

        core_assignment_read:CheckPermission('90000.00003'),
        core_assignment_update:CheckPermission('90000.00002'),
        core_assignment_delete:CheckPermission('90000.00004'),
        core_assignment_create:CheckPermission('90000.00001'),
        core_assignment_archive:CheckPermission('90000.00005'),

        core_leave_read:CheckPermission('90001.00003'),
        core_leave_update:CheckPermission('90001.00002'),
        core_leave_delete:CheckPermission('90001.00004'),
        core_leave_create:CheckPermission('90001.00001'),
        core_leave_archive:CheckPermission('90001.00005'),
        core_leave_approve:CheckPermission('90001.00006'),

        
        leader_leave_read:CheckPermission('00101.00003'),
        leader_leave_update:CheckPermission('00101.00002'),
        leader_leave_delete:CheckPermission('00101.00004'),
        leader_leave_create:CheckPermission('00101.00001'),
        leader_leave_archive:CheckPermission('00101.00005'),
        leader_leave_approve:CheckPermission('00101.00006'),


        
        boarding_read:CheckPermission('50001.00003'),
        boarding_update:CheckPermission('50001.00002'),
        boarding_delete:CheckPermission('50001.00004'),
        boarding_create:CheckPermission('50001.00001'),
        boarding_archive:CheckPermission('50001.00005'),

        
        leader_inventory_read:CheckPermission('00105.00003'),
        leader_inventory_update:CheckPermission('00105.00002'),
        leader_inventory_delete:CheckPermission('00105.00004'),
        leader_inventory_create:CheckPermission('00105.00001'),
        leader_inventory_archive:CheckPermission('00105.00005'),
        
        full_assignment:CheckPermission('00100.00011'),
        full_leave:CheckPermission('00101.00011'),
        full_timetracker:CheckPermission('00102.00011'),
        full_boarding:CheckPermission('00103.00011'),
        full_reports:CheckPermission('00104.00011'),
        full_inventory:CheckPermission('00105.00011'),

        core_open_permissions: CheckPermission('-1.0001'),
        assignments_open_permissions: CheckPermission('-2.0001'),
        leaveRequest_open_permissions: CheckPermission('-4.0001'),
        timeTracker_open_permissions: CheckPermission('-3.0001'),
        report_open_permissions: CheckPermission('-5.0001'),
        borading_open_permissions: CheckPermission('-6.0001'),
      });
      return Perm;
}


export default CheckPermissionTools;
