import {
  TextInput,
  Group,
  Grid,
  Code,
  Paper,
  Textarea,
  Title,
  Button,
  Box,
  Table,
  ActionIcon,
  Divider,
  Container,
  Col,
  Text,
  Flex,
} from "@mantine/core";
import { FormEvent, useEffect, useRef, useState } from "react";
import { hasLength, isEmail, isNotEmpty, useForm } from "@mantine/form";
import SaveBottom from "../../../ttcomponents/Button/SaveBtn";
import CancelButton from "../../../ttcomponents/Button/CancelBtn";
import { Contact, Supplier } from "../../../interface/Iinventory";
import { notifications } from "@mantine/notifications";
import {
  Icon123,
  IconCheck,
  IconTextPlus,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import { Left } from "../../time_master/svg/l";
import { Product } from "../../../interface/IProduct";
import { regGetProduct, regProductPriceHostory } from "../../../api";

interface ISupplierFormProps {
  closeFucntion: (type: number) => void;
  productItem: Product | undefined;
}

export default function ProductDetail({
  closeFucntion,
  productItem,
}: ISupplierFormProps) {
  // define rowInfo
  const rowInfo = [
    { key: "Product ID", value: productItem?.product_id },
    { key: "Product Name", value: productItem?.product_name },
    { key: "Category", value: productItem?.category_name },
    { key: "supplier", value: productItem?.supplier_name },
    { key: "Description", value: productItem?.description },
    { key: "Unit", value: productItem?.unit },
    { key: "Unit Price", value: productItem?.unit_price },
    { key: "Number", value: productItem?.inventory },
    { key: "Validity Period", value: productItem?.validity_period },
  ];

  const [productPriceHistory, setProductPriceHistory] = useState<any[]>([]);

  const fetchPriceHistory = async () => {
    const request_params = {
      page: 1,
      pagesize: 10,
      query: {
        product_id: productItem?.product_id,
      },
    };
    const res = await regProductPriceHostory(request_params);
    if (res.data.code === 200) {
      setProductPriceHistory(res.data.data.data);
    }
  };

  useEffect(() => {
    fetchPriceHistory();
  }, [productItem]);

  const hostoryRows =
    productPriceHistory &&
    productPriceHistory.map((row) => (
      <tr key={row.product_history_id}>
        <td>{row.validity_period}</td>
        <td>{row.unit_price}</td>
      </tr>
    ));

  return (
    <Container>
      <Paper p="md">
        <Flex direction="column" justify="space-between">
          {rowInfo.map((item, index) => (
            <Flex
              gap="lg"
              direction="row"
              wrap="wrap"
              key={index}
              mb="md"
              justify="space-between"
              align="center"
            >
              <Text weight={600}>{item.key}</Text>
              <Text>{item.value}</Text>
            </Flex>
          ))}
        </Flex>
        {hostoryRows && hostoryRows.length > 0 && (
          <>
            <Divider variant="dashed" />
            <Text weight={600} my="xs" color="gray">
              Price History
            </Text>
            <Table horizontalSpacing="xl" highlightOnHover withBorder>
              <thead>
                <tr>
                  <th>Date & Time</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>{hostoryRows}</tbody>
            </Table>
          </>
        )}
      </Paper>
    </Container>
  );
}
