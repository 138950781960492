import { useEffect, useState } from 'react'
import { Text, Paper, Box, Checkbox, Group, Alert, ScrollArea, Table, Flex } from '@mantine/core'
import WordTranslationComponent from '../../../../../../utils/language_pack/words'
import {
  AssignablePermissions,
  PermissionActionInterface,
  PermissionIdentifierInterface,
  RolePermission
} from '../../../../../../interface/Iemloyee'

import { IconAlertCircle, IconArrowBack } from '@tabler/icons-react'
import UniversalButton from '../../../../../../ttcomponents/Button/UniversalButton'
import ResetBtn from '../../../../../../ttcomponents/Button/ResetBtn'
import { useCostCenterLeader, useCostCenterLeaderApi } from '../../context'
import PermissionSetCheckBoxGroup from './permission_checkbox_group'
import { SelectRole } from '../../../../../../interface/Icommon'
import { getCustomTaskListFunction, getProjectListGeneralFunction } from '../../../../../../api/assignments_request'
import ItemAssignment from './item_assignment'
import { CurrentEdit } from './type'
import TableTextInputTitle from '../../../../../../ttcomponents/TableTextInputTitle/TableTextInputTitle'

interface ComponentProps {
  currentPermission: RolePermission[]
  currentEdit: CurrentEdit
  identifierListApi: (data: any, methed: string) => any
  actionListApi: (data: any, methed: string) => any
  assignablePermissionApi: (data: any) => any
}

export default function PermissionSet({
  currentPermission,
  currentEdit,
  identifierListApi,
  actionListApi,
  assignablePermissionApi
}: ComponentProps) {
  // Get translation public configuration information
  const word_translation = WordTranslationComponent()

  const {
    costCenter_id,
    identifier,
    project_ids,
    order_ids,
    task_ids,
    ignore_costCenter,
    all_project,
    all_order,
    all_task
  } = useCostCenterLeader()
  const { updateRole_permissions, updateData, updateDatePermissionRole } = useCostCenterLeaderApi()

  const [identifierList, setIdentifierList] = useState<PermissionIdentifierInterface[]>([])
  const [actionList, setActionList] = useState<PermissionActionInterface[]>([])
  const [allChecked, setAllChecked] = useState(false)
  const [assignablePermissions, setAssignablePermissions] = useState<AssignablePermissions | undefined>()

  // Execute the useeffect when the first load is loaded.
  useEffect(() => {
    const getIdentifierList = async () => {
      identifierList.length == 0 && setIdentifierList(await identifierListApi({}, 'all'))
    }
    // Get the list of IdentifierList
    const getActionList = async () => {
      actionList.length == 0 && setActionList(await actionListApi({}, 'all'))
    }
    getIdentifierList()
    getActionList()
  }, [])

  // Define all the project lists that can be selected
  const [projectList, setProjectList] = useState<SelectRole[]>([])
  const [taskList, setTaskList] = useState<SelectRole[]>([])

  useEffect(() => {
    const getProjectList = async () => {
      const param = costCenter_id ? { costCenter_id } : ''
      const projectList = await getProjectListGeneralFunction(param)
      setProjectList(projectList)
    }

    getProjectList()
  }, [costCenter_id])

  useEffect(() => {
    const getAssignablePermissionList = async () => {
      const data = await assignablePermissionApi({
        costCenter_id
      })
      setAssignablePermissions(data?.grand_id ? data : undefined)
    }
    if (costCenter_id) {
      getAssignablePermissionList()
    }
  }, [costCenter_id])

  useEffect(() => {
    const getTaskList = async () => {
      const params: {
        costCenter_id?: string
        project_id?: string[]
        project_is_null?: boolean
        all_project?: boolean
        list: boolean
      } = {
        list: true
      }

      costCenter_id && Object.assign(params, { costCenter_id })
      if (all_project) {
        Object.assign(params, { all_project: true })
      } else if (project_ids.length !== 0) {
        Object.assign(params, { project_id: project_ids })
      } else {
        Object.assign(params, { project_is_null: true })
      }

      // Call the Get Cost Center General Selection function and set the cost center data
      setTaskList(await getCustomTaskListFunction(params))
    }

    getTaskList()
  }, [costCenter_id, project_ids, all_project])

  // Get the list of IdentifierList
  const setItemPermissionSetFunction = (id_permission_identifier: string, permission_ids: string[]) => {
    // Check if id_permission_identifier is missing, return false
    if (!id_permission_identifier) {
      return false
    }

    updateRole_permissions({
      permission_ids: permission_ids,
      permission_identifier_id: id_permission_identifier
    })
  }

  // =============== Form Reset Function ===============
  // Resetting the form is different from the reset button; it resets the entire form, including permissions
  const undoFunction = () => {
    updateDatePermissionRole(currentEdit)

    // Delayed execution
    setTimeout(() => {
      updateData('role_permissions', currentPermission)
    }, 0)
  }
  const resetFunction = () => {
    // Reset the form data by setting it to undefined
    updateData('role_permissions', [])
  }
  return (
    <Paper px={20} py={10} mih={180} radius="md" withBorder p="lg" bg="var(--mantine-color-body)">
      <Box py={5}>
        <Box h={30} lh={30}>
          <Checkbox
            checked={ignore_costCenter}
            label={<TableTextInputTitle class_name="leave-title" py={0} pb={5} title={'All costCenter'} />}
            onChange={event => updateData('ignore_costCenter', event.currentTarget.checked)}
          />
        </Box>
        {/*Project Select */}
        <Group key={'project'}>
          <ItemAssignment
            title={word_translation.all_project}
            checked={all_project}
            setChecked={value => updateData('all_project', value)}
            itemIds={project_ids}
            setItemIds={value => updateData('project_ids', value)}
            projectList={projectList}
          />
        </Group>
        {/*Project Select END */}

        {/*Order Select Start */}
        <Group py={10} key={'order'}>
          <ItemAssignment
            title={word_translation.all_order}
            checked={all_order}
            setChecked={value => updateData('all_order', value)}
            itemIds={order_ids}
            setItemIds={value => updateData('order_ids', value)}
            projectList={[] as SelectRole[]}
          />
        </Group>
        {/*Order Select End */}

        {/*Task Select Start */}
        <Group key={'task'}>
          <ItemAssignment
            title={word_translation.all_task}
            checked={all_task}
            setChecked={value => updateData('all_task', value)}
            itemIds={task_ids}
            setItemIds={value => updateData('task_ids', value)}
            projectList={taskList}
          />
        </Group>
        {/*Task Select End */}
      </Box>
      <ScrollArea h={320}>
        {identifierList.length === 0 || actionList.length === 0 ? (
          <Group position="center">
            <Alert icon={<IconAlertCircle size="1rem" />} title={word_translation.no_data}>
              {word_translation.permission_select_hitn}
            </Alert>
          </Group>
        ) : (
          <Table highlightOnHover>
            <tr>
              <Box h={20}></Box>
            </tr>
            <tr className="table_tr_xs">
              <th> </th>
              <Checkbox.Group w={550} m={0} withAsterisk>
                <Group w="100%" pl={10} mb={15}>
                  {actionList.map((item, index) => {
                    return (
                      <th key={index} className="input_title">
                        <Text className="rotate--90deg" w={40}>
                          {item.title}
                        </Text>
                      </th>
                    )
                  })}
                </Group>
              </Checkbox.Group>
            </tr>
            <tbody>
              {identifierList.map((item, index) => (
                <PermissionSetCheckBoxGroup
                  key={index}
                  PermissionIdentifierItem={item}
                  PermissionAction={actionList as []}
                  assignablePermissions={assignablePermissions}
                  allCheck={allChecked}
                  callBack={setItemPermissionSetFunction}
                />
              ))}
            </tbody>
          </Table>
        )}
      </ScrollArea>
      <Flex justify={'space-between'}>
        <Checkbox
          mt={10}
          disabled={identifier && costCenter_id ? false : true}
          indeterminate={identifier && costCenter_id ? false : true}
          label={word_translation.select_all}
          checked={allChecked}
          onChange={event => setAllChecked(event.currentTarget.checked)}
        />
        <Group position="right" mt={10}>
          <UniversalButton
            btn_icon={<IconArrowBack size={14} />}
            btn_bg_color="blue"
            btn_text={word_translation.undo}
            callBack={undoFunction}
          />
          <ResetBtn callBack={resetFunction} />
        </Group>
      </Flex>
    </Paper>
  )
}
