import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ItemProps } from '../../../interface/IProduct';

export interface ExpandStatusInterface {
  productData: ItemProps[];
}

const initialState: ExpandStatusInterface = {
  productData: [],
};

/**
 */
export const ProductListSlice = createSlice({
  name: 'productData',
  initialState,
  reducers: {
    setProductListSlice: (state, action: PayloadAction<ItemProps[]>) => {
      state.productData = action.payload.map(item => ({
        ...item,
        defaultValue: item.defaultValue as string[], // 在这里进行类型转换
      }));
    },
  },
});

export const { setProductListSlice } = ProductListSlice.actions;

export default ProductListSlice.reducer;
