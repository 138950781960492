
import { ActionIcon, Box, Checkbox, ColorInput, ColorPicker, ColorSwatch, Grid, Group, Modal, MultiSelect, ScrollArea, SegmentedControl, Select, Space, Switch, Table, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import WordTranslationComponent from '../../../../../utils/language_pack/words';
import { useEffect, useState } from 'react';
import TableTextInputTitle from '../../../../../ttcomponents/TableTextInputTitle/TableTextInputTitle';
import ResetBtn from '../../../../../ttcomponents/Button/ResetBtn';
import SubmitBtn from '../../../../../ttcomponents/Button/SubmitBtn';
import localstorageUnits from '../../../../../utils/localstorageUnits';
import { DateInput } from '@mantine/dates';
import { IconCalendar } from '@tabler/icons-react';
import { getCustomerListGeneralFunction, getOrderProductsGeneralFunction } from '../../../../../api/assignments_request';
import { SelectRole } from '../../../../../interface/Icommon';
import GlobalCoreSetting from '../../../../../utils/globalCortSetting';
import UniversalButton from '../../../../../ttcomponents/Button/UniversalButton';
import TableTextInputTitleDesc from '../../../../../ttcomponents/TableTextInputTitleDesc/TableTextInputTitleDesc';
import ModalTitleText from '../../../../../ttcomponents/ModalTitleText/ModalTitleText';
import { AssignmentOrderProductData } from '../../../../../interface/Iorder';
import { truncateString } from '../../../../../utils/function';
import AssignmentsOrderedProducts from './ordered_produc ts';
import AssignmentsOrderNewProduct from './order_new_product';

interface ComponentProps {
    openFormStatus: boolean;
    is_load: boolean;
    closeFunction: () => void;
    objectsUsed: string;
    
    successCallback?: (value: any) => void
}

function AssignmentsOrderProductList({ openFormStatus, closeFunction, is_load,successCallback, objectsUsed }: ComponentProps) {
  
    // Get the default unchanged configuration
    const globalCoreSetting = GlobalCoreSetting();
    // Define all the customer lists that can be selected
    const [customerList, setCustomerList] = useState<SelectRole[]>([]);
    const [orderProducts, setOrderProduct] = useState<AssignmentOrderProductData[]>([]);
    
    const [randNumber, setRandNumber] = useState(1);
    // Get the initial time of the system
    const initSystemTime = localstorageUnits.getSystem().init_use_date;

    const [openForm, { open: openModalFunction, close: closeModalFunction }] = useDisclosure(false);
    // Get translation public configuration information
    const word_translation = WordTranslationComponent();

    useEffect(() => {
        openFormStatus ? openModalFunction() : closeModalFunction();
    }, [openFormStatus])

    useEffect(() => {
        // getOrderProducts();
    }, [])

    
    // Close component
    const closeBtnModalFunction = () => {
        closeModalFunction();
        closeFunction();
    }

    // Click save
    const applyBtnFunction = () => {
        console.log('Apply');
    }

   
    const resetColorFunction = () => {
        console.log('Reset');
       
    }

    const getOrderProducts= async() =>{
         getOrderProductsGeneralFunction('')
         is_load && setOrderProduct(await getOrderProductsGeneralFunction(""));
    }

    const getCustomerList = async () => {
        is_load && setCustomerList(await getCustomerListGeneralFunction(""));
      };
    
  const [assignmentModa, setAssignmentsModa] = useState<string[]>([]);
    const setAssignmentsModaExpandFunction=(value:any) =>
    { 
    if(Array.isArray(value)){
        setAssignmentsModa(value)
    }else if(value && typeof  value === 'string' ){
        setAssignmentsModa(value.split(","))
    }
    }
    return (
        <Modal
        //    opened={true}
        opened={openForm}
            onClose={closeBtnModalFunction}
            closeOnClickOutside={false}
            title={<><ModalTitleText title={'Computer -'+word_translation.product_management} /> </>}
            padding="xs"
            size='80%'
            radius={10}
        >
            <Box  px={10} pb={20}>
              <AssignmentsOrderedProducts />
            </Box>
            
            <Box className='border-t-1'>
            </Box>
            
            <Box  p={10}>
              <AssignmentsOrderNewProduct is_load={true} />
            </Box>
            
          
        </Modal>
    );
}
export default AssignmentsOrderProductList;
