import { useIntl } from "react-intl";
import WordTranslationComponent from "./language_pack/words";
import { Arrow } from "../ttcomponents/Header/svg/svg/Arrow";

 
const GlobalCoreSetting = () => {
  // Use the useIntl hook to access the intl object
  const intl = useIntl();
  // Get translation public configuration information
  const word_translation = WordTranslationComponent();

  const globalCoreSetting = {
    // Set the HOST
    Host: "http://localhost:8181",
    // 定义日期显示格式
    globalTimeFormatList: [
      { value: "YYYY-MM-DD", label: "YYYY-MM-DD" },
      { value: "DD/MM/YYYY", label: "DD/MM/YYYY" },
      { value: "MM/DD/YYYY", label: "MM/DD/YYYY" },
      { value: "DD-MM-YYYY", label: "DD-MM-YYYY" },
      { value: "YYYY/MM/DD", label: "YYYY/MM/DD" },
    ],
    // Set the language format
    globalLanguageList: [
      { value: "en-US", label: "en-US" },
      { value: "zh-CN", label: "zh-CN" },
      { value: "it-IT", label: "it-IT" },
      { value: "de-DE", label: "de-DE" },
    ],
    // Set TASK type  ----- task_only :project->task , Both: project->order->task  and project->task
    globalTaskModeList: [
      // { value: '0', label: word_translation.project    'Both' },
      { value: "1", label: <>{word_translation.project} <Arrow  /> {word_translation.order} <Arrow  /> {word_translation.task}</> },
      { value: "4", label: <>{word_translation.order} <Arrow  /> {word_translation.task}</> },
      { value: "2", label: <>{word_translation.project} <Arrow  /> {word_translation.order}</> },
      { value: "5", label: <>{word_translation.single_order}</> },
      { value: "3", label: <>{word_translation.project} <Arrow  /> {word_translation.task}</> },
      { value: "6", label: <>{word_translation.single_task}</> },
    ],
    //type,That kind of table
    reportBadgeList: {
      Company: [
        { value: "1", label: "PROJECT ALL", type: "1", formal: "1" },
        { value: "2", label: "EMPLOYEE ALL", type: "2", formal: "1" },
        { value: "3", label: "VACATION ", type: "2", formal: "1" },
        { value: "4", label: "SICK TIME", type: "2", formal: "1" },
        { value: "5", label: "TARGET", type: "2", formal: "1" },
        { value: "6", label: "ACTUAL", type: "2", formal: "1" },
      ],
    },
    Operator: [
      { value: "AND", label: "AND" },
      { value: "NOT", label: "NOT" },
      { value: "INTERSECT", label: "INTERSECT" },
    ],
    OperatorStr: ["AND", "OR", "NOT", "IS NULL", "/"],
    //  Report time type
    ReportTimeType: [
      { value: "range", label: "Range" },
      { value: "picker", label: "Picker" },
      { value: "month", label: "Month" },
      { value: "year", label: "Year" },
      { value: "week", label: "CW" },
    ],
    // Time interval dimension
    ReportTimeinterval: [
      { value: "weekly", label: "Weekly" },
      { value: "monthly", label: "Monthly" },
      { value: "annual", label: "Annual" },
      { value: "certainday", label: "Certain Date" },
      { value: "onetime", label: "One Time" },
    ],
  
    // Time interval dimension
    ReportWeekofDay: [
      { value: "monday", label: "Monday" },
      { value: "tuesday", label: "Tuesday" },
      { value: "wednesday", label: "Wednesday" },
      { value: "thursday", label: "Thursday" },
      { value: "friday", label: "Friday" },
      { value: "saturday", label: "Saturday" },
      { value: "sunday", label: "Sunday" },
    ],
    // Five states of requests
    LeaveStatus: [
      { value: "1", label: "Pending" },
      { value: "2", label: "Approved" },
      { value: "3", label: "Rejected" },
      { value: "4", label: "Canceled" },
      { value: "5", label: "Modified" },
    ],
  
    // Five states of requests
    PickingProductType: [
      { value: "task", label: "Task" },
      { value: "order", label: "Order" },
      { value: "project", label: "Project" },
      { value: "task_only", label: "TaskOnly" },
      { value: "other", label: "Other" },
    ],
    /**
     * active -> the on boarding process is in process ( all section have to do their tasks e.g. IT, department, warehouse etc. ) -> here a feature will come in the future for that.
     * paused -> is just paused for some reason ( maybe for now not needed )
     * canceled -> on boarding is canceled
     * complete -> on boarding is complete
     */
    ONBOARDING_STATUS: [
      { value: "1", label:intl.formatMessage({id: "onboarding",defaultMessage: "ONBOARDING", })} ,
      { value: "2", label:intl.formatMessage({id: "onboarding_paused",defaultMessage: "ONBOARDING_PAUSED", })} ,
      { value: "3", label:intl.formatMessage({id: "onboarding_canceled",defaultMessage: "ONBOARDING_CANCELED", })} ,
      { value: "4", label:intl.formatMessage({id: "onboarding_completed",defaultMessage: "ONBOARDING_COMPLETED", })} ,
    ],
    /**
     * modals.openConfirmModal(
     *  Delete Madal attributes parameter
     */

    ASSIGNMENT_STATUS: [
       { value: "1",color: "blue", label:word_translation.ready },
      { value: "2",color: "orange", label:word_translation.running } ,
      { value: "3",color: "green", label:word_translation.finished } ,
      { value: "4",color: "grape", label:word_translation.cancelled } ,
       { value: "5",color: "indigo", label:word_translation.paused } ,
      { value: "6",color: "gray", label:word_translation.archived } 
    ],
   
    UPDATE_ASSIGNMENT_STATUS: [
      // { value: "1",color: "blue", label:word_translation.ready },
      { value: "2",color: "orange", label:word_translation.running } ,
      { value: "3",color: "green", label:word_translation.finished } ,
      { value: "4",color: "grape", label:word_translation.cancelled } ,
      // { value: "5",color: "indigo", label:word_translation.paused } ,
      { value: "6",color: "gray", label:word_translation.archived } 
    ],

    USER_TIMETRACKER_TYPE: [
      { value: "3",color: "TimeTracker", label:word_translation.timetracker } ,
      { value: "4",color: "No TimeTracker", label:word_translation.no_timetracker } ,
      { value: "5",color: "Student", label:word_translation.student } ,
    ],
    USER_TIMETRACKER_TYPE_STR: [
      { value: "time",color: "time", label:word_translation.timetracker } ,
      { value: "no_time",color: "no_time", label:word_translation.no_timetracker } ,
      { value: "student",color: "student", label:word_translation.student } ,
    ],
    KWS: [
      { value: "1",color: "blue", label: "mon"},
      { value: "2",color: "orange", label:"tues" } ,
      { value: "3",color: "green", label:"weds"} ,
      { value: "4",color: "grape", label:"thurs" } ,
      { value: "5",color: "indigo", label:"fri"} ,
      { value: "6",color: "indigo", label:"sat" } ,
      { value: "7",color: "gray", label:"sun"} 
    ],

    DeleteOpenConfirmModaLabels: {
      confirm: word_translation.confirm_delete,
      cancel:word_translation.cancel_delete,
    },
    
    EditOpenConfirmModaLabels: {
      confirm: word_translation.submit,
      cancel:word_translation.cancel,
    },
    DeleteOpenConfirmModaConfirmProps: { color: "red" },
 
  };



  

  return globalCoreSetting;
};


export default GlobalCoreSetting;

