//

import { Badge, Box, Checkbox, Code, Group } from "@mantine/core";
import WordTranslationComponent from "../../../../../../utils/language_pack/words";
import { CorePermissonSetFormDataInterface, CorePermissonSetFormDataUpdateInterface, PermissionActionInterface, PermissionIdentifierInterface, PermissionRoleInterface, PermissonSetFormDataInterface } from "../../../../../../interface/Iemloyee";

import { MenuActionItemInterFace, MenuItemInterFace } from "../../../../../../interface/Iuser";
import { useEffect, useRef, useState } from "react";
import React from "react";
import memoryUtils from "../../../../../../utils/memoryUtils";


interface ComponentInterface {
  PermissionIdentifierItem: PermissionIdentifierInterface;
  PermissionAction: MenuActionItemInterFace[];
  currentItem: CorePermissonSetFormDataUpdateInterface | undefined;
  index: number;
  managementStatus:boolean;
  formValue: any;
  moduleSelectAll:any;
  currentColumn: (index: any) => void;
  currentUserid: string | undefined;
  callBack: (id_permission_identifier: any, permission_ids: string[], id_permission_set: any) => void;
  allCheck:boolean
}


// 定义一个具有字符串索引签名的接口
interface PermissionItem {
  identifier: string;
  [key: string]: any; // 字符串索引签名
}
// 原始的函数组件
const PermissionSetCheckBoxGroup = ({
  PermissionIdentifierItem, managementStatus, currentColumn,moduleSelectAll,allCheck,formValue,currentItem, callBack, PermissionAction
}: ComponentInterface) => {


  const id_user = memoryUtils.user.id_user;

  const [randNumber, setRandNumber] = useState(1)
  // Get translation public configuration information
  const word_translation = WordTranslationComponent();
  // console.log("sonValue",sonValue,"fatherChecked",fatherChecked);

  const [value, setValue] = useState<string[]>([]);

  const [checked, setChecked] = useState(false);
  
  const [isHovered, setIsHovered] = useState(false);
  
  
  

  useEffect(() => {
    setChecked(allCheck);
    if(allCheck == false){
      setValue([]);
      callBack(PermissionIdentifierItem.identifier, [], currentItem?.id_permission_set)
    }else{
      const allidentifiers = PermissionAction.map((item) => item.identifier.toString());
      setValue(allidentifiers);
      console.log("allidentifiers",PermissionIdentifierItem.identifier,allidentifiers);
      callBack(PermissionIdentifierItem.identifier, allidentifiers, currentItem?.id_permission_set);
    }
  }, [allCheck]);

  
  // ===================== Select all state Start ===================== 
  
  useEffect(() => {
      if(PermissionIdentifierItem.module == 'core' ){ 
        setCheckedFunction(moduleSelectAll?.Core  )
      }
  }, [moduleSelectAll?.Core]);
  
  
  useEffect(() => {
    if(PermissionIdentifierItem.module == 'report' ){ 
      setCheckedFunction(moduleSelectAll?.Report  )
    }
}, [moduleSelectAll?.Report]);
  
  
useEffect(() => {
  if(PermissionIdentifierItem.module == 'timetracker' ){ 
    setCheckedFunction(moduleSelectAll?.Timetracker  )
  }
}, [moduleSelectAll?.Timetracker]);

  
  
useEffect(() => {
  if(PermissionIdentifierItem.module == 'assignment' ){ 
    setCheckedFunction(moduleSelectAll?.Assignment  )
  }
}, [moduleSelectAll?.Assignment]);

  
  
useEffect(() => {
  if(PermissionIdentifierItem.module == 'leave' ){ 
    setCheckedFunction(moduleSelectAll?.Leave  )
  }
}, [moduleSelectAll?.Leave]);

  // ===================== Select all state End ===================== 
  
  const handleTrMouseLeave = () => {
      setIsHovered(false);
   };
     /**
   * 当鼠标移入元素时，设置isHovered为true
   */
  const handleMouseEnter = () => {
    setIsHovered(true);
  };


  useEffect(() => {
    setValue(currentItem?.permission_action || []);
    if(PermissionIdentifierItem.identifier == '00023' ||PermissionIdentifierItem.identifier == '00022'

    ||PermissionIdentifierItem.identifier == '30002'
    ||PermissionIdentifierItem.identifier.endsWith('99999')
    ){
      if(currentItem?.permission_action.length >= 1){
        setChecked(true);
      }else{
        setChecked(false);
      }


    }else{
     
        if(currentItem?.permission_action.length == PermissionAction.length){
          setChecked(true);
      }else{
        setChecked(false);
      }
    }

  }, [currentItem]);


  const setCheckedFunction = (value: boolean) => {
    setChecked(value);
    if(value === false || value == undefined ){
      setValue([]);
      callBack(PermissionIdentifierItem.identifier, [], currentItem?.id_permission_set)
    }else{
      const allidentifiers = PermissionAction.map((item) => item.identifier.toString());
      if(PermissionIdentifierItem.identifier == '00023' 
      ||PermissionIdentifierItem.identifier == '00022' 
      ){
        callBack(PermissionIdentifierItem.identifier, ['00001'], currentItem?.id_permission_set);
        setValue( ['00001']); 
      }else if(PermissionIdentifierItem.identifier == '30002'){
        callBack(PermissionIdentifierItem.identifier, ['00006'], currentItem?.id_permission_set);
        setValue( ['00006']); 
      }else if(PermissionIdentifierItem.identifier.endsWith('99999')){
        callBack(PermissionIdentifierItem.identifier, ['0001'], currentItem?.id_permission_set);
        setValue( ['0001']); 
      }else{
        setValue(allidentifiers);
        callBack(PermissionIdentifierItem.identifier, allidentifiers, currentItem?.id_permission_set);
      }
    }

    
  }

  
  
  const setValueCheckedFunction = (value: any) => {
    setValue(value)
    callBack(PermissionIdentifierItem.identifier, value, currentItem?.id_permission_set)
  }


  
  // 决定复选框是否被禁用
  // 决定复选框是否被禁用
const setDisabledCheckbox = (item: MenuActionItemInterFace): boolean => {
  var result = false;
  if(PermissionIdentifierItem?.permission_action){
    if(PermissionIdentifierItem.permission_action.includes(item.identifier)){
    }else{
      return false;
    }
  }

  
  // 设置禁止选择的权限
  let disable_action = [
    {identifier:'30002',action: ['00006','0001','00010','00050']},
    {identifier:'30002',action: ['00006','0001','00010','00050']},
    {identifier:'00002',action: ['00006','0001','00010','00050']},
    {identifier:'00003',action: ['00006','0001','00010','00050']},
    {identifier:'00004',action: ['00006','0001','00010','00050']},
    {identifier:'00005',action: ['00006','0001','00010','00050']},
    {identifier:'00006',action: ['00006','0001','00010','00050']},
    {identifier:'00007',action: ['00006','0001','00010','00050']},
    {identifier:'00008',action: ['00006','0001','00010','00050']},
    {identifier:'00009',action: ['00006','0001','00010','00050']},
  ]

  // let i = 0;
  for(let i=0;i<disable_action.length;i++){
      let current_permission = disable_action[i];
      if(current_permission.identifier == PermissionIdentifierItem.identifier ){
          if(current_permission.action.includes(item.identifier)){
              return false;
          }
      }
  }


  switch (PermissionIdentifierItem.identifier) {
    // case '00023':
    // case '00022':
    //   if (item.identifier !== '00001' &&  item.identifier != '0001') {
    //     return false;
    //   }
    //   break;
    // case '30002':
    //   if (item.identifier !== '00006' &&  item.identifier != '0001') {
    //     return false;
    //   }
    //   break;
    default:
      if (PermissionIdentifierItem.identifier.endsWith('99999')) {
        if (item.identifier !== '0001') {
          return false;
        }
      }
  }

  // disabled={ ? false:true}
  if ((managementStatus && formValue?.title) || (formValue?.user_ids && formValue?.user_ids.length > 0)) {
    result = true;
  }

  return result;
}

  
    /**
   * 当鼠标移出元素时，设置isHovered为false
   */
    const handleTdMouseLeave = (key:any) => {
      currentColumn(null)

    };
    const handleTdMouseEnter = (key:any) => {
      currentColumn(key)
    };
      
  return (
    <tr className="text-center justify-center position_relative  border-bt-1  items-center"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleTrMouseLeave}
      >
        
      <td className="text-left  input_title" >
        <Checkbox.Group
          w={180}
          value={value} onChange={setValueCheckedFunction} withAsterisk >
          <Group className=""> {PermissionIdentifierItem.name} &nbsp;&nbsp; &nbsp;&nbsp;</Group>
        </Checkbox.Group>
      </td>
      <Checkbox.Group
        w={600}
        className=""
        value={value} onChange={setValueCheckedFunction}
        withAsterisk
      >
          {PermissionAction.map((item, key) => {
            return (<> <td 
            
              onMouseEnter={()=>handleTdMouseEnter(key)}
              onMouseLeave={()=>handleTdMouseLeave(key)}
            className="text-left input_title" >
             <Checkbox
                w={50}
                className="border-left"
                pt={7}
                pl={20}
                // disabled={(managementStatus &&  formValue?.title) || (formValue?.user_ids &&formValue?.user_ids.length>0) ? false:true}
                disabled={!setDisabledCheckbox(item)}
                key={PermissionIdentifierItem.id_permission_identifier + '-' + key +  formValue?.role_id}
                value={item.identifier.toString()}
              /> 
            </td>
            </>
            )
          })}
      </Checkbox.Group>
      
      {isHovered &&　<td className="permission-all" >
          <Checkbox　label={word_translation.all} 
             disabled={(managementStatus &&  formValue?.title) || (!managementStatus &&  formValue?.user_ids) ? false:true}
             indeterminate={formValue?.user_ids　&&　formValue?.costCenter_id  ? false:true}　
             checked={checked} 
             onChange={(event) => setCheckedFunction(event.currentTarget.checked)} />
      </td>　} 
    </tr>
  )
}


// 使用 React.memo 进行包装
export default React.memo(PermissionSetCheckBoxGroup); 