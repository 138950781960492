import { ActionIcon, Button, Tooltip } from "@mantine/core";
import { IconCircleCheckFilled, IconCirclePlus, IconTrash, IconX } from "@tabler/icons-react";
import { FormattedMessage } from "react-intl";
import WordTranslationComponent from "../../utils/language_pack/words";

// Define the props interface for the ChildComponent
interface ChildComponentProps {
    size?: any;
    stroke?: any;
    tip?:string;
    color?:any;
    disabled?:boolean;
  callBack: () => void; // Callback function to handle button click events
}

// ChildComponent representing the Create Button
const ChecXIcon: React.FC<ChildComponentProps> = ({stroke,tip='-',disabled=true,size,color, callBack }) => {
  
  // Get translation public configuration information
  const word_translation = WordTranslationComponent();
  
  return (
    <ActionIcon disabled={!disabled} onClick={() => callBack()}>
            <Tooltip label={tip}>
                   <IconX size={size ? size :'1rem'}  stroke={stroke?stroke:'1.5'} color={color ?  color:'red'} />
              </Tooltip>
    </ActionIcon>
  

  );
};

export default ChecXIcon;
