import { useState, useEffect } from 'react';
import {
  Group,
  Box,
  Collapse,
  ThemeIcon,
  Text,
  UnstyledButton,
  createStyles,
  rem,
} from '@mantine/core';
import { IconCalendarStats, IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import { write } from 'fs';
import localstorageUnits from '../../utils/localstorageUnits';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from "react-intl";
import memoryUtils from '../../utils/memoryUtils';

const useStyles = createStyles((theme) => ({
  control: {
    fontWeight: 500,
    display: 'block',
    borderRadius: 5,
    textAlign: 'center',
    width: '94%',
    padding: `0.3rem ${theme.spacing.md}`,
    marginLeft: "3%",
    marginTop: 5,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
    fontSize: theme.fontSizes.sm,
    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.customize[0] : theme.colors.customize[0],
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    },
  },

  link: {
    fontWeight: 500,
    textDecoration: 'none',
    borderRadius: 5,
    marginRight: rem(30),
    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.gray[0] : theme.colors.customize[1],
      color: theme.colorScheme === 'dark' ? theme.black : theme.white,
    },
  },
  subbox: {
    fontWeight: 500,
    marginLeft: rem(33),
    width: '151px',
    fontSize: 12
  },
  chevron: {
    transition: 'transform 200ms ease',
  },
}));

interface LinksGroupProps {
  icon: React.FC<any>;
  label: string;
  initiallyOpened?: boolean;
  links?: { label: string;status?:boolean; link: string }[];
  link: string;
  status?:boolean;
  subActiveIndex: string;
  setActiveIndex?: (index: string) => void;
  setSubActiveIndex?: (index: string) => void;
  callback: () => void;
  isActive?: boolean;
}

export function LinksGroup({ icon,status =true, label, initiallyOpened, links, link, subActiveIndex, callback, setActiveIndex, setSubActiveIndex, isActive }: LinksGroupProps) {
  // 钩子函数-切换语言
  const intl = useIntl();

  const [user_role_id, set_user_role_id] = useState<string | null>(memoryUtils.user.user_role_id);
  const navigate = useNavigate();
  const location = useLocation();
  const { classes, theme } = useStyles();
  const hasLinks = Array.isArray(links);
  const dark = theme.colorScheme === 'dark';
  const flink = link; 
  const fubActiveIndex = subActiveIndex;
  const fisActive = isActive;
  const [opened, setOpened] = useState(initiallyOpened || false);
  const ChevronIcon = theme.dir === 'ltr' ? IconChevronRight : IconChevronLeft;
  useEffect(() => {
    if (initiallyOpened) {
      setOpened(true);
    }
  }, [initiallyOpened]);




  const [childMentStatus, setChildMentStatus] = useState(false);

  const items = (hasLinks && links.length > 0 ? links : []).map((link, subIndex) => {
    let subActiveBool = false;
    if (link.label === fubActiveIndex) {
      subActiveBool = true;
    }
      
    return (
  <Box key={subIndex}>
          {link.status ?
            <Box className={`${classes.subbox} `} style={{ cursor: 'pointer' }} key={link.label}>
            <Box py={2} px={4} m={0} p={0} className={` ${subActiveBool ? "navSubBoxActive" : "navSubBox"} `} >
            <Text
            py={8}
            px={4}
            size={10} fw={500}
            className={` ${subActiveBool ? "navSubBgColor" : "navSubBgColorMo"}   ${classes.link}`}
            color={dark ? theme.colors.customize[3] : theme.colors.customize[2]}
            onClick={() => handleSubMenuClick(link)}
          >
            <FormattedMessage id={link.label} defaultMessage={link.label} />
          </Text>  </Box>

          </Box>:
            <></>
          }
      </Box>

    )
  })

  function handleSubMenuClick(sublink: object): void {
    const subLable = (sublink as any).label;
    const subLink = (sublink as any).link;
    localstorageUnits.savePath({ currentChildrenActivePath: sublink });
    localstorageUnits.savePath({ currentPreChildrenActivePath: sublink });
    localstorageUnits.savePath({ currentActiveIndex: label });
    localstorageUnits.savePath({ currentPreActiveIndex: label });
    const currentUrl = location.pathname;
    localstorageUnits.savePath({ currentUrl: label });
    setSubActiveIndex && setSubActiveIndex(subLable);
    setActiveIndex && setActiveIndex(label);
    callback();
    navigate(subLink);
  }

  function handleMenuClick(path: string): void {
    const cleanedPath = path.replace('/', ''); // Remove the slope character `/`
    if (cleanedPath) {
      const capitalizedPath = cleanedPath.charAt(0).toUpperCase() + cleanedPath.slice(1); // The first letters, match Label
      localstorageUnits.savePath({ currentActiveIndex: capitalizedPath })
      localstorageUnits.savePath({ currentPreActiveIndex: capitalizedPath })
      const currentUrl = location.pathname;
      localstorageUnits.savePath({ currentUrl: label })
    }
    setActiveIndex && setActiveIndex(label)
    setSubActiveIndex && setSubActiveIndex('');
    callback();
    navigate(flink);
  }

  function handNolinksMenu(flink: string, links: { label: string; link: string }[]): void {
    setOpened((o) => !o)
    let initiallyOpeneds = {
      label: label,
      opened: !opened,
    }
    const cleanedPath = flink.replace('/', ''); // Remove the slope character `/`
    if (cleanedPath) {
      const capitalizedPath = cleanedPath.charAt(0).toUpperCase() + cleanedPath.slice(1); // The first letters, match Label
      console.log(flink,links)
      localstorageUnits.savePath({ currentActiveIndex: capitalizedPath })
      localstorageUnits.savePath({ currentPreActiveIndex: capitalizedPath })
      if(links.length > 0){
        localstorageUnits.savePath({ currentChildrenActivePath: links[0].link });
        localstorageUnits.savePath({ currentPreChildrenActivePath: links[0].link });
        setSubActiveIndex && setSubActiveIndex(links[0].label)
      }
      const currentUrl = location.pathname;
      localstorageUnits.savePath({ currentUrl: label })
    }
    setActiveIndex && setActiveIndex(label)
    localstorageUnits.savePath({ initiallyOpeneds: initiallyOpeneds })
    callback();
    navigate(flink);
  }

  return (
    <>
      { !status  ? 
      <></> :  
      
      hasLinks ? <UnstyledButton onClick={() => handNolinksMenu(flink, links)} className={`navBarMo ${fisActive ? "navBar" : ""}   ${classes.control}`} >
        <Group position="apart" spacing={0} className="">
          <Box sx={{ display: 'flex', alignItems: 'center' }} >
            <ThemeIcon variant="" size={30}>
             {icon as any}
            </ThemeIcon>
            <Box ><Text color={dark ? theme.colors.customize[3] : theme.white} >
              <FormattedMessage id={label} defaultMessage={label} />
            </Text></Box>
          </Box>
          <ChevronIcon
            className={classes.chevron}
            size="0.8rem"
            stroke={1.8}
            color='white'
            style={{
              transform: opened ? `rotate(${theme.dir === 'rtl' ? -90 : 90}deg)` : 'none',
            }}
          />
        </Group>
      </UnstyledButton> :

        <UnstyledButton onClick={() => handleMenuClick(flink)} className={`navBarMo ${fisActive ? "navBar" : ""}  ${classes.control}`} >
          <Group position="apart" spacing={0} >
            <Box sx={{ display: 'flex', alignItems: 'center' }} >
            
              <Box  ><Text color={dark ? theme.colors.customize[3] : theme.white} >
                <FormattedMessage id={label} defaultMessage={label} />
              </Text>
              </Box>
            </Box>
          </Group>
        </UnstyledButton>
        }
      {hasLinks ? <Collapse mt={5} ml={10} in={opened} style={{ marginLeft: 0 }}>{items}</Collapse> : null}
    </>
  );
}

const mockdata = {
  label: 'Releases',
  icon: IconCalendarStats,
  links: [
    { label: 'Upcoming releases', link: '/' },
    { label: 'Previous releases', link: '/' },
    { label: 'Releases schedule', link: '/' },
  ],
  link: ''
};

export function NavbarLinksGroup() {
  return (
    <Box
      sx={(theme) => ({
        minHeight: rem(220),
        padding: theme.spacing.md,
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
      })}
    >
      <LinksGroup callback={function (): void {
        throw new Error('Function not implemented.');
      }} subActiveIndex={''} {...mockdata} />
    </Box>
  );
}
