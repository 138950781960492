import { Box } from "@mantine/core";

interface ComponentInterface{
    title:any;
    value:any;
    value_class?:string;
    class_name?:string;
    colspan?:number;
    font_color?:string
}
export default function  TableTd({colspan=1,font_color,value_class = 'assignment_text_content',class_name,value,title}:ComponentInterface){
    return(
        <td  className={class_name}  colSpan={colspan} >
            <Box className="hide-excess-text assignment_text_title">{title}</Box>
            <Box style={{color:font_color?font_color:''}} className={`hide-excess-text  ${value_class}`}> {value || value == 0? value :'-'}</Box>
        </td>
    )
}