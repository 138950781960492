import {
    Group,
    Text,
    Avatar,
    Grid,
    ActionIcon,
    Tooltip,
    Badge,
} from "@mantine/core";
import React from "react";
import { NewBoardingListData } from "../../../interface/Iuser";
import { IconBookDownload, IconDoorExit, IconEdit, IconRefreshDot, IconShieldCheck, IconShieldCog, IconShieldHalf, IconShieldX, IconSwitch } from "@tabler/icons-react";
import EditIconPerm from "../../../ttcomponents/EditIcon/EditIconPerm";
import DeleteIconPerm from "../../../ttcomponents/DeleteIcon/DeleteIconPerm";
import apiUrl from '../../../config/api_config/config';
import CheckPermissionTools from "../../../utils/permission";
interface ComponentInterface {
    row: NewBoardingListData;
    boarding_status_id: any;
    boarding: any;
    Onboarding: (row: NewBoardingListData, boarding_status_id: any, type: string, action: any, color: any) => void;
    handleEdit: (row: NewBoardingListData) => void;
    delHandle: (row: NewBoardingListData) => void;
    switchCostCenter: (row: NewBoardingListData) => void;
}
/**
* Extras 
* @returns 
*/
export default function TrBoarding({ row, switchCostCenter, boarding, Onboarding, delHandle, boarding_status_id, handleEdit }: ComponentInterface) {
    //-------------permission list allocation-------------

    const Perm = CheckPermissionTools();
    return (
        <tr key={row.id_user} className="border-bt-2 ">
            <td>{row.stuff_no}</td>
            <td >
                <Grid justify="center" align="center">
                    <Grid.Col offset={3} span={2} className="text-right"><Avatar
                        src={apiUrl.fileSystemDomain+row.user_avatar}
                        radius={40}
                        size={26}
                    /></Grid.Col>
                    <Grid.Col span={6}><Text className="text-left">{row.username}</Text>
                        <Text className="text-left" fz={11}>{row.email}</Text></Grid.Col>
                </Grid>
            </td>
            <td>{row.start_date}</td>
            <td>{row.end_date}</td>
            <td>{row.created_at}</td>
            <td>{row.vacation_day_year}</td>
            <td>{row.work_hours_week}</td>
            <td>{row?.costCenter}</td>
            <td className="img-center">
                {/* <Avatar.Group spacing="md"   >
                            {row.team_leader.map(
                                (item_leader: any, item_leader_index: any) => (
                                <Tooltip
                                    label={item_leader.first_name}
                                    key={item_leader.first_name}
                                >
                                    <Avatar
                                    src={item_leader.avatar}
                                    radius={40}
                                    size={26}
                                    />
                                </Tooltip>
                                )
                            )}
                    </Avatar.Group> */}
            </td>
            <td>
                <Badge color={row.boarding == 1 ? 'green' : row.boarding == 2 ? 'red' : row.boarding == 3 ? 'dark' : 'blue'}>{row.boarding_name}</Badge></td>
            <td>
                <Badge color={row.boarding_status_id == 1 ? 'green' : row.boarding_status_id == 2 ? 'red' : row.boarding_status_id == 3 ? 'dark' : 'blue'}>{row.status_name ? row.status_name : '--'}</Badge></td>

            <td>
                    
                <Group spacing={0} position="center">
                    {row.boarding_status_id != '4' && <>
                      {/* new boarding operation options start  */}
                      {(row.boarding == 1 && ( row.boarding_status_id != '1' ))  &&
                        <>
                          {row.boarding_status_id != 2 &&
                                <ActionIcon onClick={() => handleEdit(row)}>
                                    <Tooltip label="Cancel Onboarding completed">
                                        <EditIconPerm enable={Perm.boarding_update} callBack={() => { }} />
                                    </Tooltip>
                                </ActionIcon>
                            }

                            <ActionIcon disabled={!Perm.boarding_update} onClick={() => Onboarding(row, '1', '1', 'onboarding', '')}>
                                <Tooltip label="Onboarding completed">
                                    <IconShieldCheck size="1.2rem" color="blue" stroke={1.5} />
                                </Tooltip>
                            </ActionIcon>
                            {row.boarding_status_id == '' &&
                                <ActionIcon disabled={!Perm.boarding_update}  onClick={() => Onboarding(row, '3', '1', 'onhold', '')}>
                                    <Tooltip label="Onboarding onHold">
                                        <IconShieldHalf size="1.2rem" color="green" stroke={1.5} />
                                    </Tooltip>
                                </ActionIcon>
                            }

                            <ActionIcon  disabled={!Perm.boarding_update}  onClick={() => Onboarding(row, '2', '1', 'cancel', 'red')}>
                                <Tooltip label="Cancel Onboarding completed">
                                    <IconShieldX size="1.2rem" color="red" stroke={1.5} />
                                </Tooltip>
                            </ActionIcon>
                        </>
                    }

                    {/* new boarding operation options end  */}
                    {(row.boarding == 2  &&   row.boarding_status_id != '1' ) &&
                        <>
                            <ActionIcon disabled={!Perm.boarding_update}  onClick={() => Onboarding(row, '0', '3', 'resign', '')}>
                                <Tooltip label="Change">
                                    <IconShieldCog size="1.2rem" stroke={1.5} color="orange" />
                                </Tooltip>
                            </ActionIcon>
                            
                            <ActionIcon disabled={!Perm.boarding_update}  onClick={() => Onboarding(row, '2', '1', 'cancel', 'red')}>
                                <Tooltip label="Cancel Onboarding completed">
                                    <IconShieldX size="1.2rem" color="red" stroke={1.5} />
                                </Tooltip>
                            </ActionIcon>
                            
                            <ActionIcon disabled={!Perm.boarding_update}  onClick={() => Onboarding(row, '1', '2', 'cancel', 'red')}>
                                <Tooltip label="Off boarding completed">
                                    <IconShieldCheck size="1.2rem" color="blue" stroke={1.5} />
                                </Tooltip>
                            </ActionIcon>
                        </>
                    }

                    
                    {/* off boarding operation options start  */}
                    {row.boarding == 3  &&   row.boarding_status_id != '1' && 
                        <>
                            <ActionIcon disabled={!Perm.boarding_update}  onClick={() => switchCostCenter(row)}>
                                <Tooltip label="Changing costCenter">
                                    <IconSwitch size="1.2rem" stroke={1.5} color="green" />
                                </Tooltip>
                            </ActionIcon>
                            <ActionIcon disabled={!Perm.boarding_update}  onClick={() => Onboarding(row, '3', '3', 'onhold', '')}>
                                    <Tooltip label="Changing onHold">
                                        <IconShieldHalf size="1.2rem" color="green" stroke={1.5} />
                                    </Tooltip>
                                </ActionIcon>
                            <ActionIcon disabled={!Perm.boarding_update} onClick={() => Onboarding(row, '1', '3', 'onboarding', '')}>
                                <Tooltip label="Changing completed">
                                    <IconShieldCheck size="1.2rem" color="blue" stroke={1.5} />
                                </Tooltip>
                            </ActionIcon>
                        </>
                    }

                    {/* off boarding operation options end  */}
                    
                 
                    <ActionIcon    disabled={!Perm.boarding_archive}  onClick={() => Onboarding(row, '4', row.boarding , 'onboarding', '')}>
                            <Tooltip label="Archive completed">
                                <IconBookDownload size="1.2rem" color="blue" stroke={1.5} />
                            </Tooltip>
                    </ActionIcon>
                    
                    </>}
                
                        {Perm.boarding_delete &&   <DeleteIconPerm enable={false}  callBack={() => delHandle(row)} />}
                 
                    {/* ONBOARDING ARCHIVE  end  */}

                </Group>
            </td>
        </tr>
    )
}