import en from './en-US.json';
import cn from './zh-CN.json';
import it from './it-IT.json';
import de from './de-DE.json';
export default {
  'en-US': en,
  'zh-CN': cn,
  'de-DE': de,
  'it-IT': it,
};
