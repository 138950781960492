import { ActionIcon, Avatar, Box ,Flex,Group,Menu,Select,Text, Tooltip } from "@mantine/core";
import { User } from "../../../../time_master/svg/u";
import WordTranslationComponent from "../../../../../utils/language_pack/words";
import { forwardRef, useState } from "react";
import { UserItemPropsInterface } from "../../../../../interface/Icommon";

import apiUrl from '../../../../../config/api_config/config';
import { IconChevronCompactDown } from "@tabler/icons-react";
interface ComponentInteface {
    userlistnew:UserItemPropsInterface[];
    userId:string|null;
    callBack:(value:any)=>void
}

export default function ChooseUser({userlistnew,userId,callBack}:ComponentInteface) {
    
  // Get translation public configuration information
  const word_translation = WordTranslationComponent();
  const [selectedUserInfo,setSelectedUserInfo] = useState<any>(undefined)
  const SelectItem = forwardRef<HTMLDivElement, UserItemPropsInterface>(
    ({ image, label, description, ...others }: UserItemPropsInterface, ref) => (
      <Tooltip label={label}>
        <Box ref={ref} {...others}>
        <Group>
          <Avatar size={20} src={apiUrl.fileSystemDomain + image} />
          {label}
        </Group>
        </Box>
      </Tooltip>
    )
  );
  const  setUseIdFunction=(value:any)=>{
        const seletedInfo = userlistnew.find(item=>{return item.value == value})
        setSelectedUserInfo(seletedInfo)
        console.log(seletedInfo,'222');
        callBack(value);
  }
    return (
        <>
            <Box>
                <Text c="#fff" size={13}>{selectedUserInfo? selectedUserInfo?.label :word_translation.choose_user} &nbsp;</Text>
            </Box>
            <Box pt={1}>
                <Menu openDelay={100} closeDelay={400} position="bottom-end">
                    <Menu.Target>
                        <Flex >
                            <ActionIcon
                                size={30}
                                mr={30}
                                radius="xl"
                                variant="transparent"
                                color="customize-gray.7"
                            >
                                 {selectedUserInfo ?  <Avatar size={28} radius={20} src={apiUrl.fileSystemDomain + selectedUserInfo?.image} />:  <User />}
                            </ActionIcon>
                        </Flex>
                    </Menu.Target>
                    <Menu.Dropdown>
                        <Select
                            className="mantine-Select-dropdown-grid-only"
                            placeholder={word_translation.choose_user}
                            itemComponent={SelectItem}
                            data={userlistnew}
                            searchable
                            w={250}
                            variant="filled"
                            defaultValue={userId}
                            onChange={(value)=>setUseIdFunction(value) }
                            maxDropdownHeight={400}
                            nothingFound="Nobody here"
                            filter={(value, item) =>
                                item.label?.toLowerCase()?.includes(value.toLowerCase().trim()) ||
                                item.description?.includes(value.toLowerCase().trim())
                              }
                        />
                    </Menu.Dropdown>
                </Menu>
            </Box>
        </>
    )
}