import {  Badge, Box, Group,Text, Tooltip } from "@mantine/core";

import WordTranslationComponent from "../../../../utils/language_pack/words";
import { TimeSummaryInterface } from "../../../../interface/Itimetrack";
import { ConvertTimeStringToDecimal, formatTimeHHSS } from "../../../../utils/function";
interface ComponentInteface {
    timeSummary: TimeSummaryInterface | undefined;
}

export default function TimeTrackerWeekStatisticsByUserId({ timeSummary }: ComponentInteface) {
    // Get translation public configuration information
    const word_translation = WordTranslationComponent();

    return (
        <>
            <Box>
                <Group position="apart">
                    <Box>
                        <Group >
                            <Text mx={20} fw={600}>
                                {word_translation.total_time}:
                                {ConvertTimeStringToDecimal(formatTimeHHSS(timeSummary?.total_time as string ? timeSummary?.total_time as string : '00:00'))}  {word_translation.hour}
                            </Text>
                            <Text mx={20} fw={600}>
                                {word_translation.target}:{ConvertTimeStringToDecimal(formatTimeHHSS(timeSummary?.target_time as string ? timeSummary?.target_time as string : '00:00'))} {word_translation.hour}
                            </Text>
                            <Text  mx={20}  fw={600}  c={timeSummary?.difference && timeSummary.difference.includes("-") ? "red" : "green"} >
                                {word_translation.diff_time}:
                                <>{ConvertTimeStringToDecimal(formatTimeHHSS(timeSummary?.difference as string ? timeSummary?.difference as string : '00:00'))}  {word_translation.hour}</>
                            </Text>
                            <Tooltip label={<Text
                                fw={600}
                            >
                                {word_translation.productivity}:{timeSummary?.completion ? timeSummary?.completion : 0}%
                            </Text>}>
                                <Text mx={20} fw={600}>
                                {word_translation.overtime}:
                                    <>{ConvertTimeStringToDecimal(formatTimeHHSS(timeSummary?.overtime as string ? timeSummary?.overtime as string : '00:00'))}  {word_translation.hour}</> 
                                </Text>
                            </Tooltip>
                            <Text
                                ml={500}
                                fw={600}
                            >
                                {word_translation.productivity}:{timeSummary?.completion ? timeSummary?.completion : 0}%
                            </Text>
                        </Group>
                    </Box>
                </Group>
            </Box>
            <Box mt={10}>
                <Badge color="red" mx={10}>{word_translation.week_end}</Badge>
                <Badge color="yellow" mx={10}>{word_translation.public_holiday}</Badge>
                <Badge color="indigo" mx={10}>{word_translation.half_day_holiday}</Badge>
                <Badge color="lime" mx={10}>{word_translation.holiday}</Badge>
                <Badge color="gray" mx={10}>{word_translation.sick}</Badge>
            </Box>
        </>
    )
} 