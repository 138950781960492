import { Button } from "@mantine/core";
import { IconCircleCheck } from "@tabler/icons-react";
import { FormattedMessage } from "react-intl";

interface ChildComponentProps {
  formRef: React.RefObject<HTMLFormElement>;
  margintop?: number;

}

const SaveBottom: React.FC<ChildComponentProps> = ({ formRef,margintop }) => {
  // Click the event to return to the submit of the form
  const handleButtonClick = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(new Event("submit", { cancelable: true }));
    }
  };
  return (
    <div>
      <Button
        mt={margintop || margintop === 0 ? margintop :10}
        type="submit"
        size="xs"
        onClick={handleButtonClick}
        leftIcon={<IconCircleCheck width={18} height={18} />}
        styles={{
          leftIcon: { marginRight: "4px" },
        }}
      >
        <FormattedMessage id="Save" defaultMessage="Save" />
      </Button>
    </div>
  );
};

export default SaveBottom;
