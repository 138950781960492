import { ActionIcon, Tooltip, Text } from "@mantine/core";
import { IconDots, IconX } from "@tabler/icons-react";
import WordTranslationComponent from "../../utils/language_pack/words";

// Define the props interface for the MoreActionBtnWithBottom component
interface SearchBoxProps {
  moreOpened: any; // Boolean indicating whether the "more" section is opened
  callBack: (moreOpened: boolean) => void; // Callback function to handle button click events
}

// MoreActionBtnWithBottom component definition
export default function MoreActionBtnWithBottom({ moreOpened, callBack }: SearchBoxProps) {
  // Get translation public configuration information
  const word_translation = WordTranslationComponent();

  // Click event handler to invoke the callback function with the updated moreOpened value
  const handleButtonClick = (moreOpened: boolean) => {
    callBack(moreOpened);
  };

  return (
    // ActionIcon component representing the more action button
    <ActionIcon size={30} variant="outline" className="positioned-right-bottom" color="blue" onClick={() => handleButtonClick(!moreOpened)}>
      {/* Tooltip displaying a label for the more action button */}
      <Tooltip label={word_translation.more_tips}>
        {/* Conditional rendering of IconX or IconDots based on the moreOpened state */}
        {moreOpened ? <IconX size="1.8rem" /> : <IconDots size="1.8rem" />}
      </Tooltip>
    </ActionIcon>
  );
}
