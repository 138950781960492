import React, { FormEvent, useRef, useState } from 'react';
import { ICategory } from '../../../../interface/IProductCategory';
import { Box, Card, Text, ScrollArea, Group, ActionIcon, Tooltip, Modal, TextInput, Code, Badge } from '@mantine/core';
import { IconCheck, IconEdit, IconFilePlus, IconPlus, IconSquarePlus, IconTrash, IconX } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import { isNotEmpty, useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { regProductCategory } from '../../../../api';
import CancelButton from '../../../../ttcomponents/Button/CancelBtn';
import SaveBottom from '../../../../ttcomponents/Button/SaveBtn';
import CategoryIndexFrom from './category_index_from';
import { useIntl } from 'react-intl';

//Interface definition
interface ICategoryProps {
  category: ICategory;
  callback: (value: any) => void;
}

const Category = ({ category, callback }: ICategoryProps) => {
  
  // useIntl for multiple language

  const [isCollapsed, setIsCollapsed] = useState(true);
  const [currentValue, setCurrentValue] = useState<ICategory>({
    category_id: '',
    category_name: '',
    category_pid: '',
    children:[]
  });
  
  const [opened, { open:openCategory, close:closeCategory }] = useDisclosure(false);


  //Click the function triggered when clicking the classification item
  const toggleCollapse = (category: any) => {
    if (category.children && category.children.length > 0) {
      console.log(category)
      setIsCollapsed(!isCollapsed);
    } else {
      // If there is no sub -classification of classification, call the callback function
      // setCurrentValue(category)
      callback(category);
    }
  };

  // Transfer the callback function to the auxiliary function of the sub -component
  function takeOverFunction(value: any) {
    closeCategory()
    callback(value);
  }

  function handleDel(row: any): void {
    throw new Error('Function not implemented.');
  }

  const handleEdit = async (row: ICategory) => {
      setCurrentValue({
        category_id:row.category_id,
        category_name:row.category_name,
        category_pid:row.category_pid,
      })

    // form.setFieldValue('category_pid',row.category_pid.toString())
    // form.setFieldValue('category_name',row.category_name.toString())
    // form.setFieldValue('category_id',row.category_id.toString())
    openCategory();
  };
  
  const createFunction = async (row: ICategory) => {
    console.log(row)
    setCurrentValue({
      category_id:'',
      category_name:row.category_name,
      category_pid:row.category_id,
    })
    openCategory();
  };

  
  const closeFucntion = async (value: any) => {
    // if(value=='3'){
      closeCategory()
       callback(value)
    // }/
  };

  return (
    <Box>
      <Box>
        <Group>
          <Box mr={20}  >
            <Group>
              <Text  onClick={() => toggleCollapse(category)} style={{ cursor: 'pointer' }} miw={120}
               className='category_style time-track-clumn-sub-bg-hover'> {category.category_name}</Text>
              <ActionIcon onClick={() => handleEdit(category)}>
                <Tooltip label="Edit">
                  <IconEdit size="1rem" stroke={1.5} />
                </Tooltip>
              </ActionIcon>
              <ActionIcon onClick={() => createFunction(category)}>
                <Tooltip label="Add">
                  <IconSquarePlus size="1rem" stroke={1.5} />
                </Tooltip>
              </ActionIcon>
              {category.children && category.children.length == 0 &&(
              <ActionIcon>
              <Tooltip label="Delete">
                   <IconTrash size="1rem"  color='red' stroke={1.5} />
              </Tooltip>
            </ActionIcon>
          )}
            </Group>
          </Box>
       
          {category.children && category.children.length > 0 && (
            <Box>
              <Group spacing={0} position="center">
                {isCollapsed ? '➕' : '➖'}
              </Group>
            </Box>
          )}
        </Group>
      </Box>
      {!isCollapsed && (
        <Box style={{ marginLeft: '25px' }}>
          {category.children && category.children.map((childCategory: ICategory) => (
            <Box key={childCategory.category_id}>
              {/* {/ * Recursively call Category components */}
              <Category callback={takeOverFunction} category={childCategory}  />
            </Box>
          ))}
        </Box>
      )}
      
      <Modal  zIndex={202} opened={opened} onClose={closeCategory} title="Authentication">
              <CategoryIndexFrom currentValue={currentValue} closeFucntion={closeFucntion} />
      </Modal>
    </Box>
  );
};








// Main application component
interface Icategories {
  categories: ICategory[];
  callback: (value: any) => void;
}

function CategoryAction({ categories, callback }: Icategories) {
  const intl = useIntl();
  // The auxiliary function, pass the callback to the child component
  function takeOverFunction(value: any) {
    callback(value);
  }

  const [opened, { open:openCategory, close:closeCategory }] = useDisclosure(false);

  const [currentValue, setCurrentValue] = useState<ICategory>({
    category_id: '',
    category_name: '',
    category_pid: '',
    children:[]
  });
  
  const closeFucntion = async (value: any) => {
    // if(value=='3'){
      closeCategory()
       callback(value)
    // }/
  };

  const createFunction = async () => {

    setCurrentValue({
      category_id:'',
      category_name:'',
      category_pid:0,
    })
    openCategory();
  };

  return (
    <Card>
      <Text fs="italic"  td="underline" style={{ cursor: 'pointer' }}  onClick={()=>createFunction()}> 
      {intl.formatMessage({  id: "create_product_category_title", defaultMessage: "Add top Category"})}
      </Text>
      <ScrollArea type="always" mih={400} scrollbarSize={4} scrollHideDelay={20}>
        <Box className=''>
          {categories.map((category: ICategory) => (
            <Box key={category.category_id}>
              {/* Rendering top classification */}
              <Category callback={takeOverFunction} category={category} />
            </Box>
          ))}
        </Box>
      </ScrollArea>
      <Modal  zIndex={202} opened={opened} onClose={closeCategory} title="Authentication">
              <CategoryIndexFrom currentValue={currentValue} closeFucntion={closeFucntion} />
      </Modal>
    </Card>

  );
}

export default CategoryAction;

