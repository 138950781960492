
import { Tabs, Paper, Box, LoadingOverlay, Badge, Group } from '@mantine/core';
import PageTitle from '../../../ttcomponents/PageTitle/PageTitle';
import { FormEvent, useEffect, useRef, useState } from 'react';
import GlobalCoreSetting from '../../../utils/globalCortSetting';
import WordTranslationComponent from '../../../utils/language_pack/words';
import { regGetUserRoleList, requestTeamOvertime } from '../../../api';
import { requestEmployeeOvertime } from '../../../api/index_v2';
import { RootState } from '../../../state_management/store';
import { useSelector } from 'react-redux';
import { EmployeeOvertimeInterface } from '../../../interface/IReport';
import { ConvertTimeStringToDecimal, formatTimeHHSS } from '../../../utils/function';
// OvertimeReport component definition
export default function OvertimeReport() {
  // Get the default unchanged configuration
  const globalCoreSetting = GlobalCoreSetting();
  // Get translation public configuration information
  const word_translation = WordTranslationComponent();

  
  const [loading, setLoading] = useState(false);
  const [employeeOvertime, setEmployeeOvertime] = useState<EmployeeOvertimeInterface[]>([]);
 
  const [totalTime, setTotalTime] = useState('');
  
  
  // 获取redux store中的值
  const SearchValue = useSelector((state:RootState)=>state.search.searchValue)
  // Execute the useeffect when the first load is loaded.
  const isInitialMount = useRef(true);
  useEffect(() => {
    if (!isInitialMount.current) {
        ajaxCurrentList({search:SearchValue});
    }
    isInitialMount.current = false;
  }, [SearchValue]);


    // Define an asynchronous function ajaxcurrenTlist to send AJAX request to obtain the list of suppliers
    const ajaxCurrentList = async (value:any={}) => {
      setLoading(true); // Set the loading status to TRUE, indicating that the data is loading

  
      // Send GET requests to get supplier datasetEmployeeOvertime
      const responseOverTime = await requestTeamOvertime(value,'GET');
      if (responseOverTime.data.code === 200) {
        setEmployeeOvertime(responseOverTime.data.data.employee_overtime_list); 
        setTotalTime(responseOverTime.data.data.total_overtime); 
      }
      setLoading(false); // After the data is loaded, set the loading status to false
    
    };
    // Use the USEEFFECT hook. When Search changes, call the AjaxCurrenTList function
    useEffect(() => {
      ajaxCurrentList();
    }, []);

  return (
    <Paper px={20}  mih={600}  className='position_relative'  >
      <PageTitle title={`${word_translation.team_report}      `} />
      <LoadingOverlay visible={loading} overlayBlur={2} />
      {/* Tabs for different settings */}
      <Group my={10} className='border_coustom'>
          <Badge size='md' color='dark'>{word_translation.over_time_total_count} :  {formatTimeHHSS(totalTime)}  {word_translation.hour}</Badge> 
      </Group>
      <Box>
      <table className="table_custome text-center">
              <thead  className="table_th_xs">
                 <tr>
                    <th>{word_translation.id_user}</th>
                    <th>{word_translation.first_name}</th>
                    <th>{word_translation.surname}</th>
                    <th>{word_translation.work_hours_week}</th>
                    {/* <th>{word_translation.start_year}</th>
                    <th>{word_translation.start_kw}</th>
                    <th>{word_translation.end_year}</th>
                    <th>{word_translation.end_kw}</th> */}
                    <th>{word_translation.hourly_account}</th>
                    {/* <th>{word_translation.time_report}</th> */}
                </tr>
              </thead>
              <tbody>
            
  {  employeeOvertime.map((row,index) => (
      <tr key={index} className="table_tr_hover border-bt-2 my-10 ">
      <td className="">
           {row.user_id}
       </td>
        <td>{row.first_name}</td>
        <td>{row.last_name}</td>
        <td>{row.work_hours_week}</td>
        {/* <td><Badge radius="sm" color='dark'>{row.start_year}</Badge></td>
        <td><Badge radius="sm" color='dark'>{row.start_week}</Badge></td>
        <td><Badge radius="sm" color='dark'>{row.end_year}</Badge></td>
        <td><Badge radius="sm" color='dark'>{row.end_week}</Badge></td> */}
        <td> {ConvertTimeStringToDecimal(formatTimeHHSS(row.overtime))} {word_translation.hour}</td>
        {/* <td><Badge radius="sm" color='gray'>{row.time_logger ? "Y" : 'N'}</Badge></td> */}
     
      </tr>
    ))}

              </tbody>
            </table>
      </Box>
         
    </Paper>
  );
}
