import {
  Avatar,
  Card,
  Checkbox,
  Group,
  Select,
  Text,
  TransferList,
  TransferListData,
  TransferListItemComponent,
  TransferListItemComponentProps,
} from "@mantine/core";
import { IconDots, IconTrash, IconX } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { ApiUserResponse, UserAllList } from "../../interface/Iuser";
import { EmployeeRoleResponseApi } from "../../interface/Iemloyee";
import { SelectRole } from "../../interface/Icommon";
import {
  regCustomerRole,
  regEmployeeroles,
  regEmployeerolesList,
} from "../../api";
import { notifications } from "@mantine/notifications";
import { useIntl } from "react-intl";

// 示例数据
const mockdata = [
  {
    value: "bender",
    avatar: "https://img.icons8.com/clouds/256/000000/futurama-bender.png",
    label: "Bender Bending Rodríguez",
    eamil: "Fascinated with cooking, though has no sense of taste",
  },

  {
    value: "carol2",
    avatar: "https://img.icons8.com/clouds/256/000000/futurama-mom.png",
    label: "Carol Miller",
    eamil: "One of the richest people on Earth",
  },

  {
    value: "carol1",
    avatar: "https://img.icons8.com/clouds/256/000000/futurama-mom.png",
    label: "Carol Miller",
    eamil: "One of the richest people on Earth",
  },

  {
    value: "carol3",
    avatar: "https://img.icons8.com/clouds/256/000000/futurama-mom.png",
    label: "Carol Miller",
    eamil: "task 10",
  },
  // ...other items
];
interface UserDataListProps {
  userlist: UserAllList[];
  callBack: (value: TransferListData) => void;
  targetUserIdsArr?: string[];

  [key: string]: any;
}
export default function SelectUserToProject({
  userlist,
  targetUserIdsArr,
  resetUser,
  callBack,
  employee,
  orderid,
}: UserDataListProps) {
  // Use the useIntl hook to access the intl object
  const intl = useIntl();

  const [data, setData] = useState<TransferListData>([mockdata, []]);
  const [datatest, setDatatest] = useState([]);

  const [employeesList, setEmployeesList] = useState<SelectRole[]>([]);
  useEffect(() => {
    const customerData = userlist.map((item) => ({
      value: item.id_user.toString() as string,
      label: item.username,
      avatar: item.avatar,
      eamil: item.email,
    }));
    if (!targetUserIdsArr) targetUserIdsArr = [];
    const targetUserIds: string[] = targetUserIdsArr;
    const extractedUsers: SelectRole[] = [];
    const remainingUsers: SelectRole[] = [];
    for (const user of customerData) {
      if (targetUserIds.includes(user.value)) {
        extractedUsers.push(user);
      } else {
        remainingUsers.push(user);
      }
    }

    setDatatest([]);
    setData([remainingUsers, extractedUsers]);
  }, [userlist, resetUser]);

  const ItemComponent: TransferListItemComponent = ({
    data,
    selected,
  }: TransferListItemComponentProps) => (
    <Group noWrap>
      <Avatar src={data.avatar} radius="xl" size="lg" />
      <div style={{ flex: 1 }}>
        <Text size="sm" weight={500}>
          {data.label}
        </Text>
        <Text size="xs" color="dimmed" weight={400}>
          {data.eamil}
        </Text>
      </div>
      <Checkbox
        checked={selected}
        onChange={() => {}}
        tabIndex={-1}
        sx={{ pointerEvents: "none" }}
      />
    </Group>
  );
  const setDataHandle = (value: TransferListData) => {
    if (!employee) {
      notifications.show({
        title: intl.formatMessage({
          id: "warning",
          defaultMessage: "warning",
        }),
        color: "red",
        icon: <IconX />,
        message: "Need to assign roles to employees",
      });
      return null;
    }
    if (!orderid) {
      notifications.show({
        title: intl.formatMessage({
          id: "warning",
          defaultMessage: "warning",
        }),
        color: "red",
        icon: <IconX />,
        message: "Need to order id",
      });
      return null;
    }

    console.log(value); // 打印选中的值
    setData(value); // 更新 data 状态
    callBack(value);
  };
  console.log(data, "--------------------"); // 打印选中的值
  const projectStaffTitle = intl.formatMessage({
    id: "Opt-in project staff",
    defaultMessage: "Opt-in project staff",
  });
  const selectedEmployeesTitle = intl.formatMessage({
    id: "selectedEmployeesTitle",
    defaultMessage: "selectedEmployeesTitle",
  });
  const [searchEmployeesValue, onEmployeesSearchChange] = useState("");
  return (
    <TransferList
      value={data}
      mx={10}
      mt={10}
      onChange={setDataHandle}
      searchPlaceholder={intl.formatMessage({
        id: "Search employees...",
        defaultMessage: "Search employees...",
      })}
      nothingFound={intl.formatMessage({
        id: "Please select employees",
        defaultMessage: "Please select employees",
      })}
      initialSelection={[datatest, []]}
      titles={[projectStaffTitle, selectedEmployeesTitle]}
      listHeight={300}
      className="input_title"
      breakpoint="sm"
      itemComponent={ItemComponent}
      filter={(query, item) =>
        item.label.toLowerCase().includes(query.toLowerCase().trim())
      }
    />
  );
}
