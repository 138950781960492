import { useEffect, useState } from "react";
import {
  createStyles,
  Table,
  ScrollArea,
  UnstyledButton,
  Group,
  Text,
  Title,
  Center,
  LoadingOverlay,
  TextInput,
  rem,
  Button,
  ActionIcon,
  Modal,
  Box,
  Tooltip,
} from "@mantine/core";
import { keys } from "@mantine/utils";
import {
  IconSelector,
  IconChevronDown,
  IconChevronUp,
  IconSearch,
  IconX,
  IconCheck,
  IconEdit,
  IconTrash,
  IconPencil,
  IconUserPlus,
  IconRefresh,
  IconPlus,
} from "@tabler/icons-react";
import theme from "../../../../theme";
import DeleteModal from "../../../../ttcomponents/DeleteModals/DeleteModal";
import { modals } from "@mantine/modals";
import { ITaskCategoryParameter } from "../../../../interface/ItaskCategoryParameter";
import { useDisclosure } from "@mantine/hooks";
import TaskCategoryParameterFrom from "./task_category_parameter_from";
import { regTaskCategoryParameter } from "../../../../api";
import { notifications } from "@mantine/notifications";
import CreateBtn from "../../../../ttcomponents/Button/CreateBtn";
import { useIntl } from "react-intl";
import WordTranslationComponent from "../../../../utils/language_pack/words";
import ModalTitleText from "../../../../ttcomponents/ModalTitleText/ModalTitleText";
import CheckPermissionTools from "../../../../utils/permission";
import { NotPermission } from "../../../../ttcomponents/NoPermission";

const useStyles = createStyles((theme) => ({
  th: {
    padding: "0 !important",
  },

  control: {
    width: "100%",
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },

  icon: {
    width: rem(21),
    height: rem(21),
    borderRadius: rem(21),
  },
}));

interface TableSortProps {
  taskCategoryParameterList: ITaskCategoryParameter[];
  isLoading: boolean;
  onValueEdit?: (value: object) => void;
  onValueDel?: (id: string) => void;
  callback: () => void;
  [key: string]: any;
}

interface ThProps {
  children: React.ReactNode;
  reversed: boolean;
  sorted: boolean;
  onSort(): void;
}

function Th({ children, reversed, sorted, onSort }: ThProps) {
  const { classes } = useStyles();
  const Icon = sorted
    ? reversed
      ? IconChevronUp
      : IconChevronDown
    : IconSelector;
  return (
    <th className={classes.th}>
      <UnstyledButton onClick={onSort} className={classes.control}>
        <Group position="apart">
          <Text fw={500} fz="sm">
            {children}
          </Text>
          <Center className={classes.icon}>
            <Icon size="0.9rem" stroke={1.5} />
          </Center>
        </Group>
      </UnstyledButton>
    </th>
  );
}

function filterData(data: ITaskCategoryParameter[], search: string) {
  const query = search.toLowerCase().trim();
  return data.filter((item) =>
    keys(data[0]).some((key) => item[key].toLowerCase().includes(query))
  );
}

function sortData(
  data: ITaskCategoryParameter[],
  payload: {
    sortBy: keyof ITaskCategoryParameter | null;
    reversed: boolean;
    search: string;
  }
) {
  const { sortBy } = payload;

  if (!sortBy) {
    return filterData(data, payload.search);
  }

  return filterData(
    [...data].sort((a, b) => {
      if (payload.reversed) {
        return b[sortBy].localeCompare(a[sortBy]);
      }

      return a[sortBy].localeCompare(b[sortBy]);
    }),
    payload.search
  );
}

export default function TableCateGoryParaMeter({  callRefresh,  taskCategoryParameterList,  isLoading,  onValueEdit,  onValueDel,}: TableSortProps) {
  // Use the useIntl hook to access the intl object
  const intl = useIntl();

  const Perm = CheckPermissionTools();
  // Get translation public configuration information
  const word_translation = WordTranslationComponent();
  useEffect(() => {
    setSortedData(taskCategoryParameterList);
    // ...
  }, [taskCategoryParameterList]);
  // 定义打开状态
  const [
    taskCategoryParameterStatus,
    { open: openTaskCategoryParameterStatus, close: closeTaskCategoryParameterStatus },
  ] = useDisclosure(false);
  // 定义一条task类型参数初始化信息为空的参数变量
  const [taskCategoryParameterInfo, setTaskCategoryParameterInfo] =  useState<ITaskCategoryParameter>();
  const [sortedData, setSortedData] = useState(taskCategoryParameterList);

  const delHandle = (row: ITaskCategoryParameter) => {
    modals.openConfirmModal({
      title: intl.formatMessage({
        id: "delete_title",
        defaultMessage: "Delete Confirmation",
      }),
      centered: true,
      children: <Text size="sm">Paramete identifier: {row.identifier}</Text>,
      labels: {
        confirm: intl.formatMessage({
          id: "confirm_delete",
          defaultMessage: "Confirm Delete",
        }),
        cancel: intl.formatMessage({
          id: "cancel_delete",
          defaultMessage: "Cancel Delete",
        }),
      },
      confirmProps: { color: "red" },
      onConfirm: async () => {
        const response = await regTaskCategoryParameter(
          { id: row.id_task_category_parameter },
          "DELETE"
        );
        const result = response.data;
        if (result.code === 200) {
          callRefresh();
          notifications.show({
            color: "green",
            icon: <IconCheck />,
            message: result.msg,
          });
        } else {
          notifications.show({
            color: "red",
            icon: <IconX />,
            message: result.msg,
          });
        }
      },
    });
  };



  // 打开编辑或者创建任务类型参数模态框
  const openDataEdit = (value: any) => {
    setTaskCategoryParameterInfo(value);
    openTaskCategoryParameterStatus();
  };
  // 修改表单创建表单回调函数、关闭模态框
  const callBackFrom = () => {
    callRefresh();
    closeTaskCategoryParameterStatus();
  };
  return (
    <ScrollArea>
      <LoadingOverlay visible={isLoading} overlayBlur={2} />
      <Group my={10}>
        <Box>
          <Title order={6}>
            {word_translation.task_category_management}
          </Title>
        </Box>
        <Box>
          {(Perm.task_category_parameter_create   || Perm.core_assignment_create ) && 
            <ActionIcon onClick={() => openDataEdit(undefined)}>
            <Tooltip label={word_translation.create}>
             <IconPlus size="1rem" stroke={1.5} />
            </Tooltip>
          </ActionIcon>}
        </Box>
      </Group>
      {Perm.task_category_parameter_read === true   || Perm.core_assignment_read ?   <table className="table_custome text-center">
        <thead className="table_th_xs  ">
          <tr>
            <th id="table-th-title-new">{word_translation.name}</th>
            <th id="table-th-title-new">{word_translation.identifier}</th>
            <th id="table-th-title-new">{word_translation.format_id}</th>
            <th id="table-th-title-new">{word_translation.option}</th>
          </tr>
        </thead>
        <tbody>
          {sortedData?.length > 0 ? (
             sortedData?.map((row, index) => (
              <tr className="text-center" key={index}>
                {/* <td>{row.id_task_category_parameter}</td> */}
                <td>{row.name}</td>
                <td>{row.identifier}</td>
                <td>{row.format_id}</td>
                <td>
                  <Group spacing={0} position="center">
                    <ActionIcon onClick={() => openDataEdit(row)}>
                      <IconPencil size="1rem" stroke={1.5} />
                    </ActionIcon>
                    <ActionIcon onClick={() => delHandle(row)}>
                      <IconTrash size="1rem" stroke={1.5} color="red" />
                    </ActionIcon>
                  </Group>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={5}>
                <Text weight={500} align="center">
                  {word_translation.no_data}
                </Text>
              </td>
            </tr>
          )}
        </tbody>
      </table>: <NotPermission />}
    
      {/* 添加order */}
      <Modal
        size="md"
        opened={taskCategoryParameterStatus}
        closeOnClickOutside={false}
        onClose={closeTaskCategoryParameterStatus}
        title={<ModalTitleText title={word_translation.task_category_patameter} />}
      >
        <TaskCategoryParameterFrom
          taskCategoryParameterInfo={taskCategoryParameterInfo}
          callback={callBackFrom}
        />
      </Modal>
    </ScrollArea>
  );
}
