import { ActionIcon, Tooltip } from '@mantine/core'
import { IconTrash } from '@tabler/icons-react'
import WordTranslationComponent from '../../utils/language_pack/words'

import * as React from 'react';
// Define the props interface for the ChildComponent
interface ChildComponentProps {
  size?: any
  stroke?: any
  color?: any
  enable?: boolean
  callBack: () => void // Callback function to handle button click events
}

// ChildComponent representing the Create Button
const DeleteIconPerm: React.FC<ChildComponentProps> = ({ stroke, enable = true, size, color, callBack }) => {
  // Get translation public configuration information
  const word_translation = WordTranslationComponent()

  return (
    <ActionIcon disabled={!enable} onClick={() => callBack()}>
      <Tooltip label={word_translation.delete_tips}>
        <IconTrash
          size={size ? size : '1rem'}
          stroke={stroke ? stroke : '1.5'}
          color={!enable ? '#333' : color ? color : 'red'}
        />
      </Tooltip>
    </ActionIcon>
  )
}

export default DeleteIconPerm
