import React, { useEffect,FormEvent,useState, useRef ,ReactNode } from "react";
import {
  TextInput,
  Box,
  Paper,
  Grid,
  Group,
  NumberInput
} from "@mantine/core";
import {
  hasLength,
  isNotEmpty,
  useForm,
} from "@mantine/form";
import { IconCheck, IconTrashX, IconX } from "@tabler/icons-react";
import { notifications } from "@mantine/notifications";
import { reAttributeType, regGetUserRoleList } from "../../../api";
import { AttributeType, UserRoleData } from "../../../interface/Iuser";
import TableTextInputTitle from "../../../ttcomponents/TableTextInputTitle/TableTextInputTitle";
import { useIntl } from "react-intl";
import WordTranslationComponent from "../../../utils/language_pack/words";
import CancelButton from "../../../ttcomponents/Button/CancelBtn";
import SaveBottom from "../../../ttcomponents/Button/SaveBtn";
import TableTopTitle from "../../../ttcomponents/TableTopTitle/TableTopTitle";
import ResetBtn from "../../../ttcomponents/Button/ResetBtn";
import { HintInfo, getPreviewComponent } from "../../../utils/function";

interface ICustomAttrbutesTypeForm {
  callFunction: () => void;
  current_item_info:AttributeType |undefined
}

export default function CustomAttrbutesTypeForm({
  callFunction,
  current_item_info
}: ICustomAttrbutesTypeForm) {
  // Initialize references and hooks
  const formRef = useRef<HTMLFormElement>(null);
  const [previewDom, setPreviewDom] = useState<ReactNode>(null);
  const [rand_number, setRandNumber] = useState<number>(1);

  const word_translation = WordTranslationComponent();
  const form = useForm({
    initialValues: {
      attribute_type_desc:'',
      id:'',
      attribute_type_name:'',
      attribute_type_notation:'',
      attribute_category:'1',
    },
    // Validation rules for form fields
    validate: {
      attribute_type_notation: hasLength(
        { min: 2, max: 100 },
        word_translation.attribute_type_notation_validata
      ),
      attribute_type_name: isNotEmpty(
        word_translation.attribute_type_name_validata
      ),
    },
  });

  // Handle form submission
  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Validate form fields
    const valid = form.validate();
    if (valid.hasErrors) {
      // Display error notification if validation fails
      notifications.show({
        color: "yellow.7",
        icon: <IconX />,
        message: Object.values(valid.errors)[0],
      });
      return;
    }

    // Determine submission type (POST or PUT)
    const submitType = form.values.id ? "PUT" : "POST";
    // Perform API request to save user role data
    const response = await reAttributeType(form.values, submitType);
   
    HintInfo(response.data);
    if (response.data.code == 200){
        callFunction();
        resetFunction();
        setRandNumber(rand_number+1)
    }
  };
  /**
   * Reset the form
   */
  const resetFunction = ()=>{
    form.reset();
    form.setFieldValue('attribute_category','1')
  }
  
  /**
   * Set the value of the table field.
   * @param field_name 
   * @param field_value 
   */
  const setFieldValueFunction = (field_name:string,field_value:string)=>{
    form.setFieldValue(field_name,field_value)
  }

  useEffect(() => {
     setPreviewDom(getPreviewComponent(form.values.attribute_type_notation))
  }, [form.values.attribute_type_notation]);

  /**
   * useEffect Hook，当 current_item_info 改变时执行回调函数
   */
  useEffect(() => {
    // 打印当前 item 信息
    console.log(current_item_info)
    setFieldValueFunction('attribute_type_desc',current_item_info ?current_item_info.attribute_type_desc:'');
    setFieldValueFunction('attribute_type_name',current_item_info ?current_item_info.attribute_type_name:'');
    setFieldValueFunction('attribute_type_notation',current_item_info ?current_item_info.attribute_type_notation:'');
    setFieldValueFunction('attribute_category',current_item_info ?current_item_info.attribute_category:'1');
    setFieldValueFunction('id',current_item_info ?current_item_info.id_attribute_type:'');
    setRandNumber(rand_number+1)
 }, [current_item_info]);

  return (
       <form onSubmit={handleFormSubmit} ref={formRef}>
       <TableTopTitle title={word_translation.create_attribute_type} />
            <Box className="pl-x-15 pt-xs pt-4">
                  <Grid>
                      <Grid.Col span={5} >
                          <TableTextInputTitle title={word_translation.attribute_type_name} />
                          <TextInput 
                             key={rand_number}
                             defaultValue={form.values.attribute_type_name}
                             onChange={(event) => setFieldValueFunction('attribute_type_name',event.currentTarget.value)} 
                          />
                      </Grid.Col>
                      <Grid.Col span={6} >
                          <TableTextInputTitle title={word_translation.description} />
                          <TextInput 
                             key={rand_number}
                             defaultValue={form.values.attribute_type_desc}
                             onChange={(event) => setFieldValueFunction('attribute_type_desc',event.currentTarget.value)} 
                          />
                      </Grid.Col >
                  </Grid>
                  <Grid className="pt-2">
                      <Grid.Col span={5}>
                          <TableTextInputTitle title={word_translation.attribute_type_notation} />
                          <TextInput 
                             key={rand_number}
                             defaultValue={form.values.attribute_type_notation}
                             onChange={(event) => setFieldValueFunction('attribute_type_notation',event.currentTarget.value)} 
                          />
                      </Grid.Col >
                  </Grid>
                  <Grid className="pt-2">
                      <Grid.Col span={5} >
                          <TableTextInputTitle title={word_translation.preview} />
                            <Box className="border-custom border-radius-xs pt-4 px-15" mih={100} mah={150}>
                              {previewDom}
                           </Box>
                      </Grid.Col>
                  </Grid>
                  
                  <Group className="m-xs" position="right">
                      <ResetBtn callBack={() => resetFunction()} />
                      <SaveBottom  margintop={0} formRef={formRef} />
                 </Group> 
         </Box>
      </form>
 
  );
}
