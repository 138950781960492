export function Left(props: React.ComponentPropsWithoutRef<'svg'>) {
    return (
        
        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="26" viewBox="0 0 27 26" fill="none">
        <g filter="url(#filter0_d_24_3342)">
          <path d="M21.2398 2.06034L21.2398 2.06038L21.2433 2.06521C21.8343 2.868 22.1525 3.92855 22.1525 5.229V12.771C22.1525 14.3583 21.674 15.5955 20.8221 16.436C19.9698 17.277 18.714 17.75 17.1026 17.75H10.5386V0.25H17.1026C18.4226 0.25 19.4993 0.564445 20.3139 1.14821L20.3138 1.14827L20.3187 1.15161C20.677 1.39566 20.9927 1.70726 21.2398 2.06034ZM15.3307 11.959L15.5063 11.781L15.3307 11.959C15.5171 12.1429 15.7548 12.229 15.9898 12.229C16.2248 12.229 16.4624 12.1429 16.6488 11.959L16.4732 11.781L16.6488 11.959C17.0125 11.6001 17.0126 11.0081 16.6489 10.6492C16.6489 10.6491 16.6488 10.6491 16.6488 10.649L14.9857 9L16.6488 7.35096L16.4732 7.173L16.6492 7.35053L16.6492 7.35062C17.0126 6.99169 17.0124 6.39982 16.6488 6.04104C16.2869 5.68399 15.6926 5.68399 15.3307 6.04104L12.9955 8.34504C12.6318 8.70393 12.6318 9.29607 12.9955 9.65496L15.3307 11.959Z" fill="white" stroke="#D9D9D9" stroke-width="0.5"/>
          <path d="M5.32083 15.9408L5.32089 15.9408L5.31734 15.936C4.72635 15.1331 4.4082 14.0726 4.4082 12.7722V5.23014C4.4082 3.72976 4.83627 2.54183 5.60124 1.70802C6.31809 0.926663 7.35203 0.433089 8.66984 0.299448V17.7115C7.70325 17.6123 6.892 17.3188 6.2475 16.8535L6.24756 16.8534L6.24196 16.8496C5.88368 16.6054 5.56791 16.2939 5.32083 15.9408Z" fill="white" stroke="#D9D9D9" stroke-width="0.5"/>
        </g>
        <defs>
          <filter id="filter0_d_24_3342" x="0.158203" y="0" width="26.2441" height="26" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="4"/>
            <feGaussianBlur stdDeviation="2"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_24_3342"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_24_3342" result="shape"/>
          </filter>
        </defs>
      </svg>
        
    );
  }

