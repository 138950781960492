import {
  Avatar,
  Card,
  Checkbox,
  Group,
  Select,
  Text,
  TransferList,
  TransferListData,
  TransferListItemComponent,
  TransferListItemComponentProps,
} from "@mantine/core";
import { IconDots, IconTrash, IconX } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { ApiUserResponse, UserAllList } from "../../../interface/Iuser";
import { EmployeeRoleResponseApi } from "../../../interface/Iemloyee";
import { SelectRole } from "../../../interface/Icommon";
import {
  regCustomerRole,
  regEmployeeroles,
  regEmployeerolesList,
  regUserAll,
} from "../../../api";
import { notifications } from "@mantine/notifications";
import localstorageUnits from "../../../utils/localstorageUnits";
import { useIntl } from "react-intl";

const elements = [
  { position: 6, mass: 12.011, symbol: "C", name: "Carbon" },
  { position: 7, mass: 14.007, symbol: "N", name: "Nitrogen" },
  { position: 39, mass: 88.906, symbol: "Y", name: "Yttrium" },
  { position: 56, mass: 137.33, symbol: "Ba", name: "Barium" },
  { position: 58, mass: 140.12, symbol: "Ce", name: "Cerium" },
];

// 示例数据
const mockdata = [
  {
    id: "1",
    value: "ProjectA",
    label: "ProjectA",
    avatar: "https://img.icons8.com/clouds/256/000000/futurama-mom.png",
    eamil: "Fascinated with cooking, though has no sense of taste",
  },

  {
    id: "2",
    value: "XingbaKe",
    avatar: "https://img.icons8.com/clouds/256/000000/futurama-mom.png",
    label: "XingbaKe",
    eamil: "One of the richest people on Earth",
  },

  {
    id: "3",
    value: "Paiqun",
    avatar: "https://img.icons8.com/clouds/256/000000/futurama-mom.png",
    label: "Paiqun",
    eamil: "One of the richest people on Earth",
  },

  {
    id: "4",
    value: "xuanziru",
    label: "xuanziru",
    avatar: "https://img.icons8.com/clouds/256/000000/futurama-mom.png",
    eamil: "One of the richest people on Earth",
  },

  {
    id: "5",
    value: "Filter AA",
    label: "Filter AA",
    avatar: "https://img.icons8.com/clouds/256/000000/futurama-mom.png",
    eamil: "One of the richest people on Earth",
  },
  // ...other items
];
interface UserDataListProps {
  callBack: (value: string) => void;
  [key: string]: any;
}
export default function SelectUserFilter({ callBack }: UserDataListProps) {
  // Use the useIntl hook to access the intl object
  const intl = useIntl();

  const [data, setData] = useState<TransferListData>([[], []]);
  const [datatest, setDatatest] = useState([]);
  // get Task Mode Flag
  const taskModeFlag = localstorageUnits.getSystem().task_mode_flag;

  /**
   * Get user information
   */
  const ajaxCurrentList = async () => {
    const response = await regUserAll({}, "GET");
    const result = response.data;
    const userListData = result.data as UserAllList[];
    const transferData = userListData.map((item) => {
      return {
        value: item.id_user.toString() as string,
        id_user: item.id_user.toString() as string,
        label: item.username,
        avatar: item.avatar,
        email: item.email,
      };
    });
    setData([transferData, []]);
  };

  // 请求user列表
  useEffect(() => {
    ajaxCurrentList();
  }, []);

  const ItemComponent: TransferListItemComponent = ({
    data,
    selected,
  }: TransferListItemComponentProps) => (
    <Group noWrap>
      <Avatar src={data.avatar} radius="xl" size="md" />
      <div style={{ flex: 1 }}>
        <Text className="select-main">{data.label}</Text>
        <Text className="select-desc">{data.email}</Text>
      </div>
      <Checkbox
        checked={selected}
        onChange={() => {}}
        tabIndex={-1}
        sx={{ pointerEvents: "none" }}
      />
    </Group>
  );

  const setDataHandle = (value: TransferListData) => {
    let selectedUser = value[1]
      .map((item) => {
        return item.id_user;
      })
      .join(",");
    setData(value); // 更新 data 状态
    callBack(selectedUser);
  };
  const [searchEmployeesValue, onEmployeesSearchChange] = useState("");
  return (
    <>
      {" "}
      <Text className="selectTitle  mb-3">
        {intl.formatMessage({
          id: "Search User Filter",
          defaultMessage: "Search User Filter",
        })}
      </Text>
      <TransferList
        value={data}
        onChange={setDataHandle}
        searchPlaceholder={intl.formatMessage({
          id: "Search",
          defaultMessage: "Search",
        })}
        nothingFound={intl.formatMessage({
          id: "No One Here",
          defaultMessage: "No One Here",
        })}
        initialSelection={[datatest, []]}
        listHeight={300}
        className="input_title"
        breakpoint="sm"
        itemComponent={ItemComponent}
        filter={(query, item) =>
          item.label.toLowerCase().includes(query.toLowerCase().trim()) ||
          item.email.toLowerCase().includes(query.toLowerCase().trim())
        }
      />
    </>
  );
}
