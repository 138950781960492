import { Box, Flex, Text } from "@mantine/core";
import { FormattedMessage } from "react-intl";
interface SearchBoxProps {
  title: any;
  lang_id?: any;
}
export default function TableTopTitle({ title, lang_id = ''}: SearchBoxProps) {
  return (
        <Box className="border-bt-1 p-y-xs  pl-x-15">
        <Text className="leave-title">
          {lang_id ? <FormattedMessage id={lang_id} defaultMessage={title}/> : title  }
        </Text>
    </Box>
  );
}
