import {
  Paper,
  TextInput,
  Box,
  Group,
  Text,
  Modal,
  Grid,
  Table,
  ActionIcon,
  LoadingOverlay,
  Tooltip,
  Flex,
  Badge,
  Dialog,
  Button,
  Textarea,
  Select,
} from "@mantine/core";
import { FormEvent, useEffect, useState } from "react";
import { Supplier, SuppliersData } from "../../../../interface/Iinventory";
import { useDisclosure } from "@mantine/hooks";

import PageTitle from "../../../../ttcomponents/PageTitle/PageTitle";
import {
  IconEdit,
  IconPencil,
  IconSearch,
  IconTrash,
  IconUserPlus,
  IconUsersMinus,
  IconX,
} from "@tabler/icons-react";
import CreateBtn from "../../../../ttcomponents/Button/CreateBtn";
import { useIntl } from "react-intl";
import { useForm } from "@mantine/form";
import { modals } from "@mantine/modals";
import {
  regGetPickingOutBound,
  regGetProduct,
  regGetProductCategory,
  regGetProductPurchase,
  regGetProductPurchaseCheck,
  regGetReturnProductPurchase,
  regGetSupplier,
  regProduct,
  regProductPurchase,
  regSupplier,
} from "../../../../api";
import { HintInfo, formatDateUpgraded } from "../../../../utils/function";
import SearchBox from "../../../../ttcomponents/SearchBox/SearchBox";
import { IPagination } from "../../../../interface/Ipagintion";
import PaginationA from "../../../../ttcomponents/Pagination/PaginationA";
import { IProductResponse, Product } from "../../../../interface/IProduct";
import { DetailCustome } from "../../../../public/svg/DetailCustome";
import SupplierSelect from "../../../../ttcomponents/Supplier/SupplierSelect";
import { ICategory } from "../../../../interface/IProductCategory";
import {
  Cancellation,
  Purchase,
  Receipt,
} from "../../../../public/svg/ProductOrder";
import {
  IProductProcurementData,
  IProductProcurementOrderData,
} from "../../../../interface/IProductProcurement";
import { notifications } from "@mantine/notifications";
import {
  IDeliveryData,
  IDeliveryPickData,
} from "../../../../interface/IDeliveryProduct";
interface ComponentInterface {
  is_load_str: string;
}

export default function PickingOutBoundProductIndex({
  is_load_str,
}: ComponentInterface) {
  // useIntl for multiple language
  const intl = useIntl();
  // Edit modal status
  const [openedFrom, { open: openFrom, close: closeFrom }] =
    useDisclosure(false);

  // return the goods
  const [returnOpened, { toggle: openReturnOpened, close: closeReturnOpened }] =
    useDisclosure(false);

  // contact modal status
  const [
    ProductProcurementShow,
    { open: openProductProcurementShow, close: colseProductProcurementShow },
  ] = useDisclosure(false);

  const [loading, setLoading] = useState(false);
  // Form type
  const [formType, setFormType] = useState("create");
  const [currentRowInfo, setCurrentRowInfo] = useState<
    IDeliveryPickData | undefined
  >();
  const [productCategory, setProductCategory] = useState<ICategory[]>([]);
  const [returnNotes, setReturnNotes] = useState("");

  // define the supplier list
  const [productProcurementList, setProductProcurementList] =
    useState<IDeliveryData>({
      total: 0,
      data: [], // Use the data property from the first item in the mock data array
    });

  const [pagination, setPagination] = useState<IPagination>({
    search: "",
    page: 1,
    pagesize: 10,
    sortBy: "",
    sortOrder: "asc",
    query: {},
  });
  // Receive the callback return data, search for content
  const ReceiveSearchBack = (value: string | number) => {
    setPagination((prevpagination) => ({
      ...prevpagination,
      search: value,
      page: 0,
      query: {},
    }));
  };
  const ReceiveSupplierBack = (value: any) => {
    if (value.type == "supplier") {
      setPagination((prevpagination) => {
        const { query, ...rest } = prevpagination; // Extract the attributes other than Query to the REST
        if (value.value == "") {
          // If value.value exists, delete the support
          delete query.supplier_id;
        } else {
          query.supplier_id = value.value;
        }

        return {
          ...rest,
          page: 0, // Keep other attributes in REST
          query: query, // Set the modified query
        };
      });
    }
  };

  //Pagling recovery trigger update Terrament recovery trigger update
  const ReceivePaginationABack = (page: number) => {
    setPagination((prevpagination) => ({
      ...prevpagination,
      page: page,
    }));
  };
  // set search value
  const [search, setSearch] = useState("");

  // Define an asynchronous function ajaxcurrenTlist to send AJAX request to obtain the list of suppliers
  const ajaxCurrentList = async () => {
    console.log(is_load_str);
    if (is_load_str === "pick") {
      // setLoading(true); // Set the loading status to TRUE, indicating that the data is loading
      let searchStr = {};
      // Check whether there are search conditions, if so, build a search parameter object
      if (search) {
        searchStr = {
          search: search,
        };
      }
      setLoading(true); // After the data is loaded, set the loading status to false
      // Send GET requests to get supplier data
      const responseProductProcurement = await regGetPickingOutBound(
        pagination,
        "GET"
      );
      setLoading(false); // After the data is loaded, set the loading status to false
      if (responseProductProcurement.data.code === 200) {
        setProductProcurementList(responseProductProcurement.data.data); // 如果响应状态码为200，将供应商列表更新为响应数据中的数据
      }
    }
  };
  // Use the USEEFFECT hook. When Search changes, call the AjaxCurrenTList function
  useEffect(() => {
    ajaxCurrentList();
  }, [pagination]);

  // Edit supplier
  const handleEdit = async (row: IDeliveryPickData) => {
    setFormType("edit");
    openProductProcurementShow();
    setCurrentRowInfo(row);
  };

  // Delete supplier
  const handleDel = (row: IDeliveryPickData) => {
    modals.openConfirmModal({
      title: "Delete this row",
      centered: true,
      children: (
        <Text size="sm" mt={12}>
          Supplier Name: {row.supplier_name}
        </Text>
      ),
      labels: {
        confirm: intl.formatMessage({
          id: "confirm_delete",
          defaultMessage: "Confirm Delete",
        }),
        cancel: intl.formatMessage({
          id: "cancel_delete",
          defaultMessage: "Cancel Delete",
        }),
      },
      confirmProps: { color: "red" },
      onConfirm: () => delSupplierInfo(row.picking_id),
    });
  };

  // Delete supplier info to backend
  const delSupplierInfo = async (id: string) => {
    const productResult = await regProductPurchase(
      { order_id: id, confirm_deletion: "no" },
      "DELETE"
    );
    HintInfo(productResult.data);
    if (productResult.data.code == 200) ajaxCurrentList();
  };

  // Create supplier
  const handleCallback = async () => {
    openProductProcurementShow();
    setCurrentRowInfo(undefined);
  };

  //  Review this order
  const reviewFuction = (row: IDeliveryPickData) => {
    modals.openConfirmModal({
      title: "Select an option",
      centered: true,
      children: (
        <Text size="sm">
          You can choose to enter the warehouse after review or return the
          goods.
        </Text>
      ),
      labels: { confirm: "Enter the warehouse", cancel: "Return the goods" },
      confirmProps: { color: "green" },
      cancelProps: { color: "red" },
      onCancel: () => {
        setCurrentRowInfo(row);
        openReturnOpened();
      },
      onConfirm: () => {
        examinationPassed(row);
      },
    });
  };
  // examination passed
  const examinationPassed = async (row: IDeliveryPickData) => {
    const examinationPassedResult = await regGetProductPurchaseCheck(
      { order_id: row.picking_id, flag: 1 },
      "PUT"
    );
    HintInfo(examinationPassedResult.data);
    if (examinationPassedResult.data.code == 200) ajaxCurrentList();
    setCurrentRowInfo(undefined);
  };

  // Returntreatment
  const returnTreatment = async (row: IDeliveryPickData) => {
    setCurrentRowInfo(row);
    // if (row.flag != 2) {
    //     notifications.show({
    //         color: "yellow",
    //         icon: <IconX />,
    //         message: 'This order cannot be returned',
    //     });
    // } else {
    //     openReturnOpened()
    // }
  };

  const rows = productProcurementList.data.map((row) => {
    let flagStr: any, statusStr: any;

    return (
      <tr className="border-bt-2 my-10 ">
        <>
          {" "}
          <td> {row.product_name} </td>
          <td> ASC </td>
          <td> {row.total_price ? row.total_price : "-"} </td>
          <td>
            {" "}
            {row.picking_out_bound_quantity
              ? row.picking_out_bound_quantity
              : "-"}{" "}
          </td>
          <td>
            {" "}
            {row.out_bound_date
              ? formatDateUpgraded(new Date(row.out_bound_date), "YYYY-MM-DD")
              : "-"}{" "}
          </td>
          <td>
            {" "}
            <Badge
              color={
                row.type == "task"
                  ? "blue"
                  : row.type == "order"
                  ? "green"
                  : "red"
              }
            >
              {" "}
              {row.type}
            </Badge>{" "}
          </td>
          <td>
            {" "}
            {row.name ? (
              row.name
            ) : (
              <Text c="#ccc">The order is missing</Text>
            )}{" "}
          </td>
          <td> {row.unit ? row.unit : "-"} </td>
          <td>
            <Group spacing={0} position="center">
              {/* <ActionIcon onClick={() => handleEdit(row)}>
                                <Tooltip label="Edit">
                                    <IconEdit size="1.2rem" stroke={1.5} />
                                </Tooltip>
                            </ActionIcon> */}

              {/* <ActionIcon onClick={() => reviewFuction(row)}>
                                <Tooltip label="Edit">
                                    <Receipt />
                                </Tooltip>
                            </ActionIcon>

                            <ActionIcon onClick={() => returnTreatment(row)}>
                                <Tooltip label="Edit">
                                    <Cancellation />
                                </Tooltip>
                            </ActionIcon>

                            <ActionIcon onClick={() => handleDel(row)}>
                                <Tooltip label="Delete">
                                    <IconTrash size="1.2rem" stroke={1.5} color="red" />
                                </Tooltip>
                            </ActionIcon> */}
            </Group>
          </td>
        </>
      </tr>
    );
  });
  const closeEditFunction = (type: number) => {
    closeFrom();
    openReturnOpened();
    colseProductProcurementShow();
    if (type == 2) {
      ajaxCurrentList();
    }
  };

  function setSelectValueFunction(value: string | null): void {}
  // list all the suppliers
  return (
    <Box>
      <LoadingOverlay visible={loading} overlayBlur={2} />

      <table className="table_custome text-center">
        <tr className="table_th">
          <th>
            {intl.formatMessage({
              id: "Product Name",
              defaultMessage: "Product Name",
            })}
          </th>
          <th>
            {intl.formatMessage({
              id: "Supplier",
              defaultMessage: "Supplier",
            })}
          </th>
          <th>
            {intl.formatMessage({
              id: "Total Price",
              defaultMessage: "Total Price",
            })}
          </th>
          <th>
            {intl.formatMessage({
              id: "Picking Out Bound Quantity",
              defaultMessage: "Picking Out Bound Quantity",
            })}
          </th>
          <th>
            {intl.formatMessage({
              id: "Out Bound Date",
              defaultMessage: "Out Bound Date",
            })}
          </th>
          <th>
            {intl.formatMessage({
              id: "Type",
              defaultMessage: "Type",
            })}
          </th>
          <th>
            {intl.formatMessage({
              id: "Receiver",
              defaultMessage: "Receiver",
            })}
          </th>
          <th>
            {intl.formatMessage({
              id: "Unit",
              defaultMessage: "Unit",
            })}
          </th>
          {/* <th>
                      {intl.formatMessage({
                        id: "Option",
                        defaultMessage: "Option",
                      })}
                    </th> */}
        </tr>

        {rows}
      </table>

      <PaginationA
        callBack={ReceivePaginationABack}
        total_count={productProcurementList.total as number}
        pagesize={pagination.pagesize}
        page={pagination.page}
      />

      <Modal
        opened={openedFrom}
        onClose={closeFrom}
        title={formType === "create" ? "Create Product" : "Edit Supplier"}
        centered={true}
        size="60%"
        padding="md"
        radius={12}
      >
        {/* <ProductForm productCategory={productCategory} productItem={currentRowInfo} closeFucntion={closeEditFunction} /> */}
      </Modal>

      <Modal
        opened={ProductProcurementShow}
        onClose={colseProductProcurementShow}
        title=" Supplier Contact"
        // centered={true}
        size="60%"
        padding="md"
        radius={12}
      >
        {/* <SupplierContactIndex supplier={currentRowInfo}  /> */}
      </Modal>
      {/* Quick purchase order */}

      <Modal
        opened={ProductProcurementShow}
        onClose={colseProductProcurementShow}
        title=" Quick purchase "
        // centered={true}
        size="60%"
        padding="md"
        radius={12}
      >
        {/* <ProductProcurementFrom unit_price_prohibit_edit={false} additional_information={currentRowInfo}  closeFucntion={closeEditFunction} /> */}
      </Modal>
      <Modal
        opened={returnOpened}
        withCloseButton
        onClose={closeReturnOpened}
        size="xl"
        centered={true}
        radius="md"
      >
        {/* <ReturnProductOrderFrom  type={false} additional_information={currentRowInfo}  closeFucntion={closeEditFunction} /> */}
        {/* <Textarea value={returnNotes} onChange={(event) => setReturnNotes(event.currentTarget.value)} />;
                    <Button onClick={closeReturnOpened}>Subscribe</Button> */}
      </Modal>
    </Box>
  );
}
