import {
  Button,
  PaperProps,
  Box,
  ActionIcon,
  Text,
  useMantineColorScheme,
  Container,
  Paper,
  Grid,
} from "@mantine/core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  IconArrowLeft,
  IconSun,
  IconMoonStars,
  IconCheck,
  IconX,
} from "@tabler/icons-react";
import { Outlet, Route, Routes, useNavigate } from "react-router-dom";
import {
  Navbar,
  Group,
  Code,
  ScrollArea,
  createStyles,
  rem,
} from "@mantine/core";
import { NavbarNested } from "../../ttcomponents/NavbarNested/NavbarNested";
import Dashboard from "../dashboard/dashboard";
import {
  AppShell,
  Header,
  Footer,
  Aside,
  MediaQuery,
  Burger,
  useMantineTheme,
} from "@mantine/core";
import { HeaderMiddle } from "../../ttcomponents/Header/HeaderMiddle";
import localstorageUnits from "../../utils/localstorageUnits";
import ProjectsManage from "../projects/projects_manage";
import ProjectStatus from "../project_status/project_status";
import ProjectName from "../assignments/order_name/order_name";
import OrderName from "../assignments/order_name/order_name";
import ProjectCreate from "../assignments/assignments/create_assignments";
import ProfileSettings from "../core/profiles/profile_settings";
import TimeTrack from "../assignments/time_sheet/home/timeTrack";
import TaskCategory from "../projects/task_category/task_category_mange";
import TaskOnly from "../projects/task_only/task_only";
import CustomerCategory from "../customer_category/customer_category_mange";
import Reports from "../reports/employeesReports/reports";
import ReportsAll from "../reports/employeesReports/reportsAll";
import ProjectTableWithReports from "../reports/projectReport/projectTableWithReports";
import ReportsProjectAll from "../reports/projectReport/reports_project";
import Rrports from "../reports/report/report";
import ReportsFilter from "../reports/report/report";
import memoryUtils from "../../utils/memoryUtils";
import { notifications } from "@mantine/notifications";
import Login from "../core/login/login";
import FilterReportStastic from "../reports/report_all/filter_statistics";
import TaskProject from "../projects/task_only/task_only_project/task_only_project";
import UserReportStastic from "../reports/report_all/user_statistics";
import ProjectReportStastic from "../reports/report_all/project_statistics";
import ReportCustom from "../reports/report_custom/report_custome";
import LeaveRequest from "../leave_request/apply_holidays/leaveIndex";
import LeaveManger from "../leave_request/leave_manger/leave_manger";
import SickLeave from "../leave_request/sick_leave";
import ApprovalTimeTracker from "../time_master/approval/approval";
import SupplierIndex from "../inventory/supplier/supplier_index";
import ProductIndex from "../inventory/product/product_index";
import ProductProcurementIndex from "../inventory/purchasing/purchasing";
import ReturnReasonIndex from "../inventory/return_product/return_reason/return_reason";
import StoreHouseIndex from "../inventory/storehouse/store_house_index";
import DeliveredManagementIndex from "../inventory/storehouse/delivered_management/delivered_management";
import RecyclingCompanyIndex from "../inventory/recycling_ company/recycling_ company_index";
import ProductRequirmentsIndex from "../inventory/customer_order/product_procurement/product_requirments";
import OrderOnlyIndex from "../projects/order_only/order_only_index";
import OrderCreateIndex from "../projects/order_create";
import OnBoarding from "../boarding/on_boarding/on_boarding";
import NewBoarding from "../boarding/new_boarding/new_boarding";
import OffBoarding from "../boarding/off_bording/off_boarding";
import BoardingArchive from "../boarding/boarding_archive/boarding_archive";
import CustomAttrbutesBoarding from "../boarding/custom_attributes/custom_attrbutes";
import UserMg from "../core/user/user";
import UserChangeLogs from "../core/user/user_change_log";
import UserRole from "../core/user/user_role/user_role";
import CustomerStack from "../assignments/customer/customer";
import ContactRoleIndex from "../assignments/contact role/contact_role";
import Settings from "../core/settings/settings";
import CreateCustomer from "../assignments/customer/customer_create";
import CreateAssignments from "../assignments/assignments/create_assignments";
import EmployeeRole from "../assignments/permission/permission_role/permission_role";
import AssignmentOverview from "../assignments/assignments";
import PermissionIdentifier from "../assignments/permission/permission_identifier/permission_identifier";
import PermissionAction from "../assignments/permission/permission_action/permission_action";
import OvertimeReport from "../report/overtime_report";
import EmployeeOvertimeReport from "../report/overtime_report/employee";
import EmployeeTimeReportRecord from "../report/employee_time_record/employee_time_record";
import EmployeeNoTimeReportRecord from "../report/employee_time_record/employee_no_time_record";
import StudentTimeReportRecord from "../report/employee_time_record/student_time_report";
import FejpTimeReportRecord from "../report/employee_time_record/fejp_time_report";
import { useDispatch } from "react-redux";
import { setMenuFromApi } from "../../state_management/features/menu/menuSlice";
import CorePermissionIndex from "../core/settings/permission_action/_core";
import LeaveCotegory from "../leave_request/leave_category";
import LogSettings from "../core/settings/log/log_settings";
export default function Admin(props: PaperProps) {
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  const [routePath, setRoutePath] = useState("");
  // Set the top searche top search
  const [searchValue, setSearchValue] = useState("");
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const callBread = () => {
    let storagePath = localstorageUnits.getPath();
    if (storagePath && Object.keys(storagePath).length > 0) {
      setRoutePath(storagePath.currentUrl);
    }
  };
  // Flows to refresh the demo, don’t delete it, if you do this in the future, you can learn from
  const containerRef = useRef<HTMLDivElement>(null);
  const handleScroll = () => {
    const container = containerRef.current;
    if (container) {
      const { scrollTop, clientHeight, scrollHeight } = container;
      if (scrollTop + clientHeight === scrollHeight) {
        setIsScrolledToBottom(true);
      } else {
        setIsScrolledToBottom(false);
      }
    }
  };
  
  const setHeaderMiddleCallback = () => {
    setIsScrolledToBottom(false);
  }

 
  const setSearchValueCallback = useCallback((value: React.SetStateAction<string>) => {
    setSearchValue(value);
  }, [setSearchValue]);

  useEffect(() => {
    if (isScrolledToBottom) {
      setIsScrolledToBottom(false);
    }
  }, [isScrolledToBottom]);

  
const fetchMenuData = () => {
  const menuData = localstorageUnits.getMenu();
  if (!menuData) {
    setTimeout(fetchMenuData, 2000); // 延迟2秒后再次尝试获取
  } else {
    dispatch(setMenuFromApi(menuData));
  }
};

// set menu list
  const dispatch = useDispatch();
  useEffect(() => {
      fetchMenuData();
  }, []);

  
  const navigate = useNavigate();
  const userExists = memoryUtils.user;
  if (!userExists.username) {
    navigate('/login');
    return <Login />;
  }
  
  return (
    <>
      <Grid gutter={0} style={{ display: 'flex' }}>
        <Grid.Col span="content" style={{ height: "100vh" }} >
          <NavbarNested  callback={callBread} />
        </Grid.Col>
        <Grid.Col style={{ flex: 1, overflowX: "hidden", }} >
          <Box style={{ overflow: "auto", height: "100vh" }} className="pt-15  scrollable-content-table" onScroll={handleScroll} ref={containerRef}>
            <div >
              <Paper>
                <HeaderMiddle  sendCurrentPath={routePath} searchValue={searchValue} searchValueCallback={setSearchValue} callback={setSearchValueCallback} />
                <Box style={{ maxHeight: "calc(100% - 48px)", overflow: "auto" }}>
                  <Outlet />
                </Box>
              </Paper></div>
          </Box>
        </Grid.Col>
      </Grid>
    </>
  );
}
