import { Box, Button, Card, Divider, Flex, Grid, Group, LoadingOverlay, Menu, ScrollArea, Text } from '@mantine/core'
import { FC, useEffect, useState } from 'react'
import IconCardTitle from '../../ttcomponents/IconCardTitle/TableTextInputTitle'
import WordTranslationComponent from '../../utils/language_pack/words'
import { IconDotsVertical, IconHomeUp, IconRefresh } from '@tabler/icons-react'
import { IconHomeDown } from '@tabler/icons-react'
import { ReactComponent as Alarm } from '../../assets/svg/alarm.svg'
import { reqPostSign, reqPostSignDel, reqPostSignShowData } from '../../api'
import { HintInfo, HintInfoCatchError, HintInfoError } from '../../utils/function'
import memoryUtils from '../../utils/memoryUtils'

interface clock_in_outProps { }

type BreakLists = {
  date: string
  end_time: string
  id: number
  start_time: string
  time: string
}

export const ClockInOut: FC<clock_in_outProps> = ({ }) => {
  // Get translation public configuration information
  const word_translation = WordTranslationComponent()

  const userExists = memoryUtils.user
  const [loading, setLoading] = useState(false)

  const [blockIn, setBlockIn] = useState<string>('')
  const [blockOut, setBlockOut] = useState<string>('')
  const [breakLists, setBreakLists] = useState<BreakLists[]>([])

  const [curDate, setCurDate] = useState<Date>()

  useEffect(() => {
    getSignShowData()
    const timer = setInterval(() => {
      const date = new Date()
      setCurDate(date)
    }, 1000)
    return () => {
      clearInterval(timer)
    }
  }, [])

  const handleBlockIn = async (type:string) => {
    try {
      setLoading(true)
      const response = await reqPostSign({type:type}, 'POST')
      const result = response.data
      // Display information based on the response
      HintInfo(response.data)
      if (result.code === 200) {
        getSignShowData()
      } else {
        HintInfoError(result.msg)
      }
    } catch (error) {
      // Log an error if there is an exception during the request
      HintInfoCatchError()
    } finally {
      setLoading(false)
    }
  }

  const handleBlockOut = async () => {
    try {
      setLoading(true)
      const response = await reqPostSignDel({ user_id: userExists.id_user }, 'POST')
      const result = response.data
      // Display information based on the response
      HintInfo(response.data)
      if (result.code === 200) {
        getSignShowData()
      } else {
        HintInfoError(result.msg)
      }
    } catch (error) {
      // Log an error if there is an exception during the request
      HintInfoCatchError()
    } finally {
      setLoading(false)
    }
  }

  const getSignShowData = async () => {
    try {
      setLoading(true)
      const response = await reqPostSignShowData({}, 'POST')
      let result = response.data

      if (result.code === 200) {
        result = result.data
        setBreakLists(result.data)
        setBlockIn(result.in)
        setBlockOut(result.out)
      }
    } catch (error) {
      HintInfoError(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Card px={0} withBorder w={320} mih={330} radius="md" shadow="sm">
      <LoadingOverlay visible={loading} overlayBlur={2} />

      <Grid>
        <Grid.Col span={9} mah={38}>
          <IconCardTitle icon_l={<Alarm color="#989898" />} title={word_translation.clock_in_out} />
        </Grid.Col>
        <Grid.Col span="auto">
          <Group
            spacing={'xs'}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginRight: '0.1rem'
            }}
          >
            <IconRefresh color="#989898" onClick={getSignShowData} size="1rem" style={{ cursor: 'pointer' }} />
            <Menu
              width={120}
              position="bottom-end"
              transitionProps={{ transition: 'pop-top-right' }}
              // onClose={() => setUserMenuOpened(false)}
              // onOpen={() => setUserMenuOpened(true)}
              withinPortal
              shadow="md"
            >
              <Menu.Target>
                <IconDotsVertical color="#989898" size="1rem" />
              </Menu.Target>
            </Menu>
          </Group>
        </Grid.Col>
      </Grid>
      <Divider my="sm" />

      <Group px={16} pb={12} position="apart" spacing={0}>
        <Flex direction="column" w={100}>
          <Group align="start" spacing={4}>
            <IconHomeUp color="#989898" size={'1rem'} />
            <Box c={'#34373b'} fw={600} fz={13}>
              <Text>{word_translation.clock_in}:</Text>
              <Text ta={'right'}>{blockIn}</Text>
            </Box>
          </Group>

          <Group align="start" spacing={4} mt={12}>
            <IconHomeDown color="#989898" size={'1rem'} />
            <Box c={'#34373b'} fw={600} fz={13}>
              <Text>{word_translation.clock_out}:</Text>
              <Text ta={'right'}>{blockOut}</Text>
            </Box>
          </Group>
        </Flex>
        <Flex style={{ flex: '1' }} direction="column" justify={'center'}>
          <Flex gap={0} fw={700} justify={'center'}>
            <Text fz={50} lh={0.9}>
              {curDate ? `${prefixZero(curDate.getHours())}:${prefixZero(curDate.getMinutes())}` : '00:00'}
            </Text>
            <Text mt={16}>:{curDate ? prefixZero(curDate.getSeconds()) : "00"}</Text>
          </Flex>
          <Flex justify={'space-around'} mt={8}>
            {/* 签入按钮 */}
            <Button
              bg={'#40c057'}
              onClick={()=>handleBlockIn('1')}
              styles={{
                leftIcon: { marginRight: '5px' },
                root: {
                  '&:hover': {
                    background: '#3cb352'
                  }
                }
              }}
            >
              <IconHomeUp size={'2rem'} />
            </Button>
            {/* 签出按钮 */}
            <Button
              bg={'#f7454a'}
              styles={{
                leftIcon: { marginRight: '5px' },
                root: {
                  '&:hover': {
                    background: '#eb4f4f'
                  }
                }
              }}
            >
              <IconHomeDown size={'2rem'} onClick={()=>handleBlockIn('2')} />
            </Button>
          </Flex>
        </Flex>
      </Group>
      <Box p={8} pr={16} pb={0} style={{ borderTop: '1px solid rgb(222, 226, 230)' }}>
        <Text fz={13} fw={600}>
          Breaks:
        </Text>

        <ScrollArea h={108} pl={20} fz={12} fw={550} mt={4}>
          {breakLists.length > 0 &&
            breakLists.map((b, index) => (
              <Group position="apart" key={b.id}>
                <Group spacing={6}>
                  <Text>{index + 1}.</Text>
                  <Text>
                    {b.start_time} - {b.end_time}
                  </Text>
                </Group>
                <Text>{b.time}</Text>
              </Group>
            ))}
        </ScrollArea>
      </Box>
    </Card>
  )
}

function prefixZero(n: number) {
  return n.toString().padStart(2, '0')
}
