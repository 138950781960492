import { createStyles, Card, Text, SimpleGrid, UnstyledButton, Anchor, Group, rem, Divider, Box } from '@mantine/core'
import {
  IconSettings,
  IconReportSearch,
  IconUserCircle,
  IconTruck,
  IconListCheck,
  IconHourglass,
  IconDoorExit
} from '@tabler/icons-react'
import { useNavigate } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import localstorageUnits from '../../utils/localstorageUnits'
import { IconZeppelin } from '@tabler/icons-react'
import CheckPermissionTools from '../../utils/permission'


const useStyles = createStyles(theme => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0]
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 700
  },

  item: {
    textAlign: 'center',
    borderRadius: theme.radius.md,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    transition: 'box-shadow 150ms ease, transform 100ms ease',

    '&:hover': {
      boxShadow: theme.shadows.md,
      transform: 'scale(1.05)'
    }
  },
  item_disabled: {
    textAlign: 'center',
    borderRadius: theme.radius.md,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    transition: 'box-shadow 150ms ease, transform 100ms ease',

    '&:hover': {
      cursor: 'auto'
    }
  }
}))

export function Services() {
  
  const { classes, theme } = useStyles()
  const navigate = useNavigate()
  const Perm = CheckPermissionTools()
  function handNolinksMenu(flink: string, activeIndex: string): void {
    localstorageUnits.savePath({
      currentUrl: activeIndex,
      currentActiveIndex: activeIndex,
      currentPreActiveIndex: activeIndex
    })
    navigate(flink)
  }

  const serviceList = [
    { title: 'TimeTracker',permission:Perm.time_tracker_read, icon: IconHourglass, color: '#7c54f2', link:'/timetracker', activeIndex: 'TimeTracker' },
    { title: 'Reports', permission:Perm.employee_overtime_report_read, icon: IconReportSearch, color: '#4c6ef5', link: '/employee_overtime', activeIndex: 'Reports' },
    { title: 'Profile',permission:true,  icon: IconUserCircle, color: '#228be6', link: '/profile', activeIndex: 'Profile' },
    {
      title: 'Assignments',
      icon: IconListCheck,
      permission:Perm.assignment_read, 
      color: '#40c057',
      link: '/assignment_overview',
      activeIndex: 'Assignments'
    },
    { title: 'Customer',permission:Perm.customer_read,  icon: IconUserCircle, color: '#12b886', link: '/customer', activeIndex: 'Customer' },
    // { title: 'TimeMaster', icon: IconBrandMastercard, color: '#12b886', link: '#', activeIndex: 'TimeTracker' },
    {
      title: 'On/Off-Boarding',
      permission:Perm.boarding_update || Perm.boarding_delete|| Perm.boarding_read, 
      icon: IconZeppelin,
      color: '#15aabf',
      // link: '/on_boarding',
      link: '/new_boarding',
      activeIndex: 'On/Off-Boarding'
    },
    { title: 'Leave-Request',
    permission:Perm.leave_request_read || Perm.leader_leave_create,  icon: IconDoorExit, color: '#e64880', link: '/leave', activeIndex: 'Leave-Request' },
    {
      title: 'Inventory',
      icon: IconTruck,
      // color: '#fa5252',
      //  link: '/product_requirments',
      color: 'gray',
      link: '#',
      activeIndex: 'Inventory'
    },
    { title: 'Settings', permission:Perm.time_tracker_read, icon: IconSettings, color: '#fd7e14', link: '/settings', activeIndex: 'Settings' }
  ]
  const items = serviceList.map(item => (
    <UnstyledButton
      key={item.title}
      className={ classes.item}
      py={8}
      w={116}
    >
      <Text onClick={item.permission ? () => handNolinksMenu(`${item.link}`, `${item.activeIndex}`) :()=>{}}>
        <item.icon color={ item.permission ? item.color : 'gray' } size="2rem" />
      </Text>
      <Text size="xs" mt={2}>
        <FormattedMessage id={item.title} defaultMessage={item.title} />
      </Text>
    </UnstyledButton>
  ))

  return (
    <Card withBorder radius="md" shadow="sm" bg="#f8f9fa" p={16} h={328}>
      <Group position="apart">
        <Text size={14} fw={700} color="customize-gray.4">
          <FormattedMessage id="quick_access" defaultMessage="Quick Access" />
        </Text>
        {/* <Group spacing={4}>
          <IconRefresh
            color="#989898"
            cursor={'pointer'}
            // onClick={refreshLiveTracking}
            size="1.2rem"
          />
          <IconDotsVertical color="#989898" cursor={'pointer'} size="1.2rem" />
        </Group> */}
      </Group>
      <SimpleGrid cols={3} mt="md">
        {items}
      </SimpleGrid>
    </Card>
  )
}
