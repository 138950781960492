import { ActionIcon,Text, Avatar, Box, Grid, Paper, Tooltip, Progress, Group, LoadingOverlay, ScrollArea, Code } from "@mantine/core";
import PageTitle from "../../../ttcomponents/PageTitle/PageTitle";
import WordTranslationComponent from "../../../utils/language_pack/words";
import PaginationA from "../../../ttcomponents/Pagination/PaginationA";
import { IconAdjustmentsHorizontal, IconArrowBigDownLine, IconArrowsMaximize, IconChecklist, IconEdit, IconEye, IconPencil, IconTrash } from "@tabler/icons-react";
import './css/index.css'
import UniversalButton from "../../../ttcomponents/Button/UniversalButton";
import AssignmentProjectRow from "./project/project_item_row";
import AssignmentOrdertRow from "./order/order_item_row";
import AssignmentTaskRow from "./task/task_item_row";
import AssignmentListFilter from "./assignment_list_filter_modal";
import { useDisclosure } from "@mantine/hooks";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../state_management/store";
import { IPagination } from "../../../interface/Ipagintion";
import { regGetAssignments } from "../../../api";
import { AssignmentsInterface } from "../../../interface/Iprojects";
import { setExpandStatus } from "../../../state_management/features/assignments_expand_status/assignmentsExpandStatusSlice";
import { requestProject } from "../../../api/index_v2";
import React from "react";
import { setRefreshStatus } from "../../../state_management/features/assignments_refresh_status/assignmentsRefreshStatusSlice";
import { ClientWarningHint } from "../../../utils/function";
import { isNumber } from "../../../utils/validataUnits";

export default function AssignmentOverview(){
  const dispatch = useDispatch();
    // Get translation public configuration information
  const word_translation = WordTranslationComponent();
  const [visible, toggle] = useDisclosure(false);
  // 获取redux store中SearchValue的值
  const SearchValue = useSelector((state:RootState)=>state.search.searchValue)
  
  // 获取redux store中refreshStatus的值
  const refreshStatus = useSelector((state:RootState)=>state.refreshStatus.refreshStatus)
  // 获取redux store中refreshStatus的值
  const ActionObj = useSelector((state:RootState)=>state.ActionObj.action_index)

  // 获取redux store中的expandStatus值
  const expandStatus = useSelector((state:RootState)=>state.expandStatus.expandStatus)
  
  // 定义字渲染的key 值 
  const [rand_number, setRandNumber] = useState(0);
  //打开过滤modal选项
  // const [openSelectFilterStatus, { open: openFilterModalFunction, close: closeFilterModalFunction }] =useDisclosure(false);
  const [openSelectFilterStatus, setOpenSelectFilterStatus] =useState<boolean>(false);

  const [assignmentListData, setAssignmentListData] = useState<AssignmentsInterface[]>([]);
  
  const [isExpanded, setIsExpanded] = useState(expandStatus);
  const [totalCount, setTotalCount] = useState<number>(0);
  //展开单个的记录
  const expandFunction =()=>{
    dispatch(setExpandStatus(!isExpanded));
    setIsExpanded(!isExpanded)
  }

  const [pagination, setPagination] = useState<IPagination>({
    search:'',
    page: 1,
    pagesize: 20,
    sortBy: "",
    sortOrder: "asc",
    status_ids: '2'
  });
  

  const getCurrentPageData = async () => {
    try {
      toggle.open();
  
      const response = await requestProject(pagination, "GET");
      const result = response.data;
  
      toggle.close();
  
      if (result.code === 200 ) {
        const targetType = ActionObj.type + '_id';
        
        const updatedDataIndex = result.data.data.findIndex((item: { [x: string]: any; type: string; }) => {
          // console.log('ceshi',item[targetType],item,ActionObj)
          const itemId = item[targetType];
          return item.type === ActionObj.type && itemId !== undefined && itemId.toString() == ActionObj.id.toString();
        });
        
        if (updatedDataIndex !== -1) {
          const updatedAssignmentListData = [...assignmentListData];
          updatedAssignmentListData[ActionObj.index as number] = result.data.data[updatedDataIndex];
          setAssignmentListData(updatedAssignmentListData);
        } else if (result.data.length === 0) {
          ClientWarningHint(word_translation.thats_all);
        }
      } else {
        console.error(result.msg);
      }
    } catch (error) {
      console.error('Error fetching user registrations:', error);
    } finally {
      
      // Set loading state to false regardless of success or failure
    }
  };
  
  

  // 获取assignment list
  const getAssignmentListFunction = async () => {
    // Set loading state to true while fetching data

    try {
      // Make a GET request to retrieve user registrations with pagination parameters
      toggle.open();
      const response = await requestProject(pagination, "GET");
      toggle.close();
      const result = response.data ;

      // Check if the response is successful and the data is not an array
      if (result.code === 200 && !Array.isArray(result.data)) {
        if(result.data.data.length == 0){
          // ClientWarningHint(word_translation.thats_all)
          return false
        }
        // Update the total count and user registration data in component state
        setAssignmentListData(prevData => [...prevData, ...result.data.data]);
        // 如果页码为1 则替换，不为1 则追加
        if(pagination.page == 1){
          setAssignmentListData(result.data.data);
        }

        setTotalCount(result.data.total);
      } else {
        // Log an error message if the response indicates an issue
        console.error(result.msg);
      }
    } catch (error) {
      // Log any errors that occur during the request
      console.error('Error fetching user registrations:', error);
    } finally {
      // Set loading state to false regardless of success or failure
    
    }
  }
  

const ReceiveSearchBack = (value: string | number) => {
    // 延迟一段时间后再执行清空数据和更新 pagination 状态
    setAssignmentListData([]);
    setPagination(prevPagination => ({
      ...prevPagination,
      search: value,
      page: 1,
    }));
  }
  
  // 在每次搜索值变化时执行 ReceiveSearchBack
  useEffect(() => {
    // 第一次加载时不执行
    if (!isInitialMount.current) {
      // 清空数据和更新 pagination 状态
      ReceiveSearchBack(SearchValue);
    }
  }, [SearchValue]);
  

  // // 在每次加载时执行
  // useEffect(() => {
  //   // 第一次加载时不执行
  //   if (!isInitialMount.current) {
  //     // 执行获取数据函数
  //     getAssignmentListFunction();
  //   }
  //   // 设置 isInitialMount 为 false，表示已经加载过一次了
  //   isInitialMount.current = false;
  // }, []);
  

 // Execute the useeffect when the first load is loaded.
 const isInitialMount = useRef(true);
 useEffect(() => {
   if (!isInitialMount.current) {
     ReceiveSearchBack(SearchValue);
   }
   isInitialMount.current = false;
 }, [SearchValue]);



useEffect(() => {
    if(refreshStatus){
      // getAssignmentListFunction();
      dispatch(setRefreshStatus(false))
      //请求本页数据 获取删除的索引  ActionIndex
      getCurrentPageData();
    }
}, [refreshStatus]);



useEffect(() => {
    getAssignmentListFunction();
}, [pagination,]);





    // 分页回调触发更新 分页回调触发更新
  const ReceivePaginationABack = (page: number) => {
    setPagination((prevpagination) => ({
      ...prevpagination,
      page: page,
    }));
  };


  const callBackListFuction = (value: any) => {
    // value && getAssignmentListFunction();
    if(isNumber(value)){
      setAssignmentListData(prev => prev.filter(item => item.project_id !== value))
    }
  };
  // &start_date=2023-11-01&end_date=2024-01-01&customer_ids=1,2,3&status_ids=1,2,3&mode_types=1,2,3
  const successCallbackFunction = (value: any) => {
    setAssignmentListData([])
    setPagination((prevpagination) => ({
      ...prevpagination,
      page:1,
      start_date:value.start_date,
      end_date:value.end_date,
      customer_ids:value.customer_ids,
      status_ids:value.status_ids,
      mode_types:value.mode_types,
    }));
  };
  const setOpenSelectFilterStatusFunction = () => {
    setOpenSelectFilterStatus(!openSelectFilterStatus)
  };

  const initFunction = () => {
    setOpenSelectFilterStatus(false)
  };

  
  
  const handleScroll = () => {
    const element = document.getElementById('ScrollableDiv');
    const scrollTop = element?.scrollTop ?? 0;
    const scrollHeight = element?.scrollHeight ?? 0;
    const clientHeight = element?.clientHeight ?? 0;
    // Check if scrolled to the bottom
    if (scrollTop + clientHeight === scrollHeight) {
      // loadMoreData();
      setPagination((prevPagination) => ({
        ...prevPagination,
        page: prevPagination.page+1,
      }));
    }
  };


  useEffect(() => {
    const scrollableDiv = document.getElementById('ScrollableDiv');
    
    // Check if the scrollable div is present in the DOM
    if (scrollableDiv) {
      scrollableDiv.addEventListener('scroll', handleScroll);
    }
    
    // Clean up the event listener to prevent memory leaks
    return () => {
      if (scrollableDiv) {
        scrollableDiv.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);


  return (
    <Paper px={0} pb={10} w={"100%"} className="filter_relative">
      <LoadingOverlay visible={visible} overlayBlur={2} />
      <PageTitle px={20} title={word_translation.assignment} />
      <Box px={20}>
        <Group className="assignment_btn" position="right" py={4}>
          {/* <UniversalButton btn_bg_color="#E7F5FF"  btn_icon={<IconArrowsMaximize  color="#228BE6"  size={16} />} btn_size={'xs'} callBack={()=>expandFunction()}  btn_text="Expand All" /> */}
          <UniversalButton btn_bg_color="#E7F5FF" btn_icon={<IconAdjustmentsHorizontal color="#228BE6" size={16} />} btn_size={'xs'} callBack={setOpenSelectFilterStatusFunction} btn_text=" Filter" />
        </Group>
      </Box>
      <Grid gutter={0} onClick={initFunction}>
        <Box id="ScrollableDiv" className="scrollable-content-xs" style={{ overflowY: 'auto', height: '82vh' }} w='100vw' >
          <table key={rand_number} className=" aa table_custome_2 text-center table_custome_spacing position_guanzhi" >
            <tbody>
              {assignmentListData.map((item, index) => {
                if (item.type === 'project') {
                  return (
                    <React.Fragment key={index}>
                      <AssignmentProjectRow currentIndex={index} callBackFuction={callBackListFuction} row={item} />
                    </React.Fragment>
                  )
                } else if (item.type === 'order') {
                  return (
                    <React.Fragment key={index}>
                      <AssignmentOrdertRow row={item} leve={1} /></React.Fragment>
                  )
                } else if (item.type === 'task') {
                  return (
                    <React.Fragment key={index}>
                      <AssignmentTaskRow row={item} leve={1} /></React.Fragment>
                   )
                }
              })}
            </tbody>
          </table>
        </Box>
      </Grid>
      <>
      </>
      {openSelectFilterStatus && <AssignmentListFilter pagination={pagination} is_load={openSelectFilterStatus} objectsUsed={word_translation.task} successCallback={(value) => successCallbackFunction(value)} closeFunction={() => { }} openFormStatus={openSelectFilterStatus} />}
    </Paper>

    )
}

