import { Card, Table } from "@mantine/core";
import { UserTimeRecord } from "../../../interface/Ireports";
import WordTranslationComponent from "../../../utils/language_pack/words";
import React from 'react';
import { ConvertTimeStringToDecimal, formatTimeHHSS } from "../../../utils/function";
interface ComponentInterface {
    userTimeRecord: UserTimeRecord[] | undefined; // Array of MonthlyTotal objects or undefined
    getUserProjectReport:(value:any)=>void; // Array of MonthlyTotal objects or undefined
}

export default function UserProject({ userTimeRecord ,getUserProjectReport}: ComponentInterface) {
    // Get translation public configuration information
    const word_translation = WordTranslationComponent();
    return (
        <Card bg='#eee'>
        <Table>
          <thead>
            <tr>
              <th> {word_translation.user}</th>
              <th> {word_translation.actual_time}</th>
            </tr>
          </thead>
          <tbody>
            {userTimeRecord?.map((item2, index2) => {
              return (
                <tr>
                  <td onClick={() => getUserProjectReport(item2.user_id)}> {item2.first_name} {item2.last_name}</td>
                  <td> {ConvertTimeStringToDecimal(formatTimeHHSS(item2?.total as string))} &nbsp; {word_translation.hour}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>

      </Card>
    )
}
