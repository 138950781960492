import { useIntl } from "react-intl";
import React from 'react';
import { Day } from "@mantine/dates";
const WordTranslationComponent  = () => {
  // Use the useIntl hook to access the intl object
  const intl = useIntl();
  const [word_translation, setWordTranslation] = React.useState({
    kw:intl.formatMessage({id: "kw",defaultMessage: "Week", }),
    role_name:intl.formatMessage({id: "role_name",defaultMessage: "Role name", }),
    role_name_template:intl.formatMessage({id: "role_name_template",defaultMessage: "Role permission template", }),
    user_role_desc:intl.formatMessage({id: "user_role_desc",defaultMessage: "Role Desc", }),
    user_role:intl.formatMessage({id: "User Role",defaultMessage: "User Role", }),
    delete_title:intl.formatMessage({id: "delete_title",defaultMessage: "Delete Confirmation", }),
    enable_disable:intl.formatMessage({id: "enable_disable",defaultMessage: "Enable Disable", }),
    enable:intl.formatMessage({id: "enable",defaultMessage: "Enable ", }),
    disable:intl.formatMessage({id: "disable",defaultMessage: "Disable ", }),
    confirm_delete:intl.formatMessage({id: "confirm_delete",defaultMessage: "Delete", }),
    cancel_delete:intl.formatMessage({id: "cancel_delete",defaultMessage: "Cancel ", }),
    option:intl.formatMessage({id: "option",defaultMessage: "Option", }),
    identifier_user_role:intl.formatMessage({id: "identifier_user_role",defaultMessage: "Role identifier", }),
    create_user_role:intl.formatMessage({id: "create_user_role",defaultMessage: "Create a new user role", }),
    update_user_role:intl.formatMessage({id: "update_user_role",defaultMessage: "Modify the role information", }),
    identifier_user_rosle:intl.formatMessage({id: "delete_title",defaultMessage: "Delete", }),
    create:intl.formatMessage({id: "Create",defaultMessage: "Add", }),
    delete_tips:intl.formatMessage({id: "delete_tips",defaultMessage: "Delete?", }),
    create_tips:intl.formatMessage({id: "create_tips",defaultMessage: "Create new?", }),
    drag_img_tips:intl.formatMessage({id: "drag_img_tips",defaultMessage: " Drag images here or click to select files", }),
    file_size_1mb_tips:intl.formatMessage({id: "file_size_1mb_tips",defaultMessage: "File should not exceed 1mb", }),
    edit_tips:intl.formatMessage({id: "edit_tips",defaultMessage: "Edit?", }),
    edit:intl.formatMessage({id: "edit",defaultMessage: "Edit", }),
    admin:intl.formatMessage({id: "admin",defaultMessage: "Admin", }),
    contract_start_time:intl.formatMessage({id: "contract_start_time",defaultMessage: "Contract Start Time", }),
    contract_end_time:intl.formatMessage({id: "contract_end_time",defaultMessage: "Contract End Time", }),
    
    costcenter_leader_permission:intl.formatMessage({id: "costcenter_leader_permission",defaultMessage: "CostCenter Leader Permission", }),
    preview_tips:intl.formatMessage({id: "preview_tips",defaultMessage: "Preview?", }),
    more_tips:intl.formatMessage({id: "more_tips",defaultMessage: "More actions", }),
    role_name_validata:intl.formatMessage({id: "role_name_validata",defaultMessage: "Role name must be 2-100 characters long", }),
    role_original_name_not_empty:intl.formatMessage({id: "role_original_name_not_empty",defaultMessage: "Role name must be 2-100 characters long", }),
    cannot_modified_after_confirmation:intl.formatMessage({id: "cannot_modified_after_confirmation",defaultMessage: "It cannot be modified after confirmation", }),
    profile_settings:intl.formatMessage({id: "profile_settings",defaultMessage: "PROFILE SETTINGS", }),
    create_attribute:intl.formatMessage({id: "create_attribute",defaultMessage: "Create Attribute", }),
    attribute_key:intl.formatMessage({id: "attribute_key",defaultMessage: "Name" }),
    description:intl.formatMessage({id: "description",defaultMessage: "Description", }),
    attribute_type:intl.formatMessage({id: "attribute_type",defaultMessage: "Type", }),
    query_permission:intl.formatMessage({id: "query_permission",defaultMessage: "Query Permission", }),
    default_value:intl.formatMessage({id: "default_value",defaultMessage: "Default Value", }),
    preview:intl.formatMessage({id: "preview",defaultMessage: "Preview", }),
    attribute_type_name:intl.formatMessage({id: "attribute_type_name",defaultMessage: "Name", }),
    attribute_type_notation:intl.formatMessage({id: "attribute_type_notation",defaultMessage: "Notation", }),
    create_attribute_type:intl.formatMessage({id: "create_attribute_type",defaultMessage: "Create Type", }),
    attribute_type_notation_validata:intl.formatMessage({id: "attribute_type_notation_validata",defaultMessage: "Notation must be 2-100 characters long", }),
    attribute_type_name_validata:intl.formatMessage({id: "attribute_type_name_validata",defaultMessage: "Attribute type name cannot be empty", }),
    reset_btn:intl.formatMessage({id: "reset",defaultMessage: "Reset", }),
    creator:intl.formatMessage({id: "creator",defaultMessage: "Creator", }),
    modifier:intl.formatMessage({id: "modifier",defaultMessage: "Modifier", }),
    created_at:intl.formatMessage({id: "created_at",defaultMessage: "Created At", }),
    attribute_type_list:intl.formatMessage({id: "attribute_type_list",defaultMessage: "Attribute list", }),
    choose_attribute_type:intl.formatMessage({id: "choose_attribute_type",defaultMessage: "Choose a attribute type", }),
    attribute_key_validata:intl.formatMessage({id: "attribute_key_validata",defaultMessage: "Attribute name must be 2-100 characters long", }),
    general_informations:intl.formatMessage({id: "general_informations",defaultMessage:"General Informations", }),
    personal_no:intl.formatMessage({id: "personal_no",defaultMessage: "Stuff Number", }),
    number:intl.formatMessage({id: "number",defaultMessage: " Number", }),
    personal_no_2:intl.formatMessage({id: "personal_no_2",defaultMessage: "Personal Number", }),
    first_name:intl.formatMessage({id: "first_name",defaultMessage: "First Name", }),
    last_name:intl.formatMessage({id: "last_name",defaultMessage: "First Name", }),
    surname:intl.formatMessage({id: "surname",defaultMessage: "Surname", }),
    vacation_day_year:intl.formatMessage({id: "vacation_day_year",defaultMessage: "Vacation Days", }),
    update_reason:intl.formatMessage({id: "update_reason",defaultMessage: "Please enter the reason for the modification (optional)", }),
    work_hours_week:intl.formatMessage({id: "work_hours_week",defaultMessage: "Hours / Week", }),
    working_days_week:intl.formatMessage({id: "working_days_week",defaultMessage: "Work Days / Week", }),
    email:intl.formatMessage({id: "email",defaultMessage: "Email", }),
    email_suffix:intl.formatMessage({id: "email_suffix",defaultMessage: "Email Suffix", }),
    domains:intl.formatMessage({id: "domains",defaultMessage: "Select domains", }),
    phone:intl.formatMessage({id: "phone",defaultMessage: "Telephone", }),
    select_customer_roles:intl.formatMessage({id: "select_customer_roles",defaultMessage: "TelephoSelect Customer Rolesne", }),
    user_type:intl.formatMessage({id: "user_type",defaultMessage: "User Type", }),
    allows_to_timeTracker:intl.formatMessage({id: "allows_to_timeTracker",defaultMessage: "Allowed To TimeTracker", }),
    affiliation:intl.formatMessage({id: "affiliation",defaultMessage: "Affiliation", }),
    extras:intl.formatMessage({id: "extras",defaultMessage: "Extras", }),
    custom_attributes:intl.formatMessage({id: "custom_attributes",defaultMessage: "Custom Attributes", }),
    select_team_leader:intl.formatMessage({id: "select_team_leader",defaultMessage: "Select Team Leaders", }),
    boarding_status:intl.formatMessage({id: "boarding_status",defaultMessage: "Boarding Status", }),
    pick_one:intl.formatMessage({id: "pick_one",defaultMessage: "Pick one", }),
    leader:intl.formatMessage({id: "leader",defaultMessage: "Leader", }),
    version:intl.formatMessage({id: "version",defaultMessage: "Version", }),
    
    select_role_name:intl.formatMessage({id: "select_role_name",defaultMessage: "Select Role", }),
    select_calculation_type:intl.formatMessage({id: "select_calculation_type",defaultMessage: "Select Calculation Type", }),
    calculation_type:intl.formatMessage({id: "calculation_type",defaultMessage: "Calculation Type", }),
    calculation:intl.formatMessage({id: "calculation",defaultMessage: "Calculation ", }),
    select_core_name:intl.formatMessage({id: "select_core_name",defaultMessage: "Select Core Role", }),
    cost_center_manage:intl.formatMessage({id: "cost_center_manage",defaultMessage: "CostCenter manage", }),
    cost_center_name:intl.formatMessage({id: "cost_center_name",defaultMessage: "Costcenter", }),
    street2:intl.formatMessage({id: "street2",defaultMessage: "Street2", }),
    street1:intl.formatMessage({id: "street1",defaultMessage: "Street1", }),
    street:intl.formatMessage({id: "street",defaultMessage: "Street", }),
    company_postal_code:intl.formatMessage({id: "company_postal_code",defaultMessage: "company Postal Code", }),
    city:intl.formatMessage({id: "city",defaultMessage: "City", }),
    cost_center_identifier:intl.formatMessage({id: "cost_center_identifier",defaultMessage: "CostCenter identifier", }),
    cost_center_identifier_tips:intl.formatMessage({id: "cost_center_identifier_tips",defaultMessage: "Once created, the department identifier cannot be modified. The identifiers 'hr' and 'it' hold special significance, representing the HR and IT departments in the system. These identifiers cannot be deleted or modified ", }),
    global_database_ip:intl.formatMessage({id: "global_database_ip",defaultMessage: "Database ip", }),
    global_licence_type:intl.formatMessage({id: "global_licence_type",defaultMessage: "Licence type", }),
    global_language:intl.formatMessage({id: "global_language",defaultMessage: "Select language", }),
    global_time_format:intl.formatMessage({id: "global_time_format",defaultMessage: "Select time format", }),
    use_system_time:intl.formatMessage({id: "use_system_time",defaultMessage: "System usage time", }),
    global_on_premise:intl.formatMessage({id: "global_on_premise",defaultMessage: "Local deployment", }),
    domains_string:intl.formatMessage({id: "domains_string",defaultMessage: "Please follow the specified format for entering domain names: domain1.cn,domain2.cn, ..., etc.", }),
    domain_used:intl.formatMessage({id: "domain_used",defaultMessage: "Domain name used by the system", }),
    task_mode_flag:intl.formatMessage({id: "task_mode_flag",defaultMessage: "Choose business model", }),
    syetem_info:intl.formatMessage({id: "syetem_info",defaultMessage: "System Info", }),
    settings:intl.formatMessage({id: "Settings",defaultMessage: "Settings", }),
    company_settings:intl.formatMessage({id: "company_settings",defaultMessage: "Company Setting", }),
    modules_serve:intl.formatMessage({id: "modules_serve",defaultMessage: "Modules serve Setting", }),
    currency:intl.formatMessage({id: "currency",defaultMessage: "Currency Setting", }),
    country:intl.formatMessage({id: "country",defaultMessage: "Country Setting", }),
    currency_set:intl.formatMessage({id: "currency_set",defaultMessage: "Currency Setting", }),
    country_set:intl.formatMessage({id: "currency_set",defaultMessage: "Country Setting", }),
    global_core:intl.formatMessage({id: "global_core",defaultMessage: "Global core Setting", }),
    costcenter_settings:intl.formatMessage({id: "costcenter_settings",defaultMessage: "Cost center Setting", }),
    log_settings: intl.formatMessage({id: 'log_settings', defaultMessage: 'Log Setting'}),
    server_settings: intl.formatMessage({id: 'server_settings', defaultMessage: 'Server Setting '}),
    dba_settings: intl.formatMessage({id: 'dba_settings', defaultMessage: 'DBA Setting '}),
    developer_settings: intl.formatMessage({id: 'developer_settings', defaultMessage: 'Developer Setting '}),
    application_settings: intl.formatMessage({id: 'application_settings', defaultMessage: 'Application Setting '}),
    network_settings: intl.formatMessage({id: 'network_settings', defaultMessage: 'Network Setting'}),
    security_settings: intl.formatMessage({id: 'security_settings', defaultMessage: 'Security  Setting '}),
    public_settings: intl.formatMessage({id: 'public_settings', defaultMessage: 'Public  Setting'}),
    email_settings: intl.formatMessage({id: 'email_settings', defaultMessage: 'Email Setting '}),
    email_name: intl.formatMessage({id: 'email_name', defaultMessage: 'Email Name '}),
    email_driver: intl.formatMessage({id: 'email_driver', defaultMessage: 'Email Driver '}),
    email_host: intl.formatMessage({id: 'email_host', defaultMessage: 'Email Host '}),
    email_port: intl.formatMessage({id: 'email_port', defaultMessage: 'Email Port '}),
    email_username: intl.formatMessage({id: 'email_username', defaultMessage: 'User Name '}),
    email_hostname: intl.formatMessage({id: 'email_hostname', defaultMessage: 'Host Name '}),
    from_email_name: intl.formatMessage({id: 'from_email_name', defaultMessage: 'From Email Name '}),
    log_details: intl.formatMessage({id: 'log_details', defaultMessage: 'Log Details'}),
    company_name:intl.formatMessage({id: "company_name",defaultMessage: "Company name", }),
    company_logo:intl.formatMessage({id: "company_logo",defaultMessage: "company_logo", }),
    click_image_update:intl.formatMessage({id: "click_image_update",defaultMessage: "click_image_update", }),
    validata_postalcode:intl.formatMessage({id: "validata_postalcode",defaultMessage: "validata_postalcode", }),
    module_name:intl.formatMessage({id: "module_name",defaultMessage: "Module name", }),
    module_ip:intl.formatMessage({id: "module_ip",defaultMessage: "Module ip", }),
    table_name:intl.formatMessage({id: "table_name",defaultMessage: "Table name", }),
    id_log:intl.formatMessage({id: "id_log",defaultMessage: "ID log", }),
    row_id:intl.formatMessage({id: "row_id",defaultMessage: "Row ID", }),
    column_name:intl.formatMessage({id: "column_name",defaultMessage: "Column name", }),
    type:intl.formatMessage({id: "type",defaultMessage: "Type", }),
    old_value:intl.formatMessage({id: "old_value",defaultMessage: "Old value", }),
    new_value:intl.formatMessage({id: "new_value",defaultMessage: "New value", }),
    changed_by:intl.formatMessage({id: "changed_by",defaultMessage: "Changed by", }),
    changed_at:intl.formatMessage({id: "changed_at",defaultMessage: "Changed at", }),
    desc:intl.formatMessage({id: "desc",defaultMessage: "Desc", }),
    sequence_number:intl.formatMessage({id: "sequence_number",defaultMessage: "Sequence number", }),
    delete_status:intl.formatMessage({id: "delete_status",defaultMessage: "Delete status", }),
    licence_count:intl.formatMessage({id: "licence_count",defaultMessage: "Licence count ", }),
    is_enable:intl.formatMessage({id: "is_enable",defaultMessage: "Enable ?", }),
    refresh_module_cache:intl.formatMessage({id: "refresh_module_cache",defaultMessage: "Refresh module cache", }),
    modules_manage:intl.formatMessage({id: "modules_manage",defaultMessage: "Modules manage", }),

    offset_overtime:intl.formatMessage({id: "offset_overtime",defaultMessage: "Offset Overtime", }),
    save:intl.formatMessage({id: "save",defaultMessage: "Save", }),

    server_info:intl.formatMessage({id: "server_info",defaultMessage: "Server Info Setting", }),

    license_info:intl.formatMessage({id: "license_info",defaultMessage: "License info Setting", }),
    catch_error:intl.formatMessage({id: "catch_error",defaultMessage: "Request exception", }),
    currency_name:intl.formatMessage({id: "currency_name",defaultMessage: "Currency name", }),
    currency_country:intl.formatMessage({id: "currency_country",defaultMessage: "Currency country", }),
    currency_notation:intl.formatMessage({id: "currency_notation",defaultMessage: "Currency notation", }),
    currency_info:intl.formatMessage({id: "currency_info",defaultMessage: "Currency information", }),
	  country_info:intl.formatMessage({id: "country_info",defaultMessage: "Country information", }),
    country_name:intl.formatMessage({id: "country_name",defaultMessage: "Country name", }),
    country_code:intl.formatMessage({id: "country_code",defaultMessage: "Country code", }),
    country_iso:intl.formatMessage({id: "country_iso",defaultMessage: "Country iso", }),
    customer_contact_role_id:intl.formatMessage({id: "customer_contact_role_id",defaultMessage: "Customer role id", }),
    customer_contact_role_desc:intl.formatMessage({id: "customer_contact_role_desc",defaultMessage: "Customer role desc", }),
    customer_contact_role_name:intl.formatMessage({id: "customer_contact_role_name",defaultMessage: "Customer role name", }),
    customer_roles:intl.formatMessage({id: "customer_roles",defaultMessage: "Customer role", }),
    no_data:intl.formatMessage({id: "no_data",defaultMessage: "No data . . .", }),
    no_data_v2:intl.formatMessage({id: "no_data_v2",defaultMessage: "No data", }),
    create_customer_roles:intl.formatMessage({id: "create_customer_roles",defaultMessage: "Create customer role", }),
    update_customer_roles:intl.formatMessage({id: "update_customer_roles",defaultMessage: "Modify customer contact role", }),
    contact_management:intl.formatMessage({id: "contact_management",defaultMessage: "Contact management", }),
    customer_management:intl.formatMessage({id: "customer_management",defaultMessage: "Customer management", }),
    create_customer_title:intl.formatMessage({id: "create_customer_title",defaultMessage: "Create Customer", }),
    website:intl.formatMessage({id: "website",defaultMessage: "Website", }),
    company_info:intl.formatMessage({id: "company_info",defaultMessage: "Company info", }),
    project_num:intl.formatMessage({id: "project_num",defaultMessage: "Project counts", }),
    project:intl.formatMessage({id: "project",defaultMessage: "Project", }),
    order:intl.formatMessage({id: "order",defaultMessage: "Order", }),
    task:intl.formatMessage({id: "task",defaultMessage: "Task", }),
    single_order:intl.formatMessage({id: "single_order",defaultMessage: "Single Order", }),
    single_task:intl.formatMessage({id: "single_task",defaultMessage: "Single Task", }),
    customer_id:intl.formatMessage({id: "customer_id",defaultMessage: "Customer's id", }),
    create_customer_contact:intl.formatMessage({id: "create_customer_contact",defaultMessage: "Preview customer contact", }),
    cat_customer_contact:intl.formatMessage({id: "cat_customer_contact",defaultMessage: "Save", }),
    edit_customer:intl.formatMessage({id: "edit_customer",defaultMessage: "Modify customer", }),
    edit_task:intl.formatMessage({id: "edit_task",defaultMessage: "Edit Task", }),
    customer_contact:intl.formatMessage({id: "customer_contact",defaultMessage: "Customer Contact", }),
    
    udpate_user:intl.formatMessage({id: "udpate_user",defaultMessage: "Modify user", }),
    create_user:intl.formatMessage({id: "create_user",defaultMessage: "Create User", }),
    id_user:intl.formatMessage({id: "id_user",defaultMessage: "User’s id", }),
    username:intl.formatMessage({id: "usernmae",defaultMessage: "Account", }),
    project_leader:intl.formatMessage({id: "project_leader",defaultMessage: "Project leader", }),
    order_leader:intl.formatMessage({id: "order_leader",defaultMessage: "Order leader", }),
    approval_permission:intl.formatMessage({id: "approval_permission",defaultMessage: "Approval permission", }),
    active:intl.formatMessage({id: "active",defaultMessage: "Active", }),
    timeTracker_licence:intl.formatMessage({id: "timeTracker_licence",defaultMessage: "TimeTracker Licence", }),
    Leader_of_department:intl.formatMessage({id: "Leader_of_department",defaultMessage: "Leader Of Department", }),
    Leader_of_projects:intl.formatMessage({id: "Leader_of_projects",defaultMessage: "Leader Of Projects", }),
    display_inactive:intl.formatMessage({id: "display_inactive",defaultMessage: "Display Inactive Users", }),
    include_lnactive_users:intl.formatMessage({id: "include_lnactive_users",defaultMessage: " Include lnactive Users", }),
    last_login:intl.formatMessage({id: "last_login",defaultMessage: "Last login time", }),
    create_assignment:intl.formatMessage({id: "create_assignment",defaultMessage: "Create assignment", }),
    create_project:intl.formatMessage({id: "create_project",defaultMessage: "Create a new project", }),
    create_order:intl.formatMessage({id: "create_order",defaultMessage: "Create a new order", }),
    create_task:intl.formatMessage({id: "create_task",defaultMessage: "Create a new task", }),
    validata_ip:intl.formatMessage({id: "validata_ip",defaultMessage: "Invalid IP address format", }),
    no_assignments_moda_tips:intl.formatMessage({id: "no_assignments_moda_tips",defaultMessage: "There is currently no setting assignment mode, please go to Settings > Global Settings to select", }),
    not_select_this_assignments_moda_tips:intl.formatMessage({id: "not_select_assignments_moda_tips",defaultMessage: "The assignment mode type has not been selected yet", }),
    not_this_assignments_moda_tips:intl.formatMessage({id: "active",defaultMessage: "There is no such assignment mode", }),
    assignment_desc:intl.formatMessage({id: "assignment_desc",defaultMessage: "Here you can create projects, orders and tasks.You can skip any creation process, by clicking on the upper step-buttons.    For example: If you wish to create only a single task, click the task-step in the upper stepper:", }),  
    project_name:intl.formatMessage({id: "project_name",defaultMessage: "Project name", }),
    project_name_up:intl.formatMessage({id: "project_name_up",defaultMessage: "Project Name", }),
    customer_project_no:intl.formatMessage({id: "customer_project_no",defaultMessage: "Customer project no ", }),
    budget:intl.formatMessage({id: "budget",defaultMessage: "Budget", }),
    info:intl.formatMessage({id: "info",defaultMessage: "Info", }),
    select_employees:intl.formatMessage({id: "select_employees",defaultMessage: "Select employees", }),
    select_employees_role:intl.formatMessage({id: "select_employees_role",defaultMessage: "Select employees role", }),
    select_customer:intl.formatMessage({id: "select_customer",defaultMessage: "Select Customer", }),
    select_role:intl.formatMessage({id: "select_role",defaultMessage: "Select User Role", }),
    cost_center:intl.formatMessage({id: "cost_center",defaultMessage: "Cost Center  ", }),
    select_cost_center:intl.formatMessage({id: "select_cost_center",defaultMessage: "Select Cost Center  ", }),
    customer_name:intl.formatMessage({id: "customer_name",defaultMessage: "Customer", }),
    customer:intl.formatMessage({id: "customer",defaultMessage: "Customer", }),
    start_time:intl.formatMessage({id: "start_time",defaultMessage: "Start Time", }),
    end_time:intl.formatMessage({id: "end_time",defaultMessage: "End Time", }),
    project_no:intl.formatMessage({id: "project_no",defaultMessage: "Project No", }),
    estimated_time:intl.formatMessage({id: "estimated_time",defaultMessage: "Estimated Time", }),
    add_user:intl.formatMessage({id: "add_user",defaultMessage: "Add User", }),
    add_contact_user:intl.formatMessage({id: "add_contact_user",defaultMessage: "Add Contact User", }),
    
    search_user:intl.formatMessage({id: "search_user",defaultMessage: "Search User", }),
    associated_order_name:intl.formatMessage({id: "associated_order_name",defaultMessage: "Associated Order Name", }),
    associated_order_no:intl.formatMessage({id: "associated_order_no",defaultMessage: "Associated Order Number", }),
    associated_project_no:intl.formatMessage({id: "associated_project_no",defaultMessage: "Associated Project Number", }),
    associated_project_name:intl.formatMessage({id: "associated_project_name",defaultMessage: "Associated Project Name", }),
    associated_customer:intl.formatMessage({id: "associated_customer",defaultMessage: "Associated Customer", }),
    associated_customer_desc_tips:intl.formatMessage({id: "associated_customer_desc_tips",defaultMessage: "Info", }),
    associated_projec_desc_tips:intl.formatMessage({id: "associated_projec_desc_tips",defaultMessage: "you skipped step 1, here you can choose a existing project", }),
    all_project:intl.formatMessage({id: "all_project",defaultMessage: "All Project", }),
    all_order:intl.formatMessage({id: "all_order",defaultMessage: "All Order", }),
    all_task:intl.formatMessage({id: "all_task",defaultMessage: "All Task", }),
    link_order:intl.formatMessage({id: "link_order",defaultMessage: "Link new task to this orde", }),
    internal:intl.formatMessage({id: "internal",defaultMessage: "Internal", }),
    order_name:intl.formatMessage({id: "order_name",defaultMessage: "Order Name", }),
    order_no:intl.formatMessage({id: "order_no",defaultMessage: "Order No", }),
    add_products:intl.formatMessage({id: "add_products",defaultMessage: "Add Products", }),
    cancel_add_product:intl.formatMessage({id: "cancel_add_product",defaultMessage: "Cancel Products", }),
    service_fee:intl.formatMessage({id: "service_fee",defaultMessage: "Service Fee", }),
    product_name:intl.formatMessage({id: "product_name",defaultMessage: "Product Name", }),
    supplier:intl.formatMessage({id: "supplier",defaultMessage: "Supplier", }),
    unit_price:intl.formatMessage({id: "unit_price",defaultMessage: "Unit Price", }),
    product_not_available:intl.formatMessage({id: "product_not_available",defaultMessage: "Product not available in warehouse", }),
    warehouse_needs_purchasing:intl.formatMessage({id: "warehouse_needs_purchasing",defaultMessage: "Warehouse needs purchasing", }),
    task_category:intl.formatMessage({id: "task_category",defaultMessage: "Task Category", }),
    task_desc:intl.formatMessage({id: "task_desc",defaultMessage: "Task Description", }),
    target_time:intl.formatMessage({id: "target_time",defaultMessage: "Target Hour", }),
	task_team_leader:intl.formatMessage({id: "task_team_leader",defaultMessage: "Task  Leaders", }),
	users_counts:intl.formatMessage({id: "users_counts",defaultMessage: "Users Counts", }),
	users_counts_desc:intl.formatMessage({id: "users_counts_desc",defaultMessage: "Add Users on the right section", }),
    add_task:intl.formatMessage({id: "add_task",defaultMessage: "Add Task ", }),
    choose_a_role:intl.formatMessage({id: "choose_a_role",defaultMessage: "Choose a role ", }),
	choose_now_or_late:intl.formatMessage({id: "choose_now_or_late",defaultMessage: "Choose now or later", }),
    leave_empty:intl.formatMessage({id: "leave_empty",defaultMessage: "Leave empty, if not needed", }),
    user:intl.formatMessage({id: "user",defaultMessage: "User", }),
    onboarding_start:intl.formatMessage({id: "onboarding_start",defaultMessage: "Start Onboarding Date", }),
    costcenter_leader:intl.formatMessage({id: "costcenter_leader",defaultMessage: "Costcenter leader ", }),
    
    user_info:intl.formatMessage({id: "user_info",defaultMessage: "User Info", }),
    profile:intl.formatMessage({id: "profile",defaultMessage: "Profile", }),
    on_off_boarding:intl.formatMessage({id: "on_off_boarding",defaultMessage: "On/Off-Boarding", }),
    expand_tips:intl.formatMessage({id: "expand_tips",defaultMessage: "Expand?", }),
    status:intl.formatMessage({id: "status",defaultMessage: "Status", }),
    boarding:intl.formatMessage({id: "boarding",defaultMessage: "Boarding", }),
    onboarding:intl.formatMessage({id: "onboarding",defaultMessage: "On Boarding", }),
    offboarding:intl.formatMessage({id: "offboarding",defaultMessage: "Off Boarding", }),
    boarding_name:intl.formatMessage({id: "boarding_name",defaultMessage: "boarding_name", }),
    add_estimnated:intl.formatMessage({id: "add_estimnated",defaultMessage: "Add extra estimated time.", }),
    // add_estimnated_tooltip:intl.formatMessage({id: "add_estimnated_tooltip",defaultMessage: "<div>When the estimated time for the project is insufficient,</div> <p>please modify the estimated time here. The estimated time for the project equals this extra estimated time plus the target hours.</p>", }),
    add_estimnated_tooltip:intl.formatMessage({id: "add_estimnated_tooltip",defaultMessage: "When the estimated time for the project is insufficient,please modify the estimated time here. The estimated time for the project equals this extra estimated time plus the target hours.", }),
    status_manage:intl.formatMessage({id: "status_manage",defaultMessage: "Status Management", }),
    actual_time:intl.formatMessage({id: "actual_time",defaultMessage: "Actual Time", }),
    over_time:intl.formatMessage({id: "over_time",defaultMessage: "Overtime", }),
    add_overtime:intl.formatMessage({id: "add_overtime",defaultMessage: "Add Overtime", }),
    completion:intl.formatMessage({id: "completion",defaultMessage: "Completion", }),
    cost:intl.formatMessage({id: "cost",defaultMessage: "Cost", }),
    orders:intl.formatMessage({id: "orders",defaultMessage: "Orders", }),
    tasks:intl.formatMessage({id: "tasks",defaultMessage: "Tasks", }),
    delay_falg:intl.formatMessage({id: "delay_falg",defaultMessage: "Delay +/-", }),
    set_row_style:intl.formatMessage({id: "set_row_style",defaultMessage: "Set row style", }),
    overtime:intl.formatMessage({id: "overtime",defaultMessage: "Over time", }),
    task_name:intl.formatMessage({id: "task_name",defaultMessage: "Task Name", }),
    customize:intl.formatMessage({id: "customize",defaultMessage: "Customize", }),
    apply_to_all:intl.formatMessage({id: "apply_to_all",defaultMessage: "Apply to all ", }),
 taready:intl.formatMessage({id: "ready",defaultMessage: "Ready", }),
    running:intl.formatMessage({id: "running",defaultMessage: "Running", }),
    finished:intl.formatMessage({id: "finished",defaultMessage: "Finished", }),
    onhold:intl.formatMessage({id: "onhold",defaultMessage: "Onhold", }),
    cancelled:intl.formatMessage({id: "cancelled",defaultMessage: "Cancelled", }),
    archived:intl.formatMessage({id: "archived",defaultMessage: "Archived", }),
    paused:intl.formatMessage({id: "paused",defaultMessage: "Paused", }),
    task_times:intl.formatMessage({id: "task_times",defaultMessage: "Task Times", }),
    overtime_init:intl.formatMessage({id: "overtime_init",defaultMessage: "0 hour", }),
    cancel:intl.formatMessage({id: "cancel",defaultMessage: "Cancel", }),
    submit:intl.formatMessage({id: "submit",defaultMessage: "Submit", }),
    undo:intl.formatMessage({id: "undo",defaultMessage: "Undo", }),
    quantity:intl.formatMessage({id: "quantity",defaultMessage: "Quantity", }),
    add:intl.formatMessage({id: "add",defaultMessage: "Add", }),
    add_costcenter:intl.formatMessage({id: "add_costcenter",defaultMessage: "Add CostCenter", }),
    warehouse:intl.formatMessage({id: "warehouse",defaultMessage: "Warehouse", }),
    price:intl.formatMessage({id: "price",defaultMessage: "Price", }),
    order_new_price:intl.formatMessage({id: "order_new_price",defaultMessage: "Order New Price", }),
    ordered_products:intl.formatMessage({id: "ordered_products",defaultMessage: "Ordered Products", }),
    delivery_schedule:intl.formatMessage({id: "delivery_schedule",defaultMessage: "Delivery Schedule", }),
    delivery_time:intl.formatMessage({id: "delivery_date",defaultMessage: "Delivery Time", }),
    save_current_statue:intl.formatMessage({id: "save_current_statue",defaultMessage: "Save Current Statue", }),
    product_management:intl.formatMessage({id: "product_management",defaultMessage: "Product Management", }),
    project_times:intl.formatMessage({id: "project_times",defaultMessage: "Project Times ", }),
    subtract_one_hour:intl.formatMessage({id: "subtract_one_hour",defaultMessage: "- 1 hour", }),
    background:intl.formatMessage({id: "background",defaultMessage: "Background", }),
    text:intl.formatMessage({id: "text",defaultMessage: "Text", }),
    select_data:intl.formatMessage({id: "select_data",defaultMessage: "Select Data", }),
    no_select:intl.formatMessage({id: "no_select",defaultMessage: 'No option selected', }),
    clear:intl.formatMessage({id: "clear",defaultMessage: "Clear", }),
    clear_all_permission:intl.formatMessage({id: "clear_all_permission",defaultMessage: "Clear All Permission", }),
    get_all_permission:intl.formatMessage({id: "get_all_permission",defaultMessage: "Get All Permission", }),
    views:intl.formatMessage({id: "views",defaultMessage: "Views", }),
    apply:intl.formatMessage({id: "apply",defaultMessage: "Apply", }),
    add_one_hour:intl.formatMessage({id: "add_one_hour",defaultMessage: "+ 1 hour", }),
    order_new_product:intl.formatMessage({id: "order_new_product",defaultMessage: "Add new product", }),
    filter:intl.formatMessage({id: "filter",defaultMessage: "Filter", }),
    ready:intl.formatMessage({id: "ready",defaultMessage: "Ready", }),
    product_duplicate:intl.formatMessage({id: "product_duplicate",defaultMessage: "Duplicate product ID found!", }),
    task_category_management:intl.formatMessage({id: "task_category_management",defaultMessage: "Task category management", }),
    identifier:intl.formatMessage({id: "identifier",defaultMessage: "Identifier", }),
    parameter_collection:intl.formatMessage({id: "parameter_collection",defaultMessage: "Parameter collection", }),
    search:intl.formatMessage({id: "search",defaultMessage: "Search", }),
    task_category_patameter:intl.formatMessage({id: "task_category_patameter",defaultMessage: "Task category patameter", }),
    name:intl.formatMessage({id: "name",defaultMessage: "Name", }),
    matrix:intl.formatMessage({id: "matrix",defaultMessage: "Format Matrix", }),
    select_matrix_paramerter:intl.formatMessage({id: "select_matrix_paramerter",defaultMessage: "Select Matrix Paramerter", }),
    format_id:intl.formatMessage({id: "format_id",defaultMessage: "Format’id", }),
    parameter_matrix_ids:intl.formatMessage({id: "parameter_matrix_ids",defaultMessage: "Parameter’ids", }),
    project_permission:intl.formatMessage({id: "project_permission",defaultMessage: "Permissions Edit", }),
    project_leader_role_editing:intl.formatMessage({id: "project_leader_role_editing",defaultMessage: "Project Leader - Role Editing", }),
    permission_role_management:intl.formatMessage({id: "permission_role_management",defaultMessage: "Permissions  Role Management", }),
    permission_role:intl.formatMessage({id: "permission_role",defaultMessage: "Permissions Role ", }),
    existing_role:intl.formatMessage({id: "existing_role",defaultMessage: "Existing Roles", }),
    assigment:intl.formatMessage({id: "assigment",defaultMessage: "Assigment", }),
    employee_role_name:intl.formatMessage({id: "employee_role_name",defaultMessage: "Employee Permissions Role name", }),
    employee_role_id:intl.formatMessage({id: "employee_role_id",defaultMessage: "Employee Permissions Role ID", }),
    add_employee_role:intl.formatMessage({id: "add_employee_role",defaultMessage: "Create Employee Permissions Role", }),
    edit_employee_role:intl.formatMessage({id: "add_employee_role",defaultMessage: "Modify Employee Permissions Role", }),
    permissions_title:intl.formatMessage({id: "permissions_title",defaultMessage: "Permissions Title", }),
    permissions_settings:intl.formatMessage({id: "permissions_settings",defaultMessage: "Permissions settings ", }),
    permissions:intl.formatMessage({id: "permissions",defaultMessage: "Permissions", }),  
    module_permissions:intl.formatMessage({id: "module_permissions",defaultMessage: "CostCenter Permissions", }),  
    activated:intl.formatMessage({id: "activated",defaultMessage: "Activated", }),
    core_role:intl.formatMessage({id: "core_role",defaultMessage: "Core Role", }),
    management_role :intl.formatMessage({id: "management_role",defaultMessage: "Special User Permission", }),
	  not_activated:intl.formatMessage({id: "not_activated",defaultMessage: " Not activated", }),
	  add_new:intl.formatMessage({id: "add_new",defaultMessage: " Create ", }),
    id_permission_identifier:intl.formatMessage({id: "id_permission_identifier",defaultMessage: "Name", }),
    permission_identifier:intl.formatMessage({id: "permission_identifier",defaultMessage: "Permission Identifier", }),
    permission_identifier_set:intl.formatMessage({id: "permission_identifier_set",defaultMessage: "Permission Identifier Setting ", }),
    permission_edit:intl.formatMessage({id: "permission_edit",defaultMessage: "Permission Edit", }),
    permission_view:intl.formatMessage({id: "permission_view",defaultMessage: "Permission View", }),
    permission_action:intl.formatMessage({id: "permission_action",defaultMessage: "Permission Action", }),
    permission_action_set:intl.formatMessage({id: "permission_action_set",defaultMessage: "Permission Action Setting", }),
    permission_title:intl.formatMessage({id: "permission_title",defaultMessage: "Permission Title", }),
    permission_identifier_introduction:intl.formatMessage({id: "permission_identifier_introduction",defaultMessage: " This is the operated object for permission actions, such as orders, projects, timetrackers, etc.   The term `action` refers to operations like create, delete, update, and read, and it is configured in the  Title", }),
    edit_role_permission:intl.formatMessage({id: "edit_role_permission",defaultMessage: "Edit role permission", }),
    all_flag:intl.formatMessage({id: "all_flag",defaultMessage: " All  ", }),
    all:intl.formatMessage({id: "all",defaultMessage: " All  ", }),
    permissions_set_all_flag:intl.formatMessage({id: "permissions_set_all_flag",defaultMessage: 'When "All" is selected, ensure that at least one action is chosen. ' }),
	  role_permission:intl.formatMessage({id: "role_permission",defaultMessage: " Role permission", }),
    role_edit:intl.formatMessage({id: "role_edit",defaultMessage: "Role Edit", }),
    created_orders:intl.formatMessage({id: "created_orders",defaultMessage: "Created Orders", }),
    associated_product:intl.formatMessage({id: "associated_product",defaultMessage: "Associated Products", }), 
    already_has_the_role:intl.formatMessage({id: "already_has_the_role",defaultMessage: " already has the role of ", }),
	  please_choose_member:intl.formatMessage({id: "please_choose_member",defaultMessage: " Please choose a member to join the task", }),
    please_choose_permission_role:intl.formatMessage({id: "please_choose_permission_role",defaultMessage: " Please select a role", }),
	  customer_overview:intl.formatMessage({id: "customer_overview",defaultMessage: " Customer Overview", }),
	  project_overview:intl.formatMessage({id: "project_overview",defaultMessage: " Project Overview", }),
    timesheet_title:intl.formatMessage({id: "timesheet_title",defaultMessage: "EDIT TIMESHEET", }),
    employee_count:intl.formatMessage({id: "employee_count",defaultMessage: "Employee Counts", }),
    actual_start_time:intl.formatMessage({id: "actual_start_time",defaultMessage: "Actual Start Date", }),
    actual_end_time:intl.formatMessage({id: "actual_end_time",defaultMessage: "Actual End  Date", }),
    choose_user:intl.formatMessage({id: "choose_user",defaultMessage: "Choose User", }),
    target:intl.formatMessage({id: "target",defaultMessage: "Target Time", }),
    diff_time:intl.formatMessage({id: "diff_time",defaultMessage: "Diff Time", }),
    actual:intl.formatMessage({id: "actual",defaultMessage: "Actual", }),
    productivity:intl.formatMessage({id: "productivity",defaultMessage: "Productivity", }),
    week_end:intl.formatMessage({id: "week_end",defaultMessage: "Week End", }),
    public_holiday:intl.formatMessage({id: "public_holiday",defaultMessage: "Public Holiday", }),
    half_day_holiday:intl.formatMessage({id: "half_day_holiday",defaultMessage: "Half Day Holiday", }),
    one_day_holiday:intl.formatMessage({id: "one_day_holiday",defaultMessage: "One Day Holiday", }),
    holiday:intl.formatMessage({id: "one_day_holiday",defaultMessage: "Holiday", }),
    select_project:intl.formatMessage({id: "select_project",defaultMessage: "Select Project", }),
    select_order:intl.formatMessage({id: "select_order",defaultMessage: "Select Order", }),
    select_task:intl.formatMessage({id: "select_task",defaultMessage: "Select Task", }),
    select_all:intl.formatMessage({id: "select_all",defaultMessage: "Select All", }),
    saturday:intl.formatMessage({id: "saturday",defaultMessage: "Saturday", }),
    sunday:intl.formatMessage({id: "sunday",defaultMessage: "Sunday", }),
    sick:intl.formatMessage({id: "sick",defaultMessage: "Sick", }),
    total_count:intl.formatMessage({id: "total_count",defaultMessage: "Total Count", }),
    
    mon:intl.formatMessage({id: "mon",defaultMessage: "Monday", }),
    tues:intl.formatMessage({id: "tues",defaultMessage: "Tuesday", }),
    weds:intl.formatMessage({id: "weds",defaultMessage: "Wednesday", }),
    thurs:intl.formatMessage({id: "thurs",defaultMessage: "Thursday", }),
    fri:intl.formatMessage({id: "fri",defaultMessage: "Friday", }),
    sat:intl.formatMessage({id: "sat",defaultMessage: "Saturday", }),
    sun:intl.formatMessage({id: "sun",defaultMessage: "Sunday", }),
    mark_sick:intl.formatMessage({id: "mark_sick",defaultMessage: "Record Sick Leave" }),
    
    quick_time_add:intl.formatMessage({id: "quick_time_add",defaultMessage: "Quick Time Add", }),
    clock_in_out:intl.formatMessage({id: "clock_in_out",defaultMessage: "Clock In & Out", }),
    clock_in:intl.formatMessage({id: "clock_in",defaultMessage: "In", }),
    clock_out:intl.formatMessage({id: "clock_out",defaultMessage: "Out", }),
    time:intl.formatMessage({id: "time",defaultMessage: "Time", }),
    over_time_report:intl.formatMessage({id: "over_time_report",defaultMessage: "OverTime Report", }),
    id:intl.formatMessage({id: "id",defaultMessage: "ID", }),
    start_year:intl.formatMessage({id: "start_year",defaultMessage: "Start Year", }),
    start_kw:intl.formatMessage({id: "start_kw",defaultMessage: "Start Week", }),
    end_year:intl.formatMessage({id: "end_year",defaultMessage: "Start Year", }),
    end_kw:intl.formatMessage({id: "end_kw",defaultMessage: "Start Week", }),
    hourly_account:intl.formatMessage({id: "hourly_account",defaultMessage: "Hourly Account", }),
    time_report:intl.formatMessage({id: "time_report",defaultMessage: "Time Report", }),
    hour:intl.formatMessage({id: "hour",defaultMessage: "h", }),
    hiti:intl.formatMessage({id: "hiti",defaultMessage: "Hint", }),
    over_time_total_count:intl.formatMessage({id: "over_time_total_count",defaultMessage: "Total overtime count", }),
    get_report:intl.formatMessage({id: "get_report",defaultMessage: "Get Report", }),
    emloyee_report_hitn:intl.formatMessage({id: "emloyee_report_hitn",defaultMessage: "Select an employee, choose the desired year for inquiry, and click the button to obtain the overtime information for that employee in the selected year.", }),
    report_hitn:intl.formatMessage({id: "report_hitn",defaultMessage: "Please select the above options as needed and click the button to get the report", }),

    permission_select_hitn:intl.formatMessage({id: "permission_select_hitn",defaultMessage: "Please select a cost center first before the permission options can be displayed. If the permission options still do not appear at this point, please go to the permission identifier and permission action to add the corresponding operations.", }),
    
    print:intl.formatMessage({id: "print",defaultMessage: "Print", }),
    items_to_exclude:intl.formatMessage({id: "items_to_exclude",defaultMessage: "Select items to exclude", }),
    excluded_project:intl.formatMessage({id: "excluded_project",defaultMessage: "Exclude", }),
    password:intl.formatMessage({id: "password",defaultMessage: "Password", }),
    not_skip:intl.formatMessage({id: "not_skip",defaultMessage: "There is no pattern to proceed to", }),
    assignment:intl.formatMessage({id: "assignment",defaultMessage: "Assignments", }),
    total_time:intl.formatMessage({id: "total_time",defaultMessage: "Total Time", }),
    total_actual_time_change:intl.formatMessage({id: "total_actual_time_change",defaultMessage: "Total Actual Time Change Time", }),
    student_time_report:intl.formatMessage({id: "student_time_report",defaultMessage: "Student Report", }),
    special_for:intl.formatMessage({id: "special_for",defaultMessage: "Special Request For", }),
    special_days:intl.formatMessage({id: "special_days",defaultMessage: "Special Days", }),
    special_leave:intl.formatMessage({id: "special_leave",defaultMessage: "Special Leave", }),
    
    notime_employee_report:intl.formatMessage({id: "notime_employee_report",defaultMessage: "None Time Recording Eployees  Report", }),
    time_employee_report:intl.formatMessage({id: "time_employee_report",defaultMessage: "Time Recording Eployees  Report", }),
    all_employee_report:intl.formatMessage({id: "time_employee_report",defaultMessage: "All Eployees  Report", }),
    fejp_time_report:intl.formatMessage({id: "fejp_time_report",defaultMessage: "None Time Recording Eployees FEJP Report", }),
    failed_to_get_data:intl.formatMessage({id: "failed_to_get_data",defaultMessage: "Failed to get data", }),
    
    inventory:intl.formatMessage({id: "inventory",defaultMessage: "Inventory", }),
    leave_request:intl.formatMessage({id: "leave_request",defaultMessage: "Leave Request", }),
    permission_set_hitn:intl.formatMessage({id: "permission_set_hitn",defaultMessage: "No data available. Please select a department center first and configure the permission sets. If the department is not displayed, it indicates that the permissions for this department have not been set up yet. Please contact the IT administrator." }),
    
    permission_set:intl.formatMessage({id: "permission_set",defaultMessage: "Permission Set", }),
    team_leader:intl.formatMessage({id: "team_leader",defaultMessage: "Team Leader", }),
    approver:intl.formatMessage({id: "approver",defaultMessage: "Approver", }),
    to:intl.formatMessage({id: "to",defaultMessage: "To", }),
    team_productivity:intl.formatMessage({id: "team_productivity",defaultMessage: "Team Productivity", }),
    team_report:intl.formatMessage({id: "team_report",defaultMessage: "Team Report", }),
    timetracker:intl.formatMessage({id: "timetracker",defaultMessage: "Timetracker", }),
    no_timetracker:intl.formatMessage({id: "no_timetracker",defaultMessage: "No Timetracker", }),
    student:intl.formatMessage({id: "student",defaultMessage: "Student", }),
    team_calendar:intl.formatMessage({id: "team_calendar",defaultMessage: "Team Calendar", }),
    
    from:intl.formatMessage({id: "from",defaultMessage: "From", }),
    half_day:intl.formatMessage({id: "half_day",defaultMessage: "Half Day", }),
    only_vaild_for_one_day:intl.formatMessage({id: "only_vaild_for_one_day",defaultMessage: "Only vaild for one day", }),
    sick_days:intl.formatMessage({id: "sick_days",defaultMessage: "Sick Days", }),
    
    select_vacation_end_date:intl.formatMessage({id: "select_vacation_end_date",defaultMessage: "Select Vacation End Date", }),
    select_vacation_start_date:intl.formatMessage({id: "select_vacation_start_date",defaultMessage: "Select Vacation Start Date", }),
    rules:intl.formatMessage({id: "rules",defaultMessage: "Rules", }),
    rules_description:intl.formatMessage({id: "rules_description",defaultMessage: "Your vacation application information will be delivered to your order leader or project leader for approval.", }),
    
    report:intl.formatMessage({id: "report",defaultMessage: "Report", }),
    time_tracker_editable_days_limit:intl.formatMessage({id: "time_tracker_editable_days_limit",defaultMessage: "EditTime Restriction", }),
    
    
    days:intl.formatMessage({id: "days",defaultMessage: "Day(s)", }),
    day_left:intl.formatMessage({id: "day_left",defaultMessage: "Day(s)left", }),
    day_ago:intl.formatMessage({id: "day_ago",defaultMessage: "Day(s)AGO", }),
    
    manager:intl.formatMessage({id: "manager",defaultMessage: "Manager", }),
    members:intl.formatMessage({id: "members",defaultMessage: "Members", }),
    mobile:intl.formatMessage({id: "mobile",defaultMessage: "Mobile", }),
    mark_or_not:intl.formatMessage({id: "mark_or_not",defaultMessage: "Mark or not", }),
    no:intl.formatMessage({id: "no",defaultMessage: "No", }),
    yes:intl.formatMessage({id: "yes",defaultMessage: "Yes", }),
    
    requested_date:intl.formatMessage({id: "requested_date",defaultMessage: "Requested Date", }),
    reject:intl.formatMessage({id: "reject",defaultMessage: "Reject", }),

    employee_vacation_information:intl.formatMessage({id: "employee_vacation_information",defaultMessage: "Employee Vacation Information", }),

    latest_request:intl.formatMessage({id: "latest_request",defaultMessage: "Latest Request", }),
    business_mode_set:intl.formatMessage({id: "business_mode_set",defaultMessage: 'Business Mode Set', }),
    business_mode_list:intl.formatMessage({id: "business_mode_list",defaultMessage: 'Business Mode List', }),
    leave_category:intl.formatMessage({id: "update_leave_category",defaultMessage: "Leave Category", }),
    leave_hours:intl.formatMessage({id: "leave_hours",defaultMessage: " Leave Hours", }),
    leave_info:intl.formatMessage({id: "leave_info",defaultMessage: " Leave Info", }),
    
    
    agree:intl.formatMessage({id: "agree",defaultMessage: "Agree", }),
    approve_this_leave:intl.formatMessage({id: "approve_this_leave",defaultMessage: "Approve this leave?", }),
    applicant:intl.formatMessage({id: "applicant",defaultMessage: "Applicant", }),
    approver_remarks:intl.formatMessage({id: "approver_remarks",defaultMessage: "Approver Remarks", }),
    vacation:intl.formatMessage({id: "vacation",defaultMessage: "Vacation", }),
    decline:intl.formatMessage({id: "decline",defaultMessage: "Only possible if a single tag is selected", }),
    half_day_select_hitn:intl.formatMessage({id: "half_day_select_hitn",defaultMessage: "Decline", }),
    batch_processing:intl.formatMessage({id: "batch_processing",defaultMessage: " Batch processing", }),
    
    update_leave_category:intl.formatMessage({id: "update_leave_category",defaultMessage: "Update Leave Category", }),
    user_remark:intl.formatMessage({id: "user_remark",defaultMessage: "User remark", }),
    approve_remark:intl.formatMessage({id: "approve_remark",defaultMessage: "Approve remark", }),
    
    change:intl.formatMessage({id: "change",defaultMessage: "Change", }),
    compute:intl.formatMessage({id: "compute",defaultMessage: "Compute", }),
    confirm_password:intl.formatMessage({id: "confirm_password",defaultMessage: "Confirm Password", }),
    category_name:intl.formatMessage({id: "category_name",defaultMessage: "Category Name",}),
    choose_a_employee:intl.formatMessage({id: "choose_a_employee",defaultMessage: "Choose A Employee", }),
    calendar:intl.formatMessage({id: "calendar",defaultMessage: "Calendar", }),
    current_requests_list:intl.formatMessage({id: "current_requests_list",defaultMessage: "Current Requests List", }),
    create_leave_category:intl.formatMessage({id: "create_leave_category",defaultMessage: "Create Leave Category", }),
    company_calendar:intl.formatMessage({id: "company_calendar",defaultMessage: "Company Calendar", }),
    cant_view_report:intl.formatMessage({id: "cant_view_report",defaultMessage: "The current user cannot view the report!", }),
    core:intl.formatMessage({id: "core",defaultMessage: "Core", }),
    administrator_permission:intl.formatMessage({id: "administrator_permission",defaultMessage: "Administrator permissions ", }),
    core_permission:intl.formatMessage({id: "administrator_permission",defaultMessage: "Core permissions ", }),
    contact_name:intl.formatMessage({id: "contact_name",defaultMessage: "Contact Name", }),
    
    post_leave:intl.formatMessage({id: "post_leave",defaultMessage: "After-the-fact leave request",}),
    thats_all:intl.formatMessage({id: "thats_all",defaultMessage: "All loaded",}),
    is_sick_leave:intl.formatMessage({id: "is_sick_leave",defaultMessage: "Is it confirmed as sick leave?", }),
    is_exec:intl.formatMessage({id: "is_exec",defaultMessage: "Do you confirm the operation?", }),
    
    vacation_approve_modal:intl.formatMessage({id: "vacation_approve_modal",defaultMessage: "Holiday Approval Modal", }),
    vacation_entitlement:intl.formatMessage({id: "vacation_entitlement",defaultMessage: "Vacation Entitlement", }),
    vacation_remaining:intl.formatMessage({id: "vacation_remaining",defaultMessage: "Remaining days of vacation", }),
    vacation_request:intl.formatMessage({id: "vacation_request",defaultMessage: "Vacation Request", }),
    vacation_approved:intl.formatMessage({id: "vacation_approved",defaultMessage: "The vacation has been approved.", }),
    vacation_canceled:intl.formatMessage({id: "vacation_canceled",defaultMessage: "The vacation has been canceled.", }),
    vacation_rejected:intl.formatMessage({id: "vacation_rejected",defaultMessage: "The vacation has been rejected.", }),
    vacation_sick_no_cancel:intl.formatMessage({id: "vacation_sick_no_cancel",defaultMessage: "The sick leave cannot be canceled.", }),
    
    promptForSuccessfulLeaveRequest:intl.formatMessage({id: "promptForSuccessfulLeaveRequest",defaultMessage: "The requested vacation days have already been deducted.", }),
    /**
     * iput hint
     */
    
    title:intl.formatMessage({id: "title",defaultMessage: "Title", }),
    Input:intl.formatMessage({id: "Input",defaultMessage: "Input", }),
    Input_max_5:intl.formatMessage({id: "Input_max_5",defaultMessage: "Please enter up to 5 characters.", }),
    Input_max_150:intl.formatMessage({id: "Input_max_150",defaultMessage: "Please enter up to 150 characters.", }),
    Input_max_255:intl.formatMessage({id: "Input_max_255",defaultMessage: "Please enter up to 255 characters.", }),
    wrong_request:intl.formatMessage({id: "wrong_request",defaultMessage: "Wrong request", }),
	
 /**   aplly:intl.formatMessage({id: "aplly",defaultMessage: "Aplly", }),
      aplly:intl.formatMessage({id: "aplly",defaultMessage: "Aplly", }),
	
   * Verify translation collection
   */  
   
  validata_value_need_1_255_length:intl.formatMessage({id: "value_need_1_255_length",defaultMessage: "This value must be 1-255 characters long", }),
  validata_value_need_1_150_length:intl.formatMessage({id: "value_need_1_150_length",defaultMessage: "This value must be 1-150 characters long", }),
  validata_value_need_1_5_length:intl.formatMessage({id: "value_need_1_5_length",defaultMessage: "This value must be 1-5 characters long", }),
  validata_value_need_2_100_length:intl.formatMessage({id: "value_need_2_100_length",defaultMessage: "This value must be 6-100 characters long", }),
  validata_value_need_6_100_length:intl.formatMessage({id: "value_need_6_100_length",defaultMessage: "This value must be 4-100 characters long", }),
  validata_value_need_2_10_length:intl.formatMessage({id: "value_need_2_10_length",defaultMessage: " must be 2-10 characters long", }),
  validata_value_need_1_10_length:intl.formatMessage({id: "value_need_1_10_length",defaultMessage: " must be 1-10 characters long", }),
  validata_identifier_1_10_digits:intl.formatMessage({id: "value_need_1_10_length",defaultMessage: " Identifier is a number with up to 10 digits", }),
   
  validata_email:intl.formatMessage({id: "validata_email",defaultMessage: " Invalid email", }),
  validata_age:intl.formatMessage({id: "validata_age",defaultMessage: "Must be between 18-99 years old", }),
  validata_postal_code:intl.formatMessage({id: "validata_postal_code",defaultMessage: "Postal code must consist of a combination of numbers and letters.", }),
  validata_url:intl.formatMessage({id: "validata_url",defaultMessage: "Invalid url ", }),
  validata_date:intl.formatMessage({id: "validata_date",defaultMessage: "Invalid date, it is in the format 0000-00-00", }),
  validata_time:intl.formatMessage({id: "validata_time",defaultMessage: "the time is invalid. It is in the format 00:00:00", }),
  validata_phone:intl.formatMessage({id: "validata_phone",defaultMessage: "Invalid phone ", }),
  validata_module_ip_is_required:intl.formatMessage({id: "validata_module_ip_is_required",defaultMessage: "The IP of the module cannot be empty", }),
  quantity_gt_1_:intl.formatMessage({id: "quantity_gt_1_",defaultMessage: "Quantity must be greater than 0", }),
  product_name_is_require:intl.formatMessage({id: "product_name_is_require",defaultMessage: "Product name must exist", }),
  is_not_empty:intl.formatMessage({id: "is_not_empty",defaultMessage: "cannot be empty", }),
  vacation_select_employee:intl.formatMessage({id: "vacation_select_employee",defaultMessage: "Please select employees to join the project ", }),
  /**
   * System prompt translation
   */
  
  });
  return word_translation;
}; 
export default WordTranslationComponent;

