import { regGetProduct, regProduct } from ".";
import { Product } from "../interface/IProduct";
import { ProductOrder } from "../public/svg/ProductOrder";
import WordTranslationComponent from "../utils/language_pack/words";

 


export const getProductGeneralSelectFunction = async (trans_unit_price: string,trans_product_not_available:string) => {

  // Initialize the return result
  let returnResult: any = [];
try {
  // Initiate an asynchronous request and use the "GET" method to obtain product configuration information through the regGetProduct function
   const response = await regGetProduct({},"GET");
    const result = response.data;

    // Check if the API response is successful (status code 200)
    if (result.code === 200) {
        // Extract and format data for the 'select' type
        const products = result.data.data.map(
          (item: Product) => ({
            image: <ProductOrder />,
            label: `${item.product_name} --- ${item.supplier_name}`,
            value: item.product_id?.toString(),
            type: item.type,
            unit_price: item.unit_price != null
              ? trans_unit_price + ': ' + item.unit_price
              : trans_product_not_available,
          })
        );
        returnResult = products;

    }
  } catch (error) {
    // Handle any errors that occur during the API call
    console.error('Error fetching project list:', error);
    // Optionally, you can throw the error or handle it as needed
    throw error;
  }

  // Return the final result
  return returnResult;
};


/**
 * 获得产品详细信息 根据 product_id
 * 
 */

// Fetches the project list
export const getProductsDetailGeneralFunction = async (where: any, get_type = 'select') => {
  // Initialize the return result
  let returnResult: any = [];

  try {
    // Call the API to get the project list
    const response = await regProduct(where, "GET");
    const result = response.data;

    // Check if the API response is successful (status code 200)

    if (result.code === 200) {
      returnResult = result.data;
    } 
   
  } catch (error) {
    // Handle any errors that occur during the API call
    console.error('Error fetching role list:', error);
    // Optionally, you can throw the error or handle it as needed
    throw error;
  }

  // Return the final result
  return returnResult;
};
