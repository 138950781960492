import { useEffect, useState } from 'react'
import { TextInput, Paper, Grid, Select, Box, Checkbox, ActionIcon, Group, Code, MultiSelect } from '@mantine/core'
import TableTextInputTitle from '../../../../../../ttcomponents/TableTextInputTitle/TableTextInputTitle'
import WordTranslationComponent from '../../../../../../utils/language_pack/words'
import { SelectRole } from '../../../../../../interface/Icommon'
import { IconPlus, IconX } from '@tabler/icons-react'
import ResetBtn from '../../../../../../ttcomponents/Button/ResetBtn'
import { deepEqual } from '../../../../../../utils/function'
import CostCenterForm from '../../../cost_center/form'
import { useDisclosure } from '@mantine/hooks'
import { useCostCenterLeader, useCostCenterLeaderApi } from '../../context'

interface ComponentProps {
  costCenterData: SelectRole[]
  defaultRoleData?: SelectRole[]
  resetFunction: () => void
}

export default function CommonPermissionRoleForm({
  defaultRoleData = [],
  costCenterData,
  resetFunction
}: ComponentProps) {
  // Get translation public configuration information
  const word_translation = WordTranslationComponent()

  const { costCenter_id, role_costCenter_id, active, description, identifier } = useCostCenterLeader()
  const { clearCostCenter, clearRole, updateData, changeIdentifier } = useCostCenterLeaderApi()

  // Control the CostCenterForm component.
  const [opened, { open, close }] = useDisclosure(false)

  // roleList
  const [roleList, setRoleList] = useState<SelectRole[]>([])
  const [notAllCostCenterData, SetNotAllCostCenterData] = useState<SelectRole[]>([])
  useEffect(() => {
    if (!deepEqual(defaultRoleData, roleList)) {
      setRoleList(defaultRoleData)
    }
  }, [defaultRoleData])

  
  useEffect(() => {
    const filteredData = costCenterData.filter(entry => entry?.value != '-1');
    SetNotAllCostCenterData(filteredData);
  }, [costCenterData])

  const onCostCenterIDChange = (value: string) => {
    clearRole()
    updateData('costCenter_id', value)
  }

  const onCostCenterIDClear = () => {
    clearCostCenter()
  }

  const findLabel = (value: string) => {
    return roleList.find(role => role.value === value)?.label || ''
  }
  const onIdentiferChange = (value: string) => {
    const label = findLabel(value)
    changeIdentifier(value, label)
  }
  const onIdentiferClear = () => {
    clearRole()
  }

  return (
    <Paper>
      <Box py={6}>
        <TableTextInputTitle title={word_translation.permission_role} />
      </Box>
      <Paper px={20} py={10} mih={180} radius="md" withBorder p="lg" bg="var(--mantine-color-body)">
        <Grid gutter={20}>
          <Grid.Col span={6}>
            <TableTextInputTitle
              icon_r={
                <ActionIcon onClick={open} size="xs">
                  <IconPlus size={14}></IconPlus>
                </ActionIcon>
              }
              title={word_translation.cost_center}
            />
            <Select
              labelProps={{ className: 'input_title' }}
              onChange={onCostCenterIDChange}
              rightSection={
                <ActionIcon size="xs" onClick={onCostCenterIDClear}>
                  <IconX />
                </ActionIcon>
              }
              placeholder={word_translation.cost_center}
              searchable
              key={costCenter_id}
              size="xs"
              defaultValue={costCenter_id}
              data={notAllCostCenterData}
            />
          </Grid.Col>

          <Grid.Col span={6}>
            <TableTextInputTitle title={word_translation.description} />
            <TextInput
              size="xs"
              value={description}
              onChange={value => updateData('description', value.target.value)}
              placeholder={word_translation.Input_max_255}
            />
          </Grid.Col>
        </Grid>

        <Grid gutter={20}>
          <Grid.Col span={6}>
            <TableTextInputTitle title={word_translation.permission_role} />
            <Select
              labelProps={{ className: 'input_title' }}
              rightSection={
                <ActionIcon size="xs" onClick={onIdentiferClear}>
                  <IconX />
                </ActionIcon>
              }
              placeholder={word_translation.pick_one}
              searchable
              key={identifier}
              creatable={true}
              getCreateLabel={query => `+ Create ${query}`}
              onCreate={query => {
                const item = { value: query, label: query }
                setRoleList(current => [...current, item])
                return item
              }}
              size="xs"
              onChange={onIdentiferChange}
              value={identifier}
              data={roleList}
            />
          </Grid.Col>

          {/* role costCenter */}
          {/* if costCenter_id == -2 display element, 
            then change costCenter_id = role_costCenter_id, role_costCenter_id = -2 
          */}
          {costCenter_id == '-2' && (
            <Grid.Col span={6}>
              <TableTextInputTitle
                icon_r={
                  <ActionIcon onClick={open} size="xs">
                    <IconPlus size={14}></IconPlus>
                  </ActionIcon>
                }
                title={word_translation.select_cost_center}
              />
              <MultiSelect
                labelProps={{ className: 'input_title' }}
                onChange={v => updateData('role_costCenter_id', v)}
                rightSection={
                  <ActionIcon size="xs" onClick={onCostCenterIDClear}>
                    <IconX />
                  </ActionIcon>
                }
                placeholder={word_translation.select_cost_center}
                searchable
                // key={role_costCenter_id.toString()}
                size="xs"
                value={role_costCenter_id}
                data={costCenterData}
              />
            </Grid.Col>
          )}
        </Grid>

        <Group mt={5} position="apart">
          <Checkbox
            mt={10}
            size="xs"
            label={<TableTextInputTitle py={0} title={word_translation.active} />}
            checked={active == '1' ? true : false}
            onChange={event => updateData('active', event.currentTarget.checked ? '1' : '0')}
          />
          <ResetBtn callBack={resetFunction} />
        </Group>

        <CostCenterForm
          callBackId={value => {
            clearRole()
            updateData('costCenter_id', value.toString())
          }}
          openFormState={opened}
          closeFormModal={close}
          refreshFunction={() => 1}
          costCenter={{
            city: '',
            name: '',
            street1: '',
            id_costcenter: '',
            cost_center_identifier: '',
            postalCode: '',
            street2: ''
          }}
        />
      </Paper>
    </Paper>
  )
}
