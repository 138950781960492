import { hasLength, matches, useForm } from "@mantine/form";
import {
  TextInput,
  Box,
  Flex,
  Paper,
  Select,
  useMantineColorScheme,
  Checkbox,
  Card,
  Group,
  Grid,
  Switch,
  Code,
  Textarea
} from "@mantine/core";
import { FormEvent, useEffect, useRef, useState } from "react";
import { regGlobalSetting } from "../../../../api";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconX } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import globalCore from "../../../../utils/globalCortSetting";
import localstorageUnits from "../../../../utils/localstorageUnits";
import SaveBottom from "../../../../ttcomponents/Button/SaveBtn";
import { DatePicker, DatePickerInput } from "@mantine/dates";
import { HintErrorMantineInfo, HintInfo, formatDateUpgraded } from "../../../../utils/function";
import GlobalCoreSetting from "../../../../utils/globalCortSetting";
import { domainsRegex, ipRegex, validateDomainString } from "../../../../utils/validataUnits";
import TableTopTitle from "../../../../ttcomponents/TableTopTitle/TableTopTitle";
import WordTranslationComponent from "../../../../utils/language_pack/words";
import TableTextInputTitle from "../../../../ttcomponents/TableTextInputTitle/TableTextInputTitle";
import { ComponentInterface } from "../../../../interface/Icommon";
import { useIntl } from "react-intl";
import SubmitBtn from "../../../../ttcomponents/Button/SubmitBtn";
import { getGlobalSettingGeneralFunction } from "../../../../api/common_request";
import { CheckPermission } from "../../../../utils/permission/permission";
import { NotPermission } from "../../../../ttcomponents/NoPermission";
import CheckPermissionTools from "../../../../utils/permission";

export default function GlobalCoreSettings({ is_load_str, }: ComponentInterface) {


  const Perm = CheckPermissionTools();
    //-------------permission list allocation-------------

const isEditStatus = CheckPermission('global_seting.create')
const isDeleStatus = CheckPermission('global_seting.delete')
const isCreateStatus = CheckPermission('global_seting.update')
const isReadStatus = CheckPermission('global_seting.read')

//------------- permission list allocation-------------
  const [rand_number, setRandNumber] = useState<number>(1);

  // Get the default unchanged configuration
  const globalCoreSetting = GlobalCoreSetting();
  // Get translation public configuration information
  const word_translation = WordTranslationComponent();
  const [assignmentModa, setAssignmentsModa] = useState<string[]>([]);
  const formRef = useRef<HTMLFormElement>(null);
  const form = useForm({
    validateInputOnBlur: true,
    initialValues: {
      licenceTypes_id: 0,
      databaseIP: "",
      init_use_date: new Date(),
      OnPremise: false,
      id_globalsettings: 0,
      time_tracker_editable_days_limit:30,
      licenceTypes: "",
      date_format: "",
      default_lang: "",
      leave_module: {
        display_vacation_entitlement:'0',
        display_special_days:'0',
        display_latest_request:'0',
        display_sick_days:'0',
      },
      assignment_modas: "",
      domains: "",
    },
    // functions will be used to validate values at corresponding key
    validate: {
      domains: matches(domainsRegex, `${word_translation.domains}${word_translation.validata_phone}`),
      databaseIP: matches(ipRegex, `${word_translation.global_database_ip}${word_translation.validata_ip}`),
      licenceTypes: hasLength({ min: 2, max: 100 }, `${word_translation.global_licence_type}${word_translation.validata_value_need_2_100_length}`),
 
    },
  });

  /**
   * Get currency settings informationency settings information
   */
  const ajaxCurrentList = async () => {
    if (is_load_str === "global_core") {
      const result  = await getGlobalSettingGeneralFunction();
      if (result) {
        form.setFieldValue("databaseIP", result.databaseIP);
        form.setFieldValue("OnPremise", result.OnPremise == "1");
        form.setFieldValue("licenceTypes", result.licenceTypes);
        form.setFieldValue("licenceTypes_id", result.licenceTypes_id);
        form.setFieldValue("id_globalsettings", result.id_globalsettings);
        form.setFieldValue("date_format", result.date_format);
        form.setFieldValue("default_lang", result.default_lang);
        
        form.setFieldValue("leave_module.display_vacation_entitlement", result.leave_module?.display_vacation_entitlement);
        form.setFieldValue("leave_module.display_special_days", result.leave_module?.display_special_days);
        form.setFieldValue("leave_module.display_latest_request", result.leave_module?.display_latest_request);
        form.setFieldValue("leave_module.display_sick_days", result.leave_module?.display_sick_days);

        form.setFieldValue("assignments_modes", result.assignments_modes);
        form.setFieldValue("init_use_date", new Date(result.init_use_date));
        form.setFieldValue("domains", result.domains);
        form.setFieldValue("time_tracker_editable_days_limit", result.time_tracker_editable_days_limit);
        form.setFieldValue("assignment_modas", result.assignments_modes);
        setAssignmentsModa(result.assignments_modes.split(','))
        setRandNumber(rand_number + 1)
      } else {
        // console.log(result.msg);
      }
    }
  };

  const setAssignmentsModaExpandFunction=(value:any) =>
  { 
    if(Array.isArray(value)){
      setAssignmentsModa(value)
      form.setFieldValue('assignment_modas',value.join(','))
    }else if(value && typeof  value === 'string' ){
      setAssignmentsModa(value.split(","))
    }
  }
  const handleFormSubmit = async () => {

    const valid = await form.validate();

    let originalData = {
      OnPremise: form.values.OnPremise,
      databaseIP: form.values.databaseIP,
      licenceTypes: form.values.licenceTypes,
      date_format: form.values.date_format,
      leave_module: form.values.leave_module,
      time_tracker_editable_days_limit: form.values.time_tracker_editable_days_limit,
      assignments_modes:form.values.assignment_modas,
      domains: form.values.domains,
      init_use_date: formatDateUpgraded(
        new Date(form.values.init_use_date),
        "YYYY-MM-DD"
      ),
      default_lang: form.values.default_lang,
    };
    
    // The database only receives INT, so set the value of the OnPremise based on the value of chenkbox
    let formData = {};
    if (form.values.OnPremise === false) {
      formData = { ...originalData, OnPremise: 0 };
    } else {
      formData = { ...originalData, OnPremise: 1 };
    }

    // Based whether there is an ID decide whether it is created or updated
    formData = form.values.id_globalsettings
      ? { ...formData, id: form.values.id_globalsettings }
      : formData;

    const methodType = form.values.id_globalsettings ? "PUT" : "POST";
    //Data verification and sending request
    if (!valid.hasErrors) {
      if (validateDomainString(form.values.domains) === false) {
        return false
      }
      const response = await regGlobalSetting(formData, methodType);
      HintInfo(response.data);
      if (response.data.code == 200) {
        const Settingresponse = await regGlobalSetting({}, "GET");
        localstorageUnits.saveSystem(Settingresponse.data.data);
        window.location.reload();
      }
    } else {
      HintErrorMantineInfo(valid)
    }
  };
  useEffect(() => {
    ajaxCurrentList();
  }, [is_load_str]);


  return (
    <Paper>
       {Perm.global_seting_read ?   <Box >
        <form onSubmit={handleFormSubmit} ref={formRef}>
        <Flex
            gap="md"
            justify="flex-start"
            direction="row"
            wrap="wrap"
          >
         
          <Box w={700} className="border-radius-xs shadow-sm  border-custom">
            <TableTopTitle title={word_translation.syetem_info} />
            <Card radius={15} padding="lg" >
              <Grid gutter={0}   >
                {/* General Informations */}
                <Grid.Col span={12} >
                  <Grid>
                    <Grid.Col span={6} >
                      <TableTextInputTitle title={word_translation.global_database_ip} />
                      <TextInput
                        placeholder={word_translation.Input}
                        {...form.getInputProps("databaseIP")}
                      />
                      <TableTextInputTitle py={10} title={word_translation.global_licence_type} />
                      <TextInput
                        placeholder={word_translation.Input}
                        {...form.getInputProps("licenceTypes")}
                      />
                      <TableTextInputTitle title={word_translation.global_language} />
                      <Select
                        key={rand_number}
                        placeholder={word_translation.pick_one}
                        defaultValue={form.values.default_lang}
                        onChange={(value) =>
                          form.setFieldValue("default_lang", value as string)
                        }
                        data={globalCoreSetting.globalLanguageList}
                      />
                    </Grid.Col>

                    <Grid.Col span={6} >
                      <TableTextInputTitle title={word_translation.global_time_format} />
                      <Select
                        data={globalCoreSetting.globalTimeFormatList}
                        placeholder={word_translation.pick_one}
                        onChange={(value) =>
                          form.setFieldValue("date_format", value as string)
                        }
                        key={form.values.date_format}
                        defaultValue={form.values.date_format}
                      />

                      <TableTextInputTitle py={10} title={word_translation.use_system_time} />
                      <DatePickerInput
                        placeholder={word_translation.pick_one}
                        mx="auto"
                        {...form.getInputProps("init_use_date")}
                      />
                      <TableTextInputTitle title={word_translation.time_tracker_editable_days_limit} />
                      <TextInput
                        radius={5}
                        placeholder={word_translation.time_tracker_editable_days_limit}
                        {...form.getInputProps("time_tracker_editable_days_limit")}
                      />
                    </Grid.Col>
                    
                  </Grid>
                </Grid.Col>
              </Grid>

              <Grid gutter={0} >
                <Grid.Col span={10} >
                  <TableTextInputTitle py={10} title={word_translation.task_mode_flag} />
                  {/* assignments_modes */}
                  <Switch.Group
                    defaultValue={['react']}
                    mt={10}
                    withAsterisk
                    value={assignmentModa}  onChange={(value)=>setAssignmentsModaExpandFunction(value)}
                  >
                    <Grid>
                      {globalCoreSetting.globalTaskModeList.map((item,index) => (
                        <Grid.Col span={6} key={index}>
                          <Switch key={rand_number} value={item.value} label={item.label} />
                        </Grid.Col>
                      ))}
                    </Grid>
                  </Switch.Group>

                </Grid.Col>
              </Grid>
              <Grid gutter={0} >
                <Grid.Col span={12} >
                  <TableTextInputTitle py={10} title={word_translation.domain_used} />
                  <Textarea
                    minRows={6}
                    placeholder={word_translation.domains_string}
                    {...form.getInputProps("domains")}
                  />
                </Grid.Col>
              </Grid>

              <Grid py={10} gutter={0} >
                <Grid.Col span={6} >
                  <Checkbox
                    label= {<TableTextInputTitle title={word_translation.global_on_premise} />}
                    checked={form.values.OnPremise}
                    {...form.getInputProps("OnPremise", { type: "checkbox" })}
                  />
                </Grid.Col>
              </Grid>
              <Group position="right">
                <SubmitBtn disabled={!Perm.global_seting_update} btn_size="xs" callBack={handleFormSubmit}/>
              </Group>
            </Card>
          </Box>
          <Box w={500}>
            <Box  className="border-radius-xs shadow-sm  border-custom">
              <TableTopTitle title={word_translation.leave_info} />
                <Card radius={15} padding="lg" >
                    <Checkbox
                      label={<TableTextInputTitle  py={-15} title={`Display ${word_translation.vacation_entitlement}`}/>}
                      checked={form.values.leave_module.display_vacation_entitlement == "1" ? true :false}
                      onChange={(event) =>{
                        form.setFieldValue('leave_module.display_vacation_entitlement',event.currentTarget.checked ?  "1":"0")
                      }}
                    />
                    <Checkbox
                    label={<TableTextInputTitle  py={-15} title={`Display ${word_translation.sick_days}`} />}
                    checked={form.values.leave_module.display_sick_days == "1" ? true :false}
                    onChange={(event) =>{

                      form.setFieldValue('leave_module.display_sick_days',event.currentTarget.checked ?  "1":"0")
                    }}
                  />
                      <Checkbox
                      label={<TableTextInputTitle  py={-15} title={`Display ${word_translation.special_days}`} />}
                      checked={form.values.leave_module.display_special_days == "1" ? true :false}
                      onChange={(event) =>{
                        form.setFieldValue('leave_module.display_special_days',event.currentTarget.checked ? "1":"0")
                      }}
                    />
                      <Checkbox
                      label={<TableTextInputTitle  py={-15} title={`Display ${word_translation.latest_request}`} />}
                      checked={form.values.leave_module.display_latest_request == "1" ? true :false}
                      onChange={(event) =>{
                        form.setFieldValue('leave_module.display_latest_request' ,event.currentTarget.checked ? "1":"0")
                      }}
                    />
                </Card>           
            </Box>
          </Box>
          </Flex>
        </form>
      </Box>  : <NotPermission />}
                      {/* <Code block 
                      >{JSON.stringify(form.values,null,2)}</Code> */}
    </Paper>
  );
}
