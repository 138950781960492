import { Box, Card, Modal, Group, LoadingOverlay, Flex, Paper } from '@mantine/core'
import { regCustomerRole } from '../../../api'
import { useEffect, useRef, useState } from 'react'
import TableUniversal from '../../../ttcomponents/Table/TableUniversal'
import PaginationA from '../../../ttcomponents/Pagination/PaginationA'
import { useDisclosure } from '@mantine/hooks'
import ContactRowForm from './contact_role_form'
import { IPagination } from '../../../interface/Ipagintion'
import { useSelector } from 'react-redux/es/hooks/useSelector'
import { RootState } from '../../../state_management/store'
import CreateBtn from '../../../ttcomponents/Button/CreateBtn'
import PageTitle from '../../../ttcomponents/PageTitle/PageTitle'
import WordTranslationComponent from '../../../utils/language_pack/words'
import { ContactRole } from '../../../interface/Icoustomer'
import { HintInfo } from '../../../utils/function'
import ModalTitleText from '../../../ttcomponents/ModalTitleText/ModalTitleText'
import MoreActionBtnWithBottom from '../../../ttcomponents/MoreActionBtnWithBottom/MoreActionBtnWithBottom'

interface ApiResponse {
  code: number
  data: {
    data: ContactRole[]
    filtered_count: number
    total_count: number
  }
  msg: string
}
export default function ContactRoleIndex() {
  // Get translation public configuration information
  const word_translation = WordTranslationComponent()

  // Show more switch settings
  const [moreOpened, setMoreOpened] = useState(false)
  // 获取redux store中的值
  const SearchValue = useSelector((state: RootState) => state.search.searchValue)

  const [rand_number, setRandumber] = useState(1)

  //加载中状态
  const [loading, setLoading] = useState(false)
  //表单title 初始化
  const [formTitle, setFormTitle] = useState('create')

  const [contactRoleata, setContactRoleata] = useState<ContactRole[]>([])
  const [formRoleData, setFormRoleData] = useState<ContactRole>({
    id_customer_roles: 0,
    role_name: '',
    role_desc: ''
  })
  const [custListCount, setCustListCount] = useState<ApiResponse | null>(null)
  const [openFormState, { open: openFormModal, close: closeFormModal }] = useDisclosure(false)
  const [pagination, setPagination] = useState<IPagination>({
    search: '',
    page: 1,
    pagesize: 10,
    sortBy: '',
    sortOrder: 'asc'
  })

  // Utilizing RTK to implement search
  const ReceiveSearchBack = (value: string | number) => {
    // Update pagination state with the new search value
    setPagination(prevPagination => ({
      ...prevPagination,
      search: value
    }))
  }

  // Execute the useeffect when the first load is loaded.
  const isInitialMount = useRef(true)
  useEffect(() => {
    if (!isInitialMount.current) {
      ReceiveSearchBack(SearchValue)
    }
    isInitialMount.current = false
  }, [SearchValue])

  /**
   * Fetches the list of user registrations from the server.
   * Makes a GET request to the 'regCustomerRole' endpoint with pagination parameters.
   * Updates component state based on the response.
   */
  const ajaxCurrentList = async () => {
    // Set loading state to true while fetching data
    setLoading(true)

    try {
      // Make a GET request to retrieve user registrations with pagination parameters
      const response = await regCustomerRole(pagination, 'GET')
      const result = response.data as ApiResponse

      // Check if the response is successful and the data is not an array
      if (result.code === 200 && !Array.isArray(result.data)) {
        // Update the total count and user registration data in component state
        setCustListCount(result)
        setContactRoleata(result.data.data)
      } else {
        // Log an error message if the response indicates an issue
        console.error(result.msg)
      }
    } catch (error) {
      // Log any errors that occur during the request
      console.error('Error fetching user registrations:', error)
    } finally {
      // Set loading state to false regardless of success or failure
      setLoading(false)
    }
  }

  /**
   * Effect hook to automatically fetch user registrations when the 'pagination' parameter changes.
   */
  useEffect(() => {
    ajaxCurrentList()
  }, [pagination])

  // 分页回调触发更新 分页回调触发更新
  const ReceivePaginationABack = (page: number) => {
    setPagination(prevpagination => ({
      ...prevpagination,
      page: page
    }))
  }

  const receiveNotifications = (result: boolean) => {
    closeFormModal()
    setFormRoleData({ id_customer_roles: 0, role_name: '', role_desc: '' })
    ajaxCurrentList()
  }

  const handleOnValueEdit = (value: ContactRole) => {
    setFormTitle('update')
    setRandumber(rand_number + 1)
    openFormModal()
    setFormRoleData(value)
  }

  /**
   * Function to handle the deletion of a value.
   * @param id - ID of the value to be deleted
   */
  const handleOnValueDel = async (id: string) => {
    // Send a DELETE request to delete the value with the specified ID
    const response = await regCustomerRole({ id: id }, 'DELETE')
    // Call the HintInfo method with response.data as a parameter
    HintInfo(response.data)
  }

  /**
   * Open the form for the current page.
   */
  const openCurrentPageForm = () => {
    // Set the form title to "create"
    setFormTitle('create')
    // Set the role data to an empty object
    setFormRoleData({ id_customer_roles: '', role_name: '', role_desc: '' })
    // Open modal 1
    openFormModal()
  }

  return (
    <Paper px={20}>
      <LoadingOverlay visible={loading} overlayBlur={2} />
      <Box pb={20}>
        <PageTitle title={word_translation.contact_management} />
        <Group mt={10}>
          <Modal
            opened={openFormState}
            onClose={closeFormModal}
            centered={true}
            padding="md"
            radius={12}
            title={
              <ModalTitleText
                title={
                  formTitle === 'create'
                    ? word_translation.create_customer_roles
                    : `${word_translation.update_user_role}:${formRoleData.role_name}`
                }
              />
            }
          >
            <ContactRowForm rand_number={rand_number} formRoleData={formRoleData} callBack={receiveNotifications} />
          </Modal>
        </Group>

        <TableUniversal
          onValueEdit={handleOnValueEdit}
          onValueDel={handleOnValueDel}
          callBack={receiveNotifications}
          roles={contactRoleata}
        />

        <PaginationA
          callBack={ReceivePaginationABack}
          total_count={custListCount?.data.total_count as number}
          pagesize={pagination.pagesize}
          page={pagination.page}
        />
      </Box>

      {moreOpened && (
        <Paper className="positioned-right-bottom-content" shadow="sm">
          <Card>
            <Flex
              className="border-radius-md"
              mih={50}
              gap="md"
              justify="flex-end"
              align="flex-end"
              direction="column"
              wrap="wrap"
            >
              <CreateBtn callBack={openCurrentPageForm} />
            </Flex>
            <Group noWrap>{/* Add more elements if needed */}</Group>
          </Card>
        </Paper>
      )}
      <MoreActionBtnWithBottom moreOpened={moreOpened} callBack={setMoreOpened} />
    </Paper>
  )
}
