import { Card, Text,Table } from "@mantine/core";
import { TimeTrackerInterface } from "../../../interface/Itimetrack";

import WordTranslationComponent from "../../../utils/language_pack/words";
import React from 'react';
import { ConvertTimeStringToDecimal, formatTimeHHSS } from "../../../utils/function";
import TableTextInputTitle from "../../../ttcomponents/TableTextInputTitle/TableTextInputTitle";
interface ComponentInterface {
    timeTracker: TimeTrackerInterface | undefined; // Array of MonthlyTotal objects or undefined
    weekDate:any[];
}

export default function WeekDetail({ timeTracker ,weekDate}: ComponentInterface) {
    // Get translation public configuration information
    const word_translation = WordTranslationComponent();
    return (
        <Card mt={20} bg='#eee'>
         {timeTracker ?
          <>
            <TableTextInputTitle py={10} title='Personal weekly records under the project' />
            <Table withBorder withColumnBorders striped highlightOnHover>
              <thead>
                <tr>
                  <th>   {word_translation.mon}  <Text fw={400} color="red" size={12}>({weekDate[0]})</Text></th>
                  <th>   {word_translation.tues}  <Text fw={400} color="red" size={12}>{weekDate[1]}</Text></th>
                  <th>   {word_translation.weds}  <Text fw={400} color="red" size={12}>{weekDate[2]}</Text></th>
                  <th>   {word_translation.thurs} <Text fw={400} color="red" size={12}>{weekDate[3]}</Text> </th>
                  <th>   {word_translation.fri}  <Text fw={400} color="red" size={12}>{weekDate[4]}</Text></th>
                  <th>   {word_translation.sat}  <Text fw={400} color="red" size={12}>{weekDate[5]}</Text></th>
                  <th>   {word_translation.sun} <Text fw={400} color="red" size={12}>{weekDate[6]}</Text> </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td> {ConvertTimeStringToDecimal(formatTimeHHSS(timeTracker?.mon as string))} {word_translation.hour}</td>
                  <td> {ConvertTimeStringToDecimal(formatTimeHHSS(timeTracker?.tues as string))} {word_translation.hour}</td>
                  <td> {ConvertTimeStringToDecimal(formatTimeHHSS(timeTracker?.weds as string))} {word_translation.hour}</td>
                  <td> {ConvertTimeStringToDecimal(formatTimeHHSS(timeTracker?.thurs as string))} {word_translation.hour}</td>
                  <td> {ConvertTimeStringToDecimal(formatTimeHHSS(timeTracker?.fri as string))} {word_translation.hour}</td>
                  <td> {ConvertTimeStringToDecimal(formatTimeHHSS(timeTracker?.sat as string))} {word_translation.hour}</td>
                  <td> {ConvertTimeStringToDecimal(formatTimeHHSS(timeTracker?.sun as string))} {word_translation.hour}</td>
                </tr>
                <tr>
                  <th colSpan={7}>   {word_translation.task_name} : {timeTracker?.task_name as string}</th>

                </tr>

              </tbody>
            </Table>  </> : <Text c='red' size={13} mt={10}> Click on CW and you will see the weekly record</Text>}
        </Card>
    )
}
