import { ActionIcon, Box, Text, Group, Select, TextInput } from "@mantine/core";
import { IconSearch, IconX } from "@tabler/icons-react";
import { forwardRef, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { regGetProduct, regGetProductId, regGetSupplier } from "../../api";
import { Supplier, SuppliersData } from "../../interface/Iinventory";
import { SelectRole } from "../../interface/Icommon";
import { ProductOrder, Purchase } from "../../public/svg/ProductOrder";
import { Product } from "../../interface/IProduct";

// 定义接口，接收一个函数操作 Define the interface and receive a function operation
interface SupplierSelectBoxProps {
  callBack: (row: any) => void;
  currentId?: string;
  width?: number | string;
  disabled: boolean;
  createNew: boolean;
  labelStr?: string;
}

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  image: string;
  label: string;
  value: string;
  supplier_id: string;
  unit_price: string;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ image, label, unit_price, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        {image}
        <div>
          <Text size="sm">{label}</Text>
          <Text size="xs" opacity={0.65}>
            {unit_price != null
              ? " unit_price:" + unit_price
              : "This product has not been purchased in the warehouse"}
          </Text>
        </div>
      </Group>
    </div>
  )
);

export default function ProductSelect({
  callBack,
  currentId,
  labelStr,
  width,
  disabled,
  createNew,
}: SupplierSelectBoxProps) {
  // 钩子函数-切换语言
  const intl = useIntl();

  const [rand, setRand] = useState(0);

  const [data, setData] = useState<ItemProps[]>([]);

  const [dataList, setListData] = useState<SelectRole[]>([]);

  const [productID, setProductID] = useState("");
  const [productName, setProductName] = useState("");
  const [productTip, setProductTip] = useState(
    "Open the interface of the added product"
  );
  const [unitPrice, setUnitPrice] = useState("");

  const ajaxCurrentList = async () => {
    // setLoading(true);
    const responseProduct = await regGetProduct({});
    // setLoading(false);
    if (responseProduct.data.code == 200) {
      // Assembly product
      const products = responseProduct.data.data.data.map((item: Product) => ({
        image: <ProductOrder />,
        label: item.product_name,
        value: item.product_id,
        unit_price: item.unit_price,
        supplier_id: item.supplier_id,
      }));
      setData(products);
    }
  };

  const setProductNameFunction = async (string: string) => {
    if (typeof string != "number") {
      const responseProduct = await regGetProductId({ product_name: string });
      if (responseProduct.data.code == 200) {
        console.log(typeof responseProduct.data.data.product_id);
        setProductID(responseProduct.data.data.product_id);
      }
      setProductName(string);
    } else {
      // 使用 filter 方法过滤出 product_id 为 targetProductId 的信息
      const filteredData = data?.filter((item) => item.value === string);

      setProductID(string);
      if (filteredData && filteredData.length > 0) {
        console.log(filteredData[0]);
        setProductName(filteredData[0].label);
      }

      callBack({
        value: string,
        label: filteredData[0].label,
        supplier_id: filteredData[0].supplier_id,
        unit_price:
          filteredData[0].unit_price != null
            ? filteredData[0].unit_price
            : "0.00",
        type: "product",
      });
    }
  };
  // function setSelectValueFunction(value: string | null): void {
  //   const row =  dataList.filter((item,index)=>{return item.value == value })
  //   callBack(row[0])
  //   }
  //   // Clear conditions
  // function  clearConditionFunction(): void {
  //   setRand(rand+1)
  //   callBack( {
  //       value: '',
  //       label:'',
  //       type: 'supplier',
  //     })
  //   }
  // Page initialization function

  useEffect(() => {
    ajaxCurrentList();
  }, []);
  if (createNew) {
    return (
      <>
        {" "}
        <Select
          placeholder={intl.formatMessage({
            id: "Product Name",
            defaultMessage: "Product Name",
          })}
          itemComponent={SelectItem}
          data={data}
          defaultValue={currentId}
          searchable
          label={labelStr}
          disabled={disabled}
          creatable
          onChange={(event) => setProductNameFunction(event as string)}
          nothingFound="Nobody here"
          getCreateLabel={(query) => `+ Create ${query}`}
          onCreate={(query) => {
            const item = {
              image: <Purchase />,
              label: query,
              value: query,
              unit_price: "warehouse needs purchasing",
            };
            setData((current) => [...(current as any), item]);
            return item;
          }}
          filter={(value, item) =>
            (item.label &&
              item.label.toLowerCase().includes(value.toLowerCase().trim())) ||
            item.unit_price.toLowerCase().includes(value.toLowerCase().trim())
          }
        />
      </>
    );
  } else {
    return (
      <>
        {" "}
        <Select
          placeholder={intl.formatMessage({
            id: "Product Name",
            defaultMessage: "Product Name",
          })}
          itemComponent={SelectItem}
          data={data}
          defaultValue={currentId}
          searchable
          label={labelStr}
          disabled={disabled}
          onChange={(event) => setProductNameFunction(event as string)}
          nothingFound="Nobody here"
        />
      </>
    );
  }
}
