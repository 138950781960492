import { Container, Title, Text, Button, Group } from '@mantine/core';
// import { Illustration } from './Illustration';
import classes from './NothingFoundBackground.module.css';
import React from 'react';

export function Development(){

    return (
      <Container pt={100} className='text-center' >
      <div className={classes.inner}>
        {/* <Illustration className={classes.image} /> */}
        <div className={classes.content}>
          <Title className={classes.title}> In development</Title>
          <Text c="dimmed" size="lg" ta="center" className={classes.description}>
          This content is under development
          </Text>
        </div>
      </div>
    </Container>
    )
}