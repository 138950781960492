
import {
    Paper,
    Box,
    Group,
    Text,
    Modal,
    Grid,
    Card,
    ActionIcon,
    LoadingOverlay,
    Tooltip,
    Badge,
  } from "@mantine/core";
  import { FormEvent, useEffect, useState,useRef } from "react";
  import { Supplier, SuppliersData } from "../../../interface/Iinventory";
  import { useClickOutside, useDisclosure } from "@mantine/hooks";
 
  import PageTitle from "../../../ttcomponents/PageTitle/PageTitle";
  import {
    IconCircleX,
    IconDots,
    IconDotsVertical,
    IconEdit,
    IconHome,
    IconPlus,
    IconTrash,
    IconX,
  } from "@tabler/icons-react";
  import CreateBtn from "../../../ttcomponents/Button/CreateBtn";
  import { useIntl } from "react-intl";
  import { modals } from "@mantine/modals";
  import { mockapi1, regGetSupplier, regGetUserRoleList, regSupplier, regVacationCategory } from "../../../api";
  import { HintInfo, getOnboardingStatusLabel } from "../../../utils/function";
  import SearchBox from "../../../ttcomponents/SearchBox/SearchBox";
  import { IPagination } from "../../../interface/Ipagintion";
  import PaginationA from "../../../ttcomponents/Pagination/PaginationA";
  import { NewBoardingListData, NewBoardingUserData, UserRoleData, UserRoleList } from "../../../interface/Iuser";

  import GlobalCoreSetting from "../../../utils/globalCortSetting";
import ModalTitleText from "../../../ttcomponents/ModalTitleText/ModalTitleText";
import WordTranslationComponent from "../../../utils/language_pack/words";
import { ItopSearch } from "../../../interface/Icommon";
import LeaveCotegoryForm from "./leave_category_form";
import { IcategoryInteface } from "../../../interface/Ivaction";
import EditIcon from "../../../ttcomponents/EditIcon/EditIcon";
import DeleteIcon from "../../../ttcomponents/DeleteIcon/DeleteIcon";
import CheckPermissionTools from "../../../utils/permission";

  export default function LeaveCotegory({searchValue}:ItopSearch) {
    const [moreOpened, setMoreOpened] = useState(false);
    
    // Get translation public configuration information
    const word_translation = WordTranslationComponent();
    // Edit modal status
    const [openCategoryFrom, { open: openEdit, close: closeCategoryFrom }] =
      useDisclosure(false);
      
    const Perm = CheckPermissionTools();
    
    const [loading, setLoading] = useState(false);
    const [totalNumber, setTotalNumber] = useState(0);
    // Form type
    const [formType, setFormType] = useState("create");
    const [currentRowInfo, setCurrentRowInfo] = useState<IcategoryInteface | undefined>();
    const [currentRowInfoList, setCurrentRowInfoList] = useState<IcategoryInteface[]>([]);
  

  
    const [pagination, setPagination] = useState<IPagination>({
        search: "",
        page: 1,
        pagesize: 10,
        sortBy: "",
        sortOrder: "desc",
      });

  // 接收回调返回数据，搜索内容  Receive the callback return data, search for content
  const ReceiveSearchBack = (value: string | number) => {
    setPagination((prevpagination) => ({
      ...prevpagination,
      search: value as string,
      page: 1,
    }));
  };
    
  // Execute the useeffect when the first load is loaded.
  const isInitialMount = useRef(true);
  useEffect(() => {
    if (!isInitialMount.current) {
      ReceiveSearchBack(searchValue);
    }
    isInitialMount.current = false;
  }, [searchValue]);

    // 分页回调触发更新 分页回调触发更新
    const ReceivePaginationABack = (page: number) => {
      setPagination((prevpagination) => ({
        ...prevpagination,
        page: page,
      }));
    };

    // Define an asynchronous function ajaxcurrenTlist to send AJAX request to obtain the list of suppliers
    const ajaxCurrentList = async () => {
      setLoading(true); // Set the loading status to TRUE, indicating that the data is loading
      // Send GET requests to get supplier data
      const responseSupplier = await regVacationCategory(pagination, "GET");
  
      setLoading(false); // After the data is loaded, set the loading status to false
      if (responseSupplier.data.code === 200) {
        setCurrentRowInfoList(responseSupplier.data.data.data); // 如果响应状态码为200，将供应商列表更新为响应数据中的数据
        setTotalNumber(responseSupplier.data.data.total_count); // 如果响应状态码为200，将供应商列表更新为响应数据中的数据
        
      }
    };
    // Use the USEEFFECT hook. When Search changes, call the AjaxCurrenTList function
    useEffect(() => {
      ajaxCurrentList();
    }, [pagination]);
  
    // Edit supplier
    const handleEdit = (row: IcategoryInteface) => {
      setFormType("edit");
      openEdit();
      setCurrentRowInfo(row);
    };
  
    // Delete user role
    const handleDel =  (row: IcategoryInteface) => {
      modals.openConfirmModal({
        title: word_translation.delete_title,
        centered: true,
        children: (
          <Text size="sm" mt={12}>
              {word_translation.category_name} : {row.name}
          </Text>
        ),
        labels: {
          confirm: word_translation.confirm_delete,
          cancel:word_translation.cancel_delete,
        },
        confirmProps: { color: "red" },
        onConfirm: async() => {
            const SupplierResult = await regVacationCategory({ id: row.id_leave_category }, "DELETE");
            HintInfo(SupplierResult.data);
            if (SupplierResult.data.code == 200) {
              ajaxCurrentList();
            }
        },
      });
    };
  

    // Create supplier
    const handleCallback = () => {
      setFormType("create");
      openEdit();
      setCurrentRowInfo(undefined);
    };
  

  
    // Create a row for each supplier
    const rows = currentRowInfoList.map((row) => (
      <tr key={row.id_leave_category} className="border-bt-2 my-10 ">
      <td>{row.id_leave_category}</td>
      <td>{row.name}</td>
      <td>
          <Group spacing={1} position="center">
             <EditIcon disabled={Perm.leave_category_read == false} callBack={() => handleEdit(row)} />
             <DeleteIcon disabled={Perm.leave_category_delete == false} callBack={() => handleDel(row)} />
          </Group>
        </td>
      </tr>
    ));

    const closeCategoryFromFunction = (type: number) => {
      closeCategoryFrom();
      if (type == 2) {
        ajaxCurrentList();
      }
    };
    // list all the suppliers
    return (
     <>
      <Box>
        <Paper px={20} pb={10} w={"100%"} style={{ position: "relative" }}>
          <PageTitle title="NEW - BOARDINGS" lang_id="new_boarding" />
          <Grid gutter={0} mt={10}>
            <LoadingOverlay visible={loading} overlayBlur={2} />
            <table className="table_custome text-center">
              <thead  className="table_th">
              <tr>
                <th>{word_translation.id}</th>
                <th>{word_translation.category_name}</th>
                <th>{word_translation.option}</th>
                </tr>
              </thead>
              <tbody>
                 {rows}
              </tbody>
            </table>
          </Grid>
          <PaginationA
            callBack={ReceivePaginationABack}
            total_count={totalNumber}
            pagesize={pagination.pagesize}
            page={pagination.page}
          />
         <>

    
    </>
        </Paper>
      </Box>   
      <Modal
        opened={openCategoryFrom}
        onClose={closeCategoryFrom}
        title={<ModalTitleText   title={ formType === "create" ?  word_translation.create_leave_category : word_translation.update_leave_category } /> }
        centered={true}
        padding="md"
        radius={12}
      >
        <LeaveCotegoryForm
          CotegoryItem={currentRowInfo}
          closeFunction={closeCategoryFromFunction}
        />
      </Modal>
            {moreOpened && (
                <Paper className="positioned-right-bottom-content" shadow="sm">
                    <Card>
                        <CreateBtn callBack={handleCallback} />
                    </Card>
                </Paper>
            )}
            <ActionIcon size={30}  variant="outline" className="positioned-right-bottom" color="blue"  onClick={() => setMoreOpened(!moreOpened)}>
              <Tooltip label={word_translation.more_tips}>
                 {moreOpened? <IconX color="red" size="1.8rem" />:<IconDots  size="1.8rem" />}
              </Tooltip>
            </ActionIcon> </>
    
    );
  }
  