import {
  Card,
  Divider,
  Flex,
  Grid,
  Group,
  RingProgress,
  Text,
  createStyles,
  rem,
} from "@mantine/core";
import { IconRefreshDot } from "@tabler/icons-react";
import { FormattedMessage, useIntl } from "react-intl";
import { regBoardMostRecentProject, regProjectAllBoard } from "../../api";
import { FormEvent, useEffect, useState } from "react";
import { ProjectAllInfoProps } from "../../interface/Ireports";
const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
  },

  label: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 700,
    lineHeight: 1,
  },

  lead: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 700,
    fontSize: rem(22),
    lineHeight: 1,
  },

  inner: {
    display: "flex",

    [theme.fn.smallerThan("xs")]: {
      flexDirection: "column",
    },
  },

  ring: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",

    [theme.fn.smallerThan("xs")]: {
      justifyContent: "center",
      marginTop: theme.spacing.md,
    },
  },
}));
interface StatsRingCardProps {
  title: string;
  completed: number;
  total: number;
  stats: {
    value: number;
    label: string;
  }[];
}

interface Project {
  id_project: string;
  name: string;
  time_consumption: string;
  time_estimate: string;
  percent: number;
  today_total_time: string;
  total_days: number;
}
export function ProjectItem({
  title,
  completed,
  total,
  stats,
}: StatsRingCardProps) {
  const [projectAll, setProjectAll] = useState<Project[]>();
  const getProjectAllInfo = async () => {
    const responeseProjectAllBoard = await regBoardMostRecentProject({}, "GET");
    setProjectAll(responeseProjectAllBoard.data.data);
  };
  const { classes, theme } = useStyles();
  useEffect(() => {
    getProjectAllInfo();
  }, []);
  const items = stats.map((stat) => (
    <div key={stat.label}>
      {/* <Text className={classes.label}>  {pitem.today_total_time}</Text>
      <Text size="xs" color="dimmed">
        <FormattedMessage id={stat.label} defaultMessage={stat.label} />

      </Text> */}
    </div>
  ));

  return (
    <>
      {projectAll?.map((pitem, pindex) => {
        if (pindex < 10) {
          return (
            <Card
              withBorder
              radius="md"
              shadow="sm"
              px={0}
              className={classes.card}
            >
              <Grid>
                <Grid.Col span={10}>
                  <Group px="1rem">
                    <Text size={14} fw={700} color="customize-gray.4">
                      {pitem.name}
                    </Text>
                  </Group>{" "}
                </Grid.Col>
              </Grid>
              <Divider my="sm" />
              <Flex className={classes.inner} px="xl">
                <div>
                  {/* <Text fz="xl" className={classes.label}>
                  {title}
                </Text> */}

                  <div>
                    <Text className={classes.lead} mt={30}>
                      {pitem.time_consumption}
                    </Text>
                    <Text fz="xs" color="dimmed">
                      <FormattedMessage
                        id="Completed"
                        defaultMessage="Completed"
                      />
                    </Text>
                  </div>
                  <Group mt="lg">
                    <div>
                      <Text className={classes.label}>
                        {pitem.today_total_time}
                      </Text>
                      <Text size="xs" color="dimmed">
                        Today hours
                      </Text>
                    </div>
                    <div>
                      <Text className={classes.label}>{pitem.total_days}</Text>
                      <Text size="xs" color="dimmed">
                        Total days
                      </Text>
                    </div>
                  </Group>
                </div>

                <div className={classes.ring}>
                  <RingProgress
                    roundCaps
                    thickness={6}
                    size={100}
                    sections={[
                      {
                        value: (completed / total) * 100,
                        color: theme.primaryColor,
                      },
                    ]}
                    label={
                      <div>
                        <Text ta="center" fz="lg" className={classes.label}>
                          {pitem.percent}%
                        </Text>
                        <Text ta="center" fz="xs" c="dimmed">
                          <FormattedMessage
                            id="Completed"
                            defaultMessage="Completed"
                          />
                        </Text>
                      </div>
                    }
                  />
                </div>
              </Flex>
            </Card>
          );
        }
      })}
    </>
  );
}
