
import { Grid,Box, Group, Textarea, List, ThemeIcon} from '@mantine/core';
import { IComment} from '../../interface/Ivaction';
import { isNotEmpty, useForm } from '@mantine/form';
import { IconCircleCheck } from '@tabler/icons-react';
import { regGetVationComment } from '../../api';
import { HintInfo } from '../../utils/function';
import WordTranslationComponent from '../../utils/language_pack/words';
import SubmitBtn from '../../ttcomponents/Button/SubmitBtn';
import TableTextInputTitle from '../../ttcomponents/TableTextInputTitle/TableTextInputTitle';


interface ILeaveVactionInfo{
    row:IComment[] |undefined;
    id_leave:string;
    callback:()=>void
}

export default function   LeaveVactionCommnet({id_leave,row,callback}:ILeaveVactionInfo) {
 // Get translation public configuration information
   const word_translation = WordTranslationComponent();
    const form = useForm({
    initialValues: {
        leave_id:id_leave,approver_remarks:''},
        validate: {
            approver_remarks: isNotEmpty(word_translation.is_not_empty),
          },
    // functions will be used to validate values at corresponding key
    });
    // Submit form information
    const handleFormSubmit = async () => {
            const valid = await form.validate();
            if (!valid.hasErrors) {
                const CommentResult = await regGetVationComment(form.values, "POST");
                HintInfo(CommentResult.data)
                if(CommentResult.data.code==200)
                    callback()
            } else {
                
            }
        };
    return (
        <Box>
                <Grid gutter={0} mt={5}>
                    <Grid.Col span={12}>
                        <TableTextInputTitle title={word_translation.approver_remarks} />
                        <Box>
                             <Textarea className='commnet-content' {...form.getInputProps('approver_remarks')} />
                        </Box>
                    </Grid.Col>
                </Grid>
                <Group position="right" mt={10}>
                    <SubmitBtn btn_size="xs" callBack={handleFormSubmit} />
                </Group>
              <List 
            mt={10}
            spacing="xs"
            size="sm"
            center
            icon={
                <ThemeIcon color="teal" size={24} radius="xl">
                  <IconCircleCheck size="1rem" />
                </ThemeIcon>
            }
            >
                {row?.map((item,index)=>(
                    <>
                        <List.Item> <strong> </strong>{item.approver_remarks}  </List.Item>
                    </>
                ))}
            </List>
        </Box>
    )
}