import {
  Box,
  Code,
  Grid,
  Group,
  List,
  NumberInput,
  TextInput,
  ThemeIcon,
} from "@mantine/core";
import {
  IconCalendar,
  IconCheck,
  IconCircleCheck,
  IconCircleDashed,
  IconX,
} from "@tabler/icons-react";
import { IOrderProduct } from "../../../../interface/Iorder";
import { DateInput, DateValue } from "@mantine/dates";
import CancelButton from "../../../../ttcomponents/Button/CancelBtn";
import SaveBottom from "../../../../ttcomponents/Button/SaveBtn";
import ProductSelect from "../../../../ttcomponents/Product/ProductSelect";
import SupplierSelect from "../../../../ttcomponents/Supplier/SupplierSelect";
import { isNotEmpty, useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { useRef, FormEvent } from "react";
import { regDeliveryToCustomer, regProduct } from "../../../../api";
import { formatDateUpgraded } from "../../../../utils/function";
import { useIntl } from "react-intl";

interface ProductSaleProps {
  additional_information: IOrderProduct | undefined;
  closeFucntion: (type: number) => void;
}

export default function SaleFrom({
  additional_information,
  closeFucntion,
}: ProductSaleProps) {
  // Use the useIntl hook to access the intl object
  const intl = useIntl();

  const formRef = useRef<HTMLFormElement>(null);

  const form = useForm({
    initialValues: {
      delivery_id: additional_information?.delivery_id,
      product_id: additional_information?.product_id,
      customer_order_id: additional_information?.order_product_id,
      customer_order_name: additional_information?.order_name,
      delivery_quantity: additional_information?.delivered_quantitly,
      delively_time: additional_information?.delively_time
        ? new Date(additional_information?.delively_time)
        : null,
      total_price: additional_information?.total_price,
    },
    validate: {
      delivery_quantity: isNotEmpty("Delivery quantity Schedule  is required"),
      total_price: isNotEmpty("Total Price is required"),
    },
  });

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Stop the formal submission of the form default
    // Verification form
    const valid = form.validate();
    if (valid.hasErrors) {
      notifications.show({
        color: "yellow.7",
        icon: <IconX />,
        message: Object.values(valid.errors)[0],
      });
      return;
    }

    const updatedFormValues = {
      ...form.values,
      delively_time: formatDateUpgraded(
        form.values.delively_time as Date,
        "YYYY-MM-DD HH:mm:ss"
      ),
    };

    let submit_type = "POST";
    if (form.values.delivery_id) {
      submit_type = "PUT";
    }

    const response = await regDeliveryToCustomer(
      updatedFormValues,
      submit_type
    );
    if (response.data.code == 200) {
      closeFucntion(2);
      notifications.show({
        color: "green",
        icon: <IconCheck />,
        message: response.data.msg,
      });
    } else {
      notifications.show({
        color: "yellow.7",
        icon: <IconX />,
        message: response.data.data.database_returns_results
          ? response.data.data.database_returns_results
          : response.data.msg,
      });
    }
  };

  const changeDelivelyTime = (num: any) => {
    if (typeof num === "number") {
      const unitPrice =
        parseFloat(String(additional_information?.unit_price)) || 0;
      const totalPrice = (unitPrice * num).toFixed(2); // 保留两位小数
      form.setFieldValue("total_price", totalPrice);
    }
    form.setFieldValue("delivery_quantity", num);
  };

  return (
    <Box>
      <List
        spacing="xs"
        size="sm"
        mt={20}
        center
        icon={
          <ThemeIcon color="teal" size={24} radius="xl">
            <IconCircleCheck size="1rem" />
          </ThemeIcon>
        }
      >
        <List.Item>
          Product Name:{additional_information?.product_name}
        </List.Item>
        <List.Item>
          Customer Order Name:{form.values?.customer_order_name}
        </List.Item>
      </List>

      <form onSubmit={handleFormSubmit} ref={formRef}>
        <Grid>
          <Grid.Col span={12}>
            <TextInput
              disabled
              defaultValue={form.values.customer_order_name}
              label={intl.formatMessage({
                id: "Customer Order Number",
                defaultMessage: "Customer Order Number",
              })}
              style={{ width: "100%" }}
            />

            <TextInput
              {...form.getInputProps("total_price")}
              disabled
              label="Total Price"
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <TextInput
              disabled
              value={additional_information?.product_name}
              label="Product Name"
              style={{ width: "100%" }}
            />

            <NumberInput
              disabled
              defaultValue={additional_information?.order_quantity}
              // value={categoryName}
              label="Order Quantity"
            />
            <NumberInput
              required
              defaultValue={form.values?.delivery_quantity}
              onChange={(event) => changeDelivelyTime(event)}
              // value={categoryName}
              label="Delivery Quantity"
            />

            <DateInput
              valueFormat="DD/MM/YYYY"
              required
              labelProps={{ className: "input_title" }}
              icon={<IconCalendar size="1.1rem" stroke={1.5} />}
              {...form.getInputProps("delively_time")}
              label="Delivery Time"
              mx="auto"
            />
          </Grid.Col>
        </Grid>

        <Group position="right">
          <CancelButton callBack={() => closeFucntion(1)} />
          <SaveBottom formRef={formRef} />
        </Group>

        {/** can see form values */}
        <Code block mt={5}>
          {JSON.stringify(form.values, null, 2)}
        </Code>
      </form>
    </Box>
  );
}
