import { Box, Text, Tooltip } from "@mantine/core"
import { CSSProperties } from "react"

interface ComponetntInterface {
    title: string,
    class_name?: string,
    tip?: string,
    callBack?: () => void
    style?: CSSProperties 
}
export default function LeaveManageRowTd({ tip, title, callBack, class_name, style }: ComponetntInterface) {
    return (
        <td className="text-center border-b-1  width-xs" style={style} onClick={callBack} >
            {tip ? <Tooltip label={tip}>
                <Text className={class_name}>
                    {title}
                </Text>
            </Tooltip> :
             <Text className={class_name}>
                {title}
            </Text>}

        </td>
    )
}