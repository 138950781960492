import { useDisclosure } from "@mantine/hooks";
import { hasLength, useForm } from "@mantine/form";
import {
  Tooltip,
  ActionIcon,
  Group,
  TextInput,
  Box,
  Paper,
  Card,
  Modal,
} from "@mantine/core";
import { FormEvent, useEffect, useRef, useState } from "react";
import { regCostcenterSetting, regModules } from "../../../../api";
import TableCostCenter from "../../../../ttcomponents/Table/TableCostCenter";
import { FormattedMessage, useIntl } from "react-intl";
import SaveBottom from "../../../../ttcomponents/Button/SaveBtn";
import WordTranslationComponent from "../../../../utils/language_pack/words";
import CreateBtn from "../../../../ttcomponents/Button/CreateBtn";
import MoreActionBtnWithBottom from "../../../../ttcomponents/MoreActionBtnWithBottom/MoreActionBtnWithBottom";
import ResetBtn from "../../../../ttcomponents/Button/ResetBtn";
import { HintErrorMantineInfo, HintInfo } from "../../../../utils/function";
import { getCostCenterGeneralFunction } from "../../../../api/common_request";
import { IcostCenter } from "../../../../interface/ICostCenter";
import { ComponentInterface } from "../../../../interface/Icommon";
import CheckPermissionTools from "../../../../utils/permission";
import CostCenterForm from "./form";

export default function CostCenterSettings({  is_load_str,}: ComponentInterface){
  const [moreOpened, setMoreOpened] = useState(false);

  const formRef = useRef<HTMLFormElement>(null);
  // Get translation public configuration information
 const word_translation = WordTranslationComponent();
 const Perm = CheckPermissionTools();

  const [opened, { open, close }] = useDisclosure(false);
  const [costCenteData, setcostCenteData] = useState([]);
  const form = useForm({
    validateInputOnBlur: true,
    initialValues: {
      id_costcenter: '',
      street1: "",
      street2: "",
      cost_center_identifier:"",
      city: "",
      name: "",
      postalCode: "",
    },
    // functions will be used to validate values at corresponding key
    validate: {
      name: hasLength({ min: 2, max: 200 }, word_translation.validata_value_need_2_100_length),
      cost_center_identifier: hasLength({ min: 2, max: 200 }, word_translation.validata_value_need_2_100_length),
    },
  });

  
 
  const ajaxCurrentList = async () => {
    if (is_load_str === "costcenter_settings") {
      setcostCenteData(await getCostCenterGeneralFunction({have_all_costcenter:false}));
    }
  };
  
  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Stop the formal submission of the form default
    // Verification form
    const valid = await form.validate();
    let originalData = {
      street2: form.values.street2,
      street1: form.values.street1,
      name: form.values.name,
      cost_center_identifier: form.values.cost_center_identifier,
      postalCode: form.values.postalCode,
      city: form.values.city,
    };
    const methodType = form.values.id_costcenter ? "PUT" : "POST";
    const formData = form.values.id_costcenter
      ? { ...originalData, id: form.values.id_costcenter }
      : originalData;

    if (!valid.hasErrors) {
      const response = await regCostcenterSetting(formData, methodType);
      HintInfo(response.data);
      if (response.data.code == 200){
          close();
          ajaxCurrentList();
          resetFunction();
      }else{
        HintErrorMantineInfo(valid)
      }
    }
  };
  useEffect(() => {
    ajaxCurrentList();
  }, [is_load_str]);

// Handle the deletion of a cost center setting by ID
const handleOnValueDel = async (id: string) => {
  // Send a DELETE request to the server to delete the cost center setting with the specified ID
  const response = await regCostcenterSetting({ id: id }, "DELETE");

  // Display a notification based on the response result using the HintInfo function
  HintInfo(response.data);

  // If the deletion was successful (response code is 200), proceed with additional actions
  if (response.data.code === 200) {
    // Fetch the updated list of cost center settings
    ajaxCurrentList();

    // Reset the state or perform any necessary cleanup
    resetFunction();
  }
};

  const openCostCenterForm = () => {
    // Reset the state or perform any necessary cleanup
    form.reset();
    open();
  }
  const handleOnValueEdit = (editedValue: IcostCenter) => {
    form.setFieldValue("city", editedValue.city);
    form.setFieldValue("id_costcenter", editedValue.id_costcenter);
    form.setFieldValue("name", editedValue.name);
    form.setFieldValue("postalCode", editedValue.postalCode);
    form.setFieldValue("street1", editedValue.street1);
    form.setFieldValue("street2", editedValue.street2);
    form.setFieldValue("cost_center_identifier", editedValue.cost_center_identifier);
    open();
  };

/**
   * Reset the form
   */
    const resetFunction = ()=>{
      form.reset();
    }
    
  return (
    <Paper w={"100%"} pb={20}>
      <Box w={"100%"}>


        <CostCenterForm 
          callBackId={()=>{}}
          openFormState={opened}
          closeFormModal={close}
          refreshFunction={ajaxCurrentList}
          costCenter={form.values}
        />
        {/* <Modal
          opened={opened}
          shadow="sm"
          onClose={close}
          closeOnClickOutside={false}
          title={word_translation.cost_center_manage}
          overlayProps={{ opacity: 0.5, blur: 4 }}
        >
          <Card p={20} radius={15} color="customize">
            <form onSubmit={handleFormSubmit} ref={formRef}>
            <Box>
              <TextInput
                required
                label={word_translation.cost_center_name}
                placeholder={word_translation.Input}
                {...form.getInputProps("name")}
              />
              <TextInput
                required
                description={word_translation.cost_center_identifier_tips}
                label={word_translation.cost_center_identifier}
                placeholder={word_translation.Input}
                {...form.getInputProps("cost_center_identifier")}
              />
                <TextInput
                required
                label={word_translation.street1}
                placeholder={word_translation.street1}
                {...form.getInputProps("street1")}
              />
              <TextInput
                label={word_translation.street2}
                placeholder={word_translation.street2}
                {...form.getInputProps("street2")}
              />
              <TextInput
                label={word_translation.company_postal_code}
                placeholder={word_translation.company_postal_code}
                {...form.getInputProps("postalCode")}
              />
              <TextInput
                label={word_translation.city}
                placeholder={word_translation.city}
                {...form.getInputProps("city")}
              />
            </Box>
            <Group position="right" mt={10}>
                <ResetBtn callBack={resetFunction} />
                <SaveBottom margintop={0} formRef={formRef} />
            </Group>
            </form>
          </Card>
        </Modal> */}
        {costCenteData.length > 0 && (
            <TableCostCenter
              data={costCenteData}
              onValueEdit={handleOnValueEdit}
              onValueDel={handleOnValueDel}
            />
          )}
      </Box>
      
          {moreOpened && (
                <Paper className="positioned-right-bottom-content" shadow="sm">
                    <Card>
                        <CreateBtn disable={!Perm.cost_center_create} callBack={openCostCenterForm} />
                    </Card>
                </Paper>
            )}
            <MoreActionBtnWithBottom moreOpened={moreOpened} callBack={setMoreOpened}/>
    </Paper>
  );
}
