import { Box, Flex, Group, Text, Tooltip } from '@mantine/core'
import { IconMoneybag } from '@tabler/icons-react'
import { CSSProperties } from 'react'
import { FormattedMessage } from 'react-intl'
interface SearchBoxProps {
  title: any
  py?: number
  pb?: number
  lang_id?: any
  class_name?: string
  style?: CSSProperties
  tooltip?: any
  icon_r?: any
  icon_l?: any
  multiline?: boolean
}

export default function TableTextInputTitle({
  class_name,
  style,
  tooltip,
  title,
  py,
  pb,
  icon_r,
  icon_l,
  lang_id = '',
  multiline = false
}: SearchBoxProps) {
  return (
    <Group position="left" style={style}>
      {icon_l && <>{icon_l}</>}
      <Box>
        <Tooltip
          label={tooltip ? tooltip : title}
          multiline={multiline}
          width={multiline? 300 : 'auto'}
          color="gray"
          arrowPosition="center"
          position="top-start"
          withArrow
        >
          <Text
            className={class_name ? class_name : 'input_title'}
            pt={py || py === 0 ? py : 4}
            pb={pb || pb === 0 ? pb : 4}
          >
            {lang_id ? <FormattedMessage id={lang_id} defaultMessage={title} /> : title}
          </Text>
        </Tooltip>
      </Box>
      {icon_r && <>{icon_r}</>}
    </Group>
  )
}
