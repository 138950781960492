import { isNotEmpty, useForm } from '@mantine/form';
import { TextInput, Grid, Button, Box,Select,  Text, ActionIcon, Group } from '@mantine/core';
import { SelectRole } from '../../../../interface/Icommon';

import { IconSquareRoundedPlus, IconX } from '@tabler/icons-react';
import { HintErrorMantineInfo, HintInfo, TimeHHSS, formatDate, getCurrentWeekUtils, getWeekDates, getWeekInfo, getYear } from '../../../../utils/function';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import localstorageUnits from '../../../../utils/localstorageUnits';
import Message from '../../../../ttcomponents/Message/Message';
import TableTextInputTitleDesc from '../../../../ttcomponents/TableTextInputTitleDesc/TableTextInputTitleDesc';
import WordTranslationComponent from '../../../../utils/language_pack/words';
import { getAllTaskCategoryGeneralFunction, getCustomerListGeneralFunction, getOrderListGeneralFunction, getProjectDetailByIdFunction, getProjectListGeneralFunction, getTaskListGeneralFunction } from '../../../../api/assignments_request';
import { requestTimeTrackerActionList } from '../../../../api/index_v2';
import GlobalCoreSetting from '../../../../utils/globalCortSetting';
import { getSystemAssignmentsFunction } from '../../../../api/core_request';
import CheckPermissionTools from '../../../../utils/permission';

interface TimeAddProps {
  projectData: SelectRole[];
  userId: string | null;
  currentDate: Date | null;
  customerlist?: SelectRole[] |[];
  getCustomerCatogory: SelectRole[];
  taskcateGoryListData: SelectRole[];
  callBack: (value: any) => void
}



export default function CreateTimerTrackRecord({ userId, taskcateGoryListData, callBack, currentDate, projectData }: TimeAddProps) {


  const Perm = CheckPermissionTools();
  // Get translation public configuration information
  const word_translation = WordTranslationComponent();
  
  // get Task Mode Flag
  const taskModeFlag = localstorageUnits.getSystem().task_mode_flag

  const weekDayInfo = getWeekDates(currentDate);

  const form = useForm({
    // Initialize form data
    validateInputOnBlur: true,
    initialValues: {
      project_id: '', 
      task_category_id: '', 
      order_id: '',
      user_id: userId,
      customer_id:'',
      mon: "00:00",
      tues: "00:00",
      weds: "00:00",
      thurs: "00:00",
      fri: "00:00",
      sat: "00:00",
      sun: "00:00",
      week: "",
      year: "2023"
    },
    validate: {
      customer_id:  isNotEmpty(`${word_translation.customer} ${word_translation.is_not_empty}`),
      week:  isNotEmpty(`${word_translation.kw} ${word_translation.is_not_empty}`),
  },
  });

  // 定义选择的项目
  const [searchValue, onSearchChange] = useState<string | null>(null);
  // 定义选择的订单ID
  const [searchOrderIdValue, onSearchOrderIdChange] = useState<string | null>(null);
  // 定义选择的TaskID
  const [searchTaskIdValue, onSearchTaskIdChange] = useState<string | null>(null);
  // 定义customer
  const [customerID, SetcustomerID] = useState<string | null>(null);

  const [customerid, SetcustomerIDwithProject] = useState<string>('');
  // Define the current year
  const [currentYear, setCurrentYear] = useState(0);
  //Define the current week
  const [currentWeek, setCurrentWeek] = useState(0);
  // Define the current week
  const [currentDayofWeek, setCurrentDayofWeek] = useState('');
  // Project update list key
  const [resetKey, setResetKey] = useState(0);
  // Customer update list key
  const [customerKey, setcustomerKey] = useState(0);
  //Order update list key
  const [orderkey, setorderkey] = useState(0);
  const [randNumber, setRandNumber] = useState(0);
  //Order update list key
  const [showORderStatus, setShowORderStatus] = useState(false);
  // Task update list key
  const [taskkey, settaskkey] = useState(0);
  // Button Loading
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  //Define the task of choosing customers
  const [userTaskList, setUserTaskList] = useState<SelectRole[]>([]);
  // Define the selected project below OrderList
  //Define the orderlist below the selected item
  const [ordernamelist, setOrderNameList] = useState<SelectRole[]>([])
  // Define the selected project below the orderList below the tasklist
  const [orderTasklist, setOrderTaskList] = useState<SelectRole[]>([])
  const [timeKey, setTimeKey] = useState(0);
  const [showMessage, setShowMessage] = useState(false);
  // State to store the message content
  const [showMessageStr, setShowMessageStr] = useState('');
  

  useEffect(()=>{
    form.setFieldValue('user_id',userId)
  },[userId])

   // Get the global configuration
  const globalCoreSetting = GlobalCoreSetting();


  // 提交快速上传timetrack记录
  const createTimeTrackRecode = async () => {
        // Validate the Form
      const valid = await form.validate();
      //  If there are validation errors, display error information
      if (valid.hasErrors) {
        HintErrorMantineInfo(valid);
        return false;
      }

    const response = await requestTimeTrackerActionList(form.values, 'POST');
    setIsBtnLoading(false)
    const result = response.data;
    HintInfo(result);
    if (result.code === 200) {
      resetValues();
      callBack(result.data.id);
    }else if(result.code === 1011) {
      callBack( result.data.id_time_tracker);
    }
  }

    // 重置值
    const resetValues = () => {
      setOrderTaskList([])
      setOrderNameList([])
      form.setFieldValue('mon',"00:00")
      form.setFieldValue('tues',"00:00")
      form.setFieldValue('weds',"00:00")
      form.setFieldValue('thurs',"00:00")
      form.setFieldValue('fri',"00:00")
      form.setFieldValue('sat',"00:00")
      form.setFieldValue('sun',"00:00")
      form.setFieldValue('project_id',"")
      form.setFieldValue('order_id',"")
      form.setFieldValue('task_category_id',"")
      form.setFieldValue('customer_id',"")
      setResetKey(resetKey + 1);
      setcustomerKey(customerKey + 1)
      setorderkey(orderkey + 1);
      settaskkey(taskkey + 1);
    }


  const handleBlur = async (events: any, arg: string) => {
    const value = TimeHHSS(events);
    console.log(value)
    let taskUpid;
    if (searchTaskIdValue == '') {
      taskUpid = null;
    } else {
      taskUpid = searchTaskIdValue;
    }

    let orderUpid;
    if (searchOrderIdValue == '') {
      orderUpid = null;
    } else {
      orderUpid = searchOrderIdValue;
    }
    const origin = {
      project_id: searchValue,
      order_id: orderUpid,
      task_id: taskUpid,
      flag: taskModeFlag,
      [arg]: value,
      week: currentWeek,
      year: currentYear,
    }
  };
  // Get the current week and year
  const getCurrentWeekNumber = (currentDate: Date | null) => {
    if (currentDate == null) {
      currentDate = new Date();
    }

    const { year, week, dayOfWeek } = getWeekInfo(currentDate);
    setCurrentWeek(getCurrentWeekUtils(currentDate));
    setCurrentYear(year)
    setCurrentDayofWeek(dayOfWeek)
  };
  // After the page is loaded, the rendering data can be rendered.
  useEffect(() => {
    getCurrentWeekNumber(currentDate)
    setCurrentYear(getYear(currentDate as Date));
  }, [currentDate, projectData, orderTasklist, ordernamelist]);


  //Get the name and ID of all onlytaskname
  const getTaskProject = (customer: string | null) => {
    console.log(customer)
    SetcustomerID(customer)
    SetcustomerIDwithProject(customer as string)
  };



  // ***************************************************/
  // ***************************************************/
  // ***************************************************/
  // ***************************************************/

  // 1 这里要加载所有的客户
  

  const getCurrentAssigment= async ()=>{
    let assignmentModals = await getSystemAssignmentsFunction();
    console.log(assignmentModals);
    if (assignmentModals.split(',').some((item: string) => ['1', '2', '4','6'].includes(item))) {
      setShowORderStatus(true)
    }else{
      setShowORderStatus(false)
    }
 
}
  // init Data
  useEffect(()=>{
    // load customer info
    getCustomerList();
    getCurrentAssigment();
  },[])



  useEffect(()=>{
      form.setFieldValue('week',currentWeek.toString())
  },[currentWeek])
  
  useEffect(()=>{
    form.setFieldValue('year',currentYear.toString())
},[currentYear])

  const [customerCurrentPageList, setCustomerList] = useState<SelectRole[]>([]);

// get all customer list 
  const getCustomerList = async () => {
    setCustomerList(await getCustomerListGeneralFunction(""));
  };

   // Execute the useeffect when the first load is loaded.
 const isInitialMount = useRef(true);
 useEffect(() => {
   if (!isInitialMount.current) {
    
      if(form.values.customer_id){
        // DOTO 加载对应的项目和订单，禁止加载任务
        // getProjectList({'customer_id':form.values.customer_id,'user_id':userId})
        getProjectList({'customer_id':form.values.customer_id})
        // DOTO 加载对应的项目和订单，禁止加载任务
        getOrderList({'customer_id':form.values.customer_id,'project_id':0})
      }else{
        // 清空项目订单列表
        setProjectList([]);
        requestProjectListByUserId();
        setOrderList([]);
        setUserTaskList([]);
        form.setFieldValue('project_id','')
        form.setFieldValue('order_id','')
        form.setFieldValue('task_category_id','')
      }
      setTimeKey(timeKey+1)
   }
   isInitialMount.current = false;
 }, [form.values.customer_id]);


 // 监听表单中 projectid的变化，一旦有变化 则进行数据变更，
//  请求当前用户下的order 以及 project 中没有订单的task
 useEffect(() => {
  if (!isInitialMount.current) {
    //  if(form.values.customer_id){
       // DOTO 加载对应的订单，禁止加载任务
       getOrderList({'project_id':form.values.project_id,})
      //  getOrderList({'project_id':form.values.project_id,'user_id':userId})
      //  getTaskList({'project_id':form.values.project_id,'user_id':userId,'order_id':0})
      //  getTaskList({'project_id':form.values.project_id,'order_id':0})
    //  }else{
    //   setOrderList([]);
    //   setUserTaskList([]);
    //  }
    
    form.setFieldValue('order_id','')
    form.setFieldValue('task_category_id','')
     if(form.values.project_id == ''){
     }else{
      getProjectDeatil(form.values.project_id)
     }
     setTimeKey(timeKey+1)
  }
  isInitialMount.current = false;
}, [form.values.project_id]);

const getProjectDeatil = async (project_id:string) =>{
  const projectInfo = await getProjectDetailByIdFunction(project_id);
    
  form.setFieldValue('customer_id',projectInfo.customer_id?.toString())
 }


 // 监听表单中order_id的变化，一旦有变化 则进行数据变更，
//  请求当前用户下的order 以及 project 中没有订单的task
useEffect(() => {
  requestProjectListByUserId()
  if (!isInitialMount.current) {
     if(form.values.customer_id){
       // DOTO 加载对应的订单，禁止加载任务
      //  getTaskList({'order_id':form.values.order_id,'user_id':userId})
     }
  }
  isInitialMount.current = false;
}, [form.values.order_id]);

  // Define all the project lists that can be selected
  const [projectList, setProjectList] = useState<SelectRole[]>([]);
// get all project list 
  const getProjectList = async (where:any) => {
    setProjectList(await getProjectListGeneralFunction(where));
  };


  //获取全部的任务类型
  const getTaskCategoryList = async ()=> {
    setUserTaskList(await getAllTaskCategoryGeneralFunction({list:true}))
    setRandNumber(randNumber+1)
  }


  // get task list <FUNCTION>
  const getTaskList = async (value: any) => {
    setUserTaskList((await getTaskListGeneralFunction(value)))
  };

  // Define all the project lists that can be selected
  const [orderList, setOrderList] = useState<SelectRole[]>([]);
  const getOrderList = async (value: any) => {
    setOrderList(await getOrderListGeneralFunction(value))
  };

  const requestProjectListByUserId = async() =>{
      // TODO 获取当前用户应该有的项目信息
      // if(projectList.length == 0 && form.values.customer_id == ''){
      //   setProjectList(await getProjectListGeneralFunction(""));
      //   setRandNumber(randNumber+1)
      // }
      setProjectList(await getProjectListGeneralFunction(""));
      setRandNumber(randNumber+1)
  }

  const requesOrderListByUserId = async() =>{
    // TODO 获取当前用户应该有的项目信息
    if(orderList.length == 0){
      await getOrderList({'user_id':userId,'project_id':0})
    }
}
const requesTaskListByUserId = async() =>{
  // TODO 获取当前用户应该有的项目信息
  // if(userTaskList.length == 0 && form.values.project_id == '' && form.values.order_id == ''){
  //   // await getTaskCategoryList(); 
  //   getTaskList({'order_id':0,'project_id':0})
  // }
  await getTaskCategoryList()
  
}


  // ***************************************************/
  // ***************************************************/
  // ***************************************************/
  interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
    image: string;
    label: string;
    project_no: string;
  }
  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ image, label, project_no, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          <div>
            <Text size="sm">{label}</Text>
            <Text size="xs" opacity={0.65}>
              {project_no}
            </Text>
          </div>
        </Group>
      </div>
    )
  );

  return (
    <Box mx="auto" px={10} mt={40}>
      <form >
        <table className="table_custome text-left">
          <tr className="">
            {/* select customer */}
            <th>
              <TableTextInputTitleDesc title={word_translation.select_customer} />
              <Select
                size='xs'
                key={timeKey}
                variant='filled'
                searchable
                className='time_sheet_home'
                rightSection={<ActionIcon size='xs' onClick={()=> form.setFieldValue("customer_id", '')}> <IconX /></ActionIcon>}
                defaultValue={form.values.customer_id?.toString()}
                onChange={(value) =>{
                  form.setFieldValue('project_id','')
                  form.setFieldValue('order_id','')
                  form.setFieldValue('task_category_id','')
                  form.setFieldValue("customer_id", value as string)
                 }
                }
                data={customerCurrentPageList as []} />
            </th>
            {/* select project */}
            <th>
              <TableTextInputTitleDesc title={word_translation.select_project} />
              <Select 
                  size='xs' 
                  variant='filled' 
                  searchable 
                  key={timeKey}
                  miw={280}
            
                  className='time_sheet_home'
                  itemComponent={SelectItem}
                  defaultValue={form.values.project_id?.toString()}
                  rightSection={<ActionIcon size='xs' onClick={()=>{
                    form.setFieldValue("project_id", '')
                    setRandNumber(randNumber+1);
                  }}> <IconX /></ActionIcon>}
                  onChange={(value) =>
                    form.setFieldValue("project_id", value as string)
                  }
                  // onClick={requestProjectListByUserId}
                  data={projectList} 
                  filter={(value, item) =>{
                    return  item.label?.toLowerCase()?.includes(value.toLowerCase()?.trim())  ||item.project_no?.toLowerCase()?.includes(value.toLowerCase()?.trim()) 
                  }
                  }
                  />
            </th>
            {/* select order */}
            {showORderStatus &&  <th>
              <TableTextInputTitleDesc title={word_translation.select_order} />
              <Select
                size='xs'
                variant='filled'
                searchable
          
                className='time_sheet_home'
                key={randNumber}
                onClick={requesOrderListByUserId}
                onChange={(value) =>
                  form.setFieldValue("order_id", value as string)
                }
                defaultValue={searchOrderIdValue}
                data={orderList} />
            </th>}
           
            {/* select task */}
            <th>
              <TableTextInputTitleDesc title={word_translation.select_task} />
              <Select
                size='xs'
                key={timeKey}
                searchable
                className='time_sheet_home'
                onChange={(value) =>
                  form.setFieldValue("task_category_id", value as string)
                }
                variant='filled'
                onClick={requesTaskListByUserId}
                creatable
          
                getCreateLabel={(query) => `+ New  ${query}`}
                onCreate={(query) => {
                  const item = { value: '@@@' + query, label: query };
                  setUserTaskList((current) => [...current, item]);
                  return item;
                }}
                data={userTaskList} />
            </th>


            {globalCoreSetting.KWS.map((item,index)=>{
                return (
                  <th>
                  <Text className='title-date-desc text-left'>
                    {<FormattedMessage id={item.label} defaultMessage={item.label} />}-{formatDate(weekDayInfo[index])}
                  </Text>
                  <TextInput
                    withAsterisk
                    variant='filled'
                    size='xs'
                    className={index>4?'rest-input ': ''}
                    key={timeKey}
                    onBlur={(event) => handleBlur(event, item.label)} //Pass the parameter "MO" by closing the package
                    defaultValue={form.values.mon}
                    onChange={(event) => form.setFieldValue(item.label, event.target.value)}
                    placeholder='mo'
                  />
                </th>
                )
            })}
           

            <th>
              <Text className='title-date-desc'>
                &nbsp;
              </Text>
              <Button ml={10}
                loading={isBtnLoading ? true : false}
                onClick={createTimeTrackRecode}
                disabled={
                 ( Perm.time_tracker_create ||
                   Perm.time_tracker_update ||
                    Perm.time_tracker_manage || 
                    Perm.leader_timetracker_update || 
                    Perm.leader_timetracker_create || 
                    Perm.core_timetracker_read) ? false :true
                }
                leftIcon={<IconSquareRoundedPlus width={14} height={14} />}
                styles={{
                  leftIcon: { marginRight: '5px' },
                }}
                size='xs'><FormattedMessage id="add" defaultMessage="Add" /></Button>
            </th>
            <Grid.Col span={1}>

              {/* <Button mt='1.2rem'  mx={1}  color='red' onClick={reset} size='xs'>only task</Button> */}
            </Grid.Col>

          </tr>
        </table>

        {/* <Code  block mt={5}>
                 {JSON.stringify(form.values, null, 2)}
             </Code> */}
      </form>
      {showMessage && (
        <Message content={showMessageStr} duration={3000} color="green" />
      )}
    </Box>
  );
}