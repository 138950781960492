import { Box, Button, Card, Grid, Paper, PasswordInput, Select, TextInput } from '@mantine/core'
import { isNotEmpty, useForm } from '@mantine/form'
import { useDisclosure } from '@mantine/hooks'
import { IconCheck, IconUser, IconX } from '@tabler/icons-react'
import TrackedHours from './tracked_hours'
import { regCostcenterSetting, regGeUser, regUser, regLogout } from '../../../api'
import { FormEvent, useEffect, useState } from 'react'
import { notifications } from '@mantine/notifications'
import memoryUtils from '../../../utils/memoryUtils'
import localstorageUnits from '../../../utils/localstorageUnits'
import { useNavigate } from 'react-router-dom'
import apiUrl from '../../../config/api_config/config'
import { SelectRole } from '../../../interface/Icommon'
import { FormattedMessage, useIntl } from 'react-intl'
import PageTitle from '../../../ttcomponents/PageTitle/PageTitle'
import WordTranslationComponent from '../../../utils/language_pack/words'
import { getCostCenterGeneralSelectFunction } from '../../../api/common_request'
import UploadSingleImage from '../../../ttcomponents/UploadImage/UploadSingleImage/UploadSingleImage'

export default function ProfileSettings() {
  // 钩子函数-切换语言
  const intl = useIntl()

  // The status of the image path
  const [imagePath, setImagePath] = useState('')
  // Get translation public configuration information
  const word_translation = WordTranslationComponent()
  // 跳转
  const navigate = useNavigate()
  // 声明存储用户分支中心的变量costCenterData
  const [costCenterData, setCostCenterData] = useState<SelectRole[]>([])

  // 监听表单是否被修改
  const [tempFormData, setTempFormData] = useState({
    username: '',
    phone: '',
    confirm_password: '',
    old_password: '',
    password: '',
    avatar: '',
    mobil: '',
    email: '',
    first_name: '',
    last_name: '',
    costCenter_id: '',
    id: memoryUtils.user.id_user
  })

  const form = useForm({
    initialValues: {
      username: '',
      phone: '',
      confirm_password: '',
      old_password: '',
      first_name: '',
      last_name: '',
      password: '',
      avatar: '',
      mobil: '',
      email: '',
      costCenter_id: '',
      id: memoryUtils.user.id_user
    },
    validate: {
      username: value => {
        if (!value) {
          return 'username  is required'
        }
        if (value.length < 5 || value.length > 36) {
          return 'username   must be between 6 and 36 characters'
        }
        return null
      }
    }
  })
  // 获取用户信息

  const ajaxCurrentList = async () => {
    const response = await regGeUser(memoryUtils.user.id_user)

    const result = response.data

    if (result.code === 200 && !Array.isArray(result.data)) {
      form.setFieldValue('username', result.data.username)
      form.setFieldValue('phone', result.data.phone)
      form.setFieldValue('email', result.data.email)
      form.setFieldValue('mobil', result.data.mobil)
      form.setFieldValue('avatar', result.data.avatar)
      form.setFieldValue('first_name', result.data.first_name)
      form.setFieldValue('last_name', result.data.last_name)
      setImagePath(result.data.avatar)
      form.setFieldValue(
        'costCenter_id',
        isNotEmpty(result?.data?.cost_center) ? result.data.cost_center[0]?.id_costcenter || '0' : '0'
      )

      setTempFormData({
        username: result.data.username,
        phone: result.data.phone,
        confirm_password: '',
        old_password: '',
        password: '',
        first_name: result.data.first_name,
        last_name: result.data.last_name,
        avatar: result.data.avatar,
        mobil: result.data.mobil,
        email: result.data.email,
        costCenter_id: isNotEmpty(result?.data?.cost_center) ? result.data.cost_center[0]?.id_costcenter || '0' : '0',
        id: memoryUtils.user.id_user
      })
    } else {
      console.log(result.msg)
    }
  }

  /**
   * Get cost center data
   */
  const getCostCenter = async () => {
    // Call the Get Cost Center General Selection function and set the cost center data
    setCostCenterData(await getCostCenterGeneralSelectFunction())
  }

  useEffect(() => {
    ajaxCurrentList()
    getCostCenter()
  }, [])

  const [visible, { toggle }] = useDisclosure(false)

  // 用于修改密码后退出登录
  const loginOut = async () => {
    const response = await regLogout()
    const result = response.data
    if (result.code === 200) {
      localstorageUnits.saveUser({})
      navigate('/login')
      notifications.show({
        title: 'You have exited the system',
        color: 'green',
        icon: <IconCheck size="1.5rem" />,
        message: result.msg
      })
    } else {
      notifications.show({
        title: 'logOut Error',
        color: 'red',
        icon: <IconX size="1.5rem" />,
        message: result.msg
      })
    }
  }

  // 检查表单是否修改
  const onFormEdit = () => {
    let isEdit = true
    const entries = Object.entries(tempFormData)
    for (const [key, value] of entries) {
      if (form.getInputProps(key).value !== value) {
        isEdit = false
        return isEdit
      }
    }
    return isEdit
  }

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault() // Stop the formal submission of the form default
    // Verification form
    const valid = await form.validate()

    // 最终提交的form数据
    let formData = {}
    if (!valid.hasErrors) {
      // 全部留空，为不修改
      if (form.values.password === '') {
        // 除去密码相关
        const { old_password, password, confirm_password, ...newFormData } = form.values
        formData = newFormData
      } else {
        if (form.values.password !== form.values.confirm_password) {
          notifications.show({
            title: 'Password Error ',
            color: 'red',
            icon: <IconX />,
            message: 'The two passwords do not match'
          })
          return
        }

        // 验证输入的旧密码
        if (form.values.old_password === form.values.password) {
          notifications.show({
            title: 'Password Error ',
            color: 'red',
            icon: <IconX />,
            message: 'NewPassword InputValue Same AS OldPassword InputValue'
          })
          return
        }

        formData = form.values
      }

      const response = await regUser(formData, 'PUT')
      const result = response.data
      if (result.code === 200) {
        // 保存当前请求成功的数据
        setTempFormData(form.values)

        const preInfo = localstorageUnits.getUser()
        localstorageUnits.saveUser({ ...preInfo, avatar: imagePath })
        notifications.show({
          color: 'green',
          icon: <IconCheck />,
          message: result.msg
        })

        // 修改成功，退出登录
        // if ("password" in formData) {
        //   loginOut();
        // }
      } else {
        notifications.show({
          color: 'red',
          icon: <IconX />,
          message: result.msg
        })
      }
    }
  }

  const handleValueChange = (value: string) => {
    form.setFieldValue('avatar', value)
    setImagePath(value)
  }
  return (
    <Paper px={20} w={'100%'}>
      <Box pb={20}>
        <PageTitle title={word_translation.profile_settings} />
        <form onSubmit={handleFormSubmit}>
          <Grid gutter={0}>
            <Grid.Col span={6}>
              <Box>
                <UploadSingleImage
                  callback={value => handleValueChange(value)}
                  imagepath={apiUrl.fileSystemDomain + imagePath}
                  is_custome={true}
                  wimage={95}
                  himage={90}
                />
              </Box>

              <Card withBorder mt={20}>
                <Grid gutter={0}>
                  <Grid.Col span={5}>
                    <TextInput
                      label="First Name"
                      placeholder="Your First Name"
                      withAsterisk
                      mt="md"
                      {...form.getInputProps('first_name')}
                    />

                    <TextInput
                      label={<FormattedMessage id="username" defaultMessage="Username" />}
                      mt="md"
                      placeholder={intl.formatMessage({
                        id: 'input',
                        defaultMessage: 'Input'
                      })}
                      disabled
                      withAsterisk
                      {...form.getInputProps('username')}
                    />

                    <TextInput
                      label={<FormattedMessage id="mobil" defaultMessage="Mobil" />}
                      placeholder={intl.formatMessage({
                        id: 'input',
                        defaultMessage: 'Input'
                      })}
                      withAsterisk
                      mt="md"
                      {...form.getInputProps('mobil')}
                    />
                  </Grid.Col>
                  <Grid.Col span={5} mx={40}>
                    {/* <TextInput
                    label="Last Name"
                    placeholder="Your Last Name"
                    withAsterisk
                    mt="md"
                    {...form.getInputProps('')}
                  /> */}
                    <TextInput
                      label="Last Name"
                      placeholder="Your Last Name"
                      withAsterisk
                      mt="md"
                      {...form.getInputProps('last_name')}
                    />

                    <TextInput
                      label={<FormattedMessage id="phone" defaultMessage="Phone" />}
                      mt="md"
                      placeholder={intl.formatMessage({
                        id: 'input',
                        defaultMessage: 'Input'
                      })}
                      withAsterisk
                      {...form.getInputProps('phone')}
                    />

                    <TextInput
                      label={<FormattedMessage id="Email" defaultMessage="Email" />}
                      mt="md"
                      placeholder={intl.formatMessage({
                        id: 'input',
                        defaultMessage: 'Input'
                      })}
                      withAsterisk
                      {...form.getInputProps('email')}
                    />
                  </Grid.Col>
                </Grid>
                <Grid gutter={0}>
                  <Grid.Col span={5}>
                    <PasswordInput
                      label={<FormattedMessage id="password_placeholder" defaultMessage="Password" />}
                      defaultValue=""
                      placeholder={intl.formatMessage({
                        id: 'input',
                        defaultMessage: 'Input'
                      })}
                      {...form.getInputProps('password')}
                      mt="md"
                      visible={visible}
                      onVisibilityChange={toggle}
                    />
                  </Grid.Col>
                  <Grid.Col mx={40} span={5}>
                    <PasswordInput
                      label={<FormattedMessage id="confirm_password" defaultMessage="Confirm password" />}
                      defaultValue="secret"
                      placeholder={intl.formatMessage({
                        id: 'input',
                        defaultMessage: 'Input'
                      })}
                      {...form.getInputProps('confirm_password')}
                      mt="md"
                      visible={visible}
                      onVisibilityChange={toggle}
                    />
                  </Grid.Col>
                </Grid>
                <Grid gutter={0} mt={20}>
                  <Grid.Col span={5}>
                    <PasswordInput
                      label={<FormattedMessage id="old_password" defaultMessage="Old Password" />}
                      placeholder={intl.formatMessage({
                        id: 'input',
                        defaultMessage: 'Input'
                      })}
                      withAsterisk
                      visible={visible}
                      onVisibilityChange={toggle}
                      {...form.getInputProps('old_password')}
                    />
                  </Grid.Col>
                  <Grid.Col mx={40} span={5}>
                    <Select
                      label={<FormattedMessage id="cost_center" defaultMessage="Cost Center" />}
                      data={costCenterData}
                      labelProps={{ className: 'input_title' }}
                      dropdownPosition="top"
                      disabled
                      placeholder={intl.formatMessage({
                        id: 'select_items',
                        defaultMessage: 'Pick one'
                      })}
                      onChange={value => form.setFieldValue('costCenter_id', value as string)}
                      key={form.values.costCenter_id}
                      defaultValue={form.values.costCenter_id.toString()}
                    />
                  </Grid.Col>
                </Grid>
                <Grid mt={20}>
                  <Grid.Col span={3}></Grid.Col>
                  <Grid.Col span={3}></Grid.Col>
                  <Grid.Col span={3} offset={3}>
                    <Button disabled={onFormEdit()} leftIcon={<IconUser />} type="submit">
                      {<FormattedMessage id="Save" defaultMessage="Save" />}
                    </Button>
                  </Grid.Col>
                </Grid>
              </Card>
            </Grid.Col>
            <Grid.Col span={3} mt={20} mx={10}>
              <TrackedHours />
            </Grid.Col>
          </Grid>
          {/* <Text size="sm" weight={500} mt="xl">
            Form values:
          </Text>
          <Code block mt={5}>
            {JSON.stringify(form.values, null, 2)}
          </Code> */}
        </form>
      </Box>
    </Paper>
  )
}
