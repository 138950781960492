import React, { useEffect, useState } from 'react';
import { ActionIcon, Grid, Code, Box, Group, Menu, Select, Text, Tooltip, MultiSelect, Textarea, TextInput, Modal } from "@mantine/core";

import TableTextInputTitle from "../../../../ttcomponents/TableTextInputTitle/TableTextInputTitle";
import WordTranslationComponent from '../../../../utils/language_pack/words';
import TableTextInputTitleDesc from '../../../../ttcomponents/TableTextInputTitleDesc/TableTextInputTitleDesc';
import { isNotEmpty, useForm } from '@mantine/form';
import { SelectRole } from '../../../../interface/Icommon';
import { DateInput } from '@mantine/dates';
import localstorageUnits from '../../../../utils/localstorageUnits';
import { IconBuilding, IconCalendar, IconHistory, IconMoneybag, IconPlus, IconX } from '@tabler/icons-react';
import ResetBtn from '../../../../ttcomponents/Button/ResetBtn';
import { HintErrorMantineInfo, HintInfo, formatDateUpgraded } from '../../../../utils/function';
import { requestTask } from '../../../../api/index_v2';
import UniversalButton from '../../../../ttcomponents/Button/UniversalButton';
import AddUserToTask from './add_user_totask';
import { PermissionUserAllList, UserAllList } from '../../../../interface/Iuser';


interface ItaskFromProps {
  callBackFunction: (task_id: string) => void;
  close: () => void;
  project_id?: string;
  project_name?: string;
  customer_id?: string;
  order_id?: string;
  c_userList: UserAllList[];
  edit_task_id?: string;
  userList: SelectRole[];
  opened:boolean;
  defaultRoleData:SelectRole[];
  costCenterData:SelectRole[];
  costCenterId?:string;
  taskCategoryList: SelectRole[];
  [key: string]: any;
}


const QuickCreateTask = ({project_id,order_id,
  userList,c_userList,customer_id,
  callBackFunction,opened,close,project_name,defaultRoleData,costCenterId,costCenterData,taskCategoryList}:ItaskFromProps) => {
  const [rand_number, setRandNumber] = useState(0);
    
  // Get the initial time of the system
  const initSystemTime = localstorageUnits.getSystem().init_use_date;
  // Get translation public configuration information
  const word_translation = WordTranslationComponent();


    useEffect(() => {
      if(costCenterId){
        form.setFieldValue('costCenter_id', costCenterId?.toString());
      }else{
        form.setFieldValue('costCenter_id','');
      }
      setRandNumber(Math.random());
      return () => {
        form.reset();
      }
    }, [opened]);  

    const form = useForm({
      validateInputOnBlur: true,
      initialValues: {
        project_id: project_id ? project_id : '',
        order_id: order_id ? order_id : '',
        customer_id:customer_id?customer_id:'',
        task_category_id: "",
        start_date: new Date(),
        costCenter_id: '',
        end_date: new Date(),
        budget: "0.00",
        id: "",
        free_description: "",
        cost: "0.00",
        target_time: "00:00",
        user_id: [],
        // user_id:  '',
        employees: [],
      },
      validate: {
        customer_id: isNotEmpty(`${word_translation.customer} ${word_translation.is_not_empty}`),
        task_category_id: isNotEmpty(`${word_translation.task_category} ${word_translation.is_not_empty}`),
        // user_id: isNotEmpty(`${word_translation.task_team_leader} ${word_translation.is_not_empty}`),
      },
    });
    
    /**
     * 
     * @param type 
     * @returns 
     */
  const FormSubmitFunction = async (type = 'save') => {
    // Validate the form
    const valid = await form.validate();
    //  If there are validation errors, display error information
    if (valid.hasErrors) {
      HintErrorMantineInfo(valid);
      return false;
    }
    // TODO:Convert time into data received by the backend
    const submitFormValues = {
      ...form.values,
      start_date: formatDateUpgraded(
        form.values.start_date as Date,
        "YYYY-MM-DD"
      ),
      end_date: formatDateUpgraded(
        form.values.end_date as Date,
        "YYYY-MM-DD"
      ),
    };

    const response = await requestTask(submitFormValues, form.values.id ? "PUT" : "POST");
  
    HintInfo(response.data);
    if (response.data.code == 200) {
      // resetProductFormFunction();
      if (response.data.data.id) {
        callBackFunction(response.data.data.id)
        return false;
      }
      callBackFunction('')
      return false;
    } else {
      return false;
    };
  };

  /**
   * Reset the form
   */
  function resetProductFormFunction(): void {
    form.reset();
    if (costCenterId) {
      form.setValues({
        'costCenter_id': costCenterId?.toString()
      })
    }
  }

  
    return (
      <Modal  closeOnClickOutside={false} opened={opened} onClose={close} title={`${project_name} - Task`}>
            <Box mt={10} >
                <TableTextInputTitle
                  icon_l={<IconBuilding size={15} />}
                  title={word_translation.select_cost_center}
                />
                <Select
                  onChange={(value) => {
                    form.setFieldValue("costCenter_id", value as string)
                   }
                  }
                  rightSection={<ActionIcon size='xs' onClick={() => form.setFieldValue("costCenter_id", '')}> <IconX /></ActionIcon>}
                  placeholder={word_translation.select_cost_center}
                  disabled={costCenterId?true:false}
                  size="xs"
                  value={form.values.costCenter_id?.toString()}
                  data={costCenterData}
                />

                <TableTextInputTitle
                  title={word_translation.task_category}
                />
                <TableTextInputTitleDesc
                  title={word_translation.pick_one}
                />
                <Select
                  placeholder={word_translation.task_category}
                  searchable
                  size="xs"
                  key={form.values.task_category_id}
                  defaultValue={form.values.task_category_id?.toString()}
                  nothingFound={word_translation.no_data}
                  onChange={(value) =>
                    form.setFieldValue("task_category_id", value as string)
                  }
                  data={taskCategoryList}
                />
                  <TableTextInputTitle
                  icon_l={<IconMoneybag size={15} />}
                  title={word_translation.budget}
                />
                <TableTextInputTitleDesc
                  title={word_translation.leave_empty}
                />
                <TextInput
                  size="xs"
                  placeholder={word_translation.budget}
                  {...form.getInputProps("budget")}
                />
                <TableTextInputTitle
                  icon_l={<IconHistory size={15} />}
                  title={word_translation.target_time}
                />
                <TableTextInputTitleDesc
                  title={word_translation.leave_empty}
                />
                <TextInput
                  placeholder={word_translation.target_time}
                  {...form.getInputProps("target_time")}
                  size="xs"
                />

              {/*    <TableTextInputTitle title={word_translation.description} />
                <Textarea
                  placeholder={word_translation.Input}
                  size="xs"
                  {...form.getInputProps("free_description")}
                />

                <TableTextInputTitle
                  title={word_translation.task_team_leader}
                />
                <MultiSelect
                  onChange={(value) =>
                    form.setFieldValue("user_id", value as [])
                  }
                  size="xs"
                  key={rand_number}
                  defaultValue={form.values.user_id}
                  placeholder={word_translation.task_team_leader}
                  searchable
                  data={userList}
                />
                <Grid>
                  <Grid.Col span={6}>
                    <TableTextInputTitle title={word_translation.start_time} />
                    <DateInput
                      valueFormat="DD/MM/YYYY"
                      minDate={new Date(initSystemTime)}
                      size="xs"
                      defaultValue={new Date()}
                      required
                      icon={<IconCalendar size="1.1rem" stroke={1.5} />}
                      {...form.getInputProps("start_date")}
                      // onBlur={(event) => checkTime(event.target.value)}
                      placeholder={word_translation.start_time}
                    />
                  </Grid.Col>

                  <Grid.Col span={6}>
                    <TableTextInputTitle title={word_translation.end_time} />
                    <DateInput
                      valueFormat="DD/MM/YYYY"
                      size="xs"
                      minDate={form.values.start_date}
                      required
                      icon={<IconCalendar size="1.1rem" stroke={1.5} />}
                      {...form.getInputProps("end_date")}
                      placeholder={word_translation.end_time}
                    // onBlur={(event) => checkTime(event.target.value)}
                    />
                  </Grid.Col>


                </Grid> */}
          
              

             
        

            {/* <Box mt={10} className="border-radius-xs border-custom ">
              <AddUserToTask
                existsUserList={existsUserList}
                callBackFunction={callBackAddUserToTaskFunction}
                UserList={c_userList}
                projectUserRolelist={defaultRoleData} />
            </Box>          */}
         
                <Group mt={20} position="right" >
                  <ResetBtn callBack={resetProductFormFunction} />
                  <UniversalButton
                   btn_text={word_translation.add_task} 
                   callBack={FormSubmitFunction} 
                   btn_size='xs' 
                   btn_color="green" 
                   btn_icon={<IconPlus />} />
                </Group>
         
          </Box>
          
      </Modal>
    );
};

export default QuickCreateTask;

