import { useEffect, useState } from "react";
import {
  createStyles,
  Table,
  ScrollArea,
  UnstyledButton,
  Group,
  Modal,
  Text,
  Title,
  Center,
  TextInput,
  rem,
  Button,
  ActionIcon,
  Box,
  Code,
  LoadingOverlay,
  Badge,
  Tooltip,
} from "@mantine/core";
import { keys } from "@mantine/utils";
import {
  IconSelector,
  IconChevronDown,
  IconChevronUp,
  IconSearch,
  IconX,
  IconCheck,
  IconEdit,
  IconTrash,
  IconPencil,
  IconUserPlus,
  IconPlus,
} from "@tabler/icons-react";
import theme from "../../../theme";
import DeleteModal from "../../../ttcomponents/DeleteModals/DeleteModal";
import { modals } from "@mantine/modals";
import { ITaskCategoryParameter } from "../../../interface/ItaskCategoryParameter";
import { useDisclosure } from "@mantine/hooks";
import TaskCategoryFrom from "./task_category_from";
import {
  ITaskCategoryParameterWithItaskCategory,
  ItaskCategory,
} from "../../../interface/ItaskCategory";
import { regTaskCategory } from "../../../api";
import { notifications } from "@mantine/notifications";
import CreateBtn from "../../../ttcomponents/Button/CreateBtn";
import { useIntl } from "react-intl";
import WordTranslationComponent from "../../../utils/language_pack/words";
import ModalTitleText from "../../../ttcomponents/ModalTitleText/ModalTitleText";
import { NotPermission } from "../../../ttcomponents/NoPermission";
import CheckPermissionTools from "../../../utils/permission";

const useStyles = createStyles((theme) => ({
  th: {
    padding: "0 !important",
  },

  control: {
    width: "100%",
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },

  icon: {
    width: rem(21),
    height: rem(21),
    borderRadius: rem(21),
  },
}));

interface TableSortProps {
  taskCategoryParameterList: ITaskCategoryParameter[];
  isLoading: boolean;
  taskCategoryList: ItaskCategory[];
  onValueEdit?: (value: object) => void;
  onValueDel?: (id: string) => void;
  callRefresh: () => void;
  callback: () => void;
}

interface ThProps {
  children: React.ReactNode;
  reversed: boolean;
  sorted: boolean;
  onSort(): void;
}

function Th({ children, reversed, sorted, onSort }: ThProps) {
  const { classes } = useStyles();
  const Icon = sorted
    ? reversed
      ? IconChevronUp
      : IconChevronDown
    : IconSelector;
  return (
    <th className={classes.th}>
      <UnstyledButton onClick={onSort} className={classes.control}>
        <Group position="apart">
          <Text fw={500} fz="sm">
            {children}
          </Text>
          <Center className={classes.icon}>
            <Icon size="0.9rem" stroke={1.5} />
          </Center>
        </Group>
      </UnstyledButton>
    </th>
  );
}

function filterData(data: ItaskCategory[], search: string) {
  const query = search.toLowerCase().trim();

  console.log(data, "data", keys(data[0]));
  return data.filter(
    (item) =>
      item["description"].toLowerCase().includes(query) ||
      item["identifier"].toLowerCase().includes(query)
  );
}

function sortData(
  data: ItaskCategory[],
  payload: {
    sortBy: keyof ItaskCategory | null;
    reversed: boolean;
    search: string;
  }
) {
  const { sortBy } = payload;

  if (!sortBy) {
    return filterData(data, payload.search);
  }

  return filterData(
    [...data].sort((a, b) => {
      if (payload.reversed) {
        return b[sortBy].localeCompare(a[sortBy]);
      }

      return a[sortBy].localeCompare(b[sortBy]);
    }),
    payload.search
  );
}

export default function TableCateGory({
  callRefresh,
  taskCategoryParameterList,
  taskCategoryList,
  isLoading,
  onValueEdit,
  onValueDel,
}: TableSortProps) {
  // Use the useIntl hook to access the intl object
  const intl = useIntl();
  // Get translation public configuration information
  const word_translation = WordTranslationComponent();

  const Perm = CheckPermissionTools();
  useEffect(() => {
    setSortedData(taskCategoryList);
    // ...
  }, [taskCategoryList]);
  // 定义打开状态
  const [
    editDataStatus,
    { open: openEditDataStatus, close: closeEditDataStatus },
  ] = useDisclosure(false);
  // 定义初始化新增或者修改时的复选框

  const [parameterIdValuesFather, setParameterIdValues] = useState<string[]>();

  const [taskCategoryInfo, setTaskCategoryInfo] = useState<ItaskCategory>({
    id_task_category: "",
    identifier: "",
    description: "",
    type: "1",
    parameters: [],
  });
  const [sortedData, setSortedData] = useState(taskCategoryList);

  const delHandle = (row: ItaskCategory) => {
    modals.openConfirmModal({
      title: intl.formatMessage({
        id: "delete_title",
        defaultMessage: "Delete Confirmation",
      }),
      centered: true,
      children: (
        <Text size="sm">
          identifier: {row.identifier}
          <br />
          description：{row.description}
        </Text>
      ),
      labels: {
        confirm: intl.formatMessage({
          id: "confirm_delete",
          defaultMessage: "Confirm Delete",
        }),
        cancel: intl.formatMessage({
          id: "cancel_delete",
          defaultMessage: "Cancel Delete",
        }),
      },
      confirmProps: { color: "red" },
      onConfirm: async () => {
        const response = await regTaskCategory(
          { id: row.id_task_category },
          "DELETE"
        );
        const result = response.data;
        if (result.code === 200) {
          callRefresh();
          notifications.show({
            color: "green",
            icon: <IconCheck />,
            message: result.msg,
          });
        } else {
          notifications.show({
            color: "red",
            icon: <IconX />,
            message: result.msg,
          });
        }
      },
    });
  };

  // 打开编辑或者创建任务类型参数模态框
  const openDataEdit = (value: any) => {
    if (value == undefined) {
      setParameterIdValues([]);
    } else {
      const parameterIds = value.parameters.map(
        (parameter: { id_task_category_parameter: any }) =>
          parameter.id_task_category_parameter.toString()
      );
      setParameterIdValues(parameterIds);
    }
    setTaskCategoryInfo(value);
    openEditDataStatus();
  };

  // 修改表单创建表单回调函数、关闭模态框
  const callbackFrom = () => {
    callRefresh();
    closeEditDataStatus();
  };
  function handleCallback(value:any): void {
    openDataEdit(value);
  }

  return (
    <ScrollArea>
      <LoadingOverlay visible={isLoading} overlayBlur={2} />
      <Group my={10}>
        <Box>
          <Title order={6}>
            {word_translation.task_category}
          </Title>
        </Box>
        <Box>
          { ( Perm.task_category_create || Perm.core_assignment_create) && 
          <ActionIcon onClick={() => handleCallback(undefined)}>
            <Tooltip label={word_translation.create}>
              <IconPlus size="1rem" stroke={1.5} />
            </Tooltip>
          </ActionIcon> }
          
        </Box>
      </Group>
      
      {(Perm.task_category_read  || Perm.core_assignment_read ) ?   <table className="table_custome text-center">
        <thead className="table_th_xs  ">
          <tr>
            <th id="table-th-title-new">{word_translation.identifier}</th>
            <th id="table-th-title-new">{word_translation.description}</th>
            <th id="table-th-title-new">{word_translation.parameter_collection}</th>
            <th id="table-th-title-new">{word_translation.option}</th>
          </tr>
        </thead>
        <tbody>
          {sortedData.length > 0 ? sortedData?.map((row, index) => (
            <tr key={index}>
              <td>{row.identifier}</td>
              <td>{row.description}</td>
              <td>
                {row.parameters?.map((item, index) => (
                  <Box>
                    {item.name} -<Code> {item.matrix}</Code>
                  </Box>
                ))}
              </td>
              <td>
                <Group spacing={0} position="center">
                  <ActionIcon onClick={() => openDataEdit(row)}>
                    <Tooltip label={word_translation.edit_tips}>
                      <IconPencil size="1rem" stroke={1.5} />
                    </Tooltip>
                  </ActionIcon>
                  <ActionIcon onClick={() => delHandle(row)}>
                    <Tooltip label={word_translation.delete_tips}>
                      <IconTrash size="1.2rem" color="red" stroke={1.5} />
                    </Tooltip>
                  </ActionIcon>
                </Group>
              </td>
            </tr>
          )) : (
            <tr>
              <td colSpan={5}>
                <Text weight={500} align="center">
                  {word_translation.no_data}
                </Text>
              </td>
            </tr>
          )}
        </tbody>
      </table> :<NotPermission /> }
    
      {/* 添加order */}
      <Modal
        size="md"
        opened={editDataStatus}
        onClose={closeEditDataStatus}
        title={<ModalTitleText title={word_translation.task_category} />} 
      >
        <TaskCategoryFrom
          parameterIdValuesFather={parameterIdValuesFather}
          callback={callbackFrom}
          taskCategoryInfo={taskCategoryInfo}
          taskCategoryParameterList={taskCategoryParameterList}
        />
      </Modal>
    </ScrollArea>
  );
}
