import { Paper, Tabs } from '@mantine/core'
import { IconBuilding, IconSettings } from '@tabler/icons-react'
import WordTranslationComponent from '../../../../utils/language_pack/words'
import { useEffect, useState } from 'react'
import { getCorePermissionIdentifierUniqueList } from '../../../../api/core_request'
import {
  requestCorePermissionIdentifier,
  requestLeavePermissionIdentifier,
  requestReportPermissionIdentifier
} from '../../../../api'
import PermissionIdentifier from '../../../../ttcomponents/Permission/permission_identifier'
import { NotPermission } from '../../../../ttcomponents/NoPermission'
import CheckPermissionTools from '../../../../utils/permission'
import { SelectRole } from '../../../../interface/Icommon'
import { getTmPerIdentifierUniqueList } from '../../../../api/assignments_request'
import { requestPermissionIdentifier, requestTMPermissionIdentifier } from '../../../../api/index_v2'
import { getLeavePerIdentifierUniqueList } from '../../../../api/leave_request'
import { getTMPerIdentifierUniqueList } from '../../../../api/timetracker_request'
interface ComponentInterface {
  is_load_str?: string
}
export default function PermissionIdentifierIndex({ is_load_str }: ComponentInterface) {
  // Get translation public configuration information
  const word_translation = WordTranslationComponent()
  const Perm = CheckPermissionTools()

  const [permissionIdentifier, setPermissionIdentifier] = useState<SelectRole[]>([])

  const [currentType, setActiveTab] = useState<string | null>('core')
  // Get the unique logo list
  const getUniqueIdentifierList = async (closureFunction: any) => {
    // Call the Get Cost Center General Selection function and set the cost center data
    setPermissionIdentifier(await closureFunction)
  }
  var text = <NotPermission />

  useEffect(() => {
    setPermissionIdentifier([])
    switch (currentType) {
      case 'core':
        getUniqueIdentifierList(getCorePermissionIdentifierUniqueList())
        break
      case 'assignment':
        getUniqueIdentifierList(getTmPerIdentifierUniqueList())
        break
      case 'leave_request':
        getUniqueIdentifierList(getLeavePerIdentifierUniqueList())
        break
      case 'timetracker':
        getUniqueIdentifierList(getTMPerIdentifierUniqueList())
        break
      default:
        setPermissionIdentifier([])
    }
  }, [currentType])
  /**
   *----------
   *组件需要的权限
   *----------
   */
  const corePremission: any = {
    create: Perm.permission_create,
    update: Perm.permission_update,
    delete: Perm.permission_delete
  }

  const settings = {
    links: [
      {
        label: 'core',
        view: Perm.identifier_read ? (
          <PermissionIdentifier
            Premission={corePremission}
            permissionIdentifier={permissionIdentifier}
            submitFunction={requestCorePermissionIdentifier}
            requestListFunction={requestCorePermissionIdentifier}
            is_load_str={is_load_str}
          />
        ) : (
          <NotPermission />
        ),

        icon: IconSettings
      },
      {
        label: 'assignment',
        view: (
          <PermissionIdentifier
            Premission={corePremission}
            permissionIdentifier={permissionIdentifier}
            submitFunction={requestPermissionIdentifier}
            requestListFunction={requestPermissionIdentifier}
            is_load_str={is_load_str}
          />
        ),

        icon: IconBuilding
      },
      {
        label: 'leave_request',
        view: (
          <PermissionIdentifier
            Premission={corePremission}
            permissionIdentifier={permissionIdentifier}
            submitFunction={requestLeavePermissionIdentifier}
            requestListFunction={requestLeavePermissionIdentifier}
            is_load_str={is_load_str}
          />
        ),

        icon: IconBuilding
      },

      {
        label: 'timetracker',
        view: (
          <PermissionIdentifier
            Premission={corePremission}
            permissionIdentifier={permissionIdentifier}
            submitFunction={requestTMPermissionIdentifier}
            requestListFunction={requestTMPermissionIdentifier}
            is_load_str={is_load_str}
          />
        ),

        icon: IconBuilding
      },

      {
        label: 'report',
        view: (
          <PermissionIdentifier
            Premission={corePremission}
            permissionIdentifier={permissionIdentifier}
            submitFunction={requestReportPermissionIdentifier}
            requestListFunction={requestReportPermissionIdentifier}
            is_load_str={is_load_str}
          />
        ),

        icon: IconBuilding
      },
      { label: 'inventory', view: <>{text}</>, icon: IconSettings }
    ]
  }

  return (
    <Paper>
      <Tabs mt={10} defaultValue={currentType} onTabChange={setActiveTab} keepMounted={false}>
        <Tabs.List>
          {/* Render individual tabs with icons and labels */}
          {settings.links.map((item, index) => (
            <Tabs.Tab key={item.label} value={item.label} icon={<item.icon size={20} slope={1.5} />}>
              {word_translation[item.label as keyof typeof word_translation]}
            </Tabs.Tab>
          ))}
        </Tabs.List>
        {/* Render corresponding panels for each tab */}
        {settings.links.map((item, index) => (
          <Tabs.Panel key={index} value={item.label} pt="xs">
            {item.view}
          </Tabs.Panel>
        ))}
      </Tabs>
      {/* Tabs for different settings */}
    </Paper>
  )
}
