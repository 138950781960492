import { Box, Card, ScrollArea, Table, Tooltip } from "@mantine/core";
import { UserTimeRecord } from "../../../interface/Ireports";
import WordTranslationComponent from "../../../utils/language_pack/words";
import React from 'react';
import { ConvertTimeStringToDecimal, formatTimeHHSS } from "../../../utils/function";

interface WeekData {
  week: string;
  time?: string;
  year?: string;
}


interface ResponseData {
  list: WeekData[];
  title: string[];
}
interface ComponentInterface {
  userTimeProject: ResponseData | undefined; // Array of MonthlyTotal objects or undefined
  getUserWeekProjectReport: (value: any) => void; // Array of MonthlyTotal objects or undefined
}

export default function UserItemProject({ userTimeProject, getUserWeekProjectReport }: ComponentInterface) {
  // Get translation public configuration information
  const word_translation = WordTranslationComponent();
  return (
    <Card bg='#eee'>
      <ScrollArea w='100%'>
        {/* ... content */}
        <Table mt={20} withBorder withColumnBorders striped highlightOnHover>
          <thead>
            <tr>
              {userTimeProject?.title.map((item3, index3) => {
                return (
                  <th className="cursor-pointer text-right" onClick={() => getUserWeekProjectReport(item3)}>
                    <Box>
                      <Tooltip position="bottom" label={item3}>
                        <span> {item3.substring(5)}</span>
                      </Tooltip>
                    </Box>
                    <Box>
                      <Tooltip position="bottom" label={item3}>
                        <span> {item3.substring(0, 4)}</span>
                      </Tooltip></Box>
                  </th>
                )
              })}

            </tr>
          </thead>
          <tbody>
            <tr>
              {userTimeProject?.list.map((item4, index4) => {
                return (
                  <td> {ConvertTimeStringToDecimal(formatTimeHHSS(item4?.time as string))} {word_translation.hour}</td>
                )
              })}
            </tr>
          </tbody>
        </Table>
      </ScrollArea>


    </Card>
  )
}
