import { Box, Paper, Grid, Group, Text, Button, TextInput, Table } from "@mantine/core";
import PageTitle from "../../../ttcomponents/PageTitle/PageTitle";
import { useIntl } from "react-intl";
import { useForm } from "@mantine/form";
import { IconAt, IconClipboardPlus, IconTrashX } from "@tabler/icons-react";
import globalCoreSetting from "../../../utils/globalCortSetting";
import GlobalCoreSetting from "../../../utils/globalCortSetting";
import { FocusEvent, FormEvent, useEffect, useRef, useState } from "react";
import { SelectRole } from "../../../interface/Icommon";
import { regUserAll } from "../../../api";
import { Attribute, AttributeType, ResponseUserData } from "../../../interface/Iuser";
import CustomAttributeTable from "./custom_attribute_table";
import TableTopTitle from "../../../ttcomponents/TableTopTitle/TableTopTitle";
import CustomAttributeTypeTable from "./custom_attribute_type_table";
import WordTranslationComponent from "../../../utils/language_pack/words";
import TableTextInputTitle from "../../../ttcomponents/TableTextInputTitle/TableTextInputTitle";
import CustomAttrbutesForm from "./custom_attrbutes_form";
import CustomAttrbutesTypeForm from "./custom_attrbutes_type_form";

/**
 * OnBoarding 
 * @returns 
 */
export default function CustomAttrbutesBoarding() {

    // Use the useIntl hook to access the intl object
    const intl = useIntl();
    // Get the default unchanged configuration
    const globalCoreSetting = GlobalCoreSetting();
    
    const [attributeList, setAttributeListFunction] = useState<AttributeType[]>([]);
    // Get translation public configuration information
    const word_translation = WordTranslationComponent();

    // Define all the list of employees you can choose
    const [userlist, setUserList] = useState<SelectRole[]>([]);
    // 设置是否刷新自定义属性列表
    const [refreshAttribute, setRefreshAttribute] = useState<number>(1);
    const [refreshAttributeType, setRefreshAttributeType] = useState<number>(1);
    // 定义自定义属性类型某一条变量
    const [attribute_type_item, setAttributeTypeItem] = useState<AttributeType | undefined>();
    // 定义自定义属性某一条变量
    const [attribute_item, setAttributeItem] = useState<Attribute| undefined>();
    
    const form = useForm({
        initialValues: {
            user_id: "",
            personal_no: "",
            username: "",
            cost_center_id: "",
            email: "",
            phone: "",
            mobil: "",
            avatar: "",
            approval_permission: "",
            year_vacation_days: "30",
            week_target_hours: "40",
            active: "",
            confirm_password: "",
            password: "",
        },
        validate: {
            /**
                     * 验证用户名函数
                     * @param {string} value - 用户名
                     * @returns {string|null} 错误信息或null
                     */
            username: (value) => {
                if (!value) {
                    return "username  is required";
                }
                if (value.length < 5 || value.length > 36) {
                    return "username   must be between 6 and 36 characters";
                }
                return null;
            },
            /**
                     * 验证密码函数
                     * @param {string} value - 密码
                     * @param {Object} values - 所有表单字段的值
                     * @returns {string|null} 错误信息或null
                     */
            password: (value, values) => {
                if (values.password && values.password.length < 6) {
                    return "Password must be at least 6 characters";
                }
                return null;
            },
        },
    });



    //  Selected information of loading items: customers, employees, order names
    const ajaxCurrentList = async () => {
        //Obtain user
        const responseUser = await regUserAll({});
        const resultUser = responseUser.data as ResponseUserData;
        const userData = resultUser.data.map((item) => ({
            value: item.id_user as string,
            label: item.username,
        }));
        setUserList(userData);
    };
    useEffect(() => {
        ajaxCurrentList();

    }, []);
  
     
    function CustomAttrbutesCallTableFunction(item:Attribute): void {
        setAttributeItem(item)
    }
    
     
    function CustomAttrbutesCallFunction(): void {
        setRefreshAttribute(refreshAttribute+1)
    }
    
    function CustomAttrbutesTypeCallTableFunction(item:AttributeType): void {
        setAttributeTypeItem(item)
    }   
    
     
    function CustomAttrbutesTypeCallFunction(): void {
        setRefreshAttributeType(refreshAttributeType+1)
    }
    
    return (
        <Paper px={20}>
            <PageTitle title="Custom Attrbutes - BOARDING" lang_id="custom_attrbutes_boarding" />
            <Box mt={10}>
                <Grid gutter={0}  >
                    <Grid.Col span={5} >
                        {/* Create Attribute */}
                        <Box className="border-radius-xs min-h-20 border-custom">
                            <CustomAttrbutesForm attributeList={attributeList} current_item_info={attribute_item}    callFunction={CustomAttrbutesCallFunction}  />
                        </Box>

                        {/* Create Attribute end*/}

                        {/* Create Type */}
                        <Box className="border-radius-xs min-h-20 mt-lg border-custom">
                            <CustomAttrbutesTypeForm current_item_info={attribute_type_item}  callFunction={CustomAttrbutesTypeCallFunction}  />
                        </Box>
                        {/*Create Type end */}

                    </Grid.Col>
                    <Grid.Col span={6} >

                        {/* Existing Attribute */}
                        <Box className="border-radius-xs min-h-26  border-custom  px-xs">
                            <TableTopTitle lang_id="existing_attribute" title="Existing Attribute"  />
                            <CustomAttributeTable callBackfunction={CustomAttrbutesCallTableFunction}  is_refresh={refreshAttribute} />     
                        </Box>
                        {/* Existing Attribute End */}

                            {/* Existing Types */}
                        <Box h={270} mt={6} className="border-radius-xs border-custom  px-xs   " id="mt-lg">
                            <TableTopTitle lang_id="attribute_type_list" title="Attribute List"  />
                            <CustomAttributeTypeTable returnAttributeList={setAttributeListFunction} callBackfunction={CustomAttrbutesTypeCallTableFunction} is_refresh={refreshAttributeType} />     
                        </Box>
                        {/* Existing Types End */}
                    </Grid.Col>
                    {/* Affiliation end */}

                </Grid>
            </Box>
            {/* Custom Attributes */}
            <Box>

            </Box>
            {/* Custom Attributes end */}
        </Paper>
    )
}