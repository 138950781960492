import {
  Box,
  Grid,
  Avatar,
  Code,
  Modal,
  Group,
  Text,
  ScrollArea,
  Tooltip,
  ActionIcon,
  Textarea,
  Mark,
  Table,
  Badge,
  Flex,
  LoadingOverlay,
} from "@mantine/core";
import "../css/index.css";
import { useEffect, useRef, useState } from "react";
import { momentLocalizer, } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import { Vacation } from "../../../interface/Ivaction";
import { HintInfo, formatDate } from "../../../utils/function";
import { modals } from "@mantine/modals";
import {
  regDeliverHolidays,
  regGetVationAction,
  regLeaveSickReaded,
  regVacationApproval,
} from "../../../api";
import EditVation from "../edit_vacation";
import { useDisclosure } from "@mantine/hooks";
import memoryUtils from "../../../utils/memoryUtils";
import { useIntl } from "react-intl";
import apiUrl from '../../../config/api_config/config';
import GlobalCoreSetting from "../../../utils/globalCortSetting";
import WordTranslationComponent from "../../../utils/language_pack/words";
import LeaveManageRowTh from "./leave_th";
import LeaveManageRowTd from "./leave_td";
import EditIcon from "../../../ttcomponents/EditIcon/EditIcon";
import DeleteIcon from "../../../ttcomponents/DeleteIcon/DeleteIcon";
import TableTextInputTitle from "../../../ttcomponents/TableTextInputTitle/TableTextInputTitle";
import ChecXIcon from "../../../ttcomponents/Icon/IconChecX";
import IconCheckDs from "../../../ttcomponents/Icon/IconChecks";
import ViewIcon from "../../../ttcomponents/ViewIcon/ViewIcon";
import LeaveInfo from "../common/preview";
import { IconFilter } from "@tabler/icons-react";

interface IRow {
  UserVationData: Vacation[];
  type: number;
  callBackUser: (value: Vacation) => void;
  refreshItem: (value: string) => void;
  callBack: () => void;
  statusSwich: () => void;
}
export default function LeaveRows({
  UserVationData,
  callBackUser,
  refreshItem,
  callBack,
  statusSwich,
  type,
}: IRow) {

  // Get translation public configuration information
  const word_translation = WordTranslationComponent();

  const [openvacationInfoStatus, { open: openvacationInfo, close: closevacationInfo }] = useDisclosure(false);
  const [openedEditVation, { open: EditVationopened, close: EditVationClose }] =
    useDisclosure(false);
  const [vacation, setVacation] = useState<Vacation>();
  var remarkNew = "";
  // Settings
  const [commnetvalue, setCommentValue] = useState("");
  const [loading, setLoading] = useState(false);
  const countRef = useRef(commnetvalue);
  countRef.current = commnetvalue;

  const openHandleVacationModal = async (row: any, type: string) => {
    type TitleSaveType = string | JSX.Element;
    let saveBtnText = '';
    let saveBtnColor = 'greater';
    let contentHtml: TitleSaveType;

    contentHtml = (
      <Box>
        <Grid>
          <Grid.Col span={12}>
            <Group>
              <TableTextInputTitle title={word_translation.username} />
              <Code className="columns-text">   {row.user_info.username}</Code>
            </Group>
            <Group>
              <TableTextInputTitle title={word_translation.start_time} />
              <Code className="columns-text">   {row.from}</Code>
            </Group>
            <Group>
              <TableTextInputTitle title={word_translation.end_time} />
              <Code className="columns-text">   {row.to}</Code>
            </Group>
          </Grid.Col>
        </Grid>
      </Box>
    );


    if (type == "agree") {
      saveBtnText = word_translation.agree;
    } else if (type == "reject") {
      saveBtnText = word_translation.reject;
      saveBtnColor = 'red'
    }

    if (row.status_id.toString() == '6') {
      saveBtnText = "Cancel an approved vacation"
    }


    modals.openConfirmModal({
      title: word_translation.vacation_approve_modal,
      children: (
        <Box >
          {/* 对已经审批的假期为取消状态的时候，不需要写remark */}
          {row.status_id.toString() != '6' && <Grid>
            <Grid.Col span={12}>
              <TableTextInputTitle title={word_translation.approver_remarks} />
              <Textarea
                className="commnet-content"
                onChange={(event) => handleInputChange(event.currentTarget.value)} />
            </Grid.Col>
          </Grid>}

          <Grid>
            <Grid.Col span={12}>
              <Text className="columns-text"> {contentHtml}</Text>{" "}
            </Grid.Col>
          </Grid>
        </Box>
      ),
      labels: { confirm: saveBtnText, cancel: word_translation.cancel },
      onCancel: () => console.log(word_translation.cancel),
      confirmProps: { color: saveBtnColor },
      onConfirm: async () => {
        await ProcessingRequestHandle(row, type, remarkNew);
      },
    });
  };


  const handleInputChange = (newValue: string) => {
    setCommentValue(newValue); //update status
  };
  // Holiday request for processing application
  const ProcessingRequestHandle = async (
    row: any,
    type: string,
    remarks: string
  ) => {
    if (type == "agree") {
      const ApprovalResult = await regVacationApproval({ id: row.id_leave, status_id: "2", approver_remarks: commnetvalue }, "PUT");
      HintInfo(ApprovalResult.data);
      if (ApprovalResult.data.code == 200) {
        refreshItem(row.id_leave);
      }
    }

    if (type == "reject") {

      let status_id = row.status_id.toString == '6' ? '2' : '3';
      const ApprovalResult = await regVacationApproval({ id: row.id_leave, status_id: status_id, approver_remarks: commnetvalue }, "PUT");
      HintInfo(ApprovalResult.data);
      if (ApprovalResult.data.code == 200) {
        refreshItem(row.id_leave);
      }
    }


    if (type === "edit") {
      setVacation(row);
      EditVationopened();
    }
    if (type === "delete") {
      modals.openConfirmModal({
        title: word_translation.delete_title,
        centered: true,
        children: <Text size="sm">
          {word_translation.delete_tips}
        </Text>,
        labels: {
          confirm: word_translation.confirm_delete,
          cancel: word_translation.cancel_delete,
        },
        confirmProps: { color: "red" },
        onConfirm: async () => {
          const ApprovalResult = await regGetVationAction(
            { id: row.id_leave },
            "DELETE"
          );
          HintInfo(ApprovalResult.data);
          if (ApprovalResult.data.code == 200) {
            refreshItem(row.id_leave);
          }
        },
      });
    }
  };


  const EditVationCallback = () => {
    callBack();
    EditVationClose();
  };
  const callBackUserinfo = (value: Vacation) => {
    callBackUser(value);
  };
  const OpenVacation = (value: Vacation) => {
    setVacation(value)
    openvacationInfo()
  };


  const handleReadRequest = (row: Vacation) => {
    const openModal = () => modals.openConfirmModal({
      title: word_translation.is_sick_leave,
      labels: { confirm: word_translation.yes, cancel: word_translation.no },
      children: <>
        {word_translation.mark_or_not}
      </>,
      onCancel: () => console.log('Cancel'),
      onConfirm: async () => {
        const ApprovalResult = await regLeaveSickReaded({ id: row.id_leave }, "PUT");
        HintInfo(ApprovalResult.data);
        if (ApprovalResult.data.code == 200) {
          refreshItem(row.id_leave);
        }
      },
    });
    openModal()
  };


  // 监听滚动事件
  const handleScroll = () => {
    const scrollableDiv = document.getElementById('ScrollableDivLeave');
    if (scrollableDiv) {
      if (scrollableDiv.scrollTop + scrollableDiv.clientHeight >= scrollableDiv.scrollHeight) {
        // 当滚动到底部时执行加载更多内容的逻辑
        setLoading(true)
        callBack();
      }
    }
  };

  useEffect(() => {
    // 添加滚动事件监听器
    const scrollableDiv = document.getElementById('ScrollableDivLeave');
    if (scrollableDiv) {
      scrollableDiv.addEventListener('scroll', handleScroll);
      return () => {
        // 移除滚动事件监听器
        scrollableDiv.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  useEffect(() => {
    setLoading(false)
  }, [UserVationData]);


  return (
    <Box py={10}  pos="relative">
      <LoadingOverlay visible={loading} overlayBlur={2} />
      <Box mt={10} mx={20} id="ScrollableDivLeave" className="scrollable-content-xs" style={{ overflowX: 'auto', overflowY: 'auto', height: '20vh' }}  >
        <table width='100%' style={{minWidth: '730px'}} className="scrollable-content-xs table_custome_spacing" >
          <thead className="ScrollableDiv ">
            <tr className="border-b-1">
              {type == 2 ? (
                <LeaveManageRowTh class_name="columns-title" title={word_translation.applicant} />
              ) : null}
              <LeaveManageRowTh class_name="columns-title" style={{minWidth: '72px'}} title={word_translation.from} />
              <LeaveManageRowTh class_name="columns-title" style={{minWidth: '72px'}} title={word_translation.to} />
              <LeaveManageRowTh class_name="columns-title" title={word_translation.days} />
              <LeaveManageRowTh class_name="columns-title" title={word_translation.manager} />
              <LeaveManageRowTh class_name="columns-title" title={word_translation.category_name} />
              <th className="text-center cursor-pointer" onClick={statusSwich}>
                <Group position="center">
                  <Text className="columns-title " >
                    {word_translation.status}
                  </Text>
                  <IconFilter color="#666" size={16} />
                </Group>
              </th>
              <LeaveManageRowTh class_name="columns-title" title={word_translation.requested_date} />
              <LeaveManageRowTh class_name="columns-title" title={word_translation.option} />
            </tr>
          </thead>
          <tbody>
            {UserVationData && UserVationData.length > 0 &&
              UserVationData?.map((item, index) => {
                const color = item.status_id == "2" ? "green" : item.status_id == "1" ? "pending" : "red";
                return (
                  <tr  className="border-b-1">
                    {type == 2 ? (
                      <LeaveManageRowTd callBack={() => callBackUserinfo(item)} class_name="columns-text" title={item.user_info.username} />
                    ) : null}

                    <LeaveManageRowTd tip={`${word_translation.category_name} : ${item.leave_category_name as string}`} callBack={() => callBackUserinfo(item)} class_name="columns-text" title={formatDate(item.from)} />
                    <LeaveManageRowTd tip={`${word_translation.category_name} : ${item.leave_category_name as string}`} callBack={() => callBackUserinfo(item)} class_name="columns-text" title={formatDate(item.to)} />
                    <LeaveManageRowTd tip={`${word_translation.category_name} : ${item.leave_category_name as string}`} callBack={() => callBackUserinfo(item)} class_name="columns-text" title={item.flag_halfday.toString() == "1" ? "0.5" : item.days} />


                    <td className="border-b-1 ">
                      <Box onClick={() => callBackUserinfo(item)}>
                        <Text className="columns-text">
                          <Avatar.Group spacing="sm">
                            {item.manager.map(
                              (item_leader: any, item_leader_index: any) => (
                                <Tooltip label={item_leader.username} key={item_leader.username + item_leader.avatar} >
                                  <Avatar
                                    src={apiUrl.fileSystemDomain + item_leader.avatar}
                                    radius={40}
                                    size={25}
                                  />
                                </Tooltip>
                              )
                            )}
                          </Avatar.Group>
                        </Text>
                      </Box>

                    </td>
                    <td className="text-left border-b-1 ">
                      <Tooltip label={item.leave_category_name as string}>
                        <Badge color={item.leave_category_id.toString() == '2' ? "dark" : ''}>
                          {
                            // truncateString(item.leave_category_name as string,6)}
                            item.leave_category_name
                          }
                        </Badge>
                      </Tooltip>

                    </td>
                    <LeaveManageRowTd tip={item.leave_category_name as string}
                      callBack={() => callBackUserinfo(item)}
                      class_name={`columns-text  leave-badge-layout ${color}-badge badge-text`}
                      title={item.leave_category_id.toString() == '2' ? "ACKNOWLEDGED" :
                        // truncateString(item.status_name as string,12)
                        item.status_name
                      } />

                    <LeaveManageRowTd tip={item.leave_category_name as string} callBack={() => callBackUserinfo(item)} class_name={`columns-text `} title={formatDate(item.appliance_date)} />
                    <td className="border-b-1 ">
                      <Flex>


                        <ViewIcon callBack={() => { OpenVacation(item) }} />
                        {/* 当前人的 可以修改删除 */}
                        {type == 1 ? (
                          <>
                            <EditIcon callBack={() => ProcessingRequestHandle(item, "edit", "")} />
                            <DeleteIcon disabled={item.status_id != '1'} callBack={() => ProcessingRequestHandle(item, "delete", "")} />
                          </>
                        ) :
                          (
                            <>

                              {item.status_id == '1' &&
                                <>
                                  <ChecXIcon tip={word_translation.reject} callBack={() => openHandleVacationModal(item, "reject")} />
                                  <IconCheckDs tip={word_translation.approve_this_leave} callBack={() => openHandleVacationModal(item, "agree")} />
                                </>
                              }

                              {item.status_id == '2' ?
                                item.leave_category_id == 2 && item.is_read === 0 ?
                                  //  处理病假未读信息
                                  <>
                                    <ChecXIcon tip={word_translation.vacation_sick_no_cancel} color="#B8B8B8" callBack={() => { }} />
                                    <IconCheckDs tip={word_translation.is_sick_leave} color={'red'} callBack={() => handleReadRequest(item)} />
                                  </> :
                                  <>
                                    <ChecXIcon tip={word_translation.vacation_approved} color="#B8B8B8" callBack={() => { }} />
                                    <IconCheckDs tip={word_translation.vacation_approved} color="green" callBack={() => { }} />
                                  </>
                                : null
                              }

                              {item.status_id == '3' &&
                                <>
                                  <ChecXIcon tip={word_translation.vacation_rejected} color="#B8B8B8" callBack={() => ProcessingRequestHandle(item, "reject", "")} />
                                  <IconCheckDs tip={word_translation.vacation_rejected} color="#B8B8B8" callBack={() => openHandleVacationModal(item, "agree")} />
                                </>
                              }

                              {item.status_id == '4' &&
                                <>
                                  <ChecXIcon tip={word_translation.vacation_canceled} color="#B8B8B8" callBack={() => ProcessingRequestHandle(item, "reject", "")} />
                                  <IconCheckDs tip={word_translation.vacation_canceled} color="#B8B8B8" callBack={() => openHandleVacationModal(item, "agree")} />
                                </>
                              }


                              {item.status_id == '6' &&
                                <>
                                  <ChecXIcon tip={word_translation.reject} color="red" callBack={() => ProcessingRequestHandle(item, "reject", "")} />
                                  <IconCheckDs color="blue" callBack={() => openHandleVacationModal(item, "agree")} />
                                </>
                              }
                            </>
                          )}
                      </Flex>
                    </td>
                  </tr>

                );
              })}
          </tbody>
        </table>
      </Box>

      <Modal
        radius="md"
        size="xl"
        opened={openvacationInfoStatus}
        onClose={closevacationInfo}
        closeOnClickOutside={false}
        title={word_translation.preview}
      >
        <LeaveInfo row={vacation} />
      </Modal>
      <Modal
        opened={openedEditVation}
        onClose={EditVationClose}
        size='md'
        title="Modify the request"
      >
        <EditVation closeBack={EditVationClose} row={vacation} callback={EditVationCallback} />
        {/* Modal content */}
      </Modal>
    </Box>
  );
}

function findscroller(element: any) {
  element.onscroll = function() {
    console.log(element)
  }
  Array.from(element.children).forEach(findscroller)
}