import { Box,  Grid ,MultiSelect,Select} from "@mantine/core";
import {useEffect,  useState } from "react";
import {  SelectRole } from "../../../interface/Icommon";
import WordTranslationComponent from "../../../utils/language_pack/words";
import TableTextInputTitle from "../../../ttcomponents/TableTextInputTitle/TableTextInputTitle";
import TableTopTitle from "../../../ttcomponents/TableTopTitle/TableTopTitle";
import { getCostCenterGeneralSelectFunction, getUserRoleGeneralSelectFunction } from "../../../api/common_request";
import { getCustomTaskListFunction, getProjectListGeneralFunction } from "../../../api/assignments_request";

interface ComponentInterface {
  setFormValue: (data: any) => any;
  formValue: any
}

/**
 * OnBoarding 
 * @returns 
 */
export default function Affiliation({formValue,setFormValue}:ComponentInterface) {

  const [rand_number, setRandNumber] = useState<number>(1);
    // Get translation public configuration information
   const word_translation = WordTranslationComponent();

  // Define all the list of user role you can choose
  const [userRoleList, setUserRoleList] = useState<SelectRole[]>([]);

  // Declare the variable costCenterData that stores the user branch center
  const [costCenterData, setCostCenterData] = useState<SelectRole[]>([]);

 
    /**
     * Get the current list data
     */
    const ajaxCurrentList = async () => {
        // Get a list of cost centers
        getCostCenter();
        getProjectList();
        getTaskList();
    };

    /**
     * Get cost center data
     */
    const getCostCenter = async () => {
        // Call the Get Cost Center General Selection function and set the cost center data
        let r = await getCostCenterGeneralSelectFunction({have_all_costcenter:false});
        console.log(r,'==')
        setCostCenterData(r);
    };


    const [projectList, setProjectList] = useState<SelectRole[]>([]);

    // get all product list 
    const getProjectList = async () => {
      setProjectList(await getProjectListGeneralFunction(""));
    };

    const [taskList, setTaskList] = useState<SelectRole[]>([])
   // get all task list 
    const getTaskList = async () => {
      let params  = {
        list:true,
        project_is_null:true
      }
      setTaskList(await getCustomTaskListFunction(params))
    };


    /**
     * When the component is mounted, call the ajaxCurrentList method to get the data
     */
    useEffect(() => {
        // Call the ajaxCurrentList method to get the data
        ajaxCurrentList(); 
    }, []);

    /**
     * handle Data
     */
    const handleData = async (name:any,value: any) => {
      // 更新状态
      setFormValue((prevData: any) => {
          return {
              ...prevData,
              [name]: value // 使用 name 作为属性名动态更新状态
          };
      });
  };

    return (
            <Box className="border-radius-xs min-h-25 border-custom  px-xs">
            {/* Affiliation */}
                <TableTopTitle  title={word_translation.affiliation} />
                <Box className="px-15 pt-xs pt-4">
                        <Grid>
                            <Grid.Col span={12} >
                                    <TableTextInputTitle title={word_translation.select_cost_center} />
                                    <Select
                                        labelProps={{ className: "input_title" }}
                                        onChange={(value) =>handleData("costCenter_id", value) }
                                        placeholder={word_translation.select_cost_center}
                                        searchable
                                        defaultValue={formValue.costCenter_id.toString()}
                                        key={formValue.costCenter_id}
                                        data={costCenterData}
                                      />
                                      {/* <Box>
                                      <TableTextInputTitle title={word_translation.select_project} />
                                      <MultiSelect
                                        className="badge_text"
                                        maxDropdownHeight={150}
                                        labelProps={{ className: "input_title" }}
                                        onChange={(value) =>handleData("project_ids", value) }
                                        placeholder={word_translation.pick_one}
                                        nothingFound={word_translation.no_data}
                                        defaultValue={formValue.project_ids}
                                        key={formValue.project_ids}
                                        data={projectList}
                                      />
                                      </Box>
                                      <TableTextInputTitle title={word_translation.select_task} />
                                      <MultiSelect
                                        className="badge_text"
                                        maxDropdownHeight={150}
                                        labelProps={{ className: "input_title" }}
                                        placeholder={word_translation.pick_one}
                                        onChange={(value) =>handleData("task_ids", value) }
                                        nothingFound={word_translation.no_data}
                                  
                                        data={taskList}
                                      /> */}
                                      
                                </Grid.Col>
                        </Grid>
                </Box>
            </Box>
    )
}