import { ActionIcon, Box, Code, Grid, Group, MultiSelect,Space, Switch, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import WordTranslationComponent from '../../../utils/language_pack/words';
import { useEffect, useState } from 'react';
import TableTextInputTitle from '../../../ttcomponents/TableTextInputTitle/TableTextInputTitle';
import ResetBtn from '../../../ttcomponents/Button/ResetBtn';
import localstorageUnits from '../../../utils/localstorageUnits';
import { DateInput } from '@mantine/dates';
import { IconCalendar } from '@tabler/icons-react';
import { getCustomerListGeneralFunction, getItemStatus } from '../../../api/assignments_request';
import { SelectRole } from '../../../interface/Icommon';
import GlobalCoreSetting from '../../../utils/globalCortSetting';
import UniversalButton from '../../../ttcomponents/Button/UniversalButton';
import TableTextInputTitleDesc from '../../../ttcomponents/TableTextInputTitleDesc/TableTextInputTitleDesc';
import { formatDateUpgraded } from '../../../utils/function';
import { getSystemAssignmentsFunction } from '../../../api/core_request';
import { IPagination } from '../../../interface/Ipagintion';

interface ComponentProps {
    openFormStatus: boolean;
    is_load: boolean;
    closeFunction: () => void;
    objectsUsed: string;
    pagination:IPagination | undefined;
    successCallback: (value: any) => void
}

function AssignmentListFilter({ openFormStatus, pagination,closeFunction, is_load,successCallback, objectsUsed }: ComponentProps) {

  // Get the default unchanged configuration
  const globalCoreSetting = GlobalCoreSetting();
  // Define all the customer lists that can be selected
  const [customerList, setCustomerList] = useState<SelectRole[]>([]);
  const [newAssignmentModals, setNewAssignmentModals] = useState<any[]>([]);
  const [customerSelectData, SetCustomerSelectData] = useState([]);
  const [status, SetStatus] = useState<string[]>([]);


  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [assignmentModa, setAssignmentsModa] = useState<string[]>([]);
  const [randNumber, setRandNumber] = useState(1);
  // Get the initial time of the system
  const initSystemTime = localstorageUnits.getSystem().init_use_date;
  const [openForm, { open: openModalFunction, close: closeModalFunction }] = useDisclosure(false);
  // Get translation public configuration information
  const word_translation = WordTranslationComponent();
  const [projectStatusList, setProjectStatusList] = useState<SelectRole[]>([]);

  useEffect(() => {
      openFormStatus ? openModalFunction() : closeModalFunction();
  }, [openFormStatus])

  useEffect(() => {
        getCurrentAssigment()
        getStatus();
        getCustomerList();
  }, [])

useEffect(() => {
    // 默认选中状态 是 running (value = 2)
    let statusList = projectStatusList.find(item => item.value == '2');
    if(statusList?.value){
      SetStatus([statusList.value as string]);
    }
}, [projectStatusList])
  // 获取状态
  const getStatus = async () => {
    setProjectStatusList(await getItemStatus({active:1},'select'))
  };

  useEffect(() => {
      
    if(pagination?.start_date){
       setStartDate(pagination?.start_date)
    }

    if(pagination?.end_date){
      setEndDate(pagination?.end_date)
    }

    if(pagination?.end_date){
      setEndDate(pagination?.end_date)
    }

    if(pagination?.customer_ids){
      SetCustomerSelectData(pagination?.customer_ids.split(','))
    }

    if(pagination?.status_ids){
      SetStatus(pagination?.status_ids.split(','))
    }
    if(pagination?.mode_types){
      setAssignmentsModa(pagination?.mode_types.split(','))
    }

    setRandNumber(randNumber+1)
  }, [pagination])
    
  const getCurrentAssigment= async ()=>{
        let assignmentModals = await getSystemAssignmentsFunction();
        console.log(assignmentModals);
        
        // 假设 globalCoreSetting 是包含 globalTaskModeList 的对象
        let matchingEntries = globalCoreSetting.globalTaskModeList.filter(item => assignmentModals.includes(item.value));
        
        setNewAssignmentModals(matchingEntries);
     
    }
    // Close component
  const closeBtnModalFunction = () => {
      closeModalFunction();
      closeFunction();
  }

    
    // const [endDate, setEndDate] = useState('');
    // Click save
  const applyBtnFunction = () => {
      successCallback({
          start_date:startDate,
          end_date:endDate,
          customer_ids:customerSelectData.join(','),
          status_ids:status.join(','),
          mode_types:assignmentModa.join(','),
      })
  }


  const resetFilterFunction = () => {
      SetCustomerSelectData([])
      SetStatus([])
      setAssignmentsModa([])
      setStartDate('')
      setEndDate('')
      setRandNumber(randNumber+1)
  }

  const getCustomerList = async () => {
      is_load && setCustomerList(await getCustomerListGeneralFunction(""));
    };
    
    return (
        
            <Box className='filter_site' w={350}>
   
            <Group position="apart">
             
                  <TableTextInputTitle class_name='filter_title_text' title={word_translation.select_data} />
                              <ActionIcon onClick={() =>{
                                setStartDate('')
                                setEndDate('')
                                setRandNumber(randNumber+1)
                              }}>
                                <Tooltip label={word_translation.delete_tips}>
                                  <TableTextInputTitleDesc color="blue" title={word_translation.clear} />
                                </Tooltip>
                            </ActionIcon> 
                        </Group>
                     <Grid px={10} align='center' >
                     <Grid.Col span={6}>
                        <TableTextInputTitle title={word_translation.start_time} />
                        <DateInput
                          valueFormat="DD/MM/YYYY"
                          minDate={new Date(initSystemTime)}
                          required
                          key={randNumber}
                          defaultValue={startDate== ''? null: new Date(startDate)}
                          icon={<IconCalendar size="1.1rem" stroke={1.5} />}
                          onChange={(value)=>{setStartDate(formatDateUpgraded(value as Date,'YYYY-MM-DD'))}}
                          placeholder={word_translation.start_time}
                        />
                       </Grid.Col>
                
                     <Grid.Col span={6}>
                        <TableTextInputTitle title={word_translation.end_time} />
                        <DateInput
                          valueFormat="DD/MM/YYYY"
                          key={randNumber}
                          minDate={new Date(initSystemTime)}
                          defaultValue={endDate ==''? null: new Date(endDate)}
                          required
                          icon={<IconCalendar size="1.1rem" stroke={1.5} />}
                          onChange={(value)=>{setEndDate(formatDateUpgraded(value as Date,'YYYY-MM-DD'))}}
                          placeholder={word_translation.start_time}
                        />
                       </Grid.Col>
         
   
                       <Grid.Col span={12}>

                        <Group position="apart">
                              <TableTextInputTitle title={word_translation.customer} />
                              <ActionIcon onClick={() =>{
                                SetCustomerSelectData([])
                                setRandNumber(randNumber+1)
                              }}>
                                <Tooltip label={word_translation.delete_tips}>
                                  <TableTextInputTitleDesc color="blue" title={word_translation.clear} />
                                </Tooltip>
                            </ActionIcon> 
                        </Group>
                        <MultiSelect
                            placeholder={word_translation.customer}
                            searchable
                            key={randNumber}
                            onChange={(value)=>SetCustomerSelectData(value as [])}
                            value={customerSelectData} 
                            data={customerList}
                        />
                       </Grid.Col>
                  
                       <Grid.Col span={12}>
                        <Group position="apart">
                              <TableTextInputTitle title={word_translation.status} />
                              <ActionIcon onClick={() =>{
                                SetStatus([])
                                setRandNumber(randNumber+1)
                              }}>
                                <Tooltip label={word_translation.delete_tips}>
                                  <TableTextInputTitleDesc color="blue" title={word_translation.clear} />
                                </Tooltip>
                            </ActionIcon> 
                        </Group>
                        <MultiSelect
                            data={projectStatusList}
                            placeholder={word_translation.pick_one}
                            key={randNumber}
                            value={status} 
                            onChange={(value)=>SetStatus(value as [])}
                            />
                       </Grid.Col>

                      {
                        newAssignmentModals.length > 1 && <Grid.Col span={12}>
                       <TableTextInputTitle py={10} title={word_translation.views} />
                        {/* assignments_modes */}
                        <Switch.Group
                            defaultValue={['react']}
                            mt={10}
                            key={randNumber}
                            withAsterisk
                            value={assignmentModa}  onChange={(value)=>setAssignmentsModa(value)}
                        >
                            <Grid>
                            {newAssignmentModals.map((item,index) => (
                                <Grid.Col span={12} key={index}>
                                <Switch key={randNumber} value={item.value} label={item.label} />
                                </Grid.Col>
                            ))}
                            </Grid>
                        </Switch.Group>
                       </Grid.Col>
                       }
                 
                 <Grid.Col mt={10} span={12}>
                    <Group position="apart">
                        <ResetBtn callBack={resetFilterFunction} />
                        <UniversalButton btn_text={word_translation.apply} btn_size='xs' callBack={applyBtnFunction} />
                    </Group>
                </Grid.Col>
            </Grid>
            </Box>
        // </Modal>
    );
}
export default AssignmentListFilter;
