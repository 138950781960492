import { Group, Text, ActionIcon, Code, Box, Modal, LoadingOverlay, Card, Paper, Tooltip } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconReportAnalytics, IconUserPlus } from '@tabler/icons-react'
import { useEffect, useRef, useState } from 'react'
import { regCustomer, regGeCustome } from '../../../api'
import { modals } from '@mantine/modals'
import { IPagination } from '../../../interface/Ipagintion'
import { APIResponse, CustomerData, CustomerRole } from '../../../interface/Icoustomer'
import CustomerFrom from './customer_form'
import { FormattedMessage } from 'react-intl'
import PageTitle from '../../../ttcomponents/PageTitle/PageTitle'
import WordTranslationComponent from '../../../utils/language_pack/words'
import ModalTitleText from '../../../ttcomponents/ModalTitleText/ModalTitleText'
import ContactForm from '../contact/contact_from'
import Contact from '../contact/contact_role'
import EditIconPerm from '../../../ttcomponents/EditIcon/EditIconPerm'
import DeleteIconPerm from '../../../ttcomponents/DeleteIcon/DeleteIconPerm'
import GlobalCoreSetting from '../../../utils/globalCortSetting'
import { HintInfo } from '../../../utils/function'
import CheckPermissionTools from '../../../utils/permission'
interface ICustomerProps {
  [key: string]: any
}

export default function CustomerStack({ searchValue }: ICustomerProps) {
  // Get translation public configuration information
  const word_translation = WordTranslationComponent()

  const Perm = CheckPermissionTools()

  const globalCoreSetting = GlobalCoreSetting()
  const [closeCustomerStatus, { open: openCustomerForm, close: closeCustomerForm }] = useDisclosure(false)
  const [openedModal2, { open: openModal2, close: closeModal2 }] = useDisclosure(false)
  const [openedModal3, { open: openModal3, close: closeModal3 }] = useDisclosure(false)

  //loading status
  const [loading, setLoading] = useState(false)

  const [customerSingleData, setCustomerSingleData] = useState<CustomerData | undefined>(undefined)
  const [customerData, setCustomerData] = useState<CustomerData[]>([])
  const [customerRollesData, setCustomerRollesData] = useState<CustomerRole[]>([])
  const [pagination, setPagination] = useState<IPagination>({
    search: '',
    page: 1,
    pagesize: 20,
    sortBy: '',
    sortOrder: 'desc'
  })

  /**
   * Get user information
   */
  const ajaxCurrentList = async () => {
    setLoading(true)
    const response = await regCustomer(pagination, 'GET')
    setLoading(false)
    const result = response.data as APIResponse
    if (result.code === 200 && !Array.isArray(result.data)) {
      setCustomerData(prevList => [...prevList, ...result.data.data])
      // setCustomerData(result.data.data);
    } else {
      console.log(result.msg)
    }
  }

  // when pagination change, run ajaxCurrentList()
  useEffect(() => {
    ajaxCurrentList()
  }, [pagination])

  const openCustomer = (value: CustomerRole[]) => {
    setCustomerRollesData(value)
    openModal2()
  }

  const editCustomer = (editData: CustomerData) => {
    setCustomerSingleData(editData)
    openCustomerForm()
  }

  // ===================Quickly add customer contacts  ===================
  const addContactName = (value: object) => {
    const editData = value as CustomerData
    setCustomerSingleData(editData)
    openModal3()
  }

  // =============== Submit customer information ===============
  //  type: 'edit'
  const customerFormCallbackFunction = async (
    customer_id: string,
    type: 'editCustomer' | 'addContactName' | 'openCustomer'
  ) => {
    if (!customer_id) return
    switch (type) {
      case 'editCustomer':
        closeCustomerForm()
        break
      case 'addContactName':
        closeModal3()
        break
      case 'openCustomer':
        // closeModal2()
        break
    }

    const response = await regGeCustome(customer_id)
    const item = response.data
    if (item.code == 200) {
      setCustomerData(prev => {
        let temp = [...prev]
        const index = prev.findIndex(item => item.id_customer == customer_id)
        temp[index] = item.data
        return temp
      })
    }
  }

  // ===============  Delete customers ===============
  const delHandle = (row: object) => {
    const delValue = row as { id_customer: string; company_name: string }
    modals.openConfirmModal({
      title: <FormattedMessage id="delete_confirm" defaultMessage="Delete Confirmation" />,
      centered: true,
      children: (
        <Text mt={10} size="sm">
          <FormattedMessage id="Company Name" defaultMessage="Company Name" />:<Code> {delValue.company_name}</Code>
        </Text>
      ),
      labels: globalCoreSetting.DeleteOpenConfirmModaLabels,
      confirmProps: globalCoreSetting.DeleteOpenConfirmModaConfirmProps,
      onConfirm: async () => {
        const response = await regCustomer({ id: delValue.id_customer }, 'DELETE')
        const result = response.data
        HintInfo(result)
        if (result.code === 200) {
          setCustomerData(prev => prev.filter(item => item.id_customer !== delValue.id_customer))
        }
      }
    })
  }

  // Receive the callback return data, search for content
  const ReceiveSearchBack = (value: string | number) => {
    setCustomerData([])
    setPagination(prevpagination => ({
      ...prevpagination,
      search: value as string,
      page: 1
    }))
  }

  // Execute the useeffect when the first load is loaded.
  const isInitialMount = useRef(true)
  useEffect(() => {
    if (!isInitialMount.current) {
      ReceiveSearchBack(searchValue)
    }
    isInitialMount.current = false
  }, [searchValue])

  const handleScroll = () => {
    const element = document.getElementById('ScrollableDivCostomer')
    const scrollTop = element?.scrollTop ?? 0
    const scrollHeight = element?.scrollHeight ?? 0
    const clientHeight = element?.clientHeight ?? 0
    // Check if scrolled to the bottom
    if (scrollTop + clientHeight === scrollHeight) {
      // loadMoreData();
      setPagination(prevPagination => ({
        ...prevPagination,
        page: prevPagination.page + 1
      }))
    }
  }

  useEffect(() => {
    const ScrollableDivCostomer = document.getElementById('ScrollableDivCostomer')

    // Check if the scrollable div is present in the DOM
    if (ScrollableDivCostomer) {
      ScrollableDivCostomer.addEventListener('scroll', handleScroll)
    }

    // Clean up the event listener to prevent memory leaks
    return () => {
      if (ScrollableDivCostomer) {
        ScrollableDivCostomer.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  return (
    <Paper px={0} pb={10} w={'100%'} className="filter_relative">
      <LoadingOverlay visible={loading} overlayBlur={2} />
      <PageTitle px={20} title={word_translation.customer_management} />
      <Box
        mt={10}
        mx={20}
        id="ScrollableDivCostomer"
        className="scrollable-content-xs"
        style={{ overflowY: 'auto', height: '82vh' }}
      >
        <table
          width="100%"
          className=" customer_tale  table_custome_2 text-center table_custome_spacing position_guanzhi"
        >
          {/* <table className="table_custome text-center"> */}
          <thead className="table_th">
            <tr>
              <th>{word_translation.company_name}</th>
              <th>{word_translation.email}</th>
              <th>{word_translation.phone}</th>
              <th>{word_translation.street}</th>
              <th>{word_translation.city}</th>
              <th>{word_translation.company_postal_code}</th>
              <th>{word_translation.project_num}</th>
              <th>{word_translation.website}</th>
              <th>{word_translation.option}</th>
            </tr>
          </thead>
          <tbody>
            {customerData.length > 0 ? (
              customerData.map(item => (
                <tr key={item.id_customer} className="border-top-sheet border-bt-2 my-10">
                  <td>
                    <Group spacing="xs" position="center">
                      {/* <Avatar size={40} src={item.} radius={40} /> */}
                      <div>
                        <Text fz="xs" fw={500}>
                          {item.company_name}
                        </Text>
                        <Text c="dimmed" fz="xs">
                          ID:{item.id_customer}
                        </Text>
                      </div>
                    </Group>
                  </td>
                  <td>
                    <Text fz="xs">{item.email}</Text>
                  </td>
                  <td>
                    <Text fz="xs">{item.tel} </Text>
                  </td>
                  <td>
                    <Text fz="xs">{item.street} </Text>
                  </td>
                  <td>
                    <Text fz="xs">{item.city} </Text>
                  </td>
                  <td>
                    <Text fz="xs">{item.postalcode} </Text>
                  </td>
                  <td>
                    <Text fz="xs">{item.project_count} </Text>
                  </td>
                  <td>
                    <Text fz="xs">{item.website} </Text>
                  </td>
                  <td>
                    <Group spacing={0} position="center">
                      <EditIconPerm
                        enable={Perm.customer_update}
                        callBack={Perm.customer_update == true ? () => editCustomer(item) : () => {}}
                      />

                      <ActionIcon disabled={Perm.contact_role_create == false} onClick={() => addContactName(item)}>
                        <Tooltip label={word_translation.create_customer_contact}>
                          <IconUserPlus size="1rem" stroke={1.5} />
                        </Tooltip>
                      </ActionIcon>
                      <ActionIcon
                        disabled={
                          Perm.contact_role_read == false ||
                          !item.hasOwnProperty('customer_roles') ||
                          item.customer_roles.length === 0
                        }
                        onClick={() => openCustomer(item.customer_roles)}
                      >
                        <Tooltip label={word_translation.cat_customer_contact}>
                          <IconReportAnalytics size="1rem" stroke={1.5} />
                        </Tooltip>
                      </ActionIcon>
                      <DeleteIconPerm enable={Perm.customer_delete} callBack={() => delHandle(item)} />
                    </Group>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={9}></td>
              </tr>
            )}
          </tbody>
        </table>
      </Box>

      <Modal
        radius="md"
        opened={closeCustomerStatus}
        className="modal-setting"
        closeOnClickOutside={false}
        onClose={closeCustomerForm}
        title={<ModalTitleText title={word_translation.edit_customer} />}
        overlayProps={{ opacity: 0.5, blur: 4 }}
      >
        <Card radius={15} color="customize">
          <CustomerFrom current_item={customerSingleData} callBack={customerFormCallbackFunction} />
        </Card>
      </Modal>

      <Modal
        size="70%"
        opened={openedModal3}
        onClose={closeModal3}
        closeOnClickOutside={false}
        title={word_translation.customer_contact}
        overlayProps={{ opacity: 0.5, blur: 4 }}
      >
        <Card p={20} radius={15} color="customize">
          <ContactForm customerData={customerSingleData} callBack={customerFormCallbackFunction} />
        </Card>
      </Modal>

      <Modal
        size="60%"
        opened={openedModal2}
        padding="md"
        onClose={closeModal2}
        title={<FormattedMessage id="customer_roles" defaultMessage="Customer Roles" />}
        transitionProps={{ transition: 'fade', duration: 200 }}
      >
        {customerRollesData && customerRollesData.length > 0 ? (
          <Contact datacontcatList={customerRollesData} refreshCustomerList={customerFormCallbackFunction} />
        ) : (
          <div style={{ textAlign: 'center' }}>{<FormattedMessage id="No Data" defaultMessage="No Data" />}</div>
        )}
      </Modal>
      {/* <Box ref={}></Box> */}
    </Paper>
  )
}
