import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

// Define the refresh status interface for the assignment list
export interface RefreshStatusInterface {
  refreshStatus: boolean;
}

// Initial state, with the default refresh status set to false
const initialState: RefreshStatusInterface = {
  refreshStatus: false,
};

// Create a Redux Slice for managing the refresh status of the assignment list
export const assignmentsRefreshStatusSlice = createSlice({
  name: 'refreshStatus', // Name of the Slice
  initialState, // Initial state
  reducers: {
    // Define a reducer to set the refresh status
    setRefreshStatus: (state, action: PayloadAction<boolean>) => {
      state.refreshStatus = action.payload as RefreshStatusInterface['refreshStatus'];
    },
  },
});

// Export the reducer and action for easy use in other files
export const { setRefreshStatus } = assignmentsRefreshStatusSlice.actions;

export default assignmentsRefreshStatusSlice.reducer;
