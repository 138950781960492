import { FC, useEffect, useReducer, useRef, useState } from 'react'
import { ComponentInterface } from '../../../../interface/Icommon'
import { Group, Paper, Box, LoadingOverlay } from '@mantine/core'
import { ILog } from '../../../../interface/Ilog'
import { regLogs } from '../../../../api'
import { HintInfoCatchError } from '../../../../utils/function'
import CheckPermissionTools from '../../../../utils/permission'
import { NotPermission } from '../../../../ttcomponents/NoPermission'
import WordTranslationComponent from '../../../../utils/language_pack/words'
import LogListRow from './log_list_row'
import LogColgroup from './log_colgroup'

import s from './index.module.css'
import { useDisclosure } from '@mantine/hooks'
import LogDetail from './log_detail'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../state_management/store'
import PaginationA from '../../../../ttcomponents/Pagination/PaginationA'
import PageTitle from '../../../../ttcomponents/PageTitle/PageTitle'

const colWidth: number[] = [6, 8, 15, 4, 14, 6, 13, 13, 7, 15, 12, 14]

type State = {
  search: string
  page: number
  pagesize: number
  sortBy: string
  sortOrder: string
}

type Action = { type: 'setPageIndex'; payload: number } | { type: 'setSearch'; payload: string }

function reducer(state: State, action: Action) {
  switch (action.type) {
    case 'setSearch':
      return { ...state, page: 1, search: action.payload }
    case 'setPageIndex':
      return { ...state, page: action.payload }
    default:
      throw new Error()
  }
}

const LogSettings: FC<ComponentInterface> = ({ is_load_str }) => {
  const Perm = CheckPermissionTools()
  // get translation public configuration infomation
  const word_translation = WordTranslationComponent()

  const isInitialMount = useRef(true)
  const searchValue = useSelector((state: RootState) => state.search.searchValue)
  const [visible, { close: closeLoading, open: openLoading }] = useDisclosure(false)
  const [queryState, dispatch] = useReducer(reducer, {
    search: '',
    page: 1,
    pagesize: 10,
    sortBy: '',
    sortOrder: ''
  } as State)
  const [total, setTotal] = useState<number>(0)
  const [logList, setLogList] = useState<ILog[]>([])

  useEffect(() => {
    const fetData = async () => {
      try {
        openLoading()
        const response = await regLogs(queryState)
        closeLoading()
        const result = response.data
        // if the response is unsuccessful
        if (result.code !== 200 || !Array.isArray(result.data.data)) {
          HintInfoCatchError()
        }
        // update data
        setLogList(result.data.data)
        // update total
        setTotal(result.data.total)
      } catch (error) {
        HintInfoCatchError()
      }
    }
    fetData()
  }, [queryState])

  useEffect(() => {
    if (!isInitialMount.current) {
      dispatch({ type: 'setSearch', payload: searchValue })
    }
    isInitialMount.current = false
  }, [searchValue])

  const ReceivePaginationABack = (page: number) => {
    dispatch({ type: 'setPageIndex', payload: page })
  }

  // handle log detail
  const [opened, { open, close }] = useDisclosure(false)
  const [curClickLog, setCurClickLog] = useState<ILog>()
  const onRowClick = (row: ILog) => {
    setCurClickLog(row)
    open()
  }
  return ( <Paper
    px={20}
    w={"100%"}
    className="scrollable-container-table"
    style={{ position: "relative" }}
  >
    <PageTitle        title="Log"        lang_id="customer_cartegory_title"      />
      <Group position="left">
        {Perm.module_service_read ? (
          logList.length > 0 && (
            <Box miw={1180}>
              <table className={`${s.table} table_custome text-center`}>
                <LogColgroup colWidth={colWidth} />
                <thead className="table_th">
                  <tr>
                    <th>{word_translation.id_log}</th>
                    <th>{word_translation.module_name}</th>
                    <th>{word_translation.table_name}</th>
                    <th>{word_translation.row_id}</th>
                    <th>{word_translation.column_name}</th>
                    <th>{word_translation.type}</th>
                    <th>{word_translation.old_value}</th>
                    <th>{word_translation.new_value}</th>
                    <th>{word_translation.changed_by}</th>
                    <th>{word_translation.changed_at}</th>
                    <th>{word_translation.desc}</th>
                    <th>{word_translation.sequence_number}</th>
                  </tr>
                </thead>
              </table>
              <Box className={`${s.scrollableBody} scrollable-content-xs`}>
                <LoadingOverlay visible={visible} overlayBlur={2} />
                <table className={`${s.table} table_custome text-center`}>
                  <LogColgroup colWidth={colWidth} />
                  <tbody>
                    {logList.map((log, index) => (
                      <tr key={index} onClick={() => onRowClick(log)}>
                        <LogListRow row={log} />
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Box>
              {curClickLog && <LogDetail row={curClickLog} isShow={opened} onClose={close} />}
            </Box>
          )
        ) : (
          <NotPermission />
        )}
        <PaginationA
          callBack={ReceivePaginationABack}
          total_count={total}
          pagesize={queryState.pagesize}
          page={queryState.page}
        />
      </Group>
    </Paper>
  )
}

export default LogSettings
