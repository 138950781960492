import { Container, Title, Text, Button, Group } from '@mantine/core';
// import { Illustration } from './Illustration';
import classes from './NothingFoundBackground.module.css';

export function NotPermission(){

    return (
      <Container pt={100} className='text-center' >
      <div className={classes.inner}>
        {/* <Illustration className={classes.image} /> */}
        <div className={classes.content}>
          <Title className={classes.title}>Not enough permissions</Title>
          <Text c="dimmed" size="lg" ta="center" className={classes.description}>
             You don't have read permission. Please contact the administrator
          </Text>
        </div>
      </div>
    </Container>
    )
}