import {
  Box,
  Button,
  Card,
  LoadingOverlay,
  Grid,
  Textarea,
  Group,
  Title,
  Select,
  Text,
  TextInput,
  TransferListData,
  Table,
  Avatar,
  ActionIcon,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { hasLength, isNotEmpty, useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import {
  IconCalendar,
  IconCheck,
  IconDots,
  IconMoneybag,
  IconPlus,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import { FormEvent, useEffect, useRef, useState } from "react";
import { regTaskOnly, regOrderTask } from "../../../api";
import SelectUserToProject from "../select_user_to_project";
import { SelectRole, SelectUser } from "../../../interface/Icommon";
import { selectEmployeeProps } from "../../../interface/Iemloyee";
import { IOrderNewCreateList, IOrderProps } from "../../../interface/Iorder";
import { ITask } from "../../../interface/Itask";
import { FormattedMessage, useIntl } from "react-intl";
import { JudgmentTimeThreshold, TimeHHSS } from "../../../utils/function";
import SaveBottom from "../../../ttcomponents/Button/SaveBtn";

interface IOrderFromProps {
  callBack: () => void;
  orderItem: IOrderProps | undefined;
  taskcateGoryListData: SelectRole[];
  [key: string]: any;
}

// Custom validation function for time_consumtion field
const validateTimeConsumtion = (value: string) => {
  const pattern = /^(\d+):(\d+):([0-5][0-9])$/;
  if (!pattern.test(value)) {
    return "Time consumption must be in the format HH:MM";
  }

  const [hours, minutes] = value.split(":");
  if (Number(hours) > 10000 || Number(minutes) >= 60) {
    return "Invalid time consumption";
  }
  return undefined;
};

export default function TaskOnlyEditFrom({
  callBack,
  formDataProps,
  customerListProps,
  statusListProps,
  taskcateGoryListData,
  orderItem,
  employeesList,
  taskItem,
  UserLists,
  userlist,
}: IOrderFromProps) {
  //加载中状态
  const [loading, setLoading] = useState(false);
  const [teamLeaderValue, setTeamLeaderValue] = useState<string[]>([]);
  const [employeestool, setEmployees] = useState<selectEmployeeProps[]>(
    taskItem.task_employee
  );
  const [taskItemSingel, setTaskItemSingel] = useState<ITask>(taskItem);
  const [resetUser, setresetUser] = useState(false);
  const [projectEmployeelist, setProjectEmployeelist] =
    useState<TransferListData>([[], []]);
  const [searchEmployeesValue, onEmployeesSearchChange] = useState("");
  // 设置task的free descripotion 是否可以填写
  const [descEnable, setDesEnable] = useState<boolean>(false);

  // 定义选择顾客可搜查函数并且赋值给searchCustomerValue
  const [searchCustomerValue, onSearchCustomerChange] = useState("");

  // 定义选择顾客可搜查函数并且赋值给searchCustomerValue
  const [searchStatusValue, onSearchStatusValue] = useState("");

  // 钩子函数-切换语言
  const intl = useIntl();

  const formRef = useRef<HTMLFormElement>(null);
  // 初始化表单数据
  const form = useForm({
    initialValues: {
      // project_id: orderItem?.project_id,
      id_task: formDataProps.id_task,
      // order_id: orderItem?.id_order,
      // time_estimate: formDataProps.time_estimate,
      // flag: formDataProps.flag,
      cost: formDataProps.cost,
      // budget: formDataProps.budget,
      free_description: formDataProps.free_description,
      start_date: new Date(formDataProps.start_date),
      end_date: formDataProps.end_date ? new Date(formDataProps.end_date) : "",
      // time: formDataProps.time_consumption,
      // task_leader_id: formDataProps.task_leader_id,
      // employees_id: formDataProps.employees_id,
      task_category_id: formDataProps.task_category_id,
      customer_id: formDataProps.customer_id,
      status_id: formDataProps.status_id,
    },

    validate: {
      // time: (value) => validateTimeConsumtion(value),
    },
  });
  const setTeamLeaderValueHandle = (value: React.SetStateAction<string[]>) => {
    setTeamLeaderValue(value);
    form.setFieldValue("order_team_leaders", value as []);
  };

  // 处理修改请求
  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    setLoading(false);
    event.preventDefault(); // Stop the formal submission of the form default
    // Verification form
    const valid = await form.validate();
    // The start time of the setting cannot exceed the initial time of the system settings
    if (JudgmentTimeThreshold(form.values.start_date) === false) {
      return false;
    }
    if (!valid.hasErrors) {
      let originalData = {
        id: form.values.id_task,
        // flag: form.values.flag,
        // time_estimate: form.values.time_estimate,
        task_category_id: form.values.task_category_id,
        start_date: form.values.start_date.toISOString().split("T")[0],
        end_date: form.values.end_date
          ? new Date(form.values.end_date).toISOString().split("T")[0]
          : "",
        // time_consumption: form.values.time,
        free_description: form.values.free_description,
        // task_leader_id: form.values.task_leader_id,
        cost: form.values.cost,
        // budget: form.values.budget,
        customer_id: form.values.customer_id,
        status_id: form.values.status_id,
        // employees: filteredData,
      };
      // console.log(originalData, formDataProps, "formDataProps")
      // return;
      const response = await regTaskOnly(originalData, "PUT");
      const result = response.data;
      if (result.code === 200) {
        setLoading(false);
        callBack();
        notifications.show({
          color: "green",
          icon: <IconCheck />,
          message: result.msg,
        });
      } else {
        notifications.show({
          color: "red",
          icon: <IconX />,
          message: result.msg,
        });
      }
    } else {
      notifications.show({
        title: "Error",
        color: "yellow.7",
        icon: <IconX />,
        message: Object.values(valid.errors)[0],
      });
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = TimeHHSS(event);
    form.setFieldValue("time_estimate", value);
  };

  // 删除已选择的雇员信息
  const delOrderList = (indexs: number) => {
    const newData = employeestool.filter((item, index) => index !== indexs);
    form.setFieldValue("project_order", newData);
    setEmployees(newData); // 更新状态
  };

  //
  const rows = employeestool.map((element, index) => (
    <tr key={index}>
      <td>
        <Avatar src={element.avatar} radius={40} size={26} />
        <Text>{element.label ? element.label : element.username}</Text>
      </td>
      <td>
        {employeesList.map((item: any, index: any) => {
          if (element.employee_role_id == item.value) {
            return item.label;
          }
        })}
      </td>
      <td>
        <Group mt="md">
          <ActionIcon onClick={() => delOrderList(index)}>
            <IconTrash size="1rem" stroke={1.5} />
          </ActionIcon>
        </Group>
      </td>
    </tr>
  ));

  useEffect(() => {
    setDesEnable(taskItemSingel.flag === "0" ? false : true);
  }, [taskItemSingel.flag]);
  // 更具flag选择 选择freedescripotion 是否可填
  function pickCategory(value: string | null): void {
    setDesEnable(value === "0" ? false : true);
    form.setFieldValue("flag", value as string);
  }

  function onEmployeesSearchChangeHandle(value: string | null): void {
    setresetUser(true);
    form.setFieldValue("employees_id", value as string);
  }

  return (
    <form onSubmit={handleFormSubmit} ref={formRef}>
      <LoadingOverlay visible={loading} overlayBlur={2} />
      <Box ml={20} mih={500}>
        <Card>
          <Grid>
            <Grid.Col span={12}>
              <Select
                labelProps={{ className: "input_title" }}
                label={
                  <FormattedMessage
                    id="Task Category"
                    defaultMessage="Task Category"
                  />
                }
                placeholder={intl.formatMessage({
                  id: "select_items",
                  defaultMessage: "Select Items",
                })}
                onChange={(value) =>
                  form.setFieldValue("task_category_id", value as string)
                }
                defaultValue={form.values.task_category_id}
                key={form.values.task_category_id}
                data={taskcateGoryListData}
                dropdownPosition={"bottom"}
              />
              <Box>
                <Select
                  placeholder={intl.formatMessage({
                    id: "select_items",
                    defaultMessage: "Select Items",
                  })}
                  searchValue={searchCustomerValue}
                  labelProps={{ className: "input_title" }}
                  label={
                    <FormattedMessage id="Customer" defaultMessage="Customer" />
                  }
                  onSearchChange={onSearchCustomerChange}
                  defaultValue={form.values.customer_id}
                  key={form.values.customer_id}
                  searchable
                  nothingFound={intl.formatMessage({
                    id: "No options",
                    defaultMessage: "No options",
                  })}
                  onChange={(value) =>
                    form.setFieldValue("customer_id", value as string)
                  }
                  data={customerListProps}
                  dropdownPosition={"bottom"}
                />
              </Box>
              <Select
                placeholder={intl.formatMessage({
                  id: "select_items",
                  defaultMessage: "Select Items",
                })}
                searchValue={searchStatusValue}
                labelProps={{ className: "input_title" }}
                label={<FormattedMessage id="Status" defaultMessage="Status" />}
                onSearchChange={onSearchStatusValue}
                defaultValue={form.values.status_id}
                key={form.values.status_id}
                searchable
                nothingFound={intl.formatMessage({
                  id: "No options",
                  defaultMessage: "No options",
                })}
                onChange={(value) =>
                  form.setFieldValue("status_id", value as string)
                }
                data={statusListProps}
              />
              <TextInput
                withAsterisk
                mt={10}
                labelProps={{ className: "input_title" }}
                {...form.getInputProps("cost")}
                label={<FormattedMessage id="cost" defaultMessage="Cost" />}
                placeholder={intl.formatMessage({
                  id: "input",
                  defaultMessage: "Input",
                })}
              />
              <DateInput
                valueFormat="DD/MM/YYYY"
                {...form.getInputProps("start_date")}
                icon={<IconCalendar size="1.1rem" stroke={1.5} />}
                label={
                  <FormattedMessage
                    id="start_time"
                    defaultMessage="Start Time"
                  />
                }
                placeholder={intl.formatMessage({
                  id: "start_time",
                  defaultMessage: "Start Time",
                })}
                mx="auto"
              />
              <DateInput
                valueFormat="DD/MM/YYYY"
                {...form.getInputProps("end_date")}
                icon={<IconCalendar size="1.1rem" stroke={1.5} />}
                label={
                  <FormattedMessage id="end_time" defaultMessage="End Time" />
                }
                placeholder={intl.formatMessage({
                  id: "end_time",
                  defaultMessage: "End Time",
                })}
                mx="auto"
              />
              <Textarea
                // disabled={descEnable}
                labelProps={{ className: "input_title" }}
                placeholder={intl.formatMessage({
                  id: "input",
                  defaultMessage: "Input",
                })}
                label={
                  <FormattedMessage
                    id="task_description"
                    defaultMessage="Task Description"
                  />
                }
                {...form.getInputProps("free_description")}
              />
            </Grid.Col>
          </Grid>
          {/* 如果存在雇员以表格形式显示 */}

          <SaveBottom formRef={formRef} />
        </Card>
      </Box>
    </form>
  );
}
