import React, { useEffect, useRef, useState } from 'react';
import {
  Group,
  NumberInput,
  TextInput,
  Box,
  Paper,
  Grid,
} from '@mantine/core';
import {
  Card,
  Image,
} from '@mantine/core';
import { regCompanySeting, regImgUpload } from '../../../../api';
import UploadSingleImage from '../../../../ttcomponents/UploadImage/UploadSingleImage/UploadSingleImage';
import apiUrl from '../../../../config/api_config/config';
import { ComponentInterface } from '../../../../interface/Icommon';
import TableTopTitle from '../../../../ttcomponents/TableTopTitle/TableTopTitle';
import WordTranslationComponent from '../../../../utils/language_pack/words';
import { HintErrorMantineInfo, HintInfo } from '../../../../utils/function';
import TableTextInputTitle from '../../../../ttcomponents/TableTextInputTitle/TableTextInputTitle';
import TableTextInputTitleDesc from '../../../../ttcomponents/TableTextInputTitleDesc/TableTextInputTitleDesc';
import { hasLength, useForm } from '@mantine/form';
import { getCompanyGeneralFunction } from '../../../../api/common_request';
import SubmitBtn from '../../../../ttcomponents/Button/SubmitBtn';
import { CheckPermission } from '../../../../utils/permission/permission';
import { NotPermission } from '../../../../ttcomponents/NoPermission';
import CheckPermissionTools from '../../../../utils/permission';

export default function CompanySettings({ is_load_str }: ComponentInterface) {

  //-------------permission list allocation-------------

  const Perm = CheckPermissionTools();

//------------- permission list allocation-------------

  // Get translation public configuration information
  const word_translation = WordTranslationComponent();

// Get form references
const formRef = useRef<HTMLFormElement>(null);

  // The status of the image path
  const [imagePath, setImagePath] = useState('');
  //Create a form instance

  const form = useForm({
     // Validate the input value when the focus is lost
    validateInputOnBlur: true,
     // Initialize the form values

    initialValues: {
    id: '',
      companyName: '',
      companyStreet2: '',
      companyCity: '',
      companyLogoPath: '',
      companyStreet1: '',
      companyPostalCode: 0,
    },

    //Form validation rules

    validate: {
    // Company Name Validation Rules
    companyName: hasLength(
        { min: 2, max: 100 },
        `${word_translation.company_name}${word_translation.validata_value_need_2_100_length}`
      ),
      //Street Address 1 Validation Rules
      companyStreet1: hasLength(
        { min: 2, max: 200 },
        `${word_translation.street1}${word_translation.validata_value_need_2_100_length}`
      ),
      //City Validation Rules
      companyCity: hasLength(
        { min: 2, max: 200 },
        `${word_translation.city}${word_translation.validata_value_need_2_100_length}`
      ),
    },
  }); 

  
/**
 * Handles the form submission.
 * Prevents the default form submission, validates the form,
 * and sends a request to update or create company settings.
 */
const handleFormSubmit = async () => {

  // Validate the form
  const validationResult = await form.validate();

  // Check if there are no validation errors
  if (!validationResult.hasErrors) {
    try {
      // Send a request to update or create company settings
      const response = await regCompanySeting(
        form.values,
        form.values.id ? 'PUT' : 'POST'
      );

      // Display information based on the response
      HintInfo(response.data);

      // Check if the request was successful
      if (response.data.code === 200) {
        // Additional actions can be performed here if needed
      }
    } catch (error) {
      // Log an error if there is an exception during the request
      console.error('Error handling form submission:', error);
    }
  } else {
    // Display error information if there are validation errors
    HintErrorMantineInfo(validationResult);
  }
};

  
/**
 * Fetches company settings information from the server.
 */
const fetchCompanySettings = async () => {

  // Check if the current section is related to company settings
  if (is_load_str === 'company_settings') {
    try {
      // Make an API request to get company settings data
      const result  = await getCompanyGeneralFunction()
      // Check if the request was successful and the data is in the expected format
      if (result) {
        // Destructure relevant properties from the data
        const {
          companyCity,
          id_companysettings,
          companyLogoPath,
          companyName,
          companyPostalCode,
          companyStreet1,
          companyStreet2,
        } = result;

        // Update form fields with the retrieved data
        form.setFieldValue('companyCity', companyCity);
        form.setFieldValue('id', id_companysettings);
        form.setFieldValue('companyLogoPath', companyLogoPath);
        form.setFieldValue('companyName', companyName);
        form.setFieldValue('companyPostalCode', parseFloat(companyPostalCode));
        form.setFieldValue('companyStreet1', companyStreet1);
        form.setFieldValue('companyStreet2', companyStreet2);

        // Set the image path for preview
        setImagePath(
          companyLogoPath ? apiUrl.fileSystemDomain + companyLogoPath : ''
        );
      } else {
        // Log an error message if the request was not successful
        console.error(result.msg);
      }
    } catch (error) {
      // Log an error if there is an exception during the request
      console.error('Error fetching company settings:', error);
    }
  }
};

// Trigger the fetchCompanySettings function when the component mounts or when is_load_str changes
useEffect(() => {
  fetchCompanySettings();
}, [is_load_str]);


  const handleValueChange = (value: string ) => {
    form.setFieldValue('companyLogoPath', value);
    setImagePath(apiUrl.fileSystemDomain + value);
  };

  return (
  
    <Paper>
        {Perm.company_read ?    <Grid gutter={0} align="left">
        <Grid.Col span={5}>
          <Box w={700} className="border-radius-xs shadow-sm border-custom">
            <TableTopTitle title={word_translation.company_settings} />
            <form
              className="px-15"
              onSubmit={handleFormSubmit}
              ref={formRef}
            >
              <Box py={20}>
                <Grid gutter={40} align="left">
                  <Grid.Col span={4}>
                    <Group>
                      <TableTextInputTitle title={word_translation.company_logo} />
                      <TableTextInputTitleDesc title={word_translation.click_image_update} />
                    </Group>
                    <UploadSingleImage
                      callback={(value) => handleValueChange(value)}
                      imagepath={imagePath}
                      wimage={285}
                      himage={180}
                    />
                  </Grid.Col>
                </Grid>

                <Grid gutter={40} align="left">
                  <Grid.Col span={6}>
                    <TableTextInputTitle title={word_translation.company_name} />
                    <TextInput
                      placeholder={word_translation.company_name}
                      {...form.getInputProps('companyName')}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <TableTextInputTitle title={word_translation.city} />
                    <TextInput
                      placeholder={word_translation.city}
                      {...form.getInputProps('companyCity')}
                    />
                  </Grid.Col>
                </Grid>
                <Grid gutter={40} align="left">
                  <Grid.Col span={4}>
                    <TableTextInputTitle title={word_translation.company_postal_code} />
                    <NumberInput
                      placeholder={word_translation.company_postal_code}
                      {...form.getInputProps('companyPostalCode')}
                    />
                  </Grid.Col>
                </Grid>

                <Grid gutter={40} align="left">
                  <Grid.Col span={12}>
                    <TableTextInputTitle title={word_translation.street1} />
                    <TextInput
                      placeholder={word_translation.Input}
                      {...form.getInputProps('companyStreet1')}
                    />
                    <TableTextInputTitle title={word_translation.street2} />
                    <TextInput
                      placeholder={word_translation.Input}
                      {...form.getInputProps('companyStreet2')}
                    />
                  </Grid.Col>
                </Grid>

                <Group position="right" mt="md">
                <SubmitBtn disabled={Perm.company_update == false}  btn_size="xs" callBack={handleFormSubmit}/>
                </Group>
              </Box>
            </form>
          </Box>
        </Grid.Col>
        <Grid.Col span={7} className="img-center">
          <Card shadow="sm" padding="lg" radius="md" withBorder>
            <TableTextInputTitle title={word_translation.preview} />
            {imagePath && <Image src={imagePath} maw={500} />}
          </Card>
        </Grid.Col>
      </Grid>: <NotPermission />}
   
    </Paper>
  );
}
