import { Box, Group, Tooltip } from "@mantine/core";
import { IconCaretRightFilled } from "@tabler/icons-react";

interface ComponentInterface{
    title:string | null;
    is_icon?:boolean;
    type?:any;
}
export default function PreSheetTd({title,type,is_icon=true}:ComponentInterface){

    return(
        <td className="sheet_td b_color">
            
            <Group position="left" grow={false}> 
                    <Box>{(is_icon &&  <IconCaretRightFilled  size={15}/>) } </Box>
                    {type ? <Tooltip  label={type}>
                            <Box className="b_color sheet_td"> {title}</Box>
                    </Tooltip>:  <Box> {title}</Box>}
            </Group>  
       </td> 
    )

} 