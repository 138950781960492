import {
  Box,
  Code,
  Grid,
  Group,
  NumberInput,
  Paper,
  Select,
  TextInput,
  Textarea,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import CancelButton from "../../../../ttcomponents/Button/CancelBtn";
import SaveBottom from "../../../../ttcomponents/Button/SaveBtn";
import { FormEvent, useEffect, useRef, useState } from "react";
import { isNotEmpty, useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { IconCalendar, IconX } from "@tabler/icons-react";
import {
  HintInfo,
  TotalPriceFunction,
  formatDateUpgraded,
} from "../../../../utils/function";
import globalCortSetting from "../../../../utils/globalCortSetting";
import ProductSelect from "../../../../ttcomponents/Product/ProductSelect";
import { regCustomerList, regRentProduct } from "../../../../api";
import { ResponseCustomerData } from "../../../../interface/Icoustomer";
import { SelectRole } from "../../../../interface/Icommon";
import { useIntl } from "react-intl";
interface deliverProductProps {
  additional_information: any;
  closeFucntion: (type: number) => void;
}
export default function RentProductFrom({
  additional_information,
  closeFucntion,
}: deliverProductProps) {
  // Use the useIntl hook to access the intl object
  const intl = useIntl();

  const [customerlist, setCustomerList] = useState<SelectRole[]>([]);
  const [currentCostomerId, setCurrentCostomerId] = useState("");
  const formRef = useRef<HTMLFormElement>(null);
  console.log("222", additional_information);
  const form = useForm({
    initialValues: {
      rent_id: additional_information?.rent_id,
      product_id: additional_information?.product_id,
      rent_quantity: additional_information?.rent_quantity,
      total_price: additional_information?.total_price,
      customer_id: additional_information?.customer_id,
      description: additional_information?.description,
      contract_no: additional_information?.contract_no,
      status: additional_information?.status,
      rental_start_time: additional_information.rental_start_time
        ? new Date(additional_information.rental_start_time)
        : new Date(),
      rental_end_time: additional_information.rental_end_time
        ? new Date(additional_information.rental_end_time)
        : new Date(),
    },

    validate: {
      product_id: isNotEmpty("Product name is required"),
      rent_quantity: isNotEmpty("picking_out_bound_quantity  is required"),
    },
  });

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    // Stop the formal submission of the form default
    event.preventDefault();
    // Verification form
    const valid = form.validate();
    if (valid.hasErrors) {
      notifications.show({
        color: "yellow.7",
        icon: <IconX />,
        message: Object.values(valid.errors)[0],
      });
      return;
    }
    let submit_type = "POST";
    if (form.values.rent_id) {
      submit_type = "PUT";
    }

    const updatedFormValues = {
      ...form.values,
      rental_start_time: formatDateUpgraded(
        form.values.rental_start_time,
        "YYYY-MM-DD"
      ),
      rental_end_time: formatDateUpgraded(
        form.values.rental_end_time,
        "YYYY-MM-DD"
      ),
    };

    const response = await regRentProduct(updatedFormValues, submit_type);
    HintInfo(response.data);
    if (response.data.code == 200) {
      closeFucntion(2);
    }
  };
  const ReceiveSupplierBack = (value: any) => {};

  const changeDelivelyTime = (num: any) => {
    const totalPrice = TotalPriceFunction(
      num,
      additional_information?.unit_price
    );
    form.setFieldValue("total_price", totalPrice as string);
    form.setFieldValue("rent_quantity", num);
  };

  // Get Timetrack Record
  const ajaxCurrentList = async () => {
    if (customerlist.length <= 0) {
      const responseCustomer = await regCustomerList({});
      const result = responseCustomer.data as ResponseCustomerData;
      if (result.code === 200) {
        const customerData = result.data.map((item) => ({
          value: item.id_customer.toString() as string,
          label: item.company_name,
        }));
        setCustomerList(customerData);
      }
    }
  };

  useEffect(() => {
    // if(rental_status == true){
    ajaxCurrentList();
    // }
  }, []);
  const isEdit = additional_information?.rent_id ? true : false;
  console.log(currentCostomerId, customerlist, 2222);
  return (
    <Paper p={10}>
      <form onSubmit={handleFormSubmit} ref={formRef}>
        <Grid>
          <Grid.Col span={12}>
            <ProductSelect
              createNew={false}
              disabled={additional_information?.product_id ? true : false}
              labelStr="Product Name"
              currentId={additional_information?.product_id as string}
              callBack={ReceiveSupplierBack}
            />
            {/* {additional_information?.customer_id} */}

            <TextInput
              {...form.getInputProps("contract_no")}
              label="Contract No"
              disabled={isEdit}
            />

            <Select
              label="Select Customer"
              searchable
              data={customerlist}
              defaultValue={additional_information?.customer_id}
              disabled={isEdit}
              onChange={(value) =>
                form.setFieldValue("customer_id", value as string)
              }
            />

            <NumberInput
              disabled={additional_information?.rent_quantity ? true : false}
              {...form.getInputProps("rent_quantity")}
              // value={categoryName}
              label="Rent Quantity"
            />

            <TextInput
              {...form.getInputProps("total_price")}
              label="Total Price"
            />

            <DateInput
              valueFormat="DD/MM/YYYY"
              required
              disabled={isEdit}
              icon={<IconCalendar size="1.1rem" stroke={1.5} />}
              {...form.getInputProps("rental_start_time")}
              label={intl.formatMessage({
                id: "Rental Begin Time",
                defaultMessage: "Rental Begin Time",
              })}
            />

            <DateInput
              valueFormat="DD/MM/YYYY"
              required
              minDate={form.values.rental_start_time}
              icon={<IconCalendar size="1.1rem" stroke={1.5} />}
              {...form.getInputProps("rental_end_time")}
              label={intl.formatMessage({
                id: "Rental End Time",
                defaultMessage: "Rental End Time",
              })}
            />

            <Textarea
              label="Introduction"
              {...form.getInputProps("description")}
              minRows={5}
              radius="md"
            />
          </Grid.Col>
        </Grid>

        <Group position="right">
          {/* <CancelButton callBack={() => closeFucntion(1)} /> */}
          <SaveBottom formRef={formRef} />
        </Group>

        {/** can see form values */}
        <Code block mt={5}>
          {JSON.stringify(form.values, null, 2)}
        </Code>
      </form>
    </Paper>
  );
}
