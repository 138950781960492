/**
 * Including all interface request functions in the application
 */

// ajax('http://local.fr.com/login',{username:"admin",password:"admin123"},"POST")

import ajax from "./ajax";

import apiUrl from "../config/api_config/config";
// Timetrack service

// Default Host value
// let Host = "http://localhost:8181/api/";
let Host = apiUrl.apiUrl + "/api/";

/**************************************************************************
 *
 *                   **********      ***********
 * ******************    ***    *****    ***    ***************************
 *                   **********      ***********
 *
 *                           core
 *                           核心
 *
 *
 * ************************************************************************************
 *
 *
 *
 *************************************************************************
 */

/**************************************************************************
 *
 *                   **********      ***********
 * ******************    ***    *****    ***    ***************************
 *                   **********      ***********
 *
 *                           TimeTrack
 *                           模块接口测试
 *
 *
 * ************************************************************************************
 *
 *
 *
 *************************************************************************
 */

const assignment_module = "assignment/v2/";

//  Processing of projects (adding, deleting, modifying, and checking)
export const requestProject = (data, method) =>
  ajax(Host + assignment_module + "project", data, method, false);

// Get one the projects
export const requestSingleProject = (data) =>
  ajax(Host + assignment_module + "project/" + data, {}, "GET", false);

//// 获取所有的项目
export const requestProjectList = (data, method) =>
  ajax(Host + assignment_module + "project_list", data, method, false);

// Processing of Order (adding, deleting, modifying, and checking)
export const requestOrder = (data, method) =>
  ajax(Host + assignment_module + "order", data, method, false);
// Get one the order
export const requestSingleOrder = (data) =>
  ajax(Host + assignment_module + "order/" + data, {}, "GET", false);

// get all orders
export const requestOrderList = (data, method) =>
  ajax(Host + assignment_module + "order_list", data, method, false);

// Processing of Task (adding, deleting, modifying, and checking)
export const requestTask = (data, method) =>
  ajax(Host + assignment_module + "task", data, method, false);

// get all tasks
export const requestTaskList = (data, method) =>
  ajax(Host + assignment_module + "task_list", data, method, false);

export const requestCustomTaskList = (data, method) =>
  ajax(
    Host + assignment_module + "custom_conditions_task_list",
    data,
    method,
    false
  );

export const requestProjectTaskByCostCenter = (data, method) =>
  ajax(Host + assignment_module + "project_task_together", data, method, false);

export const requestTaskOverTime = (data, method) =>
  ajax(Host + assignment_module + "task_overtime", data, method, false);

// Get one the task
export const requestSingleTask = (data) =>
  ajax(Host + assignment_module + "task/" + data, {}, "GET", false);

// set permission_role
export const requestPermissionRole = (data, method) =>
  ajax(Host + assignment_module + "permission_role", data, method, false);
export const requestPermissionListRole = (data, method) =>
  ajax(Host + assignment_module + "permission_role_list", data, method, false);

export const requestGetPermissionRoleDetail = (data, method) =>
  ajax(Host + assignment_module + "get_permission_role", data, method, false);

export const requestPermissionRoleProjectDetail = (data, method) =>
  ajax(
    Host + assignment_module + "get_permission_role_project",
    data,
    method,
    false
  );

export const requestPermissionSetList = (data, method) =>
  ajax(Host + assignment_module + "permission_set_list", data, method, false);

export const requestPermissionProvision = (data, method) =>
  ajax(
    Host + assignment_module + "assignment_provision_permission",
    data,
    method,
    false
  );

// Processing of permission identifie (adding, deleting, modifying, and checking)
export const requestPermissionIdentifier = (data, method) =>
  ajax(Host + assignment_module + "permission_identifier", data, method, false);

// Get one the permission identifie list no Pagination
export const requestPermissionIdentifierList = (data, method) =>
  ajax(
    Host + assignment_module + "permission_identifier_list",
    data,
    method,
    false
  );

// Get one the permission identifie list no Pagination
export const requestTimeTrackerUniquePermissionIdentifierList = (
  data,
  method
) =>
  ajax(
    Host + assignment_module + "get_unique_permission_identifier",
    data,
    method,
    false
  );

// Processing of permission action (adding, deleting, modifying, and checking)
export const requestPermissionAction = (data, method) =>
  ajax(Host + assignment_module + "permission_action", data, method, false);

// Get one the permission action list no Pagination
export const requestTimeTrackerUniquePermissionActionList = (data, method) =>
  ajax(
    Host + assignment_module + "get_unique_permission_action",
    data,
    method,
    false
  );

// Get one the permission action list no Pagination
export const requestPermissionActionList = (data, method) =>
  ajax(
    Host + assignment_module + "permission_action_list",
    data,
    method,
    false
  );

// set permission_role
export const requestPermissionSet = (data, method) =>
  ajax(Host + assignment_module + "permission_set", data, method, false);
export const requestAssignmentPermissionSetDetail = (data, method) =>
  ajax(Host + assignment_module + "get_permission_set", data, method, false);

//get employee report
export const requestEmployeeOvertime = (data, method) =>
  ajax(Host + assignment_module + "employee_overtime", data, method, false);

export const requestSignalEmployeeOvertime = (id, data, method) =>
  ajax(
    Host + assignment_module + "employee_overtime/" + id,
    data,
    method,
    false
  );

//get employee report
export const requestEmployeeTime = (data, method) =>
  ajax(Host + assignment_module + "employee_time", data, method, false);

export const requestAssignmentPermissionSetList = (data, method) =>
  ajax(Host + assignment_module + "permission_set_list", data, method, false);

// mock
export const requestOrderProduct = (data, method) =>
  ajax(
    "https://mockapi.eolink.com/SHHZMze4031ad4d6a7a37aee142f098547eef42b3f201be/api/get/get_order_product",
    data,
    method,
    false
  );

/**************************************************************************
 *
 *                   **********      ***********
 * ******************    ***    *****    ***    ***************************
 *                   **********      ***********
 *
 *                           TimeSheet
 *                           模块接口测试
 *
 *
 * ************************************************************************************
 *
 *
 *
 *************************************************************************
 */

const timetracker_module = "timetracker/";

//  const timetracker_module =  'assignment/v2/'

// Get one the permission action list no Pagination
export const requestTimeTrackerActionList = (data, method) =>
  ajax(Host + timetracker_module + "time_tracker", data, method, false);

export const requestTimeTrackerRemark = (data, method) =>
  ajax(Host + timetracker_module + "time_remark", data, method, false);

export const requestTimeTrackerRemarkDetail = (data, method) =>
  ajax(Host + timetracker_module + "time_remark_list", data, method, false);

export const requestTimeTrackerQuickLyAdd = (data, method) =>
  ajax(Host + timetracker_module + "time_tracker_quick", data, method, false);

export const requestTimeSheetUserList = (data, method) =>
  ajax(Host + timetracker_module + "user_list_managed", data, method, false);

// Get one the permission identifie list no Pagination
export const requestTMUniquePermissionIdentifierList = (data, method) =>
  ajax(
    Host + timetracker_module + "get_unique_permission_identifier",
    data,
    method,
    false
  );

// Processing of permission identifie (adding, deleting, modifying, and checking)
export const requestTMPermissionIdentifier = (data, method) =>
  ajax(
    Host + timetracker_module + "permission_identifier",
    data,
    method,
    false
  );

// Processing of permission action (adding, deleting, modifying, and checking)
export const requestTMPermissionAction = (data, method) =>
  ajax(Host + timetracker_module + "permission_action", data, method, false);

// Get one the permission action list  unique
export const requesTmUniquePermissionActionList = (data, method) =>
  ajax(
    Host + timetracker_module + "get_unique_permission_action",
    data,
    method,
    false
  );

export const requestTMPermissionIdentifierListAction = (data, method) =>
  ajax(
    Host + timetracker_module + "permission_identifier_list",
    data,
    method,
    false
  );

export const requestTMPermissionListAction = (data, method) =>
  ajax(
    Host + timetracker_module + "permission_action_list",
    data,
    method,
    false
  );

export const requestTMPermissionRole = (data, method) =>
  ajax(Host + timetracker_module + "permission_role", data, method, false);

export const requestTMPermissionRoleList = (data, method) =>
  ajax(Host + timetracker_module + "permission_role_list", data, method, false);

export const requestTMPermissionRoleDetail = (data, method) =>
  ajax(Host + timetracker_module + "get_permission_role", data, method, false);

export const requestTMPermissionSetDetail = (data, method) =>
  ajax(Host + timetracker_module + "get_permission_set", data, method, false);
export const requestTMPermissionSetList = (data, method) =>
  ajax(Host + timetracker_module + "permission_set_list", data, method, false);

export const requestTMPermissionSet = (data, method) =>
  ajax(Host + timetracker_module + "permission_set", data, method, false);
