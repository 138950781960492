import { Box, LoadingOverlay, Paper } from "@mantine/core";
import PageTitle from "../../ttcomponents/PageTitle/PageTitle";
import OrderFrom from "./order_from";
import { useEffect, useState } from "react";
import { regCustomerList, regUserAll } from "../../api";
import { ResponseUserData, UserAllList } from "../../interface/Iuser";
import { Project } from "../../interface/Iprojects";
import { SelectRole } from "../../interface/Icommon";
import { ResponseCustomerData } from "../../interface/Icoustomer";
import localstorageUnits from "../../utils/localstorageUnits";
import { useIntl } from "react-intl";

export default function OrderCreateIndex() {
  // Use the useIntl hook to access the intl object
  const intl = useIntl();

  const taskModeFlag = localstorageUnits.getSystem().task_mode_flag;
  // 模式类型
  const ProjectOrderTaskModel = taskModeFlag == 1;
  const ProjectTaskModel = taskModeFlag == 2;
  const OrderModel = taskModeFlag == 3;

  const [UserList, setResultUser] = useState<UserAllList[]>([]);
  const [userlist, setUserList] = useState<SelectRole[]>([]);
  // Define all the customer lists that can be selected
  const [customerlist, setCustomerList] = useState<SelectRole[]>([]);
  const [ProjectDataSingle, setProjectDataSingle] = useState<Project>({
    project_no: "",
    customer_id: "",
    name: "",
    start_date: "",
    end_date: "",
    status_id: "",
    info: "",
    id_project: "",
    delete_status: "",
  });
  const handleContactSubmitForm = () => {
    getCustomerList();
  };

  //  Selected information of loading items: customers, employees, order names
  //   const ajaxCurrentList = async () => {
  //     //   Get customer information

  //     //Obtain user
  //     const responseUser = await regUserAll({});
  //     const resultUser = responseUser.data as ResponseUserData;
  //     setResultUser(resultUser.data);

  //     const userData = resultUser.data.map((item) => ({
  //       value: item.id_user as string,
  //       label: item.username,
  //     }));
  //     setUserList(userData);
  //     //获取订单
  //     const responseOrderName = await regOrdernameList({});
  //     const resultOrderName = responseOrderName.data as ResponseOrderData;
  //     const OrderNameData = resultOrderName.data.map((item) => ({
  //       value: item.id_order_name as string,
  //       label: item.name,
  //     }));
  //     setOrderNameList(OrderNameData);
  //     // 获取所有的项目状态
  //     const statusresponse = await regProjectStatus({}, "GET");
  //     const statusResult = statusresponse.data as APIStatesResponse;
  //     let datass;
  //     if (statusResult.code === 200) {
  //       datass = statusResult.data.data.map((item) => ({
  //         value: item.id_status as string,
  //         label: item.status_name,
  //       }));
  //       setProjectStatusSelect(datass);
  //     }

  //     // Get the task type
  //     const cateGoryInfo = await regTaskCategory({});
  //     setLoading(false);
  //     if (cateGoryInfo.data.code == 200) {
  //       const taskcateGoryData = cateGoryInfo.data.data.data.map(
  //         (item: { id_task_category: string; identifier: any }) => ({
  //           value: item.id_task_category as string,
  //           label: item.identifier,
  //         })
  //       );
  //       setTaskcateGoryListData(taskcateGoryData);
  //     }
  //     // Get all employee characters
  //     const responseEmployeerolesList = await regEmployeerolesList({}, "GET");
  //     const resultEmployeerolesList =
  //       responseEmployeerolesList.data as EmployeeRoleResponseApi;
  //     const EmployeesListData = resultEmployeerolesList.data.map((item) => ({
  //       value: item.id_employee_roles as string,
  //       label: item.role_name,
  //     }));
  //     setEmployeesList(EmployeesListData);
  //   };
  //   useEffect(() => {
  //     ajaxCurrentList();
  //     if (taskModeFlag == "2") {
  //       setValueType(["task_only"]);
  //     }
  //   }, []);
  const getUserList = async () => {
    const responseUser = await regUserAll({});
    const resultUser = responseUser.data as ResponseUserData;
    setResultUser(responseUser.data.data);
    const userData = resultUser.data.map((item) => ({
      value: item.id_user as string,
      label: item.username,
    }));
    setUserList(userData);
  };

  const getCustomerList = async () => {
    const response = await regCustomerList({});
    const result = response.data as ResponseCustomerData;
    if (result.code === 200) {
      const customerData = result.data.map((item) => ({
        value: item.id_customer as string,
        label: item.company_name,
      }));
      setCustomerList(customerData);
    }
  };
  const ajaxCurrentList = async () => {
    getCustomerList();
    getUserList();
  };
  useEffect(() => {
    ajaxCurrentList();
  }, []);

  return (
    <Paper px={20}>
      <Box pb={20}>
        <PageTitle title="CREATE ORDER" lang_id="CREATE ORDER" />
      </Box>
      <Box maw={700}>
        <OrderFrom
          projectData={ProjectDataSingle}
          callBack={() => {
            handleContactSubmitForm();
          }}
          customerlist={customerlist}
          userlist={userlist}
        />
      </Box>
    </Paper>
  );
}
