import React, { createRef, useEffect, useRef, useState } from 'react'
import { Alert, Box, Button, Divider, Drawer, Grid, Group, Paper, Stepper, Text, useMantineTheme } from '@mantine/core'
import {
  Icon12Hours,
  IconAlertCircle,
  IconArrowBigRightLine,
  IconArrowBigRightLines,
  IconCheck,
  IconDeviceFloppy,
  IconLoader,
  IconX
} from '@tabler/icons-react'
import WordTranslationComponent from '../../../utils/language_pack/words'
import { getUserGeneralFunction } from '../../../api/common_request'
import TableTextInputTitle from '../../../ttcomponents/TableTextInputTitle/TableTextInputTitle'
import TableTextInputTitleDesc from '../../../ttcomponents/TableTextInputTitleDesc/TableTextInputTitleDesc'
import { getSystemAssignmentsFunction } from '../../../api/core_request'
import { ClientWarningHint } from '../../../utils/function'
import PageTitle from '../../../ttcomponents/PageTitle/PageTitle'
import OrderFrom from './order/order_create'
import { SelectRole } from '../../../interface/Icommon'
import { UserAllList } from '../../../interface/Iuser'
import ProjectCreate, { ChildMethods } from './project/project_create'
import TaskFrom from './task/create_task'
import UniversalButton from '../../../ttcomponents/Button/UniversalButton'
import ResetBtn from '../../../ttcomponents/Button/ResetBtn'
import SubmitBtn from '../../../ttcomponents/Button/SubmitBtn'
import {
  getCustomerListGeneralFunction,
  getOrderDetailByIdFunction,
  getProjectDetailByIdFunction,
  getTaskDetailByIdFunction
} from '../../../api/assignments_request'

import { ProjectDetailInterface } from '../../../interface/Iprojects'
import { OrderDataInterface } from '../../../interface/Iorder'
import { UserTaskInteface } from '../../../interface/Itask'
import { ProjectLeft } from './components/project_left'

// Component to create assignments
export default function CreateAssignments() {
  // Get translation public configuration information
  const word_translation = WordTranslationComponent()
  // Create a ref object that can reference child component methods
  const childRef = useRef<ChildMethods>(null)

  const [active, setActive] = useState(0)
  // Define all the list of employees you can choose
  const [userList, setUserList] = useState<SelectRole[]>([])
  // Define the user list (including the user's detailed information)
  const [allUserList, setAllUserList] = useState<UserAllList[]>([])

  // Define all the customer lists that can be selected
  const [customerList, setCustomerList] = useState<SelectRole[]>([])

  const [currentProjectId, setCurrentProjectId] = useState('')
  const [currentOrderId, setCurrentOrderId] = useState('')
  const [currentTaskId, setCurrentTaskId] = useState('')
  //  Define current  project info  in the page
  const [currentProjectInfo, setCurrentProjectInfo] = useState<ProjectDetailInterface>()
  //  Define current  order info  in the page
  const [currentOrderInfo, setCurrentOrderInfo] = useState<OrderDataInterface>()
  //  Define current  task  info  in the page
  const [currentTaskInfo, setCurrentTaskInfo] = useState<UserTaskInteface>()

  const getOrderDeatil = async (order_id: string) => {
    const orderIndo = await getOrderDetailByIdFunction(order_id)
    // 如果当前订单存在项目ID
    if (orderIndo.project_id) {
      // form.setFieldValue('project_id',orderIndo.project_id.toString())
      getProjectDeatil(orderIndo.project_id)
    }
    setCurrentOrderInfo(orderIndo)
  }

  const geTaskDeatil = async () => {
    const taskInfo = await getTaskDetailByIdFunction(currentTaskId)
    // 如果当前订单存在项目ID
    if (taskInfo.order_id) {
      // form.setFieldValue('project_id',orderIndo.project_id.toString())
      getOrderDeatil(taskInfo.order_id)
    } else if (taskInfo.project_id) {
      getProjectDeatil(taskInfo.project_id)
    }
    setCurrentTaskInfo(taskInfo)
  }

  const getProjectDeatil = async (project_id: string) => {
    setCurrentProjectInfo(await getProjectDetailByIdFunction(project_id))
  }

  //  Task callback Function <FUNCTION>
  const taskFromCallBackfunction = (task_id: any) => {
    if (task_id !== false) {
      handleNextFunction('task')
      setCurrentTaskId(task_id)
    }
  }

  //  Order callback Function <FUNCTION>
  const orderFromCallBackfunction = (order_id: any) => {
    console.log('order_id', order_id)
    if (order_id !== false) {
      handleNextFunction('order')
      setCurrentOrderId(order_id)
    }
  }

  // Project callback Function <FUNCTION>
  const projectFormCallbackFunction = (project_id: any) => {
    if (project_id !== false) {
      handleNextFunction('project')
      setCurrentProjectId(project_id)
    }
  }

  //inti
  useEffect(() => {
    getCustomerList()
    getUser()
    InitializeProcessingAssignment()
  }, [])

  interface AssignmentModa {
    type: string
    is_skipped: boolean
    allowStepSelect: boolean
    label: string
    description: string
    view: JSX.Element
  }
  /**
   *Get the initial value passed into the component
   */
  const getInitConfigFunction = async () => {
    // Get the system configuration mode. If it does not exist, jump to the settings page to set it.
    const assignmentModals = await getSystemAssignmentsFunction()
    if (!assignmentModals) {
      console.log('没有设置模式.跳转设置')
      return false
    }

    // 辅助函数，用于检查 assignmentModals 是否包含指定模式
    const includesAny = (modes: string[]) => assignmentModals.split(',').some((item: string) => modes.includes(item))

    setAssignmentModas(prevAssignmentModas => {
      // Use map to update elements and keep others unchanged
      return prevAssignmentModas
        .filter(assignmentModa => {
          if (assignmentModa.type === 'project') {
            return includesAny(['1', '2', '3'])
          }
          if (assignmentModa.type === 'order') {
            return includesAny(['1', '2', '4', '5'])
          }
          if (assignmentModa.type === 'task') {
            return includesAny(['6', '1', '3', '4'])
          }
          // Keep other elements unchanged
          return true
        })
        .map(assignmentModa => {
          if (assignmentModa.type === 'project') {
            if (!includesAny(['1', '2', '3'])) {
              // Keep the assignmentModa unchanged
              return assignmentModa
            }
            // Update c_allUserList
            return {
              ...assignmentModa,
              view: (
                <Group
                  align="flex-start"
                  spacing={30}
                  fz={14}
                  fw={400}
                  lh={1.5}
                  c={'rgb(134, 142, 150)'}
                  mt={12}
                  position={'center'}
                >
                  <ProjectLeft />
                  <Box
                    w={800}
                    style={{
                      border: '1px solid rgba(0, 0, 0, 0.1)',
                      borderRadius: '5px'
                    }}
                  >
                    <ProjectCreate
                      callBackFunction={projectFormCallbackFunction}
                      ref={childRef}
                      customerList={customerList}
                      c_allUserList={allUserList}
                      c_userList={userList}
                    />
                  </Box>
                  <Box w={340} mt={500}>
                    <Text>Select all project members here. You can also add more members later.</Text>
                  </Box>
                </Group>
              )
            }
          }
          if (assignmentModa.type === 'order') {
            if (!includesAny(['1', '2', '4', '5'])) {
              // Keep the assignmentModa unchanged
              return assignmentModa
            }
            // Update c_allUserList
            return {
              ...assignmentModa,
              view: (
                <OrderFrom
                  ref={childRef}
                  customerList={customerList}
                  callBackFunction={orderFromCallBackfunction}
                  project_id={currentProjectId}
                  callBack={orderFromCallBackfunction}
                  is_load_str={'modules_serve'}
                  c_allUserList={allUserList}
                  c_userList={userList}
                />
              )
            }
          }
          if (assignmentModa.type === 'task') {
            if (!includesAny(['6', '1', '3', '4'])) {
              // Keep the assignmentModa unchanged
              return assignmentModa
            }
            // Update c_allUserList
            return {
              ...assignmentModa,
              view: (
                <TaskFrom
                  callBackFunction={taskFromCallBackfunction}
                  project_id={currentProjectId}
                  order_id={currentOrderId}
                  ref={childRef}
                  customerList={customerList}
                  is_load_str={'modules_serve'}
                  c_allUserList={allUserList}
                  c_userList={userList}
                />
              )
            }
          }
          // Keep other elements unchanged
          return assignmentModa
        })
    })
  }

  /**
   * Get a list of users
   */
  const getUser = async () => {
    // Call the Get User Universal Selection function and set the user list
    let userList = await getUserGeneralFunction('all_info')

    setUserList(
      userList.map((item: { id_user: string; username: any }) => ({
        value: item.id_user as string,
        label: item.username
      }))
    )

    setAllUserList(userList)
  }

  const getCustomerList = async () => {
    setCustomerList(await getCustomerListGeneralFunction(''))
  }

  useEffect(() => {
    getInitConfigFunction()
  }, [userList, allUserList, customerList, currentProjectId, currentOrderId])

  useEffect(() => {
    const updatedAssignmentModas = [...assignment_modas]
    if (updatedAssignmentModas[active]) {
      updatedAssignmentModas[active].is_skipped = false
      setAssignmentModas(updatedAssignmentModas)
    }
    if (active > assignment_modas.length - 1) {
      if (currentTaskId) {
        geTaskDeatil()
      } else if (currentOrderId) {
        getOrderDeatil(currentOrderId)
      }
    }
  }, [active])
  // Assignment steps configuration
  const [assignment_modas, setAssignmentModas] = useState<AssignmentModa[]>([
    {
      type: 'project',
      is_skipped: false,
      allowStepSelect: false,
      label: word_translation.project,
      description: word_translation.create_project,
      view: (
        <ProjectCreate
          callBackFunction={projectFormCallbackFunction}
          ref={childRef}
          customerList={customerList}
          c_allUserList={allUserList}
          c_userList={userList}
        />
      )
    },
    {
      type: 'order',
      is_skipped: false,
      allowStepSelect: true,
      label: word_translation.order,
      description: word_translation.create_order,
      view: (
        <OrderFrom
          project_id={currentProjectId}
          c_allUserList={allUserList}
          ref={childRef}
          c_userList={userList}
          callBackFunction={orderFromCallBackfunction}
          customerList={customerList}
          callBack={orderFromCallBackfunction}
          is_load_str={'modules_serve'}
        />
      )
    },
    {
      type: 'task',
      is_skipped: false,
      allowStepSelect: false,
      label: word_translation.task,
      description: word_translation.create_task,
      view: (
        <TaskFrom
          project_id={currentProjectId}
          order_id={currentOrderId}
          c_allUserList={allUserList}
          customerList={customerList}
          c_userList={userList}
          ref={childRef}
          callBackFunction={taskFromCallBackfunction}
          is_load_str={'modules_serve'}
        />
      )
    }
  ])

  /**
   * 初始化处理分配函数
   */
  const InitializeProcessingAssignment = async () => {
    // 获取系统分配函数
    let assignmentModals = await getSystemAssignmentsFunction()

    // 如果没有设置模式，则跳转到设置页面
    if (!assignmentModals) {
      console.log('没有设置模式. 跳转设置')
      return false
    }

    // 初始化下一个索引
    let nextIndex = 0

    // 如果存在项目>订单>任务或项目>订单模式或项目>任务模式，则设置为nextIndex为当前索引
    if (assignmentModals.split(',').some((item: string) => ['1', '2', '3'].includes(item))) {
      setActive(nextIndex)
    } else if (
      !assignmentModals.split(',').some((item: string) => ['1', '2', '3'].includes(item)) &&
      assignmentModals.split(',').some((item: string) => ['4', '5'].includes(item))
    ) {
      // 如果没有项目>订单>任务和项目>订单模式或项目>任务模式，但有订单>任务模式，跳过当前步骤并设置为nextIndex+1为当前索引
      const updatedAssignmentModas = [...assignment_modas]
      updatedAssignmentModas[nextIndex].is_skipped = false
      setAssignmentModas(updatedAssignmentModas)
      setActive(nextIndex)
    } else if (
      !assignmentModals.split(',').some((item: string) => ['1', '2', '3', '4', '5'].includes(item)) &&
      assignmentModals.split(',').some((item: string) => ['6'].includes(item))
    ) {
      // 如果没有项目>订单>任务、项目>订单和订单>任务模式，但有单一任务模式，跳过当前步骤和下一步骤并设置为nextIndex+2为当前索引
      const updatedAssignmentModas = [...assignment_modas]
      updatedAssignmentModas[nextIndex].is_skipped = false
      setAssignmentModas(updatedAssignmentModas)
      setActive(nextIndex)
    }
  }

  // Determine whether the previous level is skipped <FUNCTION>
  const isSkipPreviousLevelFUnction = () => {
    if (active > 0) {
      return assignment_modas[active - 1].is_skipped
    } else {
      return true
    }
  }

  /**
   *
   */
  const handleNextFunction = async (type: string) => {
    // Calculate the index for the next step: active is prohibited. Reason: Because of the asynchronous problem, the previous value will always be obtained.
    let nextIndex = assignment_modas.findIndex(item => item.type === type)
    const includesAny = await getAssignmentModals()
    const click_type = assignment_modas[active].type
    // Helper function for checking whether assignmentModals contains the specified pattern
    if (click_type == 'order') {
      if (isSkipPreviousLevelFUnction()) {
        // 如果上一级是跳过的,说明没有项目则进行判断有没有订单任务模式或者单任务模式，有则进行一跳，没有进行二跳
        //  If the previous level is skipped, it means that there is no project,
        //  then it will be judged whether there is an order task mode or a single task mode.\
        //  If there is, then the first jump will be made, and if there is no second jump, it will be judged.
        if (includesAny(['4', '6'])) {
          nextIndex += 1
        } else {
          nextIndex += 2
        }
      } else {
        // If there is no skipping, it is necessary to judge whether it contains project order tasks, order tasks, and single task modes.
        //  If so, one jump will be performed, and if there is no skip, the second jump will be performed.
        if (includesAny(['1'])) {
          nextIndex += 1
        } else {
          nextIndex += 2
        }
      }
    }
    // 如果是项目，点击下一步判断有没有 项目订单任务模式 ，项目订单模式，或者项目任务模式
    //  If it is a project, click Next to determine whether there is a project order task mode, project order mode, or project task mode
    if (click_type == 'project') {
      if (includesAny(['1', '2'])) {
        // 如果是包含项目订单任务模式 和 项目订单模式则跳一层
        //  If it contains project order task mode and project order mode, skip one level.
        nextIndex += 1
      } else if (includesAny(['3'])) {
        //  如果有单订单模式需要在这个模式上加一个跳过的标志
        // If there is a single order mode, a skip flag needs to be added to this mode.
        if (includesAny(['5'])) {
          setAssignmentModas(
            assignment_modas.map(item => {
              if (item.type === 'order') {
                return { ...item, is_skipped: true }
              }
              return item
            })
          )
        }

        const CurrentIndex = assignment_modas.findIndex(item => {
          if (item.type === 'task') {
            return { ...item, is_skipped: true }
          }
          return item
        })
        // 如果是只包含项目任务模式则跳二层
        //  If it only contains project task mode, jump to the second level.
        nextIndex = CurrentIndex + 1
      } else {
        // Others jump out directly
        nextIndex += 3
      }
    }
    if (click_type == 'task') {
      nextIndex += 1
    }
    // 更新 active
    setActive(nextIndex)
  }

  // Function to skip the current step
  const handlePrevStepFunction = async () => {
    console.log('上一步')
    // 获取系统配置模式，如果不存在则跳转设置页面进行设置
    const assignmentModals = await getSystemAssignmentsFunction()
    if (!assignmentModals) {
      return false
    }
    // 计算下一步的索引
    let prevIndex = active

    // 辅助函数，用于检查 assignmentModals 是否包含指定模式
    const includesAny = (modes: string[]) => assignmentModals.split(',').some((item: string) => modes.includes(item))
    const is_skipped = assignment_modas[prevIndex - 1].is_skipped

    switch (active) {
      // 当前在order组件中
      case 1:
        // 说明模式没有project ,不能跳转
        if (!includesAny(['1', '2'])) {
          ClientWarningHint(word_translation.not_skip)
          return false
        }
        prevIndex -= 1
        const updatedAssignmentModas = [...assignment_modas]
        updatedAssignmentModas[prevIndex].is_skipped = false
        setAssignmentModas(updatedAssignmentModas)
        break
      // 当前在task组件中
      case 2:
        if (is_skipped) {
          if (!includesAny(['1', '3', '5', '4'])) {
            ClientWarningHint(word_translation.not_skip)
            break
          }
          const updatedAssignmentModas = [...assignment_modas]
          updatedAssignmentModas[prevIndex - 1].is_skipped = false
          setAssignmentModas(updatedAssignmentModas)
          prevIndex -= 1
        } else {
          if (includesAny(['1', '4'])) {
            prevIndex -= 1
            //  说明存在含有订单模式 ,不做处理
          } else if (includesAny(['3'])) {
            const updatedAssignmentModas = [...assignment_modas]
            updatedAssignmentModas[prevIndex].is_skipped = false
            setAssignmentModas(updatedAssignmentModas)
            prevIndex -= 1
          } else {
            ClientWarningHint(word_translation.not_skip)
          }
        }
        break

      case 0:
        // 在task页面点击下一步永远+1，不会跳过了 (下面没了)
        break

      default:
        break
    }
    //点击跳过时,当前的is_skipped一定是true
    const updatedAssignmentModas = [...assignment_modas]
    updatedAssignmentModas[active].is_skipped = false
    setAssignmentModas(updatedAssignmentModas)
    // 更新 active
    setActive(prevIndex)
  }

  // Function to skip the current step
  const skipStep = async () => {
    const includesAny = await getAssignmentModals()
    const click_type = assignment_modas[active].type

    let index = active + 1

    // 如果是Order ,则需要判定有没有order的 模式
    if (click_type == 'order') {
      // 直接点击订单,需要判断有没有订单>任务模式, 或者单个订单模式
      if (includesAny(['1', '4', '6'])) {
        setActive(index)
        const updatedAssignmentModas = [...assignment_modas]
        // updatedAssignmentModas[0].is_skipped = true;
        updatedAssignmentModas[index].is_skipped = false
        setAssignmentModas(updatedAssignmentModas)
      } else {
        ClientWarningHint(word_translation.not_skip)
      }
    } else if (click_type == 'task') {
      ClientWarningHint(word_translation.not_skip)
      return false
    } else if (click_type == 'project') {
      if (includesAny(['4', '5', '6'])) {
        setActive(index)
        const updatedAssignmentModas = [...assignment_modas]
        // updatedAssignmentModas[2].is_skipped = true;
        // updatedAssignmentModas[1].is_skipped = true;
        updatedAssignmentModas[index].is_skipped = false
        setAssignmentModas(updatedAssignmentModas)
      } else {
        ClientWarningHint(word_translation.not_skip)
        const updatedAssignmentModas = [...assignment_modas]
        setAssignmentModas(updatedAssignmentModas)
      }
    }

    // 把当前点击之前的 全部变成跳过 也就是:assignment_modas 中的 is_skip 为 true
    setAssignmentModas(prevAssignmentModas => {
      return prevAssignmentModas.map((assignmentModa, i) => {
        if (i < index) {
          return {
            ...assignmentModa,
            is_skipped: true
          }
        }
        return assignmentModa
      })
    })
    return false
  }

  const getAssignmentModals = async () => {
    // 获取系统配置模式，如果不存在则跳转设置页面进行设置
    const assignmentModals = await getSystemAssignmentsFunction()
    if (!assignmentModals) {
      ClientWarningHint(word_translation.no_assignments_moda_tips)
      // Return a function that always returns false if assignmentModals are not available
      return () => false
    }

    // 辅助函数，用于检查 assignmentModals 是否包含指定模式
    const includesAny = (modes: string[]) => assignmentModals.split(',').some((item: string) => modes.includes(item))

    // 返回 includesAny 函数
    return includesAny
  }

  // 直接点击切换图标按钮切换
  const clickDirectlyActive = async (index: number) => {
    const includesAny = await getAssignmentModals()
    const click_type = assignment_modas[index].type

    // 如果是Order ,则需要判定有没有order的 模式
    if (click_type == 'order') {
      // 直接点击订单,需要判断有没有订单>任务模式, 或者单个订单模式
      if (includesAny(['5', '4', '1', '2'])) {
        setActive(index)
        const updatedAssignmentModas = [...assignment_modas]
        // updatedAssignmentModas[0].is_skipped = true;
        updatedAssignmentModas[index].is_skipped = false
        setAssignmentModas(updatedAssignmentModas)
      } else {
        ClientWarningHint('No  mode jump')
      }
    } else if (click_type == 'task') {
      // 直接点击订单,需要判断有没有订单>任务模式, 或者单个订单模式
      if (includesAny(['6', '1', '3', '4'])) {
        setActive(index)
        const updatedAssignmentModas = [...assignment_modas]
        // updatedAssignmentModas[1].is_skipped = true;
        updatedAssignmentModas[index].is_skipped = false
        setAssignmentModas(updatedAssignmentModas)
      } else {
        ClientWarningHint('No  mode jump')
      }
    } else if (click_type == 'project') {
      if (includesAny(['1', '2', '3'])) {
        setActive(index)
        const updatedAssignmentModas = [...assignment_modas]
        // updatedAssignmentModas[2].is_skipped = true;
        // updatedAssignmentModas[1].is_skipped = true;
        updatedAssignmentModas[index].is_skipped = false
        setAssignmentModas(updatedAssignmentModas)
      } else {
        ClientWarningHint('No  mode jump')
      }
    }

    // 把当前点击之前的 全部变成跳过 也就是:assignment_modas 中的 is_skip 为 true
    setAssignmentModas(prevAssignmentModas => {
      return prevAssignmentModas.map((assignmentModa, i) => {
        if (i < index) {
          return {
            ...assignmentModa,
            is_skipped: true
          }
        }
        return assignmentModa
      })
    })
  }

  // Save Data
  const handleSubmitCurrentFormFunction = () => {
    const click_type = assignment_modas[active]?.type

    // TODO handle project’s form submit, use callback to submit state
    if (click_type === 'project') {
      // use ref call ProjectCreatedFormComponent to save data
      childRef.current?.projectSaveFormData?.()
    }

    if (click_type === 'order') {
      // use ref call ProjectCreatedFormComponent to save data
      childRef.current?.orderSaveFormData?.()
    }

    if (click_type === 'task') {
      // use ref call ProjectCreatedFormComponent to save data
      childRef.current?.taskSaveFormData?.()
    }
  }

  // Save Data
  const handleSaveAndNextFunction = () => {
    const click_type = assignment_modas[active].type
    let is_skip: undefined | string = ''
    // TODO : handel project’s form  submit,
    if (click_type == 'project') {
      // use ref call ProjectCreatedFormComponent to save data
      childRef.current?.projectSaveAndNextFormData?.()
    }

    if (click_type === 'order') {
      // use ref call ProjectCreatedFormComponent to save data
      console.log(childRef.current?.orderSaveAndNextFormData?.())
    }

    if (click_type === 'task') {
      // use ref call ProjectCreatedFormComponent to save data
      childRef.current?.taskSaveAndNextFormData?.()
    }
  }

  // Save Data
  const handleResetFunction = () => {
    const click_type = assignment_modas[active].type
    let is_skip: undefined | string = ''
    // TODO : handel project’s form  submit,
    if (click_type == 'project') {
      // use ref call ProjectCreatedFormComponent to save data
      childRef.current?.projectRest?.()
    }

    if (click_type === 'order') {
      // use ref call ProjectCreatedFormComponent to save data
      console.log(childRef.current?.orderRest?.())
    }

    if (click_type === 'task') {
      // use ref call ProjectCreatedFormComponent to save data
      childRef.current?.taskRest?.()
    }
  }
  return (
    <Paper px={20}>
      <PageTitle title={word_translation.create_assignment} />
      {/* <Box key={currentProjectId}> currentProjectId：    {currentProjectId}</Box>
       <Box key={currentProjectId}> currentOrderId    {currentOrderId}</Box> */}

      {/* Stepper component */}
      <Box style={{ height: '80vh' }}>
        <Grid columns={20} mt={10}>
          <Grid.Col span={20}>
            <Stepper active={active} onStepClick={clickDirectlyActive} breakpoint="sm">
              {/* Map over assignment steps and create Stepper.Step for each */}
              {assignment_modas.map((item, index) => (
                <Stepper.Step
                  key={index}
                  id="stepper-top"
                  radius={0}
                  className={item.is_skipped ? 'skipped' : ''}
                  progressIcon={<IconLoader size="1.4rem" />}
                  completedIcon={
                    item.is_skipped ? <IconArrowBigRightLine color="#ffffff" /> : <IconCheck size="1.4rem" />
                  }
                  label={<TableTextInputTitle py={0} pb={0} title={item.label} />}
                  icon={<IconX size="1.4rem" />}
                  description={<TableTextInputTitleDesc title={item.description} />}
                >
                  {item.view}
                </Stepper.Step>
              ))}

              {/* Completed step */}
              <Stepper.Completed>
                <Alert icon={<IconAlertCircle size="1rem" />} title={word_translation.no_data}>
                  Created, preview image is under development...
                </Alert>
              </Stepper.Completed>
            </Stepper>
            {/* Navigation buttons */}
          </Grid.Col>
          <Grid.Col span={6}></Grid.Col>
        </Grid>
      </Box>
      <Box>
        <Group position="right" mb={4}>
          {/* { (active > 0 && active < 3) &&    
                <Button variant="default" onClick={handlePrevStepFunction}>
                  Back
                </Button>}
             */}
          <ResetBtn callBack={handleResetFunction} />
          <SubmitBtn btn_size="xs" callBack={handleSubmitCurrentFormFunction} />
          &nbsp;&nbsp;&nbsp;&nbsp;
          {active < 2 && (
            <UniversalButton
              btn_size="xs"
              btn_icon={<IconArrowBigRightLines size={15} />}
              btn_text="Skip & Next"
              callBack={skipStep}
            />
          )}
          {active == assignment_modas.length - 1 ? (
            <UniversalButton
              btn_size="xs"
              btn_icon={<IconDeviceFloppy size={15} />}
              btn_text="Save & Finish"
              callBack={handleSaveAndNextFunction}
            />
          ) : (
            <UniversalButton
              btn_size="xs"
              btn_icon={<IconDeviceFloppy size={15} />}
              btn_text="Save & Next"
              callBack={handleSaveAndNextFunction}
            />
          )}
        </Group>
      </Box>
    </Paper>
  )
}
