import {
  ActionIcon,
  Paper,
  Flex,
  Select,
  Button,
  Code,
  Grid,
  Group,
  LoadingOverlay,
  Box,
  Menu,
  Modal,
  ScrollArea,
  Table,
  Text,
  ThemeIcon,
  Slider,
  Divider,
} from "@mantine/core";
import { useEffect, useState, FormEvent } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { HintInfo, getWeeKAll, getYearAll } from "../../../utils/function";
import { SelectRole, selectApiRespone } from "../../../interface/Icommon";
import {
  regGetFilter,
  regProject,
  regProjectList,
  regReportEmployeeReport,
  regReportUserAllProject,
  regUserAll,
} from "../../../api";
import { ResponseUserData } from "../../../interface/Iuser";
import { IconCheck, IconClipboardPlus, IconX } from "@tabler/icons-react";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { WeeklyTimeTrackerReports } from "../../../interface/Ireports";
import {
  IFilterStatistic,
  detailProps,
  filterReportData,
  userReportData,
} from "../../../interface/IfilterStatistic";
import { useDisclosure } from "@mantine/hooks";
import FilterItemReports from "./filterItemReport";
import { EmployeeReportWithProject } from "./statistics";
import { IProjectProps, ITaskProps } from "../../../interface/IReport";
import { EmployeeReportWithTask } from "./statisticsTaskOnly";
import PageTitle from "../../../ttcomponents/PageTitle/PageTitle";

const stats = [
  {
    value: 447,
    label: "Remaining",
  },
  {
    value: 76,
    label: "In progress",
  },
];

const itemTypeList = [
  {
    value: "project",
    label: "project",
  },
  {
    value: "order",
    label: "order",
  },
  {
    value: "task",
    label: "task",
  },
  {
    value: "task_only",
    label: "task_only",
  },
];

export default function UserReportStastic() {
  // Use the useIntl hook to access the intl object
  const intl = useIntl();

  //Get all the weekly collection
  const weekNumberAll = getWeeKAll();
  //Get all the Year collection
  const yearNumberAll = getYearAll();
  // Get all employees
  const [userlistnew, setUserList] = useState<SelectRole[]>([]);
  const [rpojectReportWithEmployeer, setRpojectReportWithEmployeer] = useState<
    IProjectProps[]
  >([]);
  const [reportTask, setReportTask] = useState<ITaskProps[]>([]);
  const [reportData, setReportData] = useState<userReportData>();
  // 选择过滤项
  const [filterItemList, setFilterItemList] = useState<SelectRole[]>([]);
  const [cowSpan, setCowSpan] = useState(0);
  // 设置过滤人员名单
  const [username, SetUserName] = useState("");
  const [filterUserList, setFilterUserList] = useState<SelectRole[]>([]);

  const [detailPropsData, setDetailPropsData] = useState<detailProps>({
    id: 0,
    start_week: "",
    end_week: "",
    type: "",
    year: "",
  });

  const [idNow, setIdNow] = useState(0);

  const [openedModal1, { open: openModal1, close: closeModal1 }] =
    useDisclosure(false);
  const [WeeklyTimeTracker, SetWeeklyTimeTracker] = useState<
    WeeklyTimeTrackerReports[]
  >([]);

  //Mid -load state
  const [loading, setLoading] = useState(false);
  // The data is initialized after the page is loaded
  const ajaxCurrentList = async () => {
    setLoading(true);
    //Get all the project lists
    const resFilter = await regGetFilter();
    const { data } = resFilter.data as IFilterStatistic;
    const itemFilterList = data.filter((item) => {
      return item.type === "1";
    });

    const users_filter = data.filter((item) => {
      return item.type === "2";
    });
    setFilterItemList(
      itemFilterList.map((item) => {
        return {
          label: item.filter_type + "---" + item.filter_name,
          value: item.id_filter,
        };
      })
    );

    setFilterUserList(
      users_filter.map((item) => {
        return {
          label: item.filter_name,
          value: item.id_filter,
        };
      })
    );

    // 获取user
    const responseUser = await regUserAll({});
    const resultUser = responseUser.data as ResponseUserData;
    const userData = resultUser.data.map((item) => ({
      value: item.id_user as string,
      label: item.username,
    }));
    setUserList(userData);

    setLoading(false);
  };
  useEffect(() => {
    ajaxCurrentList();
  }, []);
  // Form submission
  const form = useForm({
    initialValues: {
      year: "",
      user_id: "",
      start_week: "",
      end_week: "",
    },
    validate: {
      end_week: (value, values) => {
        if (parseInt(values.start_week) > parseInt(value)) {
          return "The end week must be greater than the beginning week";
        }
        return null;
      },
      start_week: (value) => {
        if (!value) {
          return "start week is required";
        }
        return null;
      },
    },
  });

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Stop the formal submission of the form default
    // Verification form
    const valid = await form.validate();

    if (!valid.hasErrors) {
      const selectedItem = userlistnew.find(
        (item) => item.value === form.values.user_id
      );
      console.log(selectedItem);
      SetUserName(selectedItem?.label as string);
      // const response = await
      const resFilterResponse = await regReportUserAllProject(
        form.values,
        "GET"
      );
      const result = resFilterResponse.data;
      if (HintInfo(result)) {
        setRpojectReportWithEmployeer(result.data.project_list);
        setReportTask(result.data.task_only_list);
      }
    }
  };

  // 查看详情
  const getDetail = (id: string) => {
    // // 获取project详情
    alert(`aaaaaaaaaaaaaaaaaaaaa  === ${id}`);
    // const url = `/project_item_reports?id_project=${id}`;
    // window.open(url, '_blank');
    // setIdNow(id)
    // openModal1()
  };

  return (
    <Paper px={20} mb={20} style={{ overflow: "auto", minHeight: "70vh" }}>
      <PageTitle title="EMPLOYEES REPORTS" lang_id="EMPLOYEES REPORTS" />
      <Box>
        <form onSubmit={handleFormSubmit}>
          <Group className="" mt={10} align="center">
            <Select
              placeholder={intl.formatMessage({
                id: "Select A Employee",
                defaultMessage: "Select A Employee",
              })}
              searchable
              required
              w={220}
              {...form.getInputProps("user_id")}
              data={userlistnew}
            />
            <Select
              placeholder={intl.formatMessage({
                id: "Choose Year",
                defaultMessage: "Choose Year",
              })}
              w={140}
              required
              {...form.getInputProps("year")}
              searchable
              data={yearNumberAll}
            />
            <Select
              placeholder={intl.formatMessage({
                id: "Start Week",
                defaultMessage: "Start Week",
              })}
              w={140}
              searchable
              required
              {...form.getInputProps("start_week")}
              data={weekNumberAll}
            />
            {intl.formatMessage({
              id: "To",
              defaultMessage: "To",
            })}
            <Select
              w={140}
              placeholder={intl.formatMessage({
                id: "End Week",
                defaultMessage: "End Week",
              })}
              searchable
              required
              {...form.getInputProps("end_week")}
              data={weekNumberAll}
            />
            <Button type="submit" leftIcon={<IconClipboardPlus />}>
              {intl.formatMessage({
                id: "Generate Reports",
                defaultMessage: "Generate Reports",
              })}
            </Button>
          </Group>
        </form>
      </Box>
      <Box mt={20}>
        {/* <Grid columns={7}>
                    {rpojectReportWithEmployeer.length > 0 ? rpojectReportWithEmployeer.map((item, index) => {
                        return (
                            <Grid.Col span={1}>
                                <EmployeeReportWithProject item={item} />
                            </Grid.Col>
                        )
                    }) : null}
                </Grid>
                */}
        {username &&
        form.values.year &&
        form.values.start_week &&
        form.values.end_week ? (
          <Box>
            {" "}
            <Text fw={600}>
              {" "}
              Report for ${username} : ${form.values.year} Start KW: $
              {form.values.start_week} End KW: ${form.values.end_week}
            </Text>
          </Box>
        ) : null}

        {rpojectReportWithEmployeer.length > 0 ? (
          <>
            <Divider
              my="xs"
              label="Project Statistics"
              labelPosition="center"
            />
            <Table
              maw={600}
              striped
              highlightOnHover
              withBorder
              withColumnBorders
            >
              <thead>
                <tr>
                  <th>Project Name</th>
                  <th>Hour Worked (h)</th>
                </tr>
              </thead>
              <tbody>
                {rpojectReportWithEmployeer.map((element, index) => {
                  return (
                    <tr key={index}>
                      <td>{element.name}</td>
                      <td>{element.project_actual_time}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </>
        ) : null}

        {reportTask.length > 0 ? (
          <Divider
            my="xs"
            label="Task Only Statistics"
            labelPosition="center"
          />
        ) : null}

        {rpojectReportWithEmployeer.length > 0 ? (
          <>
            <Table
              maw={600}
              striped
              highlightOnHover
              withBorder
              withColumnBorders
            >
              <thead>
                <tr>
                  <th>Taks Only Name</th>
                  <th> Hour Worked (h)</th>
                </tr>
              </thead>
              <tbody>
                {reportTask.map((element, index) => {
                  return (
                    <tr key={index}>
                      <td>{element.identifier}</td>
                      <td>{element.task_actual_time}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </>
        ) : (
          <Box>
            {" "}
            <Divider
              my="xs"
              label={<FormattedMessage id="No Data" defaultMessage="No Data" />}
              labelPosition="center"
            />
          </Box>
        )}
        <Modal
          radius="md"
          size={"80%"}
          opened={openedModal1}
          className="modal-setting"
          onClose={closeModal1}
          title={
            <FormattedMessage
              id="Report Detail"
              defaultMessage="Report Detail"
            />
          }
          overlayProps={{ opacity: 0.5, blur: 4 }}
        >
          <FilterItemReports detailPropsData={detailPropsData} />
        </Modal>
      </Box>
    </Paper>
  );
}
