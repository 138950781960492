import React, { useEffect, forwardRef, useState, useImperativeHandle } from 'react'
import {
  ActionIcon,
  Box,
  Code,
  Grid,
  Group,
  MultiSelect,
  Paper,
  Select,
  TextInput,
  Textarea,
  TransferListData
} from '@mantine/core'
import {
  IconCalendar,
  IconCircleCheck,
  IconCircleX,
  IconClockHour7,
  IconLicense,
  IconMoneybag,
  IconSubtask
} from '@tabler/icons-react'
import { hasLength, isNotEmpty, matches, useForm } from '@mantine/form'
import { LoadingOverlay } from '@mantine/core'
import WordTranslationComponent from '../../../../utils/language_pack/words'
import TableTextInputTitle from '../../../../ttcomponents/TableTextInputTitle/TableTextInputTitle'
import Message from '../../../../ttcomponents/Message/Message'
import { useDisclosure } from '@mantine/hooks'
import localstorageUnits from '../../../../utils/localstorageUnits'
import { DateInput } from '@mantine/dates'
import { SelectRole } from '../../../../interface/Icommon'
import { UserAllList } from '../../../../interface/Iuser'
import TransferListUserComponent from '../../../../ttcomponents/TransferListUserComponent/TransferListUserComponent'
import { timeRegex } from '../../../../utils/validataUnits'
import {
  ClientWarningHint,
  HintErrorMantineInfo,
  HintInfo,
  TimeHHSS,
  formatDateUpgraded,
  formatTimeHHSS
} from '../../../../utils/function'
import { requestProject } from '../../../../api/index_v2'
import ProjectPermissionsFrom from './permissions/project_permissions'
import { ProjectDataInterface } from '../../../../interface/Iprojects'
import ProjectCreateLeft from './project_create_left'
import UniversalButton from '../../../../ttcomponents/Button/UniversalButton'
import ProjectCreateRight from './project_create_left'
import {
  CorePermissonSetFormDataInterface,
  CorePermissonSetFormDataUpdateInterface
} from '../../../../interface/Iemloyee'
import { initialValuesCheckboxInterface } from './permissions/check_box'
import GlobalCoreSetting from '../../../../utils/globalCortSetting'
import ProductTask from './project_task'
import { getCostCenterSelfSelectFunction, getItemStatus } from '../../../../api/assignments_request'
import CheckPermissionTools from '../../../../utils/permission'

export interface ChildMethods {
  projectSaveFormData?: () => void
  projectSaveAndNextFormData?: () => void
  orderSaveAndNextFormData?: () => void
  orderSaveFormData?: () => void
  taskSaveFormData?: () => void
  projectRest?: () => void
  orderRest?: () => void
  taskRest?: () => void
  taskSaveAndNextFormData?: () => void
}

interface ComponentProps {
  c_allUserList: any
  c_userList: any
  customerList: any
  projectInfoFromEditPage?: ProjectDataInterface
  callBackFunction: (project_id: string, value: any) => void
}
const ProjectCreate = forwardRef<ChildMethods, ComponentProps>(
  ({ callBackFunction, projectInfoFromEditPage, c_allUserList, c_userList, customerList }, ref) => {
    // Get translation public configuration information
const Perm = CheckPermissionTools();
    const word_translation = WordTranslationComponent()
    // Use useImperativeHandle to throw child component methods to parent components
    useImperativeHandle(ref, () => ({ projectSaveFormData, projectSaveAndNextFormData, projectRest }))

    const [openPermissionStatus, { open: openPermissionModalFunction, close: closePermissionModalFunction }] =
      useDisclosure(false)
    const [openProjectTaskStatus, { open: openProjectTaskModalFunction, close: closeProjectTaskModalFunction }] =
      useDisclosure(false)
    // Define all the customer lists that can be selected
    const [currentPageCustomerList, setCustomerList] = useState<SelectRole[]>([])
    // Define all the list of employees you can choose
    const [userlist, setUserList] = useState<SelectRole[]>([])
    const [projectStatusList, setProjectStatusList] = useState<SelectRole[]>([])
    // Define the user list (including the user's detailed information)
    const [UserList, setResultUser] = useState<UserAllList[]>([])
    const [userLeader, setUserLeader] = useState<UserAllList[]>([])

    // Get the global configuration
    const globalCoreSetting = GlobalCoreSetting()

    const [resetUser, setresetUser] = useState(false)
    // Get the initial time of the system
    const initSystemTime = localstorageUnits.getSystem().init_use_date
    // State to control the visibility of the message
    const [showMessage, setShowMessage] = useState(false)
    const [haveUserList, setHaveUserIds] = useState<string[]>([])

    // State to store the message content
    const [showMessageStr, setShowMessageStr] = useState('')

    const [permissonSetFormData, setPermissonSetFormData] = useState<CorePermissonSetFormDataInterface[]>([])
    const [permissonCurrentSetFormData, setCurrentPermissonSetFormData] = useState<
      CorePermissonSetFormDataUpdateInterface[]
    >([])
    // Use LoadingOverlay
    const [loading, setLoading] = useState(false)

    const [randNumber, setRandNumber] = useState(0)
    // 初始化提交表单 Initialization Submitting Form
    const form = useForm({
      validateInputOnBlur: true,
      initialValues: {
        id: '',
        project_no: '',
        customer_id: '',
        status_id: '1',
        name: '',
        estimated_start_date: new Date(),
        estimated_end_date: null as Date | null,
        info: '',
        target_time: '00:00',
        budget: '0.00',
        estimated_increase_time: '00:00',
        user_id: [],
        costCenter_id: '',
        project_employee_roles: []
      },
      validate: {
        name: hasLength(
          { min: 2, max: 100 },
          `${word_translation.street}${word_translation.validata_value_need_2_100_length}`
        ),
        // project_no: hasLength({ min: 2, max: 100 }, `${word_translation.project_no}${word_translation.validata_value_need_2_100_length}`),
        estimated_start_date: isNotEmpty(`${word_translation.start_time}${word_translation.validata_date}`),
        target_time: matches(timeRegex, `${word_translation.target_time}${word_translation.validata_time}`)
        // estimated_end_date: isNotEmpty(`${word_translation.target_time}${word_translation.is_not_empty}`),
      }
    })

    // 获取状态
    const getStatus = async () => {
      setProjectStatusList(await getItemStatus({ active: 1 }, 'select'))
    }

    useEffect(() => {
      getCostCenter();
      getStatus()
    }, [])

    useEffect(() => {
      setCustomerList(customerList)
    }, [customerList])

    useEffect(() => {
      if (projectInfoFromEditPage) {
        form.setFieldValue('id', projectInfoFromEditPage.id_project)
        form.setFieldValue('project_no', projectInfoFromEditPage.project_no)
        form.setFieldValue('customer_id', projectInfoFromEditPage.customer_id)
        form.setFieldValue('status_id', projectInfoFromEditPage.status_id)
        form.setFieldValue('estimated_increase_time', projectInfoFromEditPage.estimated_increase_time as string)
        form.setFieldValue('name', projectInfoFromEditPage.name)
        form.setFieldValue('estimated_start_date', new Date(projectInfoFromEditPage.estimated_start_date))
        form.setFieldValue(
          'estimated_end_date',
          projectInfoFromEditPage.estimated_end_date
            ? (new Date(projectInfoFromEditPage.estimated_end_date) as Date)
            : (null as null)
        )

        form.setFieldValue('info', projectInfoFromEditPage.info)
        form.setFieldValue('target_time', projectInfoFromEditPage.target_time)
        form.setFieldValue('budget', projectInfoFromEditPage.budget)
        form.setFieldValue('costCenter_id', projectInfoFromEditPage.costCenter_id)
        //  TODO : Split user_id into int array form
        // The reason is that the component that selects the leader only accepts inter type.
        const user_id = projectInfoFromEditPage?.user_id

        const user_id_array = user_id ? user_id.split(',').map(item => Number(item)) : []

        form.setFieldValue('user_id', user_id_array as [])

        // TODO : Add employee information to the form
        const project_employee_roles = projectInfoFromEditPage?.project_employee_roles
        const currentProjectEmployee = project_employee_roles?.map(item => {
          return {
            user_id: item.id_user
          }
        })
        form.setFieldValue('project_employee_roles', currentProjectEmployee as [])

        // TODO : Populate default value in TransferListUserComponent component
        setHaveUserIds(
          project_employee_roles?.map(item => {
            return item.id_user?.toString()
          }) as string[]
        )

        setRandNumber(randNumber + 1)
      }
    }, [projectInfoFromEditPage])

    // Select Employees to Join the Project <FUNCTION>
    const selectUserToProject = (value: TransferListData) => {
      form.setFieldValue(
        'project_employee_roles',
        value[1].map(item => {
          return {
            user_id: item.value
          }
        }) as []
      )
    }

    //Correct time format <FUNCTION>
    const handleTimeInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      const value = TimeHHSS(event)
      if (timeRegex.test(value) == false) {
        ClientWarningHint(word_translation.validata_time)
        return false
      }
      console.log(event)
      // form.setFieldError('target_time', 'Invalid email');
      form.setFieldValue(event.target.name, value)
    }

    useEffect(() => {
      // Monitor changes in c_allUserList and c_userList passed by the parent component
      setUserList(c_userList)
      setResultUser(c_allUserList)
    }, [c_allUserList, c_userList])

    const projectSaveFormData = async () => {
      const product_id = await FormSubmitFunction('save')
    }
    const projectRest = () => {
      form.reset()
    }

    const projectSaveAndNextFormData = async () => {
      const product_id = await FormSubmitFunction('save')
      callBackFunction(product_id, '')
      // callBackFunction('8')
    }

    // The CreateAssignments component calls the method of the ProjectCreate component to save the project data. <FUNCTION>
    const FormSubmitFunction = async (type = 'save') => {
      // Validate the form
      const valid = await form.validate()
      //  If there are validation errors, display error information
      if (valid.hasErrors) {
        HintErrorMantineInfo(valid)
        return false
      }

      // TODO:Determine whether the employee exists
      if (form.values.project_employee_roles.length === 0) {
        // HintErrorMantineInfo(word_translation.vacation_select_employee);
        // return false;
      }

      // TODO:Convert time into data received by the backend

      const submitFormValues = {
        ...form.values,
        all_permission: permissonSetFormData,
        current_permission: permissonCurrentSetFormData,
        estimated_start_date: formatDateUpgraded(form.values.estimated_start_date as Date, 'YYYY-MM-DD'),
        estimated_end_date: formatDateUpgraded(form.values.estimated_end_date as Date, 'YYYY-MM-DD')
      }

      const response = await requestProject(submitFormValues, form.values.id ? 'PUT' : 'POST')
      HintInfo(response.data)
      if (response.data.code == 200) {
        if (type != 'save') {
          form.reset()
        }
        if (response.data.data.id) {
          return response.data.data.id
        }
        return ''
      } else {
        // -1  Indicates that the save failed and the next step is not allowed to jump.
        return false
      }
    }

    const editFunction = async () => {
      const editResult = await FormSubmitFunction()
      if (editResult !== false) {
        callBackFunction(form.values.id, '')
        return false
      }
      callBackFunction('-1', '')
    }

    const refreshProject = () => {
      callBackFunction(form.values.id, 'project_task')
    }

    const callBackPermissionFunction = (value: initialValuesCheckboxInterface[], type: string) => {
      // Permissions
      value.map((item, index) => {
        if (item.checked === true) {
          setPermissonSetFormData(prevPermissions => [
            ...prevPermissions,
            {
              permission_ids: item.id_permission,
              permission_identifier_id: item.id_permission_identifier
            }
          ])
        } else {
          setPermissonSetFormData(prevPermissions =>
            prevPermissions.filter(
              permission =>
                permission.permission_ids !== item.id_permission ||
                permission.permission_identifier_id !== item.id_permission_identifier
            )
          )
        }
      })

      refreshProject()
    }

    const callBackCurrentPermissionFunction = (permission_ids: string[], id_permission_identifier: string) => {
      // Check if id_permission_identifier is missing, return false
      if (!id_permission_identifier) {
        return false
      }

      // Determine whether there is data related to permission_identifier_id that currently exists in the array.
      const existingPermissionIndex = permissonCurrentSetFormData.findIndex(
        permission => permission.permission_identifier_id.toString() === id_permission_identifier.toString()
      )

      if (existingPermissionIndex == -1) {
        setCurrentPermissonSetFormData(prevPermissions => [
          ...prevPermissions,
          {
            permission_ids: permission_ids,
            permission_identifier_id: id_permission_identifier
          }
        ])
      } else {
        // 将索引里面的值替换为新值
        setCurrentPermissonSetFormData(prevPermissions => {
          const updatedPermissions = [...prevPermissions]
          updatedPermissions[existingPermissionIndex] = {
            permission_ids: permission_ids,
            permission_identifier_id: id_permission_identifier
          }
          return updatedPermissions
        })
      }
    }

    
    const [costCenterData, setCostCenterData] = useState<SelectRole[]>([])
    // getDatas
    const getCostCenter = async () => {
      // Call the Get Cost Center General Selection function and set the cost center data
      let costCenterReuslt = await getCostCenterSelfSelectFunction({permission:true});
      setCostCenterData(costCenterReuslt)
    }

    // Open the leadership choice permissions box <FUNCTION>
    const openPermissionModal = () => {
      // If there is no selection of the leader, the prompt is made, the non -warrior permissions selection
      if (form.values.user_id.length == 0) {
        ClientWarningHint('Please select Leader users first')
        return false
      }
      // Find corresponding user information for selected user_ids in the UserList array.

      const selectedUsers = form.values.user_id
        .map(userId => {
          const user = UserList.find(u => u.id_user === userId)
          return user ? user : null
        })
        .filter(Boolean)

      setUserLeader(selectedUsers as UserAllList[])
      openPermissionModalFunction()
    }

    // Open the leadership choice permissions box <FUNCTION>
    const openProjectTaskModal = () => {
      openProjectTaskModalFunction()
    }

    return (
      <Paper px={12}>
        <LoadingOverlay visible={loading} overlayBlur={2} />
        <Box px={40} pt={24}>
          <Grid gutter={8} columns={24} justify={'flex-start'} align={'flex-start'}>
            <Grid.Col span={10}>
              <TableTextInputTitle py={5} pb={5} title={word_translation.project_name} />
              <TextInput
                size="xs"
                placeholder={word_translation.project_name}
                defaultValue={form.values.name}
                onChange={event => {
                  form.setFieldValue('name', event.target.value)
                }}
              />
            </Grid.Col>
            <Grid.Col span={2}></Grid.Col>
            <Grid.Col span={12}>
              <TableTextInputTitle py={5} pb={5} title={word_translation.customer} />
              <Select
                placeholder={word_translation.pick_one}
                searchable
                size="xs"
                nothingFound={word_translation.no_data}
                key={form.values.customer_id}
                defaultValue={form.values.customer_id.toString()}
                onChange={value => form.setFieldValue('customer_id', value as string)}
                data={currentPageCustomerList}
              />
            </Grid.Col>

            <Grid.Col span={10}>
              <TableTextInputTitle py={5} pb={5} title={word_translation.project_no} />
              <TextInput
                size="xs"
                placeholder={word_translation.project_no}
                defaultValue={form.values.project_no}
                {...form.getInputProps('project_no')}
              />
            </Grid.Col>
            <Grid.Col span={2}></Grid.Col>
            <Grid.Col span={6}>
              <TableTextInputTitle
                icon_l={<IconMoneybag size={16} />}
                py={5}
                pb={5}
                title={word_translation.budget}
                style={{ gap: '0.4rem' }}
              />
              <TextInput size="xs" placeholder={word_translation.Input} {...form.getInputProps('budget')} />
            </Grid.Col>
            <Grid.Col span={6}>
              <TableTextInputTitle
                icon_l={<IconClockHour7 size={16} />}
                py={5}
                pb={5}
                title={word_translation.target_time}
                style={{ gap: '0.4rem' }}
              />
              <TextInput
                onBlur={handleTimeInputBlur}
                size="xs"
                disabled={form.values.id ? true : false}
                placeholder={word_translation.Input}
                name="target_time"
                value={formatTimeHHSS(form.values.target_time)}
                onChange={event => form.setFieldValue('target_time', event.target.value)}
              />

            </Grid.Col>
            <Grid.Col span={10}>
              <TableTextInputTitle py={5} pb={5} title={word_translation.project_leader} />
              <MultiSelect
                size="xs"
                placeholder={word_translation.project_leader}
                searchable
                key={randNumber}
                defaultValue={form.values.user_id}
                rightSection={
                  form.values.id ? (
                    <> </>
                  ) : (
                    <ActionIcon onClick={() => openPermissionModal()}>
                      {' '}
                      <IconLicense color="red" />
                    </ActionIcon>
                  )
                }
                data={userlist}
                onChange={value => form.setFieldValue('user_id', value as [])}
              />
            </Grid.Col>
            <Grid.Col span={2}>
            </Grid.Col>
            <Grid.Col span={6}>
              <TableTextInputTitle py={5} pb={5} title={word_translation.start_time} />
              <DateInput
                valueFormat="DD/MM/YYYY"
                minDate={new Date(initSystemTime)}
                defaultValue={new Date()}
                required
                size="xs"
                icon={<IconCalendar size="1.1rem" stroke={1.5} />}
                {...form.getInputProps('estimated_start_date')}
                // onBlur={(event) => checkTime(event.target.value)}
                placeholder={word_translation.start_time}
              />
            </Grid.Col>
            {form.values.id && (
              <Grid.Col span={6}>
                <TableTextInputTitle
                  multiline={true}
                  tooltip={word_translation.add_estimnated_tooltip}
                  py={5}
                  pb={5}
                  title={word_translation.add_estimnated}
                />
                <TextInput
                  onBlur={handleTimeInputBlur}
                  size="xs"
                  placeholder={word_translation.Input}
                  name="estimated_increase_time"
                  value={formatTimeHHSS(form.values.estimated_increase_time)}
                  onChange={event => form.setFieldValue('estimated_increase_time', event.target.value)}
                />
              </Grid.Col>
            )}

            <Grid.Col span={10}>
              <TableTextInputTitle py={5} pb={5} title={word_translation.info} />
              <Textarea size="xs" placeholder={word_translation.info} {...form.getInputProps('info')} />
            </Grid.Col>
            
            <Grid.Col span={2}></Grid.Col>
            <Grid.Col span={6}>
              <TableTextInputTitle py={5} pb={5} title={word_translation.end_time} />
              <DateInput
                valueFormat="DD/MM/YYYY"
                minDate={form.values.estimated_start_date}
                required
                size="xs"
                icon={<IconCalendar size="1.1rem" stroke={1.5} />}
                {...form.getInputProps('estimated_end_date')}
                placeholder={word_translation.end_time}
              />
            </Grid.Col>
            {form.values.id && (
              <Grid.Col span={6}>
                <TableTextInputTitle py={5} pb={5} title={word_translation.status} />
                <Select
                  placeholder={word_translation.pick_one}
                  nothingFound={word_translation.no_data}
                  key={form.values.status_id}
                  size="xs"
                  defaultValue={form.values.status_id.toString()}
                  onChange={value => form.setFieldValue('status_id', value as string)}
                  data={projectStatusList}
                />
              </Grid.Col>
            )}
          </Grid>
          
          {(Perm.cost_center_select || Perm.admin )&&
           <Grid>
           <Grid.Col span={5}>
         <TableTextInputTitle
             icon_l={<IconClockHour7 size={16} />}
             py={5}
             pb={5}
             title={word_translation.select_cost_center}
             style={{ gap: '0.4rem' }}
           />
           <Select
           labelProps={{ className: 'input_title' }}
           placeholder={word_translation.cost_center}
           searchable
           size="xs"
           nothingFound={word_translation.no_data}
           key={form.values.costCenter_id}
           defaultValue={form.values.costCenter_id.toString()}
           onChange={value => form.setFieldValue('costCenter_id', value as string)}
           data={costCenterData}
         />
 
 </Grid.Col>
           </Grid>
          }
         

          <Grid gutter={0} mt={4}>
            <Grid.Col span={12} pb={20}>
              <Group position="apart">
                <TableTextInputTitle py={5} pb={5} title={word_translation.add_user} />
                <TableTextInputTitle
                  py={5}
                  pb={5}
                  title={word_translation.users_counts + ' : ' + form.values.project_employee_roles.length}
                />
              </Group>
              <TransferListUserComponent
                resetUser={resetUser}
                height={resetUser}
                listHeight={280}
                key={randNumber}
                targetUserIdsArr={haveUserList}
                callBack={selectUserToProject}
                userlist={UserList}
              />

              <TableTextInputTitle
                icon_l={<IconClockHour7 size={16} />}
                py={5}
                pb={5}
                title={word_translation.select_cost_center}
                style={{ gap: '0.4rem' }}
              />
              <TextInput
                onBlur={handleTimeInputBlur}
                size="xs"
                disabled={form.values.id ? true : false}
                placeholder={word_translation.Input}
                name="target_time"
                value={formatTimeHHSS(form.values.target_time)}
                onChange={event => form.setFieldValue('target_time', event.target.value)}
              />

            </Grid.Col>
          </Grid>
        </Box>

        {/* only dispaly in edit project */}
        {form.values.id && (
          <Group position="apart" align="flex-end">
            <Group position="right" mt={5}>
              <Box>
                <Box>
                  <UniversalButton
                    btn_bg_color="rgb(253, 126, 20)"
                    btn_icon={<IconSubtask size={16} />}
                    btn_text={word_translation.edit_task}
                    btn_size="xs"
                    callBack={() => openProjectTaskModal()}
                  />
                </Box>
                <Box mt={4}>
                  <UniversalButton
                    btn_bg_color="rgb(253, 126, 20)"
                    btn_icon={<IconLicense size={16} />}
                    btn_text="Edit Project Leader Permission"
                    btn_size="xs"
                    callBack={() => openPermissionModal()}
                  />
                </Box>
              </Box>
            </Group>
            <Group position="right" mt={5}>
              <UniversalButton
                btn_bg_color="#fa5252"
                btn_icon={<IconCircleX size={16} />}
                btn_text={word_translation.cancel}
                btn_size="xs"
                callBack={() => callBackFunction('close', '')}
              />
              <UniversalButton
                btn_icon={<IconCircleCheck size={16} />}
                btn_text={word_translation.save}
                btn_size="xs"
                callBack={() => editFunction()}
              />
            </Group>
          </Group>
        )}

        {showMessage && <Message content={showMessageStr} duration={3000} color="green" />}

        <ProjectPermissionsFrom
          is_load={false}
          item_name={form.values.name}
          userLeader={userLeader}
          objectsUsed={word_translation.task}
          callBackPermissionCheckBox={callBackPermissionFunction}
          callBackCurrentPermissionCheckBox={callBackCurrentPermissionFunction}
          closeFunction={closePermissionModalFunction}
          openFormStatus={openPermissionStatus}
        />

        {openProjectTaskStatus && (
          <ProductTask
            is_load={false}
            refresh={refreshProject}
            userLeader={userLeader}
            projectId={form.values.id}
            customer_id={form.values.customer_id}
            projectTargetTime={form.values.target_time}
            projectBudget={form.values.budget}
            projectName={form.values.name}
            closeFunction={closeProjectTaskModalFunction}
            openFormStatus={openProjectTaskStatus}
          />
        )}
      </Paper>
    )
  }
)

export default ProjectCreate
