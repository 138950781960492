import {
  Avatar,
  Badge,
  Table,
  Group,
  Text,
  ActionIcon,
  Anchor,
  ScrollArea,
  useMantineTheme,
  Modal,
  Slider,
  Tooltip,
} from "@mantine/core";
import {
  ITask,
  ITaskGeResponseNopage,
  TaskInterFace,
} from "../../../interface/Itask";
import {
  IconCheck,
  IconHeart,
  IconPencil,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import { useState } from "react";
import { useDisclosure } from "@mantine/hooks";
import TaskEditFrom from "./task_edit_from";
import { modals } from "@mantine/modals";
import { regGeOrderTask, regOrderTask, regTaskCategory } from "../../../api";
import { notifications } from "@mantine/notifications";
import { IOrderProps } from "../../../interface/Iorder";
import memoryUtils from "../../../utils/memoryUtils";
import { SelectRole } from "../../../interface/Icommon";
import { formatDate } from "../../../utils/function";
import localstorageUnits from "../../../utils/localstorageUnits";
import { useIntl } from "react-intl";

interface ITaskFromProps {
  callBack: (orderItem: IOrderProps) => void;
  taskList: TaskInterFace[];
  refreashTable: (orderid: string) => void;
  [key: string]: any;
}

export default function TaskTable({
  callBack,
  refreashTable,
  orderItem,
  taskList,
  employeesList,
  selelctOrderLIst,
  UserLists,
  userlist,
}: ITaskFromProps) {
  // Use the useIntl hook to access the intl object
  const intl = useIntl();

  // 定义打开task的开关
  const [TaskModalStatus, { open: openModal1, close: closeModal1 }] =
    useDisclosure(false);

  // get Task Mode Flag
  const taskModeFlag = localstorageUnits.getSystem().task_mode_flag;
  // 初始化userID
  const [userId, setUseId] = useState(memoryUtils.user.id_user);

  //定义修改的tasl承接变量
  const [TaskItem, setTaskItem] = useState<TaskInterFace>();

  // 定义任务类型列表
  const [taskcateGoryListData, setTaskcateGoryListData] = useState<
    SelectRole[]
  >([]);

  // 打开修改task页面
  const editTask = async (item: TaskInterFace) => {
    // 获取任务类型
    const cateGoryInfo = await regTaskCategory({});
    if (cateGoryInfo.data.code == 200) {
      const taskcateGoryData = cateGoryInfo.data.data.data.map(
        (item: { id_task_category: string; identifier: any }) => ({
          value: item.id_task_category as string,
          label: item.identifier,
        })
      );
      setTaskcateGoryListData(taskcateGoryData);
    }
    // 获取这个task的详细信息、雇员等情况
    const TaskresponseUser = await regGeOrderTask(item.id_task);
    const resultTask = TaskresponseUser.data;
    setTaskItem(resultTask.data);
    openModal1();
  };

  //  定义修改表单后的回调函数
  const callBackhandle = (order: string) => {
    refreashTable(order);
    closeModal1();
  };
  // 删除task
  const delHandle = (item: TaskInterFace) => {
    modals.openConfirmModal({
      title: intl.formatMessage({
        id: "delete_title",
        defaultMessage: "Delete Confirmation",
      }),
      centered: true,
      children: <Text size="sm">moduleName: {item.free_description}</Text>,
      labels: {
        confirm: intl.formatMessage({
          id: "confirm_delete",
          defaultMessage: "Confirm Delete",
        }),
        cancel: intl.formatMessage({
          id: "cancel_delete",
          defaultMessage: "Cancel Delete",
        }),
      },
      confirmProps: { color: "red" },
      onConfirm: async () => {
        const FormData = {
          id: item.id_task,
        };
        const response = await regOrderTask(FormData, "DELETE");
        const result = response.data;
        if (result.code === 200) {
          callBack(orderItem);
          notifications.show({
            color: "green",
            icon: <IconCheck />,
            message: result.msg,
          });
        } else {
          notifications.show({
            color: "red",
            icon: <IconX />,
            message: result.msg,
          });
        }
      },
    });
  };
  // 循环出Task列表 ''
  const rows =
    taskList.length > 0 ? (
      taskList.map((item, index) => (
        <tr key={index} className="tr">
          <td style={{ border: "none" }}>
            <Group spacing="sm">{item.identifier && item.identifier}</Group>
          </td>
          <td>
            <Group spacing="sm">
              <div>
                <Group position="apart" mt="md">
                  <Avatar.Group spacing="sm">
                    {item.leader_detail.map(
                      (item_leader: any, item_leader_index: any) => (
                        <>
                          <Tooltip label={item_leader.username}>
                            <Avatar
                              key={item_leader_index + item_leader.avatar}
                              src={item_leader.avatar}
                              radius={40}
                              size={26}
                            />
                          </Tooltip>
                          <br />
                        </>
                      )
                    )}
                  </Avatar.Group>
                </Group>
                <Text c="dimmed" fz="xs"></Text>
              </div>
            </Group>
          </td>
          <td> {formatDate(item.start_date)} </td>
          <td> {item.end_date ? formatDate(item.end_date) : ""} </td>
          <td>{item.time_estimate}</td>
          <td>{item.time_consumption} </td>
          <td>{item.budget} </td>
          <td>{item.cost}</td>
          <td>
            <Avatar.Group spacing="sm">
              {item.employee_user.map(
                (item_leader: any, item_leader_index: any) => (
                  <Tooltip label={item_leader.username}>
                    <Avatar
                      key={item_leader_index + item_leader.avatar}
                      src={item_leader.avatar}
                      radius={40}
                      size={26}
                    />
                  </Tooltip>
                )
              )}
            </Avatar.Group>
          </td>
          {/* <td>
        
      {item.timevisual as any < 100 ? 
      
      <Slider 
      value={item.timevisual}    w={60} color='green'  label={(value) => `${item.timevisual} %`} />
       :
       <Slider  
      value={item.timevisual - 100 }    w={60} color='red'  label={(value) => `${item.timevisual-100} %` }/> }
      </td> */}
          <td>
            <Group spacing={0} position="left">
              {(item.task_leader_id &&
                item.task_leader_id.split(",").includes(userId)) ||
              userId == "4" ||
              (item.order_leader_ids &&
                item.order_leader_ids.split(",").includes(userId)) ||
              (item.project_leader_ids &&
                item.project_leader_ids.split(",").includes(userId)) ? (
                <>
                  <ActionIcon onClick={() => editTask(item)}>
                    <IconPencil size="1rem" stroke={1.5} />
                  </ActionIcon>
                  {userId == "4" ||
                  (item.order_leader_ids &&
                    item.order_leader_ids.split(",").includes(userId)) ||
                  (item.project_leader_ids &&
                    item.project_leader_ids.split(",").includes(userId)) ? (
                    <ActionIcon color="red" onClick={() => delHandle(item)}>
                      <IconTrash size="1rem" stroke={1.5} />{" "}
                    </ActionIcon>
                  ) : null}
                </>
              ) : null}
            </Group>
          </td>
        </tr>
      ))
    ) : (
      <tr>
        {" "}
        <td colSpan={7}> currently no task...</td>
      </tr>
    );
  return (
    <ScrollArea>
      <Table sx={{ minWidth: 800 }} verticalSpacing="sm">
        <thead>
          <tr>
            <th>
              {intl.formatMessage({
                id: "task",
                defaultMessage: "Task",
              })}
            </th>
            <th>
              {intl.formatMessage({
                id: "taskLeader",
                defaultMessage: "Task Leader",
              })}
            </th>
            <th>
              {intl.formatMessage({
                id: "startTime",
                defaultMessage: "Start Time",
              })}
            </th>
            <th>
              {intl.formatMessage({
                id: "endTime",
                defaultMessage: "End Time",
              })}
            </th>
            <th>
              {intl.formatMessage({
                id: "estimatedTime",
                defaultMessage: "Estimated time",
              })}
            </th>
            <th>
              {intl.formatMessage({
                id: "consumptionTime",
                defaultMessage: "Consumption time",
              })}
            </th>
            <th>
              {intl.formatMessage({
                id: "cost",
                defaultMessage: "Cost",
              })}
            </th>
            <th>
              {intl.formatMessage({
                id: "budget",
                defaultMessage: "Budget",
              })}
            </th>
            <th>
              {intl.formatMessage({
                id: "taskMembers",
                defaultMessage: "Task Members",
              })}
            </th>
            <th>
              {intl.formatMessage({
                id: "Option",
                defaultMessage: "Option",
              })}
            </th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
      <Modal
        size={taskModeFlag == 2 ? "md" : "70%"}
        opened={TaskModalStatus}
        onClose={closeModal1}
        title="Edit Task"
      >
        <TaskEditFrom
          taskcateGoryListData={taskcateGoryListData}
          taskItem={TaskItem}
          orderItem={orderItem}
          callBack={callBackhandle}
          employeesList={employeesList}
          userlist={userlist}
          UserLists={UserLists}
        />
      </Modal>
    </ScrollArea>
  );
}
