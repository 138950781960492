import {
  Paper,
  TextInput,
  Box,
  Group,
  Text,
  Modal,
  Grid,
  Table,
  ActionIcon,
  LoadingOverlay,
  Tooltip,
  Flex,
  Badge,
  Dialog,
  Button,
  Textarea,
} from "@mantine/core";
import { FormEvent, useEffect, useState } from "react";
import { Supplier, SuppliersData } from "../../../../interface/Iinventory";
import { useDisclosure } from "@mantine/hooks";

import PageTitle from "../../../../ttcomponents/PageTitle/PageTitle";
import {
  IconArrowBarUp,
  IconCircleKey,
  IconEdit,
  IconLockSquareRounded,
  IconPencil,
  IconRefresh,
  IconSearch,
  IconShoppingBag,
  IconTir,
  IconTrash,
  IconUserPlus,
  IconUsersMinus,
  IconX,
} from "@tabler/icons-react";
import CreateBtn from "../../../../ttcomponents/Button/CreateBtn";
import { useIntl } from "react-intl";
import { useForm } from "@mantine/form";
import { modals } from "@mantine/modals";
import {
  regGetOrderProductList,
  regLockedProduct,
  regProductPurchase,
  regRefreshOrderProduct,
} from "../../../../api";
import { HintInfo, formatDateUpgraded } from "../../../../utils/function";
import SearchBox from "../../../../ttcomponents/SearchBox/SearchBox";
import { IPagination } from "../../../../interface/Ipagintion";
import PaginationA from "../../../../ttcomponents/Pagination/PaginationA";
import { IProductResponse, Product } from "../../../../interface/IProduct";
import { DetailCustome } from "../../../../public/svg/DetailCustome";
import SupplierSelect from "../../../../ttcomponents/Supplier/SupplierSelect";
import { ICategory } from "../../../../interface/IProductCategory";
import {
  Cancellation,
  Purchase,
  Receipt,
} from "../../../../public/svg/ProductOrder";
import {
  IProductProcurementData,
  IProductProcurementOrderData,
} from "../../../../interface/IProductProcurement";
import { notifications } from "@mantine/notifications";
import { IOrderProduct, OrderProductData } from "../../../../interface/Iorder";
import ProductProcurementFrom from "../../purchasing/purchasing_from";
import SaleFrom from "../../out_of_warehouse/sale_out/sale_form";

export default function ProductRequirmentsIndex() {
  // useIntl for multiple language
  const intl = useIntl();
  // Edit modal status
  const [openedFrom, { open: openFrom, close: closeFrom }] =
    useDisclosure(false);

  // return the goods
  const [saleOpened, { toggle: openSaleOpened, close: closeSaleOpened }] =
    useDisclosure(false);

  // contact modal status
  const [
    ProductProcurementShow,
    { open: openProductProcurementShow, close: colseProductProcurementShow },
  ] = useDisclosure(false);

  const [loading, setLoading] = useState(false);
  const [rowloading, setRowloading] = useState(false);
  // Form type
  const [formType, setFormType] = useState("create");
  const [currentRowInfo, setCurrentRowInfo] = useState<
    IOrderProduct | undefined
  >();
  const [productCategory, setProductCategory] = useState<ICategory[]>([]);
  const [returnNotes, setReturnNotes] = useState("");

  // define the supplier list
  const [orderProductList, setProductProcurementList] =
    useState<OrderProductData>({
      total: 0,
      data: [], // Use the data property from the first item in the mock data array
    });

  const [pagination, setPagination] = useState<IPagination>({
    search: "",
    page: 1,
    pagesize: 10,
    sortBy: "",
    sortOrder: "asc",
    query: {
      table: {
        name: "order",
      },
      delivery_status: "@@@_in_query1,2",
      // is_group:'product_id'
    },
  });
  // Receive the callback return data, search for content
  const ReceiveSearchBack = (value: string | number) => {
    setPagination((prevpagination) => ({
      ...prevpagination,
      search: value,
      page: 0,
    }));
  };
  const ReceiveSupplierBack = (value: any) => {
    if (value.type == "supplier") {
      setPagination((prevpagination) => {
        const { query, ...rest } = prevpagination; // Extract the attributes other than Query to the REST
        if (value.value == "") {
          // If value.value exists, delete the support
          delete query.supplier_id;
        } else {
          query.supplier_id = value.value;
        }

        return {
          ...rest,
          page: 0, // Keep other attributes in REST
          query: query, // Set the modified query
        };
      });
    }
  };

  //Pagling recovery trigger update Terrament recovery trigger update
  const ReceivePaginationABack = (page: number) => {
    setPagination((prevpagination) => ({
      ...prevpagination,
      page: page,
    }));
  };
  // set search value
  const [search, setSearch] = useState("");

  // Define an asynchronous function ajaxcurrenTlist to send AJAX request to obtain the list of suppliers
  const ajaxCurrentList = async () => {
    setLoading(true); // Set the loading status to TRUE, indicating that the data is loading
    let searchStr = {};

    // Check whether there are search conditions, if so, build a search parameter object
    if (search) {
      searchStr = {
        search: search,
      };
    }

    // Send GET requests to get supplier data
    const responseOrderProduct = await regGetOrderProductList(
      pagination,
      "GET"
    );

    setLoading(false); // After the data is loaded, set the loading status to false
    if (responseOrderProduct.data.code === 200) {
      setProductProcurementList(responseOrderProduct.data.data); // 如果响应状态码为200，将供应商列表更新为响应数据中的数据
    }
  };
  // Use the USEEFFECT hook. When Search changes, call the AjaxCurrenTList function
  useEffect(() => {
    ajaxCurrentList();
  }, [pagination]);

  // Edit supplier
  const PurchaseAction = async (row: IOrderProduct) => {
    setFormType("edit");
    openProductProcurementShow();
    const newOrderProcurementDataInit = {
      product_id: row.product_id,
      order_quantity: row.order_quantity,
      order_product_id: row.order_product_id,
      product_name: row.product_name,
      supplier_id: row.supplier_id,
    };
    setCurrentRowInfo(newOrderProcurementDataInit);
  };

  const LockedProduct = async (row: IOrderProduct, locked: number) => {
    const data = {
      order_product_id: row.order_product_id,
      order_quantity: row.order_quantity,
      product_id: row.product_id,
      locked: locked,
    };

    const responseLOckedData = await regLockedProduct(data, "PUT");
    HintInfo(responseLOckedData.data);
    if (responseLOckedData.data.code == 200) ajaxCurrentList();
  };

  const rows = orderProductList.data.map((row) => {
    let flagStr: any, statusStr: any;
    if (row.delivery_status == 1) {
      statusStr = <Badge color="blue"> Not </Badge>;
    } else if (row.delivery_status == 2) {
      statusStr = <Badge color="red"> Partially </Badge>;
    } else {
      statusStr = <Badge color="green">All </Badge>;
    }
    return (
      <tr className="border-bt-2 my-10 ">
        <LoadingOverlay visible={rowloading} overlayBlur={2} />
        <td> {row.product_id ? row.product_id : "-"}</td>
        <>
          {" "}
          <td>
            {" "}
            {row.product_name} <span className="red-font"> </span>{" "}
          </td>
          <td> {row.order_quantity ? row.order_quantity : "-"} </td>
          {/* <td> {row.order_name ? row.order_name:'-'}</td> */}
          <td> {row.order_no ? row.order_no : "-"}</td>
          {/* <td> {row.supplier_name? row.supplier_name:'-'} </td>*/}
          {/* <td> {row.unit_price ? row.unit_price:'-'}  </td>  */}
          <td>
            {" "}
            <span
              className={
                (row.amount as number) >= row.order_quantity
                  ? "green-font"
                  : "red-font"
              }
            >
              {row.amount ? row.amount : "0"}{" "}
            </span>{" "}
          </td>
          {/* <td> {row.total_price ? row.total_price:'-'}</td> */}
          {/* <td>{row.delivered_quantitly ? row.delivered_quantitly:'-'} </td> */}
          {/* <td> {row.delivery_schedule ? formatDateUpgraded(new Date(row.delivery_schedule),'YYYY-MM-DD') :'-'} </td> */}
          {/* <td> {row.delivered_date ? formatDateUpgraded(new Date(row.delivered_date),'YYYY-MM-DD'):'-'} </td> */}
          {/* <td> {flagStr} </td> */}
          {/* <td> {statusStr} </td> */}
          <td>
            <Group spacing={0} position="center">
              <ActionIcon onClick={() => PurchaseAction(row)}>
                <Tooltip
                  label={
                    row.supplier_id != null
                      ? "You can purchase products  to " +
                        row.supplier_name +
                        "You can purchase products here "
                      : "You can go to the supplier to purchase the ordere"
                  }
                >
                  <IconShoppingBag size="1.2rem" stroke={1.5} color="green" />
                </Tooltip>
              </ActionIcon>
            </Group>
          </td>
        </>
      </tr>
    );
  });
  const closeEditFunction = (type: number) => {
    closeFrom();
    closeSaleOpened();
    colseProductProcurementShow();
    if (type == 2) {
      ajaxCurrentList();
    }
  };
  // list all the suppliers
  return (
    <Box>
      <LoadingOverlay visible={loading} overlayBlur={2} />
      <Paper
        px={20}
        mih={500}
        pb={10}
        w={"100%"}
        style={{ position: "relative" }}
      >
        <PageTitle
          title="CUSTOMER ORDER PRODUCT MANAGEMENT"
          lang_id="CUSTOMER ORDER PRODUCT MANAGEMENT"
        />
        <Group my={10}>
          <SearchBox callBack={ReceiveSearchBack} />
        </Group>

        <table className="table_custome text-center">
          <tr className="table_th">
            <th>
              {intl.formatMessage({
                id: "Product Id",
                defaultMessage: "Product Id",
              })}
            </th>
            <th>
              {intl.formatMessage({
                id: "Product Name",
                defaultMessage: "Product Name",
              })}
            </th>
            <th>
              {intl.formatMessage({
                id: "Order Quantity",
                defaultMessage: "Order Quantity",
              })}
            </th>
            <th>
              {intl.formatMessage({
                id: "Customer Order No",
                defaultMessage: "Customer Order No",
              })}
            </th>
            {/* <th>{intl.formatMessage({
  id: "Order No",
  defaultMessage: "Order No",
})}</th> */}
            {/* <th>Current Unit Price</th> */}
            <th>
              {intl.formatMessage({
                id: "Current In Stock",
                defaultMessage: "Current In Stock",
              })}
            </th>
            {/* <th>Total Price</th> */}
            {/* <th>{intl.formatMessage({
  id: "Delivered Quantity",
  defaultMessage: "Delivered Quantity",
})}</th>
                         <th>Delivery Schedule</th> */}
            {/* <th>{intl.formatMessage({
  id: "Delivered Date",
  defaultMessage: "Delivered Date",
})}</th> */}
            {/* <th>{intl.formatMessage({
  id: "Delivered Status",
  defaultMessage: "Delivered Status",
})}</th> */}
            <th>
              {intl.formatMessage({
                id: "Option",
                defaultMessage: "Option",
              })}
            </th>
          </tr>
          {rows}
        </table>

        <PaginationA
          callBack={ReceivePaginationABack}
          total_count={orderProductList.total as number}
          pagesize={pagination.pagesize}
          page={pagination.page}
        />
      </Paper>

      {/* Quick purchase order */}

      <Modal
        opened={ProductProcurementShow}
        onClose={colseProductProcurementShow}
        title=" Quick purchase "
        // centered={true}
        size="60%"
        padding="md"
        radius={12}
      >
        <ProductProcurementFrom
          unit_price_prohibit_edit={false}
          additional_information={currentRowInfo}
          closeFucntion={closeEditFunction}
        />
      </Modal>
      <Modal
        opened={saleOpened}
        withCloseButton
        onClose={closeSaleOpened}
        size="sm"
        title=" Sale"
        radius="md"
      >
        <SaleFrom
          closeFucntion={closeEditFunction}
          additional_information={currentRowInfo}
        />
      </Modal>
    </Box>
  );
}
