import { ActionIcon, Box, Button, Divider, Grid, Group, NumberInput, Switch, TextInput, Textarea, useMantineTheme } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconCheck, IconTrash, IconX } from "@tabler/icons-react";
import { DatesProvider, MonthPickerInput, DatePickerInput, DateInput } from '@mantine/dates';
import { FormEvent, useState } from "react";
import { IOrderNameWithCallback, IOrderNameData, APIResponse } from "../../../interface/IorderName";
import { notifications } from "@mantine/notifications";
import { regCustomerRoleContact, regOrdername } from "../../../api";


export default function OrderNameForm({orderNameSingleData ,callBack}: IOrderNameWithCallback ) {
    const form = useForm<IOrderNameData>({
        initialValues: orderNameSingleData,
        validate: (values) => ({
            name: values.name.length < 2 ? 'Too short name' : null,
        }),
    });


    
    const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault(); // Stop the formal submission of the form default
        // Verification form
        const valid = await form.validate();
        if (!valid.hasErrors) {
          let originalData = {
            name: form.values.name,
            order_name_description: form.values.order_name_description,
           }
           let formData = {};
           const methodType = form.values.id_order_name ? "PUT" : "POST";
           formData = form.values.id_order_name ? { ...originalData, id: form.values.id_order_name } : originalData;
           const response = await regOrdername(formData,methodType);
           const result = response.data;
            if (result.code === 200) {
                callBack(true)
                notifications.show({
                    title:  result.code,
                    color: 'green',
                    icon: <IconCheck />,
                    message: result.msg,
                })
            } else {
                 callBack(false)
                notifications.show({
                    title:  result.code,
                    color: 'red',
                    icon: <IconX />,
                    message: result.msg,
                })
            }
        }
    };
    
    return (
        <form onSubmit={handleFormSubmit} >
           <TextInput label="Status name" required placeholder="Order Name" {...form.getInputProps('name')} />
           <Textarea
                label=" Information"
                placeholder="Please write some profiles of the order name"
                minRows={5}
                maxRows={5}
                {...form.getInputProps('order_name_description')}
            />   
            <Group position="right" mt="md" > 
                <Button variant='outline' fw={200} type="submit" my={10}>Save</Button>
            </Group>
        </form>
    )
}