import { ActionIcon, Button, Tooltip } from "@mantine/core";
import { IconCheck, IconChecks, IconCircleCheckFilled, IconCirclePlus, IconTrash, IconX } from "@tabler/icons-react";
import { FormattedMessage } from "react-intl";
import WordTranslationComponent from "../../utils/language_pack/words";

// Define the props interface for the ChildComponent
interface ChildComponentProps {
    size?: any;
    stroke?: any;
    color?:any;
    tip?:string;
    disabled?:boolean;
  callBack: () => void; // Callback function to handle button click events
}

// ChildComponent representing the Create Button
const IconCheckDs: React.FC<ChildComponentProps> = ({stroke,tip ='-',disabled=true,size,color, callBack }) => {
  
  // Get translation public configuration information
  const word_translation = WordTranslationComponent();
 
  return (
    <ActionIcon disabled={!disabled} onClick={() => callBack()}>
            <Tooltip label={tip}>
                   <IconChecks size={size ? size :'1rem'}  stroke={stroke?stroke:'1.5'} color={color ?  color:'green'} />
              </Tooltip>
    </ActionIcon>
  

  );
};

export default IconCheckDs;
