import { ScrollArea, Tabs } from '@mantine/core'
import { IconMoneybag, IconBuilding, IconClock } from '@tabler/icons-react'
import { Modal } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import ModalTitleText from '../../../../../ttcomponents/ModalTitleText/ModalTitleText'
import WordTranslationComponent from '../../../../../utils/language_pack/words'
import { useEffect, useState } from 'react'
import { UserAllList } from '../../../../../interface/Iuser'
import TaskTargetTime from './target_time'
import { requestProjectTaskByCostCenter } from '../../../../../api/index_v2'
import { TaskGroupInterface } from '../../../../../interface/Itask'
import TaskBudget from './budget'

interface ComponentProps {
  openFormStatus: boolean
  is_load: boolean
  closeFunction: () => void
  refresh: () => void
  projectBudget: string
  projectId: string
  projectName?: string
  customer_id?: string
  projectTargetTime: string
  userLeader?: UserAllList[]
}

function ProductTask({
  openFormStatus,
  closeFunction,
  refresh,
  projectBudget,
  projectId,
  customer_id,
  projectTargetTime,
  projectName
}: ComponentProps) {
  const [openForm, { open: openModalFunction, close: closeModalFunction }] = useDisclosure(false)
  // Get translation public configuration information
  const word_translation = WordTranslationComponent()

  const [taskByCostCenter, setTaskGroupByCostCenter] = useState<TaskGroupInterface[]>([])

  const [taskTargetTime, setTaskTargetTime] = useState('')
  const [taskBudget, setTaskBudget] = useState('')
  useEffect(() => {
    if (openFormStatus) {
      openModalFunction()
    } else {
      closeModalFunction()
    }
    if (openFormStatus) {
      openModalFunction()
      getProjectTaskListByCostCenter()
    } else {
      closeModalFunction()
    }
  }, [openFormStatus])

  const getProjectTaskListByCostCenter = async () => {
    try {
      const response = await requestProjectTaskByCostCenter({ project_id: projectId })
      const result = response.data
      // Check if the response code is 200 (OK)
      if (result.code === 200) {
        setTaskGroupByCostCenter(result.data.list)
        setTaskTargetTime(result.data.total)
        setTaskBudget(result.data.total_budget)
      }
    } catch (error) {
      // Handle errors by returning an empty array
      console.error('Error fetching user data:', error)
    }
  }

  // Close component
  const refreshInfo = () => {
    getProjectTaskListByCostCenter()
    refresh()
  }
  // Close component
  const closeBtnModalFunction = () => {
    closeModalFunction()
    closeFunction()
  }
  return (
    <Modal
      opened={openForm}
      onClose={closeBtnModalFunction}
      closeOnClickOutside={false}
      title={<ModalTitleText title={`${word_translation.edit_task} - ${projectName} `} />}
      centered={false}
      size={900}
      radius={10}
    >
      <Tabs mt={20} variant="outline" defaultValue="target_time">
        <Tabs.List>
          <Tabs.Tab value="target_time" icon={<IconClock size="0.8rem" />}>
            {word_translation.target_time}
          </Tabs.Tab>
          <Tabs.Tab value="budget" icon={<IconMoneybag size="0.8rem" />}>
            {word_translation.budget}
          </Tabs.Tab>
          <Tabs.Tab value="settings" icon={<IconBuilding size="0.8rem" />}>
            PlaceHolder
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="target_time">
          <TaskTargetTime
            project_id={projectId}
            closeBtnModalFunction={closeBtnModalFunction}
            projectTargetTime={projectTargetTime}
            callBack={refreshInfo}
            customer_id={customer_id}
            projectName={projectName}
            taskTargetTime={taskTargetTime}
            taskByCostCenter={taskByCostCenter}
          />
        </Tabs.Panel>
        <Tabs.Panel value="budget">
          <TaskBudget
            project_id={projectId}
            customer_id={customer_id}
            projectName={projectName}
            closeBtnModalFunction={closeBtnModalFunction}
            projectBudget={projectBudget}
            taskBudget={taskBudget}
            projectTargetTime={projectTargetTime}
            callBack={refreshInfo}
            taskTargetTime={taskTargetTime}
            taskByCostCenter={taskByCostCenter}
          />
        </Tabs.Panel>

        <Tabs.Panel value="settings" pt="xs">
          PlaceHolder
        </Tabs.Panel>
      </Tabs>
    </Modal>
  )
}
export default ProductTask
