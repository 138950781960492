import { Checkbox, Group } from '@mantine/core'
import WordTranslationComponent from '../../../../../../utils/language_pack/words'
import { AssignablePermissions, PermissionIdentifierInterface } from '../../../../../../interface/Iemloyee'

import { MenuActionItemInterFace } from '../../../../../../interface/Iuser'
import { useCallback, useEffect, useMemo, useState } from 'react'
import React from 'react'
import { useCostCenterLeader } from '../../context'
import CheckPermissionTools from '../../../../../../utils/permission'

interface ComponentInterface {
  PermissionIdentifierItem: PermissionIdentifierInterface
  PermissionAction: MenuActionItemInterFace[]
  assignablePermissions: AssignablePermissions | undefined
  callBack: (id_permission_identifier: any, permission_ids: string[]) => void
  allCheck: boolean
}

const PermissionSetCheckBoxGroup = ({
  PermissionIdentifierItem,
  allCheck,
  assignablePermissions,
  callBack,
  PermissionAction
}: ComponentInterface) => {
  // Get translation public configuration information
  const word_translation = WordTranslationComponent()
  const Perm = CheckPermissionTools()

  const {
    id,
    costCenter_id,
    identifier,
    task_ids,
    project_ids,
    order_ids,
    all_project,
    all_order,
    all_task,
    role_permissions
  } = useCostCenterLeader()

  const value = useMemo(() => {
    const currentItem = role_permissions.find(
      role => role.permission_identifier_id === PermissionIdentifierItem.id_permission_identifier
    )
    if (currentItem) {
      return currentItem.permission_ids
    }
    return []
  }, [role_permissions])

  const assignablePermission_ids = useMemo(() => {
    if (!assignablePermissions) {
      return []
    }
    const currentPermissions = assignablePermissions.permissions.find(
      i => i.permission_identifier_id == PermissionIdentifierItem.id_permission_identifier
    )
    if (currentPermissions) {
      return currentPermissions.permission_ids
    }
    return []
  }, [assignablePermissions])

  const grandID = useMemo(() => (assignablePermissions ? assignablePermissions.grand_id : ''), [assignablePermissions])

  const SpecialDisablingAll = useMemo(() => {
    if (!costCenter_id || !identifier) {
      return true
    }

    // admin user open all
    if (Perm.admin) {
      return false
    }
    if (assignablePermission_ids.includes(grandID.toString())) return false
    return true
  }, [costCenter_id, identifier, assignablePermission_ids, grandID])

  // control current allchecked btn
  const [checked, setChecked] = useState(allCheck)

  useEffect(() => {
    if (allCheck) {
      setChecked(true)
    } else {
      setChecked(false)
    }
  }, [allCheck, id, identifier])

  useEffect(() => {
    // select all case
    const getAssignablePermission = () => {
      let ids: string[] = []
      if (Perm.admin) {
        ids = PermissionAction.map(item => item.id_permission.toString())
      } else {
        ids = assignablePermission_ids
      }

      const create_action = PermissionAction.find(
        action => action.identifier == '00001'
      )?.id_permission.toString() as string

      // project
      if (PermissionIdentifierItem.identifier == '10001') {
        if (all_project || project_ids?.length > 0) {
          // others
          ids = ids.filter(id => id !== create_action)
        } else {
          // just create_action and grant
          ids = ids.filter(id => id === create_action || id == grandID)
        }
      }

      // order
      if (PermissionIdentifierItem.identifier == '10002') {
        if (order_ids.length > 0 || all_order) {
          ids = ids.filter(id => id !== create_action)
        } else {
          ids = ids.filter(id => id === create_action || id == grandID)
        }
      }

      // task
      if (PermissionIdentifierItem.identifier == '10003') {
        if (task_ids.length > 0 || all_task) {
          ids = ids.filter(id => id !== create_action)
        } else {
          ids = ids.filter(id => id === create_action || id == grandID)
        }
      }
      return ids
    }
    if (checked) {
      callBack(PermissionIdentifierItem.id_permission_identifier, getAssignablePermission())
    }
  }, [checked, task_ids, project_ids, order_ids, all_project, all_order, all_task, grandID])

  useEffect(() => {
    if (!checked) {
      callBack(PermissionIdentifierItem.id_permission_identifier, [])
    }
  }, [checked])

  useEffect(() => {
    if (!checked) {
      // project
      if (PermissionIdentifierItem.identifier == '10001') {
        if (all_project || project_ids?.length > 0) {
          callBack(PermissionIdentifierItem.id_permission_identifier, [])
        }
      }
    }
  }, [checked, all_project, project_ids])
  useEffect(() => {
    if (!checked) {
      // order
      if (PermissionIdentifierItem.identifier == '10002') {
        if (order_ids.length > 0 || all_order) {
          callBack(PermissionIdentifierItem.id_permission_identifier, [])
        }
      }
    }
  }, [checked, all_order, order_ids])
  useEffect(() => {
    if (!checked) {
      // task
      if (PermissionIdentifierItem.identifier == '10003') {
        if (task_ids.length > 0 || all_task) {
          callBack(PermissionIdentifierItem.id_permission_identifier, [])
        }
      }
    }
  }, [checked, all_task, task_ids])

  // mouse hover
  const [isHovered, setIsHovered] = useState(false)
  const handleTrMouseLeave = () => {
    setIsHovered(false)
  }
  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const setValueCheckedFunction = (value: any) => {
    callBack(PermissionIdentifierItem.id_permission_identifier, value)
  }

  const SpecialDisabling = useCallback(
    (item: MenuActionItemInterFace) => {
      if (!costCenter_id || !identifier) {
        return true
      }

      let result = false

      // Permit
      if (PermissionIdentifierItem.identifier.endsWith('99999')) {
        if (item.identifier === '0001') {
          if (Perm.admin) {
            return false
          } else {
            return assignablePermissions?.is_permit === false
          }
        } else {
          return true
        }
      }

      // project
      if (PermissionIdentifierItem.identifier == '10001') {
        if (all_project || project_ids?.length > 0) {
          result = item.identifier == '00001'
        } else {
          if (item.identifier == '00001' || item.identifier == '0001') {
            result = false
          } else {
            result = true
          }
        }
      }

      // order
      if (PermissionIdentifierItem.identifier == '10002') {
        if (order_ids.length > 0 || all_order) {
          result = item.identifier == '00001'
        } else {
          if (item.identifier == '00001' || item.identifier == '0001') {
            result = false
          } else {
            result = true
          }
        }
      }

      // task
      if (PermissionIdentifierItem.identifier == '10003') {
        if (task_ids.length > 0 || all_task) {
          result = item.identifier == '00001'
        } else {
          if (item.identifier == '00001' || item.identifier == '0001') {
            result = false
          } else {
            result = true
          }
        }
      }

      if (result === true) return true

      // admin user open all
      if (Perm.admin) {
        return false
      }

      // other user need check current assignable permission's grant
      if (assignablePermission_ids.includes(grandID.toString())) {
        // has permit
        if (assignablePermissions?.is_permit) {
          if (assignablePermission_ids.includes(item.id_permission.toString())) {
            console.log(item.identifier, assignablePermission_ids)
            return false
          }
        } else {
          if (item.identifier == '0001') {
            return true
          } else {
            if (assignablePermission_ids.includes(item.id_permission.toString())) {
              return false
            }
          }
        }
        return true
      }
      return true
    },
    [
      assignablePermissions,
      costCenter_id,
      identifier,
      assignablePermission_ids,
      grandID,
      task_ids,
      project_ids,
      order_ids,
      all_project,
      all_order,
      all_task
    ]
  )

  return (
    <tr
      className="text-center position_relative border-bt-1"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleTrMouseLeave}
    >
      <td className="text-left  input_title">
        <Checkbox.Group w={126} h={24} value={value} onChange={setValueCheckedFunction} withAsterisk>
          <Group style={{ fontSize: '0.8rem', lineHeight: '1.6rem' }}>
            {PermissionIdentifierItem.name} &nbsp;&nbsp;
          </Group>
        </Checkbox.Group>
      </td>
      <td colSpan={5} className="text-left  input_title">
        <Checkbox.Group w={500} h={24} value={value} onChange={setValueCheckedFunction} withAsterisk>
          <Group w="100%">
            {PermissionAction.map((item, key) => {
              return (
                <Checkbox
                  w={40}
                  pt={4}
                  pl={20}
                  className="border-left"
                  indeterminate={SpecialDisabling(item)}
                  disabled={SpecialDisabling(item)}
                  key={PermissionIdentifierItem.id_permission_identifier + '-' + key}
                  value={item.id_permission.toString()}
                />
              )
            })}
          </Group>
        </Checkbox.Group>
      </td>
      {isHovered && (
        <td className="permission-all">
          <Checkbox
            label={word_translation.all}
            disabled={SpecialDisablingAll}
            indeterminate={SpecialDisablingAll}
            checked={checked}
            onChange={event => setChecked(event.currentTarget.checked)}
          />
        </td>
      )}
    </tr>
  )
}

export default PermissionSetCheckBoxGroup
