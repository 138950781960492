import React, { FC, useEffect, useState } from 'react'
import { Text, Paper, Box, Checkbox, Group, Alert, ScrollArea, Table, Flex } from '@mantine/core'
import WordTranslationComponent from '../../../../../../utils/language_pack/words'
import {
  AssignablePermissions,
  PermissionActionInterface,
  PermissionIdentifierInterface,
  RolePermission
} from '../../../../../../interface/Iemloyee'

import PermissionSetCheckBoxGroup from './permission_checkbox_group'
import { IconAlertCircle, IconArrowBack } from '@tabler/icons-react'
import UniversalButton from '../../../../../../ttcomponents/Button/UniversalButton'
import ResetBtn from '../../../../../../ttcomponents/Button/ResetBtn'
import { useCostCenterLeader, useCostCenterLeaderApi } from '../../context'

interface ComponentProps {
  currentPermission: RolePermission[]
  identifierListApi: (data: any, methed: string) => any
  actionListApi: (data: any, methed: string) => any
  assignablePermissionApi: (data: any) => any
  leftWidth?: number
}

export default function CommonPermissionSet({
  currentPermission,
  identifierListApi,
  actionListApi,
  assignablePermissionApi,
  leftWidth
}: ComponentProps) {
  // Get translation public configuration information
  const word_translation = WordTranslationComponent()

  const { costCenter_id } = useCostCenterLeader()
  const { updateRole_permissions, updateData } = useCostCenterLeaderApi()

  const [identifierList, setIdentifierList] = useState<PermissionIdentifierInterface[]>([])
  const [actionList, setActionList] = useState<PermissionActionInterface[]>([])
  // Get the list of IdentifierList
  const [allChecked, setAllChecked] = useState(false)
  const [assignablePermissions, setAssignablePermissions] = useState<AssignablePermissions | undefined>()

  // Execute the useeffect when the first load is loaded.
  useEffect(() => {
    const getIdentifierList = async () => {
      identifierList.length == 0 && setIdentifierList(await identifierListApi({}, 'all'))
    }
    // Get the list of IdentifierList
    const getActionList = async () => {
      actionList.length == 0 && setActionList(await actionListApi({}, 'all'))
    }

    getIdentifierList()
    getActionList()
  }, [])

  useEffect(() => {
    const getAssignablePermissionList = async () => {
      const data = await assignablePermissionApi({
        costCenter_id
      })
      setAssignablePermissions(data?.grand_id ? data : undefined)
    }
    if (costCenter_id) {
      getAssignablePermissionList()
    }
  }, [costCenter_id])

  const setItemPermissionSetFunction = (id_permission_identifier: string, permission_ids: string[]) => {
    // Check if id_permission_identifier is missing, return false
    if (!id_permission_identifier) {
      return false
    }
    updateRole_permissions({
      permission_ids: permission_ids,
      permission_identifier_id: id_permission_identifier
    })
  }

  // =============== Form Reset Function ===============
  // Resetting the form is different from the reset button; it resets the entire form, including permissions
  const undoFunction = () => {
    updateData('role_permissions', currentPermission)
  }
  const resetFunction = () => {
    // Reset the form data by setting it to undefined
    updateData('role_permissions', [])
  }

  return (
    <Paper px={20} py={10} mih={180} radius="md" withBorder p="lg" bg="var(--mantine-color-body)">
      <ScrollArea h={380}>
        {identifierList.length === 0 || actionList.length === 0 ? (
          <Group position="center">
            <Alert icon={<IconAlertCircle size="1rem" />} title={word_translation.no_data}>
              {word_translation.permission_select_hitn}
            </Alert>
          </Group>
        ) : (
          <Table highlightOnHover>
            <tr>
              <Box h={20}></Box>
            </tr>
            <tr className="table_tr_xs">
              <th> </th>
              <Checkbox.Group w={480} m={0} withAsterisk>
                <Group w="100%" pl={10} mb={15}>
                  {actionList.map((item, index) => {
                    return (
                      <th key={index} className="input_title">
                        <Text className="rotate--90deg" w={50}>
                          {item.title}
                        </Text>
                      </th>
                    )
                  })}
                </Group>
              </Checkbox.Group>
            </tr>
            <tbody>
              {identifierList.map((item, index) => (
                <PermissionSetCheckBoxGroup
                  key={index}
                  PermissionIdentifierItem={item}
                  PermissionAction={actionList as []}
                  assignablePermissions={assignablePermissions}
                  allCheck={allChecked}
                  callBack={setItemPermissionSetFunction}
                  leftWidth={leftWidth}
                />
              ))}
            </tbody>
          </Table>
        )}
      </ScrollArea>
      <Flex justify={'space-between'}>
        <SelectAll allChecked={allChecked} setAllChecked={setAllChecked} />
        <Group position="right" mt={10}>
          <UniversalButton
            btn_icon={<IconArrowBack size={14} />}
            btn_bg_color="blue"
            btn_text={word_translation.undo}
            callBack={undoFunction}
          />
          <ResetBtn callBack={resetFunction} />
        </Group>
      </Flex>
    </Paper>
  )
}

const SelectAll: FC<{
  allChecked: boolean
  setAllChecked: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ allChecked, setAllChecked }) => {
  const { costCenter_id, identifier } = useCostCenterLeader()
  const word_translation = WordTranslationComponent()

  return (
    <Checkbox
      mt={10}
      disabled={identifier && costCenter_id ? false : true}
      indeterminate={identifier && costCenter_id ? false : true}
      label={word_translation.select_all}
      checked={allChecked}
      onChange={event => setAllChecked(event.currentTarget.checked)}
    />
  )
}
