import React, { forwardRef, useEffect, useRef, useState } from "react";
import {
  Text,
  Paper,
  Grid,
  Group,
  Select,
  Box,
  Checkbox,
  Avatar,
  MultiSelect,
  Code,
  Tooltip,
  ActionIcon,
  TextInput,
} from "@mantine/core";
import {
  hasLength,
  useForm,
} from "@mantine/form";
import TableTextInputTitle from "../../../../../../ttcomponents/TableTextInputTitle/TableTextInputTitle"; // Importing a custom component
import WordTranslationComponent from "../../../../../../utils/language_pack/words"; // Importing a utility function for language translation
import { PermissionRoleInterface } from "../../../../../../interface/Iemloyee"; // Importing an interface
import { SelectRole } from "../../../../../../interface/Icommon"; // Importing an interface
import apiUrl from '../../../../../../config/api_config/config'; // Importing API configuration
import { getUserGeneralFunction } from "../../../../../../api/common_request"; // Importing an API function
import { get } from "http"; // Importing a module
import { getCoreUserRoleFunction, getDefaultFunction, getListTemPermissionFunction, getPermissionManageDetail } from "../../../../../../api/core_request"; // Importing API functions
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../state_management/store";
interface ComponentProps {
  costCenterData: SelectRole[]; // Prop for cost center data
  setFormValue: (data: any) => void; // Function to set form value
  formValue: any; // Prop for form value
  setCostTemPermissionList: (data: SelectRole[]) => void; // Function to set form value
  managementStatus:boolean;
  reload:number;
}

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  label: string; // Prop for label
  email: string; // Prop for email
  value: string; // Prop for value
  avatar: string; // Prop for avatar
}

// Forwarded ref component for selecting an item
const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ label, email, avatar, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <Avatar src={apiUrl.fileSystemDomain + avatar} /> {/* Avatar with source */}
        <div>
          <Text size="sm">{label}</Text> {/* Label text */}
          <Text size="xs" opacity={0.65}>
            {email}
          </Text> {/* Email text */}
        </div>
      </Group>
    </div>
  )
);

// CoreRolePermissionForm functional component
export default function CoreRolePermissionForm({ formValue,reload, setCostTemPermissionList,managementStatus,setFormValue, costCenterData }: ComponentProps) {

  // Get translation public configuration information
  const word_translation = WordTranslationComponent();

  const [userRoles, setUserRolesInfo] = useState<SelectRole[]>([]);
  const [temPermissionsInfo, setTemPermissionsInfo] = useState<SelectRole[]>([]);
  const [currentUserid, setCurrentUserid] = useState<string>();
  const [randNumber, setRandNumber]  = useState(0);
  const [UserList, setResultUser] = useState<ItemProps[]>([]);

  // Function to get a list of users
  const getUser = async (where: any) => {
    // Call the Get User Universal Selection function and set the user list
    managementStatus == false && setResultUser(await getUserGeneralFunction(where, 'select'));
  };


  
  // Function to get a tem permission list 
  const getTemPermission = async () => {
    // Call the Get User Universal Selection function and set the user list
    setTemPermissionsInfo(await getListTemPermissionFunction())
  };
  useEffect(() => {
    setCostTemPermissionList(temPermissionsInfo); // Call getCoreUserRole function on component mount
  }, [temPermissionsInfo]);

  // 获取redux store中refreshStatus的值
  const refreshStatus = useSelector((state:RootState)=>state.refreshStatus.refreshStatus)
  // Execute the useeffect when the first load is loaded.
  const isInitialMount = useRef(true);
  useEffect(() => {
    if (!isInitialMount.current) {
      getTemPermission();
    }
    isInitialMount.current = false;
  }, [refreshStatus]);
  

  useEffect(() => {
    getPermissionManageRoleDetail()
  }, [reload]);
  // Function to get a list of user roles
  
  const getCoreUserRole = async (where:any) => {
     // Call the Get User Universal Selection function and set the user list
     if( userRoles.length  == 0 ){
      var rolelist = await getCoreUserRoleFunction({type:"core_role"})
      rolelist = [...rolelist,{'label':'All User',value:'0'}]
      setUserRolesInfo(rolelist);
     }
  };

  useEffect(() => {
    var data = managementStatus? {type:"core_role"} :{}
    getCoreUserRole(data); // Call getCoreUserRole function on component mount
  }, [managementStatus]);
  

  
  
  useEffect(() => {
    setRandNumber(randNumber + 1);
  }, [UserList]);
  

  useEffect(() => {
    getTemPermission()
  }, []);

  
  useEffect(() => {
    if(formValue?.user_ids && formValue?.user_ids.length == 0){
      setFormValue((prevData: any) => {
        return {
          ...prevData,
          permissions: []
        };
      });
    }
  }, [formValue?.user_ids]);


  // useEffect(() => {
  //   if(formValue?.role_id && formValue?.costCenter_id && formValue.costCenter_id.length >0 ){
  //     getPermissionManageRoleDetail();
  //   }
  // }, [reload]);
  

  const getPermissionManageRoleDetail = async () => {
    if(formValue?.table_id  ){
      var result: any;
      result = await getPermissionManageDetail({ id_management_permission_set_table : formValue?.table_id })
  
      if (result?.permissions){
        const updatedPermissions = result.permissions.map((permission: any) => {
          return {
            ...permission,
            is_edit: false,
            permission_action: permission?.permission_action.split(",") || []
          };
        });

        setFormValue((prevData: any) => {
          return {
            ...prevData,
            manage: true,
            permissions: updatedPermissions
          };
        });
        // Load special permissions
      }
    }
  };
  
  useEffect(() => {
    getUser({'user_role_id': formValue?.role_id ,list:true});
    if(formValue?.costCenter_id && formValue?.costCenter_id.length == 0 ){
      setFormValue((prevData: any) => {
        return {
          ...prevData,
          permissions: []
        };
      });
    }
    
  }, [formValue?.role_id]);

  
  useEffect(() => {
    getPermissionManageRoleDetail();
  }, [formValue?.table_id]);


  useEffect(() => {
    getPermissionManageRoleDetail();
    if(formValue?.costCenter_id && formValue?.costCenter_id.length == 0 ){
      setFormValue((prevData: any) => {
        return {
          ...prevData,
          permissions: []
        };
      });
    }
    
  }, [formValue?.costCenter_id ]);
  
  // Event handler for cost center change
  const handleCostCenterChange = (value: any) => {
    setFormValue((prevData: any) => {
      return {
        ...prevData,
        costCenter_id: value
      };
    });
  };


  // Event handler for user change
  const handleUserChange = (value: any) => {
    setFormValue((prevData: any) => {
      return {
        ...prevData,
        user_ids: value
      };
    });
  };

  
  // Event handler for user change
  const handleTemplateChange = (value: any) => {
    setFormValue((prevData: any) => {
      return {
        ...prevData,
        title: value
      };
    });
  };
  

  // Event handler for user role change
  const handleUserRoleChange = (value: any) => {
    // if (formValue.role_id !== value) {
    //   getUser({ 'user_role_id': value });
    // }
    setFormValue((prevData: any) => {
      return {
        ...prevData,
        role_id: value,
      };
    });
    setRandNumber(randNumber + 1);
  };

    // Event handler for user role change
    const handleTemPermissionChange = (value: any) => {
      // if (formValue.role_id !== value) {
      //   getUser({ 'user_role_id': value });
      // }
      setFormValue((prevData: any) => {
        return {
          ...prevData,
          table_id: value,
        };
      });
      setRandNumber(randNumber + 1);
    };
  
  
  
  return (
    <Paper>
      <Grid>
        <Grid.Col span={12}>
          <Paper  mih={180}  radius="md" withBorder p="lg" bg="var(--mantine-color-body)">
            <Grid gutter={20}>
              <Grid.Col span={8}>
              {!managementStatus ?<> <Box>
                <Group>
                    <Box>
                        <TableTextInputTitle title={word_translation.select_role_name} />
                          <Select
                            labelProps={{ className: "input_title" }}
                            placeholder={word_translation.pick_one}
                            onChange={(value) => handleUserRoleChange(value)}
                            searchable
                            size="xs"
                            defaultValue={formValue?.role_id　&&　formValue?.role_id.toString()}
                            key={formValue?.role_id}
                            data={userRoles}
                          />
                    </Box>
                    <Box>
                      <TableTextInputTitle title={word_translation.role_name_template} />
                      <Select
                        labelProps={{ className: "input_title" }}
                        placeholder={word_translation.pick_one}
                        onChange={(value) => handleTemPermissionChange(value)}
                        searchable
                        size="xs"
                        defaultValue={formValue?.role_id　&&　formValue?.role_id.toString()}
                        key={formValue?.role_id}
                        data={temPermissionsInfo}
                      />
                    </Box>
                </Group>
                </Box>
                <Box>
                  <TableTextInputTitle title={word_translation.select_cost_center} />
                  <MultiSelect
                    labelProps={{ className: "input_title" }}
                    key={formValue?.costCenter_id}
                    onChange={(value) => handleCostCenterChange(value)}
                    placeholder={word_translation.select_cost_center}
                    searchable
                    defaultValue={formValue?.costCenter_id}
                    size="xs"
                    data={costCenterData}
                  />
                </Box>
            
                <Box>
                  <TableTextInputTitle title={word_translation.user} />
                  <MultiSelect
                    placeholder={word_translation.pick_one}
                    itemComponent={SelectItem}
                    data={UserList as []}
                    searchable
                    key={formValue?.user_ids}
                    defaultValue={formValue?.user_ids}
                    maxDropdownHeight={400}
                    className='time_sheet_home'
                    onChange={(value) => handleUserChange(value)}
                    size="xs"
                    nothingFound="Nobody here"
                  />

                </Box>
             </>:<>
                  <TableTextInputTitle title={word_translation.role_name_template} />
                  <TextInput
                    placeholder={word_translation.Input}
                    value={formValue?.title}
                    onChange={(value) => handleTemplateChange(value.target.value)}
                  />
             </>}
                </Grid.Col>
                
              <Grid.Col span={8}>
                
              </Grid.Col>
           
            </Grid>
              {managementStatus &&<Box pt={10}>
                  <Text color="#333333" fz={12} className="cursor-pointer" fs="italic" >
                  Here is where you create permissions for roles. Once created, 
                  when you create permissions for users under that role, 
                  the permissions created here will be automatically assigned to that user.
                  </Text>
              </Box> }  
          </Paper>
        </Grid.Col>
      
      </Grid>
    </Paper>
  );
}
