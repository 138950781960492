import { Card, Table } from "@mantine/core";
import { ReportProjectInfo } from "../../../interface/Ireports";
import WordTranslationComponent from "../../../utils/language_pack/words";
import React from 'react';
import { ConvertTimeStringToDecimal, formatTimeHHSS } from "../../../utils/function";
interface ComponentInterface {
    projectinfo: ReportProjectInfo | undefined; // Array of MonthlyTotal objects or undefined
}

export default function ProjectDetail({ projectinfo }: ComponentInterface) {
    // Get translation public configuration information
    const word_translation = WordTranslationComponent();
    return (
        <Card mt={20} bg='#eee'>
            <Table>
                <thead>
                    <tr>
                        <th>{word_translation.project_name_up}</th>
                        <th>{projectinfo?.project_name}</th>
                    </tr>
                    <tr>
                        <th>{word_translation.actual_time}</th>
                        <th>{ConvertTimeStringToDecimal(formatTimeHHSS(projectinfo?.actual_used_time as string))}&nbsp;{word_translation.hour}</th>
                    </tr>
                    <tr>
                        <th>{word_translation.target_time}</th>
                        <th>{ConvertTimeStringToDecimal(formatTimeHHSS(projectinfo?.target_time as string))}&nbsp;{word_translation.hour}</th>
                    </tr>
                    <tr>
                        <th>Actual value (%)</th>
                        <th>{projectinfo?.percent && (projectinfo?.percent * 100).toFixed(2)}</th>
                    </tr>
                </thead>
            </Table>
        </Card>
    )
}
