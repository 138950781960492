import { Paper, Group, Grid, LoadingOverlay, TextInput, Textarea } from '@mantine/core'
import { useState } from 'react'
import { hasLength, useForm } from '@mantine/form'
import { regCustomerRole } from '../../../api'
import SubmitBtn from '../../../ttcomponents/Button/SubmitBtn'
import { ContactRole } from '../../../interface/Icoustomer'
import WordTranslationComponent from '../../../utils/language_pack/words'
import TableTextInputTitle from '../../../ttcomponents/TableTextInputTitle/TableTextInputTitle'
import { HintErrorMantineInfo, HintInfo } from '../../../utils/function'

//  Define the interface and receive a function operation
interface ContactRowFormProps {
  callBack: (value: boolean) => void
  rand_number?: number
  formRoleData: ContactRole
}

export default function ContactRowForm({ formRoleData, rand_number, callBack }: ContactRowFormProps) {
  // Get translation public configuration information
  const word_translation = WordTranslationComponent()
  //加载中状态
  const [loading, setLoading] = useState(false)

  const form = useForm({
    validateInputOnBlur: true,
    initialValues: formRoleData,
    // functions will be used to validate values at corresponding key
    validate: {
      role_name: hasLength(
        { min: 2, max: 10 },
        `${word_translation.customer_contact_role_name}${word_translation.validata_value_need_2_10_length}`
      )
    }
  })
  /**
   * Handles the form submission.
   * Validates the form and determines the request type (POST or PUT) based on the form values.
   * Sends a request to create or update customer roles and displays information based on the response.
   */
  const handleFormSubmit = async () => {
    // Validate the form
    const valid = await form.validate()

    // If there are validation errors, display error information
    if (valid.hasErrors) {
      HintErrorMantineInfo(valid)
      return false
    }

    // Prepare data for the request
    let originalData = {
      role_name: form.values.role_name,
      role_desc: form.values.role_desc
    }
    let formData = {}

    // Determine the request type (POST or PUT)
    const methodType = form.values.id_customer_contact_person_role ? 'PUT' : 'POST'

    // Set the form data based on the request type
    formData = form.values.id_customer_contact_person_role
      ? { ...originalData, id: form.values.id_customer_contact_person_role }
      : originalData

    // Display loading state
    setLoading(true)

    // Send a request to create or update customer roles
    const response = await regCustomerRole(formData, methodType)

    // Hide loading state
    setLoading(false)

    // Display information based on the response
    HintInfo(response.data)

    // Execute callback if the request is successful
    if (response.data.code === 200) {
      callBack(true)
    }
  }

  return (
    <>
      <Paper p={10}>
        <Grid>
          <Grid.Col span={12}>
            <LoadingOverlay visible={loading} overlayBlur={2} />
            <TableTextInputTitle pb={10} title={word_translation.customer_contact_role_name} />
            <TextInput
              key={rand_number}
              placeholder={word_translation.customer_contact_role_name}
              {...form.getInputProps('role_name')}
            />
            <TableTextInputTitle py={10} pb={10} title={word_translation.customer_contact_role_desc} />
            <Textarea
              minRows={8}
              key={rand_number}
              placeholder={word_translation.customer_contact_role_desc}
              {...form.getInputProps('role_desc')}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <Group position="right">
              <SubmitBtn btn_size="xs" callBack={handleFormSubmit} />
            </Group>
          </Grid.Col>
        </Grid>
      </Paper>
    </>
  )
}
