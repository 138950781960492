import {
    Badge,
    Flex,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { IconArrowRight, IconShieldCheck, IconShieldX, IconSwitch } from "@tabler/icons-react";
import WordTranslationComponent from "../../../utils/language_pack/words";
interface ComponentInterface {
    boarding: any;
    boarding_status: any;
}

export default function ChangeStatus({ boarding, boarding_status }: ComponentInterface) {

    const word_translation = WordTranslationComponent();

    return (
        <>

            {/* {boarding} - {boarding_status} */}
            {((boarding == 1 && boarding_status == 1) || (boarding == 1 && boarding_status == '')) && <Flex
                mih={50}
                gap="md"
                justify="flex-start"
                align="center"
                direction="row"
                wrap="wrap"
            >
                <Badge color='bule' >{word_translation.onboarding}</Badge>
                <IconArrowRight size={20} />
                <Badge color='green' >{word_translation.finished}</Badge>
            </Flex>}


            {boarding == 1 && boarding_status == 3 && <Flex
                mih={50}
                gap="md"
                justify="flex-start"
                align="center"
                direction="row"
                wrap="wrap"
            >
                <Badge color='bule' >{word_translation.onboarding}</Badge>
                <IconArrowRight size={20} />
                <Badge color='dark' >{word_translation.onhold}</Badge>
            </Flex>}

            {boarding == 1 && boarding_status == 2 && <Flex
                mih={50}
                gap="md"
                justify="flex-start"
                align="center"
                direction="row"
                wrap="wrap"
            >
                <Badge color='bule' >{word_translation.onboarding}</Badge>
                <IconArrowRight size={20} />
                <Badge color='red' >{word_translation.cancel}</Badge>
            </Flex>}


            {boarding == 3 && boarding_status == 0 && <Flex
                mih={50}
                gap="md"
                justify="flex-start"
                align="center"
                direction="row"
                wrap="wrap"
            >
                <Badge color='bule' >{word_translation.offboarding}</Badge>
                <IconArrowRight size={20} />
                <Badge color='red' >{word_translation.change}</Badge>
            </Flex>}
            
            {boarding == 3 && boarding_status == -1 && <Flex
                mih={50}
                gap="md"
                mt={20}
                justify="center"
                align="center"
                direction="row"
                wrap="wrap"
            >
              <Badge color='red'  size="xl">{word_translation.change} - {word_translation.cost_center_name}</Badge>
            </Flex>}

            
            {boarding == 3 && boarding_status == 3 && <Flex
                mih={50}
                gap="md"
                mt={20}
                justify="center"
                align="center"
                direction="row"
                wrap="wrap"
            >
              <Badge color='red'  size="xl">{word_translation.change} - {word_translation.onhold}</Badge>
            </Flex>}

            {boarding_status == 4 && <Flex
                mih={50}
                gap="md"
                mt={20}
                justify="center"
                align="center"
                direction="row"
                wrap="wrap"
            >
              <Badge color='red'  size="xl">{word_translation.archived}</Badge>
            </Flex>}
        </>
    )
}