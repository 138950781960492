import {
  Box,
  Title,
  LoadingOverlay,
  Card,
  Divider,
  Grid,
  Drawer,
  Flex,
  Group,
  Paper,
  Text,
} from "@mantine/core";
import TaskCategoryParameter from "./task_category";
import { useEffect, useState } from "react";
import TableCateGoryParaMeter from "./task_category_parameter/task_category_parameter_table";
import { ItaskCategory } from "../../../interface/ItaskCategory";
import { ITaskCategoryParameter } from "../../../interface/ItaskCategoryParameter";
import TableCateGory from "./task_category_table";
import { regTaskCategory, regTaskCategoryParameter } from "../../../api";
import PageTitle from "../../../ttcomponents/PageTitle/PageTitle";
import { useIntl } from "react-intl";
import WordTranslationComponent from "../../../utils/language_pack/words";
import CheckPermissionTools from "../../../utils/permission";

export default function TaskCategory() {

  const Perm = CheckPermissionTools();
  // Get translation public configuration information
  const word_translation = WordTranslationComponent();
  //Determine the task type list of storage variables
  const [taskCategoryList, setTaskCategoryList] = useState<ItaskCategory[]>([]);
  // Determine the task type parameter list of storage variables
  const [taskCategoryParameterList, setTaskCategoryParameterList] = useState<
    ITaskCategoryParameter[]
  >([]);

  //Mid -load state
  const [loading, setLoading] = useState(false);
  //Mid -load state
  const [loading1, setLoading1] = useState(false);
  //  Load the task type list, and the list of task type parameters
  const ajaxCurrentList = async () => {

    setLoading1(true);
    try{
      setLoading(true);
      const cateGoryParameter = await regTaskCategoryParameter();
      setLoading(false);
      const cateGoryResponse = cateGoryParameter.data;
      if(cateGoryResponse.code == 200){
        setTaskCategoryParameterList(cateGoryResponse.data.data);
      }
    }catch(e){
      console.log(word_translation.Input);
    }

    try{
      const cateGoryInfo = await regTaskCategory({});
      setLoading1(false);
      if (cateGoryInfo.data.code == 200) {
        setTaskCategoryList(cateGoryInfo.data.data.data);
      }else{

      }
    }catch(e){

    }
  };
  // After the component is rendered, perform some operations
  useEffect(() => {
    ajaxCurrentList();
  }, []);

  const TableCateGoryParaMeterCallback = () => {};

  const callRefresh = () => {
    ajaxCurrentList();
  };

  return (
    <Paper px={20} w={"100%"}>
      <Box pb={20}>
        <PageTitle    title={word_translation.task_category_management} />
        <Grid gutter={0} mt={10}>
          <Grid.Col span={6} mx={10}>
            <TableCateGory
              isLoading={loading}
              callRefresh={callRefresh}
              taskCategoryParameterList={taskCategoryParameterList}
              taskCategoryList={taskCategoryList}
              callback={TableCateGoryParaMeterCallback}
            />
          </Grid.Col>
          <Divider orientation="vertical" />
          <Grid.Col span={5} mx={10}>
            <TableCateGoryParaMeter
              isLoading={loading1}
              callRefresh={callRefresh}
              taskCategoryParameterList={taskCategoryParameterList}
              callback={TableCateGoryParaMeterCallback}
            />
          </Grid.Col>
        </Grid>
      </Box>
    </Paper>
  );
}
