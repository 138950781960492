
import { IconBold,IconCheck, IconItalic, IconLineHeight, IconX } from '@tabler/icons-react';
import { Select, Checkbox, Textarea,Card,Button, Group, Badge ,Text,Title, Code, LoadingOverlay} from '@mantine/core';
import { useForm } from '@mantine/form';

import { ITimeTrackerPropsUpdate, ItimeTrackTask } from "../../../../interface/Itimetrack";
import { FormEvent, useEffect, useRef } from 'react';
import { regTaskCategory, regTimeTrackRecordUp, regrackJobs } from '../../../../api';
import { notifications } from '@mantine/notifications';
import React, { useState } from 'react';
import TimeRecord from './time_record';
import { ApiResponseJobMark, TimeRemarkInterface } from '../../../../interface/IjobMarks';
import { SelectRole } from '../../../../interface/Icommon';
import SaveBottom from '../../../../ttcomponents/Button/SaveBtn';
import { requestTimeTrackerRemark } from '../../../../api/index_v2';
import { HintInfo } from '../../../../utils/function';

interface IOrderFromProps {
  callBack: () => void;
  time_tracker_id: number ;
  currentsetCurrenMarks:TimeRemarkInterface |undefined;
  [key: string]: any;
  currentDayOfweek: string; // 指定 currentDayOfweek 的类型为字符串
}
export default function TimeTrackRecordEdit({ currentsetCurrenMarks,currentContent,callBack, time_tracker_id, currentDayOfweek }: IOrderFromProps) {
  //Set text receiving
  const [content,setContent]  =  useState('');
  //Define the state of loading
  const [loading, setLoading] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  
  const [taskcateGoryListData, setTaskcateGoryListData] = useState<SelectRole[]>([])
  // Define the selected project below the orderList below the tasklist
    const form = useForm({
      initialValues: {
        id:currentsetCurrenMarks?.id_time_remark,
        time_tracker_id:time_tracker_id,
        content:currentsetCurrenMarks?.content,
        day_of_week: currentDayOfweek // 添加 currentDayOfweek 到表单数据中  regTimeTrackRecordUp
      },
  });
   


  useEffect(()=>{ 
      form.setFieldValue('content',currentsetCurrenMarks?.content)
  },[currentsetCurrenMarks])

  
  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
   
    event.preventDefault();
    const valid = await form.validate();
    if (!valid.hasErrors) {
      let method = form.values.id ? 'PUT' : 'POST'
      setLoading(true);
      const response = await requestTimeTrackerRemark(form.values,method);
      setLoading(false);
      const result = response.data;
      HintInfo(result)
      if (result.code === 200) {
        callBack();
      }
    }
  };
  // 富文本回调得到备注
  const textCallBack =(value:string)=>{
    setContent(value)
    form.setFieldValue('content',value);
  }
    return (
      <Card  mx="auto"  className='bg-img-light' >
        <form onSubmit={handleFormSubmit}>
        <LoadingOverlay visible={loading} overlayBlur={2} />
          <TimeRecord callBack={textCallBack}  content={form.values.content}/>
          <Group position="right" mt="md">
              <SaveBottom formRef={formRef} />
          </Group>
         
        </form>
       
        {/* <Code block mt={5}>
          {JSON.stringify(form.values, null, 2)}
        </Code> */}
      </Card>
    )
  }