import {
  Button,
  Group,
  TextInput,
  LoadingOverlay,
  Textarea,
  Modal,
  Box,
  Select,
  Checkbox,
  Code,
  ActionIcon,
} from "@mantine/core";
import { IconCheck, IconLicense, IconX } from "@tabler/icons-react";
import { FormEvent, useEffect, useRef, useState } from "react";
import { PermissionRoleInterface, PermissionSetDetailInterface, Role } from "../../../../interface/Iemloyee";
import { hasLength, useForm } from "@mantine/form";
import ModalTitleText from "../../../../ttcomponents/ModalTitleText/ModalTitleText";
import WordTranslationComponent from "../../../../utils/language_pack/words";
import TableTextInputTitle from "../../../../ttcomponents/TableTextInputTitle/TableTextInputTitle";
import UniversalButton from "../../../../ttcomponents/Button/UniversalButton";
import { SelectRole } from "../../../../interface/Icommon";
import { getCostCenterGeneralSelectFunction } from "../../../../api/common_request";
import { requestPermissionRole } from "../../../../api/index_v2";
import { HintErrorMantineInfo, HintInfo, HintInfoCatchError, HintInfoError } from "../../../../utils/function";
import { useDisclosure } from "@mantine/hooks";
import PermissionSetForm from "../permission_set/permission_set_form";


// If is_load is set to false, the initialization request within the component will not be initiated in advance.
// request  will be controlled by the change in this field when rendering the component. Otherwise, it behaves oppositely.
interface ComponentProps {
  permissionSetDetail: PermissionSetDetailInterface[];
  // closeFunction: () => void;
  costCenterData: SelectRole[];
  currentItemInfo?: PermissionRoleInterface ;
  successCallback: (value: boolean) => void
}


export default function   EmployeeRowForm({  permissionSetDetail,costCenterData, successCallback, currentItemInfo }: ComponentProps) {

  const [openPermissionSetStatus, { open: openPermissionSetModal, close: closePermissionSetModal }] = useDisclosure(false);
  // Get translation public configuration information
  const word_translation = WordTranslationComponent();
  //加载中状态
  const [loading, setLoading] = useState(false);
  const [is_load, setIsLoad] = useState(false);
  
  const form = useForm({
    validateInputOnBlur: true,
    initialValues: {
      title: currentItemInfo? currentItemInfo.title : '',
      id: currentItemInfo? currentItemInfo.id_permission_role : '',
      costCenter_id: currentItemInfo? currentItemInfo.costCenter_id : '',
      identifier:currentItemInfo? currentItemInfo.identifier : '',
      description: currentItemInfo? currentItemInfo.description : '',
      active:currentItemInfo? currentItemInfo.active : '0',
      role_permissions:[]
    },
    validate: {
      title: hasLength({ min: 1, max: 255 }, `${word_translation.validata_value_need_1_150_length}`),
          description: hasLength({ min: 1, max: 255 }, `${word_translation.validata_value_need_1_255_length}`),
          identifier: hasLength({ min: 1, max: 5 }, `${word_translation.validata_value_need_1_5_length}`),

    },
  });

  useEffect(() => {
    form.setFieldValue('role_permissions' ,permissionSetDetail.map((item,index) =>({
      permission_ids:item.permission_ids,
      permission_identifier_id:item.permission_identifier_id,
      all_flag:item.all_flag
     }
    )) as [] )  
        
  },[permissionSetDetail])
  // PermissionSetForm CallBack  and  set form.values.role_permissions
 const PermissionSetFormCallBackFunction=(value:any)=>{
     form.setFieldValue('role_permissions' ,value as [] )
    console.log('PermissionSetFormCallBackFunction', value);

 }
  const handleFormSubmit = async () => {
    // Validate the form
    const valid = await form.validate();

    // If there are validation errors, display error information
    if (valid.hasErrors) {
      HintErrorMantineInfo(valid);
      return false;
    }
    const methodType = form.values.id ? "PUT" : "POST";

    try {
      setLoading(true);
      const response = await requestPermissionRole(form.values, methodType);
      const result = response.data;
      // Display information based on the response
      HintInfo(response.data);
      if (result.code === 200) {
        // Reset the form and invoke the callback
        form.reset();
        successCallback(true);
      } else {
        HintInfoError(result.msg)
      }
    } catch (error) {
      HintInfoCatchError();
    } finally {
      setLoading(false);
    }

  };



  return (

    <>
   
        <LoadingOverlay visible={loading} overlayBlur={2} />
        <Box px={10}>
          <TableTextInputTitle title={word_translation.permissions_title} />
          <TextInput
            required
            placeholder={word_translation.Input}
            rightSection={<ActionIcon onClick={()=>openPermissionSetModal()}> <IconLicense color="red" /></ActionIcon>}
            {...form.getInputProps("title")}
          />
          <TableTextInputTitle title={word_translation.select_cost_center} />
          <Select
            labelProps={{ className: "input_title" }}
            onChange={(value) => form.setFieldValue("costCenter_id", value as string)}
            placeholder={word_translation.select_cost_center}
            searchable
            key={form.values.costCenter_id}
            defaultValue={form.values.costCenter_id?.toString()}
            data={costCenterData}
          />
          <TableTextInputTitle title={word_translation.identifier} />

          <TextInput
            required
            disabled={currentItemInfo?.id_permission_role ? true:false}
            placeholder={word_translation.Input_max_5}
            {...form.getInputProps("identifier")}
          />
          <TableTextInputTitle title={word_translation.description} />
          <Textarea
            minRows={4}
            placeholder={word_translation.Input_max_255}
            {...form.getInputProps("description")}
          />
          <Checkbox
            mt={10}
            size="xs"
            label={
              <TableTextInputTitle py={0} title={word_translation.active} />}
            checked={form.values.active == '1' ? true : false}
            onChange={(event) => { form.setFieldValue('active', event.currentTarget.checked ? '1' : '0') }}
          />
          <Group position="apart" mt={5}>

            <UniversalButton btn_bg_color='#868E96' btn_text={word_translation.cancel} btn_size='xs' callBack={()=>{}} />
            <UniversalButton btn_text={form.values.id ?  word_translation.edit : word_translation.add} btn_size='xs' callBack={() => handleFormSubmit()} />
          </Group>
              
          <PermissionSetForm
                is_load = {is_load}
                currentItem={form.values.role_permissions}
                successCallback={PermissionSetFormCallBackFunction} 
                closeFunction={closePermissionSetModal} 
                openFormStatus={openPermissionSetStatus} />

        <Code block mt={5}>
          {JSON.stringify(form.values, null, 2)}
        </Code>

      </Box>
    </>
  );
}
