import { Box, Grid, Select, TextInput, } from "@mantine/core";

import GlobalCoreSetting from "../../../utils/globalCortSetting";
import WordTranslationComponent from "../../../utils/language_pack/words";
import TableTextInputTitle from "../../../ttcomponents/TableTextInputTitle/TableTextInputTitle";
import TableTopTitle from "../../../ttcomponents/TableTopTitle/TableTopTitle";
import { DateInput } from "@mantine/dates";
import { useState } from "react";
import { formatDateUpgraded } from "../../../utils/function";


interface ComponentInterface {
    setFormValue: (data: any) => any;
    formValue: any
}


/**
 * Extras 
 * @returns 
 */
export default function Extras({ setFormValue, formValue }: ComponentInterface) {

    const [rand_number, setRandNumber] = useState<number>(1);
    /**
     * handle Data
     */
    const handleData = async (event: any, item: any = '') => {
        console.log(event, item)
        var name = item;
        var value = event;
        if (item) {
            if (item == 'start_date' || item == 'end_date') {
                value = formatDateUpgraded(value as Date, "YYYY-MM-DD")
            }
        } else {
            // 从 event.currentTarget 获取 name 和 value
            name = event.currentTarget.name;
            value = event.currentTarget.value;
        }
        // if((name =="work_hours_week" || name =="working_days_week" || name =="vacation_day_year" ) && !isNumber(value)){
        //     return ClientWarningHint(`${name} Must be a number`);
        // }
        // 更新状态
        setFormValue((prevData: any) => {
            return {
                ...prevData,
                [name]: value // 使用 name 作为属性名动态更新状态
            };
        });
        setRandNumber(rand_number + 1)
    };

    // Get the default unchanged configuration
    const globalCoreSetting = GlobalCoreSetting();

    // Get translation public configuration information
    const word_translation = WordTranslationComponent();
    return (
        <Box className="border-radius-xs min-h-25 border-custom">
            <TableTopTitle title={word_translation.extras} />
            <Box className="pl-x-15 pt-xs pt-4">
                <Grid>
                    <Grid.Col span={5} >
                        <TableTextInputTitle title={word_translation.boarding_status} />
                        {/* <Select
                                value='1'
                                data={globalCoreSetting.ONBOARDING_STATUS}
                                placeholder={word_translation.pick_one}
                                onChange={(value) =>
                                    handleData("boarding_status", value as string)
                                }
                                /> */}
                        <TextInput
                            name="stuff_no"
                            disabled
                            value='onboarding'
                        />
                        <TableTextInputTitle
                            title={word_translation.offset_overtime}
                        />
                        <TextInput
                            onChange={(event) => { handleData(event) }}
                            name="offset_overtime"
                            defaultValue={formValue?.offset_overtime}
                        />

                        {/* <TableTextInputTitle
                            title={word_translation.active}
                        />
                         <Select
                            labelProps={{ className: "input_title" }}
                            onChange={(value) =>handleData(value, 'active') }
                            placeholder={word_translation.pick_one}
                            searchable
                            data={[{value:"1",label:'Yes'},{value:'0',label:"No"}]}
                        /> */}

                        {formValue?.role_identifier < 900000 &&
                            <> 
                             {/* <TableTextInputTitle
                                title={word_translation.timeTracker_licence}
                            />
                                <TextInput
                                    onChange={(event) => { handleData(event) }}
                                    name="timetracker_licence"
                                    defaultValue={formValue?.timeTracker_licence}
                                /> */}
                            </>}

                    </Grid.Col>
                    <Grid.Col offset={1} span={5} >
                        <TableTextInputTitle
                            title={word_translation.contract_start_time}
                        />
                        <DateInput
                            onChange={(event) => { handleData(event, 'start_date') }}
                            name="start_date"
                            placeholder="Date input"
                            valueFormat="DD/MM/YYYY"
                            key={formValue?.start_date + rand_number}
                            defaultValue={formValue?.start_date && formValue?.start_date != '0000-00-00' ? new Date(formValue.start_date) : null}
                            maw={400}
                            mx="auto"
                        />
                        <TableTextInputTitle
                            title={word_translation.contract_end_time}
                        />

                        <DateInput
                            onChange={(event) => { handleData(event, 'end_date') }}
                            name="end_time"
                            placeholder="Date input"
                            minDate={formValue?.start_date ? new Date(formValue?.start_date) : new Date()}
                            valueFormat="DD/MM/YYYY"
                            key={formValue?.end_date}
                            defaultValue={formValue?.end_date && formValue?.end_date != '0000-00-00' ? new Date(formValue.end_date) : null}
                            maw={400}
                            mx="auto"
                        />
                        {formValue?.role_identifier < 900000 &&
                            <>
                                {/* <TableTextInputTitle
                                    title={word_translation.Leader_of_department}
                                />
                                <TextInput
                                    onChange={(event) => { handleData(event) }}
                                    name="leader_of_department"
                                    defaultValue={formValue?.leader_of_department}
                                />
                                <TableTextInputTitle
                                    title={word_translation.Leader_of_projects}
                                />
                                <TextInput
                                    onChange={(event) => { handleData(event) }}
                                    name="leader_of_projects"
                                    defaultValue={formValue?.Leader_of_projects}
                                /> */}
                            </>}

                    </Grid.Col>
                </Grid>
            </Box>
        </Box>
    )
}
