import {
  Paper,
  TextInput,
  Box,
  Group,
  Text,
  Modal,
  Grid,
  Table,
  ActionIcon,
  LoadingOverlay,
  Tooltip,
} from "@mantine/core";
import { FormEvent, useEffect, useState } from "react";
import { Supplier, SuppliersData } from "../../../interface/Iinventory";
import { useDisclosure } from "@mantine/hooks";

import PageTitle from "../../../ttcomponents/PageTitle/PageTitle";
import {
  IconPencil,
  IconSearch,
  IconTrash,
  IconUserPlus,
  IconUsersMinus,
} from "@tabler/icons-react";
import CreateBtn from "../../../ttcomponents/Button/CreateBtn";
import { useIntl } from "react-intl";
import { useForm } from "@mantine/form";
import { modals } from "@mantine/modals";
import SupplierForm from "./supplier_form";
import { regGetSupplier, regSupplier } from "../../../api";
import { HintInfo } from "../../../utils/function";
import SearchBox from "../../../ttcomponents/SearchBox/SearchBox";
import SupplierContactIndex from "../supplier_contact/supplier_contact_index";
import { IPagination } from "../../../interface/Ipagintion";
import PaginationA from "../../../ttcomponents/Pagination/PaginationA";

export default function SupplierIndex() {
  // useIntl for multiple language
  const intl = useIntl();
  // Edit modal status
  const [openedEdit, { open: openEdit, close: closeEdit }] =
    useDisclosure(false);

  // contact modal status
  const [contactShow, { open: opencontactShow, close: colsecontactShow }] =
    useDisclosure(false);

  const [loading, setLoading] = useState(false);
  // Form type
  const [formType, setFormType] = useState("create");
  const [currentRowInfo, setCurrentRowInfo] = useState<Supplier | undefined>();

  // define the supplier list
  const [supplierList, setSupplierList] = useState<SuppliersData>({
    total: 0,
    data: [], // Use the data property from the first item in the mock data array
  });

  const [pagination, setPagination] = useState<IPagination>({
    search: "",
    page: 1,
    pagesize: 10,
    sortBy: "",
    sortOrder: "asc",
    query: {},
  });
  // 接收回调返回数据，搜索内容  Receive the callback return data, search for content
  const ReceiveSearchBack = (value: string | number) => {
    setPagination((prevpagination) => ({
      ...prevpagination,
      search: value,
      query: {},
    }));
  };
  // 分页回调触发更新 分页回调触发更新
  const ReceivePaginationABack = (page: number) => {
    setPagination((prevpagination) => ({
      ...prevpagination,
      page: page,
    }));
  };
  // set search value
  const [search, setSearch] = useState("");

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSearch(value);
  };
  //Define an asynchronous function SearchFunction for setting up search conditions
  const searchFunction = async (value: string) => {
    setSearch(value); // Set the transmitted value to search conditions
  };

  // Define an asynchronous function ajaxcurrenTlist to send AJAX request to obtain the list of suppliers
  const ajaxCurrentList = async () => {
    setLoading(true); // Set the loading status to TRUE, indicating that the data is loading
    let searchStr = {};

    // Check whether there are search conditions, if so, build a search parameter object
    if (search) {
      searchStr = {
        search: search,
      };
    }

    // Send GET requests to get supplier data
    const responseSupplier = await regGetSupplier(pagination, "GET");

    setLoading(false); // After the data is loaded, set the loading status to false
    if (responseSupplier.data.code === 200) {
      setSupplierList(responseSupplier.data.data); // 如果响应状态码为200，将供应商列表更新为响应数据中的数据
    }
  };
  // Use the USEEFFECT hook. When Search changes, call the AjaxCurrenTList function
  useEffect(() => {
    ajaxCurrentList();
  }, [pagination]);

  // Edit supplier
  const handleEdit = (row: Supplier) => {
    setFormType("edit");
    openEdit();
    setCurrentRowInfo(row);
  };

  // Delete supplier
  const handleDel = (row: Supplier) => {
    modals.openConfirmModal({
      title: "Delete this row",
      centered: true,
      children: (
        <Text size="sm" mt={12}>
          Supplier Name: {row.supplier_name}
        </Text>
      ),
      labels: {
        confirm: intl.formatMessage({
          id: "confirm_delete",
          defaultMessage: "Confirm Delete",
        }),
        cancel: intl.formatMessage({
          id: "cancel_delete",
          defaultMessage: "Cancel Delete",
        }),
      },
      confirmProps: { color: "red" },
      onConfirm: () => delSupplierInfo(row.supplier_id),
    });
  };

  // Delete supplier info to backend
  const delSupplierInfo = async (id: number) => {
    const SupplierResult = await regSupplier({ supplier_id: id }, "DELETE");
    HintInfo(SupplierResult.data);
    if (SupplierResult.data.code == 200) ajaxCurrentList();
  };

  // Create supplier
  const handleCallback = () => {
    setFormType("create");
    openEdit();
    setCurrentRowInfo(undefined);
  };

  const contackList = (row: Supplier) => {
    opencontactShow();
    setCurrentRowInfo(row);
  };

  // Create a row for each supplier
  const rows = supplierList.data.map((row) => (
    <tr key={row.supplier_id} className="border-bt-2 my-10 ">
      <td>{row.supplier_name}</td>
      <td>{row.tax_identification_number}</td>
      <td>{row.address}</td>
      <td>{row.city}</td>
      <td>{row.postal_code}</td>
      <td>{row.tel}</td>
      <td>{row.email}</td>
      <td>
        <Group spacing={0} position="left">
          <ActionIcon onClick={() => contackList(row)}>
            <Tooltip label="Contact">
              <IconUsersMinus size="1rem" stroke={1.5} />
            </Tooltip>
          </ActionIcon>

          <ActionIcon onClick={() => handleEdit(row)}>
            <Tooltip label="Edit">
              <IconPencil size="1rem" stroke={1.5} />
            </Tooltip>
          </ActionIcon>

          <ActionIcon onClick={() => handleDel(row)}>
            <Tooltip label="Delete">
              <IconTrash size="1rem" stroke={1.5} color="red" />
            </Tooltip>
          </ActionIcon>
        </Group>
      </td>
    </tr>
  ));
  const closeEditFunction = (type: number) => {
    closeEdit();
    if (type == 2) {
      ajaxCurrentList();
    }
  };
  // list all the suppliers
  return (
    <Box>
      <Paper px={20} pb={10} w={"100%"} style={{ position: "relative" }}>
        <PageTitle title="SUPPLIER MANAGEMENT" lang_id="SUPPLIER MANAGEMENT" />
        {/* <Group py={20}>
          <SearchBox callBack={searchFunction} />
          <Box h={30}>
            <CreateBtn callBack={handleCallback} />
          </Box>
        </Group> */}
        <Group my={10}>
          <SearchBox callBack={ReceiveSearchBack} />
          <Box h={30}>
            <CreateBtn callBack={handleCallback} />
          </Box>
        </Group>
        <Grid gutter={0}>
          <LoadingOverlay visible={loading} overlayBlur={2} />

          <table className="table_custome text-center">
            <tr className="table_th">
              <th>
                {intl.formatMessage({
                  id: "Supplier Name",
                  defaultMessage: "Supplier Name",
                })}
              </th>
              <th>
                {intl.formatMessage({
                  id: "TIN",
                  defaultMessage: "TIN",
                })}
              </th>
              <th>
                {intl.formatMessage({
                  id: "Address",
                  defaultMessage: "Address",
                })}
              </th>
              <th>
                {intl.formatMessage({
                  id: "City",
                  defaultMessage: "City",
                })}
              </th>
              <th>
                {intl.formatMessage({
                  id: "Postal Code",
                  defaultMessage: "Postal Code",
                })}
              </th>
              <th>
                {intl.formatMessage({
                  id: "Tel",
                  defaultMessage: "Tel",
                })}
              </th>
              <th>
                {intl.formatMessage({
                  id: "Email",
                  defaultMessage: "Email",
                })}
              </th>
              <th>
                {intl.formatMessage({
                  id: "Option",
                  defaultMessage: "Option",
                })}
              </th>
            </tr>
            {rows}
          </table>
        </Grid>{" "}
        <PaginationA
          callBack={ReceivePaginationABack}
          total_count={supplierList.total as number}
          pagesize={pagination.pagesize}
          page={pagination.page}
        />
      </Paper>

      <Modal
        opened={openedEdit}
        onClose={closeEdit}
        title={formType === "create" ? "Create Supplier" : "Edit Supplier"}
        centered={true}
        size="60%"
        padding="md"
        radius={12}
      >
        <SupplierForm
          supplieritem={currentRowInfo}
          closeFucntion={closeEditFunction}
        />
      </Modal>

      <Modal
        opened={contactShow}
        onClose={colsecontactShow}
        title=" Supplier Contact"
        // centered={true}
        size="60%"
        padding="md"
        radius={12}
      >
        <SupplierContactIndex supplier={currentRowInfo} />
      </Modal>
    </Box>
  );
}
