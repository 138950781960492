import { Box ,Text} from "@mantine/core"
import { CSSProperties } from "react";

interface ComponetntInterface{
    title:string,
    class_name?:string,
    style?: CSSProperties;
}
export default function LeaveManageRowTh({title,class_name, style}:ComponetntInterface){
    return (
        <th className="text-center" style={style}>
            <Text className={class_name}>
                {title}
            </Text>
        </th>
    )
}