import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  regCustomerList,
  regEmployeerolesList,
  regGeOrder,
  regGetTaskList,
  regOrder,
  regTaskCategory,
  regTaskList,
  regUserAll,
} from "../../../api";
import { APIResponse, Project } from "../../../interface/Iprojects";
import {
  ActionIcon,
  Avatar,
  Badge,
  Box,
  Grid,
  Group,
  LoadingOverlay,
  Modal,
  Paper,
  Text,
  Tooltip,
} from "@mantine/core";
import {
  IconList,
  IconPencil,
  IconSubtask,
  IconTrain,
  IconTrash,
} from "@tabler/icons-react";
import PaginationA from "../../../ttcomponents/Pagination/PaginationA";
import PageTitle from "../../../ttcomponents/PageTitle/PageTitle";
import SearchBox from "../../../ttcomponents/SearchBox/SearchBox";
import { IPagination } from "../../../interface/Ipagintion";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import OrderEditForm from "../order_edit_form";
import OrderOnlyEditForm from "./order_only_edit";
import { IOrderProps, IOrderPropsUp } from "../../../interface/Iorder";
import { ResponseUserData, UserAllList } from "../../../interface/Iuser";
import { SelectRole } from "../../../interface/Icommon";
import memoryUtils from "../../../utils/memoryUtils";
import { EmployeeRoleResponseApi } from "../../../interface/Iemloyee";
import TaskEditFrom from "../../assignments/task/task_from";
import TaskTable from "../../assignments/task/task_table";
import {
  ITask,
  ITaskResponseNopage,
  TaskInterFace,
} from "../../../interface/Itask";
import { ResponseCustomerData } from "../../../interface/Icoustomer";
import localstorageUnits from "../../../utils/localstorageUnits";

export default function OrderOnlyIndex() {
  // multilanguage
  const intl = useIntl();

  const taskModeFlag = localstorageUnits.getSystem().task_mode_flag;
  const ProjectOrderTaskModel = taskModeFlag == 1;
  const ProjectTaskModel = taskModeFlag == 2;
  const OrderModel = taskModeFlag == 3;
  const ProjectOrder = taskModeFlag == 4;
  const OrderNotaskModel = taskModeFlag == 5;
  const [orderAction, { open: openOrderFrom, close: closeOrderFrom }] =
    useDisclosure(false);

  const [addTaskAction, { open: openTaskFrom, close: closeTaskFrom }] =
    useDisclosure(false);

  const [TaskAction, { open: openTaskModal, close: closeModal4 }] =
    useDisclosure(false);

  const [loading, setLoading] = useState(false);
  // 设置用户承接信息列表
  const [userlist, setUserList] = useState<SelectRole[]>([]);
  // 设置详细用户承接信息列表
  const [UserList, setResultUser] = useState<UserAllList[]>([]);
  // 设置员工角色承接信息列表
  const [employeesList, setEmployeesList] = useState<SelectRole[]>([]);
  // 设置任务类型承接信息列表
  const [taskcateGoryListData, setTaskcateGoryListData] = useState<
    SelectRole[]
  >([]);
  // 设置客户承接信息列表
  const [customerlist, setCustomerList] = useState<SelectRole[]>([]);

  const [userId, setUseId] = useState(memoryUtils.user.id_user);
  // 初始化角色类型
  const [userType, setUserType] = useState(memoryUtils.user.user_type);

  const [orderItem, setOrderItem] = useState<IOrderProps>();

  const [orderDetail, setOrderDetail] = useState<IOrderProps>();

  const [openedEdit, { open: openEdit, close: closeEdit }] =
    useDisclosure(false);
  const [modalType, setModalType] = useState<string>("create");
  const [row, setRow] = useState<any>({});

  // Receive pagination back
  const ReceivePaginationABack = (page: number) => {
    setPagination((prevpagination) => ({
      ...prevpagination,
      page: page,
    }));
  };
  // Receive search back
  const ReceiveSearchBack = (value: string | number) => {
    setPagination((prevpagination) => ({
      ...prevpagination,
      search: value,
      query: {},
    }));
  };

  // pagination
  const [pagination, setPagination] = useState<IPagination>({
    page: 1,
    pagesize: 10,
    limit: 10,
    search: "",
    sortBy: "",
    sortOrder: "desc",
    order_type: "no_project",
  });

  // list count
  const [listCount, setListCount] = useState<number>(0);
  const [listData, setListData] = useState<IOrderPropsUp[]>([]);

  // when index page is loaded, it will be called
  useEffect(() => {
    ajaxCurrentList();
  }, [pagination]);

  // get order only data
  const ajaxCurrentList = async () => {
    setLoading(true);
    const response = await regOrder(pagination, "GET");
    const result = response.data;
    if (result.code === 200) {
      setListCount(result.data.total_count);
      setListData(result.data.data);
    }
    setLoading(false);
  };

  // handle edit
  // const handleEdit = (row: any) => {
  //   setModalType("edit");
  //   openEdit();
  //   console.log("edit row: ", row);
  // };

  const getUserList = async () => {
    const responseUser = await regUserAll({});
    const resultUser = responseUser.data as ResponseUserData;
    const userData = resultUser.data.map((item) => ({
      value: item.id_user.toString() as string,
      label: item.username,
    }));
    setResultUser(responseUser.data.data);
    setUserList(userData);
  };

  const getEmployeerolesList = async () => {
    const responseEmployeerolesList = await regEmployeerolesList({}, "GET");
    const resultEmployeerolesList =
      responseEmployeerolesList.data as EmployeeRoleResponseApi;
    const EmployeesListData = resultEmployeerolesList.data.map((item) => ({
      value: item.id_employee_roles as string,
      label: item.role_name,
    }));
    setEmployeesList(EmployeesListData);
  };

  const getTaskCategoryList = async () => {
    const cateGoryInfo = await regTaskCategory({});
    if (cateGoryInfo.data.code == 200) {
      const taskcateGoryData = cateGoryInfo.data.data.data.map(
        (item: { id_task_category: string; identifier: any }) => ({
          value: item.id_task_category as string,
          label: item.identifier,
        })
      );
      setTaskcateGoryListData(taskcateGoryData);
    }
  };

  const handleEdit = async (item: IOrderProps) => {
    // get user and single order
    setLoading(true);
    getUserList();
    getCustomerList();
    // if(orderDetail){
    const responseOrderDetail = await regGeOrder(item.id_order as string);
    const resultOrderDetail = responseOrderDetail.data as IOrderProps;
    const orderDetailData = resultOrderDetail.data;
    setOrderDetail(orderDetailData);
    setOrderItem(item);
    // }

    // handle order

    setLoading(false);
    openOrderFrom();
  };
  // handle delete
  const handleDel = (row: any) => {
    modals.openConfirmModal({
      title: "Confirm Deletion",
      centered: true,
      children: (
        <Text size="sm" mt={12}>
          Are you sure you want to delete this item?
        </Text>
      ),
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onConfirm: () => delOrderRow(row.id_order),
    });
  };

  const delOrderRow = async (id: number) => {
    console.log("delete row id: ", id);
  };

  const handleOrderEditFromFunction = async (value?: any) => {
    if (value && value.type == "refresh") {
      const responseOrderDetail = await regGeOrder(value.id);
      const resultOrderDetail = responseOrderDetail.data as IOrderProps;
      const orderDetailData = resultOrderDetail.data;
      setOrderDetail(orderDetailData);
    } else {
      closeOrderFrom();
      ajaxCurrentList();
    }
  };

  // 添加任务
  const addTask = async (value: IOrderProps) => {
    setOrderItem(value);
    // 获取user
    setLoading(true);
    getUserList();
    // 获取所有员工角色
    getEmployeerolesList();
    // 获取任务类型
    getTaskCategoryList();
    setLoading(false);
    openTaskFrom();
  };

  // 回调地址
  const handleTaskFromCallBack = async (id_project: string) => {
    closeTaskFrom();
  };

  // index rows data
  const rows = listData?.map((row) => {
    const isLeader =
      (row.order_leader_ids &&
        row.order_leader_ids.split(",").includes(userId.toString())) ||
      userType == "1";

    return (
      <tr key={row.id_order} className="border-bt-2 my-10 ">
        <td>{row.order_name}</td>
        <td>{row.order_no}</td>
        <td>
          {row.is_internal == 1 ? (
            <Badge color="blue">Y</Badge>
          ) : (
            <Badge color="dark">No</Badge>
          )}
        </td>
        <td>
          {row.service_charge == 1 ? (
            <Badge color="blue">Y</Badge>
          ) : (
            <Badge color="dark">No</Badge>
          )}
        </td>
        <td>{row.time_consumption}</td>
        <td>{row.end_date}</td>
        <td className="">
          <Group>
            <Avatar.Group spacing="sm">
              {row.order_leader_info.map(
                (item_leader: any, item_leader_index: any) => (
                  <>
                    {" "}
                    <Tooltip
                      label={item_leader.username}
                      key={item_leader.username}
                    >
                      <Avatar src={item_leader.avatar} radius={40} size={26} />
                    </Tooltip>{" "}
                    <Text size="sm" fw={500}>
                      {item_leader.username}
                    </Text>
                  </>
                )
              )}
            </Avatar.Group>
          </Group>
        </td>
        <td>{row.company_name}</td>
        <td>
          <Badge
            color={
              row.status_id == "1"
                ? "green"
                : row.status_id == "2"
                ? "yellow"
                : row.status_id == "3"
                ? "orange"
                : row.status_id == "4"
                ? "red"
                : row.status_id == "5"
                ? "blue"
                : "gray"
            }
          >
            {row.status_name}
          </Badge>
        </td>
        <td>
          <Group position="center" spacing="xs">
            <ActionIcon onClick={isLeader ? () => handleEdit(row) : undefined}>
              <Tooltip label="Edit">
                <IconPencil
                  size="1rem"
                  color={isLeader ? "#666" : "#ccc"}
                  stroke={1.5}
                />
              </Tooltip>
            </ActionIcon>
            {OrderNotaskModel ? null : (
              <>
                <Tooltip label="add task" disabled={isLeader ? false : true}>
                  <ActionIcon
                    className={isLeader ? "" : "actionIcon-w-1-nohl"}
                    onClick={isLeader ? () => addTask(row) : undefined}
                  >
                    <IconSubtask
                      color={isLeader ? "#666" : "#ccc"}
                      size="1rem"
                      stroke={1.5}
                    />
                  </ActionIcon>
                </Tooltip>
                <Tooltip label=" Task list">
                  <ActionIcon onClick={() => listTask(row)}>
                    <IconList size="1rem" color="#666" stroke={1.5} />
                  </ActionIcon>
                </Tooltip>
              </>
            )}

            <ActionIcon onClick={() => handleDel(row)}>
              <Tooltip label="Delete">
                <IconTrash size="1rem" stroke={1.5} color="red" />
              </Tooltip>
            </ActionIcon>
          </Group>
        </td>
      </tr>
    );
  });

  const [taskList, setTaskList] = useState<TaskInterFace[]>([]);

  const getTaskList = async (value: IOrderProps) => {
    const Taskresponser = await regGetTaskList({ order_id: value.id_order });
    const resultTask = Taskresponser.data;
    setTaskList(resultTask.data);
  };
  // 查看所有任务
  const listTask = async (value: IOrderProps) => {
    setOrderItem(value);
    getTaskList(value);

    getUserList();
    getEmployeerolesList();
    // 获取所有员工角色
    openTaskModal();
  };

  // Treatment to modify and delete Task's callback
  const handleTaskTableCallback = async (value: IOrderProps) => {
    setOrderItem(value);
    getTaskList(value);
  };

  const refreashTable = async (value: string) => {
    setLoading(true);
    const Taskresponser = await regGetTaskList({ order_id: value });
    const resultTask = Taskresponser.data;
    setTaskList(resultTask.data);
    setLoading(false);
  };
  // 获取客户列表
  const getCustomerList = async () => {
    const response = await regCustomerList({});
    const result = response.data as ResponseCustomerData;
    if (result.code === 200) {
      const customerData = result.data.map((item) => ({
        value: item.id_customer as string,
        label: item.company_name,
      }));
      setCustomerList(customerData);
    }
  };

  return (
    <>
      <Box>
        <Paper px={20} pb={10} w={"100%"} style={{ position: "relative" }}>
          <PageTitle
            title="ORDER ONLY MANAGEMENT"
            lang_id="ORDER ONLY MANAGEMENT"
          />
          <Group my={12}>
            <SearchBox callBack={ReceiveSearchBack} />
          </Group>
          <Grid gutter={0}>
            <table className="table_custome text-center">
              <LoadingOverlay visible={loading} overlayBlur={2} />
              <tr className="table_th">
                <th>
                  {intl.formatMessage({
                    id: "Order No",
                    defaultMessage: "Order No",
                  })}
                </th>
                <th>Order name</th>
                <th>Internal orders</th>
                <th>Service fee</th>
                <th>Time consumption</th>
                <th>Completion time</th>
                <th>Leader</th>
                <th>Customer name</th>
                <th>
                  {intl.formatMessage({
                    id: "status",
                    defaultMessage: "status",
                  })}
                </th>
                <th>
                  {intl.formatMessage({
                    id: "Option",
                    defaultMessage: "Option",
                  })}
                </th>
              </tr>
              {rows}
            </table>
          </Grid>{" "}
          {listCount > pagination.pagesize && (
            <PaginationA
              callBack={ReceivePaginationABack}
              total_count={listCount}
              pagesize={pagination.pagesize}
              page={pagination.page}
            />
          )}
        </Paper>

        <Modal
          opened={orderAction}
          onClose={closeOrderFrom}
          title={
            modalType === "create"
              ? intl.formatMessage({
                  id: "Add New Order",
                  defaultMessage: "Add New Order",
                })
              : intl.formatMessage({
                  id: "Edit Order",
                  defaultMessage: "Edit Order",
                })
          }
          centered={true}
          size="60%"
          padding="md"
          radius={12}
        >
          <OrderEditForm
            customerlist={customerlist}
            callBack={handleOrderEditFromFunction}
            userlist={userlist}
            orderItem={orderItem}
            orderDetail={orderDetail}
          />
        </Modal>

        <Modal
          size="70%"
          opened={addTaskAction}
          onClose={closeTaskFrom}
          title="Create Task"
        >
          <TaskEditFrom
            taskcateGoryListData={taskcateGoryListData}
            orderItem={orderItem}
            callBack={handleTaskFromCallBack}
            employeesList={employeesList}
            userlist={userlist}
            UserLists={UserList}
          />
        </Modal>

        <Modal
          size="75%"
          opened={TaskAction}
          onClose={closeModal4}
          title="Show Task"
        >
          <TaskTable
            refreashTable={refreashTable}
            taskList={taskList}
            orderItem={orderItem}
            employeesList={employeesList}
            userlist={userlist}
            UserLists={UserList}
            callBack={handleTaskTableCallback}
          />
        </Modal>
      </Box>
    </>
  );
}
