import { ActionIcon, Avatar, Box, Group, LoadingOverlay, ScrollArea, Table, Tooltip, Text } from "@mantine/core";
import { reAttributeType, reGetEmployeeCustomAttributeList, reGetEmployeeCustomAttributetTypesList } from "../../../../api";
import { Key, useEffect, useState } from "react";
import { Attribute, AttributeType } from "../../../../interface/Iuser";
import cx from 'clsx';
import classes from './custom_attrbutes/TableScrollArea.module.css';
import { IconDots, IconEdit, IconGripVertical, IconTrash } from "@tabler/icons-react";
import WordTranslationComponent from "../../../../utils/language_pack/words";
import { modals } from "@mantine/modals";
import { HintInfo } from "../../../../utils/function";
import GlobalCoreSetting from "../../../../utils/globalCortSetting";
import { IconDotsVertical } from "@tabler/icons-react";
import { UserTaskInteface } from "../../../../interface/Itask";
import TaskListTr from "./task_list_tr";
import React from "react";

interface ComponentInterface {
    is_refresh: number;
    is_load: boolean;
    userTaskList?: UserTaskInteface[];
    callBackfunction: (value: UserTaskInteface) => void,
    callBack: (value: any) => void,
    callBackTaskIDfunction: (value: string,is_checked: boolean) => void,
    returnAttributeList: (value: UserTaskInteface[]) => void,
}
export default function TaskList({ is_refresh, userTaskList, callBack,is_load, callBackTaskIDfunction, returnAttributeList }: ComponentInterface) {

    const [loading, setLoading] = useState(false);
    // Get translation public configuration information
    const word_translation = WordTranslationComponent();
    // Get the global configuration
    const [attributeList, setAttributeList] = useState<UserTaskInteface[]>([]);


    const ajaxCurrentList = async () => {

        if (is_load === false) {
            return false;
        }
        setLoading(true); // Set the loading status to TRUE, indicating that the data is loading
        // Send GET requests to get Attribute Type data
        const responseAttributeTypeList = await reGetEmployeeCustomAttributetTypesList({}, "GET");
        setLoading(false); // After the data is loaded, set the loading status to false
        if (responseAttributeTypeList.data.code === 200) {
            setAttributeList(responseAttributeTypeList.data.data);
        }
        returnAttributeList(responseAttributeTypeList.data.data)
    };
    // Use the USEEFFECT hook. When Search changes, call the AjaxCurrenTList function
    useEffect(() => {
        ajaxCurrentList();
    }, [is_refresh]);

    
    return (
        <Box className="px-15 pt-xs pt-4">
            <LoadingOverlay visible={loading} overlayBlur={2} />
            <ScrollArea h={150} >
                <Table>
                    <thead className="scrolleder ">
                        <tr>
                            <th colSpan={2} id="table-th-title-new">{word_translation.task}</th>
                            <th id="table-th-title-new">{word_translation.description}</th>
                            <th id="table-th-title-new">{word_translation.task_team_leader}</th>
                            <th id="table-th-title-new">{word_translation.order_no}</th>
                            <th id="table-th-title-new">{word_translation.option}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userTaskList && userTaskList?.length > 0 && userTaskList?.map((item, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <TaskListTr  callDeleteBackfunction={callBack} callBackfunction={callBackTaskIDfunction} row={item} index={index} />
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </Table>
            </ScrollArea>
        </Box>
    )
}