import {
  Avatar,
  Card,
  Checkbox,
  Group,
  Select,
  Text,
  TransferList,
  TransferListData,
  TransferListItemComponent,
  TransferListItemComponentProps,
} from "@mantine/core";
import { IconDots, IconTrash, IconX } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { ApiUserResponse, UserAllList } from "../../../interface/Iuser";
import { EmployeeRoleResponseApi } from "../../../interface/Iemloyee";
import { SelectRole } from "../../../interface/Icommon";
import {
  regCustomerRole,
  regEmployeeroles,
  regEmployeerolesList,
  regItemListByType,
} from "../../../api";
import { notifications } from "@mantine/notifications";
import { filterProjectItem, filterTaskItem } from "../../../interface/IReport";
import { useIntl } from "react-intl";

const elements = [
  { position: 6, mass: 12.011, symbol: "C", name: "Carbon" },
  { position: 7, mass: 14.007, symbol: "N", name: "Nitrogen" },
  { position: 39, mass: 88.906, symbol: "Y", name: "Yttrium" },
  { position: 56, mass: 137.33, symbol: "Ba", name: "Barium" },
  { position: 58, mass: 140.12, symbol: "Ce", name: "Cerium" },
];

// 示例数据
const mockdata = [
  {
    id: "1",
    value: "ProjectA",
    label: "ProjectA",
    eamil: "Fascinated with cooking, though has no sense of taste",
  },

  {
    id: "2",
    value: "XingbaKe",
    label: "XingbaKe",
    eamil: "One of the richest people on Earth",
  },

  {
    id: "3",
    value: "Paiqun",
    label: "Paiqun",
    eamil: "One of the richest people on Earth",
  },

  {
    id: "4",
    value: "xuanziru",
    label: "xuanziru",
    eamil: "One of the richest people on Earth",
  },

  {
    id: "5",
    value: "Filter AA",
    label: "Filter AA",
    eamil: "One of the richest people on Earth",
  },
  // ...other items
];
interface UserDataListProps {
  callBack: (value: string) => void;
  taskData: filterTaskItem[];
  [key: string]: any;
}
export default function SelectTaskFilter({
  callBack,
  taskData,
}: UserDataListProps) {
  // Use the useIntl hook to access the intl object
  const intl = useIntl();

  const [data, setData] = useState<TransferListData>([mockdata, []]);
  const [datatest, setDatatest] = useState([]);

  // 请求user列表
  useEffect(() => {
    let transferList = taskData.map((item) => {
      return {
        value: item.id_task.toString() as string,
        id_task: item.id_task.toString() as string,
        name: item.identifier,
        label: item.name,
        order_id: item.order_id,
        description: item.identifier,
        order_name: item.order_name,
      };
    });
    setData([transferList, []]);
  }, [taskData]);
  const ItemComponent: TransferListItemComponent = ({
    data,
    selected,
  }: TransferListItemComponentProps) => (
    <Group noWrap>
      <div style={{ flex: 1 }}>
        <Text className="select-main">{data.name}</Text>
        <Text className="select-desc">order:{data.order_name}</Text>
        <Text className="select-desc">project: {data.label}</Text>
      </div>
      <Checkbox
        checked={selected}
        onChange={() => {}}
        tabIndex={-1}
        sx={{ pointerEvents: "none" }}
      />
    </Group>
  );
  const setDataHandle = (value: TransferListData) => {
    let selectedFilter = value[1]
      .map((item) => {
        return item.id_task;
      })
      .join(",");
    setData(value); // 更新 data 状态
    callBack(selectedFilter);
  };
  const [searchEmployeesValue, onEmployeesSearchChange] = useState("");
  return (
    <>
      <Text className="selectTitle  mb-3">
        {intl.formatMessage({
          id: "Search Item Filter",
          defaultMessage: "Search Item Filter",
        })}
      </Text>
      <TransferList
        value={data}
        onChange={setDataHandle}
        searchPlaceholder="Search "
        nothingFound={intl.formatMessage({
          id: "No One Here",
          defaultMessage: "No One Here",
        })}
        initialSelection={[datatest, []]}
        listHeight={300}
        className="input_title"
        breakpoint="sm"
        itemComponent={ItemComponent}
        filter={(query, item) =>
          item.label.toLowerCase().includes(query.toLowerCase().trim())
        }
      />
    </>
  );
}
