import { Box, Checkbox, Group } from '@mantine/core'
import { useEffect, useState } from 'react'
import React from 'react'
import { PermissionIdentifierInterface } from '../../../../interface/Iemloyee'
import { MenuActionItemInterFace } from '../../../../interface/Iuser'
import WordTranslationComponent from '../../../../utils/language_pack/words'
import { PermissionData } from './type'

interface ComponentInterface {
  PermissionIdentifierItem: PermissionIdentifierInterface
  PermissionAction: MenuActionItemInterFace[]
  value?: PermissionData
  setValue: React.Dispatch<React.SetStateAction<PermissionData[]>> // Function to set form value
  selectAll: boolean
}

// 原始的函数组件
const PermissionSetCheckBoxGroup = ({
  PermissionIdentifierItem,
  value,
  setValue,
  PermissionAction,
  selectAll
}: ComponentInterface) => {
  // Get translation public configuration information
  const word_translation = WordTranslationComponent()

  // current row select all
  const [checked, setChecked] = useState(false)

  const [isHovered, setIsHovered] = useState(false)

  // ===================== Select all state End =====================
  useEffect(() => {
    setChecked(selectAll)
  }, [selectAll])

  // useEffect(() => {
  //   if (checked) {
  //     // if is true, set all
  //     const allidentifiers = PermissionAction.map(item => item.identifier.toString())
  //     setValueCheckedFunction(allidentifiers)
  //   } else {
  //     // if is false, set []
  //     setValueCheckedFunction([])
  //   }
  // }, [checked])

  const handleTrMouseLeave = () => {
    setIsHovered(false)
  }
  /**
   * 当鼠标移入元素时，设置isHovered为true
   */
  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const setValueCheckedFunction = (v: string[]) => {
    setValue((prev: PermissionData[]) => {
      const index = prev.findIndex(item => item.permission_identifier == value?.permission_identifier)

      // aleary exist
      if (index !== -1) {
        const temp = [...prev]
        temp[index].permission_action = v
        return temp
      } else {
        return [
          ...prev,
          {
            permission_identifier: PermissionIdentifierItem.identifier,
            permission_action: v
          }
        ]
      }
    })
  }

  const handleSelectAllChange = (v: boolean) => {
    setChecked(v)
    if (v) {
      // if is true, set all
      const allidentifiers = PermissionAction.map(item => item.identifier.toString())
      setValueCheckedFunction(allidentifiers)
    } else {
      // if is false, set []
      setValueCheckedFunction([])
    }
  }

  return (
    <tr
      className="text-center justify-center position_relative  border-bt-1  items-center"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleTrMouseLeave}
    >
      <td className="text-left  input_title">
        <Checkbox.Group w={180} value={value?.permission_action} onChange={setValueCheckedFunction} withAsterisk>
          <Group className=""> {PermissionIdentifierItem.name} &nbsp;&nbsp; &nbsp;&nbsp;</Group>
        </Checkbox.Group>
      </td>
      <td colSpan={10}>
        <Checkbox.Group
          value={value?.permission_action}
          style={{ display: 'flex', justifyContent: 'space-between' }}
          onChange={setValueCheckedFunction}
          withAsterisk
        >
          {PermissionAction.map((item, key) => {
            return (
              <Checkbox
                w={50}
                className="border-left text-left input_title"
                pt={7}
                pl={20}
                key={PermissionIdentifierItem.id_permission_identifier + '-' + key}
                value={item.identifier.toString()}
              />
            )
          })}
        </Checkbox.Group>
      </td>

      <td>
        <Box w={50}>
          {isHovered && (
            <Checkbox
              mt={4}
              label={word_translation.all}
              checked={checked}
              onChange={event => handleSelectAllChange(event.currentTarget.checked)}
            />
          )}
        </Box>
      </td>
    </tr>
  )
}

// 使用 React.memo 进行包装
export default React.memo(PermissionSetCheckBoxGroup)
