import {
  ActionIcon,
  Paper,
  Flex,
  Select,
  Button,
  Code,
  Grid,
  Group,
  LoadingOverlay,
  Box,
  Menu,
  Modal,
  ScrollArea,
  Table,
  Text,
  ThemeIcon,
  Slider,
  Divider,
} from "@mantine/core";
import { useEffect, useState, FormEvent } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  HintInfo,
  formatTimeHHSS,
  getWeeKAll,
  getYearAll,
} from "../../../utils/function";
import { SelectRole, selectApiRespone } from "../../../interface/Icommon";
import {
  regGetFilter,
  regProject,
  regProjectList,
  regProjectOnly,
  regReportEmployeeReport,
  regReportsByFilter,
  regUserAll,
} from "../../../api";
import { ResponseUserData } from "../../../interface/Iuser";
import { IconCheck, IconClipboardPlus, IconX } from "@tabler/icons-react";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { WeeklyTimeTrackerReports } from "../../../interface/Ireports";
import {
  IFilterStatistic,
  detailProps,
  filterReportData,
  projectOrderFilter,
} from "../../../interface/IfilterStatistic";
import { useDisclosure } from "@mantine/hooks";
import FilterItemReports from "./filterItemReport";

export default function ProjectReportStastic() {
  // Use the useIntl hook to access the intl object
  const intl = useIntl();

  //Get all the weekly collection
  const weekNumberAll = getWeeKAll();
  //Get all the Year collection
  const yearNumberAll = getYearAll();
  // taskOnlyProjectData
  const [taskOnlyProjectData, setTaskOnlyProjectData] = useState<SelectRole[]>(
    []
  );
  // Get all the project collection
  // Get all employees
  const [userlistnew, setUserList] = useState<SelectRole[]>([]);
  const [reportData, setReportData] = useState<projectOrderFilter>();
  // 选择过滤项
  const [filterItemList, setFilterItemList] = useState<SelectRole[]>([]);
  const [filterUserList, setFilterUserList] = useState<SelectRole[]>([]);

  const [detailPropsData, setDetailPropsData] = useState<detailProps>({
    id: 0,
    start_week: "",
    end_week: "",
    type: "",
    year: "",
  });

  const [idNow, setIdNow] = useState(0);

  const [openedModal1, { open: openModal1, close: closeModal1 }] =
    useDisclosure(false);
  const [WeeklyTimeTracker, SetWeeklyTimeTracker] = useState<
    WeeklyTimeTrackerReports[]
  >([]);

  //Mid -load state
  const [loading, setLoading] = useState(false);
  // The data is initialized after the page is loaded
  const ajaxCurrentList = async () => {
    setLoading(true);
    // Get all the task only project lists

    const ProjectOnlytaskInfo = await regProjectOnly({});
    if (ProjectOnlytaskInfo.data.code == 200) {
      const ProjectOnlyListData = ProjectOnlytaskInfo.data.data.data.map(
        (item: {
          id_task_only_project: string;
          name: any;
          customer_id: string;
        }) => ({
          value: item.id_task_only_project as string,
          label: item.name,
          customer_id: item.customer_id,
        })
      );
      setTaskOnlyProjectData(ProjectOnlyListData);
    }

    const resFilter = await regGetFilter();
    const { data } = resFilter.data as IFilterStatistic;
    const itemFilterList = data.filter((item) => {
      return item.type === "1";
    });

    const users_filter = data.filter((item) => {
      return item.type === "2";
    });
    setFilterItemList(
      itemFilterList.map((item) => {
        return {
          label: item.filter_type + "---" + item.filter_name,
          value: item.id_filter,
        };
      })
    );

    setFilterUserList(
      users_filter.map((item) => {
        return {
          label: item.filter_name,
          value: item.id_filter,
        };
      })
    );

    let dataq3e12 = {
      week_number_list: [1, 2, 3, 4, 5],
      items_list: [
        {
          project_id: "1",
          project_name: "ProjectA",
          project_no: "ProjectA",
          order_list: [
            {
              order_id: "2",
              order_name: "OrderName",
              customer_list: [
                {
                  user_name: "Lixiang",
                  time_list: {
                    1: "00:00",
                    2: "00:00",
                    3: "00:00",
                    4: "00:00",
                    5: "00:00",
                  },
                },
                {
                  user_name: "Haiyang",
                  time_list: {
                    1: "00:00",
                    2: "00:00",
                    3: "00:00",
                    4: "00:00",
                    5: "00:00",
                  },
                },
                {
                  user_name: "Chengcheng",
                  time_list: {
                    1: "00:00",
                    2: "00:00",
                    3: "00:00",
                    4: "00:00",
                    5: "00:00",
                  },
                },
              ],
            },
            {
              order_id: "2",
              order_name: "OrderName222222",
              customer_list: [
                {
                  user_name: "Lixiang",
                  time_list: {
                    1: "00:00",
                    2: "00:00",
                    3: "00:00",
                    4: "00:00",
                    5: "00:00",
                  },
                },
                {
                  user_name: "Haiyang",
                  time_list: {
                    1: "00:00",
                    2: "00:00",
                    3: "00:00",
                    4: "00:00",
                    5: "00:00",
                  },
                },
                {
                  user_name: "Chengcheng",
                  time_list: {
                    1: "00:00",
                    2: "00:00",
                    3: "00:00",
                    4: "00:00",
                    5: "00:00",
                  },
                },
              ],
            },
            {
              order_id: "2",
              order_name: "OrderName33333",
              customer_list: [
                {
                  user_name: "Lixiang",
                  time_list: {
                    1: "00:00",
                    2: "00:00",
                    3: "00:00",
                    4: "00:00",
                    5: "00:00",
                  },
                },
                {
                  user_name: "Haiyang",
                  time_list: {
                    1: "00:00",
                    2: "00:00",
                    3: "00:00",
                    4: "00:00",
                    5: "00:00",
                  },
                },
                {
                  user_name: "Chengcheng",
                  time_list: {
                    1: "00:00",
                    2: "00:00",
                    3: "00:00",
                    4: "00:00",
                    5: "00:00",
                  },
                },
              ],
            },
          ],
        },
        {
          project_id: "23",
          project_name: "ProjectA",
          project_no: "ProjectA",
          order_list: [
            {
              order_id: "2",
              order_name: "OrderName",
              customer_list: [
                {
                  user_name: "Lixiang",
                  time_list: {
                    1: "00:00",
                    2: "00:00",
                    3: "00:00",
                    4: "00:00",
                    5: "00:00",
                  },
                },
                {
                  user_name: "Haiyang",
                  time_list: {
                    1: "00:00",
                    2: "00:00",
                    3: "00:00",
                    4: "00:00",
                    5: "00:00",
                  },
                },
                {
                  user_name: "Chengcheng",
                  time_list: {
                    1: "00:00",
                    2: "00:00",
                    3: "00:00",
                    4: "00:00",
                    5: "00:00",
                  },
                },
              ],
            },
            {
              order_id: "2",
              order_name: "OrderName222222",
              customer_list: [
                {
                  user_name: "Lixiang",
                  time_list: {
                    1: "00:00",
                    2: "00:00",
                    3: "00:00",
                    4: "00:00",
                    5: "00:00",
                  },
                },
                {
                  user_name: "Haiyang",
                  time_list: {
                    1: "00:00",
                    2: "00:00",
                    3: "00:00",
                    4: "00:00",
                    5: "00:00",
                  },
                },
                {
                  user_name: "Chengcheng",
                  time_list: {
                    1: "00:00",
                    2: "00:00",
                    3: "00:00",
                    4: "00:00",
                    5: "00:00",
                  },
                },
              ],
            },
            {
              order_id: "2",
              order_name: "OrderName33333",
              customer_list: [
                {
                  user_name: "Lixiang",
                  time_list: {
                    1: "00:00",
                    2: "00:00",
                    3: "00:00",
                    4: "00:00",
                    5: "00:00",
                  },
                },
                {
                  user_name: "Haiyang",
                  time_list: {
                    1: "00:00",
                    2: "00:00",
                    3: "00:00",
                    4: "00:00",
                    5: "00:00",
                  },
                },
                {
                  user_name: "Chengcheng",
                  time_list: {
                    1: "00:00",
                    2: "00:00",
                    3: "00:00",
                    4: "00:00",
                    5: "00:00",
                  },
                },
              ],
            },
          ],
        },
        {
          project_id: "5",
          project_name: "ProjectA",
          project_no: "ProjectA",
          order_list: [
            {
              order_id: "2",
              order_name: "OrderName",
              customer_list: [
                {
                  user_name: "Lixiang",
                  time_list: {
                    1: "00:00",
                    2: "00:00",
                    3: "00:00",
                    4: "00:00",
                    5: "00:00",
                  },
                },
                {
                  user_name: "Haiyang",
                  time_list: {
                    1: "00:00",
                    2: "00:00",
                    3: "00:00",
                    4: "00:00",
                    5: "00:00",
                  },
                },
                {
                  user_name: "Chengcheng",
                  time_list: {
                    1: "00:00",
                    2: "00:00",
                    3: "00:00",
                    4: "00:00",
                    5: "00:00",
                  },
                },
              ],
            },
            {
              order_id: "2",
              order_name: "OrderName222222",
              customer_list: [
                {
                  user_name: "Lixiang",
                  time_list: {
                    1: "00:00",
                    2: "00:00",
                    3: "00:00",
                    4: "00:00",
                    5: "00:00",
                  },
                },
                {
                  user_name: "Haiyang",
                  time_list: {
                    1: "00:00",
                    2: "00:00",
                    3: "00:00",
                    4: "00:00",
                    5: "00:00",
                  },
                },
                {
                  user_name: "Chengcheng",
                  time_list: {
                    1: "00:00",
                    2: "00:00",
                    3: "00:00",
                    4: "00:00",
                    5: "00:00",
                  },
                },
              ],
            },
            {
              order_id: "2",
              order_name: "OrderName33333",
              customer_list: [
                {
                  user_name: "Lixiang",
                  time_list: {
                    1: "00:00",
                    2: "00:00",
                    3: "00:00",
                    4: "00:00",
                    5: "00:00",
                  },
                },
                {
                  user_name: "Haiyang",
                  time_list: {
                    1: "00:00",
                    2: "00:00",
                    3: "00:00",
                    4: "00:00",
                    5: "00:00",
                  },
                },
                {
                  user_name: "Chengcheng",
                  time_list: {
                    1: "00:00",
                    2: "00:00",
                    3: "00:00",
                    4: "00:00",
                    5: "00:00",
                  },
                },
              ],
            },
          ],
        },
      ],
    };

    setReportData(dataq3e12);

    setLoading(false);
  };
  useEffect(() => {
    ajaxCurrentList();
  }, []);
  // Form submission
  const form = useForm({
    initialValues: {
      year: "",
      filter_type: "project",
      start_week: "",
      end_week: "",
      item_filter_id: "",
      user_filter_id: "",
    },
    validate: {
      end_week: (value, values) => {
        if (parseInt(values.start_week) > parseInt(value)) {
          return "The end week must be greater than the beginning week";
        }
        return null;
      },
      start_week: (value) => {
        if (!value) {
          return "start week is required";
        }
        return null;
      },
    },
  });

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Stop the formal submission of the form default
    // Verification form
    const valid = await form.validate();

    if (!valid.hasErrors) {
      const response = await regReportsByFilter(form.values, "GET");
      const result = response.data.data as projectOrderFilter;
      setReportData(result);
    }
  };

  // 查看详情
  const getDetail = (id_item: number, type: string) => {
    // // 获取project详情
    // alert(`aaaaaaaaaaaaaaaaaaaaa ${value} === ${type}`)
    // const url = `/project_item_reports?id_project=${id}`;
    // window.open(url, '_blank');
    setDetailPropsData({
      id: id_item,
      type: type,
      year: form.values.year || "",
      start_week: form.values.start_week || "",
      end_week: form.values.end_week || "",
    });
    setIdNow(id_item);
    openModal1();
  };

  // 第二种展示
  const rows = reportData ? (
    reportData.items_list.map((item, index) => {
      return (
        <>
          <tr key={index}>
            <td>
              <Text className="title_text " pt={5} size={15} color="dark">
                {item.project_name}
              </Text>
            </td>
            <td>
              {item.order_list.map((orderItem, index) => {
                return (
                  <>
                    <Text className="title_text " key={index} pt={5} size={13}>
                      {" "}
                      {orderItem.order_name}
                    </Text>
                    <Divider />
                  </>
                );
              })}
            </td>
            <td>
              {item.order_list.map((orderItem, index) => {
                return (
                  <>
                    {orderItem.customer_list.map((customerItem, index) => {
                      <Text
                        className="title_text "
                        key={index}
                        pt={5}
                        size={13}
                      >
                        {" "}
                        {customerItem.user_name}
                      </Text>;
                    })}
                    <Divider />
                  </>
                );
              })}
            </td>

            {reportData.week_number_list.map((weekitem) => {
              return (
                <td>
                  {item.order_list.map((orderItem, index) => {
                    return (
                      <>
                        {orderItem.customer_list.map((customerItem, index) => {
                          const isbool = parseInt(
                            customerItem.time_list[weekitem]
                          );

                          return (
                            <>
                              <Text
                                className="title_text "
                                key={index}
                                pt={5}
                                size={12}
                                color={`${isbool > 0 ? "green" : "dark"}`}
                              >
                                {" "}
                                {isbool
                                  ? formatTimeHHSS(
                                      customerItem.time_list[weekitem]
                                    )
                                  : "N/A"}
                              </Text>
                            </>
                          );
                        })}
                      </>
                    );
                  })}
                </td>
              );
            })}
            <td>小计</td>

            {/* <td >
                        <Text onClick={() => getDetail(item.id_item, item.type)} style={{ cursor: "pointer" }} >Detail</Text>
                    </td> */}
          </tr>
        </>
      );
    })
  ) : (
    <Text className={"textcenter"}></Text>
  );

  return (
    <Paper
      px={20}
      mb={20}
      style={{ overflowY: "auto", overflowX: "hidden", minHeight: "70vh" }}
    >
      <Box>
        <Flex className="title_header" justify="left" align="center" pl={30}>
          <Text className="title_text" size={18} fw={700} color="white">
            <FormattedMessage
              id="Filter Statistic"
              defaultMessage="Filter Statistic"
            />
          </Text>
        </Flex>
      </Box>
      <Box>
        <form onSubmit={handleFormSubmit}>
          <Group className="" mt={10} align="center">
            {/* <Select
                            placeholder="Select Filter Type"
                            searchable
                            required
                            defaultValue={"project"}
                            key={'project'}
                            onChange={changeSelectFilterType}

                            data={itemTypeList}
                        /> */}
            <Select
              placeholder={intl.formatMessage({
                id: "Select A Filter",
                defaultMessage: "Select A Filter",
              })}
              searchable
              required
              {...form.getInputProps("item_filter_id")}
              data={filterItemList}
            />
            <Select
              placeholder={intl.formatMessage({
                id: "Select User Filter",
                defaultMessage: "Select User Filter",
              })}
              searchable
              {...form.getInputProps("user_filter_id")}
              data={filterUserList}
            />
            <Select
              placeholder={intl.formatMessage({
                id: "Choose Year",
                defaultMessage: "Choose Year",
              })}
              required
              {...form.getInputProps("year")}
              searchable
              data={yearNumberAll}
            />
            <Select
              placeholder={intl.formatMessage({
                id: "Start Week",
                defaultMessage: "Start Week",
              })}
              searchable
              required
              {...form.getInputProps("start_week")}
              data={weekNumberAll}
            />
            {intl.formatMessage({
              id: "To",
              defaultMessage: "To",
            })}
            <Select
              placeholder={intl.formatMessage({
                id: "End Week",
                defaultMessage: "End Week",
              })}
              searchable
              required
              {...form.getInputProps("end_week")}
              data={weekNumberAll}
            />
            <Button type="submit" leftIcon={<IconClipboardPlus />}>
              {intl.formatMessage({
                id: "Generate Reports",
                defaultMessage: "Generate Reports",
              })}
            </Button>
          </Group>
        </form>
      </Box>
      <ScrollArea mt={20}>
        {reportData ? (
          <Table>
            <thead>
              <tr key={"index"}>
                <th>
                  <Text className="title_text " size={18} color="dark">
                    {" "}
                    project/Week
                  </Text>
                </th>
                <th>
                  <Text className="title_text " size={18} color="dark">
                    {" "}
                    order/Week
                  </Text>
                </th>
                <th>
                  <Text className="title_text " size={18} color="dark">
                    {" "}
                    customer
                  </Text>
                </th>
                {reportData.week_number_list.map((item, index) => {
                  return <th key={index}>KW{item}</th>;
                })}
                <th>
                  <Text className="title_text " size={12} color="dark">
                    {" "}
                    小计
                  </Text>
                </th>
                {/* <th >
                                <Text className="title_text " size={12} color="dark" > 详情</Text>
                            </th> */}
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
        ) : (
          <Box w="100%" className="text-center">
            {" "}
            <Divider
              my="xs"
              label={<FormattedMessage id="No Data" defaultMessage="No Data" />}
              labelPosition="center"
            />
          </Box>
        )}

        <Modal
          radius="md"
          size={"80%"}
          opened={openedModal1}
          className="modal-setting"
          onClose={closeModal1}
          title={
            <FormattedMessage
              id="Report Detail"
              defaultMessage="Report Detail"
            />
          }
          overlayProps={{ opacity: 0.5, blur: 4 }}
        >
          <FilterItemReports detailPropsData={detailPropsData} />
        </Modal>
      </ScrollArea>
    </Paper>
  );
}
