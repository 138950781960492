import React, { FC, useContext, useMemo, useReducer } from 'react'
import { MembersData, RolePermission } from '../../../../interface/Iemloyee'

type State = MembersData & {
  selectedRole: string
  role_costCenter_id: string[]

  // assignment_request
  task_ids: string[]
  project_ids: string[]
  order_ids: string[]
  ignore_costCenter: boolean
  all_project: boolean
  all_order: boolean
  all_task: boolean
}
type UpdateDatePermissionRoleState = Partial<State>
type Api = {
  clearCostCenter: () => void
  clearRole: () => void
  updateData: (name: string, value: any) => void
  updateRole_permissions: (permissions: RolePermission) => void
  clear_permissions: () => void
  updateDatePermissionRole: (payload: UpdateDatePermissionRoleState) => void
  changeIdentifier: (value: string, label: string) => void
}

const CostCenterLeaderContext = React.createContext({} as State)
const CostCenterLeaderApiContext = React.createContext({} as Api)

type Actions =
  | { type: 'clearCostCenter' }
  | { type: 'updateData'; payload: { name: string; value: any } }
  | { type: 'clearRole' }
  | { type: 'updateRole_permissions'; payload: RolePermission }
  | { type: 'clear_permissions' }
  | { type: 'updateDatePermissionRole'; payload: UpdateDatePermissionRoleState }
  | { type: 'changeIdentifier'; payload: Pick<State, 'identifier' | 'title'> }

const initCurPermissionRole = {
  role_permissions: [],
  description: '',
  active: '0',
  user_ids: [],
  selectedRole: ''
}

const initAssignment = {
  task_ids: [],
  project_ids: [],
  order_ids: [],
  ignore_costCenter: false,
  all_project: false,
  all_order: false,
  all_task: false
}

const reducer = (state: State, action: Actions) => {
  switch (action.type) {
    case 'clearCostCenter':
      return {
        costCenter_id: '',
        role_costCenter_id: [],
        identifier: '',
        title: '',
        id: '',
        ...initCurPermissionRole,
        ...initAssignment
      }
    case 'clearRole':
      return {
        ...state,
        identifier: '',
        title: '',
        id: '',
        role_costCenter_id: [],
        ...initCurPermissionRole,
        ...initAssignment
      }
    case 'updateData':
      return { ...state, [action.payload.name]: action.payload.value }
    case 'updateRole_permissions':
      const existsIndex = state.role_permissions.findIndex(
        permission => permission.permission_identifier_id === action.payload.permission_identifier_id
      )
      if (existsIndex === -1) {
        // if not exists
        return { ...state, role_permissions: [...state.role_permissions, action.payload] }
      } else {
        const new_role_permissions = [...state.role_permissions]
        if (action.payload.permission_ids.length) {
          new_role_permissions[existsIndex] = action.payload
        } else {
          new_role_permissions.splice(existsIndex, 1)
        }
        return { ...state, role_permissions: new_role_permissions }
      }
    case 'updateDatePermissionRole':
      return { ...state, ...action.payload }
    case 'clear_permissions':
      return { ...state, role_permissions: [] }
    case 'changeIdentifier':
      return {
        ...state,
        identifier: action.payload.identifier,
        title: action.payload.title,
        id: '',
        role_costCenter_id: [],
        ...initCurPermissionRole,
        ...initAssignment
      }
  }
}

export const CostCenterLeaderProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    title: '',
    id: '', // same as id_permission_role
    costCenter_id: '',
    role_costCenter_id: [],
    identifier: '',
    ...initCurPermissionRole,

    // assignment_request
    ...initAssignment
  })
  const api = useMemo(() => {
    const clearCostCenter = () => dispatch({ type: 'clearCostCenter' })

    const clearRole = () => dispatch({ type: 'clearRole' })

    const updateData = (name: string, value: any) => dispatch({ type: 'updateData', payload: { name, value } })
    const updateRole_permissions = (permissions: RolePermission) =>
      dispatch({ type: 'updateRole_permissions', payload: permissions })
    const updateDatePermissionRole = (payload: UpdateDatePermissionRoleState) =>
      dispatch({
        type: 'updateDatePermissionRole',
        payload
      })

    const clear_permissions = () => dispatch({ type: 'clearCostCenter' })
    const changeIdentifier = (identifier: string, title: string) =>
      dispatch({ type: 'changeIdentifier', payload: { identifier, title } })

    return {
      clearCostCenter,
      updateData,
      clearRole,
      updateRole_permissions,
      clear_permissions,
      updateDatePermissionRole,
      changeIdentifier
    }
  }, [])
  return (
    <CostCenterLeaderApiContext.Provider value={api}>
      <CostCenterLeaderContext.Provider value={state}>{children}</CostCenterLeaderContext.Provider>
    </CostCenterLeaderApiContext.Provider>
  )
}

export const useCostCenterLeader = () => useContext(CostCenterLeaderContext)
export const useCostCenterLeaderApi = () => useContext(CostCenterLeaderApiContext)
