import {
    Group,
    Text,
    Avatar,
    Grid,
    ActionIcon,
    Tooltip,
    Badge,
    Select,
    LoadingOverlay,
    Box,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { NewBoardingListData } from "../../../interface/Iuser";
import { IconDoorExit, IconShieldCheck, IconShieldX, IconSwitch } from "@tabler/icons-react";
import EditIconPerm from "../../../ttcomponents/EditIcon/EditIconPerm";
import { SelectRole } from "../../../interface/Icommon";
import { getCostCenterSelfSelectFunction } from "../../../api/assignments_request";
import WordTranslationComponent from "../../../utils/language_pack/words";
import SubmitBtn from "../../../ttcomponents/Button/SubmitBtn";
interface ComponentInterface {
    request_status: boolean;
    handleCostCenterChange: (value: any) => void
}



/**
* Extras 
* @returns 
*/
export default function SelectCostCenter({ request_status, handleCostCenterChange }: ComponentInterface) {

    const [costCenterData, setCostCenterData] = useState<SelectRole[]>([])
    const [value, setValue] = useState<string | null>(null);
    const word_translation = WordTranslationComponent();

    const [loadingCostCenter, setLoadingCostCenter] = useState(false);
    const [randNumber, setRandNumber] = useState(0);
    const getCostCenter = async () => {
        setLoadingCostCenter(true)
        var costCenterReult = await getCostCenterSelfSelectFunction();
        setLoadingCostCenter(false)
        setRandNumber(randNumber+1)
        // Call the Get Cost Center General Selection function and set the cost center data
        setCostCenterData(costCenterReult)
    };

    // Use the USEEFFECT hook. When Search changes, call the AjaxCurrenTList function
    useEffect(() => {
        request_status && getCostCenter();
    }, [request_status]);



    return (
        <>
            <Box style={{ position: "relative"}} pt={50} mih={250} 
              sx={(theme) => ({
                textAlign: 'center',
                borderRadius: theme.radius.md,
                cursor: 'pointer'
              })}
            >
                <LoadingOverlay visible={loadingCostCenter} overlayBlur={2} />
                <Select
                    labelProps={{ className: "input_title" }}
                    onChange={setValue}
                    placeholder={word_translation.select_cost_center}
                    searchable
                    maxDropdownHeight={180}
                    key={randNumber}
                    dropdownPosition={"bottom"}
                    data={costCenterData}
                /> 
            </Box>
            <Box  sx={(theme) => ({
                textAlign: 'center',
                borderRadius: theme.radius.md,
                cursor: 'pointer'
              })}>
              <SubmitBtn btn_size="xs" callBack={()=>handleCostCenterChange(value)}  />
            </Box>
        </>
    )
}