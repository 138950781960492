import { getPublicHolidayList, reGetDomains, reGetEmployeeCustomAttributeList, regCalculateEstimated, regCalculationGet, regCompanySeting, regCostcenterSetting, regGetEmailListNopagination, regGetPermissionRoleListNopagination, regGetUserRoleList, regGetUserRoleListNopagination, regGlobalSetting, regTemPermissionTable, regUserAll, reqGetLeaveApproveUserList, requestAllPermissionIdentifierList, requestAssignablePermissionList, requestCoreAssignablePermissionList, requestCorePermissionAction, requestCorePermissionIdentifierListAction, requestCorePermissionIdentifierUniqueList, requestCorePermissionListAction, requestCorePermissionRoleList, requestCorePermissionSetList, requestCorePermissionUniqueAction, requestCoreUpdateUserRoleTransfer, requestGetDefaultRole, requestGetManagePermissionSetDetail, requestGetPermissionRoleDetail, requestGetPermissionSetDetail, requestManageRolePermissionDetail, requestSetPermissionShow } from ".";
import localstorageUnits from "../utils/localstorageUnits";



/******************************************************System***********************************************************************/

/**
 * Fetches the list of domain names.
 * @returns {Promise<string[]>} A Promise resolving to an array of domain names.
 */
export const getDomainsSelectFunction = async (): Promise<string[]> => {
  try {
    // Call the RegetDomains method to get the list of domain names
    const responseGetDomains = await reGetDomains({});
    // Convert the response data to the ANY type
    const resultGetDomains = responseGetDomains.data as any;

    // Check if the response code is 200 (OK)
    if (resultGetDomains.code === 200) {
      // Return the array of domain names directly
      return resultGetDomains.data;
    }

    // If the response code is not 200, return an empty array
    return [];
  } catch (error) {
    // Handle errors by returning an empty array
    console.error('Error fetching domain names:', error);
    return [];
  }
};


  /**
 * Fetches cost center general functions asynchronously.
 * @returns {Promise<Array<{ value: string; label: string }>>} An array of cost center data with 'value' and 'label' properties.
 */
export const getCostCenterGeneralSelectFunction = async (): Promise<Array<{ value: string; label: string }>> => {
    let centerData: Array<{ value: string; label: string }> = [];
    try {
      // Make an asynchronous request to fetch cost center settings
      const response = await regCostcenterSetting({}, "GET");
      const result = response.data;
      // Check if the response code is 200 (OK)
      if (result.code === 200) {
        // Map the data to the required format with 'value' and 'label' properties
        centerData = result.data.data.map((item: { id_costcenter: string; name: any }) => ({
          value: item.id_costcenter as string,
          label: item.name,
        }));
      }
    } catch (error) {
        return centerData;
    }
    return centerData;
  };


/**
 * Fetches cost center general functions asynchronously.
 * @returns {Promise<Array<any>>} An array of cost center data with any format.
 */
export const getCostCenterGeneralFunction = async (): Promise<[]> => {
  // Initialize an empty array to store cost center data
  let centerData: [] = [];

  try {
    // Make an asynchronous request to fetch cost center settings using the regCostcenterSetting function with the "GET" method
    const response = await regCostcenterSetting({}, "GET");
    const result = response.data;

    // Check if the response code is 200 (OK)
    if (result.code === 200) {
      // Assign the data to the centerData array
      centerData = result.data.data;
    }
  } catch (error) {
    // If an error occurs during the request, return the empty array
    return centerData;
  }

  // Return the array of cost center data
  return centerData;
};

/**
* Asynchronous function to obtain company configuration information.
*
* @returns Returns a Promise that resolves to company configuration information on success and an empty array on failure.
*/
export const getCompanyGeneralFunction = async (): Promise<any> => {
  //Initialize an empty array to store company configuration information
  let companySettings: any;

  try {
  // Initiate an asynchronous request and use the "GET" method to obtain company configuration information through the regCompanySeting function
    const response = await regCompanySeting({}, 'GET');
    const result = response.data;

   // 检查响应代码是否为 200 (OK)
    if (result.code === 200) {
      // Assign data to companySettings 
      companySettings = result.data;
    }
  } catch (error) {
    // If an error occurs during the request, an empty array is returned
    return companySettings;
  }

  // Returns   of company configuration information
  return companySettings;
};

/**
 * Asynchronous function to obtain core configuration information.
 *
 * @returns Returns a Promise that resolves to core configuration information on success and an empty array on failure.
 */
export const getGlobalSettingGeneralFunction = async (): Promise<any> => {
  // Initialize an empty variable to store core configuration information
  let globalSetting: any;

  try {
    // Initiate an asynchronous request and use the "GET" method to obtain core configuration information through the regGlobalSetting function
    const response = await regGlobalSetting({}, "GET");
    const result = response.data;

    // Check if the response code is 200 (OK)
    if (result.code === 200) {
      // Assign data to globalSetting
      globalSetting = result.data;
    }
  } catch (error) {
    // If an error occurs during the request, return an empty array
    return globalSetting;
  }

  // Return core configuration information
  return globalSetting;
};


export const getSystemAssignmentsFunction = async () => {
  let AssignmentModals = localstorageUnits.getSystem().assignments_modes;

  if(!AssignmentModals){
      console.log('no')
      const result  = await getGlobalSettingGeneralFunction()
      localstorageUnits.saveSystem(result);
      AssignmentModals = result.assignments_modes
  }

  return AssignmentModals;
};



// Fetches the permission action Unique list
export const getCorePermissionIdentifierUniqueList = async () => {
  // Initialize the return result
  let returnResult: any = [];

  try {
    // Call the API to get the project list
    const response = await requestCorePermissionIdentifierUniqueList();
    const result = response.data;

    // Check if the API response is successful (status code 200)
    if (result.code === 200) {
      
        // Extract and format data for the 'select' type
        const customerData = result.data.map(
          (item: { identifier: string;  }) => ({
            value: item.identifier,
            label: item.identifier,
          })
        );

        // Set the formatted project list data to the return result
        returnResult = customerData;
    }
  } catch (error) {
    // Handle any errors that occur during the API call
    console.error('Error fetching project list:', error);
    // Optionally, you can throw the error or handle it as needed
    throw error;
  }

  // Return the final result
  return returnResult;
};

// Fetches the permission action Unique list
export const getCorePermissionActionUniqueList = async () => {
  // Initialize the return result
  let returnResult: any = [];

  try {
    // Call the API to get the project list
    const response = await requestCorePermissionUniqueAction();
    const result = response.data;

    // Check if the API response is successful (status code 200)
    if (result.code === 200) {
      
        // Extract and format data for the 'select' type
        const customerData = result.data.map(
          (item: { identifier: string;  }) => ({
            value: item.identifier,
            label: item.identifier,
          })
        );

        // Set the formatted project list data to the return result
        returnResult = customerData;
    }
  } catch (error) {
    // Handle any errors that occur during the API call
    console.error('Error fetching project list:', error);
    // Optionally, you can throw the error or handle it as needed
    throw error;
  }

  // Return the final result
  return returnResult;
};



// Fetches the permission action list
export const getCorePermissionActionListGeneralFunction = async (where: any, get_type = 'select') => {
  // Initialize the return result
  let returnResult: any = [];

  try {
    // Call the API to get the project list
    const response = await requestCorePermissionListAction(where);
    const result = response.data;

    // Check if the API response is successful (status code 200)
    if (result.code === 200) {
      if (get_type === 'select') {
        // Extract and format data for the 'select' type
        const customerData = result.data.map(
          (item: { id_permission_identifier: string; name: any; }) => ({
            value: item.id_permission_identifier.toString(),
            label: item.name,
          })
        );

        // Set the formatted project list data to the return result
        returnResult = customerData;
      }
      returnResult = result.data;
    }
  } catch (error) {
    // Handle any errors that occur during the API call
    console.error('Error fetching project list:', error);
    // Optionally, you can throw the error or handle it as needed
    throw error;
  }

  // Return the final result
  return returnResult;
};

// Fetches the permission action list
export const getCorePermissionShow = async (where: any, get_type = 'select') => {
  // Initialize the return result
  let returnResult: any = [];

  try {
    // Call the API to get the project list
    const response = await requestSetPermissionShow();
    const result = response.data;

    // Check if the API response is successful (status code 200)
    if (result.code === 200) {
      if (get_type === 'select') {
        // Extract and format data for the 'select' type
        const customerData = result.data.map(
          (item: { id_permission_identifier: string; name: any; }) => ({
            value: item.id_permission_identifier.toString(),
            label: item.name,
          })
        );

        // Set the formatted project list data to the return result
        returnResult = customerData;
      }
      returnResult = result.data;
    }
  } catch (error) {
    // Handle any errors that occur during the API call
    console.error('Error fetching project list:', error);
    // Optionally, you can throw the error or handle it as needed
    throw error;
  }

  // Return the final result
  return returnResult;
};


// Fetches the permission identifier list
export const getCorePermissionIdentifierListGeneralFunction = async (where: any, get_type = 'select') => {
  // Initialize the return result
  let returnResult: any = [];

  try {
    // Call the API to get the project list
    const response = await requestCorePermissionIdentifierListAction(where);
    const result = response.data;

    // Check if the API response is successful (status code 200)
    if (result.code === 200) {
      if (get_type === 'select') {
        // Extract and format data for the 'select' type
        const customerData = result.data.map(
          (item: { id_permission_identifier: string; name: any; }) => ({
            value: item.id_permission_identifier.toString(),
            label: item.name,
          })
        );

        // Set the formatted project list data to the return result
        returnResult = customerData;
      }
      returnResult = result.data;
    }
  } catch (error) {
    // Handle any errors that occur during the API call
    console.error('Error fetching project list:', error);
    // Optionally, you can throw the error or handle it as needed
    throw error;
  }

  // Return the final result
  return returnResult;
};



// Fetches the permission identifier list
export const getAllPermissionIdentifierListGeneralFunction = async (where: any, get_type = 'select') => {
  // Initialize the return result
  let returnResult: any = [];

  try {
    // Call the API to get the project list
    const response = await requestAllPermissionIdentifierList(where);
    const result = response.data;

    // Check if the API response is successful (status code 200)
    if (result.code === 200) {
      if (get_type === 'select') {
        // Extract and format data for the 'select' type
        const customerData = result.data.map(
          (item: { id_permission_identifier: string; name: any; }) => ({
            value: item.id_permission_identifier.toString(),
            label: item.name,
          })
        );

        // Set the formatted project list data to the return result
        returnResult = customerData;
      }
      returnResult = result.data;
    }
  } catch (error) {
    // Handle any errors that occur during the API call
    console.error('Error fetching project list:', error);
    // Optionally, you can throw the error or handle it as needed
    throw error;
  }

  // Return the final result
  return returnResult;
};




// Fetches the permission role set list
export const getCorePermissionSetListGeneralFunction = async (where: any, get_type = 'select') => {
  // Initialize the return result
  let returnResult: any = [];

  try {
    // Call the API to get the project list
    const response = await requestCorePermissionSetList(where);
    const result = response.data;

    // Check if the API response is successful (status code 200)
    if (result.code === 200) {
      if (get_type === 'select') {
        // Extract and format data for the 'select' type
        const customerData = result.data.map(
          (item: { id_permission_identifier: string; name: any; }) => ({
            value: item.id_permission_identifier.toString(),
            label: item.name,
          })
        );
        // Set the formatted project list data to the return result
        returnResult = customerData;
      }
      returnResult = result.data;
    }
  } catch (error) {
    // Handle any errors that occur during the API call
    console.error('Error fetching project list:', error);
    // Optionally, you can throw the error or handle it as needed
    throw error;
  }

  // Return the final result
  return returnResult;
};


export const getAssignablePermissionFunction = async (where: any) => {
  // Initialize the return result
  let returnResult: any = []

  try {
    const response = await requestAssignablePermissionList(where)
    const result = response.data
    if (result.code === 200) {
      returnResult = result.data
    }
  } catch (error) {
    // Handle any errors that occur during the API call
    console.error('Error fetching AssignablePermission list:', error)
    // Optionally, you can throw the error or handle it as needed
    throw error
  }

  return returnResult
}



export const getCoreAssignablePermissionFunction = async (where: any) => {
  // Initialize the return result
  let returnResult: any = []

  try {
    const response = await requestCoreAssignablePermissionList(where)
    const result = response.data
    if (result.code === 200) {
      returnResult = result.data
    }
  } catch (error) {
    // Handle any errors that occur during the API call
    console.error('Error fetching AssignablePermission list:', error)
    // Optionally, you can throw the error or handle it as needed
    throw error
  }

  return returnResult
}

export const updateCoreUserRoleTransferFunction = async (where: any) => {
  try {
    const response = await requestCoreUpdateUserRoleTransfer(where)
    const result = response.data
    if (result.code === 200) {
      return result.data
    }
    return false
  } catch (error) {
    // Handle any errors that occur during the API call
    console.error('Error update UserRoleTransferFunction', error)
    // Optionally, you can throw the error or handle it as needed
    throw error
  }
}



// get public holiday list
export const getPublicHolidayListGeneralFunction = async (where: any, get_type = 'select')=> {
  // Initialize the return result
  let returnResult: any = [];

  try {
    // Call the API to get the holiday list
    const response = await getPublicHolidayList(where);
    const result = response.data;

    // Check if the API response is successful (status code 200)
    if (result.code === 200) {
      if (get_type === 'select') {
        // Extract and format data for the 'select' type
        const customData = result.data.map((item: { date: any }) => item.date);
// customData 现在是一个包含日期字符串的数组，例如 ['2020-10-10', '2020-10-10']

        // Set the formatted project list data to the return result
        returnResult = customData;
      }
    }
  } catch (error) {
    // Handle any errors that occur during the API call
    console.error('Error fetching project list:', error);
    // Optionally, you can throw the error or handle it as needed
    throw error;
  }
  return returnResult;

}

/**
 * Fetches user roles and returns them in the required format for a select dropdown.
 * @returns {Promise<Array<{ value: string; label: string }>>} A Promise containing an array of objects with 'value' and 'label' properties.
 */
export const getUserRoleGeneralSelectFunction = async (): Promise<Array<{ value: string; label: string }>> => {
  let returnArray: Array<{ value: string; label: string }> = [];

  try {
    // Make an asynchronous request to fetch all user roles
    const response = await requestCorePermissionRoleList({}, "GET");
    const result = response.data;

    // Check if the response code is 200 (OK)
    if (result.code === 200) {
      // Map the user role data to the required format with 'value' and 'label' properties
      returnArray = result.data.map((item: { id_user_role: string; role_name: string; role_identifier: string }) => ({
        value: item.id_user_role.toString() as string,
        label: `${item.role_name} (${item.role_identifier})`, // Combine role_name and role_identifier in the label
      }));
    }
  } catch (error) {
    // Handle errors by returning an empty array
    console.error('Error fetching user role data:', error);
  }

  return returnArray;
};

/**
 * Fetches user roles and returns them in the required format for a select dropdown.
 * @returns {Promise<Array<{ value: string; label: string }>>} A Promise containing an array of objects with 'value' and 'label' properties.
 */
export const getCoreRoleFunction = async (where:any=''): Promise<Array<{ value: string; label: string }>> => {
  let returnArray: Array<{ value: string; label: string }> = [];

  try {
    // Make an asynchronous request to fetch all user roles
    const response = await regGetPermissionRoleListNopagination(where, "GET");
    const result = response.data;

    // Check if the response code is 200 (OK)
    if (result.code === 200) {
      // Map the user role data to the required format with 'value' and 'label' properties
      returnArray = result.data.map((item: { id_permission_role: string; title: string; identifier: string }) => ({
        value: item.id_permission_role.toString() as string,
        label: `${item.title} (${item.identifier})`, // Combine role_name and role_identifier in the label
      }));
    }
  } catch (error) {
    // Handle errors by returning an empty array
    console.error('Error fetching user role data:', error);
  }

  return returnArray;
};


/**
 * Fetches core user roles from the server and returns them in the required format.
 * @param where Optional parameter for filtering user roles
 * @returns Promise<Array<{ value: string; label: string }>> An array of objects with 'value' and 'label' properties representing user roles
 */
export const getCoreUserRoleFunction = async (where:any=''): Promise<Array<{ value: string; label: string }>> => {
  let returnArray: Array<{ value: string; label: string }> = [];

  try {
    // Make an asynchronous request to fetch all user roles
    const response = await regGetUserRoleListNopagination(where, "GET");
    const result = response.data;

    // Check if the response code is 200 (OK)
    if (result.code === 200) {
      // Map the user role data to the required format with 'value' and 'label' properties
      returnArray = result.data.map((item: { id_user_role: string; role_identifier: string; role_name: string }) => ({
        value: item.id_user_role.toString() as string,
        label: `${item.role_name} `, // Combine role_name and role_identifier in the label
        // label: `${item.role_name} (${item.role_identifier})`, // Combine role_name and role_identifier in the label
      }));
    }
  } catch (error) {
    // Handle errors by returning an empty array and logging the error
    console.error('Error fetching user role data:', error);
  }

  return returnArray;
};

// email options
export const getEmailOptionsFunction = async (where: any=''): Promise<Array<string>> => {
  let returnArray: Array<string> = [];

  try {
    // Make an asynchronous request to fetch all user roles
    const response = await regGetEmailListNopagination(where, "GET");
    const result = response.data;

    // Check if the response code is 200 (OK)
    if (result.code === 200) {
      // Map the user role data to the required format with 'value' and 'label' properties
      returnArray = result.data
    }
  } catch (error) {
    // Handle errors by returning an empty array and logging the error
    console.error('Error fetching user role data:', error);
  }

  return returnArray;
}

type Approver = {
  label: string
  value: string
  costCenter_id: string
  email: string
  avatar: string
}

// vacation Approver options
export const getLeaveApproveUserListFunction = async (where: any=''): Promise<Array<Approver>> => {
  let returnArray: Array<Approver> = [];

  try {
    // Make an asynchronous request to fetch all user roles
    const response = await reqGetLeaveApproveUserList(where, "GET");
    const result = response.data;

    // Check if the response code is 200 (OK)
    if (result.code === 200) {
      // Map the user role data to the required format with 'value' and 'label' properties
      returnArray = result.data.map((d: any) => {
        return {
          label: d.username,
          value: d.id_user.toString() as string,
          costCenter_id: d.costCenter_id,
          email: d.email,
          avatar: d.avatar
        }
      });
    }
  } catch (error) {
    // Handle errors by returning an empty array and logging the error
    console.error('Error fetching user role data:', error);
  }

  return returnArray;
}

export const getCalculationTypeFunction = async (where:any=''): Promise<Array<{ value: string; label: string }>> => {
  let returnArray: Array<{ value: string; label: string }> = [];

  try {
    const response = await regCalculationGet(where, "GET");
    const result = response.data;

    if (result.code === 200) {
      returnArray = result.data.map((item: { id: string; name: string; }) => ({
        value: item.id.toString() as string,
        label: `${item.name} `,
      }));
    }
  } catch (error) {
    // Handle errors by returning an empty array and logging the error
    console.error('Error fetching user role data:', error);
  }

  return returnArray;
};

/**
 * Fetches core user roles from the server and returns them in the required format.
 * @param where Optional parameter for filtering user roles
 * @returns Promise<Array<{ value: string; label: string }>> An array of objects with 'value' and 'label' properties representing user roles
 */
export const getListTemPermissionFunction = async (where:any=''): Promise<Array<{ value: string; label: string }>> => {
  let returnArray: Array<{ value: string; label: string }> = [];

  try {
    // Make an asynchronous request to fetch all user roles
    const response = await regTemPermissionTable(where, "GET");
    const result = response.data;

    // Check if the response code is 200 (OK)
    if (result.code === 200) {
      // Map the user role data to the required format with 'value' and 'label' properties
      returnArray = result.data.map((item: { id_management_permission_set_table: string; title: string; }) => ({
        value: item.id_management_permission_set_table.toString() as string,
        label: `${item.title} `, // Combine role_name and role_identifier in the label
      }));
    }
  } catch (error) {
    // Handle errors by returning an empty array and logging the error
    console.error('Error fetching user role data:', error);
  }

  return returnArray;
};



export const getDefaultFunction = async (where:any =''): Promise<Array<{ value: string; label: string }>> => {
  let returnArray: Array<{ value: string; label: string }> = [];

  try {
    // Make an asynchronous request to fetch all user roles
    const response = await requestGetDefaultRole(where, "GET");
    const result = response.data;

    // Check if the response code is 200 (OK)
    if (result.code === 200) {
      returnArray = result.data 
    }
  } catch (error) {
    // Handle errors by returning an empty array
    console.error('Error fetching default  role data:', error);
  }

  return returnArray;
};







// get role detail by project_id
export const getPermissionRoleDetail = async (where: any) => {
  let returnArray: any = {};
  try {
    const response = await requestGetPermissionRoleDetail(where);
    const result = response.data;
    // Check if the response code is 200 (OK)
    if (result.code === 200) {
        returnArray =  result.data

        if(Array.isArray(returnArray) && returnArray.length === 0){
          returnArray = undefined;
        }
    }
  } catch (error) {
    // Handle errors by returning an empty array
    console.error('Error fetching user data:', error);
  }

  return returnArray;
};



// get role detail by project_id
export const getPermissionSetDetail = async (where: any) => {
  let returnArray: any = {};
  try {
    const response = await requestGetPermissionSetDetail(where);
    const result = response.data;
    // Check if the response code is 200 (OK)
    if (result.code === 200) {
        returnArray =  result.data

        if(Array.isArray(returnArray) && returnArray.length === 0){
          returnArray = undefined;
        }
    }
  } catch (error) {
    // Handle errors by returning an empty array
    console.error('Error fetching user data:', error);
  }

  return returnArray;
};

// get role detail by project_id
export const getPermissionManageDetail = async (where: any) => {
  let returnArray: any = {};
  try {
    const response = await requestManageRolePermissionDetail(where);
    const result = response.data;
    // Check if the response code is 200 (OK)
    if (result.code === 200) {
        returnArray =  result.data

        if(Array.isArray(returnArray) && returnArray.length === 0){
          returnArray = undefined;
        }
    }
  } catch (error) {
    // Handle errors by returning an empty array
    console.error('Error fetching user data:', error);
  }

  return returnArray;
};

// get role detail by project_id
export const getPermissionManageUserDetail = async (where: any) => {
  let returnArray: any = {};
  try {
    const response = await requestGetManagePermissionSetDetail(where);
    const result = response.data;
    // Check if the response code is 200 (OK)
    if (result.code === 200) {
        returnArray =  result.data

        if(Array.isArray(returnArray) && returnArray.length === 0){
          returnArray = undefined;
        }
    }
  } catch (error) {
    // Handle errors by returning an empty array
    console.error('Error fetching user data:', error);
  }

  return returnArray;
};
