import {
  Box,
  Button,
  Card,
  Badge,
  Code,
  Grid,
  LoadingOverlay,
  MultiSelect,
  Select,
  Text,
  TextInput,
  Group,
  ActionIcon,
  Title,
  Textarea,
  Table,
  Divider,
  Tooltip,
  Avatar,
  Checkbox,
  Modal,
  Paper,
  NumberInput,
  TransferListData,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { hasLength, isNotEmpty, useForm } from "@mantine/form";
import {
  IconBuilding,
  IconCalendar,
  IconHistory,
  IconMoneybag,
  IconPlus,
  IconX,
} from "@tabler/icons-react";
import { FormEvent, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";

import {
  ClientWarningHint,
  HintErrorMantineInfo,
  HintInfo,
  formatDateUpgraded,
} from "../../../../utils/function";
import {
  IProductResponse,
  ItemProps,
  Product,
} from "../../../../interface/IProduct";
import localstorageUnits from "../../../../utils/localstorageUnits";
import WordTranslationComponent from "../../../../utils/language_pack/words";
import TableTextInputTitle from "../../../../ttcomponents/TableTextInputTitle/TableTextInputTitle";
import TableTextInputTitleDesc from "../../../../ttcomponents/TableTextInputTitleDesc/TableTextInputTitleDesc";
import {
  getAllTaskCategoryGeneralFunction,
  getCostCenterGeneralSelectFunction,
  getCustomerListGeneralFunction,
  getOrderDetailByIdFunction,
  getOrderListGeneralFunction,
  getPermissiontRoleGeneralFunction,
  getPermissiontRoleListGeneralFunction,
  getProjectDetailByIdFunction,
  getProjectListGeneralFunction,
  getProjectRoleGeneralFunction,
  getTaskDetailByIdFunction,
  getTaskListGeneralFunction,
} from "../../../../api/assignments_request";
import { SelectRole } from "../../../../interface/Icommon";
import { PermissionUserAllList, UserAllList } from "../../../../interface/Iuser";
import { getProductGeneralSelectFunction } from "../../../../api/inventory_request";
import ResetBtn from "../../../../ttcomponents/Button/ResetBtn";
import UniversalButton from "../../../../ttcomponents/Button/UniversalButton";
import { ApiOrderResponse, OrderDataInterface } from "../../../../interface/Iorder";
import TaskList from "./task_list";
import TransferListUserComponent from "../../../../ttcomponents/TransferListUserComponent/TransferListUserComponent";
import UserListComponent from "../../../core/user/user_list_component";
import { ProjectDataInterface, ProjectDetailInterface } from "../../../../interface/Iprojects";
import { ChildMethods } from "../project/project_create";
import { requestTask } from "../../../../api/index_v2";
import { UserTaskInteface } from "../../../../interface/Itask";
import TaskUserListComponent from "./task_user_list_component";
import apiUrl from '../../../../config/api_config/config';

import AddUserToTask from "./add_user_totask";
import { getDefaultFunction } from "../../../../api/core_request";

interface ItaskFromProps {
  callBackFunction: (task_id: string) => void;
  c_allUserList: UserAllList[];
  c_userList: SelectRole[];
  edit_task_id?: string;
  [key: string]: any;
}



const TaskFrom = forwardRef<ChildMethods, ItaskFromProps>(({ edit_task_id, cannotBeChanged, callBackFunction, customerList, project_info, order_id, project_id, c_allUserList, c_userList, }, ref) => {

  // Get translation public configuration information
  const word_translation = WordTranslationComponent();
  //Use useImperativeHandle to throw child component methods to parent components
  useImperativeHandle(ref, () => ({ taskSaveFormData, taskRest, taskSaveAndNextFormData }));

  const taskRest = () => {
    form.reset();
    setRandNumber(rand_number + 1)
  }
  // refresh status number
  const [rand_number, setRandNumber] = useState<number>(1);
  // Get the initial time of the system
  const initSystemTime = localstorageUnits.getSystem().init_use_date;
  // Define   isLinkOrder indicates whether to associate Order
  const [isLinkOrder, setIsLinkOrder] = useState<boolean>(false);
  const [userTaskList, setUserTaskList] = useState<UserTaskInteface[]>([]);

  // Define   existsUser indicates whether to associate Order
  const [existsUserList, setExistsUserList] = useState<PermissionUserAllList[]>([]);

  // Define all the project lists that can be selected
  const [orderList, setOrderList] = useState<SelectRole[]>([]);
  // Define all the customer lists that can be selected
  const [customerCurrentPageList, setCustomerList] = useState<SelectRole[]>([]);

  // Define all the project lists that can be selecteduserlist
  const [projectList, setProjectList] = useState<SelectRole[]>([]);

  // Define all the list of employees you can choose
  const [userList, setUserList] = useState<SelectRole[]>([]);
  // Define the user list (including the user's detailed information)
  const [UserList, setResultUser] = useState<UserAllList[]>([]);
  // 定义默认角色
  const [defaultRoleData, setDefaultRoleData] = useState<SelectRole[]>([])

  // Define all the list of product you can choose
  const [productData, setProductData] = useState<ItemProps[] | undefined>([]);
  //  Define current  project info  in the page
  const [currentProjectInfo, setCurrentProjectInfo] = useState<ProjectDetailInterface>();

  //  Define current  project info  in the page
  const [currentOrderInfo, setCurrentOrderInfo] = useState<OrderDataInterface>();
  //Define the orderlist below the selected project or  under order>task moda  all order
  useEffect(() => {
    setResultUser(c_allUserList);
    setUserList(c_userList);
  }, [c_allUserList, c_userList]);


  //加载中状态
  const [loading, setLoading] = useState(false);

  const form = useForm({
    validateInputOnBlur: true,
    initialValues: {
      project_id: project_id ? project_id : '',
      order_id: order_id ? order_id : '',
      customer_id: "",
      task_category_id: "",
      start_date: new Date(),
      costCenter_id: '',
      end_date: new Date(),
      budget: "0.00",
      id: "",
      free_description: "",
      cost: "0.00",
      target_time: "00:00",
      user_id: [],
      // user_id:  '',
      employees: [],
    },
    validate: {
      customer_id: isNotEmpty(`${word_translation.customer} ${word_translation.is_not_empty}`),
      task_category_id: isNotEmpty(`${word_translation.task_category} ${word_translation.is_not_empty}`),
      user_id: isNotEmpty(`${word_translation.task_team_leader} ${word_translation.is_not_empty}`),
    },
  });
  const setTeamLeaderValueHandle = (value: React.SetStateAction<string[]>) => {
    // setTeamLeaderValue(value);
    form.setFieldValue("order_team_leaders", value as []);
  };

  // 选择项目

  // Click the function triggered when selecting the project. Value parameter is the project ID
  const selectProjectItemFunction = async (project_id: string | null) => {
    // Reset Data
  };

  const getCustomerList = async () => {
    setCustomerList(await getCustomerListGeneralFunction(""));
  };

  const getProjectList = async (value: any) => {
    setProjectList(await getProjectListGeneralFunction(value));
  };

  const getDefaultRole = async () => {
    // Call the Get Cost Center General Selection function and set the cost center data
    setDefaultRoleData(await getDefaultFunction());
  };
  // 使用useState定义data和setData并初始化为Role[]类型
  const [projectUserRolelist, setProjectUserRoleList] = useState<SelectRole[]>([]);
  // 使用useState定义data和setData并初始化为Role[]类型
  const [taskCategoryList, setTaskCategoryList] = useState<SelectRole[]>([]);
  const [costCenterData, setCostCenterData] = useState<SelectRole[]>([])
  /**
  * get project user role
  */
  const getProjectUserRole = async (project_id: any = '') => {
    setProjectUserRoleList([]);
    setProjectUserRoleList(await getPermissiontRoleListGeneralFunction(project_id))
  }


  const getCostCenter = async () => {
    // Call the Get Cost Center General Selection function and set the cost center data
    setCostCenterData(await getCostCenterGeneralSelectFunction());
  };



  // 获取全部的订单

  const getOrder = async () => {
    const result = await getProductGeneralSelectFunction(word_translation.unit_price, word_translation.product_not_available);
    // setProductList
    setProductData(result);
  }
  // 获取项目下的订单


  const getOrderDeatil = async (order_id: string) => {
    const orderIndo = await getOrderDetailByIdFunction(order_id)
    // 如果当前订单存在项目ID
    if (orderIndo.project_id) {
      // form.setFieldValue('project_id',orderIndo.project_id.toString())
      getProjectDeatil(orderIndo.project_id)
    }
    setIsLinkOrder(true)
    setCurrentOrderInfo(orderIndo);
  }



  const getProjectDeatil = async (project_id: string) => {
    try {
      const projectInfo = await getProjectDetailByIdFunction(project_id);
      await getProjectUserRole({ costCenter_id: projectInfo.costCenter_id });

      if (projectInfo && projectInfo.project_employee_roles && Array.isArray(projectInfo.project_employee_roles)) {
        setResultUser(
          projectInfo.project_employee_roles.map((_item: any, _index: any) => {
            return {
              id_user: _item.id_user,
              username: _item.username,
              email: _item.email,
              phone: _item.phone,
              avatar: _item.avatar,
            };
          })
        );
      } else {
        setResultUser([]); // Set an empty array if projectInfo or project_employee_roles is not as expected
      }

      setCurrentProjectInfo(projectInfo)
    } catch (error) {

    }
  }

  /**
   * Retrieves the task category list asynchronously and sets it in the component state.
   * @returns {Promise<void>} A promise that resolves when the task category list is retrieved and set.
   */
  const getTaskCategoryList = async (): Promise<void> => {
    setTaskCategoryList(await getAllTaskCategoryGeneralFunction({list:true}));
  }

  useEffect(() => {
    // If the project exists, then assign values to the project_id and customer_id in the Form
    form.setFieldValue('project_id', currentProjectInfo?.id_project.toString());
    form.setFieldValue('customer_id', (currentProjectInfo?.customer_id ? currentProjectInfo.customer_id.toString() : currentProjectInfo?.customer_id.toString()) as string);
    // 请求该项目下的Project
    if (currentProjectInfo?.id_project) {
      getTaskList({ project_id: currentProjectInfo?.id_project })
    }
    setRandNumber(rand_number + 1)
  }, [currentProjectInfo]);

  useEffect(() => {
    // If the project exists, then assign values to the project_id and customer_id in the Form
    form.setFieldValue('order_id', currentOrderInfo?.id_order)
    form.setFieldValue('customer_id', currentOrderInfo?.customer_id.toString() as string)
  }, [currentOrderInfo]);

  // 获取项目
  useEffect(() => {
    getDefaultRole();
    getCustomerList();
    getOrderList('');
    getProjectList('');
    getTaskCategoryList();
    getCostCenter();
    // getProjectUserRole();
  }, []);

  // 监听上级组件的值 如果没有上级组件则不触发
  useEffect(() => {
    if (order_id) {
      getOrderDeatil(order_id)
    } else if (project_id) {
      getProjectDeatil(project_id)
    } else if (cannotBeChanged) {
      getOrderDeatil(cannotBeChanged)
      setIsLinkOrder(true)
    }
  }, [order_id, project_id, cannotBeChanged])



  //  重置订单产品信息
  const resetProductFormFunction = () => {
    form.reset();
    setRandNumber(rand_number + 1)
  }



  //  删除订单产品信息
  const callBackProductFunction = (value: any) => {

  }
  // 监听 task_id 的值
  useEffect(() => {
    if (edit_task_id == '' || edit_task_id == undefined) {
    } else {
      callBackTaskIDfunction(edit_task_id as string, true, true)
    }
  }, [edit_task_id])

  const callBackTaskIDfunction = async (value: string, is_checked: boolean, need_update = false) => {
    if (is_checked) {
      if (need_update) {
        form.setFieldValue('id', value);
      }
      const taskInfo = await getTaskDetailByIdFunction(value);
      form.setFieldValue('task_category_id', taskInfo.task_category_id.toString());
      form.setFieldValue('budget', taskInfo.budget);
      form.setFieldValue('target_time', taskInfo.target_time);
      form.setFieldValue('free_description', taskInfo.free_description);

      form.setFieldValue('customer_id', taskInfo.customer_id);
      form.setFieldValue('start_date', new Date(taskInfo.start_date));
      // The reason is that the component that selects the leader only accepts inter type.
      const user_id = taskInfo?.user_id;
      const user_id_array = user_id ? String(user_id).split(',').map(Number) : [];
      form.setFieldValue('user_id', user_id_array as []);
      // TODO : 循环便利输出
      setExistsUserList(taskInfo.task_employee_roles.map((item: {
        user_info: any;
        username: any;
        email: any;
        role_name: any; user_id: any; role_id: any;
      }) => {
        return {
          id_user: item.user_id,
          permisson_role_title: item.role_name,
          id_permission_role: item.role_id,
          email: item.user_info.email,
          avatar: apiUrl.fileSystemDomain + item.user_info.avatar,
          username: item.user_info.username,

        }
      }))

      // id_user: user.value as string,
      // permisson_role_title: selectedUserRole?.label as string,
      // id_permission_role: selectedUserRole?.value as string,
      form.setFieldValue('employees', taskInfo.task_employee_roles.map((item: { user_id: any; role_id: any; }) => {
        return {
          user_id: item.user_id,
          user_role_id: item.role_id,

        }
      }));

      taskInfo.end_time && form.setFieldValue('start_date', new Date(taskInfo.end_time));
      if (taskInfo.order_id) {
        getOrderDeatil(taskInfo.order_id)
      }
      setRandNumber(rand_number + 1)
    }
  };



  const getOrderList = async (value: any) => {
    setOrderList(await getOrderListGeneralFunction(value))
  };

  // get task list <FUNCTION>
  const getTaskList = async (value: any) => {
    setUserTaskList((await getTaskListGeneralFunction(value, 'all')))
  };

  const taskSaveFormData = async () => {
    const order_id = await FormSubmitFunction('save');
  }

  // save task info <FUNCTION>
  const taskSaveAndNextFormData = async () => {
    callBackFunction(await FormSubmitFunction('save'));
  }

  const FormSubmitFunction = async (type = 'save') => {
    // Validate the form
    const valid = await form.validate();
    //  If there are validation errors, display error information
    if (valid.hasErrors) {
      HintErrorMantineInfo(valid);
      return false;
    }
    // TODO:Convert time into data received by the backend
    const submitFormValues = {
      ...form.values,
      start_date: formatDateUpgraded(
        form.values.start_date as Date,
        "YYYY-MM-DD"
      ),
      end_date: formatDateUpgraded(
        form.values.end_date as Date,
        "YYYY-MM-DD"
      ),
    };

    setLoading(true);
    const response = await requestTask(submitFormValues, form.values.id ? "PUT" : "POST");
    setLoading(false);
    HintInfo(response.data);
    if (response.data.code == 200) {
      if (type != 'save') {
        form.reset()
      }
      if (response.data.data.id) {
        return (response.data.data.id)
      }
      return ('')
    } else {
      return false;
    };
  };

  // slect project <FUNCTION>  
  const projectChangeFucntion = (value: string) => {
    form.setFieldValue("project_id", value)
    form.setFieldValue("order_id", '')
    try {
      setCurrentOrderInfo(undefined)
      getProjectDeatil(value)
      getOrderList({ project_id: value })
    } catch (error) {

    }
  }

  // slect order <FUNCTION>  
  const orderChangeFucntion = (value: string) => {
    form.setFieldValue("order_id", value)
    getOrderDeatil(value)
  }

  // slect customer <FUNCTION>  
  const customeChangeFucntion = (value: string) => {
    form.setFieldValue("customer_id", value)
    // Get all orders and projects of the current customer
    getOrderList({ customer_id: value })
    getProjectList({ customer_id: value })
  }


  // clear current CustomeInfo info  <FUNCTION>
  const clearCustomeInfo = () => {
    form.setFieldValue("customer_id", '')
    // TODO get all order list 
    getOrderList('');
    // TODO get all product list 
    getProjectList('');
  }
  // clear current project info  <FUNCTION>
  const clearCurrentProjectInfo = () => {
    setCurrentProjectInfo(undefined);
    // TODO get all order list 
    getOrderList('');
  }



  //Click the checkbox whether to associate order information <FUNCTION>
  const setIsLinkOrderFunction = (value: any) => {
    // If associated, make order selectable, otherwise disable the order option box
    form.setFieldValue("order_id", '')
    setCurrentOrderInfo(undefined)
    setIsLinkOrder(!isLinkOrder)
  }
  // Using new fields to assemble new data and adding it to form <FUNCTION>
  const callBackAddUserToTaskFunction = (value: any) => {
    if (Array.isArray(value)) {
      const employees = value.map((item: any) => ({
        "user_role_id": item.id_permission_role,
        "permissions": item.permissions,
        "title": item.permisson_role_title,
        "user_id": item.id_user,
      }));

      form.setFieldValue('employees', employees as []);
    }
  }
  // clear current project info  <FUNCTION>
  const callTaskListBackfunction = (value: any) => {
    if (value) {
      // 请求该项目下的Project
      getTaskList({ project_id: currentProjectInfo?.id_project })
    }
  }
  const editFunction = async () => {
    const editResult = await FormSubmitFunction();
    if (editResult !== false) {
      callBackFunction(form.values.id);
      return false
    }
    callBackFunction('-1');
  }
  return (
    <Paper px={20}>
      {/* <LoadingOverlay visible={loading} overlayBlur={2} /> */}
      <Box pb={20}>
        <Grid gutter={10} mt={10} columns={24} >
          <Grid.Col span={10} offset={1} className="">
            <Box className="border-radius-xs border-custom  px-xs">
              <Box className=" pt-xs ">
                {/*  associated projectname */}
                <Box pt={10} pb={10} className="px-15 border-bt-1">
                  <Grid>
                    <Grid.Col span={7}>
                      <TableTextInputTitle
                        title={word_translation.associated_project_name}
                      />
                      <TableTextInputTitleDesc
                        pt={2}
                        pb={2}
                        title={word_translation.associated_projec_desc_tips}
                      />
                      <Select
                        className="badge_text"
                        size="xs"
                        disabled={cannotBeChanged ? true : false}
                        key={currentProjectInfo?.id_project.toString()}
                        searchable={true}
                        placeholder={word_translation.pick_one}
                        defaultValue={currentProjectInfo?.id_project ? currentProjectInfo?.id_project.toString() : ""}
                        rightSection={<ActionIcon onClick={() => clearCurrentProjectInfo()}> <IconX /></ActionIcon>}
                        nothingFound={word_translation.no_data}
                        onChange={(value) => projectChangeFucntion(value as string)}
                        data={projectList}
                      />
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <TableTextInputTitle
                        title={word_translation.associated_project_no}
                      />
                      <TableTextInputTitleDesc
                        pt={2}
                        pb={2}
                        title={word_translation.associated_project_no}
                      />

                      {currentProjectInfo?.project_no &&
                        <Box mt={10} pl={2} h={30} className="border-custom align-center"  >
                          <Badge radius={5}>{currentProjectInfo?.project_no}</Badge>
                        </Box>}

                    </Grid.Col>
                  </Grid>
                  <Grid>
                    <Grid.Col span={8}>
                      <TableTextInputTitle
                        title={word_translation.associated_customer}
                      />
                      <Select
                        placeholder={word_translation.pick_one}
                        searchable
                        key={form.values.customer_id}
                        size="xs"
                        rightSection={<ActionIcon onClick={() => clearCustomeInfo()}> <IconX /></ActionIcon>}
                        disabled={form.values.project_id ? true : false}
                        defaultValue={form.values.customer_id?.toString()}
                        nothingFound={word_translation.no_data}
                        onChange={(value) => customeChangeFucntion(value as string)
                        }
                        data={customerCurrentPageList}
                      />
                    </Grid.Col>
                  </Grid>
                </Box>
                {/*  associated project end */}

                <Box className=" pt-xs">
                  {/*  associated order */}
                  <Box pt={10} pb={10} className="px-15 border-bt-1">
                    <Grid gutter={0}>
                      <Grid.Col span={12}>
                        <Checkbox
                          size="xs"
                          label={
                            <TableTextInputTitle py={0} title={word_translation.link_order} />}
                          checked={isLinkOrder}
                          disabled={cannotBeChanged ? true : false}
                          onChange={(event) => setIsLinkOrderFunction(event.currentTarget.checked)}
                        />
                      </Grid.Col>
                    </Grid>
                    <Grid>
                      <Grid.Col span={7}>
                        <TableTextInputTitle py={0} title={word_translation.associated_order_name} />
                        <Select
                          required
                          maxDropdownHeight={150}
                          labelProps={{ className: "input_title" }}
                          placeholder={word_translation.pick_one}
                          disabled={cannotBeChanged ? true : !isLinkOrder}
                          key={currentOrderInfo?.id_order.toString()}
                          onChange={(value) => orderChangeFucntion(value as string)}

                          defaultValue={currentOrderInfo?.id_order.toString()}
                          size="xs"
                          data={orderList}
                        />
                      </Grid.Col>
                      <Grid.Col span={4}>
                        <TableTextInputTitle py={0} title={word_translation.associated_order_no} />
                        {currentOrderInfo?.order_no &&
                          <Box pl={2} h={30} className="border-custom align-center"  >
                            <Badge radius={5}>{currentOrderInfo?.order_no}</Badge>
                          </Box>}
                      </Grid.Col>
                    </Grid>
                  </Box>

                </Box>
                {/*  create task Info  */}
                <Box pt={10} className="px-15 ">
                  <Grid>
                    <Grid.Col span={7}>
                      <TableTextInputTitle
                        title={word_translation.task_category}
                      />
                      <TableTextInputTitleDesc
                        title={word_translation.choose_now_or_late}
                      />
                      <Select
                        placeholder={word_translation.task_category}
                        searchable
                        size="xs"
                        key={form.values.task_category_id}
                        defaultValue={form.values.task_category_id?.toString()}
                        nothingFound={word_translation.no_data}
                        onChange={(value) =>
                          form.setFieldValue("task_category_id", value as string)
                        }
                        data={taskCategoryList}
                      />
                      <TableTextInputTitle title={word_translation.description} />
                      <Textarea
                        placeholder={word_translation.Input}
                        size="xs"
                        {...form.getInputProps("free_description")}
                      />

                      <TableTextInputTitle
                        title={word_translation.task_team_leader}
                      />
                      <MultiSelect
                        onChange={(value) =>
                          form.setFieldValue("user_id", value as [])
                        }
                        size="xs"
                        key={rand_number}
                        defaultValue={form.values.user_id}
                        placeholder={word_translation.task_team_leader}
                        searchable
                        data={userList}
                      />
                      <Grid>
                        <Grid.Col span={6}>
                          <TableTextInputTitle title={word_translation.start_time} />
                          <DateInput
                            valueFormat="DD/MM/YYYY"
                            minDate={new Date(initSystemTime)}
                            size="xs"
                            defaultValue={new Date()}
                            required
                            icon={<IconCalendar size="1.1rem" stroke={1.5} />}
                            {...form.getInputProps("start_date")}
                            // onBlur={(event) => checkTime(event.target.value)}
                            placeholder={word_translation.start_time}
                          />
                        </Grid.Col>

                        <Grid.Col span={6}>
                          <TableTextInputTitle title={word_translation.end_time} />
                          <DateInput
                            valueFormat="DD/MM/YYYY"
                            size="xs"
                            minDate={form.values.start_date}
                            required
                            icon={<IconCalendar size="1.1rem" stroke={1.5} />}
                            {...form.getInputProps("end_date")}
                            placeholder={word_translation.end_time}
                          // onBlur={(event) => checkTime(event.target.value)}
                          />
                        </Grid.Col>


                      </Grid>
                    </Grid.Col>
                    <Grid.Col span={5}>
                      <TableTextInputTitle
                        icon_l={<IconMoneybag size={15} />}
                        title={word_translation.budget}
                      />
                      <TableTextInputTitleDesc
                        title={word_translation.leave_empty}
                      />
                      <TextInput
                        size="xs"
                        placeholder={word_translation.budget}
                        {...form.getInputProps("budget")}
                      />
                      <TableTextInputTitle
                        icon_l={<IconHistory size={15} />}
                        title={word_translation.target_time}
                      />
                      <TextInput
                        placeholder={word_translation.target_time}
                        {...form.getInputProps("target_time")}
                        size="xs"
                      />
                      <TableTextInputTitle
                        icon_l={<IconBuilding size={15} />}
                        title={word_translation.select_cost_center}
                      />

                      <Select
                        labelProps={{ className: "input_title" }}
                        onChange={(value) => {
                          form.setFieldValue("costCenter_id", value as string)
                        }
                        }
                        rightSection={<ActionIcon size='xs' onClick={() => form.setFieldValue("costCenter_id", '')}> <IconX /></ActionIcon>}
                        placeholder={word_translation.select_cost_center}
                        searchable
                        key={form.values.costCenter_id}
                        size="xs"
                        defaultValue={form.values.costCenter_id?.toString()}
                        data={costCenterData}
                      />
                      <Group position="right" p={0} >
                        <TableTextInputTitle title={word_translation.users_counts} />
                        <TextInput
                          size="xs"
                          // value={form.values.task_employee_roles.length}
                          placeholder={word_translation.users_counts}
                        />
                      </Group>
                    </Grid.Col>
                  </Grid>

                  <Grid align="center" py={8}>
                    <Grid.Col span={12}>
                      <Group position="right" >
                        <ResetBtn callBack={resetProductFormFunction} />
                        <UniversalButton btn_text={word_translation.add_task} callBack={FormSubmitFunction} btn_size='xs' btn_color="green" btn_icon={<IconPlus />} />
                      </Group>
                    </Grid.Col>
                  </Grid>
                </Box>
                {/*  order Info end  */}
                {/* product Info */}
              </Box>
            </Box>
          </Grid.Col>

          <Grid.Col span={11} className="">
            <Box className="border-radius-xs border-custom ">
              <Box pt={10} className="px-15  ">
                <TaskList callBack={callTaskListBackfunction} callBackTaskIDfunction={callBackTaskIDfunction} is_load={false} userTaskList={userTaskList} is_refresh={0} callBackfunction={callBackProductFunction} returnAttributeList={() => { }} />
              </Box>
            </Box>
            <Box mt={10} className="border-radius-xs border-custom ">
              <AddUserToTask
                existsUserList={existsUserList}
                callBackFunction={callBackAddUserToTaskFunction}
                UserList={UserList}
                projectUserRolelist={defaultRoleData} />
            </Box>
          </Grid.Col>
        </Grid>
        {(form.values.id || cannotBeChanged) &&
          <Group position="right" mt={5}>
            <UniversalButton btn_bg_color='#868E96' btn_text={word_translation.cancel} btn_size='xs' callBack={() => { }} />
            <UniversalButton btn_text={form.values.id ? word_translation.edit : word_translation.add} btn_size='xs' callBack={() => editFunction()} />
          </Group>
        }
      </Box>
    </Paper>
  );
})
export default TaskFrom