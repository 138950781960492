import { Button, Tooltip } from "@mantine/core";
import { IconCircleCheckFilled, IconCirclePlus } from "@tabler/icons-react";
import { FormattedMessage } from "react-intl";
import WordTranslationComponent from "../../utils/language_pack/words";

// Define the props interface for the ChildComponent
interface ChildComponentProps {
    btn_text?: string;
    btn_color?: string;
    btn_icon?: any;
    disabled?: boolean;
    btn_fw?: any;
    btn_size?: any;
    btn_bg_color?:any;
    btn_variant?: string;
   callBack: () => void; // Callback function to handle button click events
}

// ChildComponent representing the Create Button
const UniversalButton: React.FC<ChildComponentProps> = ({btn_bg_color,btn_size='xs',btn_text,btn_fw,btn_variant,btn_color,btn_icon,disabled = false, callBack }) => {
  
  // Click event handler to invoke the callback function
  const handleButtonClick = () => {
    callBack();
  };

  return (
    // Button component with a submit type, click event handler, and left icon
    <Button
      type="button"
      fw={btn_fw}
      size={btn_size}
      disabled = {disabled}
      variant={btn_variant}
      color={btn_color ? btn_color : ''}
      bg={btn_bg_color ? btn_bg_color : ''}
      onClick={handleButtonClick}
      leftIcon={btn_icon}
      styles={{
        leftIcon: { marginRight: "5px" },
      }}
    >
      {btn_text} {/* Display the "create" text */}
    </Button>
  );
};

export default UniversalButton;
