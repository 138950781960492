//

import { Group } from "@mantine/core";
import WordTranslationComponent from "../../../../utils/language_pack/words";
import { PermissionRoleInterface } from "../../../../interface/Iemloyee";
import ActiveBadge from "../../../../ttcomponents/ActiveBadge/ActiveBadge";
import EditIcon from "../../../../ttcomponents/EditIcon/EditIcon";
import DeleteIcon from "../../../../ttcomponents/DeleteIcon/DeleteIcon";


interface ComponentInterface {
  currentItem: PermissionRoleInterface;
  editHandle: (value: PermissionRoleInterface) => void;
  delHandle: (value: PermissionRoleInterface) => void;
}

export default function PermissionSetTableTr({  currentItem,editHandle,delHandle}: ComponentInterface) {

  // Get translation public configuration information
  const word_translation = WordTranslationComponent();
  // Get the information to be modified and pass to the parent -level component
  return (
    <tr  className="border-bt-2 my-10 ">
      <td> {currentItem.title}</td>
      <td> {currentItem.description}</td>
      <td> {currentItem.identifier}</td>
      <td> {currentItem.costCenter_id}</td>
      <td><ActiveBadge value={currentItem.active} /></td>
      <td>
        <Group position="center">
            <EditIcon callBack={() => editHandle(currentItem)}/>
            <DeleteIcon callBack={() => delHandle(currentItem)}/>
        </Group>
      </td>
    </tr>
  )
}