import { ActionIcon, Box, LoadingOverlay, ScrollArea, Table, Tooltip, Paper, Group, TextInput } from '@mantine/core'
import { useEffect, useState } from 'react'
import { IconSearch } from '@tabler/icons-react'
import WordTranslationComponent from '../../../../../../utils/language_pack/words'
import React from 'react'
import { PermissionRoleInterface } from '../../../../../../interface/Iemloyee'
import ListTr from './tr'
import TableTextInputTitle from '../../../../../../ttcomponents/TableTextInputTitle/TableTextInputTitle'
import { useCostCenterLeader } from '../../context'

interface ComponentInterface {
  randNumber: number
  requestPermissionRole: (data: any, method: any) => Promise<any>
  requestPermissionRoleList: (data: any, method: any) => Promise<any>
}

export default function CommonRoleList({
  randNumber,
  requestPermissionRole,
  requestPermissionRoleList
}: ComponentInterface) {
  const [loading, setLoading] = useState(false)
  // Get translation public configuration information
  const word_translation = WordTranslationComponent()

  const { costCenter_id, identifier } = useCostCenterLeader()

  const [permissionRoleData, setPermissionRoleData] = useState<PermissionRoleInterface[]>([])

  const [value, setSearchValue] = useState('')

  const getCorePermissionRoleList = async () => {
    if (!costCenter_id) {
      return false
    }
    setLoading(true)
    try {
      const data: {
        costCenter_id: string
        identifier?: string
        query?: string
      } = {
        costCenter_id
      }
      if (identifier) {
        data.identifier = identifier
      }
      if (value) {
        data.query = value
      }
      const response = await requestPermissionRoleList(data, 'GET')
      const result = response.data
      // Check if the response is successful and the data is not an array
      if (result.code === 200 && Array.isArray(result.data)) {
        // Update the total count and user registration data in component state
        setPermissionRoleData(result.data)
      } else {
        // Log an error message if the response indicates an issue
        console.error(result.msg)
      }
    } catch (error) {
      // Log any errors that occur during the request
      console.error('Error fetching user registrations:', error)
    } finally {
      // Set loading state to false regardless of success or failure
      setLoading(false)
    }
  }
  useEffect(() => {
    if (costCenter_id) {
      getCorePermissionRoleList()
    } else {
      setPermissionRoleData([])
    }
  }, [costCenter_id, identifier, randNumber])

  const setValueFunction = () => {
    getCorePermissionRoleList()
  }
  return (
    <Paper>
      <Box>
        <Box>
          <Group py={4} position="apart">
            <Box>
              <TableTextInputTitle title={word_translation.permission_set} />
            </Box>
            <Box>
              <Group>
                <TextInput
                  onChange={event => setSearchValue(event.currentTarget.value)}
                  size={'xs'}
                  rightSection={
                    <ActionIcon onClick={setValueFunction}>
                      <Tooltip label={word_translation.search}>
                        <IconSearch color="#cccccc" />
                      </Tooltip>
                    </ActionIcon>
                  }
                />

                <TableTextInputTitle title={`${word_translation.total_count} : ${permissionRoleData.length}`} />
              </Group>
            </Box>
          </Group>
        </Box>
      </Box>

      <Paper px={20} py={10} mih={270} radius="md" withBorder p="lg" bg="var(--mantine-color-body)">
        <LoadingOverlay visible={loading} overlayBlur={2} />
        <ScrollArea  h={260}>
          <Table>
            <thead className="scrolleder ">
              <tr>
                <th id="table-th-title-new"></th>
                <th id="table-th-title-new">{word_translation.title}</th>
                {/* <th  id="table-th-title-new">{word_translation.identifier}</th> */}
                <th id="table-th-title-new">{word_translation.cost_center_name}</th>
                <th id="table-th-title-new">{word_translation.created_at}</th>
                <th id="table-th-title-new">{word_translation.creator}</th>
                <th id="table-th-title-new">{word_translation.members}</th>
                <th id="table-th-title-new">{word_translation.active}</th>
                <th id="table-th-title-new">{word_translation.option}</th>
              </tr>
            </thead>
            <tbody>
              {permissionRoleData &&
                permissionRoleData?.length > 0 &&
                permissionRoleData?.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <ListTr
                        refreshRoleList={getCorePermissionRoleList}
                        row={item}
                        index={index}
                        requestPermissionRole={requestPermissionRole}
                      />
                    </React.Fragment>
                  )
                })}
            </tbody>
          </Table>
        </ScrollArea>
      </Paper>
    </Paper>
  )
}
