import { Box,  Modal,  Paper } from "@mantine/core";
import {  useEffect, useState } from "react";
import { useDisclosure } from "@mantine/hooks";
import TableProjectStatus from "../../ttcomponents/Table/TableProjectStatus";
import ProjectStatusForm from "./project_status_form";
import { regProjectStatus } from "../../api";
import { IProjectStatusData } from "../../interface/IprojectStatus";
import PageTitle from "../../ttcomponents/PageTitle/PageTitle";
import WordTranslationComponent from "../../utils/language_pack/words";
import { getItemStatus } from "../../api/assignments_request";
export default function ProjectStatus() {
    
    // Get translation public configuration information
    const word_translation = WordTranslationComponent();
    const [openedModal1, { open: openModal1, close: closeModal1 }] = useDisclosure(false);
    const [projectStatusList, setProjectStatusList] = useState<IProjectStatusData[]>([]);
    const [projectStatusData, setProjectStatusData] = useState<IProjectStatusData>({ id_status: 0, status_name: '', active:0,status_desc: '', });

    /**
* 
* Get user information
*/
    const ajaxCurrentList = async () => {
        setProjectStatusList(await getItemStatus('','all'));
    }


    const receiveNotifications = (result: boolean) => {
        closeModal1()
        ajaxCurrentList();
    }
    
    useEffect(() => {
        ajaxCurrentList();
    }, []);
    const handleOnValueEdit = (value: object) => {
        openModal1()
        const editedValue = value as IProjectStatusData;
        setProjectStatusData(editedValue)
    };

    const handleOnValueDel = (values: boolean) => {
        closeModal1()
    }

    return (
        <Paper px={20} pb={10} w={"100%"} style={{ position: "relative" }}>
           <PageTitle title={word_translation.status_manage}  />
            <Box mt={10} w={{ base: '100%', xs: 200, sm: 600, lg: '100%', md: 800 }} pb={20} >
                <TableProjectStatus onValueEdit={handleOnValueEdit} onValueDel={handleOnValueDel} callBack={receiveNotifications} projectStatusData={projectStatusList} />
            </Box>
			
            <Modal radius='md' size="sm" opened={openedModal1} onClose={closeModal1} title={word_translation.status}   >
                <ProjectStatusForm projectStatusSingleData={projectStatusData} callBack={receiveNotifications} />
            </Modal>
        </Paper>
        
    )
}

