/**
 * 发送异步请求的函数ajax
 *
 */
import axios from "axios";
import memoryUtils from "../utils/memoryUtils";
import { notifications } from "@mantine/notifications";
import { IconX } from "@tabler/icons-react";
import { Box } from "@mantine/core";

export default function ajax(
  url,
  data = {},
  type = "GET",
  isContainsFiles = false
) {
  const token = memoryUtils.user.token;
  return new Promise((resolve, reject) => {
    let promise;
    //发送GET请求
    //send GET request
    if (type === "GET") {
      let config = {
        headers: {
          Authorization: token,
        },
      };
      promise = axios.get(url, {
        params: data,
        ...config,
      });
      //发送POST请求
      //Send POST request
    } else if (type === "POST") {
      // 默认application/json方式提交
      // Submit by default application/json
      let config = {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      };

      //  文件提交
      // file submission
      if (isContainsFiles === true) {
        config = {
          headers: {
            Authorization: token,
            "content-type": "multipart/form-data",
          },
        };
      }
      promise = axios.post(url, data, config);
    } else if (type === "PUT") {
      // 默认application/json方式提交
      // Submit by default application/json
      let config = {};
      if (isContainsFiles === "blob") {
        config = {
          headers: {
            Authorization: token,
            "Content-Type": "text/plain",
          },
          responseType: "blob",
        };
      } else if (isContainsFiles === true) {
        config = {
          headers: {
            Authorization: token,
            "content-type": "multipart/form-data",
          },
        };
      } else {
        config = {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        };
      }
      promise = axios.put(url, data, config);
    } else if (type === "DELETE") {
      // Submit by default application/json
      let config = {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      };
      // file submission
      if (isContainsFiles === true) {
        config = {
          headers: {
            Authorization: token,
            "content-type": "multipart/form-data",
          },
        };
      }
      promise = axios.delete(url, { data, ...config });
    }
    promise
      .then((response) => {
        if (response.data.code === 401) {
          window.location.href = "/login";
          notifications.show({
            title: response.data.msg,
            color: "yellow.7",
            icon: <IconX />,
            message:
              "Login information is abnormal, please exit and log in again",
          });
        }
        resolve(response);
      })
      .catch((error) => {
        return <>123</>;
      });
  });
}
