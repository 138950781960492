import {
  ActionIcon,
  Paper,
  Flex,
  Select,
  Button,
  Code,
  Grid,
  Group,
  LoadingOverlay,
  Box,
  Menu,
  Modal,
  ScrollArea,
  Table,
  Text,
  ThemeIcon,
  Slider,
  Divider,
  Avatar,
  Badge,
} from "@mantine/core";
import { useEffect, useState, FormEvent, forwardRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  HintInfo,
  formatTimeHHSS,
  getWeeKAll,
  getYearAll,
} from "../../../utils/function";
import { SelectRole, selectApiRespone } from "../../../interface/Icommon";
import {
  regGetFilter,
  regProject,
  regProjectList,
  regReportEmployeeReport,
  regReportsByFilter,
  regUserAll,
} from "../../../api";
import { ResponseUserData } from "../../../interface/Iuser";
import { IconCheck, IconClipboardPlus, IconX } from "@tabler/icons-react";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { WeeklyTimeTrackerReports } from "../../../interface/Ireports";
import {
  IFilterStatistic,
  detailProps,
  filterReportData,
} from "../../../interface/IfilterStatistic";
import { useDisclosure } from "@mantine/hooks";
import FilterItemReports from "./filterItemReport";
import PageTitle from "../../../ttcomponents/PageTitle/PageTitle";

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  image: string;
  label: string;
  description: string;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ image, label, description, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <div>
          <Text size="xs" opacity={0.65}>
            {image}
          </Text>
          <Badge>{label}</Badge>
        </div>
      </Group>
    </div>
  )
);

export default function FilterReportStastic() {
  // Use the useIntl hook to access the intl object
  const intl = useIntl();

  //Get all the weekly collection
  const weekNumberAll = getWeeKAll();
  //Get all the Year collection
  const yearNumberAll = getYearAll();
  // Get all the project collection
  // Get all employees
  const [userlistnew, setUserList] = useState<SelectRole[]>([]);
  const [reportData, setReportData] = useState<filterReportData>();
  // 选择过滤项
  const [filterItemList, setFilterItemList] = useState<SelectRole[]>([]);
  const [filterUserList, setFilterUserList] = useState<SelectRole[]>([]);

  const [detailPropsData, setDetailPropsData] = useState<detailProps>({
    id: 0,
    start_week: "",
    end_week: "",
    type: "",
    year: "",
  });

  const [idNow, setIdNow] = useState(0);

  const [openedModal1, { open: openModal1, close: closeModal1 }] =
    useDisclosure(false);
  const [WeeklyTimeTracker, SetWeeklyTimeTracker] = useState<
    WeeklyTimeTrackerReports[]
  >([]);

  //Mid -load state
  const [loading, setLoading] = useState(false);
  // The data is initialized after the page is loaded
  const ajaxCurrentList = async () => {
    setLoading(true);
    //Get all the project lists
    const resFilter = await regGetFilter();
    const { data } = resFilter.data as IFilterStatistic;
    const itemFilterList = data.filter((item) => {
      return item.type === "1";
    });

    const users_filter = data.filter((item) => {
      return item.type === "2";
    });
    setFilterItemList(
      itemFilterList.map((item) => {
        return {
          image: item.filter_type,
          label: item.filter_name,
          value: item.id_filter,
        };
      })
    );

    setFilterUserList(
      users_filter.map((item) => {
        return {
          label: item.filter_name,
          value: item.id_filter,
        };
      })
    );

    setLoading(false);
  };
  useEffect(() => {
    ajaxCurrentList();
  }, []);
  // Form submission
  const form = useForm({
    initialValues: {
      year: "",
      // filter_type: 'project',
      start_week: "",
      end_week: "",
      item_filter_id: "",
      user_filter_id: "",
    },
    validate: {
      end_week: (value, values) => {
        if (parseInt(values.start_week) > parseInt(value)) {
          return "The end week must be greater than the beginning week";
        }
        return null;
      },
      start_week: (value) => {
        if (!value) {
          return "start week is required";
        }
        return null;
      },
    },
  });

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Stop the formal submission of the form default
    // Verification form
    const valid = await form.validate();

    if (!valid.hasErrors) {
      const response = await regReportsByFilter(form.values, "GET");
      const result = response.data.data as filterReportData;
      setReportData(result);
    }
  };

  // check the details
  const getDetail = (id_item: number, type: string) => {
    setDetailPropsData({
      id: id_item,
      type: type,
      year: form.values.year || "",
      start_week: form.values.start_week || "",
      end_week: form.values.end_week || "",
    });
    setIdNow(id_item);
    openModal1();
  };

  // 第二种展示
  const rows = reportData ? (
    reportData.items_list.map((item, index) => {
      return (
        <>
          <tr key={index}>
            <td>
              <Text className="title_text " pt={5} size={15} color="dark">
                {item.name}
              </Text>
            </td>
            <td>
              {item.timetracker_list.map((timeItem, index) => {
                return (
                  <>
                    <Text className="title_text " key={index} pt={5} size={13}>
                      {" "}
                      {timeItem.user_name}
                    </Text>
                  </>
                );
              })}
            </td>
            {reportData.week_number_list.map((weekitem) => {
              return (
                <td>
                  {item.timetracker_list.map((timeItem, index) => {
                    const isbool = timeItem.week_time[weekitem] !== "00:00";
                    return (
                      <>
                        <Text
                          className="title_text "
                          key={index}
                          pt={5}
                          size={12}
                          color={`${isbool ? "green" : "dark"}`}
                        >
                          {" "}
                          {isbool ? timeItem.week_time[weekitem] : "N/A"}
                        </Text>
                      </>
                    );
                  })}
                </td>
              );
            })}
            <td>
              {item.timetracker_list.map((timeItem, index) => {
                const isboolTotal = timeItem.week_total_time !== "00:00";
                return (
                  <>
                    <Text
                      className="title_text "
                      key={index}
                      pt={5}
                      size={15}
                      color="dark"
                    >
                      {" "}
                      {isboolTotal ? timeItem.week_total_time : "N/A"}
                    </Text>
                  </>
                );
              })}
            </td>
          </tr>
        </>
      );
    })
  ) : (
    <Text className={"textcenter"}></Text>
  );

  return (
    <Paper
      px={20}
      mb={20}
      style={{ overflowY: "auto", overflowX: "hidden", minHeight: "70vh" }}
    >
      <PageTitle title="FILTER STATISTICS" lang_id="FILTER STATISTICS" />
      <Box>
        <form onSubmit={handleFormSubmit}>
          <Group className="" mt={10} align="center">
            {/* <Select
                            placeholder="Select Filter Type"
                            searchable
                            required
                            defaultValue={"project"}
                            key={'project'}
                            onChange={changeSelectFilterType}

                            data={itemTypeList}
                        /> */}
            <Select
              placeholder={intl.formatMessage({
                id: "Select A Filter",
                defaultMessage: "Select A Filter",
              })}
              searchable
              required
              {...form.getInputProps("item_filter_id")}
              itemComponent={SelectItem}
              data={filterItemList}
            />
            <Select
              placeholder={intl.formatMessage({
                id: "Select User Filter",
                defaultMessage: "Select User Filter",
              })}
              searchable
              {...form.getInputProps("user_filter_id")}
              data={filterUserList}
            />
            <Select
              placeholder={intl.formatMessage({
                id: "Choose Year",
                defaultMessage: "Choose Year",
              })}
              required
              {...form.getInputProps("year")}
              searchable
              data={yearNumberAll}
            />
            <Select
              placeholder={intl.formatMessage({
                id: "Start Week",
                defaultMessage: "Start Week",
              })}
              searchable
              required
              {...form.getInputProps("start_week")}
              data={weekNumberAll}
            />
            {intl.formatMessage({
              id: "To",
              defaultMessage: "To",
            })}
            <Select
              placeholder={intl.formatMessage({
                id: "End Week",
                defaultMessage: "End Week",
              })}
              searchable
              required
              {...form.getInputProps("end_week")}
              data={weekNumberAll}
            />
            <Button type="submit" leftIcon={<IconClipboardPlus />}>
              {intl.formatMessage({
                id: "Generate Reports",
                defaultMessage: "Generate Reports",
              })}
            </Button>
          </Group>
        </form>
      </Box>
      <ScrollArea mt={20}>
        {reportData && reportData.items_list.length > 0 ? (
          <Table>
            <thead>
              <tr key={"index"}>
                <th>
                  <Text className="title_text " size={18} color="dark">
                    {" "}
                    {reportData.items_list[0]?.type}/Week
                  </Text>
                </th>
                <th>
                  <Text className="title_text " size={18} color="dark">
                    {" "}
                    Customer
                  </Text>
                </th>
                {reportData.week_number_list.map((item, index) => {
                  return <th key={index}>KW{item}</th>;
                })}
                <th>
                  <Text className="title_text " size={12} color="dark">
                    {" "}
                    ∑
                  </Text>
                </th>
                <th>
                  {/* <Text className="title_text " size={12} color="dark" > Detail</Text> */}
                </th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
        ) : (
          <Box w="100%" className="text-center">
            {" "}
            <Divider
              my="xs"
              label={<FormattedMessage id="No Data" defaultMessage="No Data" />}
              labelPosition="center"
            />
          </Box>
        )}

        <Modal
          radius="md"
          size={"lg"}
          opened={openedModal1}
          className="modal-setting"
          onClose={closeModal1}
          title={
            <FormattedMessage
              id="Report Detail"
              defaultMessage="Report Detail"
            />
          }
          overlayProps={{ opacity: 0.5, blur: 4 }}
        >
          <FilterItemReports detailPropsData={detailPropsData} />
        </Modal>
      </ScrollArea>
    </Paper>
  );
}
