import { ActionIcon, Box, Group, Table, Text } from "@mantine/core";
import { modals } from "@mantine/modals";
import { regCustomerRole, regOrdername } from "../../api";
import { IconCheck, IconPencil, IconTrash, IconX } from "@tabler/icons-react";
import { notifications } from "@mantine/notifications";
import {
  IOrderNameWithCallback,
  IOrderNameData,
  APIResponse,
} from "../../interface/IorderName";
import { useIntl } from "react-intl";

export default function TableOrderNames({
  orderNameData,
  onValueEdit,
  callBack,
  onValueDel,
}: IOrderNameWithCallback) {
  // Use the useIntl hook to access the intl object
  const intl = useIntl();

  // 获取要修改的信息并传递到父级组件当中  Get the information to be modified and pass to the parent -level component
  const editCustomer = (value: object) => {
    const editData = value as IOrderNameData;
    onValueEdit!(editData);
  };
  // 删除客户 并给通知  Delete customers
  const delHandle = (row: object) => {
    const delValue = row as IOrderNameData;
    modals.openConfirmModal({
      title: intl.formatMessage({
        id: "delete_title",
        defaultMessage: "Delete Confirmation",
      }),
      centered: true,
      children: (
        <Box>
          <Box>order name: {delValue.name}</Box>
          <Box>order desc: {delValue.order_name_description}</Box>
        </Box>
      ),
      labels: {
        confirm: intl.formatMessage({
          id: "confirm_delete",
          defaultMessage: "Confirm Delete",
        }),
        cancel: intl.formatMessage({
          id: "cancel_delete",
          defaultMessage: "Cancel Delete",
        }),
      },
      confirmProps: { color: "red" },
      onConfirm: async () => {
        const FormData = {
          id: delValue.id_order_name,
        };
        const response = await regOrdername(FormData, "DELETE");
        const result = response.data;
        if (result.code === 200) {
          callBack(true);
          notifications.show({
            color: "green",
            icon: <IconCheck />,
            message: result.msg,
          });
        } else {
          notifications.show({
            color: "red",
            icon: <IconX />,
            message: result.msg,
          });
        }
      },
    });
  };

  const rows = orderNameData ? (
    orderNameData.map((item, index) => (
      <tr key={index}>
        <td>
          {" "}
          <Text fz="sm">{item.id_order_name} </Text>{" "}
        </td>
        <td>
          {" "}
          <Text fz="sm">{item.name} </Text>{" "}
        </td>
        <td>
          {" "}
          <Text fz="sm">{item.order_name_description}</Text>{" "}
        </td>
        <td>
          <Group spacing={0} position="left">
            <ActionIcon onClick={() => editCustomer(item)}>
              <IconPencil size="1rem" stroke={1.5} />
            </ActionIcon>
            <ActionIcon onClick={() => delHandle(item)}>
              <IconTrash size="1rem" stroke={1.5} />
            </ActionIcon>
          </Group>
        </td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan={6}>Loading...</td>
    </tr>
  );
  return (
    <Table
      mt={20}
      striped
      highlightOnHover
      withBorder
      withColumnBorders
      fontSize="sm"
    >
      <thead>
        <tr>
          <th>Order Name ID</th>
          <th>Order Name</th>
          <th>Order Name Desc</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </Table>
  );
}
