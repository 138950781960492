import {  Box,  Paper,  Grid,  Group,  LoadingOverlay,  TextInput,} from "@mantine/core";
import { hasLength, isEmail,matches, useForm } from "@mantine/form";
import { regCustomer } from "../../../api";
import {  useState } from "react";
import WordTranslationComponent from "../../../utils/language_pack/words";
import TableTextInputTitle from "../../../ttcomponents/TableTextInputTitle/TableTextInputTitle";
import SubmitBtn from "../../../ttcomponents/Button/SubmitBtn";
import TableTopTitle from "../../../ttcomponents/TableTopTitle/TableTopTitle";
import { phoneRegex, urlRegex } from "../../../utils/validataUnits";
import { HintInfo, HintInfoCatchError, HintInfoError } from "../../../utils/function";
import { CustomerData } from "../../../interface/Icoustomer";

interface CustomerFromProps {
  callBack: (id: string, type: 'editCustomer' | 'addContactName' | 'openCustomer') => void;
  current_item?:CustomerData
}

export default function CustomerFrom({ callBack,current_item }: CustomerFromProps) {
  // Get translation public configuration information
  const word_translation = WordTranslationComponent();
  // Loading status
  const [loading, setLoading] = useState(false);
  const form = useForm({
    validateInputOnBlur: true,
    initialValues: {
      company_name: current_item ? current_item.company_name:"",
      id_customer: current_item ? current_item.id_customer: 0,
      email: current_item ? current_item.email: "",
      street:  current_item ? current_item.street:"",
      postalcode:  current_item ? current_item.postalcode:"",
      city:  current_item ? current_item.city:"",
      tel: current_item ? current_item.tel: 0,
      website: current_item ? current_item.website: "",
    },
    validate: {
      company_name: hasLength({ min: 2, max: 100 }, `${word_translation.company_name}${word_translation.validata_value_need_2_100_length}`),
      // street: hasLength({ min: 2, max: 100 }, `${word_translation.street}${word_translation.validata_value_need_2_100_length}`),
      // city: hasLength({ min: 2, max: 100 }, `${word_translation.city}${word_translation.validata_value_need_2_100_length}`),
      // email: isEmail(word_translation.validata_email),
      // postalcode: matches(/^[0-9a-zA-Z]+$/, word_translation.validata_postal_code),
      // website: matches(urlRegex, `${word_translation.website}${word_translation.validata_url}`),
      // tel: matches(phoneRegex, `${word_translation.phone}${word_translation.validata_phone}`),

    },
  });

// Submit customer information
const handleFormSubmit = async () => {
  // Validate the form
  const valid = await form.validate();
  
  // Prepare the data
  let originalData = {
    company_name: form.values.company_name,
    street: form.values.street,
    postalcode: form.values.postalcode,
    website: form.values.website,
    tel: form.values.tel,
    city: form.values.city,
    email: form.values.email,
    customer_roles: [],
  };
  let formData = {};
  
  // Determine the request method (POST or PUT)
  const methodType = form.values.id_customer ? "PUT" : "POST";
  
  // Set form data based on the request method
  formData = form.values.id_customer
    ? { ...originalData, id: form.values.id_customer }
    : originalData;

  // If there are no validation errors, proceed with the request
  if (!valid.hasErrors) {
    setLoading(true);
    try {
      const response = await regCustomer(formData, methodType);
      const result = response.data;
      // Display information based on the response
      if (result.code === 200) {
        HintInfo(response.data);
        form.reset();
        callBack(result.data.id as string, 'editCustomer');
      }else{
        HintInfoError(result.msg)
      }
    } catch (error) {
      HintInfoCatchError();
    } finally {
      setLoading(false);
    }
  }
};



  return (
    <>
      <Paper>
        <Box className={`${current_item?.id_customer ? '' : 'border-custom'} border-radius-xs min-h-25`}>
        <LoadingOverlay  visible={loading} overlayBlur={2} />
          {current_item?.id_customer == '' &&  <TableTopTitle title={word_translation.company_info} /> }
          <Grid className={`${current_item?.id_customer ? '' : 'px-15'}`}>
            <Grid.Col span={7}>
              <TableTextInputTitle py={current_item?.id_customer ? 0 : 20} title={word_translation.company_name} />
              <TextInput
                required
                placeholder={word_translation.company_name}
                {...form.getInputProps("company_name")}
              />

            </Grid.Col>
            <Grid.Col span={12}>
              <TableTextInputTitle title={word_translation.street} />
              <TextInput
                placeholder={word_translation.street}
                {...form.getInputProps("street")}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <Grid>
                <Grid.Col span={4}>
                  <TableTextInputTitle title={word_translation.company_postal_code} />
                  <TextInput
                    required
                    placeholder={word_translation.Input}
                    {...form.getInputProps("postalcode")}
                  />
                </Grid.Col>
                <Grid.Col span={8}>
                  <TableTextInputTitle title={word_translation.city} />
                  <TextInput
                    required
                    placeholder={word_translation.Input}
                    {...form.getInputProps("city")}
                  />
                </Grid.Col>
              </Grid>
            </Grid.Col>
            <Grid.Col span={12}>
              <Grid>
                <Grid.Col span={4}>
                  <TableTextInputTitle title={word_translation.phone} />
                  <TextInput
                    placeholder={word_translation.Input}
                    {...form.getInputProps("tel")}
                  />
                </Grid.Col>
                <Grid.Col span={8}>
                  <TableTextInputTitle title={word_translation.email} />
                  <TextInput
                    placeholder={word_translation.Input}
                    {...form.getInputProps("email")}
                  />

                </Grid.Col>
              </Grid>

              </Grid.Col>
            <Grid.Col span={12}>
              <TableTextInputTitle title={word_translation.website} />
              <TextInput
                placeholder={word_translation.website}
                {...form.getInputProps("website")}
              />


            </Grid.Col>
            <Grid.Col span={12} pb={20}>
              <Group position="right">
                <SubmitBtn btn_size="xs" callBack={handleFormSubmit} />
              </Group>
            </Grid.Col>
          </Grid>

        </Box>
      </Paper>
    </>
  );
}
