
import { ActionIcon, Box, Checkbox, ColorInput, ColorPicker, ColorSwatch, Grid, Group, Modal, MultiSelect, NumberInput, ScrollArea, SegmentedControl, Select, Space, Switch, Table, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import WordTranslationComponent from '../../../../../utils/language_pack/words';
import { useEffect, useState } from 'react';
import TableTextInputTitle from '../../../../../ttcomponents/TableTextInputTitle/TableTextInputTitle';
import ResetBtn from '../../../../../ttcomponents/Button/ResetBtn';
import SubmitBtn from '../../../../../ttcomponents/Button/SubmitBtn';
import localstorageUnits from '../../../../../utils/localstorageUnits';
import { DateInput } from '@mantine/dates';
import { IconArrowsSplit, IconArrowsSplit2, IconCalendar, IconCheck, IconCircle, IconCircleCheck, IconCircleX, IconHomeBolt, IconHomeCancel, IconHomeCheck, IconHomeQuestion, IconHomeSearch, IconTextPlus, IconVector } from '@tabler/icons-react';
import { getCustomerListGeneralFunction, getOrderProductsGeneralFunction } from '../../../../../api/assignments_request';
import { SelectRole } from '../../../../../interface/Icommon';
import GlobalCoreSetting from '../../../../../utils/globalCortSetting';
import UniversalButton from '../../../../../ttcomponents/Button/UniversalButton';
import TableTextInputTitleDesc from '../../../../../ttcomponents/TableTextInputTitleDesc/TableTextInputTitleDesc';
import ModalTitleText from '../../../../../ttcomponents/ModalTitleText/ModalTitleText';
import { AssignmentOrderProductData } from '../../../../../interface/Iorder';
import { truncateString } from '../../../../../utils/function';
import ProductList from '../order_product/product_list';
import OrderProductForm from '../order_product/order_product_from';
import { ItemProps } from '../../../../../interface/IProduct';
import { getProductGeneralSelectFunction } from '../../../../../api/inventory_request';

interface ComponentProps {
    is_load: boolean;
}

function AssignmentsOrderNewProduct({  is_load}: ComponentProps) {
  
    // Get the default unchanged configuration
    const globalCoreSetting = GlobalCoreSetting();
    // Define all the customer lists that can be selected
    const [customerList, setCustomerList] = useState<SelectRole[]>([]);
    const [orderProducts, setOrderProduct] = useState<AssignmentOrderProductData[]>([]);
    // Define all the list of product you can choose
    const [productData, setProductData] = useState<ItemProps[] | undefined>([]);
  
    
    const [randNumber, setRandNumber] = useState(1);
    // Get the initial time of the system
    const initSystemTime = localstorageUnits.getSystem().init_use_date;

    // Get translation public configuration information
    const word_translation = WordTranslationComponent();

    useEffect(() => {
        getProduct();
    }, [])

   
    const getProduct =async () => {
        is_load && setProductData(await getProductGeneralSelectFunction(word_translation.unit_price,word_translation.product_not_available));
    }

    const setQuantityFucntion = (index:number,value:any)=>{
        console.log("数量：",value)
    }
    
  const [assignmentModa, setAssignmentsModa] = useState<string[]>([]);
    const setAssignmentsModaExpandFunction=(value:any) =>
    { 
    if(Array.isArray(value)){
        setAssignmentsModa(value)
    }else if(value && typeof  value === 'string' ){
        setAssignmentsModa(value.split(","))
    }
    }
    return (
            <Box className='border-bt-1'  p={10}>
                <Box py={5}> <TableTextInputTitle class_name='subtitle' title={word_translation.ordered_products} /></Box>
                <ScrollArea h={380} >

                <table >
                        <thead  className="scrolleder">
                            <tr className=''>
                                <th  id="table-th-title-new">{word_translation.product_name}</th>
                                <th  id="table-th-title-new">{word_translation.warehouse}</th>
                                <th  id="table-th-title-new">{word_translation.quantity}</th>
                                <th  id="table-th-title-new">{word_translation.price}</th>
                                <th  id="table-th-title-new">&nbsp;</th>
                                <th  id="table-th-title-new">{word_translation.delivery_schedule}</th>
                                <th  id="table-th-title-new">&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {/* <td width={400}><OrderProductForm product_data={productData}  is_loadding={false}/>  </td> */}
                                <td width={130}><NumberInput placeholder={word_translation.Input}  onChange={(event) =>  setQuantityFucntion(1,event)  }  /></td>
                                <td width={130}><NumberInput placeholder={word_translation.Input}  onChange={(event) =>  setQuantityFucntion(1,event)  }  /></td>
                                <td width={130}><NumberInput placeholder={word_translation.Input}  onChange={(event) =>  setQuantityFucntion(1,event)  }  /></td>
                                <td >
                                
                                    <ActionIcon size='md'  variant="filled"  bg='#40C057'>
                                            <IconHomeCheck />
                                    </ActionIcon>
                                </td>
                                <td width={130}>
                                    <DateInput
                                        z-tabIndex={10}
                                        valueFormat="DD/MM/YYYY"
                                        size="xs"
                                        minDate={new Date(initSystemTime)}
                                        required
                                        icon={<IconCalendar size="1.1rem" stroke={1.5} />}
                                        placeholder={word_translation.end_time} 
                                        // onBlur={(event) => checkTime(event.target.value)}
                                    />
                                </td>
                                <td>
                                    <Group spacing={0} position="left">
                                        <ActionIcon onClick={() => {} }>
                                        <IconHomeSearch   color='#FAB005'  stroke={1.5} />
                                        </ActionIcon>
                                        <ActionIcon onClick={() => {}}>
                                            <IconCircleCheck  color="red" stroke={1.5} />
                                        </ActionIcon>
                                        <ActionIcon onClick={() => {}}>
                                            <IconCircleX color="red" stroke={1.5} />
                                        </ActionIcon>
                                    </Group>
                                    </td>
                                                    </tr>
                            <tr>
                                {/* <td width={400}><OrderProductForm product_data={productData}  is_loadding={false}/>  </td> */}
                                <td width={130}><NumberInput placeholder={word_translation.Input}  onChange={(event) =>  setQuantityFucntion(1,event)  }  /></td>
                                <td width={130}><NumberInput placeholder={word_translation.Input}  onChange={(event) =>  setQuantityFucntion(1,event)  }  /></td>
                                <td width={130}><NumberInput  placeholder={word_translation.Input}  onChange={(event) =>  setQuantityFucntion(1,event)  }  /></td>
                                <td >
                                    <ActionIcon size='md'  variant="filled"  bg='red'>
                                             <IconHomeCancel />
                                    </ActionIcon>
                                </td>
                                <td width={130}>
                                    <DateInput
                                        z-tabIndex={10}
                                        valueFormat="DD/MM/YYYY"
                                        size="xs"
                                        minDate={new Date(initSystemTime)}
                                        required
                                        icon={<IconCalendar size="1.1rem" stroke={1.5} />}
                                        placeholder={word_translation.end_time} 
                                        // onBlur={(event) => checkTime(event.target.value)}
                                    />
                                </td>
                                
                                <td>
                                    <Group spacing={0} position="left">
                                        <ActionIcon onClick={() => {} }>
                                        <IconHomeSearch  color='#FAB005' stroke={1.5} />
                                        </ActionIcon>
                                        <ActionIcon onClick={() => {} }>
                                        <IconHomeQuestion color='#40C057' stroke={1.5} />
                                        </ActionIcon>
                                        <ActionIcon onClick={() => {}}>
                                            <IconArrowsSplit2  color="red" stroke={1.5} />
                                        </ActionIcon>
                                   
                                        <ActionIcon onClick={() => {}}>
                                            <IconCircleX color="red" stroke={1.5} />
                                        </ActionIcon>
                                    </Group>
                                    </td>
                            </tr>
                            <tr>
                                {/* <td width={400}><OrderProductForm product_data={productData}  is_loadding={false}/>  </td> */}
                                <td width={130}><NumberInput placeholder={word_translation.Input}  onChange={(event) =>  setQuantityFucntion(1,event)  }  /></td>
                                <td width={130}><NumberInput placeholder={word_translation.Input}  onChange={(event) =>  setQuantityFucntion(1,event)  }  /></td>
                                <td width={130}><NumberInput placeholder={word_translation.Input}  onChange={(event) =>  setQuantityFucntion(1,event)  }  /></td>
                                <td >
                                    <ActionIcon size='md'  variant="filled"  bg='#FAB005'>
                                        <IconHomeBolt />
                                    </ActionIcon>
                                </td>
                                <td className='aaaa' width={130}>
                                    <DateInput
                                        z-tabIndex={10}
                                        valueFormat="DD/MM/YYYY"
                                        size="xs"
                                        minDate={new Date(initSystemTime)}
                                        required
                                        icon={<IconCalendar size="1.1rem" stroke={1.5} />}
                                        placeholder={word_translation.end_time} 
                                        // onBlur={(event) => checkTime(event.target.value)}
                                    />
                                </td>

                                <td>
                                    <Group spacing={0} position="left">
                                        <ActionIcon onClick={() => {} }>
                                        <IconTextPlus color='#40C057' stroke={1.5} />
                                        </ActionIcon>
                                    </Group>
                                    </td>
                            </tr>
                            <tr>
                              <td ></td>
                              <td ></td>
                              <td ></td>
                              <td ></td>
                              <td ></td>
                              <td ></td>
                              <td ><UniversalButton btn_size='xs' btn_text={word_translation.save_current_statue} callBack={function (): void {
                            throw new Error('Function not implemented.')  } } /></td>
                                
                            </tr>
                        </tbody>
                        </table>

                      
                </ScrollArea>
            </Box>
    );
}
export default AssignmentsOrderNewProduct;
