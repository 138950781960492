import { Box, Flex, Text } from "@mantine/core";
import { FormattedMessage } from "react-intl";
interface SearchBoxProps {
  title: any;
  px?:number;
  lang_id?: any;
}
export default function PageTitle({ px = 0,title, lang_id = '' }: SearchBoxProps) {
  return (
    <Box px={px}>
      <Flex className="title_header" justify="left" align="center">
        <Text className="Headlinestitle_text" color="white">
          {lang_id ? <FormattedMessage id={lang_id} defaultMessage={title}/> : title  }
        </Text>
      </Flex>
    </Box>
  );
}
