import {
  Avatar,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Group,
  Paper,
  Table,
  Text,
} from "@mantine/core";
import { IconAlarmFilled, IconSitemap } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { regItemReport } from "../../../api";
import { Project } from "../../../interface/Ireports";
import { detailProps } from "../../../interface/IfilterStatistic";
import { useIntl } from "react-intl";
interface IdetailProps {
  detailPropsData: detailProps;
}
export default function FilterItemReports({ detailPropsData }: IdetailProps) {
  // Use the useIntl hook to access the intl object
  const intl = useIntl();

  const urlParams = new URLSearchParams(window.location.search);
  const item_id = detailPropsData.id;
  const [projectinfo, setProjectReports] = useState<Project>();
  const ajaxList = async () => {
    const response = await regItemReport(
      {
        item_id: item_id,
        type: detailPropsData.type,
        year: detailPropsData.year || "",
        start_week: detailPropsData.start_week || "",
        end_week: detailPropsData.end_week || "",
      },
      "GET"
    );
    const result = response.data;
    setProjectReports(result.data);
  };
  useEffect(() => {
    ajaxList();
  }, []);
  console.log(projectinfo);
  return (
    <Grid gutter={5} columns={60}>
      <Grid.Col span={60} className="text-center">
        {projectinfo && (
          <Card radius="md">
            <Box></Box>
            <Table
              mb={10}
              striped
              highlightOnHover
              withBorder
              withColumnBorders
            >
              <thead>
                <tr>
                  <th>Project Name</th>
                  <th>Actual Value (h)</th>
                  <th>Target (h)</th>
                  <th>Actual Value (%)</th>
                  <th>Leader Time</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{projectinfo.name}</td>
                  <td>{projectinfo.time_consumption}</td>
                  <td>{projectinfo.time_estimate}</td>
                  <td>{projectinfo.percent} % </td>{" "}
                  <td>
                    {projectinfo.project_timetracker
                      ? projectinfo.project_timetracker.map(
                          (projectitem, projectindex) => {
                            return (
                              <>
                                <tr>
                                  <td>
                                    {" "}
                                    <Avatar
                                      src={projectitem.avatar}
                                      radius={40}
                                      size={26}
                                    />
                                  </td>{" "}
                                  <td>{projectitem.username} </td>
                                  <td>{projectitem.total}</td>
                                </tr>
                              </>
                            );
                          }
                        )
                      : null}
                  </td>
                </tr>
              </tbody>
            </Table>
            <Box mt={60}>
              <Card>
                {projectinfo.order_list
                  ? projectinfo.order_list.map((item, index) => {
                      return (
                        <>
                          <Divider
                            mt={30}
                            variant="dashed"
                            size="sm"
                            labelPosition="center"
                          />
                          <Table
                            my={10}
                            striped
                            highlightOnHover
                            withBorder
                            withColumnBorders
                          >
                            <thead>
                              <tr>
                                <th>Order Name</th>
                                <th>Actual Time</th>
                                <th>Target (h)</th>
                                <th>Actual Value (%)</th>
                                <th>Leader Time</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{item.name}</td>
                                <td>{item.time_consumption}</td>
                                <td>{item.time_estimate}</td>
                                <td>{item.percent}%</td>
                                <td>
                                  {item.order_timetracker
                                    ? item.order_timetracker.map(
                                        (orderitem, orderindex) => {
                                          return (
                                            <>
                                              <tr>
                                                <td>
                                                  {" "}
                                                  <Avatar
                                                    src={orderitem.avatar}
                                                    radius={40}
                                                    size={26}
                                                  />
                                                </td>{" "}
                                                <td>{orderitem.username} </td>
                                                <td>{orderitem.total}</td>
                                              </tr>
                                            </>
                                          );
                                        }
                                      )
                                    : null}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                          {item.task_timetracker &&
                          item.task_timetracker.length > 0 ? (
                            <Table
                              striped
                              highlightOnHover
                              withBorder
                              withColumnBorders
                            >
                              <thead>
                                <tr>
                                  <th>TasK Name</th>
                                  <th>Actual time</th>
                                  <th>Target (h)</th>
                                  <th>Actual value (%)</th>
                                  <th>Employees Time</th>
                                </tr>
                              </thead>
                              <tbody>
                                {item.task_timetracker
                                  ? item.task_timetracker.map(
                                      (taskItem, index) => {
                                        return (
                                          <>
                                            <tr>
                                              <td>{taskItem.name}</td>
                                              <td>
                                                {taskItem.time_consumption}
                                              </td>
                                              <td>{taskItem.time_estimate}</td>
                                              <td>{taskItem.percent}%</td>
                                              <td>
                                                {taskItem.task_timetracker
                                                  ? taskItem.task_timetracker.map(
                                                      (useritem, userindex) => {
                                                        return (
                                                          <>
                                                            <tr>
                                                              <td>
                                                                {" "}
                                                                <Avatar
                                                                  src={
                                                                    useritem.avatar
                                                                  }
                                                                  radius={40}
                                                                  size={26}
                                                                />
                                                              </td>{" "}
                                                              <td>
                                                                {
                                                                  useritem.username
                                                                }{" "}
                                                              </td>
                                                              <td>
                                                                {useritem.total}
                                                              </td>
                                                            </tr>
                                                          </>
                                                        );
                                                      }
                                                    )
                                                  : null}
                                              </td>
                                            </tr>
                                          </>
                                        );
                                      }
                                    )
                                  : null}
                              </tbody>
                            </Table>
                          ) : null}
                        </>
                      );
                    })
                  : null}
              </Card>
            </Box>
          </Card>
        )}
      </Grid.Col>
      {/* <Grid.Col span={28}>
              

            </Grid.Col>
            <Grid.Col span={16}>

            </Grid.Col> */}
    </Grid>
  );
}
