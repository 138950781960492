import { ActionIcon, Tooltip } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import WordTranslationComponent from "../../utils/language_pack/words";

// Define the props interface for the ChildComponent
interface ChildComponentProps {
    size?: any;
    stroke?: any;
    disabled?:boolean
    color?:any;
  callBack: () => void; // Callback function to handle button click events
}

// ChildComponent representing the Create Button
const EditIcon: React.FC<ChildComponentProps> = ({stroke,disabled=false,size,color, callBack }) => {
  
  // Get translation public configuration information
  const word_translation = WordTranslationComponent();
  
  return (
    <ActionIcon disabled={disabled} onClick={() => callBack()}>
            <Tooltip  label={word_translation.edit_tips}>
                   <IconEdit size={size ? size :'1rem'}  stroke={stroke?stroke:'1.5'} color={color ?  color:'#7D7D7D'} />
              </Tooltip>
    </ActionIcon>
  

  );
};

export default EditIcon;
