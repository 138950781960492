import {
  Group,
  TextInput,
  LoadingOverlay,
  Box,
  Select,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { PermissionIdentifierInterface} from "../../../interface/Iemloyee";
import { hasLength, matches, useForm } from "@mantine/form";
import WordTranslationComponent from "../../../utils/language_pack/words";
import TableTextInputTitle from "../../TableTextInputTitle/TableTextInputTitle";
import UniversalButton from "../../Button/UniversalButton";
import { SelectRole } from "../../../interface/Icommon";
import { HintErrorMantineInfo, HintInfo, HintInfoCatchError } from "../../../utils/function";
import memoryUtils from "../../../utils/memoryUtils";
import { numberRegex } from "../../../utils/validataUnits";

// If is_load is set to false, the initialization request within the component will not be initiated in advance.
// request  will be controlled by the change in this field when rendering the component. Otherwise, it behaves oppositely.
interface ComponentProps {
  // openFormStatus: boolean;
  closeFunction: () => void;
  costCenterData: SelectRole[];
  permissionIdentifier : SelectRole[];

  submitFunction: (data:any,method?:string) => any;
  currentItemInfo?: PermissionIdentifierInterface ;
  successCallback: (value: boolean) => void
}

export default function   PermissionIdentifierForm({closeFunction,permissionIdentifier, submitFunction, costCenterData, successCallback, currentItemInfo }: ComponentProps) {

  // Get translation public configuration information
  const word_translation = WordTranslationComponent();
  // loading Statue
  const [loading, setLoading] = useState(false);
  const [permissionIdentifierList, setPermissionIdentifierList] = useState<SelectRole[]>([]);
  
  const user_type = useState<string | null>(memoryUtils.user.user_type);
  const form = useForm({
    validateInputOnBlur: true,
    initialValues: {
      name: currentItemInfo? currentItemInfo.name : '',
      id: currentItemInfo? currentItemInfo.id_permission_identifier : '',
      costCenter_id: currentItemInfo? currentItemInfo.costCenter_id : '',
      identifier:currentItemInfo? currentItemInfo.identifier : '',
    },
    validate: {
      name: hasLength({ min: 1, max: 255 }, `${word_translation.validata_value_need_1_150_length}`),
      identifier: matches(numberRegex, `${word_translation.validata_identifier_1_10_digits}`),
    },
  });

  const handleFormSubmit = async () => {
    // Validate the form
    const valid = await form.validate();

    // If there are validation errors, display error information
    if (valid.hasErrors) {
      HintErrorMantineInfo(valid);
      return false;
    }
    const methodType = form.values.id ? "PUT" : "POST";

    try {
      setLoading(true);
      const response = await submitFunction(form.values, methodType);
      const result = response.data;
      // Display information based on the response
      HintInfo(response.data);
      if (result.code === 200) {
        // Reset the form and invoke the callback
        form.reset();
        successCallback(true);
      }
    } catch (error) {
      HintInfoCatchError();
    } finally {
      setLoading(false);
    }

  };

  useEffect(()=>{
    setPermissionIdentifierList(permissionIdentifier)
  },[permissionIdentifier])

  return (
    <>
        <LoadingOverlay visible={loading} overlayBlur={2} />
        <Box px={10}>
          <TableTextInputTitle title={word_translation.name} />
          <TextInput
            required
            size="xs"
            placeholder={word_translation.Input}
            {...form.getInputProps("name")}
          />
     
      <TableTextInputTitle title={word_translation.identifier} />
          <TextInput
           placeholder={word_translation.Input}
            {...form.getInputProps("identifier")}
          />
      {/* <TableTextInputTitle title={word_translation.identifier} /> */}
      {/* <Select
            labelProps={{ className: "input_title" }}
            onChange={(value) => form.setFieldValue("identifier", value as string)}
            placeholder={word_translation.pick_one}
            searchable
            size="xs"
            creatable={user_type.toString() == '1' ? false: true }
            getCreateLabel={(query) => `+ Create ${query}`}
            onCreate={(query) => {
              const item = { value: query, label: query };
              setPermissionIdentifierList((current) => [...current, item]);
              return item;
            }}
            maxDropdownHeight={200}
            defaultValue={form.values.identifier}
            data={permissionIdentifierList}
          />  */}

          {/* <TextInput
            disabled={form.values.id ? true:false}
            required
            placeholder={word_translation.Input_max_5}
            {...form.getInputProps("identifier")}
          /> */}
          <Box my={10}>
          {/* <TableTextInputTitle title={word_translation.description} /> */}
          {/* <Text size={14}>{word_translation.permission_identifier_introduction}</Text> */}
          </Box>
          <Group position="apart" mt={5}>
            <UniversalButton btn_bg_color='#868E96' btn_text={word_translation.cancel} btn_size='xs' callBack={()=>closeFunction()} />
            <UniversalButton btn_text={form.values.id ?  word_translation.edit : word_translation.add} btn_size='xs' callBack={() => handleFormSubmit()} />
          </Group>
        </Box>
    </>
  );
}
