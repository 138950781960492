import {
  TextInput,
  Group,
  Grid,
  Code,
  Paper,
  Textarea,
  Title,
  Button,
  Box,
  Table,
  ActionIcon,
  Divider,
} from "@mantine/core";
import { FormEvent, useRef, useState } from "react";
import { hasLength, isEmail, isNotEmpty, useForm } from "@mantine/form";
import SaveBottom from "../../../ttcomponents/Button/SaveBtn";
import CancelButton from "../../../ttcomponents/Button/CancelBtn";
import { Contact, Supplier } from "../../../interface/Iinventory";
import { notifications } from "@mantine/notifications";
import {
  Icon123,
  IconCheck,
  IconTextPlus,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import { Left } from "../../time_master/svg/l";
import {
  isValidEmail,
  isNotEmpty as isNotEmptyCustom,
  isValidUsername,
} from "../../../utils/validataUnits";
import { regGetProduct, regProduct, regSupplier } from "../../../api";
import { Product } from "../../../interface/IProduct";
import SupplierSelect from "../../../ttcomponents/Supplier/SupplierSelect";
import { ICategory } from "../../../interface/IProductCategory";
import AppCategory from "./category/category";
import { useIntl } from "react-intl";

interface ISupplierFormProps {
  closeFucntion: (type: number) => void;
  productCategory: ICategory[];
  productItem: Product | undefined;
}

// interface SupplierFormValues {
//   supplier_id: number | undefined;
//   supplier_name: string | undefined;
//   tax_identification_number: string | undefined;
//   address: string | undefined;
//   city: string | undefined;
//   postal_code: string | undefined;
//   tel: string | undefined;
//   email: string | undefined;
//   contact: Contact[]; // 定义 contact 为 Contact 数组
// }

export default function ProductForm({
  closeFucntion,
  productCategory,
  productItem,
}: ISupplierFormProps) {
  // Use the useIntl hook to access the intl object
  const intl = useIntl();

  const formRef = useRef<HTMLFormElement>(null);

  const [contactName, setContactName] = useState("");
  const [contactIntroduction, setContactIntroduction] = useState("");
  const [categoryName, setCategoryName] = useState(productItem?.category_name);
  const [contactPhone, setContactPhone] = useState("");

  const form = useForm<Product>({
    initialValues: {
      product_id: productItem?.product_id,
      supplier_id: productItem?.supplier_id,
      category_id: productItem?.category_id,
      unit_price: productItem?.unit_price, // 初始值为 productItem 中的 unit_price
      product_name: productItem?.product_name,
      unit: productItem?.unit,
      description: productItem?.description,
      validity_period: productItem?.validity_period,
    },
    validate: {
      product_name: isNotEmpty("Product name is required"), // 验证 product_name 不为空
      unit: isNotEmpty("Unit is required"), // 验证 unit 不为空
      supplier_id: isNotEmpty("Supplier ID is required"), // 验证 supplier_id 不为空
      category_id: isNotEmpty("Category ID is required"), // 验证 category_id 不为空
      unit_price: (value) => {
        if (value) {
          if (/^\d+(\.\d+)?$/.test(value)) {
            return null; // 验证通过，返回 undefined
          }
          return "Need to be floating -point numbers";
        }
        return null;
      },
    },
  });

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Stop the formal submission of the form default
    // Verification form
    const valid = form.validate();
    if (valid.hasErrors) {
      notifications.show({
        color: "yellow.7",
        icon: <IconX />,
        message: Object.values(valid.errors)[0],
      });
      return;
    }
    let submit_type = "POST";
    if (form.values.product_id) {
      submit_type = "PUT";
    }

    const response = await regProduct(form.values, submit_type);
    if (response.data.code == 200) {
      closeFucntion(2);
      notifications.show({
        color: "green",
        icon: <IconCheck />,
        message: response.data.msg,
      });
    } else {
      notifications.show({
        color: "yellow.7",
        icon: <IconX />,
        message: response.data.data.database_returns_results,
      });
    }
  };

  const ReceiveSupplierBack = (value: any) => {
    if (value.type === "supplier") {
      if (value.value === "") {
        form.setFieldValue("supplier_id", "");
      } else {
        form.setFieldValue("supplier_id", value.value);
      }
    }
  };

  function AppCategoryFunction(value: any): void {
    setCategoryName(value.category_name);
    form.setFieldValue("category_id", value.category_id);
  }

  return (
    <Paper p={10}>
      <form onSubmit={handleFormSubmit} ref={formRef}>
        <Grid>
          <Grid.Col span={7}>
            <TextInput
              {...form.getInputProps("product_name")}
              placeholder={intl.formatMessage({
                id: "Product Name",
                defaultMessage: "Product Name",
              })}
              label={intl.formatMessage({
                id: "product_name",
                defaultMessage: "Product Name",
              })}
              style={{ width: "100%" }}
            />

            <TextInput
              // {...form.getInputProps("category_id")}
              placeholder={intl.formatMessage({
                id: "Category",
                defaultMessage: "Category",
              })}
              disabled
              value={categoryName}
              label={intl.formatMessage({
                id: "Category",
                defaultMessage: "Category",
              })}
            />
            <AppCategory
              callback={AppCategoryFunction}
              categories={productCategory}
            />
          </Grid.Col>
          <Grid.Col span={5}>
            <SupplierSelect
              labelStr={intl.formatMessage({
                id: "Supplier",
                defaultMessage: "Supplier",
              })}
              width={"auto"}
              currentId={form.values.supplier_id as string}
              callBack={ReceiveSupplierBack}
            />
            <TextInput
              mt={40}
              {...form.getInputProps("unit_price")}
              label={intl.formatMessage({
                id: "Unit Price",
                defaultMessage: "Unit Price",
              })}
            />
            <TextInput {...form.getInputProps("unit")} label={intl.formatMessage({
                id: "unit",
                defaultMessage: "Unit",
              })} />
            <Textarea
              label={intl.formatMessage({
                id: "Product Introduction",
                defaultMessage: "Product Introduction",
              })}
              {...form.getInputProps("description")}
              radius="md"
            />
          </Grid.Col>
        </Grid>

        <Group position="right">
          <CancelButton callBack={() => closeFucntion(1)} />
          <SaveBottom formRef={formRef} />
        </Group>

        {/** can see form values */}
        {/* <Code block mt={5}>
          {JSON.stringify(form.values, null, 2)}
        </Code> */}
      </form>
    </Paper>
  );
}
