import { ActionIcon, Text, Image, Box, Grid, Paper, Tooltip, Progress, Group, Badge, Modal } from "@mantine/core";

import WordTranslationComponent from "../../../../utils/language_pack/words";
import { IconAdjustmentsHorizontal, IconArrowBigDownLine, IconArrowsMaximize, IconChecklist, IconClock, IconEdit, IconEye, IconPencil, IconSubtask, IconTrash } from "@tabler/icons-react";
import '../css/index.css'
import { useEffect, useRef, useState } from "react";
import { IconGripVertical } from "@tabler/icons-react";
import ColorSelect from "../color_modal";
import { useClickOutside, useDisclosure } from "@mantine/hooks";
import AssignmentAddOvertime from "../assignment_add_overtime_modal";
import { AssignmentsInterface, ProjectDataInterface } from "../../../../interface/Iprojects";
import { HintInfo, formatTimeHHSS, getAssignmentStatusColorByValue, getAssignmentStatusLabelByValue, truncateString } from "../../../../utils/function";
import AvatarGroupComponnet from "../../../../ttcomponents/AvatarGroup/AvatarGroup";
import ModalTitleText from "../../../../ttcomponents/ModalTitleText/ModalTitleText";
import TaskFrom from "./create_task";
import { ChildMethods } from "../project/project_create";
import { useDispatch } from "react-redux";
import { setRefreshStatus } from "../../../../state_management/features/assignments_refresh_status/assignmentsRefreshStatusSlice";
import { getUserGeneralFunction } from "../../../../api/common_request";
import { getCustomerListGeneralFunction, getOrderDetailByIdFunction, getProjectDetailByIdFunction, getTaskDetailByIdFunction } from "../../../../api/assignments_request";
import { SelectRole } from "../../../../interface/Icommon";
import { UserAllList } from "../../../../interface/Iuser";
import { OrderDataInterface } from "../../../../interface/Iorder";
import EditIcon from "../../../../ttcomponents/EditIcon/EditIcon";
import TableTd from "../../../../ttcomponents/TableTrTd/TableTd";
import { modals } from "@mantine/modals";
import GlobalCoreSetting from "../../../../utils/globalCortSetting";
import { requestTask } from "../../../../api/index_v2";
import DeleteIconPerm from "../../../../ttcomponents/DeleteIcon/DeleteIconPerm";
import ViewIcon from "../../../../ttcomponents/ViewIcon/ViewIcon";
import { getSystemAssignmentsFunction } from "../../../../api/core_request";
import { Purchase } from "../../../../public/svg/ProductOrder";
import tticon from "../../../../public/images/tt.png";
import CheckPermissionTools from "../../../../utils/permission";

// 层级 1: 说明是单一任务（singletask），处于第一层级，与全模式下的项目 (project) 保持一致。
// 层级 2: 说明有订单（order），但没有项目，处于第二层级，与全模式下的订单 (order) 一致。
// 层级 3: 说明既有订单又有项目，处于第三层级。

interface ComponentProps {
    leve: number;
    row:AssignmentsInterface;
    index?:number;
}

export default function AssignmentTaskRow({ row,index,leve }: ComponentProps) {
    const dispatch = useDispatch();
    
  // Get the global configuration
  const globalCoreSetting = GlobalCoreSetting();
  const Perm = CheckPermissionTools();
  
    // Get translation public configuration information
    const word_translation = WordTranslationComponent();

    const [openSelectColorStatus, { open: openSelectColorModalFunction, close: closeSelectColorModalFunction }] = useDisclosure(false);
    const [openAddOvertimeStatus, { open: openAddOvertimeModal, close: closeAddOvertimeModalFunction }] = useDisclosure(false);
    
    //  Define current  project info  in the page
    const [currentProjectInfo,setCurrentProjectInfo] = useState<ProjectDataInterface>();
  //  Define current  project info  in the page
  const [currentTaskInfo,setCurrentTaskInfo] = useState<AssignmentsInterface>();
  // Define all the customer lists that can be selected
  const [customerList, setCustomerList] = useState<SelectRole[]>([]);

  // Define all the project lists that can be selected
  const [projectList, setProjectList] = useState<SelectRole[]>([]);

  // Define all the list of employees you can choose
  const [userlist, setUserList] = useState<SelectRole[]>([]);
  // Define the user list (including the user's detailed information)
  const [, setResultUser] = useState<UserAllList[]>([]);

  const [allUserList, setAllUserList] = useState<UserAllList[]>([]);
 //  Define current  order info  in the page
 const [currentTaskId,setCurrentTaskId] = useState<string>('');
    const [openTaskFormStatus, { open: openCurrentPageTaskForm, close: closeTaskPageFormFunction }] = useDisclosure(false);

  const childRef = useRef<ChildMethods>(null);
    //展开单个的记录
    const expandItemFunction = (value: any) => {
        console.log('展开单个的记录', value)
    }
    
    /**
     * useState Hook用于管理组件的状态
     * @param {boolean} isHovered - 当前鼠标是否悬停在元素上
     * @returns {Array} - 包含isHovered和setIsHovered两个值的数组
     */
    const [isHovered, setIsHovered] = useState(false);


      //Order update list key
  const [showBtnStatus, setShowBtnStatus] = useState(false);

  
  const getCurrentAssigment= async ()=>{
    let assignmentModals = await getSystemAssignmentsFunction();
    console.log(assignmentModals);
    if (assignmentModals.split(',').some((item: string) => ['1', '2', '4','6'].includes(item))) {
        setShowBtnStatus(true)
    }else{
        setShowBtnStatus(false)
    }
 
}
    /**
     * 当鼠标移入元素时，设置isHovered为true
     */
    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    /**
     * 当鼠标移出元素时，设置isHovered为false
     */

    const handleMouseLeave = () => {
        setIsHovered(false);
        setOpened(false)  };
    const handleTrMouseLeave = () => {
          setIsHovered(false);
    };
        
    // 自定义的颜色和文本选择
  const [backgroundColor, setBackgroundColor] = useState(row.bg_color);
  const [textColor, setTextColor] = useState(row.text_color);
  // 控制行内 文字颜色
  const changeBgColorCallback = (color:string) => {
    color && setTextColor(color);
  }
  const resetColorCallback = () =>{ 
    setBackgroundColor(row.bg_color);
    setTextColor(row.text_color);
  }
    // 控制行内 背景颜色
    const changeTextColorCallback = (color:string) => {
        color && setBackgroundColor(color);
      }


 /**
 * Get a list of users
 */
 const getUser = async() =>{
    // Call the Get User Universal Selection function and set the user list
    let userList = await getUserGeneralFunction('all_info')
    setUserList(userList.map((item: { id_user: string; username: any }) => ({
        value: item.id_user as string,
        label: item.username,
    })));
    setAllUserList(userList);
}

const getCustomerList = async () => {
    setCustomerList(await getCustomerListGeneralFunction(""));
};


// get a  task detail by  project_id
const getTaskDeatil = async (task_id:number) =>{
    setCurrentTaskInfo(await getTaskDetailByIdFunction(task_id))
   
}
   
// get a  product detail by  project_id
const getProjectDeatil = async (project_id:string) =>{
   setCurrentProjectInfo(await getProjectDetailByIdFunction(project_id))
}
 
   

const editHandleFunction = (row:AssignmentsInterface)=>{
    openCurrentPageTaskForm();
    setCurrentTaskId(row.task_id as string)
    getUser();
    getCustomerList();
}
     // Order callback Function <FUNCTION>
const orderFormCallbackFunction =(project_id:any)=>{
    if(project_id !== '-1'){
        closeTaskPageFormFunction();
        dispatch(setRefreshStatus(true));
    }
} 
    
// 添加加班时间
const openAddOvertimeModalFunction = async (row:AssignmentsInterface)=>{
    console.log(row)
    await getTaskDeatil(row.task_id as number)

    openAddOvertimeModal()
}
// Fetches the delete  task
const handleDel = async (row: AssignmentsInterface) => {
    modals.openConfirmModal({
        title: word_translation.delete_title,
        centered: true,
        children: (
            <Text size="sm" mt={12}>
                <p>  {word_translation.task_category} : {row.task_category_identifier}</p>
            </Text>
        ),
        labels: globalCoreSetting.DeleteOpenConfirmModaLabels,
        confirmProps: globalCoreSetting.DeleteOpenConfirmModaConfirmProps,
        onConfirm: async () => {
            try {
                const deleteTaskResult = await requestTask({ id: row.task_id }, "DELETE");
                HintInfo(deleteTaskResult.data);
                if (deleteTaskResult.data.code == 200) {
                    dispatch(setRefreshStatus(true));
                }
            } catch (error) {
                // Handle any errors that occur during the API call
                console.error('Error fetching project list:', error);
                // Optionally, you can throw the error or handle it as needed
                throw error;
            }

        },
    });
};

useEffect(()=>{
    if(currentTaskInfo?.project_id){
        getProjectDeatil(currentTaskInfo.project_id as string)
    }
},[currentTaskInfo])
  const [opened, setOpened] = useState(false);
  /**
   * 定义行的class
   */
   const  tdClass = ( leve == 1)? '' : 'border-b-1'
//    判断能不能删除
    return (
        <>
        {(Perm.admin||row.global_permissions?.read||  row.permission?.read) && <>
         <tr className={`text-center assignment_laypuout tr-container   ${leve == 1 ? "assignment_project_item" : 'assignment_order_item'}`}
                //  <tr className="text-center assignment_laypuout tr-container assignment_order_item"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleTrMouseLeave}
            >
               

                  {leve == 1 ? <>
                    <td  className={tdClass} style={{border:0,borderTopLeftRadius:'10px',borderBottomLeftRadius:'10px'}}  >
                            <Box className="assignment_text_title text-left">
                                <Group position="left" >
                                   
                                    <Box className="hoveer">
                                        {isHovered &&
                                        <ActionIcon onClick={() => setOpened(true)}>
                                            <Tooltip label={word_translation.edit_tips}>
                                                <IconGripVertical size="1.2rem" color="#7D7D7D" stroke={1.5} />
                                            </Tooltip>
                                        </ActionIcon>} 
                                    </Box>
                               
                                    <Box ml={5} className="text-left">
                                      {truncateString(word_translation.cost_center_name,20)}
                                    </Box>
                                  
                                </Group>
                                    
                                </Box >
                                    <Box  ml={5} w={200} style={{color:''}} className="text-left overhide assignment_text_content">
                                    <Tooltip label={row.costCenter_name}>
                                        <Text className="hide-excess-text" color={textColor}>{truncateString(row.costCenter_name, 30)}</Text>
                                    </Tooltip>
                                </Box>
                        </td>
                        <TableTd  colspan={leve == 1 ? 2:1}  class_name={tdClass} font_color={textColor}  value={row.customer_name} title={word_translation.customer}  /> 
                    </> : leve == 2 ?
                        <>
                         <td className={tdClass}>
                            <Group ml={40}>
                              <Box><Image mt={10} width={14} height={14} src={tticon}  />
                            </Box>                     
                            
                            <Box >
                                 <Box className="assignment_text_title text-center">{word_translation.cost_center_name}</Box>
                                <Box className="assignment_text_content text-center hide-excess-text"> {row.costCenter_name}</Box>
                            </Box>
                            </Group>
                        </td> 
                    </>
                : <>
                <td  className={tdClass}>
                   <Box ml={120}>
                       <Box className="assignment_text_title  text-left">{word_translation.task_name}</Box>
                       <Box className="assignment_text_content text-left hide-excess-text"> {row.task_name}</Box>
                   </Box>
               </td> 
               </>}
              
                <TableTd  class_name={tdClass} colspan={leve == 1 ? 1:2} font_color={textColor}  value={row.start_date} title={word_translation.start_time}  /> 
                <TableTd  class_name={tdClass} colspan={leve == 1 ? 1:2} font_color={textColor}  value={row.end_date} title={word_translation.end_time}  /> 
               <TableTd  class_name={tdClass} font_color={textColor}  value={ <AvatarGroupComponnet UserInfo={row.user_info}/>}  value_class="img-center" title={word_translation.task_team_leader}  />     
               <TableTd class_name={tdClass}   value={   <Badge  color= {getAssignmentStatusColorByValue(row.status_id)}> {getAssignmentStatusLabelByValue(row.status_id)}</Badge>}  title={word_translation.status}  />
               <TableTd  class_name={tdClass} font_color={textColor}  value={formatTimeHHSS(row.target_time)} title={word_translation.target_time}  /> 
               <TableTd  class_name={tdClass} font_color={textColor}  value={formatTimeHHSS(row.actual_time)} title={word_translation.actual_time}  /> 
               <TableTd  class_name={tdClass} font_color={textColor}  value={formatTimeHHSS(row.over_time)} title={word_translation.overtime}  /> 
     
                <td className={tdClass}  >
                    <Box mt={8} className="assignment_text_title">{word_translation.completion}</Box>
                    <Box>      
                    <Text mb={2} >  <span className="timevisual_blue"> {row.completion}% </span></Text>
                    <Progress
                    mt={3}
                        value={row.completion}
                        size="md"
                        color="blue"
                    /> </Box>
                </td>
             
                <TableTd  class_name={tdClass} font_color={textColor}  value={row.cost} title={word_translation.cost}  /> 
               <TableTd  class_name={tdClass} font_color={textColor}  value={row.budget} title={word_translation.budget}  /> 
              
              
              
           
     
           
        <TableTd class_name={tdClass}   value="&nbsp;" title="&nbsp;"  />     <TableTd class_name={tdClass}   value="&nbsp;" title="&nbsp;"  />  
               {/* <TableTd  class_name={tdClass} colspan={2} font_color={textColor}  value={row.costCenter_name?row.costCenter_name:'-' } title={word_translation.cost_center_name}  /> */}
                {/* <td className={tdClass}  style={{borderTopRightRadius:10,borderBottomRightRadius:10}}> */}
                <td className={tdClass}>
                    <Box className="assignment_text_title">{word_translation.option}</Box>
                    <Group spacing={0} position="center">
                {showBtnStatus ? <>
                        <ActionIcon  variant="transparent" disabled> </ActionIcon>
                        <ActionIcon onClick={() => openAddOvertimeModalFunction(row)}>
                            <Tooltip label={word_translation.add_overtime}>
                                <IconClock size="1.2rem" color="#7D7D7D" stroke={1.5} />
                            </Tooltip>
                        </ActionIcon>
                        <EditIcon callBack={()=>editHandleFunction(row)} />
                        <ViewIcon callBack={()=>editHandleFunction(row)} />
</>:<></>}

                        <DeleteIconPerm  enable={(Perm.admin||row.global_permissions?.delete||  row.permission?.delete)} callBack={()=>handleDel(row)}/>
                    </Group>
                </td>
                {isHovered && (<>


            
                    </>
                )}
           </tr>

            <Modal
                opened={openTaskFormStatus}
                onClose={closeTaskPageFormFunction}
                closeOnClickOutside={false}
                title={<ModalTitleText title={`${word_translation.edit} - `} />}
                size='90%'
                radius={10}
            >
                {/* Form Madal */}
                <TaskFrom
                    callBackFunction={orderFormCallbackFunction}
                    ref={childRef}
                    edit_task_id={currentTaskId}
                    customerList={customerList}
                    c_allUserList={allUserList}
                    c_userList={userlist} />

            </Modal>

            <AssignmentAddOvertime
                is_load = {false}
                currentTaskInfo={currentTaskInfo}
                currentProjectInfo={currentProjectInfo}
                objectsUsed={word_translation.task} 
                successCallback={() => { console.log('sucess') }} 
                closeFunction={closeAddOvertimeModalFunction} 
                openFormStatus={openAddOvertimeStatus} />
          {opened && (
                        <Paper shadow="sm"   className='hovered-button-cell-color'
                             onMouseLeave={handleMouseLeave}>
                                 <ColorSelect 
                                    objectsUsed={word_translation.task} 
                                    changeBgCallback={changeBgColorCallback} 
                                    changeTextCallback={changeTextColorCallback} 
                                    successCallback={()=>{console.log('sucess')}} 
                                    closeFunction={closeSelectColorModalFunction} 
                                    resetCallback={resetColorCallback}
                                    openFormStatus={opened} />
                        </Paper>)}
                        </> }  </>
    )
}

