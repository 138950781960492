import {
  Paper,
  Box,
  Text,
  Grid,
  LoadingOverlay,
  Modal,
  Select,
  Group,
  ActionIcon,
  Badge,
  Flex,
} from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { requestDataListBoarding, mockapi1, regSupplier, requestSetStatus, updateUserCostCenter, requestDeleteBoarding } from "../../../api";
import { HintInfo, getOnboardingStatusLabel } from "../../../utils/function";
import { IPagination } from "../../../interface/Ipagintion";
import PaginationA from "../../../ttcomponents/Pagination/PaginationA";
import { NewBoardingListData, NewBoardingUserData } from "../../../interface/Iuser";
import WordTranslationComponent from "../../../utils/language_pack/words";
import TrBoarding from "./tr";
import ChangeStatus from "./change_status";

import { IconArrowRight, IconX, } from "@tabler/icons-react";
import SelectCostCenter from "./select_cost_center";
import OnBoarding from "../on_boarding/on_boarding";
import GlobalCoreSetting from "../../../utils/globalCortSetting";
interface ComponentInterface {
  boarding_status_id: any
}
/**
* Extras 
* @returns 
*/
export default function TableBoarding({ boarding_status_id }: ComponentInterface) {
  // Get translation public configuration information
  const word_translation = WordTranslationComponent();
  

  // Edit modal status
  const [openedEdit, { open: openEdit, close: closeEdit }] = useDisclosure(false);
  const [openedCostCenterStatus, { open: openCostCenter, close: closeCostCenter }] = useDisclosure(false);

  const [loading, setLoading] = useState(false);
  // Form type  const [value, setValue] = useState<string | null>(null);
  const [formType, setFormType] = useState("create");
  const [boarding, setBoardingValue] = useState<string | null>(null);
  const [boardingStatus, setBoardingStatusValue] = useState<string | null>(null);
  const [id, setId] = useState(0);

  const globalCoreSetting = GlobalCoreSetting();
  // define the supplier list
  const [boardingList, setBoardingList] = useState<NewBoardingUserData>({
    total_count: 0,
    data: [], // Use the data property from the first item in the mock data array
  });

  const [currentRowInfo, setCurrentRowInfo] = useState<NewBoardingListData>()

  const [pagination, setPagination] = useState<IPagination>({
    search: "",
    page: 1,
    pagesize: 10,
    boarding: boarding,
    boarding_status_id: boarding_status_id == 4 ? boarding_status_id:'',
    sortBy: "",
    sortOrder: "asc",
    query: {},
  });


  // Receive the callback return data, search for content
  const ReceiveSearchBack = (value: string | number) => {
    setPagination((prevpagination) => ({
      ...prevpagination,
      search: value,
      query: {},
    }));
  };

  // Pagination callback triggers update Pagination callback triggers update
  const ReceivePaginationABack = (page: number) => {
    setPagination((prevpagination) => ({
      ...prevpagination,
      page: page,
    }));
  };
  // set search value
  const [search, setSearch] = useState("");


  // Define an asynchronous function ajaxcurrenTlist to send AJAX request to obtain the list of suppliers
  const ajaxCurrentList = async () => {
    setLoading(true); // Set the loading status to TRUE, indicating that the data is loading
    let searchStr = {};
    // Check whether there are search conditions, if so, build a search parameter object
    if (search) {
      searchStr = {
        search: search,
      };
    }
    // Send GET requests to get supplier data
    const responseBoarding = await requestDataListBoarding(pagination, "GET");
    console.log(responseBoarding.data.data)
    setLoading(false); // After the data is loaded, set the loading status to false
    if (responseBoarding.data.code === 200) {
      setBoardingList(responseBoarding.data.data); // 如果响应状态码为200，将供应商列表更新为响应数据中的数据
    }
  };
  
  // Use the USEEFFECT hook. When Search changes, call the AjaxCurrenTList function
  useEffect(() => {
    ajaxCurrentList();
  }, [pagination]);


  // Use the USEEFFECT hook. When Search changes, call the AjaxCurrenTList function
  useEffect(() => {
    setPagination((prevpagination) => ({
      ...prevpagination,
      boarding: boarding,
    }));
  }, [boarding]);

   // Execute the useeffect when the first load is loaded.
 const isInitialMount = useRef(true);
 useEffect(() => {
   if (!isInitialMount.current) {
    setPagination((prevpagination) => ({
      ...prevpagination,
      boarding_status_id: boardingStatus,
    }));
   }
   isInitialMount.current = false;
 }, [boardingStatus]);

  


  // ============================================== Onboarding ==================================// 
  // ==============================================    start   ==================================// 

  // Edit User
  const handleEdit = (row: NewBoardingListData) => {
    setFormType("Edit : " + row.username);
    setId(row.id);
    openEdit();
  };
  // del User
  const delHandle = (row: NewBoardingListData) => {
    modals.openConfirmModal({
      title: 'Please confirm your action',
      centered: true,
      children: "Do you confirm deletion?" ,
      labels:globalCoreSetting.DeleteOpenConfirmModaLabels ,
      confirmProps:globalCoreSetting.DeleteOpenConfirmModaConfirmProps,
      onConfirm: async () => {
        const response = await requestDeleteBoarding({id: row.id}, "DELETE");
        const result = response.data;
        HintInfo(result);
        if (result.code === 200) {
          ajaxCurrentList();
        }
      },
    });
  };
  
  const Onboarding = (row: NewBoardingListData, boarding_status_id: any, boarding_id: any = '', type: string = 'onboarding', color: string = '') => modals.openConfirmModal({
    title: 'Please confirm your action',
    children: (
      <Text size="sm"> 
          <ChangeStatus boarding={boarding_id} boarding_status={boarding_status_id} />
      </Text>),
    labels: { confirm: 'Confirm', cancel: 'Cancel' },
    onCancel: () => console.log('Cancel'),
    onConfirm: async () => {
      let data = { id: row.id, boarding_status_id: boarding_status_id, boarding: boarding_id };
      setLoading(true);
      const response = await requestSetStatus(data, "POST");
      setLoading(false);
      HintInfo(response.data);
      if (response.data.code == 200) {
        ajaxCurrentList();
      }
    },
  });

  // ==============================================    end      ==================================// 
  // ============================================== Onboarding  ==================================// 



  // ============================================== Offboarding ==================================// 
  // ==============================================    start   ==================================// 



  const switchCostCenter = (row: NewBoardingListData) => {
    setCurrentRowInfo(row);
    openCostCenter()
  };

  const handleCostCenterChange = async (value: any) => {
    if (currentRowInfo) {
      //  将部门换成新的costCenter
      let user_data = { user_id: currentRowInfo?.user_id, costCenter_id: value };
      const response = await updateUserCostCenter(user_data, "POST");
      HintInfo(response.data);
      if (response.data.code === 200) {
        ajaxCurrentList();
        closeCostCenter();
      }
    }
  };


  // Confirmed,  correct?
  // ==============================================    end      ==================================// 
  // ============================================== Offboarding  ==================================// 

  // Create a row for each supplier
  const rows = boardingList.data.map((row) => (
    <TrBoarding
      switchCostCenter={switchCostCenter}
      Onboarding={Onboarding}
      handleEdit={handleEdit}
      delHandle={delHandle}
      row={row}
      boarding={boarding}
      boarding_status_id={boardingStatus} />
  ));

  return (
    <Box>
      <Paper px={20} w={"100%"} mih={400} style={{ position: "relative" }}>
      
      {boarding_status_id != 4 &&  <Group>
          <Box>
            <Select
              w={200}
              placeholder="boarding"
              rightSection={<ActionIcon size='xs' onClick={() => { setBoardingValue('') }}> <IconX color="gray" /></ActionIcon>}
              defaultValue={boarding}
              key={boarding}
              onChange={setBoardingValue}
              data={[
                { value: '1', label: 'On boarding' },
                { value: '2', label: 'Off boarding' },
                { value: '3', label: 'Changing' },
              ]}
            />
          </Box>
          <Box>
            <Select
              w={200}
              placeholder="boarding status"
              rightSection={<ActionIcon size='xs' onClick={() => { setBoardingStatusValue('') }}> <IconX color="gray" /></ActionIcon>}
              defaultValue={boardingStatus}
              key={boardingStatus}
              onChange={setBoardingStatusValue}
              data={[
                { value: '1', label: 'Finish' },
                { value: '2', label: 'Cancel' },
                { value: '3', label: 'Onhold' },
              ]}
            />
          </Box>
        </Group>} 

        <Grid gutter={0} mt={5} >
          <LoadingOverlay visible={loading} overlayBlur={2} />
          <table className="table_custome text-center">
            <thead className="table_th_xs">
              <tr className="table_th  text-left">
                <th>{word_translation.personal_no}</th>
                <th>{word_translation.user}</th>
                <th>{word_translation.contract_start_time}</th>
                <th>{word_translation.contract_end_time}</th>
                <th>{word_translation.onboarding_start}</th>
                <th>{word_translation.vacation_day_year}</th>
                <th>{word_translation.work_hours_week}</th>
                <th>{word_translation.cost_center_name}</th>
                <th>{word_translation.costcenter_leader}</th>
                <th>{word_translation.boarding_name}</th>
                <th>{word_translation.status}</th>
                <th>{word_translation.option}</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        </Grid>{" "}
        <PaginationA
          callBack={ReceivePaginationABack}
          total_count={boardingList.total_count as number}
          pagesize={pagination.pagesize}
          page={pagination.page}
        />
      </Paper>
      <Modal opened={openedEdit} size='100%' onClose={closeEdit} title={formType} centered>
        <OnBoarding id={id} />
      </Modal>

      <Modal opened={openedCostCenterStatus} zIndex={9999} onClose={closeCostCenter} title="Authentication" centered>
        <ChangeStatus boarding={3} boarding_status={-1} />
        <SelectCostCenter handleCostCenterChange={handleCostCenterChange} request_status={openedCostCenterStatus} />
      </Modal>



    </Box>
  );
}
