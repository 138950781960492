import { Box, Button, Card, Divider, Drawer, Group, Paper } from "@mantine/core";
import SearchBox from "../../../ttcomponents/SearchBox/SearchBox";
import { IPagination } from "../../../interface/Ipagintion";
import { useEffect, useState } from "react";
import { useDisclosure } from "@mantine/hooks";
import { regCustomerRole, regOrdername } from "../../../api";
import { IOrderNameWithCallback, IOrderNameData, APIResponse } from "../../../interface/IorderName";
import ProjectNameForm from "./order_name_form";
import TableOrderNames from "../../../ttcomponents/Table/TableOrderNames";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconX } from "@tabler/icons-react";
import OrderNameForm from "./order_name_form";
export default function OrderName(){
    const [openedModal1, { open: openModal1, close: closeModal1 }] = useDisclosure(false);
    
    const [orderNameList, setOrderNameList] = useState<IOrderNameData[]|[]>([]);
    
    const [orderNameData, setOrderNameData] = useState<IOrderNameData>({ id_order_name:'',    order_name_description: '',    name: '',  });
    
    const [pagination, setPagination] = useState<IPagination>({
        search: '',
        page: 1,
        pagesize: 10,
        sortBy: '',
        sortOrder: 'asc',
      });

          /**
   * 
   * Get user information
   */
    const ajaxCurrentList = async () => {
        const response = await regOrdername(pagination, "GET");
        const result = response.data as APIResponse;
        if (result.code === 200 && !Array.isArray(result.data)) {
        //   setCustListCount(result)
          setOrderNameList(result.data.data)
        } else {
            console.log(result.msg);
        }
      }
      useEffect(() => {
        ajaxCurrentList();
      }, [pagination]);
  
    // 接收回调返回数据，搜索内容  Receive the callback return data, search for content
    const ReceiveSearchBack=(value:string|number)=>{
        console.log(value)
        setPagination(prevpagination => ({
            ...prevpagination,
            search:value
        }));
        }
    const receiveNotifications=(result:boolean)=>{
            closeModal1()
            // setFormRoleData({id_customer_roles: 0,role_name:'', role_desc: '', })
            ajaxCurrentList();
         }
        
   
         const handleOnValueEdit = (value: object) => {
           console.log(value)
           openModal1()
           const editedValue = value as IOrderNameData ;
           setOrderNameData(editedValue)
       };
     
       const handleOnValueDel =  (value: boolean) => {
                closeModal1()
        }
        const createBnt =()=>{
            setOrderNameData({ id_order_name: '',    order_name_description: '',    name: '',  })
            openModal1()
         }
    return (
        <Paper w={'100%'} >
        <Box  w={{ base: '100%', xs: 200, sm:  600 , lg: '100%',md:800}} pb={20} >
            <Group>
            <SearchBox callBack={ReceiveSearchBack} />
            <Divider size="xs" orientation="vertical" />
                <Box h={30}>
                <Button
                    color="customize.1"
                    className="btnHover"
                    uppercase
                    onClick={createBnt}
                >
                    create
                </Button>
                </Box>
                <Drawer  size="sm"   opened={openedModal1} onClose={closeModal1} title="Project Status Manage"   position="left" >
                <Card p={20} className="border-dotted min-h-min-600" radius={10} color="customize" >
                   <OrderNameForm orderNameSingleData={orderNameData} callBack={receiveNotifications} />
                </Card>
            </Drawer>
            </Group>
              <TableOrderNames onValueEdit={handleOnValueEdit}  onValueDel={handleOnValueDel}  callBack={receiveNotifications} orderNameData={orderNameList} />
        </Box>
    </Paper>
    )
}