import { Box, Modal } from "@mantine/core";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { CSSProperties, useEffect, useState } from "react";
import { regGetVationComment } from "../../../api";
import { ClientWarningHint } from "../../../utils/function";
import WordTranslationComponent from "../../../utils/language_pack/words";
import { get } from "http";
import { getPublicHolidayListGeneralFunction } from "../../../api/core_request";
import { useDisclosure } from "@mantine/hooks";
import LeaveVactionCommnet from "../leave_commnet";
import { IComment } from "../../../interface/Ivaction";

const localizer = momentLocalizer(moment);

interface ComponentInterface {
    events:any[];
    callBack:(year:string,current_month:string)=>void
    callCommontBack:(value:any)=>void
}

export default function TeamCalendar({callCommontBack,events,callBack}:ComponentInterface){
    
  const [CurrentDatas, setVactionCaledarSelect] = useState<any[]>([]);
  const [publicDays, setPublicDays] = useState<string[]>([]);
  const [randNumber, setRandNumber] = useState<number>(1);
  const [year, setYear] = useState('');
  const [month, setMonth] = useState('');

  const [currentVactionId, setCurrentVactionId] = useState<any>();
  const [vacationDetailedStatue, { open: vacationDetailedOpened, close: vacationDetailedClose }] = useDisclosure(false);

  // Define holiday remarks
  const [vactionComment, setVactionComment] = useState<IComment[]>();
  
 // Get translation public configuration information
 const word_translation = WordTranslationComponent();
 useEffect(()=>{
    setVactionCaledarSelect(events)
    setRandNumber(randNumber+1)
 },[events])
  



 useEffect(()=>{
    const parsedDate = moment(new Date());
    setYear(parsedDate.format('YYYY')) ;
    getPublicHoliday('')
 },[])
  


 const getPublicHoliday = async (where:any|null) => {
    setPublicDays(await getPublicHolidayListGeneralFunction(where));
  };

  // 使用dayPropGetter设置每个日期的样式
  const dayPropGetter = (date: moment.MomentInput) => {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    const isSpecialDate = publicDays.includes(formattedDate);
    const backgroundColor = isSpecialDate ? '#fff9db' : undefined;

    return {
      style: { backgroundColor },
    };
  };

  const handleEventClick = async (event: any) => {
    setCurrentVactionId(event.id);
     vacationDetailedOpened();
     const ApprovalResult = await regGetVationComment({ leave_id: event.id });
     if (ApprovalResult.data.code === 200) {
       setVactionComment(ApprovalResult.data.data);
     } else {
       ClientWarningHint(
         "You have no operational permissions for this vacation"
       );
     }
  };

  const handleSelectSlot = async (row: any) => { 
    // alert(12)
    // const ApprovalResult = await regGetVation(row.id_vacation);
  };


  function eventStyleGetter(
    event: {
      title: string;
      id: string;
      time: string;
      time_project_id: string;
      start: Date;
      end: Date;
      leave_category_id: string;
      status_id: string;
      color: string;
    },
    start: Date,
    end: Date,
    isSelected: boolean
  ): { className?: string | undefined; style?: CSSProperties | undefined } {
    // Define the mapping relationship between status_id and color
    const statusColorMap: Record<string, string> = {
      // Add mapping here
      "1": "var(--orange-6, #FD7E14)",
      "2": "var(--green-6, #40C057)",
      "3": "red",
      "4": "red",
      "5": "red",
      // ...
    };
    //Look up the color in the mapping table according to status_id, if not found, use the default color
    const eventColor = statusColorMap[event.status_id] ? event.leave_category_id == '2' ?'#c1c2c5' : statusColorMap[event.status_id] :  "blue";
    
    const style = {
      backgroundColor: event.color || eventColor, //Use event color property or color from mapping
    };

    return {
      style,
    };
  }
  
  
  const handleOnView = (view: any) => {

    console.log(view)

  };
  const handleNavigate = (date: any, view: string) => {
    
    const parsedDate = moment(date);
    console.log(parsedDate.format('YYYY-MM'))
    const current_year = parsedDate.format('YYYY');
    const current_month = parsedDate.format('MM');
    if(year != current_year){
        getPublicHoliday({year:current_year})
    }

    if(month != current_month){
      // 发起请求
        callBack(current_year,current_month)
    }
    setYear(current_year)
    setMonth(current_month)
  };


  const EditVationCallback = () => {
    vacationDetailedClose();
  };


    return(
        <Box>
             <Calendar
              className="calendar"
              localizer={localizer}
              events={CurrentDatas}
              startAccessor="start"
              dayPropGetter={dayPropGetter}
              endAccessor="end"
              selectable={true}
              onView={handleOnView}
              onNavigate={handleNavigate}
              onSelectEvent={handleEventClick} // 添加事件点击回调函数
              onSelectSlot={handleSelectSlot}
              eventPropGetter={eventStyleGetter} // 设置事件样式
              style={{ height: "500px", fontSize: "14px" }}
            />

             <Modal
        opened={vacationDetailedStatue}
        onClose={vacationDetailedClose}
        title="Modify the request"
      >
        <LeaveVactionCommnet
          row={vactionComment}
          id_leave={currentVactionId}
          callback={EditVationCallback}
        />
        {/* Modal content */}
      </Modal>  
        </Box>
    )
}
