import {
  Table,
  Group,
  Avatar,
  Text,
  ActionIcon,
  TransferListData,
  Grid,
  Select,
  Code,
  Textarea,
  ScrollArea,
  TextInput,
  Button,
  Box,
  Modal,
  LoadingOverlay,
  NumberInput,
  Drawer,
  Card,
  Checkbox,
  Pagination,
  Divider,
  Paper,
  Flex,
  Skeleton,
} from "@mantine/core";
import CustomerCategoryEditFrom from "../../../customer_category/customer_category_edit_form";
import CustomerCategoryAddFrom from "../../../customer_category/customer_category_add_form";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import {
  IconPencil,
  IconCalendar,
  IconMessages,
  IconNote,
  IconReportAnalytics,
  IconTrash,
  IconDots,
  IconSearch,
  IconCheck,
  IconX,
  IconUserPlus,
  IconRefresh,
  IconSquareRoundedPlus,
} from "@tabler/icons-react";
import { FormEvent, useEffect, useRef, useState } from "react";
import { notifications } from "@mantine/notifications";
import { modals } from "@mantine/modals";
import { IPagination } from "../../../../interface/Ipagintion";
import { FormattedMessage, useIntl } from "react-intl";
import PaginationA from "../../../../ttcomponents/Pagination/PaginationA";
import {
  APICustomerCategory,
  ICustomerCategoryItem,
} from "../../../../interface/ICustomerCategory";
import { regProjectOnly, regTaskOnlyCategory } from "../../../../api";
import { IprojectTask } from "../../../../interface/ItaskOnlyProject";
import TaskOnlyProjectEdit from "./task_only_project_edit_form";
// import { ApiResponse } from '../../interface/Iemloyee';
interface ICustomerProps {
  [key: string]: any;
}
// { callback, searchValue, isScrolledToBottom }: ICustomerProps
export default function TaskProject() {
  // 钩子函数-切换语言
  const intl = useIntl();

  //加载中状态
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const [openedModal1, { open: openModal1, close: closeModal1 }] =
    useDisclosure(false);
  const [openedModal2, { open: openModal2, close: closeModal2 }] =
    useDisclosure(false);

  // search
  const [search, setSearch] = useState("");
  //等待数据
  const [loadingData, setLoadingData] = useState(true);
  //定义修改的tasl承接变量
  const [taskOnlyCategoryItem, setTaskOnlyCategoryItem] =
    useState<IprojectTask>({
      id_task_only_project: "",
      customer_id: "",
      status_id: "",
      project_no: "",
      name: "",
      timestamp: "",
      info: "",
      delete_status: "",
    });

  const containerRef = useRef<HTMLDivElement>(null);

  const [custListCount, setCustListCount] =
    useState<APICustomerCategory | null>(null);
  const [taskOnlyCategoryData, setTaskOnlyCategoryData] = useState<
    IprojectTask[]
  >([]);
  const [pagination, setPagination] = useState<IPagination>({
    search: "",
    page: 1,
    pagesize: 10,
    sortBy: "",
    sortOrder: "desc",
  });
  const form = useForm({
    initialValues: {
      delete_status: "0",
      id_task_only_category: "",
      description: "",
      name: "",
      info: "",
    },
    // validate: {
    //     name:(value) =>
    // }
  });

  /**
   *
   * Get user information
   */
  const ajaxCurrentList = async () => {
    setLoading(true);
    const response = await regProjectOnly(pagination, "GET");
    const result = response.data;

    if (result.code === 200 && !Array.isArray(result.data)) {
      setTaskOnlyCategoryData(result.data.data);
      setCustListCount(result);
      // setCustomerData((prevList) => [...prevList, ...result.data.data]);
    } else {
      console.log(result.msg);
    }
    setLoading(false);
  };

  //  定义修改表单后的回调函数
  const callBackhandle = () => {
    ReceivePaginationABack(pagination.page);
    closeModal1();
    closeModal2();
  };

  const edit = async (value: object) => {
    // 获取这个task的详细信息、雇员等情况
    const editData = value as IprojectTask;
    setTaskOnlyCategoryItem(editData);
    openModal1();
  };

  const openDataAdd = async () => {
    openModal2();
  };

  const rows =
    taskOnlyCategoryData.length > 0 ? (
      taskOnlyCategoryData.map((item, index) => (
        <tr key={index}>
          <td>
            {" "}
            <Text fz="xs">{item.id_task_only_project} </Text>{" "}
          </td>
          <td>
            {" "}
            <Text fz="xs">{item.name} </Text>{" "}
          </td>
          <td>
            {" "}
            <Text fz="xs">{item.info} </Text>{" "}
          </td>
          <td>
            {" "}
            <Group spacing={0} position="left">
              <ActionIcon onClick={() => edit(item)}>
                <IconPencil size="1rem" stroke={1.5} />
              </ActionIcon>
              <ActionIcon onClick={() => delHandle(item)}>
                <IconTrash size="1rem" stroke={1.5} color="red" />
              </ActionIcon>
            </Group>
          </td>
        </tr>
      ))
    ) : (
      <>
        <tr>
          <FormattedMessage id="no_one_here" defaultMessage="No One Here" />
        </tr>
      </>
    );

  // 删除客户  Delete customers
  const delHandle = (row: IprojectTask) => {
    const delValue = row;
    modals.openConfirmModal({
      title: (
        <FormattedMessage
          id="delete_confirm"
          defaultMessage="Delete Confirmation"
        />
      ),
      centered: true,
      children: (
        <Text mt={10} size="sm">
          <FormattedMessage id="Name" defaultMessage="Name" />:{" "}
          <Code> {delValue.name}</Code>
        </Text>
      ),
      labels: {
        confirm: (
          <FormattedMessage
            id="confirm_delete"
            defaultMessage="Confirm Delete"
          />
        ),
        cancel: (
          <FormattedMessage id="cancel_delete" defaultMessage="Cancel Delete" />
        ),
      },
      confirmProps: { color: "red" },
      onConfirm: async () => {
        const FormData = {
          id: delValue.id_task_only_project,
        };
        const response = await regProjectOnly(FormData, "DELETE");
        const result = response.data;
        if (result.code === 200) {
          // ajaxCurrentList();
          setTaskOnlyCategoryData(
            taskOnlyCategoryData.filter(
              (item) =>
                item.id_task_only_project !== delValue.id_task_only_project
            )
          );
          notifications.show({
            color: "green",
            icon: <IconCheck />,
            message: result.msg,
          });
        } else {
          notifications.show({
            color: "red",
            icon: <IconX />,
            message: result.msg,
          });
        }
      },
    });
  };

  // 接收回调返回数据，搜索内容  Receive the callback return data, search for content
  const ReceiveSearchBack = (value: string | number) => {
    setTaskOnlyCategoryData([]);
    setPagination((prevpagination) => ({
      ...prevpagination,
      search: value as string,
      page: 1,
    }));
  };
  // 分页回调触发更新
  const ReceivePaginationABack = (page: number) => {
    setPagination((prevpagination) => ({
      ...prevpagination,
      page: page,
    }));
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSearchValue(value);
  };

  useEffect(() => {
    ReceiveSearchBack(searchValue);
  }, [searchValue]);

  useEffect(() => {
    ajaxCurrentList();
  }, [pagination]);

  return (
    <Paper
      px={20}
      w={"100%"}
      className="scrollable-container-table"
      style={{ position: "relative" }}
    >
      <LoadingOverlay visible={loading} overlayBlur={2} />
      <Box
        pb={20}
        h="85vh"
        style={{ overflow: "auto" }}
        ref={containerRef}
        className="scrollable-content-table"
      >
        <Flex className="title_header" justify="left" align="center" pl={30}>
          <Text
            className="Headlinestitle_text"
            fw={700}
            size={18}
            color="white"
          >
            TASK ONLY PROJECT MANAGEMENT
          </Text>{" "}
        </Flex>
        <Group mt={10}>
          <TextInput
            placeholder={intl.formatMessage({
              id: "Search",
              defaultMessage: "Search",
            })}
            icon={<IconSearch size="0.9rem" stroke={1.5} />}
            value={searchValue}
            onChange={handleSearchChange}
          />
        </Group>

        <Table
          horizontalSpacing="xs"
          verticalSpacing="xs"
          fontSize="xs"
          w="100%"
        >
          <thead>
            <tr>
              <th>
                <Text fw={500} fz="sm">
                  <FormattedMessage id="ID" defaultMessage="ID" />
                </Text>
              </th>
              <th>
                <Text fw={500} fz="sm">
                  <FormattedMessage id="name" defaultMessage="Project Name" />
                </Text>
              </th>
              <th>
                <Text fw={500} fz="sm">
                  <FormattedMessage
                    id="desciption"
                    defaultMessage="Desciption"
                  />
                </Text>
              </th>

              <th>
                <Text fw={500} fz="sm">
                  <FormattedMessage id="Option" defaultMessage="Option" />
                </Text>
              </th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
        <PaginationA
          callBack={ReceivePaginationABack}
          total_count={custListCount?.data.total_count as number}
          pagesize={pagination.pagesize}
          page={pagination.page}
        />

        <Modal
          radius="md"
          size={"md"}
          opened={openedModal1}
          className="modal-setting"
          onClose={closeModal1}
          title={
            <FormattedMessage
              id="Task Only Category Edit"
              defaultMessage="Task Only Category Edit"
            />
          }
          overlayProps={{ opacity: 0.5, blur: 4 }}
        >
          <TaskOnlyProjectEdit
            formDataProps={taskOnlyCategoryItem}
            callBack={callBackhandle}
          />
        </Modal>

        <Modal
          radius="md"
          size={"md"}
          opened={openedModal2}
          className="modal-setting"
          onClose={closeModal2}
          title={
            <FormattedMessage
              id="Task Only Category Add"
              defaultMessage="Task Only Category Add"
            />
          }
          overlayProps={{ opacity: 0.5, blur: 4 }}
        >
          <CustomerCategoryAddFrom callBack={callBackhandle} />
        </Modal>
      </Box>
    </Paper>
  );
}
