import {
  Box,
  Button,
  Card,
  Badge,
  Code,
  Grid,
  LoadingOverlay,
  MultiSelect,
  Select,
  Text,
  TextInput,
  Group,
  ActionIcon,
  Title,
  Textarea,
  Table,
  Divider,
  Tooltip,
  Avatar,
  Checkbox,
  Modal,
  Paper,
  NumberInput,
  ScrollArea,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { hasLength, isNotEmpty, useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import {
  IconAddressBookOff,
  IconArrowBadgeDownFilled,
  IconArrowBadgeUpFilled,
  IconCalendar,
  IconCheck,
  IconDots,
  IconPlug,
  IconPlus,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import { FormEvent, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import {
  regGetProduct,
  regGetProductId,
  regOrder,
  regOrderList,
  regTimeViolation,
} from "../../../../api";
import {
  ClientWarningHint,
  HintErrorMantineInfo,
  HintInfo,
  JudgmentTimeThreshold,
  TimeHHSS,
  convertDateFormat,
  formatDate,
  formatDateUpgraded,
  formatTimeHHSS,
} from "../../../../utils/function";
import SaveBottom from "../../../../ttcomponents/Button/SaveBtn";
import { getCalculateEstimated } from "../../../../api/common_request";
import Message from "../../../../ttcomponents/Message/Message";
import { ProductOrder, Purchase } from "../../../../public/svg/ProductOrder";
import {
  ICreateProduct,
  IProductResponse,
  ItemProps,
  Product,
  ProductInterface,
} from "../../../../interface/IProduct";
import { useDisclosure } from "@mantine/hooks";
import localstorageUnits from "../../../../utils/localstorageUnits";
import { useIntl } from "react-intl";
import CustomerFrom from "../../customer/customer_form";
import WordTranslationComponent from "../../../../utils/language_pack/words";
import TableTopTitle from "../../../../ttcomponents/TableTopTitle/TableTopTitle";
import TableTextInputTitle from "../../../../ttcomponents/TableTextInputTitle/TableTextInputTitle";
import TableTextInputTitleDesc from "../../../../ttcomponents/TableTextInputTitleDesc/TableTextInputTitleDesc";
import {
  getCustomerListGeneralFunction,
  getOrderListGeneralFunction,
  getProjectDetailByIdFunction,
  getProjectListGeneralFunction,
} from "../../../../api/assignments_request";
import { SelectRole } from "../../../../interface/Icommon";
import { UserAllList } from "../../../../interface/Iuser";
import SelectItem from "./order_product/order_product_select";
import OrderProductForm from "./order_product/order_product_from";
import { getProductGeneralSelectFunction } from "../../../../api/inventory_request";
import ResetBtn from "../../../../ttcomponents/Button/ResetBtn";
import UniversalButton from "../../../../ttcomponents/Button/UniversalButton";
import ProductList from "./order_product/product_list";
import OrderList from "./order_list";
import { ApiOrderResponse, OrderDataInterface } from "../../../../interface/Iorder";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../state_management/store";
import { setProductListSlice } from "../../../../state_management/features/product_list/productListSlice";
import OrderProductFormI from "./order_product/order_product_from_I";
import { ProjectDataInterface } from "../../../../interface/Iprojects";
import { requestOrder } from "../../../../api/index_v2";
import { ChildMethods } from "../project/project_create";

interface IOrderFromProps {
  callBack: () => void;
  [key: string]: any;
  c_allUserList: any;
  disPalyCurrentPageBtn?:boolean;
  orderInfoFromEditPage?:OrderDataInterface;
  c_userList: any;
  callBackFunction: (order_id:string) => void;
}




const OrderFrom = forwardRef<ChildMethods, IOrderFromProps>(({orderInfoFromEditPage,disPalyCurrentPageBtn,callBackFunction,  customerList, project_id,c_allUserList,  c_userList,}, ref) => {
// export default function OrderFrom({  callBack,  project_id,  c_userList,  c_allUserList,}: IOrderFromProps) {

  console.log(project_id,"project_id")
  const dispatch = useDispatch();
  // Get translation public configuration information
  const word_translation = WordTranslationComponent();
  // refresh status number
  const [rand_number, setRandNumber] = useState<number>(1);
  const [rand_product_number, setProductRandNumber] = useState<number>(1);

  
 //Use useImperativeHandle to throw child component methods to parent components
  useImperativeHandle(ref, () => ({  orderSaveFormData,orderRest,orderSaveAndNextFormData,}));
  const orderRest=  () => {
    form.reset();
    form.setFieldValue('customer_id',currentProjectInfo?.customer_id as string)
    setRandNumber(rand_number+1)
  }   
  //  Define current  project info  in the page
  const [currentProjectInfo,setCurrentProjectInfo] = useState<ProjectDataInterface>();
  // Define all the customer lists that can be selected
  const [customerCurrentPageList, setCustomerList] = useState<SelectRole[]>([]);

  // Define all the project lists that can be selected
  const [projectList, setProjectList] = useState<SelectRole[]>([]);

  // Define all the list of employees you can choose
  const [userlist, setUserList] = useState<SelectRole[]>([]);
  // Define the user list (including the user's detailed information)
  const [UserList, setResultUser] = useState<UserAllList[]>([]);

  // Define all the list of product you can choose
  const [productData, setProductData] = useState<ItemProps[] | undefined>([]);
  // Define the order list (including the user's detailed information)
  const [orderlist, setOrderList] = useState<OrderDataInterface[]>([]);
 
  
  // 获取redux productDataSlice store中的值
  const productDataSlice =  useSelector((state: RootState) => state.productData.productData)    

  const [productDetailsToBeAdded, setProductDetailsToBeAdded] = useState<ProductInterface>();

  /**
   * 定义添加的订单商品信息存储变量
   */
  const [formProductList, setFormProductList] = useState<ProductInterface[]>([]);


 // 定义当前即将添加的产品的数量
  const [currentProductQuantity, setCurrentProductQuantity] = useState<number>(0);

  useEffect(() => {
    setResultUser(c_allUserList);
    setUserList(c_userList);
  }, [c_allUserList, c_userList]);

  
  useEffect(() => {
    setCustomerList(customerList);
  }, [ customerList]);
  
  
  useEffect(() => {
    // If the project exists, then assign values to the project_id and customer_id in the Form
    form.setFieldValue('project_id',currentProjectInfo?.id_project)
    form.setFieldValue('customer_id',currentProjectInfo?.customer_id as string)
  }, [currentProjectInfo]);


  useEffect(() => {
    if(orderInfoFromEditPage){
      form.setFieldValue('id',orderInfoFromEditPage.id_order)
      form.setFieldValue('order_no',orderInfoFromEditPage.order_no)
      form.setFieldValue('customer_id',orderInfoFromEditPage.customer_id)
      form.setFieldValue('status_id',orderInfoFromEditPage.status_id)
      form.setFieldValue('order_name',orderInfoFromEditPage.order_name)
      form.setFieldValue('delivery_schedule', new Date(orderInfoFromEditPage.delivery_schedule))
      // form.setFieldValue('delivery_time',new Date(orderInfoFromEditPage.delivery_time))
      form.setFieldValue('is_internal',orderInfoFromEditPage.is_internal )
      form.setFieldValue('service_charge',orderInfoFromEditPage.service_charge)
      form.setFieldValue('costCenter_id',orderInfoFromEditPage.costCenter_id)
      //  TODO : Split user_id into int array form
      // The reason is that the component that selects the leader only accepts inter type.
      const user_id = orderInfoFromEditPage?.user_id; 
      const user_id_array = user_id ? String(user_id).split(',').map(Number) : [];
      form.setFieldValue('user_id',user_id_array as [])
      setRandNumber(rand_number+1)
  }
  }, [orderInfoFromEditPage]);
  

  const [productList, setProductList] = useState<IProductResponse>({
    total: 0,
    data: [], // Use the data property from the first item in the mock data array
  });
  const [teamLeaderValue, setTeamLeaderValue] = useState<string[]>([]);
  //加载中状态
  const [loading, setLoading] = useState(false);
   // 初始化提交表单 Initialization Submitting Form

  
  const form = useForm({
    validateInputOnBlur: true,
    initialValues: {
      project_id:  project_id ? project_id : '',
      customer_id: "",
      status_id: "1",
      order_no:"",
      id:"",
      order_name: "",
      is_internal: "1",
      service_charge: "1",
      delivery_schedule:new Date(),
      user_id: [],
      product: [],
    },

    validate: {
        order_name: hasLength({ min: 2, max: 100 }, `${word_translation.street}${word_translation.validata_value_need_2_100_length}`),
        order_no: hasLength({ min: 2, max: 100 }, `${word_translation.company_name}${word_translation.validata_value_need_2_100_length}`),
        user_id: isNotEmpty(`${word_translation.order_leader} ${word_translation.is_not_empty}`),
        customer_id: isNotEmpty(`${word_translation.customer} ${word_translation.is_not_empty}`),
    },
  });
  const setTeamLeaderValueHandle = (value: React.SetStateAction<string[]>) => {
    // setTeamLeaderValue(value);
    form.setFieldValue("order_team_leaders", value as []);
  };


  
  const orderSaveFormData= async () => {
     const order_id = await FormSubmitFunction('save');    
  }   

  const  orderSaveAndNextFormData= async () => {
    callBackFunction( await FormSubmitFunction('save'));
  }   


  const FormSubmitFunction = async ( type='save') => {
    // Validate the form
    const valid = await form.validate();
    //  If there are validation errors, display error information
    if (valid.hasErrors) {
      HintErrorMantineInfo(valid);
      return false;
    }

    const submitFormValues = {
      ...form.values,
        delivery_schedule: formatDateUpgraded(
          form.values.delivery_schedule as Date,
          "YYYY-MM-DD"
        ),
  
    };

      setLoading(true);
      const response = await requestOrder(submitFormValues, form.values.id?"PUT":"POST");
      setLoading(false);
      HintInfo(response.data);
      if (response.data.code == 200) {
        if(type != 'save'){
          form.reset()
        }
        if( response.data.data.id){
          return (response.data.data.id)
        }
        return ('')
      }else{
        return false
      };
  };
/**
 * The callback function after selecting the product
 * @param value 
 */
const selectProductCallback = async (value: any) => {
  if (typeof value === 'object') {
    // TODO: If the returned value is an object, it represents a valid product record.
    // Display information such as stock and price on the page and await confirmation.
    console.log('Product Information:', value);
    setProductDetailsToBeAdded(value)
  } 
  if (typeof value === 'string') {
    // TODO: If the returned value is a string, it indicates that the product is not currently in the warehouse.
    // Only display the name on the page and await confirmation.
    console.log('Product Name:', value);
    setProductDetailsToBeAdded({
      product_name: value,
      unit_price: '',
      product_id: '',
      product_in_stock: 0,
    })
  }
  setRandNumber(rand_number+1)
};
// 对已经存在列表的产品进行更新
const selectedProductCallback = async (value: any) => {
  console.log('已存在产品',value)
};

  // Click the function triggered when selecting the project. Value parameter is the project ID <FUNCTION>
  const selectProjectItemFunction = async (project_id: string | null) => {
     await  getProjectDeatil(project_id as string)
     getOrderList({project_id:project_id})
  };
  
  // get all order list  or  get order list by project id
  const getOrderList = async (value: any) => {
    setOrderList(await getOrderListGeneralFunction(value,''))
  };

// get all customer list 
  const getCustomerList = async () => {
    setCustomerList(await getCustomerListGeneralFunction(""));
  };
  
// get all product list 
  const getProjectList = async () => {
    setProjectList(await getProjectListGeneralFunction(""));
  };

// get a  product detail by  project_id
  const getProjectDeatil = async (project_id:string) =>{
   setCurrentProjectInfo(await getProjectDetailByIdFunction(project_id))
  }

// Listen to the project_id from the parent component. If the value exists, get the detailed information of the project.
 useEffect(()=>{
    if(project_id){
      // Call the function to get project details
      getProjectDeatil(project_id)
    }
  },[project_id])

  const getProduct =async () => {
    const result = await getProductGeneralSelectFunction(word_translation.unit_price,word_translation.product_not_available);
    // setProductList
    setProductData(result);
  }
  // 获取项目
  useEffect(() => {
    getProduct();
    // getCustomerList();
    getProjectList();
  }, []);

  
    //Delete added product  in order 
  const delOrderedProduct = (indexs: number) => {
    const newData = formProductList.filter(
      (item, index) => index !== indexs
    );
    setFormProductList(newData);
    setRandNumber(rand_number+1)
  };
  
  const setQuantityFucntion = (value:any,index = -1)=>{
    if(index === -1){//说明是新加的一行
      setCurrentProductQuantity(value)
    }else{ //修改已经存在的行
        
    }
  }
    
    //  重置订单产品信息
    const resetProductFormFunction = ()=>{

    }

    // Add order product information to the list to be added
    const addProductToOrder = ()=>{
       //  product name is require
       if (productDetailsToBeAdded?.product_name === '' || productDetailsToBeAdded?.product_name === undefined) {
        ClientWarningHint(word_translation.product_name_is_require);
        return false;
      }
      //  product quantity must > 0
        if(currentProductQuantity < 1){
           ClientWarningHint(word_translation.quantity_gt_1_);
           return false;
        }
     
      // Perform Duplicate Data Validation by product ID and product name 
      // If result is true, it means there is a duplicate product  in added order.
       if (formProductList.some(item => item.product_id == productDetailsToBeAdded.product_id && item.product_name == productDetailsToBeAdded.product_name)) {
            // Handle duplicate product ID case
          ClientWarningHint(word_translation.product_duplicate);
          return false;
      } 

        // Assemble Data
      let productData: ProductInterface = {
        product_id:productDetailsToBeAdded?.product_id.toString(), // 确保 product_id 为字符串,只有这样添加的时候才能和<OrderProductForm组件默认值保持一直,反显
        product_name: productDetailsToBeAdded?.product_name || '',
        product_in_stock: productDetailsToBeAdded?.product_in_stock, // 确保 product_in_stock 为数字
        unit_price: productDetailsToBeAdded?.unit_price, // 确保 unit_price 为字符串
        order_quantity: currentProductQuantity,
      };

      const addOrderProductToForm = [...formProductList,productData];
      // TODO   assign values to the[ product_id and product_name and order_item_quantity ] in the Form
      form.setFieldValue('product', addOrderProductToForm.map(item => {
        return { 
          product_id:item.product_id,
          product_name: item.product_name,
          order_item_quantity: item.order_quantity,
        };
    }) as []);

        setFormProductList(addOrderProductToForm);
        setProductDetailsToBeAdded(undefined)
        setCurrentProductQuantity(0)
        setRandNumber(rand_number+1)
        // TODO refresh Product select component
        setProductRandNumber(rand_product_number+1)
    }
     //  添加订单产品信息到提交表单列表
     const addProductFunction = ()=>{
         FormSubmitFunction();
         getOrderList({project_id:currentProjectInfo?.id_project as string})
     }
     

    //  删除订单产品信息
    const callBackProductFunction = (value:any)=>{

    }
    
    const editFunction= async ()=>{
      const editResult = await FormSubmitFunction();
      if(editResult !== false){
        callBackFunction(form.values.id);
        return false
      }
      callBackFunction('-1');
  }
  return (
    <Paper px={20}>
      <LoadingOverlay visible={loading} overlayBlur={2} />
      <Box pb={20}>
        <Grid gutter={10} mt={10} columns={24} >
          <Grid.Col span={11} offset={1} className="">
            <Box className="border-radius-xs border-custom  px-xs">
               <Box className=" pt-xs pt-4">
                     {/*  associated projectname */}
                <Box pt={10} pb={35} className="px-15 border-bt-1">
                  <Grid>
                    <Grid.Col span={7}>
                      <TableTextInputTitle
                        title={word_translation.associated_project_name}
                      />
                      <TableTextInputTitleDesc
                        pt={2}
                        pb={2}
                        title={word_translation.associated_projec_desc_tips}
                      />
                      <Select
                        className="badge_text"
                        maxDropdownHeight={150}
                        labelProps={{ className: "input_title" }}
                        placeholder={word_translation.pick_one}
                        disabled={form.values.id?true:false}
                        nothingFound={word_translation.no_data}
                        onChange={selectProjectItemFunction}
                        size="xs"
                        defaultValue={project_id?project_id.toString():''}
                        key={project_id}
                        data={projectList}
                      />
                    </Grid.Col>
                    <Grid.Col span={5}>
                      <TableTextInputTitle
                        title={word_translation.associated_project_no}
                      />
                      <TableTextInputTitleDesc
                        pt={2}
                        pb={2}
                        title={word_translation.associated_project_no}
                      />
                      <TextInput
                        placeholder={word_translation.project_no}
                        value={currentProjectInfo?.project_no}
                        size="xs"
                        disabled
                      />
                    </Grid.Col>
                  </Grid>
                  <Grid>
                    <Grid.Col span={8}>
                      <TableTextInputTitle
                        title={word_translation.associated_customer}
                      />
                      <Select
                        placeholder={word_translation.pick_one}
                        searchable
                        disabled={currentProjectInfo?.id_project? true:false}
                        key={form.values.customer_id?.toString()}
                        nothingFound={word_translation.no_data}
                        size="xs"
                        
                        defaultValue={form.values.customer_id?.toString()}
                        onChange={(value) =>
                          form.setFieldValue("customer_id", value as string)
                        }
                        data={customerCurrentPageList}
                      />
                    </Grid.Col>
                  </Grid>
                </Box>
                     {/*  associated project end */}
                     {/*  order Info  */}
                <Box  pt={10} pb={35}  className="px-15 border-bt-1 ">
                  <Grid>
                    <Grid.Col span={7}>
                      <TableTextInputTitle
                        title={word_translation.order_name}
                      />
                      <TextInput
                        placeholder={word_translation.order_name}
                        size="xs"
                        {...form.getInputProps("order_name")}
                      />
                    </Grid.Col>
                    <Grid.Col span={5}>
                      <TableTextInputTitle title={word_translation.order_no} />
                      <TextInput
                        placeholder={word_translation.order_no}
                        size="xs"
                        {...form.getInputProps("order_no")}
                      />
                    </Grid.Col>
                  </Grid>
                  <Grid>
                    <Grid.Col span={8}>
                      <TableTextInputTitle
                        title={word_translation.order_leader}
                      />
                      <MultiSelect
                        onChange={(value) =>
                          form.setFieldValue("user_id", value as [])
                        }
                        size="xs"
                        key={rand_number}
                        placeholder={word_translation.project_leader}
                        searchable
                        defaultValue={form.values.user_id}
                        data={userlist}
                      />
                    </Grid.Col>
                  </Grid>
                </Box>
                     {/*  order Info end  */}
             
             {/* product Info */}
                <Box  pt={10} pb={35}  className="px-15 ">
                  <Grid>
                    <Grid.Col my={10} span={3}>
                      <Checkbox
                      label={
                        <TableTextInputTitle
                              title={word_translation.service_fee}
                            />}
                        size="xs"
                        checked={form.values.service_charge == '1' ? true : false}
                        onChange={(event) =>{
                          form.setFieldValue('service_charge',event.currentTarget.checked ? '1':'0')
                        }
                        }
                      />
                    </Grid.Col>
                    
                  <Grid.Col my={10} span={9}>
                    <Checkbox
                      checked={form.values.is_internal == '1' ? true : false}
                      label={ <TableTextInputTitle
                        title={word_translation.internal}
                      />}
                      size="xs"
                      onChange={(event) =>{
                        form.setFieldValue('is_internal',event.currentTarget.checked ? '1':'0')
                      }}
                    />
                  </Grid.Col>
                    <Grid.Col span={12}>
                    {formProductList.map((item,index)=>{
                          return (
                            <Grid  key={index} align="center"> 
                            <Grid.Col span={5}>
                               <OrderProductForm  
                                  rand_number={rand_number} 
                                  current_value={item.product_id } 
                                  selectProductCallback={selectedProductCallback} 
                                  product_data={productData}  
                                  product_name={item.product_name}  
                                  is_show={true}  
                                  is_loading={false}/>   </Grid.Col>
                            <Grid.Col span={2}> <Box> <NumberInput      size="xs" placeholder={word_translation.Input} disabled value={item.product_in_stock as number} /></Box> </Grid.Col>
                            <Grid.Col span={2}> <Box> <NumberInput      size="xs" placeholder={word_translation.Input}  defaultValue={item.order_quantity} onChange={(event) =>  setQuantityFucntion(event,1)  } /></Box> </Grid.Col>
                            <Grid.Col span={2}> <Box> <TextInput     size="xs"   placeholder={word_translation.Input} disabled defaultValue={item.unit_price} /></Box> </Grid.Col>
                            <Grid.Col span={1}> 
                                <ActionIcon  variant="filled" color="red" onClick={() => delOrderedProduct(index)}>
                                     <Tooltip label={word_translation.delete_tips}>
                                             <IconX  stroke={1.5} />
                                     </Tooltip>
                                 </ActionIcon>   
                             </Grid.Col>
                         </Grid>
                          )
                        })}
                        
                        {/* 等待添加区域 */}
                        <Grid align="center">
                            <Grid.Col span={5}  key={rand_product_number}>  <OrderProductForm 
                              is_show={false}   selectProductCallback={selectProductCallback}  product_data={productData}  is_loading={false}/>   </Grid.Col>
                            <Grid.Col span={2}> <Box> <NumberInput      size="xs"  key={rand_number} placeholder={word_translation.Input}  defaultValue={productDetailsToBeAdded?.product_in_stock} disabled /></Box> </Grid.Col>
                            <Grid.Col span={2}> <Box> <NumberInput      size="xs"  key={rand_number}placeholder={word_translation.Input}   onChange={(event) =>  setQuantityFucntion(event,-1)  }  /></Box> </Grid.Col>
                            <Grid.Col span={2}> <Box> <TextInput      size="xs"  key={rand_number}  placeholder={word_translation.Input}    defaultValue={productDetailsToBeAdded?.unit_price}   /></Box> </Grid.Col>
                            <Grid.Col span={1}> 
                            {/* 确认按钮 */}
                                <ActionIcon  variant="filled" color="green" onClick={() => addProductToOrder()}>
                                    <Tooltip label={word_translation.delete_tips}>
                                            <IconCheck  stroke={1.5} />
                                    </Tooltip>
                                </ActionIcon>   
                            </Grid.Col>
                        </Grid>         
                                                
                        <Grid align="center"> 
                          <Grid.Col span={12}> 
                             <Group position="right" mt={10}>
                                  <ResetBtn  callBack={resetProductFormFunction}/>
                                  <UniversalButton  btn_text={word_translation.add_products} callBack={addProductFunction} btn_size='xs'  btn_color="green"  btn_icon={<IconPlus />}  />
                              </Group> 
                          </Grid.Col>
                        </Grid>
                    </Grid.Col>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Grid.Col>
          <Grid.Col span={12}  className="">
            <TableTextInputTitle py={10} title={word_translation.created_orders} />
             <Box className="border-radius-xs border-custom ">
              <Box pt={10} pb={35} className="px-15  border-bt-1">
                  <OrderList orderlist={orderlist}  is_refresh={0}  callBackOrderIDfunction={(value)=>{console.log(value)}} callBackfunction={callBackProductFunction} returnAttributeList={()=>{}}  />
              </Box>
              </Box>
            <TableTextInputTitle py={10} title={word_translation.associated_product} />
             <Box className="border-radius-xs border-custom ">
                <Box className="px-15  border-bt-1">
                    <ProductList  is_refresh={0}  callBackfunction={callBackProductFunction} returnOrederProduct={()=>{}}  />
                </Box>
              </Box>
          </Grid.Col>
        </Grid>
          {/* only dispaly in edit project */}
          {form.values.id || disPalyCurrentPageBtn &&
          <Group position="right" mt={5}>
            <UniversalButton  btn_bg_color='#868E96' btn_text={word_translation.cancel} btn_size='xs' callBack={()=>{}} />
            <UniversalButton btn_text={form.values.id ?  word_translation.edit : word_translation.add} btn_size='xs' callBack={() => editFunction()} />
          </Group>
        }
      </Box>

 
    </Paper>
  );

})

export default OrderFrom;