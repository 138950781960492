import { FC } from 'react'
import { ILog } from '../../../../interface/Ilog'
import { Box, Tooltip, Text } from '@mantine/core'
import { truncateString } from '../../../../utils/function'

const LogListRow: FC<{ row: ILog }> = ({ row }) => {
  return (
    <>
      <td>{row.id_log}</td>
      <td>{row.module_name}</td>
      <td>{row.table_name}</td>
      <td>{row.row_id}</td>
      <td>
        <TruncateString str={row.column_name} maxLength={16} />
      </td>
      <td>{row.type}</td>
      <td>
        <TruncateString str={row.old_value} maxLength={16} />
      </td>
      <td>
        <TruncateString str={row.new_value} maxLength={16} />
      </td>
      <td>{row.changed_by}</td>
      <td>{row.changed_at}</td>
      <td>
        <TruncateString str={row.desc} maxLength={14} />
      </td>
      <td>{row.sequence_number}</td>
    </>
  )
}

const TruncateString: FC<{ str: string; maxLength: number }> = ({ str, maxLength }) => {
  if (str.length > maxLength) {
    return (
      <Tooltip label={str}>
        <Text inherit>{truncateString(str, maxLength)}</Text>
      </Tooltip>
    )
  }
  return <>{str}</>
}

export default LogListRow
