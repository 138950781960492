import {
  Paper,
  Tabs,
} from "@mantine/core";
import {
  IconBrandUnity,
  IconBuilding,
  IconMessageCircle,
  IconPhoto,
  IconSettings,
} from "@tabler/icons-react";
import WordTranslationComponent from "../../../../utils/language_pack/words";
import CompanySettings from "../company/company_settings";
import { useEffect, useState } from "react";
import PermissionAction from "../../../../ttcomponents/Permission/permission_action";
import { getCorePermissionActionListGeneralFunction, getCorePermissionActionUniqueList } from "../../../../api/core_request";
import { requestCorePermissionAction, requestCorePermissionIdentifier, requestLeavePermissionAction, requestReportPermissionAction } from "../../../../api";
import CheckPermissionTools from "../../../../utils/permission";
import { NotPermission } from "../../../../ttcomponents/NoPermission";
import { requestPermissionAction, requestTMPermissionAction, requestTimeTrackerUniquePermissionIdentifierList } from "../../../../api/index_v2";
import { SelectRole } from "../../../../interface/Icommon";
import { getTmPerActionUnique } from "../../../../api/assignments_request";
import { getLeavePerActionUnique } from "../../../../api/leave_request";
import { getTMPerActionUnique } from "../../../../api/timetracker_request";
import memoryUtils from "../../../../utils/memoryUtils";
interface ComponentInterface {
  is_load_str?: string
}
export default function PermissionRoleIndex({ is_load_str }: ComponentInterface) {
  // Get translation public configuration information
  const word_translation = WordTranslationComponent();

  const [user_type, set_user_role_id] = useState<string | null>(memoryUtils.user.user_type);
  
  const Perm = CheckPermissionTools();

  const [currentType, setActiveTab] = useState<string | null>('core');

  // Declare the variable actionIdentifier that stores the user branch center
  const [actionIdentifier, setActionIdentifier] = useState<SelectRole[]>([]);
  // Get the unique logo list
  const getUniqueActionList = async (closureFunction: any) => {
    // Call the Get Cost Center General Selection function and set the cost center data
    setActionIdentifier(await closureFunction);
  };

  useEffect(() => {
    setActionIdentifier([]);
    switch (currentType) {
      case 'core':
        is_load_str == 'permission_action' &&  getUniqueActionList(getCorePermissionActionUniqueList());
        break;
      case 'assignment':
        is_load_str == 'permission_action' &&   getUniqueActionList(getTmPerActionUnique());
          break;
      case 'assignment':
        is_load_str == 'permission_action' &&     getUniqueActionList(getLeavePerActionUnique());
            break;
      case 'assignment':
        is_load_str == 'permission_action' &&     getUniqueActionList(getTMPerActionUnique());
              break;
      default:
        setActionIdentifier([]);

    }
  }, [currentType])
  /**
   *----------
   *组件需要的权限
   *----------
   */

  const corePremission: any = {
    'read': Perm.permission_read,
    'create': Perm.permission_create,
    'update': Perm.permission_update,
    'delete': Perm.permission_delete,
  }

  const TimetrackerPremission: any = {
    'read': true,
    'create': true,
    'update': Perm.permission_update,
    'delete': Perm.permission_delete,
  }






  var text = <NotPermission />;
  const settings = {
    links: [
      {
        label: 'core', view:<PermissionAction
          Permission={corePremission}
          actionIdentifierList={actionIdentifier}
          submitFunction={requestCorePermissionAction}
          requestListFunction={requestCorePermissionAction}
          is_load_str={is_load_str} />, icon: IconBrandUnity
      },
      {
        label: 'assignment', view: <><PermissionAction
          Permission={TimetrackerPremission}
          actionIdentifierList={actionIdentifier}
          submitFunction={requestPermissionAction}
          requestListFunction={requestPermissionAction}
          is_load_str={is_load_str} /></>, icon: IconBuilding
      },
      {
        label: 'leave_request', view: <><PermissionAction
          Permission={TimetrackerPremission}
          actionIdentifierList={actionIdentifier}
          submitFunction={requestLeavePermissionAction}
          requestListFunction={requestLeavePermissionAction}
          is_load_str={is_load_str} /></>, icon: IconBuilding
      },
      {
        label: 'timetracker', view: <><PermissionAction
          Permission={TimetrackerPremission}
          actionIdentifierList={actionIdentifier}
          submitFunction={requestTMPermissionAction}
          requestListFunction={requestTMPermissionAction}
          is_load_str={is_load_str} /></>, icon: IconBuilding
      },
      {
        label: 'report', view: <><PermissionAction
          Permission={TimetrackerPremission}
          actionIdentifierList={actionIdentifier}
          submitFunction={requestReportPermissionAction}
          requestListFunction={requestReportPermissionAction}
          is_load_str={is_load_str} /></>, icon: IconBuilding
      },
      { label: 'inventory', view: <>{text}</>, icon: IconSettings },
    ],
  };


  return (
    <Paper>
        <Tabs mt={10} defaultValue={currentType} onTabChange={setActiveTab} keepMounted={false} >
        <Tabs.List>
          {/* Render individual tabs with icons and labels */}
          {settings.links.map((item, index) => (
            <Tabs.Tab key={item.label} value={item.label} icon={<item.icon size={20} slope={1.5} />}>
              {word_translation[item.label as keyof typeof word_translation]}
            </Tabs.Tab>
          ))}
        </Tabs.List>
        {/* Render corresponding panels for each tab */}
        {settings.links.map((item, index) => (
          <Tabs.Panel key={index} value={item.label} pt="xs">
            {item.view}
          </Tabs.Panel>
        ))}
      </Tabs>
      {/* Tabs for different settings */}
  
    </Paper>
  );
}
