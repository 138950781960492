import React, { FormEvent, useRef } from "react";
import {
  TextInput,
  Textarea,
  Paper,
  Grid,
  Group,
} from "@mantine/core";
import {
  hasLength,
  isNotEmpty,
  useForm,
} from "@mantine/form";
import { IconCheck, IconCircleX, IconX } from "@tabler/icons-react";
import { notifications } from "@mantine/notifications";
import { regGetUserRoleList, regVacationCategory } from "../../../api";
import { UserRoleData } from "../../../interface/Iuser";
import TableTextInputTitle from "../../../ttcomponents/TableTextInputTitle/TableTextInputTitle";
import { useIntl } from "react-intl";
import WordTranslationComponent from "../../../utils/language_pack/words";
import CancelButton from "../../../ttcomponents/Button/CancelBtn";
import SaveBottom from "../../../ttcomponents/Button/SaveBtn";
import { IcategoryInteface } from "../../../interface/Ivaction";
import SubmitBtn from "../../../ttcomponents/Button/SubmitBtn";
import { ClientWarningHint, HintInfo } from "../../../utils/function";
import UniversalButton from "../../../ttcomponents/Button/UniversalButton";

interface ISupplierFormProps {
  closeFunction: (type: number) => void;
  CotegoryItem: IcategoryInteface | undefined;
}

export default function LeaveCotegoryForm({
  closeFunction,
  CotegoryItem,
}: ISupplierFormProps) {
  // Initialize references and hooks
  const formRef = useRef<HTMLFormElement>(null);
  const word_translation = WordTranslationComponent();
  const form = useForm({
    initialValues: {
      name: CotegoryItem?.name,
      id: CotegoryItem?.id_leave_category,
    },
    // Validation rules for form fields
    validate: {
      name: hasLength({ min: 2, max: 100 }, `${word_translation.street}${word_translation.validata_value_need_2_100_length}`),
   
    },
  });

  // Handle form submission
  const handleFormSubmit = async () => {
    // Validate form fields
    const valid = form.validate();
    if (valid.hasErrors) {
      // Display error notification if validation fails
      ClientWarningHint( Object.values(valid.errors)[0])
      return;
    }
    // Determine submission type (POST or PUT)
    const submitType = form.values.id ? "PUT" : "POST";
    // Perform API request to save user role data
    const response = await regVacationCategory(form.values, submitType);
    HintInfo(response.data)
    if (response.data.code === 200) {
      closeFunction(2);
    } 
  };

  return (
    <Paper py={10}>
        <Grid>
          {/* Input field for role name */}
          <Grid.Col span={12}>
          <TableTextInputTitle title={word_translation.category_name} />
            <TextInput
              {...form.getInputProps("name")}
              placeholder={word_translation.Input}
              radius="md"
            />
          </Grid.Col>
        </Grid>
        {/* Button group for form actions */}
        <Group position="right" mt={10}>
          <UniversalButton btn_bg_color='red' btn_text={word_translation.cancel} btn_icon={<IconCircleX width={14} height={14}  />} btn_size="xs"  callBack={() => closeFunction(1)} />
          <SubmitBtn btn_size="xs" callBack={handleFormSubmit} />
        </Group>
    </Paper>
  );
}
