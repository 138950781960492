import {
  Paper,
  TextInput,
  Box,
  Group,
  Text,
  Modal,
  Grid,
  Table,
  ActionIcon,
  LoadingOverlay,
  Tooltip,
  Flex,
} from "@mantine/core";
import { FormEvent, useEffect, useState } from "react";
import { Supplier, SuppliersData } from "../../../interface/Iinventory";
import { useDisclosure } from "@mantine/hooks";

import PageTitle from "../../../ttcomponents/PageTitle/PageTitle";
import {
  IconEdit,
  IconInfoCircle,
  IconPencil,
  IconReportAnalytics,
  IconSearch,
  IconTransferIn,
  IconTrash,
  IconTruckDelivery,
  IconTruckReturn,
  IconUserPlus,
  IconUsersMinus,
  IconX,
} from "@tabler/icons-react";
import CreateBtn from "../../../ttcomponents/Button/CreateBtn";
import { useIntl } from "react-intl";
import { useForm } from "@mantine/form";
import { modals } from "@mantine/modals";
import {
  regGetProduct,
  regGetProductCategory,
  regGetSupplier,
  regProduct,
  regSupplier,
} from "../../../api";
import { HintInfo } from "../../../utils/function";
import SearchBox from "../../../ttcomponents/SearchBox/SearchBox";
import SupplierContactIndex from "../supplier_contact/supplier_contact_index";
import { IPagination } from "../../../interface/Ipagintion";
import PaginationA from "../../../ttcomponents/Pagination/PaginationA";
import { IProductResponse, Product } from "../../../interface/IProduct";
import { DetailCustome } from "../../../public/svg/DetailCustome";
import SupplierSelect from "../../../ttcomponents/Supplier/SupplierSelect";
import { ICategory } from "../../../interface/IProductCategory";
import {
  Cancellation,
  DetailStorage,
  Purchase,
  ReturnFromStorage,
  ReturnFromStorageProhibit,
} from "../../../public/svg/ProductOrder";
import ProductProcurementFrom from "../purchasing/purchasing_from";
import { notifications } from "@mantine/notifications";
import ReturnProductOrderFrom from "../return_product/return_product_order_from";
import DeliveryIndex from "./delivery";
import RecyclingFrom from "./recycling/recycling_from";

export default function StoreHouseIndex() {
  // useIntl for multiple language
  const intl = useIntl();
  // Edit modal status
  const [openedFrom, { open: openFrom, close: closeFrom }] =
    useDisclosure(false);

  const [
    RecyclingStatus,
    { open: openRecyclingForm, close: closeRecyclingForm },
  ] = useDisclosure(false);

  // contact modal status
  const [
    ProductProcurementShow,
    { open: openProductProcurementShow, close: colseProductProcurementShow },
  ] = useDisclosure(false);

  const [loading, setLoading] = useState(false);
  // Form type
  const [formType, setFormType] = useState("create");
  const [currentRowInfo, setCurrentRowInfo] = useState<Product | undefined>();
  const [productCategory, setProductCategory] = useState<ICategory[]>([]);

  // return the goods
  const [returnOpened, { toggle: openReturnOpened, close: closeReturnOpened }] =
    useDisclosure(false);
  // define the supplier list
  const [productList, setProductList] = useState<IProductResponse>({
    total: 0,
    data: [], // Use the data property from the first item in the mock data array
  });

  const [pagination, setPagination] = useState<IPagination>({
    search: "",
    page: 1,
    pagesize: 10,
    sortBy: "",
    sortOrder: "asc",
    query: {},
  });
  // Receive the callback return data, search for content
  const ReceiveSearchBack = (value: string | number) => {
    setPagination((prevpagination) => ({
      ...prevpagination,
      search: value,
      page: 1,
      query: {},
    }));
  };
  const ReceiveSupplierBack = (value: any) => {
    if (value.type == "supplier") {
      setPagination((prevpagination) => {
        const { query, ...rest } = prevpagination; // Extract the attributes other than Query to the REST
        if (value.value == "") {
          // If value.value exists, delete the support
          delete query.supplier_id;
        } else {
          query.supplier_id = value.value;
        }

        return {
          ...rest,
          page: 1, // Keep other attributes in REST
          query: query, // Set the modified query
        };
      });
    }
  };

  //Pagling recovery trigger update Terrament recovery trigger update
  const ReceivePaginationABack = (page: number) => {
    setPagination((prevpagination) => ({
      ...prevpagination,
      page: page,
    }));
  };
  // set search value
  const [search, setSearch] = useState("");

  // Define an asynchronous function ajaxcurrenTlist to send AJAX request to obtain the list of suppliers
  const ajaxCurrentList = async () => {
    setLoading(true); // Set the loading status to TRUE, indicating that the data is loading
    let searchStr = {};

    // Check whether there are search conditions, if so, build a search parameter object
    if (search) {
      searchStr = {
        search: search,
      };
    }

    // Send GET requests to get supplier data
    const responseSupplier = await regGetProduct(pagination, "GET");

    setLoading(false); // After the data is loaded, set the loading status to false
    if (responseSupplier.data.code === 200) {
      setProductList(responseSupplier.data.data); // 如果响应状态码为200，将供应商列表更新为响应数据中的数据
    }
  };
  // Use the USEEFFECT hook. When Search changes, call the AjaxCurrenTList function
  useEffect(() => {
    ajaxCurrentList();
  }, [pagination]);

  // Edit supplier
  const recyclingFunction = async (row: Product) => {
    setCurrentRowInfo(row);
    setFormType("recycling");
    openRecyclingForm();
  };

  // Edit supplier
  const deliveryFunction = async (row: Product) => {
    setCurrentRowInfo(row);
    setFormType("edit");
    openFrom();

    const responseProductCategor = await regGetProductCategory({}, "GET");
    if (responseProductCategor.data.code == 200) {
      setProductCategory(responseProductCategor.data.data);
    }
  };

  // Delete supplier
  const handleDel = (row: Product) => {
    modals.openConfirmModal({
      title: "Delete this row",
      centered: true,
      children: (
        <Text size="sm" mt={12}>
          Supplier Name: {row.supplier_name}
        </Text>
      ),
      labels: {
        confirm: intl.formatMessage({
          id: "confirm_delete",
          defaultMessage: "Confirm Delete",
        }),
        cancel: intl.formatMessage({
          id: "cancel_delete",
          defaultMessage: "Cancel Delete",
        }),
      },
      confirmProps: { color: "red" },
      onConfirm: () => delSupplierInfo(row.product_id as number),
    });
  };

  // Delete supplier info to backend
  const delSupplierInfo = async (id: number) => {
    const productResult = await regProduct({ product_id: id }, "DELETE");
    HintInfo(productResult.data);
    if (productResult.data.code == 200) ajaxCurrentList();
  };

  // Create supplier
  const handleCallback = async () => {
    setFormType("create");
    openFrom();
    setCurrentRowInfo(undefined);
    const responseProductCategor = await regGetProductCategory({}, "GET");
    if (responseProductCategor.data.code == 200) {
      setProductCategory(responseProductCategor.data.data);
    }
  };

  // Purchasing order action
  const returnTreatment = async (row: Product) => {
    setCurrentRowInfo(row);
    console.log(row.inventory);
    if (row.inventory == 0) {
      notifications.show({
        color: "yellow",
        icon: <IconX />,
        message: "This Product cannot be returned",
      });
    } else {
      openReturnOpened();
    }
  };

  // Create a row for each supplier
  const rows = productList.data.map((row) => (
    <tr className="border-bt-2 my-10 ">
      {/* <td> {row.product_id ? row.product_id : '0'}  </td> */}
      <td> {row.product_name} </td>
      {/* <td> {row.inventory ? row.inventory : '0'}  </td> */}
      <td> {row.amount ? row.amount : "0"} </td>
      <td> {row.supplier_name ? row.supplier_name : "-"} </td>
      {/* <td> {row.category_name ? row.category_name : '-'}</td>
            <td> {row.unit_price ? row.unit_price : '-'}  </td> */}
      <td className="text-left">
        <Group spacing={0} position="center">
          <ActionIcon>
            <Tooltip label="Detail">
              <IconInfoCircle color="green" />
            </Tooltip>
          </ActionIcon>

          {row.inventory && row.inventory >= 0 ? (
            <>
              <ActionIcon onClick={() => returnTreatment(row)}>
                <Tooltip label="Return">
                  <IconTruckReturn color="red" />
                </Tooltip>
              </ActionIcon>
              <ActionIcon onClick={() => deliveryFunction(row)}>
                <Tooltip label="Delivery">
                  {/* <IconTransferIn color="#2C79DF" /> */}
                  <IconTruckDelivery color="#2C79DF" />
                </Tooltip>
              </ActionIcon>
              <ActionIcon onClick={() => recyclingFunction(row)}>
                <Tooltip label="Garbage recovery">
                  <IconTransferIn color="teal" />
                </Tooltip>
              </ActionIcon>
            </>
          ) : (
            <>
              <ActionIcon onClick={() => returnTreatment(row)}>
                <Tooltip label="There is no inventory, it cannot be Returned">
                  <IconTruckReturn color="#ccc" />
                </Tooltip>
              </ActionIcon>
              <ActionIcon>
                <Tooltip label="There is no inventory, it cannot be Delivered">
                  {/* <IconTransferIn color="#ccc" /> */}
                  <IconTruckDelivery color="#ccc" />
                </Tooltip>
              </ActionIcon>
              <ActionIcon>
                <Tooltip label="There is no inventory, it cannot be recycled">
                  <IconTransferIn color="#ccc" />
                </Tooltip>
              </ActionIcon>
            </>
          )}
        </Group>
      </td>
    </tr>
  ));

  const closeEditFunction = (type: number) => {
    closeFrom();
    closeReturnOpened();
    closeRecyclingForm();
    colseProductProcurementShow();
    if (type == 2) {
      ajaxCurrentList();
    }
  };
  // list all the suppliers
  return (
    <Box>
      <Paper
        px={20}
        mih={500}
        pb={10}
        w={"100%"}
        style={{ position: "relative" }}
      >
        <PageTitle title="STORAGE MANAGEMENT" lang_id="STORAGE MANAGEMENT" />
        <Group my={10}>
          <SearchBox callBack={ReceiveSearchBack} />
          <SupplierSelect callBack={ReceiveSupplierBack} />
          <Box h={30}>
            <CreateBtn callBack={handleCallback} />
          </Box>
        </Group>

        <LoadingOverlay visible={loading} overlayBlur={2} />

        <table className="table_custome text-center">
          <tr className="table_th">
            {/* <th>{intl.formatMessage({
  id: "Product Id",
  defaultMessage: "Product Id",
})}</th> */}
            <th>
              {intl.formatMessage({
                id: "Product Name",
                defaultMessage: "Product Name",
              })}
            </th>
            <th>
              {intl.formatMessage({
                id: "Quantity",
                defaultMessage: "Quantity",
              })}
            </th>
            <th>
              {intl.formatMessage({
                id: "Supplier",
                defaultMessage: "Supplier",
              })}
            </th>
            {/* <th>{intl.formatMessage({
  id: "Category",
  defaultMessage: "Category",
})}</th>
                        <th>Current Unit Price</th> */}
            <th>
              {intl.formatMessage({
                id: "Option",
                defaultMessage: "Option",
              })}
            </th>
          </tr>
          {rows}
        </table>
        <PaginationA
          callBack={ReceivePaginationABack}
          total_count={productList.total as number}
          pagesize={pagination.pagesize}
          page={pagination.page}
        />
      </Paper>

      <Modal
        opened={openedFrom}
        onClose={closeFrom}
        title="Out of the warehouse "
        size="md"
        padding="md"
        radius={12}
      >
        {/* <DeliveryIndex productCategory={productCategory} productItem={currentRowInfo} closeFucntion={closeEditFunction} /> */}
        <DeliveryIndex
          additional_information={currentRowInfo}
          closeFucntion={closeEditFunction}
        />
      </Modal>

      <Modal
        opened={ProductProcurementShow}
        onClose={colseProductProcurementShow}
        title=" Supplier Contact"
        // centered={true}
        size="60%"
        padding="md"
        radius={12}
      >
        {/* <SupplierContactIndex supplier={currentRowInfo}  /> */}
      </Modal>

      <Modal
        opened={returnOpened}
        withCloseButton
        onClose={closeReturnOpened}
        title="Return to supplier"
        size="xl"
        centered={true}
        radius="md"
      >
        <ReturnProductOrderFrom
          type={true}
          additional_information={currentRowInfo}
          closeFucntion={closeEditFunction}
        />
        {/* <Textarea value={returnNotes} onChange={(event) => setReturnNotes(event.currentTarget.value)} />;
                    <Button onClick={closeReturnOpened}>Subscribe</Button> */}
      </Modal>

      <Modal
        opened={RecyclingStatus}
        title="Garbage company recycling"
        withCloseButton
        onClose={closeRecyclingForm}
        size="xl"
        centered={true}
        radius="md"
      >
        <RecyclingFrom
          additional_information={currentRowInfo}
          closeFucntion={closeEditFunction}
        />
      </Modal>
    </Box>
  );
}
