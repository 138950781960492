// SelectItem.tsx
import { Group,Text,Badge } from '@mantine/core';
import React, { forwardRef } from 'react';

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
    image: string;
    label: string;
    value: string;
    supplier_name: string;
    unit_price: string;
    type: string;
  }

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  (
    { image, label, unit_price, supplier_name, type, ...others }: ItemProps,
    ref
  ) => (
    <div ref={ref} {...others}>
    <Group noWrap>
      {image}
      <div>
        <Text size="sm">
          {label}{" "}
          {type == "1" ? (
            <Badge color="green">New</Badge>
          ) : type == "2" ? (
            <Badge color="red">Second -hand</Badge>
          ) : (
            <Badge color="">Inquiry</Badge>
          )}
        </Text>
        <Text size="xs" opacity={0.65}>
          {unit_price}
        </Text>
        <Text size="xs" opacity={0.65}></Text>
      </div>
    </Group>
  </div>
  )
);

export default SelectItem;
