import { Badge, Box, Button, Group, Modal ,Text} from "@mantine/core";
import SubmitBtn from "../../../../../ttcomponents/Button/SubmitBtn";
import WordTranslationComponent from "../../../../../utils/language_pack/words";
import { IPermissionDetailInterface } from "../../../../../interface/Ipermission";


interface ComponentInterface {
    opened:boolean ;
    tempRoleId:string ;
    permissionDetail: IPermissionDetailInterface[];
    callBack:(value:string)=>void;
    close:()=>void;
    submitFormFunction:()=>void;
}
export default function PermissionExistsView({opened,permissionDetail,tempRoleId,close,submitFormFunction,callBack}:ComponentInterface){
    // Get translation public configuration information
    const word_translation = WordTranslationComponent();
    return (
        <Modal opened={opened} onClose={close} title="This permission already exists">
        <Box mt={5}>
           <Text fz={15} >
           　The permission set already exists. The users submitted this time will be replace the existing permission set. Are you sure you want to proceed?
           </Text>
                   {permissionDetail.map((item: any,index: any)=>{
                     return(
                  <Group>
                           <Badge>{item.identifier}</Badge> - {item.action}
                         </Group>
                       )
                     })}
        </Box>
        <Box>
          <Group  mt={20} position="apart"> 
            <Box>
            <Button size="xs" color="green" onClick={()=>{
              callBack(tempRoleId);
              close();
            }}>Close & Choose Existing Permission </Button></Box>
            <Box><Group>
            <Button size="xs" color='gray' onClick={close}>{word_translation.cancel}</Button>
            <SubmitBtn btn_size="xs" callBack={submitFormFunction}/>
            </Group></Box>
          </Group>
        </Box>
      </Modal>
    )
}