import React, { useEffect, useState } from "react";
import {
  RingProgress,
  Text,
  Group,
  Card,
  Grid,
  Divider,
  Title,
  Flex,
  Box,
  Menu,
  ActionIcon,
} from "@mantine/core";
import { IconRefresh, IconDots } from "@tabler/icons-react";
import { FormattedMessage } from "react-intl";
import { assignColorToCharacter } from "../../../utils/businessFunction";
import { regBoardProfileTrackedHour } from "../../../api";
import memoryUtils from "../../../utils/memoryUtils";
import { MonthPicker } from "@mantine/dates";
import localstorageUnits from "../../../utils/localstorageUnits";
import {
  JudgmentTimeThreshold,
  formatDateUpgraded,
} from "../../../utils/function";

// Definition interface
interface Project {
  project_id: string;
  project_name: string;
  total_hours: string;
  percentage: number;
}

interface ApiResponse {
  project_list: Project[];
  tracked_hours: string;
}

interface TooltipData {
  value: number;
  color: string;
  tooltip: string;
}

/**
 *
 * @returns User's own time record statistics preview
 */
export default function TrackedHours() {
  const initSystemTime = localstorageUnits.getSystem().init_use_date;
  const [range_value, setRangeValue] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  // Define data interface
  const [projectInfo, setProjectInfo] = useState<ApiResponse | null>(null);
  // Define the circular display information
  const [tooltipData, setTooltipData] = useState<TooltipData[]>([]);
  // send request
  const ajaxCurrentList = async () => {
    let responseUser: any;
    if (range_value[0] != null && range_value[1] != null) {
      responseUser = await regBoardProfileTrackedHour(
        {
          user_id: memoryUtils.user.id_user,
          start_date: formatDateUpgraded(range_value[0], "YYYY-MM-DD"),
          end_date: formatDateUpgraded(range_value[1], "YYYY-MM-DD"),
        },
        "GET"
      );
    } else {
      responseUser = await regBoardProfileTrackedHour(
        { user_id: memoryUtils.user.id_user },
        "GET"
      );
    }

    const result = responseUser.data;
    if (result.code === 200) {
      setProjectInfo(result.data);

      //Convert data to a specific format
      if (result.data && result.data.project_list) {
        const transformedData: TooltipData[] = result.data.project_list.map(
          (project: {
            total_hours: string;
            percentage: any;
            color: any;
            project_name: string;
          }) => ({
            value: project.percentage,
            // color: assignColorToCharacter(project.project_name),
            color: project.color,
            tooltip: project.project_name,
          })
        );
        setTooltipData(transformedData);
      }
    }
  };

  useEffect(() => {
    if (range_value[0] == null && range_value[1] == null) ajaxCurrentList();
  }, []);

  useEffect(() => {
    ajaxCurrentList();
  }, [range_value]);

  function setDateValue(value: any) {
    if (value[0] == null) {
      return false;
    }
    // Compare time with the system use time, not allowed to exceed the threshold
    if (JudgmentTimeThreshold(value[0]) === false) {
      return false;
    }
    setRangeValue(value);
  }
  return (
    <Card px={0} withBorder radius="md" shadow="sm">
      <Grid>
        <Grid.Col span={9}>
          <Group px="1rem">
            <Text size={16} fw={700} color="customize-gray.4">
              <FormattedMessage
                id="tracked_hours"
                defaultMessage="Tracked Hours"
              />
            </Text>
          </Group>
        </Grid.Col>
        <Grid.Col span="auto">
          <Group>
            {/* <IconRefresh size="1rem" stroke={1.5} /> */}
            <Menu openDelay={100} closeDelay={400} position="bottom-end">
              <Menu.Target>
                <ActionIcon
                  size={30}
                  mt={4}
                  mx={15}
                  radius="md"
                  variant="transparent"
                  color="customize-gray.7"
                >
                  <IconDots size="1rem" stroke={1.5} />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown>
                <MonthPicker
                  className=" report"
                  type="range"
                  value={range_value}
                  onChange={(even) => setDateValue(even)}
                />
              </Menu.Dropdown>
            </Menu>
          </Group>
        </Grid.Col>
      </Grid>
      <Flex px="1rem" mt={10} direction="column">
        <Title order={1}>{projectInfo?.tracked_hours}</Title>
        <Text size={10} color="customize-gray.4">
          <FormattedMessage
            id="tracked_hours_over_all_project"
            defaultMessage="Tracked Hours Over All Projects"
          />
        </Text>
      </Flex>
      <Divider my="sm" />
      <Group position="center">
        <RingProgress
          size={170}
          thickness={16}
          label={
            <Text
              size="xs"
              align="center"
              px="xs"
              sx={{ pointerEvents: "none" }}
            >
              <FormattedMessage
                id="hover_sections_to_see_tooltips"
                defaultMessage="Hover sections to see tooltips"
              />
            </Text>
          }
          sections={tooltipData}
        />
        <Flex px="1rem" mt={10} direction="column">
          {tooltipData.map((item, index) => (
            <Group key={index}>
              <Box
                h={10}
                w={20}
                mt={5}
                sx={{ backgroundColor: item.color }}
                className=""
              ></Box>
              <Text size={10} color="customize-gray.4">
                {item.tooltip}
              </Text>
            </Group>
          ))}
        </Flex>
      </Group>
    </Card>
  );
}
