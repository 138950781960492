import { TextInput, Group, Grid, Code, Paper, Textarea, Title, Button, Box, Table, ActionIcon, Divider } from "@mantine/core";
import { FormEvent, useRef, useState } from "react";
import { hasLength, isEmail, isNotEmpty, useForm } from "@mantine/form";
import SaveBottom from "../../../ttcomponents/Button/SaveBtn";
import CancelButton from "../../../ttcomponents/Button/CancelBtn";
import { Contact, Supplier } from "../../../interface/Iinventory";
import { notifications } from "@mantine/notifications";
import { Icon123, IconCheck, IconTextPlus, IconTrash, IconX } from "@tabler/icons-react";
import { isValidEmail, isNotEmpty as isNotEmptyCustom, isValidUsername } from "../../../utils/validataUnits";
import { regRecyclingCompany, regSupplier } from "../../../api";
import { ICompanyData } from "../../../interface/IDeliveryProduct";

interface ISupplierFormProps {
  closeFucntion: (type: number) => void;
  company_item: ICompanyData | undefined;
}




export default function RecyclingCompanyForm({
  closeFucntion,
  company_item,
}: ISupplierFormProps) {
  const formRef = useRef<HTMLFormElement>(null);


  const form = useForm({
    initialValues: {
      recycling_company_id: company_item?.recycling_company_id,
      company_name: company_item?.company_name,
      email: company_item?.email,
      tel: company_item?.tel,
      address: company_item?.address,
    },
    validate: {
      company_name: hasLength({ min: 2, max: 100 }, 'Supplier must be 2-10 characters long'),
      address: isNotEmpty('Enter supplier current address'),
      tel: isNotEmpty('Tel supplier current address'),
      email: isEmail('Invalid email'),
    },
  });


  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Stop the formal submission of the form default
    // Verification form
    const valid = form.validate();
    if (valid.hasErrors) {
      notifications.show({
        color: "yellow.7",
        icon: <IconX />,
        message: Object.values(valid.errors)[0],
      });
      return;
    } 
    let  submit_type = 'POST';
    if(form.values.recycling_company_id){
      submit_type = 'PUT'
    }

    const response = await regRecyclingCompany(form.values, submit_type);
    if (response.data.code == 200) {
      closeFucntion(2)
      notifications.show({
        color: 'green',
        icon: <IconCheck />,
        message: response.data.msg,
      })
    } else {
      notifications.show({
        color: "yellow.7",
        icon: <IconX />,
        message: response.data.data.database_returns_results,
      });
    }
  };





  return (
    <Paper p={10}>
      <form onSubmit={handleFormSubmit} ref={formRef}>
        <Grid>
          <Grid.Col span={6}>
            <TextInput
              {...form.getInputProps("company_name")}
              placeholder="Garbage  Recovery Company  Name"
              label="Company Name"
              radius="md"
              style={{ width: "100%" }}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              {...form.getInputProps("address")}
              placeholder="Address"
              label="Address"
              radius="md"
              style={{ width: "100%" }}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              {...form.getInputProps("tel")}
              placeholder="Tel"
              label="Tel"
              required
              radius="md"
              style={{ width: "100%" }}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              {...form.getInputProps("email")}
              placeholder="Email"
              label="Email"
              radius="md"
              style={{ width: "100%" }}
            />
          </Grid.Col>
        </Grid>


        <Group position="right">
          <CancelButton callBack={() => closeFucntion(1)} />
          <SaveBottom formRef={formRef} />
        </Group>


        {/** can see form values */}
        <Code block mt={5}>
          {JSON.stringify(form.values, null, 2)}
        </Code>
      </form>
    </Paper>
  );
}
