//

import { Box, Checkbox, Group } from "@mantine/core";
import WordTranslationComponent from "../../../../utils/language_pack/words";
import { PermissionActionInterface, PermissionIdentifierInterface, PermissionRoleInterface, PermissonSetFormDataInterface } from "../../../../interface/Iemloyee";
import ActiveBadge from "../../../../ttcomponents/ActiveBadge/ActiveBadge";
import EditIcon from "../../../../ttcomponents/EditIcon/EditIcon";
import DeleteIcon from "../../../../ttcomponents/DeleteIcon/DeleteIcon";
import TableTextInputTitle from "../../../../ttcomponents/TableTextInputTitle/TableTextInputTitle";
import { useEffect, useRef, useState } from "react";
import TableTextInputTitleDesc from "../../../../ttcomponents/TableTextInputTitleDesc/TableTextInputTitleDesc";


interface ComponentInterface {
  disabled?: boolean;
  identifierList: PermissionIdentifierInterface;
  actionList: PermissionActionInterface[];
  index?: number;
  currentPremissionSetItem:PermissonSetFormDataInterface;
  callBack: (index:number,permission_ids: string[], permission_identifier_id: string, all_flag: string) => void;
}

export default function PermissionSetCheckBoxGroup({ disabled = false,identifierList, actionList, currentPremissionSetItem,index, callBack }: ComponentInterface) {

  // Get translation public configuration information
  const word_translation = WordTranslationComponent();
  // Get the information to be modified and pass to the parent -level component
  const [permissionSelected, setPermissionSelected] = useState<string[]>([]);

  const [checked, setChecked] = useState(false);

  // Execute the useeffect when the first load is loaded.
  const isInitialMount = useRef(true);
  useEffect(() => {
    if (!isInitialMount.current) {
      callbackFunction()
    }
    isInitialMount.current = false;
  }, [permissionSelected, checked,])

  useEffect(() => {
    console.log('currentPremissionSetItem', currentPremissionSetItem)
    setChecked(currentPremissionSetItem?.all_flag === '1' ? true : false)
    if(currentPremissionSetItem?.permission_ids.length > 0){
      setPermissionSelected(currentPremissionSetItem?.permission_ids.split(','))
    }
  }, [currentPremissionSetItem])
  
   const callbackFunction= ()=>{
      callBack(index as number,permissionSelected, identifierList.id_permission_identifier, checked ? '1' : '0')
   }

  return (
    <>
      <Group mt={10}>
        <Box>
          <TableTextInputTitle title={identifierList.name} />
          <TableTextInputTitleDesc title={`${word_translation.permission_identifier} - ${identifierList.identifier}`} />
        </Box>
        <Box>
          <Checkbox
            mt={10}
            size="xs"
            disabled={disabled} 
            label={
              <TableTextInputTitle py={0} title={word_translation.all_flag} />}
            checked={checked} onChange={(event) => setChecked(event.currentTarget.checked)
            }
          />
        </Box>

      </Group>
      <Checkbox.Group
        defaultValue={['react']}
        key={index}
        mt={10}
        value={permissionSelected} onChange={setPermissionSelected}
      >
        <Group mt="xs">
          {actionList.map((_item, _index) => {
            return (
              <Checkbox  
              disabled={disabled} 
             value={_item.id_permission.toString()} label={_item.title} />
            )
          })}
        </Group>
      </Checkbox.Group>


    </>
  )
}