import {
  Avatar,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Group,
  Modal,
  Paper,
  Table,
  Text,
  Tooltip,
} from "@mantine/core";
import { IconAlarmFilled, IconSitemap } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { regItemReport, requestReportItem, requestUserProjectReports, requestUserWeekProjectReports } from "../../../api";
import { MonthlyTotal, Project, ReportProjectInfo, ReportTaskInfo, UserTimeRecord } from "../../../interface/Ireports";
import { useIntl } from "react-intl";
import WordTranslationComponent from "../../../utils/language_pack/words";
import { ConvertTimeStringToDecimal, formatTimeHHSS } from "../../../utils/function";
import { useDisclosure } from "@mantine/hooks";
import TableTextInputTitle from "../../../ttcomponents/TableTextInputTitle/TableTextInputTitle";
import MonthlyStatistics from "./monthly_statistics";
import ProjectDetail from "./project_detail";
import ProjectTaskCategory from "./task_category";
import UserProject from "./user_project";
import WeekDetail from "./week_detail";
import UserItemProject from "./user_item_project";
import { TimeTrackerInterface } from "../../../interface/Itimetrack";



interface WeekData {
  week: string;
  time?: string;
  year?: string;
}


export interface ResponseData {
  list: WeekData[];
  title: string[];
}






export default function ProjectItemReports() {
  // Get translation public configuration information
  const word_translation = WordTranslationComponent();
  const [opened, { open, close }] = useDisclosure(false);

  const urlParams = new URLSearchParams(window.location.search);
  const idProject = urlParams.get("item_id");
  const [projectinfo, setProjectInfo] = useState<ReportProjectInfo>();
  const [taskInfo, setTaskInfo] = useState<ReportTaskInfo>();
  const [monthlyTotal, setMonthlyTotal] = useState<MonthlyTotal[]>();
  const [userId, setUserId] = useState('');
  const [weekDate, setWeekDate] = useState([]);
  const [timeTracker, setTimeTracker] = useState<TimeTrackerInterface>();
  const [userTimeRecord, SetUserTimeRecord] = useState<UserTimeRecord[]>();
  const [userTimeProject, SetUserTimeProject] = useState<ResponseData>();
  const ajaxList = async () => {

    try {
      const response = await requestReportItem({ item_id: idProject }, "GET");
      if (response.data.code == 200) {
        const result = response.data;
        setProjectInfo(result.data.project_info);
        setTaskInfo(result.data.task_info);
        setMonthlyTotal(result.data.monthly_totals);
        SetUserTimeRecord(result.data.user_time_record_statistics);
      }
    } catch (error) {
      console.log(error);
    }

  };
  useEffect(() => {
    ajaxList();
  }, []);

  const getUserProjectReport = async (user_id: string | number) => {

    try {
      const response = await requestUserProjectReports({ project_id: idProject, user_id: user_id }, "GET");
      if (response.data.code == 200) {
        setUserId(user_id.toString() as string)
        const result = response.data;
        SetUserTimeProject(result.data);
        setTimeTracker(undefined)
        open()
        // setTaskInfo(result.data.task_info);
        // SetUserTimeRecord(result.data.user_time_record_statistics);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const getUserWeekProjectReport = async (week: string | number) => {


    // const weekDayInfo = getWeekDates(currentDate);
    try {
      const response = await requestUserWeekProjectReports({ project_id: idProject, user_id: userId, week: week }, "GET");

      if (response.data.code == 200) {
        const result = response.data.data;
        setTimeTracker(result)
        setWeekDate(result.date)
      }
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <>
      <Grid p={20} grow gutter="xs">
        <Grid.Col span={9}>
          <ProjectTaskCategory taskInfo={taskInfo} />
          <ProjectDetail projectinfo={projectinfo} />
          <MonthlyStatistics monthlyTotal={monthlyTotal} />
        </Grid.Col>
        <Grid.Col span={3}>
          <Box>
            <UserProject userTimeRecord={userTimeRecord} getUserProjectReport={getUserProjectReport} />
          </Box>
        </Grid.Col>
      </Grid>

      <Modal size='100%' py={20} opened={opened} onClose={close} closeOnClickOutside={false} title="Personal records under the project">
        
        <UserItemProject userTimeProject={userTimeProject} getUserWeekProjectReport={getUserWeekProjectReport} />
        <WeekDetail weekDate={weekDate} timeTracker={timeTracker} />
    
      </Modal>
    </>
  );
}
