//

import { ActionIcon, Group, Text } from '@mantine/core'
import { modals } from '@mantine/modals'
import { IconPencil, IconTrash } from '@tabler/icons-react'
import { regCustomerRole } from '../../api'
import WordTranslationComponent from '../../utils/language_pack/words'
import { ContactRole } from '../../interface/Icoustomer'
import { HintInfo } from '../../utils/function'

interface RolesProps {
  roles: ContactRole[]
  callBack: (value: boolean) => void
  onValueEdit: (value: ContactRole) => void
  onValueDel: (id: string) => void
}

export default function TableUniversal({ roles, onValueEdit, callBack }: RolesProps) {
  // Get translation public configuration information
  const word_translation = WordTranslationComponent()
  // Get the information to be modified and pass to the parent -level component
  const editCustomer = (value: object) => {
    const editData = value as ContactRole
    onValueEdit(editData)
  }

  /**
   * Handles the delete action for a contact role.
   * Opens a confirmation modal with role details and deletes the contact role upon confirmation.
   * @param {ContactRole} row - The contact role to be deleted.
   */
  const delHandle = (row: ContactRole) => {
    modals.openConfirmModal({
      title: word_translation.delete_title,
      centered: true,
      children: (
        <Text size="sm" mt={12}>
          <p>
            {word_translation.customer_contact_role_name} : {row.role_name}
          </p>
          <p>
            {word_translation.customer_contact_role_desc} : {row.role_desc}
          </p>
        </Text>
      ),
      labels: {
        confirm: word_translation.confirm_delete,
        cancel: word_translation.cancel_delete
      },
      confirmProps: { color: 'red' },
      onConfirm: async () => {
        // Delete the contact role by sending a DELETE request
        const response = await regCustomerRole({ id: row.id_customer_contact_person_role }, 'DELETE')
        const result = response.data

        // Display information based on the response
        HintInfo(response.data)

        if (result.code === 200) {
          // If deletion is successful, invoke the callback
          callBack(true)
        }
      }
    })
  }

  const rows =
    roles.length > 0 ? (
      roles.map((item, index) => (
        <tr key={item.id_customer_contact_person_role} className="border-bt-2 my-10 ">
          <td>
            <Text fz="sm">{item.id_customer_contact_person_role} </Text>
          </td>
          <td>
            <Text fz="sm">{item.role_name} </Text>{' '}
          </td>
          <td>
            <Text fz="sm">{item.role_desc}</Text>{' '}
          </td>
          <td>
            <Group position="center">
              <ActionIcon onClick={() => editCustomer(item)}>
                <IconPencil size="1rem" stroke={1.5} />
              </ActionIcon>
              <ActionIcon onClick={() => delHandle(item)}>
                <IconTrash size="1rem" stroke={1.5} color="red" />
              </ActionIcon>
            </Group>
          </td>
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan={6}>{word_translation.no_data}</td>
      </tr>
    )
  return (
    <table className="table_custome text-center">
      <thead className="table_th">
        <tr>
          <th>{word_translation.customer_contact_role_id}</th>
          <th>{word_translation.customer_contact_role_name}</th>
          <th>{word_translation.customer_contact_role_desc}</th>
          <th>{word_translation.option}</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  )
}
