import {
  Box,
  Checkbox,
  Flex,
  Select,
  Paper,
  Table,
  ScrollArea,
  Button,
  Grid,
  Radio,
  Group,
  Badge,
  MultiSelect,
  TextInput,
  Text,
  Dialog,
  Modal,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import {
  IconBrackets,
  IconBrandCodesandbox,
  IconBrandYatse,
  IconCalendarStats,
  IconClipboardText,
  IconColorPicker,
  IconLetterW,
  IconLoader,
  IconLoader2,
  IconMail,
  IconPlus,
  IconUserPlus,
} from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import SearchBox from "../../../ttcomponents/SearchBox/SearchBox";
import Formal from "./formal";
import CreateReport from "./create_report";
import Calculations from "./calculations";
import globalCortSetting from "../../../utils/globalCortSetting";
import { useDisclosure } from "@mantine/hooks";
import { SelectRole } from "../../../interface/Icommon";
import {
  IFormMal,
  TeamReport,
  TimeTrackerComponentProp,
  TimeTrackerResponseCustomer,
} from "../../../interface/Ireports";
import {
  regGeReport,
  regReporFormal,
  regReport,
  regUserAll,
} from "../../../api";
import { isValidEmail } from "../../../utils/validataUnits";
import { ResponseUserData, UserAllList } from "../../../interface/Iuser";
import {
  ClientWarningHint,
  HintInfo,
  JudgmentTimeThreshold,
  formatDateUpgraded,
} from "../../../utils/function";
import ReportTemplate from "../template/ReportTemplate";
import ReportTemplateTwo from "../template/ReportTemplateTwo";
import { filterReportData } from "../../../interface/IfilterStatistic";
import { modals } from "@mantine/modals";
import localstorageUnits from "../../../utils/localstorageUnits";
import ReportTemplateThree from "../template/ReportTemplateThree";
import ReportTemplateFour from "../template/ReportTemplateFour";
import "../css/reports_custom.css";
import { Save } from "../svg/Save";
import { Generate } from "../svg/Generate";
import PageTitle from "../../../ttcomponents/PageTitle/PageTitle";
import ReportTemplateFive from "../template/ReportTemplateFive";
import { ScikTimeReport } from "../../../interface/IReport";
import GlobalCoreSetting from "../../../utils/globalCortSetting";
const data = [
  { value: "Markus@163.com", label: "Markus" },
  { value: "haiyang@163.com", label: "@163.com" },
  { value: "svelte@qq.com", label: "svelte@qq.com" },
  { value: "vue", label: "Vue" },
  { value: "riot", label: "Riot" },
  { value: "next", label: "Next.js" },
  { value: "123", label: "ds.js" },
  { value: "123", label: "nif.js" },
  { value: "12324", label: "52sad.js" },
];

interface DataRows {
  week_number_list: any[];
  items_list: any[];
  [key: string]: any;
}

interface IFormal {
  dataLable: string;
  dataTypeValue: string;
  dataValue: string;
  datacolor: string;
}

// Task Mode Flag
const taskModeFlag = localstorageUnits.getSystem().task_mode_flag;

export default function ReportCustom() {
  // Use the useIntl hook to access the intl object
  const intl = useIntl();

   // Get the default unchanged configuration
   const globalCoreSetting = GlobalCoreSetting();
  // Define the type in the date selection as the constant of the time range
  const [range_value, setRangeValue] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  //Declaration of all information
  const [userList, setUserList] = useState<SelectRole[]>([]);
  const [opened, { open: emailenterOpen, close: emailenteclose }] =
    useDisclosure(false);
  const [valueDate, setValueDate] = useState<Date | null>(null);

  const [FormMals, SetFormMal] = useState<IFormMal[]>([]);
  const [valueSentEmail, setvalueSentEmail] = useState("");
  // 设置报表名字
  const [reportName, setReportName] = useState("");
  // 设置报表时间
  const [reportDate, SetReportDate] = useState<string[]>([]);
  // 设置报表formal
  const [reportFormale, SetReportFormal] = useState<string[]>([]);
  // 设置datetype
  const [reportDatetype, setReportDateType] = useState("");

  const [reportDateYear, setReportDateYear] = useState("");

  const [reportDateStart, setReportDateStart] = useState("");

  const [reportDateEnd, setReportDateEnd] = useState("");

  const [report_type, setReportType] = useState("team");

  const [badges, setBadges] = useState<any>();
  const [DateValue, setDateValue] = useState<any[]>([]);

  const [intervalSettings, SetintervalSettings] = useState<string[]>([]);

  const [
    openedTemplate,
    { open: openedTemplateOpen, close: openedTemplateClose },
  ] = useDisclosure(false);

  const [
    openedTemplateTwo,
    { open: openedTemplateTwoOpen, close: openedTemplateTwoClose },
  ] = useDisclosure(false);
  const [
    openedTemplateFour,
    { open: openedTemplateFourOpen, close: openedTemplateFourClose },
  ] = useDisclosure(false);
  const [
    openedTemplateFive,
    { open: openedTemplateFiveOpen, close: openedTemplateFiveClose },
  ] = useDisclosure(false);

  const [
    openedTemplateThree,
    { open: openedTemplateThreeOpen, close: openedTemplateThreeClose },
  ] = useDisclosure(false);

  // Add label badge
  const [openedBadge, { open: openedBadgeAddView, close: closeBadgeAddView }] =
    useDisclosure(false);
  //声disclaimerYouChooseTheUserMailbox
  const [UserMailbox, setUserMailbox] = useState<string | null>(null);
  // Define the plan task time interval
  const [planTimeValue, setPlanTimeValue] = useState("weekly");
  const [keyemailArr, setkeyemailArr] = useState(0);
  // Whether to cover
  const [FormalId, SetFormalId] = useState("");
  // Whether to cover
  const [isCover, SetCover] = useState(false);
  const [emailuser, { open: emailuseropen, close: emailuseropenclose }] =
    useDisclosure(false);

  //Define the state of the display status logic calculation symbol state
  const [logicState, SetLogicState] = useState(false);

  // Selection rules
  const [Calculation, SetCalculation] = useState<SelectRole[]>([]);
  // Selection rules
  const [Templatereport, SetTemplatereport] = useState<SelectRole[]>([]);

  // Select the drop -down collection rules
  const [PullDownEmailArr, SetPullDownEmailArr] = useState<SelectRole[]>([]);

  const [emailArr, SetEmailArr] = useState<string[]>([]);
  const [sickTimeReport, setSickTimeReport] = useState<
    ScikTimeReport | undefined
  >();
  const [nameFormal, SetnameFormal] = useState("");

  const [teamReport, setTeamReport] = useState<TeamReport>();
  // Define formal collection
  const [formalValue, setFormalValue] = useState<any[]>([]);
  const [DataRows, setDatarows] = useState<DataRows | undefined>();
  const [ProjectV2Datarows, setProjectV2Datarows] =
    useState<TimeTrackerResponseCustomer>({
      items_type: "project_v2",
      items_list: [], // Your data here
      week_number_list: [1],
    });

  const [reportData, setReportData] = useState<filterReportData>({
    week_number_list: [],
    items_list: [],
  });

  const [EmailValue, SetEmailValue] = useState("");
  //Define the name constant of the calculator
  const handleEmail = (selectedValue: any) => {
    //Update logState in the onChange event of the <select> component to Falselect> component to False
    SetLogicState(false);
    SetEmailValue(selectedValue.target.value);
  };
  // send request
  const ajaxInit = async () => {
    const resFilter = await regReporFormal({}, "GET");
    const resReporData = await regReport({}, "GET");
    const responseUser = await regUserAll({});
    const resultUser = responseUser.data as ResponseUserData;
    setUserList(
      resultUser.data.map((item: { username: any; email: any }) => ({
        label: item.username,
        value: item.email,
      }))
    );

    const TemplatereporttData = resReporData.data.data.filter(
      (item: { type: string }) => item.type == "0"
    );
    // Use the map function for mapping operation to create the FilteriteMList array
    SetTemplatereport(
      TemplatereporttData.map((item: { name: any; id_report: any }) => {
        return {
          label: item.name,
          value: item.id_report,
        };
      })
    );
    SetCalculation(
      resFilter.data.data.map((item: { formal_name: any; id_formal: any }) => {
        return {
          label: item.formal_name,
          value: item.id_formal,
        };
      })
    );

    SetFormMal(resFilter.data.data);
  };

  // Initialization request
  useEffect(() => {
    ajaxInit();
  }, []);

  // Add the sending mailbox
  const SentEmailAdd = () => {
    const exsits = PullDownEmailArr.find(
      (item) => item.value === valueSentEmail
    );
    if (exsits) {
      ClientWarningHint("Select, please select in the drop -down box");
      return;
    }
    if (isValidEmail(valueSentEmail)) {
      // Check if email is valid
      setkeyemailArr(keyemailArr + 1);
      SetPullDownEmailArr([
        ...PullDownEmailArr,
        { value: valueSentEmail, label: valueSentEmail },
      ]); //Add a new mailbox to the drop -down menu
      SetEmailArr([...emailArr, valueSentEmail]); // Add email to the array
    } else {
      ClientWarningHint("Email format error");
    }
  };

  // Choose the user’s and add the user's mailbox to the sending queue
  const SentEmailSelectUser = () => {
    const user = userList.find((item) => item.value === UserMailbox);
    if (!user) return null; //Or return to other appropriate values, indicate that the user did not find
    const result = {
      label: user.label,
      value: user.value,
    };

    const exsits = PullDownEmailArr.find((item) => item.value === UserMailbox);
    if (exsits) {
      ClientWarningHint("Select, please select in the drop -down box");
      return;
    }
    //Add a new mailbox to the drop -down menu
    SetPullDownEmailArr([...PullDownEmailArr, user as unknown as SelectRole]);
    // Add email to the array
    SetEmailArr([...emailArr, UserMailbox as string]);

    setkeyemailArr(keyemailArr + 1);
  };

  const findOperator = (label: string) => {
    return globalCoreSetting.OperatorStr.find((item) => item === label);
  };

  //  Receive the callback return data, search for content
  const emailOpen = (value: string) => {
    if (value == "email") opened ? emailenteclose() : emailenterOpen();
    if (value == "user") emailuser ? emailuseropenclose() : emailuseropen();
  };

  function FormalHandleFunction(): void {
    ajaxInit();
  }

  const ReceiveSearchBackCallback = async (value: string) => {
    const resFilter = await regReporFormal({ search: value }, "GET");
    SetFormMal(resFilter.data.data);
  };

  const CalculationsCallback = (value: any, type: string) => {
    if (type == "up") {
      SetnameFormal(value.formal_name);
      setBadges(value.formal_value);
      SetFormalId(value.id_formal);
    }
  };
  const badgeViewcallBack = () => {};

  const cover = (type: string, value: string) => {
    modals.openConfirmModal({
      title: "Hint",
      centered: true,
      children: <Text size="sm">{value}</Text>,
      labels: { confirm: "Yes", cancel: "No" },
      confirmProps: { color: "red" },
      onConfirm: async () => {
        await SetCover(true);
        SubmitDataReport(type, "true");
        ReceiveSearchBackCallback("");
      },
    });
  };
  // Request to get a report
  const SubmitDataReport = async (type: string, iscover: string) => {
    //Get the name
    SetReportDate([]);

    let originData: any = {
      name: reportName,
      date_type: reportDatetype,
      type: type,
      flag: taskModeFlag,
      formal_id: reportFormale,
      report_type: report_type,
      sending_interval: planTimeValue,
      is_overwrite: iscover == "true",
      interval_settings: intervalSettings,
      recipients: emailArr,
    };

    switch (reportDatetype) {
      case "week":
        if (parseInt(reportDateStart) > parseInt(reportDateEnd)) {
          ClientWarningHint("The start time must be less than the end time");
          return;
        }
        reportDate.push(reportDateYear, reportDateStart, reportDateEnd);
        const date_value = { date_value: reportDate };
        originData.date_value = reportDate; // Add new attribute to ORIGINDATA object
        break;
      default:
        originData.date_value = DateValue;
        break;
    }

    const resFilter = await regReport(originData, "POST");
    if (resFilter.data.data.length < 1 && resFilter.data.code == 200) {
      ClientWarningHint("No Data,Will not produce reportsot produce reports");
      return;
    } else if (resFilter.data.code == 413) {
      cover(type, resFilter.data.msg);
    } else {
      HintInfo(resFilter.data);
    }

    switch (resFilter.data.data.items_type) {
      case "project":
        setReportData(resFilter.data.data as filterReportData);
        openedTemplateOpen();
        return;
        break;
      case "user":
        setDatarows(resFilter.data.data);
        openedTemplateTwoOpen();
        return;
        break;
      case "team":
        setTeamReport(resFilter.data.data);
        openedTemplateFourOpen();
        return;
        break;
      case "sick_time_week":
        setSickTimeReport(resFilter.data.data);
        openedTemplateFiveOpen();
        return;
        break;
      case "project_v2":
        console.log(resFilter.data.data);
        setProjectV2Datarows(resFilter.data.data);
        if (resFilter.data.data.items_list.length == 0) {
          ClientWarningHint(
            "No Data,Will not produce reportsot produce reports"
          );
          return false;
        }
        openedTemplateThreeOpen();
        return;
        break;
    }
  };

  //Create a report component to return the callback
  const CreateReportCallback = (type: string, value: any) => {
    switch (type) {
      case "name":
        setReportName(value);
        break;
      case "weekly":
        break;
      case "formal":
        SetReportFormal(value);
        break;
      case "year":
        setReportDateYear(value);
        break;
      case "start":
        setReportDateStart(value);
        break;
      case "report_type":
        setReportType(value);
        break;
      case "end":
        setReportDateEnd(value);
        break;
      case "date_value":
        const newBadges = [];
        if (value.length == 2 && value[0] && value[1]) {
          newBadges.push(formatDateUpgraded(value[0], "YYYY-MM-DD"));
          newBadges.push(formatDateUpgraded(value[1], "YYYY-MM-DD"));
          setDateValue(newBadges);
        }

        break;
      case "date_type":
        setReportDateType(value);
        break;
      case "template":
        break;
    }
  };

  return (
    <Paper
      px={20}
      w={"100%"}
      className="scrollable-container-table"
      style={{ position: "relative" }}
      mb={10}
    >
      <PageTitle title="Generate Reports" lang_id="Generate Reports" />
      <Box className="mt-22">
        <Text className="title_font">
          {intl.formatMessage({
            id: "Generate Reports",
            defaultMessage: "Generate Reports",
          })}
        </Text>
      </Box>
      {/* Upper part starts */}
      <Grid columns={20} gutter={18}>
        <Grid.Col span={8}>
          <CreateReport
            callback={CreateReportCallback}
            Templatereport={Templatereport}
            Calculation={Calculation}
          />
        </Grid.Col>
        {/*----------------------------------------------------Left -right segmentation line------------------------------------------------------------ */}
        <Grid.Col span={12}>
          <Formal
            badgeView={badgeViewcallBack}
            name={nameFormal}
            FormalId={FormalId}
            badgesFather={badges}
            callBack={FormalHandleFunction}
          />
        </Grid.Col>
      </Grid>

      {/*The lower part starts */}
      <Grid columns={20} gutter={18}>
        <Grid.Col span={8}>
          <Text className="title_font">
            {intl.formatMessage({
              id: "Create Email Schedule",
              defaultMessage: "Create Email Schedule",
            })}
          </Text>
        </Grid.Col>
        <Grid.Col span={12}>
          <Text className="title_title">
            {intl.formatMessage({
              id: "Search",
              defaultMessage: "Search",
            })}
          </Text>
        </Grid.Col>
      </Grid>
      <Grid columns={20} gutter={18}>
        <Grid.Col span={8}>
          <Box className="border-creates" mih={310} pl={10} pt={10}>
            <Box>
              <Grid columns={20} gutter={18}>
                <Grid.Col span={8}>
                  <Text className="form-title">
                    {intl.formatMessage({
                      id: "Recipients",
                      defaultMessage: "Recipients",
                    })}
                  </Text>
                  <Box className="">
                    <MultiSelect
                      defaultValue={emailArr}
                      key={keyemailArr}
                      data={PullDownEmailArr}
                    />
                  </Box>
                </Grid.Col>
                <Grid.Col mt={10} span={12} style={{ position: "relative" }}>
                  <Text className="form-title-desc mb-6">
                    {intl.formatMessage({
                      id: "Add Recipients By Email Or Employee",
                      defaultMessage: "Add Recipients By Email Or Employee",
                    })}
                  </Text>
                  <Group>
                    <Button
                      size="xs"
                      radius={5}
                      onClick={() => emailOpen("email")}
                      leftIcon={<IconMail />}
                    >
                      {intl.formatMessage({
                        id: "Add Email",
                        defaultMessage: "Add Email",
                      })}
                    </Button>
                    <Button
                      size="xs"
                      radius={5}
                      onClick={() => emailOpen("user")}
                      mx={5}
                      leftIcon={<IconUserPlus size={15} />}
                    >
                      {intl.formatMessage({
                        id: "Add Employee",
                        defaultMessage: "Add Employee",
                      })}
                    </Button>
                  </Group>
                </Grid.Col>
              </Grid>
            </Box>
            <Box mt={10}>
              <Group>
                <Grid columns={20} gutter={18}>
                  <Grid.Col span={8}>
                    <Radio.Group
                      value={planTimeValue}
                      onChange={setPlanTimeValue}
                      name="favoriteFramework"
                      withAsterisk
                    >
                      {globalCoreSetting.ReportTimeinterval.map(
                        (item, index) => {
                          return (
                            <Radio
                              value={item.value}
                              className="mt-5"
                              label={
                                <Text className="single-choice-font">
                                  {intl.formatMessage({
                                    id: item.label,
                                    defaultMessage: item.label,
                                  })}{" "}
                                </Text>
                              }
                            />
                          );
                        }
                      )}
                    </Radio.Group>
                  </Grid.Col>
                  <Grid.Col span={12}>
                    {planTimeValue == "weekly" ? (
                      <>
                        <Checkbox.Group
                          defaultValue={[]}
                          value={intervalSettings}
                          onChange={SetintervalSettings}
                          withAsterisk
                        >
                          <Group>
                            {globalCoreSetting.ReportWeekofDay.map(
                              (item, index) => (
                                <Checkbox
                                  className="mt-5"
                                  value={item.value}
                                  label={
                                    <Text className="single-choice-font">
                                      {intl.formatMessage({
                                        id: item.label,
                                        defaultMessage: item.label,
                                      })}{" "}
                                    </Text>
                                  }
                                />
                              )
                            )}
                          </Group>
                        </Checkbox.Group>
                      </>
                    ) : planTimeValue == "certainday" ? (
                      <DatePicker
                        mt={5}
                        className=" report"
                        value={valueDate}
                        onChange={setValueDate}
                      />
                    ) : (
                      <>
                        <Box sx={{ width: "140px" }}></Box>
                      </>
                    )}
                  </Grid.Col>
                </Grid>
              </Group>
            </Box>
          </Box>
          <Group position="right" className="mt-10">
            <Button
              size="xs"
              color="green"
              onClick={() => SubmitDataReport("0", "false")}
              leftIcon={<IconMail />}
            >
              Save as Template
            </Button>
            <Button
              size="xs"
              mx={5}
              onClick={() => SubmitDataReport("1", "false")}
              leftIcon={<IconClipboardText size={15} />}
            >
              Generate Report
            </Button>
          </Group>
        </Grid.Col>

        <Grid.Col span={12}>
          <Calculations
            callback={CalculationsCallback}
            ReceiveSearchBackCallback={ReceiveSearchBackCallback}
            FormMals={FormMals}
          />
        </Grid.Col>
      </Grid>

      <Dialog
        opened={opened}
        withCloseButton
        onClose={emailenteclose}
        size="lg"
        position={{ bottom: 10, left: 10 }}
        radius="md"
      >
        <Text size="sm" mb="xs" weight={500}>
          <Text className="form-title-desc mb-6">
            {intl.formatMessage({
              id: "Please Input Email",
              defaultMessage: "Please Input Email",
            })}
          </Text>
        </Text>
        <Group align="flex-end">
          <TextInput
            placeholder={intl.formatMessage({
              id: "Please Input Email",
              defaultMessage: "Please Input Email",
            })}
            value={valueSentEmail}
            onChange={(event) => setvalueSentEmail(event.currentTarget.value)}
            size="sm"
          />
          <Button onClick={SentEmailAdd}>
            {intl.formatMessage({
              id: "Save",
              defaultMessage: "Save",
            })}
          </Button>
        </Group>
      </Dialog>
      {/* Select user mailbox*/}
      <Dialog
        opened={emailuser}
        withCloseButton
        onClose={emailuseropenclose}
        size="lg"
        position={{ bottom: 10, left: 10 }}
        radius="md"
      >
        <Text size="sm" mb="xs" weight={500}>
          {intl.formatMessage({
            id: "Choose A Employee",
            defaultMessage: "Choose A Employee",
          })}
        </Text>
        <Group align="flex-end">
          <Select
            required
            labelProps={{ className: "input_title" }}
            placeholder={intl.formatMessage({
              id: "Choose A Employee",
              defaultMessage: "Choose A Employee",
            })}
            onChange={setUserMailbox}
            data={userList}
          />
          <Button onClick={SentEmailSelectUser}>
            {intl.formatMessage({
              id: "Save",
              defaultMessage: "Save",
            })}
          </Button>
        </Group>
      </Dialog>
      <Modal
        opened={openedTemplate}
        onClose={openedTemplateClose}
        title={` Report - ${reportName}`}
        fullScreen
        transitionProps={{ transition: "fade", duration: 200 }}
      >
        {" "}
        <ReportTemplate Data={reportData} />
      </Modal>

      <Modal
        opened={openedTemplateTwo}
        onClose={openedTemplateTwoClose}
        title={` Report - ${reportName}`}
        fullScreen
        transitionProps={{ transition: "fade", duration: 200 }}
      >
        <ReportTemplateTwo Data={DataRows} />
      </Modal>

      <Modal
        opened={openedTemplateThree}
        onClose={openedTemplateThreeClose}
        title={` Report - ${reportName}`}
        fullScreen
        transitionProps={{ transition: "fade", duration: 200 }}
      >
        {" "}
        <ReportTemplateThree Data={ProjectV2Datarows} />
      </Modal>

      <Modal
        opened={openedTemplateFour}
        onClose={openedTemplateFourClose}
        title={`Team Report - ${reportName}`}
        fullScreen
        transitionProps={{ transition: "fade", duration: 200 }}
      >
        <ReportTemplateFour Data={teamReport} />
      </Modal>

      <Modal
        opened={openedTemplateFive}
        onClose={openedTemplateFiveClose}
        title={`Sick Report - ${reportName}`}
        fullScreen
        transitionProps={{ transition: "fade", duration: 200 }}
      >
        <ReportTemplateFive Data={sickTimeReport} />
      </Modal>

      {/* Add custom badge */}
      <Modal
        opened={openedBadge}
        onClose={closeBadgeAddView}
        title={`  Report - ${reportName}`}
        fullScreen
        transitionProps={{ transition: "fade", duration: 200 }}
      >
        <ReportCustom />
      </Modal>
    </Paper>
  );
}
