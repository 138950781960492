import {
  Box,
  Grid,
  Group,
  Text,
  TextInput,
  Checkbox,
  Select,
  Code,
  Textarea,
} from "@mantine/core";

import { FormEvent, useEffect, useRef, useState } from "react";
import { ItaskCategory } from "../../../interface/ItaskCategory";
import { isNotEmpty, useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconX } from "@tabler/icons-react";
import { regTaskCategory } from "../../../api";
import { ITaskCategoryParameter } from "../../../interface/ItaskCategoryParameter";
import SaveBottom from "../../../ttcomponents/Button/SaveBtn";
import TableTextInputTitle from "../../../ttcomponents/TableTextInputTitle/TableTextInputTitle";
import WordTranslationComponent from "../../../utils/language_pack/words";
// Define the interface of the component, specify that the component needs to accept the parameters
interface ItaskCategoryParameterProps {
  taskCategoryInfo: ItaskCategory;
  taskCategoryParameterList: ITaskCategoryParameter[];
  callback: () => void;
  [key: string]: any;
}
export default function TaskCategoryFrom({
  taskCategoryInfo,
  parameterIdValuesFather,
  taskCategoryParameterList,
  callback,
}: ItaskCategoryParameterProps) {

  // Get translation public configuration information
  const word_translation = WordTranslationComponent()
  
  const formRef = useRef<HTMLFormElement>(null);
  // 声明任务类型参数列表存储变量
  // const [taskCategoryParameterList,setTaskCategoryParameterList] = useState<ItaskCategory[]>([])
  //  Set the task type parameter ID collection
  const [parameterIdValues, setParameterIdValues] = useState<string[]>(
    parameterIdValuesFather
  );
  console.log(taskCategoryInfo);
  // 创建表单字段及其验证
  const form = useForm({
    validateInputOnBlur: true,
    initialValues: {
      id: taskCategoryInfo?.id_task_category,
      description: taskCategoryInfo?.description,
      parameter_ids: parameterIdValuesFather,
      identifier: taskCategoryInfo?.identifier,
    },
    // functions will be used to validate values at corresponding key
    validate: {
      identifier:  isNotEmpty(`${word_translation.identifier} ${word_translation.is_not_empty}`),
      parameter_ids:  isNotEmpty(`${word_translation.parameter_matrix_ids} ${word_translation.is_not_empty}`),
      description:  isNotEmpty(`${word_translation.description} ${word_translation.is_not_empty}`),
    },
  });

  //  加载任务类型列表，以及任务类型参数列表
  const ajaxCurrentList = async () => {};
  // 组件渲染完毕后执行一些操作
  useEffect(() => {
    ajaxCurrentList();
  }, []);
  // 设置请求参数

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Stop the formal submission of the form default
    // Verification form
    const valid = await form.validate();
    console.log(form.values);
    let type = "POST";
    if (form.values.parameter_ids.length < 1) {
      notifications.show({
        title: "",
        color: "yellow.7",
        icon: <IconX />,
        message: "please choose parameters",
      });
      return;
    }
    if (!valid.hasErrors) {
      if (form.values.id != "") {
        type = "PUT";
      }
      const response = await regTaskCategory(form.values, type);
      const result = response.data;
      if (result.code === 200) {
        callback();
        notifications.show({
          color: "green",
          icon: <IconCheck />,
          message: result.msg,
        });
      } else {
        console.log(result);
        notifications.show({
          color: "red",
          icon: <IconX />,
          message: result.msg,
        });
      }
    }
  };
  function parameterIds(value: string[]): void {
    setParameterIdValues(value);
    form.setFieldValue("parameter_ids", value);
  }
  return (
    <Box>
      <form onSubmit={handleFormSubmit} ref={formRef}>
       <TableTextInputTitle title={word_translation.identifier} />
        <TextInput
          radius={5}
          {...form.getInputProps("identifier")}
          placeholder={word_translation.identifier}
        />
   
   <TableTextInputTitle title={word_translation.description} />
        <Textarea
          radius={5}
          {...form.getInputProps("description")}
          placeholder={word_translation.description}
        />


        <Checkbox.Group
          mb={30}
          value={parameterIdValues}
          onChange={parameterIds}
        >
        <TableTextInputTitle py={10} title={word_translation.select_matrix_paramerter} />
          <Grid gutter={0}>
            {taskCategoryParameterList.map((item, index) => (
              <Grid.Col mr={12} span={3} key={index}>
                <Checkbox
                  value={item.id_task_category_parameter.toString()}
                  label={
                    <Text
                      className="chekbox_text"
                      style={{ cursor: "pointer" }}
                    >
                      {item.name}
                    </Text>
                  }
                />
              </Grid.Col>
            ))}
          </Grid>
        </Checkbox.Group>

        <Group position="right">
          <SaveBottom formRef={formRef} />
        </Group>
      </form>
      {/* <Code block mt={5}>
        {JSON.stringify(form.values, null, 2)}
      </Code> */}
    </Box>
  );
}
