//

import { Group } from "@mantine/core";
import WordTranslationComponent from "../../../../utils/language_pack/words";
import { PermissionIdentifierInterface } from "../../../../interface/Iemloyee";
import EditIcon from "../../../../ttcomponents/EditIcon/EditIcon";
import DeleteIcon from "../../../../ttcomponents/DeleteIcon/DeleteIcon";


interface ComponentInterface {
  currentItem: PermissionIdentifierInterface;
  editHandle: (value: PermissionIdentifierInterface) => void;
  delHandle: (value: PermissionIdentifierInterface) => void;
}

export default function PermissionIdentifierTableTr({  currentItem,editHandle,delHandle}: ComponentInterface) {

  // Get the information to be modified and pass to the parent -level component
  return (
    <tr  className="border-bt-2 my-10 ">
      <td> {currentItem.id_permission_identifier}</td>
      <td> {currentItem.name}</td>
      <td> {currentItem.identifier}</td>
      <td> {currentItem.costCenter_name}</td>
      <td> {currentItem.created_at}</td>
      <td>
        <Group position="center">
            <EditIcon callBack={() => editHandle(currentItem)}/>
            <DeleteIcon callBack={() => delHandle(currentItem)}/>
        </Group>
      </td>
    </tr>
  )
}