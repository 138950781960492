import {
  Group,
  TextInput,
  LoadingOverlay,
  Text,
  Box,
  Select,
  Textarea,
  Checkbox,
  Code,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { PermissionActionInterface, PermissionIdentifierInterface} from "../../../interface/Iemloyee";
import { hasLength, matches, useForm } from "@mantine/form";
import WordTranslationComponent from "../../../utils/language_pack/words";
import TableTextInputTitle from "../../TableTextInputTitle/TableTextInputTitle";
import UniversalButton from "../../Button/UniversalButton";
import { SelectRole } from "../../../interface/Icommon";
import { requestPermissionAction, requestPermissionIdentifier } from "../../../api/index_v2";
import { HintErrorMantineInfo, HintInfo, HintInfoCatchError, HintInfoError } from "../../../utils/function";
import { requestCorePermissionAction } from "../../../api";
import memoryUtils from "../../../utils/memoryUtils";
import { numberRegex } from "../../../utils/validataUnits";

// If is_load is set to false, the initialization request within the component will not be initiated in advance.
// request  will be controlled by the change in this field when rendering the component. Otherwise, it behaves oppositely.
interface ComponentProps {
  // openFormStatus: boolean;
  closeFunction: () => void;
  costCenterData: SelectRole[];
  actionIdentifierList:SelectRole[];
  submitFunction: (data:any,method:string) => any;
  currentItemInfo?: PermissionActionInterface ;
  successCallback: (value: boolean) => void
}

export default function   PermissionActionForm({closeFunction, actionIdentifierList,submitFunction, costCenterData, successCallback, currentItemInfo }: ComponentProps) {
  
  const user_type = useState<string | null>(memoryUtils.user.user_type);
  // Get translation public configuration information
  const word_translation = WordTranslationComponent();
  // Declare the variable actionIdentifier that stores the user branch center
  const [actionIdentifier, setActionIdentifier] = useState<SelectRole[]>([]);
  // loading Statue
  const [loading, setLoading] = useState(false);
  const form = useForm({
    validateInputOnBlur: true,
    initialValues: {
      title: currentItemInfo? currentItemInfo.title : '',
      id: currentItemInfo? currentItemInfo.id_permission : '',
      costCenter_id: currentItemInfo? currentItemInfo.costCenter_id : '',
      identifier:currentItemInfo? currentItemInfo.identifier : '',
      description: currentItemInfo? currentItemInfo.description : '',
      active:currentItemInfo? currentItemInfo.active : '0',
    },
    validate: {
      identifier: matches(numberRegex, `${word_translation.validata_identifier_1_10_digits}`),
      title: hasLength({ min: 1, max: 255 }, `${word_translation.validata_value_need_1_255_length}`),
      description: hasLength({ min: 1, max: 255 }, `${word_translation.validata_value_need_1_255_length}`),
      
    },
  });

  
  useEffect(() => {

    console.log(actionIdentifierList,'最终落点')
    setActionIdentifier(actionIdentifierList);
  }, [actionIdentifierList]);

  const handleFormSubmit = async () => {
    // Validate the form
    const valid = await form.validate();

    // If there are validation errors, display error information
    if (valid.hasErrors) {
      HintErrorMantineInfo(valid);
      return false;
    }
    const methodType = form.values.id ? "PUT" : "POST";

    try {
      setLoading(true);
      const response = await submitFunction(form.values, methodType);
      const result = response.data;
      // Display information based on the response
      HintInfo(response.data);
      if (result.code === 200) {
        // Reset the form and invoke the callback
        form.reset();
        successCallback(true);
      } else {
        HintInfoError(result.msg)
      }
    } catch (error) {
      HintInfoCatchError();
    } finally {
      setLoading(false);
    }

  };

  return (
    <>
        <LoadingOverlay visible={loading} overlayBlur={2} />
        <Box px={10}>
          <TableTextInputTitle title={word_translation.permissions_title} />
          <TextInput
            required
            placeholder={word_translation.Input}
            {...form.getInputProps("title")}
          />

          <TableTextInputTitle title={word_translation.identifier} />
          <TextInput
           placeholder={word_translation.Input}
            {...form.getInputProps("identifier")}
          />
         {/* <Select
            labelProps={{ className: "input_title" }}
            onChange={(value) => form.setFieldValue("identifier", value as string)}
            placeholder={word_translation.select_cost_center}
            searchable
            creatable={user_type.toString() == '1' ? false: true }
            getCreateLabel={(query) => `+ Create ${query}`}
            onCreate={(query) => {
              const item = { value: query, label: query };
              setActionIdentifier((current) => [...current, item]);
              return item;
            }}
            key={form.values.identifier}
            defaultValue={form.values.identifier}
            data={actionIdentifier}
          /> */}

          <TableTextInputTitle title={word_translation.description} />
          <Textarea
            minRows={4}
            placeholder={word_translation.Input_max_255}
            {...form.getInputProps("description")}
          />
          <Checkbox
            mt={10}
            size="xs"
            label={
              <TableTextInputTitle py={0} title={word_translation.active} />}
            checked={form.values.active == '1' ? true : false}
            onChange={(event) => { form.setFieldValue('active', event.currentTarget.checked ? '1' : '0') }}
          />
          <Group position="apart" mt={5}>
            <UniversalButton btn_bg_color='#868E96' btn_text={word_translation.cancel} btn_size='xs' callBack={()=>{}} />
            <UniversalButton btn_text={form.values.id ?  word_translation.edit : word_translation.add} btn_size='xs' callBack={() => handleFormSubmit()} />
          </Group>
       
      <Code block mt={5}>
        {JSON.stringify(form.values, null, 2)}
      </Code>

        </Box>
    </>
  );
}
