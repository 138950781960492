/**
 * 验证规则
 * validation rules
 */

import { notifications } from "@mantine/notifications";
import { IconX } from "@tabler/icons-react";

// 验证电子邮件地址格式
// Validate email address format
export const isValidEmail = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
};
// 验证账号
// verify account
export const isValidUsername = (username) => {
  const re = /^[A-Za-z0-9_@.]{2,18}$/;
  return re.test(username);
};

// 验证密码是否符合要求
//密码必须包含至少一个数字、一个小写字母、一个大写字母 8-16位 允许有下划线
// Verify that the password meets the requirements
//Password must contain at least one number, one lowercase letter, one uppercase letter, 8-16 digits, underscore is allowed
export const isValidPassword = (password) => {
  // const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,16}$/;
  const re = /^[A-Za-z0-9_@.]{3,18}$/;
  return re.test(password);
};

// 验证确认密码是否与密码匹配
// Verify that the password matches the password
export const isConfirmPasswordMatched = (password, confirmPassword) => {
  return password === confirmPassword;
};

// 验证字符串是否为空
// Verify that the string is empty
export const isNotEmpty = (str) => {
  return str.trim() !== "";
};

// 验证字符串是否是数字
// Verify that the string is a number
export const isNumber = (str) => {
  return !isNaN(str);
};

export const maxLength = (value, length) => {
  if (value.length > length) {
    return `This field cannot exceed ${length} characters`;
  }
};
//判断货币符号
// determine the currency symbol
export const validateCurrencySymbol = (inputString) => {
  const currencySymbols = ["$", "€", "¥", "£", "₽", "₹", "₩", "₺", "₴", "₿"];
  const pattern = new RegExp(`^[${currencySymbols.join("")}]`);
  return pattern.test(inputString);
};

export const validateTimeFormat = (inputString) => {
  const pattern = /^(?!-)[0-9]{1,3}[:：]{1}[0-5]?[0-9]?$/;
  return pattern.test(inputString);
};

/**
 * Validate whether a domain string is legal
 * @param {string} inputString - The domain string to be validated, multiple domains separated by commas
 * @returns {boolean} - Returns a boolean indicating whether the domain string is legal
 */
export const validateDomainString = (inputString) => {
  // Split the domain string into an array using commas
  const domains = inputString.split(",");

  // Iterate over each domain
  for (let i = 0; i < domains.length; i++) {
    // Trim leading and trailing spaces from the domain
    const domain = domains[i].trim();

    // Check if the domain is valid (modify the regex pattern based on your requirements)
    // Define a regular expression to validate the legality of the domain
    const domainRegex = /^([a-zA-Z0-9]+(-[a-zA-Z0-9]+)*\.)+[a-zA-Z]{2,}$/;

    // If the domain is not legal, display an error message and return false
    if (!domainRegex.test(domain)) {
      notifications.show({
        color: "red",
        icon: <IconX />,
        message: `Invalid domain: ${domain}`,
      });
      return false;
    }
  }

  // If all domains are legal, return true
  return true;
};

/** 通用正则表达式集合 */
export const urlRegex =
  /^(https?:\/\/)?((([a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,})|((\d{1,3}\.){3}\d{1,3})|localhost)(:[0-9]+)?(\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=%]*)*$/;
export const phoneRegex = /^[0-9-]+$/;
export const domainsRegex = /^\s*([^,\s]+(\s*,\s*[^,\s]+)*)\s*$/;
export const ipRegex = /^(\d{1,3}\.){3}\d{1,3}$/;
export const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

// 日期时间验证 (ISO 8601格式)
export const dateTimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;

// 小时数无上限
// 小时数无上限
export const timeRegex =
  /^(?:[0-9]|[1-9]|10)\d{0,10}:([0-5][0-9])(?::[0-5][0-9])?$/;

export const numberRegex = /^0*\d{1,10}$/;
