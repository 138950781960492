import { Text, Box, ScrollArea, Table, Divider } from "@mantine/core";
import { filterReportData } from "../../../interface/IfilterStatistic";


interface IReportTemplateOne {
    Data: filterReportData 
}
export default function ReportTemplate({ Data }: IReportTemplateOne) {
    return (
        <ScrollArea mt={20}>
            <Table>
   
                    <thead >
                        <tr key={"index"}>
                            <th>
                                <Text fw={500} size={15} color="dark" > {Data.items_list[0]?.type}/Week</Text>
                            </th>
                            <th>
                                <Text fw={500} size={15} color="dark" > staff</Text>
                            </th>
                            {Data.week_number_list.map((item, index) => {
                                return (
                                    <th key={index}>
                                      <Text fw={500} size={15} color="dark" >    KW {item}</Text>
                                    </th>
                                )
                            })}
                            <th >
                                <Text className="title_text " size={12} color="dark" > ∑</Text>
                            </th>
                            <th >
                            </th>
                        </tr>
                    </thead>
                <tbody>


                {Data.items_list.map((item, index) => {
       return(
          
                <tr key={index} >
                    <td > <Text pt={5} size={15} color="dark" >{item.name}</Text>  </td>
                    <td> {item.timetracker_list.map((timeItem, index) => {
                            return (   <> <Text className="title_text " key={index} pt={5} size={13}  > {timeItem.user_name}</Text>  </>  )
                        })}
                    </td>
                    {Data.week_number_list.map(weekitem => {
                        return (
                            <td>
                                {item.timetracker_list.map((timeItem, index) => {
                                    const isbool = timeItem.week_time[weekitem] !== '00:00';
                                    return (
                                        <>
                                            <Text className="title_text " key={index} pt={5} size={12} color={`${isbool ? 'green' : "dark"}`} > 
                                                {isbool ?<><Text c="#637381" className="fontBold"  key={index} pt={5}   >{timeItem.week_time[weekitem]}</Text></>  : <Text c="#637381" className=""  key={index} pt={5}   >N/A</Text>  }
                                             </Text>


                                            
                                        </>
                                     )
                                })}
                            </td>
                        )
                    })}
                    <td >
                        {item.timetracker_list.map((timeItem, index) => {
                            const isboolTotal = timeItem.week_total_time !== '00:00';
                            return (
                                <>

                                {isboolTotal ?<><Text c="#637381" className="fontBold"  key={index} pt={5}   >{ timeItem.week_total_time}</Text></>  : <Text c="#637381" className=""  key={index} pt={5}   >N/A</Text>  }
                                           

                                </>
                            )
                        })}
                    </td>
                </tr>
            )})}



                 
                </tbody>      
            </Table>
          
        </ScrollArea>
    )
}