import { Button, Card, Table, TextInput, Select, Grid } from "@mantine/core";
import {
  IconCheck,
  IconEdit,
  IconTrack,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import { FormEvent, useEffect, useRef, useState } from "react";

import { useForm } from "@mantine/form";
import { Switch, Group, ActionIcon, Box, Text, Code } from "@mantine/core";
import { randomId } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { regCustomerRole, regCustomerRoleContact } from "../../../api";
import ContactRoleSelect from "./contact_role_select";
import { FormattedMessage, useIntl } from "react-intl";
import SaveBottom from "../../../ttcomponents/Button/SaveBtn";
import { getProjectRoleGeneralFunction } from "../../../api/assignments_request";
import WordTranslationComponent from "../../../utils/language_pack/words";
import SubmitBtn from "../../../ttcomponents/Button/SubmitBtn";

interface Role {
  id_customer_contact_person_role: string;
  role_name: string;
  role_desc: string;
}
interface SelectRole {
  value: string;
  label: string;
}

interface ApiResponse {
  code: number;
  data: {
    data: Role[];
    filtered_count: number;
    total_count: number;
  };
  msg: string;
}

interface CustomerRole {
  role_name: string;
  role_desc: string;
  customer_contact_person_role_id: string;
  customer_id: string;
  contact_name: string;
  contact_email: string;
  contact_tel: string;
  contact_tell: string;
  selection_id: string;
}

interface CustomerData {
  id_customer: string | number;
  company_name: string;
  street: string;
  postalcode: string;
  city: string;
  tel: string;
  website: string;
  email: string;
  customer_roles: CustomerRole[];
}
interface CustomerWithCallback {
  customerData: CustomerData | undefined;
  callBack: (customer_id: string, type: 'editCustomer' | 'addContactName' | 'openCustomer') => void;
}

export default function ContactForm({
  customerData,
  callBack,
}: CustomerWithCallback) {
  // 钩子函数-切换语言
  const intl = useIntl();
  // Get translation public configuration information
  const word_translation = WordTranslationComponent();
  const formRef = useRef<HTMLFormElement>(null);
  // export default function ContactForm({ customerData}: { customerData: CustomerWithCallback | undefined }) {

  // const [searchValue, onSearchChange] = useState('');
  const [selectValues, setSelectValues] = useState<{ [key: string]: string }>(
    {}
  );

  // 使用useState定义data和setData并初始化为Role[]类型
  const [data, setData] = useState<SelectRole[]>([]);
  /**
   *
   * Get user information
   */
  const ajaxCurrentList = async () => {
    const response = await regCustomerRole({}, "GET");
    const result = response.data as ApiResponse;
    console.log(result);
    if (result.code === 200 && !Array.isArray(result.data)) {
      const datas = result.data.data.map((item) => ({
        value: item.id_customer_contact_person_role.toString(),
        label: item.role_name,
      }));
      console.log(datas);
      setData(datas);
    } else {
      console.log(result.msg);
    }
  };
  /**
   * get project user role
   */
  const getProjectUserRole = async ()=> {
    setData(await getProjectRoleGeneralFunction(''))
  }
  useEffect(() => {
    getProjectUserRole();
  }, []);

  const form = useForm({
    initialValues: {
      contact_data: [
        {
          customer_id: customerData?.id_customer,
          contact_name: "",
          contact_tel: "",
          contact_email: "",
          customer_contact_person_role_id: "",
          key: randomId(),
        },
      ],
    },
    validate: {
      contact_data: {
        contact_name: (value) =>
          value.length < 2
            ? "Contact Name should have at least 2 letters"
            : null,

        contact_email: (value) => {
          if (!/^\S+@\S+$/.test(value)) {
            return "Incorrect email format";
          }
        },
        contact_tel: (value) =>
          value.length > 20 ? "Contact Tel No more than 20 characters" : null,
      },
    },
  });

  const handleFormSubmit = async () => {
    // Verification form
    const valid = await form.validate();
    console.log(form.values);
    if (!valid.hasErrors) {
      const response = await regCustomerRoleContact(form.values, "POST");
      const result = response.data;
      if (result.code === 200) {
        callBack(customerData?.id_customer as string, 'addContactName');
        notifications.show({
          color: "green",
          icon: <IconCheck />,
          message: result.msg,
        });
      } else {
        //Login failed
        console.log(result);
        notifications.show({
          color: "red",
          icon: <IconX />,
          message: result.msg,
        });
      }
    }
  };
  const onSearchChangeHandle = (value: number) => {
   
  };
  const fields = form.values.contact_data.map((item, index) => (
    <Group key={item.key} mt="xs" position="apart" >
      <Group >
        <TextInput
          placeholder={word_translation.Input}
          label={word_translation.contact_name}
          {...form.getInputProps(`contact_data.${index}.contact_name`)}
        />
      </Group>

      <Group >
        <TextInput
          label={word_translation.email}
          placeholder={word_translation.Input}
          width={150}
          {...form.getInputProps(`contact_data.${index}.contact_email`)}
        />
      </Group>
      <Group >
        <TextInput
          label={word_translation.phone}
          placeholder={word_translation.Input}
          width={150}
          {...form.getInputProps(`contact_data.${index}.contact_tel`)}
        />
      </Group>
      <Group >
        <Group>
          <Select
            zIndex={999}
            label={word_translation.select_customer_roles}
            placeholder={word_translation.pick_one}
            searchable
            w={200}
            searchValue={selectValues[index]}
            nothingFound={word_translation.no_data}
            onChange={(value) =>
              form.setFieldValue(
                `contact_data.${index}.customer_contact_person_role_id`,
                value
              )
            }
            data={data}
          />
        </Group>
      </Group>
      <Group mt={30}>
        <ActionIcon
          sx={{ flex: 1 }}
          color="red"
          onClick={() => form.removeListItem("contact_data", index)}
        >
          <IconTrash size="1rem" />
        </ActionIcon>
      </Group>
    </Group>
  ));

  return (
    <form style={{ zIndex: 300, minHeight: '300px'}} >
      <Box>
        
        {fields}
        <Group position="right" mt="md">
          <Button
            variant="outline"
            size="xs"
            fw={200}
            onClick={() =>
              form.insertListItem("contact_data", {
                customer_id: customerData?.id_customer,
                contact_name: "",
                contact_tel: "",
                contact_email: "",
                customer_contact_person_role_id: "",
                key: randomId(),
              })
            }
          >
            {word_translation.add_contact_user}
          </Button>
          <SubmitBtn btn_size="xs" callBack={handleFormSubmit} />
        </Group>
      </Box>
    </form>
  );
}
