import { FC, useEffect, useState } from 'react'
import { ILog } from '../../../../interface/Ilog'
import { regLogDetail } from '../../../../api'
import { HintInfoCatchError } from '../../../../utils/function'
import { Box, Group, LoadingOverlay, Modal, ScrollArea, Table, Text } from '@mantine/core'
import WordTranslationComponent from '../../../../utils/language_pack/words'
import s from './index.module.css'
import { useDisclosure } from '@mantine/hooks'

type TransformedLogDetail = Pick<
  ILog,
  'module_name' | 'table_name' | 'changed_at' | 'changed_by' | 'row_id' | 'sequence_number'
> & {
  columns: Pick<ILog, 'column_name' | 'old_value' | 'new_value'>[]
}

function transformData(sourceData: ILog[]): TransformedLogDetail[] {
  return sourceData.reduce((allValue: any, curValue) => {
    const {
      module_name,
      table_name,
      changed_at,
      changed_by,
      row_id,
      column_name,
      old_value,
      new_value,
      sequence_number
    } = curValue

    const findElement = allValue.find((item: any) => item.sequence_number === curValue.sequence_number)
    // if findElement is undefined
    if (findElement === undefined) {
      allValue.push({
        module_name,
        table_name,
        changed_at,
        changed_by,
        row_id,
        sequence_number,
        columns: [
          {
            column_name,
            old_value,
            new_value
          }
        ]
      })
    } else {
      // if elements exist
      findElement.columns.push({
        column_name,
        old_value,
        new_value
      })
    }
    return allValue
  }, [])
}

const LogDetail: FC<{ row: ILog; isShow: boolean; onClose: () => void }> = ({ row, isShow, onClose }) => {
  // get translation public configuration infomation
  const word_translation = WordTranslationComponent()
  const [visible, { close: closeLoading, open: openLoading }] = useDisclosure(false)

  const [logDetails, setLogDetails] = useState<TransformedLogDetail[]>([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        openLoading()
        const response = await regLogDetail({
          sequence_number: row.sequence_number,
          table_name: row.table_name,
          module_name: row.module_name,
          desc: row.desc
        })
        closeLoading()
        const result = response.data
        // if the response is unsuccessful
        if (result.code !== 200 || !Array.isArray(result.data)) {
          HintInfoCatchError()
        }
        const transformedData = transformData(result.data[0])
        setLogDetails(transformedData)
      } catch (error) {
        HintInfoCatchError()
      }
    }
    if (isShow) {
      fetchData()
    }
  }, [isShow])

  return (
    <Modal opened={isShow} onClose={onClose} title={word_translation.log_details} centered size="56%" radius={10}>
      <ScrollArea mah={700}>
        {logDetails.length > 0 &&
          logDetails.map(logDetail => (
            <Box key={logDetail.sequence_number} m={10} p={15} className="border-radius-xs border-custom px-16">
              <LoadingOverlay visible={visible} overlayBlur={2} />
              <Group mb={8} align={'center'} grow={true} spacing={'xs'} noWrap={true} style={{ textAlign: 'center' }}>
                {(['module_name', 'table_name', 'changed_at', 'changed_by', 'row_id'] as const).map(k => (
                  <Box key={k} py={4} className={'border-radius-xs border-custom'}>
                    <Text size="xs" color="#495057" weight="bold">
                      {word_translation[k]}
                    </Text>
                    <Text size="sm">{logDetail[k]}</Text>
                  </Box>
                ))}
              </Group>
              <Table
                className={'text-center'}
                fontSize={'xs'}
                horizontalSpacing="xs"
                verticalSpacing="xs"
                withBorder={true}
              >
                <thead className={`${s.thead}`}>
                  <tr>
                    <th>{word_translation.column_name}</th>
                    <th>{word_translation.old_value}</th>
                    <th>{word_translation.new_value}</th>
                  </tr>
                </thead>
                <tbody>
                  {logDetail.columns.map(column => (
                    <tr key={column.column_name}>
                      <td>{column.column_name}</td>
                      <td>{column.old_value}</td>
                      <td>{column.new_value}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Box>
          ))}
      </ScrollArea>
    </Modal>
  )
}

export default LogDetail
