import {
  Button,
  Card,
  Divider,
  Menu,
  Box,
  Flex,
  Grid,
  Group,
  Select,
  Text,
  TextInput,
  ActionIcon,
  Badge,
} from "@mantine/core";
import {
  IconCheck,
  IconClockRecord,
  IconClockHour7,
  IconPlus,
  IconRefresh,
  IconTrash,
  IconX,
  IconDotsVertical,
} from "@tabler/icons-react";
import { SelectRole } from "../../interface/Icommon";
import { forwardRef, useEffect, useRef, useState } from "react";
import { ApiOrderResponse, IOrderProps } from "../../interface/Iorder";
import {
  regOrderList,
  regTaskCategory,
  regTaskList,
  regTimeTrackRecordUp,
} from "../../api";
import { ITaskResponseNopage } from "../../interface/Itask";
import { notifications } from "@mantine/notifications";
import { ClientWarningHint, HintInfo, TimeHHSS, getWeekInfo } from "../../utils/function";
import localstorageUnits from "../../utils/localstorageUnits";
import { useLocation, useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import IconCardTitle from "../../ttcomponents/IconCardTitle/TableTextInputTitle";
import WordTranslationComponent from "../../utils/language_pack/words";
import { getAllTaskCategoryGeneralFunction, getOrderListGeneralFunction, getProjectListGeneralFunction, getTaskListGeneralFunction } from "../../api/assignments_request";
import TableTextInputTitleDesc from "../../ttcomponents/TableTextInputTitleDesc/TableTextInputTitleDesc";
import TableTextInputTitle from "../../ttcomponents/TableTextInputTitle/TableTextInputTitle";
import ResetBtn from "../../ttcomponents/Button/ResetBtn";
import CreateBtn from "../../ttcomponents/Button/CreateBtn";
import UniversalButton from "../../ttcomponents/Button/UniversalButton";
import { requestTimeTrackerQuickLyAdd } from "../../api/index_v2";
import { getSystemAssignmentsFunction } from "../../api/core_request";
import GlobalCoreSetting from "../../utils/globalCortSetting";
import CheckPermissionTools from "../../utils/permission";
interface TimeAddProps {
  projectData: SelectRole[];
  userTaskList: SelectRole[];

}
export default function TimeAdd({ projectData,userTaskList }: TimeAddProps) {
  // get Task Mode Flag
  const taskModeFlag = localstorageUnits.getSystem().task_mode_flag;
  const Perm = CheckPermissionTools()
  // Get translation public configuration information
  const word_translation = WordTranslationComponent();
  // Hook function-switch language
  const intl = useIntl();
  // Navigation of the upper right corner ...
  const navigate = useNavigate();
  const location = useLocation();
  const [activeIndex, setActiveIndex] = useState(" "); // The activation status of the management menu item
  const [subActiveIndex, setSubActiveIndex] = useState(" "); // The activation status of the management sub -menu item
  const [initiallyOpeneds, setInitiallyOpeneds] = useState("");
  const [isSelectOrderStr, setIsSelectOrderStr] = useState("choose_a_order");
  const [isSelectOrder, setIsSelectOrder] = useState(true);
  const [isSelectTaskStr, setIsSelectTaskStr] = useState("choose_a_task");
  const [isSelectTask, setIsSelectTask] = useState(true);

  // 定义选择的订单ID
  const [searchOrderIdValue, onSearchOrderIdChange] = useState<string | null>(
    null
  );
  // Define the selected taskid
  const [searchTaskIdValue, onSearchTaskIdChange] = useState<string | null>(
    null
  );
  // 定义输入的时间
  const [timeInput, setTimeInput] = useState<string>("");
  const [projectSelectValue, setProjectSelectValue] = useState<number>(0);
  const [orderSelectValue, setOrderSelectValue] = useState<number>(0);
  const [taskSelectValue, setTaskSelectValue] = useState<number>(0);
  const [isRefresh, setIsRefresh] = useState<Boolean>(true);
  // Define the type filling in
  const [category, setCategory] = useState<string | null>(null);
  // 定义当前年份
  const [currentYear, setCurrentYear] = useState(0);
  // 定义当前周
  const [currentWeek, setCurrentWeek] = useState(0);
  // Define the current week
  const [currentDayOfWeek, setCurrentDayOfWeek] = useState("");

  //Define What type of project is_clude_order or with order
  const [isOrderDisable, setIsOrderDisable] = useState(false);

  const [randNumber, setRandNumber] = useState<number>(1); 
  const [ProjecOrderListInfo, setProjecOrderListInfo] = useState<
    IOrderProps[] | undefined
  >(undefined);
  //Define the orderlist below the selected item
  const [ordernamelist, setOrderNameList] = useState<SelectRole[]>([]);

  // Define the selected project below the orderList below the tasklist
  const [orderTasklist, setOrderTaskList] = useState<SelectRole[]>([]);
  //  Reset data
  const InitData = () => {
    // setUserTaskList([]);
    setProjectList([]);
    setOrderList([]);
    setProjectId("");
    setTimeInput("");
    setOrderId("");
    setTaskId("");
    setRandNumber(randNumber+1)
  };


  // Define all the project lists that can be selected
  const [projectList, setProjectList] = useState<SelectRole[]>([]);
  
  // Define all the order lists that can be selected
  const [orderList, setOrderList] = useState<SelectRole[]>([]);

  // const [userTaskList, setUserTaskList] = useState<SelectRole[]>([]);

  const [projectId, setProjectId] = useState<string | null>(null); 

  const [orderId, setOrderId] = useState<string | null>(null);
  const [taskId, setTaskId] = useState<string | null>(null); 
  const [isShowOrder, setIsShowOrder] = useState<boolean>(false); 
  const [projectNo, setProjectNo] = useState<string>(''); 


  const getCurrentAssigment= async ()=>{
    let assignmentModals = await getSystemAssignmentsFunction();
    const assignmentModalsArray = assignmentModals.split(',');
    if (!assignmentModalsArray.some((item: string) => ['2', '1', '4', '5'].includes(item))) {
      setIsShowOrder(false);
    } else {
      setIsShowOrder(true);
    }
    
  }
  const requestProjectListByUserId = async() =>{
    setProjectList(await getProjectListGeneralFunction(""));
    setRandNumber(randNumber+1)
}

  //Obtain all taskids under the order according to Orderid
  const selectOrderList = async (order_id: string | null) => {
    setTaskSelectValue(taskSelectValue + 1);
    setOrderTaskList([]);
    onSearchOrderIdChange(order_id);
    // Get all the tasks under the order
    const TaskresponseUser = await regTaskList({ order_id: order_id });
    const resultTask = TaskresponseUser.data as ITaskResponseNopage;
    // The form format that can be identified as a component
    const OrdeTaskData = resultTask.data.map((item) => ({
      value: item.id_task as string,
      label: item.task_category.identifier
        ? item.task_category.identifier
        : intl.formatMessage({
            id: "unknown_task_",
            defaultMessage: "unknown_task_",
          }) + item.id_task,
    }));
    setOrderTaskList(OrdeTaskData);
  };
  // Submit the fast upload Timetrack record
  const createTimeTrackRecode = async () => {
    if (!timeInput) {
      ClientWarningHint(`${word_translation.time} ${word_translation.is_not_empty}`)
      return;
    }
    const originalData = {
      project_id: projectId,
      order_id: orderId,
      task_category_id: taskId,
      time: timeInput,
      day_of_week: currentDayOfWeek,
      week: currentWeek,
      year: currentYear,
    };
    const response = await requestTimeTrackerQuickLyAdd(originalData, "POST");
    const result = response.data;
    HintInfo(result);
    if (result.code === 200) {
      InitData();
    } 
  };
  // 获取当前的星期和年
  const getCurrentWeekNumber = () => {
    const { year, week, dayOfWeek } = getWeekInfo(new Date());
    setCurrentWeek(week);
    setCurrentYear(year);
    setCurrentDayOfWeek(dayOfWeek);
  };
  
   // Execute the useeffect when the first load is loaded.
   const isInitialMount = useRef(true);
  // 页面加载后渲染数据得出当前年和星期
  useEffect(() => {
    getCurrentAssigment();
    getCurrentWeekNumber();
  }, []);

  useEffect(() => {
    if (ordernamelist.length == 0) {
      setIsSelectOrder(true);
      setIsSelectOrderStr(
        intl.formatMessage({
          id: "No options",
          defaultMessage: "No options",
        })
      );
      setIsSelectTask(true);
      onSearchTaskIdChange("");
      setTaskSelectValue(taskSelectValue + 1);
      setOrderSelectValue(orderSelectValue + 1);
    } else if (ordernamelist.length == 1) {
      setIsSelectOrderStr(
        intl.formatMessage({
          id: "choose_a_order",
          defaultMessage: "choose_a_order",
        })
      );
      console.log(String(ordernamelist[0]["value"]));
      onSearchOrderIdChange(String(ordernamelist[0]["value"]));
      selectOrderList(String(ordernamelist[0]["value"]));
      setIsSelectOrder(false);
      setOrderSelectValue(orderSelectValue + 1);
    } else {
      setIsSelectOrderStr(
        intl.formatMessage({
          id: "choose_a_order",
          defaultMessage: "choose_a_order",
        })
      );
      setOrderSelectValue(orderSelectValue + 1);
      setTaskSelectValue(taskSelectValue + 1);
      setIsSelectOrder(false);
    }
  }, [ordernamelist]);
  



  useEffect(() => {
    if (!isInitialMount.current) {
    //  getTaskList({'order_id':orderId,})
     setTaskId('')
     setRandNumber(randNumber+1)
    }
    isInitialMount.current = false;
  }, [orderId]);

  useEffect(() => {
    if (!isInitialMount.current) {
      getOrderList({' ':projectId,})
      // getTaskList({'project_id':projectId,'order_id':0,})
      setOrderId('')
      setTaskId('')
      setRandNumber(randNumber+1)
    }

    const selectedProject = projectList.find(item => item.value === projectId);
    if (selectedProject) {
      setProjectNo(selectedProject.project_no)
    } else {
      setProjectNo('-')
    }
    
    isInitialMount.current = false;
  }, [projectId]);

  const getOrderList = async (value: any) => {
    setOrderList(await getOrderListGeneralFunction(value))
  };



  useEffect(() => {
    if (orderTasklist.length == 0) {
      setIsSelectTask(true);
      setIsSelectTaskStr(
        intl.formatMessage({
          id: "No options",
          defaultMessage: "No options",
        })
      );
      setTaskSelectValue(taskSelectValue + 1);
    } else if (orderTasklist.length == 1) {
      setIsSelectTaskStr(
        intl.formatMessage({
          id: "choose_a_task",
          defaultMessage: "choose_a_task",
        })
      );
      onSearchTaskIdChange(String(orderTasklist[0]["value"]));
      setTaskSelectValue(taskSelectValue + 1);
      setIsSelectTask(false);
    } else {
      setIsSelectTaskStr(
        intl.formatMessage({
          id: "choose_a_task",
          defaultMessage: "choose_a_task",
        })
      );
      setTaskSelectValue(taskSelectValue + 1);
      setIsSelectTask(false);
    }
  }, [orderTasklist]);

  
  // 导航跳转
  interface InavItem {
    label: string;
    icon: React.FC<any>;
    [key: string]: any;
  }
  const handleSubMenuClick = (navItem: InavItem) => {
    navigate(navItem.link);
    localstorageUnits.savePath({ currentUrl: navItem.label });

    let storagePath = localstorageUnits.getPath();
    if (storagePath && Object.keys(storagePath).length > 0) {
      storagePath.currentActiveIndex
        ? setActiveIndex && setActiveIndex(storagePath.currentActiveIndex)
        : setActiveIndex && setActiveIndex(storagePath.currentPreActiveIndex);
      if (storagePath.currentChildrenActivePath) {
        storagePath.currentChildrenActivePath.label
          ? setSubActiveIndex &&
            setSubActiveIndex(storagePath.currentChildrenActivePath.label)
          : setSubActiveIndex &&
            setSubActiveIndex(storagePath.currentPreChildrenActivePath.label);
      }
      setInitiallyOpeneds(
        storagePath.initiallyOpeneds ? storagePath.initiallyOpeneds : []
      );
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = TimeHHSS(event);
    if (event.target.value) {
      setTimeInput(value);
    }
  };

  const handleBlurTimeInput = (value: string) => {
    setTimeInput(value);
  };
  const refreshLiveTracking = () => {
    setIsRefresh(false);
    setTimeout(function () {
      setIsRefresh(true);
    }, 400);
  };

    // ***************************************************/
  // ***************************************************/
  // ***************************************************/
  interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
    image: string;
    label: string;
    project_no: string;
  }
  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ image, label, project_no, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          <div>
            <Text size="sm">{label}</Text>
            <Text size="xs" opacity={0.65}>
              {project_no}
            </Text>
          </div>
        </Group>
      </div>
    )
  );

  return (
    <>
      {isRefresh ? (
        <Card px={0} withBorder mih={310} radius="md" shadow="sm">
          <Grid>
            <Grid.Col span={9}>
               <IconCardTitle icon_l={<IconClockRecord color="#989898" size="1.2rem" />} title={word_translation.quick_time_add} />
            </Grid.Col>
            <Grid.Col span="auto">
              <Group
                spacing={"xs"}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginRight: "0.1rem",
                }}
              >
                <IconRefresh
                  color="#989898"
                  onClick={refreshLiveTracking}
                  size="1rem"
                />
                <Menu
                  width={120}
                  position="bottom-end"
                  transitionProps={{ transition: "pop-top-right" }}
                  // onClose={() => setUserMenuOpened(false)}
                  // onOpen={() => setUserMenuOpened(true)}
                  withinPortal
                  shadow="md"
                >
                  <Menu.Target>
                    <IconDotsVertical color="#989898" size="1rem" />
                  </Menu.Target>
                  <Menu.Dropdown>
                    <Menu.Item
                      onClick={() =>
                        handleSubMenuClick({
                          label: "TimeTrack",
                          icon: IconClockHour7,
                          link: "/timetracker",
                        })
                      }
                      className="mantine-Menu-itemIcon-mr0"
                      icon={<IconClockHour7 size="0.8rem" />}
                    >
                      <Text mx={4} size="xs">
                        <FormattedMessage
                          id="timetrack"
                          defaultMessage="TimeTracker"
                        />
                      </Text>
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              </Group>
            </Grid.Col>
          </Grid>
          <Divider my="sm" />
          <Grid px="1rem">
            <Grid.Col span={6}>
              <TableTextInputTitle title={word_translation.project_name} />
              <TableTextInputTitleDesc title={word_translation.select_project} />
              <Select 
                  size='xs' 
                  searchable 
                  maxDropdownHeight={160}
                  className='time_sheet_home'
                  key={projectId}
                  placeholder={word_translation.pick_one}
                  rightSection={<ActionIcon size='xs' onClick={()=>setProjectId('')}> <IconX /></ActionIcon>}
                  onChange={setProjectId }
                  value={projectId}
                  itemComponent={SelectItem}
                  onClick={requestProjectListByUserId}
                  data={projectList} 
                  filter={(value, item) =>{
                    return  item.label?.toLowerCase()?.includes(value.toLowerCase()?.trim())  ||item.project_no?.toLowerCase()?.includes(value.toLowerCase()?.trim()) 
                  }}
                  />

{isShowOrder &&  <> <TableTextInputTitle title={word_translation.order_name} />
                  <TableTextInputTitleDesc title={word_translation.pick_one} />
                  <Select
                    size="xs"
                    required
                    maxDropdownHeight={140}
                    onChange={setOrderId}
                    defaultValue={orderId}
                    placeholder={word_translation.pick_one}
                    // disabled={orderList.length > 0 ? false:true}
                    key={randNumber}
                    data={orderList}
                  /></>}  
                <TableTextInputTitle title={word_translation.task_name} />
                <TableTextInputTitleDesc title={word_translation.select_task} />
                <Select
                  size="xs"
                  required
                  // disabled={userTaskList.length > 0 ? false:true}
                  dropdownPosition="top"
                  defaultValue={taskId}
                  className='time_sheet_home'
                  onChange={setTaskId}
                  maxDropdownHeight={150}
                  placeholder={word_translation.pick_one}
                  key={randNumber}
                  data={userTaskList}
                />
             
            </Grid.Col>
            <Grid.Col span={6}>       
           
             <> <TableTextInputTitle py={17} title='Project No.' /> 
                         <TextInput
                        size="xs"
                        value={projectNo}
                        disabled
                  
                />
                  </>
           
                 <TableTextInputTitle title={word_translation.time} />
                  <TableTextInputTitleDesc title={word_translation.pick_one} />
                <TextInput
                  size="xs"
                  value={timeInput}
                  onBlur={handleBlur}
                  onChange={(event) =>
                    handleBlurTimeInput(event.currentTarget.value)
                  }
                  placeholder={word_translation.Input}
                />

           <Group  position="right"  px="1rem" mt={40}>
             <Box> 
              <Box>
                <ResetBtn callBack={InitData}/>
              </Box>
              <Box mt={10}>
                <UniversalButton 
                
                disabled={Perm.time_tracker_create == false}
                btn_icon={<IconPlus size="1rem" stroke={1.5} />}  btn_text={word_translation.add} callBack={createTimeTrackRecode}/>
              </Box>
            </Box>
          
           </Group>
            </Grid.Col>
            
          </Grid>
        </Card>
      ) : (
        ""
      )}
    </>
  );
}
