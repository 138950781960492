import { TextInput, Group, Text, Code, Paper, Textarea, Title, Button, Box, Table, ActionIcon, Divider, Badge } from "@mantine/core";
import { FormEvent, useRef, useState } from "react";
import { hasLength, isEmail, isNotEmpty, useForm } from "@mantine/form";
import SaveBottom from "../../../../ttcomponents/Button/SaveBtn";
import CancelButton from "../../../../ttcomponents/Button/CancelBtn";
import { Contact, Supplier } from "../../../../interface/Iinventory";
import { notifications } from "@mantine/notifications";
import { Icon123, IconCheck, IconTextPlus, IconTrash, IconX } from "@tabler/icons-react";
import { Left } from "../../../time_master/svg/l";
import { isValidEmail, isNotEmpty as isNotEmptyCustom, isValidUsername } from "../../../../utils/validataUnits";
import { regGetProduct, regProduct, regProductCategory, regSupplier } from "../../../../api";
import { Product } from "../../../../interface/IProduct";
import SupplierSelect from "../../../../ttcomponents/Supplier/SupplierSelect";
import { ICategory } from "../../../../interface/IProductCategory";
import AppCategory from "./category";
import CategoryAction from "./category_action";
import { FormattedMessage, useIntl } from "react-intl";

interface  CategoryFormProps {
  closeFucntion: (value: any) => void;
  currentValue:ICategory;
}



export default function CategoryIndexFrom({closeFucntion, currentValue}: CategoryFormProps) {
  /**
   * Get a form reference
   */
  const formRef = useRef<HTMLFormElement>(null);
  /**
   *International configuration
   */
  const intl = useIntl();
  /**
   * Create a form instance
   * @param {Object} options - Form options
   * @param {Object} options.initialValues - Initial value: In computer programming
   * @param {Function} options.validate - Validation function
   */
  const form = useForm({
    initialValues: {
      category_id:currentValue.category_id,
      category_name:currentValue.category_name,
      category_pid:currentValue.category_pid  ,
    },
    validate: {
    category_name: isNotEmpty('category_name is required'), // verify product_name Not empty
    },
  });

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Stop the formal submission of the form default
    // Verification form
    const valid = form.validate();
    if (valid.hasErrors) {
      notifications.show({
        color: "yellow.7",
        icon: <IconX />,
        message: Object.values(valid.errors)[0],
      });
      return;
    } 

    let  submit_type = 'POST';
    if(form.values.category_id){
      submit_type = 'PUT'
    }

    const response = await regProductCategory(form.values, submit_type);
    if (response.data.code == 200) {
      closeFucntion(3)
      notifications.show({
        color: 'green',
        icon: <IconCheck />,
        message: response.data.msg,
      })
    } else {
      notifications.show({
        color: "yellow.7",
        icon: <IconX />,
        message: response.data.data.database_returns_results,
      });
    }
  };


  return (
    <Paper p={10}>
         <form onSubmit={handleFormSubmit} ref={formRef}>
            <Text><FormattedMessage id="level" defaultMessage="Level" /> : <Badge>{currentValue.category_name  ?  currentValue.category_name:<FormattedMessage id="top" defaultMessage="Top" />}</Badge></Text>
            <TextInput
              {...form.getInputProps("category_name")}
              placeholder= {intl.formatMessage({  id: "create_product_category_title", defaultMessage: "Add top Category"})}
              label={<FormattedMessage id="product_category_name" defaultMessage="Product category name" />}
              style={{ width: "100%" }}
            />
        <Group position="right">
          <CancelButton callBack={() => closeFucntion(1)} />
          <SaveBottom formRef={formRef} />
        </Group>
        {/** can see form values */}
        <Code block mt={5}>
          {JSON.stringify(form.values, null, 2)}
        </Code>
      </form>
    </Paper>
  );
}
