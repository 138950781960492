import {
  Paper,
  TextInput,
  Box,
  Group,
  Text,
  Modal,
  Grid,
  Table,
  ActionIcon,
  LoadingOverlay,
  Tooltip,
  Flex,
  Badge,
  Dialog,
  Button,
  Textarea,
} from "@mantine/core";
import { FormEvent, useEffect, useState } from "react";
import { Supplier, SuppliersData } from "../../../../interface/Iinventory";
import { useDisclosure } from "@mantine/hooks";

import PageTitle from "../../../../ttcomponents/PageTitle/PageTitle";
import {
  IconArrowBarUp,
  IconCircleKey,
  IconEdit,
  IconLockSquareRounded,
  IconPencil,
  IconRefresh,
  IconSearch,
  IconShoppingBag,
  IconTir,
  IconTrash,
  IconUserPlus,
  IconUsersMinus,
  IconX,
} from "@tabler/icons-react";
import CreateBtn from "../../../../ttcomponents/Button/CreateBtn";
import { useIntl } from "react-intl";
import { useForm } from "@mantine/form";
import { modals } from "@mantine/modals";
import {
  regGetOrderProductList,
  regLockedProduct,
  regProductPurchase,
  regRefreshOrderProduct,
} from "../../../../api";
import { HintInfo, formatDateUpgraded } from "../../../../utils/function";
import SearchBox from "../../../../ttcomponents/SearchBox/SearchBox";
import { IPagination } from "../../../../interface/Ipagintion";
import PaginationA from "../../../../ttcomponents/Pagination/PaginationA";
import { IProductResponse, Product } from "../../../../interface/IProduct";
import { DetailCustome } from "../../../../public/svg/DetailCustome";
import SupplierSelect from "../../../../ttcomponents/Supplier/SupplierSelect";
import { ICategory } from "../../../../interface/IProductCategory";
import {
  Cancellation,
  Purchase,
  Receipt,
} from "../../../../public/svg/ProductOrder";
import {
  IProductProcurementData,
  IProductProcurementOrderData,
} from "../../../../interface/IProductProcurement";
import { notifications } from "@mantine/notifications";
import { IOrderProduct, OrderProductData } from "../../../../interface/Iorder";
import ProductProcurementFrom from "../../purchasing/purchasing_from";
import SaleFrom from "../../out_of_warehouse/sale_out/sale_form";

interface ComponentInterface {
  is_load_str: string;
}
export default function OrderProductDeliveryIndex({
  is_load_str,
}: ComponentInterface) {
  // useIntl for multiple language
  const intl = useIntl();
  // Edit modal status
  const [openedFrom, { open: openFrom, close: closeFrom }] =
    useDisclosure(false);

  // return the goods
  const [saleOpened, { toggle: openSaleOpened, close: closeSaleOpened }] =
    useDisclosure(false);

  // contact modal status
  const [
    ProductProcurementShow,
    { open: openProductProcurementShow, close: colseProductProcurementShow },
  ] = useDisclosure(false);

  const [loading, setLoading] = useState(false);
  const [rowloading, setRowloading] = useState(false);
  // Form type
  const [formType, setFormType] = useState("create");
  const [currentRowInfo, setCurrentRowInfo] = useState<
    IOrderProduct | undefined
  >();
  const [productCategory, setProductCategory] = useState<ICategory[]>([]);
  const [returnNotes, setReturnNotes] = useState("");

  // define the supplier list
  const [orderProductList, setProductProcurementList] =
    useState<OrderProductData>({
      total: 0,
      data: [], // Use the data property from the first item in the mock data array
    });

  const [pagination, setPagination] = useState<IPagination>({
    search: "",
    page: 1,
    pagesize: 10,
    sortBy: "updated_at",
    sortOrder: "desc",
    query: {
      table: {
        key: ["is_internal", "service_charge"],
        value: ["0", "0"],
      },
    },
  });
  // Receive the callback return data, search for content
  const ReceiveSearchBack = (value: string | number) => {
    setPagination((prevpagination) => ({
      ...prevpagination,
      search: value,
      page: 0,
      query: {},
    }));
  };
  const ReceiveSupplierBack = (value: any) => {
    if (value.type == "supplier") {
      setPagination((prevpagination) => {
        const { query, ...rest } = prevpagination; // Extract the attributes other than Query to the REST
        if (value.value == "") {
          // If value.value exists, delete the support
          delete query.supplier_id;
        } else {
          query.supplier_id = value.value;
        }

        return {
          ...rest,
          page: 0, // Keep other attributes in REST
          query: query, // Set the modified query
        };
      });
    }
  };

  //Pagling recovery trigger update Terrament recovery trigger update
  const ReceivePaginationABack = (page: number) => {
    setPagination((prevpagination) => ({
      ...prevpagination,
      page: page,
    }));
  };
  // set search value
  const [search, setSearch] = useState("");

  // Define an asynchronous function ajaxcurrenTlist to send AJAX request to obtain the list of suppliers
  const ajaxCurrentList = async () => {
    if (is_load_str == "delivery") {
      setLoading(true); // Set the loading status to TRUE, indicating that the data is loading
      let searchStr = {};
      // Check whether there are search conditions, if so, build a search parameter object
      if (search) {
        searchStr = {
          search: search,
        };
      }

      // Send GET requests to get supplier data
      const responseOrderProduct = await regGetOrderProductList(
        pagination,
        "GET"
      );

      setLoading(false); // After the data is loaded, set the loading status to false
      if (responseOrderProduct.data.code === 200) {
        setProductProcurementList(responseOrderProduct.data.data); // 如果响应状态码为200，将供应商列表更新为响应数据中的数据
      }
    }
  };
  // Use the USEEFFECT hook. When Search changes, call the AjaxCurrenTList function
  useEffect(() => {
    ajaxCurrentList();
  }, [pagination]);

  useEffect(() => {
    ajaxCurrentList();
  }, [is_load_str]);

  // Edit supplier
  const PurchaseAction = async (row: IOrderProduct) => {
    setFormType("edit");
    openProductProcurementShow();

    const newOrderProcurementDataInit = {
      product_id: row.product_id,
      order_quantity: row.order_quantity,
      supplier_id: row.supplier_id,
    };
    setCurrentRowInfo(newOrderProcurementDataInit);
  };

  const RefreshRecordAction = async (row: IOrderProduct) => {
    const responseOrderProduct = await regRefreshOrderProduct(
      { order_product_id: row.order_product_id },
      "PUT"
    );
    HintInfo(responseOrderProduct.data);
    if (responseOrderProduct.data.code == 200) ajaxCurrentList();
  };

  // Delete supplier
  const handleDel = (row: IOrderProduct) => {
    modals.openConfirmModal({
      title: "Delete this row",
      centered: true,
      children: (
        <Text size="sm" mt={12}>
          Order Name: {row.order_name}
        </Text>
      ),
      labels: {
        confirm: intl.formatMessage({
          id: "confirm_delete",
          defaultMessage: "Confirm Delete",
        }),
        cancel: intl.formatMessage({
          id: "cancel_delete",
          defaultMessage: "Cancel Delete",
        }),
      },
      confirmProps: { color: "red" },
      onConfirm: () => delSupplierInfo(row.order_product_id as string),
    });
  };

  // Delete supplier info to backend
  const delSupplierInfo = async (id: string) => {
    const productResult = await regProductPurchase(
      { order_id: id, confirm_deletion: "no" },
      "DELETE"
    );
    HintInfo(productResult.data);
    if (productResult.data.code == 200) ajaxCurrentList();
  };

  // Create supplier
  const SaleFunction = async (row: IOrderProduct) => {
    setCurrentRowInfo(row);
    openSaleOpened();
  };

  const LockedProduct = async (row: IOrderProduct, locked: number) => {
    const data = {
      order_product_id: row.order_product_id,
      order_quantity: row.order_quantity,
      product_id: row.product_id,
      locked: locked,
    };

    const responseLOckedData = await regLockedProduct(data, "PUT");
    HintInfo(responseLOckedData.data);
    if (responseLOckedData.data.code == 200) ajaxCurrentList();
  };

  const rows = orderProductList.data.map((row) => {
    let flagStr: any, statusStr: any;
    if (row.delivery_status == 1) {
      statusStr = <Badge color="blue"> Not </Badge>;
    } else if (row.delivery_status == 2) {
      statusStr = <Badge color="red"> Partially </Badge>;
    } else {
      statusStr = <Badge color="green">All </Badge>;
    }
    return (
      <tr className="border-bt-2 my-10 ">
        <LoadingOverlay visible={rowloading} overlayBlur={2} />
        <>
          {" "}
          <td>
            {" "}
            {row.product_name} <span className="red-font"> </span>{" "}
          </td>
          <td> {row.order_quantity ? row.order_quantity : "-"} </td>
          {/* <td> {row.order_name ? row.order_name:'-'}</td> */}
          <td> {row.order_no ? row.order_no : "-"}</td>
          {/* <td> {row.supplier_name? row.supplier_name:'-'} </td>*/}
          {/* <td> {row.unit_price ? row.unit_price:'-'}  </td>  */}
          {/* <td>  <span className={row.inventory as number >= row.order_quantity ? 'green-font':'red-font'}>{row.inventory ? row.inventory:'-'}  </span>  </td>  */}
          {/* <td> {row.total_price ? row.total_price:'-'}</td> */}
          <td>{row.delivered_quantitly ? row.delivered_quantitly : "-"} </td>
          <td>
            {" "}
            {row.delivery_schedule
              ? formatDateUpgraded(
                  new Date(row.delivery_schedule),
                  "YYYY-MM-DD"
                )
              : "-"}{" "}
          </td>
          {/* <td> {row.delivered_date ? formatDateUpgraded(new Date(row.delivered_date),'YYYY-MM-DD'):'-'} </td> */}
          {/* <td> {flagStr} </td> */}
          <td> {statusStr} </td>
          <td>
            <Group spacing={0} position="center">
              {/* <ActionIcon onClick={row.delivery_status != 3  ? () => RefreshRecordAction(row) : undefined} >
                                    <Tooltip label={row.delivery_status != 3  ? "You can update the price of this order, and the delivery date" : "This order has been completed"}>
                                        <IconRefresh  size="1.2rem" stroke={1.5} color={row.delivery_status == 3 ?"#cccccc" :"blue" } />
                                    </Tooltip>
                                </ActionIcon> */}

              {/* <ActionIcon onClick={parseFloat(row.locked_quantitly as string) >  0 ? () => LockedProduct(row) : undefined} >
                                    <Tooltip label={parseFloat(row.locked_quantitly as string) >  0 ? "You can update the price of this order, and the delivery date" : "This order has been completed"}>
                                        <IconLockSquareRounded  size="1.2rem" stroke={1.5} color={parseFloat(row.locked_quantitly as string) >  0?"#cccccc" :"blue" } />
                                    </Tooltip>
                                </ActionIcon> */}
              {/* {parseFloat(row.locked_quantitly as string) >  0 
                                 ? <ActionIcon onClick={ () => LockedProduct(row,0) } >
                                 <Tooltip label=''>
                                 <IconCircleKey  size="1.2rem" stroke={1.5}  />
                                 </Tooltip>
                             </ActionIcon>:
                             <ActionIcon onClick={() => LockedProduct(row,1)  } >
                             <Tooltip label= ''>
                                     <IconLockSquareRounded  size="1.2rem" stroke={1.5} />
                             </Tooltip>
                         </ActionIcon>
                             } */}
              <ActionIcon
                onClick={
                  row.delivery_status != 3 ? () => SaleFunction(row) : undefined
                }
              >
                <Tooltip
                  label={
                    row.delivery_status != 3
                      ? "Manual delivery"
                      : "This order has been completed"
                  }
                >
                  <IconArrowBarUp
                    color={row.delivery_status == 3 ? "#cccccc" : "red"}
                    size="1.2rem"
                    stroke={1.5}
                  />
                </Tooltip>
              </ActionIcon>

              {/* <ActionIcon onClick={() => PurchaseAction(row)}>
                                    <Tooltip label= {row.supplier_id != null ?  "You can purchase products  to "+row.supplier_name+"You can purchase products here ":"You can go to the supplier to purchase the ordere"  }>
                                        <IconShoppingBag  size="1.2rem" stroke={1.5} color="green" />
                                    </Tooltip>
                                </ActionIcon> */}
            </Group>
          </td>
        </>
      </tr>
    );
  });
  const closeEditFunction = (type: number) => {
    closeFrom();
    closeSaleOpened();
    colseProductProcurementShow();
    if (type == 2) {
      ajaxCurrentList();
    }
  };
  // list all the suppliers
  return (
    <Box>
      <LoadingOverlay visible={loading} overlayBlur={2} />
      <Paper
        px={20}
        mih={500}
        pb={10}
        w={"100%"}
        style={{ position: "relative" }}
      >
        <table className="table_custome text-center">
          <tr className="table_th">
            <th>
              {intl.formatMessage({
                id: "Product Name",
                defaultMessage: "Product Name",
              })}
            </th>
            <th>
              {intl.formatMessage({
                id: "Order Quantity",
                defaultMessage: "Order Quantity",
              })}
            </th>
            <th>
              {intl.formatMessage({
                id: "Customer Order No",
                defaultMessage: "Customer Order No",
              })}
            </th>
            <th>
              {intl.formatMessage({
                id: "Delivered Quantity",
                defaultMessage: "Delivered Quantity",
              })}
            </th>
            <th>
              {intl.formatMessage({
                id: "Delivery Schedule",
                defaultMessage: "Delivery Schedule",
              })}
            </th>
            <th>
              {intl.formatMessage({
                id: "Delivered Status",
                defaultMessage: "Delivered Status",
              })}
            </th>
            <th>
              {intl.formatMessage({
                id: "Option",
                defaultMessage: "Option",
              })}
            </th>
          </tr>
          {rows}
        </table>

        <PaginationA
          callBack={ReceivePaginationABack}
          total_count={orderProductList.total as number}
          pagesize={pagination.pagesize}
          page={pagination.page}
        />
      </Paper>

      <Modal
        opened={openedFrom}
        onClose={closeFrom}
        title={formType === "create" ? "Create Product" : "Edit Supplier"}
        centered={true}
        size="60%"
        padding="md"
        radius={12}
      >
        {/* <ProductForm productCategory={productCategory} productItem={currentRowInfo} closeFucntion={closeEditFunction} /> */}
      </Modal>

      <Modal
        opened={ProductProcurementShow}
        onClose={colseProductProcurementShow}
        title=" Supplier Contact"
        // centered={true}
        size="60%"
        padding="md"
        radius={12}
      >
        {/* <SupplierContactIndex supplier={currentRowInfo}  /> */}
      </Modal>
      {/* Quick purchase order */}

      <Modal
        opened={ProductProcurementShow}
        onClose={colseProductProcurementShow}
        title=" Quick purchase "
        // centered={true}
        size="60%"
        padding="md"
        radius={12}
      >
        <ProductProcurementFrom
          unit_price_prohibit_edit={false}
          additional_information={currentRowInfo}
          closeFucntion={closeEditFunction}
        />
      </Modal>
      <Modal
        opened={saleOpened}
        withCloseButton
        onClose={closeSaleOpened}
        size="sm"
        title=" Sale"
        radius="md"
      >
        <SaleFrom
          closeFucntion={closeEditFunction}
          additional_information={currentRowInfo}
        />
      </Modal>
    </Box>
  );
}
