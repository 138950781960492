import { ActionIcon, Avatar, Box, Group, Tooltip, Text, Checkbox, LoadingOverlay } from '@mantine/core'
import { useState } from 'react'
import WordTranslationComponent from '../../../../../../utils/language_pack/words'
import { modals } from '@mantine/modals'
import { HintInfo, HintInfoCatchError, HintInfoError, formatDate } from '../../../../../../utils/function'
import GlobalCoreSetting from '../../../../../../utils/globalCortSetting'
import apiUrl from '../../../../../../config/api_config/config'
import DeleteIcon from '../../../../../../ttcomponents/DeleteIcon/DeleteIcon'
import { PermissionRoleInterface } from '../../../../../../interface/Iemloyee'
import { useCostCenterLeader, useCostCenterLeaderApi } from '../../context'

interface ComponentInterface {
  index: number
  row: PermissionRoleInterface
  refreshRoleList: () => {}
  requestPermissionRole: (data: any, method: any) => Promise<any>
}
export default function ListTr({ index, row, requestPermissionRole, refreshRoleList }: ComponentInterface) {
  // Get translation public configuration information
  const word_translation = WordTranslationComponent()
  // Get the global configuration
  const globalCoreSetting = GlobalCoreSetting()

  const { selectedRole } = useCostCenterLeader()
  const { updateDatePermissionRole, clearRole, updateData } = useCostCenterLeaderApi()
  const [loading, setLoading] = useState(false)

  const SelectFunction = () => {
    const { id_permission_role, identifier, title, description, active } = row

    // If not equal, update the current data
    if (selectedRole != id_permission_role.toString()) {
      updateDatePermissionRole({
        id: id_permission_role,
        identifier,
        active,
        title,
        description,
        selectedRole: id_permission_role
      })
    } else {
      // If equal, clear
      clearRole()
    }
  }

  // active
  const checkResultFunction = (value: boolean) => {
    modals.openConfirmModal({
      title: value ? word_translation.enable : word_translation.disable,
      centered: true,
      children: (
        <Text size="sm" mt={12}>
          <p>
            {word_translation.title} : {row.title}
          </p>
        </Text>
      ),
      labels: globalCoreSetting.EditOpenConfirmModaLabels,
      confirmProps: globalCoreSetting.DeleteOpenConfirmModaConfirmProps,
      onConfirm: async () => {
        try {
          setLoading(true)
          const response = await requestPermissionRole(
            {
              id: row.id_permission_role,
              active: row.active == '1' ? '0' : '1'
            },
            'PUT'
          )
          const result = response.data
          // Display information based on the response

          if (result.code === 200) {
            HintInfo(response.data)
            refreshRoleList()
            updateData('active', row.active == '1' ? '0' : '1')
          } else {
            HintInfoError(result.msg)
          }
        } catch (error) {
          HintInfoCatchError()
        } finally {
          setLoading(false)
        }
      }
    })
  }

  const handleEdit = (row: PermissionRoleInterface) => {
    // callBackfunction&&callBackfunction(row)
  }
  // Delete user role
  const handleDel = (row: PermissionRoleInterface) => {
    modals.openConfirmModal({
      title: word_translation.delete_title,
      centered: true,
      children: (
        <Text size="sm" mt={12}>
          <p>
            {word_translation.title} : {row.title}
          </p>
        </Text>
      ),
      labels: globalCoreSetting.DeleteOpenConfirmModaLabels,
      confirmProps: globalCoreSetting.DeleteOpenConfirmModaConfirmProps,
      onConfirm: async () => {
        const attributeTypeResult = await requestPermissionRole({ id: row.id_permission_role }, 'DELETE')
        HintInfo(attributeTypeResult.data)
        if (attributeTypeResult.data.code == 200) {
          clearRole()
          refreshRoleList()
        }
      }
    })
  }

  return (
    <>
      <LoadingOverlay visible={loading} overlayBlur={2} />
      <tr className="text-center table_tr_xs tr-hover grod-radius assignment_relative " key={index}>
        <td>
          <Group spacing={0} align="center" position="center">
            <ActionIcon onClick={SelectFunction}>
              <Tooltip label={word_translation.edit_tips}>
                <Checkbox size="xs" checked={selectedRole == row.id_permission_role.toString()} />
              </Tooltip>
              {/* ... other icons ... */}
            </ActionIcon>
          </Group>
        </td>
        <td onClick={SelectFunction}> {row.title}</td>
        {/* <td>{row.permission_identifier_name}</td> */}
        <td>{row.costCenter_name}</td>
        <td>{formatDate(row.created_at as string)}</td>
        <td className="img-center">
          <Box className="img-center">
            <Avatar src={apiUrl.fileSystemDomain + row.avatar} radius={40} size={26} />
          </Box>
        </td>
        <td>{row.members}</td>

        <td>
          <Group spacing={0} align="center" position="center">
            <ActionIcon>
              <Tooltip label={word_translation.edit_tips}>
                <Checkbox
                  size="xs"
                  checked={row.active == '1'}
                  onChange={event => checkResultFunction(event.currentTarget.checked)}
                />
              </Tooltip>
              {/* ... other icons ... */}
            </ActionIcon>
          </Group>
        </td>
        <td>
          <Group spacing={0} position="center">
            {/* <ActionIcon onClick={() => handleEdit(row)}>
              <Tooltip label={word_translation.edit_tips}>
                <IconDotsVertical size="1rem" stroke={1.5} />
              </Tooltip>
            </ActionIcon> */}
            <DeleteIcon disabled={false} callBack={() => handleDel(row)} />
          </Group>
        </td>
      </tr>
    </>
  )
}
