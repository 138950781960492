import { ActionIcon,  Box,  LoadingOverlay, ScrollArea, Table, Tooltip, Skeleton, Paper, Group, TextInput } from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import { IconSearch } from "@tabler/icons-react";
import WordTranslationComponent from "../../../../../../utils/language_pack/words";
// import OrderListTr from "./order_list_tr";
import React from "react";
import { PermissionRoleInterface } from "../../../../../../interface/Iemloyee";
import ListTr from "./tr";
import { RootState } from "../../../../../../state_management/store";
import { useDispatch, useSelector } from "react-redux";
import { setRefreshStatus } from "../../../../../../state_management/features/assignments_refresh_status/assignmentsRefreshStatusSlice";
import TableTextInputTitle from "../../../../../../ttcomponents/TableTextInputTitle/TableTextInputTitle";
import { regTemPermissionTable, requestManagePermissionList } from "../../../../../../api";
import { SelectRole } from "../../../../../../interface/Icommon";
import { getListTemPermissionFunction, getPermissionManageDetail } from "../../../../../../api/core_request";

interface ComponentInterface{
  callBack:(value:string,costCenter_id:string)=>void;
  listApi:(data:any,methed:string)=>any;
  actionApi:(data:any,methed:string)=>any;
  IdentifierInfo:string;
  formValue: any;
  setFormValue: (data: any) => void; // Function to set form value
  type?:string;
  selectedRole:string;
  managementStatus:boolean;
  Permission:any
}

interface User {
  title: number | string;
  id_management_permission_set_table: string;
}

export default function TemPermissionList({callBack,formValue,setFormValue,selectedRole,actionApi,Permission,IdentifierInfo}:ComponentInterface){
    
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
    // Get translation public configuration information
  const word_translation = WordTranslationComponent();

  const [value, setSearchValue] = useState('');
  
  const [permissionRoleData, setPermissionRoleData] = useState<User[]>([]);

  const [isCompleted, setIsCompleted] = useState(false);

  // 获取redux store中refreshStatus的值
  const refreshStatus = useSelector((state:RootState)=>state.refreshStatus.refreshStatus)

  // Execute the useeffect when the first load is loaded.
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (!isInitialMount.current) {
      ajaxCurrentList();
      setPermissionRoleData([])
    }
    isInitialMount.current = false;
  }, [refreshStatus]);



  useEffect(() => {
    ajaxCurrentList()
  }, []);
  

 

 const ajaxCurrentList = async (value: any = '', role_id: any = '') => {

  try {
    let data = {};
    if (value) {
      data = { ...data, ...{ title: value } };
    }

    setIsCompleted(true); // 设置正在进行请求的状态为 true
    // Make a GET request to retrieve user registrations with pagination parameters
    const response = await regTemPermissionTable(data, "GET");

    const result = response.data;

    // Check if the response is successful and the data is not an array
    if (result.code === 200 && Array.isArray(result.data)) {
      // Update the total count and user registration data in component state
      setPermissionRoleData(result.data);
    } else {
      // Log an error message if the response indicates an issue
      console.error(result.msg);
    }
  } catch (error) {
    // Log any errors that occur during the request
    console.error('Error fetching user registrations:', error);
  } finally {
    dispatch(setRefreshStatus(false));
    setIsCompleted(false); // 请求结束后，将正在进行请求的状态设置为 false
  }
};


const getPermissionManageRoleDetail = async () => {
  
};
  

  const CallRefresh=()=>{
      ajaxCurrentList();
  }
  
  const callBackOrderIDfunction=()=>{
    
  }
  const callBackTemPermission= async (id:any,value:any)=>{
    if(id){
      var result: any;
      result = await getPermissionManageDetail({ id_management_permission_set_table : id })
  
      if (result?.permissions){
        const updatedPermissions = result.permissions.map((permission: any) => {
          return {
            ...permission,
            is_edit: false,
            permission_action: permission?.permission_action.split(",") || []
          };
        });
  
        setFormValue((prevData: any) => {
          return {
            ...prevData,
            table_id: id,
            title: value,
            permissions: updatedPermissions
          };
        });
        // Load special permissions
      }
    }
  } 
  
   
  const setValueFunction = () => {
      ajaxCurrentList(value)
  }
     // Check if getIdentifierList has completed before rendering
  if (isCompleted) {
    return (<Box h={600}> <Skeleton height={600}>
      Lorem ipsum dolor sit amet...
      {/* other content */}
    </Skeleton></Box>);
  }
    return (
        <Paper  className="position_relative">
             <Box>
              <Box>
                <Group py={4} position="apart">
                    <Box>
                      <TableTextInputTitle title={word_translation.existing_role} />
                    </Box>
                    <Box>
                      <Group>
                      <TextInput 
                        onChange={(event) => setSearchValue(event.currentTarget.value)} 
                        size={"xs"}
                        defaultValue={value}
                        rightSection={<ActionIcon 
                                        onClick={() => setValueFunction()} >
                                      <Tooltip label={word_translation.search}><IconSearch color="#cccccc" /></Tooltip>
                                      </ActionIcon>} />

                      
                     <TableTextInputTitle title={`${word_translation.total_count} : ${permissionRoleData.length}` } />
                      </Group>
                    
                    </Box>
                </Group>
              </Box>
          </Box>
      
    <Paper  px={20} py={10} mih={270} radius="md" withBorder p="lg" bg="var(--mantine-color-body)">
        <LoadingOverlay visible={loading} overlayBlur={2} />
        <ScrollArea>
           <Table>
            <thead  className="scrolleder ">
                <tr>
                <th  id="table-th-title-new"></th>
                <th  id="table-th-title-new">{word_translation.title}</th>
                <th  id="table-th-title-new">{word_translation.option}</th>
                </tr>
            </thead>
            <tbody>
              {permissionRoleData && permissionRoleData?.length > 0 && permissionRoleData?.map((item, index) => {
                    var ischeck = false;
                    return (
                        <React.Fragment key={index}>
                             <ListTr 
                                actionApi={actionApi}
                                selectedRole={selectedRole} 
                                callBack={CallRefresh} 
                                callBackSelectFunction={callBackTemPermission} 
                                Permission={Permission} 
                                callBackfunction={callBackOrderIDfunction}
                                row={item}
                                formValue={formValue}
                                ischeck={ischeck}
                                index={index} />
                        </React.Fragment>
                    );
                })} 
            </tbody>
            </Table> 
     
            </ScrollArea></Paper>
        </Paper>
    )
}