import {
  Box,
  Button,
  Card,
  LoadingOverlay,
  Grid,
  Textarea,
  Group,
  Title,
  Select,
  Code,
  Text,
  TextInput,
  TransferListData,
  Table,
  Avatar,
  ActionIcon,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { hasLength, isNotEmpty, useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import {
  IconCalendar,
  IconCheck,
  IconDots,
  IconMoneybag,
  IconPlus,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import { FormEvent, useRef, useState } from "react";
import { regOrder, regOrderTask } from "../../../api";
import SelectUserToProject from "../../projects/select_user_to_project";
import { SelectUser } from "../../../interface/Icommon";
import { selectEmployeeProps } from "../../../interface/Iemloyee";
import { IOrderNewCreateList, IOrderProps } from "../../../interface/Iorder";
import { JudgmentTimeThreshold, TimeHHSS } from "../../../utils/function";
import SaveBottom from "../../../ttcomponents/Button/SaveBtn";
import { useIntl } from "react-intl";

interface IOrderFromProps {
  callBack: (order_id: string) => void;
  orderItem: IOrderProps | undefined;
  [key: string]: any;
}

// Custom validation function for time_consumtion field
const validateTimeConsumtion = (value: string) => {
  const pattern = /^(\d+):([0-5][0-9])$/;
  if (!pattern.test(value)) {
    return "Time consumption must be in the format HH:MM";
  }

  const [hours, minutes] = value.split(":");
  if (Number(hours) > 10000 || Number(minutes) >= 60) {
    return "Invalid time consumption";
  }
  return undefined;
};
export default function TaskEditFrom({
  callBack,
  taskcateGoryListData,
  remainingResourceData,
  orderItem,
  employeesList,
  taskItem,
  UserLists,
  userlist,
  taskcategory,
}: IOrderFromProps) {
  // Use the useIntl hook to access the intl object
  const intl = useIntl();

  const messageTo = intl.formatMessage({
    id: "To",
    defaultMessage: "To",
  });

  const messageProjectFrom = intl.formatMessage({
    id: "The project cycle starts from",
    defaultMessage: "The project cycle starts from",
  });

  const messageRemainingTime = intl.formatMessage({
    id: "remaining time",
    defaultMessage: "remaining time",
  });

  const messageRemainingBudget = intl.formatMessage({
    id: "remaining budget",
    defaultMessage: "remaining budget",
  });

  const [teamLeaderValue, setTeamLeaderValue] = useState<string[]>([]);
  const [employeestool, setEmployees] = useState<selectEmployeeProps[]>([]);
  const formRef = useRef<HTMLFormElement>(null);
  //加载中状态
  const [loading, setLoading] = useState(false);
  const [resetUser, setresetUser] = useState(false);
  const [projectEmployeelist, setProjectEmployeelist] =
    useState<TransferListData>([[], []]);
  const [searchEmployeesValue, onEmployeesSearchChange] = useState("");
  const [descEnable, setDesEnable] = useState<boolean>(false);
  const form = useForm({
    initialValues: {
      project_id: orderItem?.project_id,
      order_id: orderItem?.id_order,
      time_estimate: "00:00",
      flag: "1",
      task_category_id: "",
      free_description: "",
      start_date: new Date(),
      end_date: "",
      time: "",
      budget: "0.00",
      task_leader_id: "",
      employees_id: "",
      employees: [],
    },
    validate: {},
  });
  const setTeamLeaderValueHandle = (value: React.SetStateAction<string[]>) => {
    setTeamLeaderValue(value);
    form.setFieldValue("order_team_leaders", value as []);
  };

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    setLoading(true);
    event.preventDefault(); // Stop the formal submission of the form default
    // Verification form
    const valid = await form.validate();
    console.log(valid);
    // 使用 Set 数据结构来判断是否已经存在相同的元素
    const filteredData = employeestool.filter((item, index, self) => {
      const uniqueKey = `${item.employee_role_id}-${item.project_id}-${item.order_id}-${item.user_id}`;
      return (
        index ===
        self.findIndex((el) => {
          const elUniqueKey = `${el.employee_role_id}-${el.project_id}-${el.order_id}-${el.user_id}`;
          return elUniqueKey === uniqueKey;
        })
      );
    });

    // The start time of the setting cannot exceed the initial time of the system settings
    if (JudgmentTimeThreshold(form.values.start_date) === false) return false;

    if (!valid.hasErrors) {
      let originalData = {
        order_id: form.values.order_id,
        flag: 1,
        time_estimate: form.values.time_estimate,
        start_date: form.values.start_date.toISOString().split("T")[0],
        end_date: form.values.end_date
          ? new Date(form.values.end_date).toISOString().split("T")[0]
          : "",
        time_consumtion: form.values.time,
        free_description: form.values.free_description,
        task_category_id: form.values.task_category_id,
        task_leader_id: form.values.task_leader_id,
        budget: form.values.budget,
        employees: filteredData,
      };
      const response = await regOrderTask(originalData, "POST");
      const result = response.data;
      if (result.code === 200) {
        setLoading(false);
        callBack(form.values.order_id as string);
        notifications.show({
          color: "green",
          icon: <IconCheck />,
          message: result.msg,
        });
      } else {
        setLoading(false);
        notifications.show({
          color: "red",
          icon: <IconX />,
          message: result.msg,
        });
      }
    }
  };

  // 删除订单
  const delOrderList = (indexs: number) => {
    const newData = employeestool.filter((item, index) => index !== indexs);
    form.setFieldValue("project_order", newData);
    setEmployees(newData); // 更新状态
  };

  const selectUserToProject = (value: TransferListData) => {
    let selectElement = value[1] as unknown as SelectUser[];
    const employees_id = form.values.employees_id;
    if (!employees_id) {
      notifications.show({
        title: intl.formatMessage({
          id: "warning",
          defaultMessage: "warning",
        }),
        color: "red",
        icon: <IconX />,
        message: "Need to assign roles to employees",
      });
      return null;
    }
    const updatedEmployeesSub = selectElement.map((item) => {
      return {
        employee_role_id: employees_id,
        avatar: item.avatar,
        label: item.label,
        project_id: form.values.project_id,
        order_id: form.values.order_id,
        user_id: item.value,
      };
    });

    const updatedEmployees = Array.isArray(employeestool)
      ? employeestool.concat(
          updatedEmployeesSub as unknown as selectEmployeeProps[]
        )
      : updatedEmployeesSub;
    setEmployees(updatedEmployees as selectEmployeeProps[]);
    setProjectEmployeelist(value);

    const filteredData = updatedEmployees.filter((item, index, self) => {
      // 使用 Set 数据结构来判断是否已经存在相同的元素
      const uniqueKey = `${item.employee_role_id}-${item.project_id}-${item.order_id}-${item.user_id}`;
      return (
        index ===
        self.findIndex((el) => {
          const elUniqueKey = `${el.employee_role_id}-${el.project_id}-${el.order_id}-${el.user_id}`;
          return elUniqueKey === uniqueKey;
        })
      );
    });

    setEmployees(filteredData as selectEmployeeProps[]);
  };
  const rows = employeestool.map((element, index) => (
    <tr key={index}>
      <td>
        <Avatar src={element.avatar} radius={40} size={26} />
      </td>
      <td> {orderItem?.order_no}</td>
      <td>{element.project_id}</td>
      <td>
        {employeesList.map((item: any, index: any) => {
          if (element.employee_role_id == item.value) {
            return item.label;
          }
        })}
      </td>
      <td>
        <Group mt="md">
          <ActionIcon onClick={() => delOrderList(index)}>
            <IconTrash size="1rem" color="red" stroke={1.5} />
          </ActionIcon>
        </Group>
      </td>
    </tr>
  ));

  function pickCategory(value: string | null): void {
    setDesEnable(value === "0" ? false : true);
    form.setFieldValue("flag", value as string);
  }
  function onEmployeesSearchChangeHandle(value: string | null): void {
    setresetUser(true);
    form.setFieldValue("employees_id", value as string);
  }

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = TimeHHSS(event);
    form.setFieldValue("time_estimate", value);
  };

  return (
    <form onSubmit={handleFormSubmit} ref={formRef}>
      <LoadingOverlay visible={loading} overlayBlur={2} />
      <Box ml={20} mt={10}>
        <Card>
          <Grid>
            <Grid.Col span={5}>
              <Title order={5} color="blue">
                {intl.formatMessage({
                  id: "order_no",
                  defaultMessage: "order_no",
                })}
                : {orderItem?.order_no}
              </Title>
              <Select
                labelProps={{ className: "input_title" }}
                label={intl.formatMessage({
                  id: "Task Category",
                  defaultMessage: "Task Category",
                })}
                searchable
                required
                placeholder={intl.formatMessage({
                  id: "Select Leader",
                  defaultMessage: "Select Leader",
                })}
                onChange={(value) =>
                  form.setFieldValue("task_category_id", value as string)
                }
                data={taskcateGoryListData}
              />
              <Select
                mt={20}
                labelProps={{ className: "input_title" }}
                label={intl.formatMessage({
                  id: "Task Team Leaders",
                  defaultMessage: "Task Team Leaders",
                })}
                placeholder={intl.formatMessage({
                  id: "Select Leader",
                  defaultMessage: "Select Leader",
                })}
                searchable
                onChange={(value) =>
                  form.setFieldValue("task_leader_id", value as string)
                }
                data={userlist}
              />

              <Grid>
                <Grid.Col span={6}>
                  <DateInput
                    valueFormat="DD/MM/YYYY"
                    {...form.getInputProps("start_date")}
                    mt={10}
                    icon={<IconCalendar size="1.1rem" stroke={1.5} />}
                    label={intl.formatMessage({
                      id: "Start Time",
                      defaultMessage: "Start Time",
                    })}
                    placeholder={intl.formatMessage({
                      id: "Start Time",
                      defaultMessage: "Start Time",
                    })}
                    maw={400}
                    mx="auto"
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <DateInput
                    valueFormat="DD/MM/YYYY"
                    {...form.getInputProps("end_date")}
                    mt={10}
                    icon={<IconCalendar size="1.1rem" stroke={1.5} />}
                    label={intl.formatMessage({
                      id: "End Time",
                      defaultMessage: "End Time",
                    })}
                    minDate={form.values.start_date}
                    placeholder={intl.formatMessage({
                      id: "End Time",
                      defaultMessage: "End Time",
                    })}
                    maw={400}
                    mx="auto"
                  />
                </Grid.Col>

                <Grid.Col span={12}>
                  <Text color="red" size={12}>
                    {remainingResourceData &&
                      messageProjectFrom +
                        " " +
                        remainingResourceData?.start_date +
                        " " +
                        messageTo +
                        " " +
                        remainingResourceData?.end_date}
                  </Text>
                </Grid.Col>
              </Grid>
            </Grid.Col>
            <Grid.Col span={6} ml={30}>
              {/* <Select
                                mt={20}
                                labelProps={{ className: 'input_title', }}
                                label="Flag"
                                placeholder="Select category"
                                defaultValue='0'
                                onChange={(value) => pickCategory(value)}
                                data={[{ 'value': '0', 'label': 'no' }, { 'value': '1', 'label': 'yes' },]} /> */}
              <Textarea
                mt={20}
                disabled={descEnable}
                labelProps={{ className: "input_title" }}
                placeholder={intl.formatMessage({
                  id: "Input",
                  defaultMessage: "Input",
                })}
                label={intl.formatMessage({
                  id: "Task Description",
                  defaultMessage: "Task Description",
                })}
                {...form.getInputProps("free_description")}
              />
              <TextInput
                mt={10}
                labelProps={{ className: "input_title" }}
                {...form.getInputProps("budget")}
                label={
                  <Group>
                    <IconMoneybag />
                    {intl.formatMessage({
                      id: "Budget",
                      defaultMessage: "Budget",
                    })}{" "}
                    <Text color="red" size={12}>
                      {remainingResourceData &&
                        messageRemainingBudget +
                          ": " +
                          remainingResourceData?.remaining_budget}
                    </Text>
                  </Group>
                }
                placeholder={intl.formatMessage({
                  id: "Input",
                  defaultMessage: "Input",
                })}
              />

              <TextInput
                mt={10}
                labelProps={{ className: "input_title" }}
                description=""
                label={
                  <Group>
                    {intl.formatMessage({
                      id: "Estimated time",
                      defaultMessage: "Estimated time",
                    })}{" "}
                    <Text color="red" size={12}>
                      {remainingResourceData &&
                        messageRemainingTime +
                          ": " +
                          remainingResourceData?.remaining_time}
                    </Text>
                  </Group>
                }
                onBlur={handleBlur}
                value={form.values.time_estimate}
                placeholder={intl.formatMessage({
                  id: "Input",
                  defaultMessage: "Input",
                })}
                onChange={(event) =>
                  form.setFieldValue("time_estimate", event.target.value)
                }
                // {...form.getInputProps('time_estimate')}
              />
            </Grid.Col>
          </Grid>
          <Card px={0} mt={10} withBorder radius="md" shadow="sm">
            <Select
              mx={10}
              placeholder="Select Role "
              labelProps={{ className: "input_title" }}
              label={intl.formatMessage({
                id: "Employees Role",
                defaultMessage: "Employees Role",
              })}
              searchValue={searchEmployeesValue}
              searchable
              onChange={(value) => onEmployeesSearchChangeHandle(value)}
              onSearchChange={onEmployeesSearchChange}
              w={300}
              defaultValue={searchEmployeesValue}
              data={employeesList}
            />
            <SelectUserToProject
              resetUser={resetUser}
              orderid={form.values.order_id}
              employee={form.values.employees_id}
              callBack={selectUserToProject}
              userlist={UserLists}
            />
            {rows.length > 0 ? (
              <Table w="50%" mx={20} mt={20}>
                <thead>
                  <tr>
                    <th>
                      {intl.formatMessage({
                        id: "user",
                        defaultMessage: "User",
                      })}
                    </th>
                    <th>
                      {intl.formatMessage({
                        id: "orderId",
                        defaultMessage: "Order ID",
                      })}
                    </th>
                    <th>
                      {intl.formatMessage({
                        id: "projectId",
                        defaultMessage: "Project ID",
                      })}
                    </th>
                    <th>
                      {intl.formatMessage({
                        id: "roleName",
                        defaultMessage: "Role Name",
                      })}
                    </th>
                    <th>
                      {intl.formatMessage({
                        id: "Option",
                        defaultMessage: "Option",
                      })}
                    </th>
                  </tr>
                </thead>
                <tbody>{rows}</tbody>
              </Table>
            ) : null}
          </Card>

          {/* <Text size="sm" weight={500} mt="xl">
                    Form values:
                </Text>
                <Code block mt={5}>
                    {JSON.stringify(form.values, null, 2)}
                </Code> */}

          <Group position="right">
            <SaveBottom formRef={formRef} />
          </Group>
        </Card>
      </Box>
    </form>
  );
}
