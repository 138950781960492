
import {
  Paper,
  Box,
  Group,
  Text,
  Modal,
  Grid,
  Card,
  ActionIcon,
  LoadingOverlay,
  Tooltip,
  Badge,
} from "@mantine/core";
import { useEffect, useState, useRef } from "react";
import { useDisclosure } from "@mantine/hooks";

import PageTitle from "../../../../ttcomponents/PageTitle/PageTitle";
import {
  IconCheck,
  IconDots,
  IconEdit,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import CreateBtn from "../../../../ttcomponents/Button/CreateBtn";
import { useIntl } from "react-intl";
import { modals } from "@mantine/modals";
import { regGetUserRoleList } from "../../../../api";
import { HintInfo } from "../../../../utils/function";
import { IPagination } from "../../../../interface/Ipagintion";
import PaginationA from "../../../../ttcomponents/Pagination/PaginationA";
import { UserRoleData, UserRoleList } from "../../../../interface/Iuser";
import GlobalCoreSetting from "../../../../utils/globalCortSetting";
import UserRoleFrom from "./user_role_form";
import ModalTitleText from "../../../../ttcomponents/ModalTitleText/ModalTitleText";
import WordTranslationComponent from "../../../../utils/language_pack/words";
import { ItopSearch } from "../../../../interface/Icommon";
import EditIconPerm from "../../../../ttcomponents/EditIcon/EditIconPerm";
import DeleteIconPerm from "../../../../ttcomponents/DeleteIcon/DeleteIconPerm";
import CheckPermissionTools from "../../../../utils/permission";

export default function UserRole({ searchValue }: ItopSearch) {
  const [moreOpened, setMoreOpened] = useState(false);

  // useIntl for multiple language
  const intl = useIntl();
  // Get the default unchanged configuration
  const globalCoreSetting = GlobalCoreSetting();
  // Get translation public configuration information
  const word_translation = WordTranslationComponent();
  // Edit modal status
  const [openUserRoleFrom, { open: openEdit, close: closeUserRoleFrom }] =
    useDisclosure(false);

  const Perm = CheckPermissionTools();
  
  const [loading, setLoading] = useState(false);
  // Form type
  const [formType, setFormType] = useState("create");
  const [currentRowInfo, setCurrentRowInfo] = useState<UserRoleData | undefined>();

  // define the supplier list
  const [tableListData, setSupplierList] = useState<UserRoleList>({
    total_count: 0,
    filtered_count: 0,
    data: [], // Use the data property from the first item in the mock data array
  });

  const [pagination, setPagination] = useState<IPagination>({
    search: "",
    page: 1,
    pagesize: 10,
    sortBy: "",
    sortOrder: "desc",
  });

  // 接收回调返回数据，搜索内容  Receive the callback return data, search for content
  const ReceiveSearchBack = (value: string | number) => {
    setSupplierList({
      total_count: 0,
      filtered_count: 0,
      data: [], // Use the data property from the first item in the mock data array
    });
    setPagination((prevpagination) => ({
      ...prevpagination,
      search: value as string,
      page: 1,
    }));
  };

  // Execute the useeffect when the first load is loaded.
  const isInitialMount = useRef(true);
  useEffect(() => {
    if (!isInitialMount.current) {
      ReceiveSearchBack(searchValue);
    }
    isInitialMount.current = false;
  }, [searchValue]);

  // 分页回调触发更新 分页回调触发更新
  const ReceivePaginationABack = (page: number) => {
    setPagination((prevpagination) => ({
      ...prevpagination,
      page: page,
    }));
  };
  // set search value
  const [search, setSearch] = useState("");

  // Define an asynchronous function ajaxcurrenTlist to send AJAX request to obtain the list of suppliers
  const ajaxCurrentList = async () => {
    setLoading(true); // Set the loading status to TRUE, indicating that the data is loading
    let searchStr = {};

    // Check whether there are search conditions, if so, build a search parameter object
    if (search) {
      searchStr = {
        search: search,
      };
    }

    // Send GET requests to get supplier data
    const responseSupplier = await regGetUserRoleList(pagination, "GET");

    setLoading(false); // After the data is loaded, set the loading status to false
    if (responseSupplier.data.code === 200) {
      setSupplierList(responseSupplier.data.data); // 如果响应状态码为200，将供应商列表更新为响应数据中的数据
    }
  };
  // Use the USEEFFECT hook. When Search changes, call the AjaxCurrenTList function
  useEffect(() => {
    ajaxCurrentList();
  }, [pagination]);

  // Edit supplier
  const handleEdit = (row: UserRoleData) => {
    setFormType("edit");
    openEdit();
    setCurrentRowInfo(row);
  };

  // Delete user role
  const handleDel = (row: UserRoleData) => {
    modals.openConfirmModal({
      title: word_translation.delete_title,
      centered: true,
      children: (
        <Text size="sm" mt={12}>
          {word_translation.role_name} : {row.role_name}
        </Text>
      ),
      labels: {
        confirm: word_translation.confirm_delete,
        cancel: word_translation.cancel_delete,
      },
      confirmProps: { color: "red" },
      onConfirm: async () => {
        const SupplierResult = await regGetUserRoleList({ id: row.id_user_role }, "DELETE");
        HintInfo(SupplierResult.data);
        if (SupplierResult.data.code == 200) {
          ajaxCurrentList();
        }
      },
    });
  };

  // Delete supplier info to backend
  const delSupplierInfo = async (id: number | string) => {

  };

  // Create supplier
  const handleCallback = () => {
    setFormType("create");
    openEdit();
    setCurrentRowInfo(undefined);
  };



  // Create a row for each supplier
  const rows = tableListData.data.map((row) => (
    <tr key={row.id_user_role} className="border-bt-2 my-10 ">
      <td className="">
        <Badge> {row.role_identifier}</Badge>
      </td>
      <td>{row.role_name}</td>
      <td>{row.disabled == 0 ? <IconCheck color="green" /> : <IconX color="red" />}</td>
      <td>{row.role_desc}</td>
      <td>
        <Group spacing={0} position="center">
          <EditIconPerm  enable={Perm.user_role_update}  callBack={() => handleEdit(row)} />
          <DeleteIconPerm enable={Perm.user_role_delete} callBack={() => handleDel(row)} />
        </Group>
      </td>
    </tr>
  ));

  const closeUserRoleFromFunction = (type: number) => {
    closeUserRoleFrom();
    if (type == 2) {
      ajaxCurrentList();
    }
  };
  // list all the suppliers
  return (
    <>
      <Box>
        <Paper px={20} pb={10} w={"100%"} style={{ position: "relative" }}>
          <PageTitle title="NEW - BOARDINGS" lang_id="new_boarding" />
          <Grid gutter={0} mt={10}>
            <LoadingOverlay visible={loading} overlayBlur={2} />
            <table className="table_custome text-center">
              <thead className="table_th">
                <tr>
                  <th>{word_translation.identifier_user_role}</th>
                  <th>{word_translation.role_name}</th>
                  <th>{word_translation.disable}</th>
                  <th>{word_translation.user_role_desc}</th>
                  <th>{word_translation.option}</th>
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </table>
          </Grid>
          <PaginationA
            callBack={ReceivePaginationABack}
            total_count={tableListData.total_count as number}
            pagesize={pagination.pagesize}
            page={pagination.page}
          />
          <>


          </>
        </Paper>
      </Box>
      <Modal
        opened={openUserRoleFrom}
        onClose={closeUserRoleFrom}
        title={<ModalTitleText title={formType === "create" ? word_translation.create_user_role : word_translation.update_user_role} />}
        centered={true}
        padding="md"
        radius={12}
      >
        <UserRoleFrom
          userRoleItem={currentRowInfo}
          closeFunction={closeUserRoleFromFunction}
        />
      </Modal>
      {moreOpened && (
        <Paper className="positioned-right-bottom-content" shadow="sm">
          <Card>
            <CreateBtn callBack={handleCallback} />
          </Card>
        </Paper>
      )}
      <ActionIcon size={30} variant="outline" className="positioned-right-bottom" color="blue" onClick={() => setMoreOpened(!moreOpened)}>
        <Tooltip label={word_translation.more_tips}>
          {moreOpened ? <IconX color="red" size="1.8rem" /> : <IconDots size="1.8rem" />}
        </Tooltip>
      </ActionIcon> </>

  );
}
