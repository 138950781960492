import { useDispatch, useSelector } from "react-redux";
import { refreshPermissions } from "../../api";
import localstorageUnits from "../localstorageUnits";
import { notifications } from "@mantine/notifications";
import { RootState } from "../../state_management/store";
import { setLockStatus } from "../../state_management/features/executing_lock_status/executing_lock_statusSlice";
import memoryUtils from "../memoryUtils";

export const CheckPermission =  (permissionIdentifier_action: string) :boolean => {
    const ExecutingLockStatus = useSelector((state:RootState)=>state.ExecutingLockStatus.ExecutingLockStatus)
    const dispatch = useDispatch();
    const id_user =memoryUtils.user.id_user;

    if(id_user == "1"){
        return true
    }
    
    try {
        // 从缓存当中取出对象集合
        let permissions =  localstorageUnits.getPermissionIdentifier();
        permissions = permissions.map((permission:any) => {
            if (permission.includes('@')) {
                return permission.split('@')[0]
            }
            return permission
        })

        if (Array.isArray(permissions) &&permissions.length > 0 && permissions.includes(permissionIdentifier_action)) {
            return true;  
        }  else {
            // if(ExecutingLockStatus){
            //     dispatch(setLockStatus(false))
            //     getPermissionAction();
            // }          
            // setTimeout(() => {
            //     dispatch(setLockStatus(true))
            // }, 1000);
        }  
        return false
    } catch (error) {
        // 
        console.error('Error in CheckPermission:', error);
        return false;
    } finally {
        // 在延时后将 isExecutingLock 设置为 false
    
    }
};

const getPermissionAction = async (): Promise<string[]> => {
    
    try {
        // 获取个人权限
        const response = await refreshPermissions();
        const result = response.data;
      
        if (result.code === 200 && Array.isArray(result.data) && result.data.length > 0) {
            localstorageUnits.savePermissionIdentifier(result.data);
        notifications.show({
            color: 'green',
            autoClose:false,
            message: 'Permission exception, automatically verified, please refresh the page.',
          })
              return result.data;
        } else {
            localstorageUnits.savePermissionIdentifier({ permission: 'none' });
            return [];
        }
    } catch (error) {
        // 处理异常，可以打印错误信息或者进行其他处理
        console.error('Error in getPermissionAction:', error);
        return [];
    }
}
