import React, { useState, useEffect } from 'react';
import './Message.css'; // 样式文件导入

interface MessageProps {
  content: string;
  duration: number;
  color?: string;
  icon?: React.ReactElement;
}

const Message: React.FC<MessageProps> = ({ content, duration, color = 'green', icon }) => {
  const [showMessage, setShowMessage] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowMessage(false);
    }, duration);

    return () => clearTimeout(timeout);
  }, [duration]);

  if (!showMessage) {
    return null;
  }

  return (
    <div className={`auto-hide-message ${color}`}>
      {icon}
      {content}
    </div>
  );
};

export default Message;
