import { Box, Code, Grid, Group, NumberInput, Paper, Select, TextInput, Textarea } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import CancelButton from "../../../../ttcomponents/Button/CancelBtn";
import SaveBottom from "../../../../ttcomponents/Button/SaveBtn";
import { FormEvent, useEffect, useRef, useState } from "react";
import { isNotEmpty, useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { IconCalendar, IconX } from "@tabler/icons-react";
import { HintInfo, TotalPriceFunction, formatDateUpgraded } from "../../../../utils/function";
import globalCortSetting from "../../../../utils/globalCortSetting";
import ProductSelect from "../../../../ttcomponents/Product/ProductSelect";
import { regCustomerList, regRentProduct, regReturnRentProduct } from "../../../../api";
import { ResponseCustomerData } from "../../../../interface/Icoustomer";
import { SelectRole } from "../../../../interface/Icommon";
interface deliverProductProps {
    additional_information: any;
    closeFucntion: (type: number) => void;
  }
export default function ReturnRentProductFrom({additional_information,closeFucntion}:deliverProductProps){

    
  const [customerlist, setCustomerList] = useState<SelectRole[]>([]);
  const formRef = useRef<HTMLFormElement>(null);

  const form = useForm({
    initialValues: {
      rent_id: additional_information?.rent_id,
      return_quantity:'',
      total_price:additional_information?.total_price,
      actual_return_time:new Date(),
    },
    validate: {
      total_price: isNotEmpty('Total Price name is required'),
      return_quantity: isNotEmpty('Renturn quantity  is required'),
      actual_return_time: isNotEmpty('Actual Return Time   is required'),
    },
  });


  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
     // Stop the formal submission of the form default
    event.preventDefault();
    // Verification form
    const valid = form.validate();
    if (valid.hasErrors) {
      notifications.show({
        color: "yellow.7",
        icon: <IconX />,
        message: Object.values(valid.errors)[0],
      });
      return;
    }

        
    const updatedFormValues = {
        ...form.values,
        actual_return_time: formatDateUpgraded(
          form.values.actual_return_time,
          "YYYY-MM-DD"
        )
      }
    
  const response = await regReturnRentProduct(updatedFormValues, 'PUT');
    HintInfo(response.data)
    if(response.data.code == 200){
       closeFucntion(2)
    }
  };

  // Get Timetrack Record
  const ajaxCurrentList = async () => {
    if(customerlist.length <=  0){
        const responseCustomer = await regCustomerList({});
        const result = responseCustomer.data as ResponseCustomerData;
        if (result.code === 200) {
        const customerData = result.data.map((item) => ({
            value: item.id_customer as string,
            label: item.company_name,
        }));
        setCustomerList(customerData);
        }
    }
   
  };
console.log(additional_information)
  useEffect(() => {
    // if(rental_status == true){
        ajaxCurrentList();
    // }
  }, []);

    return (
        <Paper p={10}>
          <form onSubmit={handleFormSubmit} ref={formRef}>
            <Grid>
              <Grid.Col span={12}>
            
              <TextInput
                value={additional_information.product_name}
                disabled
                label="Product  Name"
                />
                
              <TextInput
                value={additional_information.customer_id}
                disabled
                label="Customer Name(ID)"
                />
                <TextInput

                  value={additional_information.rent_quantity}
                  disabled
                 label="Rent Quantity"
                  
                  />
             
                <NumberInput
                  {...form.getInputProps("return_quantity")}
                  // value={categoryName}
                  label="Renturn Quantity"
                />
    
                 <TextInput
                  {...form.getInputProps("total_price")}
                 label="Total Price"
                />

                <DateInput valueFormat="DD/MM/YYYY"
                  required
                  icon={<IconCalendar size="1.1rem" stroke={1.5} />}
                  {...form.getInputProps("actual_return_time")}
                  label="Actual Return Time"
                />
                
              </Grid.Col>
            </Grid>
    
            <Group position="right">
              {/* <CancelButton callBack={() => closeFucntion(1)} /> */}
              <SaveBottom formRef={formRef} />
            </Group>
    
            {/** can see form values */}
            <Code block mt={5}>
              {JSON.stringify(form.values, null, 2)}
            </Code>
          </form>
        </Paper>
      )
}