import { ActionIcon, Button, Tooltip } from "@mantine/core";
import { IconCircleCheckFilled, IconCirclePlus, IconEye, IconTrash } from "@tabler/icons-react";
import { FormattedMessage } from "react-intl";
import WordTranslationComponent from "../../utils/language_pack/words";

// Define the props interface for the ChildComponent
interface ChildComponentProps {
    size?: any;
    stroke?: any;
    color?:any;
  callBack: () => void; // Callback function to handle button click events
}

// ChildComponent representing the Create Button
const ViewIcon: React.FC<ChildComponentProps> = ({stroke,size,color, callBack }) => {
  
  // Get translation public configuration information
  const word_translation = WordTranslationComponent();
  
  return (
    <ActionIcon onClick={() => callBack()}>
            <Tooltip label={word_translation.preview}>
                <IconEye size="1.2rem" color="#7D7D7D" stroke={1.5} />
              </Tooltip>
    </ActionIcon>

  

  );
};

export default ViewIcon;
