import { Box, Code, Grid, Group } from "@mantine/core"
import TableTextInputTitle from "../../../ttcomponents/TableTextInputTitle/TableTextInputTitle"
import WordTranslationComponent from "../../../utils/language_pack/words";

interface ComponentInterface{
    row:any
}
export default function Index({row}:ComponentInterface){
    

 // Get translation public configuration information
 const word_translation = WordTranslationComponent();
    return(
        <Box>
        <Grid>
          <Grid.Col span={12}>
              <Group>
                <TableTextInputTitle title={word_translation.user} />
                <Code className="columns-text">   {row.user_info.username}</Code>
              </Group>
              <Group>
                <TableTextInputTitle title={word_translation.start_time} />
                <Code className="columns-text">   {row.from}</Code>
              </Group>
              <Group>
                <TableTextInputTitle title={word_translation.end_time} />
                <Code className="columns-text">   {row.to}</Code>
              </Group>
          </Grid.Col>
        </Grid>
        {/* <Box mt={10}>
            Agree or refuse ?
          </Box> */}
      </Box>
    )
}