import { ActionIcon, Button, Tooltip } from "@mantine/core";
import { IconCircleCheckFilled, IconCirclePlus, IconEye, IconTrash } from "@tabler/icons-react";
import { FormattedMessage } from "react-intl";
import WordTranslationComponent from "../../utils/language_pack/words";

// Define the props interface for the ChildComponent
interface ChildComponentProps {
    size?: any;
    stroke?: any;
    radius?:string;
    color?:any;
    mr?:number;
    icon:any;
    ml?:number;
    variant?:string
    callBack: () => void; // Callback function to handle button click events
}

// ChildComponent representing the Create Button
const UniversalIcon: React.FC<ChildComponentProps> = ({mr=0, ml=0,radius='xl',variant='transparent',stroke,size=30,color, icon,callBack }) => {
  
  // Get translation public configuration information
  const word_translation = WordTranslationComponent();
  
  return (
    <ActionIcon
    size={size}
    mr={mr}
    variant={variant}
    radius={radius}
    onClick={() => callBack()}>
            <Tooltip label={word_translation.preview}>
                     {icon} 
              </Tooltip>
    </ActionIcon>

  

  );
};

export default UniversalIcon;
