import { Checkbox, Group } from '@mantine/core'
import WordTranslationComponent from '../../../../../../utils/language_pack/words'
import { AssignablePermissions, PermissionIdentifierInterface } from '../../../../../../interface/Iemloyee'

import { MenuActionItemInterFace } from '../../../../../../interface/Iuser'
import { useCallback, useEffect, useMemo, useState } from 'react'
import React from 'react'
import { useCostCenterLeader } from '../../context'
import CheckPermissionTools from '../../../../../../utils/permission'

interface ComponentInterface {
  PermissionIdentifierItem: PermissionIdentifierInterface
  PermissionAction: MenuActionItemInterFace[]
  assignablePermissions: AssignablePermissions | undefined
  callBack: (id_permission_identifier: any, permission_ids: string[]) => void
  allCheck: boolean
  leftWidth?: number
}

const PermissionSetCheckBoxGroup = ({
  PermissionIdentifierItem,
  allCheck,
  assignablePermissions,
  callBack,
  PermissionAction,
  leftWidth
}: ComponentInterface) => {
  // Get translation public configuration information
  const word_translation = WordTranslationComponent()

  const { id, costCenter_id, identifier, role_permissions } = useCostCenterLeader()

  const Perm = CheckPermissionTools()

  const value = useMemo(() => {
    const currentItem = role_permissions.find(
      role => role.permission_identifier_id === PermissionIdentifierItem.id_permission_identifier
    )
    if (currentItem) {
      return currentItem.permission_ids
    }
    return []
  }, [role_permissions])

  const assignablePermission_ids = useMemo(() => {
    if (!assignablePermissions) {
      return []
    }
    const currentPermissions = assignablePermissions.permissions.find(
      i => i.permission_identifier_id == PermissionIdentifierItem.id_permission_identifier
    )
    if (currentPermissions) {
      return currentPermissions.permission_ids
    }
    return []
  }, [assignablePermissions])

  const grandID = useMemo(() => (assignablePermissions ? assignablePermissions.grand_id : ''), [assignablePermissions])

  const SpecialDisablingAll = useMemo(() => {
    if (!costCenter_id || !identifier) {
      return true
    }
    // admin user open all
    if (Perm.admin) {
      return false
    }
    if (assignablePermission_ids.includes(grandID.toString())) return false
    return true
  }, [costCenter_id, identifier, assignablePermission_ids, grandID])

  // control current allchecked btn
  const [checked, setChecked] = useState(allCheck)

  useEffect(() => {
    if (allCheck) {
      setChecked(true)
    } else {
      setChecked(false)
    }
  }, [allCheck, id, identifier])

  useEffect(() => {
    // select all case
    const getAssignablePermission = () => {
      if (Perm.admin) {
        return PermissionAction.map(item => item.id_permission.toString())
      }
      return assignablePermission_ids
    }
    callBack(PermissionIdentifierItem.id_permission_identifier, checked ? getAssignablePermission() : [])
  }, [checked])

  // mouse hover
  const [isHovered, setIsHovered] = useState(false)
  const handleTrMouseLeave = () => {
    setIsHovered(false)
  }
  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const setValueCheckedFunction = (value: any) => {
    callBack(PermissionIdentifierItem.id_permission_identifier, value)
  }

  const SpecialDisabling = useCallback(
    (item: MenuActionItemInterFace) => {
      if (!costCenter_id || !identifier) {
        return true
      }
      // Permit
      if (PermissionIdentifierItem.identifier.endsWith('99999')) {
        if (item.identifier === '0001') {
          if (Perm.admin) {
            return false
          } else {
            return assignablePermissions?.is_permit === false
          }
        } else {
          return true
        }
      }

      // admin user open all
      if (Perm.admin) {
        return false
      }
      // other user need check current assignable permission's grant
      if (assignablePermission_ids.includes(grandID.toString())) {
        if (assignablePermissions?.is_permit) {
          if (assignablePermission_ids.includes(item.id_permission.toString())) {
            return false
          }
        } else {
          if (item.identifier == '0001') {
            return true
          } else {
            if (assignablePermission_ids.includes(item.id_permission.toString())) {
              return false
            }
          }
        }
        return true
      }
      return true
    },
    [costCenter_id, identifier, assignablePermission_ids, grandID]
  )

  return (
    <tr className="text-center position_relative " onMouseEnter={handleMouseEnter} onMouseLeave={handleTrMouseLeave}>
      <td className="text-left  input_title">
        <Checkbox.Group
          w={leftWidth ? leftWidth : 134}
          h={24}
          value={value}
          onChange={setValueCheckedFunction}
          withAsterisk
        >
          <Group style={{ fontSize: '0.8rem', lineHeight: '1.6rem' }}>{PermissionIdentifierItem.name}&nbsp;</Group>
        </Checkbox.Group>
      </td>
      <td colSpan={5} className="text-left  input_title">
        <Checkbox.Group w={460} h={24} value={value} onChange={setValueCheckedFunction} withAsterisk>
          <Group w="100%">
            {PermissionAction.map((item, key) => {
              return (
                <Checkbox
                  w={50}
                  pt={4}
                  pl={20}
                  className="border-left"
                  indeterminate={SpecialDisabling(item)}
                  disabled={SpecialDisabling(item)}
                  key={PermissionIdentifierItem.id_permission_identifier + '-' + key}
                  value={item.id_permission.toString()}
                />
              )
            })}
          </Group>
        </Checkbox.Group>
      </td>
      {isHovered && (
        <td className="permission-all">
          <Checkbox
            label={word_translation.all}
            disabled={SpecialDisablingAll}
            indeterminate={SpecialDisablingAll}
            checked={checked}
            onChange={event => setChecked(event.currentTarget.checked)}
          />
        </td>
      )}
    </tr>
  )
}

// 使用 React.memo 进行包装
export default React.memo(PermissionSetCheckBoxGroup)
