import {
  ActionIcon,
  Paper,
  Flex,
  Select,
  Button,
  Code,
  Grid,
  Group,
  LoadingOverlay,
  Box,
  Menu,
  Modal,
  ScrollArea,
  Table,
  Text,
  ThemeIcon,
  Slider,
  Divider,
} from "@mantine/core";
import { useEffect, useState, FormEvent } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { HintInfo, getWeeKAll, getYearAll } from "../../../utils/function";
import { SelectRole, selectApiRespone } from "../../../interface/Icommon";
import {
  regGetFilter,
  regProject,
  regProjectList,
  regReportEmployeeReport,
  regReportsByFilter,
  regUserAll,
} from "../../../api";
import { ResponseUserData } from "../../../interface/Iuser";
import { IconCheck, IconClipboardPlus, IconX } from "@tabler/icons-react";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { StatsRingCard } from "./statistics";
import { WeeklyTimeTrackerReports } from "../../../interface/Ireports";
import { IFilterStatistic } from "../../../interface/IfilterStatistic";
import { User, WeekReport } from "../../../interface/IReport";
import PageTitle from "../../../ttcomponents/PageTitle/PageTitle";

const stats = [
  {
    value: 447,
    label: "Remaining",
  },
  {
    value: 76,
    label: "In progress",
  },
];

export default function Reports() {
  // Use the useIntl hook to access the intl object
  const intl = useIntl();

  //Get all the weekly collection
  const weekNumberAll = getWeeKAll();
  //Get all the Year collection
  const yearNumberAll = getYearAll();
  // Get all the project collection
  const [ProjectList, setProjectList] = useState<SelectRole[]>([]);
  // Get all employees
  const [userlistnew, setUserList] = useState<SelectRole[]>([]);
  const [WeeklyTimeTracker, SetWeeklyTimeTracker] = useState<
    WeeklyTimeTrackerReports[]
  >([]);
  const [WeekReportData, SetWeekReport] = useState<User[]>([]);
  const [WeekTitle, SetWeekTitle] = useState<Number[]>([]);

  const [filterUserList, setFilterUserList] = useState<SelectRole[]>([]);
  //Definition of project variables with filtration exclusion
  const [filterProject, setProjectFilter] = useState<SelectRole[]>([]);
  //Mid -load state
  const [loading, setLoading] = useState(false);
  // The data is initialized after the page is loaded
  const ajaxCurrentList = async () => {
    setLoading(true);
    //Get all the project lists
    const responeseProjectListData = await regProjectList({});
    const ProjectList = responeseProjectListData.data as selectApiRespone;
    const projectListInfo = ProjectList.data.map(
      (item: { id_project: string; name: any }) => ({
        value: item.id_project as string,
        label: item.name,
      })
    );
    setProjectList(projectListInfo);
    const responseUser = await regUserAll({});
    const resultUser = responseUser.data as ResponseUserData;
    const userData = resultUser.data.map((item) => ({
      value: item.id_user as string,
      label: item.username,
    }));
    setUserList(userData);
    setLoading(false);
    const resFilter = await regGetFilter();
    const { data } = resFilter.data as IFilterStatistic;
    // Get user filtering row
    const users_filter = data.filter((item) => {
      return item.type === "2";
    });
    //Only obtain the request of the exclusion project
    const resExcludeProjectFilter = data.filter((item) => {
      return item.is_filter_include === "2";
    });
    // Variable assignment
    setFilterUserList(
      users_filter.map((item) => {
        return {
          label: item.filter_name,
          value: item.id_filter,
        };
      })
    );
    // Variable assignment
    setProjectFilter(
      resExcludeProjectFilter.map((item) => {
        return {
          label: item.filter_name,
          value: item.id_filter,
        };
      })
    );
  };
  useEffect(() => {
    ajaxCurrentList();
  }, []);
  // Form submission
  const form = useForm({
    initialValues: {
      year: "",
      item_filter_id: "",
      start_week: "",
      end_week: "",
      user_filter_id: "",
      type: "user",
      id_project: "",
    },
    validate: {
      end_week: (value, values) => {
        if (parseInt(values.start_week) > parseInt(value)) {
          return "The end week must be greater than the beginning week";
        }
        return null;
      },
    },
  });

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Stop the formal submission of the form default
    // Verification form
    const valid = await form.validate();

    if (!valid.hasErrors) {
      const response = await regReportsByFilter(form.values, "GET");
      const result = response.data;

      SetWeekReport(result.data.items_list);
      SetWeekTitle(result.data.week_number_list);
      if (HintInfo(result)) {
        SetWeeklyTimeTracker(result.data);
      }
    }
  };

  return (
    <Paper
      px={20}
      mb={20}
      style={{ overflowY: "auto", overflowX: "hidden", minHeight: "70vh" }}
    >
      <PageTitle title="EMPLOYEES REPORTS" lang_id="EMPLOYEES REPORTS" />
      <Box>
        <form onSubmit={handleFormSubmit}>
          <Group className="" mt={10} align="center">
            <Select
              placeholder={intl.formatMessage({
                id: "Select User Filter",
                defaultMessage: "Select User Filter",
              })}
              searchable
              {...form.getInputProps("user_filter_id")}
              data={filterUserList}
            />
            <Select
              placeholder={intl.formatMessage({
                id: "Exclude Project Filter",
                defaultMessage: "Exclude Project Filter",
              })}
              searchable
              {...form.getInputProps("item_filter_id")}
              data={filterProject}
            />
            <Select
              placeholder={intl.formatMessage({
                id: "Choose Year",
                defaultMessage: "Choose Year",
              })}
              w={150}
              required
              {...form.getInputProps("year")}
              searchable
              data={yearNumberAll}
            />
            <Select
              placeholder={intl.formatMessage({
                id: "Start Week",
                defaultMessage: "Start Week",
              })}
              searchable
              required
              w={130}
              {...form.getInputProps("start_week")}
              data={weekNumberAll}
            />
            {intl.formatMessage({
              id: "To",
              defaultMessage: "To",
            })}
            <Select
              placeholder={intl.formatMessage({
                id: "End Week",
                defaultMessage: "End Week",
              })}
              searchable
              required
              w={120}
              {...form.getInputProps("end_week")}
              data={weekNumberAll}
            />
            <Button type="submit" leftIcon={<IconClipboardPlus />}>
              {intl.formatMessage({
                id: "Generate Reports",
                defaultMessage: "Generate Reports",
              })}
            </Button>
          </Group>
        </form>
      </Box>
      <Box mt={20}>
        <ScrollArea mt={20}>
          {WeekReportData.length > 0 ? (
            <Table>
              <thead>
                <tr>
                  <th>User/Week</th>
                  {WeekTitle.length > 0
                    ? WeekTitle.map((item, index) => {
                        return (
                          <th>
                            Kw <>{item}</>
                          </th>
                        );
                      })
                    : ""}
                  <th>Total </th>
                </tr>
              </thead>
              <tbody>
                {WeekReportData.map((item, index) => {
                  return (
                    <>
                      <tr>
                        <td>
                          <Text className="title_text " size={13} color="dark">
                            {" "}
                            <strong>
                              {" "}
                              <u>{item.user_name}</u>
                            </strong>{" "}
                          </Text>
                          {item.week_report.map((sItem, sindex) => {
                            if (sindex > 0) return null;
                            return (
                              <>
                                {sItem.exclude_items.map((eitem, eindex) => {
                                  return (
                                    <Text
                                      className="title_text "
                                      size={13}
                                      color="blue"
                                    >
                                      {eitem.item_name}
                                    </Text>
                                  );
                                })}
                              </>
                            );
                          })}
                          <Text className="title_text " size={13} color="dark">
                            IST
                          </Text>
                          <Text className="title_text " size={13} color="dark">
                            {" "}
                            SOLL
                          </Text>
                          <Text className="title_text " size={13} color="dark">
                            {" "}
                            DIFF
                          </Text>
                          {/* <Text className="title_text " size={13} color="dark" > Check detailed</Text> */}
                        </td>
                        {item.week_report.map((sItem, sindex) => (
                          <td>
                            <Text
                              className="title_text "
                              size={12}
                              color="dark"
                            >
                              {" "}
                              {sItem.productivity}%
                            </Text>
                            {sItem.exclude_items.map((eitem, eindex) => {
                              return (
                                <Text
                                  className="title_text "
                                  size={12}
                                  color="blue"
                                >
                                  {" "}
                                  {eitem.time}
                                </Text>
                              );
                            })}
                            <Text
                              className="title_text "
                              size={12}
                              color="green"
                            >
                              {" "}
                              {sItem.actual_time}
                            </Text>
                            <Text
                              className="title_text "
                              size={12}
                              color="dark"
                            >
                              {" "}
                              {sItem.target_time}
                            </Text>
                            <Text
                              className="title_text "
                              size={12}
                              color={
                                sItem.diff_time.includes("-") ? "red" : "dark"
                              }
                            >
                              {" "}
                              {sItem.diff_time}
                            </Text>
                          </td>
                        ))}
                        <td>
                          <Text className="title_text " size={12} color="dark">
                            {" "}
                            {item.week_report_total.productivity}%
                          </Text>
                          <Text className="title_text " size={12} color="dark">
                            {" "}
                            {item.week_report_total.actual_time}
                          </Text>
                          <Text className="title_text " size={12} color="dark">
                            {" "}
                            {item.week_report_total.target_time}
                          </Text>
                          <Text
                            className="title_text "
                            size={12}
                            color={
                              item.week_report_total.diff_time.includes("-")
                                ? "red"
                                : "dark"
                            }
                          >
                            {" "}
                            {item.week_report_total.diff_time}
                          </Text>
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </Table>
          ) : (
            <Box w="100%" className="text-center">
              {" "}
              <Divider
                my="xs"
                label={
                  <FormattedMessage id="No Data" defaultMessage="No Data" />
                }
                labelPosition="center"
              />
            </Box>
          )}
        </ScrollArea>
        {/* 一个人 */}
        {/* <Grid>
          {WeeklyTimeTracker.length > 0 ? WeeklyTimeTracker.map((item, index) => {
            return (
              <Grid.Col span={3} key={index}> <StatsRingCard item={item} title='week 1' completed="25:40" total={45} /></Grid.Col>
            )
          }) : <Box w='100%' className="text-center">    <Divider my="xs" label={<FormattedMessage id="No Data" defaultMessage="No Data" />} labelPosition="center" /></Box>}
        </Grid> */}
      </Box>
    </Paper>
  );
}
