import { Box, Flex, Group, Text } from "@mantine/core";
import { IconClockRecord, IconMoneybag } from "@tabler/icons-react";
import { FormattedMessage } from "react-intl";
interface SearchBoxProps {
  title?: any;
  py?: number;
  pb?: number;
  lang_id?: any;
  class_name?:string;
  icon_l?: any
}
export default function IconCardTitle({class_name, title, py, pb, icon_l, lang_id = '' }: SearchBoxProps) {
  return (
    <Group px="1rem">
    {icon_l} 
    <Text size={14} fw={700} color="customize-gray.4">
      {title}
    </Text>
  </Group>

  

  );
}
