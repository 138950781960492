import {
  Button,
  Group,
  Card,
  LoadingOverlay,
  TextInput,
  Select,
  Textarea,
} from "@mantine/core";
import {
  IconCheck,
  IconEdit,
  IconTrack,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import { FormEvent, useEffect, useRef, useState } from "react";

import { isEmail, useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { FormattedMessage, useIntl } from "react-intl";
import { regCustomerRole, regSupplierContact } from "../../../api";
import SaveBottom from "../../../ttcomponents/Button/SaveBtn";
import { SupplierContact } from "../../../interface/ISupplierContact";
import { HintInfo } from "../../../utils/function";

interface Role {
  id_customer_roles: string | number;
  role_name: string;
  role_desc: string;
}

// 定义接口，接收一个函数操作 Define the interface and receive a function operation
interface ContactRowFormProps {
  callBack: (value: boolean) => void;
  SupplierRowData: SupplierContact;
}

export default function SupplierContactForm({
  SupplierRowData,
  callBack,
}: ContactRowFormProps) {
  // 钩子函数-切换语言
  const intl = useIntl();

  const formRef = useRef<HTMLFormElement>(null);
  //加载中状态
  const [loading, setLoading] = useState(false);

  const form = useForm({
    validateInputOnBlur: true,
    initialValues: SupplierRowData,
    // functions will be used to validate values at corresponding key
    validate: {
      contact_email: isEmail("Invalid email"),
      contact_name: (value) => {
        if (!value) {
          return "Contact Role Name is required";
        }
        if (value.length < 2 || value.length > 100) {
          return "Contact Role Name must be between 2 and 200 characters";
        }
        return null;
      },
      contact_introduction: (value) => {
        if (value) {
          if (value.length > 800) {
            return "	Contact Role Desc  No more than 20 characters";
          }
        }
        return null;
      },
    },
  });
  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Stop the formal submission of the form default
    // Verification form
    const valid = await form.validate();
    if (!valid.hasErrors) {
      let originalData = {
        contact_name: form.values.contact_name,
        supplier_id: form.values.supplier_id,
        contact_introduction: form.values.contact_introduction,
        contact_email: form.values.contact_email,
        contact_phone: form.values.contact_phone,
      };
      let formData = {};
      const methodType = form.values.supplier_contact_id ? "PUT" : "POST";
      formData = form.values.supplier_contact_id
        ? {
            ...originalData,
            supplier_contact_id: form.values.supplier_contact_id,
          }
        : originalData;
      setLoading(true);
      const response = await regSupplierContact(formData, methodType);
      setLoading(false);
      HintInfo(response.data);
      if (response.data.code == 200) callBack(true);
    }
  };
  return (
    <form onSubmit={handleFormSubmit} ref={formRef}>
      <LoadingOverlay visible={loading} overlayBlur={2} />
      <TextInput
        mr={20}
        label={intl.formatMessage({
          id: "contact_name",
          defaultMessage: "Contact Role Name",
        })}
        w={300}
        {...form.getInputProps("contact_name")}
      />
      <TextInput
        mr={20}
        label={intl.formatMessage({
          id: "Email",
          defaultMessage: "Contact Email ",
        })}
        w={300}
        {...form.getInputProps("contact_email")}
      />
      <TextInput
        mr={20}
        label={intl.formatMessage({
          id: "phone",
          defaultMessage: "Contact Phone",
        })}
        w={300}
        {...form.getInputProps("contact_phone")}
      />
      <Textarea
        minRows={8}
        placeholder=""
        {...form.getInputProps("contact_introduction")}
        label={intl.formatMessage({
          id: "desc",
          defaultMessage: "Contact Introduction",
        })}
      />
      <Group position="right">
        <SaveBottom formRef={formRef} />
      </Group>
    </form>
  );
}
