import React, { ReactNode, FormEvent, useEffect, useRef, useState } from "react";
import {
  TextInput,
  Box,
  Paper,
  Grid,
  Group,
  Button,
  Select,
  Code
} from "@mantine/core";
import {
  hasLength,
  isNotEmpty,
  useForm,
} from "@mantine/form";
import { IconCheck, IconTrashX, IconX } from "@tabler/icons-react";
import { notifications } from "@mantine/notifications";
import { reAttribute, regGetUserRoleList } from "../../../api";
import { Attribute, AttributeType, UserRoleData } from "../../../interface/Iuser";
import TableTextInputTitle from "../../../ttcomponents/TableTextInputTitle/TableTextInputTitle";
import { useIntl } from "react-intl";
import WordTranslationComponent from "../../../utils/language_pack/words";
import CancelButton from "../../../ttcomponents/Button/CancelBtn";
import SaveBottom from "../../../ttcomponents/Button/SaveBtn";
import TableTopTitle from "../../../ttcomponents/TableTopTitle/TableTopTitle";
import ResetBtn from "../../../ttcomponents/Button/ResetBtn";
import { SelectRole } from "../../../interface/Icommon";
import { HintInfo, formatDateUpgraded, getAttributesPreviewComponent, getPreviewComponent } from "../../../utils/function";
import SubmitBtn from "../../../ttcomponents/Button/SubmitBtn";

interface ICustomAttrbutesForm {
  callFunction: () => void;
  attributeList: AttributeType[],
  current_item_info: Attribute | undefined
}

export default function CustomAttrbutesForm({
  callFunction,
  attributeList,
  current_item_info,
}: ICustomAttrbutesForm) {
  const word_translation = WordTranslationComponent();

  const [previewDom, setPreviewDom] = useState<ReactNode>(null);

  const [rand_number, setRandNumber] = useState<number>(1);

  const [attributeListData, setAttributeListData] = useState<SelectRole[]>([]);
  const form = useForm({
    initialValues: {
      id: '',
      type: '',
      name: '',
      description: '',
      // default: '',
    },
    // Validation rules for form fields
    validate: {
      name: hasLength(
        { min: 2, max: 100 },
        word_translation.attribute_key_validata
      ),
      //   role_original_name: isNotEmpty(
      //     word_translation.role_original_name_not_empty
      //   ),
    },
  });

  useEffect(() => {
    if (attributeList.length > 0) {
      const attributeListSelect = attributeList.map((item) => ({
        value: item.id_attribute_type.toString() as string,
        label: item.attribute_type_name,
        notation: item.attribute_type_notation,
      }));
      setAttributeListData(attributeListSelect)
    }
  }, [attributeList]);


  useEffect(() => {
    console.log(attributeListData)
    setFieldValueFunction('name', current_item_info ? current_item_info.name : '');
    setFieldValueFunction('description', current_item_info ? current_item_info.attribute_desc : '');
    // setFieldValueFunction('default', current_item_info ? current_item_info.default : '');
    // setFieldValueFunction('extra_option', current_item_info ? current_item_info.attribute_type_notation : '');
    setFieldValueFunction('type', String(current_item_info ? current_item_info.type : ''));
    setFieldValueFunction('id', current_item_info ? current_item_info.id_attribute : '');
    setRandNumber(rand_number + 1)
  }, [current_item_info]);

  // Handle form submission
  const handleFormSubmit = async () => {
    // // Validate form fields
    // const valid = form.validate();
    // if (valid.hasErrors) {
    //   // Display error notification if validation fails
    //   notifications.show({
    //     color: "yellow.7",
    //     icon: <IconX />,
    //     message: Object.values(valid.errors)[0],
    //   });
    //   return;
    // }
    // Determine submission type (POST or PUT)
    const submitType = formValue.id ? "POST" : "POST";
    // Perform API request to save user role data
    const response = await reAttribute(formValue, submitType);

    HintInfo(response.data);
    if (response.data.code == 200) {
      callFunction();
      resetFunction();
      setPreviewDom(null)
      setRandNumber(rand_number + 1)
    }
  };

    // Define all the list of employees you can choose
    const [formValue, setFormValue] = useState<any>({
      name: "",
      description: "",
      type: "",
  });
  /**
   * Reset formt form
   */
  const resetFunction = () => {
    form.reset()
  }


  /**
    * Set the value of the table field.
    * @param field_name 
    * @param field_value 
    */
  const setFieldValueFunction = (field_name: string, field_value: any) => {
    form.setFieldValue(field_name, field_value)
  }


  useEffect(() => {
    if (form.values.type) {
      const notation = attributeListData.find((item) => item.value === form.values.type)?.notation || '';
      // setPreviewDom(getAttributesPreviewComponent('', notation, form.values.name, form.values.default,
      setPreviewDom(getAttributesPreviewComponent('', notation, form.values.name,'',
        (id: string, newValue: string | number) => {
          console.log('Preview Success');
        }
      ))
    }
  }, [form.values.type, form.values.name]);

    /**
     * handle Data
     */
    const handleData = async (event: any,item:any='') => {
      console.log(event)
      var  name = item ;
      var  value = event;
      if(item){
          if(item == 'start_time' || item == 'end_time' ){
            value = formatDateUpgraded( value as Date, "YYYY-MM-DD")
          }
      }else{
          // 从 event.currentTarget 获取 name 和 value
            name = event.currentTarget.name;
            value = event.currentTarget.value;
      }
      // if((name =="work_hours_week" || name =="working_days_week" || name =="vacation_day_year" ) && !isNumber(value)){
      //     return ClientWarningHint(`${name} Must be a number`);
      // }
      // 更新状态
      setFormValue((prevData: any) => {
          return {
              ...prevData,
              [name]: value // 使用 name 作为属性名动态更新状态
          };
      });
  };
  // , form.values.default,
  return (
    <Paper p={10}>

      <TableTopTitle title={word_translation.create_user_role} />
      <Box className="pl-x-15 pt-xs pt-4">
        <Grid>

          <Grid.Col span={5} >
            <TableTextInputTitle title={word_translation.name} />
            <TextInput
              key={rand_number}
              defaultValue={form.values.name}
              name = 'name'
              onChange={(event) => { handleData(event) }}

              // onChange={(event) => setFieldValueFunction('name', event.currentTarget.value)}
            />
            <Box>
              <TableTextInputTitle title={word_translation.attribute_type} />
              <Select
                required
                key={rand_number}
                defaultValue={form.values.type}
                name='type'
                onChange={(event) => { handleData(event,'type') }}
                placeholder={word_translation.choose_attribute_type}
                data={attributeListData}
              />

            </Box>
            <TableTextInputTitle title={word_translation.preview} />
            <Box className="border-custom pt-4  px-15 border-radius-xs" mih={100} mah={150}>
              {previewDom}
            </Box>
          </Grid.Col>

          <Grid.Col span={6} >
            <TableTextInputTitle title={word_translation.description} />
            <TextInput
              key={rand_number}
              defaultValue={form.values.description}
              name='description'
              onChange={(event) => { handleData(event) }}
              // onChange={(event) => setFieldValueFunction('description', event.currentTarget.value)}
            />

            <Box>
              <TableTextInputTitle title={word_translation.default_value} />
              <TextInput
                key={rand_number}
                name='default'
                onChange={(event) => { handleData(event) }}
              /> </Box>
          </Grid.Col >
        </Grid>


      </Box>
      <Group className="m-xs" position="right">
        <ResetBtn callBack={() => resetFunction()} />
        <SubmitBtn btn_size="xs" callBack={handleFormSubmit} />
      </Group>

    </Paper>
  );
}
