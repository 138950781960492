import { Text, Box, ScrollArea, Table, Divider } from "@mantine/core";
import { TeamReport, TimeTrackerResponseCustomer } from "../../../interface/Ireports";
import React from "react";
import { TimeHHSS, formatTimeHHSS } from "../../../utils/function";


const ReportTemplateFour: React.FC<{ Data: TeamReport | undefined }> = ({ Data }) => {
  return (
    <ScrollArea mt={20}>
      Total productivity：{Data?.aggregate.percentage}%
      <Text  color="red" size={12}> (Projects actual time/target time)</Text>
      <Table my={20}>
        <thead>
          <tr>
            <th>Team Name</th>
            <th>Team productivity</th>
            {Data?.Projectaggregate.map((titleitem, titleindex) => {
              return (
                <>
                  <th>{titleitem.name}</th>
                </>
              )
            })}
            <th>Sick Time</th>
          </tr>
        </thead>
        <tbody>
          {Data?.team.map((item, index) => {
            return (
              <tr key={index}>
                <td>{item.team_name}</td>
                <td>{item.percentage}%</td>
                {item.porject_list.map((list, listindex) => {
                  return (
                    <>
                      <td>{formatTimeHHSS(list.actual_time as string)}</td>
                    </>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </Table>
      <Text size={12} color="red">Team productivity:The actual working time in the team members time area/ projects total actual time </Text>
    </ScrollArea>
  )
}

export default ReportTemplateFour;