import { Box, Button, Group, TextInput } from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

// 定义接口，接收一个函数操作 Define the interface and receive a function operation
interface SearchBoxProps {
  callBack: (value: string) => void;
}
export default function SearchBox({ callBack }: SearchBoxProps) {
  // 钩子函数-切换语言
  const intl = useIntl();

  // 声明搜索内容 State the search content
  const [search, setSearch] = useState("");
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSearch(value);
    callBack(value as string);
  };
  // 点击搜索按钮，回传搜索值 Click the search button to return the search value
  const handleClickChange = () => {
    // 将搜索内容返回 Return the search content
  };
  return (
    <Box>
      <Group>
        <Box>
          <TextInput
            placeholder={intl.formatMessage({
              id: "Search",
              defaultMessage: "Search",
            })}
            w={300}
            icon={<IconSearch size="0.9rem" stroke={1.5} />}
            value={search}
            onChange={handleSearchChange}
          />
        </Box>
        {/* <Box h={30}>
                    <Button
                        color="customize.1"
                        className="btnHover"
                        uppercase
                        onClick={handleClickChange}
                    >
                        <FormattedMessage id="Search" defaultMessage="Search" />
                    </Button>
                </Box> */}
      </Group>
    </Box>
  );
}
