import { Dropzone, DropzoneProps, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { Group, Text, Box, rem, Image } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { regImgUpload } from '../../../api';
import { IconAlertTriangleFilled, IconCheck, IconPhoto, IconUpload, IconX } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import { useNavigate } from 'react-router-dom';import {
    Tooltip,
  } from "@mantine/core";
import WordTranslationComponent from '../../../utils/language_pack/words';
import { HintErrorMantineInfo, HintInfo } from '../../../utils/function';

interface UploadSingleImageComponentProps {
    callback: (value: string) => void;
    imagepath?:string;
    w?:number;
    h?:number;
    is_custome?:boolean;
    wimage?:number;
    himage?:number;
}
// const UploadSingleImage: React.FC<UploadSingleImageComponentProps & Partial<DropzoneProps>> = (props) => {

const UploadSingleImage = ({imagepath,w,h,wimage,is_custome=false,himage,callback}:UploadSingleImageComponentProps) => {
  // Get translation public configuration information
    const word_translation = WordTranslationComponent();
    const navigate = useNavigate();
    const [selectedImage, setSelectedImage] = useState(''); 

    useEffect(() => {
        setSelectedImage(imagepath || '');
      }, [imagepath]);

    const handleImageUpload = async (files: File[]) => {
        const file = files[0];
        const path = URL.createObjectURL(file); // create URL for uploaded file
        setSelectedImage(path);
        const formData = new FormData();
        formData.append('image', file);
        const response = await regImgUpload(formData, 'POST');
    
        HintInfo(response.data);
        if (response.data.code == 200){  
            const result = response.data;
            const url = result.data.url;
            callback(url as string);
        } else if(response.data.code == 401) {
            navigate('/login');
        }
    };

    const onRejectFunction = async (files:any) => {
        if (typeof files === 'object') {
            console.log(files[0].errors[0].code)
            if(files[0].errors[0].code && files[0].errors[0].code =='file-too-large'){
                notifications.show({
                    color: "yellow.7",
                    icon: <IconAlertTriangleFilled />,
                    message:word_translation.file_size_1mb_tips,
                  });
            }
        }
    };
    return (
        <Dropzone
            style={{ width:wimage, height:himage}}
            p={0}
            radius={is_custome ? (wimage as number) / 2 :'md'} 
            onDrop={(files) => handleImageUpload(files)}
            onReject={(files) => onRejectFunction(files)}
            maxSize={2 * 1024 ** 2}
            accept={IMAGE_MIME_TYPE}
            sx={(theme) => ({
                minHeight: rem(120),
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: 0,
                // backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        
                '&[data-accept]': {
                //   color: theme.white,
                //   backgroundColor: theme.colors.blue[6],
                },
        
                '&[data-reject]': {
                //   color: theme.white,
                //   backgroundColor: theme.colors.red[6],
                },
              })}
        >
            <Group position="center" spacing="xl" style={{  pointerEvents: 'none' }}>
     
                {selectedImage  ? (
                      <Tooltip label={word_translation.company_logo}>
                          <Image  alt='111'   mx="auto" radius={is_custome? (wimage as number - 10) / 2 : 'md'}   src={selectedImage}  width={wimage as number - 10} height={himage as number - 5}/>
                     </Tooltip>
                ) : (
                    <>
                        <Box p={20}>
                            <Text size="xl" inline>
                                {word_translation.drag_img_tips}
                            </Text>
                            <Text size="sm" color="dimmed" inline mt={7}>
                                {word_translation.file_size_1mb_tips}
                            </Text>
                        </Box>
                    </>
                )}
            </Group>
        </Dropzone>
    );
};

export default UploadSingleImage;
