import {
  TextInput,
  Group,
  Grid,
  Code,
  Paper,
  Textarea,
  Title,
  Button,
  Box,
  Table,
  ActionIcon,
  Divider,
} from "@mantine/core";
import { FormEvent, useRef, useState } from "react";
import { hasLength, isEmail, isNotEmpty, useForm } from "@mantine/form";
import SaveBottom from "../../../ttcomponents/Button/SaveBtn";
import CancelButton from "../../../ttcomponents/Button/CancelBtn";
import { Contact, Supplier } from "../../../interface/Iinventory";
import { notifications } from "@mantine/notifications";
import {
  Icon123,
  IconCheck,
  IconTextPlus,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import { Left } from "../../time_master/svg/l";
import {
  isValidEmail,
  isNotEmpty as isNotEmptyCustom,
  isValidUsername,
} from "../../../utils/validataUnits";
import { regSupplier } from "../../../api";
import { useIntl } from "react-intl";

interface ISupplierFormProps {
  closeFucntion: (type: number) => void;
  supplieritem: Supplier | undefined;
}

interface SupplierFormValues {
  supplier_id: number | undefined;
  supplier_name: string | undefined;
  tax_identification_number: string | undefined;
  address: string | undefined;
  city: string | undefined;
  postal_code: string | undefined;
  tel: string | undefined;
  email: string | undefined;
  contact: Contact[]; // 定义 contact 为 Contact 数组
}

export default function SupplierForm({
  closeFucntion,
  supplieritem,
}: ISupplierFormProps) {
  // Use the useIntl hook to access the intl object
  const intl = useIntl();

  const formRef = useRef<HTMLFormElement>(null);

  const [contactName, setContactName] = useState("");
  const [contactIntroduction, setContactIntroduction] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactPhone, setContactPhone] = useState("");

  const form = useForm<SupplierFormValues>({
    initialValues: {
      supplier_id: supplieritem?.supplier_id,
      supplier_name: supplieritem?.supplier_name,
      tax_identification_number: supplieritem?.tax_identification_number,
      address: supplieritem?.address,
      city: supplieritem?.city,
      postal_code: supplieritem?.postal_code,
      tel: supplieritem?.tel,
      email: supplieritem?.email,
      contact: [],
    },
    validate: {
      supplier_name: hasLength(
        { min: 2, max: 100 },
        "Supplier must be 2-10 characters long"
      ),
      address: isNotEmpty("Enter supplier current address"),
      tel: isNotEmpty("Tel supplier current address"),
      email: isEmail("Invalid email"),
    },
  });

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Stop the formal submission of the form default
    // Verification form
    const valid = form.validate();
    if (valid.hasErrors) {
      notifications.show({
        color: "yellow.7",
        icon: <IconX />,
        message: Object.values(valid.errors)[0],
      });
      return;
    }
    let submit_type = "POST";
    if (form.values.supplier_id) {
      submit_type = "PUT";
    }

    const response = await regSupplier(form.values, submit_type);
    if (response.data.code == 200) {
      closeFucntion(2);
      notifications.show({
        color: "green",
        icon: <IconCheck />,
        message: response.data.msg,
      });
    } else {
      notifications.show({
        color: "yellow.7",
        icon: <IconX />,
        message: response.data.msg?response.data.msg:response.data.data.database_returns_results,
      });
    }
  };

  const addContact = () => {
    // Check whether there is a contact person with the same name
    const existingContact = form.values.contact.find(
      (c) => c.contact_name === contactName
    );

    if (existingContact) {
      // If a contact with the same name already exists, display a notification and return
      notifications.show({
        color: "yellow.7",
        icon: <IconX />,
        message: "A contact with the same name already exists",
      });
      return;
    }

    // Check if the email is valid
    if (isValidEmail(contactEmail) === false) {
      notifications.show({
        color: "yellow.7",
        icon: <IconX />,
        message: "The email address is invalid",
      });
      return;
    }

    // Check if the contact person is valid
    if (
      isNotEmptyCustom(contactName) === false &&
      isValidUsername(contactName) === false
    ) {
      notifications.show({
        color: "yellow.7",
        icon: <IconX />,
        message: "The contact person is invalid",
      });
      return;
    }

    // Create a contact object
    let contactItem = {
      contact_name: contactName,
      contact_introduction: contactIntroduction,
      contact_phone: contactPhone,
      contact_email: contactEmail,
    };

    // Add the contact object to the form
    form.setFieldValue("contact", [contactItem, ...form.values.contact]);
  };

  const rows = form.values.contact.map((element, index) => (
    <tr key={element.contact_name}>
      <td>{element.contact_name}</td>
      <td>{element.contact_introduction}</td>
      <td>{element.contact_phone}</td>
      <td>{element.contact_email}</td>
      <td>
        <ActionIcon
          onClick={() => {
            delHandle(index);
          }}
        >
          <IconTrash size="1rem" color="red" stroke={1.5} />{" "}
        </ActionIcon>
      </td>
    </tr>
  ));

  //Delete contact
  const delHandle = (indexs: number) => {
    const newData = form.values.contact.filter(
      (item, index) => index !== indexs
    );
    form.setFieldValue("contact", newData);
  };

  return (
    <Paper p={10}>
      <form onSubmit={handleFormSubmit} ref={formRef}>
        <Grid>
          <Grid.Col span={6}>
            <TextInput
              {...form.getInputProps("supplier_name")}
              placeholder="Supplier Name"
              label={intl.formatMessage({
                id: "Supplier Name",
                defaultMessage: "Supplier Name",
              })}
              radius="md"
              style={{ width: "100%" }}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              {...form.getInputProps("tax_identification_number")}
              placeholder="TIN"
              label="TIN"
              radius="md"
              style={{ width: "100%" }}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              {...form.getInputProps("address")}
              placeholder="Address"
              label="Address"
              radius="md"
              style={{ width: "100%" }}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              {...form.getInputProps("city")}
              placeholder="City"
              label="City"
              radius="md"
              style={{ width: "100%" }}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              {...form.getInputProps("postal_code")}
              placeholder="Postal Code"
              label="Postal Code"
              radius="md"
              style={{ width: "100%" }}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              {...form.getInputProps("tel")}
              placeholder="Tel"
              label="Tel"
              required
              radius="md"
              style={{ width: "100%" }}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              {...form.getInputProps("email")}
              placeholder="Email"
              label="Email"
              radius="md"
              style={{ width: "100%" }}
            />
          </Grid.Col>
        </Grid>
        {form.values.supplier_id ? null : (
          <Box>
            <Title my={5} order={6}>
              {" "}
              {intl.formatMessage({
                id: "Add Supplier Contents",
                defaultMessage: "Add Supplier Contents",
              })}
            </Title>
            <Box className="border border-1" p={10}>
              <Grid gutter={4} grow>
                <Grid.Col span={12}>
                  <Grid gutter={6}>
                    <Grid.Col span={3}>
                      <TextInput
                        // {...form.getInputProps("contact_name")}
                        placeholder="Content Name"
                        radius="md"
                        onChange={(event) =>
                          setContactName(event.currentTarget.value)
                        }
                      />
                    </Grid.Col>
                    <Grid.Col span={3}>
                      <TextInput
                        placeholder="Content Phone"
                        radius="md"
                        onChange={(event) =>
                          setContactPhone(event.currentTarget.value)
                        }
                      />
                    </Grid.Col>
                    <Grid.Col span={3}>
                      <TextInput
                        placeholder="Content Email"
                        radius="md"
                        onChange={(event) =>
                          setContactEmail(event.currentTarget.value)
                        }
                      />
                    </Grid.Col>
                  </Grid>
                </Grid.Col>
              </Grid>

              <Grid gutter={4} my={10} align="flex-end">
                <Grid.Col span={6}>
                  <Textarea
                    placeholder="Content Introduction"
                    onChange={(event) =>
                      setContactIntroduction(event.currentTarget.value)
                    }
                    radius="md"
                  />
                </Grid.Col>
                <Grid.Col span={2}>
                  <Button
                    onClick={addContact}
                    size="xs"
                    leftIcon={<IconTextPlus />}
                  >
                    add
                  </Button>
                </Grid.Col>
              </Grid>
              {form.values.contact.length > 0 ? (
                <Table>
                  <thead>
                    <tr>
                      <th>Element position</th>
                      <th>Element name</th>
                      <th>Symbol</th>
                      <th>Atomic mass</th>
                      <th>
                        {intl.formatMessage({
                          id: "Option",
                          defaultMessage: "Option",
                        })}
                      </th>
                    </tr>
                  </thead>
                  <tbody>{rows}</tbody>
                </Table>
              ) : (
                <Divider
                  my="xs"
                  c="#ccc"
                  label="You can add contacts"
                  labelPosition="center"
                />
              )}
            </Box>
          </Box>
        )}

        <Group position="right">
          <CancelButton callBack={() => closeFucntion(1)} />
          <SaveBottom formRef={formRef} />
        </Group>

        {/** can see form values */}
        {/* <Code block mt={5}>
          {JSON.stringify(form.values, null, 2)}
        </Code> */}
      </form>
    </Paper>
  );
}
