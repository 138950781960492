import React from 'react';
import {
  BarChart,
  Bar,
  Brush,
  ReferenceLine,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Area,
} from 'recharts';
import { EmployeeOvertimeReportChart } from '../../../interface/IReport';


interface ComponentInterface{
  datas : EmployeeOvertimeReportChart[] | undefined
}

export default function EmployeeOvertime({datas}:ComponentInterface) {
  console.log(datas)
  return (
    <BarChart
      width={1200}
      height={700}
      data={datas}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="week" />
      <YAxis />
      <Tooltip />
      <Area type="monotone" dataKey="uv" fill="#1c7ed6" stroke="#1c7ed6" />
      <Legend verticalAlign="top" wrapperStyle={{ lineHeight: '40px' }} />
      <ReferenceLine y={0} stroke="#000" />
      <Brush dataKey="week" height={30} stroke="#1c7ed6" />
      <Bar  dataKey="ActualTime" fill="#1c7ed6" />
    </BarChart>
  );
};

