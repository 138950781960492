import { ActionIcon,Text, Avatar, Box, Grid, Paper, Tooltip, Progress, Group, Divider, Badge, Modal, Flex } from "@mantine/core";
import PageTitle from "../../../../ttcomponents/PageTitle/PageTitle";
import WordTranslationComponent from "../../../../utils/language_pack/words";
import PaginationA from "../../../../ttcomponents/Pagination/PaginationA";
import { IconAdjustmentsHorizontal, IconArrowBigDownLine, IconArrowBigUpLine, IconArrowsMaximize, IconCalendarTime, IconChecklist, IconEdit, IconEye, IconGripVertical, IconPencil, IconTrash } from "@tabler/icons-react";
import '../css/index.css'
import UniversalButton from "../../../../ttcomponents/Button/UniversalButton";
import AssignmentOrderRow from "../order/order_item_row";
import { useEffect, useRef, useState } from "react";
import AssignmentTaskRow from "../task/task_item_row";
import ColorSelect from "../color_modal";
import { useClickOutside, useDisclosure } from "@mantine/hooks";
import { AssignmentsInterface, ProjectDataInterface } from "../../../../interface/Iprojects";
import { HintInfo, formatTimeHHSS, getAssignmentStatusColorByValue, getAssignmentStatusLabelByValue, truncateString } from "../../../../utils/function";
import { RootState } from "../../../../state_management/store";
import { useDispatch, useSelector } from "react-redux";
import AvatarGroupComponnet from "../../../../ttcomponents/AvatarGroup/AvatarGroup";
import EditIconPerm from "../../../../ttcomponents/EditIcon/EditIconPerm";
import React from "react";
import DeleteIconPerm from "../../../../ttcomponents/DeleteIcon/DeleteIconPerm";
import ProjectCreate, { ChildMethods } from "./project_create";
import { UserAllList } from "../../../../interface/Iuser";
import { SelectRole } from "../../../../interface/Icommon";
import { getUserGeneralFunction } from "../../../../api/common_request";
import { getCustomerListGeneralFunction, getProjectDetailByIdFunction } from "../../../../api/assignments_request";
import ModalTitleText from "../../../../ttcomponents/ModalTitleText/ModalTitleText";
import OrderFrom from "../order/order_create";
import TableTd from "../../../../ttcomponents/TableTrTd/TableTd";
import { setRefreshStatus } from "../../../../state_management/features/assignments_refresh_status/assignmentsRefreshStatusSlice";
import ViewIcon from "../../../../ttcomponents/ViewIcon/ViewIcon";
import { OrderDataInterface } from "../../../../interface/Iorder";
import ProjectInfo from "./project_info";
import { getSystemAssignmentsFunction } from "../../../../api/core_request";
import { setActionIndexStatus } from "../../../../state_management/features/action_index/actionIndexSlice";
import AssignmentTaskCategoryRow from "../task_category/task_category";
import CheckPermissionTools from "../../../../utils/permission";
import { setCorlorSeting } from "../../../../api";
import { requestProject } from "../../../../api/index_v2";
import { modals } from "@mantine/modals";
import GlobalCoreSetting from "../../../../utils/globalCortSetting";



interface ComponentProps{
    row:AssignmentsInterface;
    currentIndex:number;
    callBackFuction:(value:any)=>void
}
export default function AssignmentProjectRow({row,currentIndex,callBackFuction}:ComponentProps){
    
  // Get the global configuration
  const globalCoreSetting = GlobalCoreSetting();
const Perm = CheckPermissionTools();
    // Get translation public configuration information
  const word_translation = WordTranslationComponent();
   // 控制组建内的展开状态
  const [isExpanded, setIsExpanded] = useState(false);
  //展开单个的记录
  const expandItemFunction =()=>{
    setIsExpanded(!isExpanded)
  }
  const [openOrderFormStatus, { open: openCurrentPageOrderForm, close: closeOrderPageFormFunction }] = useDisclosure(false);

  const dispatch = useDispatch();
  // 获取redux store中的值
  const expandStatus = useSelector((state:RootState)=>state.expandStatus.expandStatus)
  
  //  Define current  project info  in the page
  const [currentProjectInfo,setCurrentProjectInfo] = useState<ProjectDataInterface>();

  //  Define current  order info  in the page
  const [currentOrderInfo,setCurrentOrderInfo] = useState<OrderDataInterface>();
  const [openProjectInfoStatus, { open: openProjectInfo, close: closeProjectInfo }] = useDisclosure(false);
  const [allUserList, setAllUserList] = useState<UserAllList[]>([]);

  // Define all the customer lists that can be selected
  const [customerList, setCustomerList] = useState<SelectRole[]>([]);
  // Define all the list of employees you can choose
  const [userList, setUserList] = useState<SelectRole[]>([]);

  const [openProjectFormStatus, { open: openCurrentPageProjectForm, close: closeProjectPageFormFunction }] =
    useDisclosure(false);

    const [colorLoading, setColorLoading] = useState(false);
 // 用redux中的状态值来控制组件的展开状态
  useEffect(()=>{
    setIsExpanded(expandStatus)
  },[expandStatus])
  /**
   * useState Hook用于管理组件的状态
   * @param {boolean} isHovered - 当前鼠标是否悬停在元素上
   * @returns {Array} - 包含isHovered和setIsHovered两个值的数组
   */
  const [isHovered, setIsHovered] = useState(false);
// 自定义的颜色和文本选择
  const [backgroundColor, setBackgroundColor] = useState(row.bg_color);
  const [textColor, setTextColor] = useState(row.text_color);

  // 打开颜色设置组件
  const [opened, setOpened] = useState(false);
  // 控制行内 背景颜色
  const changeTextColorCallback = async (color:string) => {
    color && setBackgroundColor(color);
    if(color){
      let data  ={
        type:'project',
        p_id:row.project_id,
        color:color
      }
      setColorLoading(true)
      await setCorlorSeting(data,'POST')
      setColorLoading(false)
    }
  }
  // 控制行内 文字颜色
  const changeBgColorCallback =async  (color:string) => {
    color && setTextColor(color);
    if(color){
      let data  ={
        type:'project',
        p_id:row.project_id,
        content_color:color
      }
      setColorLoading(true)
      await setCorlorSeting(data,'POST')
      setColorLoading(false)
    }
  }
  const resetColorCallback = () =>{
    setBackgroundColor(row.bg_color);
    setTextColor(row.text_color);
  }

  const childRef = useRef<ChildMethods>(null);

  const [openSelectColorStatus, { open: openSelectColorModalFunction, close: closeSelectColorModalFunction }] =useDisclosure(false);
  /**
   * 当鼠标移入元素时，设置isHovered为true
   */
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  

  //Order update list key
  const [showORderStatus, setShowORderStatus] = useState(false);
  const getCurrentAssigment= async ()=>{
    let assignmentModals = await getSystemAssignmentsFunction();
    console.log(assignmentModals);
    if (assignmentModals.split(',').some((item: string) => ['1', '2', '4','6'].includes(item))) {
      setShowORderStatus(true)
    }else{
      setShowORderStatus(false)
    }
 
}
  // init Data
  useEffect(()=>{
    // load customer info
    // getCustomerList();
    getCurrentAssigment();
  },[])

  /**
   * 当鼠标移出元素时，设置isHovered为false
   */
  const handleMouseLeave = () => {
    setIsHovered(false);
    setOpened(false)  };
  const handleTrMouseLeave = () => {
      setIsHovered(false);
   };
    

 /**
 * Get a list of users
 */
 const getUser = async() =>{
    // Call the Get User Universal Selection function and set the user list
    let userList = await getUserGeneralFunction('all_info')
    setUserList(userList.map((item: { id_user: string; username: any }) => ({
        value: item.id_user as string,
        label: item.username,
    })));
    setAllUserList(userList);
}

const getCustomerList = async () => {
    setCustomerList(await getCustomerListGeneralFunction(""));
};

// get a  product detail by  project_id
const getProjectDeatil = async (project_id:number) =>{
    setCurrentProjectInfo(await getProjectDetailByIdFunction(project_id))
}



const createrOrderForm = (row:AssignmentsInterface)=>{
    openCurrentPageOrderForm();
    getProjectDeatil(row.project_id as number)
    getUser();
    getCustomerList();
}
const editHandleFunction = (row:AssignmentsInterface)=>{
  openCurrentPageProjectForm();
  // 确定修改的条目，修改成功后根据这个信息局部刷新
  dispatch(setActionIndexStatus({
      index: currentIndex,
      id: row.project_id,
      type: 'project',
  }));

  getProjectDeatil(row.project_id as number)
  getUser();
  getCustomerList();
}
const previewHandleFunction = async (row:AssignmentsInterface)=>{
  await getProjectDeatil(row.project_id as number)
  openProjectInfo();
}

const handleDel = (row:AssignmentsInterface)=>{
  modals.openConfirmModal({
    title: word_translation.delete_title,
    centered: true,
    children: (
        <Text size="sm" mt={12}>
            <p>  {word_translation.product_name} : {row.name}</p>
        </Text>
    ),
    labels: globalCoreSetting.DeleteOpenConfirmModaLabels,
    confirmProps: globalCoreSetting.DeleteOpenConfirmModaConfirmProps,
    onConfirm: async () => {
        try {
            const deleteTaskResult = await requestProject({ id: row.project_id }, "DELETE");
            HintInfo(deleteTaskResult.data);
            if (deleteTaskResult.data.code == 200) {
              callBackFuction(row.project_id)
            }
        } catch (error) {
            // Handle any errors that occur during the API call
            console.error('delete  project :', error);
            // Optionally, you can throw the error or handle it as needed
            throw error;
        }

    },
});
}
  // Project callback Function <FUNCTION>
const projectFormCallbackFunction =(project_id:any,from ='')=>{
  if(project_id == 'close'){
    closeProjectPageFormFunction()
  }else  if(project_id !== '-1'){
        // callBackFuction(true)
        dispatch(setRefreshStatus(true));
        if(from != 'project_task'){
          closeProjectPageFormFunction();
        }
  }
  // 确定修改的条目，修改成功后根据这个信息局部刷新
  dispatch(setActionIndexStatus({
      index: currentIndex,
      id: row.project_id,
      type: 'project',
  }));
}

  // Project callback Function <FUNCTION>
  const orderFormCallbackFunction =(project_id:any)=>{
    if(project_id !== '-1'){
        closeOrderPageFormFunction();
        dispatch(setRefreshStatus(true));
    }
  }


  const delAbled : boolean = (row.task_count == 0 &&  row.order_count == 0)

  
  return (
    <React.Fragment >
    <tr  style={{background:backgroundColor,borderRadius:10,marginBottom:10}} className="text-center assignment_laypuout tr-container  border-top-sheet assignment_project_item"  
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleTrMouseLeave}>
        <td style={{borderTopLeftRadius:'10px',borderBottomLeftRadius:'10px'}}  >
            <Box className="assignment_text_title text-center">
                <Group position="left" className="text_tetss"> 
                 <Box className="hoveer">
                    {isHovered &&
                     <ActionIcon onClick={() => setOpened(true)}>
                          <Tooltip label={word_translation.edit_tips}>
                              <IconGripVertical size="1.2rem" color="#7D7D7D" stroke={1.5} />
                          </Tooltip>
                      </ActionIcon>} 
                  </Box>
                  <Box ml={2}>
                    {truncateString(word_translation.project_name,20)}
                  </Box>
              </Group>
                
              </Box >
                <Box ml={2} w={200} style={{color:''}} className="text-left overhide assignment_text_content">
                  {row.name.length > 25 ?    <Tooltip label={row.name}>
                    <Text className="hide-excess-text" color={textColor}>{truncateString(row.name, 23)}</Text>
                  </Tooltip>: <Text className="hide-excess-text" color={textColor}>{truncateString(row.name, 25)}</Text>}
             
                </Box>
        </td>

        <td>
            <Box mr={20}>
                <ActionIcon onClick={() => expandItemFunction()}>
                    <Tooltip label={word_translation.expand_tips}>
                       {isExpanded ? <IconArrowBigUpLine size="1.2rem"  color='#7D7D7D' stroke={1.5} /> :<IconArrowBigDownLine size="1.2rem"  color='#7D7D7D' stroke={1.5} /> }
                    </Tooltip>
                </ActionIcon> 
            </Box>
        </td>
       
        <td>
            <Box  px={15} className="text-center"  >
              <Text  className="assignment_text_title" color={textColor}>{word_translation.customer_name} </Text>
            </Box>
         
            <Box  className="text-center"  px={15} style={{color:''}} >
                  {row.customer_name.length > 15 ?    <Tooltip label={row.customer_name}>
                    <Text  className="text assignment_text_content" color={textColor}>{truncateString(row.customer_name, 15)}</Text>
                  </Tooltip>:  <Text className="hide-excess-text assignment_text_content" color={textColor}>{truncateString(row.customer_name, 16)}</Text>}
             
            </Box>
        </td>
        {/* <TableTd font_color={textColor}  value={row.customer_name} title={word_translation.customer}  />   */}
        <TableTd  font_color={textColor}   value={row.project_no} title={word_translation.project_no} colspan={2} />
        <TableTd font_color={textColor}  value={  <AvatarGroupComponnet UserInfo={row.user_info}/>} title={word_translation.project_leader}  value_class="img-center"/>
        <TableTd class_name={'assignment_text_title'}   value={   <Badge  color= {getAssignmentStatusColorByValue(row.status_id)}> {getAssignmentStatusLabelByValue(row.status_id)}</Badge>}  title={word_translation.status}  />
        <TableTd font_color={textColor}  value={row.target_time?formatTimeHHSS(row.target_time):'00:00'}   title={word_translation.target_time} />     
        <TableTd font_color={textColor}  value={row.actual_time?formatTimeHHSS(row.actual_time):'00:00'}  title={word_translation.actual_time}  />    
        <TableTd font_color={textColor}  value={formatTimeHHSS(row.over_time)} title={word_translation.overtime}  />     
        <td>
            <Box mt={8} className="assignment_text_title">{word_translation.completion}</Box>
            <Box>      
            <span className="timevisual_blue"> <Text  size={10}>{row.completion?row.completion : 0 }% </Text> </span>
           </Box>
           <Box >
           <Progress
                 mt={3}
                value={row.completion}
                size="md"
                color="blue"
            />
           </Box>
                  
        </td>
       
       <TableTd font_color={textColor}  value={row.cost} title={word_translation.cost}  />     
       <TableTd font_color={textColor}  value={row.budget} title={word_translation.budget}  />   
       <TableTd font_color={textColor}  value={row.order_count} title={word_translation.orders}  /> 
       <TableTd font_color={textColor}  value={row.task_count} title={word_translation.tasks}  />     
        <td className="" style={{borderTopRightRadius:10,borderBottomRightRadius:10}}>
            <Box  className="assignment_text_title">{word_translation.option}</Box>
            <Flex justify='center'>
               {/* <ActionIcon onClick={() =>createrOrderForm(row)}>
                    <Tooltip label={word_translation.create_order}>
                        <IconCalendarTime size="1.2rem" color="#7D7D7D" stroke={1.5} />
                    </Tooltip>
                 </ActionIcon>  */}
       
                <EditIconPerm enable={Perm.admin||row.global_permissions?.update||  row.permission?.update} callBack={()=>editHandleFunction(row)} />
                <ViewIcon callBack={()=>previewHandleFunction(row)} />
                      {showORderStatus &&   <ActionIcon onClick={() =>createrOrderForm(row)}>
                    <Tooltip label={word_translation.create_order}>
                        <IconChecklist size="1.2rem" color="#7D7D7D" stroke={1.5} />
                    </Tooltip>
                 </ActionIcon> }
                <DeleteIconPerm  
                enable={ (Perm.admin||row.global_permissions?.delete || row.permission?.delete)&& delAbled  } callBack={()=>handleDel(row)}/>
        </Flex>
        </td>
          </tr>
          {isExpanded && row.child_list.map((item, index) => {
                  if (item.type == 'order') {
                      return (<React.Fragment key={index}>
                          <AssignmentOrderRow row={item} index={index} leve={2} /></React.Fragment>
                      )
                  }else if (item.type == 'task_category') {
                    return (<React.Fragment key={index}>
                        <AssignmentTaskCategoryRow row={item} index={index} leve={2} /></React.Fragment>
                    )
                } else {
                      return (<React.Fragment key={index}>
                          <AssignmentTaskRow row={item} index={index} leve={2} /></React.Fragment>
                      )
                  }
              })}
          {opened && (
              <Paper shadow="sm" className='hovered-button-cell-color'
                  onMouseLeave={handleMouseLeave}
              >
                <ColorSelect
                      objectsUsed={word_translation.task}
                      changeBgCallback={changeBgColorCallback}
                      changeTextCallback={changeTextColorCallback}
                      successCallback={() => { console.log('sucess') }}
                      closeFunction={closeSelectColorModalFunction}
                      resetCallback={resetColorCallback}
                      openFormStatus={opened} />
              </Paper>
          )}
     <Modal
        opened={openProjectFormStatus}
        onClose={closeProjectPageFormFunction}
        closeOnClickOutside={false}
        title={<ModalTitleText title={`${word_translation.project} ${word_translation.edit} - ${currentProjectInfo?.name}`} />}
        size={900}
        radius={10}
      >
        {/* Form Madal */}
        <ProjectCreate  
        callBackFunction={projectFormCallbackFunction} 
        ref={childRef} 
        projectInfoFromEditPage={currentProjectInfo}
        customerList={customerList}
        c_allUserList={allUserList} 
        c_userList={userList} /> 
      </Modal>

   
      <Modal
        opened={openOrderFormStatus}
        onClose={closeOrderPageFormFunction}
        closeOnClickOutside={false}
        title={<ModalTitleText title={`${word_translation.edit} - `} />}
        size='90%'
        radius={10}
      >
        {/* Form Madal */}
      <OrderFrom 
        callBackFunction={orderFormCallbackFunction} 
        ref={childRef} 
        disPalyCurrentPageBtn={true}
        project_id={currentProjectInfo?.id_project}
        customerList={customerList} 
        c_allUserList={allUserList} 
        c_userList={userList} /> 
      </Modal>

      <Modal
        radius="md"
        size="xl"
        opened={openProjectInfoStatus}
        onClose={closeProjectInfo}
        closeOnClickOutside={false}
        title={word_translation.preview}
      >
        <ProjectInfo
          row={currentProjectInfo as ProjectDataInterface }
        />
      </Modal>
      {/* <ProjectInfo > */}

    </React.Fragment>
    )
}

