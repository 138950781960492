import { HintInfo, formatDateWithIOS, formatTimeHHSS, getWeekDates } from "../../../../../utils/function";
import WordTranslationComponent from "../../../../../utils/language_pack/words";
import { ActionIcon, Box, Flex, Group, LoadingOverlay, Text, TextInput, Tooltip } from "@mantine/core";
import { useState } from "react";
import { IconCaretRightFilled, IconLicense, IconPencil } from "@tabler/icons-react";
import { TimeTrackerInterface } from "../../../../../interface/Itimetrack";
import SheetTableBodyTd from "./table_body_td";
import React from "react";
import DeleteIconPerm from "../../../../../ttcomponents/DeleteIcon/DeleteIconPerm";
import { modals } from "@mantine/modals";
import GlobalCoreSetting from "../../../../../utils/globalCortSetting";
import { requestTimeTrackerActionList } from "../../../../../api/index_v2";
import { setRefreshStatus } from "../../../../../state_management/features/assignments_refresh_status/assignmentsRefreshStatusSlice";
import { useDispatch } from "react-redux";
import PreSheetTd from "./pre_sheet_td";
import CheckPermissionTools from "../../../../../utils/permission";


// Define the props interface for the Component
interface ChildComponentProps {
    timeTracker: TimeTrackerInterface;
    publicDay: string[];
    vactionDay: string[];
    halfDay: string[];
    halPublicDay: string[];
    sickDay: string[];
    existMarkid:any;
      weeks: number;
    currentDate: Date;
}



// Component representing the SheetTableThead
const SheetTableBodyTr: React.FC<ChildComponentProps> = ({ weeks,existMarkid,timeTracker,sickDay,publicDay, vactionDay, halfDay, halPublicDay, currentDate }) => {
    // Get translation public configuration information
    const word_translation = WordTranslationComponent();
    const dispatch = useDispatch();
   // Get the global configuration
   const Perm = CheckPermissionTools();
   
   const globalCoreSetting = GlobalCoreSetting();
    let leve;
    switch (timeTracker.type) {
        case 'project':
        case 'order':
        case 'task':
        case 'sick_time':
            
        leve = 3;
        break;
        case 'project_order':
        case 'order_task':
        case 'project_task':
        leve = 2;
        break;
        default:
        leve = 1;
    }
    
  // Delete the delete  task
const handleDel = async (row: TimeTrackerInterface) => {
    modals.openConfirmModal({
        title: word_translation.delete_title,
        centered: true,
        children: (
            <Text size="sm" mt={12}>
                <p>  {word_translation.kw} : {row.week}</p>
            </Text>
        ),
        labels: globalCoreSetting.DeleteOpenConfirmModaLabels,
        confirmProps: globalCoreSetting.DeleteOpenConfirmModaConfirmProps,
        onConfirm: async () => {
            try {
                const deleteTaskResult = await requestTimeTrackerActionList({ id: row.id_time_tracker }, "DELETE");
                HintInfo(deleteTaskResult.data);
                if (deleteTaskResult.data.code == 200) {
                    dispatch(setRefreshStatus(true));
                }
            } catch (error) {
                // Handle any errors that occur during the API call
                console.error('Error fetching project list:', error);
                // Optionally, you can throw the error or handle it as needed
                throw error;
            }

        },
    });
};
    
const is_editAbled = timeTracker.sick_time_from_leave; 

    return (
        <>  
        <tr className={`sheet_tr ${timeTracker.id_time_tracker != 0 && timeTracker.id_time_tracker == existMarkid ? 'tr_active' : ''}`}>
            {leve == 1 && (
               <> 
                <PreSheetTd type={word_translation.project_name}  title={timeTracker.project_name as string} is_icon={false} />
                <PreSheetTd type={word_translation.order_name}  title={timeTracker.order_name as string} />
                <PreSheetTd type={word_translation.task_name}  title={timeTracker.task_category_identifier as string} />
                </>
            )}

            {leve == 2 && (
                <> 
                    <PreSheetTd type={timeTracker.type == 'order_task' ? <>{word_translation.order_name}</>:<> {word_translation.project_name}</>} title={timeTracker.type == 'order_task' ? timeTracker.order_name:timeTracker.project_name} is_icon={false}   />
                    <PreSheetTd type={timeTracker.type == 'project_order' ?  <>{word_translation.order_name}</>: <>{word_translation.task_name}</>} title={timeTracker.type == 'project_order' ? timeTracker.order_name:timeTracker.task_category_identifier}   />
                    <PreSheetTd type={timeTracker.type}  title={'ㅤㅤㅤ'}  is_icon={false}   />
                </>
             )}

            {leve == 3 && (
                <> 
                     <PreSheetTd  type={timeTracker.type == 'order' ?<>{word_translation.order_name}</>:timeTracker.type == 'project' ?<>{word_translation.project_name}</>: <>{word_translation.task_name}</> } title={timeTracker.type == 'order' ? timeTracker.order_name:timeTracker.type == 'project' ?timeTracker.project_name : timeTracker.task_category_identifier }  is_icon={false}   />
                     <PreSheetTd   title={'ㅤㅤㅤ'}  is_icon={false}   />
                     <PreSheetTd   title={'ㅤㅤㅤ'}  is_icon={false}   />
                </>
             )}
            <td  className="c_9BC6FF b_color sheet_td " >{formatTimeHHSS(timeTracker.total_time)}</td>
            {globalCoreSetting.KWS.map((item,index)=>{
                return (
                    <React.Fragment key={index}>
                           <SheetTableBodyTd 
                           is_editAbled={is_editAbled} 
                           timeTracker={timeTracker} 
                           current_day_of_week={item.label} 
                           index={index} 
                           weeks={weeks}
                           currentDate={currentDate as Date} 
                           publicDay={publicDay}
                           sickDay={sickDay}
                            vactionDay={vactionDay}
                             halfDay={halfDay} 
                             halPublicDay={halPublicDay}/>
                    </React.Fragment>
                )
            })}
          
         <td  className="sheet_td">
                <Group spacing={0} position="center">
                {timeTracker?.sick_time_from_leave == '1'  ?
                  <DeleteIconPerm enable={false} callBack={() => handleDel(timeTracker)}/>:
                  <DeleteIconPerm enable={ Perm.time_tracker_delete} callBack={() => handleDel(timeTracker)}/>
                }

                </Group>
             </td>
        </tr></>
    )

};

export default SheetTableBodyTr;
