import {
  Box,
  Code,
  Grid,
  Group,
  NumberInput,
  Paper,
  Select,
  TextInput,
  Textarea,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import CancelButton from "../../../../ttcomponents/Button/CancelBtn";
import SaveBottom from "../../../../ttcomponents/Button/SaveBtn";
import { FormEvent, useEffect, useRef, useState } from "react";
import { isNotEmpty, useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { IconCalendar, IconX } from "@tabler/icons-react";
import {
  HintInfo,
  TotalPriceFunction,
  calculateUnitPrice,
  formatDateUpgraded,
} from "../../../../utils/function";
import globalCortSetting from "../../../../utils/globalCortSetting";
import ProductSelect from "../../../../ttcomponents/Product/ProductSelect";
import {
  regCustomerList,
  regRentProduct,
  regReturnInternalUseProduct,
  regReturnRentProduct,
} from "../../../../api";
import { ResponseCustomerData } from "../../../../interface/Icoustomer";
import { SelectRole } from "../../../../interface/Icommon";
import { useIntl } from "react-intl";
interface deliverProductProps {
  additional_information: any;
  closeFucntion: (type: number) => void;
}
export default function ReturnInternalProductFrom({
  additional_information,
  closeFucntion,
}: deliverProductProps) {
  // Use the useIntl hook to access the intl object
  const intl = useIntl();

  const [customerlist, setCustomerList] = useState<SelectRole[]>([]);
  const formRef = useRef<HTMLFormElement>(null);

  const form = useForm({
    initialValues: {
      internal_use_id: additional_information?.internal_use_id,
      return_quantity: additional_information?.return_quantity,
      unit_price: calculateUnitPrice(
        additional_information.total_price,
        additional_information.quantity
      ),
    },
    validate: {
      unit_price: isNotEmpty(" Price name is required"),
      return_quantity: isNotEmpty("Renturn quantity  is required"),
      internal_use_id: isNotEmpty("internal_use_id  is required"),
    },
  });

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    // Stop the formal submission of the form default
    event.preventDefault();
    // Verification form
    const valid = form.validate();
    if (valid.hasErrors) {
      notifications.show({
        color: "yellow.7",
        icon: <IconX />,
        message: Object.values(valid.errors)[0],
      });
      return;
    }

    const updatedFormValues = {
      ...form.values,
    };

    const response = await regReturnInternalUseProduct(
      updatedFormValues,
      "PUT"
    );
    HintInfo(response.data);
    if (response.data.code == 200) {
      closeFucntion(2);
    }
  };

  // Get Timetrack Record
  const ajaxCurrentList = async () => {
    if (customerlist.length <= 0) {
      const responseCustomer = await regCustomerList({});
      const result = responseCustomer.data as ResponseCustomerData;
      if (result.code === 200) {
        const customerData = result.data.map((item) => ({
          value: item.id_customer as string,
          label: item.company_name,
        }));
        setCustomerList(customerData);
      }
    }
  };
  console.log(additional_information);
  useEffect(() => {
    // if(rental_status == true){
    ajaxCurrentList();
    // }
  }, []);
  return (
    <Paper p={10}>
      <form onSubmit={handleFormSubmit} ref={formRef}>
        <Grid>
          <Grid.Col span={12}>
            <TextInput
              value={additional_information.product_name}
              disabled
              label="Product  Name"
            />

            <TextInput
              value={additional_information.department}
              disabled
              label="department Name(ID)"
            />
            <TextInput
              value={additional_information.quantity}
              disabled
              label="Return Quantity"
            />

            <TextInput
              value={additional_information.total_price}
              disabled
              label="Total Price"
            />

            <NumberInput
              {...form.getInputProps("return_quantity")}
              label="Rent Quantity"
            />
            <TextInput
              {...form.getInputProps("unit_price")}
              label={`Unit Price (Reference unit price:  ${calculateUnitPrice(
                additional_information.total_price,
                additional_information.quantity
              )})`}
            />
          </Grid.Col>
        </Grid>

        <Group position="right">
          {/* <CancelButton callBack={() => closeFucntion(1)} /> */}
          <SaveBottom formRef={formRef} />
        </Group>

        {/** can see form values */}
        <Code block mt={5}>
          {JSON.stringify(form.values, null, 2)}
        </Code>
      </form>
    </Paper>
  );
}
