import {
  Button,
  Card,
  Divider,
  Flex,
  Grid,
  Group,
  Menu,
  MultiSelect,
  UnstyledButton,
  Select,
  Text,
  TextInput,
  ThemeIcon,
  Title,
  Box,
  ActionIcon,
  Badge,
} from "@mantine/core";
import {
  IconCheck,
  IconClockRecord,
  IconDotsVertical,
  IconClockHour7,
  IconPlus,
  IconRefresh,
  IconRefreshAlert,
  IconStepOut,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import { forwardRef, useEffect, useRef, useState } from "react";
import { SelectRole } from "../../interface/Icommon";
import { notifications } from "@mantine/notifications";
import {
  regOrderList,
  regTaskCategory,
  regTaskList,
  regTimeTrackRecordUp,
} from "../../api";
import { ITaskResponseNopage } from "../../interface/Itask";
import { ApiOrderResponse, IOrderProps } from "../../interface/Iorder";
import { ClientWarningHint, HintInfo, getWeekInfo } from "../../utils/function";
import Timer from "./timer";
import localstorageUnits from "../../utils/localstorageUnits";
import { useLocation, useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { getAllTaskCategoryGeneralFunction, getOrderListGeneralFunction, getProjectListGeneralFunction, getTaskListGeneralFunction } from "../../api/assignments_request";
import TableTextInputTitle from "../../ttcomponents/TableTextInputTitle/TableTextInputTitle";
import TableTextInputTitleDesc from "../../ttcomponents/TableTextInputTitleDesc/TableTextInputTitleDesc";
import WordTranslationComponent from "../../utils/language_pack/words";
import { requestTimeTrackerQuickLyAdd } from "../../api/index_v2";
import { getSystemAssignmentsFunction } from "../../api/core_request";
import CheckPermissionTools from "../../utils/permission";
interface LiveTrackingProps {}

interface TimeAddProps {
  projectData: SelectRole[];
  userTaskList: SelectRole[];
}
export default function LiveTracking({ projectData,userTaskList }: TimeAddProps) {
  // Hook function-switch language
  const intl = useIntl();
  // Get translation public configuration information
  const word_translation = WordTranslationComponent();

  const taskModeFlag = localstorageUnits.getSystem().task_mode_flag;

  const Perm = CheckPermissionTools()

  const navigate = useNavigate();
  const location = useLocation();
  const [activeIndex, setActiveIndex] = useState(" "); // The activation status of the management menu item
  const [subActiveIndex, setSubActiveIndex] = useState(" "); // The activation status of the management sub -menu item
  const [initiallyOpeneds, setInitiallyOpeneds] = useState("");
  const [isRefresh, setIsRefresh] = useState<Boolean>(true);
  //Definition of selected projects
  const [searchValue, onSearchChange] = useState<string | null>(null);
  // Define the order ID of selected
  const [searchOrderIdValue, onSearchOrderIdChange] = useState<string | null>(
    null
  );
  //Define the selected taskid
  const [searchTaskIdValue, onSearchTaskIdChange] = useState<string | null>(
    null
  );
  // Define the input time
  const [timeInput, setTimeInput] = useState<string>("");
  //Define the type filling in
  const [category, setCategory] = useState<string | null>(null);
  // Define the current year
  const [currentYear, setCurrentYear] = useState(0);
  const [isShowOrder, setIsShowOrder] = useState<boolean>(false); 
  // Define the current week
  const [currentWeek, setCurrentWeek] = useState(0);
  // Define the current week of day
  const [currentDayOfWeek, setCurrentDayOfWeek] = useState("");

  //Define What type of project is_clude_order or with order
  const [isOrderDisable, setIsOrderDisable] = useState(false);

  const [isSelectOrderStr, setIsSelectOrderStr] = useState(
    intl.formatMessage({
      id: "choose_a_order",
      defaultMessage: "choose_a_order",
    })
  );
  const [isSelectOrder, setIsSelectOrder] = useState(true);
  const [isSelectTaskStr, setIsSelectTaskStr] = useState(
    intl.formatMessage({
      id: "choose_a_task",
      defaultMessage: "choose_a_task",
    })
  );
  const [isSelectTask, setIsSelectTask] = useState(true);

  const getCurrentAssigment= async ()=>{
    let assignmentModals = await getSystemAssignmentsFunction();
    const assignmentModalsArray = assignmentModals.split(',');
    if (!assignmentModalsArray.some((item: string) => ['2', '1', '4', '5'].includes(item))) {
      setIsShowOrder(false);
    } else {
      setIsShowOrder(true);
    }
    
  }

  //Define the orderlist below the selected item
  const [ordernamelist, setOrderNameList] = useState<SelectRole[]>([]);
  // Define the selected project below the orderList below the tasklist
  const [orderTasklist, setOrderTaskList] = useState<SelectRole[]>([]);
  const [projectSelectValue, setProjectSelectValue] = useState<number>(0);
  const [orderSelectValue, setOrderSelectValue] = useState<number>(0);
  const [taskSelectValue, setTaskSelectValue] = useState<number>(0);
  //  Reset data
  const InitData = () => {
    setOrderTaskList([]);
    setOrderNameList([]);
    onSearchOrderIdChange("");
    setTimeInput("");
    setCategory("");
    setProjectSelectValue(projectSelectValue + 1);
    setOrderSelectValue(orderSelectValue + 1);
    setTaskSelectValue(taskSelectValue + 1);
  };
  const [projectNo, setProjectNo] = useState<string>(''); 
 
  //Click the function triggered when selecting the project. Value parameter is the project ID
  const selectProject = async (project_id: string | null) => {
    onSearchChange(project_id);
    // Determine the type of current project
    const filteredRoles = projectData.find((role) => role.value === project_id);
    const isOrderDisable = filteredRoles?.is_include_order === "1";
    setIsOrderDisable(isOrderDisable);
    if (isOrderDisable) {
      // Get the task type
      const cateGoryInfo = await regTaskCategory({});
      const resultTask = cateGoryInfo.data.data as ITaskResponseNopage;
      // The form format that can be identified as a component
      const OrdeTaskData = resultTask.data.map((item) => ({
        value: item.id_task_category as string,
        label: item?.identifier
          ? item.identifier
          : intl.formatMessage({
              id: "unknown_task_",
              defaultMessage: "unknown_task_",
            }) + item.id_task_category,
      }));
      setOrderTaskList(OrdeTaskData);
    } else {
      const responseUser = await regOrderList({ project_id: project_id });
      const resultOrderLists = responseUser.data as ApiOrderResponse;
      // The form format that can be identified as a component
      const OrderNameData = resultOrderLists.data.map((item) => ({
        value: item.id_order as string,
        label: item.order_name,
      }));
      setOrderNameList(OrderNameData);
    }
  };

  // 根据orderID获取某个订单下所有的TASKID
  const selectOrderList = async (order_id: string | null) => {
    onSearchOrderIdChange(order_id);
    // 获取项目下所有的OrderList
    const TaskresponseUser = await regTaskList({ order_id: order_id });
    const resultTask = TaskresponseUser.data as ITaskResponseNopage;
    // 组装成组件能识别的数组形式
    const OrdeTaskData = resultTask.data.map((item) => ({
      value: item.id_task as string,
      label: item.task_category.identifier
        ? item.task_category.identifier
        : intl.formatMessage({
            id: "unknown_task_",
            defaultMessage: "unknown_task_",
          }) + item.id_task,
    }));
    console.log(OrdeTaskData);
    setOrderTaskList(OrdeTaskData);
  };

  
  // Define all the project lists that can be selected
  const [projectList, setProjectList] = useState<SelectRole[]>([]);
  
  // Define all the order lists that can be selected
  const [orderList, setOrderList] = useState<SelectRole[]>([]);


  const [projectId, setProjectId] = useState<string | null>(null); 

  const [orderId, setOrderId] = useState<string | null>(null);

  const [taskId, setTaskId] = useState<string | null>(null); 

  const [randNumber, setRandNumber] = useState<number>(1); 


   // Execute the useeffect when the first load is loaded.
   const isInitialMount = useRef(true);


  useEffect(() => {
    if (!isInitialMount.current) {
    //  getTaskList({'order_id':orderId,})
    getOrderList({'project_id':projectId,})
    setOrderId('')
    setTaskId('')
    const selectedProject = projectList.find(item => item.value === projectId);
    if (selectedProject) {
      setProjectNo(selectedProject.project_no)
    } else {
      setProjectNo('-')
    }
    setRandNumber(randNumber+1)
    }
    isInitialMount.current = false;
  }, [projectId]);


  useEffect(() => {
    setTaskId('')
    setRandNumber(randNumber+1)
  },[orderId])


  const getOrderList = async (value: any) => {
    setOrderList(await getOrderListGeneralFunction(value))
  };


  const requestProjectListByUserId = async() =>{
    setProjectList(await getProjectListGeneralFunction(""));
    setRandNumber(randNumber+1)
  }


    // Submit the fast upload Timetrack record
    const createTimeTrackRecode = async () => {
      if (!timeInput) {
        ClientWarningHint(`${word_translation.time} ${word_translation.is_not_empty}`)
        return;
      }
      const originalData = {
        project_id: projectId,
        order_id: orderId,
        task_category_id: taskId,
        time: timeInput,
        day_of_week: currentDayOfWeek,
        week: currentWeek,
        year: currentYear,
      };
      const response = await requestTimeTrackerQuickLyAdd(originalData, "POST");
      const result = response.data;
      HintInfo(result);
      if (result.code === 200) {
        InitData();
      } 
    };
  
  // 获取当前的星期和年
  const getCurrentWeekNumber = () => {
    const { year, week, dayOfWeek } = getWeekInfo(new Date());
    setCurrentWeek(week);
    setCurrentYear(year);
    setCurrentDayOfWeek(dayOfWeek);
  };
  // 页面加载后渲染数据得出当前年和星期
  useEffect(() => {
    getCurrentWeekNumber();
    getCurrentAssigment()
  }, []);

  useEffect(() => {
    if (ordernamelist.length == 0) {
      setIsSelectOrder(true);
      setIsSelectOrderStr(
        intl.formatMessage({
          id: "No options",
          defaultMessage: "No options",
        })
      );
      setIsSelectTask(true);
      onSearchTaskIdChange("");
      setTaskSelectValue(taskSelectValue + 1);
      setOrderSelectValue(orderSelectValue + 1);
    } else if (ordernamelist.length == 1) {
      setIsSelectOrderStr(
        intl.formatMessage({
          id: "choose_a_order",
          defaultMessage: "choose_a_order",
        })
      );
      console.log(String(ordernamelist[0]["value"]));
      onSearchOrderIdChange(String(ordernamelist[0]["value"]));
      selectOrderList(String(ordernamelist[0]["value"]));
      setIsSelectOrder(false);
      setOrderSelectValue(orderSelectValue + 1);
    } else {
      setIsSelectOrderStr(
        intl.formatMessage({
          id: "choose_a_order",
          defaultMessage: "choose_a_order",
        })
      );
      setOrderSelectValue(orderSelectValue + 1);
      setTaskSelectValue(taskSelectValue + 1);
      setIsSelectOrder(false);
    }
  }, [ordernamelist]);

  useEffect(() => {
    if (orderTasklist.length == 0) {
      setIsSelectTask(true);
      setIsSelectTaskStr(
        intl.formatMessage({
          id: "No options",
          defaultMessage: "No options",
        })
      );
      setTaskSelectValue(taskSelectValue + 1);
    } else if (orderTasklist.length == 1) {
      setIsSelectTaskStr(
        intl.formatMessage({
          id: "choose_a_task",
          defaultMessage: "choose_a_task",
        })
      );
      console.log("22", String(orderTasklist[0]["value"]));
      onSearchTaskIdChange(String(orderTasklist[0]["value"]));
      setTaskSelectValue(taskSelectValue + 1);
      setIsSelectTask(false);
    } else {
      setIsSelectTaskStr(
        intl.formatMessage({
          id: "choose_a_task",
          defaultMessage: "choose_a_task",
        })
      );
      setTaskSelectValue(taskSelectValue + 1);
      setIsSelectTask(false);
    }
  }, [orderTasklist]);

  interface InavItem {
    label: string;
    icon: React.FC<any>;
    [key: string]: any;
  }

  const handleSubMenuClick = (navItem: InavItem) => {
    navigate(navItem.link);
    localstorageUnits.savePath({ currentUrl: navItem.label });

    let storagePath = localstorageUnits.getPath();
    if (storagePath && Object.keys(storagePath).length > 0) {
      storagePath.currentActiveIndex
        ? setActiveIndex && setActiveIndex(storagePath.currentActiveIndex)
        : setActiveIndex && setActiveIndex(storagePath.currentPreActiveIndex);
      if (storagePath.currentChildrenActivePath) {
        storagePath.currentChildrenActivePath.label
          ? setSubActiveIndex &&
            setSubActiveIndex(storagePath.currentChildrenActivePath.label)
          : setSubActiveIndex &&
            setSubActiveIndex(storagePath.currentPreChildrenActivePath.label);
      }
      setInitiallyOpeneds(
        storagePath.initiallyOpeneds ? storagePath.initiallyOpeneds : []
      );
    }
  };

  const refreshLiveTracking = () => {
    setIsRefresh(false);
    setTimeout(function () {
      setIsRefresh(true);
    }, 400);
  };


    // ***************************************************/
  // ***************************************************/
  // ***************************************************/
  interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
    image: string;
    label: string;
    project_no: string;
  }
  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ image, label, project_no, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          <div>
            <Text size="sm">{label}</Text>
            <Text size="xs" opacity={0.65}>
              {project_no}
            </Text>
          </div>
        </Group>
      </div>
    )
  );
  return (
    <>
      {isRefresh ? (
        <Card px={0} withBorder radius="md" shadow="sm">
          <Grid>
            <Grid.Col span={9}>
              <Group px="1rem">
                <IconClockRecord color="#989898" size="1.2rem" />
                <Text size={14} fw={700} color="customize-gray.4">
                  <FormattedMessage
                    id="quick_live_tracking"
                    defaultMessage="Quick - Live Tracking"
                  />
                </Text>
              </Group>{" "}
            </Grid.Col>
            <Grid.Col span="auto">
              <Group
                spacing={"xs"}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginRight: "0.1rem",
                }}
              >
                <IconRefresh
                  color="#989898"
                  onClick={refreshLiveTracking}
                  size="1rem"
                />

                <Menu
                  width={120}
                  position="bottom-end"
                  transitionProps={{ transition: "pop-top-right" }}
                  // onClose={() => setUserMenuOpened(false)}
                  // onOpen={() => setUserMenuOpened(true)}
                  withinPortal
                  shadow="md"
                >
                  <Menu.Target>
                    <IconDotsVertical color="#989898" size="1rem" />
                  </Menu.Target>
                  <Menu.Dropdown>
                    <Menu.Item
                      className="mantine-Menu-itemIcon-mr0"
                      onClick={() =>
                        handleSubMenuClick({
                          label: "TimeTrack",
                          icon: IconClockHour7,
                          link: "/timetracker",
                        })
                      }
                      icon={<IconClockHour7 size="0.8rem" stroke={1.5} />}
                    >
                      <Text mx={5} size="xs">
                        <FormattedMessage
                          id="timetrack"
                          defaultMessage="TimeTracker"
                        />
                      </Text>
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              </Group>
            </Grid.Col>
          </Grid>
          <Divider my="sm" />

          <Grid px="1rem">
            <Grid.Col span={6}>
              
              <TableTextInputTitle title={word_translation.project_name} />
              <TableTextInputTitleDesc title={word_translation.select_project} />
              <Select 
                  size='xs' 
                  searchable 
                  key={projectId}
                  className="time_sheet_home"
                  maxDropdownHeight={160}
                  rightSection={<ActionIcon size='xs' onClick={()=>setProjectId('')}> <IconX /></ActionIcon>}
                  onChange={setProjectId }
                  value={projectId}
                  onClick={requestProjectListByUserId}
                  data={projectList} 
                  
                  itemComponent={SelectItem}
                  filter={(value, item) =>{
                    return  item.label?.toLowerCase()?.includes(value.toLowerCase()?.trim())  ||item.project_no?.toLowerCase()?.includes(value.toLowerCase()?.trim()) 
                  }}
                  />

{isShowOrder && <>
              <TableTextInputTitle title="Project No." />
                  <TableTextInputTitleDesc title={word_translation.pick_one} />
                  <Select
                    size="xs"
                    required
                    maxDropdownHeight={140}
                    onChange={setOrderId}
                    defaultValue={orderId}
                    placeholder={word_translation.pick_one}
                    // disabled={orderList.length > 0 ? false:true}
                    key={randNumber}
                    data={orderList}
                  />
            </>}
            
           
                <TableTextInputTitle title={word_translation.task_name} />
                <TableTextInputTitleDesc title={word_translation.select_task} />
                <Select
                  size="xs"
                  required
                  // disabled={userTaskList.length > 0 ? false:true}
   
                  defaultValue={taskId}
                  className="time_sheet_home"
                  maxDropdownHeight={150}
                  onChange={setTaskId}
                  placeholder={word_translation.pick_one}
                  key={randNumber}
                  data={userTaskList}
                />

           
            </Grid.Col>
            <Grid.Col span={5}>
            <>
            <TableTextInputTitle py={17} title='Project No.' /> 
                         <TextInput
                        size="xs"
                        value={projectNo}
                        disabled />
            </>
            
              <Flex direction="column" >
                <Text size={12} fw={600} mt={5}>
               
                  <FormattedMessage
                    id="current_saved_time"
                    defaultMessage="Current Saved Time"
                  />
                </Text>
                
                <Text size={10} color="customize-gray.5">
                  <FormattedMessage
                    id="recorded_time"
                    defaultMessage="Recorded time"
                  />
                </Text>
                <Flex direction="column" justify="right">
                  <Text fw={700} size={14}>
                    + {timeInput}
                  </Text>
                  {/* <Text fw={700} size={14}>+ 00:00:00</Text>
                        <Text fw={700} size={14}>+ 00:00:00</Text> */}
                </Flex>
              </Flex>
            </Grid.Col>
          </Grid>
          <Grid px="1rem">
            <Grid.Col span={7}>
              <Timer callback={setTimeInput} />
            </Grid.Col>
            <Grid.Col
              span={5}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Flex>
                {/* <Button size='xs' disabled >
              <IconStepOut size="1rem" stroke={1.5} /> <Text ><FormattedMessage id="add" defaultMessage="Add" /></Text>
            </Button> */}
                <Button
                disabled={Perm.time_tracker_create == false}
                  onClick={createTimeTrackRecode}
                  size="xs"
                  color="customizegreen.0"
                >
                  <IconPlus size="1rem" stroke={1.5} />{" "}
                  <Text fw="700">
                    <FormattedMessage
                      id="save_records"
                      defaultMessage="Save Records"
                    />
                  </Text>
                </Button>
              </Flex>
            </Grid.Col>
          </Grid>
        </Card>
      ) : (
        ""
      )}
    </>
  );
}
