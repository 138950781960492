import { Card, Table } from "@mantine/core";
import {  ReportTaskInfo } from "../../../interface/Ireports";
import WordTranslationComponent from "../../../utils/language_pack/words";
import React from 'react';
import { ConvertTimeStringToDecimal, formatTimeHHSS } from "../../../utils/function";
interface ComponentInterface {
    taskInfo: ReportTaskInfo | undefined; // Array of MonthlyTotal objects or undefined
}

export default function ProjectTaskCategory({ taskInfo }: ComponentInterface) {
    // Get translation public configuration information
    const word_translation = WordTranslationComponent();
    return (
        <Card bg='#eee'>
            <Table>
                <thead>
                    <tr>
                        <th>Task</th>
                        {taskInfo?.title_x.map((item, index) => {
                            return <th key={index}>{item}</th>
                        })}
                    </tr>
                </thead>
                <tbody>
                    {taskInfo?.list.map((item, index) => {
                        return (
                            <><tr>
                                <td key={index}>{item.task_name}</td>
                                {item.list.map((item1, index1) => {
                                    return (
                                        <td> {ConvertTimeStringToDecimal(formatTimeHHSS(item1?.total as string))} &nbsp; {word_translation.hour}</td>
                                    )
                                })
                                }
                            </tr>  </>
                        )
                    })}
                </tbody>
            </Table>
        </Card>
    )
}
