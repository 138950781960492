import { AspectRatio, Box, Checkbox, Dialog, Grid, LoadingOverlay, Select, TransferListData } from "@mantine/core"
import TableTextInputTitle from "../../../../ttcomponents/TableTextInputTitle/TableTextInputTitle"
import WordTranslationComponent from "../../../../utils/language_pack/words";
import { useEffect, useState } from "react";
import { PermissionUserAllList, UserAllList } from "../../../../interface/Iuser";
import { SelectRole } from "../../../../interface/Icommon";
import UniversalButton from "../../../../ttcomponents/Button/UniversalButton";
import { isNotEmpty } from "@mantine/form";
import { ClientWarningHint } from "../../../../utils/function";
import TransferListUserComponent from "../../../../ttcomponents/TransferListUserComponent/TransferListUserComponent";
import TaskUserListComponent from "./task_user_list_component";
import { randomId, useDisclosure, useListState } from "@mantine/hooks";

interface ComponentInterface {
  projectUserRolelist: SelectRole[];
  existsUserList?: PermissionUserAllList[];
  UserList: UserAllList[];
  callBackFunction: (value: PermissionUserAllList[]) => void
}  

const initialValues = [
  { label: 'Read',identifier: '2', checked: true, is_edit:false, key: randomId() },
  { label: 'Update', identifier: '3',checked: false, is_edit:true,key: randomId() },
  { label: 'Delete', identifier: '4',checked: false, is_edit:true,key: randomId() },
];


export default function AddUserToTask({ projectUserRolelist, existsUserList, callBackFunction, UserList }: ComponentInterface) {
  // Get translation public configuration information
  const word_translation = WordTranslationComponent();

  const [userAllList, setUserAllList] = useState<UserAllList[]>([]);

  const [permissionUserAllList, setPermissionUserAllList] = useState<PermissionUserAllList[]>([]);

  const [currentPermissionRole, selectProjectUserRoleFunction] = useState<string | null>(null);

  const [values, handlers] = useListState(initialValues);

  const allChecked = values.every((value) => value.checked);

  const indeterminate = values.some((value) => value.checked) && !allChecked;



  const items = values.map((value, index) => (
    <Checkbox
      mt="xs"
      ml={33}
      disabled={!value.is_edit}
      label={value.label}
      key={value.key}
      checked={value.checked}
      onChange={(event) => handlers.setItemProp(index, 'checked', event.currentTarget.checked)}
    />
  ));

  useEffect(() => {
    setPermissionUserAllList(existsUserList as [])
  }, [existsUserList])

  //Select members from the selection box until to add
  const selectUserToProject = (value: TransferListData) => {
    console.log('value', value);
    setUserAllList(value[1] as unknown as [])
  };
  const [opened, { toggle, close }] = useDisclosure(false);

  const [resetUser, setresetUser] = useState(false);
  // Add member to the task
  const addPremissionRoleInTask = () => {
    // Find out the current character name and ID 
    const selectedUserRole = projectUserRolelist.find(item => { return item.value == currentPermissionRole })
    // 
    if (checkComponents() === false) {
      return false
    }
    
    const updatedUserList: PermissionUserAllList[] = userAllList.map(user => {
      // After finding the same role of the same role, the prompt has been added when adding it again, no need to add the array again
      const existingUser = permissionUserAllList.find(
        existingUser => existingUser.id_user == user.value as string && existingUser.id_permission_role == selectedUserRole?.value
      );

      if (!existingUser) {

        const filteredValues = values.filter(item => item.checked).map(item => item.identifier);

        return {
          ...user,
          id_user: user.value as string,
          permisson_role_title: selectedUserRole?.label as string,
          id_permission_role: selectedUserRole?.value as string,
          permissions:filteredValues          
        };

      } else {

        ClientWarningHint(`${existingUser.email} ${word_translation.already_has_the_role}  ${existingUser.permisson_role_title}`);
      
        return null;
      }
    })
      .filter(user => user !== null) as PermissionUserAllList[];
    // Add the updated user list to the original array
    setPermissionUserAllList(prevList => [...prevList, ...updatedUserList]);

  }
  // 当permissionUserAllList 有变化时反映到 上级组件，准备同步更新
  useEffect(() => {
    callBackFunction(permissionUserAllList)
  }, [permissionUserAllList])

  // Delete member information that has been added to the task
  const callBackDelTaskUserfunction = (index: number) => {
    if (isNotEmpty(index)) {
      // 使用 filter 过滤掉索引为 index 的元素
      const updatedUserList = permissionUserAllList.filter((user, currentIndex) => currentIndex !== index);
      // 更新状态
      setPermissionUserAllList(updatedUserList);
    }
  };


  const selectPermisionFunction = () => {

    if (checkComponents() === false) {
      return false
    }

    toggle()
  };

  function checkComponents(): boolean {

    if (userAllList.length == 0) {
      ClientWarningHint(word_translation.please_choose_member);
      return false;
    }

    if (!currentPermissionRole) {
      ClientWarningHint(word_translation.please_choose_permission_role);
      return false;
    }

    return true
  }
  return (
    <>
      <Box className="px-15  border-bt-1  ">
        <Grid columns={20} justify="center" align="center">
          <Grid.Col span={4}>
            <TableTextInputTitle title={word_translation.select_role_name} />
            <Select
              mb={10}
              placeholder={word_translation.pick_one}
              searchable
              nothingFound={word_translation.no_data}
              onChange={selectProjectUserRoleFunction}
              data={projectUserRolelist} />
            <UniversalButton callBack={selectPermisionFunction} btn_text={word_translation.permissions} />
          </Grid.Col>
          <Grid.Col span={16} py={20}>
            <TableTextInputTitle title={word_translation.add_user} />
            <TransferListUserComponent
              resetUser={resetUser}
              callBack={selectUserToProject}
              userlist={UserList}
            />
          </Grid.Col>
        </Grid>

        <Box>
          <Dialog className="aaa" opened={opened} zIndex={999} withCloseButton onClose={close} size="lg" radius="md">
            <Checkbox
              checked={allChecked}
              indeterminate={indeterminate}
              label="All"
              transitionDuration={0}
              onChange={() =>
                handlers.setState((current) =>
                  current.map((value) => ({ ...value, checked: !allChecked }))
                )
              }
            />
            {items}
            <Box mt={50}>
              <UniversalButton callBack={addPremissionRoleInTask} btn_text={word_translation.create} />
            </Box>
          </Dialog>
        </Box>
      </Box>
      <Box>
        <TaskUserListComponent
         callBackDelfunction={callBackDelTaskUserfunction}
          permissionTaskUserList={permissionUserAllList} 
          is_refresh={0}
           callBackfunction={() => { }} 
          returnAttributeList={() => { }} />
      </Box>

    </>
  )
}