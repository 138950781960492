import {
  Box,
  Table,
  Text,
  ScrollArea,
  Button,
  Group,
  Badge,
  Grid,
  ActionIcon,
} from "@mantine/core";
import { IconEdit, IconTrash, IconUpload } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import SearchBox from "../../../ttcomponents/SearchBox/SearchBox";
import { regReporFormal } from "../../../api";
import { IFormMal } from "../../../interface/Ireports";
import globalCortSetting from "../../../utils/globalCortSetting";
import { modals } from "@mantine/modals";
import localstorageUnits from "../../../utils/localstorageUnits";
import { useIntl } from "react-intl";
import GlobalCoreSetting from "../../../utils/globalCortSetting";

interface FormMalProps {
  FormMals: IFormMal[];
  ReceiveSearchBackCallback: (value: string) => void;
  callback: (value: any, type: string) => void;
}
export default function Calculations({
  FormMals,
  callback,
  ReceiveSearchBackCallback,
}: FormMalProps) {
  // Use the useIntl hook to access the intl object
  const intl = useIntl();

   // Get the default unchanged configuration
   const globalCoreSetting = GlobalCoreSetting();
  //Define the name constant of the calculator
  const [calculatorValue, setCalculatorValue] = useState("");

  //  Receive the callback return data, search for content
  const ReceiveSearchBack = (value: string) => {
    ReceiveSearchBackCallback(value);
  };

  const delHandle = (row: IFormMal) => {
    modals.openConfirmModal({
      title: intl.formatMessage({
        id: "delete_title",
        defaultMessage: "Delete Confirmation",
      }),
      centered: true,
      children: (
        <Text size="sm">
          {intl.formatMessage({
            id: "Filter Name",
            defaultMessage: "Filter Name",
          }) + ": "}
          {row.formal_name}
        </Text>
      ),
      labels: {
        confirm: intl.formatMessage({
          id: "confirm_delete",
          defaultMessage: "Confirm Delete",
        }),
        cancel: intl.formatMessage({
          id: "cancel_delete",
          defaultMessage: "Cancel Delete",
        }),
      },
      confirmProps: { color: "red" },
      onConfirm: async () => {
        const response = await regReporFormal({ id: row.id_formal }, "DELETE");
        ReceiveSearchBackCallback("");
      },
    });
  };

  const editHandle = (row: IFormMal) => {
    callback(row, "up");
  };
  // Define the type in the date selection as the constant of the time range
  const [range_value, setRangeValue] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  return (
    <Box className="border-creates" mih={310} pl={10} pt={10}>
      <Box>
        <Text className="form-title">
          {intl.formatMessage({
            id: "Search Existing Calculations Method",
            defaultMessage: "Search Existing Calculations Method",
          })}
        </Text>
        <Box className="" mb={10}>
          <SearchBox callBack={ReceiveSearchBack} />
        </Box>
        <Grid columns={20}>
          <Grid.Col span={3}>
            {" "}
            <Text className="table-title">
              {intl.formatMessage({
                id: "Calculation Name",
                defaultMessage: "Calculation Name",
              })}
            </Text>{" "}
          </Grid.Col>
          <Grid.Col span={10}>
            <Text className="table-title">
              {intl.formatMessage({
                id: "Calculation Method",
                defaultMessage: "Calculation Method",
              })}
            </Text>
          </Grid.Col>
          <Grid.Col span={4}>
            <Text className="table-title">
              {intl.formatMessage({
                id: "Create At",
                defaultMessage: "Create At",
              })}
            </Text>
          </Grid.Col>
          <Grid.Col span={3}>
            <Text className="table-title">
              {intl.formatMessage({
                id: "Option",
                defaultMessage: "Option",
              })}
            </Text>
          </Grid.Col>
        </Grid>

        <ScrollArea h={200}>
          {FormMals.map((item, index) => {
            const valuesArray = item.formal_value.split(",");
            const name = valuesArray.map((value, index) => {
              if (value.includes("@@")) {
                return (
                  <Badge
                    key={index}
                    size="xs"
                    mx={5}
                    className="cursor-pointer"
                    color="green"
                    variant="outline"
                    radius="xs"
                  >
                    {value.replace(/@@/g, "")}
                  </Badge>
                );
              } else if (globalCoreSetting.OperatorStr.includes(value)) {
                return value;
              } else {
                return (
                  <Badge
                    key={index}
                    size="xs"
                    mx={5}
                    className="cursor-pointer"
                    variant="outline"
                    radius="xs"
                  >
                    {value}
                  </Badge>
                );
              }
            });

            return (
              <Grid columns={20} gutter={0} mt={5}>
                <Grid.Col span={3}>
                  {" "}
                  <Text className="table-td"> {item.formal_name}</Text>{" "}
                </Grid.Col>
                <Grid.Col span={10}>
                  {" "}
                  <Text className="table-td"> {name}</Text>{" "}
                </Grid.Col>
                <Grid.Col span={4}>
                  {" "}
                  <Text className="table-td"> {item.create_at} </Text>{" "}
                </Grid.Col>
                <Grid.Col span={3}>
                  <Group>
                    <ActionIcon
                      onClick={() => editHandle(item)}
                      style={{ position: "relative" }}
                      className="cursor-pointer"
                    >
                      <IconUpload color="gray" size={20} />
                    </ActionIcon>
                    <ActionIcon
                      style={{ position: "relative" }}
                      className="cursor-pointer"
                    >
                      <IconEdit color="gray" size={20} />
                    </ActionIcon>
                    <ActionIcon
                      style={{ position: "relative" }}
                      className="cursor-pointer"
                    >
                      <IconTrash
                        onClick={() => delHandle(item)}
                        color="red"
                        size={20}
                      />
                    </ActionIcon>
                  </Group>
                </Grid.Col>
              </Grid>
            );
          })}
        </ScrollArea>
      </Box>
      <Box mt={10}></Box>
    </Box>
  );
}
