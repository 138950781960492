export function SettingsSvg(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="25" viewBox="0 0 27 25" fill="none">
  <g filter="url(#filter0_d_2_4148)">
    <path d="M21.4616 7.6589C19.6259 7.6589 18.8754 6.3789 19.7882 4.8089C20.3156 3.8989 20.0012 2.7389 19.0782 2.2189L17.3237 1.2289C16.5224 0.758901 15.488 1.0389 15.0113 1.8289L14.8997 2.0189C13.9869 3.5889 12.4859 3.5889 11.563 2.0189L11.4514 1.8289C10.995 1.0389 9.96053 0.758901 9.1593 1.2289L7.40473 2.2189C6.4818 2.7389 6.1674 3.9089 6.69478 4.8189C7.61771 6.3789 6.8672 7.6589 5.03148 7.6589C3.97671 7.6589 3.10449 8.5089 3.10449 9.5589V11.3189C3.10449 12.3589 3.96657 13.2189 5.03148 13.2189C6.8672 13.2189 7.61771 14.4989 6.69478 16.0689C6.1674 16.9789 6.4818 18.1389 7.40473 18.6589L9.1593 19.6489C9.96053 20.1189 10.995 19.8389 11.4717 19.0489L11.5833 18.8589C12.496 17.2889 13.9971 17.2889 14.92 18.8589L15.0316 19.0489C15.5082 19.8389 16.5427 20.1189 17.3439 19.6489L19.0985 18.6589C20.0215 18.1389 20.3359 16.9689 19.8085 16.0689C18.8855 14.4989 19.6361 13.2189 21.4718 13.2189C22.5265 13.2189 23.3988 12.3689 23.3988 11.3189V9.5589C23.3886 8.5189 22.5265 7.6589 21.4616 7.6589ZM13.2466 13.6889C11.4311 13.6889 9.95038 12.2289 9.95038 10.4389C9.95038 8.6489 11.4311 7.1889 13.2466 7.1889C15.062 7.1889 16.5427 8.6489 16.5427 10.4389C16.5427 12.2289 15.062 13.6889 13.2466 13.6889Z" fill="#808080"/>
  </g>
  <defs>
    <filter id="filter0_d_2_4148" x="0" y="0" width="27" height="25" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 10 0" result="softAlpha"/>
      <feOffset dy="1"/>
      <feGaussianBlur stdDeviation="2.5"/>
      <feComposite in2="hardAlpha" operator="over"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_4148"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2_4148" result="shape"/>
    </filter>
  </defs>
</svg>
    
  );
}