// Importing required components and utilities from external libraries
import { Badge, Box, Flex, Text } from "@mantine/core";
import WordTranslationComponent from "../../utils/language_pack/words";
import { defineMessage } from "react-intl";

// Defining the interface for the component props
interface ComponentInterface {
  value: any;        // The value that determines the state of the badge
  color?: string;    // Optional: color of the badge
  radius?: string;   // Optional: radius of the badge
  variant?: string;  // Optional: variant of the badge
}

// React functional component that represents an Active Badge
export default function ActiveBadge({ value, color, radius = 'sm', variant = 'light' }: ComponentInterface) {

  // Get translation public configuration information
  const word_translation = WordTranslationComponent();

  // Determine the text content of the badge based on the provided value
  const text = value == '1' || value == true ? word_translation.activated : word_translation.not_activated;

  // Determine the color of the badge based on the provided value
  const defineColor = value == '1' || value == true ? 'blue' : 'red';

  // Return the Badge component with the determined text, color, radius, and variant
  return (
    <Badge color={color ? color : defineColor} radius={radius} variant={variant}>
      {text}
    </Badge>
  );
}
