import { ActionIcon, Box, Checkbox, ColorInput, ColorPicker, ColorSwatch, Grid, Group, Modal, SegmentedControl, Space, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import ModalTitleText from '../../../ttcomponents/ModalTitleText/ModalTitleText';
import WordTranslationComponent from '../../../utils/language_pack/words';
import { useEffect, useState } from 'react';
import TableTextInputTitle from '../../../ttcomponents/TableTextInputTitle/TableTextInputTitle';
import ResetBtn from '../../../ttcomponents/Button/ResetBtn';
import SubmitBtn from '../../../ttcomponents/Button/SubmitBtn';

interface ComponentProps {
    openFormStatus: boolean;
    closeFunction: () => void;
    objectsUsed: string;
    changeBgCallback: (value: any) => void;
    changeTextCallback: (value: any) => void;
    resetCallback: () => void;
    successCallback?: (value: any) => void;
}

const backgroundColorData = ['#FD6A68', '#F9FF92', '#EAF5FE', '#EAF5FE', '#B0E3F7', '#AFD8F4',
    '#CEB0D3', '#E1B0D1', '#F3ACCC', '#F3ACCC', '#FCD7AA', '#FBE5B5',
    '#DBE9AE', '#CEE7C8', '#C6E5D7'
];

const textColorData = [
    '#25262B', '#FFF', '#767676', '#FA5252', '#E64980', '#BE4BDB',
    '#4C6EF5', '#228BE6', '#15AABF', '#12B886', '#40C057', '#82C91E',
    '#FAB005', '#FD7E14', '#FD7E14'
];

function ColorSelect({ openFormStatus,resetCallback, closeFunction, changeBgCallback,changeTextCallback,successCallback, objectsUsed }: ComponentProps) {
    const [applyStatus, setApplyStatus] = useState(false);
    const [selectObj, setSelectObj] = useState('background');

    // Set color value function
    const [colorValue, setColorValue] = useState('');
    const [bgColorValue, setBgColorValue] = useState('');
    const [textColorValue, setTextColorValue] = useState('');
    const [bgApplyStatus, setBgApplyStatus] = useState('');
    const [textApplyStatus, setTextApplyStatus] = useState('');

    const [randNumber, setRandNumber] = useState(1);

    const [openUserRoleForm, { open: openModalFunction, close: closeModalFunction }] = useDisclosure(false);
    // Get translation public configuration information
    const wordTranslation = WordTranslationComponent();

    useEffect(() => {
        openFormStatus ? openModalFunction() : closeModalFunction();
    }, [openFormStatus])

    useEffect(() => {
        if(openFormStatus){
            if(selectObj === 'text') {
                setBgColorValue(colorValue);
                changeBgCallback(colorValue)
            }else{
                setTextColorValue(colorValue);
                changeTextCallback(colorValue)
            }
        }
    }, [colorValue])

    useEffect(() => {
        applyStatus ?
            selectObj === 'background' ? setBgApplyStatus('1') : setTextApplyStatus('1') :
            selectObj === 'background' ? setBgApplyStatus('') : setTextApplyStatus('');

        setRandNumber(randNumber + 1);
    }, [applyStatus])

    
    // Close component
    const closeBtnModalFunction = () => {
        closeModalFunction();
        closeFunction();
    }

    // Click save
    const applyBtnFunction = () => {
        console.log('Apply');
    }

    const resetColorFunction = () => {
        console.log('Reset');
        setColorValue('');
        setBgColorValue('');
        setTextColorValue('');
        setBgApplyStatus('');
        setTextApplyStatus('');
        setApplyStatus(false);
        resetCallback();
    }

    // Switch background and text button trigger
    const setSelectObjFunction = (value: any) => {
        value === 'background' ? setApplyStatus(bgApplyStatus === '1') : setApplyStatus(textApplyStatus === '1');
        setSelectObj(value);
    }

    return (<>
{openFormStatus ?  <Box w={300}> 
        <Grid px={10}  align='center' justify='center'>
                   <Grid.Col mt={10} span={12}>
                       <SegmentedControl
                           value={selectObj}
                           w='100%'
                           onChange={(value) => setSelectObjFunction(value)}
                           data={[
                               { label: 'Background', value: 'background' },
                               { label: 'Text', value: 'text' },
                           ]}
                       />
                   </Grid.Col>
   
                   <Grid.Col span={12} className='text-center'>
                       <Checkbox
                           label={<TableTextInputTitle title={wordTranslation.apply_to_all + objectsUsed} />}
                           checked={applyStatus}
                           key={randNumber}
                           onChange={(event) => setApplyStatus(event.currentTarget.checked)} />
                   </Grid.Col>
   
                   <Grid.Col span={12}>
                       <Grid key={selectObj}>
                           {selectObj === 'background' && backgroundColorData.map((item, index) => (
                               <Grid.Col span={2} key={index}>
                                   <ActionIcon onClick={() => setColorValue(item)} h={40} w={40}>
                                       <Tooltip label={item}>
                                           <ColorSwatch key={1} size={40} color={item} />
                                       </Tooltip>
                                   </ActionIcon>
                               </Grid.Col>
                           ))}
   
                           {selectObj === 'text' && textColorData.map((item, index) => (
                               <Grid.Col span={2} key={index}>
                                   <ActionIcon onClick={() => setColorValue(item)} h={40} w={40}>
                                       <Tooltip label={item}>
                                           <ColorSwatch key={1} size={40} color={item} />
                                       </Tooltip>
                                   </ActionIcon>
                               </Grid.Col>
                           ))}
                       </Grid>
                   </Grid.Col>
   
                   <Grid.Col mt={10} span={12}>
                       <ColorInput value={colorValue}
                           key={randNumber}
                           onChange={setColorValue}
                       />
                   </Grid.Col>
   
                   <Grid.Col mt={10} span={12}>
                       <Group position="apart">
                           <ResetBtn callBack={resetColorFunction} />
                           <SubmitBtn btn_size='xs' callBack={applyBtnFunction} />
                       </Group>
                   </Grid.Col>
               </Grid>
               {/* <Group>
                   <Box>
                       bg : all row {bgApplyStatus} <ColorSwatch key={1} size={40} color={bgColorValue} />
                   </Box>
                   <Space />
                   <Box>
                       text : all row {textApplyStatus} <ColorSwatch key={21} size={40} color={textColorValue} />
                   </Box>
               </Group> */}
           </Box> :null}        
  
           </>
    );
}
export default ColorSelect;
