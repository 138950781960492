// config.js
/**
 * @type {Config}
 */
const config = {
  /**
   * API Host
   * @type {string}
   */
        apiUrl: "https://letterhouse-backend.asc-vision.com",
  fileSystemDomain: "https://letterhouse-backend.asc-vision.com",
  encryption_key: "gqlPLQxKhtvx",
  version: "0.1.0",
};

export default config;
