import {
  Avatar,
  Checkbox,
  Group,
  TransferList,
  TransferListData,
  TransferListItemComponent,
  TransferListItemComponentProps
} from '@mantine/core'
import { useEffect, useState } from 'react'
import React from 'react'
import TableTextInputTitle from '../../../../../../ttcomponents/TableTextInputTitle/TableTextInputTitle'
import TableTextInputTitleDesc from '../../../../../../ttcomponents/TableTextInputTitleDesc/TableTextInputTitleDesc'
import { UserAllList } from '../../../../../../interface/Iuser'
import WordTranslationComponent from '../../../../../../utils/language_pack/words'
import { useCostCenterLeader, useCostCenterLeaderApi } from '../../context'
import { SelectRole } from '../../../../../../interface/Icommon'
import apiUrl from '../../../../../../config/api_config/config'

const ItemComponent: TransferListItemComponent = React.memo(({ data, selected }: TransferListItemComponentProps) => (
  <Group noWrap>
    <Avatar src={data.avatar} radius="xl" size={25} />
    <div style={{ flex: 1 }}>
      <TableTextInputTitle title={data.label} />
      <TableTextInputTitleDesc title={data.eamil} />
    </div>
    <Checkbox checked={selected} onChange={() => {}} tabIndex={-1} sx={{ pointerEvents: 'none' }} />
  </Group>
))
// 示例数据
const mockdata = [
  {
    value: 'bender',
    avatar: 'https://img.icons8.com/clouds/256/000000/futurama-bender.png',
    label: 'Bender Bending Rodríguez',
    eamil: 'Fascinated with cooking, though has no sense of taste'
  },

  {
    value: 'carol2',
    avatar: 'https://img.icons8.com/clouds/256/000000/futurama-mom.png',
    label: 'Carol Miller',
    eamil: 'One of the richest people on Earth'
  },

  {
    value: 'carol1',
    avatar: 'https://img.icons8.com/clouds/256/000000/futurama-mom.png',
    label: 'Carol Miller',
    eamil: 'One of the richest people on Earth'
  },

  {
    value: 'carol3',
    avatar: 'https://img.icons8.com/clouds/256/000000/futurama-mom.png',
    label: 'Carol Miller',
    eamil: 'task 10'
  }
  // ...other items
]
interface UserDataListProps {
  userlist: UserAllList[]
  updateUserRoleTransferApi: (data: any) => Promise<any>
  refreshPermissionSetCallBack: () => void
}

// 原始的函数组件
const TransferListUserComponent = ({ userlist, updateUserRoleTransferApi, refreshPermissionSetCallBack }: UserDataListProps) => {
  // Get translation public configuration information
  const word_translation = WordTranslationComponent()

  const { id, user_ids, costCenter_id } = useCostCenterLeader()

  const [data, setData] = useState<TransferListData>([mockdata, []])
  const [randNumber, setRandNumber] = useState(1)

  const { updateData } = useCostCenterLeaderApi()

  useEffect(() => {
    const customerData = userlist.map(item => ({
      value: item.id_user.toString() as string,
      label: item.username,
      avatar: apiUrl.fileSystemDomain + item.avatar,
      role: '',
      email: item.email
    }))

    const remainingUsers: SelectRole[] = []
    const extractedUsers: SelectRole[] = []
    for (const user of customerData) {
      if (user_ids.includes(user.value)) {
        extractedUsers.push(user)
      } else {
        remainingUsers.push(user)
      }
    }
    setRandNumber(prev => prev + 1)
    setData([remainingUsers, extractedUsers])
  }, [userlist, user_ids])
  // update userRole transfer
  const updateUserRoleTransfer = async (cur_user_ids: string[]) => {
    if (!id) {
      return
    }
    await updateUserRoleTransferApi({
      permission_role_id: id,
      costCenter_id,
      user_id: cur_user_ids
    })
    refreshPermissionSetCallBack()
  }

  const setDataHandle = (value: TransferListData) => {
    setData(value)
    updateData(
      'user_ids',
      value[1].map(item => item.value)
    )
    updateUserRoleTransfer(value[1].map(item => item.value))
  }
  return (
    <TransferList
      listHeight={310}
      value={data}
      onChange={setDataHandle}
      searchPlaceholder={word_translation.search_user}
      nothingFound={word_translation.search_user}
      className="input_title"
      breakpoint="sm"
      key={randNumber}
      itemComponent={ItemComponent}
      filter={(query, item) => item.label.toLowerCase().includes(query.toLowerCase().trim())}
    />
  )
}

// 使用 React.memo 进行包装
export default React.memo(TransferListUserComponent)
