import { HintInfo, formatDateWithIOS, formatTimeHHSS, getWeekDates } from "../../../../../utils/function";
import WordTranslationComponent from "../../../../../utils/language_pack/words";
import { ActionIcon, Box, LoadingOverlay, Modal,TextInput, Tooltip } from "@mantine/core";
import { useEffect, useState } from "react";
import { IconPencil } from "@tabler/icons-react";
import { TimeTrackerInterface } from "../../../../../interface/Itimetrack";
import { requestTimeTrackerActionList } from "../../../../../api/index_v2";
import { setRefreshStatus } from "../../../../../state_management/features/assignments_refresh_status/assignmentsRefreshStatusSlice";
import { useDispatch } from "react-redux";
import { useDisclosure } from "@mantine/hooks";
import TimeTrackRecordEdit from "../time_track_record";
import { TimeRemarkInterface } from "../../../../../interface/IjobMarks";
import { getTimetrackerRemarkFunction } from "../../../../../api/assignments_request";
import { FormattedMessage } from "react-intl";
import CheckPermissionTools from "../../../../../utils/permission";


// Define the props interface for the Component
interface ChildComponentProps {
    timeTracker: TimeTrackerInterface;
    publicDay: string[];
    vactionDay: string[];
    halfDay: string[];
    index: number;
    weeks: number;
    halPublicDay: string[];
    sickDay: string[];
    currentDate: Date;
    is_editAbled:any
    current_day_of_week: string;
}


// Component representing the SheetTableThead
const SheetTableBodyTd: React.FC<ChildComponentProps> = ({weeks,sickDay, is_editAbled,timeTracker, index, current_day_of_week, publicDay, vactionDay, halfDay, halPublicDay, currentDate }) => {
    // Get translation public configuration information
    const word_translation = WordTranslationComponent();

    const Perm = CheckPermissionTools();

    const weekDayInfo = getWeekDates(currentDate);

    const dispatch = useDispatch();
    const [mark, setmark] = useState<string>("No content");
    const [active, setActive] = useState<string>("");

    const [rowLoading, setRowLoading] = useState(false);

    const [TimeTrackRecord, { open: openedTimeTrackRecord, close: closeTimeTrackRecord },] = useDisclosure(false);
    // Open the addition of binding project order components
    const [currentsetCurrenMarks, setCurrenMarks] = useState<TimeRemarkInterface>();

    // Define the current click Marks Record
    const [currentContent, setCurrentContent] = useState("enter……");
    const [hovered, setHovered] = useState<boolean>(false);
    const [randNumber, setRandNumber] = useState<number>(1);


    const getCurrenMarks = async () => {
        const requestData = {
            time_tracker_id: timeTracker.id_time_tracker,
            day_of_week: current_day_of_week
        }
        setCurrenMarks(await getTimetrackerRemarkFunction(requestData, 'GET'));
    }
    const handleMouseEnter = async (task: any, dates: string,) => {
        setmark("No Comment");
        if (formatTimeHHSS(timeTracker[current_day_of_week] as string) != "00:00") {
            getCurrenMarks()
        }
        setHovered(true)
    };

    useEffect(() => {
        if (currentsetCurrenMarks?.content) {
            setmark(currentsetCurrenMarks?.content)
        } else {
            setmark('No Content')
        }
    }, [currentsetCurrenMarks])


    // 编辑记录
    const editTimeTrackRecode = async (id_time_tracker: any, day_of_week: string, value: string,) => {
        const FormData = {
            id: id_time_tracker,
            time: value,
            day_of_week: day_of_week,
        };
        //  TODO : if has no update ，then not requset
        const dateValue = formatTimeHHSS(timeTracker[current_day_of_week] as string);
        if (dateValue == value) return;
        let methodType = "PUT";
        setRowLoading(true);
        const response = await requestTimeTrackerActionList(FormData, methodType);
        setRowLoading(false);
        const result = response.data;
        HintInfo(result)
        if (result.code === 200) {
            dispatch(setRefreshStatus(true));
        }
    };

    // 关闭模态框
    const closeModal = () => {
        dispatch(setRefreshStatus(true));
        closeTimeTrackRecord();
    };
    const addCommentFunction = async () => {
        const requestData = {
            time_tracker_id: timeTracker.id_time_tracker,
            day_of_week: current_day_of_week
        }
        setCurrenMarks(await getTimetrackerRemarkFunction(requestData, 'GET'));
        openedTimeTrackRecord();
    }

    const titleRowstd = (title: string, index: number) => {
        let active = "";
        if (
            (halPublicDay.includes(formatDateWithIOS(weekDayInfo[index - 1])) ||
                halfDay.includes(formatDateWithIOS(weekDayInfo[index - 1]))) &&
            (publicDay.includes(formatDateWithIOS(weekDayInfo[index - 1])) ||
                vactionDay.includes(formatDateWithIOS(weekDayInfo[index - 1])))
        ) {
            active = "one_day_holiday";
        }


        
        return (
            <th id="table-th-title-new"
                className={`table-th-title-new  ${publicDay.includes(formatDateWithIOS(weekDayInfo[index - 1]))
                        ? "public-holiday"
                        : halPublicDay.includes(formatDateWithIOS(weekDayInfo[index - 1]))
                            ? "halfday-holiday"
                            : vactionDay.includes(formatDateWithIOS(weekDayInfo[index - 1]))
                                ? "one_day_holiday"
                                : halfDay.includes(formatDateWithIOS(weekDayInfo[index - 1]))
                                    ? "halfday-holiday"
                                    : sickDay.includes(formatDateWithIOS(weekDayInfo[index - 1]))
                                    ? "sick_leave"
                                    : ""
                    }  ${active}`}
            >
                <FormattedMessage id={title} defaultMessage={title} />
            </th>
        );
    };

    // add class
    const returnColor =(index: number)=>{
        let active = "";
        if(index ===  5 || index === 6){
            setActive('rest')
        }else{
            if(publicDay?.includes(formatDateWithIOS(weekDayInfo[index]))){
                setActive('sick_leave public-holiday')
            }else if(halPublicDay?.includes(formatDateWithIOS(weekDayInfo[index]))){
                setActive('halfday-holiday')
            } if(vactionDay?.includes(formatDateWithIOS(weekDayInfo[index]))){
                setActive('one_day_holiday')
            } if(halfDay?.includes(formatDateWithIOS(weekDayInfo[index]))){
                setActive('halfday-holiday')
            } if(sickDay?.includes(formatDateWithIOS(weekDayInfo[index]))){
                setActive('sick_leave')
            }
        }
    }

    useEffect(()=>{
        setActive('')
        returnColor(index)
    },[timeTracker.id_time_tracker])

    useEffect(()=>{
        setRandNumber(timeTracker.id_time_tracker+randNumber+weeks+index)
    },[weeks])
    
    return (
        <td key={randNumber}  className= {`sheet_td time_normal ${active} `} id="sheet_td" 
        //    is_editAbled? onMouseLeave={() => setHovered(false)}
        //     onMouseEnter={() => handleMouseEnter(timeTracker, '0')}

            {...(!is_editAbled ? { 
                onMouseLeave: () => setHovered(false),
                onMouseEnter: () => handleMouseEnter(timeTracker, '0'),
              } : {})}
        >
            <LoadingOverlay visible={rowLoading} overlayBlur={2} />
            <Box className={`img-center  `} key={randNumber} >
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  <Tooltip  position="bottom" label={<div dangerouslySetInnerHTML={{ __html: mark }} style={{zIndex: 1000}} />}>
                    <TextInput
                        withAsterisk
                        className={`cursor-pointer-bg`}
                        disabled={(Perm.time_tracker_update == false
                             && Perm.core_timetracker_update == false &&
                             Perm.leader_timetracker_update == false )|| is_editAbled  }
                        key={formatTimeHHSS(timeTracker[current_day_of_week]  as string)}
                        styles={{
                            root: {
                                'input:disabled': {
                                    backgroundColor: 'transparent !important', // set disabled background
                                }
                            },
                          }}
                        onBlur={(event) =>{
                            !is_editAbled && 
                            editTimeTrackRecode(
                                timeTracker.id_time_tracker,
                                current_day_of_week,
                                event.target.value,
                            )
                        }
                        }
                   
                        rightSection={hovered ? <ActionIcon onClick={() => addCommentFunction()}> <IconPencil /></ActionIcon> : ''}
                        variant="unstyled"
                        defaultValue={formatTimeHHSS(timeTracker[current_day_of_week] as string)}
                        w={90}
                    />


                </Tooltip>
            </Box>

            {/* 对时间进行追踪 */}
            <Modal
                size="77%"
                opened={TimeTrackRecord}
                onClose={closeTimeTrackRecord}
                title={word_translation.create}
            >
            <TimeTrackRecordEdit
                currentsetCurrenMarks={currentsetCurrenMarks}
                currentContent={currentContent}
                time_tracker_id={timeTracker.id_time_tracker}
                currentDayOfweek={current_day_of_week}
                callBack={closeModal}
                />
            </Modal>
        </td>

    )

};

export default SheetTableBodyTd;
