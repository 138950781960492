import { Box, Paper, Grid, ScrollArea, Text, Select, TextInput, Tooltip, Group, Code, LoadingOverlay, Skeleton } from "@mantine/core";
import PageTitle from "../../../ttcomponents/PageTitle/PageTitle";
import { useIntl } from "react-intl";
import { useForm } from "@mantine/form";
import { IconAt, IconClipboardPlus, IconTrashX, IconX } from "@tabler/icons-react";
import globalCoreSetting from "../../../utils/globalCortSetting";
import GlobalCoreSetting from "../../../utils/globalCortSetting";
import { FocusEvent, FormEvent, useEffect, useRef, useState } from "react";
import { SelectRole } from "../../../interface/Icommon";
import { reGetDomains, reGetEmployeeCustomAttributeList, regUser, regUserAll, requestBoarding, requestDataBoarding } from "../../../api";
import { Attribute, IAttributeValueSubmit, ResponseUserData } from "../../../interface/Iuser";
import SaveBottom from "../../../ttcomponents/Button/SaveBtn";
import WordTranslationComponent from "../../../utils/language_pack/words";
import TableTextInputTitle from "../../../ttcomponents/TableTextInputTitle/TableTextInputTitle";
import TableTopTitle from "../../../ttcomponents/TableTopTitle/TableTopTitle";
import ResetBtn from "../../../ttcomponents/Button/ResetBtn";
import { getCostCenterGeneralSelectFunction, getCustomAttributesGeneralFunction, getDomainsSelectFunction, getUserGeneralSelectFunction, getUserRoleGeneralSelectFunction } from "../../../api/common_request";
import { HintErrorMantineInfo, HintInfo, getAttributesPreviewComponent } from "../../../utils/function";
import { notifications } from "@mantine/notifications";

import GeneralInformation from "./general_information";
import Affiliation from "./affiliation";
import Extras from "./extras";
import SubmitBtn from "../../../ttcomponents/Button/SubmitBtn";
import { useDisclosure } from "@mantine/hooks";
import React from "react";



interface ComponentInterface {
    setFormValue: (data: any) => any;
    formValue: any
  }
  
  /**
   * OnBoarding 
   * @returns 
   */
  export default function Attributes({formValue,setFormValue}:ComponentInterface) {

    const [loading, setLoading] = useState(false);
    const [rand_number, setRandNumber] = useState<number>(1);
    // Get translation public configuration information
    const word_translation = WordTranslationComponent();
 
    const [attributeList, setAttributeList] = useState<Attribute[]>([]);
 
    const [attributeSubmitData, setAttributeSubmitData] = useState<IAttributeValueSubmit[]>([]);


    /**
     * Get the current list data
     */
    const ajaxCurrentList = async () => {
        // Get a list of cost centers
        getCustomAttributes();
    };

    /**
     * Get a list of custom attributes
     */
    const getCustomAttributes = async () => {
         var result =  await getCustomAttributesGeneralFunction();
         setAttributeList(result);
    };


    const handleAdditionalCallback = (id: string, newValue: string | number) => {
        // 在这里处理新的值
        console.log(id, ': New Value:', newValue);
        // 使用正确的更新方式 
        setAttributeSubmitData((prevData) => {
            // 检查是否已经存在相同的 id
            const existingAttributeIndex = prevData.findIndex((item) => item.id === id);

            if (existingAttributeIndex !== -1) {
                // 如果存在，更新对应的值
                const updatedData = [...prevData];
                updatedData[existingAttributeIndex].value = newValue;
                return updatedData;
            } else {
                // 如果不存在，添加新的属性
                return [...prevData, { id: id, value: newValue }];
            }
        });
    }; 

    
    //初始化自定义属性集合 
    const InitializeCustomAttributeFunction = () => {
        // 将 attributeList 转换为新的数组形式
        const transformedAttributeList = attributeList.map(item => ({
            id: item.id,
            value: item.default
        }));

        if(formValue.attr){
            for(var i = 0;i<transformedAttributeList.length;i++){
                for(var j = 0;j<formValue.attr.length;j++){
                    if(transformedAttributeList[i]['id'] == formValue.attr[j].boarding_attributes_id ){
                       transformedAttributeList[i]['value'] =  formValue.attr[j].value
                    }
                }
            }
           
        }
        
        setAttributeSubmitData(transformedAttributeList)
        // 使用 setFieldValue 设置表单字段的值
        setFormValue((prevData: any) => {
            return {
                ...prevData,
                attr: transformedAttributeList // 使用 name 作为属性名动态更新状态
            };
        });
    }
    
    useEffect(() => {
        InitializeCustomAttributeFunction()
    }, [attributeList]);

    useEffect(() => {
        ajaxCurrentList()
    }, []);

    useEffect(() => {
        setFormValue((prevData: any) => {
            return {
                ...prevData,
                attr: attributeSubmitData // 使用 name 作为属性名动态更新状态
            };
        });
    }, [attributeSubmitData]);
    
    if(attributeSubmitData.length == 0){
        return (<> <Skeleton visible={true}>Loading...</Skeleton></>);
    }
    return (
        <Box>
            <Box className="border-radius-xs mt-md border-custom">
                <TableTopTitle title={word_translation.custom_attributes} />
                <ScrollArea h={formValue.id !=0?270:300} >
                    <Box className="px-15 pt-xs pt-4">
                        <Grid>
                            {attributeSubmitData && attributeList.map((item, index) => {
                                 for(var i = 0;i<attributeSubmitData.length;i++){
                                    if(attributeSubmitData[i]['id'] == item.id ){
                                        item.default = attributeSubmitData[i]['value'] 
                                     }
                                   }
                                return(
                                    <Grid.Col key={index} span={2}>
                                        {getAttributesPreviewComponent(item.id, item.type, item.name, item.default, handleAdditionalCallback, rand_number)}
                                    </Grid.Col>
                                )
                            })}
                        </Grid>
                    </Box>
                </ScrollArea>
            </Box>
    </Box> 
    )
}