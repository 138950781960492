import { Text,Box,ScrollArea,Table,Divider } from "@mantine/core";
interface DataRows {
    week_number_list:any[]|undefined;
    items_list:any[];
    [key:string]:any
} 
interface IReportTemplateOne{
    Data:DataRows | undefined
}
export default function ReportTemplateTwo({Data}:IReportTemplateOne){
    return (
        <ScrollArea mt={20}>
        <Table>
        <thead>
          <tr>
            <th>User/Week</th>
          {Data?.week_number_list ? Data.week_number_list.map((item,index)=>{
             return(
               <th>Kw <>{item}</></th>
             )
          }):''}
            <th>Total </th>
          </tr>
        </thead>
        <tbody>
        { Data?.items_list.map((item,index)=>{
             return(
              <><tr><td>
              <Text className="title_text " size={13} color="dark" > <strong> <u>{item.user_name}</u></strong> </Text>
              {item.week_report.map((sItem: { exclude_items: any[]; } ,sindex: number)=>{
                if(sindex>0) return null;
                return(
                 <>
                  {sItem.exclude_items.map((eitem,eindex)=>{
                    return (
                      <Text className="title_text "  style={{ whiteSpace: 'nowrap' }}  size={13} color="blue" >{eitem.item_name}</Text>
                    )
                  })}
                 </>)
              })}
              <Text className="title_text " size={13} color="dark" >IST</Text>
              <Text className="title_text " size={13} color="dark" > SOLL</Text>
              <Text className="title_text " size={13} color="dark" > DIFF</Text>
              {/* <Text className="title_text " size={13} color="dark" > Check detailed</Text> */}
              </td>
              {item.week_report.map((sItem:any,sindex:any)=>(
                 <td>
                 <Text className="title_text " size={12} color="dark" >  {sItem.productivity}%</Text>
                  {sItem.exclude_items.map((eitem:any,eindex:any)=>{
                    return (
                      <Text className="title_text "  size={12} color="blue" >  {eitem.time}</Text>
                    )
                  })}     
                 <Text className="title_text " size={12} color="green" >   {sItem.actual_time}</Text>
                 <Text className="title_text " size={12} color="dark" >   {sItem.target_time}</Text>
                 <Text className="title_text " size={12} color={sItem.diff_time.includes('-')?'red':'dark'} >  {sItem.diff_time}</Text>
                 </td>
              ))}
              <td>
                    
                 <Text className="title_text " size={12} color="dark" >  {item.week_report_total.productivity}%</Text>
                {item.week_report_total.exclude_items.map((itemRows: any,index: any)=>{
                    return (
                      <Text className="title_text "  style={{ whiteSpace: 'nowrap' }}  size={13} color="blue" >{itemRows.percentage}%</Text>
                    )
                  })}
                 <Text className="title_text " size={12} color="dark" >   {item.week_report_total.actual_time}</Text>
                 <Text className="title_text " size={12} color="dark" >   {item.week_report_total.target_time}</Text>
                 <Text className="title_text " size={12} color={item.week_report_total.diff_time.includes('-')?'red':'dark'} >    {item.week_report_total.diff_time}</Text>
              </td>
             </tr> 
             
             </>
             )
          })}
   
        </tbody>
      </Table>
 
      </ScrollArea>
    )
}