import { Badge, Box, Button, Grid, Group, LoadingOverlay, Modal, Text } from '@mantine/core'

import { useEffect, useState } from 'react'
import { UserAllList } from '../../../../../interface/Iuser'
import { RolePermission } from '../../../../../interface/Iemloyee'
import { SelectRole } from '../../../../../interface/Icommon'
import {
  getCostCenterSelfSelectFunction,
  getPermissionActionListGeneralFunction,
  getPermissionIdentifierListGeneralFunction,
  getTMPermissionRoleDetail
} from '../../../../../api/assignments_request'

import TableTextInputTitle from '../../../../../ttcomponents/TableTextInputTitle/TableTextInputTitle'
import WordTranslationComponent from '../../../../../utils/language_pack/words'
import {
  getAssignablePermissionFunction,
  getUserGeneralFunction,
  updateUserRoleTransferFunction
} from '../../../../../api/common_request'
import PermissionSet from './role_permission'
import { HintInfo, HintInfoCatchError, HintInfoError } from '../../../../../utils/function'
import UniversalButton from '../../../../../ttcomponents/Button/UniversalButton'
import ResetBtn from '../../../../../ttcomponents/Button/ResetBtn'
import { getDefaultFunction } from '../../../../../api/core_request'

import { requestPermissionListRole, requestPermissionRole } from '../../../../../api/index_v2'
import React from 'react'
import CommonPermissionRoleForm from '../common/role_form'
import GlobalCoreSetting from '../../../../../utils/globalCortSetting'
import CommonRoleList from '../common/role_list'
import { modals } from '@mantine/modals'
import { useDisclosure } from '@mantine/hooks'
import SubmitBtn from '../../../../../ttcomponents/Button/SubmitBtn'
import { useCostCenterLeader, useCostCenterLeaderApi } from '../context'
import TransferListUserComponent from '../common/TransferListUserComponent/TransferListUserComponent'
import { IPermissionDetailInterface } from '../../../../../interface/Ipermission'
import { CurrentEdit } from './role_permission/type'

interface ComponentInterface {
  is_load_str?: string
  is_load_str_top?: string
  Permission: any
}

const AssignmentRoleIndex = ({ Permission, is_load_str_top, is_load_str }: ComponentInterface) => {
  // Get translation public configuration information
  const word_translation = WordTranslationComponent()

  const [costCenterData, setCostCenterData] = useState<SelectRole[]>([])
  const [defaultRoleData, setDefaultRoleData] = useState<SelectRole[]>([])
  const {
    id,
    costCenter_id,
    role_costCenter_id,
    title,
    identifier,
    description,
    active,
    role_permissions,
    user_ids,
    project_ids,
    ignore_costCenter,
    all_project,
    all_order,
    all_task,
    task_ids,
    order_ids
  } = useCostCenterLeader()
  const { updateDatePermissionRole, clearCostCenter, updateData } = useCostCenterLeaderApi()

  const [randNumber, setRandNumber] = useState(0)
  const [currentEdit, setCurrentEdit] = useState<CurrentEdit>({
    all_project: false,
    all_order: false,
    all_task: false,
    task_ids: [],
    project_ids: [],
    order_ids: []
  })
  const [currentPermission, setCurrentPermission] = useState<RolePermission[]>([])
  const [UserList, setResultUser] = useState<UserAllList[]>([])

  const [permissionDetail, setPermissionDetail] = useState<IPermissionDetailInterface[]>([])
  const [tempRoleId, setTempRoleId] = useState('')
  // loadding
  const [loading, setLoading] = useState(false)
  const [opened, { open, close }] = useDisclosure(false)

  // get data
  const getCostCenter = async () => {
    // Call the Get Cost Center General Selection function and set the cost center data
    setCostCenterData(await getCostCenterSelfSelectFunction({permission:true}))
  }
  //=============== Get Default Role list Function===============  //
  const getDefaultRole = async () => {
    // Call the Get Cost Center General Selection function and set the cost center data
    setDefaultRoleData(await getDefaultFunction())
  }

  // Get a list of users <FUNCTION>
  const getUser = async () => {
    if (costCenter_id) {
      // Call the Get User Universal Selection function and set the user list
      let userList = await getUserGeneralFunction({ costCenter_ids: costCenter_id }, 'all_info')
      
      setResultUser(userList)
    } else {
      setResultUser([])
    }
  }
  const fetchPermissionRoleDetail = async (paramId: string) => {
    const result = await getTMPermissionRoleDetail({ id_permission_role: paramId })
    const { detail, task_ids, project_ids, order_ids, ignore_costCenter, permission_sets, userIds } = result

    const premissions = permission_sets.map((item: any) => {
      return {
        permission_ids: item.permission_ids,
        permission_identifier_id: item.permission_identifier_id
      }
    })
    const user_ids = userIds.map((item: any) => item.user_id.toString())
    const curEdit = {
      all_project: detail?.all_project || false,
      all_order: detail?.all_order || false,
      all_task: detail?.all_task || false,
      ignore_costCenter: ignore_costCenter == '1',
      task_ids: task_ids || [],
      project_ids: project_ids || [],
      order_ids: order_ids || []
    }

    updateDatePermissionRole({
      title: detail.title,
      description: detail.description,
      active: detail.active,
      role_permissions: [],
      user_ids,
      ...curEdit
    })

    if (costCenter_id == '-2') {
      updateData('role_costCenter_id', result.detail.costCenter_id as string[])
    }

    // Delayed execution
    setTimeout(() => {
      updateData('role_permissions', premissions)
    }, 0)

    setCurrentPermission(premissions)
    setCurrentEdit(curEdit)
  }

  useEffect(() => {
    is_load_str == 'assignment' && getCostCenter()
    is_load_str == 'assignment' && getDefaultRole()
  }, [is_load_str])
  useEffect(() => {
    // Obtaining department users
    getUser()
  }, [costCenter_id])

  useEffect(() => {
    if (costCenter_id && id) {
      fetchPermissionRoleDetail(id)
    }
  }, [costCenter_id, id])
  useEffect(() => {
    setCurrentPermission([])
  }, [identifier])

  useEffect(() => {}, [costCenter_id])

  // Resetting the form is different from the reset button, it is resetting the entire form
  const resetFunction = () => {
    clearCostCenter()
    setResultUser([])
    // need to clear currentPermission state
    setCurrentPermission([])
    setCurrentEdit({
      all_project: false,
      all_order: false,
      all_task: false,
      task_ids: [],
      project_ids: [],
      order_ids: []
    })
  }

  const globalCoreSetting = GlobalCoreSetting()

  //=============== Click on a permission in the list Function===============  //
  const roleListcallBackFunction = async (paramID: string) => {
    setRandNumber(randNumber + 1)
    updateData('selectedRole', paramID)
    updateData('id', paramID)
  }

  const SaveFormFunction = async () => {
    const methodType = id ? 'PUT' : 'POST'
    if (methodType == 'PUT') {
      modals.openConfirmModal({
        title: 'Prompt',
        centered: true,
        children: (
          <Text size="sm" mt={12}>
            <p>
              You are about to modify the information of the current role. Are you sure you want to proceed with the
              changes
            </p>
          </Text>
        ),
        labels: globalCoreSetting.EditOpenConfirmModaLabels,
        confirmProps: globalCoreSetting.DeleteOpenConfirmModaConfirmProps,
        onConfirm: async () => {
          submitFormFunction()
        }
      })
    } else {
      submitFormFunction()
    }
  }

  // Submit, save or modify data
  const submitFormFunction = async () => {
    let submitData: any = {
      id,
      costCenter_id,
      title,
      identifier,
      description,
      active,
      role_permissions,
      user_ids,
      project_ids,
      ignore_costCenter,
      all_project,
      all_order,
      all_task,
      task_ids,
      order_ids
    }
    if (costCenter_id == '-2') {
      submitData['costCenter_id'] = role_costCenter_id
      submitData['role_costCenter_id'] = '-2'
    } else {
      submitData['role_costCenter_id'] = costCenter_id
    }
    const methodType = submitData.id ? 'PUT' : 'POST'
    try {
      setLoading(true)
      const response = await requestPermissionRole(submitData, methodType)
      const result = response.data
      // Display information based on the response
      if (result.code === 200) {
        HintInfo(response.data)
        callBackClearRole()
        close()
        roleListcallBackFunction(result.data.id.toString())
      } else {
        if (result.code == 4113) {
          setTempRoleId(result.data.permission_identifier_id)
          setPermissionDetail(result.data.data)
          open()
        } else {
          HintInfoError(result.msg)
        }
      }
    } catch (error) {
      HintInfoCatchError()
    } finally {
      setLoading(false)
    }
  }

  
  // Submit, save or modify data
  const submitFormFunctionUp = async () => {
    let submitData: any = {
      id,
      costCenter_id,
      title,
      identifier,
      description,
      active,
      role_permissions,
      user_ids,
      project_ids,
      ignore_costCenter,
      all_project,
      all_order,
      all_task,
      task_ids,
      order_ids,
      senior_pass: true
    }
    
    if (costCenter_id == '-2') {
      submitData['costCenter_id'] = role_costCenter_id
      submitData['role_costCenter_id'] = '-2'
    } else {
      submitData['role_costCenter_id'] = costCenter_id
    }

    const methodType = submitData.id ? 'PUT' : 'POST'
    try {
      setLoading(true)
      const response = await requestPermissionRole(submitData, methodType)
      const result = response.data
      // Display information based on the response
      HintInfo(response.data)
      if (result.code === 200) {
        close()
        callBackClearRole()
      } else {
        HintInfoError(result.msg)
      }
    } catch (error) {
      HintInfoCatchError()
    } finally {
      setLoading(false)
    }
  }

  //Clear permission role callback
  const callBackClearRole = () => {
    setRandNumber(randNumber + 1)
  }
  const refreshPermissionSetCallBack = () => {
    setRandNumber(randNumber + 1)
  }

  return (
    <Box>
      <LoadingOverlay visible={loading} overlayBlur={2} />
      <Grid columns={36} gutter="xl">
        <Grid.Col span={19}>
          <CommonPermissionRoleForm
            defaultRoleData={defaultRoleData}
            costCenterData={costCenterData}
            resetFunction={resetFunction}
          />
          <Box mt={10}></Box>

          <TableTextInputTitle py={5} pb={5} title={word_translation.permission_edit} />

          <PermissionSet
            currentPermission={currentPermission}
            currentEdit={currentEdit}
            identifierListApi={getPermissionIdentifierListGeneralFunction}
            actionListApi={getPermissionActionListGeneralFunction}
            assignablePermissionApi={getAssignablePermissionFunction('assignment')}
          />
        </Grid.Col>
        <Grid.Col span={17}>
          <CommonRoleList
            randNumber={randNumber}
            requestPermissionRole={requestPermissionRole}
            requestPermissionRoleList={requestPermissionListRole}
          />
          <TableTextInputTitle py={5} pb={5} title={word_translation.members} />

          <TransferListUserComponent
            userlist={UserList}
            updateUserRoleTransferApi={updateUserRoleTransferFunction('assignment')}
            refreshPermissionSetCallBack={refreshPermissionSetCallBack}
          />

          <Group position="right" mt={10}>
            <ResetBtn callBack={resetFunction} />
            <UniversalButton disabled={true} btn_text={word_translation.udpate_user} callBack={SaveFormFunction} />
            <UniversalButton btn_text={word_translation.submit} callBack={SaveFormFunction} />
          </Group>
        </Grid.Col>
      </Grid>

      <Modal opened={opened} onClose={close} title="This permission already exists">
        <Box mt={5}>
          <Text fz={15}>
            The permission set already exists. The users submitted this time will be replace the existing permission
            set. Are you sure you want to proceed?
          </Text>
          {permissionDetail.map((item: any, index: any) => {
            return (
              <Group>
                <Badge>{item.identifier}</Badge> - {item.action}
              </Group>
            )
          })}
        </Box>
        <Box>
          <Group mt={20} position="apart">
            <Box>
              <Button
                size="xs"
                color="green"
                onClick={() => {
                  roleListcallBackFunction(tempRoleId)
                  close()
                }}
              >
                show existing permission{' '}
              </Button>
            </Box>
            <Box>
              <Group>
                <Button size="xs" color="gray" onClick={close}>
                  {word_translation.cancel}
                </Button>
                <SubmitBtn btn_size="xs" callBack={submitFormFunctionUp} />
              </Group>
            </Box>
          </Group>
        </Box>
      </Modal>
    </Box>
  )
}
// 使用 React.memo 进行包装
export default React.memo(AssignmentRoleIndex)
