import { createSlice } from  '@reduxjs/toolkit'
import type { PayloadAction } from  '@reduxjs/toolkit'

export interface expandStatusInterface{
  expandStatus:boolean
}

const initialState:expandStatusInterface ={
   expandStatus:false
}

export const assignmentsExpandStatusSlice = createSlice({
    name:"expandStatus",
    initialState,
    reducers:{
        setExpandStatus:(state,action:PayloadAction<boolean>)=>{
            state.expandStatus = action.payload as expandStatusInterface['expandStatus']
        }
    }
})

export const {setExpandStatus} = assignmentsExpandStatusSlice.actions;

export default assignmentsExpandStatusSlice.reducer;